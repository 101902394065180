import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';

// components
import Modal from 'components/Modal/Modal';
import { Button } from 'components-design-system'

const ConfirmDeleteArrayItem = ({
  openModal,
  setOpenModal,
  element,
}) => {
  const onDelete = () => {
    setOpenModal(false)
    setTimeout(element.onDropIndexClick(element.index), 100);
  }
  
  return (
    <Modal
      title={`Are you sure you want to delete?`}
      open={openModal}
      onCancel={() => setOpenModal(false)}
      className={"pft-comment-modal"}
      footer={[]}
      type="delete">
      <Text>NOTE: This action can not be undone.</Text>
      <ActionContainer>
        <Button 
          variant="outlined"
          onClick={() => setOpenModal(false)}>
          Cancel
        </Button>
        <Button 
          variant="danger"
          onClick={onDelete}>
          Delete
        </Button>
      </ActionContainer>
    </Modal>
  );
};

export default ConfirmDeleteArrayItem;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-top: 24px;
`

const Text = styled.div`
  color: #525252;
`