import React from 'react';
import { create } from 'zustand'

const initialState = {
  arms: [],
  interventions: [],
}
// this store is for arms and interventions + cross ref table
const useProtocolTableArmsStore = create((set) => ({
  ...initialState,
  setArms: (arms) => set({ arms }),
  setInterventions: (interventions) => set({ interventions }),
  resetProtocolTableArmsStore: () => set(initialState),
}))

export default useProtocolTableArmsStore;