import React from 'react';
import { useShallow } from 'zustand/react/shallow'

import useResultsBaselineStore from '../../../../hooks/stores/useResultsBaselineStore';
import useAuthoringDataStore from '../../../../hooks/stores/useAuthoringDataStore';
import useResultsTableArmsStore from '../../../../hooks/stores/useResultsTableArmsStore';

const useBaselineActions = () => {
  const {
    baselineData,
    setBaselineData,
  } = useResultsBaselineStore(
    useShallow(state => ({
      baselineData: state.baselineData,
      setBaselineData: state.setBaselineData,
    }))
  )

  const {
    baselineSas,
  } = useResultsTableArmsStore(
    useShallow(state => ({
      baselineSas: state.baselineSas,
    }))
  )

  const onCreateSubjectAnalysisSetInBaseline = (newSet) => {
    if (newSet) {
      let foundIndex = baselineSas.findIndex(sas => sas.id === newSet.id)

      if (foundIndex === -1) {
        let tempData = { ...baselineData }
        if (tempData?.eudract) {
          let sets = []
          if (tempData.eudract?.subject_analysis_sets) {
            sets = [...tempData.eudract.subject_analysis_sets]
          }
          sets.push(newSet)

          tempData.eudract.subject_analysis_sets = sets;

          let newSets = [newSet]

          if (tempData.eudract?.baseline_measures) {
            let baselineMeasures = tempData.eudract.baseline_measures;
            if (baselineMeasures) {
              baselineMeasures.forEach(bm => {
                bm.rows.forEach(bmRow => {
                  bmRow.categories.forEach(rowCat => {
                    let values = []
                    if (rowCat.values) values = [...rowCat.values]
                    let updatedSets = newSets.map(set => {
                      return {
                        arm_id: set.id,
                        registry_arm_id: set.registry_arm_id,
                        value: null
                      }
                    })
                    values = [...values, ...updatedSets]
                    rowCat.values = values
                  })
                })
              })
            }
          }
        }

        setBaselineData(tempData)
      }
    }
  }

  return {
    onCreateSubjectAnalysisSetInBaseline
  }
};

export default useBaselineActions;