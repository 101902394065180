import { useEffect, useState, useMemo } from 'react';
import { useParams } from "react-router-dom"

// components
import { Button } from 'components-design-system'

// apis
import { useUserSettings, usePostUserSettings } from 'api/hooks';
import { useGetStudy } from "api/hooks/studies/useStudiesApi";

// styles
import './Alerts.scss';

const Alerts = (props) => {
  const { studyId } = useParams()
  const { data: study } = useGetStudy(studyId)
  const { data: userSettings } = useUserSettings()
  const postUserSettings = usePostUserSettings()
  const [formData, setFormData] = useState(userSettings)

  const onBlock = (key) => {
    let temp = { ...formData }
    temp.email_notification_settings[key].blocked_studies.push(study.study_id)
    postUserSettings({ body: temp })
    setFormData(temp)
  }

  const onUnblock = (key) => {
    let temp = { ...formData }
    let foundIndex = temp.email_notification_settings[key].blocked_studies.findIndex(stuId => stuId === study.study_id)
    if (foundIndex !== -1) {
      temp.email_notification_settings[key].blocked_studies.splice(foundIndex, 1);
    }

    postUserSettings({ body: temp })
    setFormData(temp)
  }

  const isQaBlocked = useMemo(() => {
    if (userSettings?.email_notification_settings?.send_qa_comment_alert?.blocked_studies) {
      let foundIndex = userSettings.email_notification_settings.send_qa_comment_alert.blocked_studies.findIndex(id => id === study.study_id)
      if (foundIndex === -1) {
        return false
      } else {
        return true
      }
    }
  }, [formData])

  const isAnticipatedBlocked = useMemo(() => {
    if (userSettings?.email_notification_settings?.send_anticipated_date_past_alert?.blocked_studies) {
      let foundIndex = userSettings.email_notification_settings.send_anticipated_date_past_alert.blocked_studies.findIndex(id => id === study.study_id)
      if (foundIndex === -1) {
        return false
      } else {
        return true
      }
    }
  }, [formData])

  const schema = [
    {
      title: "QA comment is received/added to study",
      action: () => isQaBlocked ? onUnblock("send_qa_comment_alert") : onBlock("send_qa_comment_alert"),
      variant: isQaBlocked ? "outlined" : "primary",
      buttonTitle: isQaBlocked ? "Enable" : "Dismiss",
    },
    {
      title: "The anticipated date is in the past for a milestone",
      action: () => isAnticipatedBlocked ? onUnblock("send_anticipated_date_past_alert") : onBlock("send_anticipated_date_past_alert"),
      variant: isAnticipatedBlocked ? "outlined" : "primary",
      buttonTitle: isAnticipatedBlocked ? "Enable" : "Dismiss",
    }
  ]

  const displayRows = () => {
    return schema.map(item => {
      return (
        <tr key={item.title}>
          <td>
            <p className="alert-row-title">
              {item.title}
            </p>
          </td>
          <td>
            <Button
              size="sm"
              variant={item.variant}
              onClick={item.action}>
              {item.buttonTitle}
            </Button>
          </td>
        </tr>
      )
    })
  }

  return (
    <div className="alerts-container">
      <table>
        <thead>
          <tr>
            <th
              className="alerts-header"
              colSpan={2}>
              Notifications
            </th>
          </tr>
        </thead>
        <tbody>
          {displayRows()}
        </tbody>
      </table>
    </div>
  );
};

export default Alerts;