// used by Sidebar's ActiveSponsorTags and by ActiveSponsorsSelected
import React from 'react'
import './ActiveSponsorTag.scss'

const ActiveSponsorTag = ({
  text,
  onDelete,
  maxWidth,
  badgeStyle = {},
  disabled
}) => {
  let style = {}
  if (maxWidth) {
    style = {
      maxWidth,
      textOverflow: "ellipsis",
    }
  }
  return <div
    className="sponsor-tag"
    style={{ ...style, ...badgeStyle }}
  >
    {text}
    {disabled !== true && <i
      className="fal fa-times-circle"
      onClick={() => onDelete(text)}
    />}
  </div>
}

export default ActiveSponsorTag