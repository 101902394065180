import React from 'react';
import { Tooltip } from 'antd';
import ReactCountryFlag from "react-country-flag"
import { countries as countriesConstant } from 'constants/countries'
import { useGetCountries } from 'api/hooks/enum';

const CountriesCellRenderer = ({ value }) => {
  const { data: countries = countriesConstant } = useGetCountries()
  const countriesMap = React.useMemo(() => {
    const map = {}
    countries.forEach(country => {
      map[country.cca2] = country
    })
    return map
  })

  return (
    <div style={{ display: "flex", gap: 4 }}>
      {value
        ?.filter((item) => item)
        .map((code) => {
          let country = countriesMap?.[code]

          return (
            <div
              key={`countries-panel-${code}`}
              className="countries-panel-flag-wrap"
            >
              <Tooltip title={country?.name?.common}>
                <ReactCountryFlag
                  key={country}
                  style={{ width: '2em', height: '2em' }}
                  svg
                  countryCode={code}
                  className="country-flag"
                />
              </Tooltip>
            </div>
          )
        })}
    </div>
  );
};

export default CountriesCellRenderer;