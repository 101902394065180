import {
  useQueryClient,
  useMutation,
} from '@tanstack/react-query'
import axios from 'axios'
import { getCookie } from 'api/apiUtils'
import PropTypes from 'prop-types'

// hooks
import useAppState from 'context/hooks/useAppState'

const useMutateApi = ({
  method,
  apiEndpoint,
  invalidateQueries = [],
  successMsg,
  infoMsg,
  options = {} // axios options
}) => {
  const queryClient = useQueryClient()
  const { openToastNotification } = useAppState()

  const { mutate } = useMutation(async ({
    id,
    body,
    headers = {},
    params = {},
  }) => {
    let url = apiEndpoint
    if (id) url = `${apiEndpoint}${id}`;

    let axiosParams = {
      method,
      url,
      headers: {
        'Authorization': `Bearer ${getCookie("xopri-jwt-actoken")}`,
        ...headers,
      },
      options,
      params
    }
    if (body) axiosParams.data = body;
    return await axios(axiosParams)
  }, {
    onSuccess: (data) => {
      invalidateQueries.forEach(key => {
        queryClient.invalidateQueries(key)
      })

      if (successMsg) openToastNotification("success", "Success", successMsg);
      if (infoMsg) openToastNotification("info", "Info", infoMsg)

      return data
    },
    onError: (err) => console.log('useMutateApi err', { err })
  })

  return mutate
}

useMutateApi.propTypes = {
  props: PropTypes.shape({
    method: PropTypes.oneOf(['post', 'put', 'delete']).isRequired,
    apiEnpoint: PropTypes.string.isRequired.isRequired,
    invalidateQueries: PropTypes.array,
    successMsg: PropTypes.string, // a node can also be sent
    infoMsg: PropTypes.string, // a node can also be sent
  }).isRequired
}

export default useMutateApi