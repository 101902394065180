import { useState, memo } from 'react'
import { Handle, Position, useStore, NodeProps, useReactFlow } from 'reactflow';

// styles
import './DecisionTreeNode.scss'

export default memo((props) => {
  const { data, isConnectable } = props
  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        style={{ background: '#555' }}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <div className='dt-node-container'>
        {data.label}
      </div>
      {/* <input className="nodrag" type="color" onChange={data.onChange} defaultValue={data.color} /> */}
      <Handle
        type="source"
        position={Position.Right}
        id="a"
        // style={{ top: 10, background: '#555' }}
        isConnectable={isConnectable}
      />
      {/* <Handle
        type="source"
        position={Position.Right}
        id="b"
        style={{ bottom: 10, top: 'auto', background: '#555' }}
        isConnectable={isConnectable}
      /> */}
    </>
  );
});