import { useState } from 'react';

// components
import RowActions from '../../../components/RowActions/RowActions';

// context
import useResultsTableViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableViewStore';

// utils
import { moveLeftHelper, moveRightHelper } from '../../helpers/helpers';

const EventItemHeader = ({
  events = [],
  event,
  rowIndex,
  adverseData,
  setAdverseData,
  eventListKey,
  children
}) => {

  const {
    tableView
  } = useResultsTableViewStore()

  const [showActions, setShowActions] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleUp = () => {
    let tempData = { ...adverseData };

    Object.keys(tempData).forEach(registry => {
      let foundEventIndex = tempData[registry][eventListKey].findIndex(item => item.id === event.id);
      if (foundEventIndex !== -1 && foundEventIndex !== 0) moveLeftHelper(tempData[registry][eventListKey], foundEventIndex)
    })

    setAdverseData(tempData);
  }

  const handleDown = () => {
    let tempData = { ...adverseData };

    Object.keys(tempData).forEach(registry => {
      let foundEventIndex = tempData[registry][eventListKey].findIndex(item => item.id === event.id);
      if (foundEventIndex !== -1 && foundEventIndex !== tempData[registry][eventListKey].length - 1) moveRightHelper(tempData[registry][eventListKey], foundEventIndex)
    })

    setAdverseData(tempData);
  }

  const onDelete = (applyAll) => {
    let tempData = { ...adverseData };

    Object.keys(tempData).forEach(registry => {
      if (tableView === registry || applyAll) {
        let foundEventIndex = tempData[registry][eventListKey].findIndex(item => item.id === event.id);
        if (foundEventIndex !== -1) tempData[registry][eventListKey].splice(foundEventIndex, 1);
      }
    })

    setAdverseData(tempData);
    setShowDeleteModal(false);
  }

  return (
    <td
      style={{ position: "relative" }}
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}>
      <RowActions
        showActions={showActions}
        setShowActions={setShowActions}
        showUp={rowIndex !== 0}
        showDown={rowIndex !== events.length - 1}
        handleUp={handleUp}
        handleDown={handleDown}
        handleDelete={onDelete}
        jpath={`ReportedEvents.ae-${eventListKey}-header-${event.id}`}
        deleteModalTitle="Delete adverse event?"
        canDelete={true} />
      {children}
    </td>
  );
};

export default EventItemHeader;