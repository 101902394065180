import useGetApi from "./useGetApi"
import useMutateApi from "./useMutateApi"
// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"
import getUrl from "api/apiMap"

// user-info
const useUserInfo = () => {
  return useGetApi(getUrl(apiEndpoints.userInfo), queryKeys.userInfo)
}

// user-permissions
const useUserPermissions = () => {
  return useGetApi(getUrl(apiEndpoints.userPermissions), queryKeys.userPermissions)
}

// user-settings
const useUserSettings = () => {
  return useGetApi(getUrl(apiEndpoints.userSettings), queryKeys.userSettings)
}

const usePostUserSettings = () => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(`${apiEndpoints.userSettings}`),
    invalidateQueries: [queryKeys.userSettings],
    successMsg: 'Successfully updated settings'
  })
}

const usePollTask = (taskId, options) => {
  return useGetApi(getUrl(apiEndpoints.pollTask + taskId), queryKeys.pollingTask, options)
}

const useGetFavoriteStudies = () => {
  return useGetApi(getUrl(apiEndpoints.favoriteStudies), queryKeys.favoriteStudies)
}

const useGetFavoriteRequests = () => {
  return useGetApi(getUrl(apiEndpoints.favoriteRequests), queryKeys.favoriteRequests)
}

const useGetPasswordExpiryDeadline = () => {
  return useGetApi(getUrl(apiEndpoints.pwdExpiryDeadline), [])
}

export {
  useGetApi,
  useMutateApi,

  // user-info
  useUserInfo,

  // user-permissions
  useUserPermissions,

  // user-settings
  useUserSettings,
  usePostUserSettings,
  usePollTask,
  useGetFavoriteStudies,
  useGetPasswordExpiryDeadline,
  useGetFavoriteRequests,
}