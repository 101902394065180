/** Used by FavoriteStudyCellRenderer.jsx and FavoriteRequestCellRenderer
 * 
 * Becuase study and request pages use SSRM grids and do not invalidate react-query keys, must control value on this component
 */
import React from 'react'
import PropTypes from 'prop-types'
import './FavoriteCellRenderer.scss'

const FavoriteCellRenderer = ({
  value,
  onClick,
  readOnly
}) => {

  const [isFavorite, setIsFavorite] = React.useState(value)

  const style = {}
  if (readOnly || !onClick) style.cursor = "auto";

  const handleClick = () => {
    setIsFavorite((state) => !state)
    onClick()
  }

  return <i
    style={style}
    onClick={readOnly || !onClick ? null : handleClick}
    className={`${isFavorite === true ? 'fas' : 'fal'} fa-heart fave-cell-renderer`}
  />
}

FavoriteCellRenderer.propTypes = {
  value: PropTypes.bool,
  onClick: PropTypes.func,
  readOnly: PropTypes.bool,
}

export default FavoriteCellRenderer