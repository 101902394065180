const userToString = (user) => {
  let name = [];
  if (user?.full_name) {
    name.push(user.full_name)
  } else if (user.first_name && user.last_name) {
    if (user.first_name) name.push(user.first_name);
    if (user.last_name) name.push(user.last_name);
  }
  if (user?.email) name.push(`(${user.email})`)
  return name.join(" ");
}

export {
  userToString,
}