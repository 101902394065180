import React from 'react'
import moment from 'moment'
import { Tooltip } from "antd"
import { useUserSettings } from 'api/hooks';

const DueDateCellRenderer = ({
  value,
  data, // get note
}) => {
  const { data: userSettings } = useUserSettings()
  if (!value) return null

  let dateFormat = userSettings?.date_format

  return (
    <div>
      <Tooltip title={data.note}>
        <div>
          {moment(value).format(dateFormat)}
        </div>
      </Tooltip>
    </div>
  )
};

export default DueDateCellRenderer
