import { useState } from 'react';
import { useShallow } from 'zustand/react/shallow'

// components
import PrimeField from 'components/PrimeField/PrimeField';
import CellActions from '../CellActions/CellActions';
import RowActions from '../../../components/RowActions/RowActions';
import Label from 'components/Label/Label';
import CellValidation from '../../../components/CellValidation/CellValidation';

// context
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';
import useResultsTableViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableViewStore';

const CellHeader = ({
  row,
  rowIndex,
  period,
  type,
  participantData,
  setParticipantData,
  errorKey
}) => {
  const [showActions, setShowActions] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isTitleOther, setIsTitleOther] = useState(false)

  const {
    participantRegistries,
    participantView
  } = useResultsTableViewStore()

  const {
    readOnly
  } = useAuthoringViewStore(
    useShallow(state => ({ readOnly: state.readOnly }))
  )

  const items = [
    "Adverse Event",
    "Death",
    "Lack of Efficacy",
    "Lost to Follow-up",
    "Physician Decision",
    "Pregnancy",
    "Protocol Violation",
    "Withdrawal by Subject",
    "Other"
  ]

  const headerStyle = {
    width: 250 - 24,
    minWidth: 250 - 24
  }

  let rows = period[type]

  const canDelete = () => {
    if (type === "milestone_list" && (rowIndex === 0 || rowIndex === rows.length - 1)) return false
    return true
  }

  const canShowDown = () => {
    if (type === "milestone_list" && rows.length > 3 && rowIndex < rows.length - 2 && rowIndex !== 0) return true
    else if (type === "drop_withdrawn_reason_list" && rowIndex < rows.length - 1) return true
    return false
  }

  const canShowUp = () => {
    if (type === "milestone_list" && rows.length > 3 && rowIndex !== 1 && rowIndex < rows.length - 1 && rowIndex !== 0) return true
    else if (type === "drop_withdrawn_reason_list" && rowIndex !== 0) return true
    return false
  }

  const handleDelete = (applyAll) => {
    let tempData = { ...participantData };

    participantRegistries.forEach(registry => {
      if (applyAll || participantView === registry) {
        let foundPeriodIndex = tempData[registry].findIndex(item => item?.id === period.id);
        if (foundPeriodIndex !== -1) {
          let rowIndex = tempData[registry][foundPeriodIndex][type].findIndex(item => item?.id === row.id);
          if ((rowIndex !== -1 && rowIndex !== 0 && rowIndex !== tempData?.[registry]?.[foundPeriodIndex]?.[type]?.length - 1) || type === "drop_withdrawn_reason_list") {
            tempData[registry][foundPeriodIndex][type].splice(rowIndex, 1)
          }
        }
      }
    })

    setParticipantData(tempData);
    setOpenDeleteModal(false);
  }

  const handleUp = () => {
    let tempData = { ...participantData }
    participantRegistries.forEach(registry => {
      let foundPeriodIndex = tempData[registry].findIndex(item => item?.id === period.id);
      if (foundPeriodIndex !== -1) {
        let rowIndex = tempData[registry][foundPeriodIndex][type].findIndex(item => item?.id === row.id);
        if ((rowIndex !== -1 && rowIndex !== 1) || (type === "drop_withdrawn_reason_list" && rowIndex !== 0)) {
          let savedPeriod = tempData[registry][foundPeriodIndex][type][rowIndex - 1];
          tempData[registry][foundPeriodIndex][type][rowIndex - 1] = tempData[registry][foundPeriodIndex][type][rowIndex];
          tempData[registry][foundPeriodIndex][type][rowIndex] = savedPeriod;
        }
      }
    })
    setParticipantData(tempData);
  }

  const handleDown = () => {
    let tempData = { ...participantData }
    participantRegistries.forEach(registry => {
      let foundPeriodIndex = tempData[registry].findIndex(item => item?.id === period.id);
      if (foundPeriodIndex !== -1) {
        let rowIndex = tempData[registry][foundPeriodIndex][type].findIndex(item => item?.id === row.id);

        if ((rowIndex !== -1 && rowIndex !== tempData[registry][foundPeriodIndex][type].length - 2) || (type === "drop_withdrawn_reason_list" && rowIndex !== tempData[registry][foundPeriodIndex][type].length - 1)) {
          let savedPeriod = tempData[registry][foundPeriodIndex][type][rowIndex + 1];
          tempData[registry][foundPeriodIndex][type][rowIndex + 1] = tempData[registry][foundPeriodIndex][type][rowIndex];
          tempData[registry][foundPeriodIndex][type][rowIndex] = savedPeriod;
        }
      }
    })
    setParticipantData(tempData);
  }

  const onChange = (e) => {
    let tempData = { ...participantData }
    participantRegistries.forEach(registry => {
      let foundPeriodIndex = tempData[registry].findIndex(item => item?.id === period.id);
      if (foundPeriodIndex !== -1) {
        let foundMilestoneIndex = tempData[registry][foundPeriodIndex][type].findIndex(item => item?.id === row.id);
        if (foundMilestoneIndex !== -1) {
          if (e === "Other" && type === "drop_withdrawn_reason_list") {
            setIsTitleOther(true)
            tempData[registry][foundPeriodIndex][type][foundMilestoneIndex].required = false;
          } else {
            tempData[registry][foundPeriodIndex][type][foundMilestoneIndex].title = e;
          }
          if (type === "drop_withdrawn_reason_list" && e !== "Other" && items.find(item => item === e)) {
            tempData[registry][foundPeriodIndex][type][foundMilestoneIndex].required = true;
          }
        }
      }
    })
    setParticipantData(tempData)
  }

  const renderTitle = () => {
    if (type === "milestone_list" && !row.required) {
      return (
        <PrimeField
          schema={{
            type: "text",
            placeholder: "Title"
          }}
          readOnly={readOnly}
          value={row.title || ""}
          onChange={onChange} />
      )
    } else if (type === "drop_withdrawn_reason_list") {
      if (isTitleOther) {
        return (
          <PrimeField
            schema={{
              type: "text",
              placeholder: "Title"
            }}
            readOnly={readOnly}
            value={row.title || ""}
            onChange={onChange} />
        )
      } else {
        return (
          <PrimeField
            schema={{
              placeholder: "Select Reason",
              type: "dropdown",
              items,
            }}
            readOnly={readOnly}
            value={row.title}
            onChange={onChange}
          />
        )
      }
    }

    return row.title
  }

  const saveComment = (value) => {
    let tempData = { ...participantData }
    participantRegistries.forEach(registry => {
      let foundPeriodIndex = tempData[registry].findIndex(item => item?.id === period.id);
      if (foundPeriodIndex !== -1) {
        let foundMilestoneIndex = tempData[registry][foundPeriodIndex][type].findIndex(item => item?.id === row.id);
        if (foundMilestoneIndex !== -1) {
          tempData[registry][foundPeriodIndex][type][foundMilestoneIndex].comment = value;
        }
      }
    })
    setParticipantData(tempData);
  }

  return (
    <td
      className="header-column"
      style={headerStyle}
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}>
      <div className="flex items-center" style={{ gap: 8 }}>
        {renderTitle()}
        <CellActions
          showActions={showActions}
          setShowActions={setShowActions}
          comment={row.comment}
          saveComment={saveComment}
          jpath={`pf-${period.id}-${type}-header-${row.id}`}
          type={type}
          hideComment={type === "drop_withdrawn_reason_list"}
        />
      </div>
      {row.comment && (
        <Label style={{ marginTop: 6 }}>
          {row.comment}
        </Label>
      )}
      <RowActions
        showActions={showActions}
        setShowActions={setShowActions}
        showDown={canShowDown()}
        showUp={canShowUp()}
        handleUp={handleUp}
        handleDown={handleDown}
        handleDelete={handleDelete}
        canDelete={canDelete()}
        hideComment={true}
        jpath={`ParticipantFlowGroup.pf-${period.id}-${type}-header-${row.id}`}
        deleteModalTitle="Delete row?" />
      <CellValidation
        table="participants"
        errorKey={errorKey} />
    </td>
  )
};

export default CellHeader;