import { useState, Fragment } from 'react';
import { useShallow } from 'zustand/react/shallow'

// components
import Arms from '../Arms/Arms';
import PrimeField from 'components/PrimeField/PrimeField';
import MeasureRow from '../MeasureRow/MeasureRow';
import RJTableCommentContainer from '../../../components/RJTableCommentContainer/RJTableCommentContainer';
import CellValidation from '../../../components/CellValidation/CellValidation';

// context
import useResultsTableViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableViewStore';
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';
import useAuthoringCommentsStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringCommentsStore';

const MeasureData = ({
  measure,
  outcomeData,
  setOutcomeData,
  numOfMeasures,
  openDeleteMeasureModal,
  schema,
  showUnitsAnalyzed,
  handleChange,
  measureIndex,
  disableFields
}) => {
  const {
    tableView
  } = useResultsTableViewStore()

  const {
    readOnly,
    formView
  } = useAuthoringViewStore(
    useShallow(state => ({ 
      readOnly: state.readOnly,
      formView: state.formView
    }))
  )

  // const [showAddArmDropdown, setShowAddArmDropdown] = useState(false)
  // const [disableFields, setDisableFields] = useState(false);

  const getColSpan = () => {
    if (measure?.arms?.length > 0 || measure?.subject_analysis_sets?.length > 0) {
      return 2
    }
    return 1
  }

  const renderEudractCountableField = () => {
    let omFound = outcomeData["eudract"]?.outcome_measures?.find(om => om.id === measure.id)
    let value = "Countable"

    if (omFound) {
      value = omFound["countable"]
    }

    return (
      <tr>
        <td
          className="header-column"
          colSpan={2}>
          Countable
        </td>
        <td
          className="text-left"
          colSpan={getColSpan()}>
          <PrimeField
            schema={{
              type: "dropdown",
              items: ["Countable", "Measurable"],
              disabled: !omFound
            }}
            readOnly={readOnly}
            value={value}
            onChange={e => handleChange(e, "countable")}
          />
        </td>
      </tr>
    )
  }

  const renderOutcomeMeasureData = () => {
    let omSchema = schema.properties[tableView].properties.outcome_measure.properties;
    let dataKeys = schema.properties[tableView].properties.outcome_measure.data_keys;

    return (
      <Fragment key={"outcome-measure-data-" + measure.id}>
        {dataKeys.filter(key => {
          if (showUnitsAnalyzed) return true;
          else if (key === "type_units_analyzed") return false;
          return true;
        }).map(key => {
          let disabled = false;
          let showPercentage = false
          let schemaCopy = { ...omSchema[key] };
          if (schemaCopy.conditionals) {
            let conditionalKey = Object.keys(schemaCopy.conditionals)[0];
            if (schemaCopy.conditionals[conditionalKey][measure[conditionalKey]] &&
              schemaCopy.conditionals[conditionalKey][measure[conditionalKey]] !== measure[conditionalKey]) {
              schemaCopy.enum = schemaCopy.conditionals[conditionalKey][measure[conditionalKey]].enum
            }
          }
          let tooltip = undefined

          if (key === "dispersion_type") {
            if (measure["parameter_type"] === "Count of Participants" || measure["parameter_type"] === "Count of Units") {
              disabled = true;
            }
            if (measure["dispersion_type"] === "Confidence Interval") showPercentage = true;
          }

          if ((tableView === "ctg" && disableFields === true) && (key === "unit_of_measure" || key === "dispersion_type" || key === "parameter_type")) {
            disabled = true
            tooltip = "This field is disabled because Outcome Measure has zero total participants analyzed. This is a CTG limitation only, if you'd like to update this field in Eudract, please switch to Eudract registry."
          }

          return (
            <Fragment key={`${tableView}-om-data-${key}`}>
              {key === "parameter_type" && formView.id === "global" && renderEudractCountableField()}
              <tr>
                <PropertyHeader
                  title={omSchema[key].title}
                  measure={measure}
                  fieldKey={key} />
                <td
                  className="text-left"
                  colSpan={getColSpan()}>
                  <PrimeField
                    schema={{
                      type: schemaCopy.type,
                      items: schemaCopy.enum,
                    }}
                    tooltip={tooltip}
                    disabled={disabled}
                    readOnly={readOnly}
                    value={measure[key] || ""}
                    onChange={e => handleChange(e, key)} />
                  <CellValidation
                    table="outcome"
                    errorKey={`results_outcome_measures_outcome_measures_table_${tableView}_outcome_measures_${measureIndex + 1}_${key}`}/>
                </td>
              </tr>
              {showPercentage && (
                <tr key={`${tableView}-om-data-${key}`}>
                  <td
                    className="header-column"
                    colSpan={2}>
                    {omSchema["ci_pct_pevel"].title}
                  </td>
                  <td
                    className="text-left"
                    colSpan={getColSpan()}>
                    <PrimeField
                      schema={{
                        type: "number",
                        disabled
                      }}
                      readOnly={readOnly}
                      value={measure["ci_pct_pevel"] || ""}
                      onChange={e => handleChange(e, "ci_pct_pevel")} />
                  </td>
                </tr>
              )}
            </Fragment>
          )
        })}
      </Fragment>
    )
  }

  const renderMeasureRows = () => {
    return measure.measure_rows.map((row, rowIndex) => {
      return (
        <MeasureRow
          key={`om-data-ctg-${measure.id}-${rowIndex}`}
          row={row}
          rowIndex={rowIndex}
          measure={measure}
          measureIndex={measureIndex}
          outcomeData={outcomeData}
          setOutcomeData={setOutcomeData}
          showUnitsAnalyzed={showUnitsAnalyzed} />
      )
    })
  }

  return (
    <>
      {tableView !== "eudract" && renderOutcomeMeasureData()}
      <Arms
        measure={measure}
        measureIndex={measureIndex}
        outcomeData={outcomeData}
        setOutcomeData={setOutcomeData}
        schema={schema}
        showUnitsAnalyzed={showUnitsAnalyzed} />
      {renderMeasureRows()}
    </>
  );
};

export default MeasureData;

const PropertyHeader = ({
  title,
  measure,
  fieldKey
}) => {
  const [openCommentsForm, setOpenCommentsForm] = useState(false)
  const [showActions, setShowActions] = useState(false)

  const {
    commentsMap
  } = useAuthoringCommentsStore(
    useShallow(state => ({
      commentsMap: state.commentsMap
    }))
  )

  let jpath = `OutcomeRptGroup.om-measure-data-${measure.id}-${fieldKey}`

  const commentStyle = {
    position: "absolute",
    right: 8,
    top: 8
  }

  return (
    <td
      className="header-column"
      colSpan={2}
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}
      style={{ position: "relative" }}>
      {title}
      <RJTableCommentContainer
        label={title}
        jpath={jpath}
        openCommentsForm={openCommentsForm}
        setOpenCommentsForm={setOpenCommentsForm}
        setShowMenu={setShowActions}
        showMenu={showActions || commentsMap[jpath] || openCommentsForm}
        style={commentStyle}
        modalStyle={{ top: 24 }} />
    </td>
  )
}