import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { Space, Radio } from 'antd';

const RJCheckboxGroup = (props) => {
  let { schema } = props;
  const [isDisabled, setDisabled] = useState(false);
  const options = props.uiSchema["ui:options"]
  const title = options.title;

  useEffect(() => {
    let { value, schema, uiSchema } = props;

    if (uiSchema) {
      if (uiSchema["ui:readonly"] || uiSchema["ui:disabled"]) {
        setDisabled(true)
      }
    }
  }, [props.value]);

  return (
    <Container>
      <Radio.Group
        disabled={isDisabled}
        onChange={props.onChange}
        value={props.value}>
        <Space direction={"vertical"}>
          {schema.enum &&
            schema.enum.map((item, i) => {
              return (
                <Radio
                  id={props.id + "-" + i}
                  key={props.id + "-" + i}
                  value={item}>
                  {item}
                </Radio>
              );
            })}

        </Space>
      </Radio.Group>
      {/* <FormGroup legendText={title}>
        <RadioButtonGroup
          name={props.id}
          orientation={"vertical"}
          onChange={(e) => props.onChange(e)}
        >
          {schema.enum &&
            schema.enum.map((item, i) => {
              return (
                <RadioButton
                  key={props.id + "-" + i}
                  id={props.id + "-" + i}
                  disabled={isDisabled}
                  value={item}
                  labelText={item}
                />
              );
            })}
        </RadioButtonGroup>
      </FormGroup> */}
    </Container>
  );
};

export default RJCheckboxGroup;

const Container = styled.div`
  
`

const TooltipContainer = styled.div`

`