import React, { useState, useEffect, useRef } from 'react';
import { useStateWithCallbackLazy } from 'use-state-with-callback';

// components
import Grid from 'components/Grid/Grid';
import EmptyDocumentRow from '../EmptyDocumentRow/EmptyDocumentRow';

// assets
import rowGroupLine from 'assets/images/row-group-line.svg'

// styles
import './document-checklist.scss';

// cell renderers

import TitleSubtitle from 'components/ag-grid-cell-renderers/TitleSubtitle/TitleSubtitle';
import LanguageToFlagRenderer from 'components/ag-grid-cell-renderers/LanguageToFlag/LanguageToFlag';
import DocumentIconRenderer from 'components/ag-grid-cell-renderers/DocumentIconCellRenderer/DocumentIconCellRenderer';
import DocumentNameTypeRenderer from 'components/ag-grid-cell-renderers/DocumentNameType/DocumentNameType';
import DateRenderer from 'components/ag-grid-cell-renderers/Date/Date';
import LinkRenderer from 'components/ag-grid-cell-renderers/Link/Link';
import DocumentActionsRenderer from 'components/ag-grid-cell-renderers/DocumentActions/DocumentActions';
import Registries from 'components/ag-grid-cell-renderers/Registries/Registries';
import Languages from 'components/ag-grid-cell-renderers/Languages/Languages';
import Countries from 'components/ag-grid-cell-renderers/Countries/Countries';
import LastModifiedBy from 'components/ag-grid-cell-renderers/LastModifiedBy/LastModifiedBy';
// import DocumentAnnotationsLink from 'components/ag-grid-cell-renderers/DocumentAnnotationsLink';

// components
import Menu from 'components/Menu/Menu';

// apis
import { useUserPermissions } from 'api/hooks';
import { useGetStudyChecklistDetails } from 'api/hooks/studies/useChecklistsApi';

// context
import useChecklists from 'context/hooks/useChecklists';
import useDocuments from 'context/hooks/useDocuments';
import useStudy from 'context/hooks/useStudy';
import useAuthoringStore from '../../../Authoring/hooks/stores/useAuthoringStore';

// utilities
import useAgGridResize from 'utilities/useAgGridResize';

// styles
import './document-checklist.scss';

const DocumentChecklist = ({
  checklist,
  onDropDocument,
  openRowPanel,
  handleChecklistFileLoad = {},
}) => {
  const { data: permissions } = useUserPermissions()
  const authoringId = useAuthoringStore(state => state.authoringId)
  const {
    onOpenPanel
  } = useStudy()
  const {
    openPdf,
    documents,
  } = useDocuments()

  const [rowData, setRowData] = useState([])
  const [expand, setExpand] = useStateWithCallbackLazy(true)
  const [gridApi, setGridApi] = useState(null);
  const [search, setSearch] = useState("");
  const gridRef = useRef();
  const [showMenu, setShowMenu] = useState(false)
  const gridContainer = useRef(null);
  const { data: studyChecklistDetails = checklist } = useGetStudyChecklistDetails(authoringId, checklist?.id && checklist?.id !== -1 ? checklist.id : null)
  const {
    studyChecklists,
    // getStudyChecklistDetails,
    setStudyChecklistDocument,
    setSelectedChecklist
  } = useChecklists()

  const gridBreakpoints = [
    {
      maxWidth: 1120,
      hide: [],
      show: ['Countries', 'Registries', 'Last modified by']
    },
    {
      maxWidth: 950,
      hide: ['Last modified by'],
      show: ['Registries', 'Countries']
    },
    {
      maxWidth: 800,
      hide: ['Countries'],
      show: ['Registries', 'Countries',]
    },
    {
      maxWidth: 700,
      hide: ['Countries'],
      show: ['Registries', 'Last modified by']
    },
    {
      maxWidth: 600,
      hide: ['Countries', 'Registries', 'Last modified by'],
      show: []
    },
  ]

  useAgGridResize(gridContainer, gridApi, gridBreakpoints);

  useEffect(() => {
    if (studyChecklistDetails) {
      let rowData = studyChecklistDetails?.options?.filter(option => option.document_name);
      setRowData(rowData);
      getChecklistStatus()
    }
  }, [studyChecklistDetails, documents])

  useEffect(() => {
    setExpand(false, () => {
      setExpand(true);
    })
  }, [documents])

  const gridOptions = {
    columnDefs: [
      {
        headerName: "",
        field: "",
        cellRenderer: "DocumentIconRenderer",
        width: 50,
        suppressSizeToFit: true,
        cellRendererParams: {
          openPdf,
          documents,
        },
      },
      {
        headerName: '',
        field: '',
        minWidth: 300,
        cellRenderer: "TitleSubtitle",
        cellRendererParams: {
          title: "document_name",
          subtitle: "document_type_name",
        }
      },
      {
        headerName: 'Last modified',
        field: 'last_modified',
        cellRenderer: "LastModifiedBy",
        width: 200,
      },
      {
        headerName: '',
        field: '',
        cellRenderer: "DocumentActionsRenderer",
        cellRendererParams: {
          source: "checklist",
          documents,
          checklist,
          checklistDetails: studyChecklistDetails,
          setStudyChecklistDocument
        },
        suppressSizeToFit: true,
        width: 100
      },
    ],
    defaultColDef: {
      resizable: true,
      sortable: true,
      wrapText: true,
      filter: true,
      enablePivot: true,
      enableCharts: false,
      enableRowGroup: false,
      filterParams: { clearButton: true },
      cellStyle: { "whiteSpace": "normal !important" },
      autoHeight: true,
      cellClassRules: {
        oddBackground: function (params) {
          return params.rowIndex % 2 == 0
        },
      },
    },
    frameworkComponents: {
      TitleSubtitle,
      DocumentIconRenderer,
      DocumentNameTypeRenderer,
      LanguageToFlagRenderer,
      DateRenderer,
      LinkRenderer,
      DocumentActionsRenderer,
      Registries,
      Countries,
      Languages,
      LastModifiedBy
      // DocumentAnnotationsLink
    },
    getRowHeight: () => 62,
    suppressHorizontalScroll: true
  }

  const onGridReady = (params) => {
    setGridApi(params.api)
    params.api.sizeColumnsToFit()
  }

  const getChecklistStatus = () => {
    let status = "";
    let isStarted = studyChecklistDetails?.options?.some(option => option.document_name);
    if (isStarted) status = "started";
    let isIncomplete = studyChecklistDetails?.options?.some(option => !option.document_name || !option.document_type_name);
    if (studyChecklistDetails?.is_complete && !isIncomplete) status = "complete";

    if (studyChecklistDetails?.id === -1 && studyChecklistDetails?.name === "Documents without Checklists") {
      return <span style={{ width: 13 }} />
    }

    if (status === "complete") {
      return (
        <i
          className="fas fa-check-circle"
          style={{
            color: "#9ECE6B",
            margin: "0px 13px 0px 11px"
          }} />
      )
    } else if (status === "started") {
      return (
        <i
          className="fad fa-minus-circle"
          style={{
            color: "rgba(158, 206, 107, 0.75)",
            backgroundColor: "#fff",
            margin: "0px 13px 0px 11px"
          }} />
      )
    }

    return (
      <i
        className="far fa-circle"
        style={{
          color: "#9ECE6B",
          margin: "0px 13px 0px 11px"
        }}
      />
    )
  }

  const getDocumentCount = () => {
    return studyChecklistDetails?.options?.reduce((acc, item) => item.document_name ? acc + 1 : acc, 0);
  }

  const getDocumentStatus = (i) => {
    let numCompleted = getDocumentCount();
    let isCompleted = i < numCompleted;

    if (studyChecklistDetails?.id === -1 && studyChecklistDetails?.name === "Documents without Checklists") {
      return <span style={{ width: 13 }} />
    }

    return (
      <i
        className={isCompleted ? "fas fa-check-circle" : "far fa-circle"}
        style={{
          color: "#9ECE6B",
          margin: "13px 0px 0px 14px"
        }}
      />
    )
  }

  const renderEmptyRows = () => {
    let ret = []
    if (studyChecklistDetails?.options) {
      ret = studyChecklistDetails?.options?.filter(option => {
        if (option?.document_name) return false;
        return true;
      }).map((option, i) => {
        return (
          <div
            key={`${checklist?.id}-empty-row-${i}`}
            className="empy-row"
            style={{ marginBottom: 5 }}
          >
            <EmptyDocumentRow
              checklist={studyChecklistDetails}
              option={option}
              permissions={permissions}
            />
          </div>
        )
      })
    }
    return ret;
  }

  let height = (studyChecklistDetails?.options?.length * 65) + 10;

  return (
    <div className="document-checklist-container">
      <div className="checklist-header-container">
        <div className="left-header-container">
          <div className="expand-icon-container">
            <i
              className={`fal fa-angle-${expand ? "up" : "down"} expand-icon`}
              onClick={() => setExpand(!expand)} />
          </div>
          {getChecklistStatus()}
          <div className="title">{studyChecklistDetails?.name}</div>
          <div className="subtitle">{studyChecklistDetails?.id !== -1 && `(${getDocumentCount()} of ${studyChecklistDetails?.options?.length || 0} documents completed)`}</div>
        </div>
        <div>
          {permissions?.["study.checklist.edit"] && studyChecklistDetails?.id !== -1 && (
            <i
              className="fal fa-info-circle"
              style={{
                cursor: "pointer",
                fontSize: 12,
                color: "#878C92"
              }}
              onClick={() => {
                setSelectedChecklist(studyChecklistDetails)
                onOpenPanel("edit-checklist")
              }} />
          )}
        </div>
      </div>
      <div
        ref={gridContainer}
        className="study-checklist-grid-container"
        style={{
          height: expand ? height : "100%",
          overflow: "hidden"
        }}>
        {expand &&
          <div className="checklist-grid-content">
            <div className="row-status-container">
              {studyChecklistDetails?.id !== -1 && studyChecklistDetails?.name !== "Documents without Checklists" && studyChecklistDetails?.options?.map((option, i) => {
                return (
                  <div
                    key={`grid-row-${i}`}
                    className="row-status"
                    style={{
                      minWidth: 14,
                      position: "absolute",
                      top: 20 + (i * 62),
                      left: 4
                    }}>
                    <img
                      className="row-group-line"
                      src={rowGroupLine}
                      alt="row-grouping" />
                    {getDocumentStatus(i)}
                  </div>
                )
              })}
            </div>
            <div className="grid">
              {getDocumentCount() > 0 && <div style={{ height: (rowData?.length * 64) }}>
                <Grid
                  ref={gridRef}
                  onGridReady={onGridReady}
                  gridOptions={gridOptions}
                  rowData={rowData}
                  gridApi={gridApi}
                  search={search}
                // containerStyle={{ height: documents.length * 65 }}
                // onRowDragMove={onRowDragMove}
                />
              </div>
              }
              <div className="empty-rows-container">
                {renderEmptyRows()}
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default DocumentChecklist;