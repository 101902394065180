// import { widgetContainer } from '../../uiSchemas/styles';
import {
  ctisRouteOfAdministration,
  ctisUnitsOfMeasure,
  excludedMSC,
  countries,
  ctisLanguages,
  nonEEACountries,
  ctisTherapeuticAreaOptions,
  pharmaceuticalForms,
  ctisMemberStates,
  ctisDuties,
  ageRangeSecondaryIdentifierCtis,
  ageRangeCtis,
  clinitalTrialGroupsCtis,
  trialPhaseCtis,
  trialScopeCtis
} from '../enum/enum'

const authorizedProductProperties = {
  type: "object",
  properties: {
    medicinal_product_details: {
      type: "object",
      properties: {
        is_specific_paediatric_formulation: {
          type: "string",
          enum: ["Yes", "No"]
        },
        sponsors_product_code: {
          type: "string"
        }
      }
    },
    product_characteristics: {
      type: "object",
      properties: {
        medicinal_product_characteristics: {
          type: "string",
          enum: [
            "Narcotic",
            "Psychotropic",
            "Radiopharmaceutical",
            "Immunilogical",
            "Plasma derived",
            "Extractive",
            "Recombinant",
            "Containing GMS(s)",
            "Herbal",
            "Homeopathic",
            "Other",
          ]
        },
        other_medical_product: {
          type: 'string'
        }
      }
    },
    dosage_and_administration_details: {
      type: "object",
      properties: {
        route_of_administration: {
          type: "string",
          enum: ctisRouteOfAdministration
        },
        maximum_duration_of_treatmeant: {
          type: "object",
          properties: {
            value: {
              type: "string"
            },
            duration: {
              type: "string",
              enum: ["Day(s)", "Week(s)", "Month(s)"]
            },
          }
        },
        maximum_daily_dose_allowed: {
          type: "string"
        },
        daily_dose_unit_of_measure: {
          type: "string",
          enum: ctisUnitsOfMeasure
        },
        maximum_total_dose_allowed: {
          type: "string"
        },
        total_dose_use_of_measure: {
          type: "string",
          enum: ctisUnitsOfMeasure
        }
      }
    },
    information_about_modification_of_medicinal_product: {
      type: "object",
      properties: {
        is_product_modified_in_relation_to_marketing_authorisation: {
          type: "string",
          enum: ["Yes", "No"]
        }
      },
      dependencies: {
        is_product_modified_in_relation_to_marketing_authorisation: {
          oneOf: [
            {
              properties: {
                is_product_modified_in_relation_to_marketing_authorisation: {
                  enum: ["Yes"]
                },
                description_of_modification: {
                  type: "string"
                },
              }
            }
          ]
        }
      }
    },
    orphan_designation: {
      type: "object",
      properties: {
        product_have_orphan_drug_description: {
          type: "string",
          enum: ["Yes", "No"]
        }
      },
      dependencies: {
        product_have_orphan_drug_description: {
          oneOf: [
            {
              properties: {
                product_have_orphan_drug_description: {
                  enum: ["Yes"]
                },
                designation_number_for_orphan_drug: {
                  type: "string"
                },
              }
            }
          ]
        }
      }
    },
    active_substance: {
      type: "object",
      properties: {
        active_substance_other_descriptive_name: {
          type: "string"
        }
      }
    },
    advanced_therapy_medicinal_product: {
      type: "object",
      properties: {
        advanced_therapy: {
          type: "array",
          items: {
            type: "object",
            properties: {
              advanced_therapy_classification: {
                type: "string",
                enum: [
                  "Somatic Cell Therapy",
                  "Tissue Engineered Therapy",
                  "Gene Therapy"
                ]
              },
              cat_reference_number: {
                type: "string"
              }
            },
            dependencies: {
              advanced_therapy_classification: {
                oneOf: [
                  {
                    properties: {
                      advanced_therapy_classification: {
                        enum: ["Somatic Cell Therapy"]
                      },
                      somatic_cell_origin: {
                        type: "string",
                        enum: ["Autologous", "Allogenic", "Xenogeneic"]
                      },
                      somatic_cell_type: {
                        type: "string",
                        enum: ["Stem", "Differentiated", "Other"]
                      },
                    },
                    dependencies: {
                      somatic_cell_origin: {
                        oneOf: [
                          {
                            properties: {
                              somatic_cell_origin: {
                                enum: ["Xenogeneic"]
                              },
                              species_origin_xenogeneic_cell: {
                                type: "string"
                              }
                            }
                          }
                        ]
                      },
                      somatic_cell_type: {
                        oneOf: [
                          {
                            properties: {
                              somatic_cell_type: {
                                enum: ["Differentiated"]
                              },
                              specify_type_differentiated_cells: {
                                type: "string"
                              }
                            }
                          },
                          {
                            properties: {
                              somatic_cell_type: {
                                enum: ["Other"]
                              },
                              specity_other_somatic_cell_type: {
                                type: "string"
                              }
                            }
                          },

                        ]
                      }
                    }
                  },
                  {
                    properties: {
                      advanced_therapy_classification: {
                        enum: ["Tissue Engineered Therapy"]
                      },
                      tissue_engineered_cell_type: {
                        type: "string",
                        enum: ["Stem", "Differentiated", "Other"]
                      },
                      origin_of_engineered_tissue: {
                        type: "string",
                        enum: ["Autologous", "Allogenic", "Xenogeneic"]
                      },
                    },
                    dependencies: {
                      tissue_engineered_cell_type: {
                        oneOf: [
                          {
                            properties: {
                              tissue_engineered_cell_type: {
                                enum: ["Differentiated"]
                              },
                              cell_specification: {
                                type: "string"
                              }
                            }
                          },
                          {
                            properties: {
                              tissue_engineered_cell_type: {
                                enum: ["Other"]
                              },
                              cell_specification: {
                                type: "string"
                              }
                            }
                          },

                        ]
                      },
                      origin_of_engineered_tissue: {
                        oneOf: [
                          {
                            properties: {
                              origin_of_engineered_tissue: {
                                enum: ["Xenogeneic"]
                              },
                              tissue_engineered_xenogeneic_species_origin: {
                                type: "string"
                              }
                            }
                          }
                        ]
                      },
                    }
                  },
                  {
                    properties: {
                      advanced_therapy_classification: {
                        enum: ["Gene Therapy"]
                      },
                      gene_interest: {
                        type: "string"
                      },
                      gene_therapy_type: {
                        type: "string",
                        enum: ["In vivo", "Ex vivo"]
                      },
                      type_of_gene_transfer_product: {
                        type: "string",
                        enum: ["Naked Nucleic Acid", "Complex Nucleic Acid", "Viral Vector", "Other"]
                      },
                      genetically_modified_cell_present: {
                        type: "string",
                        enum: ["", "Yes", "No"]
                      }
                    },
                    dependencies: {
                      type_of_gene_transfer_product: {
                        oneOf: [
                          {
                            properties: {
                              type_of_gene_transfer_product: {
                                enum: ["Viral Vector"]
                              },
                              additional_description: {
                                type: "string"
                              }
                            },
                          },
                          {
                            properties: {
                              type_of_gene_transfer_product: {
                                enum: ["Other"]
                              },
                              additional_description: {
                                type: "string"
                              }
                            },
                          },
                        ]
                      },
                      genetically_modified_cell_present: {
                        oneOf: [
                          {
                            properties: {
                              genetically_modified_cell_present: {
                                enum: ["Yes"]
                              },
                              specify_type_of_cells: {
                                type: "string"
                              },
                              origin_of_genetically_modiefied_cells: {
                                type: "string",
                                enum: ["Autologous", "Allogenic", "Xenogeneic"]
                              }
                            },
                            dependencies: {
                              origin_of_genetically_modiefied_cells: {
                                oneOf: [
                                  {
                                    properties: {
                                      species_origin_xenogeneic_cell: {
                                        type: "string"
                                      }
                                    }
                                  }
                                ]
                              }
                            }
                          }
                        ]
                      }
                    }
                  }
                ]
              }
            }
          }
        }
      }
    },
    device_associated_with_medicinal_product: {
      type: "object",
      properties: {
        device: {
          type: "array",
          items: {
            type: "object",
            properties: {
              product_used_in_combination_with_device: {
                type: "string",
                enum: ["Combinec integral", "Combined not integral"]
              },
              device_type: {
                type: "string",
                enum: ["Administration device", "Integrated device", "Medical device"]
              },
              device_has_ce_mark: {
                type: "array",
                uniqueItems: true,
                items: {
                  type: "string",
                  enum: [true, false],
                }
              },
              device_trade_name: {
                type: "string"
              },
              device_notified_body: {
                type: "string"
              },
              description_of_device: {
                type: "string"
              }
            }
          }
        }
      }
    },
  },
}

export const ctisProperties = {
  study_identification: {
    // deferral_clinical_trial_information: {
    //   type: "object",
    //   properties: {
    //     short_title: {
    //       type: "string",
    //       enum: ["Category 1", "Category 2", "Category 3"]
    //     },
    //     justification_for_trial_category: {
    //       type: "string"
    //     },
    //   },
    //   dependencies: {
    //     short_title: {
    //       oneOf: [
    //         {
    //           properties: {
    //             short_title: {
    //               enum: ["Category 1"]
    //             },
    //             main_characteristics: {
    //               type: "string",
    //               enum: ["Date of decision", "Publication of final summary results"]
    //             },
    //             notifications: {
    //               type: "string",
    //               enum: ["Date of decision", "Publication of final summary results"]
    //             },
    //             subject_information_sheet: {
    //               type: "string",
    //               enum: ["Date of decision", "Manual date"]
    //             },
    //             protocol_summary_scientific_advice: {
    //               type: "string",
    //               enum: ["Date of decision", "Manual date"]
    //             },
    //             impd_sande_sections_investigator_brochure: {
    //               type: "string",
    //               enum: ["Date of decision", "Manual date"]
    //             },
    //             responses_rfi: {
    //               type: "string",
    //               enum: ["Date of decision", "Manual date"]
    //             },
    //             clinical_trial_results_summary_intermediate_data_analysis: {
    //               type: "string",
    //               enum: ["12 months after interim data analysis date", "As soon as results are submitted", "Manual number of months"]
    //             },
    //             clinical_trial_results_summmray_lay_person_summary: {
    //               type: "string",
    //               enum: ["12 months after interim data analysis date", "As soon as results are submitted", "Manual number of months"]
    //             }
    //           },
    //           dependencies: {
    //             subject_information_sheet: {
    //               oneOf: [
    //                 {
    //                   properties: {
    //                     subject_information_sheet: {
    //                       enum: ["Manual date"]
    //                     },
    //                     subject_information_sheet_years: {
    //                       type: "string"
    //                     },
    //                     subject_information_sheet_months: {
    //                       type: "string"
    //                     }
    //                   }
    //                 }
    //               ]
    //             },
    //             protocol_summary_scientific_advice: {
    //               oneOf: [
    //                 {
    //                   properties: {
    //                     protocol_summary_scientific_advice: {
    //                       enum: ["Manual date"]
    //                     },
    //                     protocol_summary_scientific_advice_years: {
    //                       type: "string"
    //                     },
    //                     protocol_summary_scientific_advice_months: {
    //                       type: "string"
    //                     }
    //                   }
    //                 }
    //               ]
    //             },
    //             impd_sande_sections_investigator_brochure: {
    //               oneOf: [
    //                 {
    //                   properties: {
    //                     impd_sande_sections_investigator_brochure: {
    //                       enum: ["Manual date"]
    //                     },
    //                     impd_sande_sections_investigator_brochure_years: {
    //                       type: "string"
    //                     },
    //                     impd_sande_sections_investigator_brochure_months: {
    //                       type: "string"
    //                     }
    //                   }
    //                 }
    //               ]
    //             },
    //             responses_rfi: {
    //               oneOf: [
    //                 {
    //                   properties: {
    //                     responses_rfi: {
    //                       enum: ["Manual date"]
    //                     },
    //                     responses_rfi_years: {
    //                       type: "string"
    //                     },
    //                     responses_rfi_months: {
    //                       type: "string"
    //                     }
    //                   }
    //                 }
    //               ]
    //             },
    //             clinical_trial_results_summary_intermediate_data_analysis: {
    //               oneOf: [
    //                 {
    //                   properties: {
    //                     clinical_trial_results_summary_intermediate_data_analysis: {
    //                       enum: ["Manual number of months"]
    //                     },
    //                     clinical_trial_results_summary_intermediate_data_analysis_months: {
    //                       type: "string"
    //                     }
    //                   }
    //                 }
    //               ]
    //             },
    //             clinical_trial_results_summmray_lay_person_summary: {
    //               oneOf: [
    //                 {
    //                   properties: {
    //                     clinical_trial_results_summmray_lay_person_summary: {
    //                       enum: ["Manual number of months"]
    //                     },
    //                     clinical_trial_results_summmray_lay_person_summary_months: {
    //                       type: "string"
    //                     }
    //                   }
    //                 }
    //               ]
    //             },
    //           }
    //         },
    //         {
    //           properties: {
    //             short_title: {
    //               enum: ["Category 2"]
    //             },
    //             subject_information_sheet: {
    //               type: "string",
    //               enum: ["Date of decision", "Manual date"]
    //             },
    //             protocol_summary_scientific_advice: {
    //               type: "string",
    //               enum: ["Date of decision", "Manual date"]
    //             },
    //             impd_sande_sections_investigator_brochure: {
    //               type: "string",
    //               enum: ["Date of decision", "Manual date"]
    //             },
    //             responses_rfi: {
    //               type: "string",
    //               enum: ["Date of decision", "Manual date"]
    //             },
    //           },
    //           dependencies: {
    //             subject_information_sheet: {
    //               oneOf: [
    //                 {
    //                   properties: {
    //                     subject_information_sheet: {
    //                       enum: ["Manual date"]
    //                     },
    //                     subject_information_sheet_years: {
    //                       type: "string"
    //                     },
    //                     subject_information_sheet_months: {
    //                       type: "string"
    //                     }
    //                   }
    //                 }
    //               ]
    //             },
    //             protocol_summary_scientific_advice: {
    //               oneOf: [
    //                 {
    //                   properties: {
    //                     protocol_summary_scientific_advice: {
    //                       enum: ["Manual date"]
    //                     },
    //                     protocol_summary_scientific_advice_years: {
    //                       type: "string"
    //                     },
    //                     protocol_summary_scientific_advice_months: {
    //                       type: "string"
    //                     }
    //                   }
    //                 }
    //               ]
    //             },
    //             impd_sande_sections_investigator_brochure: {
    //               oneOf: [
    //                 {
    //                   properties: {
    //                     impd_sande_sections_investigator_brochure: {
    //                       enum: ["Manual date"]
    //                     },
    //                     impd_sande_sections_investigator_brochure_years: {
    //                       type: "string"
    //                     },
    //                     impd_sande_sections_investigator_brochure_months: {
    //                       type: "string"
    //                     }
    //                   }
    //                 }
    //               ]
    //             },
    //             responses_rfi: {
    //               oneOf: [
    //                 {
    //                   properties: {
    //                     responses_rfi: {
    //                       enum: ["Manual date"]
    //                     },
    //                     responses_rfi_years: {
    //                       type: "string"
    //                     },
    //                     responses_rfi_months: {
    //                       type: "string"
    //                     }
    //                   }
    //                 }
    //               ]
    //             },
    //           }
    //         },
    //         {
    //           properties: {
    //             short_title: {
    //               enum: ["Category 3"]
    //             },
    //             protocol_summary_scientific_advice: {
    //               type: "string",
    //               enum: ["Date of decision", "Publication of final summary of results"]
    //             },
    //             impd_sande_sections_investigator_brochure: {
    //               type: "string",
    //               enum: ["Date of decision", "Publication of final summary of results"]
    //             },
    //             responses_rfi: {
    //               type: "string",
    //               enum: ["Date of decision", "Publication of final summary of results"]
    //             },
    //           },
    //           dependencies: {
    //             protocol_summary_scientific_advice: {
    //               oneOf: [
    //                 {
    //                   properties: {
    //                     protocol_summary_scientific_advice: {
    //                       enum: ["Publication of final summary of results"]
    //                     },
    //                     justification_deferral_published_decision: {
    //                       type: "string"
    //                     },
    //                   }
    //                 }
    //               ]
    //             },
    //           }
    //         }
    //       ]
    //     }
    //   }
    // },
    brief_title_translation: {
      type: "array",
      items: {
        type: "object",
        onAddButtonTitle: "Add public title translation",
        properties: {
          language: {
            type: "string",
            enum: ctisLanguages
          },
          translation: {
            title: "Identifier",
            type: "string",
          }
        },
      }
    },
    official_title_translation: {
      type: "array",
      items: {
        type: "object",
        onAddButtonTitle: "Add translation",
        properties: {
          language: {
            type: "string",
            enum: ctisLanguages
          },
          translation: {
            title: "Identifier",
            type: "string",
          }
        },
      }
    },
  },
  study_timeline: {
    milestone_dates: {
      type: "object",
      properties: {
        estimated_recruitment_start_date_eea: {
          type: "object",
          properties: {
            date: {
              type: "string",
            },
          }
        },
        // estimated_end_of_trial_date_eea: {
        //   type: "object",
        //   properties: {
        //     date: {
        //       type: "string",
        //     },
        //   }
        // },
        // estimated_global_end_of_trial_date: {
        //   type: "object",
        //   properties: {
        //     date: {
        //       type: "string",
        //     },
        //   }
        // },
      }
    },
  },
  ctis_form: {
    deferral_clinical_trial_information: {
      type: "object",
      properties: {
        short_title: {
          type: "string",
          enum: ["Category 1", "Category 2", "Category 3"]
        },
        justification_for_trial_category: {
          type: "string"
        },
      },
      dependencies: {
        short_title: {
          oneOf: [
            {
              properties: {
                short_title: {
                  enum: ["Category 1"]
                },
                main_characteristics: {
                  type: "string",
                  enum: ["Date of decision", "Publication of final summary results"]
                },
                notifications: {
                  type: "string",
                  enum: ["Date of decision", "Publication of final summary results"]
                },
                subject_information_sheet: {
                  type: "string",
                  enum: ["Date of decision", "Manual date"]
                },
                protocol_summary_scientific_advice: {
                  type: "string",
                  enum: ["Date of decision", "Manual date"]
                },
                impd_sande_sections_investigator_brochure: {
                  type: "string",
                  enum: ["Date of decision", "Manual date"]
                },
                responses_rfi: {
                  type: "string",
                  enum: ["Date of decision", "Manual date"]
                },
                clinical_trial_results_summary_intermediate_data_analysis: {
                  type: "string",
                  enum: ["12 months after interim data analysis date", "As soon as results are submitted", "Manual number of months"]
                },
                clinical_trial_results_summmray_lay_person_summary: {
                  type: "string",
                  enum: ["12 months after interim data analysis date", "As soon as results are submitted", "Manual number of months"]
                }
              },
              dependencies: {
                subject_information_sheet: {
                  oneOf: [
                    {
                      properties: {
                        subject_information_sheet: {
                          enum: ["Manual date"]
                        },
                        subject_information_sheet_years: {
                          type: "string"
                        },
                        subject_information_sheet_months: {
                          type: "string"
                        }
                      }
                    }
                  ]
                },
                protocol_summary_scientific_advice: {
                  oneOf: [
                    {
                      properties: {
                        protocol_summary_scientific_advice: {
                          enum: ["Manual date"]
                        },
                        protocol_summary_scientific_advice_years: {
                          type: "string"
                        },
                        protocol_summary_scientific_advice_months: {
                          type: "string"
                        }
                      }
                    }
                  ]
                },
                impd_sande_sections_investigator_brochure: {
                  oneOf: [
                    {
                      properties: {
                        impd_sande_sections_investigator_brochure: {
                          enum: ["Manual date"]
                        },
                        impd_sande_sections_investigator_brochure_years: {
                          type: "string"
                        },
                        impd_sande_sections_investigator_brochure_months: {
                          type: "string"
                        }
                      }
                    }
                  ]
                },
                responses_rfi: {
                  oneOf: [
                    {
                      properties: {
                        responses_rfi: {
                          enum: ["Manual date"]
                        },
                        responses_rfi_years: {
                          type: "string"
                        },
                        responses_rfi_months: {
                          type: "string"
                        }
                      }
                    }
                  ]
                },
                clinical_trial_results_summary_intermediate_data_analysis: {
                  oneOf: [
                    {
                      properties: {
                        clinical_trial_results_summary_intermediate_data_analysis: {
                          enum: ["Manual number of months"]
                        },
                        clinical_trial_results_summary_intermediate_data_analysis_months: {
                          type: "string"
                        }
                      }
                    }
                  ]
                },
                clinical_trial_results_summmray_lay_person_summary: {
                  oneOf: [
                    {
                      properties: {
                        clinical_trial_results_summmray_lay_person_summary: {
                          enum: ["Manual number of months"]
                        },
                        clinical_trial_results_summmray_lay_person_summary_months: {
                          type: "string"
                        }
                      }
                    }
                  ]
                },
              }
            },
            {
              properties: {
                short_title: {
                  enum: ["Category 2"]
                },
                subject_information_sheet: {
                  type: "string",
                  enum: ["Date of decision", "Manual date"]
                },
                protocol_summary_scientific_advice: {
                  type: "string",
                  enum: ["Date of decision", "Manual date"]
                },
                impd_sande_sections_investigator_brochure: {
                  type: "string",
                  enum: ["Date of decision", "Manual date"]
                },
                responses_rfi: {
                  type: "string",
                  enum: ["Date of decision", "Manual date"]
                },
              },
              dependencies: {
                subject_information_sheet: {
                  oneOf: [
                    {
                      properties: {
                        subject_information_sheet: {
                          enum: ["Manual date"]
                        },
                        subject_information_sheet_years: {
                          type: "string"
                        },
                        subject_information_sheet_months: {
                          type: "string"
                        }
                      }
                    }
                  ]
                },
                protocol_summary_scientific_advice: {
                  oneOf: [
                    {
                      properties: {
                        protocol_summary_scientific_advice: {
                          enum: ["Manual date"]
                        },
                        protocol_summary_scientific_advice_years: {
                          type: "string"
                        },
                        protocol_summary_scientific_advice_months: {
                          type: "string"
                        }
                      }
                    }
                  ]
                },
                impd_sande_sections_investigator_brochure: {
                  oneOf: [
                    {
                      properties: {
                        impd_sande_sections_investigator_brochure: {
                          enum: ["Manual date"]
                        },
                        impd_sande_sections_investigator_brochure_years: {
                          type: "string"
                        },
                        impd_sande_sections_investigator_brochure_months: {
                          type: "string"
                        }
                      }
                    }
                  ]
                },
                responses_rfi: {
                  oneOf: [
                    {
                      properties: {
                        responses_rfi: {
                          enum: ["Manual date"]
                        },
                        responses_rfi_years: {
                          type: "string"
                        },
                        responses_rfi_months: {
                          type: "string"
                        }
                      }
                    }
                  ]
                },
              }
            },
            {
              properties: {
                short_title: {
                  enum: ["Category 3"]
                },
                protocol_summary_scientific_advice: {
                  type: "string",
                  enum: ["Date of decision", "Publication of final summary of results"]
                },
                impd_sande_sections_investigator_brochure: {
                  type: "string",
                  enum: ["Date of decision", "Publication of final summary of results"]
                },
                responses_rfi: {
                  type: "string",
                  enum: ["Date of decision", "Publication of final summary of results"]
                },
              },
              dependencies: {
                protocol_summary_scientific_advice: {
                  oneOf: [
                    {
                      properties: {
                        protocol_summary_scientific_advice: {
                          enum: ["Publication of final summary of results"]
                        },
                        justification_deferral_published_decision: {
                          type: "string"
                        },
                      }
                    }
                  ]
                },
              }
            }
          ]
        }
      }
    },
  },
  member_states_concerned: {
    type: "object",
    title: "Member States Concerned",
    properties: {
      member_states_concerned_table: {
        type: "string",
        enum: ctisMemberStates
      }
    }
  },
  study_description: {
    trial_scope: {
      type: "array",
      uniqueItems: true,
      items: {
        type: "string",
        enum: trialScopeCtis
      }
    },
    ctis_trial_phase: {
      type: "string",
      enum: trialPhaseCtis,
    },
    brief_summary: {
      type: "string",
      // maxLength: 5000
    },
    brief_summary_translations: {
      type: "array",
      items: {
        type: "object",
        onAddButtonTitle: "Add translation",
        properties: {
          language: {
            type: "string",
            enum: ctisLanguages
          },
          translation: {
            title: "Identifier",
            type: "string",
          }
        },
      }
    },
    secondary_objective: {
      type: "string",
      // maxLength: 5000
    },
    secondary_objective_translations: {
      type: "array",
      items: {
        type: "object",
        onAddButtonTitle: "Add translation",
        properties: {
          language: {
            type: "string",
            enum: ctisLanguages
          },
          translation: {
            title: "Identifier",
            type: "string",
          }
        },
      }
    },
    low_intervention_trial: {
      type: "array",
      uniqueItems: true,
      tooltip: "By checking this field: I confirm that: (a) the investigational medicinal products, excluding placebos, are authorised; (b) according to the protocol of the clinical trial, (i) the investigational medicinal products are used in accordance with the terms of the marketing authorisation; or (ii) the use of the investigational medicinal products is evidence-based; (c) the additional diagnostic or monitoring procedures do not pose more than minimal additional risk or burden to the safety of the subjects compared to normal clinical practice in any Member State concerned. The description of the criteria for a low intervention trial as set out in the Regulation Article 2 (2-B).",
      items: {
        type: "string",
        tooltip: "By checking this field: I confirm that: (a) the investigational medicinal products, excluding placebos, are authorised; (b) according to the protocol of the clinical trial, (i) the investigational medicinal products are used in accordance with the terms of the marketing authorisation; or (ii) the use of the investigational medicinal products is evidence-based; (c) the additional diagnostic or monitoring procedures do not pose more than minimal additional risk or burden to the safety of the subjects compared to normal clinical practice in any Member State concerned. The description of the criteria for a low intervention trial as set out in the Regulation Article 2 (2-B).",
        enum: [true, false],
      }
    },
    medical_conditions: {
      type: "array",
      items: {
        type: "object",
        onAddButtonTitle: "Add public title translation",
        properties: {
          condition: {
            type: "string"
          },
          translations: {
            type: "array",
            items: {
              type: "object",
              onAddButtonTitle: "Add public title translation",
              properties: {
                language: {
                  type: "string",
                  enum: ctisLanguages
                },
                translation: {
                  title: "Identifier",
                  type: "string",
                }
              },
            }
          },
          is_medical_condition_rare: {
            type: "array",
            uniqueItems: true,
            items: {
              type: "string",
              enum: [true, false],
            }
          }
        }
      }
    },
    therapeutic_area: {
      type: "array",
      uniqueItems: true,
      items: {
        type: "string",
        enum: ctisTherapeuticAreaOptions
      }
    },
    meddra: {
      type: "array",
      items: {
        type: "object",
        properties: {
          term_name: {
            type: "string",
          },
          level: {
            type: "string",
            enum: [
              "System Organ Class (SOC)",
              "High Level Group Term (HLGT)",
              "High Level Term (HLT)",
              "Preferred Term (PT)",
              "Lowest Level Term (LLT)",
            ]
          },
          classification_code: {
            type: "string",
          }
        },
      }
    },
    principle_inclusion_criteria: {
      type: "array",
      items: {
        type: "object",
        onAddButtonTitle: "Add inclusion criteria",
        properties: {
          inclusion_criteria: {
            type: "string"
          },
          translations: {
            type: "array",
            items: {
              type: "object",
              onAddButtonTitle: "Add translation",
              properties: {
                language: {
                  type: "string",
                  enum: ctisLanguages
                },
                translation: {
                  type: "string",
                }
              },
            }
          },

        },
      }
    },
    principle_exlusion_criteria: {
      type: "array",
      items: {
        type: "object",
        onAddButtonTitle: "Add exlusion criteria",
        properties: {
          exclusion_criteria: {
            type: "string"
          },
          translations: {
            type: "array",
            items: {
              type: "object",
              onAddButtonTitle: "Add translation",
              properties: {
                language: {
                  type: "string",
                  enum: ctisLanguages
                },
                translation: {
                  type: "string",
                }
              },
            }
          },
        },
      }
    },
    protocol_information: {
      type: "object",
      properties: {
        periods: {
          type: "array",
          items: {
            type: "object",
            onAddButtonTitle: "Add Period",
            properties: {
              title: {
                type: "string",
              },
              description: {
                type: "string",
              },
              allocation_method: {
                type: "string",
                enum: ["Randomised Controlled", "Non Randomised Controlled", "Not Applicable"],
              },
              binding_used: {
                type: "string",
                enum: ["Single", "Double", "None"]
              },
              roles_blinded: {
                type: "array",
                uniqueItems: true,
                items: {
                  type: "string",
                  enum: [
                    "Subject",
                    "Investigator",
                    "Monitor",
                    "Analyst",
                    "Carer",
                  ]
                }
              },
              blinding_implementation_details: {
                type: "string"
              },
              arms: {
                type: "array",
                items: {
                  type: "object",
                  onAddButtonTitle: "Add Period",
                  properties: {
                    title: {
                      type: "string"
                    },
                    description: {
                      type: "string"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    scientific_advice_and_pip: {
      type: "object",
      properties: {
        scientific_advice: {
          type: "array",
          items: {
            type: "object",
            // onAddButtonTitle: "Add competent authority",
            properties: {
              name: {
                type: "string"
              },
              id: {
                type: "string"
              },
              city: {
                type: "string"
              },
              country: {
                type: "string",
                enum: countries
              },
              address: {
                type: "string"
              },
              postcode: {
                type: "string"
              },
              phone: {
                type: "string"
              },
              email: {
                type: "string"
              }
              // organisation_type: {
              //   type: "string"
              // },
              // type: {
              //   type: "string"
              // },
              // status: {
              //   type: "string"
              // },
              // legal_representative: {
              //   type: "string"
              // },
              // scientific_contact_point: {
              //   type: "string"
              // },
              // public_contact_point: {
              //   type: "string"
              // },
              // third_parties: {
              //   type: "string"
              // },
            },
          }
        },
        paediatric_investigation_plan: {
          type: "array",
          items: {
            type: "object",
            onAddButtonTitle: "Add PIP",
            properties: {
              ema_paediatric_investigation_number: {
                type: "string",
              }
            },
          }
        },
      }
    },
    associated_clinical_trials: {
      type: "object",
      properties: {
        associated_clinical_trials: {
          type: "array",
          items: {
            type: "object",
            onAddButtonTitle: "Add translation",
            properties: {
              identifier: {
                type: "string",
                enum: ["EU CT number", "EUDRA CT number"]
              },
              full_title: {
                type: "string",
              }
            },
          }
        },
      }
    },
    sponsors: {
      type: "object",
      properties: {
        sponsors: {
          type: "array",
          items: {
            type: "object",
            onAddButtonTitle: "Add sponsor",
            properties: {
              sponsor: {
                type: "object",
                properties: {
                  organisation_name: {
                    type: "string"
                  },
                  status: {
                    type: "string"
                  },
                  address: {
                    type: "string"
                  },
                  city: {
                    type: "string"
                  },
                  zip_code: {
                    type: "string"
                  },
                  country: {
                    type: "string"
                  },
                  phone: {
                    type: "string"
                  },
                  email: {
                    type: "string"
                  }
                }
              },
              add_legal_representative: {
                type: "array",
                uniqueItems: true,
                items: {
                  type: "string",
                  enum: [true, false],
                }
              },
              add_scientific_contact_point: {
                type: "array",
                uniqueItems: true,
                items: {
                  type: "string",
                  enum: [true, false],
                }
              },
              add_public_contact_point: {
                type: "array",
                uniqueItems: true,
                items: {
                  type: "string",
                  enum: [true, false],
                }
              },
              third_party: {
                type: "array",
                items: {
                  type: "object",
                  onAddButtonTitle: "Add sponsor",
                  properties: {
                    organisation_name: {
                      type: "string"
                    },
                    country: {
                      type: "string"
                    },
                    address: {
                      type: "string"
                    },
                    city: {
                      type: "string"
                    },
                    zip_code: {
                      type: "string"
                    },
                    phone: {
                      type: "string"
                    },
                    email: {
                      type: "string"
                    },
                    duties: {
                      type: "array",
                      uniqueItems: true,
                      items: {
                        type: "string",
                        enum: ctisDuties
                      }
                    },
                    other_duties: {
                      type: "string"
                    }
                  }
                }
              }
            },
            dependencies: {
              add_legal_representative: {
                oneOf: [
                  {
                    properties: {
                      add_legal_representative: {
                        enum: [true]
                      },
                      legal_representative: {
                        type: "object",
                        properties: {
                          organisation_name: {
                            type: "string"
                          },
                          status: {
                            type: "string"
                          },
                          address: {
                            type: "string"
                          },
                          city: {
                            type: "string"
                          },
                          zip_code: {
                            type: "string"
                          },
                          country: {
                            type: "string"
                          },
                          phone: {
                            type: "string"
                          },
                          email: {
                            type: "string"
                          },
                          contact: {
                            type: "object",
                            properties: {
                              first_name: {
                                type: "string"
                              },
                              last_name: {
                                type: "string"
                              },
                              email: {
                                type: "string"
                              },
                              phone: {
                                type: "string"
                              }
                            }
                          }
                        }
                      },
                    }
                  }
                ]
              },
              add_scientific_contact_point: {
                oneOf: [
                  {
                    properties: {
                      add_scientific_contact_point: {
                        enum: [true]
                      },
                      scientific_contact_point: {
                        type: "object",
                        properties: {
                          organisation_name: {
                            type: "string"
                          },
                          functional_contact_point_name: {
                            type: "string"
                          },
                          phone: {
                            type: "string"
                          },
                          email: {
                            type: "string"
                          }
                        }
                      },
                    }
                  }
                ]
              },
              add_public_contact_point: {
                oneOf: [
                  {
                    properties: {
                      add_public_contact_point: {
                        enum: [true]
                      },
                      public_contact_point: {
                        type: "object",
                        properties: {
                          organisation_name: {
                            type: "string"
                          },
                          functional_contact_point_name: {
                            type: "string"
                          },
                          phone: {
                            type: "string"
                          },
                          email: {
                            type: "string"
                          }
                        }
                      },
                    }
                  }
                ]
              },
            }
          }
        },
        contact_point_for_union: {
          type: "object",
          properties: {
            organisation_name: {
              type: "string"
            },
            address_1: {
              type: "string"
            },
            address_2: {
              type: "string"
            },
            address_3: {
              type: "string"
            },
            address_4: {
              type: "string"
            },
            city: {
              type: "string"
            },
            zip_code: {
              type: "string"
            },
            country: {
              type: "string"
            },
            functional_contact_point_name: {
              type: "string"
            },
            contact: {
              type: "object",
              properties: {
                first_name: {
                  type: "string"
                },
                last_name: {
                  type: "string"
                },
                phone: {
                  type: "string"
                },
                email: {
                  type: "string"
                }
              }
            }
          }
        }
      }
    },
    products: {
      type: "object",
      properties: {
        products: {
          type: "array",
          items: {
            type: "object",
            properties: {
              role: {
                type: "string",
                enum: ["Test", "Comparator", "Auxiliary", "Placebo"]
              },
              products: {
                type: "array",
                items: {
                  type: "object",
                  properties: {
                    product: {
                      type: "string",
                      enum: ["Authorised Product", "Authorised ATC", "Authorised Substance", "Unathorised Product"]
                    }
                  },
                  dependencies: {
                    product: {
                      oneOf: [
                        {
                          properties: {
                            product: {
                              enum: ["Authorised Product"]
                            },
                            eu_mp_number: {
                              type: "string"
                            },
                            pharmaceutical_form: {
                              type: "string",
                              enum: pharmaceuticalForms
                            },
                            marketing_authorisation_number: {
                              type: "string"
                            },
                            strength: {
                              type: "string"
                            },
                            name_of_product: {
                              type: "string"
                            },
                            active_substance_name: {
                              type: "string"
                            },
                            eu_substance_number: {
                              type: "string"
                            },
                            atc_code: {
                              type: "string"
                            },
                            excluded_msc: {
                              type: "array",
                              uniqueItems: true,
                              items: {
                                type: "string",
                                enum: excludedMSC
                              }
                            },
                            product_properties: authorizedProductProperties
                          }
                        },
                        {
                          properties: {
                            product: {
                              enum: ["Authorised ATC"]
                            },
                            atc_code: {
                              type: "string"
                            },
                            atc_name: {
                              type: "string"
                            },
                            product_properties: authorizedProductProperties
                          },
                        },
                        {
                          properties: {
                            product: {
                              enum: ["Authorised Substance"]
                            },
                            name: {
                              type: "string"
                            },
                            eu_substance_number: {
                              type: "string"
                            },
                            pharmaceutical_form: {
                              type: "string",
                              enum: pharmaceuticalForms
                            },
                            strength: {
                              type: "string"
                            },
                            product_properties: authorizedProductProperties
                          }
                        },
                        {
                          properties: {
                            product: {
                              enum: ["Unathorised Product"]
                            },
                            eu_mp_number: {
                              type: "string"
                            },
                            eu_substance_number: {
                              type: "string"
                            },
                          }
                        }
                      ]
                    }
                  }
                }
              }
            },
            dependencies: {
              role: {
                oneOf: [
                  {
                    properties: {
                      role: {
                        enum: ["Test"]
                      },
                      authorisation_number_manufacturing_and_import: {
                        type: "string"
                      },
                      quality_justification_for_no_impd_upload: {
                        type: "string"
                      },
                      safety_and_efficacy_justification_for_no_impd_upload: {
                        type: "string"
                      },
                    }
                  },
                  {
                    properties: {
                      role: {
                        enum: ["Comparator"]
                      },
                      authorisation_number_manufacturing_and_import: {
                        type: "string"
                      },
                      quality_justification_for_no_impd_upload: {
                        type: "string"
                      },
                      safety_and_efficacy_justification_for_no_impd_upload: {
                        type: "string"
                      },
                    }
                  },
                  {
                    properties: {
                      role: {
                        enum: ["Auxiliary"]
                      },
                      authorisation_number_manufacturing_and_import: {
                        type: "string"
                      },
                      reason_for_no_ampd_upload: {
                        type: "string"
                      },
                    }
                  },
                  {
                    properties: {
                      role: {
                        enum: ["Placebo"]
                      },
                      description: {
                        type: "string"
                      },
                      authorisation_number_manufacturing_and_import: {
                        type: "string"
                      },
                      // linked_products: {
                      //   type: "array",
                      //   items: {
                      //     type: "object",
                      //     properties: {

                      //     }
                      //   }
                      // }
                    }
                  }
                ]
              },
            }
          }
        }
      }
    }
  },
  study_eligibilty: {
    population_under_study: {
      type: "object",
      properties: {
        age_range_ctis: {
          type: "array",
          uniqueItems: true,
          items: {
            type: "string",
            enum: ageRangeCtis
          }
        },
        age_range_secondary_ctis: {
          type: "array",
          uniqueItems: true,
          items: {
            type: "string",
            enum: ageRangeSecondaryIdentifierCtis
          }
        },
        clinical_trial_group: {
          type: "array",
          uniqueItems: true,
          items: {
            type: "string",
            enum: clinitalTrialGroupsCtis
          }
        },
        vulnerable_population: {
          type: "array",
          uniqueItems: true,
          items: {
            type: "string",
            enum: [true, false],
          }
        },
      },
      dependencies: {
        vulnerable_population: {
          oneOf: [
            {
              properties: {
                vulnerable_population: {
                  enum: [true],
                },
                recruitment_population_group: {
                  type: "array",
                  uniqueItems: true,
                  items: {
                    type: "string",
                    enum: [
                      "Women using contraceptives",
                      "Women not using contraceptives",
                      "Pregnant women",
                      "Nursing women",
                      "Subjects incapable of giving consent",
                      "Emergency situations",
                      "Other",
                    ],
                  }
                },
                subjects_incapable_giving_consent: {
                  type: "array",
                  uniqueItems: true,
                  items: {
                    type: "string",
                    enum: [
                      "Incapacitated population",
                      "Minors",
                    ],
                  }
                },
                emergency_situation_description: {
                  type: "string"
                },
                other_description: {
                  type: "string"
                }
              },
            }
          ]
        }
      }
    },
  },
  study_outcome_measures: {
    outcome_measures_ctis: {
      items: {
        translations: {
          type: "array",
          items: {
            type: "object",
            onAddButtonTitle: "Add translation",
            properties: {
              language: {
                type: "string",
                enum: ctisLanguages
              },
              translation: {
                title: "Identifier",
                type: "string",
              }
            },
          }
        }
      }
    }
  },
  study_scope: {
    countries_outside_eea: {
      type: "array",
      uniqueItems: true,
      items: {
        type: "string",
        enum: nonEEACountries
      }
    },
  },
}

export const ctisDependencies = {
  study_identification: {
  },
  study_description: {
    low_intervention_trial: {
      oneOf: [
        {
          properties: {
            low_intervention_trial: {
              enum: [true]
            },
            justification_for_low_intervention_trial: {
              type: "string"
            },
          }
        }
      ]
    }
  }
}