import { useState, useEffect } from 'react';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { useShallow } from 'zustand/react/shallow'

// components
import RJSimpleTable from './RJSimpleTable';
import CellValidation from '../components/CellValidation/CellValidation';

// context
import useProtocolTableArmsStore from '../../../hooks/stores/useProtocolTableArmsStore';
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';
import useAuthoringVersionStore from '../../../hooks/stores/useAuthoringVersionStore';
import useAuthoringStore from '../../../hooks/stores/useAuthoringStore';

const SimpleTableWrapper = (props) => {
  const {
    id,
    onChange,
    formContext
  } = props

  const {
    formDataRef,
  } = formContext

  const formWidthClassName = useAuthoringStore(state => state.formWidthClassName)

  const {
    versionData,
    selectedVersion
  } = useAuthoringVersionStore(
    useShallow(state => ({
      versionData: state.versionData,
      selectedVersion: state.selectedVersion,
    }))
  )

  const {
    formStatus,
    readOnly,
    showPagePreview,
    showVersionCompare
  } = useAuthoringViewStore(
    useShallow(state => ({ 
      readOnly: state.readOnly,
      showPagePreview: state.showPagePreview,
      showVersionCompare: state.showVersionCompare,
      formStatus: state.formStatus,
    }))
  )

  const [tableMounting, setTableMounting] = useStateWithCallbackLazy(true)
  const [tableData, setTableData] = useStateWithCallbackLazy(null)
  const {
    setArms,
    setInterventions
  } = useProtocolTableArmsStore()

  const keys = id.split("_");
  const key = keys[keys.length - 1];

  useEffect(() => {
    if (formStatus === "ready" && tableData && readOnly === false && tableMounting === false) {
      console.log("Simple Table Change: " + key)
      onChange(tableData)
    }
  }, [tableData])

  useEffect(() => {
    if (formDataRef?.protocol?.study_arms?.[key]) {
      setTableData(formDataRef.protocol.study_arms?.[key])
      if (key === "arms") {
        setArms(formDataRef.protocol.study_arms[key])
      } else if (key === "interventions") {
        setInterventions(formDataRef.protocol.study_arms[key])
      }

    } else {
      if (key === "arms") {
        setArms([])
      } else if (key === "interventions") {
        setInterventions([])
      }
      setTableData([])
    }

    return () => {
      setTableMounting(true)
    }
  }, [showPagePreview])

  useEffect(() => {
    if (tableData !== null) setTableMounting(false)
  }, [tableMounting, tableData])

  if (tableMounting) return <></>

  return (
    <div className={["form-table-print", formWidthClassName].join(" ")}>
      <RJSimpleTable
        {...props}
        tableData={tableData}
        setTableData={setTableData}
        tableType={key} />
      {versionData && selectedVersion && showVersionCompare && (
        <div className="rjs-table-comparison-wrapper">
          <RJSimpleTable
            {...props}
            tableData={versionData?.study_data?.protocol?.study_arms?.[key]}
            setTableData={() => { }}
            tableType={key} />
        </div>
      )}
      <CellValidation
        table={key}
        errorKey={`protocol_study_arms_${key}`} />
    </div>
  );
};

export default SimpleTableWrapper;