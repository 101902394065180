import React, { useState, useEffect, useMemo, useRef } from 'react'
import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import { useShallow } from 'zustand/react/shallow'

// components
import DownloadPreviewModal from '../DownloadPreviewModal/DownloadPreviewModal'
import VersionSaveModal from '../VersionSaveModal/VersionSaveModal';
import PrimeField from 'components/PrimeField/PrimeField';
import { Tooltip } from 'antd'
import { Button } from 'components-design-system'
import RegistryButtons from '../RegistryButtons/RegistryButtons'
import { default as RevertConfirmModal } from 'components/ConfirmModal';
import FormNavigator from '../FormNavigator/FormNavigator';
import ButtonSlider from 'components/ButtonSlider/ButtonSlider';
import './FormActions.scss'
// utils/helpers
import {
  getNextMinorVersNumber,
  getNextMajorVersNumber,
  formatVersNumber,
} from '../../utils';
import { getSectionRegistry } from 'utilities/study-views';

import {
  formSchemas,
  previewFormSchemas
} from 'constants/authoring';
// hooks
import { useUserPermissions } from 'api/hooks';
import {
  useGetAuthoringStudy,
  useGetAuthoringVersions,
} from 'api/hooks/studies/useAuthoringApi';
import useAuthoringPrintStore from '../../hooks/stores/useAuthoringPrintStore'
import useAuthoringPrintActions from '../../hooks/actions/useAuthoringPrintActions'
import useAuthoringViewStore from '../../hooks/stores/useAuthoringViewStore';
import useAuthoringValidationsStore from '../../hooks/stores/useAuthoringValidationsStore'
import useAuthoringValidationActions from '../../hooks/actions/useAuthoringValidationActions'
import useAuthoringVersionStore from '../../hooks/stores/useAuthoringVersionStore'
import useAuthoringStore from '../../hooks/stores/useAuthoringStore'
import useAuthoringSaveActions from '../../hooks/actions/useAuthoringSaveActions'

// context
import useAppState from 'context/hooks/useAppState';

dayjs.extend(calendar)

const FormActions = (props) => {
  const {
    handleUnlocking,
    revertToVersion,
  } = props;

  const {
    formView,
    setFormView,
    formSection,
    setFormSection,
    readOnly,
    setReadOnly,
    isReviewerView,
    showVersionCompare,
    setShowVersionCompare,
    showPagePreview,
    setShowPagePreview,
    showRegistryBadges,
    setShowRegistryBadges,
    setFormStatus
  } = useAuthoringViewStore(useShallow(state => state))

  const {
    selectedVersion,
    setSelectedVersion,
  } = useAuthoringVersionStore(
    useShallow((state) => ({
      selectedVersion: state.selectedVersion,
      setSelectedVersion: state.setSelectedVersion,
    }))
  )

  const {
    authoringId,
    lockedFullname,
    setLockedFullName,
    isUserLocked,
    showSaveLock,
    lastSaved,
  } = useAuthoringStore(
    useShallow(state => ({
      authoringId: state.authoringId,
      lockedFullname: state.lockedFullname,
      setLockedFullName: state.setLockedFullName,
      isUserLocked: state.isUserLocked,
      showSaveLock: state.showSaveLock,
      lastSaved: state.lastSaved,
    }))
  )

  const {
    handleSubmit,
  } = useAuthoringSaveActions()

  const {
    registryValidations,
    setRegistryValidations
  } = useAuthoringValidationsStore(
    useShallow(state => ({
      registryValidations: state.registryValidations,
      setRegistryValidations: state.setRegistryValidations,
    }))
  )

  const {
    onRegistryValidate,
  } = useAuthoringValidationActions(authoringId)

  const {
    onPrintPage,
  } = useAuthoringPrintActions()

  const {
    setPrintCtgOnly
  } = useAuthoringPrintStore(
    useShallow((state) => ({
      setPrintCtgOnly: state.setPrintCtgOnly
    }))
  )

  const { data: authoring } = useGetAuthoringStudy(authoringId)

  const { sidebarOpen } = useAppState()

  const [openVersionModal, setOpenVersionModal] = useState(false)
  const [saveVersType, setSaveVersType] = useState(null)

  const [scrollPosition, setScrollPosition] = useState(0)
  const [isSticky, setIsSticky] = useState(false)
  const navbarRef = useRef(null)
  const navbarOffsetTop = 180

  useEffect(() => {
    let container = document.getElementsByClassName("right-section")[0]
    container.addEventListener("scroll", (evt) => {
      setScrollPosition(container.scrollTop);
    }, { passive: true });

    return () => container.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = (evt) => {
    setScrollPosition(window.scrollY);
  }

  const { data: permissions } = useUserPermissions()
  const { data: versionResp } = useGetAuthoringVersions(authoring?.id)
  const versionList = versionResp?.versions

  useEffect(() => {
    if (versionList) {
      const options = versionList?.map((ver) => {
        const versNum = formatVersNumber(ver.version_num);
        const versDate = dayjs(ver.date_created).format("MM/DD/YY");

        return {
          id: ver.version_num,
          text: `${versNum} - Created by ${ver.created_by_fullname} on ${versDate} (${ver.notes})`,
        };
      })
      setVersDropdownOptions(options);
    }
  }, [versionList])

  const [selectedVersOption, setSelectedVersOption] = useState(null);
  const [sectDropdownOptions, setSectDropdownOptions] = useState([]);
  const [versDropdownOptions, setVersDropdownOptions] = useState([]);
  const [openRevertModal, setOpenRevertModal] = useState(false);
  const [openDownloadPreview, setOpenDownloadPreview] = useState(false);

  // set sections dropdown menu items
  useEffect(() => {
    if (formSchemas) {
      let dropdownOptions = [];
      Object.keys(formSchemas).forEach(skey => {
        let section = { id: skey, text: formSchemas[skey].title };
        dropdownOptions.push(section);
      })
      setSectDropdownOptions(dropdownOptions)
    }
  }, [authoring?.study_data?.protocol?.study_type])

  useEffect(() => {
    if (authoring?.locked_by_fullname) setLockedFullName(authoring.locked_by_fullname)
  }, [authoring?.locked_by_fullname])

  // when switch sections set registry view
  useEffect(() => {
    setFormView(getSectionRegistry(formSection, formView));
  }, [formSection]);

  const getVersionNumber = () => {
    if (selectedVersion?.version_num) {
      return (selectedVersion.version_num).toFixed(1)
    }
    return null
  }

  // slider buttons open state
  const [formActionSliderOpen, setFormActionSliderOpen] = React.useState(false)
  const [versSliderOpen, setVersSliderOpen] = React.useState(false)
  const [saveVersSliderOpen, setSaveVersSliderOpen] = React.useState(false)

  /** EVENT HANDLERS */

  const onVersionSelect = (selectedItem) => {
    if (selectedItem) {
      setShowVersionCompare(true)
      setReadOnly(true)
      let foundItem = versDropdownOptions.find(item => item.text === selectedItem)
      setSelectedVersOption(foundItem);
      const currentVers = versionList?.find((ver) => {
        return ver.version_num === foundItem.id;
      });
      setSelectedVersion(currentVers);
      //--> Authoring useEffect when selectedVersion changes
    };
    return;
  }

  // when user clicks exit version compare button
  const onVerCompareExit = () => {
    setShowVersionCompare(false);
    setSelectedVersion(null);
    setSelectedVersOption(null);
  }

  const handleVersRevert = () => {
    setFormStatus("loading")
    onVerCompareExit();
    revertToVersion();
    setVersSliderOpen(false)
    setSaveVersSliderOpen(false)
    setTimeout(() => {
      setFormStatus("ready")
    }, 500)
  }

  const handleSection = (val) => {
    if (showPagePreview === true) {
      let schema = { ...previewFormSchemas[val.id].properties[formView.id] };
      if (!schema || Object.keys(schema).length === 0) {
        let key = Object.keys(previewFormSchemas[formSection.id].properties)[0];
        let sectionSchema = previewFormSchemas[formSection.id].properties[key];
        let formView = { id: sectionSchema.title.toLowerCase(), text: sectionSchema.title }
        setFormView(formView);
      }

    }
    setFormSection(val)
  }

  const lastSavedMessage = useMemo(() => {
    if (lastSaved) return `Last saved: ${lastSaved.calendar()}`;
  }, [lastSaved]);

  const onOpenVersModal = (type) => {
    setSaveVersType(type)
    setOpenVersionModal(true)
    setOpenVersionModal(true)
    setFormActionSliderOpen(false)
  }

  const menuItems = useMemo(() => {
    return {
      save: [
        {
          iconClassName: "fal fa-arrow-circle-down",
          label: `Save as version ${getNextMinorVersNumber(authoring?.version)}`,
          onClick: () => onOpenVersModal("minor")
        },
        {
          iconClassName: "fal fa-arrow-circle-up",
          label: `Save as version ${getNextMajorVersNumber(authoring?.version)}`,
          onClick: () => onOpenVersModal("major")
        }
      ],
      download: [
        {
          iconClassName: "fal fa-file-download",
          label: "Download",
          onClick: () => setOpenDownloadPreview(true)
        },
        {
          iconClassName: "fal fa-file-invoice",
          label: "Preview",
          onClick: () => {
            if (formView.id === "global") {
              setFormView({ id: "ctg", text: "CTG" })
            }
            setShowPagePreview(true)
          }
        },
        {
          iconClassName: "fal fa-print",
          label: "Print",
          onClick: () => {
            if (formView?.id === "ctg" && showVersionCompare !== true) {
              setPrintCtgOnly(true)
            } else {
              onPrintPage()
            }
          }
        },
      ],
      validate: {
        iconClassName: "fal fa-file-check"
      }
    }
  }, [versionList, formView, authoring, showVersionCompare])

  const renderReadOnlyActions = () => {
    let message = "editing";
    if (lockedFullname === "ETL Load") message = "in progress";

    const onHandleUnlock = () => {
      handleUnlocking()
      setReadOnly(false)
    }

    return <div className="read-only-actions-container">
      {permissions["study.unlock"] && (
        <div
          onClick={onHandleUnlock}
          style={{ marginRight: 16 }}>
          <Tooltip
            title={`Unlock form`}
            placement={isSticky ? "bottom" : "top"}>
            <i
              className="fas fa-lock-alt"
              style={{
                cursor: "pointer",
                color: "#D7542C"
              }} />

          </Tooltip>
        </div>
      )}
      <div className="authoring-hover-text">
        <i
          style={{ marginRight: 4 }}
          className="fas fa-lock-alt" />
        {lockedFullname} is {message}
      </div>
    </div >
  }

  const onCloseMenu = () => {
    setShowPagePreview(false)
    onVerCompareExit()
  }

  const renderActions = () => {
    if (isUserLocked) {
      return renderReadOnlyActions()
    }

    return <div className="right-actions-container">
      {permissions?.["study.edit"] && readOnly === false && (
        <Button
          variant={"icon"}
          size="sm"
          onClick={() => {
            handleSubmit({ unlockOnSave: true })
            setReadOnly(true)
          }}
          tooltip={showSaveLock ? "Save and unlock" : "Save"}
          className={`form-action-btn${showSaveLock ? ' locked' : ''}`}
        >
          <i className={showSaveLock ? "fal fa-lock-alt" : "far fa-unlock-alt"} />
        </Button>
      )}
      {permissions?.["study.edit"] && readOnly && (
        <Button
          variant={"icon"}
          size="sm"
          onClick={() => setReadOnly(false)}
          tooltip={"Edit form"}
          className={`form-action-btn`}
          style={{
            // marginTop: 2 
          }}
        >
          <i className={"fal fa-edit"} />
        </Button>
      )}
      {formView?.id !== 'global' && showPagePreview !== true &&
        <Button
          variant={"icon"}
          size="sm"
          onClick={onRegistryValidate}
          tooltip={"Validate"}
          className={`form-action-btn`}
        >
          <i className="fal fa-file-check" />
        </Button>
      }
      <ButtonSlider
        iconClassName={"fal fa-file-download btn-slider"}
        menuItems={menuItems.download.filter((item) => {
          if (item.label === "Download" && formView.id !== "ctg") return false;
          else if (item.label === "Preview" && formView.id === "global") return false;
          return true
        })}
        tooltip={"Form actions"}
        open={formActionSliderOpen}
        onChange={setFormActionSliderOpen}
        onOpenMenu={() => {
          setVersSliderOpen(false)
          setSaveVersSliderOpen(false)
        }}
        onCloseMenu={onCloseMenu}
        wrapClassName="form-action-btn-wrap"
      />
      <ButtonSlider
        iconClassName={"fal fa-game-board-alt btn-slider"}
        tooltip={"Version compare"}
        open={versSliderOpen}
        onChange={setVersSliderOpen}
        onOpenMenu={() => {
          setFormActionSliderOpen(false)
          setSaveVersSliderOpen(false)
        }}
        onCloseMenu={onCloseMenu}
      >
        <>
          <PrimeField
            schema={{
              type: "dropdown",
              items: versDropdownOptions.map(item => item.text),
              placeholder: "Compare to version...",
            }}
            containerStyle={{ minWidth: 195 }}
            onChange={onVersionSelect}
          />
          {permissions?.["study.form.revert"] && selectedVersOption && <Tooltip
            title={`Revert to version ${Number(selectedVersOption?.id).toFixed(1)}`}
            placement={isSticky ? "bottom" : "top"}>
            <i
              className="fal fa-undo"
              style={{ fontSize: 16 }}
              onClick={() => setOpenRevertModal(true)} />
          </Tooltip>}
        </>
      </ButtonSlider>
      {permissions?.["study.edit"] && (
        <ButtonSlider
          iconClassName={"fal fa-save btn-slider"}
          type={"primary"}
          menuItems={menuItems.save}
          tooltip={"Save version"}
          open={saveVersSliderOpen}
          onChange={setSaveVersSliderOpen}
          onOpenMenu={() => {
            setFormActionSliderOpen(false)
            setVersSliderOpen(false)
          }}
          onCloseMenu={onCloseMenu}
          wrapClassName="save-btn-wrap"
        />
      )}
      {lastSaved && <div className="authoring-hover-text">
        {lastSavedMessage}
      </div>}
    </div>
  }

  const stickyStyle = {
    width: `calc(100vw - ${sidebarOpen ? 215 : 66}px - 86px)`,
    left: `calc(${sidebarOpen ? 215 : 66}px + 50px)`
  }

  useEffect(() => {
    if (scrollPosition >= navbarOffsetTop && isSticky === false) {
      setIsSticky(true)
      let formEl = document.getElementById("authoring-form-container")
      if (formEl) formEl.style.marginTop = "80px"
    } else if (scrollPosition < navbarOffsetTop && isSticky) {
      setIsSticky(false)
      let formEl = document.getElementById("authoring-form-container")
      if (formEl) formEl.style.marginTop = "0px"
    }
  }, [scrollPosition])

  const onChangeSection = (val) => {
    let found = sectDropdownOptions.find(item => item.text === val)
    if (found) {
      handleSection(found)
      const container = document.getElementsByClassName("right-section")[0];
      if (container) {
        container.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    }
  }

  const validationErrorsExist = useMemo(() => {
    if (registryValidations === null) return null;
    let shouldBreak = false;

    if (registryValidations) {
      Object.keys(registryValidations).forEach(key => {
        registryValidations[key].forEach(validation => {
          if (shouldBreak) return
          if (validation.type === "ERROR") {
            shouldBreak = true
            return
          }
        })
        if (shouldBreak) return
      })
    }

    return shouldBreak
  }, [registryValidations])

  let classNames = [`study-data-form-actions${scrollPosition > navbarOffsetTop ? ' sticky' : ''} `]

  if (validationErrorsExist) classNames.push("form-action-validation-error")
  if (validationErrorsExist === false) classNames.push("form-action-validation-no-error")

  return <div
    className={classNames.join(" ")}
    ref={navbarRef}
    style={isSticky ? stickyStyle : {}}
  >
    <div className="registry-actions-container">
      <span className="registry-label">Registries</span>
      {/* <i className="fal fa-plus-circle registry-badge-icon" /> */}
      <RegistryButtons
        formView={formView}
        setFormView={setFormView}
        formSection={formSection}
        formSchemas={formSchemas}
        showPagePreview={showPagePreview}
        validationErrorsExist={validationErrorsExist}
        setRegistryValidations={setRegistryValidations}
      />
      {formSection.id !== "secondaryAttributes" &&
        formSection.id !== "sponsorTrialWebsite" &&
        isReviewerView !== true &&
        <>
          {showRegistryBadges ? (
            <Tooltip title="Hide registry badges">
              <i
                className="fal fa-eye show-registry-badge-icon"
                onClick={() => setShowRegistryBadges(false)} />
            </Tooltip>
          ) : (
            <Tooltip title="Show registry badges">
              <i
                className="fal fa-eye-slash registry-badge-icon"
                onClick={() => setShowRegistryBadges(true)} />
            </Tooltip>
          )}
        </>
      }
    </div>
    <div className="right-container">
      {renderActions()}
    </div>
    <RevertConfirmModal
      openModal={openRevertModal}
      setOpenModal={setOpenRevertModal}
      header={`Are you sure you want to revert to version #: ${getVersionNumber()}`}
      content={<div>This action cannot be undone. All overrides made after the version was created will be lost.</div>}
      actionLabel="Revert"
      onAction={() => handleVersRevert()}
      actionVariant="primary"
    />
    {sectDropdownOptions && <div className="form-nav-wrap">
      <FormNavigator
        sectDropdownOptions={sectDropdownOptions.filter(item => {
          if (showPagePreview) {
            if (item.text === "Protocol" || item.text === "Results") return true;
            return false
          }
          if (authoring?.study_data?.protocol?.study_type !== "Interventional" && item.id === "delayedResultsCertification")
            return false;
          return true;
        }).map(item => item.text)}
        onChangeSection={onChangeSection} />
    </div>}
    <DownloadPreviewModal
      openDownloadPreview={openDownloadPreview}
      setOpenDownloadPreview={setOpenDownloadPreview}
      authoringId={authoring?.id} />
    <VersionSaveModal
      type={saveVersType}
      open={openVersionModal}
      onHide={() => {
        setOpenVersionModal(false)
        setFormActionSliderOpen(false)
        setSaveVersSliderOpen(false)
      }}
      onCloseMenu={onCloseMenu}
      authoring={authoring}
      onSubmit={handleSubmit}
    />
  </div>
}

export default React.memo(FormActions)