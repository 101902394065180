export const translateProps = {
  ctg: { // from
    eudract: { // to
      parameter_type: { // key
        "Number": "number",
        "Mean": "arithmetic mean",
        "Median": "median",
        "Least Squares Mean": "least squares mean", 
        "Geometric Mean": "geometric mean",
      },
      dispersion_type: {
        "Standard Deviation": "standard deviation", 
        "Standard Error": "standard error", 
        "Inter-Quartile Range": "inter-quartile range (Q1-Q3)", 
        "Full Range": "full-range (min-max)", 
        "Confidence Interval": "confidence interval", 
        "Geometric Coefficient of Variation": "geometric coefficient of variation",
        "Not Applicable": "not appliable"
      }
    }
  },
  eudract: { // from 
    ctg: { // to
      parameter_type: {
        "number": "Number",
        "arithmetic mean": "Mean",
        "median": "Median",
        "least squares mean": "Least Squares Mean", 
        "geometric mean": "Geometric Mean",
        "log mean": "Mean",
      },
      dispersion_type: {
        "standard deviation": "Standard Deviation", 
        "inter-quartile range (Q1-Q3)": "Inter-Quartile Range", 
        "full-range (min-max)": "Full Range", 
        "standard error": "Standard Error", 
        "confidence interval": "Confidence Interval",
        "geometric coefficient of variation": "Geometric Coefficient of Variation",
        "not appliable": "Not Applicable"
      }
    }
  }
}


export const moveLeftHelper = (om, key, i) => {
  let tempGroups = [...om[key]]
  let saved = tempGroups[i-1];
  tempGroups[i-1] = tempGroups[i];
  tempGroups[i] = saved;
  om[key] = [...tempGroups];
}

export const moveRightHelper = (om, key, i) => {
  let tempGroups = [...om[key]]
  let saved = tempGroups[i+1];
  tempGroups[i+1] = tempGroups[i];
  tempGroups[i] = saved;
  om[key] = [...tempGroups];
}

export const moveRowUpHelper = (tempData, key, omIndex, rowIndex) => {
  let rows = [...tempData[key].outcome_measures[omIndex]?.measure_rows];
  let saved = rows[rowIndex-1];
  rows[rowIndex-1] = rows[rowIndex];
  rows[rowIndex] = saved;
  tempData[key].outcome_measures[omIndex].measure_rows = [...rows];
}

export const moveRowDownHelper = (tempData, key, omIndex, rowIndex) => {
  let rows = [...tempData[key].outcome_measures[omIndex]?.measure_rows];
  let saved = rows[rowIndex+1];
  rows[rowIndex+1] = rows[rowIndex];
  rows[rowIndex] = saved;
  tempData[key].outcome_measures[omIndex].measure_rows = [...rows];
}


export const moveCategoryUpHelper = (tempData, key, omIndex, rowIndex, catIndex) => {
  let categories = [...tempData[key].outcome_measures[omIndex].measure_rows[rowIndex].categories];
  let saved = categories[catIndex-1];
  categories[catIndex-1] = categories[catIndex];
  categories[catIndex] = saved;
  tempData[key].outcome_measures[omIndex].measure_rows[rowIndex].categories = [...categories];
}

export const moveCategoryDownHelper = (tempData, key, omIndex, rowIndex, catIndex) => {
  let categories = [...tempData[key].outcome_measures[omIndex].measure_rows[rowIndex].categories];
  let saved = categories[catIndex+1];
  categories[catIndex+1] = categories[catIndex];
  categories[catIndex] = saved;
  tempData[key].outcome_measures[omIndex].measure_rows[rowIndex].categories = [...categories];
}