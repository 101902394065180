import { useState, useContext, createContext } from "react";

const StudyContext = createContext({});

export const StudyProvider = ({ children }) => {
  const [openPanel, setOpenPanel] = useState(false)
  const [panelType, setPanelType] = useState(null)
  const [panelWidth, setPanelWidth] = useState(310)

  const onOpenPanel = (type) => {
    if (type === "edit-checklist" || type === "missing-fields") setPanelWidth(470)
    else if (type === "add-existing-document" || type === "checklist-document-upload") setPanelWidth(340)
    else setPanelWidth(310)
    setPanelType(type)
    setOpenPanel(true)
  }

  const onClosePanel = () => {
    setPanelType(null)
    setOpenPanel(false)
  }

  return (
    <StudyContext.Provider
      value={{
        openPanel,
        setOpenPanel,
        onOpenPanel,
        onClosePanel,
        panelType,
        setPanelType,
        panelWidth,
      }}>
      {children}
    </StudyContext.Provider>
  )
}

const useStudy = () => {
  return useContext(StudyContext);
}

export default useStudy;