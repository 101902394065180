import React from 'react';

// utils
import useBadges from 'utilities/useBadges'

const WorkflowBadge = (props) => {
  const {
    value,
    column,
    badgeKey,
    data
  } = props

  const getAllRows = () => {
    let rowData = [];
    props.api.forEachNode(node => rowData.push(node.data));
    return rowData;
  }

  const getUniqueValues = (data) => {
    if (data) {
      return [...new Set(data.map((data) => data?.[column?.colId]))]
    }
    return null
  }

  const { Badge } = useBadges(getUniqueValues(getAllRows()))

  if (!value && !badgeKey) return null;

  let type = data?.[badgeKey] || value

  return <div style={{ display: "flex" }}>
    <Badge value={type} />
  </div>
};

export default WorkflowBadge;