export const delayedResultsDataSchema = {
  type: "object",
  properties: {
    delayed_results_certification: {
      type: "object",
      properties: {
        result_type: {
          type: "string",
          enum: ["Extension", "Certify New Use", "Certify Initial Approval", "Unselected"],
        },
      },
      dependencies: {
        result_type: {
          oneOf: [{
            properties: {
              result_type: {
                enum: ["Certify New Use", "Certify Initial Approval"]
              },
              intervention_name: {
                type: "string"
              },
              application_number: {
                type: "string"
              },
            }
          },
          {
            properties: {
              result_type: {
                enum: ["Extension"]
              },
              month: {
                type: "string",
                enum: ["January", "February", "March", "April", "May", "June", "July", "August",
                  "September", "October", "November", "December"
                ],
              },
              day: {
                type: "number"
              },
              year: {
                type: "number",
              },
              explaination: {
                type: "string"
              },
              appeal_delayed_results_decision: {
                type: "string"
              }
            }
          },
          ]
        }
      }
    }
  }
}