import React, { useEffect, useState } from 'react';
import DocumentChecklist from '../DocumentChecklist/DocumentChecklist';

// components
import Loading from 'components/Loading/Loading'
import EmptyStateChecklist from 'components/EmptyStateChecklist/EmptyStateChecklist';

// apis
import { useUserPermissions } from 'api/hooks';

// context
import useStudy from 'context/hooks/useStudy';
import useChecklists from 'context/hooks/useChecklists';

// styles
import './document-checklists.scss'

const DocumentChecklists = ({
  onDropDocument,
  openRowPanel
}) => {
  const { data: permissions } = useUserPermissions()

  const {
    studyChecklists,
    studyNonChecklistDocuments
  } = useChecklists()
  const {
    onOpenPanel
  } = useStudy()

  let checklists = studyChecklists

  if (studyChecklists) {
    let filteredChecklists = studyChecklists.filter(checklist => checklist?.name?.toLowerCase() !== "documents without checklists")
    if (studyNonChecklistDocuments?.options?.length > 0) filteredChecklists.push(studyNonChecklistDocuments)
    checklists = filteredChecklists;
  }

  if (studyChecklists === null) {
    return <Loading />
  }

  if (studyChecklists.length === 0) {
    return <EmptyStateChecklist onAddChecklist={() => onOpenPanel("add-checklist")} permissions={permissions} />
  }

  return (
    <div className="document-checklists-container">
      {checklists.map((checklist, i) => {
        return (
          <DocumentChecklist
            key={i}
            onDropDocument={onDropDocument}
            checklist={checklist}
            openRowPanel={openRowPanel}
          />
        )
      })}
    </div>
  );
};

export default DocumentChecklists;