import getUrl from 'api/apiMap'
import useGetApi from '../useGetApi'
import useMutateApi from '../useMutateApi'

// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

export const useGetRequestTeam = (id) => {
  return useGetApi(getUrl(`${apiEndpoints.requests}${id}/team/`), queryKeys.requests.team(Number(id)))
}

export const usePostRequestTeam = (id) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(`${apiEndpoints.requests}${id}/team/`),
    invalidateQueries: [queryKeys.requests.team(Number(id))],
    successMsg: "Sucessfully updated team",
  })
}