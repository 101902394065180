import { useContext, createContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

// context
import useAppState from "./useAppState";
import useRefreshToken from "utilities/useRefreshToken";

// apis
import {
  getCookie,
  deleteCookie
} from 'api/apiUtils'

// utils
import getUrl from "api/apiMap";

export const AuthProvider = ({ children }) => {
  const { setAppState, appStateRef } = useAppState()
  const [auth, setAuth] = useState({});
  const navigate = useNavigate()
  const location = useLocation()
  const { setTokens } = useRefreshToken()

  const login = async (creds) => {
    const response = await axios.post(getUrl("/auth/api/v1/login/"), creds)
    const access = response?.data?.access;
    const refresh = response?.data?.refresh;

    setTokens({
      access,
      refresh
    })

    if (access && refresh) {
      setAppState("authenticated")
      appStateRef.current = "authenticated";

      if (location?.state?.from) {
        navigate(location.state.from)
      } else {
        navigate("/")
      }
    }

    return response
  }

  // TODO: update logout func once API is ready
  const logout = async () => {
    let refresh = getCookie("xopri-jwt-retoken")

    axios.post(getUrl("/auth/api/v1/logout/"), {
      refresh
    }).then(() => {
      appStateRef.current = "unauthenticated"
      setAppState("unauthenticated")
      navigate("/")
      setAuth({
        refresh: null,
        access: null
      });
      setTokens({
        refresh: null,
        access: null
      })
      deleteCookie("xopri-jwt-actoken")
      deleteCookie("xopri-jwt-retoken")
    })
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        login,
        logout
      }}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthContext = createContext({});

const useAuth = () => {
  return useContext(AuthContext);
}

export default useAuth;