import { useState, useEffect, useMemo, memo, useRef } from 'react'
import SegmentedTabs from 'components/SegmentedTabs/SegmentedTabs'
import Modal from '../../Modal/Modal'
import UserPresets from './UserPresets'
import PrimePresets from './PrimePresets'

//modals
import AddPreset from './modalContent/AddPreset'
import UpdatePreset from './modalContent/UpdatePreset'
import DeletePreset from './modalContent/DeletePreset'
import DeletePresetSuccess from './modalContent/DeletePresetSuccess'

// apis
// admin presets
import {
  useGetPresets,
} from 'api/hooks/admin/usePresetsApi';

// user presets
import {
  useGetGridPresets,
  usePostGridPreset,
  usePutGridPreset,
  useDeleteGridPreset
} from 'api/hooks/grids/usePresetsApi'

// styles
import './Presets.scss'

const Presets = ({
  api,
  columnApi,
  gridName,
}) => {
  const { data: gridPresets = [], isLoading: isGridPresetsLoading } = useGetGridPresets(gridName)
  const { data: allAdminPresets = [] } = useGetPresets() // TODO: rename allAdminPresets and replace state var
  const [view, setView] = useState("user")
  const initLoad = useRef(true)

  const postGridPreset = usePostGridPreset(gridName)
  const putGridPreset = usePutGridPreset(gridName)
  const deleteGridPreset = useDeleteGridPreset(gridName)

  // filter to only current grid presets
  const adminGridPresets = useMemo(() => {
    if (allAdminPresets) {
      return allAdminPresets.filter((preset) => preset.grid_ident === gridName)
    }
    return []
  }, [gridName, allAdminPresets])

  const tabValues = ["Prime Presets", "My Presets"]

  const onTabChange = (value) => {
    if (value === 'Prime Presets') {
      setView('prime')
    } else {
      setView('user')
    }
  }

  const [openModal, setOpenModal] = useState(false);
  const [modalConfig, setModalConfig] = useState({});
  const [activePreset, setActivePreset] = useState(null)

  const getCurrentState = () => {
    if (api) {
      const currentState = {
        column_state: columnApi.getColumnState(),
        filter_state: api.getFilterModel(),
        sort_state: getSortModel(),
      }
      findActiveState(currentState)
    }
  }

  useEffect(() => {
    if (gridPresets, api) {
      getCurrentState();
      const eventTypes = [
        'columnVisible',
        'columnPinned	',
        'columnResized',
        'columnMoved',
        'columnRowGroupChanged',
        'displayedColumnsChanged',
        'sortChanged',
        'filterChanged'
      ]
      eventTypes.forEach(eventType => {
        api.addEventListener(eventType, () => {
          getCurrentState();
        })
      });
    }
  }, [gridPresets, api]);

  useEffect(() => {
    if (initLoad.current && isGridPresetsLoading === false) {
      gridPresets.forEach(preset => {
        if (preset.is_default) {
          onLoad(preset)
          initLoad.current = false
        }
      })
    }
  }, [isGridPresetsLoading])

  const getSortModel = () => {
    const value = columnApi.getColumnState().find(s => s.sort != null)
    if (value) {
      return [value]
    } else {
      return []
    }
  }

  const savePreset = (name) => {
    let newPreset = {
      name,
      grid_ident: gridName,
      json: {
        column_state: columnApi.getColumnState(),
        filter_state: api.getFilterModel(),
        sort_state: getSortModel(),
      },
      is_default: false,
    }

    postGridPreset({ body: newPreset }, {
      onSuccess: (resp) => {
        setOpenModal(false);
      }
    })
  }

  const updatePreset = (preset) => {
    let req = {
      ...preset,
      preset_id: preset.id
    }

    putGridPreset({ body: req }, {
      onSuccess: (resp) => {
        setOpenModal(false);
      }
    })
  }

  const findActiveState = (currentState) => {
    let presets = view === "user" ? [...gridPresets] : [...adminGridPresets]

    let foundActivePreset = presets.find(preset => {
      let foundPreset = deepEqual(findObjectByKey(preset.json, "width"), findObjectByKey(currentState, "width"))
      return foundPreset
    })

    if (foundActivePreset) setActivePreset(foundActivePreset)
    else setActivePreset(null)
  }

  const deepEqual = (obj1, obj2) => {
    // Check if both parameters are objects
    if (obj1 && obj2 && typeof obj1 === 'object' && typeof obj2 === 'object') {
      // Get keys of both objects
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
  
      // Check if the number of keys is the same
      if (keys1.length !== keys2.length) {
        return false;
      }
  
      // Check if all keys in obj1 exist in obj2
      for (let key of keys1) {
        if (!keys2.includes(key)) {
          return false;
        }
      }
  
      // Recursively compare values
      for (let key of keys1) {
        if (!deepEqual(obj1[key], obj2[key])) {
          return false;
        }
      }
  
      // If all checks pass, the objects are deep equal
      return true;
    } else {
      // If not both objects, compare values directly
      return obj1 === obj2;
    }
  }

  const findObjectByKey = (o, key) => {
    if (!o || (typeof o === 'string')) {    //  assumes all leaves will be strings
      return o
    }
    o = Object.assign({}, o)                // make a shallow copy
    if (o[key]) {
      delete o[key]                         // delete key if found
    }
    Object.keys(o).forEach(k => {
      o[k] = findObjectByKey(o[k], key)   // do the same for children
    })
    return o                                // return the new object
  }

  const findAndDeleteObjectByKey = function (o, key) {
    if (!o || (typeof o === 'string')) {    //  assumes all leaves will be strings
      return o
    }
    o = Object.assign({}, o)                // make a shallow copy
    if (o[key]) {
      delete o[key]                         // delete key if found
    }
    Object.keys(o).forEach(k => {
      o[k] = findAndDeleteObjectByKey(o[k], key)   // do the same for children
    })
    return o                                // return the new object
  }

  const deletePreset = (deletedPreset) => {
    deleteGridPreset({
      params: {
        "preset-id": deletedPreset.id
      }
    }, {
      onSuccess: (resp) => {
        onOpenModal("delete-preset-success", deletedPreset)
      }
    })
  }

  const setDefaultPreset = (index) => {
    gridPresets.forEach(preset => {
      if (preset.is_default) {
        updatePreset({
          ...preset,
          is_default: false
        })
      }
    })

    let updatedPreset = gridPresets[index];

    updatedPreset.is_default = true;
    updatePreset(updatedPreset)
  }

  const onLoad = (preset) => {
    const {
      column_state,
      filter_state
    } = preset.json
    if (column_state) columnApi.applyColumnState({ state: column_state, applyOrder: true, });
    if (filter_state) api.setFilterModel(filter_state);

    setActivePreset(preset)
  }

  const onResetPreset = () => {
    api.setFilterModel(null)
    columnApi.resetColumnState()
    columnApi.resetColumnGroupState()
    api.onFilterChanged();
  }

  const onOpenModal = (variant, data) => {
    switch (variant) {
      case "add-preset":
        setModalConfig({
          title: "Save Preset",
          children: <AddPreset
            setOpenModal={setOpenModal}
            presets={[...allAdminPresets, ...gridPresets]} savePreset={savePreset}
          />
        })
        setOpenModal(true);
        break;
      case "update-preset":
        setModalConfig({
          title: "Update Preset",
          children: <UpdatePreset
            setOpenModal={setOpenModal}
            data={data}
            presets={[...allAdminPresets, ...gridPresets]}
            updatePreset={updatePreset}
          />
        })
        setOpenModal(true);
        break;
      case "delete-preset":
        setModalConfig({
          title: `Delete ${data.name}`,
          type: "delete",
          message: "Are you sure you would like to delete preset? This cannot be undone.",
          children: <DeletePreset
            onOpenModal={onOpenModal}
            deletePreset={deletePreset}
            setOpenModal={setOpenModal}
            data={data}
          />
        })
        setOpenModal(true);
        break;
      case "delete-preset-success":
        setModalConfig({
          label: "Preset Deleted",
          title: `Successfully deleted ${data.name}`,
          children: <DeletePresetSuccess
            setOpenModal={setOpenModal}
          />
        })
        setOpenModal(true);
        break;
      default: return;
    }
  }

  return (
    <div className="grid-presets">
      <div className="presets-header-actions">
        <SegmentedTabs
          block
          options={tabValues}
          defaultValue={tabValues[tabValues.length - 1]}
          onChange={onTabChange}
          className="preset-tabs"
        />
      </div>
      <div className="preset-content">
        {view === "prime"
          ? <PrimePresets
            presets={adminGridPresets}
            activePreset={activePreset}
            agGridApi={api}
            onLoad={onLoad}
            onOpenModal={onOpenModal}
            onResetPreset={onResetPreset}
            setDefaultPreset={setDefaultPreset}
          // setDefaultPreset={setDefaultPreset}
          />
          // TODO: update to new user preset api
          :
          <UserPresets
            presets={gridPresets}
            activePreset={activePreset}
            agGridApi={api}
            onLoad={onLoad}
            setDefaultPreset={setDefaultPreset}
            updatePreset={updatePreset}
            onResetPreset={onResetPreset}
            onOpenModal={onOpenModal}
          />
        }
      </div>
      <Modal
        className="preset-modal"
        type={modalConfig.type}
        open={openModal}
        onCancel={() => setOpenModal(false)}
        title={modalConfig.title}
        message={modalConfig.message}
        setOpenModal={setOpenModal}
        size="sm"
        footer={[]}
      >
        {modalConfig.children}
      </Modal>
    </div>
  );
};

export default memo(Presets);