import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { debounce } from 'lodash';
import { Select } from 'components-design-system';
import { Dropdown } from 'antd';
import OptionBadge from './OptionBadge/OptionBadge';

// apis
import { useGlobalSearchApi } from 'api/hooks/global';

// context
import useAutoClose from 'utilities/useAutoClose';

// styles
import './useGlobalSearch.scss'

const useGlobalSearch = () => {
  const navigate = useNavigate()
  const search = useGlobalSearchApi()
  // search for global ID
  const [options, setOptions] = useState([])

  // searchValue ensures dropdown is closed when no search string but options are available:
  const [searchValue, setSearchValue] = useState('')
  // dropdownVisible ensures dropdown is closed when user clicks outside component:
  const [dropdownVisible, setDropdownVisible] = useState(false)

  const dropdownRef = useRef();
  useAutoClose(dropdownRef, setDropdownVisible);

  const handleStudySearch = (value) => {
    let searchValue = value
    setSearchValue(value)
    if (!value) searchValue = null;
    debouncedSearch.current(searchValue)
  }

  // find all studies for global ID search
  const onSearch = (value) => {
    if (typeof value === "string") {
      search({ body: { filter_val: value } }, {
        onSuccess: res => {
          if (res?.data?.results) {
            setOptions(res.data.results)
          }
        }
      })
    }
  }

  const onClick = (option) => {
    setSearchValue("")
    if(option.result_type === "study") {
      navigate(`/study/${option.id}/`)
    } else if(option.result_type === "dsrequest") {
      navigate(`/request/${option.id}/`)
    } else if(option.result_type === "document") {
      navigate(`/file/${option.id}/`)
    }
    setDropdownVisible(false)
  }

  useEffect(() => {
    if (!searchValue || searchValue.length === 0) {
      setDropdownVisible(false)
    } else if (Array.isArray(options) && options.length > 0) {
      setDropdownVisible(true)
    }
  }, [options, searchValue])

  const debouncedSearch = useRef(debounce(onSearch, 800));

  const GlobalSearch = (
    <div className="global-search-wrap" id="global-search-wrap-id" ref={dropdownRef}>
      <Dropdown
        getPopupContainer={() => document.getElementById("global-search-wrap-id")}
        menu={{
          items: options.map((option, i) => {
            return {
              key: i.toString(),
              label: (
                <a 
                  className="global-search-dropdown-option" 
                  onClick={() => onClick(option)}>
                  <OptionBadge type={option.result_type} />
                  <div className="search_result">
                    {option.search_result}
                  </div>
                </a>
              ),
            }
          }),
        }}
        overlayClassName="global-search-dropdown"
        open={dropdownVisible}
      >
        <>
          <Select
            options={options}
            placeholder="Search Studies, Requests, or Documents"
            size="large"
            onSearch={(value) => handleStudySearch(value)}
            // value={null} // reset value when empty
            searchValue={searchValue}
            open={false}
            className="global-search-select-wrap" // wrapperClassName
            selectClassname="global-search-select"
            showSearch
            allowClear={false}
            showArrow={false}
          />
          <i
            type="button"
            className="fas fa-search"
            aria-label="toggle menu"
          />
        </>
      </Dropdown>
    </div>
  )

  return {
    GlobalSearch,
    options,
    searchValue,
    handleStudySearch
  }
};

export default useGlobalSearch;