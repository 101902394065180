import React, { useState } from 'react';
import styled from 'styled-components';
import { useShallow } from 'zustand/react/shallow'

// components
import Modal from 'components/Modal/Modal';
import { Button } from 'components-design-system'
import PrimeField from 'components/PrimeField/PrimeField';

// hooks
import useAuthoringViewStore from '../../../hooks/stores/useAuthoringViewStore';

const ResultsTableDeleteModal = (props) => {
  const [deleteForAll, setDeleteForAll] = useState(false);
  let {
    header,
    showModal,
    setShowModal,
    onSubmit,
    canDeleteFromAllRegistries,
    registrySpecific,
    hideOptions = false
  } = props;

  const { 
    formView,
  } = useAuthoringViewStore(
    useShallow(state => ({ 
      formView: state.formView,
    })),
  )

  const handleSubmit = () => {
    if (formView.id === "global") onSubmit(true);
    else onSubmit(deleteForAll);
    setDeleteForAll(false);
    setShowModal(false)
  }

  let view = formView.id;
  if (formView.id === "eudra") view = "eudract";

  return (
    <Modal
      title={header}
      // description={`delete description`}
      open={showModal}
      onCancel={() => setShowModal(false)}
      dialogClassName={"results-create-delete-modal"}
      footer={[]}
      destroyOnClose={true}
      type="delete-alt">

      {formView.id === "global"
        ? <ModalContent>
          <ContentContainer>
            Deleting from Global view will delete items from all registries.
          </ContentContainer>
          <ActionContainer>
            <Button
              onClick={() => setShowModal(false)}
              variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={() => handleSubmit()}
              variant="danger">
              Delete
            </Button>
          </ActionContainer>
        </ModalContent>
        : <ModalContent>
          <ContentContainer>
            {registrySpecific !== true && hideOptions !== true && (
              <PrimeField
                schema={{
                  type: "checkbox",
                  label: "Delete for all registries",
                  disabled: canDeleteFromAllRegistries
                }}
                value={deleteForAll}
                onChange={e => setDeleteForAll(e)} />
            )}
            {hideOptions !== true && <PrimeField
              schema={{
                type: "checkbox",
                label: `Delete for ${view?.toUpperCase()} only`,
                disabled: canDeleteFromAllRegistries
              }}
              value={true} />}
          </ContentContainer>
          <ActionContainer>
            <Button
              onClick={() => setShowModal(false)}
              variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={() => handleSubmit()}
              variant="danger">
              Delete
            </Button>
          </ActionContainer>
        </ModalContent>
      }
    </Modal>
  );
};

export default ResultsTableDeleteModal;

const Container = styled.div`

`

const ContentContainer = styled.div`
  margin-bottom: 32px;
  text-align: center;
`

const ModalContent = styled.div`

`

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 12px;
  p {
    font-size: 12px !important;
  }
`