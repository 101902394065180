import { collaborators, contactTitles } from '../enum/enum';
import { eupasProperties, eupasDependencies } from '../utils/eupasUtils';
import { ctisProperties, ctisDependencies } from '../utils/ctisUtils';

import {
  countries,
  conditions,
  STUDY_PHASES
} from '../enum/enum';

export const study_identification = {
  type: "object",
  properties: {
    brief_title: {
      title: "Brief Title",
      type: "string",
    },
    acronym: {
      type: "string",
      title: "Protocol ID",
      tooltip: "If specified, will be included at end of Brief Title in parentheses.",
    },
    official_title: {
      title: "Official Title",
      type: "string",
      placeholder: "Enter Official Title",
    },
    secondary_ids: {
      type: "array",
      items: {
        type: "object",
        // onAddButtonTitle: "Add Secondary ID",
        properties: {
          secondary_id_type: {
            type: "string",
            enum: ["US NIH Grant/Contract Award Number", "Other Grant/Funding Number", "Registry Identifier",
              "EudraCT Number", "WHO Universal Trial Number (UTN)", "ClinicalTrials.gov identifier (NCT number)", "ISRCTN number", "Other Identifier"
            ]
          },
          id: {
            title: "Identifier",
            type: "string",
          }
        },
        dependencies: {
          secondary_id_type: {
            oneOf: [
              {
                properties: {
                  secondary_id_type: {
                    enum: ["Other Grant/Funding Number"]
                  },
                  domain: {
                    title: "Grantor or Funder",
                    type: "string",
                  },
                }
              },
              {
                properties: {
                  secondary_id_type: {
                    enum: ["Registry Identifier"]
                  },
                  domain: {
                    title: "Registry Name",
                    type: "string",
                  }
                }
              },
              {
                properties: {
                  secondary_id_type: {
                    enum: ["Other Identifier"]
                  },
                  domain: {
                    title: "Issuing Organization",
                    type: "string",
                  }
                }
              },
            ]
          }
        }
      }
    },
    ...eupasProperties.study_identification,
    ...ctisProperties.study_identification
  },
  dependencies: {
    ...eupasDependencies.study_identification
  }
}

export const study_oversight = {
  interventional: {
    title: "Oversight",
    type: "object",
    properties: {
      us_drug: {
        type: "object",
        properties: {
          us_fda_drug: {
            type: "string",
            enum: ["Unselected", "Yes", "No"],
          },
        },
        dependencies: {
          us_fda_drug: {
            oneOf: [{
              properties: {
                us_fda_drug: {
                  enum: ["Yes"],
                },
                productexported_from_us: {
                  type: "string",
                  enum: ["Yes", "No"]
                },
              }
            }]
          }
        }
      },
      us_device: {
        type: "object",
        properties: {
          us_fda_device: {
            type: "string",
            enum: ["Unselected", "Yes", "No"],
          },
          unapproved_uncleared_device: {
            type: "object",
            properties: {
              device: {
                type: "string",
                enum: ["Unselected", "Yes", "No"]
              }
            },
            dependencies: {
              device: {
                oneOf: [{
                  properties: {
                    device: {
                      enum: ["Yes"]
                    },
                    post_prior_to_approval_clearance: {
                      type: "string",
                      enum: ["Unselected", "Yes", "No"]
                    },
                  },
                }],
              },
            },
          },
          pediatric_postmarket_surveillance: {
            type: "string",
            enum: ["Unselected", "Yes", "No"],
          },
        },
      },
      us_ind: {
        type: "object",
        properties: {
          us_fda_ind_ide: {
            type: "string",
            enum: ["Unselected", "Yes", "No"],
          },
        },
        dependencies: {
          us_fda_ind_ide: {
            oneOf: [{
              properties: {
                us_fda_ind_ide: {
                  enum: ["Yes"]
                },
                fda_center: {
                  enum: ["Unselected", "CDER", "CBER", "CDRH"],
                },
                ind_ide_number: {
                  type: "string"
                },
                ind_serial_number: {
                  type: "string"
                },
                availability_of_expanded_access: {
                  type: "string",
                  enum: ["Unselected", "Yes", "No", "Unknown"],
                },
              },
              dependencies: {
                availability_of_expanded_access: {
                  oneOf: [{
                    properties: {
                      availability_of_expanded_access: {
                        enum: ["Yes"]
                      },
                      expanded_access_record: {
                        type: "string"
                      },
                    },
                  },],
                },
              },
            }]
          }
        }
      },
      human_subjects_protection_review: {
        type: "object",
        properties: {
          board_status: {
            type: "string",
            enum: ["--Select--", "Request not yet submitted", "Submitted, pending", "Submitted, approved", "Exempt", "Submitted, denied", "Submission not required"],
          },
        },
        dependencies: {
          board_status: {
            oneOf: [{
              properties: {
                board_status: {
                  enum: ["Request not yet submitted", "Submitted, pending", "Submitted, denied"],
                },
                board_name: {
                  type: "string"
                },
                board_affiliation: {
                  type: "string"
                },
                board_contact: {
                  title: "Board Contact:",
                  type: "object",
                  properties: {
                    phone: {
                      type: "string"
                    },
                    extension: {
                      type: "string"
                    },
                    email: {
                      type: "string"
                    },
                    address: {
                      type: "string"
                    },
                  },
                },
              },
            },
            {
              properties: {
                board_status: {
                  enum: ["Submitted, approved"],
                },
                approvalnumber: {
                  type: "string"
                },
                board_name: {
                  type: "string"
                },
                board_affiliation: {
                  type: "string"
                },
                board_contact: {
                  title: "Board Contact:",
                  type: "object",
                  properties: {
                    phone: {
                      type: "string"
                    },
                    extension: {
                      type: "string"
                    },
                    email: {
                      type: "string"
                    },
                    address: {
                      type: "string"
                    },
                  },
                },
              },
            },
            ],
          },
        },
      },
      data: {
        type: "object",
        properties: {
          data_monitoring_committee: {
            type: "string",
            enum: ["Unselected", "Yes", "No"],
          }
        }
      },
      fda: {
        type: "object",
        properties: {
          fda_regulated_intervention: {
            type: "string",
            enum: ["Unselected", "Yes", "No"],
          },
        },
        dependencies: {
          fda_regulated_intervention: {
            oneOf: [{
              properties: {
                fda_regulated_intervention: {
                  enum: ["Yes"]
                },
                section_801_clinical_trial: {
                  type: "string",
                  enum: ["Unselected", "Yes", "No"]
                },
              },
            }]
          }
        }
      }
    },
  },
  observational: {
    title: "Oversight",
    type: "object",
    properties: {
      us_drug: {
        type: "object",
        properties: {
          us_fda_drug: {
            type: "string",
            enum: ["Yes", "No"],
          },
        },
        dependencies: {
          us_fda_drug: {
            oneOf: [{
              properties: {
                us_fda_drug: {
                  enum: ["Yes"],
                },
                productexported_from_us: {
                  type: "string",
                  enum: ["Yes", "No"]
                },
              }
            }]
          }
        }
      },
      us_device: {
        type: "object",
        properties: {
          us_fda_device: {
            type: "string",
            enum: ["Yes", "No"],
          },
        },
        dependencies: {
          us_fda_device: {
            oneOf: [{
              properties: {
                us_fda_device: {
                  enum: ["Yes"]
                },
                unapproved_uncleared_device: {
                  type: "string",
                  enum: ["Yes", "No"]
                },
                pediatric_postmarket_surveillance: {
                  type: "string",
                  enum: ["Yes", "No"],
                },
              },
              dependencies: {
                unapproved_uncleared_device: {
                  oneOf: [{
                    properties: {
                      unapproved_uncleared_device: {
                        enum: ["Yes"]
                      },
                      post_prior_to_approval_clearance: {
                        type: "string",
                        enum: ["Yes", "No"]
                      },
                    },
                  },],
                },
              },
            },],
          },
        }
      },
      us_ind: {
        type: "object",
        properties: {
          us_fda_ind_ide: {
            type: "string",
            enum: ["Yes", "No"],
          },
        },
        dependencies: {
          us_fda_ind_ide: {
            oneOf: [{
              properties: {
                us_fda_ind_ide: {
                  enum: ["Yes"]
                },
                fda_center: {
                  enum: ["CDER", "CBER", "CDRH"],
                },
                ind_ide_number: {
                  type: "string"
                },
                ind_serial_number: {
                  type: "string"
                },
                availability_of_expanded_access: {
                  type: "string",
                  enum: ["Yes", "No", "Unknown"],
                },
              },
              dependencies: {
                availability_of_expanded_access: {
                  oneOf: [{
                    properties: {
                      availability_of_expanded_access: {
                        enum: ["Yes"]
                      },
                      expanded_access_record: {
                        type: "string"
                      },
                    },
                  },],
                },
              },
            }]
          }
        }
      },
      human_subjects_protection_review: {
        type: "object",
        properties: {
          board_status: {
            type: "string",
            enum: ["Request not yet submitted", "Submitted, pending", "Submitted, approved", "Exempt", "Submitted, denied", "Submission not required"],
          },
        },
        dependencies: {
          board_status: {
            oneOf: [{
              properties: {
                board_status: {
                  enum: ["Request not yet submitted", "Submitted, pending", "Submitted, denied"],
                },
                board_name: {
                  type: "string"
                },
                board_affiliation: {
                  type: "string"
                },
                board_contact: {
                  title: "Board Contact:",
                  type: "object",
                  properties: {
                    phone: {
                      type: "string"
                    },
                    extension: {
                      type: "string"
                    },
                    email: {
                      type: "string"
                    },
                    address: {
                      type: "string"
                    },
                  },
                },
              },
            },
            {
              properties: {
                board_status: {
                  enum: ["Submitted, approved"],
                },
                approvalnumber: {
                  type: "string"
                },
                board_name: {
                  type: "string"
                },
                board_affiliation: {
                  type: "string"
                },
                board_contact: {
                  title: "Board Contact:",
                  type: "object",
                  properties: {
                    phone: {
                      type: "string"
                    },
                    extension: {
                      type: "string"
                    },
                    email: {
                      type: "string"
                    },
                    address: {
                      type: "string"
                    },
                  },
                },
              },
            },
            ],
          },
        },
      },
      data: {
        type: "object",
        properties: {
          data_monitoring_committee: {
            type: "string",
            enum: ["Yes", "No"],
          }
        }
      },
      fda: {
        type: "object",
        properties: {
          fda_regulated_intervention: {
            type: "string",
            enum: ["Yes", "No"],
          },
        },
        dependencies: {
          fda_regulated_intervention: {
            oneOf: [{
              properties: {
                fda_regulated_intervention: {
                  enum: ["Yes"]
                },
                section_801_clinical_trial: {
                  type: "string",
                  enum: ["Yes", "No"]
                },
              },
            }]
          }
        }
      }
    },
  },
  expanded_access: {
    title: "Oversight",
    type: "object",
    properties: {
      us_ind: {
        type: "object",
        properties: {
          us_fda_ind_ide: {
            type: "string",
            enum: ["Yes", "No"],
          },
        },
        dependencies: {
          us_fda_ind_ide: {
            oneOf: [{
              properties: {
                us_fda_ind_ide: {
                  enum: ["Yes"]
                },
                fda_center: {
                  enum: ["CDER", "CBER", "CDRH"],
                },
                ind_ide_number: {
                  type: "string"
                },
                ind_serial_number: {
                  type: "string"
                },
              },
            }]
          }
        }
      },
    },
  }
}

export const contact_information = {
  interventional_observational: {
    type: "object",
    variant: "parent",
    properties: {
      responsible_party: {
        enum: ["Sponsor", "Principal Investigator", "Sponsor-Investigator"],
      },
      sponsor: {
        enum: ["--Select--", "Ariad Pharmaceuticals", "Baxalta now part of Shire", "Millennium Pharmaceuticals, Inc.", "Shire", "Takeda", "Tigenix S.A.U."],
      },
      collaborators: {
        type: "string",
        enum: collaborators,
      },
      central_contacts: {
        title: "Central Contact Person:",
        type: "object",
        properties: {
          central_contacts_first_name: {
            type: "string",
          },
          central_contacts_middle_name: {
            type: "string",
          },
          central_contacts_last_name: {
            type: "string",
          },
          central_contacts_degree: {
            type: "string",
          },
          central_contacts_phone_number: {
            type: "string",
          },
          central_contacts_ext: {
            type: "string",
          },
          central_contacts_email: {
            type: "string",
          },
        }
      },
      central_contacts_backup: {
        title: "Central Contact Backup:",
        type: "object",
        properties: {
          central_contacts_backup_first_name: {
            type: "string",
          },
          central_contacts_backup_middle_name: {
            type: "string",
          },
          central_contacts_backup_last_name: {
            type: "string",
          },
          central_contacts_backup_degree: {
            type: "string",
          },
          central_contacts_backup_phone_number: {
            type: "string",
          },
          central_contacts_backup_ext: {
            type: "string",
          },
          central_contacts_backup_email: {
            type: "string",
          },
        }
      },
      overall_study_officials: {
        title: "Overall Study Officials",
        type: "array",
        items: {
          type: "object",
          onAddButtonTitle: "Add Study Official",
          properties: {
            overall_study_officials_first_name: {
              type: "string",
            },
            overall_study_officials_middle_name: {
              type: "string",
            },
            overall_study_officials_last_name: {
              type: "string",
            },
            overall_study_officials_degree: {
              type: "string",
            },
            overall_study_officials_organization_affiliation: {
              type: "string",
            },
            official_role: {
              type: "string",
              enum: ["Study Chair", "Study Director", "Study Principal Investigator"]
            },
          },
        },
      },
      ...eupasProperties.contact_information,
    },
    dependencies: {
      responsible_party: {
        oneOf: [{
          properties: {
            responsible_party: {
              enum: ["Principal Investigator"]
            },
            investigator_name: {
              type: "string",
            },
            investigator_official_title: {
              type: "string",
            },
            investigator_affiliation: {
              type: "string",
            },
          },
        },
        {
          properties: {
            responsible_party: {
              enum: ["Sponsor-Investigator"]
            },
            investigator_name: {
              title: "Investigator Name[Username]",
              type: "string",
              placeholder: "--Select--",
              errorMessage: "Investigator Name has not been selected."
            },
            investigator_official_title: {
              title: "Investigator Official Title",
              type: "string",
              placeholder: "Enter Official Title"
            },
            investigator_affiliation: {
              title: "Investigator Affiliation",
              type: "string",
              placeholder: "Enter Affiliation"
            },
          }
        },
        {
          properties: {
            responsible_party: {
              enum: ["Sponsor"]
            }
          }
        }
        ]
      },
      ...eupasDependencies.contact_information
    },
  },
  expanded_access: {
    type: "object",
    variant: "parent",
    properties: {
      responsible_party: {
        type: "string",
        enum: ["Sponsor", "Principal Investigator", "Sponsor-Investigator"],
      },
      sponsor: {
        enum: ["--Select--", "Ariad Pharmaceuticals", "Baxalta now part of Shire", "Millennium Pharmaceuticals, Inc.", "Shire", "Takeda", "Tigenix S.A.U."],
      },
      collaborators: {
        type: "string",
        enum: collaborators,
      },
      central_contacts: {
        title: "Central Contact Person:",
        type: "object",
        properties: {
          central_contacts_title: {
            type: "string",
            enum: contactTitles
          },
          central_contacts_first_name: {
            type: "string",
          },
          central_contacts_middle_name: {
            type: "string",
          },
          central_contacts_last_name: {
            type: "string",
          },
          central_contacts_degree: {
            type: "string",
          },
          central_contacts_phone_number: {
            type: "string",
          },
          central_contacts_ext: {
            type: "string",
          },
          central_contacts_email: {
            type: "string",
          },
        }
      },
      central_contacts_backup: {
        title: "Central Contact Backup:",
        type: "object",
        properties: {
          central_contacts_backup_first_name: {
            type: "string",
          },
          central_contacts_backup_middle_name: {
            type: "string",
          },
          central_contacts_backup_last_name: {
            type: "string",
          },
          central_contacts_backup_degree: {
            type: "string",
          },
          central_contacts_backup_phone_number: {
            type: "string",
          },
          central_contacts_backup_ext: {
            type: "string",
          },
          central_contacts_backup_email: {
            type: "string",
          },
        }
      },
      overall_study_officials: {
        title: "Overall Study Officials",
        type: "array",
        items: {
          type: "object",
          onAddButtonTitle: "Add Study Official",
          properties: {
            overall_study_officials_first_name: {
              type: "string",
            },
            overall_study_officials_middle_name: {
              type: "string",
            },
            overall_study_officials_last_name: {
              type: "string",
            },
            overall_study_officials_degree: {
              type: "string",
            },
            overall_study_officials_organization_affiliation: {
              type: "string",
            },
            official_role: {
              type: "string",
              enum: ["Study Chair", "Study Director", "Study Principal Investigator"]
            },
          },
        },
      },
    },
    dependencies: {
      responsible_party: {
        oneOf: [{
          properties: {
            responsible_party: {
              enum: ["Principal Investigator"]
            },
            investigator_name: {
              type: "string",
            },
            investigator_official_title: {
              type: "string",
            },
            investigator_affiliation: {
              type: "string",
            },
          },
        },
        {
          properties: {
            responsible_party: {
              enum: ["Sponsor-Investigator"]
            },
            investigator_name: {
              title: "Investigator Name[Username]",
              type: "string",
              placeholder: "--Select--",
              errorMessage: "Investigator Name has not been selected."
            },
            investigator_official_title: {
              title: "Investigator Official Title",
              type: "string",
              placeholder: "Enter Official Title"
            },
            investigator_affiliation: {
              title: "Investigator Affiliation",
              type: "string",
              placeholder: "Enter Affiliation"
            },
          }
        },
        {
          properties: {
            responsible_party: {
              enum: ["Sponsor"]
            }
          }
        }
        ]
      },
    },
  }
}

export const study_outcome_measures = {
  title: "Study Endpoints / Outcome Measures",
  type: "object",
  properties: {
    outcome_measures: {
      type: "array",
      items: {
        type: "object",
        onAddButtonTitle: "Add Secondary Outcome",
        properties: {
          type: {
            type: "string",
            enum: ["Primary", "Secondary", "Other Pre-Specified"],
          },
          title: {
            type: "string",
          },
          time_frame: {
            type: "string",
          },
          description: {
            type: "string",
          },
          // ...ctisProperties.study_outcome_measures.outcome_measures.items
        }
      }
    },
    outcome_measures_ctis: {
      type: "array",
      items: {
        type: "object",
        onAddButtonTitle: "Add Secondary Outcome",
        properties: {
          type: {
            type: "string",
            enum: ["Primary", "Secondary", "Other Pre-Specified"],
          },
          title: {
            type: "string",
          },
          ...ctisProperties.study_outcome_measures.outcome_measures_ctis.items
        }
      }
    },
    ...eupasProperties.study_outcome_measures
  }
}

export const study_timeline = {
  interventional_observational: {
    title: "Study Timelines and Status",
    type: "object",
    properties: {
      record_verification_date: {
        title: "Record Verification Date",
        type: "object",
        properties: {
          select_month: {
            type: "string",
            enum: ["January", "February", "March", "April", "May", "June", "July", "August",
              "September", "October", "November", "December"
            ],
          },
          year: {
            type: "number",
          }
        }
      },
      recruitment: {
        type: "object",
        properties: {
          recruitment_status: {
            type: "string",
            enum: ["--Select--", "Not yet recruiting", "Recruiting", "Enrolling by invitation", "Active, not recruiting",
              "Completed", "Suspended", "Terminated (Halted Prematurely)",
              "Withdrawn (No Participants Enrolled)"
            ],
          },
        },
        dependencies: {
          recruitment_status: {
            oneOf: [{
              properties: {
                recruitment_status: {
                  enum: ["Suspended"]
                },
                study_stopped: {
                  type: "string",
                },
              }
            },
            {
              properties: {
                recruitment_status: {
                  enum: ["Terminated (Halted Prematurely)"]
                },
                study_stopped: {
                  title: "Why Study Stopped?",
                  type: "string",
                  placeholder: "Enter Reason"
                },
              }
            },
            {
              properties: {
                recruitment_status: {
                  enum: ["Withdrawn (No Participants Enrolled)"]
                },
                study_stopped: {
                  title: "Why Study Stopped?",
                  type: "string",
                  placeholder: "Enter Reason"
                },
              }
            }
            ]
          }
        }
      },
      milestone_dates: {
        type: "object",
        properties: {
          // study_start_date: {
          //   type: "object",
          //   properties: {
          //     date: {
          //       type: "string",
          //     },
          //     anticipated_actual_date: {
          //       type: "string",
          //       enum: ["Anticipated", "Actual"]
          //     }
          //   }
          // },
          // primary_completion_date: {
          //   type: "object",
          //   properties: {
          //     date: {
          //       type: "string",
          //     },
          //     anticipated_actual_date: {
          //       type: "string",
          //       enum: ["Anticipated", "Actual"]
          //     }
          //   }
          // },
          // study_completion_date: {
          //   type: "object",
          //   properties: {
          //     date: {
          //       type: "string",
          //     },
          //     anticipated_actual_date: {
          //       type: "string",
          //       enum: ["Anticipated", "Actual"]
          //     }
          //   }
          // },
          ...ctisProperties.study_timeline.milestone_dates.properties
        }
      },
      ...eupasProperties.study_timeline
    },
  },
  expanded_access: {
    title: "Study Timelines and Status",
    type: "object",
    properties: {
      record_verification_date: {
        title: "Record Verification Date",
        type: "object",
        properties: {
          select_month: {
            type: "string",
            enum: ["January", "February", "March", "April", "May", "June", "July", "August",
              "September", "October", "November", "December"
            ],
          },
          year: {
            type: "number",
          }
        }
      },
      expanded: {
        type: "object",
        properties: {
          expanded_status: {
            type: "string",
            enum: ["Available", "No Longer Available", "Temporarily Not Available", "Approved For Marketing"],
          },
        },
      },
      milestone_dates: {
        type: "object",
        properties: {
          // study_completion_date: {
          //   type: "object",
          //   properties: {
          //     date: {
          //       type: "string",
          //     },
          //     anticipated_actual_date: {
          //       type: "string",
          //       enum: ["Anticipated", "Actual"]
          //     }
          //   }
          // },
          ...ctisProperties.study_timeline.milestone_dates.properties
        }
      },
      ...eupasProperties.study_timeline
    },
  }
}

export const study_description = {
  type: "object",
  properties: {
    brief_summary: {
      type: "string",
      maxLength: 5000
    },
    detailed_description: {
      type: "string",
      maxLength: 32000
    },
    // study_details_detailed_description: {
    //   type: "string",
    //   maxLength: 32000
    // },
    study_conditions: {
      type: "string",
      maxLength: 160,
      enum: conditions
      // type: "array",
      // items: {
      //   type: "object",
      //   onAddButtonTitle: "Add Condition",
      //   properties: {
      //     condition: {
      //       type: "string",
      //       maxLength: 160,
      //       enum: conditions
      //     }
      //   }
      // }
    },
    study_keywords: {
      type: "string",
      enum: null,
    },
    ...eupasProperties.study_description,
    ...ctisProperties.study_description
  },
  dependencies: {
    ...eupasDependencies.study_description,
    ...ctisDependencies.study_description
  }
}

export const study_scope = {
  interventional: {
    type: "object",
    properties: {
      primary_purpose: {
        type: "string",
        enum: ["Treatment", "Prevention", "Diagnostic", "Supportive Care", "Screening", "Health Services Research",
          "Basic Science", "Device Feasibility", "Other"
        ],
      },
      study_phase: {
        type: "string",
        enum: STUDY_PHASES,
      },
      interventional_study_model: {
        type: "string",
        enum: ["Single Group", "Parallel", "Crossover", "Factorial", "Sequential"],
      },
      model_description: {
        type: "string",
        maxLength: 1000
      },
      masking: {
        type: "array",
        uniqueItems: true,
        label: true,
        items: {
          type: "string",
          enum: ["Participant", "Care Provider", "Investigator", "Outcomes Assessor"],
          errorMessage: "Masking has not been entered"
        }
      },
      number_of_arms: {
        type: "string",
      },
      masking_description: {
        type: "string",
        maxLength: 1000
      },
      allocation: {
        type: "string",
        enum: ["N/A", "Randomized", "Non-randomized"],
        //default: "No",
      },
      enrollment_number: {
        type: "string",
      },
      enrollment_type: {
        type: "string",
        enum: ["Anticipated", "Actual"],

      },
      ...eupasProperties.study_scope,
      ...ctisProperties.study_scope
    }
  },
  observational: {
    type: "object",
    properties: {
      patient_registry: {
        type: "array",
        uniqueItems: true,
        items: {
          type: "string",
          enum: ["Patient Registry"],
        }
      },
      observational_study_model: {
        type: "string",
        enum: ["Cohort", "Case-Control", "Case-Only", "Case-Crossover", "Ecologic or Community", "Family-Based", "Other"],
      },
      time_perspective: {
        type: "string",
        enum: ["Retrospective", "Prospective", "Cross-Sectional", "Other"],
      },
      biospecimen: {
        type: "object",
        properties: {
          biospeciment_retention: {
            type: "string",
            enum: ["None Retained", "Samples with DNA", "Samples without DNA"],
          }
        },
        dependencies: {
          biospeciment_retention: {
            oneOf: [{
              properties: {
                biospeciment_retention: {
                  enum: ["Samples with DNA", "Samples without DNA"]
                },
                biospecimen_description: {
                  type: "string",
                },
              }
            }]
          }
        }
      },
      enrollment_number: {
        type: "string"
      },
      enrollment_type_obs: {
        title: "Enrollment Type",
        type: "string",
        enum: ["Actual", "Anticipated"],
        placeholder: "Select Enrollment Type"
      },
      number_of_cohorts: {
        type: "string",
      },
      ...eupasProperties.study_scope,
      ...ctisProperties.study_scope
    },
    dependencies: {
      patient_registry: {
        oneOf: [{
          properties: {
            patient_registry: {
              items: {
                enum: ["Patient Registry"]
              }
            },
            follow_up_duration: {
              type: "string",
            },
            duration_type: {
              enum: ["Years", "Months", "Weeks", "Days"]
            },
            additional_patient_registry_data: {
              type: "string"
            }
          }
        }]
      }
    }
  },
  expanded_access: {
    type: "object",
    properties: {
      selection: {
        type: "array",
        uniqueItems: true,
        items: {
          type: "string",
          enum: ["Individual Patients", "Intermediate-size Population", "Treatment IND/Protocol"],
          errorMessage: "Results may not be specified for Expanded Access records."
        }
      },
      enrollment_number: {
        type: "string"
      },
      ...eupasProperties.study_scope,
      ...ctisProperties.study_scope
    }
  }
}

export const study_arms = {
  interventional: {
    title: "Arms and Interventions",
    type: "object",
    properties: {
      arms: {
        title: "Arms",
        type: "string",
        addButtonTitle: "Add Arm",
        properties: {
          title: {
            title: "Arm Title",
            type: "textarea",
            tooltip: "Formerly Arm Label. Brief, descriptive label to be used as row or column heading in tables."
          },
          type: {
            title: "Arm Type",
            type: "dropdown",
            enum: ["Experimental", "Active Comparator", "Placebo Comparator", "Sham Comparator", "No Intervention", "Other"]
          },
          description: {
            title: "Arm Description",
            type: "textarea",
            tooltip: "Describe the intervention(s) to be administered. For drugs use generic name and include dosage form, dosage, frequency and duration."
          }
        }
      },
      interventions: {
        title: "Interventions",
        type: "string",
        addButtonTitle: "Add Intervention",
        properties: {
          type: {
            title: "Intervention Type",
            type: "dropdown",
            enum: ["Drug", "Device", "Biological/Vaccine", "Procedure/Surgery", "Radiation", "Behavioral", "Genetic", "Dietary Supplement", "Combination Product", "Diagnostic Test", "Other"]
          },
          name: {
            title: "Intervention Name",
            type: "textarea",
            tooltip: "For a drug, use generic name if established. Use the same name as in the associated Arm/Group Description(s)."
          },
          other_intervention_names: {
            title: "Other Intervention Names",
            type: "multiselect",
            mode: "tags",
            tooltip: "Include brand names, serial numbers and code names to improve search results on the ClinicalTrials.gov web site.",
          },
          description: {
            title: "Intervention Description",
            type: "textarea",
            tooltip: "Do not repeat information already included in arm/group descriptions.",
          }
        }
      },
      crossref: {
        type: "string",
        title: "Cross Reference",
      },
      ...eupasProperties.study_arms,
      ...ctisProperties.study_arms
    },
    dependencies: {
      ...eupasDependencies.study_arms,
      ...ctisDependencies.study_arms,
    }
  },
  observational: {
    title: "Arms and Interventions",
    type: "object",
    properties: {
      arms: {
        title: "Groups/Cohorts",
        type: "string",
        addButtonTitle: "Add Group",
        properties: {
          title: {
            title: "Group/Cohort Label",
            type: "string"
          },
          description: {
            title: "Group/Cohort Description",
            type: "textarea",
          }
        }
      },
      interventions: {
        title: "Interventions",
        type: "string",
        addButtonTitle: "Add Intervention",
        properties: {
          type: {
            title: "Intervention Type",
            type: "dropdown",
            enum: ["Drug", "Device", "Biological/Vaccine", "Procedure/Surgery", "Radiation", "Behavioral", "Genetic", "Dietary Supplement", "Combination Product", "Diagnostic Test", "Other"]
          },
          name: {
            title: "Intervention Name",
            type: "string"
          },
          other_intervention_names: {
            title: "Other Intervention Names",
            type: "array",
            addButtonTitle: "Add Other Intervention Name",
            properties: {
              other_intervention_name: {
                type: "string"
              }
            }
          },
          description: {
            title: "Intervention Description",
            type: "textarea",
          }
        }
      },
      crossref: {
        type: "string",
        title: "Cross Reference",
      },
      ...eupasProperties.study_arms,
      ...ctisProperties.study_arms,
    },
    dependencies: {
      ...eupasDependencies.study_arms,
      ...ctisDependencies.study_arms,
    }
  },
  expanded_access: {
    title: "Arms and Interventions",
    type: "object",
    properties: {
      interventions: {
        title: "Interventions",
        type: "string",
        addButtonTitle: "Add Intervention",
        properties: {
          type: {
            title: "Intervention Type",
            type: "dropdown",
            enum: ["Drug", "Device", "Biological/Vaccine", "Procedure/Surgery", "Radiation", "Behavioral", "Genetic", "Dietary Supplement", "Combination Product", "Diagnostic Test", "Other"]
          },
          name: {
            title: "Intervention Name",
            type: "string"
          },
          other_intervention_names: {
            title: "Other Intervention Names",
            type: "array",
            addButtonTitle: "Add Other Intervention Name",
            properties: {
              other_intervention_name: {
                type: "string"
              }
            }
          },
          description: {
            title: "Intervention Description",
            type: "textarea",
          }
        }
      },
      ...eupasProperties.study_arms,
    },
    dependencies: {
      ...eupasDependencies.study_arms,
    }
  }
}

export const study_eligibilty = {
  interventional: {
    title: "Eligibility",
    type: "object",
    properties: {
      sex: {
        type: "string",
        enum: ["Unselected", "All", "Female", "Male"]
      },
      gender_based: {
        title: "Gender Based:",
        type: "object",
        properties: {
          gender_based_1: {
            enum: ["Unselected", "Yes", "No"]
          }
        },
        dependencies: {
          gender_based_1: {
            oneOf: [{
              properties: {
                gender_based_1: {
                  enum: ["Yes"]
                },
                gender_eligibility_description: {
                  type: "string"
                },
              },
            },
            {
              properties: {
                gender_based_1: {
                  enum: ["No"]
                },
              },
            },
            ],
          },
        },
      },
      age_limits: {
        title: "Age Limits:",
        type: "object",
        properties: {
          minimum: {
            type: "object",
            properties: {
              minimum_age_type: {
                type: "string",
                enum: ["--Select--", "Years", "Months", "Weeks", "Days", "Hours", "Minutes", "N/A(No limit)"]
              },
              minimum_age_value: {
                type: "string",
              },
            },
            // dependencies: {
            //   minimum_age_type: {
            //     oneOf: [{
            //       properties: {
            //         minimum_age_type: {
            //           enum: ["Years", "Months", "Weeks", "Days", "Hours", "Minutes"]
            //         },
            //         minimum_age_value: {
            //           type: "string",
            //         },
            //       },
            //     }, ],
            //   },
            // }
          },
          maximum: {
            type: "object",
            properties: {
              maximum_age_type: {
                type: "string",
                enum: ["--Select--", "Years", "Months", "Weeks", "Days", "Hours", "Minutes", "N/A(No limit)"],
              },
              maximum_age_value: {
                type: "string",
              },
            },
            // dependencies: {
            //   maximum_age_type: {
            //     oneOf: [{
            //       properties: {
            //         maximum_age_type: {
            //           enum: ["Years", "Months", "Weeks", "Days", "Hours", "Minutes"]
            //         },
            //         maximum_age_value: {
            //           type: "string",
            //         },
            //       },
            //     }, ],
            //   },
            // },
          },
        },
      },
      accept_healthy_volunteers: {
        type: "string",
        enum: ["Unselected", "Yes", "No"]
      },
      is_clinical_study: {
        type: "string",
        enum: ["Yes", "No", "NA"]
      },
      eligibility_criteria: {
        type: "string"
      },
      ...eupasProperties.study_eligibilty,
      ...ctisProperties.study_eligibilty
    },
    dependencies: {
      ...eupasDependencies.study_eligibilty
    }
  },
  observational: {
    title: "Eligibility",
    type: "object",
    properties: {
      sex: {
        type: "string",
        enum: ["All", "Female", "Male"]
      },
      gender_based: {
        title: "Gender Based:",
        type: "object",
        properties: {
          gender_based_1: {
            enum: ["Yes", "No"]
          }
        },
        dependencies: {
          gender_based_1: {
            oneOf: [{
              properties: {
                gender_based_1: {
                  enum: ["Yes"]
                },
                gender_eligibility_description: {
                  type: "string"
                },
              },
            },],
          },
        },
      },
      age_limits: {
        title: "Age Limits:",
        type: "object",
        properties: {
          minimum: {
            type: "object",
            properties: {
              minimum_age_type: {
                type: "string",
                enum: ["--Select--", "Years", "Months", "Weeks", "Days", "Hours", "Minutes", "N/A(No limit)"]
              },
              minimum_age_value: {
                type: "string",
              },
            },
            // dependencies: {
            //   minimum_age_type: {
            //     oneOf: [{
            //       properties: {
            //         minimum_age_type: {
            //           enum: ["--Select--", "Years", "Months", "Weeks", "Days", "Hours", "Minutes"]
            //         },
            //         minimum_age_value: {
            //           type: "string",
            //         },
            //       },
            //     }, ],
            //   },
            // }
          },
          maximum: {
            type: "object",
            properties: {
              maximum_age_type: {
                type: "string",
                enum: ["--Select--", "Years", "Months", "Weeks", "Days", "Hours", "Minutes", "N/A(No limit)"],
              },
              maximum_age_value: {
                type: "string",
              },
            },
            // dependencies: {
            //   maximum_age_type: {
            //     oneOf: [{
            //       properties: {
            //         maximum_age_type: {
            //           enum: ["--Select--", "Years", "Months", "Weeks", "Days", "Hours", "Minutes"]
            //         },
            //         maximum_age_value: {
            //           type: "string",
            //         },
            //       },
            //     }, ],
            //   },
            // },
          },
        },
      },
      accept_healthy_volunteers: {
        type: "string",
        enum: ["Yes", "No"]
      },
      eligibility_criteria: {
        type: "string"
      },
      study_population_description: {
        type: "string"
      },
      sampling_method: {
        type: "string",
        enum: ["Probability Sample", "Non-Probability Sample"]
      },
      ...eupasProperties.study_eligibilty,
      ...ctisProperties.study_eligibilty
    },
    dependencies: {
      ...eupasDependencies.study_eligibilty
    }
  },
  expanded_access: {
    title: "Eligibility",
    type: "object",
    properties: {
      sex: {
        type: "string",
        enum: ["All", "Female", "Male"]
      },
      gender_based: {
        title: "Gender Based:",
        type: "object",
        properties: {
          gender_based_1: {
            enum: ["Yes", "No"]
          }
        },
        dependencies: {
          gender_based_1: {
            oneOf: [{
              properties: {
                gender_based_1: {
                  enum: ["Yes"]
                },
                gender_eligibility_description: {
                  type: "string"
                },
              },
            }],
          },
        },
      },
      age_limits: {
        title: "Age Limits:",
        type: "object",
        properties: {
          minimum: {
            type: "object",
            properties: {
              minimum_age_type: {
                type: "string",
                enum: ["--Select--", "Years", "Months", "Weeks", "Days", "Hours", "Minutes", "N/A(No limit)"]
              },
              minimum_age_value: {
                type: "string",
              },
            },
            // dependencies: {
            //   minimum_age_type: {
            //     oneOf: [{
            //       properties: {
            //         minimum_age_type: {
            //           enum: ["--Select--", "Years", "Months", "Weeks", "Days", "Hours", "Minutes"]
            //         },
            //         minimum_age_value: {
            //           type: "string",
            //         },
            //       },
            //     }, ],
            //   },
            // }
          },
          maximum: {
            type: "object",
            properties: {
              maximum_age_type: {
                type: "string",
                enum: ["--Select--", "Years", "Months", "Weeks", "Days", "Hours", "Minutes", "N/A(No limit)"],
              },
              maximum_age_value: {
                type: "string",
              },
            },
            // dependencies: {
            //   maximum_age_type: {
            //     oneOf: [{
            //       properties: {
            //         maximum_age_type: {
            //           enum: ["--Select--", "Years", "Months", "Weeks", "Days", "Hours", "Minutes"]
            //         },
            //         maximum_age_value: {
            //           type: "string",
            //         },
            //       },
            //     }, ],
            //   },
            // },
          },
        },
      },
      eligibility_criteria: {
        type: "string"
      },
      ...eupasProperties.study_eligibilty,
      ...ctisProperties.study_eligibilty
    },
    dependencies: {
      ...eupasDependencies.study_eligibilty
    }
  }
}

export const study_ipd = {
  interventional_observational: {
    title: "IPD Sharing Statements",
    type: "object",
    properties: {
      plan_to_share_IPD: {
        type: "string",
        enum: ["Yes", "No", "Undecided"]
      },
    },
    dependencies: {
      plan_to_share_IPD: {
        oneOf: [{
          properties: {
            plan_to_share_IPD: {
              enum: ["Yes"]
            },
            plan_description: {
              type: "string",
              maxLength: 1000,
            },
            ipd_sharing_info: {
              type: "array",
              uniqueItems: true,
              items: {
                type: "string",
                enum: ["Study Protocol", "Statistical Analysis Plan (SAP)", "Informed Consent Form (ICF)",
                  "Clinical Study Report (CSR)", "Analytic Code"
                ],
              }
            },
            time_frame: {
              type: "string",
              maxLength: 1000,
            },
            url_text: {
              type: "string",
              maxLength: 3999,
            },
            access_criteria: {
              type: "string",
              maxLength: 1000,
            },
          }
        },
        {
          properties: {
            plan_to_share_IPD: {
              enum: ["No"]
            },
            plan_description: {
              title: "IPD Sharing Plan Description",
              type: "string",
              maxLength: 1000,
              errorMessage: "IPD Description must have no more than 1000 characters"
            },
          }

        },
        {
          properties: {
            plan_to_share_IPD: {
              enum: ["Undecided"]
            },
            plan_description: {
              title: "IPD Sharing Plan Description",
              type: "string",
              maxLength: 1000,
              errorMessage: "IPD Description must have no more than 1000 characters"
            },
          }
        }
        ]
      }
    },
  },
}

export const study_reference = {
  interventional: {
    title: "Citations, References and URLs",
    type: "object",
    properties: {
      citations: {
        type: "array",
        items: {
          type: "object",
          onAddButtonTitle: "Add Citation",
          properties: {
            pub_med_id: {
              type: "string",
              maxLength: 8,
            },
            result_reference: {
              type: "string",
              enum: ["Unselected", "Yes", "No"]
            },
            citation_text: {
              type: "string",
              maxLength: 2000,
            },
          }
        }
      },
      links: {
        type: "array",
        items: {
          type: "object",
          onAddButtonTitle: "Add Link",
          properties: {
            url_text: {
              type: "string",
              maxLength: 3999,
            },
            description_text: {
              type: "string",
              maxLength: 254,
            },
          }
        }
      },
      available: {
        type: "array",
        items: {
          type: "object",
          onAddButtonTitle: "Add Data/Information",
          properties: {
            data_info: {
              type: "string",
              enum: ["--Select--", "Individual Participant Data Set", "Study Protocol", "Statistical Analysis Plan",
                "Informed Consent Form", "Clinical Study Report", "Analytic Code", "Other"
              ]
            },
            url_text: {
              type: "string",
              maxLength: 3999,
            },
            identifier: {
              type: "string",
              maxLength: 30,
            },
            comments: {
              type: "string",
              maxLength: 2000
            },
          },
          dependencies: {
            data_info: {
              oneOf: [{
                properties: {
                  data_info: {
                    enum: ["Other"]
                  },
                  description: {
                    type: "string",
                  }
                }
              }]
            }
          }
        }
      },
      ...eupasProperties.study_reference,
      ...ctisProperties.study_reference,
    },
  },
  observational: {
    title: "Citations, References and URLs",
    type: "object",
    properties: {
      citations: {
        type: "array",
        items: {
          type: "object",
          onAddButtonTitle: "Add Citation",
          properties: {
            pub_med_id: {
              type: "string",
              maxLength: 8,
            },
            result_reference: {
              type: "string",
              enum: ["Yes", "No"]
            },
            citation_text: {
              type: "string",
              maxLength: 2000,
            },
          }
        }
      },
      links: {
        type: "array",
        items: {
          type: "object",
          onAddButtonTitle: "Add Link",
          properties: {
            url_text: {
              type: "string",
              maxLength: 3999,
            },
            description_text: {
              type: "string",
              maxLength: 254,
            },
          }
        }
      },
      available: {
        type: "array",
        items: {
          type: "object",
          onAddButtonTitle: "Add Data/Information",
          properties: {
            data_info: {
              type: "string",
              enum: ["Individual Participant Data Set", "Study Protocol", "Statistical Analysis Plan",
                "Informed Consent Form", "Clinical Study Report", "Analytic Code", "Other"
              ]
            },
            url_text: {
              type: "string",
              maxLength: 3999,
            },
            identifier: {
              type: "string",
              maxLength: 30,
            },
            comments: {
              type: "string",
              maxLength: 2000
            },
          },
          dependencies: {
            data_info: {
              oneOf: [{
                properties: {
                  data_info: {
                    enum: ["Other"]
                  },
                  description: {
                    type: "string",
                  }
                }
              }]
            }
          }
        }
      },
      ...eupasProperties.study_reference,
    },
  },
  expanded_access: {
    title: "Citations, References and URLs",
    type: "object",
    properties: {
      citations: {
        type: "array",
        items: {
          type: "object",
          onAddButtonTitle: "Add Citation",
          properties: {
            pub_med_id: {
              type: "string",
              maxLength: 8,
            },
            result_reference: {
              type: "string",
              enum: ["Yes", "No"]
            },
            citation_text: {
              type: "string",
              maxLength: 2000,
            },
          }
        }
      },
      links: {
        type: "array",
        items: {
          type: "object",
          onAddButtonTitle: "Add Link",
          properties: {
            url_text: {
              type: "string",
              maxLength: 3999,
            },
            description_text: {
              type: "string",
              maxLength: 254,
            },
          }
        }
      },
      available: {
        type: "array",
        items: {
          type: "object",
          onAddButtonTitle: "Add Data/Information",
          properties: {
            data_info: {
              type: "string",
              enum: ["Individual Participant Data Set", "Study Protocol", "Statistical Analysis Plan",
                "Informed Consent Form", "Clinical Study Report", "Analytic Code", "Other"
              ]
            },
            url_text: {
              type: "string",
              maxLength: 3999,
            },
            identifier: {
              type: "string",
              maxLength: 30,
            },
            comments: {
              type: "string",
              maxLength: 2000
            },
          },
          dependencies: {
            data_info: {
              oneOf: [{
                properties: {
                  data_info: {
                    enum: ["Other"]
                  },
                  description: {
                    type: "string",
                  }
                }
              }]
            }
          }
        }
      },
      ...eupasProperties.study_reference,
    },
  }
}

export const ctis_form = {
  type: "object",
  variant: "parent",
  properties: {
    ...ctisProperties.ctis_form,
  },
}

export const member_states_concerned = {
  type: "object",
  variant: "parent",
  properties: {
    member_states_concerned: ctisProperties.member_states_concerned,
  },
}
