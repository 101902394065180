import { useState, useMemo, useRef, useEffect } from "react"
import { useParams } from "react-router-dom"
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import dayjs from 'dayjs'

// components
import MenuPopover from "components/MenuPopover/MenuPopover"
import AssessmentsGrid from "./AssessmentsGrid/AssessmentsGrid"
import CreateActivityModal from "./CreateActivityModal/CreateActivityModal"
import { Button } from 'components-design-system'
import { Tooltip } from "antd"

// context
import useAppState from "context/hooks/useAppState";

// constants
import { resultsFiltersMap, resultsFilterLabels } from "../../../../../constants/assessmentResults"

// apis
import { useUserPermissions, useUserSettings } from "api/hooks"
import { useGetStudy } from "api/hooks/studies/useStudiesApi"
import {
  useGetStudyAssessments,
  usePostRunAllAssessments,
} from "api/hooks/studies/useAssessmentsApi"

// styles
import './Assessments.scss'

const Assessments = ({
  // TODO: props or internal state?
  setMissingFields = null,
}) => {
  const { studyId } = useParams()
  const [showDeactivateTrees, setShowDeactivatedTrees] = useStateWithCallbackLazy(false)
  const { data: assessments, refetch: refetchAssessments } = useGetStudyAssessments(studyId, showDeactivateTrees)
  const postRunAllAssessments = usePostRunAllAssessments(studyId)

  const {
    pollingTaskId,
    setPollingTaskId,
    setTaskType,
    taskType,
    setPollingCallback
  } = useAppState()

  const toggleRefetchAssessments = () => {
    setShowDeactivatedTrees((bool) => !bool, () => {
      refetchAssessments()
    })
  }

  // const { data: activitiesResp } = useGetStudyActivities(studyId)
  const { data: userSettings } = useUserSettings()
  const { data: permissions } = useUserPermissions()
  const { isLoading: isStudyLoading, data: study } = useGetStudy(studyId)

  const dateFormat = userSettings?.date_format

  // resultFilters used for active filter button states and to calculate adding/subtracting from current state
  const [resultFilters, setResultFilters] = useState(null)
  const [currTab, setCurrTab] = useState('table')

  const [showCreateActivityModal, setShowCreateActivityModal] = useState(false)
  const [selectedAssessment, setSelectedAssessment] = useState(null)

  const gridRef = useRef()

  useEffect(() => {
    if(!pollingTaskId) {
      refetchAssessments()
    }
  }, [pollingTaskId])

  // utility to find all filler values for a key
  const getResultValuesByKey = (key) => {
    // find unique values from assessments
    const allDataResults = [...assessments].map((dataItem) => dataItem.result)
    const uniqueDataResults = [...new Set(allDataResults)]

    let keyValues = []
    // all values in data
    if (key === 'all') {
      keyValues = uniqueDataResults
    }
    // values in data associated to key
    else {
      keyValues = Object.keys(resultsFiltersMap).filter((mapVal) => key === resultsFiltersMap[mapVal] && uniqueDataResults.find((dataVal) => dataVal === mapVal)) || []
    }
    return keyValues
  }

  const isFilterActive = (key, filterValues) => {
    if (filterValues === null) return true;

    // find all values in resultsFiltersMap for a key
    const keyValues = getResultValuesByKey(key)

    // if key value not in data
    if (keyValues?.length === 0) return false;

    // check if all values for key are in current filter
    const everyKeyInFilters = keyValues.every((keyVal) => {
      if (filterValues) return filterValues.includes(keyVal);
      return true
    })

    if (everyKeyInFilters) return true
    return false
  }

  const filterByResult = (key) => {
    const gridApi = gridRef.current.api

    // find all values in resultsFiltersMap for a key
    const keyValues = getResultValuesByKey(key)

    // check if all values for key are in current filter
    const everyKeyInFilters = keyValues.every((keyVal) => {
      if (resultFilters) return resultFilters.includes(keyVal);
      return true
    })

    // add or subtract from current filter
    // null means all rows showing; [] means no rows showing
    let newFilter = []
    if (key === 'all') {
      // remove all rows
      if (!resultFilters || everyKeyInFilters) {
        newFilter = []
      }
      // add all rows
      else {
        // also if resultFilters is []
        newFilter = null
      }
    } else {
      let filterState = resultFilters

      // when resultFilters null, filtered to all items
      if (!resultFilters) {
        // find unique values from assessments
        filterState = getResultValuesByKey('all')
      }

      // remove key items from filter
      if (everyKeyInFilters) {
        newFilter = filterState.filter((filter) => !keyValues.includes(filter))
      }
      // add key item(s) to filter
      else {
        newFilter = [...new Set([...filterState, ...keyValues])]
      }
    }

    // update grid and save new resultsFilter
    const resultsFilterInstance = gridApi.getFilterInstance('result')
    // const filterModel = resultsFilterInstance.getModel()
    resultsFilterInstance.setModel({
      filterType: 'set',
      values: newFilter,
    })
    setResultFilters(newFilter)
    gridApi.onFilterChanged()
  }

  const renderFilterButtons = useMemo(() => {
    const getCount = (filterKey) => {
      if (filterKey === 'all') return assessments.length;
      let count = 0
      assessments.forEach((assess) => {
        if (filterKey === resultsFiltersMap[assess.result]) {
          count++
        }
      })
      return count
    }

    if (assessments?.length > 0) {
      return Object.keys(resultsFilterLabels).map((filterKey) => {
        const label = resultsFilterLabels[filterKey]
        const count = getCount(filterKey)
        // if no key value in data, not active:
        const activeState = count === 0 ? false : isFilterActive(filterKey, resultFilters)
        return <Tooltip title={`${count} assessments`} key={filterKey}>
          <div
            className={`filter-checkbox-group${count === 0 ? ' disabled' : ''}`}
            onClick={count === 0 ? null : () => filterByResult(filterKey)}
          >
            <span className={`filter-checkbox${filterKey ? ' ' + filterKey : ''}${activeState ? ' active' : ''}`}>&nbsp;</span>
            <span className="filter-label">{label}</span>
          </div>
        </Tooltip>
      })
    }
    return null
  }, [assessments, resultFilters])

  const toggleCreateActivityModal = (assessment) => {
    if (assessment) {
      setSelectedAssessment(assessment)
      setShowCreateActivityModal(true)
    } else {
      setSelectedAssessment(null)
      setShowCreateActivityModal(false)
    }
  }

  const onCreatedActivities = () => {
    setShowCreateActivityModal(false)
  }

  // when changing filters from grid, update filter button states
  const onFilterChanged = ({
    api,
  }) => {
    const filterInstance = api.getFilterInstance('result')
    const filterValues = filterInstance.getModel()?.values || null
    setResultFilters(filterValues)
  }

  const exportGrid = () => {
    gridRef.current.api.exportDataAsCsv({
      fileName: `prime-${study.study_id}-assessments-${dayjs().format(dateFormat)}`
    })
  }

  return (
    <div className="assessments-page">
      <div className="assessments-page-wrapper">
        <div className="header-container">
          <div className="header-left">
            <div className="header-left-top">
              <div className="header-tabs">
                {/* TODO: add tabs when add map */}
                {/* <Tabs
                  tabs={[
                    {
                      id: 'table',
                      title: <span className="tab-item">
                        <i className="fas fa-table" /> Table
                      </span>,
                      action: (tab) => setCurrTab(tab.id)
                    },
                    {
                      id: 'map',
                      title: <span className="tab-item">
                        <i className="fal fa-globe-americas" /> Map
                      </span>,
                      action: (tab) => setCurrTab(tab.id)
                    },
                  ]}
                  active={currTab}
                /> */}
              </div>
              <div className="filters-actions-wrap">
                <div className="filters">{renderFilterButtons}
                </div>
                {permissions &&
                  permissions["assessment.edit"] === true && (
                    <Button
                      variant="primary"
                      size="sm"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                      onClick={() => postRunAllAssessments({}, {
                        onSuccess: resp => {
                          setPollingTaskId(resp?.data?.task_id)
                          setTaskType("study-assessments-run-all-assessments")
                          setPollingCallback((pollingData) => {
                            refetchAssessments()
                          })
                        }
                      })}
                      loading={taskType === "study-assessments-run-all-assessments"}
                    >
                      {taskType === "study-assessments-run-all-assessments" ? `Running assessments` : 'Run all assessments'}
                    </Button>
                  )}
              </div>
            </div>
          </div>
          <div className="header-right">
            <MenuPopover
              title="ACTIONS"
              items={[
                {
                  icon: 'export',
                  text: 'Export',
                  action: () => exportGrid(),
                },
                {
                  icon: `${showDeactivateTrees ? 'hide' : 'show'}`,
                  text: `${showDeactivateTrees ? 'Hide' : 'Show'} deactivated assessments`,
                  action: () => toggleRefetchAssessments(),
                },
              ]}
              // overlayClassName="menu-popover"
              width={144}
            />
          </div>
        </div>
        {currTab === 'table' &&
          <AssessmentsGrid
            gridRef={gridRef}
            rowData={assessments}
            onFilterChanged={onFilterChanged}
            lockPosition={false}
            permissions={permissions}
            toggleCreateActivityModal={toggleCreateActivityModal}
            setMissingFields={setMissingFields}
          />
        }
      </div>
      {permissions &&
        permissions["project.create"] === true &&
        <CreateActivityModal
          open={showCreateActivityModal}
          onCancel={() => setShowCreateActivityModal(false)}
          assessment={selectedAssessment}
          assessments={assessments}
          onCreated={onCreatedActivities}
        />
      }
    </div>
  )
}

export default Assessments