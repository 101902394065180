
import React, { useState, useEffect } from 'react';
import SiteStatusBadge from 'components/badges/SiteStatusBadge/SiteStatusBadge'

const SiteID = (props) => {
  const [display, setDisplay] = useState([]);

  useEffect(() => {
    if(props.data) {
      let items = [];
      const badgeStyle = {
        marginRight: 6 
      }
      if(props.data.is_new) items.push(<SiteStatusBadge key="sd-new-badge" style={badgeStyle} variant="new" tooltipText="This site was imported from a source in the latest upload and was not present in the previous upload or was manually created."/>);
      if(props.data.recruitment_status_changed) items.push(<SiteStatusBadge  key="sd-recruitment-status-badge" style={badgeStyle} variant="recruitment" tooltipText="This site recruitment status is different from the previously stored value due to data import or manual update by a user."/>);
      if(props.data.only_on_ctg) items.push(<SiteStatusBadge key="sd-no-source-badge" style={badgeStyle}  diagnolStyle={{ transform: 'rotate(33deg)', bottom: 12, width: 64, right: -4}}  variant="no-source" tooltipText="This site will be deleted on PRS if the study is uploaded, since it is not present in Site Management."/>);
      else if(props.data.only_on_source) items.push(<SiteStatusBadge  key="sd-no-ctg-badge" style={badgeStyle} variant="no-ctg" diagnolStyle={{ transform: 'rotate(33deg)', bottom: 12, width: 44, right: -4}} tooltipText="This site does not exist in PRS, but will be added if the study is uploaded to PRS."/>);
      setDisplay(items);
    }
  }, [props.data])

  return (
    <div>
      {display}
    </div>
  );
};

export default SiteID