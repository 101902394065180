import { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { cloneDeep } from 'lodash';
import { useShallow } from 'zustand/react/shallow'

// components
import Arms from './subcomponents/Arms/Arms';
import PrimeField from 'components/PrimeField/PrimeField';
import ResultsTableCreateModal from '../../components/ResultsTableCreateModal/ResultsTableCreateModal';
import Measure from './subcomponents/Measure/Measure';
import CellValidation from '../components/CellValidation/CellValidation';

// context
import useResultsTableViewStore from '../../../hooks/stores/useResultsTableViewStore';
import useAuthoringViewStore from '../../../hooks/stores/useAuthoringViewStore';

// utils
import { registrySpecificBaselineMeasureId } from './helpers/helpers';
import { customSort } from 'utilities/misc';

const RJBaselineChars = (props) => {
  const {
    schema,
    baselineData,
    setBaselineData,
  } = props;

  const {
    formView,
    readOnly,
    formStatus
  } = useAuthoringViewStore(
    useShallow(state => ({
      readOnly: state.readOnly,
      formStatus: state.formStatus,
      formView: state.formView
    }))
  )


  const {
    tableView,
  } = useResultsTableViewStore()

  const [selectedBaselineMeasure, setSelectedBaselineMeasure] = useState(null);
  const [baselineMeasureItems, setBaselineMeasureItems] = useState([]);
  const [showBaselineMeasureModal, setShowBaselineMeasureModal] = useState(false);


  useEffect(() => {
    if (tableView) {
      if (schema?.properties?.[tableView]?.properties?.baseline_measure?.anyOf) {
        let { anyOf } = schema.properties[tableView].properties.baseline_measure;
        let items = anyOf.map(item => ({ id: item.id, text: item.title }));
        setBaselineMeasureItems(items);
      }
    }
  }, [tableView])

  const createBM = (uniqid, rowId, categoryIds, template, registry, ogTemplate) => {
    let newBM = {
      id: uniqid,
      baseline_measure_id: template.id
    }
    let row = {
      id: rowId,
      title: "",
      categories: []
    }

    Object.keys(template.properties).forEach(key => {
      newBM[key] = template.properties[key].value;
    })

    if (ogTemplate.id.includes("ctg") && registry === "eudract") {
      if (schema.transformations.ctgToEudract[ogTemplate.title]) {
        newBM.title = schema.transformations.ctgToEudract[ogTemplate.title]
      }
    }

    let catIndex = 0;

    template.rows.categories.forEach((cat) => {
      let values = [];
      let categoryProps = {};
      Object.keys(cat.properties).forEach(key => categoryProps[key] = null);

      if (baselineData[registry]?.arms) {
        values = baselineData[registry]?.arms.map(arm => {
          if (cat.id === "num-analyzed") {
            let value = arm.baseline_analysis_population;
            if (newBM.baseline_measure_id === "not-collected") value = 0;

            return {
              arm_id: arm.id,
              value,
              num_units_analyzed: arm?.num_units_analyzed
            }
          }
          return {
            arm_id: arm.id,
            ...categoryProps,
          }
        })
      }

      if (registry === "eudract") {
        let sasValues = [];
        if (baselineData[registry]?.subject_analysis_sets) {
          sasValues = baselineData[registry].subject_analysis_sets.map(arm => {
            return {
              arm_id: arm.id,
              ...categoryProps,
            }
          })
        }
        values = [...values, ...sasValues]
      }

      if (registry === "eudract" && baselineData[registry].baseline_analysis_population) {
        let additionalValues = baselineData[registry].baseline_analysis_population.map(set => {
          return {
            arm_id: set.id,
            ...categoryProps
          }
        })
        values.push(...additionalValues)
      }

      if (cat.id !== "num-analyzed") {
        catIndex++
      }

      let category = {
        id: cat.id === "num-analyzed" ? "num-analyzed" : categoryIds[catIndex],
        title: cat.title,
        values
      }

      row.categories.push(category);
    })

    newBM.rows = [row];

    return newBM;
  }

  const addBaselineMeasure = (applyAll) => {
    let tempData = { ...baselineData }
    let bmUniquid = `bm-${uuid()}`;
    let rowId = `row-${uuid()}`;
    let categoryIds = []

    for (let i = 0; i < 15; i++) {
      let cid = `Category-${uuid()}`;
      categoryIds.push(cid)
    }
    let ogTemplate = cloneDeep(schema.properties[tableView].properties.baseline_measure.anyOf.find(item => item?.id === selectedBaselineMeasure.id));

    Object.keys(schema.properties).forEach(registry => {
      if (applyAll || registry === tableView) {
        let registryTemplate = { ...ogTemplate };
        let registrySchema = cloneDeep(schema.properties[registry].properties);

        if (registry !== tableView && ogTemplate.id !== "not-collected") {
          registryTemplate.id = registrySpecificBaselineMeasureId[tableView][registry][ogTemplate.id];
          registryTemplate = cloneDeep(registrySchema.baseline_measure.anyOf.find(item => item?.id === registryTemplate.id));
          if (ogTemplate.properties.parameter_type && registryTemplate.properties.parameter_type) registryTemplate.properties.parameter_type.value = ogTemplate.properties.parameter_type.value;
          if (ogTemplate.properties.dispersion_type && registryTemplate.properties.dispersion_type) registryTemplate.properties.dispersion_type.value = ogTemplate.properties.dispersion_type.value;

          let templateCategories = cloneDeep(ogTemplate.rows.categories)

          registryTemplate.rows.categories = templateCategories
            .filter(cat => cat.unique !== true)
            .map(cat => cat)
        }

        if ((ogTemplate.id === "not-collected" && tableView === registry)
          || ogTemplate.id !== "not-collected") {
          let newBM = createBM(bmUniquid, rowId, categoryIds, registryTemplate, registry, ogTemplate);

          let baselineMeasures = [];
          if (baselineData[registry]?.baseline_measures) {
            baselineMeasures = [...baselineData[registry].baseline_measures, newBM]
          }

          tempData[registry] = {
            ...baselineData[registry],
            baseline_measures: baselineMeasures
          }

          if (registry === "ctg") {
            orderCTGBaselineMeasures(tempData, registry)
          }
        }
      }
    })

    setBaselineData(tempData);
    setSelectedBaselineMeasure(null);
  }

  const onOpenCreateBaselineMeasure = (selectedItem) => {
    let foundItem = baselineMeasureItems.find(item => item.id === selectedItem)
    setSelectedBaselineMeasure(foundItem);
    setShowBaselineMeasureModal(true);
  }

  const orderCTGBaselineMeasures = (tempData, registry) => {
    const sortBy = ['Age, Continuous', 'Age, Categorical', 'Age, Customized', 'Sex: Female, Male', 'Sex/Gender, Customized', 'Race (NIH/OMB)', 'Ethnicity (NIH/OMB)', 'Race/Ethnicity, Customized', 'Race and Ethnicity Not Collected', 'Region of Enrollment', '', null, undefined]
    let orderedBaselineMeasures = customSort({
      data: tempData[registry].baseline_measures,
      sortBy,
      sortField: "title"
    })
  }

  const displayBaselineMeasures = (baselineMeasures = []) => {
    return baselineMeasures.map((measure, i) => {

      let schemas = schema.properties[tableView].properties.baseline_measure;
      let bmSchema = schemas.anyOf.find(schema => {
        if (schema.id === measure.baseline_measure_id) {
          return true
        } else if (schema.id === "eudract-specific-categorical" && measure.baseline_measure_id === "ctg-specific-customized") {
          return true
        }
        return false;
      });

      return (
        <Measure
          key={measure.id}
          index={i}
          arms={baselineData[tableView]?.arms}
          sasArr={baselineData[tableView]?.subject_analysis_sets}
          schema={bmSchema}
          measure={measure}
          measureIndex={i}
          numOfMeasures={baselineData[tableView].baseline_measures.length}
          baselineData={baselineData}
          setBaselineData={setBaselineData} />
      )
    })
  }

  if (formStatus !== "ready" || !formView) return <></>

  return (
    <div className="results-table">
      <table>
        <tbody>
          <Arms
            arms={baselineData?.[tableView]?.arms || []}
            sasArr={baselineData?.[tableView]?.subject_analysis_sets}
            schema={schema?.properties?.[tableView]?.properties}
            baselineData={baselineData}
            setBaselineData={setBaselineData} />
          {displayBaselineMeasures(baselineData?.[tableView]?.baseline_measures)}
        </tbody>
      </table>
      {readOnly !== true && (
        <div style={{ width: 250, marginTop: 24 }}>
          <PrimeField
            schema={{
              type: "dropdown",
              placeholder: "Add Baseline Measure",
              size: "small",
              items: baselineMeasureItems
            }}
            value={null}
            onChange={e => onOpenCreateBaselineMeasure(e)} />
        </div>
      )}
      <ResultsTableCreateModal
        id="bm-add-baseline-measure"
        header="Add Baseline Measure"
        onSubmit={addBaselineMeasure}
        showModal={showBaselineMeasureModal}
        setShowModal={() => setShowBaselineMeasureModal(false)}
        registrySpecific={selectedBaselineMeasure?.id === "not-collected"} />
    </div>
  );
};

export default RJBaselineChars;