import React from "react"
import PropTypes from "prop-types"
import './StatusIndicator.scss'

const colors = {
  green: "#8AB25B",
  yellow: "#DBA51F",
  red: "#D7542C",
}

/**
 * `variant` possible values:
 * * on-time
 * * at-risk
 * * late
 */
const StatusIndicator = ({
  variant, // optional
  bgColor, // optional
  size = "med",
}) => {
  let backgroundColor = ""
  if (variant) {
    if (variant === "on-time") {
      backgroundColor = colors.green
    } else if (variant === "at-risk") {
      backgroundColor = colors.yellow
    } else if (variant === "late") {
      backgroundColor = colors.red;
    }
  } else if (bgColor) {
    backgroundColor = bgColor;
  } else {
    backgroundColor = "grey";
  }
  return <span
    style={{ backgroundColor }}
    className={`status-indicator size-${size}`}
  />
}

StatusIndicator.propTypes = {
  variant: PropTypes.oneOf([
    "on-time",
    "at-risk",
    "late",
  ]),
  bgColor: PropTypes.string,
}

export default StatusIndicator