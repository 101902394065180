import React from 'react';
import { useShallow } from 'zustand/react/shallow'

// components
import Cell from '../Cell/Cell';
import CellHeader from '../CellHeader/CellHeader';

// context
import useResultsTableViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableViewStore';
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';

const MilestoneTable = ({
  type,
  schema,
  periodIndex,
  period,
  participantData,
  setParticipantData,
}) => {
  const {
    participantRegistries,
    participantView
  } = useResultsTableViewStore()

  const {
    readOnly
  } = useAuthoringViewStore(
    useShallow(state => ({ readOnly: state.readOnly }))
  )

  const headerStyle = {
    width: 250 - 24,
    minWidth: 250 - 24
  }

  const list = period[type] || []

  const onChange = (e, row, participant) => {
    let tempData = { ...participantData };

    participantRegistries.forEach(registry => {
      let foundPeriodIndex = tempData[registry].findIndex(item => item?.id === period.id);
      if (foundPeriodIndex !== -1) {
        let foundRowIndex = tempData[registry][foundPeriodIndex][type].findIndex(item => item?.id === row.id);
        if (foundRowIndex !== -1) {
          let foundParticipantIndex = tempData[registry][foundPeriodIndex][type][foundRowIndex].participants.findIndex(item => item?.arm_id === participant.arm_id);
          if (foundParticipantIndex !== -1) {
            let participant = tempData[registry][foundPeriodIndex][type][foundRowIndex].participants[foundParticipantIndex];

            if (participant) participant.value = e
          }
        }
      }
    })

    setParticipantData(tempData)
  }

  const getRowTotal = (row) => {
    let total = 0;
    row.participants.forEach(participant => {
      let value = Number(participant?.value);

      if (typeof value === 'number' && !isNaN(value)) {
        total += value;
      }
    })

    return total
  }

  const renderNotCompletedRow = () => {
    return (
      <tr>
        <td className="header-column disabled-row" style={headerStyle}>
          Not completed
        </td>
        {list?.[list.length - 1]?.participants.map((cell, colIndex) => {

          let started = Number(list[0]?.participants[colIndex]?.value) || 0;
          let completed = Number(list[list.length - 1]?.participants[colIndex]?.value) || 0;

          if (typeof started !== 'number' && isNaN(started)) started = 0;
          if (typeof completed !== 'number' && isNaN(completed)) completed = 0;

          let diff = started - completed

          let style = {
            backgroundColor: "#f3f3f3"
          }

          if (diff < 0) {
            style = {
              backgroundColor: "#D7542C",
              color: "#fff"
            }
          }

          return (
            <td
              key={`not-completed-col-${colIndex}`}
              style={style}>
              {diff}
            </td>
          )
        })}
      </tr>
    )
  }

  const renderRemainingRow = () => {
    let milestoneList = period.milestone_list

    return (
      <tr>
        <td className="header-column disabled-row" style={headerStyle}>
          Remaining
        </td>
        {list[list.length - 1]?.participants.map((cell, colIndex) => {
          let started = Number(milestoneList[0].participants[colIndex]?.value) || 0;
          let completed = Number(milestoneList[milestoneList.length - 1].participants[colIndex]?.value) || 0;
          if (typeof started !== 'number' && isNaN(started)) started = 0;
          if (typeof completed !== 'number' && isNaN(completed)) completed = 0;

          let totalNotCompleted = 0;
          list.forEach(reason => {
            let num = Number(reason.participants[colIndex]?.value);
            if (typeof num === "number" && !isNaN(num))
              totalNotCompleted += num;
          })

          let remaining = started - completed;
          let diff = (remaining - totalNotCompleted) * -1


          let style = {
            backgroundColor: "#f3f3f3"
          }

          if (diff < 0) {
            style = {
              backgroundColor: "#D7542C",
              color: "#fff"
            }
          }

          return (
            <td
              key={`remaining-col-${colIndex}`}
              style={style}>
              {diff}
            </td>
          )

        })}
      </tr>
    )
  }

  const saveComment = (comment, row, participant) => {
    let tempData = { ...participantData };
    participantRegistries.forEach(registry => {
      let foundPeriodIndex = tempData[registry].findIndex(item => item?.id === period.id);
      if (foundPeriodIndex !== -1) {
        let foundRowIndex = tempData[registry][foundPeriodIndex][type].findIndex(item => item?.id === row.id);
        if (foundRowIndex !== -1) {
          let foundParticipantIndex = tempData[registry][foundPeriodIndex][type][foundRowIndex].participants.findIndex(item => item?.arm_id === participant.arm_id);
          if (foundParticipantIndex !== -1) {
            let participant = tempData[registry][foundPeriodIndex][type][foundRowIndex].participants[foundParticipantIndex];
            participant.comment = comment
          }
        }
      }
    })

    setParticipantData(tempData)
  }

  return (
    <>
      {period[type].map((row, rowIndex) => {
        let participants = row?.participants || []

        return (
          <tr key={`${period.id}-${type}-${rowIndex}-${row.id}`}>
            <CellHeader
              key={`${period.id}-cell-header-${rowIndex}`}
              row={row}
              period={period}
              type={type}
              rowIndex={rowIndex}
              participantData={participantData}
              setParticipantData={setParticipantData}
              hideComment={type === "drop_withdrawn_reason_list"}
              errorKey={`results_participant_flow_participant_flow_table_${participantView}_${periodIndex + 1}_${type}_${rowIndex + 1}_title`} />
            {participants.map((cell, colIndex) => {
              return (
                <Cell
                  key={`${period.id}-${periodIndex}-${type}-${row.id}-${cell?.arm_id}-${rowIndex}-${colIndex}`}
                  jpath={`pf-${period.id}-${type}-cell-${rowIndex}-${colIndex}`}
                  cell={cell}
                  row={row}
                  onChange={onChange}
                  saveComment={saveComment}
                  type={type}
                  errorKey={`results_participant_flow_participant_flow_table_${participantView}_${periodIndex + 1}_${type}_${rowIndex + 1}_participants_${colIndex + 1}_value`}
                />
              )
            })}
            <td className="disabled-row">
              {getRowTotal(row)}
            </td>
          </tr>
        )
      })}
      {type === "milestone_list" && renderNotCompletedRow()}
      {type === "drop_withdrawn_reason_list" && readOnly !== true && renderRemainingRow()}
    </>
  );
};

export default MilestoneTable;