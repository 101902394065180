// TODO: rename columentTemplates.js
import dayjs from 'dayjs'

export const dateColDef = {
  cellRenderer: "Date",
  type: "date",
  filter: 'agDateColumnFilter',
  // filterParams only called for CSRM grids
  filterParams: {
    resetButton: true,
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      const dateAsString = cellValue
      console.log('date filterParams', {
        cellValue
      })
      if (cellValue === null) return 0;
      const cellDate = dayjs(dateAsString).toDate()
      if (cellDate < filterLocalDateAtMidnight) {
        return -1
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1
      } else {
        return 0
      }
    }
  },
  flex: 1,
  minWidth: 120,
}

/**
 * Return function to convert date to "YYYY-MM-DD" format needed for CSRM AG Grid filtering and sorting.
 * 
 * Use with following columnDefs properties, which will not be called on SSRM grids), eg:
 *   valueGetter: dateFormatter('curr_load_date')
 *      -- or --
 *   filterValueGetter: dateFormatter('curr_load_date')
 * 
 * need to send field name because value not provided in valueGetter or filterValueGetter params.
 */
export const dateFormatter = (fieldName) => {
  return ({ data }) => {
    let value = data[fieldName]
    if (!value) return null
    return dayjs(value).format('YYYY-MM-DD')
  }
}

export const getRolesColDef = (roles) => {
  if(roles) {
    return roles.map(role => ({
      headerName: role?.name?.replace("_", ' ').replace('PAREN_LEFT', '(').replace('PAREN_RIGHT', ')'),
      field: role?.name?.replace(/ /g, '_').replace('(', 'PAREN_LEFT').replace(')', 'PAREN_RIGHT'),
      hide: true,
      cellRenderer: "UserFullnameCellRenderer",
      minWidth: 180,
      flex: 1,
      sortable: false,
      autofilter: {
        app: "authentication",
        model: "PactUser",
        sub_field: "fullname"
      }
    }))
  }

  return []
}

export const largeColDef = {
  filter: 'agTextColumnFilter',
  cellClass: 'cell-wrap-text',
  minWidth: 350,
  flex: 1
}