import React from 'react';
import Form from "@rjsf/core";
import { useShallow } from 'zustand/react/shallow'

// templates
import ObjectFieldTemplate from '../templates/ObjectFieldTemplate';
import ArrayFieldTemplate from '../templates/ArrayFieldTemplate';

// widgets
import Preview from '../widgets/Preview';
import SimpleTablePreview from '../widgets/SimpleTablePreview';
import ParticipantFlowPreview from '../widgets/ParticipantFlowPreview/ParticipantFlowPreview';
import BaselinePreview from '../widgets/BaselinePreview/BaselinePreview';
import OutcomePreview from '../widgets/OutcomePreview/OutcomePreview';
import AdversePreview from '../widgets/AdversePreview/AdversePreview';
import PopulationOfTrialPreview from '../widgets/PopulationOfTrialPreview/PopulationOfTrialPreview';
import AgeRangePreview from '../widgets/AgeRangePreview/AgeRangePreview';
import MscPreview from '../widgets/MscPreview/MscPreview';
import CrossReferenceWrapper from '../../../../RJS/widgets/RJCrossReferenceTable/CrossReferenceWrapper';

import useAppState from 'context/hooks/useAppState';
import useAuthoringViewStore from '../../../../hooks/stores/useAuthoringViewStore';

// styles
import 'styles/authoring-print.scss';

const RJPreviewForm = (props) => {
  const {
    schema,
    uiSchema,
    formContext,
    formData
  } = props;

  const {
    isReviewerView,
    showPanel
  } = useAuthoringViewStore(
    useShallow(state => ({ 
      isReviewerView: state.isReviewerView,
      showPanel: state.showPanel,
    }))
  )

  const {
    sidebarOpen
  } = useAppState()

  const widgets = {
    TextWidget: Preview,
    TypeUnitsAssigned: Preview,
    SelectWidget: Preview,
    TextareaWidget: Preview,
    RadioWidget: Preview,
    DateWidget: Preview,
    MultiselectWidget: Preview,
    Preview,
    SimpleTable: SimpleTablePreview,
    ParticipantFlow: ParticipantFlowPreview,
    BaselineCharacteristics: BaselinePreview,
    OutcomeMeasures: OutcomePreview,
    AdverseEvents: AdversePreview,
    MemberStatesConcerned: MscPreview,
    AgeRangePreview,
    PopulationOfTrialPreview,
    CrossReferenceTable: CrossReferenceWrapper
  };

  let classNames = ["authoring-print-page", "rjs-form"]

  if (!isReviewerView) {
    if (showPanel && sidebarOpen) {
      classNames.push('form-open-sidebar-open-panel')
    } else if (showPanel && !sidebarOpen) {
      classNames.push('form-close-sidebar-open-panel')
    } else if (!showPanel && sidebarOpen) {
      classNames.push('form-open-sidebar-close-panel')
    } else {
      classNames.push('form-close-sidebar-close-panel')
    }
  }

  return (
    <div className={classNames.join(" ")}>
      <Form
        // style={{ width: "100%" }}
        noValidate={true}
        formData={formData}
        schema={schema}
        uiSchema={uiSchema}
        widgets={widgets}
        formContext={formContext}
        ObjectFieldTemplate={ObjectFieldTemplate}
        ArrayFieldTemplate={ArrayFieldTemplate}
        onChange={() => { }}
      >
        <button
          style={{ display: "none" }}
          type="submit">
          Submit
        </button>
      </Form>
    </div>
  );
};

export default RJPreviewForm;