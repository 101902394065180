import * as React from 'react'
import { Button } from 'components-design-system'
import PropTypes from 'prop-types'
import './IconButton.scss'

const IconButton = ({
  variant = "primary-outlined",
  iconClassName,
  className,
  children,
  ...buttonProps
}) => {
  let classNames = ["icon-button"]
  if (className) classNames.push(className);

  return <Button
    variant={variant}
    size="sm"
    className={classNames.join(' ')}
    {...buttonProps}
  >
    <i className={iconClassName} />
    {children}
  </Button>
}

IconButton.propTypes = {
  variant: PropTypes.oneOf(["outlined", "dashed", "primary-dashed", "primary-outlined"]),
  iconClassName: PropTypes.string.isRequired,
}

export default IconButton