import axios from 'axios'
import getUrl from 'api/apiMap'
import useGetApi from '../useGetApi'
import useMutateApi from '../useMutateApi'

// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

// utils
import { getCookie } from 'api/apiUtils';

const useGetDocAnnotations = (documentId) => {
  return useGetApi(`${getUrl(apiEndpoints.documents)}${documentId}/annotations/`, queryKeys.documentAnnotations(documentId))
}

const usePostDocAnnotation = (documentId) => {
  return useMutateApi({
    method: 'post',
    apiEndpoint: getUrl(`${apiEndpoints.documents}${documentId}/annotations/`),
    invalidateQueries: [queryKeys.documentAnnotations(documentId)],
    successMsg: 'Successfully created annotation',
  })
}

const usePutDocAnnotation = (documentId) => {
  return useMutateApi({
    method: 'put',
    apiEndpoint: getUrl(`${apiEndpoints.documents}${documentId}/annotations/`),
    invalidateQueries: [queryKeys.documentAnnotations(documentId)],
    successMsg: 'Successfully updated annotation',
  })
}

const useDeleteDocAnnotation = (documentId) => {
  return useMutateApi({
    method: 'delete',
    apiEndpoint: getUrl(`${apiEndpoints.documents}${documentId}/annotations/`),
    invalidateQueries: [queryKeys.documentAnnotations(documentId)],
    successMsg: 'Successfully deleted annotation',
  })
}

/** NOT REACT QUERY */

const accessToken = getCookie("xopri-jwt-actoken")

// needed to manually get latest "annotations" from Adobe Embed callbacks b/c when callbacks are defined they retain an old react-query "annotations" state
const getDocAnnotations = async (documentId) => {
  return new Promise((resolve, reject) => {
    axios.get(`${getUrl(apiEndpoints.documents)}${documentId}/annotations/`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export {
  useGetDocAnnotations,
  usePostDocAnnotation,
  usePutDocAnnotation,
  useDeleteDocAnnotation,
  getDocAnnotations,
}