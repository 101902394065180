import React from 'react'
import { Tooltip } from 'antd'
import './MilestoneDateBadge.scss'

const MilestoneDateBadge = ({
  type,
  style,
  tooltip,
}) => {
  let title = ""
  if (type === "Actual" || type === "actual") {
    title = "A"
  } else if (type === "Anticipated" || type === "anticipated") {
    title = "A"
  } else if (type === "Forecast" || type === "forecast") {
    title = "F"
  } else if (type === "Baseline" || type === "baseline") {
    title = "B"
  } else if (type === "Projected" || type === "projected") {
    title = "P"
  } else if (type === "CTG" || type === "ctg") {
    title = "CTG"
  } else if (type === "Unverified" || type === "unverified") {
    title = "U"
  } else if (type === "Verified" || type === "verified") {
    title = "V"
  } else if (type === "Archived" || type === "archived") {
    title = "A"
  }

  const renderTooltip = (
    <>
      {tooltip ?
        <>
          <b style={{ fontWeight: 600 }}>
            Values Changed:
          </b> {tooltip}
        </>
        : type && type.charAt(0).toUpperCase() + type.slice(1)
      }
    </>
  );

  return (
    <Tooltip title={renderTooltip}>
      <div
        className={`badge-container ${type?.toLowerCase()}`}
        style={style}
      >
        {title}
      </div>
    </Tooltip>
  )
}

export default MilestoneDateBadge