import React from 'react';
import dayjs from "dayjs";
import { useShallow } from 'zustand/react/shallow'

// context
import useAppState from 'context/hooks/useAppState';
import useAuthoringViewStore from "containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore";
import useAuthoringDataStore from "containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringDataStore";
import useAuthoringStore from "containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringStore";

// utils
import useAuthoringValidationActions from "containers/studies/Study/subcomponents/Authoring/hooks/actions/useAuthoringValidationActions";

// apis
import {
  useGetAuthoringStudy,
  usePutAuthoringStudy,
  useGetAuthoringVersions,
} from 'api/hooks/studies/useAuthoringApi';

const useAuthoringSaveActions = () => {
  const { openToastNotification } = useAppState()

  const {
    authoringId,
    setLastSaved,
    autoSaveFreeze,
    setAutoSaveFreeze,
    setShowSaveLock,
  } = useAuthoringStore(
    useShallow(state => ({
      authoringId: state.authoringId,
      setLastSaved: state.setLastSaved,
      autoSaveFreeze: state.autoSaveFreeze,
      setAutoSaveFreeze: state.setAutoSaveFreeze,
      setShowSaveLock: state.setShowSaveLock
    }))
  )

  const {
    formData,
    forkedStudyData,
    overrideData,
    attachedDocuments,
  } = useAuthoringDataStore(
    useShallow(state => ({
      formData: state.formData,
      forkedStudyData: state.forkedStudyData,
      overrideData: state.overrideData,
      attachedDocuments: state.attachedDocuments,
    }))
  )

  const {
    isReviewerView,
    showPagePreview,
    showPanel
  } = useAuthoringViewStore(
    useShallow(state => ({
      isReviewerView: state.isReviewerView,
      showPagePreview: state.showPagePreview,
      showPanel: state.showPanel,
    }))
  )

  const { data: authoring, refetch: refetchAuthoring } = useGetAuthoringStudy(authoringId)
  const { refetch: refetchAuthoringVersions } = useGetAuthoringVersions(authoringId)
  const putAuthoringStudy = usePutAuthoringStudy(authoringId)

  const { clearValidations } = useAuthoringValidationActions()

  const handleSubmit = (options) => {
    options = { ...options } || {};
    const versionNotes = options.versionNotes || null;
    const unlockOnSave = options?.unlockOnSave;

    // When auto-saving, sending formData in options.
    const _formData = options.formData || formData;
    // const _forkedStudyData = options.forkedStudyData || forkedStudyData;

    if (authoring
      && _formData
      && isReviewerView !== true
      && autoSaveFreeze === false
      && showPagePreview !== true
    ) {
      let req = {
        study_id: authoring.study_id,
        study_data: {
          ..._formData,
          forked_study_data: forkedStudyData
        },
        attached_documents: attachedDocuments,
        override_data: options?.overrideData || overrideData
      };

      if (unlockOnSave) {
        req.is_manual_save = unlockOnSave;
        clearValidations()
      }
      setShowSaveLock(!unlockOnSave)

      // versioning
      if (options.is_major_version) req.is_major_version = true;
      else if (options.is_minor_version) req.is_minor_version = true;
      if (versionNotes) req.notes = versionNotes;

      putAuthoringStudy({
        id: `${authoring.id}/`,
        body: req
      }, {
        onSuccess: (res) => {
          // if (res?.error) console.log('ERROR', res.error);
          // if saved a new version, set new version in authoring var
          if (options.is_major_version || options.is_minor_version) {
            clearValidations()
            refetchAuthoringVersions()
            refetchAuthoring()
            if (options.is_major_version) openToastNotification("success", "Success", "Successfully saved major version")
            else if (options.is_minor_version) openToastNotification("success", "Success", "Successfully saved minor version")
          }
          setLastSaved(dayjs())
        },

        onSettled: () => {
          setTimeout(() => {
            setAutoSaveFreeze(false)
          }, 1000)
        }
      })
    }
  };

  return {
    handleSubmit
  }
};

export default useAuthoringSaveActions;