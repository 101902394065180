import { useState, useEffect } from 'react'
import './PRSUpload.scss'

// Components
import { Button } from 'components-design-system'
import PrimeField from 'components/PrimeField/PrimeField';

// api
import { useGetGlobalSiteSettings } from 'api/hooks/admin/usePrsSiteMaskingApi';

const PRSUpload = ({
  setOpenModal,
  onUploadToPRS,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isRecordVerificationDateChecked, setIsRecordVerificationDateChecked] = useState(false);
  const { data: globalSettings } = useGetGlobalSiteSettings();

  useEffect(() => {
    if (globalSettings?.release_to_ctg) {
      setIsChecked(true)
    } else {
      setIsChecked(false)
    }
  }, [globalSettings])

  return <div className="prs-upload">
      <div className="prs-upload-body">
        <PrimeField
          schema={{
            type: 'checkbox',
            label: `Release record(s) to CTG`,
            defaultChecked: false,
            onChange: setIsChecked,
          }}
          value={isChecked} />
        <PrimeField
          schema={{
            type: 'checkbox',
            label: `Update the Record Verification Date`,
            onChange: setIsRecordVerificationDateChecked
          }}
          value={isRecordVerificationDateChecked} />
      </div>
      <div className="prs-upload-actions">
        <Button
          variant="outlined"
          onClick={() => setOpenModal(false)}
          style={{ marginRight: 16 }}>
          Cancel
        </Button>
        <Button
          onClick={() => onUploadToPRS(isChecked, isRecordVerificationDateChecked)}>
          Upload
        </Button>
      </div>
    </div>
}

export default PRSUpload