import React from 'react';

// styles
import './external-box-filter.scss';

const ExternalBoxFilter = ({
  title,
  value,
  style = {},
  active = false,
  onClick
}) => {
  let classNames = ["external-box-filter-container"];
  if(active) classNames.push("active-external-box-filter");

  return (
    <div 
      className={classNames.join(" ")} 
      style={style}
      onClick={onClick}>
      <div className="title">{title}</div>
      <div className="value">{value}</div>
    </div>
  );
};

export default ExternalBoxFilter;