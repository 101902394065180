import React from 'react'
import { Tooltip } from 'antd'

// components
import RegistryBadge from '../../RegistryBadge/RegistryBadge';

const Registries = (props) => {
  let temp = [];
  let tooltipVals = [];
  if (props?.value) {
    temp = [...props.value];
    tooltipVals = [...props.value]
  }

  return (
    <div style={{
      display: "flex",
      gap: 6,
      justifyContent: "start",
      alignItems: 'center'
    }}>
      {temp?.slice(0, 2).map(registry => {
        let reg = registry;
        if (registry === "ClinicalTrials.gov") reg = "CTG"
        return (
          <RegistryBadge key={reg} registry={reg} />
        )
      })}
      {tooltipVals?.length > 2 && (
        <Tooltip
          title={tooltipVals.slice(2).map(item => {
            return <div key={item} style={{ textAlign: 'left' }}>
              {item}
            </div>
          })}
        >
          <i
            className="fas fa-plus-circle"
            style={{ color: "#878C92" }} />
        </Tooltip>
      )}
    </div>
  );
};

export default Registries;