import { v4 as uuidv4 } from 'uuid';

export const outcomeMeasuresDataSchema = {
  type: "object",
  title: "Outcome Measures",
  properties: {
    outcome_measures_table: {
      type: "string",
      properties: {
        ctg: {
          type: "object",
          properties: {
            arms: {
              type: "object",
              properties: {
                title: {
                  title: "Arm/Group Title",
                  type: "textarea",
                  required: true
                },
                description: {
                  title: "Arm/Group Description",
                  type: "textarea",
                  required: true
                },
                subjects_analyzed: {
                  title: "Overall Number of Participants Analyzed",
                  required: true,
                  type: "number",
                  value: null
                },
                num_units_analyzed: {
                  title: "Overall Number of Units Analyzed",
                  type: "number",
                  value: null
                },
              }
            },
            outcome_measure: {
              type: "object",
              properties: {
                arms: {
                  type: "array",
                  value: []
                },
                measure_type: {
                  title: "Outcome Measure Type",
                  required: true,
                  type: "dropdown",
                  value: "Primary",
                  enum: ["Primary", "Secondary", "Other pre-specified", "Post-hoc"]
                },
                title: {
                  title: "Title",
                  type: "textarea",
                  value: ""
                },
                description: {
                  title: "Description",
                  type: "textarea",
                  value: ""
                },
                time_frame: {
                  title: "Time Frame",
                  type: "textarea",
                  value: ""
                },
                anticipated_result_posted_date: {
                  title: "Anticipated Reporting Date",
                  type: "object",
                  value: {
                    month: "",
                    year: ""
                  },
                  properties: {
                    month: {
                      title: "Month",
                      placeholder: "Month",
                      type: "dropdown",
                      enum: ["Unselected", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
                    },
                    year: {
                      title: "Year",
                      placeholder: "Year",
                      type: "number",
                    }
                  }
                },
                outcome_measure_analysis: {
                  type: "array",
                  value: [],
                  properties: {
                    statistical_analysis_overview: {
                      type: "object",
                      title: "Statistical Analysis Overview",
                      properties: {
                        outcome_reporting_groups_container: {
                          title: "Comparison Group Selection",
                          type: "object",
                          required: true,
                          properties: {
                            outcome_reporting_groups: {
                              title: "Select the Outcome Measure Arms/Groups involved in the statistical analysis.",
                              type: "multiselect"
                            }
                          }
                        },
                        group_description_container: {
                          title: "Comments",
                          type: "object",
                          properties: {
                            group_description: {
                              title: "(Optional) Additional details about the statistical analysis, such as null hypothesis and description of power calculation.",
                              type: "textarea"
                            }
                          }
                        },
                        statistical_test_type_container: {
                          title: "Type of Statistical Test",
                          type: "object",
                          properties: {
                            statistical_test_type: {
                              type: "dropdown",
                              enum: ["Superiority", "Non-Inferiority", "Equivalence", "Other", "Non-Inferiority or Equivalence (legacy)", "Superiority or Other (legacy)"],
                              required: true,
                            }
                          }
                        },
                        non_inferiority_test_comment_container: {
                          type: "object",
                          title: "Comments",
                          properties: {
                            non_inferiority_test_comment: {
                              type: "textarea",
                              title: "If a non-inferiority or equivalence analysis, information on the definition of the non-inferiority or equivalence margin is required. Also describe any other key parameters and details of the power calculation (if not described elsewhere)."
                            }
                          }
                        }
                      }
                    },
                    statistical_test_of_hypothesis: {
                      type: "object",
                      title: "Statistical Test of Hypothesis",
                      properties: {
                        p_value_container: {
                          title: "P-Value",
                          type: "object",
                          properties: {
                            value_equality_relation: {
                              title: "(If Applicable, e.g < 0.01)",
                              type: "dropdown",
                              enum: ["Unselected", "=", "<", ">", "≤", "≥"]
                            },
                            p_value: {
                              type: "text"
                            }
                          }
                        },
                        p_value_comment_container: {
                          title: "Comments",
                          type: "object",
                          properties: {
                            p_value_comment: {
                              title: "(Optional) Additional information, such as whether or not the p-value is adjusted for multiple comparisons and the a priori threshold for statistical significance.",
                              type: "textarea",
                            }
                          }
                        },
                        statistical_method_container: {
                          type: "object",
                          title: "Statistical Method",
                          properties: {
                            statistical_method: {
                              type: "dropdown",
                              enum: [
                                "Unselected",
                                "ANCOVA",
                                "ANOVA",
                                "Chi-squared",
                                "Chi-squared, Corrected",
                                "Cochran-Mantel-Haenszel",
                                "Fisher-Exact",
                                "Kruskal-Wallis",
                                "Log Rank",
                                "Mantel haenszel",
                                "McNemar",
                                "Mixed Models Analysis",
                                "Regression, Cox",
                                "Regression, Linear",
                                "Regression, Logistic",
                                "Sign test",
                                "t-test, 1 sided",
                                "t-test, 2 sided",
                                "Wilcoxon (Mann-Whitney)",
                                "Other"
                              ]
                            },
                            other_method_name: {
                              title: "If other, please specify",
                              type: "text"
                            },
                          }
                        },
                        statistical_test_comment_container: {
                          type: "object",
                          title: "Statistical Test Comment",
                          properties: {
                            statistical_test_comment: {
                              title: "(Optional) Any other relevant information, such as adjustments or degrees of freedom.",
                              type: "textarea"
                            }
                          }
                        }
                      },
                    },
                    method_of_estimation: {
                      type: "object",
                      title: "Method of Estimation",
                      properties: {
                        parameter_type_container: {
                          title: "Estimation Parameter",
                          type: "object",
                          properties: {
                            parameter_type: {
                              title: "(If Applicable)",
                              type: "dropdown",
                              enum: [
                                "Unselected",
                                "Cox Proportional Hazard",
                                "Hazard Ratio (HR)",
                                "Hazard Ratio, log",
                                "Mean Difference (Final Values)",
                                "Mean Difference (Net)",
                                "Median Difference (Final Values)",
                                "Median Difference (Net)",
                                "Odds Ratio (OR)",
                                "Odds Ratio, log",
                                "Risk Difference (RD)",
                                "Risk Ratio (RR)",
                                "Risk Ratio, log",
                                "Slope",
                                "Other"
                              ]
                            },
                            other_type_name: {
                              title: "If other, please specify",
                              type: "text"
                            },
                          }
                        },
                        parameter_value_container: {
                          type: "Object",
                          title: "Estimated Value",
                          properties: {
                            parameter_value: {
                              title: "Provide the data for the Estimation Parameter",
                              type: "number",
                            }
                          }
                        },
                        ci_container: {
                          type: "object",
                          title: "Confidence Interval",
                          properties: {
                            ci_pct_value: {
                              title: "% Confidence Interval",
                              type: "number"
                            },
                            ci_num_sides: {
                              title: "Number of sides",
                              type: "dropdown",
                              enum: ["1-Sided", "2-Sided"]
                            },
                            ci_lower_limit: {
                              title: "Lower Limit",
                              type: "number"
                            },
                            ci_upper_limit: {
                              title: "Upper Limit",
                              type: "number"
                            }
                          }
                        },
                        dispersion_type_container: {
                          type: "object",
                          title: "Parameter Dispersion Type and Dispersion Value",
                          properties: {
                            dispersion_type: {
                              title: "(If applicable)",
                              type: "dropdown",
                              enum: ["Unselected", "Standard Deviation", "Standard Error of the Mean"]
                            },
                            dispersion_value: {
                              type: "number"
                            }
                          }
                        },
                        estimate_comment_container: {
                          type: "object",
                          title: "Estimation Comments",
                          properties: {
                            estimate_comment: {
                              title: "(Optional) Any other relevant estimation information, including the direction of the comparison (e.g., describe which arm or comparison group represents the numerator and denominator for relative risk).",
                              type: "textarea"
                            }
                          }
                        }
                      }
                    },
                    other_statistical_analysis: {
                      type: "object",
                      title: "Other Statistical Analysis",
                      properties: {
                        other_analysis_container: {
                          type: "object",
                          title: "",
                          properties: {
                            other_analysis: {
                              title: "If the statistical analysis cannot be submitted using the Statistical Test of Hypothesis or Method of Estimation options, provide a description and the results of the scientifically appropriate test of statistical significance.",
                              type: "textarea"
                            }
                          }
                        }
                      }
                    }
                  }
                },
                type_units_analyzed: {
                  title: "Type of Units Analyzed",
                  type: "text",
                },
                population_analysis_description: {
                  title: "Analysis Population Description",
                  type: "textarea",
                },
                parameter_type: {
                  title: "Measure Type",
                  type: "dropdown",
                  value: "Count of Participants",
                  enum: ["Count of Participants", "Mean", "Median", "Least Squares Mean", "Geometric Mean", "Geometric Least Squares Mean", "Number", "Count of Units"],
                  validationKey: "paramType"
                },
                dispersion_type: {
                  title: "Precision/Dispersion Type",
                  type: "dropdown",
                  enum: ["Not Applicable", "Standard Deviation", "Standard Error", "Inter-Quartile Range", "Full Range", "Confidence Interval", "Geometric Coefficient of Variation"],
                  conditionals: {
                    parameter_type: {
                      "Count of Participants": {
                        enum: ["Not Applicable"],
                        value: "Not Applicable"
                      },
                      "Count of Units": {
                        enum: ["Not Applicable"],
                        value: "Not Applicable"
                      },
                      "Mean": {
                        enum: ["Standard Deviation", "Standard Error", "Inter-Quartile Range", "Full Range", "Confidence Interval"],
                        value: "Standard Deviation"
                      },
                      "Median": {
                        enum: ["Standard Deviation", "Standard Error", "Inter-Quartile Range", "Full Range", "Confidence Interval"],
                        value: "Standard Deviation"
                      },
                      "Least Squares Mean": {
                        enum: ["Standard Deviation", "Standard Error", "Inter-Quartile Range", "Full Range", "Confidence Interval"],
                        value: "Standard Deviation"
                      },
                      "Geometric Mean": {
                        enum: ["Standard Deviation", "Standard Error", "Inter-Quartile Range", "Full Range", "Confidence Interval", "Geometric Coefficient of Variation"]
                      },
                      "Geometric Least Squares Mean": {
                        enum: ["Standard Deviation", "Standard Error", "Inter-Quartile Range", "Full Range", "Confidence Interval", "Geometric Coefficient of Variation"]
                      },
                      "Number": {
                        enum: ["Not Applicable", "Confidence Interval"],
                        value: "Not Applicable"
                      },
                    }
                  },
                  validationKey: "dispersType"
                },
                ci_pct_pevel: {
                  title: "Confidence Interval Percentage",
                  type: "number",
                  required: true
                },
                unit_of_measure: {
                  title: "Unit of Measure",
                  type: "text",
                  required: true,
                  validationKey: "unitOfMeasure"
                },
                measure_rows: {
                  type: "array",
                  value: [],
                }
              },
              prop_keys: ["measure_type", "title", "description", "time_frame", "anticipated_result_posted_date"],
              data_keys: ["type_units_analyzed", "population_analysis_description", "parameter_type", "dispersion_type", "unit_of_measure"]
            },
            rows: {
              type: "array",
              title: "",
              categories: [
                {
                  id: "num-analyzed",
                  unique: true,
                  title: "Number Analyzed",
                  properties: {
                    value: {
                      type: "number"
                    }
                  }
                },
                {
                  id: `Category-${uuidv4()}`,
                  title: "",
                  properties: {
                    value: {
                      type: "text"
                    }
                  }
                },
              ],
            },
          }
        },
        eudract: {
          type: "object",
          properties: {
            arms: {
              type: "object",
              properties: {
                title: {
                  title: "Arms/Groups",
                  type: "textarea",
                  value: "",
                  required: true,
                  readOnly: true
                },
                subjects_analyzed: {
                  title: "Subjects Analyzed",
                  type: "number",
                  value: "",
                  required: true,
                  // readOnly: true
                },
                comment: {
                  title: "Comments",
                  type: "textarea",
                  rows: 4,
                  value: "",
                  required: true,
                  placeholder: "Note: The comment is mandatory when the number of subjects analysed is zero",
                  // readOnly: true
                }
              }
            },
            subject_analysis_sets: {
              type: "object",
              properties: {
                title: {
                  title: "Subject analysis set title",
                  type: "textarea",
                  value: ""
                },
                type: {
                  title: "Subject analysis set type",
                  type: "dropdown",
                  enum: ["Full analysis", "Intention-to-treat", "Modiefied intention-to-treat", "Per protocol", "Safety analysis", "Sub-group analysis"],
                  value: ""
                },
                description: {
                  title: "Subject analysis set description",
                  type: "textarea",
                  value: ""
                },
                subjects_analyzed: {
                  title: "Number of Subjects",
                  type: "number",
                  value: 0,
                }
              }
            },
            outcome_measure: {
              type: "object",
              properties: {
                arms: {
                  type: "array",
                  value: []
                },
                subject_analysis_sets: {
                  type: "array",
                  value: []
                },
                title: {
                  title: "Title",
                  type: "textarea",
                  required: true,
                  value: ""
                },
                measure_type: {
                  title: "Endpoint Type",
                  required: true,
                  type: "dropdown",
                  value: "Primary",
                  enum: ["Primary", "Secondary", "Other pre-specified", "Post-hoc"],
                },
                countable: {
                  type: "dropdown",
                  title: "Countable or Measurable?",
                  enum: ["Countable", "Measurable"],
                  required: true,
                  value: "Countable",
                },
                unit_of_measure: {
                  title: "Measurable Unit",
                  type: "text",
                  required: true,
                  value: ""
                },
                parameter_type: {
                  title: "Measure Type",
                  type: "dropdown",
                  enum: ["number", "arithmetic mean", "median", "least squares mean", "geometric mean", "log mean"],
                  value: "",
                  required: true,
                },
                dispersion_type: {
                  title: "Precision/Dispersion Type",
                  type: "dropdown",
                  enum: ["not applicable", "confidence interval", "standard deviation", "inter-quartile range (Q1-Q3)", "full-range (min-max)", "standard error", "geometric coefficient of variation"],
                  value: "",
                  conditionals: {
                    parameter_type: {
                      number: {
                        enum: ["not applicable", "confidence interval"],
                        value: "not applicable"
                      },
                      "arithmetic mean": {
                        enum: ["standard deviation", "inter-quartile range (Q1-Q3)", "full-range (min-max)", "standard error", "confidence interval"]
                      },
                      median: {
                        enum: ["standard deviation", "inter-quartile range (Q1-Q3)", "full-range (min-max)", "standard error", "confidence interval"]
                      },
                      "least squares mean": {
                        enum: ["standard deviation", "inter-quartile range (Q1-Q3)", "full-range (min-max)", "standard error", "confidence interval"]
                      },
                      "log mean": {
                        enum: ["standard deviation", "inter-quartile range (Q1-Q3)", "full-range (min-max)", "standard error", "confidence interval"]
                      },
                      "geometric mean": {
                        enum: ["standard deviation", "inter-quartile range (Q1-Q3)", "full-range (min-max)", "standard error", "confidence interval", "geometric coefficient of variation"]
                      },
                    }
                  },
                  required: true,
                },
                ci_pct_pevel: {
                  title: "Percentage",
                  type: "number",
                  required: true
                },
                time_frame: {
                  title: "Time Frame",
                  type: "textarea",
                  value: "",
                  required: true
                },
                description: {
                  title: "Description",
                  type: "textarea",
                  value: ""
                },
                outcome_measure_analysis: {
                  type: "array",
                  value: [],
                  properties: {
                    statistical_analysis_details: {
                      type: "object",
                      title: "Statistical analysis details",
                      properties: {
                        title_container: {
                          title: "Statistical Analysis Title",
                          type: "object",
                          required: true,
                          properties: {
                            title: {
                              type: "text"
                            }
                          }
                        },
                        description_container: {
                          title: "Analysis Description",
                          type: "object",
                          properties: {
                            group_description: {
                              type: "textarea"
                            }
                          }
                        },
                        outcome_reporting_groups_container: {
                          title: "Comparison Groups",
                          type: "Object",
                          properties: {
                            outcome_reporting_groups: {
                              title: "Groups/Subject Analysis Sets",
                              type: "multiselect"
                            },
                          }
                        },
                        analysis_specification_container: {
                          title: "Analysis specification",
                          type: "object",
                          properties: {
                            analysis_specification: {
                              type: "dropdown",
                              enum: ["Pre-specified", "Post-hoc"]
                            }
                          }
                        },
                        statistical_test_type_container: {
                          title: "Analysis type",
                          type: "object",
                          properties: {
                            statistical_test_type: {
                              type: "dropdown",
                              enum: ["Superiority", "Non-Inferiority", "Equivalence", "Other"],
                              required: true,
                            }
                          }
                        },
                        non_inferiority_test_comment_container: {
                          type: "object",
                          title: "Analysis type comment",
                          properties: {
                            non_inferiority_test_comment: {
                              type: "textarea",
                            }
                          }
                        }
                      }
                    },
                    statistical_hypothesis_test: {
                      type: "object",
                      title: "Statistical hypothesis test",
                      properties: {
                        p_value_container: {
                          title: "P-Value",
                          type: "object",
                          properties: {
                            value_equality_relation: {
                              title: "P-value",
                              type: "dropdown",
                              enum: ["Unselected", "=", "<", ">", "≤", "≥"]
                            },
                            p_value: {
                              type: "number"
                            }
                          }
                        },
                        p_value_comment_container: {
                          title: "Comments",
                          type: "object",
                          properties: {
                            p_value_comment: {
                              title: "(Optional) Additional information, such as whether or not the p-value is adjusted for multiple comparisons and the a priori threshold for statistical significance.",
                              type: "textarea",
                            }
                          }
                        },
                        statistical_method_container: {
                          type: "object",
                          title: "Method",
                          properties: {
                            statistical_method: {
                              type: "dropdown",
                              title: "Required if a P-value is provided",
                              enum: [
                                "Unselected",
                                "ANCOVA",
                                "ANOVA",
                                "Chi-squared",
                                "Chi-squared corrected",
                                "Cochran-Mantel-Haenszel",
                                "Fisher exact",
                                "Kruskal-wallis",
                                "Logrank",
                                "Mantel-Haenszel",
                                "Mcnemar",
                                "Mixed models analysis",
                                "Regression, Cox",
                                "Regression, Linear",
                                "Regression, Logistic",
                                "Sign test",
                                "t-test, 1-sided",
                                "t-test, 2-sided",
                                "Wilcoxon (Mann-Whitney)",
                                "Other (please specify)"
                              ]
                            },
                            other_method_name: {
                              title: "Other parameter type",
                              type: "text"
                            },
                          }
                        },
                      }
                    },
                    parameter_estimate: {
                      title: "Parameter estimate",
                      type: "object",
                      properties: {
                        parameter_type_container: {
                          title: "Estimation Parameter",
                          type: "object",
                          properties: {
                            parameter_type: {
                              title: "(If Applicable)",
                              type: "dropdown",
                              enum: [
                                "Unselected",
                                "Cox Proportional Hazard",
                                "Hazard Ratio (HR)",
                                "Log hazard ratio",
                                "Mean Difference (final values)",
                                "Mean Difference (net)",
                                "Median Difference (Final Values)",
                                "Median Difference (Net)",
                                "Odds ratio (OR)",
                                "Log odds ratio",
                                "Risk difference (RD)",
                                "Risk ratio (RR)",
                                "Log risk ratio",
                                "Slope",
                                "Other (please specify)"
                              ]
                            },
                            other_type_name: {
                              title: "Other parameter type",
                              type: "text"
                            },
                          }
                        },
                        parameter_value_container: {
                          type: "Object",
                          title: "Point estimate",
                          properties: {
                            parameter_value: {
                              type: "number",
                            }
                          }
                        },
                        ci_container: {
                          type: "object",
                          title: "Confidence Interval",
                          properties: {
                            ci_num_sides: {
                              title: "sides",
                              type: "dropdown",
                              enum: ["1-Sided", "2-Sided"]
                            },
                            ci_pct_value: {
                              title: "level",
                              type: "number"
                            },
                            ci_lower_limit: {
                              title: "Lower Limit",
                              type: "number"
                            },
                            ci_upper_limit: {
                              title: "Upper Limit",
                              type: "number"
                            }
                          }
                        },
                        variability_estimate_container: {
                          type: "object",
                          title: "Variability estimate",
                          properties: {
                            dispersion_type: {
                              title: "(If applicable)",
                              type: "dropdown",
                              enum: ["Standard Deviation", "Standard error of the mean"]
                            },
                          }
                        },
                        dispersion_value_container: {
                          type: "object",
                          title: "Dispersion value",
                          properties: {
                            dispersion_value: {
                              type: "number"
                            }
                          }
                        },
                      }
                    }
                  }
                },
                measure_rows: {
                  type: "array",
                  value: [],
                }
              },
              prop_keys: ["title", "countable", "unit_of_measure", "parameter_type", "dispersion_type", "measure_type", "time_frame", "description"],
            },
            rows: {
              type: "array",
              title: "",
              categories: [
                {
                  id: `Category-${uuidv4()}`,
                  title: "",
                  properties: {
                    value: {
                      type: "text"
                    }
                  }
                },
              ],
            },
          }
        },
        global: {
          type: "object",
          properties: {
            outcome_measure: {
              type: "object",
              properties: {
                outcome_measure_analysis: {
                  type: "array",
                  value: [],
                  properties: {
                    statistical_analysis_overview: {
                      type: "object",
                      title: "Statistical Analysis Overview",
                      properties: {
                        title_container: {
                          title: "Statistical Analysis Title",
                          type: "object",
                          required: true,
                          properties: {
                            title: {
                              type: "text"
                            }
                          }
                        },
                        // description_container: {
                        //   title: "Analysis Description",
                        //   type: "object",
                        //   properties: {
                        //     group_description: {
                        //       type: "textarea"
                        //     }
                        //   }
                        // },
                        analysis_specification_container: {
                          title: "Analysis specification",
                          type: "object",
                          properties: {
                            analysis_specification: {
                              type: "dropdown",
                              enum: ["Pre-specified", "Post-hoc"]
                            }
                          }
                        },
                        outcome_reporting_groups_container: {
                          title: "Comparison Group Selection",
                          type: "object",
                          required: true,
                          properties: {
                            outcome_reporting_groups: {
                              title: "Select the Outcome Measure Arms/Groups involved in the statistical analysis.",
                              type: "multiselect"
                            }
                          }
                        },
                        group_description_container: {
                          title: "Analysis Description/Comments",
                          type: "object",
                          properties: {
                            group_description: {
                              title: "(Optional) Additional details about the statistical analysis, such as null hypothesis and description of power calculation.",
                              type: "textarea"
                            }
                          }
                        },
                        statistical_test_type_container: {
                          title: "Type of Statistical Test",
                          type: "object",
                          properties: {
                            statistical_test_type: {
                              type: "dropdown",
                              enum: ["Superiority", "Non-Inferiority", "Equivalence", "Other", "Non-Inferiority or Equivalence (legacy)", "Superiority or Other (legacy)"],
                              required: true,
                            }
                          }
                        },
                        non_inferiority_test_comment_container: {
                          type: "object",
                          title: "Comments",
                          properties: {
                            non_inferiority_test_comment: {
                              type: "textarea",
                              title: "If a non-inferiority or equivalence analysis, information on the definition of the non-inferiority or equivalence margin is required. Also describe any other key parameters and details of the power calculation (if not described elsewhere)."
                            }
                          }
                        }
                      }
                    },
                    statistical_test_of_hypothesis: {
                      type: "object",
                      title: "Statistical Test of Hypothesis",
                      properties: {
                        p_value_container: {
                          title: "P-Value",
                          type: "object",
                          properties: {
                            value_equality_relation: {
                              title: "(If Applicable, e.g < 0.01)",
                              type: "dropdown",
                              enum: ["Unselected", "=", "<", ">", "≤", "≥"]
                            },
                            p_value: {
                              type: "text"
                            }
                          }
                        },
                        p_value_comment_container: {
                          title: "Comments",
                          type: "object",
                          properties: {
                            p_value_comment: {
                              title: "(Optional) Additional information, such as whether or not the p-value is adjusted for multiple comparisons and the a priori threshold for statistical significance.",
                              type: "textarea",
                            }
                          }
                        },
                        statistical_method_container: {
                          type: "object",
                          title: "Statistical Method",
                          properties: {
                            statistical_method: {
                              type: "dropdown",
                              enum: [
                                "Unselected",
                                "ANCOVA",
                                "ANOVA",
                                "Chi-squared",
                                "Chi-squared, Corrected",
                                "Cochran-Mantel-Haenszel",
                                "Fisher-Exact",
                                "Kruskal-Wallis",
                                "Log Rank",
                                "Mantel haenszel",
                                "McNemar",
                                "Mixed Models Analysis",
                                "Regression, Cox",
                                "Regression, Linear",
                                "Regression, Logistic",
                                "Sign test",
                                "t-test, 1 sided",
                                "t-test, 2 sided",
                                "Wilcoxon (Mann-Whitney)",
                                "Other"
                              ]
                            },
                            other_method_name: {
                              title: "If other, please specify",
                              type: "text"
                            },
                          }
                        },
                        statistical_test_comment_container: {
                          type: "object",
                          title: "Statistical Test Comment",
                          properties: {
                            statistical_test_comment: {
                              title: "(Optional) Any other relevant information, such as adjustments or degrees of freedom.",
                              type: "textarea"
                            }
                          }
                        }
                      },
                    },
                    method_of_estimation: {
                      type: "object",
                      title: "Method of Estimation",
                      properties: {
                        parameter_type_container: {
                          title: "Estimation Parameter",
                          type: "object",
                          properties: {
                            parameter_type: {
                              title: "(If Applicable)",
                              type: "dropdown",
                              enum: [
                                "Unselected",
                                "Cox Proportional Hazard",
                                "Hazard Ratio (HR)",
                                "Hazard Ratio, log",
                                "Mean Difference (Final Values)",
                                "Mean Difference (Net)",
                                "Median Difference (Final Values)",
                                "Median Difference (Net)",
                                "Odds Ratio (OR)",
                                "Odds Ratio, log",
                                "Risk Difference (RD)",
                                "Risk Ratio (RR)",
                                "Risk Ratio, log",
                                "Slope",
                                "Other"
                              ]
                            },
                            other_type_name: {
                              title: "If other, please specify",
                              type: "text"
                            },
                          }
                        },
                        parameter_value_container: {
                          type: "Object",
                          title: "Estimated Value",
                          properties: {
                            parameter_value: {
                              title: "Provide the data for the Estimation Parameter",
                              type: "number",
                            }
                          }
                        },
                        ci_container: {
                          type: "object",
                          title: "Confidence Interval",
                          properties: {
                            ci_pct_value: {
                              title: "% Confidence Interval",
                              type: "number"
                            },
                            ci_num_sides: {
                              title: "Number of sides",
                              type: "dropdown",
                              enum: ["1-Sided", "2-Sided"]
                            },
                            ci_lower_limit: {
                              title: "Lower Limit",
                              type: "number"
                            },
                            ci_upper_limit: {
                              title: "Upper Limit",
                              type: "number"
                            }
                          }
                        },
                        dispersion_type_container: {
                          type: "object",
                          title: "Parameter Dispersion Type and Dispersion Value",
                          properties: {
                            dispersion_type: {
                              title: "(If applicable)",
                              type: "dropdown",
                              enum: ["Unselected", "Standard Deviation", "Standard Error of the Mean"]
                            },
                            dispersion_value: {
                              type: "number"
                            }
                          }
                        },
                        estimate_comment_container: {
                          type: "object",
                          title: "Estimation Comments",
                          properties: {
                            estimate_comment: {
                              title: "(Optional) Any other relevant estimation information, including the direction of the comparison (e.g., describe which arm or comparison group represents the numerator and denominator for relative risk).",
                              type: "textarea"
                            }
                          }
                        }
                      }
                    },
                    other_statistical_analysis: {
                      type: "object",
                      title: "Other Statistical Analysis",
                      properties: {
                        other_analysis_container: {
                          type: "object",
                          title: "",
                          properties: {
                            other_analysis: {
                              title: "If the statistical analysis cannot be submitted using the Statistical Test of Hypothesis or Method of Estimation options, provide a description and the results of the scientifically appropriate test of statistical significance.",
                              type: "textarea"
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}