import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { Tooltip } from 'antd'
import PropTypes from 'prop-types'
import { Select } from 'components-design-system'
import TextInput from '../../../../../../components/TextInput/TextInput'
import Modal from '../../../../../../components/Modal/Modal'
import Separator from '../../../../../../components/Separator/Separator'
import './CreateActivityModal.scss'

// api
import { useGetActivityTypes } from 'api/hooks/activities/useActivitiesApi'
import { usePostAssessmentActivity } from 'api/hooks/studies/useAssessmentsApi'

const CreateActivityModal = ({
  open,
  onCancel,
  assessment,
  assessments,
  onCreated,
}) => {
  const { studyId } = useParams()
  const { isLoading: activityTypesLoading, error: activityTypesError, data: activityTypes } = useGetActivityTypes()
  const [typeOptions, setTypeOptions] = useState(null)
  const [attachedActivities, setAttachedActivities] = useState(null)
  // activity input into Select component
  const [selectedTypeId, setSelectedTypeId] = useState(null)
  // list of activity types selected // { id, should_complete }
  const [selectedTypes, setSelectedTypes] = useState([])
  const postAssessmentActivity = usePostAssessmentActivity(studyId, assessment?.id)
  
  useEffect(() => {
    // find all assessment rows that are actually the same assessment and get its attached activities
    if (assessment?.attached_projects && assessments && activityTypes) {
      // filter out completed from attached
      const attached = assessment.attached_projects.filter((attach) => attach.is_completed === false || attach.is_completed === 'false')
      setAttachedActivities(attached)

      // get activity type options, filtering out attached activity types
      const options = activityTypes.reduce((acc, type) => {
        if (!attached.some((activ) => activ.title === type.title)) {
          acc.push({ id: type.id, name: type.title })
        }
        return acc
      }, [])
      setTypeOptions(options)
    }
  }, [assessment, assessments, activityTypes])

  useEffect(() => {
    // on a new assessment, clear selections
    setSelectedTypeId(null)
    setSelectedTypes([])
  }, [assessment])

  const addToSelected = (value) => {
    if (value) {
      // add to selected IDs
      const typeObj = activityTypes.find((type) => type.id === value)
      setSelectedTypes((typeObjs) => {
        let selected = [...typeObjs]
        selected.push({ project_type_id: typeObj.id, should_complete: false })
        return selected
      })
      setSelectedTypeId(null)

      // remove from dropdown options
      setTypeOptions((options) => {
        let newOptions = []
        options.forEach((option) => {
          if (option.id !== value) newOptions.push(option);
        })
        return newOptions
      })
    }
  }

  const removeFromSelected = (id) => {
    // remove from selected IDs
    setSelectedTypes((typeObjs) => {
      return [...typeObjs].filter((obj) => {
        return obj.project_type_id !== id
      })
    })

    // add to options
    setTypeOptions((options) => {
      const type = activityTypes.find((typeObj) => typeObj.id === id)
      return [...options, { id: type.id, name: type.title }]
    })
  }

  const toggleCompleteSelected = (id) => {
    setSelectedTypes((typeObjs) => {
      return [...typeObjs].map((typeObj) => {
        let newObj = { ...typeObj }
        if (newObj.project_type_id === id) {
          newObj.should_complete = typeObj?.should_complete ? !typeObj.should_complete : true
        }
        return newObj
      })
    })
  }

  const createActiities = async () => {
    postAssessmentActivity({
      body: { project_types: selectedTypes }
    }, {
      onSuccess: (res) => {
        if (res?.data?.project_ids?.length) {
          // open up only one newly created activity in a new tab
          window.open(`/study/${studyId}/activity/${res.data.project_ids[0]}`, '_blank')
        }
        onCreated() // close modal
      },
    })
  }

  const renderAttached = () => {
    if (attachedActivities?.length) {
      return attachedActivities.map((attached) => {
        return <div key={attached?.id} className="activity-field-row">
          <TextInput readOnly ellipsis
            defaultValue={attached?.title}
          />
          <Tooltip title="Open attached activity on new tab">
            <Link
              to={`/study/${studyId}/activity/${attached?.id}`}
              target="_blank"
              className="activity-button"
            ><i className="fal fa-link" /></Link>
          </Tooltip>
          <div className="activity-spacer">&nbsp;</div>
        </div>
      })
    }
    return null
  }

  const renderSelected = () => {
    return selectedTypes.map((typeObj) => {
      const defaultValue = activityTypes.reduce((acc, typesObj) => {
        if (typesObj.id === typeObj.project_type_id) acc = typesObj.title;
        return acc
      }, '')
      return <div className="activity-field-row create-activity-added-row" key={typeObj.project_type_id}>
        <TextInput readOnly ellipsis
          defaultValue={defaultValue}
        />
        <Tooltip title="Remove activity type">
          <div
            onClick={() => removeFromSelected(typeObj.project_type_id)}
            className="activity-button remove-activity-icon"
          ><i className="fal fa-times-circle" /></div>
        </Tooltip>
        <Tooltip title="Mark completed">
          <div
            onClick={() => toggleCompleteSelected(typeObj.project_type_id)}
            className="activity-button complete-activity-icon"
          ><i className={`${typeObj?.should_complete ? 'fas' : 'fal'} fa-check-circle`} /></div>
        </Tooltip>
      </div>
    })
  }

  const getTypeTitle = (id) => {
    return activityTypes.reduce((acc, typeObj) => {
      if (typeObj.id === id) acc = typeObj.title
      return acc
    }, null)
  }

  return <Modal
    className="create-activity-modal"
    type="create"
    open={open}
    onCancel={onCancel}
    onOk={createActiities}
    disabled={!selectedTypes.length || selectedTypeId}
    title="Create activities"
    message={assessment?.project_type_ids?.length > 0 ? <>
      <div>Recommended activity types:</div>
      <div>{assessment?.project_type_ids?.map((id) => getTypeTitle(id))?.join(assessment.operator === '|' ? ' or ' : ' and ')}</div>
    </> : null}
    width={530}
  >
    <div className="create-activity-content">
      {/* select activity type */}
      <div className="activity-field-row create-activity-add-row">
        <Select
          options={typeOptions ? typeOptions : []}
          // defaultValue={}
          value={selectedTypeId}
          placeholder="Activity type"
          onChange={(value) => addToSelected(value)}
        />
        <div className="activity-spacer">&nbsp;</div>
        <div className="activity-spacer">&nbsp;</div>
      </div>

      {/* types added */}
      {(selectedTypes?.length > 0 || renderAttached) &&
        <>
          <Separator marginTop={2} marginBottom={10} />
          {renderSelected()}
          {renderAttached()}
        </>
      }

    </div>
  </Modal>
}

CreateActivityModal.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
}

export default CreateActivityModal