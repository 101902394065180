// TODO: change name to assessmentsApi b/c it contains hooks but is not a hook
import useGetApi from "../useGetApi"
import useMutateApi from '../useMutateApi'

// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

// utils
import getUrl from 'api/apiMap'

export const useGetStudyActivities = (studyId) => {
  return useGetApi(getUrl(`${apiEndpoints.studies}${studyId}/activities/`), queryKeys.studies.activities(studyId))
}

export const usePostStudyActivity = (studyId) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(`${apiEndpoints.studies}${studyId}/activities/`),
    invalidateQueries: [queryKeys.studies.activities(studyId)],
    successMsg: 'Successfully created activity'
  })
}

export const useGetStudyActivitiesTimeline = (studyId) => {
  return useGetApi(getUrl(`${apiEndpoints.studies}${studyId}/activities-as-timeline/`), queryKeys.studies.timeline(studyId))
}