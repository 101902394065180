import React, { useState, useEffect } from 'react';
import ReactFlow, {
  MiniMap,
  Controls,
  useNodesState,
  useEdgesState,
  // addEdge,
  MarkerType,
  ConnectionMode
} from 'reactflow';

import ShapeNode from '../Nodes/ShapeNode/ShapeNode';
import StateNode from '../Nodes/StateNode/StateNode';
import DecisionTreeNode from '../Nodes/DecisionTreeNode/DecisionTreeNode';

import useReactFlowAutoLayout from 'components/react-flow/utils/useReactFlowAutoLayout';
// import useReactFlowForceLayout from 'components/react-flow/utils/useReactFlowForceLayout';

// styles
import './decision-tree.scss';

const colors = {
  darkred: "#D7542C",
  lightred: "#F7DDD5",
  darkgreen: "#8AB25B",
  lightgreen: "#ECF5E1",
  darkblue: "#18669E",
  lightblue: "#D1E0EC",
  active: "#18669E",
  activeLight: "#F1F6F9",
  inactive: "#5F636B",
  inactiveLight: "#fff",
  lightgrey: "#6A6E75",
  lightestgrey: "#F2F2F2"
}

const nodeTypes = {
  stateNode: StateNode,
  shapeNode: ShapeNode,
  decisionTreeNode: DecisionTreeNode
};

const DecisionTree = ({ data }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState(null);
  const [edges, setEdges, onEdgesChange] = useEdgesState(null);

  useReactFlowAutoLayout({ direction: "LR" })

  useEffect(() => {
    if (data) {
      if (data.schema?.nodeDataArray) {
        let formattedNodes = formatNodes(data.schema.nodeDataArray)
        setNodes(formattedNodes)
      }
      if (data.schema?.linkDataArray) {
        let formattedEdges = formatEdges(data.schema.linkDataArray)
        setEdges(formattedEdges)
      }
    } else {
      setNodes([])
      setEdges([])
    }

    return () => {
      setNodes(null)
      setEdges(null)
    }
  }, [data])

  const formatNodes = (nodes) => {
    return nodes?.map((node, i) => {
      let positions = node.loc.split(" ");
      // console.log({ node, positions })
      let position = {
        // x: Number(positions[0]),
        // y: Number(positions[1])
        x: 0,
        y: 0
      }
      let style = {}
      let labelStyle = { color: colors.lightgrey }
      let className = ""
      let labelClassName = ""

      if (node.active) {
        switch (node.text) {
          case "Out of Scope":
            style = { backgroundColor: colors.darkred }
            labelStyle = { color: "#fff" }
            className = "out-of-scope-active"
            break;
          case "In Scope":
            style = { backgroundColor: colors.darkgreen }
            labelStyle = { color: "#fff" }
            className = "in-scope-active"
            break;
          default:
            style = {
              backgroundColor: colors.activeLight,
              border: `1px solid ${colors.active}`
            }
            labelStyle = { color: colors.active }
            className = "node-active"
        }
      } else {
        switch (node.text) {
          case "Out of Scope":
            style = {
              background: colors.lightred,
              border: `1px solid ${colors.lightgrey}`
            }
            className = "out-of-scope-inactive"
            break;
          case "In Scope":
            style = {
              background: colors.lightgreen,
              border: `1px solid ${colors.lightgrey}`
            }
            className = "in-scope-inactive"
            break;
          default:
            style = {
              background: colors.lightestgrey,
              border: `1px solid ${colors.lightgrey}`
            }
            className = "node-inactive"
        }
      }

      let updatedNode = {
        ...node,
        id: node.key,
        position,
        type: "decisionTreeNode",
        targetPosition: 'left',
        sourcePosition: 'right',
        style,
        className: `decision-tree-node ${className}`,
        data: {
          ...node,
          label: (
            <div key={`${node.key}-label`}>
              <p>
                {node.text}
              </p>
            </div>
          )
        }
      }

      if (i === 0) {
        updatedNode = {
          ...updatedNode,
          type: "shapeNode",
          style: {},
          className: "initial-node",
          sourceHandle: "right",
          targetHandle: "right",
          // position: "right",
          data: {
            ...updatedNode.data,
            title: node.text,
            shape: 'circle',
            width: 80,
            height: 80,
            color: '#5A9C56'
          }
        }

        // console.log("updatedNode: ", updatedNode)
      } else if (node?.text?.toLowerCase() === "not required") {
        updatedNode = {
          ...updatedNode,
          type: "shapeNode",
          style: {},
          className: "not-required-node",
          data: {
            ...updatedNode.data,
            title: node.text,
            shape: 'round-rect',
            width: 114,
            height: 32,
            color: '#FEFBF3',
            borderColor: "ECCF84",
            isConnectable: false
          }
        }
      }

      return updatedNode;
    })
  }

  const formatEdges = (edges) => {
    return edges?.map(edge => {

      let style = {
        fontSize: 10,
        stroke: colors.inactive
      }

      if (edge?.active) {
        style.stroke = colors.darkblue;
        style.strokeWidth = 4
        style.fill = colors.darkblue
      }

      let labelStyle = {
        fill: edge?.active ? colors.darkblue : colors.inactive
      }
      let animated = edge?.active ? true : false;
      let classNames = ["node-edge"]
      if (edge.active) {
        classNames.push("edge-active")
      }

      let updatedEdge = {
        ...edge,
        id: `${edge.from}-${edge.to}`,
        key: `${edge.from}-${edge.to}`,
        source: edge.from,
        target: edge.to,
        label: edge.text,
        type: "step",
        // animated,
        markerEnd: {
          type: MarkerType.Arrow,
          stroke: edge?.active ? colors.darkblue : colors.inactive
        },
        className: classNames.join(" "),
        style,
        labelStyle
      }

      return updatedEdge;
    })
  }

  return (
    <div className="decision-tree-container">
      {edges && nodes && <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        fitView={true}
        attributionPosition="bottom-left"
        nodesConnectable={false}
        nodesDraggable={true}
        nodeTypes={nodeTypes}
      // connectionMode={ConnectionMode.Loose}
      >
        <MiniMap />
        <Controls />
        {/* <Background /> */}
      </ReactFlow>}
    </div>
  );
};

export default DecisionTree;