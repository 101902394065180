import * as React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'
import "./StudyTypeBadge.scss"

const map = {
  "Interventional": {
    label: "Int",
    className: "badge-int"
  },
  "Observational": {
    label: "Obs",
    className: "badge-obs"
  },
  "Expanded Access": {
    label: "Exp",
    className: "badge-exp"
  }
}

const StudyTypeBadge = (props) => {

  return (
    <Tooltip
      title={props.value}>
      <span className={`badge-study-type ${map?.[props.value]?.className}`}>
        <p className="badge-text">
          {map?.[props.value]?.label}
        </p>
      </span>
    </Tooltip>
  )
}

export default StudyTypeBadge