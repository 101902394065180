import React, { useState, useEffect } from 'react'
import { Switch as AntSwitch } from 'antd'
import { PropTypes } from 'prop-types'
import './Switch.scss'

const Switch = ({
  label,
  labelOn,
  labelOff,
  returnLabels = false, // return labelOn (checked) and labelOff (not checked)
  defaultChecked,
  checked,
  onChange,
  readOnly,
  disabled,
  size = "small",
  style,
  className,
  wrapperClassName,
  ...restOfProps // defaultChecked
}) => {
  const [isChecked, setIsChecked] = useState(false) // boolean

  let classNames = ['prime-switch']
  if (className) classNames.push(className)
  if (readOnly) classNames.push('read-only')

  let wrapperClassNames = ['prime-switch-container']
  if (wrapperClassName) wrapperClassNames.push(wrapperClassName)

  useEffect(() => {
    if (defaultChecked !== undefined) setIsChecked(!!defaultChecked);
    if (checked !== undefined) setIsChecked(!!checked);
  }, [])

  useEffect(() => {
    if (checked !== undefined) setIsChecked(!!checked);
  }, [checked])

  // clicking on the wrapper will toggle the switch
  const toggleChange = () => {
    if (!readOnly) {
      if (onChange) {
        const newVal = !isChecked
        // default callback with boolean
        let returnVal = newVal
        if (returnLabels && labelOn && labelOff) {
          // or callback with label values
          returnVal = newVal ? labelOn : labelOff
        }
        onChange(returnVal)
      }
      setIsChecked((state) => !state)
    }
  }

  const labelText = () => {
    if (labelOn && labelOff) {
      return isChecked ? labelOn : labelOff
    }
    if (label) return label;
    if (labelOn) return labelOn;
  }

  const isDisabled = readOnly || disabled

  return <div
    className={wrapperClassNames.join(' ')}
    style={style}
    onClick={isDisabled ? undefined : toggleChange}
  >
    <div className={classNames.join(' ')}>
      <AntSwitch
        size={size}
        checked={isChecked}
        disabled={isDisabled}
        {...restOfProps}
      />
    </div>
    <span className="switch-label">{labelText()}</span>
  </div>
}

Switch.propTypes = {
  label: PropTypes.string,
  labelOn: PropTypes.string,
  labelOff: PropTypes.string,
  returnLabels: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['default', 'small']),
  style: PropTypes.object,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
}

export default Switch