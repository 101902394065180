import React from 'react'
import { Link } from 'react-router-dom'

const AssessmentLinkCellRenderer = ({
  value,
  data,
  studyId,
}) => {
  if (!value) {
    return <div></div>
  } else if (value === "N/A") {
    return <div>{value}</div>
  }

  // Assessment Tab Grid
  // QUESTION: why do we have to check if have missing_fields?
  if (data.hasOwnProperty("missing_fields")) {
    return (
      <Link to={`/study/${studyId}/assessment/${data.id}`}>
        {value}
      </Link>
    )
  }

  // Activity Tab Grid
  // TODO: do not have Activity grid use this cell renderer. And fix this link. Study's unique ID needed for link:
  return (
    <Link className="prime-link" to={`/study/{study_uniqid}/assessment/${data.assessment_uniqid}`}>
      {value}
    </Link>
  )
}

export default AssessmentLinkCellRenderer