import { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useShallow } from 'zustand/react/shallow'

// components
import Label from 'components/Label/Label';
import PrimeField from 'components/PrimeField/PrimeField';
import ArmActions from '../../../components/ArmActions/ArmActions';
import CellValidation from '../../../components/CellValidation/CellValidation';

// context
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';
import useResultsTableViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableViewStore';

const Arm = ({
  measure,
  measureIndex,
  arm,
  arms,
  colIndex,
  rowIndex,
  outcomeData,
  setOutcomeData,
  handleLeft,
  handleRight,
  handleDelete,
  schema,
  fieldKey,
}) => {
  const { ref: visibleRef, inView: inViewport } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  });

  const {
    tableView,
  } = useResultsTableViewStore()
  const {
    readOnly
  } = useAuthoringViewStore(
    useShallow(state => ({ readOnly: state.readOnly }))
  )

  const [showActions, setShowActions] = useState(false)

  const onChange = (e, arm, fieldKey) => {
    let tempData = { ...outcomeData };

    const updateSubjectAnalyzed = (registry, group, foundOMIndex) => {
      tempData[registry].outcome_measures[foundOMIndex].measure_rows.forEach(row => {
        let catIndex = row.categories.findIndex(item => item.id === "num-analyzed");
        if (catIndex !== -1 && row.categories[catIndex]?.values) {

          let colIndex = row.categories[catIndex].values.findIndex(cat => cat.registry_arm_id === group.registry_arm_id);
          if (colIndex !== -1) {
            let key = "value";
            if (fieldKey === "num_units_analyzed") key = fieldKey
            if (row.categories[catIndex].values[colIndex][key] === group[fieldKey] ||
              (!row.categories[catIndex].values[colIndex][key] && !group[fieldKey])) {
              row.categories[catIndex].values[colIndex][key] = e;
            }
          }
        }
      })
    }

    Object.keys(tempData).forEach(registry => {
      let foundOMIndex = tempData[registry].outcome_measures.findIndex(item => item.id === measure.id);
      if (foundOMIndex !== -1) {
        let foundGroupIndex = tempData[registry].outcome_measures[foundOMIndex].arms.findIndex(item => item.registry_arm_id === arm.registry_arm_id);
        if (registry !== "eudract" || (registry === "eudract" && foundGroupIndex !== -1)) { // update CTG arm
          if (fieldKey === "subjects_analyzed" || fieldKey === "num_units_analyzed") updateSubjectAnalyzed(registry, arm, foundOMIndex, foundGroupIndex);
          let field = tempData[registry].outcome_measures[foundOMIndex]?.arms?.[foundGroupIndex]
          if (field) field[fieldKey] = e;
        } else if (registry === "eudract") {
          foundGroupIndex = tempData[registry].outcome_measures[foundOMIndex]?.subject_analysis_sets?.findIndex(item => item.registry_arm_id === arm.registry_arm_id);
          if (foundGroupIndex !== -1) { // update Eudract arm
            if (fieldKey === "subjects_analyzed" || fieldKey === "num_units_analyzed") updateSubjectAnalyzed(registry, arm, foundOMIndex, foundGroupIndex);
            tempData[registry].outcome_measures[foundOMIndex].subject_analysis_sets[foundGroupIndex][fieldKey] = e;
          }
          let foundSubjectAnalysisSetIndex = tempData[registry]?.subject_analysis_sets?.findIndex(item => item.registry_arm_id === arm.registry_arm_id);
          if (foundSubjectAnalysisSetIndex !== -1) {
            let set = tempData[registry].subject_analysis_sets[foundSubjectAnalysisSetIndex];
            set[fieldKey] = e;
          }
        }
      }
    })

    setOutcomeData(tempData)
  }

  let isManualOverride = fieldKey === "title" || fieldKey === "description"

  return (
    <td
      ref={visibleRef}
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}
    >
      {inViewport ? (
        <>
          <div style={{
            display: "flex",
            flexDirection: "column",
            gap: 8
          }}>
            <PrimeField
              schema={{
                type: schema[fieldKey].type,
                placeholder: schema[fieldKey].placeholder,
                items: schema[fieldKey].enum,
                rows: schema[fieldKey].rows
              }}
              readOnly={readOnly}
              disabled={isManualOverride}
              value={arm?.[fieldKey]}
              onChange={e => onChange(e, arm, fieldKey)} />
          </div>
          <ArmActions
            arms={arms}
            arm={arm}
            colIndex={colIndex}
            showActions={showActions}
            handleLeft={rowIndex === 0 ? () => handleLeft(arm) : null}
            handleRight={rowIndex === 0 ? () => handleRight(arm) : null}
            canDelete={rowIndex === 0 ? true : false}
            handleDeleteArm={handleDelete}
            disableDeleteAll={false}
            jpath={`om-${measure.id}-arm-${arm?.id}-${fieldKey}`}
            source={"outcome-measures"}
            manualOverride={isManualOverride}
            periodIndex={measureIndex} />
          {fieldKey === "subjects_analyzed" && arm?.[fieldKey] == 0 &&
            <Label style={{ marginTop: 8, width: 230 }}>
              Explain why the number of participants analyzed or the number of units analyzed (if provided) is zero.
            </Label>
          }
          <CellValidation
            table="outcome"
            errorKey={`results_outcome_measures_outcome_measures_table_${tableView}_outcome_measures_${measureIndex + 1}_arms_${colIndex + 1}_${fieldKey}`} />
        </>
      ) : (
        <div style={{ padding: 10 }} />
      )}
    </td>
  );
};

export default Arm;