import { useState, useEffect, useRef, useMemo } from 'react';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';

// components
import { Tooltip } from 'antd'
import ConfirmChangesModal from '../ConfirmChangesModal/ConfirmChangesModal';
import GhostState from '../GhostState/GhostState';
import UserBadge from '../../../badges/UserBadge/UserBadge';
import MilestoneMarker from '../MilestoneMarker/MilestoneMarker';

// apis
import { useUserSettings } from 'api/hooks';

// context
import useWorkflowTracker from 'context/hooks/useWorkflowTracker';

// styles
import './state.scss';

const isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)

const State = (props) => {
  const {
    tempTrackerData,
    state,
    index,
    isFirst,
    isLast,
    pizzaTrackerRef,
    dateFormat,
    onSaveStateDates,
    onRevertStateDates,
    showAssignees,
    showDueDates,
    showDays,
    pizzaTrackerWrapperRef,
    trackerMarginTop,
    setTrackerMarginTop,
    milestones = [],
    isToday,
    readOnly
  } = props;

  const [isDragging, setIsDragging] = useState(false);
  const [originalXPos, setOriginalXPos] = useState(0);
  const [newXPos, setNewXPos] = useState(null);
  const [openConfirmDateChangeModal, setConfirmDateChangeModal] = useState(false);
  const [showGhostState, setShowGhostState] = useState(false);
  const [showStateClicked, setShowStateClicked] = useState(false);
  const [ghostState, setGhostState] = useState({ ...props.state })
  const [numOfDays, setNumOfDays] = useState(0)
  const [fromStartToToday, setFromStartToToday] = useState(0)
  const [fromTodayToEnd, setFromTodayToEnd] = useState(0)
  const { data: userSettings } = useUserSettings()

  const {
    isWorkflowComplete,
    isWorkflowCancelled,
    isOnHold,
    workflowTrackerData
  } = useWorkflowTracker()

  const isStateComplete = state?.status === "Completed"
  const isStateBypassed = state?.status === "Bypassed"
  const isComplete = useMemo(() => {
    return isStateComplete || isStateBypassed
  }, [isStateComplete, isStateBypassed]);

  let currentBarRef = useRef()

  let newXPosRef = useRef()
  let setNewXPosRef = useRef()

  let originalXPosRef = useRef()
  let setOriginalXPosRef = useRef()

  newXPosRef.current = newXPos;
  setNewXPosRef.current = setNewXPos;
  setOriginalXPosRef.current = setOriginalXPos;

  let stateRef = useRef()
  let ghostStateRef = useRef()

  const onLongPress = (e) => {
    if (isWorkflowCancelled !== true && readOnly !== true) {
      setShowStateClicked(true)
      setIsDragging(true)
      setOriginalXPos(e.screenX)
      originalXPosRef.current = e.screenX
      pizzaTrackerRef.current.style.cursor = "grabbing"
    }
  };

  useEffect(() => {
    if (isWorkflowCancelled !== true && readOnly !== true) {
      if (isDragging === true) {
        pizzaTrackerRef.current?.addEventListener('mouseup', onLongPressRelease, true);
        pizzaTrackerRef.current?.addEventListener('mousemove', handleMouseMove, true);
      } else {
        pizzaTrackerRef.current?.removeEventListener('mouseup', onLongPressRelease, true)
        pizzaTrackerRef.current?.removeEventListener('mousemove', handleMouseMove, true);
      }
    }

    return () => {
      pizzaTrackerRef.current?.removeEventListener('mouseup', onLongPressRelease, true)
      pizzaTrackerRef.current?.removeEventListener('mousemove', handleMouseMove, true);
    }
  }, [isDragging])

  const endDate = useMemo(() => {
    return dayjs(tempTrackerData?.[index + 1]?.start_state_date)
  }, [state])

  const startDate = useMemo(() => {
    if (isLast) return dayjs(state.start_state_date)

    if (!state.start_state_date && tempTrackerData[index - 1]) {
      return dayjs(tempTrackerData[index - 1].end_state_date)
    }

    if (dayjs(state.start_state_date).isAfter(state.end_state_date, "days")) {
      return dayjs(state.end_state_date)
    }

    return dayjs(state.start_state_date)
  }, [state])

  const isLate = useMemo(() => {
    let today = dayjs();
    let endDate = dayjs(state.end_state_date);

    if (state.is_late || (state.is_current && today.isAfter(endDate, 'day'))) {
      return true
    }

    if (state.is_current) {
      let today = dayjs();

      if (today.isBetween(startDate, endDate) || endDate.format("YYYY-MM-DD") === today.format("YYYY-MM-DD")) {
        let daysUntilDue = endDate.diff(today, 'day');
        if (daysUntilDue < 20) return true
      }
    }


    return false;
  }, [state])
  
  const totalDays = useMemo(() => {
    let days = endDate.diff(startDate, "day")

    if (days < 0) days = 0
    return days
  }, [state])

  useEffect(() => {
    getNumOfDays()
  }, [state])

  const onLongPressRelease = () => {
    setIsDragging(false)
    setShowStateClicked(false)
    pizzaTrackerRef.current.style.cursor = "inherit"
    setConfirmDateChangeModal(true)
  };

  const dragState = (state, oldPos, newPos, final) => {
    if (showGhostState === false) setShowGhostState(true);

    let updatedTrackerData = [...tempTrackerData];
    let updatedState = { ...state };
    let diffDays = oldPos - newPos;


    let numDaysForActivity = Math.floor(Math.abs(diffDays) / 6);

    let ghostStart = startDate;
    let ghostEnd = endDate;
    // let prevState = null;
    // let nextState = null;

    // // let currState = state;

    // if (updatedTrackerData[index - 1]) prevState = updatedTrackerData[index - 1]
    // if (updatedTrackerData[index + 1]) nextState = updatedTrackerData[index + 1]

    if (diffDays < 0) { // move forward
      let duration = ghostEnd.diff(ghostStart, "day");
      ghostStart = ghostStart.add(numDaysForActivity, "day");
      ghostEnd = ghostStart.add(duration, "day");
      updatedState.start_state_date = ghostStart.format("YYYY-MM-DD");
      updatedState.end_state_date = ghostEnd.format("YYYY-MM-DD");

      setGhostState(updatedState)
      ghostStateRef.current = updatedState

    } else { // move back
      ghostStart = ghostStart.subtract(numDaysForActivity, "day");
      updatedState.start_state_date = ghostStart.format("YYYY-MM-DD");
      setGhostState(updatedState)
      ghostStateRef.current = updatedState

    }
  }

  let sortedMilestones = useMemo(() => {
    if (!milestones) return []
    return milestones?.filter(a => a.title !== "Created Date")?.sort((a, b) => {
      return new Date(dayjs(a.value).format("YYYY-MM-DD")) - new Date(dayjs(b.value).format("YYYY-MM-DD"));
    })
  }, [milestones])

  let milestoneOffsets = useMemo(() => {
    if (sortedMilestones) {

      let res = []

      sortedMilestones.forEach(milestone => {
        let milestoneDate = dayjs(milestone.value)
        let milestoneFromStart = milestoneDate.diff(startDate, 'day')

        let milestoneMarkerOffset = Math.ceil((milestoneFromStart / totalDays) * 100)
        if (isNaN(milestoneMarkerOffset)) res.push(0)
        else res.push(milestoneMarkerOffset)
      })

      return res
    }

    return []
  }, [sortedMilestones])

  const title = useMemo(() => {
    if(state?.state_title === "Cancelled") return ""

    return state?.state_title.replace("and", "&");
  }, [state?.state_title])

  const handleMouseMove = event => {
    setNewXPosRef.current(event.screenX)

    dragState(state, originalXPos, event.screenX, false)
  };

  // let containerWidth = state.widthPercent;

  const getBubbleClassNames = () => {
    if (isLast) {
      return ""
    }

    let classNames = []
    if (isWorkflowComplete) return `bubble-complete bubble-activity-complete`;
    if (isWorkflowCancelled || readOnly) return `no-grab`;

    if ((isStateComplete || isStateBypassed || state.is_current)) {
      classNames.push(`bubble-complete`)
    }

    if (classNames.length === 0) return "";

    return classNames.join(" ")
  }

  const getCurrentProgressBarClassNames = () => {
    let classNames = [];
    if (isWorkflowComplete) return `progress-complete`;
    if (isLast) return classNames.join(" ");

    if (isLate) classNames.push('progress-late')
    if (isWorkflowCancelled) {
      classNames.push('progress-full-width')
      classNames.push('progress-late')
    }

    if (isOnHold) classNames.push('progress-onhold')
    else if (state.is_current) {
      let today = dayjs();
      let endDate = dayjs(state.end_state_date);

      classNames.push("current-progress-bar")

      if (today.isBetween(startDate, endDate) || endDate.format("YYYY-MM-DD") === today.format("YYYY-MM-DD")) {
        let daysUntilDue = endDate.diff(today, 'day');
        if (daysUntilDue < 20) classNames.push('progress-late')
      }
    }

    if (classNames.length === 0) return "";
    return classNames.join(" ")
  }

  const getDiamondClassNames = () => {
    let classNames = [];

    if (isOnHold) {
      classNames.push("hide")
    } else if (isLate || isWorkflowCancelled) {
      classNames.push("diamond-late")
      return classNames
    }

    if (state.is_current) {
      let today = dayjs();

      if (today.isBetween(startDate, endDate) || endDate.format("YYYY-MM-DD") === today.format("YYYY-MM-DD")) {
        let daysUntilDue = endDate.diff(today, 'day');
        if (daysUntilDue < 20) classNames.push("diamond-late")
      }
    }
    return classNames.join(" ")
  }

  const getCurrentBarWidth = () => {
    if (isOnHold || isWorkflowCancelled) return 'calc(100%)';
    if (isWorkflowComplete && isLast) return "0px";
    else if (isWorkflowComplete) return `calc(100%)`;

    let today = dayjs();

    if (today.isBetween(startDate, endDate)) {
      // let totalDays = endDate.diff(startDate, 'day');
      let todayFromStart = today.diff(startDate, 'day');

      let percent = (todayFromStart / totalDays) * 100;

      return percent
    }
    return 100
  }

  const renderMarkers = (milestoneOffsets) => {
    return sortedMilestones.map((milestone, i) => {

      return (
        <MilestoneMarker
          key={i + "-milestone-date"}
          milestoneIndex={i}
          milestone={milestone}
          milestones={sortedMilestones}
          isLast={isLast}
          state={state}
          stateIndex={index}
          tempTrackerData={tempTrackerData}
          offset={milestoneOffsets[i]}
          milestoneOffsets={milestoneOffsets}
          pizzaTrackerWrapperRef={pizzaTrackerWrapperRef}
          milestoneMarkerIndex={i}
          trackerMarginTop={trackerMarginTop}
          setTrackerMarginTop={setTrackerMarginTop} />)
    })
  }

  const renderCurrentBarLabel = () => {
    if (isWorkflowCancelled) {
      return (
        <div className="progress-bar-label-container" style={{ left: "calc(50% + 10px)" }}>
          <p className="progress-bar-label">Cancelled</p>
        </div>
      )
    }
    if (isOnHold) {
      return (
        <div className="progress-bar-label-container" style={{ width: 46 }}>
          <p className="progress-bar-label">On hold</p>
        </div>
      )
    }
  }

  const getNumOfDays = () => {
    let today = dayjs()

    if (isToday) {
      let fromStartToToday = today.diff(startDate, "day")
      let fromTodayToEnd = dayjs(endDate).diff(today, "day")

      setFromStartToToday(fromStartToToday)
      setFromTodayToEnd(fromTodayToEnd)
    }

    setNumOfDays(totalDays)
  }

  let todayMarkerOffset = useMemo(() => {
    let offset = 0
    let daysFromStart = null;
    let daysFromEnd = null;

    if (isToday) {
      daysFromStart = dayjs().diff(startDate, 'day')
      daysFromEnd = endDate.diff(dayjs(), 'day')
      offset = Math.ceil((daysFromStart / totalDays) * 100)
    }

    return offset
  }, [state, isToday])

  let dragStateLeft = -8
  let dragLeftDiff = (originalXPos - newXPos) * -1;


  const draggableStateStyle = {
    left: dragStateLeft + dragLeftDiff
  }

  const renderBubble = () => {
    if (isFirst === false) {
      let prevState = tempTrackerData[index - 1];

      if (prevState.is_current) {
        if (prevState.is_onhold) {
          return (
            <div
              className={`bubble-container ${isDragging && 'dragging-state'} ${getBubbleClassNames()} ${showStateClicked && 'show-bubble-outline'}`}
              onMouseDown={onLongPress}
              style={{ border: "none" }}
            >
              <i className="fal fa-pause-circle onhold-icon" style={{ fontSize: 22 }}></i>
            </div>
          )
        } else if (prevState.state_title === "Cancelled") {
          return (
            <div
              className={`bubble-container ${isDragging && 'dragging-state'} ${getBubbleClassNames()} ${showStateClicked && 'show-bubble-outline'}`}
              onMouseDown={onLongPress}
              style={{ border: "none" }}
            >
              <i className="fal fa-times-circle cancelled-icon" style={{ fontSize: 22 }}></i>
            </div>
          )
        }
      }
    }

    return (
      <div
        className={`bubble-container ${isDragging && 'dragging-state'} ${getBubbleClassNames()} ${showStateClicked && 'show-bubble-outline'}`}
        onMouseDown={onLongPress}
      >
      </div>
    )
  }

  const renderLastBubble = () => {
    if (isWorkflowCancelled) {
      return (
        <i
          className={`${getBubbleClassNames()} ${showStateClicked && 'show-last-bubble-dragging'} fal fa-times-circle last-bubble last-bubble-icon`}
          onMouseDown={onLongPress}
          style={{ color: "#D7542C" }}></i>
      )
    }

    return isWorkflowComplete ? (
      <div
        className={`last-bubble last-bubble-complete ${isDragging && 'dragging-state'}`}>
        <i className="fal fa-check"></i>
      </div>
    ) : (
      <i
        className={`${getBubbleClassNames()} ${showStateClicked && 'show-last-bubble-dragging'} fal fa-check-circle last-bubble last-bubble-icon`}
        onMouseDown={onLongPress}></i>
    )
  }

  const closeGhostState = () => {
    setShowGhostState(false)
    setOriginalXPosRef.current(newXPosRef.current)
    originalXPosRef.current = newXPosRef.current
  }

  const renderDays = () => {
    let classNames = ["num-of-days", "num-of-days-other"]

    if (isOnHold) {
      classNames.push('hide')
    }

    if (isWorkflowComplete) {
      classNames.push('num-of-days-activity-complete')
    } else if (isStateComplete || isStateBypassed) {
      classNames.push('num-of-days-complete')
    }

    return <p className={classNames.join(' ')}>{numOfDays}</p>;
  }

  const renderStartToTodayDays = () => {
    let classNames = ["num-of-days", "num-of-days-start-to-today"]
    let currentBarWidth = getCurrentBarWidth()
    let style = {
      color: "#fff"
    }

    if (isWorkflowComplete || isOnHold || isWorkflowCancelled || currentBarWidth < 15) classNames.push("hide")

    return (
      <p className={classNames.join(' ')} style={style}>
        {fromStartToToday}
      </p>
    )
  }

  const renderTodayToEndDays = () => {
    let classNames = ["num-of-days", "num-of-days-today-to-end"]
    let currentBarWidth = getCurrentBarWidth()
    let todayToEndWidth = 0;
    if (currentBarWidth !== 100) {
      todayToEndWidth = 100 - ((100 - currentBarWidth) / 2)
    }

    if (isWorkflowComplete || isOnHold || isWorkflowCancelled || currentBarWidth >= 85) classNames.push("hide")

    return (
      <p
        className={classNames.join(' ')}
        style={{
          left: `calc(${todayToEndWidth}% - 5px)`
        }}>
        {fromTodayToEnd}
      </p>
    )
  }

  const renderDiamond = () => {
    return (
      <div
        id="diamond"
        className={getDiamondClassNames()}
        style={{
          right: -8.5,
          marginLeft: "auto"
        }} />
    )
  }
  
  return (
    <div
      className={`state-container ${isLast && 'last-state'} ${isOnHold && "onhold-background"}`}
    >
      {isFirst && (
        <div className={`first-bubble ${isDragging && 'dragging-state'} ${getBubbleClassNames()}`} onMouseDown={onLongPress}>
          <i className="fas fa-chevron-right"></i>
        </div>
      )}
      {!isLast && !isFirst && renderBubble()}
      {isLast && renderLastBubble()}
      {showDays && renderDays()}

      {showGhostState && (
        <div
          className="draggable-state"
          style={draggableStateStyle}>
          <GhostState
            ghostState={ghostState}
            setGhostState={setGhostState}
            showGhostState={showGhostState}
            setShowGhostState={setShowGhostState}
            index={index}
            isLast={isLast}
            dateFormat={dateFormat}
          />
          {showStateClicked && (
            <div id="arrowAnim-left">
              <div className="arrowSliding">
                <div className="arrow"></div>
              </div>
              <div className="arrowSliding delay1">
                <div className="arrow"></div>
              </div>
              <div className="arrowSliding delay2">
                <div className="arrow"></div>
              </div>
              <div className="arrowSliding delay3">
                <div className="arrow"></div>
              </div>
            </div>
          )}
          {showStateClicked && (
            <div id="arrowAnim-right">
              <div className="arrowSliding">
                <div className="arrow"></div>
              </div>
              <div className="arrowSliding delay1">
                <div className="arrow"></div>
              </div>
              <div className="arrowSliding delay2">
                <div className="arrow"></div>
              </div>
              <div className="arrowSliding delay3">
                <div className="arrow"></div>
              </div>
            </div>
          )}
        </div>
      )}
      <div className={`date-container ${isLast && 'last-date-container'}`}>
        <div className={`date-line ${showGhostState && 'date-line-showing-ghost-state'}`}></div>
        <div
          className="start-date-container no-text-highlight"
          style={{ color: (isWorkflowComplete || isWorkflowCancelled || isStateComplete || isStateBypassed) && "#ADAFB0" }}>
          {isStateBypassed
            ? "Bypassed"
            : startDate.format(userSettings?.date_format)
          }
        </div>
      </div>
      <ConfirmChangesModal
        state={state}
        ghostState={ghostState}
        closeGhostState={closeGhostState}
        onSaveStateDates={(ghostState) => {
          closeGhostState()
          onSaveStateDates(ghostState)
        }}
        onRevertStateDates={() => {
          closeGhostState()
          onRevertStateDates()
        }}
        openConfirmDateChangeModal={openConfirmDateChangeModal}
        setConfirmDateChangeModal={setConfirmDateChangeModal}
        dateFormat={userSettings?.date_format}
        index={index}
        isLast={isLast} />
      {isComplete && (
        <div className={`complete-progress-bar ${isWorkflowComplete && `progress-complete`}`}>
        </div>
      )}

      {(state.is_current || isWorkflowComplete) && (
        <div
          className={`${getCurrentProgressBarClassNames()}`}
          style={{ width: `calc(${getCurrentBarWidth()}%)` }}
          ref={currentBarRef}>
          <div style={{ position: "relative", width: "100%" }}>
            {renderCurrentBarLabel()}
            {!isWorkflowComplete && renderDiamond()}
            {/* {showDays && renderStartToTodayDays()} */}
          </div>
        </div>
      )}

      {isToday && (
        <Tooltip
          title={`Today's date: ${dayjs().format(userSettings?.date_format)}`}>
          <i
            style={{
              left: `calc(${todayMarkerOffset}% + 4px)`
            }}
            className="fas fa-caret-down today-marker" />
        </Tooltip>
      )}

      {showDueDates && milestones && renderMarkers(milestoneOffsets)}

      {!isLast && <div className={`state-title-container ${isLast && 'last-state-title-container'}`}>
        <p
          className="state-title no-text-highlight"
          style={{ color: (isWorkflowComplete || isWorkflowCancelled || isStateComplete || isStateBypassed) && "#ADAFB0" }}>
          {title}
        </p>
        <div className="assignee-container" style={isLast ? { justifyContent: "center" } : {}}>
          {showAssignees && state?.assigned_to_names?.filter(person => person?.trim().length > 1).map((person, i) => {
            return (
              <UserBadge
                key={i}
                user={person} />
            )
          })}
        </div>
      </div>}
    </div>
  );
};

export default State;