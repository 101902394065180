/** DataSharing.Requests.Documents API endpoint */
import getUrl from 'api/apiMap'
import useGetApi from '../useGetApi'
import useMutateApi from '../useMutateApi'

// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

const usePostAttachRequestDocument = (request_pk) => {
  return useMutateApi({
    method: 'post',
    apiEndpoint: getUrl(`${apiEndpoints.requests}${request_pk}/document-attach/`),
    invalidateQueries: [queryKeys.documents.all],
    successMsg: 'Successfully attached document to request',
  })
}

const useGetRequestDocuments = (request_pk, options) => {
  return useGetApi(`${getUrl(apiEndpoints.requests)}${request_pk}/documents/`, queryKeys.documents.requestList(request_pk), options)
}

// NOTE: using usePostDocument instead of usePostRequestDocument below on Add File modal from Files page and File details page
const usePostRequestDocument = (request_pk) => {
  return useMutateApi({
    method: 'post',
    apiEndpoint: getUrl(`${apiEndpoints.requests}${request_pk}/documents/`),
    invalidateQueries: [queryKeys.documents.all],
    successMsg: 'Successfully created document for request',
  })
}

export {
  usePostAttachRequestDocument,
  usePostRequestDocument,
  useGetRequestDocuments,
}