import { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useShallow } from 'zustand/react/shallow'

// components
import PrimeField from 'components/PrimeField/PrimeField';
import ArmActions from '../../../components/ArmActions/ArmActions';
import CellValidation from '../../../components/CellValidation/CellValidation';

// context
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';
import useResultsTableViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableViewStore';

// utils
import { moveLeftHelper, moveRightHelper } from '../../helpers/helpers';

const Arm = ({
  arm,
  arms,
  rowIndex,
  colIndex,
  fieldKey,
  fieldSchema,
  schema,
  adverseData,
  setAdverseData,
}) => {
  const [showActions, setShowActions] = useState(false)
  const { ref: visibleRef, inView: inViewport } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  });

  const {
    readOnly
  } = useAuthoringViewStore(
    useShallow(state => ({ readOnly: state.readOnly }))
  )

  const {
    tableView
  } = useResultsTableViewStore()

  const onChange = (e) => {
    let tempData = { ...adverseData }

    const updateSubjectsExposed = (registry, foundGroupIndex) => {
      let keys = ["serious_adverse_events", "non_serious_adverse_events"];

      if (registry === "ctg") keys = ["serious_adverse_events"]
      keys.forEach(key => {
        tempData[registry][key].forEach(event => {
          let foundValueIndex = event.values.findIndex(item => item.registry_arm_id === arm.registry_arm_id);

          if (foundValueIndex !== -1) {
            let value = event.values[foundValueIndex];
            if (value["num_subjects"] === tempData[registry].groups[foundGroupIndex][fieldKey] ||
              (!value["num_subjects"] && !tempData[registry].groups[foundGroupIndex][fieldKey])) {
              value.num_subjects = e
            }
          }
        })
      })
    }

    Object.keys(tempData).forEach(registry => {
      let foundGroupIndex = tempData[registry].groups.findIndex(item => item.registry_arm_id === arm.registry_arm_id);
      if (foundGroupIndex !== -1 && schema.properties[registry].groups.properties[fieldKey]) {
        if (fieldKey === "part_at_risk_serious_events") updateSubjectsExposed(registry, foundGroupIndex)
        tempData[registry].groups[foundGroupIndex][fieldKey] = e;
      }
    })

    setAdverseData(tempData)
  }

  const handleLeft = () => {
    let tempData = { ...adverseData }

    Object.keys(tempData).forEach(registry => {

      // move serious adverse events
      tempData[registry].serious_adverse_events.forEach(event => {
        let foundEventIndex = event.values.findIndex(value => value.registry_arm_id === arm.registry_arm_id);
        if (foundEventIndex !== -1 && foundEventIndex !== 0) moveLeftHelper(event.values, foundEventIndex)
      })

      // move non serious adverse events
      tempData[registry].non_serious_adverse_events.forEach(event => {
        let foundEventIndex = event.values.findIndex(value => value.registry_arm_id === arm.registry_arm_id);
        if (foundEventIndex !== -1 && foundEventIndex !== 0) moveLeftHelper(event.values, foundEventIndex)
      })

      // move arm
      let foundGroupIndex = tempData[registry].groups.findIndex(item => item.registry_arm_id === arm.registry_arm_id);
      if (foundGroupIndex !== -1 && foundGroupIndex !== 0) moveLeftHelper(tempData[registry].groups, foundGroupIndex)
    })

    setAdverseData(tempData);
  }

  const handleRight = () => {
    let tempData = { ...adverseData }

    Object.keys(tempData).forEach(registry => {

      // move serious adverse events
      tempData[registry].serious_adverse_events.forEach(event => {
        let foundEventIndex = event.values.findIndex(value => value.registry_arm_id === arm.registry_arm_id);
        if (foundEventIndex !== -1 && foundEventIndex !== event.values.length - 1) moveRightHelper(event.values, foundEventIndex)
      })

      // move non serious adverse events
      tempData[registry].non_serious_adverse_events.forEach(event => {
        let foundEventIndex = event.values.findIndex(value => value.registry_arm_id === arm.registry_arm_id);
        if (foundEventIndex !== -1 && foundEventIndex !== event.values.length - 1) moveRightHelper(event.values, foundEventIndex)
      })

      // move arm
      let foundGroupIndex = tempData[registry].groups.findIndex(item => item.id === arm.registry_arm_id);
      if (foundGroupIndex !== -1 && foundGroupIndex !== tempData[registry].groups.length - 1) moveRightHelper(tempData[registry].groups, foundGroupIndex)
    })

    setAdverseData(tempData);
  }

  const onDeleteArm = (arm, applyAll) => {
    let tempData = { ...adverseData };

    Object.keys(tempData).forEach(registry => {
      if (tableView === registry || applyAll) {
        // delete serious adverse eventsvar displayGroups
        tempData[registry].serious_adverse_events.forEach(event => {
          let foundEventIndex = event.values.findIndex(value => value.registry_arm_id === arm.registry_arm_id);
          if (foundEventIndex !== -1) event.values.splice(foundEventIndex, 1);
        })

        // delete non-serious adverse events
        tempData[registry].non_serious_adverse_events.forEach(event => {
          let foundEventIndex = event.values.findIndex(value => value.registry_arm_id === arm.registry_arm_id);
          if (foundEventIndex !== -1) event.values.splice(foundEventIndex, 1);
        })

        // delete group
        let foundGroupIndex = tempData[registry].groups.findIndex(item => item.registry_arm_id === arm.registry_arm_id);
        if (foundGroupIndex !== -1) tempData[registry].groups.splice(foundGroupIndex, 1);
      }
    })

    setAdverseData(tempData);
  }

  let isManualOverride = fieldKey === "title" || fieldKey === "description"

  return (
    <td
      ref={visibleRef}
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}>
      {inViewport ? (
        <>
          <PrimeField
            schema={{
              type: fieldSchema.type,
              items: fieldSchema.emu,
              readOnly: readOnly
            }}
            readOnly={readOnly}
            onChange={e => onChange(e)}
            disabled={isManualOverride}
            value={arm[fieldKey]} />
          <CellValidation
            table="adverse"
            errorKey={`results_adverse_events_adverse_events_table_${tableView}_groups_${colIndex + 1}_${fieldKey}`} />
          {readOnly !== true &&
            <ArmActions
              arms={arms}
              arm={arm}
              colIndex={colIndex}
              showActions={showActions}
              handleLeft={rowIndex === 0 ? handleLeft : null}
              handleRight={rowIndex === 0 ? handleRight : null}
              canDelete={rowIndex === 0 ? true : false}
              handleDeleteArm={onDeleteArm}
              setShowActions={setShowActions}
              jpath={`ae-arm-${arm.id}-${fieldKey}`}
              source={"adverse-events"}
              manualOverride={isManualOverride} />
          }
        </>
      ) : (
        <div style={{ padding: 10 }} />
      )}
    </td>
  );
};

export default Arm;