import useGetApi from "../useGetApi"
import useMutateApi from "../useMutateApi"

// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

// utils
import getUrl from "api/apiMap"

export const useGetWorkflow = ({
  workflowId,
  workflowModel
}) => {
  return useGetApi(`${getUrl(apiEndpoints.workflow)}${workflowId}/tracker`, queryKeys.workflows.detail(workflowId), {}, { model: workflowModel })
}

export const usePutWorkflowTracker = ({
  workflowId,
  workflowModel
}) => {
  return useMutateApi({
    method: "put",
    apiEndpoint: `${getUrl(apiEndpoints.workflow)}${workflowId}/`,
    invalidateQueries: [queryKeys.workflows.detail(workflowId)],
    successMsg: "Sucessfully updated " + workflowModel,
  })
}

export const usePostProgressWorkflow = ({
  workflowId,
  workflowModel
}) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: `${getUrl(apiEndpoints.workflow)}${workflowId}/progress/`,
    invalidateQueries: [queryKeys.workflows.detail(workflowId)],
    successMsg: "Sucessfully progressed " + workflowModel,
  })
}

export const usePostForceWorkflow = ({
  workflowId,
  workflowModel
}) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: `${getUrl(apiEndpoints.workflow)}${workflowId}/force_state/`,

    invalidateQueries: [queryKeys.workflows.detail(workflowId)],
    successMsg: "Sucessfully progressed " + workflowModel,
  })
}

export const usePostCancelWorkflow = ({
  workflowId,
  workflowModel
}) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: `${getUrl(apiEndpoints.workflow)}${workflowId}/cancel/`,
    invalidateQueries: [queryKeys.workflows.detail(workflowId)],
    successMsg: "Sucessfully progressed " + workflowModel,
  })
}

export const usePostHoldWorkflow = ({
  workflowId,
  workflowModel
}) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: `${getUrl(apiEndpoints.workflow)}${workflowId}/hold/`,
    invalidateQueries: [queryKeys.workflows.detail(workflowId)],
    successMsg: "Sucessfully progressed " + workflowModel,
  })
}

export const usePostUnholdWorkflow = ({
  workflowId,
  workflowModel
}) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: `${getUrl(apiEndpoints.workflow)}${workflowId}/unhold/`,
    invalidateQueries: [queryKeys.workflows.detail(workflowId)],
    successMsg: "Sucessfully progressed " + workflowModel,
  })
}

export const usePostRereview = ({
  workflowId,
}) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: `${getUrl(apiEndpoints.workflow)}${workflowId}/rereview/`,
    invalidateQueries: [queryKeys.workflows.detail(workflowId)],
    successMsg: "Sucessfully sent review",
  })
}