// TODO: compare with src/containers/ActivityDashboard/ActivityCountBox/ActivityCountBox.jsx, refactor this component or replace with ActivityCountBox
import React from "react"
import PropTypes from "prop-types"
import StatusIndicator from '../StatusIndicator/StatusIndicator'
import './ActivityStatusBox.scss'

const ActivityStatusBox = ({
  name,
  value,
  bgColor,
  variant,
  style,
  action = () => { },
  view,
  // isDisabled,
}) => {
  let customName = ""
  if (variant === "on-time") {
    customName = "On Time"
  } else if (variant === "at-risk") {
    customName = "At Risk"
  } else if (variant === "late") {
    customName = "Late"
  } else {
    customName = name
  }

  let status = name
  let state = null
  if (customName === "Total Activities") {
    status = null
    state = null
  } else if (customName === "Planned"
    || customName === "On Hold"
    || customName === "Cancelled") {
    state = customName
    status = null
  }

  return (
    <div
      style={style}
      onClick={() => action(view, status, null, null, null, state)}
      className="activity-status-box"
    >
      <span className="status-box-header">
        {(variant || bgColor) && <StatusIndicator
          variant={variant}
          bgColor={bgColor}
        />}
        <p className="status-box-title">{customName}</p>
      </span>
      <p className="status-box-value">{value && value}</p>
    </div>
  )
}

ActivityStatusBox.propTypes = {
  variant: PropTypes.oneOf(['on-time', 'at-risk', 'late']),
  // Custom name field
  name: PropTypes.string,
  value: PropTypes.number.isRequired,
  // Custom background color for status indicator
  bgColor: PropTypes.string

}

export default ActivityStatusBox