// rename to DateCellRenderer.jsx so as not to conflict with Date javascript object
import dayjs from 'dayjs'
import { useUserSettings } from 'api/hooks'

const Date = ({
  value,
}) => {
  const { data: userSettings, isLoading } = useUserSettings()

  if (!value || isLoading) return null

  const dateValue = () => {
    if (userSettings?.date_format) {
      return dayjs(value).format(userSettings.date_format)
    }
    return dayjs(value).format("MM/DD/YYYY")
  }

  return <div>
    {dateValue()}
  </div>
}

export default Date