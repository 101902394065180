/** wrapper for base field in the panel on Request Overview tab and Reviewer/Approver view with specific styles for panel fields */
import * as React from 'react'
import PropTypes from 'prop-types'
import RequestBaseFields from 'containers/requests/Request/tabs/RequestOverview/RequestBaseFields/RequestBaseFields'

// apis
import { useUserSettings } from 'api/hooks'

// styles
import './RequestFieldPanelWrapper.scss'

const RequestFieldPanelWrapper = ({
  request,
  onClick,
}) => {
  const { isLoading: isUserSettingsLoading } = useUserSettings()
  if(!request || isUserSettingsLoading) return 
  
  return <div
    onClick={onClick ? onClick : null}
    className="request-field-panel-wrapper"
  >
    <RequestBaseFields
      requestFormData={request}
      readOnly
    />
  </div>
}

RequestFieldPanelWrapper.propTypes = {
  request: PropTypes.object,
}

export default RequestFieldPanelWrapper