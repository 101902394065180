import React from 'react';
import { useShallow } from 'zustand/react/shallow'
import { useSearchParams } from 'react-router-dom'

// apis
import { useGetAuthoringComments } from 'api/hooks/studies/useAuthoringApi';
import { useGetAuthoringReviewMetadata } from 'api/hooks/studies/useAuthoringReviewApi';
import { useGetCommentTypes } from 'api/hooks/admin/useCommentTypesApi';

// hooks
import useAuthoringViewStore from '../stores/useAuthoringViewStore';
import useAuthoringCommentsStore from '../stores/useAuthoringCommentsStore';
import useAuthoringStore from '../stores/useAuthoringStore';

const useAuthoringComments = () => {
  const [searchParams] = useSearchParams()

  const {
    authoringId
  } = useAuthoringStore(
    useShallow(state => ({
      authoringId: state.authoringId
    }))
  )

  const {
    isReviewerView
  } = useAuthoringViewStore(
    useShallow(state => ({
      isReviewerView: state.isReviewerView
    }))
  )

  const {
    setCommentsMap
  } = useAuthoringCommentsStore(
    useShallow(state => ({
      setCommentsMap: state.setCommentsMap
    }))
  )

  const projId = React.useMemo(() => {
    return searchParams.get('proj-id')
  }, [])

  const taskId = React.useMemo(() => {
    return searchParams.get('task-id')
  }, [])

  const { data: comments = [] } = useGetAuthoringComments(authoringId)
  const { data: commentTypes } = useGetCommentTypes()
  const { data: authReviewData } = useGetAuthoringReviewMetadata(isReviewerView === true ? authoringId : null, isReviewerView === true ? { "project-id": projId, "actionitem-id": taskId } : {})

  React.useEffect(() => {
    let map = {};
    let filteredCommentTypes = commentTypes?.filter(type => authReviewData?.comment_types?.findIndex(id => id === type.id) !== -1)

    comments.forEach(comment => {
      let include = true
      if (isReviewerView) {
        include = comment.tags.some(tag => {
          const found = filteredCommentTypes?.findIndex(type => type.name === tag) !== -1
          return found
        })
      }

      if (include) {
        if (map[comment.field_key]) {
          let comments = map[comment.field_key]
          comments.push(comment)
          map[comment.field_key] = comments
        } else {
          let comments = [comment];
          map[comment.field_key] = comments
        }
      }
    })

    setCommentsMap(map)
  }, [
    comments,
    isReviewerView,
    authReviewData,
    commentTypes
  ])

  return {
    comments
  }
};

export default useAuthoringComments;