import React, { useContext } from 'react';

// hoc 
import InputWrapper from '../../hoc/InputWrapper';
import Note from 'components/Note/Note';
import TextInput from 'components/TextInput/TextInput';

const RJTextInput = (props) => {
  let {
    id,
    carbonProps,
    invalid,
    invalidText,
    value,
    onChange,
    onFocus,
    onBlur,
  } = props;

  let type = "text";
  let variant, placeholder;
  if (carbonProps.type) type = carbonProps.type;

  if (invalid) {
    variant = "error"
  }

  if(carbonProps.defaultValue === false) {
    return (
      <>
        <TextInput
          status={variant}
          id={id + "-field"}
          className="rjs-widget"
          disabled={carbonProps?.disabled}
          value={value}
          onChange={e => onChange(e.target.value)}
          onFocus={onFocus}
          style={carbonProps?.styles && carbonProps.styles.widget}
          onBlur={onBlur} />
        {invalid && <Note type="error" text={invalidText} style={{ marginTop: 4 }} />}
      </>
    );
  }

  return (
    <>
      <TextInput
        status={variant}
        id={id + "-field"}
        className="rjs-widget"
        disabled={carbonProps?.disabled}
        defaultValue={value}
        onChange={e => onChange(e.target.value)}
        onFocus={onFocus}
        style={carbonProps?.styles && carbonProps.styles.widget}
        onBlur={onBlur} />
      {invalid && <Note type="error" text={invalidText} style={{ marginTop: 4 }} />}
    </>
  );
};

export default InputWrapper(RJTextInput);