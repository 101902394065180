import { capitalize } from "lodash";

const buildErrorDetailsNotif = (details = {}) => {
  let notif = []
  Object.keys(details).forEach(key => {
    let label = capitalize(key)
    notif.push(<p className="label">{label.split("_").join(" ")}</p>)

    if (Array.isArray(details[key])) {
      notif.push(details[key].join(", "))
    } else {
      Object.keys(details[key]).forEach(index => {
        let item = details[key][index]
        notif.push(<p>&nbsp;&nbsp;{(Number(index) + 1)}.</p>)

        Object.keys(item).forEach(subKey => {
          let sublabel = capitalize(subKey)
          notif.push(<p>&nbsp;&nbsp;&nbsp;&nbsp;{sublabel}: {item[subKey].join(", ")}</p>)
        })
      })
    }

    notif.push(<br />)
  })

  return (<div className="error-details-container">
    {notif}
  </div>)
}

export default buildErrorDetailsNotif