import { useState, useEffect } from "react";

import PrimeField from "components/PrimeField/PrimeField";
import Note from "components/Note/Note";

// hoc 
import InputWrapper from '../../hoc/InputWrapper';

const RJSelectTag = (props) => {
  const [items, setItems] = useState([]);
  let {
    id,
    carbonProps,
    options,
    value,
    onChange,
    invalid,
    invalidText,
  } = props;
  useEffect(() => {
    if (options && options.enumOptions) {
      let newItems = options.enumOptions.map((option) => {
        return { id: option.value, text: option.value };
      });
      setItems(newItems);
    }
  }, [options]);

  return (
    <div>
      <PrimeField
        schema={{
          type: "dropdown",
          id: `${id}-field`,
          mode: "tags",
          items: value || [],
          disabled: carbonProps?.disabled,
        }}
        onChange={onChange}
        defaultValue={value} />
      {invalid && <Note type="error" text={invalidText} style={{ marginTop: 4 }} />}
    </div>
  );
};

export default InputWrapper(RJSelectTag);