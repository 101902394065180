import React from 'react';
import UserBadge from 'components/badges/UserBadge/UserBadge';

const UserFullnameCellRenderer = ({ value }) => {
  if (!value) {
    return
  }

  if (Array.isArray(value)) {
    return <div style={{ display: "flex", gap: 4, flexWrap: "wrap" }}>
      {value.map(user => (<UserBadge user={user} />))}
    </div>
  }
  return <UserBadge user={value} />
};

export default UserFullnameCellRenderer;