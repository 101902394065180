import { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow'
import XMLParser from 'react-xml-parser';
import FileUpload from 'components/files/FileUpload/FileUpload';
import dayjs from 'dayjs';

// utilities
import convertFileSize from 'utilities/convertFileSize';

// hooks
import useFileLoader from 'utilities/useFileLoader';

// context
import useResultsTableViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableViewStore';
import useAuthoringDataStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringDataStore';

// api
import { useUserSettings } from 'api/hooks';

// styles
import './adverse-events-xml-import.scss';

const AdverseEventsXMLImport = (props) => {
  const {
    formData,
  } = useAuthoringDataStore(
    useShallow(state => ({
      formData: state.formData,
      setFormData: state.setFormData,
    }))
  )

  const {
    tableView
  } = useResultsTableViewStore()

  const {
    aeFile,
    handleFileload,
    showAeImportErrors,
    setShowAeImportErrors,
    aeImportError,
    setAeImportError
  } = props;

  const { data: userSettings } = useUserSettings()
  const {
    getFileLoadAreaProps,
  } = useFileLoader({ onLoad: handleFileload })

  let dateFormat = userSettings?.date_format

  useEffect(() => {
    if (aeFile) {
      readSingleFile(aeFile);
    } else {
      setShowAeImportErrors(false);
      setAeImportError("");
    }
  }, [aeFile])

  const readSingleFile = (file) => {
    if (!file) {
      return;
    }
    let reader = new FileReader();
    reader.onload = function (e) {
      let contents = e.target.result;
      var xml = new XMLParser().parseFromString(contents);    // Assume xmlText contains the example XML
      validateXML(xml);
    };
    reader.readAsText(file);
  }

  const validateXML = (xmlObj) => {
    if (tableView === "ctg") {
      if (xmlObj?.attributes?.eudractNumber) {
        setShowAeImportErrors(true)
        setAeImportError("You've uploaded a EudraCT study XML, please upload a CTG study XML or navigate to EudraCT view.")
        return
      }
      if (xmlObj?.children?.[0]?.children) {
        let nodes = xmlObj.children[0].children;
        let foundIdNode = nodes.find(node => node.name === "id_info");
        if (foundIdNode) {
          let foundStudyIdNode = foundIdNode?.children?.find(node => node.name === "org_study_id");
          if (foundStudyIdNode && formData?.protocol?.protocol_id) {
            if (foundStudyIdNode?.value?.toLowerCase() === formData.protocol.protocol_id.toLowerCase()) {
              setShowAeImportErrors(false);
              return
            }
          } else {
            setShowAeImportErrors(true)
            setAeImportError("The study does not match, please an XML associated with the current study.")
          }
        }
      }
    } else if (tableView === "eudract") {
      if (xmlObj?.attributes?.["xmlns:prs"] || xmlObj?.attributes?.["xmlns:ns0"] === "http://clinicaltrials.gov/prs") {
        setShowAeImportErrors(true)
        setAeImportError("You've uploaded a CTG study XML, please upload a EudraCT study XML or navigate to CTG view.")
        return
      }

      let trialInfo = xmlObj?.children?.find(child => child.name === "trialInformation");
      let sponsorProtocolCode = trialInfo?.children.find(child => child.name === "sponsorProtocolCode");
      let regNum = formData?.results?.trial_info?.trial_id?.reg_num?.toLowerCase()

      if (sponsorProtocolCode?.value && regNum) {
        if (sponsorProtocolCode?.value?.toLowerCase() == regNum) {
          setShowAeImportErrors(false);
          return
        }
      } else if (sponsorProtocolCode && regNum && sponsorProtocolCode !== regNum) {
        setShowAeImportErrors(true)
        setAeImportError("Invalid XML file, please use an XML associated with the current study.")
      } else if (!sponsorProtocolCode || !regNum) {
        setShowAeImportErrors(false);
        return
      } else {
        setShowAeImportErrors(true)
        setAeImportError("The study does not match, please an XML associated with the current study.")
      }
    }

    setShowAeImportErrors(true)
    setAeImportError("Invalid XML file, please use an XML associated with the current study.")
  }

  if (aeFile) {
    return (
      <div className="ae-import-container">
        <div className="col">
          <div className="row">
            <p className="title">Name:</p>&nbsp;
            <p className="value">{aeFile?.name}</p>
          </div>
          <div className="row">
            <p className="title">Type:</p>&nbsp;
            <p className="value">{aeFile?.type}</p>
          </div>
          <div className="row">
            <p className="title">Size:</p> &nbsp;
            <p className="value">{convertFileSize(Number(aeFile?.size), true)}</p>
          </div>
          <div className="row">
            <p className="title">Last modified:</p>&nbsp;
            <p className="value">{dayjs(aeFile?.lastModifiedDate).format(dateFormat)}</p>
          </div>
        </div>
        {showAeImportErrors &&
          <div className="error-container" style={{ color: "#D7542C" }}>
            <i className="fal fa-exclamation-circle" style={{ marginTop: 6 }}></i>
            <p className="error-text">{aeImportError}</p>
          </div>
        }
      </div>
    )
  } else {
    return (
      <div>
        <div {...getFileLoadAreaProps()}>
          <FileUpload
            onLoad={handleFileload}
          />
        </div>
        <div className="error-container" style={{ color: "#18669E", display: "flex" }}>
          <i className="fal fa-exclamation-circle" style={{ marginTop: 3 }}></i>
          <p className="error-text" style={{ margin: 0 }}>Only XML files are permitted for upload</p>
        </div>
      </div>
    )
  }
};

export default AdverseEventsXMLImport;