import React from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

const EudractArms = ({
  arms,
  schema,
  view,
}) => {

  const displayProducts = (arm) => {
    return (
      <div style={{ marginTop: 12, marginLeft: 12 }}>
        {arm.products.map((product, i) => (
          <ProductContainer key={uuidv4()}>
            <ArmTitle style={{ marginBottom: 8 }}>
              {i + 1}. Product
            </ArmTitle>
            {Object.keys(schema.properties[view].properties.products.properties).map(key => {
              let value = product[key]

              if ((key === "pharmaceutical_forms" || key === "routes_of_administration") && value && Array.isArray(value)) {
                value = product[key].join(", ")
              }
              return (
                <PropertyContainer key={uuidv4()}>
                  <PropertyTitle>
                    {schema.properties[view].properties.products.properties[key].title}
                  </PropertyTitle>
                  <PropertyValue>
                    {value}
                  </PropertyValue>
                </PropertyContainer>
              )
            }
            )}
          </ProductContainer>
        ))}
      </div>
    )
  }

  return (
    <Container>
      <Title>Arms</Title>
      {arms.map((arm, i) => (
        <ArmContainer key={uuidv4()}>
          <ArmTitle>
            {i + 1}. Arm
          </ArmTitle>
          {Object.keys(schema.properties[view].properties.arms.properties).filter(key => key !== "joined_reason_details").map(key => {
            let val = ""
            if (typeof arm[key] === 'string') val = arm[key]
            // else console.log({ val, arm, key })

            return (
              <PropertyContainer key={uuidv4()} >
                <PropertyTitle style={key === "products" ? { borderRight: "0px solid #000" } : {}}>
                  {schema.properties[view].properties.arms.properties[key].title}
                </PropertyTitle>
                <PropertyValue>
                  {val}
                </PropertyValue>
              </PropertyContainer>
            )
          })}
          {arm.products && displayProducts(arm)}
        </ArmContainer>
      ))}
    </Container>
  );
};

export default EudractArms;

const Container = styled.div`
  margin: 16px 0;
`

const Title = styled.div`
  padding-top: 3;
  padding-bottom: 3;
  font-size: 15;
  font-weight: bold;
  width: 100%;
`

const ArmContainer = styled.div`
  padding: 0 16px;
  margin-bottom: 8px;
  border-bottom: 1px solid #000;
`

const ArmTitle = styled.div`
  font-weight: bold;
`

const PropertyContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-top: 1px solid #000;
`

const PropertyTitle = styled.div`
  min-width: 30%;
  font-weight: 600;
  padding: 3px 3px 3px 1px;
  border-right: 1px solid #000;
  word-break: break-word;
  display: inline-block;
  overflow-wrap: break-word;
  white-wpace: pre-line;
`

const PropertyValue = styled.div`
  padding: 3px 3px 3px 3px;
  white-space: ;pre-line;
`

const ProductContainer = styled.div`

`