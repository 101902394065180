import React, { useState, useEffect } from 'react'
import { Tooltip } from 'antd'
import '../ActionItemDocs/action-item-docs.scss'

const ActionItemForms = (props) => {
  const [formCount, setFormCount] = useState(0)
  const [forms, setForms] = useState([])

  useEffect(() => {
    if(props.value) {
      let forms = props.value.split(", ")
      setForms(forms)
      setFormCount(forms.length)
    }
  }, [props.value])

  if (props?.value?.length > 0) {
    return (
      <Tooltip
        title={forms.join(", \n")}
      >
        <span className="action-item-text">{formCount}</span>
      </Tooltip>
    )
  }

  return <span className="action-item-text">0</span>
}

export default ActionItemForms