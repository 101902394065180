
import { useShallow } from 'zustand/react/shallow'

// components
import { Space, Radio } from 'antd';
import Note from 'components/Note/Note';

// hoc 
import InputWrapper from '../../hoc/InputWrapper';

// context
import useAuthoringDataStore from '../../../hooks/stores/useAuthoringDataStore';
import useAuthoringValidationsStore from '../../../hooks/stores/useAuthoringValidationsStore';
import './RJRadio.scss'

const RJRadio = (props) => {
  let {
    id,
    schema,
    carbonProps,
    onChange,
    value,
    invalid,
    invalidText
  } = props;
  const {
    formData,
  } = useAuthoringDataStore(
    useShallow(state => ({
      formData: state.formData,
    }))
  )

  const {
    registryValidations
  } = useAuthoringValidationsStore(
    useShallow(state => ({
      registryValidations: state.registryValidations,
    }))
  )

  // let val = value

  const handleChange = (e) => {
    console.log('Radio useEffect >> onChange')
    onChange(e.target.value)
  }

  let variant;

  if (invalid) {
    variant = "error"
  }

  let val = value;

  if (id === "root_protocol_study_oversight_us_drug_productexported_from_us") {
    val = formData?.protocol?.secondary_attributes?.usa?.manufactured_in_us
  }

  return (
    <>
      <Radio.Group
        disabled={carbonProps.disabled}
        onChange={handleChange}
        value={val}
        className='rjs-radio-widget'>
        <Space direction={carbonProps.orientation ? carbonProps.orientation : "horizontal"}>
          {schema.enum &&
            schema.enum.map((item, i) => {
              return (
                <Radio
                  id={id + "-" + i}
                  key={id + "-" + i}
                  value={item}>
                  {item}
                </Radio>
              );
            })}

        </Space>
      </Radio.Group>
      {invalid && <Note type="error" text={invalidText} style={{ marginTop: 4 }} />}
    </>
  );
};

export default InputWrapper(RJRadio);
