import { capitalizeFirstLetter } from './misc'

const prsFormatToVladFormat = (pathString) => {
  let m = pathString.match(/property:Study\.locations\[(\d+)\]/);
  // console.log("m: ", m)
  let siteIdx = m;
  pathString = pathString
    .replace(/locations\[(\d+)\]\./, "")
    .replace(/property:Study\./, "root_");

  pathString = pathString.replace(/([A-Z])([A-Za-z]*)/g, function (a, b, c) {
    return (
      b.toLowerCase() +
      c.replace(/([A-Z])/g, function (d, e) {
        return "_" + e.toLowerCase();
      })
    );
  });
  // pathString = pathString.replace(/_(\d+)_/g, "[$1].");
  pathString = pathString.replace(/\[(\d+)\]\./g, "_$1_");
  pathString = pathString.replace(".", "_");
  return pathString;
};

const convertPRSMessageToVladMessage = (message) => {
  let vladMessage = {};
  if (message.severity.$t === "NOTE") {
    vladMessage.type = "note";
  } else if (message.severity.$t === "ERROR") {
    vladMessage.type = "error";
  } else {
    vladMessage.type = "warning";
  }
  vladMessage.value = message.message.$t;
  return vladMessage;
};

export const validationMessagesForNode = (validationMessages) => {
  let ret = {};

  for (var i = 0; i < validationMessages.length; i++) {
    let validationMessage = validationMessages[i];
    let prsNodePath = validationMessage.source.$t;
    let jsNodePath = prsFormatToVladFormat(prsNodePath);

    let formatted = convertPRSMessageToVladMessage(validationMessage);
    formatted.key = jsNodePath;
    formatted.prsKey = prsNodePath;
    formatted.siteIdx = i;
    if (validationMessage.source.$t === prsNodePath) {
      formatted.level = "field";
    } else if (prsNodePath.startsWith(validationMessage.source.$t)) {
      formatted.level = "section";
    } else {
      continue;
    }
    ret[formatted.key] = formatted
  }

  return ret;
};




const prsFormatToModalFormat = (pathString) => {
  let m = pathString.match(/property:Study\.locations\[(\d+)\]/);
  // console.log("m: ", m)
  let siteIdx = m;
  pathString = pathString
    .replace(/locations\[(\d+)\]\./, "")
    .replace(/property:Study\./, "");

  pathString = pathString.replace(/([A-Z])([A-Za-z]*)/g, function (a, b, c) {
    return (
      b.toLowerCase() +
      c.replace(/([A-Z])/g, function (d, e) {
        return "_" + e.toLowerCase();
      })
    );
  });
  pathString = pathString.replace(/\[(\d+)\]\./g, "_$1_");
  return pathString;
};


export const findFieldAndValue = (message, sitesMap) => {
  let segments = message.source? message.source.split(".") : message?.field_key;

  let field = segments[segments.length - 1];
  let label = field.split(/(?=[A-Z])/).map(label => capitalizeFirstLetter(label)).join(" ")
  let value = null;

  if (message.site_db_id && sitesMap.has(message.site_db_id)) {
    let site = sitesMap.get(message.site_db_id);
    if (site.location_data && site.location_data.source) {
      let { source } = site.location_data;
      let path = prsFormatToModalFormat(message.source)
      let keys = path.split(".");
      let currObj = source;


      for (let i = 0; i < keys.length; i++) {
        let cKey = keys[i];
        if (currObj[cKey] && isObject(currObj[cKey])) {
          currObj = currObj[cKey]
        } else if (i === keys.length - 1 && currObj[cKey]) {
          value = currObj[cKey]
        }
      }
    }
  }

  if(message.text) {
    label = message.field_key
    value =  message.text;
  }

  return { label, value }
}

const isObject = (obj) => {
  return typeof obj === 'object' && obj !== null
}