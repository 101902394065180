import { useState, useEffect, useRef } from 'react';
import { useShallow } from 'zustand/react/shallow'

// components
import RJCommentList from '../../../components/RJCommentList/RJCommentList';

// context
import useAuthoringCommentsStore from '../../../../hooks/stores/useAuthoringCommentsStore';

// styles
import './RJTableCommentContainer.scss';

const RJTableCommentContainer = ({
  jpath,
  label,
  openCommentsForm,
  setOpenCommentsForm,
  setShowMenu,
  showMenu,
  style = {},
  modalStyle = {}
}) => {
  const {
    openComment,
    commentsMap
  } = useAuthoringCommentsStore(
    useShallow(state => ({ 
      openComment: state.openComment,
      commentsMap: state.commentsMap,
    }))
  )

  const [comments, setComments] = useState([]);
  let openCommentRef = useRef(null);
  const commentListRef = useRef();
  const labelMenuRef = useRef();

  useEffect(() => {
    if (commentsMap) {
      if (commentsMap[jpath]) {
        let comments = commentsMap[jpath];
        setComments(comments);
      } else {
        setComments([]);
      }
    }
  }, [commentsMap]);

  useEffect(() => {
    if (openComment && openComment === jpath) {
      if (setShowMenu) {
        setShowMenu(true);
      }
      setOpenCommentsForm(true)
      scrollToField(jpath)
      openCommentRef.current = openComment
    }
  }, [openComment])

  useEffect(() => {
    if (commentListRef?.current) {
      window.addEventListener("click", handleClickOutside);
    }

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [commentListRef, openCommentsForm])

  const handleClickOutside = e => {
    if (commentListRef.current?.contains(e.target) || labelMenuRef.current?.contains(e.target)) {
      return;
    }

    if (openCommentRef.current === null && openCommentsForm === true) {
      let field = document.getElementById(jpath + "-field")
      let comment = document.getElementById(jpath + "-side-panel-comment")
      if (field) {
        field.classList.remove("highlighted-field");
      }
      if (comment) {
        comment.classList.add("highlighted-comment");
      }
      setOpenCommentsForm(false);
    }
    openCommentRef.current = null;
  };

  const scrollToField = (elementId) => {
    const element = document.getElementById(elementId);
    const container = document.getElementsByClassName("right-section")[0];

    if (element && container) {
      const containerRect = container.getBoundingClientRect();
      const elementRect = element.getBoundingClientRect();

      const offsetTop = elementRect.top - containerRect.top;
      const offsetLeft = elementRect.left - containerRect.left;

      container.scrollTo({
        top: container.scrollTop + offsetTop - 150,
        left: offsetLeft,
        behavior: 'smooth'
      });
    }
  }

  return (
    <div
      className={`hide-container ${showMenu ? 'show-container' : ''}`}
      ref={labelMenuRef}
      id={jpath}>
      <i
        className={`fas comment-icon ${comments && comments.length > 0 ? 'has-comment fa-comment-alt-dots' : 'fa-comment-alt-medical'}`}
        onClick={() => {
          scrollToField(jpath)
          setOpenCommentsForm(true)
        }}
        style={style} />
      {openCommentsForm && (
        <div

          ref={commentListRef}
          className="animate__animated animate__fadeIn animate__fadeInUp animate__faster comment-modal-container"
          style={modalStyle}>
          <RJCommentList
            label={label}
            jpath={jpath}
            openForm={openCommentsForm}
            setOpenForm={setOpenCommentsForm}
            setShowMenu={setShowMenu}
            comments={comments}
          />
        </div>
      )}
    </div>
  );
};

export default RJTableCommentContainer;