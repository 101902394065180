import { formSchemas, getSchemaSectionTitle } from './authoring';

const submissionReasons = [
  "CSR/Results Addendum",
  "CSR/Results Amendment",
  "Delayed Results Request",
  "Initial Protocol Registration",
  "Initial Results Registration",
  "Protocol Amendment",
  "Protocol Maintenance",
  "Results Maintenance",
];

const getSubmissionSchemas = () => {
  let filteredSchemas = {};
  // iterate through schemas
  for (const sectKey in formSchemas) {
    const origProps = formSchemas[sectKey].properties;
    let filteredProps = null;
    for (const regKey in origProps) {
      // filter out "global" registries
      if (regKey !== 'global') {
        filteredProps = filteredProps || {};
        filteredProps[regKey] = origProps[regKey];
      };
    };
    // filter out schemas with only "global" registries
    if (filteredProps) {
      filteredSchemas[sectKey] = {...formSchemas[sectKey]};
      filteredSchemas[sectKey].properties = filteredProps;
    };
  };
  return filteredSchemas;
};

/**
 * @returns array of form sections in format { id, text }
 */
const getSubmissionFormSections = () => {
  return Object.keys(getSubmissionSchemas()).map((sectId) => {
    return { id: sectId, text: getSchemaSectionTitle(sectId) };
  });
};

/**
 * returns object in format:
 *     { protocl: "Protocol",
 *       results: "Results" }
 * "global" not included
 */
const getSubmissionSectionTitles = () => {
  let sections = {};
  const submissionSchemas = getSubmissionSchemas();
  for (const sectKey in submissionSchemas) {
    sections[sectKey] = submissionSchemas[sectKey].title;
  };
  return sections;
};

/**
 * returns object in format:
 *     { ctg: "CTG",
 *       eudra: "EudraCT" }
 * "global" not included
 */
const getSubmissionRegistryTitles = () => {
  let registries = {};
  const submissionSchemas = getSubmissionSchemas();
  for (const sectKey in submissionSchemas) {
    const regItems = submissionSchemas[sectKey].properties;
    for (const regKey in regItems) {
      if (regKey !== 'global') registries[regKey] = regItems[regKey].title;
    };
  };
  return registries;
};

export {
  submissionReasons,
  getSubmissionSchemas,
  getSubmissionFormSections,
  getSubmissionSectionTitles,
  getSubmissionRegistryTitles,
}