import React, { memo } from 'react';
import Form from "@rjsf/core";

// Widgets
import RJTextInput from '../Widgets/RJTextInput';
import RJTextArea from '../Widgets/RJTextArea';
import RJDropdown from '../Widgets/RJDropdown';
import RJRadioGroup from '../Widgets/RJRadioGroup';
import RJDatePicker from '../Widgets/RJDatePicker';
import RJMultiselect from '../Widgets/RJMultiselect';
import RJCheckBox from '../Widgets/RJCheckBox';
import RJAutocomplete from '../Widgets/RJAutocomplete';
import RJPassword from '../Widgets/RJPassword';

const FormContainer = ({
  formContext = {},
  schema,
  uiSchema,
  formData,
  onChange,
  ObjectFieldTemplate,
  ArrayFieldTemplate,
  // onSubmit,
  // showSubmit = false,
  // children,
}) => {
  const widgets = {
    TextWidget: RJTextInput,
    SelectWidget: RJDropdown,
    TextareaWidget: RJTextArea,
    RadioWidget: RJRadioGroup,
    DateWidget: RJDatePicker,
    MultiselectWidget: RJMultiselect,
    CheckBoxWidget: RJCheckBox,
    AutocompleteWidget: RJAutocomplete,
    PasswordWidget: RJPassword
  };

  return (
    <div className="form-container">
      <Form
        noValidate={true}
        formData={formData}
        schema={schema}
        formContext={formContext}
        uiSchema={uiSchema}
        widgets={widgets}
        ObjectFieldTemplate={ObjectFieldTemplate}
        ArrayFieldTemplate={ArrayFieldTemplate}
        onChange={e => onChange(e.formData)}
        onSubmit={() => { }}>
        <button
          type="submit"
          style={{ display: "none" }}
        >
          Submit
        </button>
      </Form>
    </div>
  )
}

export default memo(FormContainer)