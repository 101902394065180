import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components';

// Components
import { Button } from 'components-design-system'

// API
// import { getStudyDetails } from '../../api/authoring/locations';

// helper
import { findFieldAndValue } from '../../utilities/PRSValidation';

const PRSValidationMessages = ({
  origin,
  setOpenModal,
  onOpenModal,
  gridProps,
  messages,
  studyData = null,
  hideSubmit = false,
  hideActions = false
}) => {
  const [sitesMap, setSitesMap] = useState(new Map());

  useEffect(() => {
    if (gridProps.source) {
      if (gridProps.source === "study-details") {
        let tempMap = new Map();
        tempMap.set(gridProps?.node?.data?.id, gridProps?.node?.data);
        setSitesMap(tempMap)
      } else if (gridProps.source === "study-list") {
        if (gridProps.data) {
          // getStudyDetails(gridProps.data).then(res => {
          //   let tempMap = new Map();
          //   if (res) {
          //     res.forEach(site => {
          //       tempMap.set(site.id, site)
          //     })
          //   }
          //   setSitesMap(tempMap)
          // })
        }
      }
    }
  }, [gridProps?.node?.data, gridProps?.source])

  const onSaveChanges = () => {
    setOpenModal(false)
  }

  return (
    <Container>
      <ModalBody>
        <MessagesContainer>
          {messages && messages.map((item, i) => {
            return <Message
              key={item.source ? item.source : item.field_key + i}
              onOpenModal={onOpenModal}
              messages={messages}
              data={gridProps?.node?.data}
              studyData={studyData}
              message={item}
              sitesMap={sitesMap}
              origin={origin}
              hideActions={hideActions} />
          })}
        </MessagesContainer>
      </ModalBody>
      <ActionContainer>
        <Button
          variant="outlined"
          onClick={() => setOpenModal(false)}
          style={{ marginRight: 16 }}>
          Cancel
        </Button>
        {hideSubmit !== true &&
          <Button
            onClick={() => onSaveChanges()}>
            Save Changes
          </Button>
        }
      </ActionContainer>
    </Container>
  );
};

export default PRSValidationMessages;

const Message = ({
  data,
  message,
  sitesMap,
  onOpenModal,
  messages,
  studyData,
  origin,
  hideActions
}) => {
  const navigate = useNavigate()
  const [iconClass, setIconClass] = useState(null);
  const [type, setType] = useState("");
  const [selectedSite, setSelectedSite] = useState(null);
  const [fieldLabel, setFieldLabel] = useState("");
  const [fieldValue, setFieldValue] = useState("");

  useEffect(() => {
    let type = message.severity || message.type;
    if ((message.severity || message.type) && message.site_db_id) {
      if (type === "ERROR") setIconClass("fas fa-do-not-enter");
      else if (type === "WARNING") setIconClass("fas fa-exclamation-triangle");
      else if (type === "NOTE") setIconClass("fas fa-info-circle");
      setType(message.severity);
      if (sitesMap.has(message.site_db_id)) {
        setSelectedSite(sitesMap.get(message.site_db_id))
      }
    } else if (message.severity || message.type) {
      if (type === "ERROR") {
        setIconClass("fas fa-times-circle");
        setType("NON-SITE")
      } else {
        if (type === "WARNING") setIconClass("fas fa-exclamation-triangle");
        else if (type === "NOTE") setIconClass("fas fa-info-circle");
        setType(message.severity);
      }
    }
  }, [message, sitesMap])

  useEffect(() => {
    let { label, value } = findFieldAndValue(message, sitesMap);
    setFieldLabel(label);
    setFieldValue(value);
    // TODO: get value of field
  }, [message, sitesMap])

  const onEditSite = () => {
    onOpenModal("edit-site", { data: selectedSite }, data, messages)
  }

  let title = "";
  if (data && data.study_id) title = data.study_id;

  const getUrl = () => {
    let type = message.severity || message.type;
    let hash = "#locations"
    if (type === "WARNING" || type === "NOTE" || type === "NON-SITE") hash = `#study-data`
    return `/study/${data.uniqid}/${hash}`
  }

  return (
    <MessageContainer>
      <MessageInfoContainer>
        <Text style={{ marginBottom: 16 }}>
          {title}
        </Text>
        {message.site_db_id &&
          <FieldValueContainer>
            <Text style={{ color: "#525252" }}>{fieldLabel}</Text>
            <Text style={{ marginLeft: 16 }}>{fieldValue}</Text>
          </FieldValueContainer>
        }
        <MessageSeverity>
          <Icon
            type={type}
            style={{
              marginRight: 12,
              marginTop: 3,
              width: 10
            }}
            className={iconClass} />
          <Text
            style={{ width: '90%' }}
            type={type}>
            {message.message || message.text}
          </Text>
        </MessageSeverity>
      </MessageInfoContainer>

      <MessageActionContainer>
        {data?.uniqid && (
          <Button
            variant="link"
            onClick={() => navigate(getUrl())}>
            Update
          </Button>
        )}
        {origin === "locations" && hideActions === false && (
          <Button
            variant="link"
            onClick={() => onEditSite()}>
            Update
          </Button>
        )}
      </MessageActionContainer>
    </MessageContainer >
  )
}

const Container = styled.div`
  width: 100%;
`

const ModalBody = styled.div`
  margin-top: 30px;
  margin-bottom: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const MessagesContainer = styled.div`
  max-height: 350px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 16px 16px 0 16px;
  overflow-y: scroll;
`

const ActionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const MessageContainer = styled.div`
  // min-height: 117px;
  width: 100%;
  background: #F4F4F4;
  border-radius: 5px;
  margin-bottom: 16px;
  padding: 16px 20px;
  display: flex;
  flex-direction: row;
  align-item: center;
`

const MessageInfoContainer = styled.div`
  width: 90%;
`

const MessageActionContainer = styled.div`
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const getIconColor = (type) => {
  switch (type) {
    case "ERROR": return "#D6542D"
    case "WARNING": return "#DBA51F"
    case "NOTE": return "#18669E"
    case "NON-SITE": return "#D6542D"
    default: return "#18669E"
  }
}

const Text = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.16px;
  color: ${props => getIconColor(props.type)};
`

const FieldValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  white-space: normal;
  word-break: break-word;
`

const MessageSeverity = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 16px;
  // white-space: normal;
  white-space: pre-line;
`

const Icon = styled.i`
  color: ${props => getIconColor(props.type)};
`