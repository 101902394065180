import React, { useContext } from "react";
import { useShallow } from 'zustand/react/shallow'
import DatePicker from "components/DatePicker/DatePicker";
import Note from 'components/Note/Note';

// hoc
import InputWrapper from '../../hoc/InputWrapper';

// context
import useAuthoringValidationsStore from "../../../hooks/stores/useAuthoringValidationsStore";

const RJDatePicker = (props) => {
  let {
    carbonProps,
    value,
    onChange,
  } = props;

  const {
    registryValidations
  } = useAuthoringValidationsStore(
    useShallow(state => ({
      registryValidations: state.registryValidations,
    }))
  )

  return (
    <>
      <DatePicker
        jpath={props.id}
        className="rj-date-picker"
        value={value ? value : null}
        onChange={onChange}
        allowClear={true}
        disabled={carbonProps?.disabled} />

      {!registryValidations && carbonProps.required && !value && (
        <Note type="error" text={"This is a required field."} style={{ marginTop: 4 }} />
      )}
    </>
  );
};

export default InputWrapper(RJDatePicker);