import { useState, useEffect } from 'react';

// components
import PrimeField from 'components/PrimeField/PrimeField';
import Modal from 'components/Modal/Modal';
import TagsField from 'components/TagsField/TagsField';
import { Button } from 'components-design-system';

// apis
import { useGetActivityTypes } from 'api/hooks/activities/useActivitiesApi';

// styles
import './CreateActivityModal.scss'

const CreateActivityModal = (props) => {
  const {
    open,
    onCancel,
    studyId,
    onCreateActivity
  } = props;
  const { data: activityTypes = [] } = useGetActivityTypes()
  const [formData, setFormData] = useState({
    project_type_id: null,
    comment_text: "",
    tags: []
  })
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const schema = {
    project_type_id: {
      type: "dropdown",
      className: "full",
      placeholder: "Select activity",
      label: "Activity type",
      items: activityTypes.map(item => item.title),
      onChange: (val) => handleChange("project_type_id", val),
      containerClassName: "field w-full"
    },
    comment_text: {
      type: "textarea",
      className: "full",
      placeholder: "Enter comment here",
      label: "Comment",
      onChange: (val) => handleChange("comment_text", val),
      containerClassName: "field"
    }
  }

  useEffect(() => {
    if (formData?.project_type_id && submitDisabled) setSubmitDisabled(false)
    else if (!formData?.project_type_id && submitDisabled === false) setSubmitDisabled(true)
    else if(formData.tags && !formData.comment_text && submitDisabled === false) setSubmitDisabled(true)
  }, [formData])

  const handleChange = (key, val) => {
    setFormData({
      ...formData,
      [key]: val
    });
  }

  const renderForm = () => {
    return Object.keys(schema).map(key => {
      return (
        <PrimeField
          key={key}
          schema={schema[key]}
          value={formData[key]} />
      )
    })
  }

  const onCloseModal = () => {
    onCancel()
    setFormData({})
  }

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      hideImage={true}
      footer={[]}>
      <div className="create-activity-modal-container">
        <div className="form-container">
          {renderForm()}
          <TagsField
            tags={formData?.["tags"]}
            setTags={(val) => handleChange("tags", val)}
          />
        </div>
        <div className="action-container">
          <Button
            variant={"outlined"}
            onClick={onCloseModal}>
            Cancel
          </Button>
          <Button
            disabled={submitDisabled}
            onClick={() => onCreateActivity(formData)}>
            Create activity
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateActivityModal;