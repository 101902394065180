import * as React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import EllipsisPopover from 'components/EllipsisPopover/EllipsisPopover'
import './RequestsCellRenderer.scss'

const RequestsCellRenderer = ({
  value,
  isReviewerView,
  arrKey,
  studyIdKey,
  idKey,
  data
}) => {

  const getArrValue = () => {
    return data[arrKey].map(request => {
      return ({
        request_id: request[studyIdKey],
        id: request[idKey]
      })
    })
  }

  let requests = value
  if (typeof value === "string") requests = getArrValue()

  // popover with all other study pills, beside the first one
  const ellipsisContent = React.useMemo(() => {
    // text without links for reviewer view
    if (isReviewerView) {
      return requests.reduce((acc, req, index) => {
        if (index > 0) acc.push(req.request_id);
        return acc
      }, []).join(", ")
    }
    // pills with links
    else {
      return requests.reduce((acc, req, index) => {
        if (index > 0) acc.push(<Link
          key={req.id}
          to={`/request/${req.id}/overview`}
        >
          {req.request_id}
        </Link>)
        return acc
      }, [])
    }
  }, [requests])

  if (!requests) return null;

  if (Array.isArray(requests)) {
    if (requests.length === 0) return null;

    // only show one request
    return <div className="requests-cell-renderer">
      {/* text without links for reviewer view */}
      {isReviewerView ?
        <span>{requests[0]?.request_id}</span> :
        <Link to={`/request/${requests[0]?.id}/overview`}>{requests[0]?.request_id}</Link>
      }
      {/* pills with links */}
      {requests?.length > 1 &&
        <EllipsisPopover
          title="REQUESTS"
          content={ellipsisContent}
          className="request-popover-content"
          display="block"
        />
      }
    </div>
  }
}

RequestsCellRenderer.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      request_id: PropTypes.string,
    })),
    PropTypes.string,
  ]),
}

export default RequestsCellRenderer