import useMutateApi from "../useMutateApi"

// constants
import apiEndpoints from "api/utils/apiEndpoints"

// utils
import getUrl from "api/apiMap"


const useGlobalDocsSearch = () => {
  return useMutateApi({
    method: "post",
    apiEndpoint: `${getUrl(apiEndpoints.globalDocsSearch)}`,
    invalidateQueries: [],
  })
}

export {
  useGlobalDocsSearch,
}