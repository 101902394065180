import getUrl from 'api/apiMap'
import useGetApi from '../useGetApi'
import useMutateApi from '../useMutateApi'

// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

const useGetDocuments = () => {
  return useGetApi(`${getUrl(apiEndpoints.documents)}`, queryKeys.documents.all)
}

const usePostDocument = () => {
  return useMutateApi({
    method: 'post',
    apiEndpoint: getUrl(`${apiEndpoints.documents}`),
    invalidateQueries: [queryKeys.documents.all],
    successMsg: 'Successfully created document',
  })
}

const useGetDocument = (docId, options) => {
  return useGetApi(`${getUrl(apiEndpoints.documents)}${docId}/`, queryKeys.documents.details(docId), options)
}

// { id: "<id>/", body }
const usePutDocument = () => {
  return useMutateApi({
    method: 'put',
    apiEndpoint: getUrl(`${apiEndpoints.documents}`),
    invalidateQueries: [queryKeys.documents.all],
    successMsg: "Sucessfully updated document"
  })
}

const useDeleteDocument = (document_pk) => {
  // if document_pk not sent when defining function, send as id in mutate function: { id: "<id>/"}
  let url = `${apiEndpoints.documents}`
  // if document_pk sent id, set in query function instead
  if (document_pk) {
    url = `${apiEndpoints.documents}${(document_pk)}/`
  }
  return useMutateApi({
    method: 'delete',
    apiEndpoint: getUrl(url),
    invalidateQueries: [queryKeys.documents.all],
    successMsg: "Sucessfully deleted document"
  })
}

const useDownloadAttachment = (url) => {
  return useGetApi(`${url}`, queryKeys.documents.downloadAttachment(url))
}

const usePostReviewerDocument = (taskId) => {
  return useMutateApi({
    method: 'post',
    apiEndpoint: getUrl(`${apiEndpoints.reviewerDocuments}?task-id=${taskId}`),
    invalidateQueries: [queryKeys.documents.all],
    successMsg: "Sucessfully created document"
  })
}

export {
  useGetDocuments,
  usePostDocument,
  useGetDocument,
  usePutDocument,
  useDeleteDocument,
  useDownloadAttachment,
  usePostReviewerDocument,
}