// TODO: because only used by Activity page, move inside src/containers/activities/Activity and rename ActionItemPriorityCellRenderer
import React from 'react'
import { Tooltip } from 'antd'

// styles
import './action-item-priority.scss'

// utils
import { getPriority } from 'utilities/useActionItems'

const ActionItemPriority = ({
  value,
}) => {
  let priority = getPriority(value)?.toLowerCase()
  let capitalized = ""
  if (priority) {
    capitalized = priority[0].toUpperCase() + priority.slice(1)
  }

  return (
    <Tooltip
      title={`${capitalized} priority`}
    >
      <div className="action-item-priority-status-container">
        <div className={`action-item-priority-status ${priority}`}></div>
      </div>
    </Tooltip>
  )
}

export default ActionItemPriority