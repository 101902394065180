const isValidUrl = (str) => {
  var pattern = new RegExp('(http|https)://[a-zA-Z0-9./?=_%:-]*', 'i')
  return !!pattern.test(str)
}

// Error validation when a user fills out a form for a document, which requires either a file to be uploaded or a link to be added
const getFileLinkError = ({
  documentFile,
  documentLink,
}) => {
  if (!documentFile && !documentLink) {
    return 'Either upload a file or add a file link'
  } else if (documentFile && documentLink) {
    return 'Only upload a file or add file link, not both'
  } else if (documentLink && !isValidUrl(documentLink)) {
    return 'Enter a valid URL starting with https://'
  } else {
    return null
  }
}

const getDocFileName = (documentFile) => {
  if (!documentFile) return '';
  const slashParts = documentFile.split('/')
  // remove file signature param:
  return slashParts[slashParts.length - 1]?.split('?')?.[0]
}

const getPutData = (file) => {
  let data = {
    // required fields
    name: file?.name || '',
    document_type_id: file?.document_type_id || '',
  }

  // add to authoringstudy_db_ids
  if (file?.authoringstudies_w_checklists && Array.isArray(file.authoringstudies_w_checklists) && file.authoringstudies_w_checklists.length > 0) {
    data.authoringstudy_db_ids = file?.authoringstudies_w_checklists?.map((study) => study.id)
  }

  if (file?.requests && Array.isArray(file.requests) && file.requests.length > 0) {
    data.request_db_ids = file?.requests?.map((req) => req.id)
  }

  // TODO: add status to api // status: file?.status || '',
  // add fields if values on server
  const fields = [
    "document_file",
    "document_link",
    "version_number",
    "document_date",
    "extra_data",
    "state", // "status",
    // TODO: add checklist_db_ids
  ]
  fields.forEach((field) => {
    if (file?.[field]) {
      if (Array.isArray(file[field])) {
        if (file[field].length > 0) {
          data[field] = file[field]
        }
      } else if (typeof file[field] === 'object') {
        if (Object.keys(file[field]).length > 0) {
          data[field] = file[field]
        }
      } else {
        data[field] = file[field]
      }
    }
  })

  if (data.version_number === 'None') {
    data.version_number = ''
  }

  return data
}

// Used for documents PUT and POST
const getSavePayload = (data) => {
  const payload = { ...data }

  for (const key in data) {
    if (data[key] === null || data[key] === 'None') {
      payload[key] = ''
    }
    else if (Array.isArray(data[key])) {
      payload[key] = JSON.stringify([...data[key]])
    }
    else if (
      typeof data[key] === 'object' &&
      !(typeof data[key]?.name === 'string') // File type
    ) {
      payload[key] = JSON.stringify({ ...data[key] })
    }
  }

  return payload
}

// TODO: review if this is needed:
// Used only for documents PUT to remove document_file string if not updating
// const cleanUpPutPayload = (payload) => {
//   let newPayload = { ...payload }
//   if (typeof newPayload?.document_file?.name !== 'string' && newPayload?.document_file) delete newPayload.document_file;
//   return newPayload
// }

export {
  isValidUrl,
  getFileLinkError,
  getDocFileName,
  getPutData,
  getSavePayload,
  // cleanUpPutPayload,
}