import React, { useState } from 'react';
import styled from 'styled-components';

// components
import PrimeField from 'components/PrimeField/PrimeField';
import Modal from 'components/Modal/Modal';
import { Button } from 'components-design-system'

const CountryRow = ({
  country,
  schema,
  onChange,
  onDeleteCountry,
  i
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  return (
    <InputContainer
      onMouseEnter={() => setShowMenu(true)}
      onMouseLeave={() => setShowMenu(false)}>
      <PrimeField
        schema={{
          type: "dropdown",
          items: schema.enum,
        }}
        value={country?.country_name || ""}
        onChange={e => onChange(e, i, "country_name")}/>
      {showMenu && 
        <TrashIcon
          className="rjs-delete fal fa-trash-alt animate__animated animate__fadeIn animate__faster"
          onClick={() => setOpenModal(true)}/>
      }

      <Modal
        title={`Are you sure you want to delete?`}
        open={openModal}
        onCancel={() => setOpenModal(false)}
        className={"pft-comment-modal"}
        footer={[]}
        type="delete">
        <Text>NOTE: This action can not be undone.</Text>
        <ActionContainer>
          <Button 
            variant="outlined"
            onClick={() => setOpenModal(false)}>
            Cancel
          </Button>
          <Button 
            variant="danger"
            onClick={() => {
              onDeleteCountry(i)
              setOpenModal(false)
              setShowMenu(false)
            }}>
            Delete
          </Button>
        </ActionContainer>
      </Modal>
    </InputContainer>
  );
};

export default CountryRow;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const TrashIcon = styled.i`
  z-index: 100;
  color: #D7542C;
  font-weight: 500;
  margin-left: 12px;
  margin-right: 6px;
  cursor: pointer;

  &:hover {
    font-weight: 600;
  }
`

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  gap: 12px;
`

const Text = styled.div`
  color: #525252;
`