import React from 'react'

const DeleteForkSvg = (props) => <svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 449.87 511.99"
  {...props}
>
  <path d="M143,356.87a40.52,40.52,0,0,0-11.05,14.91A42.59,42.59,0,0,0,128,390,42.11,42.11,0,0,0,140.75,421q13.26,13.26,31.49,13.26A41.23,41.23,0,0,0,203.18,421,41.23,41.23,0,0,0,216.44,390a42.39,42.39,0,0,0-7.73-24.86,42.84,42.84,0,0,0-20.45-16.57,20,20,0,0,1,8.29-7.18q8.83-4.42,36.46-7.19a223.54,223.54,0,0,0,24.31-2.76,110.64,110.64,0,0,0,21-6.63q12.71-5.52,19.89-14.36,11.6-14.37,12.15-37.57a42.45,42.45,0,0,0,21.55-16,43.66,43.66,0,0,0,8.28-26A41.27,41.27,0,0,0,326.94,200,41.26,41.26,0,0,0,296,186.7q-18.24,0-31.5,13.26a42.11,42.11,0,0,0-12.7,30.94,41.93,41.93,0,0,0,15.47,33.7,45,45,0,0,0,16.57,8.84q-.55,13.26-6.63,20.44-3.87,5-12.15,8.29A100.46,100.46,0,0,1,250.14,306l-19.89,1.66q-31.49,3.3-44.75,9.39V237.53a39.51,39.51,0,0,0,22.1-15.47,42.59,42.59,0,0,0,8.84-26.52,41.24,41.24,0,0,0-13.26-30.94,41.23,41.23,0,0,0-30.94-13.26q-18.23,0-31.49,13.26A42.12,42.12,0,0,0,128,195.54a44.43,44.43,0,0,0,8.29,26.52A42.09,42.09,0,0,0,159,237.53V348A45.55,45.55,0,0,0,143,356.87Zm146.41-132a9,9,0,0,1,6.63-2.76,9.52,9.52,0,0,1,8.84,8.84,9,9,0,0,1-2.77,6.63,8.22,8.22,0,0,1-6.07,2.21,7.81,7.81,0,0,1-8.84-8.84A8.2,8.2,0,0,1,289.37,224.82ZM165.61,189.46a9,9,0,0,1,6.63-2.76,9.5,9.5,0,0,1,8.84,8.84,9,9,0,0,1-2.76,6.63,8.2,8.2,0,0,1-6.08,2.21,7.81,7.81,0,0,1-8.84-8.84A8.24,8.24,0,0,1,165.61,189.46Zm0,194.48a9.05,9.05,0,0,1,6.63-2.77,9.52,9.52,0,0,1,8.84,8.84,9,9,0,0,1-2.76,6.63,8.2,8.2,0,0,1-6.08,2.21A7.81,7.81,0,0,1,163.4,390,8.22,8.22,0,0,1,165.61,383.94Z" />
  <path d="M390.93,120.71a15,15,0,0,0-15,15V421.28A45,45,0,0,1,331,466.21H121.32a45,45,0,0,1-44.94-44.93V135.69a15,15,0,1,0-29.95,0V421.28a75,75,0,0,0,74.89,74.89H331a75,75,0,0,0,74.89-74.89V135.69A15,15,0,0,0,390.93,120.71Z" />
  <path d="M420.89,76.77H331L299.54,34.88a44.74,44.74,0,0,0-35.93-18H188.72a44.84,44.84,0,0,0-36,18L121.32,76.77H31.45a15,15,0,0,0,0,30H420.89a15,15,0,1,0,0-30Zm-262.13,0,18-24a15,15,0,0,1,11.93-6h74.89a15,15,0,0,1,11.94,6l18,24Z" />
</svg>

export default DeleteForkSvg