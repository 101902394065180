import { useEffect, useState, useMemo } from 'react'
import axios from "axios";
import {
  useLocation,
  Navigate,
  useSearchParams,
  useNavigate
} from 'react-router-dom'
import dayjs from 'dayjs'
import { isExpired } from 'react-jwt';

// components
import PrimeField from 'components/PrimeField/PrimeField'
import { Button } from 'components-design-system'

// utilities
import useAuth from 'context/hooks/useAuth'
import { getCookie } from 'api/apiUtils';
import useAppState from 'context/hooks/useAppState';
import useRefreshToken from 'utilities/useRefreshToken';

// assets
import primeWhiteLogo from 'assets/images/prime-logo-white.png'
import globeLogo from 'assets/images/login-globe.png'
import loginBg from 'assets/images/login-bg.png'

// apis
import apiEndpoints from 'api/utils/apiEndpoints'
import {
  usePostForgotPassword
} from 'api/hooks/auth/useAuthApis'
import getUrl from 'api/apiMap';

// styles
import './login.scss'

const AxiosPublic = axios.create({});

const Login = () => {
  const {
    setTokens,
    setNextUrlLocalStorage
  } = useRefreshToken()
  const navigate = useNavigate()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [newPassword2, setNewPassword2] = useState("")
  const [isSubmitDisabled, setSubmitDisabled] = useState(true)

  const [view, setView] = useState("login")
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [tempTokens, setTempTokens] = useState({
    access: null,
    refresh: null
  })
  const location = useLocation()
  const pathname = location.pathname
  const [searchParams] = useSearchParams()
  const postForgotPassword = usePostForgotPassword()
  const {
    openToastNotification,
    publicEnvVar,
    setAppState,
    appStateRef
  } = useAppState()

  const {
    login,
    auth,
  } = useAuth()

  const saml = useMemo(() => {
    return searchParams.get('saml')
  }, [])

  useEffect(() => {
    let nextUrl = searchParams.get('next')
    if (nextUrl) {
      setNextUrlLocalStorage(nextUrl)
    }
  }, [searchParams])

  useEffect(() => {
    if (saml === "true") {
      const access = getCookie("access")
      const refresh = getCookie("refresh")
      setTokens({ access, refresh })

      if (access && refresh && isExpired(access) === false && isExpired(refresh) === false) {
        setAppState("authenticated")
        appStateRef.current = "authenticated";
        let nextUrl = localStorage.getItem("prime-next-url")
        if (nextUrl) {
          navigate(nextUrl)
          localStorage.removeItem("prime-next-url")
        } else {
          navigate("/")
        }
      }
    }
  }, [saml])

  useEffect(() => {
    setError(null)
    setSuccess(null)

    return () => {
      setError(null)
      setSuccess(null)
    }
  }, [view])

  useEffect(() => {
    if (pathname === "/password-reset" && view !== "password-reset") {
      setView("password-reset")
    }
  }, [pathname])

  useEffect(() => {
    var regex = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{10,}$/);
    if (!oldPassword || !newPassword || !newPassword2) {
      setSubmitDisabled(true)
    } else if (newPassword2 && newPassword2 !== newPassword) {
      setSubmitDisabled(true);
      setError("Your password does not match.");
    } else if (newPassword2 && newPassword && newPassword2 === newPassword) {
      if (regex.test(newPassword2)) {
        setSubmitDisabled(false);
        setError(null);
      } else if (isSubmitDisabled === true) {
        setSubmitDisabled(true);
        setError("Your password does not meet the required criteria.");
      }
    } else if (isSubmitDisabled === true) {
      setError(false);
      setSubmitDisabled(false);
    }
  }, [newPassword2, newPassword, oldPassword])

  const handleLogin = async () => {
    await login({ email, password })
      .catch(err => {
        if (err.response?.data?.error?.msg) setError(err.response.data.error.msg)
        else if (err?.response?.status === 302 && err?.response?.data?.reset_url) {
          setTempTokens({
            access: err.response.data.access,
            refresh: err.response.data.access,
          })
          setView("password-reset")
        }
      })
  }

  const handleResetPassword = async () => {

    AxiosPublic.post(getUrl(apiEndpoints.resetPassword), {
      old_password: oldPassword,
      new_password: newPassword,
      new_password2: newPassword2
    }, {
      headers: {
        'Authorization': `Bearer ${tempTokens?.access}`,
      }
    })
      .catch((resp) => {
        if (resp?.response?.status === 307) {
          setView("login")
          setSuccess("Password reset successful")
          openToastNotification("success", "Success", "Password reset successfully")
        } else if (Array.isArray(resp?.response?.data?.error.details?.new_password)) {
          let errorMsg = resp?.response?.data?.error.details?.new_password.join(", ")
          setError(errorMsg);
        } else if (resp?.response?.data?.error.details?.msg) {
          setError(resp?.response?.data?.error.details?.msg);
        }
      })
  }

  const handleForgotPassword = async () => {
    postForgotPassword({ body: { email } }, {
      onSuccess: resp => {
        console.log({ resp })
      }
    })
  }

  const renderNotifications = () => {
    return (
      <>
        {error && (
          <div className="login-notification-container error-notif">
            <i className="fas fa-exclamation-triangle" />
            <div>
              <div className="login-error-text">{error}</div>
              {error === "Your password has expired." && (
                <div
                  className="login-error-text-link"
                  onClick={() => setView("forgot-password")}>
                  Please reset your password here.
                </div>
              )}
            </div>
          </div>
        )}
        {success && (
          <div className="login-notification-container success-notif">
            <i className="fad fa-check-circle" />
            <div className="login-success-text">{success}</div>
          </div>
        )}
      </>
    )
  }

  const onKeyEnter = (e) => {
    if (e.code === "Enter") {
      if (view === "login") {
        handleLogin()
      } else if (view === "forgot-password") {
        handleForgotPassword()
      } else if (view === "password-reset") {
        handleResetPassword()
      }
    }
  }

  const renderSsoButtons = () => {
    return Object.keys(publicEnvVar.SSO_URL).map(key => {
      return (
        <a
          variant="outlined"
          className="prime--btn btn--outlined btn--sm sso-btn"
          size="sm"
          style={{ marginTop: 0 }}
          href={publicEnvVar.SSO_URL[key].url}>
          <img src={publicEnvVar.SSO_URL[key].img_url} />
          {publicEnvVar.SSO_URL[key].button_text || "Login with SSO"}
        </a>)
    })
  }

  if (auth?.access) {
    return <Navigate to="/" state={{ from: pathname }} replace={true} />
  }

  return (
    <div className="login-container">
      <img
        className="login-bg-img"
        src={loginBg}
        alt="xogene-prime-bg" />

      <div className="login-center-wrap">
        <div className="left-container">
          <div className="main-container">
            <img className="prime-logo" src={primeWhiteLogo} alt="prime-white-logo" />
            <img className="globe-logo" src={globeLogo} alt="globe-logo" />
            <h1 className="left-caption">Stay up to date with global requirements and take control of your compliance.</h1>
          </div>
          <div className="footer-container">
            © Copyright {dayjs().format('YYYY')}  Xogene Services LLC | V{publicEnvVar.REACT_APP_PRIME_VERSION}
          </div>
        </div>
        <div className="right-container" onKeyDown={onKeyEnter}>
          {view === "login" && (
            <div className="content-container">
              <div className="login-header">Welcome back!</div>
              {publicEnvVar.SSO_URL && Object.keys(publicEnvVar.SSO_URL).length > 0 && (
                <>
                  <div className="sso-btns">
                    {renderSsoButtons()}
                  </div>
                  <div className="break-container">
                    <span className='break-line'></span>
                    <span>&nbsp;&nbsp; or &nbsp;&nbsp;</span>
                    <span className='break-line'></span>
                  </div>
                </>
              )}
              <div className="input-container">
                <PrimeField
                  schema={{
                    type: "text",
                    placeholder: "E-mail",
                    prefix: <i className="fal fa-envelope login-input-icon" />
                  }}
                  value={email}
                  onChange={setEmail} />
                <PrimeField
                  schema={{
                    type: "password",
                    placeholder: "Password",
                    prefix: <i className="fal fa-lock-alt login-input-icon" />,
                    style: { marginTop: 12 }
                  }}
                  value={password}
                  onChange={setPassword} />
                <p
                  className="forgot-password-link"
                  onClick={() => setView("forgot-password")}>
                  Forgot password?
                </p>
                <Button
                  className="login-btn"
                  onClick={handleLogin}>
                  Login
                </Button>
                {renderNotifications()}
              </div>
            </div>
          )}
          {view === "forgot-password" && (
            <div className="content-container">
              <div className="login-header">Enter your email to receive your password reset link</div>
              <div className="input-container">
                <PrimeField
                  schema={{
                    type: "text",
                    placeholder: "E-mail",
                    prefix: <i className="fal fa-envelope login-input-icon" />
                  }}
                  value={email}
                  onChange={setEmail} />
                <p
                  className="forgot-password-link"
                  onClick={() => setView("login")}>
                  Back to login
                </p>
                <Button
                  className="login-btn"
                  onClick={handleForgotPassword}>
                  Submit
                </Button>
                {renderNotifications()}
              </div>
            </div>
          )}
          {view === "password-reset" && (
            <div className="content-container">
              <div className="login-header">Reset your password</div>
              <div className="input-container">
                <PrimeField
                  schema={{
                    type: "password",
                    placeholder: "Old password",
                    prefix: <i className="fal fa-lock-alt login-input-icon" />,
                    style: { marginTop: 12 }
                  }}
                  value={oldPassword}
                  onChange={setOldPassword} />
                <PrimeField
                  schema={{
                    type: "password",
                    placeholder: "New password",
                    prefix: <i className="fal fa-lock-alt login-input-icon" />,
                    style: { marginTop: 12 }
                  }}
                  value={newPassword}
                  onChange={setNewPassword} />
                <PrimeField
                  schema={{
                    type: "password",
                    placeholder: "Confirm password",
                    prefix: <i className="fal fa-lock-alt login-input-icon" />,
                    style: { marginTop: 12 }
                  }}
                  value={newPassword2}
                  onChange={setNewPassword2} />
                <div className="helper-text">
                  Password must use at least 10 characters and contain one uppercase letter, one lowercase letter, one special character, and one number.
                </div>
                <p
                  className="forgot-password-link"
                  onClick={() => setView("login")}>
                  Back to login
                </p>
                <Button
                  className="login-btn"
                  disabled={isSubmitDisabled}
                  onClick={handleResetPassword}
                  style={{ marginTop: 24 }}>
                  Update & Log in
                </Button>
                {renderNotifications()}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Login