import moreInfo from './shared/moreInfo';
import trialInfo from './shared/trialInfo';

export const primeResultsUISchema = {
  results: {
    "ui:order": ["participant_flow", "baseline_characteristics", "outcome_measures", "adverse_events", "*"],
    "ui:options": {
      classNames: {
        objectInnerContainer: "section-container",
      }
    },
    participant_flow: {
      "ui:options": {
        title: "Participant Flow",
        classNames: {
          objectTitleContainer: "rjs-h1-container",
          objectTitle: "rjs-h1",
          objectContainer: "p-24",
          fieldContainer: "rjs-full",
        }
      },
      protocol_enrollment: {
        "ui:options": {
          title: "Protocol Enrollment",
          label: false,
          classNames: {
            fieldContainer: "rjs-full",
            field: "rjs-col-1-3"
          },
        },
      },
      recruitment_details: {
        "ui:options": {
          title: "Recruitment Details",
          label: false,
          classNames: {
            fieldContainer: "rjs-full",
          },
        },
        "ui:widget": "TextareaWidget"
      },
      pre_assignment_details: {
        "ui:options": {
          title: "Pre-assignment Details (Optional)",
          label: false,
          classNames: {
            fieldContainer: "rjs-full",
          },
        },
        "ui:widget": "TextareaWidget"
      },
      type_units_analyzed: {
        "ui:options": {
          title: "Type of Units Assigned",
          label: false,
          classNames: {
            fieldContainer: "rjs-full",
            field: "rjs-col-1-3"
          },
        },
        "ui:widget": "TypeUnitsAssigned"
      },
      participant_flow_table: {
        "ui:options": {
          label: false,
          classNames: {
            field: "rjs-full",
            // fieldContainer: "overflow-auto rjs-full"
            fieldContainer: "overflow-auto rjs-full"
          }
        },
        "ui:widget": "ParticipantFlow"
      }
    },
    baseline_characteristics: {
      "ui:options": {
        title: "Baseline Characteristics",
        classNames: {
          objectTitleContainer: "rjs-h1-container",
          objectTitle: "rjs-h1",
          objectContainer: "p-24",
          fieldContainer: "rjs-full"
        }
      },
      baseline_characteristics_table: {
        "ui:options": {
          label: false,
          classNames: {
            fieldContainer: "overflow-auto"
          }
        },
        "ui:widget": "BaselineCharacteristics"
      }
    },
    outcome_measures: {
      "ui:options": {
        title: "Outcome Measures",
        canCopyOutcomeMeasures: true,
        classNames: {
          objectTitleContainer: "rjs-h1-container",
          objectTitle: "rjs-h1",
          objectContainer: "p-24",
          fieldContainer: "rjs-full"
        }
      },
      outcome_measures_table: {
        "ui:options": {
          label: false,
          classNames: {
            fieldContainer: "overflow-auto"
          }
        },
        "ui:widget": "OutcomeMeasures"
      }
    },
    adverse_events: {
      "ui:options": {
        title: "Adverse Events",
        classNames: {
          objectTitleContainer: "rjs-h1-container",
          objectTitle: "rjs-h1",
          objectContainer: "p-24",
          fieldContainer: "rjs-full"
        }
      },
      adverse_events_table: {
        "ui:options": {
          label: false,
          classNames: {
            fieldContainer: "overflow-auto"
          }
        },
        "ui:widget": "AdverseEvents"
      }
    },
    more_info: moreInfo(),
    trial_info: trialInfo(),
  }
}