import './Label.scss';

const Label = ({ 
  children, 
  className = [], 
  style = {} 
}) => {
  let classNames = ["prime-label", ...className]
  return (
    <div 
      className={classNames.join(" ")} 
      style={style}>
      {children}
    </div>
  );
};

export default Label;