import React from 'react'
import { motion } from "framer-motion"
import { Tooltip } from 'antd'
import PropTypes from 'prop-types'

// styles
import "./ButtonSlider.scss"

const ButtonSlider = ({
  type,
  menuItems,
  open: openControlled,
  tooltip,
  tooltipPlacement = "top",
  children,
  // events
  onChange, // use controlling component with "open"
  onOpenMenu,
  onCloseMenu,
  // classes
  iconClassName,
  wrapClassName,
}) => {
  const [openInternal, setOpenInternal] = React.useState(false)

  const open = React.useMemo(() => {
    if (openControlled !== null && openControlled !== undefined) {
      return openControlled
    } else {
      return openInternal
    }
  }, [openControlled, openInternal])

  const variants = {
    show: {
      width: "100%",
    },
    hidden: {
      width: type === 'primary' ? 34 : 16,
    }
  }

  const onOpen = () => {
    if (onOpenMenu) onOpenMenu();
    if (onChange) onChange(true);
    setOpenInternal((val) => !val)
  }

  const onClose = () => {
    if (onCloseMenu) onCloseMenu();
    if (onChange) onChange(false);
    setOpenInternal((val) => !val)
  }

  if (!open) {
    return (
      <motion.div
        className={`${"button-slider-container"}${wrapClassName ? ' ' + wrapClassName : ''}`}
        animate={'hidden'}
        variants={variants}
        onClick={onOpen}
      >
        {iconClassName && (
          <Tooltip
            title={tooltip}
            placement={tooltipPlacement}
          >
            <div className={type === "primary" ? "primary-menu-item" : ""}>
              <i
                className={iconClassName} />
            </div>
          </Tooltip>
        )}
      </motion.div>
    )
  }

  return <motion.div
    className={`${"button-slider-container"} active-menu ${wrapClassName ? ' ' + wrapClassName : ''}`}
    animate={'show'}
    variants={variants}
  >
    <div className="menu-container">
      {menuItems && menuItems.map((item) => {
        return <div
          key={item.label}
          className="menu-item"
          onClick={item.onClick}
        >
          <i className={item.iconClassName} />
          <p>{item.label}</p>
        </div>
      })}
      {children}
      <div onClick={onClose}>
        <i className="fal fa-times"></i>
      </div>
    </div>
  </motion.div>
}

ButtonSlider.propTypes = {
  type: PropTypes.oneOf(['primary']),
  menuItems: PropTypes.arrayOf(PropTypes.shape({
    iconClassName: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func,
  })),
  open: PropTypes.bool,
}

export default ButtonSlider