import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import PrimeField from "components/PrimeField/PrimeField";

const RJMultiselect = (props) => {
  const [isDisabled, setDisabled] = useState(false);
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState(null);
  const title = props.options.title;
  const placeholder = props.options.placeholder;

  useEffect(() => {
    let { value, schema, uiSchema } = props;

    if (uiSchema) {
      if (uiSchema["ui:readonly"] || uiSchema["ui:disabled"]) {
        setDisabled(true)
      }
    }
  }, [props.value]);

  return (
    <Container>
      <PrimeField
        schema={{
          type: "multiselect",
          items: props.options,
          placeholder,
          disabled: isDisabled,
          label: title,
          orientation: "vertical"
        }}
        value={props.value || []}
        onChange={props.onChange}
        />
      {/* <PrimeField
        schema={{}}/> */}
      {/* <MultiSelect
        id={props.id}
        disabled={isDisabled}
        titleText={title}
        label={placeholder}
        items={items}
        selectedItems={selectedItems}
        itemToString={(item) => (item ? item.text : "")}
        onChange={onChange}
      /> */}
    </Container>
  );
};

export default RJMultiselect;

const Container = styled.div`
  
`

const TooltipContainer = styled.div`

`