import React from 'react';
import { useReactToPrint } from 'react-to-print';
import { useShallow } from 'zustand/react/shallow'

import useAuthoringPrintStore from '../stores/useAuthoringPrintStore';
import useAuthoringViewStore from '../stores/useAuthoringViewStore';

const useAuthoringPrintActions = () => {
  const {
    setIsPrinting,
    setPrintCtgOnly,
  } = useAuthoringPrintStore(
    useShallow(state => ({
      setIsPrinting: state.setIsPrinting,
      setPrintCtgOnly: state.setPrintCtgOnly,
    }))
  )

  const { 
    setShowPagePreview,
    formView,
    showVersionCompare
  } = useAuthoringViewStore(
    useShallow(state => ({ 
      setShowPagePreview: state.setShowPagePreview,
      formView: state.formView,
      showVersionCompare: state.showVersionCompare,
    }))
  )

  const handlePrint = useReactToPrint({
    content: () => document.getElementById("authoring-print-page-container"),
    removeAfterPrint: true
  });

  const onPrintPage = () => {
    if (formView.id !== "ctg" && formView.id !== "global") {
      setShowPagePreview(true)
    }
    setIsPrinting(true)
    setTimeout(() => {
      if (showVersionCompare || formView.id === "global") {
        setTimeout(() => {
          handlePrint()
          setTimeout(() => {
            setIsPrinting(false)
          }, 2000)
        }, 1000)
      } else if (formView.id === "ctg") {
        handlePrint()
        setTimeout(() => {
          setShowPagePreview(false)
          setIsPrinting(false)
          setPrintCtgOnly(false)
        }, 2000)
      } else {
        setTimeout(() => {
          handlePrint()
          setTimeout(() => {
            setShowPagePreview(false)
            setIsPrinting(false)
          }, 3000)
        }, 500)
      }
    }, 100)
  }

  return {
    handlePrint,
    onPrintPage
  }
};

export default useAuthoringPrintActions;