import * as React from 'react'
import PropTypes from 'prop-types'
import './EmptyFavorites.scss'

// assets
import emptyFavoritesLogo from 'assets/images/empty-favorites.png'

const EmptyFavorites = ({
  type = 'studies',
}) => {
  return <div className="empty-faves-wrapper">
    <div className="empty-faves-logo-wrapper">
      <img
        className="empty-favorite-studies-logo"
        src={emptyFavoritesLogo}
        alr="empty-favorites"
      />
    </div>
    <div className="empty-faves-title">No favorite {type === 'studies' ? 'studies' : 'requests'} yet</div>
    <div className="empty-faves-subtext">Click the <i className="fas fa-heart" /> on any {type === 'studies' ? 'study' : 'request'} page to add a favorite</div>
  </div>
}

EmptyFavorites.propTypes = {
  type: PropTypes.oneOf(['studies', 'requests'])
}

export default EmptyFavorites