import React from 'react'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'

// apis
import { useUserSettings } from 'api/hooks'

import './TimelineEvent.scss';

const TimelineEvent = (props) => {
  const {
    actionTitlesMap,
    event,
  } = props;

  const {
    author_fullname,
    item_type,
    project_title,
    action_date,
    timeline_text
  } = event

  const { data: userSettings } = useUserSettings()

  let header = `${timeline_text}`
  if (item_type === "action" && header?.includes("On Hold")) {
    header = actionTitlesMap.get("hold") + " " + author_fullname
  } else if (item_type === "action" && header?.includes("hold removed")) {
    header = actionTitlesMap.get("unhold") + " " + author_fullname
  } else if (item_type === "action" && header?.includes("state forced")) {
    header = `${actionTitlesMap.get("forced")} by ${author_fullname}`
  } else if (item_type === "action" && header?.includes("Cancelled")) {
    header = `${actionTitlesMap.get("cancelled")} by ${author_fullname}`
  }

  return <div className="timeline-event">
    <i className={`fas fa-chevron-circle-right timeline-item-icon`} />
    <div className="event-container">
      <div className="event-subheader">
        {author_fullname}, {dayjs(action_date).format(`${userSettings?.date_format} - h:mm a`)}
      </div>
      <div className="event-header">
        {header}
      </div>
    </div>
  </div>
}

TimelineEvent.propTypes = {
  event: PropTypes.shape({
    author_fullname: PropTypes.string,
    title: PropTypes.string,
    item_type: PropTypes.oneOf(['action']), // 'comment'
    project_title: PropTypes.string,
    action_date: PropTypes.string,
  })
}

export default TimelineEvent