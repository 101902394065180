import React, { useState, useEffect, useContext } from "react";
import PrimeField from "components/PrimeField/PrimeField";

// hoc
import InputWrapper from '../../hoc/InputWrapper';

// api
import { useGetConditions } from "api/hooks/admin/useConditionApi";
import { useGetCollaboratorTypes } from "api/hooks/admin/useCollaboratorTypesApi";
import { useGetSponsorTypes } from "api/hooks/admin/useSponsorApi";

const RJMultiselect = (props) => {
  let {
    id,
    carbonProps,
    value,
    options,
    onChange,
    invalid,
    invalidText,
  } = props;
  const [items, setItems] = useState([]);
  
  const { data: conditions } = useGetConditions()
  const { data: collaborators } = useGetCollaboratorTypes()
  const { data: sponsors } = useGetSponsorTypes()

  useEffect(() => {
    if (id === "root_protocol_study_description_study_conditions" && conditions) {
      let newItems = conditions?.map((option) => {
        return option.name;
      });
      setItems(newItems);
    } else if (id === "root_protocol_contact_information_sponsor" && sponsors) {
      let newItems = sponsors?.map((option) => {
        return option.name;
      });
      setItems(newItems);
    } else if (id === "root_protocol_contact_information_collaborators" && collaborators) {
      let newItems = collaborators?.map((option) => {
        return option.name;
      });
      setItems(newItems);
    } else if (options && options.enumOptions) {
      let newItems = options.enumOptions.map((option) => {
        if (option.value) return option.value;
        else if (typeof option === "string") return option
        else if (options.name) return option.name
      });
      setItems(newItems);
    }
  }, [options]);

  useEffect(() => {
  }, [value]);

  const handleChange = (val) => {
    console.log('Multi useEffect >> onChange')
    onChange(val);
  };

  return (
    <PrimeField
      schema={{
        id: id + "-field",
        type: "multiselect",
        items: items || [],
      }}
      onChange={handleChange}
      defaultValue={Array.isArray(value) ? value : []} />
  );
};

export default InputWrapper(RJMultiselect);