import React, { useState, useEffect, createContext } from 'react';
import styled from 'styled-components';
import { useShallow } from 'zustand/react/shallow'

// components
import Header from '../components/Header/Header';
import BaselineMeasure from './Measure';

// context
import useAuthoringDataStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringDataStore';
import useResultsTableViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableViewStore';
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';

export const BCContext = createContext(null);

const BaselinePreview = (props) => {
  let { schema = null, options, formContext } = props;
  const {
    formData,
  } = useAuthoringDataStore(
    useShallow(state => ({
      formData: state.formData,
    }))
  )

  const {
    formView,
  } = useAuthoringViewStore(
    useShallow(state => ({
      formView: state.formView,
    })),
  )

  const {
    tableView
  } = useResultsTableViewStore()

  let bgColor = "rgb(217, 228, 253)";
  let arms = [];
  let sas = [];
  let colSpan = 2;

  const [tables, setTables] = useState([]);

  if (formData?.results?.baseline_characteristics?.baseline_characteristics_table?.[tableView]?.arms) {
    arms = formData.results.baseline_characteristics.baseline_characteristics_table[tableView].arms;
    colSpan += arms.length;
  }

  if (formData?.results?.baseline_characteristics?.baseline_characteristics_table?.[tableView]?.subject_analysis_sets) {
    sas = formData.results.baseline_characteristics.baseline_characteristics_table[tableView].subject_analysis_sets;
    colSpan += sas.length;
  }

  useEffect(() => {
    if (formData?.results?.baseline_characteristics?.baseline_characteristics_table?.[tableView]) {
      let startIndex = 0;
      let endIndex = 5;
      let tables = [];

      let armsToDisplay = [...arms, ...sas]
      if (armsToDisplay.length > endIndex) {
        while (armsToDisplay.length > startIndex) {
          if (endIndex >= armsToDisplay.length) endIndex = armsToDisplay.length;
          let table = {
            ...formData.results.baseline_characteristics.baseline_characteristics_table[tableView],
            armsToDisplay: armsToDisplay.slice(startIndex, endIndex),
            baseline_measures: formData.results.baseline_characteristics.baseline_characteristics_table[tableView].baseline_measures.map(measure => {
              return {
                ...measure,
                rows: measure.rows.map(row => {
                  return {
                    ...row,
                    categories: row.categories.map(category => {
                      return {
                        ...category,
                        values: category.values.slice(startIndex, endIndex)
                      }
                    })
                  }
                })
              }
            })
          }

          tables.push(table);
          startIndex = endIndex;
          endIndex += 5;
        }
      } else {
        tables = [{
          arms: armsToDisplay,
          baseline_measures: formData?.results?.baseline_characteristics?.baseline_characteristics_table?.[tableView]?.baseline_measures
        }]
      }
      setTables(tables)
    }
  }, [arms.length, formData?.results?.baseline_characteristics?.baseline_characteristics_table])

  let firstColumnWidth = 250;

  return (
    <BCContext.Provider value={{
      view: tableView,
      data: formData?.results?.baseline_characteristics?.baseline_characteristics_table
    }}>
      <Header
        title="Reporting Groups"
        bgColor={bgColor}
        arms={arms}
        sas={sas}
        view={tableView} />

      {tableView === "eudract" &&
        <Header
          title={"Subject Analysis Sets"}
          bgColor={bgColor}
          subjectAnalysisSets={sas || null}
          view={tableView} />
      }

      {tables.map((table, i) => {
        let totalArmsParticipants = arms?.reduce((acc, arm) => {
          if (arm.baseline_analysis_population) {
            return acc + Number(arm.baseline_analysis_population);
          }
          return acc;
        }, 0)

        let totalSasParticipants = sas?.reduce((acc, sas) => {
          if (sas.baseline_analysis_population) {
            return acc + Number(sas.baseline_analysis_population);
          }
          return acc;
        }, 0)

        return (
          <Table key={`baseline-table-${i}`}>
            <tbody>
              <tr style={{ backgroundColor: bgColor, fontWeight: "bold" }}>
                <td colSpan={2} style={{ width: firstColumnWidth * 2 }} />
                {table?.arms?.map(arm => (
                  <td key={arm.id}>
                    {arm.title}
                  </td>
                ))
                }
                {tables.length - 1 === i && <td>TOTAL</td>}
              </tr>
              <tr>
                <td colSpan={2} style={{ backgroundColor: bgColor, fontWeight: "bold", width: firstColumnWidth * 2 }}>
                  Overall Number of Baseline Participants
                </td>
                {table?.arms?.map(arm => (
                  <td key={arm.id}>
                    {arm.baseline_analysis_population || "-"}
                  </td>
                ))
                }
                {tables.length - 1 === i && <td>
                  {totalArmsParticipants + totalSasParticipants}
                </td>}
              </tr>

              {tableView === "ctg" && formData?.results?.baseline_characteristics?.baseline_characteristics_table?.ctg?.type_units_analyzed && (
                <tr>
                  <td colSpan={2} style={{ backgroundColor: bgColor, fontWeight: "bold", width: firstColumnWidth * 2 }}>
                    Overall Number of Units Analyzed
                  </td>
                  {table?.arms?.map(arm => (
                    <td key={arm.id}>
                      {arm.num_units_analyzed || "-"}
                    </td>
                  ))
                  }
                  {tables.length - 1 === i && <td>
                    {arms?.reduce((acc, arm) => {
                      if (arm.num_units_analyzed) {
                        return acc + Number(arm.num_units_analyzed);
                      }
                      return acc;
                    }, 0)}
                  </td>}
                </tr>
              )}
              {tableView === "ctg" && <tr>
                <td colSpan={2} style={{ backgroundColor: bgColor, fontWeight: "bold", width: firstColumnWidth * 2 }}>
                  Type of Units Analyzed
                </td>
                <td colSpan={arms.length + 1}>
                  {formData?.results?.baseline_characteristics?.baseline_characteristics_table?.ctg?.type_units_analyzed}
                </td>
              </tr>}
              {tableView === "ctg" && <tr>
                <td colSpan={2} style={{ backgroundColor: bgColor, fontWeight: "bold", width: firstColumnWidth * 2 }}>
                  Baseline Analysis Population Description
                </td>
                <td colSpan={arms.length + 1}>
                  {formData?.results?.baseline_characteristics?.baseline_characteristics_table?.ctg?.population_analysis_description}
                </td>
              </tr>}

              {table?.baseline_measures?.map(bm => (
                <BaselineMeasure
                  showTotal={tables.length - 1 === i}
                  arms={table.arms}
                  key={bm.id}
                  bmId={bm.id}
                  firstColumnWidth={firstColumnWidth}
                  bgColor={bgColor}
                  data={bm}
                  baselineData={formData?.results?.baseline_characteristics?.baseline_characteristics_table}
                  formData={formData?.results?.baseline_characteristics?.baseline_characteristics_table?.[tableView]}
                  view={tableView}
                  typeOfUnits={formData?.results?.baseline_characteristics?.baseline_characteristics_table?.ctg?.type_units_analyzed} />
              ))
              }
            </tbody>
          </Table>
        )
      })}
    </BCContext.Provider>
  );
};

export default BaselinePreview;

const Table = styled.table`
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 16px;

  td, th {
    border: 1px solid #444;
    text-align: left;
    padding: 8px;
  }

  th {
    font-weight: bold;
    background-color: rgb(238, 238, 254);
    text-align: center;
  }
`