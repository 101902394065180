import React from 'react'
import { Dropdown } from 'antd'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'

// components
import Tag from 'components/Tag/Tag';

// apis
import { useUserPermissions, useUserSettings } from 'api/hooks';

// assets
import jpgIcon from 'assets/images/card-icons/jpg-icon.png'
import mp3Icon from 'assets/images/card-icons/mp3-icon.png'
import mp4Icon from 'assets/images/card-icons/mp4-icon.png'
import pdfIcon from 'assets/images/card-icons/pdf-icon.png'
import pngIcon from 'assets/images/card-icons/png-icon.png'
import rtfIcon from 'assets/images/card-icons/rtf-icon.png'
import wordIcon from 'assets/images/card-icons/word-doc-icon.png'
import xmlIcon from 'assets/images/card-icons/xml-icon.png'
import urlIcon from 'assets/images/card-icons/url-icon.png'
import docGenericIcon from 'assets/images/card-icons/doc-generic-icon.png'

// styles
import './TimelineComment.scss';

const TimelineComment = ({
  comment,
  readOnly,
  onOpenCommentForm,
  onOpenDeleteComment,
  setSelectedAttachment,
  style,
}) => {
  const {
    action_date,
    attachment,
    attachment_url,
    author_id,
    author_fullname,
    tags,
    timeline_text,
    // id,
    // item_type,
    // project_title,
  } = comment

  const { data: permissions } = useUserPermissions()
  const { data: userSettings } = useUserSettings()

  const canDelete = permissions?.["study.comment.delete"] === true || permissions?.["project.comment.delete"] === true
  const canEdit = permissions.user_id === author_id
  const items = []
  const dateFormat = userSettings?.date_format || "DD MMMM, YYYY"

  let icon = pdfIcon;

  const getDocIcon = (doc) => {
    let extension = doc?.document_file;
    if (extension) extension = extension.toLowerCase();

    if (extension?.includes(".jpg") || extension?.includes(".jpeg")) {
      return jpgIcon;
    }
    else if (extension?.includes(".mp3")) {
      return mp3Icon;
    }
    else if (extension?.includes(".mp4")) {
      return mp4Icon;
    }
    else if (extension?.includes(".pdf")) {
      return pdfIcon;
    }
    else if (extension?.includes(".png")) {
      return pngIcon;
    }
    else if (extension?.includes(".rtf")) {
      return rtfIcon;
    }
    else if (extension?.includes(".doc") || extension?.includes(".docx")) {
      return wordIcon;
    }
    else if (extension?.includes(".xml")) {
      return xmlIcon;
    }
    else if (!doc?.document_file && doc?.document_link) {
      return urlIcon;
    }
    else {
      return docGenericIcon;
    }
  }

  const renderAttachment = () => {
    icon = getDocIcon(attachment_url)

    return (
      <div
        className="comment-attachment-container"
        onClick={() => setSelectedAttachment(attachment_url)}>
        <img className="attachment-icon" src={icon} alt="doc-icon" />
        <a
          style={{ color: "#9566AB" }}
        >
          {attachment.split('/').pop()}
        </a>
      </div>
    )
  }

  // TODO: update this later
  // if (canEdit) items.push({
  if (true) items.push({
    key: '1',
    label: (
      <a onClick={() => onOpenCommentForm(comment)}>
        Edit
      </a>
    ),
  })

  if (canDelete) items.push({
    key: '2',
    label: (
      <a onClick={() => onOpenDeleteComment(comment)}>
        Delete
      </a>
    ),
  })

  if (!comment) return null

  return <div className="timeline-comment">
    {readOnly !== true && <i className="fas fa-comment-alt-lines timeline-item-icon" />}
    <div className="comment-container" style={style}>
      <div className="comment-subheader-container">
        <div className="comment-subheader">
          {author_fullname}, {dayjs(action_date).format(`${dateFormat} - h:mm a`)}
        </div>
        {(canEdit || canDelete) && readOnly !== true &&
          <Dropdown
            menu={{
              items,
            }}
            placement="bottomRight"
          >
            <i
              style={{ padding: "0px 5px" }}
              className="far fa-ellipsis-v"
            />
          </Dropdown>
        }
      </div>
      {timeline_text &&
        <div className="comment-header">
          {timeline_text}
          {attachment_url && renderAttachment()}
        </div>
      }
      {tags && tags.length > 0 &&
        <div className="comment-tags">
          {
            tags.map(tag => {
              return <Tag
                key={uuid()}
                text={tag}
                style={{ background: "#E1E2E3" }}
                disabled={true} />
            })
          }
        </div>
      }
    </div>
  </div>
}

TimelineComment.propTypes = {
  comment: PropTypes.shape({
    action_date: PropTypes.string,
    attachment: PropTypes.string,
    attachment_url: PropTypes.string,
    author_id: PropTypes.number,
    author_fullname: PropTypes.string,
    tags: PropTypes.array, // PropTypes.arrayOfType(PropTypes.string)
    timeline_text: PropTypes.string,
  })
}

export default TimelineComment