import React from 'react'
import { Tooltip } from 'antd'
import PropTypes from 'prop-types'
import './ResultsBadge.scss'

// constants
import { resultsFiltersMap } from '../../../constants/assessmentResults'

const ResultsBadge = ({
  value,
  outlined,
  tooltip,
}) => {
  if (!value) return null;

  const className = Object.keys(resultsFiltersMap).reduce((acc, mapVal) => {
    if (value.toLowerCase().includes('missing field')) return 'missing-fields'
    if (value && mapVal?.toLowerCase() === value?.toLowerCase()) acc = resultsFiltersMap[mapVal];
    return acc
  }, '')

  return <Tooltip title={tooltip}>
    <div className={`results-badge${className ? ' ' + className : ''}${outlined ? ' outlined' : ''}`}>
      {value && value.replace('Manual', 'M')}
    </div>
  </Tooltip>
}

ResultsBadge.propTypes = {
  value: PropTypes.string,
}

export default ResultsBadge