import { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';

// components
import PrimeField from 'components/PrimeField/PrimeField';
import { Button } from 'components-design-system';
import TagsField from 'components/TagsField/TagsField';
import FileUpload from 'components/files/FileUpload/FileUpload';

// utils
import useFileLoader from 'utilities/useFileLoader';
import jsonToFormData from 'utilities/jsonToFormData';

// apis
// save study comments:
import {
  usePostTimelineComment,
  usePutTimelineComment,
} from 'api/hooks/studies/useTimelineFeedApi'
// save activity comments:
import {
  usePostActivityComment,
  usePutActivityComment,
} from 'api/hooks/activities/useActivitiesApi'

// save requests comments
import {
  usePostRequestFeedComment,
  usePutRequestFeedComment
} from 'api/hooks/requests/useRequestTimelineApi';

// styles
import './CommentForm.scss'

const maxSize = 3 * (1024 * 1024)

const CommentForm = ({
  timelineFeedParams,
  type,
  selectedComment, // comment item
  onCloseCommentForm,
  refetchTimeline
}) => {
  const { studyId, activityId, requestId } = useParams()

  const [formData, setFormData] = useState({
    action_date: moment().format("YYYY-MM-DD h:mm:ss a"),
    action_time: moment().format("h:mm:ss"), // not sent to server
    text: "",
    attachment_url: "",
    attachment: "",
    tags: [],
  })
  const [file, setFile] = useState(null)

  // study apis
  const postStudyComment = usePostTimelineComment(studyId, timelineFeedParams)
  const putStudyComment = usePutTimelineComment(studyId, selectedComment?.id, timelineFeedParams)

  // activitiy apis
  const postActivityComment = usePostActivityComment(studyId, activityId, timelineFeedParams)
  const putActivityComment = usePutActivityComment(studyId, activityId, selectedComment?.id, timelineFeedParams)

  // request apis
  const postRequestComment = usePostRequestFeedComment(requestId, selectedComment?.id)
  const putRequestComment = usePutRequestFeedComment(requestId, selectedComment?.id)

  const hasFile = file || formData.attachment || formData.attachment_url

  const postComment = useMemo(() => {
    if (type === "study") return postStudyComment
    else if (type === "activity") return postActivityComment
    else if (type === "request") return postRequestComment
  }, [selectedComment, type])

  const putComment = useMemo(() => {
    if (type === "study") return putStudyComment
    else if (type === "activity") return putActivityComment
    else if (type === "request") return putRequestComment
  }, [selectedComment, type])

  useEffect(() => {
    if (selectedComment?.id) {
      setFormData({
        ...selectedComment,
        action_time: moment(selectedComment.action_date).format("h:mm:ss"),
        text: selectedComment.timeline_text
      })
    }
    else resetState()
  }, [selectedComment])

  const onChange = (key, value) => {
    let newKey = key
    let newValue = value
    if (key === 'action_date') {
      let dates = formData?.action_date?.split(' ')
      if (dates?.[0]) {
        dates[0] = moment(value).format('YYYY-MM-DD')
        newValue = dates.join(' ')
      }
    } else if (key === 'action_convention') {
      let dates = formData?.action_date?.split(' ')
      if (dates?.[2]) {
        dates[2] = value.toLowerCase()
        newValue = dates.join(' ')
        newKey = 'action_date'
      }
    }
    setFormData({
      ...formData,
      [newKey]: newValue,
    })
  }

  const onDropDocument = (file) => {
    setFile(file)
    onChange('attachment', file)
  }

  const onSaveChanges = () => {
    let actionDate = formData?.action_date?.split(" ")
    if (actionDate?.[1]) actionDate[1] = formData.action_time;
    actionDate = actionDate.join(" ")
    const payload = { ...formData }
    delete payload.action_time

    if (!hasFile) {
      payload.attachment = ""
    }

    delete payload.attachment_url

    // TODO: resolve error when sending empty array [] for "tags"
    if (Array.isArray(formData.tags) && formData.tags.length === 0) {
      // payload.tags = '' // error
      // delete payload.tags // 500 error
      // payload.tags = null // will add "null" as tag
    }

    const formattedComment = jsonToFormData({
      ...payload,
      action_date: moment(actionDate).format(),
    })
    const headers = { "Content-Type": "multipart/form-data" }
    const onSuccess = () => {
      resetState()
      refetchTimeline()
      onCloseCommentForm()
    }

    if (selectedComment?.id) {
      putComment({
        body: formattedComment,
        headers,
      }, { onSuccess })
    }
    else {
      postComment({
        body: formattedComment,
        headers,
      }, { onSuccess })
    }
  }

  const onClearFile = () => {
    setFile(null)
    setFormData({
      ...formData,
      attachment: "",
      attachment_url: "",
    })
  }

  const [isDragging, setIsDraggin] = useState()

  const {
    getFileLoadAreaProps,
  } = useFileLoader({
    onLoad: onDropDocument,
    onDrag: () => setIsDraggin(true),
    onStopDrag: () => setIsDraggin(false),
  })

  const resetState = () => {
    setFormData({
      action_date: moment().format("YYYY-MM-DD h:mm:ss a"),
      action_time: moment().format("h:mm:ss"),
      text: "",
      tags: [],
      attachment: "",
      attachment_url: "", // not sent to server
    })
    setFile(null)
  }

  const disabled = useMemo(() => {
    return !formData?.text || file?.size >= maxSize
  }, [formData, file])

  return <div className="timeline-comment-form-container">
    <div className="comment-form-date-container">
      <PrimeField
        schema={{
          title: "Date",
          type: "date",
          label: "Date"
        }}
        containerStyle={{ maxWidth: 100, marginRight: 8 }}
        value={formData["action_date"]}
        onChange={evt => onChange("action_date", evt)} />
      <PrimeField
        schema={{
          type: "text",
          placeholder: "hh:mm:ss",
          label: "Time"
        }}
        containerStyle={{ maxWidth: 90, marginRight: 2 }}
        value={formData["action_time"]}
        onChange={evt => onChange("action_time", evt)} />
      <PrimeField
        schema={{
          type: "dropdown",
          placeholder: "AM/PM",
          items: ["AM", "PM"],
        }}
        containerStyle={{ maxWidth: 70 }}
        value={moment(formData["action_date"]).format("a") === "am" ? "AM" : "PM"}
        onChange={evt => onChange("action_convention", evt)} />
    </div>
    <PrimeField
      schema={{
        type: "textarea",
        label: "Comment",
        placeholder: "Enter comment here",
        status: formData["text"]
      }}
      value={formData["text"]}
      onChange={evt => onChange("text", evt)} />
    <TagsField
      tags={formData?.["tags"]}
      setTags={evt => onChange("tags", evt)} />
    <div>
      {hasFile ? (
        <div className="comment-form-attachment-name-container">
          <span>
            {file?.name || formData?.attachment?.split('/')?.pop()}
          </span>
          <i
            className="fal fa-trash"
            onClick={onClearFile} />
        </div>
      ) : (
        <div
          {...getFileLoadAreaProps({ classNames: ["file-upload-container", "comment-form-file-upload"] })}
        >
          <FileUpload
            onLoad={onDropDocument}
            isDragging={isDragging}
          />
        </div>
      )}
      <div className="comment-form-tags-label">
        Only one file can be attached. 3MB max file size.
      </div>
    </div>

    <div className="comment-form-modal-actions">
      <Button
        onClick={onCloseCommentForm}
        variant="outlined">
        Cancel
      </Button>
      <Button
        onClick={onSaveChanges}
        disabled={disabled}
        tooltip={file?.size >= maxSize && "Attached file is over 3 MB."}
        variant="primary">
        Save Changes
      </Button>
    </div>
  </div>
}

export default CommentForm