import React from 'react';
import { useShallow } from 'zustand/react/shallow'

// components
import Label from 'components/Label/Label';
import PrimeField from 'components/PrimeField/PrimeField';

// context
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';

const DispersionRange = ({
  parameterType,
  dispersionType,
  dispersionValue,
  dispersionValueUpperBound,
  cell,
  cellArmId,
  onChange,
}) => {
  const {
    readOnly
  } = useAuthoringViewStore(
    useShallow(state => ({ readOnly: state.readOnly }))
  )

  return (
    <div className="field-container">
      <PrimeField
        schema={{
          type: "text",
          label: parameterType,
          orientation: "vertical",
        }}
        readOnly={readOnly}
        onChange={(e) => onChange(e, cellArmId, "value")}
        value={cell.value || ""} />
      <div className="range-container">
        <Label style={{ marginBottom: ".3rem", width: "100%" }}>{dispersionType}</Label>
        <div className='field-items'>
          <PrimeField
            schema={{
              type: "text",
              label: parameterType,
              orientation: "vertical",
              style: { marginRight: 8 }
            }}
            readOnly={readOnly}
            onChange={(e) => onChange(e, cellArmId, "dispersion_value")}
            value={dispersionValue || ""} />
          <Label style={{ marginBottom: 6 }}>to</Label>
          <PrimeField
            schema={{
              type: "text",
              orientation: "vertical",
              style: { marginRight: 8 }
            }}
            readOnly={readOnly}
            onChange={(e) => onChange(e, cellArmId, "dispersion_value_upper_bound")}
            value={dispersionValueUpperBound || ""} />
        </div>
      </div>
    </div>
  );
};

export default DispersionRange;