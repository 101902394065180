import { useState, useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow'
import GridTypeA from 'components/grids/GridTypeA/GridTypeA';
import { isObject } from 'lodash';
import styled from 'styled-components';

// Cell Renderers
import SiteID from '../../../Locations/SiteDetails/CellRenderers/SiteID';
import ContactFormatter from '../../../Locations/SiteDetails/CellRenderers/ContactFormatter';
import InvestigatorFormatter from '../../../Locations/SiteDetails/CellRenderers/InvestigatorFormatter';
import PRSValidation from 'components/ag-grid-cell-renderers/LocationsPRSValidation/PRSValidation';

// context
import useAuthoringValidationsStore from '../../hooks/stores/useAuthoringValidationsStore';

const SitesGrid = (props) => {
  const { sites, study } = props;
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState([]);

  const {
    sitesValidations,
  } = useAuthoringValidationsStore(
    useShallow(state => ({
      sitesValidations: state.sitesValidations,
    }))
  )

  useEffect(() => {
    if (sites) {
      formatSites()
    }
  }, [sites, sitesValidations])

  const formatSites = () => {
    let formattedSites = [];

    if (sites && Array.isArray(sites)) {
      sites?.forEach(item => {
        let formattedSite = {
          ...item
        }

        let maskedInformation = {};
        if (formattedSite?.location_data?.mask) maskedInformation = formattedSite.location_data.mask
        formattedSite.location_data.source = {
          ...formattedSite.location_data.ctg,
          ...formattedSite.location_data.source,
          ...maskedInformation
        }

        formattedSites.push(formattedSite)
      })
    }


    if (sitesValidations?.length > 0) {
      let valMap = {}

      sitesValidations.forEach(validation => {
        if (valMap[validation.site_db_id]) {
          valMap[validation.site_db_id].push(validation)
        } else {
          valMap[validation.site_db_id] = [validation]
        }
      })

      formattedSites = formattedSites.map(site => {
        return {
          ...site,
          validations: valMap[site.id] ? valMap[site.id] : []
        }
      })
    }
    setRowData(formattedSites)
  }

  const gridOptions = {
    columnDefs: [
      {
        field: "site_id",
        headerName: "",
        cellRenderer: SiteID,
        cellClass: "simpleField",
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          if (nodeA?.data?.only_on_ctg) return 1;
          else if (nodeA?.data?.only_on_source) return -1;
          return 0
        },
        valueSetter: (params) => {
          let res = [];
          if (params.data) {
            if (params.data.is_new) res.push("New");
            if (params.data.only_on_source) res.push("No CTG");
            if (params.data.only_on_ctg) res.push("No source");
            if (params.data.recruitment_status_changed) res.push("Recruitment status changed");
            return res.join(", ");
          }
          return null;
        },
        valueGetter: params => {
          let res = [];
          if (params.data) {
            if (params.data.is_new) res.push("New");
            if (params.data.only_on_source) res.push("No CTG");
            if (params.data.only_on_ctg) res.push("No source");
            if (params.data.recruitment_status_changed) res.push("Recruitment status changed");
            return res.join(", ");
          }
          return null;
        },
        width: 150,
      },
      {
        headerName: "Site ID",
        field: "site_rowid",
        cellClass: "simpleField",
        valueGetter: params => {
          if (params?.data?.location_data?.source?.site_rowid) {
            return params.data.location_data.source.site_rowid
          } else if (params?.data?.site_id) {
            return params.data.site_id
          }
          return ""
        },
        width: 150,
      },
      {
        headerName: "Site Number",
        field: "location_data.source.site_number",
        cellClass: "simpleField",
        width: 120
      },
      {
        headerName: "Site Activation Date",
        field: "location_data.source.site_active_date",
        cellClass: "simpleField"
      },
      {
        headerName: "Recruitment Status",
        field: "location_data.source.status",
        width: 150,
        // cellRenderer: "comparator",
        // valueGetter: params => sourceValueGetter(params),
        // cellClassRules: getSourceCellClasses
      },
      {
        headerName: "PRS Validation",
        field: "validations",
        valueGetter: params => {
          if (params?.data?.validations) {
            let validations = params?.data?.validations;
            if (isObject(validations) && !Array.isArray(validations) && validations !== null) {
              validations = [validations];
            }
  
            let errors = [];
            let nonSiteErrors = [];
            let warnings = [];
            let notes = [];
  
  
            if (validations && validations.length > 0) {
              validations.forEach(item => {
                if (item.severity === "ERROR") {
                  if (item.site_db_id) {
                    errors.push(item)
                  } else {
                    nonSiteErrors.push(item)
                  }
                };
                if (item.severity === "WARNING") warnings.push(item);
                if (item.severity === "NOTE") notes.push(item);
              })
  
              let items = [];
              if (errors.length > 0) items.push(errors.length + " error(s)");
              if (warnings.length > 0) items.push(warnings.length + " warning(s)");
              if (notes.length > 0) items.push(notes.length + " note(s)");
              if (nonSiteErrors.length > 0) items.push(nonSiteErrors.length + " non-site errors");
  
              if (items && items.length > 0) return items.join(", ");
  
  
              return null;
            }
          }
          return null
        },
        cellRenderer: PRSValidation,
        cellRendererParams: {
          source: "study-details",
          onOpenModal: () => {},
          studyData: study
        },
        filter: false
      },
      {
        headerName: "Facility Name",
        field: "location_data.source.facility.name",
        width: 250,
        // cellRenderer: "AddressComparator",
        // valueGetter: params => sourceValueGetter(params),
        // cellClassRules: getSourceCellClasses,
      },
      {
        headerName: "City",
        field: "location_data.source.facility.address.city",
        // cellRenderer: "AddressComparator",
        width: 150,
        // valueGetter: params => sourceValueGetter(params),
        // cellClassRules: getSourceCellClasses,
      },
      {
        headerName: "State/Province",
        field: "location_data.source.facility.address.state",
        // cellRenderer: "AddressComparator",
        // valueGetter: params => sourceValueGetter(params),
        // cellClassRules: getSourceCellClasses,
      },
      {
        headerName: "Country",
        field: "location_data.source.facility.address.country",
        // cellRenderer: "AddressComparator",
        // valueGetter: params => sourceValueGetter(params),
        // cellClassRules: getSourceCellClasses,
      },
      {
        headerName: "Postal Code",
        field: "location_data.source.facility.address.zip",
        // cellRenderer: "AddressComparator",
        // valueGetter: params => sourceValueGetter(params),
        // cellClassRules: getSourceCellClasses,
      },
      {
        headerName: "Site Contact",
        field: "location_data.source.contact",
        // cellRenderer: "ContactComparator",
        width: 250,
        cellRenderer: ContactFormatter,
        // valueGetter: params => sourceValueGetter(params),
        // cellClassRules: getSourceCellClasses,
      },
      {
        headerName: "Backup Site Contact",
        field: "location_data.source.contact_backup",
        // cellRenderer: "ContactComparator",
        width: 250,
        cellRenderer: ContactFormatter,
        // valueGetter: params => sourceValueGetter(params),
        // cellClassRules: getSourceCellClasses,
      },
      {
        headerName: "Principal Investigator",
        field: "location_data.source.investigator",
        cellRendererParams: {
          type: "Principal Investigator"
        },
        cellRenderer: InvestigatorFormatter,
        // cellRenderer: "InvestigatorComparator",
        // valueGetter: params => sourceValueGetter(params),
        // cellClassRules: getSourceCellClasses,
      },
      {
        headerName: "Sub-Investigator",
        field: "location_data.source.investigator",
        cellRendererParams: {
          type: "Sub-Investigator"
        },
        cellRenderer: InvestigatorFormatter,
        // valueGetter: params => sourceValueGetter(params),
        // cellClassRules: getSourceCellClasses,
      },
    ],
    defaultColDef: {
      resizable: true,
      sortable: true,
      wrapText: true,
      filter: true,
      enablePivot: true,
      enableCharts: false,
      enableRowGroup: false,
      filterParams: { clearButton: true },
      cellStyle: { "whiteSpace": "normal !important" },
      autoHeight: true,
      cellClassRules: {
        oddBackground: function (params) {
          return params.rowIndex % 2 == 0;
        },
      },
    },
    frameworkComponents: {
      SiteID,
      PRSValidation
    }
  }

  const onGridReady = (params) => {
    setGridColumnApi(params.columnApi);
  }

  return (
    <Container className="ag-theme-balham" id="ag-theme-prime-disabled">
      <GridTypeA
        onGridReady={onGridReady}
        gridOptions={gridOptions}
        rowData={rowData}
        domLayout="autoHeight"
      />
    </Container>
  );
};

export default SitesGrid;

const Container = styled.div`
  width: 100%;
`