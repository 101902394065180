import * as React from 'react'
import PropTypes from 'prop-types'
import GridSearchInput from 'containers/requests/components/GridSearchInput/GridSearchInput'
import IconButton from 'containers/requests/components/IconButton/IconButton'
import './FilesGridTools.scss'

const FilesGridTools = ({
  setSearch,
  setAddFilesModalOpen,
  showSearch = true
}) => {
  return <div className="files-grid-tools">
    {showSearch && <GridSearchInput
      setSearch={setSearch}
      placeholder="Search files"
    />}
    <IconButton
      variant="primary-dashed"
      iconClassName="fal fa-file-plus"
      className="request-action-btn"
      onClick={() => setAddFilesModalOpen(true)}
    >Add file
    </IconButton>
  </div>
}

FilesGridTools.propTypes = {
  setSearch: PropTypes.func,
  setAddFilesModalOpen: PropTypes.func,
}

export default FilesGridTools