import React, { useContext } from 'react';

// components
import Note from 'components/Note/Note';
import TextInput from 'components/TextInput/TextInput';

// hoc
import InputWrapper from '../../hoc/InputWrapper';

const RJTextArea = (props) => {
  let {
    id,
    carbonProps,
    invalid,
    invalidText,
    value,
    onChange,
    onFocus,
    onBlur,
  } = props;

  return (
    <>
      <TextInput
        type="textarea"
        id={id + "-field"}
        className={"rjs-widget"}
        style={carbonProps?.styles && carbonProps.styles.widget}
        disabled={carbonProps?.disabled}
        defaultValue={value}
        onChange={e => onChange(e.target.value)}
        onFocus={onFocus}
        onBlur={onBlur}
        autoSize={true}
      />
      {invalid && <Note type="error" text={invalidText} style={{ marginTop: 4 }} />}
    </>
  );
};

export default InputWrapper(RJTextArea);