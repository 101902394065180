import { baselineCharacteristicsDataSchema } from '../../../widgets/RJBaselineChars/schemas/dataSchema';
import { outcomeMeasuresDataSchema } from '../../../widgets/RJOutcomeMeasures/schemas/dataSchema';
import { adverseEventsDataSchema } from '../../../widgets/RJAdverseEvents/schemas/dataSchema';

import {
  countries
} from '../enum/enum';

export const primeResultsDataSchema = {
  type: "object",
  properties: {
    results: {
      type: "object",
      properties: {
        more_info: {
          type: "object",
          properties: {
            certain_agreements: {
              type: "object",
              properties: {
                is_pis_employees: {
                  type: "string",
                  enum: ["Yes", "No"],
                },
                results_disclosure: {
                  type: "string",
                  enum: ["Please Select--", "Yes", "No"],
                },
              },
              dependencies: {
                results_disclosure: {
                  oneOf: [{
                    properties: {
                      results_disclosure: {
                        enum: ["Yes"],
                      },
                      pi_disclosure: {
                        type: "string",
                        enum: ["None Selected", " The only disclosure restriction on the PI is that the sponsor can review results communications prior to public release and can embargo communications regarding trial results for a period that is less than or equal to 60 days from the time submitted to the sponsor for review. The sponsor cannot require changes to the communication and cannot extend the embargo.", 
                        "The only disclosure restriction on the PI is that the sponsor can review results communications prior to public release and can embargo communications regarding trial results for a period that is more than 60 days but less than or equal to 180 days from the time submitted to the sponsor for review. The sponsor cannot require changes to the communication and cannot extend the embargo.", 
                        "Other disclosure agreement that restricts the right of the PI to discuss or publish trial results after the trial is completed."],
                      },
                    },
                    dependencies: {
                      pi_disclosure: {
                        oneOf: [{
                          properties: {
                            pi_disclosure: {
                              enum: ["Other disclosure agreement that restricts the right of the PI to discuss or publish trial results after the trial is completed."],
                            },
                            describe_agreement: {
                              type: "string",
                            }
                          }
                        }]
                      }
                    }
                  }]
                }
              }
            },
            results_poc: {
              type: "object",
              properties: {
                name: {
                  type: "string",
                },
                organization_name: {
                  type: "string",
                },
                phone: {
                  type: "string",
                },
                ext: {
                  type: "string",
                },
                email: {
                  type: "string",
                },
    
              },
            },
            global_protocol_amendments: {
              type: "object",
              properties: {
                amendments: {
                  type: "string",
                  enum: ["Yes", "No"],
                },
              },
              dependencies: {
                amendments: {
                  oneOf: [{
                    properties: {
                      amendments: {
                        enum: ["Yes"],
                      },
                      add_amendment: {
                        type: "array",
                        items: {
                          type: "object",
                          properties: {
                            amendment_date: {
                              type: "string",
                            },
                            amendment_description: {
                              type: "string",
                            }
                          }
                        }
                      }
                    }
                  }]
                }
              },
            },
            global_protocol_interruptions: {
              type: "object",
              properties: {
                interruptions: {
                  type: "string",
                  enum: ["Yes", "No"],
                  errorMessage: "The question 'Were there any global interruptions to the protocol?' has not been answered. Provide an answer to this question.",
                },
              },
              dependencies: {
                interruptions: {
                  oneOf: [{
                    properties: {
                      interruptions: {
                        enum: ["Yes"],
                      },
                      add_interruption: {
                        type: "array",
                        items: {
                          type: "object",
                          properties: {
                            interruption_date: {
                              type: "string",
                            },
                            interruption_description: {
                              type: "string",
                            },
                            restart: {
                              type: "string",
                            }
                          }
                        }
                      }
                    }
                  }]
                }
              },
            },
            limitations_caveats: {
              type: "string",
            },
            online_references: {
              type: "object",
              properties: {
                pub_med_identifier: {
                   type: "array",
                   items: {
                    type: "object",
                    properties: {
                      link: {
                        type: "string",
                      },
                    },
                  },
                },
              },
            },
          },
        },
        trial_info: {
          title: "Trial Information",
          type: "object",
          properties: {
            trial_id: {
              title: "Trial Identification",
              type: "object",
              properties: {
                eu_num: {
                  type: "string",
                  maxLength: 14
                },
                reg_num: {
                  type: "string",
                  maxLength: 35
                },
                official_title: {
                  type: "string",
                  maxLength: 2000
                },
              },
            },
            additional_study_identifiers: {
              title: "Additional Study Identifiers",
              type: "object",
              properties: {
                isrctn_num: {
                  type: "string",
                  maxLength: 50
                },
                nct_id: {
                  type: "string",
                  maxLength: 50
                },
                who_id: {
                  type: "string",
                  maxLength: 50
                },
              }
            },
            secondary_ids: {
              type: "array",
              items: {
                type: "object",
                onAddButtonTitle: "Add Secondary ID",
                properties: {
                  other_identifier_name: {
                    type: "string",
                    maxLength: 50
                  },
                  other_identifier_code: {
                    type: "string",
                    maxLength: 50
                  },
                },
              }
            },
            sponsor: {
              type: "array",
              items: {
                type: "object",
                onAddButtonTitle: "Add Sponsor",
                properties: {
                  name: {
                    type: "string",
                    maxLength: 160
                  },
                  address: {
                    type: "string",
                    maxLength: 100
                  },
                  city: {
                    type: "string",
                    maxLength: 100
                  },
                  zip_code: {
                    type: "string",
                    maxLength: 20
                  },
                  country: {
                    type: "string",
                    enum: countries
                  },
                  scientific_contact: {
                    title: "Scientific Contact Point",
                    type: "object",
                    properties: {
                      name: {
                        type: "string",
                        maxLength: 100
                      },
                      title: {
                        type: "string",
                        maxLength: 100
                      },
                      area_code: {
                        type: "string",
                        maxLength: 8
                      },
                      phone_number: {
                        type: "string",
                        maxLength: 8
                      },
                      email: {
                        type: "string",
                        maxLength: 100
                      },
                    }
                  },
                  public_contact: {
                    title: "Public Contact Point",
                    type: "object",
                    properties: {
                      name: {
                        type: "string",
                        maxLength: 100
                      },
                      title: {
                        type: "string",
                        maxLength: 100
                      },
                      area_code: {
                        type: "string",
                        maxLength: 8
                      },
                      phone_number: {
                        type: "string",
                        maxLength: 8
                      },
                      email: {
                        type: "string",
                        maxLength: 100
                      },
                    }
                  },
                }
              }
            },
            paediatric_regulatory_details: {
              type: "object",
              title: "",
              properties: {
                trial_part: {
                  type: "string",
                  enum: ["Yes", "No"]
                },
              },
              dependencies: {
                trial_part: {
                  oneOf: [
                    {
                      properties: {
                        trial_part: {
                          enum: ["Yes"],
                        },
                        ema_plan: {
                          type: "string",
                        }
                      }
                    },
                    {
                      properties: {
                        trial_part: {
                          enum: ["No"],
                        }
                      }
                    }
                  ]
                }
              }
            },
            article_45: {
              type: "string",
              enum: ["Yes", "No"]
            },
            article_46: {
              type: "string",
              enum: ["Yes", "No"]
            },
            result_analysis_stage: {
              type: "object",
              properties: {
                analysis_stage: {
                  type: "string",
                  enum: ["Interim", "Final"]
                },
                analysis_date: {
                  type: "string",
                },
                primary_completion_data: {
                  type: "object",
                  properties: {
                    is_primary_completion_data: {
                      type: "string",
                      enum: ["Yes", "No"]
                    },
                  },
                  dependencies: {
                    is_primary_completion_data: {
                      oneOf: [
                        {
                          properties: {
                            is_primary_completion_data: {
                              enum: ["Yes"],
                            },
                            primary_completion_date: {
                              type: "string"
                            },
                          }
                        },
                        {
                          properties: {
                            is_primary_completion_data: {
                              enum: ["No"],
                            },
                          }
                        }
                      ]
                    }
                  }
                },
                global_end_date: {
                  type: "object",
                  properties: {
                    is_trial_date_reached: {
                      type: "string",
                      enum: ["Yes", "No"]
                    },
                  },
                  dependencies: {
                    is_trial_date_reached: {
                      oneOf: [
                        {
                          properties: {
                            is_trial_date_reached: {
                              type: "string",
                              enum: ["Yes"],
                            },
                            trial_date: {
                              type: "string"
                            },
                            trial_end: {
                              type: "string",
                              enum: ["Yes", "No"]
                            }
                          }
                        },
                        {
                          properties: {
                            is_trial_date_reached: {
                              type: "string",
                              enum: ["No"]
                            },
                          },
                        }
                      ]
                    }
                  }
                },
              }
            },
            general_info: {
              type: "object",
              properties: {
                trial_objectives: {
                  type: "string",
                  maxlength: 1000
                },
                actual_recruitment_start_date: {
                  type: "string"
                },
                follow_up: {
                  type: "object",
                  properties: {
                    is_planned: {
                      type: "string",
                      enum: ["Yes", "No"]
                    },
                  },
                  dependencies: {
                    is_planned: {
                      oneOf: [{
                        properties: {
                          is_planned: {
                            enum: ["Yes"],
                          },
                          rationale: {
                            type: "array",
                            uniqueItems: true,
                            items: {
                              type: "string",
                              enum: ["Safety", "Efficacy", "Ethical reason", "Regulatory reason", "Scientific research"]
                            }
                          },
                          range: {
                            type: "string",
                          },
                          duration_type: {
                            type: "string",
                            enum: ["Months", "Years"]
                          },
                        }
                      }]
                    }
                  }
                },
                independent: {
                  type: "string",
                  enum: ["Yes", "No"]
                },
                protection: {
                  type: "string",
                  maxLength: 2000
                },
                background: {
                  type: "string",
                  maxLength: 2000
                },
                evidence: {
                  type: "string",
                  maxLength: 2000
                },
              }
            },
            population: {
              title: "Population of trial subjects",
              type: "object",
              properties: {
                country_subject_counts: {
                  title: "Subject number per country",
                  enum: countries,
                  type: "string",
                },
                population_age_group: {
                  title: "Age Breakdown For Trial",
                  type: "string",
                }
              }
            },
          }
        },
        participant_flow: {
          title: "Participant Flow",
          type: "object",
          properties: {
            protocol_enrollment: {
              type: "string"
            },
            recruitment_details: {
              type: "string"
            },
            pre_assignment_details: {
              type: "string"
            },
            type_units_analyzed: {
              type: "string"
            },
            participant_flow_table: {
              type: "string",
            },
          }
        },
        baseline_characteristics: baselineCharacteristicsDataSchema,
        outcome_measures: outcomeMeasuresDataSchema,
        adverse_events: adverseEventsDataSchema
      }
    }
  }
}