import React, { useState, useEffect } from "react";
import styled from "styled-components";

// hoc 
import InputWrapper from '../../hoc/InputWrapper';

const RJIntegerInput = (props) => {
  let { id, carbonProps } = props;
  
  const [value, setValue] = useState(0);
  const [isDisabled, setDisabled] = useState(false);

  useEffect(() => {
    if(props.value || typeof props.value === 'number') {
      let num = parseInt(props.value);
      if(typeof num === 'number') setValue(num);
    }
  }, [props.value]);

  const onMinus = () => {
    console.log('Integer useEffect >> onChange')
    props.onChange(value - 1);
  };

  const onPlus = () => {
    console.log('Integer useEffect >> onChange')
    props.onChange(value + 1);
  };

  return (
    <Container>
      <InputContainer id={id + "-field"}>
        <MinusContainer 
          disabled={carbonProps.disabled} 
          onClick={() => onMinus()}>
          <i className="fal fa-minus"/>
        </MinusContainer>
        <ValueContainer disabled={carbonProps.disabled}>{value}</ValueContainer>
        <PlusContainer 
          disabled={carbonProps.disabled} 
          onClick={() => onPlus()}>
          <i className="fal fa-plus"/>
        </PlusContainer>
      </InputContainer>
    </Container>
  );
};

export default InputWrapper(RJIntegerInput);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
`;

const MinusContainer = styled.div`
  margin-right: 14px;
  cursor: ${props => props.disabled ? "not-allowed" : "pointer" };
  color: ${props => props.disabled ? "#c6c6c6" : "#000" };
`;

const PlusContainer = styled.div`
  margin-left: 14px;
  cursor: ${props => props.disabled ? "not-allowed" : "pointer" };
  color: ${props => props.disabled ? "#c6c6c6" : "#000" };
`;

const ValueContainer = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #18669E;
  background-color: ${props => props.disabled ? "#f4f4f4" : "#fff" };
  color: ${props => props.disabled ? "#c6c6c6" : "#000" };
  border: ${props => props.disabled ? "2px solid #c6c6c6" : "2px solid #18669E" };
`;
