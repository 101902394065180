import * as React from 'react'
import PropTypes from 'prop-types'
import FavoriteCellRenderer from '../FavoriteCellRenderer'

// apis
import { usePostFavoriteStudy } from 'api/hooks/studies/useStudiesApi'

const FavoriteStudyCellRenderer = ({
  value,
  data,
  studyIdKey,
}) => {
  const postFavoriteStudy = usePostFavoriteStudy(data?.[studyIdKey])

  const saveFavoriteState = () => {
    if (!data?.[studyIdKey]) return
    postFavoriteStudy({
      id: `${data?.[studyIdKey]}/favorite/`
    })
  }

  return <FavoriteCellRenderer
    value={value}
    onClick={saveFavoriteState}
  />
}

FavoriteStudyCellRenderer.propTypes = {
  value: PropTypes.bool,
  data: PropTypes.object,
  studyIdKey: PropTypes.oneOf(['id', 'study__id']),
}

export default FavoriteStudyCellRenderer