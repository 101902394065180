import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PrimeField from '../../PrimeField/PrimeField';

const RJCheckBox = (props) => {
  const [isChecked, setChecked] = useState(false);
  const options = props.options;
  const title = options.title;

  useEffect(() => {
    setChecked(props.value)
  }, [props.value])

  const onChange = (e) => {
    props.onChange(e)
    setChecked(e)
  }

  useEffect(() => {
    setChecked(props.value)
  }, [props.value])

  return (
    <Container style={options.styles && options.styles.field}>
      <PrimeField
        schema={{
          id: props.id,
          label: title,
          type: "checkbox",
        }}
        value={isChecked}
        onChange={onChange}/>
      {/* <Checkbox 
        style={{
          display: "block",
          position: "relative",
          overflow: "hidden"
        }}
        id={props.id}
        aria-label={props.id + "-aria"}
        labelText={title} 
        checked={isChecked}
        onChange={onChange}/> */}
    </Container>
  );
};

export default RJCheckBox;

const Container = styled.div`
  
`