// TODO: rename to studyDocumentsApis.js to distinguish from non-study specific documents and b/c this file does not do a default export of a hook
import useMutateApi from "../useMutateApi"

// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

// apis
import useGetApi from "../useGetApi"
import getUrl from "api/apiMap"
import {
  useGetDocument,
  usePutDocument,
  useDeleteDocument,
} from "api/hooks/documents/useDocumentsApi"

const useGetStudyDocuments = (authoringId) => {
  return useGetApi(getUrl(`${apiEndpoints.authoring}${authoringId}/documents/`), queryKeys.documents.authoringList(authoringId))
}

// to get document use useGetDocument from useDocumentsApi

// NOTE: use usePostDocument when not associating new document with a study
const usePostStudyDocument = (authoringId) => {
  return useMutateApi({
    method: 'post',
    apiEndpoint: getUrl(`${apiEndpoints.authoring}${authoringId}/documents/`),
    invalidateQueries: [queryKeys.documents.authoringList(authoringId), queryKeys.checklists.list(authoringId), queryKeys.checklists.nonChecklists(authoringId)],
    successMsg: "Sucessfully created document"
  })
}

// to post document in review view use usePostDocument from useDocumentsApi

// to put document use usePutDocument from useDocumentsApi

// to delete document use useDeleteDocument from useDocumentsApi

const usePutReviewerDocument = (authoringId, documentId) => {
  return useMutateApi({
    method: 'put',
    apiEndpoint: getUrl(`${apiEndpoints.authoring}${authoringId}/documents/${documentId}/review-update/`),
    invalidateQueries: [queryKeys.documents.authoringList(authoringId), queryKeys.documents.details(documentId)],
    successMsg: "Sucessfully updated document"
  })
}

// get CTG ONLY documents
const useGetCtgDocuments = (authoringId) => {
  return useGetApi(getUrl(`${apiEndpoints.authoring}${authoringId}/ctg-documents-list/`), queryKeys.documents.ctgDocuments(authoringId))
}

const useGetExtraDocumentFields = () => {
  return useGetApi(getUrl(apiEndpoints.extraDocumentFields), queryKeys.extraDocumentFields.all)
}

export {
  useGetStudyDocuments,
  usePostStudyDocument,
  usePutReviewerDocument,
  useGetCtgDocuments,
  useGetExtraDocumentFields,

  // imported from /api/hooks/documents/useDocumentsApi:
  useGetDocument,
  usePutDocument,
  useDeleteDocument,
}