import { useState } from 'react';
import { v4 as uuid } from 'uuid'
import { useShallow } from 'zustand/react/shallow'

// components
import { Button } from 'components-design-system';
import PrimeField from 'components/PrimeField/PrimeField';
import Label from 'components/Label/Label';
import Milestone from './Milestone';
import Withdrawn from './Withdrawn';

// context
import useAppState from 'context/hooks/useAppState';
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';
import useAuthoringDataStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringDataStore';

const PrePeriod = ({
  participantData,
  setParticipantData,
  schema
}) => {
  const {
    openToastNotification
  } = useAppState()

  const {
    formData,
    setFormData,
  } = useAuthoringDataStore(
    useShallow(state => ({
      formData: state.formData,
      setFormData: state.setFormData,
    }))
  )

  const {
    readOnly
  } = useAuthoringViewStore(
    useShallow(state => ({ readOnly: state.readOnly }))
  )

  const [showPeriod, setShowPeriod] = useState(participantData?.pre_assignment_period ? true : false);

  const addPeriod = () => {
    setShowPeriod(true)
    let tempData = { ...participantData };
    tempData.pre_assignment_period = {};
    setParticipantData(tempData)
  };

  const deletePeriod = () => {
    setShowPeriod(false);
    let tempData = { ...participantData };
    delete tempData.pre_assignment_period
    setParticipantData(tempData)
    let globalData = { ...formData }
    globalData.results.participant_flow.participant_flow_table = { ...tempData }
    setFormData(globalData)
  };

  const onChangePeriodProps = (e, key) => {
    let tempData = { ...participantData };
    let prePeriod = tempData?.pre_assignment_period || {}
    prePeriod = { ...prePeriod, [key]: e };
    tempData.pre_assignment_period = prePeriod
    setParticipantData(tempData)
  };

  const onChangeOtherMilestone = (e, key, index) => {
    let tempData = { ...participantData };

    let preAssignmentPeriod = {}
    if(tempData.pre_assignment_period) preAssignmentPeriod = {... tempData.pre_assignment_period};
    preAssignmentPeriod.other_milestones[index][key] = e;

    setParticipantData(tempData)
  };

  const onChangeWithdrawn = (e, key, index) => {
    let tempData = { ...participantData };

    let preAssignmentPeriod = {}
    if(tempData.pre_assignment_period) preAssignmentPeriod = {... tempData.pre_assignment_period};
    
    if(key === "title" && e !== "Other (please specify)") {
      let foundIndex = preAssignmentPeriod?.not_completed_reason_details?.findIndex(item => {
        return item[key] === e
      });
  
      if(foundIndex !== -1) {
        openToastNotification("error", "Duplicate reason", "Non Completion Reason already exists.");
      } else {
        preAssignmentPeriod.not_completed_reason_details[index][key] = e;
        setParticipantData(tempData)
      }
    } else {
      if(preAssignmentPeriod?.not_completed_reason_details?.[index]) {
        preAssignmentPeriod.not_completed_reason_details[index][key] = e;
        setParticipantData(tempData)
      }
    }
  };

  const addMilestone = () => {
    let tempData = { ...participantData };

    let newMilestone = {
      id: `OtherMilestone-${uuid()}`,
      title: "",
      subjects: null
    }

    let preAssignmentPeriod = {}
    if(tempData.pre_assignment_period) preAssignmentPeriod = { ...tempData.pre_assignment_period };
    let items = [];

    if(tempData?.pre_assignment_period?.other_milestones) items = [...tempData.pre_assignment_period.other_milestones]
    items.push(newMilestone)
    preAssignmentPeriod.other_milestones = items;

    tempData.pre_assignment_period = {...preAssignmentPeriod}
    setParticipantData(tempData)    
  }

  const addNonCompletion = () => {
    let tempData = { ...participantData }
    let prePeriod = tempData?.pre_assignment_period || {}
    let notCompletedReasons = prePeriod.not_completed_reason_details || []
    const newWithdrawn = {
      id: `OtherMilestone-${uuid()}`,
      title: "",
      subjects: null
    };

    notCompletedReasons.push(newWithdrawn)
    prePeriod.not_completed_reason_details = notCompletedReasons
    tempData.pre_assignment_period = prePeriod
    setParticipantData(tempData)
  }

  const renderPeriod = () => {
    let startedSchema = schema.properties.eudract.properties.pre_assignment_period.properties.started_milestone_achievement
    let completedSchema = schema.properties.eudract.properties.pre_assignment_period.properties.completed_milestone_achievement

    return (
      <>
        <tr key={`eudract-pre-assignment-period-started`}>
          <td className="header-column">
            {startedSchema.title}
          </td>
          <td>
            <PrimeField
              schema={{
                placeholder: "Number of Subjects",
                type: "number",
                size: "small",
                style: { width: "100%" }
              }}
              readOnly={readOnly}
              value={participantData?.pre_assignment_period?.started_milestone_achievement}
              onChange={e => onChangePeriodProps(e, "started_milestone_achievement")}
            />
          </td>
        </tr>
        {participantData?.pre_assignment_period?.other_milestones?.map((milestone, i) => {
          return (
            <Milestone
              key={`preassignment-milestone-${i}`}
              milestone={milestone}
              onChangeOtherMilestone={onChangeOtherMilestone}
              i={i}
              participantData={participantData}
              setParticipantData={setParticipantData}
              errorKeyPrefix={`results_participant_flow_participant_flow_table_pre_assignment_period_other_milestones`} />
          )
        })}
        <tr key={`eudract-pre-assignment-period-completed`}>
          <td className="header-column">
            {completedSchema.title}
          </td>
          <td>
            <PrimeField
              schema={{
                placeholder: "Number of Subjects",
                type: "number",
                orientation: "vertical",
                style: { width: "100%" }
              }}
              readOnly={readOnly}
              value={participantData?.pre_assignment_period?.completed_milestone_achievement}
              onChange={e => onChangePeriodProps(e, "completed_milestone_achievement")}

            />
          </td>
        </tr>
      </>
    )
  }

  return (
    <div>
      {readOnly !== true &&
        <div>
          {showPeriod
            ? <Button
              variant="primary-dashed"
              size="sm"
              onClick={deletePeriod}
            >
              <i
                className="fal fa-minus-circle"
                style={{
                  marginTop: 2,
                  marginRight: 8
                }} />
              Delete Pre-assignment Period
            </Button>
            : <Button
              variant="primary-dashed"
              size="sm"
              onClick={addPeriod}
            >
              <i
                className="fal fa-plus-circle"
                style={{
                  marginTop: 2,
                  marginRight: 8
                }} />
              Add Pre-assignment Period
            </Button>
          }
        </div>
      }
      {showPeriod &&
        <div>
          <Label style={{ marginTop: 16, marginBottom: 8 }}>Pre-assignment Period</Label>
          <table>
            <tbody>
              {renderPeriod()}
            </tbody>
          </table>
          {readOnly !== true &&
            <Button
              variant="outlined"
              size="sm"
              onClick={addMilestone}
              style={{ marginTop: 16, marginBottom: 8 }}
            >
              <i
                className="fal fa-plus-circle"
                style={{
                  marginTop: 2,
                  marginRight: 8
                }} />
              Add Intermediate milestone
            </Button>
          }
        </div>
      }
      {showPeriod &&
        <div>
          <Label style={{ marginTop: 16, marginBottom: 8 }}>
            Subject non-completion reasons
          </Label>
          <table>
            <tbody>
              {participantData?.pre_assignment_period?.not_completed_reason_details?.map((withdrawn, i) => {
                return (
                  <Withdrawn
                    key={`eudract-pre-assignment-withdrawn-${i}`}
                    withdrawn={withdrawn}
                    onChangeWithdrawn={onChangeWithdrawn}
                    i={i}
                    participantData={participantData}
                    setParticipantData={setParticipantData}
                    schema={schema}
                  />
                )
              })}
            </tbody>
          </table>
          {readOnly !== true &&
            <Button
              variant="outlined"
              size="sm"
              style={{ marginTop: 16, marginBottom: 8 }}
              onClick={addNonCompletion}
            >
              <i
                className="fal fa-plus-circle"
                style={{
                  marginTop: 2,
                  marginRight: 8
                }} />
              Add Reason for non-completion
            </Button>}
        </div>
      }

    </div>
  );
};

export default PrePeriod;