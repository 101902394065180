import { useState, useEffect } from 'react';
import { cloneDeep, uniq } from 'lodash';
import { useShallow } from 'zustand/react/shallow'

// components
import PrimeField from 'components/PrimeField/PrimeField';
import Modal from 'components/Modal/Modal';
import Note from 'components/Note/Note';

// context
import useResultsTableViewStore from '../../../../hooks/stores/useResultsTableViewStore';
import useResultsTableArmsStore from '../../../../hooks/stores/useResultsTableArmsStore';
import useAuthoringDataStore from '../../../../hooks/stores/useAuthoringDataStore';
import useAuthoringViewStore from '../../../../hooks/stores/useAuthoringViewStore';

import './ArmOverrideModal.scss';

const sources = {
  "participant-flow": [
    {
      registry: "ctg",
      label: "CTG",
      items: [
        {
          // disabled: true,
          label: "Participant flow",
          key: "pf"
        },
        {
          label: "Baseline characteristics",
          key: "bc"
        },
        {
          label: "Outcome measures",
          key: "om"
        },
        {
          label: "Adverse events",
          key: "ae"
        },
      ]
    },
    {
      registry: "eudract",
      label: "EudraCT",
      items: [
        {
          // disabled: true,
          label: "Subject disposition",
          key: "pf"
        },
        {
          // disabled: true,
          label: "Baseline characteristics",
          key: "bc"
        },
        {
          // disabled: true,
          label: "Outcome measures",
          key: "om"
        },
        {
          label: "Adverse events",
          key: "ae"
        },
      ]
    }
  ],
  "baseline-characteristics": [
    {
      registry: "ctg",
      label: "CTG",
      items: [
        {
          label: "Participant flow",
          key: "pf"
        },
        {
          label: "Baseline characteristics",
          key: "bc"
        },
        {
          label: "Outcome measures",
          key: "om"
        },
        {
          label: "Adverse events",
          key: "ae"
        },
      ]
    },
    {
      registry: "eudract",
      label: "EudraCT",
      items: [
        {
          label: "Subject disposition",
          key: "pf"
        },
        {
          label: "Baseline characteristics",
          key: "bc"
        },
        {
          label: "Outcome measures",
          key: "om"
        },
        {
          label: "Adverse events",
          key: "ae"
        },
      ]
    }
  ],
  "outcome-measures": [
    {
      registry: "ctg",
      label: "CTG",
      items: [
        {
          label: "Participant flow",
          key: "pf"
        },
        {
          label: "Baseline characteristics",
          key: "bc"
        },
        {
          label: "Outcome measures",
          key: "om",
        },
        {
          label: "Adverse events",
          key: "ae"
        },
      ]
    },
    {
      registry: "eudract",
      label: "EudraCT",
      items: [
        {
          label: "Subject disposition",
          key: "pf"
        },
        {
          label: "Baseline characteristics",
          key: "bc"
        },
        {
          label: "Outcome measures",
          key: "om"
        },
        {
          label: "Adverse events",
          key: "ae"
        },
      ]
    }
  ],
  "adverse-events": [
    {
      registry: "ctg",
      label: "CTG",
      items: [
        {
          label: "Participant flow",
          key: "pf"
        },
        {
          label: "Baseline characteristics",
          key: "bc"
        },
        {
          label: "Outcome measures",
          key: "om"
        },
        {
          label: "Adverse events",
          key: "ae",
        },
      ]
    },
    {
      registry: "eudract",
      label: "EudraCT",
      items: [
        {
          label: "Subject disposition",
          key: "pf"
        },
        {
          label: "Baseline characteristics",
          key: "bc"
        },
        {
          label: "Outcome measures",
          key: "om"
        },
        {
          label: "Adverse events",
          key: "ae",
        },
      ]
    }
  ]
}

const defaultValues = {
  "participant-flow": {
    ctg: {
      pf: true,
      bc: false,
      om: false,
      ae: false
    },
    eudract: {
      pf: true,
      bc: true,
      om: true,
      ae: false
    }
  },
  "baseline-characteristics": {
    ctg: {
      pf: false,
      bc: true,
      om: false,
      ae: false
    },
    eudract: {
      pf: true,
      bc: true,
      om: true,
      ae: false
    }
  },
  "outcome-measures": {
    ctg: {
      pf: false,
      bc: false,
      om: true,
      ae: false
    },
    eudract: {
      pf: true,
      bc: true,
      om: true,
      ae: false
    }
  },
  "adverse-events": {
    ctg: {
      pf: false,
      bc: false,
      om: false,
      ae: true
    },
    eudract: {
      pf: false,
      bc: false,
      om: false,
      ae: true
    }
  }
}

const schema = [
  {
    type: "textarea",
    label: "Title",
    key: "title"
  },
  {
    type: "textarea",
    label: "Description",
    key: "description"
  }
]

const ArmOverrideModal = ({
  open,
  onCancel,
  arm,
  source
}) => {
  const { tableView } = useResultsTableViewStore()
  const {
    formData,
    setFormData,
  } = useAuthoringDataStore(
    useShallow(state => ({
      formData: state.formData,
      setFormData: state.setFormData,
    }))
  )

  const {
    refreshFormData,
    setRefreshFormData
  } = useAuthoringViewStore(
    useShallow(state => ({
      refreshFormData: state.refreshFormData,
      setRefreshFormData: state.setRefreshFormData,
    }))
  )

  const {
    baselineSas,
    setBaselineSas
  } = useResultsTableArmsStore()
  // const [sources, setSources] = useState({})
  const [armData, setArmData] = useState({
    title: "",
    description: ""
  })

  const [applicableSections, setApplicableSections] = useState({
    eudract: {
      pf: false,
      bc: false,
      om: false,
      ae: false
    },
    ctg: {
      pf: false,
      bc: false,
      om: false,
      ae: false
    }
  })

  useEffect(() => {
    setApplicableSections(defaultValues[source])
  }, [defaultValues])

  useEffect(() => {
    if (!open) {
      setApplicableSections(defaultValues[source])
    }
  }, [open])

  useEffect(() => {
    if (arm) {
      setArmData({
        title: arm.title,
        description: arm.description
      })
    }
  }, [arm])

  const onSave = () => {
    let rawArmId = arm.id.split(".").slice(-1)[0]

    let tempData = { ...formData }
    Object.keys(applicableSections).map(registry => {
      let sections = applicableSections[registry];
      if (sections.pf) {
        findPfArmAndSave(tempData, registry, rawArmId)
      }
      if (sections.bc) {
        findBcArmAndSave(tempData, registry, rawArmId)
      }
      if (sections.om) {
        findOmArmAndSave(tempData, registry, rawArmId)
      }
      if (sections.ae) {
        findAeArmAndSave(tempData, registry, rawArmId)
      }
    })

    setFormData(tempData)
    onCancel()
    setRefreshFormData(!refreshFormData)
  }

  const findPfArmAndSave = (data, registry, rawArmId) => {
    let pfTable = data?.results?.participant_flow?.participant_flow_table
    if (registry === "ctg" && pfTable?.["global_arms"]) {
      let foundIndex = pfTable["global_arms"].findIndex(arm => arm?.id?.includes(rawArmId));
      if (foundIndex !== -1) {
        pfTable["global_arms"][foundIndex].title = armData.title;
        pfTable["global_arms"][foundIndex].description = armData.description;
      }
    } else if (registry === "eudract" && pfTable?.["eudract_periods"]) {
      pfTable["eudract_periods"].forEach(period => {
        let foundIndex = period.arms.findIndex(arm => arm.id.includes(rawArmId));
        if (foundIndex !== -1) {
          period.arms[foundIndex].title = armData.title;
          period.arms[foundIndex].description = armData.description;
        }
      })
    }
  }

  const findBcArmAndSave = (data, registry, rawArmId) => {
    let bcTable = data?.results?.baseline_characteristics?.baseline_characteristics_table
    if (bcTable?.[registry]?.arms) {
      let foundIndex = bcTable[registry].arms.findIndex(arm => arm.id.includes(rawArmId))
      if (foundIndex !== -1) {
        bcTable[registry].arms[foundIndex].title = armData.title
        bcTable[registry].arms[foundIndex].description = armData.description
      } else if (bcTable?.[registry]?.subject_analysis_sets) {

        let foundIndex = bcTable[registry].subject_analysis_sets.findIndex(arm => arm.id.includes(rawArmId))
        if (foundIndex !== -1) {
          bcTable[registry].subject_analysis_sets[foundIndex].title = armData.title
          bcTable[registry].subject_analysis_sets[foundIndex].description = armData.description

          setBaselineSas(uniq([...baselineSas, bcTable[registry].subject_analysis_sets[foundIndex]], "id"))
        }
      }
    }
  }

  const findOmArmAndSave = (data, registry, rawArmId) => {
    let omTable = data?.results?.outcome_measures?.outcome_measures_table
    if (omTable?.[registry]?.["outcome_measures"]) {
      omTable[registry]["outcome_measures"].forEach(om => {
        let foundIndex = om.arms.findIndex(arm => arm.id.includes(rawArmId))
        if (foundIndex !== -1) {
          om.arms[foundIndex].title = armData.title
          om.arms[foundIndex].description = armData.description
        } else if (om?.subject_analysis_sets) {
          let foundIndex = om.subject_analysis_sets.findIndex(arm => arm.id.includes(rawArmId))
          if (foundIndex !== -1) {
            om.subject_analysis_sets[foundIndex].title = armData.title
            om.subject_analysis_sets[foundIndex].description = armData.description
          }
        }
      })
    }
  }

  const findAeArmAndSave = (data, registry, rawArmId) => {
    let ae = data?.results?.adverse_events?.adverse_events_table
    if (ae?.[registry]?.groups) {
      let foundIndex = ae[registry].groups.findIndex(group => group.id.includes(rawArmId))
      if (foundIndex !== -1) {
        ae[registry].groups[foundIndex].title = armData.title
        ae[registry].groups[foundIndex].description = armData.description
      }
    }
  }

  const onCheck = (key, value, registry) => {
    if ((key === "pf" || key === "bc" || key === "om") && registry === "eudract") {
      setApplicableSections({
        ...applicableSections,
        [registry]: {
          ...applicableSections[registry],
          pf: value,
          bc: value,
          om: value,
        }
      })
    } else {
      setApplicableSections({
        ...applicableSections,
        [registry]: {
          ...applicableSections[registry],
          [key]: value
        }
      })
    }
  }


  let tableTitle = source?.replace("-", " ")
  if (tableView === "eudract" && tableTitle === "participant flow") tableTitle = "subject disposition"

  return (
    <Modal
      title={`Edit ${tableTitle} arm`}
      open={open}
      onCancel={onCancel}
      hideImage={true}
      width={500}
      onOk={onSave}
      okText={"Save changes"}>
      <div className="arm-override-modal-container">
        <div className="arm-properties">
          {schema.map(field => {
            return (
              <PrimeField
                key={field.key}
                schema={field}
                onChange={e => setArmData({ ...armData, [field.key]: e })}
                value={armData[field.key]} />
            )
          })}

        </div>
        <div className="registries-container">
          {sources?.[source]?.map(registry => {
            return (
              <div
                key={registry.registry}
                className="registry-container">
                <p>{registry.label}</p>
                <div>
                  {registry.items.map(checkbox => {
                    return (
                      <PrimeField
                        schema={{
                          type: "checkbox",
                          label: checkbox.label
                        }}
                        key={registry.registry + checkbox.key}
                        disabled={checkbox.disabled}
                        value={applicableSections?.[registry.registry]?.[checkbox.key]}
                        onChange={e => onCheck(checkbox.key, e, registry.registry)} />
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
        <Note
          style={{ marginTop: 24 }}
          type="note"
          text="For EudraCT, all linked Arms will be updated. If there is a need to update an arm independently, please create a Subject Analysis Set" />
      </div>
    </Modal>
  );
};

export default ArmOverrideModal;