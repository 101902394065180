import React, { useState } from 'react';
import styled from 'styled-components';
import { useShallow } from 'zustand/react/shallow'

// components
import Modal from 'components/Modal/Modal';
import PrimeField from 'components/PrimeField/PrimeField';
import { Button } from 'components-design-system'
// import { Button } from 'components-design-system'

// hooks
import useAuthoringViewStore from '../../../hooks/stores/useAuthoringViewStore';

const ResultsTableCreateModal = (props) => {
  const [createForALL, setCreateForAll] = useState(false);
  const [createCategoryCtg, setCreateCategoryCtg] = useState(false)
  const [createRowCtg, setCreateRowCtg] = useState(true)

  let {
    header,
    showModal,
    setShowModal,
    onSubmit,
    addEudractCategory = false,
    registrySpecific
  } = props;

  const { 
    formView,
  } = useAuthoringViewStore(
    useShallow(state => ({ 
      formView: state.formView,
    })),
  )

  const handleSubmit = async () => {

    setShowModal(false)
    await new Promise(r => setTimeout(r, 200));
    if (formView.id === "global") onSubmit(true, {
      createCategoryCtg,
      createRowCtg
    });
    else onSubmit(createForALL, {
      createCategoryCtg,
      createRowCtg
    });
    setCreateForAll(false);
  }

  const displayAdditionalCheckboxes = () => {
    return (
      <>
        <PrimeField
          schema={{
            type: "checkbox",
            label: "You are adding a category in EudraCT, when creating in CTG - add as a category",
          }}
          value={createCategoryCtg}
          onChange={e => {
            setCreateCategoryCtg(e)
            setCreateRowCtg(!e)
          }} />
        <PrimeField
          schema={{
            type: "checkbox",
            label: "You are adding a category in EudraCT, when creating in CTG - add as a row",
          }}
          value={createRowCtg}
          onChange={e => {
            setCreateCategoryCtg(!e)
            setCreateRowCtg(e)
          }} />
      </>
    )
  }

  let view = formView.id;
  if (formView.id === "eudra") view = "eudract";

  return (
    <Modal
      title={header}
      open={showModal}
      destroyOnClose={true}
      onCancel={() => setShowModal(false)}
      footer={[]}>
      <ModalContent>
        {formView.id === "global"
          ? <ContentContainer>
            Note: If applicable, creating items from Global view will create items for all registries.
          </ContentContainer>
          : <ContentContainer>
            {registrySpecific !== true && (
              <PrimeField
                schema={{
                  type: "checkbox",
                  label: "Create for all registries",
                }}
                value={createForALL}
                onChange={e => setCreateForAll(e)} />
              // <Checkbox
              //   id={"create-for-all-" + id}
              //   checked={createForALL}
              //   onChange={e => setCreateForAll(e)}
              //   labelText={"Create for all registries"} />
            )}
            {view === "eudract" && addEudractCategory === true && createForALL && displayAdditionalCheckboxes()}
            <PrimeField
              schema={{
                type: "checkbox",
                label: `Create for ${view?.toUpperCase()} only`,
              }}
              value={true} />
            {/* <Checkbox
              id={"create-for-registry" + id}
              checked={true}
              labelText={`Create for ${view?.toUpperCase()} only`} /> */}
            <br />
            NOTE: If adding only to {view?.toUpperCase()}, this data may not appear in the Global view
          </ContentContainer>
        }
        <ActionContainer>
          <Button
            onClick={() => setShowModal(false)}
            variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={() => handleSubmit()}
            variant="primary">
            Create
          </Button>
        </ActionContainer>
      </ModalContent>
    </Modal>
  );
};

export default ResultsTableCreateModal;

const ContentContainer = styled.div`
  margin-bottom: 32px;
`

const ModalContent = styled.div`

`

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 12px;
  p {
    font-size: 12px !important;
  }
`