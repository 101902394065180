import React, { useContext } from 'react';
import styled from 'styled-components';

import { isArmIdMatch } from '../../../../../../RJS/widgets/utils';

// styles
import './Header.scss';

const Header = (props) => {
  const {
    title,
    arms,
    bgColor,
    showAdverseEventsProps,
    schema,
    view,
    subjectAnalysisSets,
    source,
    data,
    formData
  } = props;
  let bcData = formData?.results?.baseline_characteristics?.baseline_characteristics_table?.[view]

  const getBCGroupProp = (arm, type, key) => {
    let group = bcData?.[type]?.find(group => {
      if (isArmIdMatch(group?.id, arm?.id)) return group;
    })
    if (group) return group?.[key];
    return arm?.[key];
  }

  let firstColumnWidth = 250;

  const renderSas = () => {

  }

  return (
    <div className="preview-group-header">
      {title && <div className="preview-group-header-title">
        {title}
      </div>}
      <table className="preview-group-header-table ">
        <tbody>
          <tr>
            <td style={{ backgroundColor: bgColor, fontWeight: "bold", width: firstColumnWidth }}>
              Group
            </td>
            <td style={{ backgroundColor: bgColor, fontWeight: "bold" }}>
              Description
            </td>
          </tr>
          {arms?.map(arm => {
            let keys, fieldSchema
            if (schema && view) {
              keys = schema.properties[view].groups.show_keys;
              fieldSchema = schema.properties[view].groups.properties;
            }

            let description = arm.description;

            if (source === "outcome") {
              description = getBCGroupProp(arm, "arms", "description")
            }
            return (
              <tr key={arm.id}>
                <td style={{ backgroundColor: bgColor, fontWeight: "bold", width: firstColumnWidth }}>
                  {arm.title || `(Title)`}
                </td>
                <td>
                  {description || `(Description)`}
                  {showAdverseEventsProps === true && keys?.filter(key => key !== "description" && key !== "title").map((key, i) => {
                    return (
                      <div>
                        <span>{fieldSchema[key].title}: </span>
                        <span style={{ fontWeight: "bold" }}>{arm[key]}</span>
                      </div>
                    )
                  })}
                </td>
              </tr>
            )
          })}
          {subjectAnalysisSets?.filter(sas => sas).map(sas => {
            let keys, fieldSchema
            if (schema && view) {
              keys = schema.properties[view].subject_analysis_sets.show_keys;
              fieldSchema = schema.properties[view].subject_analysis_sets.properties;
            }

            let description = sas.description;

            if (source === "outcome") {
              description = getBCGroupProp(sas, "subject_analysis_sets", "description")
            }

            return (
              <tr key={sas.id}>
                <td style={{ backgroundColor: bgColor, fontWeight: "bold" }}>
                  {sas.title || `(Title)`}
                </td>
                <td>
                  {description || `(Description)`}
                  {showAdverseEventsProps === true && keys.filter(key => key !== "description" && key !== "title").map((key, i) => {
                    return (
                      <div>
                        <span>{fieldSchema[key].title}: </span>
                        <span style={{ fontWeight: "bold" }}>{sas[key]}</span>
                      </div>
                    )
                  })}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Header;