export const commentsData = [
  {
    id: 1,
    tags: ["Reviewer"],
    field_key: "root_study_identification_protocol_id",
    field_name: "Protocol ID",
    author: "Tim Perin",
    date: new Date(),
    text: "This is a comment",
    replies: [],
    resolved: false,
    favorite: true
  },
  {
    id: 2,
    tags: ["Guidance"],
    field_key: "root_study_identification_acronym",
    field_name: "Acronym",
    author: "Tim Perin",
    date: new Date(),
    text: "This should be updated to “lorem ipsum dolor sit amet”",
    replies: [3, 4, 5], // list of comment ID's
    resolved: true,
    favorite: false
  },
  {
    id: 3,
    tags: ["Reviewer"],
    field_key: "root_study_identification_acronym",
    field_name: "Acronym",
    author: "Woo Song",
    date: new Date(),
    text: "This is another comment",
    replies: [3, 4, 5], // list of comment ID's
    resolved: true,
    favorite: false
  },
  {
    id: 4,
    tags: ["Data Source"],
    field_key: "root_study_identification_official_title",
    field_name: "Official Title",
    author: "Tim Perin",
    date: new Date(),
    text: "This should be updated to “lorem ipsum dolor sit amet”",
    replies: [],
    resolved: true,
    favorite: true
  },
  {
    id: 5,
    tags: ["Data Source"],
    field_key: "root_contact_information_central_contacts_backup_central_contacts_backup_degree",
    field_name: "Degrees",
    author: "Tim Perin",
    date: new Date(),
    text: "This should be updated to “lorem ipsum dolor sit amet”",
    replies: [],
    resolved: true,
    favorite: true
  },
]

export const validationData = [
  {
    id: 1,
    type: "error",
    form_view: "CTG",
    form_section: "Protocol",
    text: "This is a validation message",
    field_key: "root_acronym",
    field_name: "Acronym"
  }
]