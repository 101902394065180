import PropTypes from 'prop-types'
import FavoriteCellRenderer from '../FavoriteCellRenderer'
import {
  usePostFavoriteRequest,
} from 'api/hooks/requests/useRequestsApi'

const FavoriteRequestCellRenderer = ({
  value,
  data,
}) => {
  const postRequestFavorite = usePostFavoriteRequest(data?.id)

  return <FavoriteCellRenderer
    value={value}
    onClick={() => postRequestFavorite({})}
  />
}

FavoriteRequestCellRenderer.propTypes = {
  value: PropTypes.bool,
  data: PropTypes.shape({
    id: PropTypes.number,
  }),
}

export default FavoriteRequestCellRenderer