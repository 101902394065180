import { userToString } from './useUsers'

const useTeam = () => {
  const teamUsersToStr = (team) => {
    if (team) {
      let map = new Set()
      let users = []
      team.forEach(role => {
        role.members.forEach(member => {
          if (!map.has(member.user_id)) {
            let user = { id: member.person_id, text: member.person_fullname + '(' + role.role_name + ')' }
            users.push(user)
            map.add(member.person_id)
          }
        })
      })

      return users
    }

    return []
  }

  const usersStrToIds = (value, team) => {
    let userIds = []
    value?.forEach(val => {
      team.forEach(user => {
        if (val === userToString(user)) {
          userIds.push(user.person_id)
        }
      })
    })
    return userIds
  }

  return {
    teamUsersToStr,
    usersStrToIds,
  }
}

export default useTeam