import React from 'react';

// styles
import './registry-badge.scss';

const RegistryBadge = (props) => {
  let style = {

  }

  let colors = {
    ctg: {
      light: "#E8F0F5",
      dark: "#18669E"
    },
    eudract: {
      light: "#F5FAF0",
      dark: "#9ECF6B"
    },
    eupas: {
      light: "#F5FAF0",
      dark: "#9ECF6B"
    },
    ctis: {
      light: "#F5FAF0",
      dark: "#9ECF6B"
    }
  }

  switch(props.registry.toLowerCase()) {
    case "ctg":
      style = {
        border: `1px solid ${colors.ctg.dark}`,
        backgroundColor: colors.ctg.light,
        color: colors.ctg.dark
      }
      break;
    case "eudract":
      style = {
        border: `1px solid ${colors.eudract.dark}`,
        backgroundColor: colors.eudract.light,
        color: colors.eudract.dark
      }
      break;
    default: break;
  }
  return (
    <div 
      className="registry-badge-container"
      style={style}>
      {props?.registry}
    </div>
  );
};

export default RegistryBadge;