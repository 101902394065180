import getUrl from 'api/apiMap'
import useGetApi from '../useGetApi'
import useMutateApi from '../useMutateApi'

// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

export const useGetNotifications = () => {
  return useGetApi(`${getUrl(apiEndpoints.notifications)}all-notifications/`, queryKeys.notifications)
}

export const usePostMarkAllAsRead = () => {
  return useMutateApi({
    method: 'post',
    apiEndpoint: `${getUrl(apiEndpoints.notifications)}mark-all-as-read/`,
    invalidateQueries: [queryKeys.notifications],
    successMsg: 'All notifications marked as read',
  })
}

export const usePostMarkAsRead = () => {
  return useMutateApi({
    method: 'post',
    apiEndpoint: `${getUrl(apiEndpoints.notifications)}mark-as-read/`,
    invalidateQueries: [queryKeys.notifications],
    successMsg: 'Notification marked as read',
  })
}

export const useDeleteNotification = () => {
  return useMutateApi({
    method: 'delete',
    apiEndpoint: `${getUrl(apiEndpoints.notifications)}remove/`,
    invalidateQueries: [queryKeys.notifications],
    successMsg: "Sucessfully deleted notification"
  })
}