import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

// Components
import { Button } from 'components-design-system'
import FormContainer from '../SimpleRJSchema/FormContainer/FormContainer';
import ModalHeaderActions from './ModalHeaderActions';

// Templates
import ObjectTemplate from '../NewSiteDetails/RJSTempates/ObjectTemplate';
import ArrayTemplate from '../NewSiteDetails/RJSTempates/ArrayTemplate';

// Data Schema
import { newSiteSchema } from '../NewSiteDetails/DataSchema/newSiteSchema';

// UI Schema
import { newSiteUISchema } from '../NewSiteDetails/UISchema/newSiteUISchema';

// Context
import useLocations from 'context/hooks/useLocations';

// api
import {
  useGetSiteManagementLocationMasking,
  usePutSiteManagementLocationMasking
} from 'api/hooks/siteManagement/useSiteManagementApi';
import { useUserPermissions } from 'api/hooks';

// helpers
import { validationMessagesForNode } from 'utilities/PRSValidation';

const EditSiteDetails = ({
  setOpenModal,
  onOpenModal,
  gridProps,
  onEditSite,
  messages
}) => {
  const { siteManagementId } = useParams()
  const {
    setLocationMaskSettings
  } = useLocations()

  const [UISchema, setUISchema] = useState(newSiteUISchema);
  const [formData, setFormData] = useState(null);
  const [prsValidation, setPrsValidation] = useState([])
  const [maskSettings, setMaskSettings] = useState({})

  // apis
  const { data: permissions } = useUserPermissions()
  const { data: locationMasking, refetch: refetchLocationMasking } = useGetSiteManagementLocationMasking(siteManagementId, gridProps?.data?.id)
  const putSiteManagementLocationMasking = usePutSiteManagementLocationMasking(siteManagementId)

  const nodeData = gridProps?.data;

  useEffect(() => {
    if (nodeData && nodeData.site_id) {
      let form = {
        ...nodeData.location_data.source,
        site_id: nodeData.site_id,
      }
      setFormData(form);
      refetchLocationMasking()
    }
  }, [nodeData]);

  useEffect(() => {
    if (locationMasking?.masking_config) {
      setMaskSettings(locationMasking.masking_config)
    }
  }, [locationMasking])

  useEffect(() => {
    if (messages) {
      let msgs = []
      messages.forEach(message => {
        msgs.push({
          severity: { $t: message.severity },
          source: { $t: message.source },
          message: { $t: message.message }
        })
      })
      let formattedMsgs = validationMessagesForNode(msgs)
      setPrsValidation(formattedMsgs)
    }
  }, [])

  useEffect(() => {
    let UISchema = {
      ...newSiteUISchema,
      site_id: {
        ...newSiteUISchema.site_id,
        ["ui:disabled"]: true
      }
    };
    setUISchema(UISchema)
  }, [newSiteUISchema])

  useEffect(() => {
    setLocationMaskSettings(maskSettings)
  }, [maskSettings])

  const onChangeFormData = (value) => {
    setFormData(value);
  }

  const onAutocompleteChange = (data) => {
    let address = {
      city: "",
      country: "",
      state: "",
      zip: "",
    }

    data.address_components.forEach(item => {
      item.types.forEach(type => {
        switch (type) {
          case "country":
            address.country = item.long_name;
            break;
          case "postal_code":
            address.zip = item.long_name;
            break;
          case "administrative_area_level_1":
            address.state = item.long_name;
            break;
          case "locality":
            address.city = item.long_name;
          case "sublocality":
            address.city = item.long_name;
            break;
        }
      });
    });

    setFormData({
      ...formData,
      facility: {
        name: data.name,
        address: {
          ...address
        }
      }
    })
  }

  const onDelete = () => {
    onOpenModal("delete-site", gridProps, formData)
  }

  const closeModal = () => {
    setOpenModal(false);
    if (nodeData && nodeData.site_id) {
      let form = {
        ...nodeData.location_data.source,
        site_id: nodeData.site_id
      }
      setFormData(form);
    }
  }

  const onChangeMaskSettings = (key, e) => {
    let val = e.target.checked;

    let temp = {
      ...maskSettings,
      [key]: val
    }

    setMaskSettings(temp)
  }

  const formValidation = {
    country: {
      values: new Set(["United States", "US", "USA"]),
      required: new Set(["zip", "state"])
    },
  }

  return (
    <Container>
      <ModalBody>
        <ModalHeaderActions
          permissions={permissions}
          onDelete={onDelete}
          maskSettings={maskSettings}
          onChangeMaskSettings={onChangeMaskSettings} />
        <Wrapper>
          <FormContainer
            schema={newSiteSchema}
            uiSchema={UISchema}
            formData={formData}
            formContext={{
              onAutocompleteChange,
              formValidation,
              prsValidation,
              formData,
            }}
            ObjectFieldTemplate={ObjectTemplate}
            ArrayFieldTemplate={ArrayTemplate}
            onChange={onChangeFormData}
            onSubmit={() => { }}>
          </FormContainer>
        </Wrapper>
      </ModalBody>
      <ActionContainer>
        <Button
          variant="outlined"
          onClick={() => closeModal()}>
          Cancel
        </Button>
        <Button
          disabled={formData && formData.site_id ? false : true}
          onClick={() => {
            putSiteManagementLocationMasking({
              id: gridProps?.data?.id + '/',
              body: {
                masking_config: {
                  ...maskSettings,
                  contact_backup_mask: maskSettings?.contact_mask,
                }
              }
            })
            onEditSite(gridProps, formData,)
          }}>
          Save Changes
        </Button>
      </ActionContainer>
    </Container>
  );
};

export default EditSiteDetails;

const Container = styled.div`

`

const ModalBody = styled.div`
  margin-top: 16px;
  margin-bottom: 50px;
`

const ActionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;
`

const Wrapper = styled.div`
  max-height: 295px;;
  width: 100%;
  align-items: center;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 5px;
  overflow-y: scroll;
`