import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import './FavoriteItem.scss'

// api
import { usePostFavoriteStudy } from 'api/hooks/studies/useStudiesApi'
import { usePostFavoriteRequest } from 'api/hooks/requests/useRequestsApi'

// context
import useAppState from 'context/hooks/useAppState'

const FavoriteItem = ({
  type = 'studies',
  setIsOpen,
  favorite,
}) => {
  const postFavoriteStudy = usePostFavoriteStudy(type === 'studies' ? favorite.id : null)
  const postFavoriteRequest = usePostFavoriteRequest(type === 'requests' ? favorite.id : null)
  const { setRefetchStudiesGrid } = useAppState()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const handlePostFavorite = () => {
    if (type === 'studies') {
      postFavoriteStudy({
        id: `${favorite.id}/favorite/`
      }, {
        onSuccess: () => {
          if (pathname === "/studies" || pathname === "/studies/") {
            setRefetchStudiesGrid(true)
          }
        }
      })
    } else if (type === 'requests') {
      postFavoriteRequest({})
    }
  }

  const onFaveClick = () => {
    setIsOpen(false)
    navigate(`/${type === 'studies' ? 'study' : 'request'}/${favorite.id}/overview`)
  }

  return <div className="favorite-item">
    <i
      className="fal fa-heart"
      onClick={() => handlePostFavorite()}
    />
    <a onClick={onFaveClick}>
      {favorite[`${type === 'studies' ? 'study_id' : 'request_id'}`]}
    </a>
  </div>
}

FavoriteItem.propTypes = {
  type: PropTypes.oneOf(['studies', 'requests']),
  favorite: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.string,
      study_id: PropTypes.string,
    }),
    PropTypes.shape({
      id: PropTypes.string,
      request_id: PropTypes.string,
    }),
  ]),
  setIsOpen: PropTypes.func,
}

export default FavoriteItem