/**
 * Used by FilesGrid, RequestsReportGrid, and RequestsGrid
 */
import * as React from 'react'
import EllipsisPopover from '../../EllipsisPopover/EllipsisPopover'
import PropTypes from 'prop-types'
import StudyPill from '../../../containers/requests/components/StudyPill/StudyPill'
import './StudiesCellRenderer.scss'

const StudiesCellRenderer = ({
  value,
  data,
  dataKey,
  idKey = 'id',  // "core_stu_id" for documents-grid api
  isReviewerView = false,
}) => {

  const studies = React.useMemo(() => {
    if (value && typeof value === 'object') return value;
    if (!dataKey) return data?.study_ids
    return data?.[dataKey]
  }, [])

  if (!studies) return null;

  // popover with all other study pills, beside the first one
  const getEllipsisContent = () => {
    // text without links for reviewer view
    if (isReviewerView) {
      return studies.reduce((acc, studyObj, index) => {
        if (index > 2) acc.push(studyObj.study_id);
        return acc
      }, []).join(", ")
    }
    // pills with links
    else {
      return studies.reduce((acc, studyObj, index) => {
        if (index > 2) acc.push(<StudyPill
          key={studyObj.id}
          studyObj={studyObj} />)
        return acc
      }, [])
    }
  }

  return <div className="study-pill-wrap">
    {/* column contents with three studies */}
    {isReviewerView ?
      // text without links for reviewer view
      (studies.reduce((acc, studyObj, index) => {
        if (index < 3) acc.push(studyObj.study_id)
        return acc
      }, []).join(", ")) :
      // pills with links
      (studies.reduce((acc, studyObj, index) => {
        if (index < 3) acc.push(<StudyPill
          key={studyObj[idKey]}
          studyObj={studyObj}
          idKey={idKey}
        />)
        return acc
      }, []))
    }
    {/* popover with all study pills */}
    {studies.length > 3 &&
      <EllipsisPopover
        title="STUDIES"
        content={getEllipsisContent()}
        maxWidth={234}
        overlayClassName="study-popover"
        className="study-popover-content"
      />
    }
  </div>
}

StudiesCellRenderer.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  data: PropTypes.object.isRequired,
  dataKey: PropTypes.oneOf(["study_ids", "corestudies", "authoringstudies_w_stu_ids", "authoringstudies"]),
  id: PropTypes.string,
  isReviewerView: PropTypes.bool,
}

export default StudiesCellRenderer