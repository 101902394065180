import React from 'react';
import styled from 'styled-components';

const PreviewWrapper = (WrappedComponent) => {
  const formatOptions = (rjProps) => {
    let options = rjProps.uiSchema && rjProps.uiSchema["ui:options"] ? rjProps.uiSchema["ui:options"] : rjProps.options;
    let label = options.title;

    if (label === "data-schema") {
      label = `        ${rjProps.schema.title}: `;
    }
    let res = {
      ...options,
      label
    }

    if (rjProps?.schema?.uiSchema) {
      res = {
        ...res,
        ...rjProps.schema.uiSchema
      }
      if (rjProps.schema.label) {
        res.label = rjProps.schema.label
      }
    }

    return res;
  }

  return (props) => {
    let options = formatOptions(props);

    if (options.hide === true) {
      return null;
    }

    return (
      <Container key={props.id}>
        <WrappedComponent
          {...props}
          carbonProps={options}
        />
      </Container>
    )

  }
};

export default PreviewWrapper;

const Container = styled.div`
  width: 100%;
  white-space: pre-line;
`