import * as React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

// components
import Field from 'containers/requests/components/Field/Field'
import { Select } from 'components-design-system'
import PrimeField from 'components/PrimeField/PrimeField'
import SecondaryIdField from '../SecondaryIdField/SecondaryIdField'

// utils
import useStudySearch from 'utilities/useStudySearch/useStudySearch'
import useAutoClose from 'utilities/useAutoClose'

// context
import useWorkflowTracker from 'context/hooks/useWorkflowTracker'

// apis
import { useGetUsersList } from 'api/hooks/users/useUsersApi'
import { useGetRequestCategories } from 'api/hooks/admin/useRequestCategoriesApi'
import { useGetRequest } from 'api/hooks/requests/useRequestsApi'
import { useGetRequestAdditionaFields } from 'api/hooks/admin/workflowApis/useRequestTypeApis';

// styles
import './RequestAllFields.scss'

export const CORE_FIELDS = {
  "request_id": {
    type: "text",
    label: "Request ID",
    readOnly: true,
    width: 186,
    containerClassName: "md-field",
    baseClassName: "sm-field",
    base: true,
  },
  "request_type_name": {
    type: "text",
    label: "Request type",
    readOnly: true,
    width: 186,
    containerClassName: "md-field",
    baseClassName: "sm-field",
    base: true,
  },
  "request_category_id": {
    type: "dropdown",
    label: "Request category",
    width: 186,
    containerClassName: "md-field",
    items: []
  },
  "request_data.requestor": {
    type: "text",
    label: "Requestor",
    width: 186,
    containerClassName: "md-field",
    baseClassName: "sm-field",
    base: true,
  },
  "request_data.date_requested": {
    type: "date",
    label: "Requested date",
    width: 130,
    containerClassName: "sm-field",
    baseClassName: "sm-field",
    base: true,
  },
  "created_at": {
    type: "date",
    label: "Created date",
    readOnly: true,
    width: 130,
    containerClassName: "sm-field",
    baseClassName: "sm-field",
    base: true,
  },
  "due_date": {
    type: "date",
    label: "Due date",
    width: 130,
    containerClassName: "sm-field",
    baseClassName: "",
  },
  "request_data.description": {
    type: "textarea",
    label: "Description",
    containerClassName: "full-field"
  },
  "request_data.secondary_ids": {
    label: "Secondary ID's",
    width: "calc(80vw - 96px)"
  },
  "request_data.rationale": {
    type: "textarea",
    label: "Rationale",
    containerClassName: "full-field"
  },
  "request_data.organization": {
    type: "text",
    label: "Organization",
    containerClassName: "md-field"
  },
  "request_data.department": {
    type: "text",
    label: "Department",
    containerClassName: "md-field"
  },
  "request_data.point_of_contact": {
    type: "text",
    label: "Point of contact",
    containerClassName: "xl-field"
  },
  "request_data.study_db_ids": {
    label: "Studies",
    base: true,
  },
  "request_data.study_data.product_id": {
    type: "multiselect",
    label: "Product ID(s)",
    mode: "tags",
    containerClassName: "md-field",
  },
  "request_data.study_data.product_name": {
    type: "multiselect",
    label: "Product name(s)",
    mode: "tags",
    containerClassName: "md-field"
  },
  "request_data.study_data.therapeutic_area": {
    type: "multiselect",
    label: "Therapeutic area(s)",
    mode: "tags",
    containerClassName: "lg-field",
    items: []
  },
  "request_data.study_data.indication": {
    type: "multiselect",
    label: "Indication(s)",
    mode: "tags",
    containerClassName: "lg-field",
    items: []
  },
  "request_data.study_data.program_name": {
    type: "multiselect",
    label: "Program name(s)",
    mode: "tags",
    containerClassName: "md-field"
  },
}

const syncKeySet = new Set([
  "request_data.study_data.product_id",
  "request_data.study_data.product_name",
  "request_data.study_data.therapeutic_area",
  "request_data.study_data.indication",
  "request_data.study_data.program_name"
])

const RequestAllFields = ({
  requestFormData,
  readOnly,
  selectedStudies,
  setSelectedStudies,
  // customDataSchema,
  onChangeRequest = () => { },
  isReviewerView = false,
}) => {
  const { requestId } = useParams()
  const [addtlDetailsOpen, setAddtlDetailsOpen] = React.useState(false)
  const { data: users = [] } = useGetUsersList()
  const { data: requestCategories = [] } = useGetRequestCategories()
  const { data: request } = useGetRequest(requestId)
  const { data: schema = [], refetch: refetchAdditionalFields } = useGetRequestAdditionaFields(request?.request_type_id)

  // study search dropdown
  const [dropdownVisible, setDropdownVisible] = React.useState(false)
  const dropdownRef = React.useRef();
  useAutoClose(dropdownRef, setDropdownVisible);

  const {
    studyOptions,
    searchValue,
    handleStudySearch,
  } = useStudySearch()

  React.useEffect(() => {
    refetchAdditionalFields()
  }, [])

  React.useEffect(() => {
    if (!searchValue || searchValue.length === 0) {
      setDropdownVisible(false)
    } else if (Array.isArray(studyOptions) && studyOptions.length > 0) {
      setDropdownVisible(true)
    }
  }, [studyOptions, searchValue])

  const getValue = (key) => {
    const keys = key.split(".");
    const fieldKey = keys.pop();
    const obj = keys.reduce((nestedObj, nestedKey) => nestedObj?.[nestedKey], requestFormData);

    return obj?.[fieldKey] || undefined
  }

  const getDefaultStudyOptions = () => {
    return requestFormData?.corestudies?.map(study => ({ value: study.id, label: study.study_id }))
  }

  const handleChange = (key, val) => {
    let value = val
    if (syncKeySet.has(key)) {
      value = value.join(", ")
    }

    onChangeRequest(key, value)
  }

  const renderFields = () => {
    return Object.keys(CORE_FIELDS).map(key => {
      const field = CORE_FIELDS[key]
      let items = field.items
      let value = getValue(key)

      if (key === "request_data.study_db_ids") return renderStudiesField(field, key)
      if (key === "request_data.secondary_ids") return renderSecondaryIdField(field, key)
      if (key === "request_category_id") items = requestCategories.map(cat => ({ value: cat.id, label: cat.name }))

      if (syncKeySet.has(key)) {
        if (!value || value.length === 0) {
          items = []
          value = []
        } else {
          items = value.split(", ")
          value = value.split(", ")
        }
      }

      let classNames = ["request-addtl-field"]
      if (field.containerClassName) classNames.push(field.containerClassName)
      return (
        <Field
          key={key}
          label={field.label}
          className={classNames.join(" ")}
        >
          <PrimeField
            schema={{
              type: field.type,
              placeholder: field.placeholder,
              items,
              mode: field.mode
            }}
            onChange={(val) => handleChange(key, val)}
            readOnly={field.readOnly || isReviewerView}
            value={value}
            className={field.className}
          />
        </Field>
      )
    })
  }

  const renderStudiesField = (field, key) => {
    let value = requestFormData.corestudies.map(study => ({ value: study.id, label: study.study_id }))
    if (requestFormData.request_data.study_db_ids) value = requestFormData.request_data.study_db_ids.map(study => ({ value: study.id, label: study.study_id }))

    return (
      <Field
        label={field.label}
        className="xl-field"
        key="req-studies-field"
      >
        <Select
          open={dropdownVisible}
          options={[...studyOptions, ...getDefaultStudyOptions()]}
          value={isReviewerView ? value : selectedStudies}
          searchValue={searchValue}
          onChange={(val, options) => {
            setSelectedStudies(options)
            setDropdownVisible(false)
          }}
          mode="multiple"
          onSearch={(value) => handleStudySearch(value)}
          readOnly={readOnly}
          allowClear
          showSearch
          disabled={isReviewerView}
          className="studies-select"
        />
      </Field>
    )
  }

  const renderSecondaryIdField = (field, key) => {
    return (
      <div style={{ width: field.width }} key="req-secondary-ids-field">
        <Field
          label={field.label}
          className="studies-field"
        >
          <SecondaryIdField
            field={field}
            fieldKey={key}
            onChange={(key, val) => onChangeRequest(key, val)}
            value={requestFormData.request_data.secondary_ids || []}
            readOnly={isReviewerView} />
        </Field>
      </div>
    )
  }

  const renderAdditionalFields = () => {
    return schema.map((fieldObj) => {
      let field = fieldObj.field_data

      let classNames = ["request-addtl-field"]

      if (field.type === "textarea") {
        classNames.push("full-field")
      } else if (field.type === "boolean" || field.type === "boolean") {
        classNames.push("sm-field")
      } else {
        classNames.push("lg-field")
      }
      return (
        <Field
          key={fieldObj.id}
          label={field.label}
          className={classNames.join(" ")}
        >
          <PrimeField
            schema={{
              ...field,
              placeholder: isReviewerView ? undefined : field.placeholder,
              label: null,
            }}
            readOnly={isReviewerView}
            value={getValue(`request_data.custom_data.${field.key}`)}
            onChange={(val) => onChangeRequest(`request_data.custom_data.${field.key}`, val)}
          />
        </Field>
      )
    })
  }

  console.log({ schema })

  return <div className="edit-request-info">
    {renderFields()}
    {schema?.length > 0 &&
      <div className="addtl-details">
        <div
          onClick={() => setAddtlDetailsOpen((state) => !state)}
          className="addtl-details-title"
        >Additional details <i className={`far fa-angle-${addtlDetailsOpen ? 'down' : 'up'}`} /></div>
        {addtlDetailsOpen && (
          <div className="addtl-content">
            {renderAdditionalFields()}
          </div>
        )}
      </div>
    }
  </div>
}

RequestAllFields.propTypes = {
  requestFormData: PropTypes.shape({
    due_date: PropTypes.string,
    requestor: PropTypes.string,
    ask_num: PropTypes.string,
    completed: PropTypes.string,
    description: PropTypes.string,
  }),
  readOnly: PropTypes.bool,
}

export default RequestAllFields