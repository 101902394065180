import React from 'react';
import Form from "@rjsf/core";
import { useShallow } from 'zustand/react/shallow'

// widgets
import RJTextInput from './widgets/RJTextInput/RJTextInput';
import RJTextArea from './widgets/RJTextArea/RJTextArea';
import RJDropdown from './widgets/RJDropdown/RJDropdown';
import RJRadio from './widgets/RJRadio/RJRadio';
import RJDatePicker from './widgets/RJDatePicker/RJDatePicker';
import RJMultiselect from './widgets/RJMultiselect/RJMultiselect';
import RJIntegerInput from './widgets/RJIntegerInput/RJIntegerInput';
import RJCheckboxGroup from './widgets/RJCheckboxGroup/RJCheckboxGroup';
import RJLookahead from './widgets/RJLookahead/RJLookahead';
import RJMoreInfoAgeRange from './widgets/RJMoreInfoAgeRange/RJMoreInfoAgeRange';
import RJTrialCountries from './widgets/RJTrialCountries/RJTrialCountries';
import RJCopyEUPASContactCheckbox from './widgets/RJCopyEUPASContactCheckbox/RJCopyEUPASContactCheckbox';
import RJCopyTextArea from './widgets/RJCopyTextArea/RJCopyTextArea';
import RJTypeUnitsAssigned from './widgets/RJTypeUnitsAssigned/RJTypeUnitsAssigned';
import RJSingleCheckbox from './widgets/RJSingleCheckbox/RJSingleCheckbox';
import MSCWrapper from './widgets/MemberStatesConcerned/MSCWrapper';
import RJSelectTag from './widgets/RJSelectTag/RJSelectTag';

// reformatted tables
import ParticipantWrapper from './widgets/RJParticipantFlow/ParticipantWrapper';
import BaselineWrapper from './widgets/RJBaselineChars/BaselineWrapper';
import OutcomeWrapper from './widgets/RJOutcomeMeasures/OutcomeWrapper';
import AdverseWrapper from './widgets/RJAdverseEvents/AdverseWrapper';
import SimpleTableWrapper from './widgets/RJSimpleTable/SimpleTableWrapper';
import CrossReferenceWrapper from './widgets/RJCrossReferenceTable/CrossReferenceWrapper';

// context
import useAppState from 'context/hooks/useAppState';
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';

// styles
import 'styles/authoring-print.scss';

const RJForm = ({
  schema,
  uiSchema,
  onChange,
  ObjectFieldTemplate,
  ArrayFieldTemplate,
  formData,
  formContext
}) => {
  const {
    sidebarOpen
  } = useAppState()

  const {
    isReviewerView,
    showPanel,
  } = useAuthoringViewStore(
    useShallow(state => ({
      readOnly: state.readOnly,
      formView: state.formView,
      formSection: state.formSection,
      showVersionCompare: state.showVersionCompare,
      isReviewerView: state.isReviewerView,
      showPanel: state.showPanel,
    }))
  )

  const widgets = {
    TextWidget: RJTextInput,
    SelectWidget: RJDropdown,
    TextareaWidget: RJTextArea,
    RadioWidget: RJRadio,
    DateWidget: RJDatePicker,
    Multiselect: RJMultiselect,
    CustomSingleInteger: RJIntegerInput,
    CustomCheckboxGroup: RJCheckboxGroup,
    SingleCheckbox: RJSingleCheckbox,
    Lookahead: RJLookahead,
    SimpleTable: SimpleTableWrapper,
    CrossReferenceTable: CrossReferenceWrapper,
    ParticipantFlow: ParticipantWrapper,
    BaselineCharacteristics: BaselineWrapper,
    OutcomeMeasures: OutcomeWrapper,
    AdverseEvents: AdverseWrapper,
    AgeRange: RJMoreInfoAgeRange,
    TrialCountries: RJTrialCountries,
    CopyContact: RJCopyEUPASContactCheckbox,
    CopyTextArea: RJCopyTextArea,
    TypeUnitsAssigned: RJTypeUnitsAssigned,
    MemberStatesConcerned: MSCWrapper,
    SelectTag: RJSelectTag
  };

  let classNames = ["authoring-print-page", "rjs-form"]

  if (!isReviewerView) {
    if (showPanel && sidebarOpen) {
      classNames.push('form-open-sidebar-open-panel')
    } else if (showPanel && !sidebarOpen) {
      classNames.push('form-close-sidebar-open-panel')
    } else if (!showPanel && sidebarOpen) {
      classNames.push('form-open-sidebar-close-panel')
    } else {
      classNames.push('form-close-sidebar-close-panel')
    }
  }

  return (
    <div className={classNames.join(" ")}>
      <Form
        noValidate={true}
        formData={formData}
        schema={schema}
        uiSchema={uiSchema}
        widgets={widgets}
        ObjectFieldTemplate={ObjectFieldTemplate}
        ArrayFieldTemplate={ArrayFieldTemplate}
        onChange={({ formData }) => {
          onChange(formData);
        }}
        formContext={formContext}
      >
        <button type="submit" style={{ display: "none" }}>
          Submit
        </button>
      </Form>
    </div>
  )
};

export default RJForm;