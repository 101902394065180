import React from 'react'
import PropTypes from 'prop-types'
import './tabs.scss'

const Tabs = ({ active, tabs }) => {
  return (
    <div className="tabs-container">
      {tabs.map(tab => {
        let classNames = ["tab"];
        if (tab.id === active) classNames.push("active");
        return <div
          key={tab.id}
          className={classNames.join(" ")}
          onClick={() => tab.action(tab)}
        >
          {tab.title}
        </div>
      })}
    </div>
  )
}

Tabs.propTypes = {
  active: PropTypes.string,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    action: PropTypes.func,
  }))
}


export default Tabs