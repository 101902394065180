import { useState, useEffect } from 'react';
import { cloneDeep } from 'lodash';
import { useShallow } from 'zustand/react/shallow'

// components
import Modal from 'components/Modal/Modal';
import PrimeField from 'components/PrimeField/PrimeField';

// context
import useResultsTableViewStore from '../../../../hooks/stores/useResultsTableViewStore';
import useAuthoringDataStore from '../../../../hooks/stores/useAuthoringDataStore';
import useAuthoringViewStore from '../../../../hooks/stores/useAuthoringViewStore';

// styles
import '../ArmOverrideModal/ArmOverrideModal.scss'

const defaultValues = {
  "participant-flow": {
    ctg: {
      pf: true,
      bc: false,
      om: false,
      ae: false
    },
    eudract: {
      pf: true,
      bc: true,
      om: true,
      ae: false
    }
  },
  "baseline-characteristics": {
    ctg: {
      pf: false,
      bc: true,
      om: false,
      ae: false
    },
    eudract: {
      pf: true,
      bc: true,
      om: true,
      ae: false
    }
  },
  "outcome-measures": {
    ctg: {
      pf: false,
      bc: false,
      om: true,
      ae: false
    },
    eudract: {
      pf: true,
      bc: true,
      om: true,
      ae: false
    }
  },
  "adverse-events": {
    ctg: {
      pf: false,
      bc: false,
      om: false,
      ae: true
    },
    eudract: {
      pf: false,
      bc: false,
      om: false,
      ae: true
    }
  }
}

const registries = ["ctg", "eudract"]

const INITIAL_SOURCE = {
  "participant-flow": [
    {
      registry: "ctg",
      label: "CTG",
      items: [
        {
          disabled: true,
          label: "Participant flow",
          key: "pf"
        },
        {
          disabled: true,
          label: "Baseline characteristics",
          key: "bc"
        },
        {
          disabled: true,
          label: "Outcome measures",
          key: "om"
        },
        {
          disabled: true,
          label: "Adverse events",
          key: "ae"
        },
      ]
    },
    {
      registry: "eudract",
      label: "EudraCT",
      items: [
        {
          disabled: true,
          label: "Subject disposition",
          key: "pf"
        },
        {
          disabled: true,
          label: "Baseline characteristics",
          key: "bc"
        },
        {
          disabled: true,
          label: "Outcome measures",
          key: "om"
        },
        {
          disabled: true,
          label: "Adverse events",
          key: "ae"
        },
      ]
    }
  ],
  "baseline-characteristics": [
    {
      registry: "ctg",
      label: "CTG",
      items: [
        {
          disabled: true,
          label: "Participant flow",
          key: "pf"
        },
        {
          disabled: true,
          label: "Baseline characteristics",
          key: "bc"
        },
        {
          disabled: true,
          label: "Outcome measures",
          key: "om"
        },
        {
          disabled: true,
          label: "Adverse events",
          key: "ae"
        },
      ]
    },
    {
      registry: "eudract",
      label: "EudraCT",
      items: [
        {
          disabled: true,
          label: "Subject disposition",
          key: "pf"
        },
        {
          disabled: true,
          label: "Baseline characteristics",
          key: "bc"
        },
        {
          disabled: true,
          label: "Outcome measures",
          key: "om"
        },
        {
          label: "Adverse events",
          key: "ae"
        },
      ]
    }
  ],
  "outcome-measures": [
    {
      registry: "ctg",
      label: "CTG",
      items: [
        {
          disabled: true,
          label: "Participant flow",
          key: "pf"
        },
        {
          label: "Baseline characteristics",
          key: "bc",
          disabled: true,
        },
        {
          label: "Outcome measures",
          key: "om",
          disabled: true,
        },
        {
          label: "Adverse events",
          key: "ae",
          disabled: true,
        },
      ]
    },
    {
      registry: "eudract",
      label: "EudraCT",
      items: [
        {
          disabled: true,
          label: "Subject disposition",
          key: "pf"
        },
        {
          disabled: true,
          label: "Baseline characteristics",
          key: "bc"
        },
        {
          disabled: true,
          label: "Outcome measures",
          key: "om"
        },
        {
          disabled: true,
          label: "Adverse events",
          key: "ae"
        },
      ]
    }
  ],
  "adverse-events": [
    {
      registry: "ctg",
      label: "CTG",
      items: [
        {
          label: "Participant flow",
          key: "pf",
          disabled: true,
        },
        {
          label: "Baseline characteristics",
          key: "bc",
          disabled: true,
        },
        {
          label: "Outcome measures",
          key: "om",
          disabled: true,
        },
        {
          label: "Adverse events",
          key: "ae",
          disabled: true,
        },
      ]
    },
    {
      registry: "eudract",
      label: "EudraCT",
      items: [
        {
          label: "Subject disposition",
          key: "pf",
          disabled: true,
        },
        {
          disabled: true,
          label: "Baseline characteristics",
          key: "bc"
        },
        {
          disabled: true,
          label: "Outcome measures",
          key: "om"
        },
        {
          label: "Adverse events",
          key: "ae",
          disabled: true,
        },
      ]
    }
  ]
}

export const bcMoveLeftHelper = (tempData, registry, key, i) => {
  let tempKeyData = [...tempData[registry][key]]
  let saved = tempKeyData[i - 1];
  tempKeyData[i - 1] = tempKeyData[i];
  tempKeyData[i] = saved;
  tempData[registry][key] = [...tempKeyData];
}

export const bcMoveRightHelper = (tempData, registry, key, i) => {
  let tempKeyData = [...tempData[registry][key]]
  let saved = tempKeyData[i + 1];
  tempKeyData[i + 1] = tempKeyData[i];
  tempKeyData[i] = saved;
  tempData[registry][key] = [...tempKeyData];
}

export const omMoveLeftHelper = (om, key, i) => {
  let tempGroups = [...om[key]]
  let saved = tempGroups[i - 1];
  tempGroups[i - 1] = tempGroups[i];
  tempGroups[i] = saved;
  om[key] = [...tempGroups];
}

export const omMoveRightHelper = (om, key, i) => {
  let tempGroups = [...om[key]]
  let saved = tempGroups[i + 1];
  tempGroups[i + 1] = tempGroups[i];
  tempGroups[i] = saved;
  om[key] = [...tempGroups];
}

export const aeMoveLeftHelper = (array, i) => {
  let saved = array[i - 1];
  array[i - 1] = array[i];
  array[i] = saved;
}

export const aeMoveRightHelper = (array, i) => {
  let saved = array[i + 1];
  array[i + 1] = array[i];
  array[i] = saved;
}

const eudractTableSet = new Set(["participant-flow", "baseline-characteristics", "outcome-measures"])

const ArmReorderModal = ({
  arm,
  source,
  open,
  onCancel,
  direction,
  setDirection,
  colIndex,
  periodIndex
}) => {
  const {
    formData,
    setFormData,
  } = useAuthoringDataStore(
    useShallow(state => ({
      formData: state.formData,
      setFormData: state.setFormData,
    }))
  )
  const [loading, setLoading] = useState(false)

  const {
    refreshFormData,
    setRefreshFormData
  } = useAuthoringViewStore(
    useShallow(state => ({
      refreshFormData: state.refreshFormData,
      setRefreshFormData: state.setRefreshFormData,
    }))
  )

  const [applicableSections, setApplicableSections] = useState({
    eudract: {
      pf: false,
      bc: false,
      om: false,
      ae: false
    },
    ctg: {
      pf: false,
      bc: false,
      om: false,
      ae: false
    }
  })
  const [sources, setSources] = useState({})
  const { tableView } = useResultsTableViewStore()

  useEffect(() => {
    setApplicableSections(defaultValues[source])
  }, [defaultValues])

  useEffect(() => {
    if (open) {
      getSourceAndCompareArms()
    } else {
      setSources({})
    }
  }, [open])

  const getSourceAndCompareArms = () => {
    switch (source) {
      case "participant-flow":
        let pfTable = formData?.results?.participant_flow?.participant_flow_table
        let pfArms = pfTable?.["global_arms"] || []
        if (tableView === "eudract") {
          pfArms = pfTable["eudract_periods"][periodIndex]?.arms
        }
        compareArms(pfArms)
        break;
      case "baseline-characteristics":
        let bcArms = formData?.results?.baseline_characteristics?.baseline_characteristics_table?.[tableView]?.arms
        compareArms(bcArms)
        break;
      case "outcome-measures":
        let omTable = formData?.results?.outcome_measures?.outcome_measures_table;
        let omArms = omTable?.[tableView]?.outcome_measures?.[periodIndex]?.arms
        compareArms(omArms)
        break;
      case "adverse-events":
        let ae = formData?.results?.adverse_events?.adverse_events_table
        let aeArms = ae?.[tableView]?.groups
        compareArms(aeArms)
        break;
      default:
    }
  }

  const getUuid = (id) => {
    let ids = id.split(".")
    return ids[ids.length - 1]
  }

  const isEqualAndOrdered = (a = [], b = []) => {
    if (a.length !== b.length) return false

    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) return false
    }
    return true
  }

  const compareArms = (original = []) => {
    let tempData = cloneDeep(INITIAL_SOURCE)
    registries.forEach(registry => {
      let pfGlobalArms = formData?.results?.participant_flow?.participant_flow_table?.global_arms || []
      let bcArms = formData?.results?.baseline_characteristics?.baseline_characteristics_table?.[registry]?.arms || []
      let aeArms = formData?.results?.adverse_events?.adverse_events_table?.[registry]?.groups || []
      let oms = formData?.results?.outcome_measures?.outcome_measures_table?.[registry]?.outcome_measures || []
      let eudractPeriods = formData?.results?.participant_flow?.participant_flow_table?.eudract_periods || []

      let originalArmIds = original.map(arm => {
        return getUuid(arm.id)
      })

      let pfGlobalArmIds = pfGlobalArms?.map(arm => {
        return getUuid(arm.id)
      })

      let bcArmIds = bcArms?.map(arm => {
        return getUuid(arm.id)
      })

      let aeArmIds = aeArms?.map(arm => {
        return getUuid(arm.id)
      })

      if (source !== "participant-flow") {
        if (registry === "ctg") {
          if (isEqualAndOrdered(originalArmIds, pfGlobalArmIds)) {
            enableTable(tempData, source, "pf", registry)
          }
        } else if (registry === "eudract" && eudractPeriods && !eudractTableSet.has(source)) {
          for (let period of eudractPeriods) {
            let periodArms = period.arms.map(arm => {
              return getUuid(arm.id)
            })

            if (isEqualAndOrdered(originalArmIds, periodArms)) {
              enableTable(tempData, source, "pf", registry)
              break;
            }
          }
        }
      }

      if (isEqualAndOrdered(originalArmIds, bcArmIds) && source !== "baseline-characteristics") {
        if (registry !== "eudract" || !eudractTableSet.has(source)) {
          enableTable(tempData, source, "bc", registry)
        }
      }

      if (oms && source !== "outcome-measures") {
        if (registry !== "eudract" || !eudractTableSet.has(source)) {
          for (let om of oms) {
            let omArms = om.arms.map(arm => {
              return getUuid(arm.id)
            })

            if (isEqualAndOrdered(originalArmIds, omArms)) {
              enableTable(tempData, source, "om", registry)
              break;
            }
          }
        }
      }

      if (isEqualAndOrdered(originalArmIds, aeArmIds) && source !== "adverse-events") {
        enableTable(tempData, source, "ae", registry)
      } else {
        disableTable(tempData, source, "ae", registry)
        if (isEqualAndOrdered(originalArmIds, aeArmIds) !== true) {
          setApplicableSections({
            ...applicableSections,
            [registry]: {
              ...applicableSections[registry],
              ae: false
            }
          })
        }
      }
    })

    setSources(tempData)
  }

  const enableTable = (tempData, source, key, registry) => {
    let foundRegIndex = tempData[source].findIndex(item => item.registry === registry)

    if (foundRegIndex !== -1) {
      let foundTableIndex = tempData[source][foundRegIndex].items.findIndex(table => table.key === key)

      if (foundTableIndex !== -1) {
        tempData[source][foundRegIndex].items[foundTableIndex].disabled = false
      }
    }
  }

  const disableTable = (tempData, source, key, registry) => {
    let foundRegIndex = tempData[source].findIndex(item => item.registry === registry)

    if (foundRegIndex !== -1) {
      let foundTableIndex = tempData[source][foundRegIndex].items.findIndex(table => table.key === key)

      if (foundTableIndex !== -1) {
        tempData[source][foundRegIndex].items[foundTableIndex].disabled = true
      }
    }
  }

  const onSave = () => {
    setLoading(true)
    let rawArmId = arm.id.split(".").slice(-1)[0]
    let tempData = { ...formData }

    Object.keys(applicableSections).map(registry => {
      let sections = applicableSections[registry];
      if (sections.pf) {
        findPfArmAndSave(tempData, registry, rawArmId)
      }
      if (sections.bc) {
        findBcArmAndSave(tempData, registry, rawArmId)
      }
      if (sections.om) {
        findOmArmAndSave(tempData, registry, rawArmId)
      }
      if (sections.ae) {
        findAeArmAndSave(tempData, registry, rawArmId)
      }
    })

    setFormData(tempData)
    setTimeout(() => {
      onCancel()
      setRefreshFormData(!refreshFormData)
      setLoading(false)
      setDirection(null)
      setApplicableSections(defaultValues[source])
    }, 500)
  }

  const stripId = (id) => {
    let segments = id.split(".")
    let uuid = segments[segments.length - 1]
    return uuid
  }

  const findPfArmAndSave = (data, registry, rawArmId) => {
    let pfTable = data?.results?.participant_flow?.participant_flow_table

    if (direction === "right") {
      if (registry === "ctg" && pfTable?.["global_arms"]) {
        let global_arms = [...pfTable.global_arms];
        let saved = global_arms[colIndex + 1];
        global_arms[colIndex + 1] = global_arms[colIndex];
        global_arms[colIndex] = saved;

        let ctgPeriods = [...pfTable.ctg_periods];
        ctgPeriods.forEach(period => {
          let milestones = [...period.milestone_list];
          milestones.forEach(milestone => {
            let participants = [...milestone.participants];
            let saved = participants[colIndex + 1];
            participants[colIndex + 1] = participants[colIndex];
            participants[colIndex] = saved;

            milestone.participants = participants;
          })

          let withdrawnList = [...period.drop_withdrawn_reason_list];
          withdrawnList.forEach(withdrawn => {
            let participants = [...withdrawn.participants];
            let saved = participants[colIndex + 1];
            participants[colIndex + 1] = participants[colIndex];
            participants[colIndex] = saved;

            withdrawn.participants = participants;
          })

          period.drop_withdrawn_reason_list = withdrawnList;
          period.milestone_list = milestones;
        })

        data.results.participant_flow.participant_flow_table.ctg_periods = ctgPeriods;
        data.results.participant_flow.participant_flow_table.global_arms = global_arms;

      } else if (registry === "eudract" && pfTable?.["eudract_periods"]) {
        pfTable["eudract_periods"].forEach((period, periodIndex) => {
          if (period.arms && period.arms[colIndex].id.includes(rawArmId)) {
            let periodArms = [...period.arms];
            let saved = periodArms[colIndex + 1];
            periodArms[colIndex + 1] = periodArms[colIndex];
            periodArms[colIndex] = saved;

            let euPeriods = [...pfTable.eudract_periods]
            let euPeriod = euPeriods[periodIndex];
            euPeriod.arms = [...periodArms];


            let milestones = [...euPeriod.milestone_list];
            milestones.forEach(milestone => {
              let participants = [...milestone.participants];
              let saved = participants[colIndex + 1];
              participants[colIndex + 1] = participants[colIndex];
              participants[colIndex] = saved;

              milestone.participants = participants;
            })

            let withdrawnList = [...euPeriod.drop_withdrawn_reason_list];
            withdrawnList.forEach(withdrawn => {
              let participants = [...withdrawn.participants];
              let saved = participants[colIndex + 1];
              participants[colIndex + 1] = participants[colIndex];
              participants[colIndex] = saved;

              withdrawn.participants = participants;
            })

            euPeriod.drop_withdrawn_reason_list = withdrawnList;
            euPeriod.milestone_list = milestones;

            euPeriods.splice(periodIndex, 1, euPeriod);
            pfTable.eudract_periods = euPeriods
          }
        })
      }
    } else if (direction === "left") {
      if (registry === "ctg" && pfTable?.["global_arms"]) {
        if (pfTable?.["global_arms"]?.[colIndex]?.id?.includes(rawArmId)) {
          let global_arms = [...pfTable.global_arms];
          let saved = global_arms[colIndex - 1];
          global_arms[colIndex - 1] = global_arms[colIndex];
          global_arms[colIndex] = saved;

          let ctgPeriods = [...pfTable.ctg_periods];
          ctgPeriods.forEach(period => {
            let milestones = [...period.milestone_list];
            milestones.forEach(milestone => {
              let participants = [...milestone.participants];
              let saved = participants[colIndex - 1];
              participants[colIndex - 1] = participants[colIndex];
              participants[colIndex] = saved;

              milestone.participants = participants;
            })

            let withdrawnList = [...period.drop_withdrawn_reason_list];
            withdrawnList.forEach(withdrawn => {
              let participants = [...withdrawn.participants];
              let saved = participants[colIndex - 1];
              participants[colIndex - 1] = participants[colIndex];
              participants[colIndex] = saved;

              withdrawn.participants = participants;
            })

            period.drop_withdrawn_reason_list = withdrawnList;
            period.milestone_list = milestones;
          })

          pfTable.ctg_periods = ctgPeriods;
          pfTable.global_arms = global_arms;
        }
      } else if (registry === "eudract" && pfTable?.["eudract_periods"]) {
        pfTable["eudract_periods"].forEach((period, periodIndex) => {
          if (period.arms && period.arms[colIndex].id.includes(rawArmId)) {
            let periodArms = [...period.arms];
            let saved = periodArms[colIndex - 1];
            periodArms[colIndex - 1] = periodArms[colIndex];
            periodArms[colIndex] = saved;

            let euPeriods = [...pfTable.eudract_periods]
            let euPeriod = euPeriods[periodIndex];
            euPeriod.arms = [...periodArms];


            let milestones = [...euPeriod.milestone_list];
            milestones.forEach(milestone => {
              let participants = [...milestone.participants];
              let saved = participants[colIndex - 1];
              participants[colIndex - 1] = participants[colIndex];
              participants[colIndex] = saved;

              milestone.participants = participants;
            })

            let withdrawnList = [...euPeriod.drop_withdrawn_reason_list];
            withdrawnList.forEach(withdrawn => {
              let participants = [...withdrawn.participants];
              let saved = participants[colIndex - 1];
              participants[colIndex - 1] = participants[colIndex];
              participants[colIndex] = saved;

              withdrawn.participants = participants;
            })

            euPeriod.drop_withdrawn_reason_list = withdrawnList;
            euPeriod.milestone_list = milestones;

            euPeriods.splice(periodIndex, 1, euPeriod);
            pfTable.eudract_periods = euPeriods
          }
        })
      }
    }
  }

  const findBcArmAndSave = (data, registry, rawArmId) => {
    let bcTable = data?.results?.baseline_characteristics?.baseline_characteristics_table
    if (bcTable?.[registry]?.arms?.[colIndex]?.id?.includes(rawArmId)) {
      if (direction === "right") {
        // move arms

        bcMoveRightHelper(bcTable, registry, "arms", colIndex)

        // move baseline measure values
        bcTable[registry]?.baseline_measures?.forEach(bm => {
          bm.rows.forEach(row => {
            row.categories.forEach(category => {
              let tempValues = [...category.values];
              let colIndex = category.values.findIndex(item => stripId(item.arm_id) === stripId(arm.id));

              if (colIndex !== -1 && colIndex !== category.values.length - 1) {
                let saved = tempValues[colIndex + 1];
                tempValues[colIndex + 1] = tempValues[colIndex];
                tempValues[colIndex] = saved;
                category.values = [...tempValues];
              }
            })
          })
        })
      } else if (direction === "left") {
        // move arms
        bcMoveLeftHelper(bcTable, registry, "arms", colIndex)

        // move baseline measure values
        bcTable[registry]?.baseline_measures?.forEach(bm => {
          bm.rows.forEach(row => {
            row.categories.forEach(category => {
              let tempValues = [...category.values];
              let colIndex = tempValues.findIndex(item => stripId(item.arm_id) === stripId(arm.id));

              if (colIndex !== -1 && colIndex !== 0) {
                let saved = tempValues[colIndex - 1];
                tempValues[colIndex - 1] = tempValues[colIndex];
                tempValues[colIndex] = saved;
                category.values = [...tempValues];
              }
            })
          })
        })
      }
    }
  }

  const findOmArmAndSave = (data, registry, rawArmId) => {
    let omTable = data?.results?.outcome_measures?.outcome_measures_table
    if (direction === "right") {
      omTable?.[registry].outcome_measures.forEach(om => {
        if (om?.arms && om?.arms?.[colIndex]?.id?.includes(rawArmId)) {
          omMoveRightHelper(om, "arms", colIndex);
          om.measure_rows.forEach(row => {
            row.categories.forEach(category => {
              let tempValues = [...category.values];

              if (colIndex !== -1 && colIndex !== category.values.length - 1) {
                let saved = tempValues[colIndex + 1];
                tempValues[colIndex + 1] = tempValues[colIndex];
                tempValues[colIndex] = saved;
                category.values = [...tempValues];
              }
            })
          })
        }
      })
    } else if (direction === "left" && omTable?.[registry]?.outcome_measures) {
      omTable?.[registry].outcome_measures.forEach(om => {
        if (om?.arms && om?.arms?.[colIndex]?.id?.includes(rawArmId)) {
          omMoveLeftHelper(om, "arms", colIndex);
          om.measure_rows.forEach(row => {
            row.categories.forEach(category => {
              let tempValues = [...category.values];
              if (colIndex !== -1 && colIndex !== 0) {
                let saved = tempValues[colIndex - 1];
                tempValues[colIndex - 1] = tempValues[colIndex];
                tempValues[colIndex] = saved;
                category.values = [...tempValues];
              }
            })
          })
        }
      })
    }
  }

  const findAeArmAndSave = (data, registry, rawArmId) => {
    let ae = data?.results?.adverse_events?.adverse_events_table
    if (ae?.[registry]?.groups[colIndex]?.id?.includes(rawArmId)) {
      if (direction === "right") {
        // move serious adverse events
        ae[registry].serious_adverse_events.forEach(event => {
          if (colIndex !== -1 && colIndex !== event.values.length - 1) aeMoveRightHelper(event.values, colIndex)
        })

        // move non serious adverse events
        ae[registry].non_serious_adverse_events.forEach(event => {
          if (colIndex !== -1 && colIndex !== event.values.length - 1) aeMoveRightHelper(event.values, colIndex)
        })

        // move arm
        if (colIndex !== -1 && colIndex !== ae[registry].groups.length - 1) aeMoveRightHelper(ae[registry].groups, colIndex)
      } else if (direction === "left") {
        ae[registry].serious_adverse_events.forEach(event => {
          if (colIndex !== -1 && colIndex !== 0) aeMoveLeftHelper(event.values, colIndex)
        })

        // move non serious adverse events
        ae[registry].non_serious_adverse_events.forEach(event => {
          if (colIndex !== -1 && colIndex !== 0) aeMoveLeftHelper(event.values, colIndex)
        })

        // move arm
        if (colIndex !== -1 && colIndex !== 0) aeMoveLeftHelper(ae[registry].groups, colIndex)
      }
    }
  }

  const onCheck = (key, value, registry) => {
    if ((key === "pf" || key === "bc" || key === "om") && registry === "eudract") {
      setApplicableSections({
        ...applicableSections,
        [registry]: {
          ...applicableSections[registry],
          pf: value,
          bc: value,
          om: value,
        }
      })
    } else {
      setApplicableSections({
        ...applicableSections,
        [registry]: {
          ...applicableSections[registry],
          [key]: value
        }
      })
    }
  }

  return (
    <Modal
      title={`Move arm/group: ${arm?.title}`}
      open={open}
      onCancel={onCancel}
      hideImage={true}
      destroyOnClose={true}
      width={500}
      onOk={onSave}
      confirmLoading={loading}
      okText={"Save changes"}>
      <div className="arm-override-modal-container">
        <div className="registries-container">
          {sources?.[source]?.map(registry => {
            return (
              <div
                key={registry.registry}
                className="registry-container">
                <p>{registry.label}</p>
                <div>
                  {registry.items.map(checkbox => {
                    return (
                      <PrimeField
                        schema={{
                          type: "checkbox",
                          label: checkbox.label
                        }}
                        key={registry.registry + checkbox.key}
                        disabled={checkbox.disabled}
                        value={applicableSections?.[registry.registry]?.[checkbox.key]}
                        onChange={e => onCheck(checkbox.key, e, registry.registry)} />
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Modal>
  );
};

export default ArmReorderModal;