import * as React from 'react'
import { Segmented } from 'antd'
import PropTypes from 'prop-types'
import './SegmentedTabs.scss'

const SegmentedTabs = ({
  className,
  ...props
}) => {
  return <Segmented
    size="large"
    className={`prime-segmented-tab${className ? ' ' + className : ''}`}
    {...props}
  />
}

SegmentedTabs.propTypes = {

}

export default SegmentedTabs