/**
 * TODO: disable "Save Current View...":
 * • if no preset has been loaded
 * • or if grid has not been modified
 * TODO: disable "Reset View" if preset hasn't been loaded
 */
import * as React from 'react'
import { Button } from 'components-design-system'
import PropTypes from 'prop-types'
import './PresetFooterActions.scss'

const PresetFooterActions = ({
  disableSave,
  onOpenModal,
  onResetPreset,
}) => {
  return <div className="presets-footer-actions">
    {onOpenModal &&
      <Button
        size="sm"
        disabled={disableSave}
        onClick={() => onOpenModal("add-preset")}>
        Save Current View as Preset
      </Button>
    }
    {onResetPreset &&
      <Button
        variant="link"
        // style={{ marginTop: 10 }}
        onClick={onResetPreset}>
        Reset View
      </Button>
    }
  </div>
}

PresetFooterActions.propTypes = {

}

export default PresetFooterActions