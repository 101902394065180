// TODO: move outside Sidebar folder, because it's also used by XogeniePage
import React, { useState, useMemo } from 'react'
import ActiveSponsorsModal from './ActiveSponsorsModal/ActiveSponsorsModal'
import ActiveSponsorTags from './ActiveSponsorTags/ActiveSponsorTags'

// apis
import { useGetSponsorTypes } from 'api/hooks/admin/useSponsorApi';
import { useGetGlobalSponsorFilter, usePostGlobalSponsorFilter } from 'api/hooks/global';

const useActiveSponsors = ({
  tagMaxWidth = 162,
} = {}) => {
  const { data: sponsors = [] } = useGetSponsorTypes()
  const { data: activeSponsors = [] } = useGetGlobalSponsorFilter()
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const postGlobalSponsorFilter = usePostGlobalSponsorFilter()

  const handleDeleteAndSave = (sponsor) => {
    let newSelectedSponsors = activeSponsors.filter((item) => sponsor !== item)
    postGlobalSponsorFilter({
      body: {
        sponsors: newSelectedSponsors
      }
    }, {
      onSuccess: () => {
        window.location.reload();
      }
    })
  }

  const handleDeleteAndSaveGroup = () => {
    let newSelectedSponsors = activeSponsors.slice(0, 3)
    postGlobalSponsorFilter({
      body: {
        sponsors: newSelectedSponsors
      }
    }, {
      onSuccess: () => {
        window.location.reload();
      }
    })
  }

  const sortedSponsors = useMemo(() => {
    return sponsors.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    })
      .map(sponsor => sponsor.name)
  }, [sponsors])

  const Tags = () => {
    return <ActiveSponsorTags
      tagMaxWidth={tagMaxWidth}
      sponsors={sortedSponsors}
      activeSponsors={activeSponsors}
      handleDeleteAndSave={handleDeleteAndSave}
      handleDeleteAndSaveGroup={handleDeleteAndSaveGroup}
      setModalIsOpen={setModalIsOpen}
      modalIsOpen={modalIsOpen}
    />
  }

  const openSponsorsModal = () => {
    setModalIsOpen(true)
  }

  const SponsorsModal = () => {
    return <ActiveSponsorsModal
      sponsors={sortedSponsors}
      activeSponsors={activeSponsors}
      showModal={modalIsOpen}
      onHide={() => setModalIsOpen(false)}
    />
  }

  return {
    Tags,
    openSponsorsModal,
    SponsorsModal,
  }
}

export default useActiveSponsors