import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs';

// components
import { NotificationsProvider } from 'context/hooks/useNotifications'
import NotificationsMenuItem from './icon-menu/NotificationsMenuItem/NotificationsMenuItem'
import Banner from '../Banner/Banner'
import SidePanel from '../SidePanel/SidePanel'
import FavoritesPanel from './FavoritesPanel/FavoritesPanel'
import AvatarPopover from './AvatarPopover/AvatarPopover'

// styles
import './Topbar.scss'

// api
import { useUserInfo } from 'api/hooks';
import {
  useGetFavoriteStudies,
  useGetPasswordExpiryDeadline,
  useGetFavoriteRequests,
} from 'api/hooks'

// utilities
import useAutoClose from '../../utilities/useAutoClose'
import useAuth from 'context/hooks/useAuth';
import useGlobalSearch from 'utilities/useGlobalSearch/useGlobalSearch';

const Topbar = () => {
  const { data: faveStudies } = useGetFavoriteStudies()
  const { data: faveRequests } = useGetFavoriteRequests()
  const { data: passwordExpiration, isLoading: isPasswordExpirationLoading } = useGetPasswordExpiryDeadline()
  const { data: user } = useUserInfo()
  const { logout } = useAuth()
  const { GlobalSearch } = useGlobalSearch()

  // password expiration - banner
  const [showBanner, setShowBanner] = useState(false);

  // panels
  const [favePanelOpen, setFavePanelOpen] = useState(false)
  const [notifPanelOpen, setNotifPanelOpen] = useState(false)

  // close the notification panel when open the favories panel
  useEffect(() => {
    if (favePanelOpen) setNotifPanelOpen(false)
  }, [favePanelOpen])

  // close the favories panel when open the notification panel
  useEffect(() => {
    if (notifPanelOpen) setFavePanelOpen(false)
  }, [notifPanelOpen])

  // scrollable sidebars

  const topbarRef = useRef(null)
  const [offsetHeight, setOffsetHeight] = useState(null)

  useEffect(() => {
    setOffsetHeight(topbarRef.current.offsetHeight)
  }, [])

  // click outside topbar and side panels to close the panels

  const closeSidePanels = () => {
    setNotifPanelOpen(false)
    setFavePanelOpen(false)
  }

  useAutoClose(topbarRef, closeSidePanels)

  useEffect(() => {
    if (passwordExpiration) {
      let showBannerDate = localStorage.getItem('xogene_prime_show_password_expiration_date');
      let getTodaysDate = dayjs().format("YYYY-MM-DD")
      if (passwordExpiration?.show_banner === true && getTodaysDate !== showBannerDate) {
        setShowBanner(true)
      }
    }
  }, [isPasswordExpirationLoading])

  // page banner
  const displayMessage = () => {
    let numOfDays = passwordExpiration?.password_expires_in_days;
    if (Number(numOfDays) < 0) numOfDays = 0
    return (
      <div className="banner-message">
        <i className="fal fa-exclamation-circle"></i>
        <p >{`Your password will expire in ${numOfDays} day(s). Do you want to `}</p>
        <Link to="/account-settings/#password">{`change it now?`}</Link>
      </div>
    )
  }

  const hideBanner = () => {
    setShowBanner(false)
    localStorage.setItem('xogene_prime_show_password_expiration_date', dayjs().format("YYYY-MM-DD"));
  }

  const getFaveCount = () => {
    let count = 0;
    if (faveStudies) count += faveStudies?.length;
    if (faveRequests) count += faveRequests?.length;
    return count
  }

  return <>
    {showBanner && (
      <Banner
        onHide={hideBanner}>
        {displayMessage()}
      </Banner>)
    }

    <div className="topbar-container"
      ref={topbarRef}
    >
      <div className="combobox-container">
        {GlobalSearch}
      </div>

      <div className="bar-tools">

        {/* TODO: review if using NotificationsProvider */}
        <NotificationsProvider>
          <NotificationsMenuItem
            notifPanelOpen={notifPanelOpen}
            setNotifPanelOpen={setNotifPanelOpen}
            fixedScrollAtHeight={offsetHeight}
          />
        </NotificationsProvider>

        {/* FAVORITES */}

        <div
          className={`icon-wrapper favorites${getFaveCount() > 0 ? ' has-badge' : ''}`}
        >
          <i className="fal fa-heart dropdown-toggle"
            role="button"
            aria-haspopup="true"
            aria-expanded={favePanelOpen}
            onClick={() => setFavePanelOpen((val) => !val)}
          />
        </div>
        <SidePanel
          isOpen={favePanelOpen}
          setIsOpen={setFavePanelOpen}
          className="top-bar-side-panel-container"
          title={"My favorites"}
          fixedPosition="right"
          fixedScrollAtHeight={offsetHeight}
        >
          <FavoritesPanel
            setIsOpen={setFavePanelOpen}
            isOpen={favePanelOpen} />
        </SidePanel>
        {/* FRESH WIDGET */}

        <div className="icon-wrapper help">
          <i
            onClick={() => window.FreshWidget.show()}
            className="fal fa-question-circle"
          />
        </div>

        {/* ACCOUNT SETTINGS */}

        <div className="accounts-menu">
          <AvatarPopover
            user={user}
            logout={logout}
          >
            <div
              className="accounts-button"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
            >
              <div className="avatar">{user?.initials}</div><i className="far fa-angle-down" />
            </div>
          </AvatarPopover>
        </div>

      </div>
    </div>
  </>
}

export default Topbar
