// TODO: rename StudyLocationsDetails
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom';

// Components
import HeaderActions from 'components/HeaderActions/HeaderActions';
import PRSValidationBadge from 'components/badges/PRSValidationBadge/PRSValidationBadge';
import PRSValidationMessages from 'components/PRSValidationMessages/PRSValidationMessages';
import SiteStatusBadge from 'components/badges/SiteStatusBadge/SiteStatusBadge';
import Modal from 'components/Modal/Modal';
import './StudyDetailsHeader.scss'

// helpers
import { intersperse } from 'utilities/misc';

// apis
import { useUserPermissions } from 'api/hooks';
import {
  useGetPrsSitesUpload,
  usePostPrsSiteUpload,
} from 'api/hooks/siteManagement/useSiteManagementApi';
import { useGetStudy } from 'api/hooks/studies/useStudiesApi';


const StudyDetailsHeader = ({
  disabledUpload,
  onRegenerate,
  setTriggerExport,
  studyInfo,
  nonSiteErrors,
  siteManagementStudy,
  getValidationsAfterUpload
}) => {
  const { studyId, siteManagementId } = useParams()
  const [badges, setBadges] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const { data: userPermissions } = useUserPermissions()
  const { isLoading: isStudyLoading, data: study } = useGetStudy(studyId)
  let studyData = study?.study_data

  useEffect(() => {
    let items = [];
    if (studyInfo) {
      const badgeStyle = {
        marginRight: 6
      }
      if (studyInfo.is_new) items.push(<SiteStatusBadge key={"header-new-badge"} style={badgeStyle} variant="new" tooltipPlacement="right" tooltipText="This study was imported from a source in the latest upload and was not present in the previous upload." />);
      if (studyInfo.only_on_ctg) items.push(<SiteStatusBadge key={"header-no-source"} style={badgeStyle} variant="no-source" tooltipPlacement="right" tooltipText="This study was not imported from a source and cannot be submitted to PRS. Please review the source data." />);
      if (studyInfo.only_on_source) items.push(<SiteStatusBadge key={"header-no-ctg"} style={badgeStyle} diagnolStyle={{ bottom: 11, width: 45, right: -2 }} variant="no-ctg" tooltipPlacement="right" tooltipText="This study does not exist in CTG and will not be able to be uploaded." />);
      setBadges(items);
    }
  }, [studyInfo])

  const displayNonSiteErrors = () => {
    let errors = [];
    let warnings = [];
    let notes = [];
    if (nonSiteErrors?.length > 0) {

      nonSiteErrors.forEach(item => {
        if (item.severity === "ERROR" || item.type === "ERROR") errors.push(item)
        if (item.severity === "WARNING" || item.type === "WARNING") warnings.push(item);
        if (item.severity === "NOTE" || item.type === "NOTE") notes.push(item);
      })

      let items = [];

      if (errors.length > 0) {
        items.push(<PRSValidationBadge key={"sl-non-sites"} style={{ marginLeft: 4 }} type={"non-site"} num={errors.length} />)
      }
      if (warnings.length > 0) {
        items.push(<PRSValidationBadge key={"sl-warnings"} style={{ marginLeft: 4 }} type={"warning"} num={warnings.length} />)
      }
      if (notes.length > 0) {
        items.push(<PRSValidationBadge key={"sl-notes"} style={{ marginLeft: 4 }} type={"note"} num={notes.length} />)
      }

      return (
        <span style={{ display: "flex", cursor: "pointer" }} onClick={() => setOpenModal(true)}>
          {intersperse(items, ", ")}
        </span>
      )
    }
  }

  if (!study) {
    return null
  }

  return (
    <div className="study-locations-details">
      <div className="study-loc-header">
        <div className="study-loc-title-wrap">
          <p className="study-loc-title">Sites</p>
          <Link to="/site-management">Site Management</Link>
        </div>
        <HeaderActions
          prsUploadStudyList={[Number(siteManagementId)]}
          useOnGetPrsUpload={useGetPrsSitesUpload}
          useOnPostPrsUpload={usePostPrsSiteUpload}
          uploadToPrsMsg={"Send all site updates to PRS. If you would like to release data directly to CTG check the checkbox below."}
          showExport={true}
          showValidate={true}
          setTriggerExport={setTriggerExport}
          canUploadToPRS={userPermissions?.["study.site.upload"]}
          studyData={{ ...studyData, authoringId: study.authoring_study_id }}
          onRegenerate={onRegenerate}
          isUploadDisabled={disabledUpload}
          metadata={{}}
          source={"site-management-study"}
          lastImportDate={siteManagementStudy?.ctg_download_date}
          callbackAfterUpload={getValidationsAfterUpload} />
      </div>
      <div className="study-loc-badges">
        {badges}
      </div>
      <div
        className="justify-content-md-between"
        style={{ marginTop: 17 }}
      >
        <div className="study-loc-page-desc">{`All sites for study ${studyData.study_id}. Red text with strike-through indicates data on CTG or CTMS that is inconsistent.`}</div>
      </div>
      {nonSiteErrors?.length > 0 && <div style={{ display: "flex" }}>
        <span className="study-loc-page-desc">Non-Site PRS Validation messages: </span>{displayNonSiteErrors()}
      </div>}
      <Modal
        title={"Non-Site PRS Validation Messages"}
        onCancel={() => setOpenModal(false)}
        open={openModal}
        hideImage={true}
        footer={[]}
        width={720}>
        <PRSValidationMessages
          gridProps={{ data: null }}
          data={studyData}
          messages={nonSiteErrors}
          onOpenModal={() => setOpenModal(true)}
          setOpenModal={setOpenModal}
          origin={"locations"}
          hideSubmit={true}
          hideActions={true}
        />
      </Modal>
    </div>
  )
}

export default StudyDetailsHeader