import { useState, useEffect } from 'react';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { isEmpty, uniqBy } from 'lodash';
import { useShallow } from 'zustand/react/shallow'

// components
import RJOutcomeMeasures from './RJOutcomeMeasures';
import CellValidation from '../components/CellValidation/CellValidation';

// context
import useResultsTableArmsStore, { initOutcomeArmOptions, initAdverseArmOptions } from '../../../hooks/stores/useResultsTableArmsStore';
import useAuthoringViewStore from '../../../hooks/stores/useAuthoringViewStore';
import useAuthoringDataStore from '../../../hooks/stores/useAuthoringDataStore';
import useAuthoringVersionStore from '../../../hooks/stores/useAuthoringVersionStore';
import useAuthoringStore from '../../../hooks/stores/useAuthoringStore';
import useParticipantFlowStore from '../RJParticipantFlow/hooks/useParticipantFlowStore';

const OutcomeWrapper = (props) => {
  const {
    formContext,
    onChange
  } = props

  const formWidthClassName = useAuthoringStore(state => state.formWidthClassName)

  const {
    versionData,
    selectedVersion
  } = useAuthoringVersionStore(
    useShallow(state => ({
      versionData: state.versionData,
      selectedVersion: state.selectedVersion,
    }))
  )

  const {
    formStatus,
    readOnly,
    showVersionCompare
  } = useAuthoringViewStore(
    useShallow(state => ({ 
      formStatus: state.formStatus,
      readOnly: state.readOnly,
      showVersionCompare: state.showVersionCompare
    }))
  )

  const {
    baselineSas,
    baselinePeriod,
    setOutcomeArmOptions,
    setAdverseArmOptions
  } = useResultsTableArmsStore()

  const {
    participantData,
  } = useParticipantFlowStore(
    useShallow(state => ({
      participantData: state.participantData,
    }))
  )

  const initState = {
    ctg: {
      outcome_measures: []
    },
    eudract: {
      outcome_measures: []
    }
  }

  const [tableMounting, setTableMounting] = useState(true)
  const [outcomeData, setOutcomeData] = useStateWithCallbackLazy(initState)

  useEffect(() => {
    if (formStatus === "ready" && outcomeData && readOnly === false && tableMounting !== true) {
      onChange(outcomeData)
    }
  }, [outcomeData])

  useEffect(() => {
    if (formContext?.formData?.results?.outcome_measures?.outcome_measures_table) {
      if (isEmpty(formContext.formData.results.outcome_measures.outcome_measures_table)) {
        setOutcomeData(initState, () => {
          setTableMounting(false)
        })
      } else {
        setOutcomeData(formContext.formData.results.outcome_measures.outcome_measures_table, () => {
          setTableMounting(false)
        })
      }
    } else {
      setTableMounting(false)
    }

    return () => {
      setTableMounting(true)
    }
  }, [])

  useEffect(() => {
    if (participantData) {
      onSetOutcomeArmOptions()
    }
  }, [baselineSas, baselinePeriod])

  const onSetOutcomeArmOptions = () => {
    let items = [...initOutcomeArmOptions]

    if (participantData?.eudract_periods) {
      let periodArms = participantData.eudract_periods.map((period, i) => {
        return {
          id: period.id,
          text: `(EUD) ${period?.baseline_period === "Yes" ? "Baseline" : ""} Period ${i + 1} Arms: ${period.title}`
        }
      })
      items = [...items, ...periodArms]
      setAdverseArmOptions([...initAdverseArmOptions, ...periodArms])
    }

    if (baselineSas) {
      let sets = baselineSas.map(set => {
        return {
          id: set.id,
          text: `(EUD SAS) ${set.title ? set.title : '"Arm/Group Description"'} (${set.description ? set.description : '"Arm/Group Description'})`
        }
      })
      items = [...items, ...sets]
    }

    setOutcomeArmOptions(uniqBy(items, "id"))
  }

  if (tableMounting) return <></>

  return (
    <div className={["form-table-print", formWidthClassName].join(" ")} style={{ alignSelf: "flex-start" }}>
      <RJOutcomeMeasures
        {...props}
        outcomeData={outcomeData}
        setOutcomeData={setOutcomeData} />
      {versionData && selectedVersion && showVersionCompare && (
        <div className="rjs-table-comparison-wrapper">
          <RJOutcomeMeasures
            {...props}
            outcomeData={versionData?.study_data?.results?.outcome_measures?.outcome_measures_table}
            setOutcomeData={setOutcomeData} />
        </div>
      )}
      <CellValidation
        table="outcome"
        errorKey="results_outcome_measures" />
      <CellValidation
        table="outcome"
        errorKey="results_outcome_measures_outcome_measures_table" />
    </div>
  );
};

export default OutcomeWrapper;