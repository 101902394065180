const ObjectFieldTemplate = (props) => {
  const { properties, uiSchema, idSchema } = props;

  const options = uiSchema["ui:options"];
  let title = ""
  let schemaClassNames = {}
  const jpath = idSchema.$id

  if (options && options) {
    schemaClassNames = options?.classNames
    title = options.title
  }

  let objectContainerClassNames = [`rjs-object-container${options?.hide ? ' hidden' : ''}`]
  if (schemaClassNames?.objectContainer) objectContainerClassNames.push(schemaClassNames.objectContainer)

  return (
    <div
      id={jpath + "-container"}
      className="rjs-object-container">
      <div
        className="rjs-object-title-wrapper">
        <div
          className={`rjs-object-title-container ${schemaClassNames?.objectTitleContainer ? schemaClassNames.objectTitleContainer : ""}`}>
          <div className={`rjs-object-title ${schemaClassNames?.objectTitle ? schemaClassNames.objectTitle : ""}`}>
            {title}
          </div>
        </div>
      </div>
      <div className={`rjs-object-inner-container ${schemaClassNames?.objectInnerContainer ? schemaClassNames.objectInnerContainer : ""}`}>
        {properties.map((prop, i) => {
          const { uiSchema } = prop.content.props;
          const options = uiSchema["ui:options"];

          let fieldClassNames = ["rjs-field-container"];

          if (options?.classNames?.fieldContainer) {
            fieldClassNames.push(options.classNames.fieldContainer)
          }

          if (options?.hide) return null;

          return (
            <div
              className={fieldClassNames.join(" ")}
              key={prop.name + "-" + "i"}
              id={prop.content.props.idSchema["$id"] + "-field-container"}>
              {prop.content}
            </div>
          )
        })}
      </div>
    </div>
  );
};

export default ObjectFieldTemplate;