import { useState, useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs';

// components
import Panel from 'containers/requests/components/Panel/Panel';
import GridTypeA from 'components/grids/GridTypeA/GridTypeA';
import IconButton from 'containers/requests/components/IconButton/IconButton';
import Modal from 'components/Modal/Modal';
import UserBadge from 'components/badges/UserBadge/UserBadge';
import { Button } from 'components-design-system';

import TitleSubtitle from 'components/ag-grid-cell-renderers/TitleSubtitle/TitleSubtitle';
import ActionItemStatus from 'components/ag-grid-cell-renderers/ActionItemStatus/ActionItemStatus';
import ActionItemActions from 'components/ag-grid-cell-renderers/ActionItemActions/ActionItemActions';
import ActionItemPriority from 'components/ag-grid-cell-renderers/ActionItemPriority/ActionItemPriority';

// apis
import { useUserSettings } from 'api/hooks';
import { useDeleteActionItem, useSendReminder } from 'api/hooks/actionItems/useActionItemsApi';
import { useUserPermissions } from 'api/hooks';
import queryKeys from 'api/utils/queryKeys';

// assets
import empty_action_item_icon from 'assets/images/empty_action_items.png';

// styles
import './ActionItemsGrid.scss'

const ActionItemsGrid = ({
  type,
  onOpenPanel,
  actionItems = [],
  refetchActionItems = () => {},
  selectedActionItem,
  setSelectedActionItem,
  noWrap
}) => {
  const { studyId, activityId, requestId } = useParams()
  const queryClient = useQueryClient()
  const sendReminder = useSendReminder()
  const { data: userSettings } = useUserSettings()
  const { data: permissions } = useUserPermissions()
  const [gridApi, setGridApi] = useState(null)
  const [openDeleteActionItemModal, setOpenDeleteActionItemModal] = useState(false)
  const deleteActionItem = useDeleteActionItem(selectedActionItem?.id)
  const [activeFilter, setActiveFilter] = useState("all")

  const [openSendReminder, setOpenSendReminder] = useState(false)
  const [itemForReminder, setItemForReminder] = useState(null)
  const [disableReminderSet, setDisabledReminderSet] = useState(new Set)

  const onToggleSendReminderModal = (action) => {
    setOpenSendReminder(action ? true : false)
    setItemForReminder(action)
  }

  const columnDefs = [
    {
      headerName: "",
      field: "priority",
      minWidth: 40,
      maxWidth: 40,
      cellRenderer: ActionItemPriority,
    },
    {
      headerName: "Name/Task type",
      field: "title",
      // width: 250,   
      flex: 1,
      cellRenderer: TitleSubtitle,
      cellRendererParams: {
        title: "title",
        subtitle: "task_type_state"
      }
    },
    {
      headerName: 'Status',
      field: 'status',
      minWidth: 110,
      maxWidth: 120,
      cellRenderer: ActionItemStatus,
      valueGetter: params => {
        let { data } = params
        let value = data.status
        if (data.is_cancelled) value = "Cancelled"
        else if (data.is_complete) value = "Completed";
        return `${value}`;
      }
    },
    {
      headerName: "Due by",
      field: "due_date",
      type: "date",
      cellRenderer: ({ value }) => {
        if (value) return dayjs(value).format(userSettings?.date_format)
      },
      minWidth: 110,
      maxWidth: 120,
      flex: 1,
    },
    {
      headerName: "Assignees",
      field: "assigned_to_users",
      width: 130,
      maxWidth: 150,
      cellRenderer: params => {
        return <div style={{ display: "flex", flexWrap: "wrap", gap: 4 }}>
          {params.data.assigned_to_users.map(user => <UserBadge key={user.id} user={user.full_name} />)}
        </div>
      },
      flex: 1,
    },
    {
      headerName: "Forms",
      field: "forms",
      maxWidth: 85,
      cellRenderer: "ActionItemForms",
      valueGetter: params => {
        let { forms } = params.data
        let value = [];
        forms?.forEach(item => {
          value.push(`${item.form_view} ${item.form_section}`)
        })
        return value.join(", ")
      },
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        let countA = 0;
        let countB = 0;
        if (valueA) countA = valueA.split(", ").length;
        if (valueB) countB = valueB.split(", ").length;
        if (countA > countB) return 1;
        else if (countA < countB) return -1
        else return 0
      }
    },
    {
      headerName: type === "request" ? "Files" : "Documents",
      field: "docs",
      maxWidth: 120,
      cellRenderer: "ActionItemDocs",
      valueGetter: params => {
        let { docs } = params.data
        let value = [];
        docs?.forEach(item => {
          if (item?.document_name) value.push(item.document_name)
        })
        return value.join(", ")
      },
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        let countA = 0;
        let countB = 0;
        if (valueA) countA = valueA.split(", ").length;
        if (valueB) countB = valueB.split(", ").length;
        if (countA > countB) return 1;
        else if (countA < countB) return -1
        else return 0
      }
    },
    {
      headerName: 'Study',
      field: 'study_id',
      minWidth: 140,
      cellClass: 'action-pill-class',
      cellRenderer: params => {
        if(params.data.study_uniqid) return <div className='study-pill'><Link className='study-pill-text' target="_blank" to={`/study/${params.data.study_uniqid}`}>{params.value}</Link></div>
      },
      suppressSizeToFit: false
    },
    {
      headerName: "Activity",
      field: "activity_title",
      filter: 'agSetColumnFilter',
      cellRenderer: (props) => {
        let title = props.data.activity_title
        let uniqid = props.data.activity_uniqid
        return <div>
          {uniqid
            ? <Link target="_blank" to={`/study/${props.data.study_uniqid}/activity/${uniqid}`}>{title}</Link>
            : title
          }
        </div>
      },
      suppressSizeToFit: false,
      cellRendererParams: {
        title: "activity_title",
        subtitle: "activity_uniqid"
      },
      cellStyle: { 'textOverflow': 'ellipsis', 'whiteSpace': 'nowrap', 'overflow': 'hidden', 'fontSize': '13px' }
    },
    {
      headerName: '',
      field: 'actions',
      cellRenderer: ActionItemActions,
      cellRendererParams: {
        onOpenPanel,
        setOpenDeleteActionItemModal,
        onToggleSendReminderModal,
        permissions,
        setSelectedActionItem,
        type,
        disableReminderSet,
        itemForReminder
      },
      resizable: false,
      maxWidth: 80,
    }
  ]

  const gridOptions = {
    defaultColDef: {
      resizable: true,
      sortable: true,
      wrapText: true,
      filter: true,
      enablePivot: true,
      enableCharts: false,
      enableRowGroup: false,
      filterParams: { clearButton: true },
      cellStyle: { "whiteSpace": "normal !important" },
      autoHeight: true,
      cellClassRules: {
        oddBackground: function (params) {
          return params.rowIndex % 2 == 0
        },
      },
    },
    suppressHorizontalScroll: true,
    columnTypes: {
      date: {
        filter: 'agDateColumnFilter',
        filterParams: {
          resetButton: true,
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            let cellDate = null;
            let dateAsString = "";
            dateAsString = cellValue;
            if (dateAsString === null) {
              return 0;
            }
            cellDate = dayjs(dateAsString).toDate();
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          }
        }
      }
    },
  }

  const renderEmptyGrid = (
    <div className="empty-action-items-container">
      <img
        className="empty_action_item_icon"
        src={empty_action_item_icon}
        alt="empty action items" />
      <p className="empty-action-items-label">
        You don't have any action items
      </p>
    </div>
  )

  const onGridReady = (params) => {
    setGridApi(params.api)
    params.api.sizeColumnsToFit()
    params.columnApi.setColumnVisible('study_id', false);
    params.columnApi.setColumnVisible('activity_title', false);
    if((!permissions?.["actionitem.reminder"] && !permissions["actionitem.edit"]) ||
    (!permissions?.["actionitem.edit"] && actionItems.every(ai => ai.allow_send_reminder === false))) {
      params.columnApi.setColumnVisible('actions', false);
    }
    if (type === "request") {
      params.columnApi.setColumnVisible('forms', false);
    } else if (type === "my-prime") {
      params.columnApi.setColumnsVisible(['forms', 'docs'], false);
      params.columnApi.setColumnsVisible(['study_id', 'activity_title'], true);
    }
  }

  const onDeleteActionItem = () => {
    deleteActionItem({}, {
      onSuccess: () => {
        invalidateActionItems()
        setOpenDeleteActionItemModal(false)
      }
    })
  }

  const invalidateActionItems = () => {
    if (type === "activity") {
      queryClient.invalidateQueries(queryKeys.actionItems.activity(Number(studyId), Number(activityId)))
    } else if (type === "request") {
      queryClient.invalidateQueries(queryKeys.requests.actionItems(Number(requestId)))
    }
  }

  const onSendReminder = () => {
    sendReminder({ id: `${itemForReminder.id}/send-reminder/` }, {
      onSuccess: (resp) => {
        onToggleSendReminderModal(null)
        disableReminderSet.add(itemForReminder.id)
        setDisabledReminderSet(disableReminderSet)
        refetchActionItems()
      }
    })
  }

  const actions = useMemo(() => {
    let buttons = []
    if (type === "activity") {
      buttons.push(
        <div key={"ai-filters"} className="filter-container">
          <Button
            tooltip={"All"}
            className={activeFilter === "all" ? "active" : null}
            onClick={() => setActiveFilter("all")}
            variant="icon">
            <i className="fas fa-layer-group" />
          </Button>
          <Button
            tooltip={"Forms"}
            className={activeFilter === "forms" ? "active" : null}
            onClick={() => setActiveFilter("forms")}
            variant="icon">
            <i className="fal fa-table"></i>
          </Button>
          <Button
            tooltip={"Documents"}
            className={activeFilter === "documents" ? "active" : null}
            onClick={() => setActiveFilter("documents")}
            variant="icon">
            <i className="fas fa-file-alt"></i>
          </Button>
        </div>
      )
    }

    if (permissions?.["actionitem.create"]) {
      buttons.push(
        <IconButton
          key={"add-ai-btn"}
          variant="primary-dashed"
          iconClassName="far fa-plus-circle"
          onClick={onOpenPanel}
        >
          Add action item
        </IconButton>
      )
    }

    return buttons
  }, [type])

  return (
    <Panel
      title="ACTION ITEMS"
      iconClassName="fas fa-list"
      actions={actions}
      className="action-items-panel"
      noWrap={noWrap}
    >
      <div className="action-items-grid">
        {actionItems.length > 0 ? (
          <GridTypeA
            className="grid-type-b"
            onGridReady={onGridReady}
            columnDefs={columnDefs}
            gridOptions={gridOptions}
            rowData={actionItems.filter(item => {
              if (activeFilter === "all") return true;
              else if (activeFilter === "forms") return item?.forms?.length && item?.forms?.length > 0
              else if (activeFilter === "documents") return item?.docs?.length && item?.docs?.length > 0
              return true
            })}
            paginationPageSize={5}
            pagination={true}
            domLayout='autoHeight'
            colWidth='sizeToFit' />
        ) : (
          renderEmptyGrid
        )}
      </div>
      <Modal
        title={`Delete "${selectedActionItem?.title}" action item`}
        message={"This action can't be undone."}
        type="delete"
        onOk={onDeleteActionItem}
        onCancel={() => setOpenDeleteActionItemModal(false)}
        open={openDeleteActionItemModal}
      />
      <Modal
        title={`Send an E-mail reminder to ${itemForReminder?.assigned_to_users?.map(user => user.full_name).join(", ")}?`}
        message={`Action Item: ${itemForReminder?.title}`}
        type="bell"
        onOk={onSendReminder}
        okText={"Send"}
        onCancel={() => onToggleSendReminderModal(null)}
        open={openSendReminder}
      />
    </Panel>
  );
};

export default ActionItemsGrid;