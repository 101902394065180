import { useState, useEffect } from "react";

// components
import Note from 'components/Note/Note';
import PrimeField from "components/PrimeField/PrimeField";

// hoc
import InputWrapper from '../../hoc/InputWrapper';

// styles
import './RJCheckboxGroup.scss'

const RJCheckboxGroup = (props) => {
  let {
    id,
    carbonProps,
    options,
    value,
    onChange,
    invalid,
    invalidText
  } = props;
  const [items, setItems] = useState([]);
  const [isNone, setNone] = useState(false);

  useEffect(() => {
    if (value?.length === 1 && value[0] === "None") {
      setNone(true);
    }
    if (options && options.enumOptions) {
      let newItems = options.enumOptions.map((option) => {
        let isChecked = false;
        if (value && value.length > 0 && value.some) {
          isChecked = value?.some(item => option.value === item);
        }
        return { id: option.value, text: option.value, isChecked };
      });
      setItems(newItems);
    }
  }, [value]);

  const handleChange = (e, value) => {
    let temp = [...props.value];

    if (isNone === true) {
      setNone(false)
      let foundItem = temp.find((item) => item === value);
      if (!foundItem) {
        let selectedItems = [];
        let newItems = items.map((option) => {
          // Update Controlled state items
          let newOption = { ...option };
          if (option.text === value) newOption.isChecked = true;
          if (newOption.isChecked === true) selectedItems.push(option.text);
          return newOption;
        });
        setItems(newItems);
        console.log('Checkbox useEffect >> onChange')
        onChange(selectedItems);
      }

    } else if (e && isNone === false) {
      let foundItem = temp.find((item) => item === value);
      if (!foundItem) {
        let selectedItems = [];
        let newItems = items.map((option) => {
          // Update Controlled state items
          let newOption = { ...option };
          if (option.text === value) newOption.isChecked = true;
          if (newOption.isChecked === true) selectedItems.push(option.text);
          return newOption;
        });
        setItems(newItems);
        console.log('Checkbox useEffect >> onChange')
        onChange(selectedItems);
      }
    } else {
      let foundIndex = temp.findIndex((item) => item === value);
      if (foundIndex !== -1) {
        let selectedItems = [];
        let newItems = items.map((option) => {
          // Update Controlled state items
          let newOption = { ...option };
          if (option.text === value) newOption.isChecked = false;
          if (newOption.isChecked === true) selectedItems.push(option.text);
          return newOption;
        });
        setItems(newItems);
        console.log('Checkbox useEffect >> onChange')
        onChange(selectedItems);
      }
    }
  };

  const handleNone = (e) => {
    if (e) {
      let newItems = items.map((option) => {
        return { id: option.id, text: option.text, isChecked: false };
      });
      setItems(newItems);
      console.log('Checkbox useEffect >> onChange')
      onChange(["None"]);
      setNone(true);
    } else {
      setNone(false);
      onChange([]);
    }
  };

  let variant;

  if(invalid) {
    variant = "error"
  }

  return (
    <div id={id + "-field"} className="rjs-checkbox-group">
      <PrimeField
        schema={{
          type: "checkbox",
          id: props.id + "-checkbox-none",
          label: "None"
        }}
        value={isNone}
        onChange={handleNone} />
      <div className="rj-checkbox-group-divider" />
      {items.map((item, i) => {
        return (
          <PrimeField
            key={id + "-checkbox-" + item.id + "-" + i}
            schema={{
              type: "checkbox",
              id: + "-checkbox-" + item.id + "-" + i,
              label: item.text,
              disabled: carbonProps.disabled
            }}
            value={item.isChecked}
            onChange={(e) => handleChange(e, item.text)} />
        );
      })}
      {invalid && <Note type="error" text={invalidText} style={{ marginTop: 4 }} />}
    </div>
  );
};

export default InputWrapper(RJCheckboxGroup);