import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import registries from 'constants/registries'
import './RegistryBadge.scss'

const RegsitryBadge = ({
  value,
}) => {
  const [badgeValue, setBadgeValue] = useState(null)
  const [className, setClassName] = useState(null)

  useEffect(() => {
    setBadgeValue(() => {
      return registries.reduce((acc, regObj) => {
        if (regObj.registry === value) acc = regObj.badge;
        return acc
      }, value)
    })
    setClassName(() => {
      return registries.reduce((acc, regObj) => {
        if (regObj.registry === value) acc = regObj.region;
        return acc
      }, 'not-found')
    })
  }, [value])

  return <div className={`registry-badge ${className ? ' ' + className : ''}`}>
    {badgeValue}
  </div>
}

RegsitryBadge.propTypes = {
  value: PropTypes.string,
}

export default RegsitryBadge