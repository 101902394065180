import React from 'react'
import dayjs from 'dayjs'

// components
import { DatePicker as AntDatePicker } from 'antd'
import ReadOnlyField from '../ReadOnlyField/ReadOnlyField'

// styles
import './DatePicker.scss'

// apis
import { useUserSettings } from 'api/hooks'

const DatePicker = ({
  className,
  defaultValue = undefined,
  value = undefined,
  onChange, // parent function to call
  readOnly,
  placeholder,
  format = 'YYYY-MM-DD',
  ...moreProps // size, onChange
}) => {
  const { data: userSettings } = useUserSettings()
  const classNames = ['prime-date-picker']
  if (className) classNames.push(className);

  const handleChange = (evt) => {
    if (evt) {
      onChange(evt.format("YYYY-MM-DD"))
    } else {
      onChange(null)
    }
  }

  if (readOnly) {
    return (
      <ReadOnlyField
        placeholder={placeholder}
        className={classNames.join(' ')}
      >
        {dayjs(value).isValid() ? (dayjs(value).format(userSettings?.date_format) || dayjs(defaultValue).format(userSettings?.date_format)) : ""}
      </ReadOnlyField>
    )
  }

  return <AntDatePicker
    defaultValue={defaultValue && dayjs(defaultValue).isValid() ? dayjs(defaultValue) : undefined}
    placeholder={placeholder || userSettings?.date_format}
    onChange={handleChange}
    format={userSettings?.date_format}
    className={classNames.join(' ')}
    value={dayjs(value).isValid() && value ? dayjs(value) : undefined}
    {...moreProps}
  />
}

export default DatePicker