import { Fragment, useState } from 'react';
import { useShallow } from 'zustand/react/shallow'

// components
import GroupHeader from '../GroupHeader/GroupHeader';

// context
import useAuthoringDataStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringDataStore';
import useResultsTableArmsStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableArmsStore';
import useResultsTableViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableViewStore';

const ReportingGroupList = ({
  schema,
}) => {
  const {
    formData,
  } = useAuthoringDataStore(
    useShallow(state => ({
      formData: state.formData,
    }))
  )

  const {
    baselineSas
  } = useResultsTableArmsStore()

  const {
    tableView
  } = useResultsTableViewStore()

  const [showArms, setShowArms] = useState(true);
  const [showSas, setShowSas] = useState(true);

  const displayPeriodArms = () => {
    let groupSchema = schema.properties?.[tableView]?.properties?.arms.properties;

    let eudractPeriods = formData?.results?.participant_flow?.participant_flow_table?.eudract_periods || []

    return eudractPeriods.map((period, pI) => {
      return (
        <table
          key={`reporting-group-list-item-${period.id}`}
          style={{ width: 800, marginBottom: 16 }}>
          <tbody>
            <tr>
              <td
                className='text-left disabled-row text-bold'
                colSpan={2}>
                Period {pI + 1}: {period.title} {period?.baseline_period === "Yes" ? "(Baseline Period)" : ""}
              </td>
            </tr>
            {period?.arms?.map((arm, i) => {
              return (
                <Fragment key={"arms-" + arm?.id}>
                  <tr>
                    <td
                      className='header-column'
                      colSpan={2}>
                      {i + 1}. Arm
                    </td>
                  </tr>
                  {groupSchema && Object.keys(groupSchema).map(key => {
                    return (
                      <tr key={`${arm?.id}-${key}`}>
                        <td className='header-column'>
                          {groupSchema?.[key]?.title}
                        </td>
                        <td className='text-left'>
                          {arm?.[key]}
                        </td>
                      </tr>
                    )
                  })}
                </Fragment>
              )
            })}
          </tbody>
        </table>
      )
    })
  }

  const displaySubjectAnalysisSets = () => {
    let groupSchema = schema.properties?.[tableView]?.properties?.subject_analysis_sets;

    return (
      <table style={{ width: 800 }}>
        <tbody>
          {baselineSas.filter(set => set).map((set, i) => {

            return (
              <Fragment key={"sets-" + set?.id}>
                <tr>
                  <td
                    className='header-column subject-analysis-set'
                    colSpan={2}>
                    {i + 1}. Subject Analysis Set
                  </td>
                </tr>
                {groupSchema?.properties &&
                  Object.keys(groupSchema.properties).map(key => {
                    let fieldSchema = groupSchema.properties[key]

                    let value = set[key]
                    if (key === "subjects_analyzed") {
                      value = set["baseline_analysis_population"]
                    }
                    return (
                      <tr key={`${set?.id}-${key}`}>
                        <td className='header-column subject-analysis-set'>
                          {groupSchema.properties[key].title}
                        </td>
                        <td className='text-left'>
                          {value}
                        </td>
                      </tr>
                    )
                  })
                }
              </Fragment>
            )
          })}
        </tbody>
      </table>
    )
  }

  return (
    <div style={{ marginBottom: 36 }}>
      <div>
        <GroupHeader
          title={"Periods"}
          show={showArms}
          setShow={setShowArms} />
        {showArms && displayPeriodArms()}
      </div>

      <div style={{ marginTop: 16 }}>
        <GroupHeader
          title={"Subject Analysis Sets"}
          show={showSas}
          setShow={setShowSas} />
        {showSas && displaySubjectAnalysisSets()}
      </div>
    </div>
  );
};

export default ReportingGroupList;