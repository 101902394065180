import React from 'react';
import { useShallow } from 'zustand/react/shallow'

// context
import useAuthoringValidationsStore from '../../../../hooks/stores/useAuthoringValidationsStore';

// utils
import { styleValidationText } from 'utilities/registryValidations';

const CellValidation = ({
  table,
  errorKey,
  style = {}
}) => {
  const {
    resultsTableValidations,
  } = useAuthoringValidationsStore(
    useShallow(state => ({
      resultsTableValidations: state.resultsTableValidations,
    }))
  )

  if (resultsTableValidations?.[table]?.[errorKey]) {
    let validation = resultsTableValidations[table][errorKey] || []
    return (
      <div className="flex flex-col" style={{ marginTop: 6, gap: 4, whiteSpace: "normal", ...style }}>
        {validation.map(error => {
          return styleValidationText(error.type, error.text, error.text)
        })}
      </div>
    )
  }

  return <></>
};

export default CellValidation;