import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from 'components-design-system'

const ArrayTemplate = (props) => {
  const { uiSchema } = props;
  const [numOfItems, setNumOfItems] = useState(0);
  const options = uiSchema["ui:options"];
  const title = options && options.title;
  const itemTitle = options && options.itemTitle;
  const addButtonTitle = options.addButtonTitle;
  let styles = options && options.styles;
  
  useEffect(() => {
    if(props.items) {
      setNumOfItems(props.items.length)
    }
  }, [props.items])

  return (
    <Container styles={styles && styles.arrContainer}>
      <Title style={styles && styles.arrTitle}>{title} ({numOfItems})</Title>
      <ArrayContainer >
        {props.items.map(element => {
          return (
            <ArrayItemContainer key={element.key} style={styles && styles.arrInnerContainer}>
              <ItemTitle style={styles && styles.itemTitle}>{itemTitle}</ItemTitle>
              {element.hasRemove && (
                <TrashIcon
                  style={styles && styles.deleteButton}
                  className="fal fa-trash-alt"
                  onClick={element.onDropIndexClick(element.index)}/>
              )}
              {element.children}
            </ArrayItemContainer>
          )
        })}
      </ArrayContainer>
      {props.canAdd && (
        <Button 
          variant="outlined"
          style={styles && styles.addButton}
          size="sm"
          onClick={props.onAddClick}>
          {addButtonTitle}
          <i style={{ marginLeft: 16 }} className="fal fa-plus"/>
        </Button>
      )}
    </Container>
  );
};

export default ArrayTemplate;

const Container = styled.div`

`

const Title = styled.div`
`

const ItemTitle = styled.div`
`

const ArrayContainer = styled.div`
`

const ArrayItemContainer = styled.div`
`

const TrashIcon = styled.i`
`