import React from 'react';
import { create } from 'zustand'

const initialState = {
  authoringId: null,
  isUserLocked: false,
  lockedFullname: false,
  autoSaveFreeze: false,
  enableAutoSave: false,
  showOverrideModal: false,
  overrideCallback: null,
  showSaveLock: false,
  formWidthClassName: "form-close-sidebar-open-panel-inner",
  lastSaved: false,
  isProtocolOmDisabled: false,
  isProtocolOmDisabledOverriden: false,
}

const useAuthoringStore = create((set) => ({
  ...initialState,
  setAuthoringId: (authoringId) => set({ authoringId }),
  setIsUserLocked: (isUserLocked) => set({ isUserLocked }),
  setLockedFullName: (lockedFullname) => set({ lockedFullname }),
  setAutoSaveFreeze: (autoSaveFreeze) => set({ autoSaveFreeze }),
  setEnableAutoSave: (enableAutoSave) => set({ enableAutoSave }),
  setShowOverrideModal: (showOverrideModal) => set({ showOverrideModal }),
  setOverrideCallback: (overrideCallback) => set({ overrideCallback }),
  setShowSaveLock: (showSaveLock) => set({ showSaveLock }),
  setFormWidthClassName: (formWidthClassName) => set({ formWidthClassName }),
  setLastSaved: (lastSaved) => set({ lastSaved }),
  setIsProtocolOmDisabled: (isProtocolOmDisabled) => set({ isProtocolOmDisabled }),
  setIsProtocolOmDisabledOverriden: (isProtocolOmDisabledOverriden) => set({ isProtocolOmDisabledOverriden }),
  resetAuthoringStore: () => set(initialState),
}))


export default useAuthoringStore;