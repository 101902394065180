import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ConfigProvider } from 'antd'
import ReactQueryProvider from 'react-query/ReactQueryProvider'
import { HelmetProvider } from 'react-helmet-async'
import App from "app/App"


// theme
import { primaryTheme } from 'styles/theme'

// context
import AppContextProviders from 'context/AppContextProviders'

// api
import useQueryClient from 'api/utils/useQueryClient'

const AppProviders = () => {
  const {
    queryClient,
    toastContextHolder,
  } = useQueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider theme={primaryTheme}>
        <ReactQueryProvider> {/* // TODO: pre-fetch user-permissions instead of having this context provider */}
          <AppContextProviders>
            <HelmetProvider>
              {toastContextHolder}
              <App />
            </HelmetProvider>
          </AppContextProviders>
        </ReactQueryProvider>
      </ConfigProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default AppProviders