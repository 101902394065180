import React from 'react';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types'
import './user-badge.scss';

const UserBadge = ({ 
  user,
  size = "sm",
 }) => {
  let initials = "";

  if(typeof user === "string") {
    let names = user.split(" ");
    if(names[0]) initials = names[0][0];
    if(names[1]) initials += names[1][0];
    initials = initials.toUpperCase();
  }

  return (
    <Tooltip title={user} placement="bottom">
      <div className={`user-badge-container no-text-highlight ${size}-size`}>
        {initials}
      </div>
    </Tooltip>
  );
};

UserBadge.propTypes = {
  user: PropTypes.string,
  size: PropTypes.oneOf(["sm", "md"]),
}

export default UserBadge;