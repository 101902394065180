import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useShallow } from 'zustand/react/shallow'

// components
import Modal from 'components/Modal/Modal';
import PrimeField from 'components/PrimeField/PrimeField';
import { Button } from 'components-design-system'

// hooks
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';

const modalProps = {
  create: {
    header: "Create Arm",
    description: "description create"
  },
  delete: {
    header: "Delete Arm",
    description: "description delete"
  }
}

const DeleteArmModal = (props) => {
  const {
    type
  } = props;
  const [deleteAll, setDeleteAll] = useState(false);
  let { showModal, setShowModal, handleDelete, view, arm, disableDeleteAll } = props;

  const { 
    formView,
  } = useAuthoringViewStore(
    useShallow(state => ({ 
      formView: state.formView,
    })),
  )

  const onSubmit = () => {
    if (formView.id === "global") handleDelete(arm, true);
    else handleDelete(arm, deleteAll)
    setDeleteAll(false);
    setShowModal(false)
  }

  let title = "arm"
  if(type === "arm") title = "arm"
  else if(type=== "intervention") title = "intervention"
  else title = "subject analysis set"

  return (
    <Modal
      title={`Delete ${title}`}
      open={showModal}
      onCancel={() => setShowModal(false)}
      footer={[]}
      type="delete-alt">
      {formView.id === "global"
        ? <ModalContent>
          <ContentContainer>
            NOTE: Deleting from Global view will delete items from all registries.
          </ContentContainer>
          <ActionContainer>
            <Button
              style={{ fontSize: 12 }}
              onClick={() => setShowModal(false)}
              variant="outlined">
              Cancel
            </Button>
            <Button
              style={{ fontSize: 12 }}
              onClick={() => onSubmit()}
              variant="danger">
              Delete
            </Button>
          </ActionContainer>
        </ModalContent>
        : <ModalContent>
          <ContentContainer>
            <PrimeField
              schema={{
                type: "checkbox",
                label: "Delete from all registries",
                disabled: disableDeleteAll
              }}
              onChange={e => setDeleteAll(e)}
              value={deleteAll} />
            <PrimeField
              schema={{
                type: "checkbox",
                label: "Delete from registry",
              }}
              value={true} />
          </ContentContainer>
          <ActionContainer>
            <Button
              onClick={() => setShowModal(false)}
              variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={() => onSubmit()}
              variant="danger">
              Delete
            </Button>
          </ActionContainer>
        </ModalContent>
      }
    </Modal>
  );
};

export default DeleteArmModal;

const Container = styled.div`

`

const ContentContainer = styled.div`
  margin-bottom: 32px;
`

const ModalContent = styled.div`

`

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 12px;
  p {
    font-size: 12px !important;
  }
`