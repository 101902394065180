import React from 'react';
import dayjs from 'dayjs';
import { useUserSettings } from 'api/hooks';

import './last-modified-by.scss';

const LastModifiedBy = (props) => {
  const { data: userSettings } = useUserSettings()
  let { data } = props;
  let date = "";
  if (data.last_modified) {
    date = dayjs(data.last_modified).format(userSettings?.date_format)
  }

  return (
    <div className="last-modified-container">
      <span>{date}</span><span>{data?.last_modified_by_fullname}</span>
    </div>
  );
};

export default LastModifiedBy;