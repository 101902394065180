// TODO: rename DocumentActionsCellRenderer
import React, { useState, useEffect } from 'react';
import './document-actions.scss';

// context
import useDocuments from 'context/hooks/useDocuments';
import useStudy from 'context/hooks/useStudy';
import useChecklists from 'context/hooks/useChecklists';

// utils
import downloadPdf from 'components/pdfs/utils/downloadPdf';

const DocumentActions = ({
  data,
  source,
  documents,
  checklist,
  checklistDetails,
  rowIndex,
}) => {
  const {
    onOpenPanel
  } = useStudy()

  const {
    setSelectedDocument
  } = useDocuments()

  const {
    setSelectedChecklist,
    setSelectedOption
  } = useChecklists()

  const [document, setDocument] = useState(null)

  useEffect(() => {
    if (source === "checklist") {
      let foundDocument = documents.find(doc => doc.name === data.document_name && doc.document_type_name === data.document_type_name)
      setDocument(foundDocument)
    }
  }, [documents])

  const openPanel = () => {
    if (source === "checklist") {
      let foundOption = checklistDetails?.options?.find(option => option.id === data.id)
      setSelectedChecklist(checklist)
      setSelectedOption(foundOption)
      setSelectedDocument(document)
      onOpenPanel("document-details")
    } else {
      setSelectedDocument(data)
      onOpenPanel("document-details")
    }
  }

  const renderActionButtons = () => {
    const isPdf = (file) => {
      return file?.document_file && file.document_file.toLowerCase().includes('.pdf')
    }

    let file = source === "checklist" ? document : data
    // show download icon for PDFs
    if (file?.document_file && isPdf(file)) {
      return <i className="fal fa-arrow-to-bottom" onClick={() => downloadPdf(file)} />
    }
    // show external link icon for other files and for links
    else {
      return <i className="fal fa-external-link" onClick={() => window.open(file?.document_file || file.document_link)} />
    }
  }

  return (
    <div className="document-actions-cell-renderer-container">
      <i
        id={`documents-info-${rowIndex}`}
        className="fal fa-info-circle"
        onClick={openPanel}
      />
      {renderActionButtons()}
    </div>
  )
}

export default DocumentActions