import * as React from 'react'
import { Tooltip } from 'antd'
import { Select } from 'components-design-system'
import Modal from 'components/Modal/Modal'

import {
  useBulkDeleteSiteManagementLocations,
  useBulkPutSiteManagementLocations
} from 'api/hooks/siteManagement/useSiteManagementApi'

import './BulkEditPanel.scss'

const BulkEditPanel = ({
  selectedRows,
  setSelectedRows,
  study,
  gridApi,
}) => {
  const [openDeleteRowsModal, setOpenDeleteRowsModal] = React.useState(false)

  const bulkPutSiteManagementLocations = useBulkPutSiteManagementLocations(study.site_mgmt_study_id)
  const bulkDeleteSiteManagementLocations = useBulkDeleteSiteManagementLocations(study.site_mgmt_study_id)

  const changeRecruitStatus = (statusVal) => {
    const status = statusVal || ""
    bulkPutSiteManagementLocations({
      body: selectedRows.map(site => ({
        site_id: site.id,
        recruitment_status: status
      }))
    }, {
      onSuccess: () => {
        setSelectedRows([])
      }
    })
  }

  const deleteSites = () => {
    bulkDeleteSiteManagementLocations({ body: { site_ids: selectedRows.map(site => site.id) } }, {
      onSuccess: () => {
        setOpenDeleteRowsModal(false)
        setSelectedRows([])
      }
    })
  }

  return <>
    <div className={`batch-edit-panel${selectedRows.length > 0 ? ' rows-selected' : ''}`}>
      <div className="batch-action-buttons">
        <div className="edit-buttons">
          <Tooltip
            title="Change recruitment status for selected sites"
          >
            <Select
              options={["Not yet recruiting", "Recruiting", "Enrolling by invitation", "Active, not recruiting", "Completed", "Suspended", "Terminated", "Withdrawn"].map((item) => {
                return { value: item, label: item }
              })}
              placeholder="Recruiting status"
              onChange={changeRecruitStatus}
              allowClear={false}
              className="recruit-status-select"
            />
          </Tooltip>
          <Tooltip
            title="Delete selected sites"
          >
            <div className="rows-delete-btn"
              onClick={() => setOpenDeleteRowsModal(true)}
            >
              <i className="fal fa-trash-alt" />Delete
            </div>
          </Tooltip>
        </div>
        {selectedRows?.length ?
          <div className="files-seleced-indicator">{selectedRows.length} files selected</div> :
          null}
      </div>
      <Tooltip title="Deselect sites and close panel">
        <i className="fal fa-times"
          onClick={() => gridApi.deselectAll()}
        />
      </Tooltip>
    </div>
    <Modal
      type="delete"
      title="Delete sites"
      message={<>Are you sure you would like to delete this site(s)? This cannot be undone. <br/><br/> {selectedRows.map((site) => site.location_data.source.facility.name).join(', ')}</>}
      open={openDeleteRowsModal}
      onOk={deleteSites}
      onCancel={() => setOpenDeleteRowsModal(false)}
    />
  </>
}

BulkEditPanel.propTypes = {

}

export default BulkEditPanel