import { isObject, isEmpty } from 'lodash';

/** version number functions */

/* Recursive function to create lookup table of jpaths and values for study data versions
 * @param {Object} node to traverse
 * @param {Array} jpathArray array of strings making up parts to the jpaths.
 *                Also used to traverse nodes.
 * @param {Object} result becomes the object with return values
 * @returns object { jpath1: value1, jpath2: value2 }
 */
const getVersionValues = (node, jpathArray = [], result = {}) => {
  for (const nodeKey in node) {
    if (isObject(node[nodeKey]) && !isEmpty(node[nodeKey])) {
      if(isArrayOfStrings(node[nodeKey])) {
        const _jpathArr = [...jpathArray];
        _jpathArr.push(nodeKey);
        result[`root_${_jpathArr.join("_")}`] = {
          value: node[nodeKey],
          jpathArray: _jpathArr,
        };
      } else if (!isEmpty(node[nodeKey])) { // keep traversing thru tree branches // including arrays
        const _jpathArr = [...jpathArray];
        _jpathArr.push(nodeKey);
        getVersionValues(node[nodeKey], _jpathArr, result);
      };
    } else {
      // end of branch, write to result
      if (!isEmpty(node[nodeKey])) {
        const _jpathArr = [...jpathArray];
        _jpathArr.push(nodeKey);
        result[`root_${_jpathArr.join("_")}`] = {
          value: node[nodeKey],
          jpathArray: _jpathArr,
        };
      }
    };
  }
  return result;
}

const isArrayOfStrings = (value) => {
  // Check if the value is an array
  if (Array.isArray(value)) {
    // Check if all elements in the array are strings
    return value.every(item => typeof item === 'string');
  }
  // If not an array, return false
  return false;
}


const formatVersNumber = (num) => {
  return Number(num).toFixed(1).toString();
}

const getNextMinorVersNumber = (versNumber) => {
  return formatVersNumber(versNumber + 0.1);
}

const getNextMajorVersNumber = (versNumber) => {
  return formatVersNumber(Math.trunc(versNumber + 1));
}

/**
 * Get array of possible registry keys
 * UI schemas define hidden fields in registry views.
 * Filtered by form section.
 * All possible badges needed for forked fields.
 */
const getRegistryKeys = ({
  formSection,
  formSchemas,
  hiddenFields,
  jpath,
}) => {

  let registries = null;
  let currentSectSchemas = null;

  // find schema
  for (const key in formSchemas) {
    if (key === formSection?.id) currentSectSchemas = formSchemas?.[key]?.properties;
  };

  // compare current jpath to hidden nodes
  // to exclude registries hidden from field
  for (const regKey in currentSectSchemas) {
    let excludeRegistryNode = false;
    if (jpath && hiddenFields?.[formSection.id]) {
      const currentSectHiddenFields = hiddenFields[formSection.id];
      if (currentSectHiddenFields) {
        for (const key in currentSectHiddenFields) {
          if (key === regKey) {
            excludeRegistryNode = currentSectHiddenFields[key].some((path) => {
              // if exact match
              if (jpath.startsWith(path)) {
                return true
              }
              // if match on path array node, eg, "..._items_...", with jpath array item, eg, "..._1_..."
              else if (
                path.includes('_items_')
              ) {
                const arrayPathParts = path.split('_items_')
                if (
                  jpath.startsWith(arrayPathParts[0]) &&
                  jpath.endsWith(arrayPathParts[1])
                )
                  return true
              }
              // if no match
              else {
                return false
              }
            })
          }
        }
      }
    }

    if (regKey !== "global" && !excludeRegistryNode) {
      registries = registries || [];
      registries.push(regKey);
    }
  };
  return registries;
}

// recursive function to get hidden schema nodes
const getHiddenNodes = (nodes, path = "", hidden = []) => {
  for (const nodeKey in nodes) {
    if (isObject(nodes[nodeKey]) && nodes.hasOwnProperty(nodeKey) && nodeKey !== "styles") {
      if (nodes[nodeKey].hasOwnProperty('hide') && nodes[nodeKey].hide) {
        hidden.push(`${path}`);
      }
      getHiddenNodes(nodes[nodeKey], `${path}_${nodeKey}`, hidden);
    }
  }
  return hidden;
};

/** UTILITY FUNCTIONS for forked fields */

/**
 * Recursive function that returns array of property names that define the path of a node in JSON and UI schemas
 * @param {String} path - jpath or part of jpath (w/out "root_" prefix)
 * @param {Object/*} node - parent node to be recursed
 * @param {Array} result - array of keys to pass to next recursive step
 * @returns - result array after locating path in parent node, 
 *            eg, ["results", "more_info", "is_pis_employees"]
 *            last item is field name
 */
const getJpathArray = (path, node, result = []) => {
  if (
    node &&
    !isEmpty(node)
  ) {
    let _result = [];
    if (result?.length > 0) _result = [...result];
    for (const key in node) {
      if (path.startsWith(key)) {
        let _node = node[key]; // data node
        if (node[key]?.properties) _node = node[key].properties; // schema node
        if (typeof _node === 'object') {
          const _path = path.replace(`${key}_`, "");
          _result.push(key);
          return getJpathArray(_path, _node, _result);
        } else {
          result.push(key);
        }
      }
    }
  }
  return result;
};

/**
 * Get jpath array for a specific registry
 * @param {Array} jpathArray - array returned from func getJpathArray()
 * @param {string} regKey - eg, "ctg" or "eudra"
 * @returns - array with keys that make up a forked field,
 *            eg, ["results", "more_info", "is_pis_employees_ctg"]
 */
const getForkedJpathArray = (jpathArray, regKey) => {
  if (jpathArray?.length > 0) {
    const fieldParts = [...jpathArray];
    const fieldName = `${fieldParts[fieldParts.length - 1]}_${regKey}`;
    fieldParts.pop();
    fieldParts.push(fieldName);
    return fieldParts;
  }
  return null;
};

/**
 * Recursive function to get all forked fields for a particular registry
 * Looking for fields that end in `_${REGISTRY}`
 * @param {String} regKey eg, "ctg", "eudra"
 * @param {Object | *} node starting with formData
 * @param {Array} jpathArray helper prop to build jpath,
 *                add [`root_${form_sect_id}`] at init
 * @param {Array} result helper prop to build return value
 * @returns {Object} forked field jpaths and object with props:
 *                      * value - value object for forked fields
 *                      * jpathArray - array making up forked field jpath
 *                   { jpath1: { value: , jpathArray } }
 */
const getRegistryForkedFields = (regKey, node, jpathArray = [], result = {}) => {
  for (const nodeKey in node) {
    if (isObject(node[nodeKey]) && !isEmpty(node[nodeKey])) {
      // keep traversing thru tree branches
      if (!isEmpty(node[nodeKey])) { // including arrays
        const _jpathArr = [...jpathArray];
        _jpathArr.push(nodeKey);
        getRegistryForkedFields(regKey, node[nodeKey], _jpathArr, result);
      };
    } else {
      // end of branch, write to result
      if (!isEmpty(node[nodeKey])) {
        if (nodeKey.endsWith(`_${regKey}`)) {
          const _jpathArr = [...jpathArray];
          _jpathArr.push(nodeKey);
          result[`root_${_jpathArr.join("_")}`] = {
            value: node[nodeKey],
            jpathArray: _jpathArr,
          };
        }
      }
    };
  }
  return result;
};

const blankStudyData = {
  "delayed_results_certification": {},
  "results": {
    "more_info": {
      "certain_agreements": {},
      "results_poc": {
        "organization_name": "",
        "phone": "",
        "name": "",
        "email": ""
      },
      "global_protocol_amendments": {},
      "global_protocol_interruptions": {},
      "online_references": {}
    },
    "trial_info": {
      "trial_id": {},
      "additional_study_identifiers": {},
      "paediatric_regulatory_details": {},
      "result_analysis_stage": {
        "primary_completion_data": {},
        "global_end_date": {}
      },
      "general_info": {
        "follow_up": {}
      },
      "population": {}
    },
    "participant_flow": {},
    "baseline_characteristics": {
      "baseline_characteristics_table": {

      }
    },
    "outcome_measures": {},
    "adverse_events": {}
  },
  "protocol": {
    "study_type": "Interventional",
    "general": {},
    "status": {
      "record_status": "Unverified"
    },
    "milestone_dates": {
      "pra": {},
      "fpi": {},
      "lpo": {},
      "pcd": {},
      "csr": {},
      "fsa": {}
    },
    "secondary_attributes": {
      "internal": {
        "first_product_indication_approval_date_in_any_country": {}
      },
      "usa": {
        "fda_product_approval_date": {},
        "ctg_delay_certification_submitted_date": {}
      },
      "europe": {
        "ema_cta_date": {},
        "date_of_latest_approval": {},
        "date_of_cta": {},
        "date_of_results": {}
      },
      "germany": {
        "germany_cta_date": {},
        "mma_smaa_approval_date": {},
        "product_authorized_in_eu_germany_date": {}
      },
      "spain": {
        "aemps_authorization_date": {},
        "rec_authorization_date": {},
        "date_of_publication": {}
      },
      "netherlands": {},
      "mexico": {
        "cofepris_approval_date": {}
      },
      "japan": {
        "date_of_approval": {}
      },
      "united_kingdom": {}
    },
    "study_identification": {},
    "contact_information": {
      "central_contacts": {
        "central_contacts_email": "",
        "central_contacts_last_name": "",
        "central_contacts_phone_number": "",
        "central_contacts_first_name": ""
      },
      "central_contacts_backup": {}
    },
    "study_oversight": {
      "us_drug": {},
      "us_device": {
        "unapproved_uncleared_device": {}
      },
      "us_ind": {},
      "human_subjects_protection_review": {},
      "data": {},
      "fda": {}
    },
    "study_timeline": {
      "record_verification_date": {},
      "recruitment": {},
      "milestone_dates": {
        "study_start_date": {},
        "primary_completion_date": {},
        "study_completion_date": {}
      }
    },
    "study_description": {},
    "study_scope": {},
    "study_arms": {},
    "study_eligibilty": {
      "gender_based": {},
      "age_limits": {
        "minimum": {},
        "maximum": {}
      }
    },
    "study_ipd": {
      "ipd_sharing_info": [],
      "plan_to_share_IPD": "",
      "access_criteria": "",
      "url_text": "",
      "plan_description": ""
    },
    "study_outcome_measures": {},
    "study_reference": {}
  }
}

export {
  // versioning
  getVersionValues,
  formatVersNumber,
  getNextMinorVersNumber,
  getNextMajorVersNumber,

  // registry badges
  getRegistryKeys,

  // forked fields
  getJpathArray,
  getForkedJpathArray,
  getRegistryForkedFields,
  blankStudyData,
  getHiddenNodes
};
