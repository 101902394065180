import React, { useEffect, useState, useMemo, memo } from 'react';
import { Popover } from 'antd'
import { intersperse } from '../../../utilities/misc';

// Components
import PRSValidationBadge from '../../badges/PRSValidationBadge/PRSValidationBadge';

// styles
import './PRSValidations.scss'

const iconProps = {
  info: {
    color: "#18669E",
    icon: "info-circle"
  },
  warning: {
    color: "#ECB81E",
    icon: "exclamation-triangle",
  },
  error: {
    color: "#D7542C",
    icon: "do-not-enter"
  }
}

const PRSValidation = (props) => {
  const [display, setDisplay] = useState([]);
  const [validationMessages, setValidationMessages] = useState([]);

  useEffect(() => {
    if (props.data && props.source) {
      if (props.source === "study-list") {
        getStudyListBadges()
      } else if (props.source === "study-details") {
        getStudyDetailsBadges()
      }
    }
  }, [props.data, props.source]);

  const getStudyListBadges = () => {
    if (props.data.latest_upload_record_upload_report &&
      props.data.latest_upload_record_upload_report.validationMessages &&
      props.data.latest_upload_record_upload_report.validationMessages.validationMessage) {
      let { validationMessage } = props.data.latest_upload_record_upload_report.validationMessages;
      validationMessage = typeCheckValidationMessages(validationMessage);
      let errors = [];
      let nonSiteErrors = [];
      let warnings = [];
      let notes = [];
      if (validationMessage && validationMessage.length > 0) {
        setValidationMessages(validationMessage)

        validationMessage.forEach(item => {
          if (item.severity === "ERROR") {
            if (item.site_db_id) {
              errors.push(item)
            } else {
              nonSiteErrors.push(item)
            }
          };
          if (item.severity === "WARNING") warnings.push(item);
          if (item.severity === "NOTE") notes.push(item);
        })

        let items = [];

        if (errors.length > 0) {
          items.push(<PRSValidationBadge key={"sl-errors"} type={"error"} num={errors.length} />)
        }
        if (warnings.length > 0) {
          items.push(<PRSValidationBadge key={"sl-warnings"} style={{ marginLeft: 4 }} type={"warning"} num={warnings.length} />)
        }
        if (notes.length > 0) {
          items.push(<PRSValidationBadge key={"sl-notes"} style={{ marginLeft: 4 }} type={"note"} num={notes.length} />)
        }
        if (nonSiteErrors.length > 0) {
          items.push(<PRSValidationBadge key={"sl-non-sites"} style={{ marginLeft: 4 }} type={"non-site"} num={nonSiteErrors.length} />)
        }

        items = intersperse(items, ", ")
        setDisplay(items);
      }
    }
  }

  const getStudyDetailsBadges = () => {
    if (props?.data?.validations) {
      let validation_messages = props.data.validations;
      validation_messages = typeCheckValidationMessages(validation_messages);
      let errors = [];
      let nonSiteErrors = [];
      let warnings = [];
      let notes = [];

      if (validation_messages && validation_messages.length > 0) {
        setValidationMessages(validation_messages)

        validation_messages.forEach(item => {
          if (item.severity === "ERROR") {
            if (item.site_db_id) {
              errors.push(item)
            } else {
              nonSiteErrors.push(item)
            }
          };
          if (item.severity === "WARNING") warnings.push(item);
          if (item.severity === "NOTE") notes.push(item);
        })

        let items = []

        if (errors.length > 0) {
          items.push(<PRSValidationBadge key={"sl-errors"} type={"error"} num={errors.length} />)
        }
        if (warnings.length > 0) {
          items.push(<PRSValidationBadge key={"sl-warnings"} style={{ marginLeft: 4 }} type={"warning"} num={warnings.length} />)
        }
        if (notes.length > 0) {
          items.push(<PRSValidationBadge key={"sl-notes"} style={{ marginLeft: 4 }} type={"note"} num={notes.length} />)
        }
        if (nonSiteErrors.length > 0) {
          items.push(<PRSValidationBadge key={"sl-non-sites"} style={{ marginLeft: 4 }} type={"non-site"} num={nonSiteErrors.length} />)
        }

        items = intersperse(items, ", ")

        setDisplay(items);
      }
    }
  }

  const typeCheckValidationMessages = (validationMessages) => {
    if (Array.isArray(validationMessages)) {
      return validationMessages;
    } else if (typeof validationMessages === 'object' && validationMessages !== null) {
      return [validationMessages];
    }
    return null;
  }

  const onClick = () => {
    if (props.disabled !== true) {
      props.onOpenModal("prs-validation-messages", props, props.studyData, validationMessages)
    }
  }

  const getStatus = useMemo(() => {
    let statuses = []
    let errors = []
    let warnings = []
    let notes = []

    if (validationMessages) {
      validationMessages.forEach(message => {
        if (message.severity === "ERROR") {
          errors.push(message)
        } else if (message.severity === "WARNING") {
          warnings.push(message)
        } else if (message.severity === "NOTE") {
          notes.push(message)
        }
      })
    }

    if (errors.length > 0) {
      statuses.push({
        type: "Error(s)",
        icon: <i className={`fas fa-${iconProps.error.icon}`} style={{ color: iconProps.error.color, fontSize: ".75rem" }} />,
        messages: errors
      })
    }
    if (warnings.length > 0) {
      statuses.push({
        type: "Warning(s)",
        icon: <i className={`fas fa-${iconProps.warning.icon}`} style={{ color: iconProps.warning.color, fontSize: ".75rem" }} />,
        messages: warnings
      })
    }
    if (notes.length > 0) {
      statuses.push({
        type: "Note(s)",
        icon: <i className={`fas fa-${iconProps.info.icon}`} style={{ color: iconProps.info.color, fontSize: ".75rem" }} />,
        messages: notes
      })
    }

    return statuses
  }, [validationMessages])

  return (
    <div
      className="prs-validation-cell-renderer-container"
      onClick={onClick}>
      {/* {display?.length > 0 ? display : ""} */}
      {display?.length > 0 ? (
        <Popover content={(
          <div className="prs-upload-status-popover-container">
            {getStatus?.map(status => {
              return (
                <div>
                  <div style={{ fontWeight: "600" }}>
                    {status.icon} {status.type}
                  </div>
                  <ul>
                    {status.messages.map(message => {
                      return (
                        <li>
                          <div>
                            <div style={{ fontWeight: "600" }}>Source: {message.source}</div>
                            <div>Error: {message?.message}</div>
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )
            })}
          </div>
        )}>
          {display}
        </Popover>
      ) : ""}
    </div>
  );
};

export default memo(PRSValidation);
