import { hideStudyDetailsFields, hideSecondaryAttributeFields } from './utils/utils';
import {
  objectClassNames,
  arrayClassNames
} from '../classes';

export const primeProtocolUISchema = {
  "ui:options": {
    classNames: {
      objectContainer: "rjs-full",
      field: "rjs-full"
    }
  },
  protocol: {
    "ui:order": [
      "protocol_id",
      "study_type",
      "eupas_study_type",
      "eudract_number",
      "other_study_type",
      "study_identification",
      "contact_information",
      "study_oversight",
      "study_timeline",
      "milestone_dates",
      "secondary_attributes",
      "*",
    ],
    "ui:options": {
      title: "Study Identification and Information",
      classNames: {
        objectContainer: "rjs-gap-16 rjs-full",
        objectTitleContainer: "rjs-h1-container pt-24 pl-24 pr-24 pb-24 m-0",
        objectTitle: "rjs-h1",
        objectInnerContainer: "no-gap section-container flex-row gap-16 flex-wrap",
        field: "rjs-full"
      }
    },
    ...hideStudyDetailsFields(),
    ...hideSecondaryAttributeFields({ isGlobal: true }),
    protocol_id: {
      "ui:options": {
        label: false,
        title: "Unique Protocol Identification Number",
        placeholder: "Enter Unique Protocol ID",
        classNames: {
          fieldContainer: "w-50p pl-24 pr-8 pb-8",
        }
      }
    },
    study_type: {
      "ui:options": {
        title: "Study Type",
        placeholder: "Select Study Type",
        label: false,
        classNames: {
          fieldContainer: "w-50p pr-24 pl-8 pb-8",
        }
      }
    },
    eupas_study_type: {
      "ui:options": {
        title: "EU PAS Study Type",
        placeholder: "Select Study Type",
        label: false,
        classNames: {
          fieldContainer: "pl-24 pr-8 pb-8 rjs-full",
          field: "rjs-col-1-2 pr-8",
        }
      }
    },
    eudract_number: {
      "ui:options": {
        title: "EUDRACT number, if available",
        placeholder: "(YYYY-NNNNNN-CC)",
        label: false,
        classNames: {
          fieldContainer: "rjs-full pl-24 pr-8 pb-8",
          field: "rjs-col-1-2 pr-8"
        }
      }
    },
    other_study_type: {
      "ui:options": {
        title: "Other",
        placeholder: "Please Describe",
        label: false,
        classNames: {
          fieldContainer: "rjs-full pl-24 pr-8 pb-8",
          field: "rjs-col-1-2 pr-8"
        }
      }
    },
    study_identification: {
      "ui:order": ["protocol_id", "acronym", "brief_title", "brief_title_translation", "official_title", "official_title_translation", "secondary_ids", "is_requested_by_regulator", "country_regulator", "country_regulator_not_listed", "*"],
      "ui:options": {
        classNames: {
          fieldContainer: "rjs-full",
          objectContainer: "pl-24 pr-24 pb-13",
        }
      },
      brief_title: {
        "ui:options": {
          label: false,
          title: "Brief Title",
          placeholder: "Enter Brief Title",
          classNames: {
            fieldContainer: "rjs-full",
          },
        },
        "ui:widget": "textarea"
      },
      brief_title_translation: {
        "ui:options": {
          title: "Full Title Translation",
          itemTitle: "Translation",
          addButtonTitle: "Add Translation",
          label: false,
          addable: true,
          removable: true,
          orderable: true,
          classNames: arrayClassNames.primary
        },
        items: {
          language: {
            "ui:options": {
              title: "Language",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          translation: {
            "ui:options": {
              title: "Translation",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-2-3"
              }
            },
            "ui:widget": "textarea"
          },
        },
      },
      acronym: {
        "ui:options": {
          title: "Acronym (if any)",
          placeholder: "Enter Acronym ",
          label: false,
          log: true,
          classNames: {
            fieldContainer: "rjs-col-1-2",
          },
        }
      },
      official_title: {
        "ui:options": {
          label: false,
          title: "Official Title",
          placeholder: "Enter Official Title",
          classNames: {
            fieldContainer: "rjs-full",
          },
        },
        "ui:widget": "textarea"
      },
      official_title_translation: {
        "ui:options": {
          title: "Public Title Translation",
          itemTitle: "Translation",
          addButtonTitle: "Add Translation",
          label: false,
          addable: true,
          removable: true,
          orderable: true,
          classNames: arrayClassNames.primary
        },
        items: {
          language: {
            "ui:options": {
              title: "Language",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          translation: {
            "ui:options": {
              title: "Translation",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-2-3"
              }
            },
            "ui:widget": "textarea"
          },
        },
      },
      secondary_ids: {
        "ui:options": {
          title: "Secondary ID's",
          itemTitle: "Identifier",
          addButtonTitle: "Add Secondary ID",
          label: false,
          addable: true,
          removable: true,
          orderable: true,
          classNames: arrayClassNames.primary
        },
        items: {
          "ui:order": ["secondary_id_type", "id", "domain", "*"],
          secondary_id_type: {
            "ui:options": {
              label: false,
              title: "Identifier Type",
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
          },
          id: {
            "ui:options": {
              label: false,
              title: "data-schema", // data-schema title will pull title from data schema
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          domain: {
            "ui:options": {
              label: false,
              title: "data-schema", // data-schema title will pull title from data schema
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
        },
      },
      is_requested_by_regulator: {
        "ui:options": {
          title: "Was this study requested by a regulator?",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-2",
          },
        },
        "ui:widget": "radio"
      },
      country_regulator: {
        "ui:options": {
          title: "Country of Regulator",
          placeholder: "Select Countries",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-2",
          },
        },
        "ui:widget": "Lookahead"
      },
      country_regulator_not_listed: {
        "ui:options": {
          title: "Other country/ies",
          placeholder: "Specify country/ies not listed",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-2",
          },
        },
      },
      is_study_required_by_risk_management_plan: {
        "ui:options": {
          title: "Is the study required by a Risk Management Plan (RMP)?",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-2",
          },
        },
      },
      regulatory_procedure_number: {
        "ui:options": {
          title: "Regulatory procedure number (RMP Category 1 and 2 studies only)?",
          label: false,
          classNames: {
            fieldContainer: "rjs-full",
          },
        },
        "ui:widget": "textarea"
      },
      other_study_identification_numbers: {
        "ui:options": {
          title: "Other study registration identification numbers and URLs as applicable",
          label: false,
          classNames: {
            fieldContainer: "rjs-full",
          },
        },
        "ui:widget": "CopyTextArea"
      },
    },
    ctis_form: {
      "ui:options": {
        title: "Form",
        classNames: {
          objectTitleContainer: "rjs-h1-container",
          objectContainer: "rjs-object-container p-24",
          objectTitle: "rjs-h1",
          fieldContainer: "rjs-full",
        }
      },
      deferral_clinical_trial_information: {
        "ui:order": [
          "short_title",
          "justification_for_trial_category",
          "main_characteristics",
          "notifications",
          "subject_information_sheet",
          "subject_information_sheet_years",
          "subject_information_sheet_months",
          "protocol_summary_scientific_advice",
          "protocol_summary_scientific_advice_years",
          "protocol_summary_scientific_advice_months",
          "impd_sande_sections_investigator_brochure",
          "impd_sande_sections_investigator_brochure_years",
          "impd_sande_sections_investigator_brochure_months",
          "responses_rfi",
          "responses_rfi_years",
          "responses_rfi_months",
          "clinical_trial_results_summary_intermediate_data_analysis",
          "clinical_trial_results_summary_intermediate_data_analysis_months",
          "clinical_trial_results_summmray_lay_person_summary",
          "clinical_trial_results_summmray_lay_person_summary_months",
          "*"
        ],
        "ui:options": {
          title: "Deferral of clinical trial information",
          classNames: {
            fieldContainer: "rjs-full",
            objectTitle: "rjs-h2"
          }
        },
        short_title: {
          "ui:options": {
            title: "Short title / Trial category",
            tooltip: `Category applied to the trial that will determine the publishing rules. More information can be consulted on https://www.ema.europa.eu/en/documents/other/appendix-disclosure-rules-functional-specifications-eu-portal-eu-database-be-audited_en.pdf. NOTE: The action of updating from Category 1 or Catergory 2 to Category 3 will reset the publication dates to the default 'Date of decision' for that category and will also remove any deferrals`,
            label: false,
            log: true,
            classNames: {
              fieldContainer: "rjs-col-1-2"
            }
          }
        },
        justification_for_trial_category: {
          "ui:options": {
            title: "Justification for trial category / Trial category",
            tooltip: "Category applied to the trial that will determine the publishing rules. More information can be consulted on https://www.ema.europa.eu/en/documents/other/appendix-disclosure-rules-functional-specifications-eu-portal-eu-database-be-audited_en.pdf",
            placeholder: "",
            label: false,
            log: true,
            classNames: {
              fieldContainer: "rjs-col-1-2"
            }
          }
        },
        main_characteristics: {
          "ui:options": {
            title: "Main characteristics",
            placeholder: "--Select--",
            label: false,
            classNames: {
              fieldContainer: "rjs-full"
            }
          },
          "ui:widget": "radio"
        },
        notifications: {
          "ui:options": {
            title: "Notifications",
            placeholder: "--Select--",
            label: false,
            classNames: {
              fieldContainer: "rjs-full"
            }
          },
          "ui:widget": "radio"
        },
        subject_information_sheet: {
          "ui:options": {
            title: "Subject information sheet",
            placeholder: "--Select--",
            label: false,
            classNames: {
              fieldContainer: "rjs-full",
              field: "rjs-col-1-2",
            }
          },
          "ui:widget": "radio"
        },
        protocol_summary_scientific_advice: {
          "ui:options": {
            title: "Protocol and Summary of Scientific Advice",
            placeholder: "--Select--",
            label: false,
            classNames: {
              fieldContainer: "rjs-full",
              field: "rjs-col-1-2",
            }
          },
          "ui:widget": "radio"
        },
        impd_sande_sections_investigator_brochure: {
          "ui:options": {
            title: "IMPD SandE sections and Investigator Brochure",
            placeholder: "--Select--",
            label: false,
            classNames: {
              fieldContainer: "rjs-full",
              field: "rjs-col-1-2",
            }
          },
          "ui:widget": "radio"
        },
        responses_rfi: {
          "ui:options": {
            title: "Responses to RFI",
            placeholder: "--Select--",
            label: false,
            classNames: {
              fieldContainer: "rjs-full",
              field: "rjs-col-1-2",
            }
          },
          "ui:widget": "radio"
        },
        clinical_trial_results_summary_intermediate_data_analysis: {
          "ui:options": {
            title: "Clinical trial results summary for an intermediate data analysis",
            placeholder: "--Select--",
            label: false,
            classNames: {
              fieldContainer: "rjs-full"
            }
          },
          "ui:widget": "radio"
        },
        clinical_trial_results_summmray_lay_person_summary: {
          "ui:options": {
            title: "Clinical trial results summary and lay person summary",
            placeholder: "--Select--",
            label: false,
            classNames: {
              fieldContainer: "rjs-full"
            }
          },
          "ui:widget": "radio"
        },
        subject_information_sheet_years: {
          "ui:options": {
            title: "Years",
            placeholder: "--Select--",
            label: false,
            type: "number",
            classNames: {
              fieldContainer: "rjs-col-1-4"
            }
          },
        },
        subject_information_sheet_months: {
          "ui:options": {
            title: "Months after the end of trial",
            placeholder: "--Select--",
            label: false,
            type: "number",
            classNames: {
              fieldContainer: "rjs-col-1-4"
            }
          },
        },
        protocol_summary_scientific_advice_years: {
          "ui:options": {
            title: "Years",
            type: "number",
            placeholder: "--Select--",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-4"
            }
          },
        },
        protocol_summary_scientific_advice_months: {
          "ui:options": {
            title: "Months after the end of trial",
            type: "number",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-4"
            }
          },
        },
        impd_sande_sections_investigator_brochure_years: {
          "ui:options": {
            title: "Years",
            placeholder: "--Select--",
            label: false,
            type: "number",
            classNames: {
              fieldContainer: "rjs-col-1-4"
            }
          },
        },
        impd_sande_sections_investigator_brochure_months: {
          "ui:options": {
            title: "Months after the end of trial",
            placeholder: "--Select--",
            type: "number",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-4"
            }
          },
        },
        responses_rfi_years: {
          "ui:options": {
            title: "Years",
            placeholder: "--Select--",
            label: false,
            type: "number",
            classNames: {
              fieldContainer: "rjs-col-1-4"
            }
          },
        },
        responses_rfi_months: {
          "ui:options": {
            title: "Months after the end of trial",
            placeholder: "--Select--",
            label: false,
            type: "number",
            classNames: {
              fieldContainer: "rjs-col-1-4"
            }
          },
        },
        clinical_trial_results_summary_intermediate_data_analysis_months: {
          "ui:options": {
            title: "Months after the end of trial",
            placeholder: "--Select--",
            label: false,
            type: "number",
            classNames: {
              fieldContainer: "rjs-col-1-4"
            }
          },
        },
        clinical_trial_results_summmray_lay_person_summary_months: {
          "ui:options": {
            title: "Months after the end of trial",
            placeholder: "--Select--",
            label: false,
            type: "number",
            classNames: {
              fieldContainer: "rjs-col-1-4"
            }
          },
        },
        justification_deferral_published_decision: {
          "ui:options": {
            title: "Justification for deferral published at decision",
            placeholder: "--Select--",
            label: false,
            type: "number",
            classNames: {
              fieldContainer: "rjs-col-1-4"
            }
          },
          "ui:widget": "textarea"
        }
      },
    },
    mscs: {
      "ui:options": {
        title: "MSCs",
        classNames: {
          objectTitleContainer: "rjs-h1-container",
          objectContainer: "rjs-object-container p-24",
          objectTitle: "rjs-h1",
        }
      },
      member_states_concerned: {
        "ui:options": {
          title: "Member States Concerned",
          classNames: {
            objectTitle: "rjs-h2",
          }
        },
        member_states_concerned_table: {
          "ui:options": {
            label: false,
          },
          "ui:widget": "MemberStatesConcerned"
        }
      },
    },
    contact_information: {
      "ui:order": [
        "responsible_party",
        "sponsor",
        "investigator_name",
        "investigator_official_title",
        "investigator_affiliation",
        "is_study_conducted_by_registered_encepp",
        "investigator_center",
        "encepp_center_organization_affiliation",
        "encepp_center_website",
        "research_center_name",
        "research_center_location",
        "*",
        "is_study_collaboration_research_network",
        "networks_registered_encepp",
        "other_networks",
        "other_centers_study_conducted",
        "number_of_centers",
        "centers_registered_encepp",
        "other_centers",
        "country_study_is_conducted",
        "national_country",
        "international_countries",
      ],
      "ui:options": {
        title: "Study Sponsor, Collaborators and Contact Information",
        classNames: {
          objectTitleContainer: "rjs-h1-container",
          objectContainer: "rjs-object-container p-24",
          objectTitle: "rjs-h1",
        }
      },
      responsible_party: {
        "ui:options": {
          title: "Responsible Party",
          placeholder: "Select Responsible Party",
          label: false,
          tooltip: "Select Sponsor unless the Principal Investigator has been designated as Responsible Party or the Principal Investigator is the Sponsor.",
          classNames: {
            fieldContainer: "rjs-full",
          },
        },
        "ui:widget": "radio",
      },
      sponsor: {
        "ui:options": {
          title: "Sponsor",
          placeholder: "Select Sponsor",
          tooltip: "Primary organization conducting study and associated data analysis (not necessarily a funding source).",
          label: false,
          orientation: "horizontal",
          classNames: {
            fieldContainer: "rjs-full",
            field: "rjs-col-1-2",
          },
        },
        "ui:widget": "Lookahead"
      },
      investigator_name: {
        "ui:options": {
          title: "Investigator Name",
          placeholder: "Enter Investigator Name",
          label: false,
          tooltip: "Select Sponsor unless the Principal Investigator has been designated as Responsible Party or the Principal Investigator is the Sponsor.",
          classNames: {
            fieldContainer: "rjs-col-1-3",
          },
        }
      },
      investigator_official_title: {
        "ui:options": {
          title: "Investigator Official Title",
          placeholder: "Enter Official Title",
          label: false,
          tooltip: "Select Sponsor unless the Principal Investigator has been designated as Responsible Party or the Principal Investigator is the Sponsor.",
          classNames: {
            fieldContainer: "rjs-col-1-3",
          },
        }
      },
      investigator_affiliation: {
        "ui:options": {
          title: "Investigator Affiliation",
          placeholder: "Enter Investigator Affiliation",
          label: false,
          tooltip: "Select Sponsor unless the Principal Investigator has been designated as Responsible Party or the Principal Investigator is the Sponsor.",
          classNames: {
            fieldContainer: "rjs-col-1-3",
          },
        }
      },
      collaborators: {
        "ui:options": {
          title: "Collaborators",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-2"
          }
        },
        "ui:widget": "Multiselect"
      },
      central_contacts: {
        "ui:options": {
          title: "Central Contact",
          classNames: {
            objectTitle: "rjs-h2",
          }
        },
        central_contacts_title: {
          "ui:options": {
            label: false,
            title: "Title",
            placeholder: "Title",
            classNames: {
              fieldContainer: "rjs-col-1-4"
            }
          }
        },
        central_contacts_first_name: {
          "ui:options": {
            label: false,
            title: "First Name",
            placeholder: "First Name",
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          }
        },
        central_contacts_middle_name: {
          "ui:options": {
            label: false,
            title: "M.I",
            placeholder: "Enter MI",
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          }
        },
        central_contacts_last_name: {
          "ui:options": {
            label: false,
            title: "Last Name",
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          }
        },
        central_contacts_degree: {
          "ui:options": {
            label: false,
            title: "Degree",
            placeholder: "Enter Degree",
            classNames: {
              fieldContainer: "rjs-col-1-4"
            }
          }
        },
        central_contacts_phone_number: {
          "ui:options": {
            label: false,
            title: "Phone Number",
            placeholder: "Enter Phone Number",
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          }
        },
        central_contacts_ext: {
          "ui:options": {
            label: false,
            title: "Ext.",
            placeholder: "Enter Extension",
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          }
        },
        central_contacts_email: {
          "ui:options": {
            label: false,
            title: "E-mail",
            placeholder: "Enter E-mail",
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          }
        }
      },
      central_contacts_backup: {
        "ui:options": {
          title: "Central Contact Backup",
          classNames: {
            objectTitle: "rjs-h2",
          }
        },
        central_contacts_backup_first_name: {
          "ui:options": {
            label: false,
            title: "First Name",
            placeholder: "First Name",
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          }
        },
        central_contacts_backup_middle_name: {
          "ui:options": {
            label: false,
            title: "M.I",
            placeholder: "Enter MI",
            classNames: {
              fieldContainer: "rjs-col-1-4"
            }
          }
        },
        central_contacts_backup_last_name: {
          "ui:options": {
            label: false,
            title: "Last Name",
            placeholder: "Enter Last Name",
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          }
        },
        central_contacts_backup_degree: {
          "ui:options": {
            label: false,
            title: "Degree",
            placeholder: "Enter Degree",
            classNames: {
              fieldContainer: "rjs-col-1-4"
            }
          }
        },
        central_contacts_backup_phone_number: {
          "ui:options": {
            label: false,
            title: "Phone Number",
            placeholder: "Enter Phone Number",
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          }
        },
        central_contacts_backup_ext: {
          "ui:options": {
            label: false,
            title: "Ext.",
            placeholder: "Enter Extension",
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          }
        },
        central_contacts_backup_email: {
          "ui:options": {
            label: false,
            title: "E-mail",
            placeholder: "Enter E-mail",
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          }
        }
      },
      overall_study_officials: {
        "ui:options": {
          title: "Overall Study Officials",
          label: false,
          addable: true,
          removable: true,
          orderable: true,
          addButtonTitle: "Add Study Official",
          classNames: arrayClassNames.primary
        },
        items: {
          overall_study_officials_first_name: {
            "ui:options": {
              title: "First Name",
              placeholder: "Enter First Name",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          overall_study_officials_middle_name: {
            "ui:options": {
              title: "M.I",
              placeholder: "Enter MI",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-4"
              }
            }
          },
          overall_study_officials_last_name: {
            "ui:options": {
              title: "Last Name",
              placeholder: "Enter Last Name",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          overall_study_officials_degree: {
            "ui:options": {
              title: "Degree",
              placeholder: "Enter Degree",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-4"
              }
            }
          },
          overall_study_officials_organization_affiliation: {
            "ui:options": {
              title: "Organization Affiliation",
              placeholder: "Enter Organization Affiliation",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          official_role: {
            "ui:options": {
              title: "Official's Role",
              placeholder: "Select Role",
              label: false,
              classNames: {
                fieldContainer: "rjs-full"
              }
            },
            "ui:widget": "radio",
          },
        },
      },
      is_study_conducted_by_registered_encepp: {
        "ui:options": {
          title: "Is this study conducted/coordinated by a centre registered in the ENCePP inventory of research centres?",
          label: false,
          classNames: {
            fieldContainer: "rjs-full",
            field: "rjs-col-1-2",
          }
        },
        "ui:widget": "radio"
      },
      investigator_center: {
        "ui:options": {
          title: "Center to which the investigator belongs",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-2",
          }
        },
        "ui:widget": "Lookahead"
      },
      department_research_group: {
        "ui:options": {
          title: "Department/Research group",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-2",
          }
        },
      },
      encepp_center_organization_affiliation: {
        "ui:options": {
          title: "Organisation/affiliation",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-2",
          }
        },
      },
      encepp_center_website: {
        "ui:options": {
          title: "Website/Homepage",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-2",
          }
        },
      },
      research_center_name: {
        "ui:options": {
          title: "Please specify name",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-2",
          }
        },
      },
      research_center_location: {
        "ui:options": {
          title: "Please specify location",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-2",
          }
        },
      },
      is_study_collaboration_research_network: {
        "ui:options": {
          title: "Is this study being carried out with the collaboration of a research network?",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-2",
          }
        },
        "ui:widget": "radio"
      },
      networks_registered_encepp: {
        "ui:options": {
          title: "Networks registered with ENCePP",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-2",
          }
        },
        "ui:widget": "Multiselect"
      },
      other_networks: {
        "ui:options": {
          title: "Other networks",
          addButtonTitle: "Add Network",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-2",
          }
        },
      },
      other_centers_study_conducted: {
        "ui:options": {
          title: "Other centers where this study is being conducted",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-2",
          }
        },
        "ui:widget": "radio"
      },
      number_of_centers: {
        "ui:options": {
          title: "In total how many centres are involved in this Study?",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-2",
          }
        },
      },
      centers_registered_encepp: {
        "ui:options": {
          title: "Centers registered with ENCePP",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-2",
          }
        },
        "ui:widget": "Multiselect",
      },
      other_centers: {
        "ui:options": {
          title: "Other Centers",
          addButtonTitle: "Add Center",
          label: false,
          addable: true,
          removable: true,
          orderable: true,
          classNames: arrayClassNames.primary
        },
        items: {
          name: {
            "ui:options": {
              label: false,
              title: "Name",
              classNames: {
                fieldContainer: "rjs-col-1-2",
              },
            },
          },
          location: {
            "ui:options": {
              label: false,
              title: "Location",
              classNames: {
                fieldContainer: "rjs-col-1-2",
              },
            },
          }
        }
      },
      country_study_is_conducted: {
        "ui:options": {
          title: "Countries in which this study is being conducted",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
        "ui:widget": "radio"
      },
      national_country: {
        "ui:options": {
          title: "Please select a country",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
      },
      international_countries: {
        "ui:options": {
          title: "Please select countries",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3",
          }
        },
        "ui:widget": "Multiselect"
      },
      investigator_contact_details: {
        "ui:options": {
          title: "EU PAS Contact Information",
          classNames: {
            objectTitle: "rjs-h2",
          }
        },
        lead_investigator: {
          "ui:options": {
            title: "Details of (Primary) lead investigator",
            classNames: {
              field: "rjs-full",
              objectTitle: "rjs-h3",
            }
          },
          title: {
            "ui:options": {
              label: false,
              title: "Title",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          first_name: {
            "ui:options": {
              label: false,
              title: "First Name",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          last_name: {
            "ui:options": {
              label: false,
              title: "Last Name",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          address_1: {
            "ui:options": {
              label: false,
              title: "Address Line 1",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          address_2: {
            "ui:options": {
              label: false,
              title: "Address Line 2",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          address_3: {
            "ui:options": {
              label: false,
              title: "Address Line 3",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          address_4: {
            "ui:options": {
              label: false,
              title: "Address Line 4",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          city: {
            "ui:options": {
              label: false,
              title: "City",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          zipcode: {
            "ui:options": {
              label: false,
              title: "Postcode",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          country: {
            "ui:options": {
              label: false,
              title: "Country",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          phone_number: {
            "ui:options": {
              label: false,
              title: "Phone number (incl. country code)",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          phone_number_alt: {
            "ui:options": {
              label: false,
              title: "Alternative phone number",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          fax_number: {
            "ui:options": {
              label: false,
              title: "Fax number",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          email: {
            "ui:options": {
              label: false,
              title: "Email address",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          confirm_email: {
            "ui:options": {
              label: false,
              title: "Confirm email address",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          functional_contact_point_name: {
            "ui:options": {
              label: false,
              title: "Functional contact point name",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
        },
        scientific_contact: {
          "ui:options": {
            title: "Scientific Enquiries",
            classNames: {
              objectTitle: "rjs-h3",
            }
          },
          copy_lead_investigator: {
            "ui:options": {
              label: false,
              defaultValue: false,
              title: "Please tick the box if you wish the (Primary) lead investigator to be the contact person",
              classNames: {
                field: "flex flex-row-reverse justify-start items-center gap-8",
                fieldContainer: "rjs-full",
              }
            },
            "ui:widget": "CopyContact"
          },
          title: {
            "ui:options": {
              label: false,
              defaultValue: false,
              title: "Title",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          first_name: {
            "ui:options": {
              label: false,
              defaultValue: false,
              title: "First Name",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          last_name: {
            "ui:options": {
              label: false,
              defaultValue: false,
              title: "Last Name",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          address_1: {
            "ui:options": {
              label: false,
              defaultValue: false,
              title: "Address Line 1",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          address_2: {
            "ui:options": {
              label: false,
              defaultValue: false,
              title: "Address Line 2",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          address_3: {
            "ui:options": {
              label: false,
              defaultValue: false,
              title: "Address Line 3",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          city: {
            "ui:options": {
              label: false,
              defaultValue: false,
              title: "City",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          zipcode: {
            "ui:options": {
              label: false,
              defaultValue: false,
              title: "Postcode",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          country: {
            "ui:options": {
              label: false,
              defaultValue: false,
              title: "Country",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          phone_number: {
            "ui:options": {
              label: false,
              defaultValue: false,
              title: "Phone number (incl. country code)",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          phone_number_alt: {
            "ui:options": {
              label: false,
              defaultValue: false,
              title: "Alternative phone number",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          fax_number: {
            "ui:options": {
              label: false,
              defaultValue: false,
              title: "Fax number",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          email: {
            "ui:options": {
              label: false,
              defaultValue: false,
              title: "Email address",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          confirm_email: {
            "ui:options": {
              label: false,
              defaultValue: false,
              title: "Confirm email address",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
        },
        public_contact: {
          "ui:options": {
            title: "Public Enquiries",
            classNames: {
              field: "rjs-full",
              objectTitle: "rjs-h3",
            }
          },
          copy_lead_investigator: {
            "ui:options": {
              label: false,
              title: "Please tick the box if you wish the (Primary) lead investigator to be the contact person",
              classNames: {
                field: "flex flex-row-reverse justify-start items-center gap-8",
                fieldContainer: "rjs-full",
              }
            },
            "ui:widget": "CopyContact"
          },
          title: {
            "ui:options": {
              label: false,
              defaultValue: false,
              title: "Title",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          first_name: {
            "ui:options": {
              label: false,
              defaultValue: false,
              title: "First Name",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          last_name: {
            "ui:options": {
              label: false,
              defaultValue: false,
              title: "Last Name",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          address_1: {
            "ui:options": {
              label: false,
              defaultValue: false,
              title: "Address Line 1",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          address_2: {
            "ui:options": {
              label: false,
              defaultValue: false,
              title: "Address Line 2",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          address_3: {
            "ui:options": {
              label: false,
              defaultValue: false,
              title: "Address Line 3",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          city: {
            "ui:options": {
              label: false,
              defaultValue: false,
              title: "City",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          zipcode: {
            "ui:options": {
              label: false,
              defaultValue: false,
              title: "Postcode",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          country: {
            "ui:options": {
              label: false,
              defaultValue: false,
              title: "Country",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          phone_number: {
            "ui:options": {
              label: false,
              defaultValue: false,
              title: "Phone number (incl. country code)",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          phone_number_alt: {
            "ui:options": {
              label: false,
              defaultValue: false,
              title: "Alternative phone number",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          fax_number: {
            "ui:options": {
              label: false,
              defaultValue: false,
              title: "Fax number",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          email: {
            "ui:options": {
              label: false,
              defaultValue: false,
              title: "Email address",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          confirm_email: {
            "ui:options": {
              label: false,
              defaultValue: false,
              title: "Confirm email address",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
        },
        contact_ctis: {
          "ui:options": {
            title: "Contact",
            classNames: {
              field: "rjs-full",
              objectTitle: "rjs-h3",
            }
          },
          first_name: {
            "ui:options": {
              label: false,
              title: "First Name",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          last_name: {
            "ui:options": {
              label: false,
              title: "Last Name",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          email: {
            "ui:options": {
              label: false,
              title: "Email address",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
          phone: {
            "ui:options": {
              label: false,
              title: "Phone number",
              classNames: {
                fieldContainer: "rjs-col-1-3",
              }
            }
          },
        },
      },
      scientific_advice: {
        "ui:options": {
          title: "Scientific advice",
          addButtonTitle: "Add competent authority",
          label: false,
          addable: true,
          removable: true,
          orderable: true,
          classNames: arrayClassNames.primary
        },
        items: {
          name: {
            "ui:options": {
              title: "Name",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          id: {
            "ui:options": {
              title: "ID",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          city: {
            "ui:options": {
              title: "City",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          country: {
            "ui:options": {
              title: "Country",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          organisation_type: {
            "ui:options": {
              title: "Organisation type",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          type: {
            "ui:options": {
              title: "Type",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          status: {
            "ui:options": {
              title: "Status",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          legal_representative: {
            "ui:options": {
              title: "Legal representative",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          scientific_contact_point: {
            "ui:options": {
              title: "Scientific contact point",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          public_contact_point: {
            "ui:options": {
              title: "Public contact point",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          third_parties: {
            "ui:options": {
              title: "Third parties",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
        },
      },
      paediatric_investigation_plan: {
        "ui:options": {
          title: "Paediatric investigation plan",
          addButtonTitle: "Add PIP",
          label: false,
          addable: true,
          removable: true,
          orderable: true,
          classNames: arrayClassNames.primary
        },
        items: {
          ema_paediatric_investigation_number: {
            "ui:options": {
              title: "EMA paediatric investigation number",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
        },
      },
    },
    study_oversight: {
      "ui:options": {
        title: "Oversight",
        classNames: {
          objectTitleContainer: "rjs-h1-container",
          objectTitle: "rjs-h1",
          objectContainer: "rjs-oc p-24",
        }
      },
      us_drug: {
        "ui:options": {
          classNames: {
            fieldContainer: "rjs-full"
          }
        },
        us_fda_drug: {
          "ui:options": {
            title: "U.S. FDA-regulated Drug:",
            placeholder: "--Select--",
            errorMessage: "FDA Drug has not been selected.",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        productexported_from_us: {
          "ui:options": {
            title: "Product Exported from U.S.:",
            placeholder: "--Select--",
            label: false,
            disabled: true,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
      },
      us_device: {
        "ui:options": {
          classNames: {
            fieldContainer: "rjs-full"
          }
        },
        us_fda_device: {
          "ui:options": {
            title: "U.S. FDA-regulated Device:",
            placeholder: "--Select--",
            errorMessage: "FDA Device has not been selected.",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        unapproved_uncleared_device: {
          "ui:options": {
            title: "Unapproved/Uncleared Device",
            label: false,
            classNames: {
              fieldContainer: "rjs-full",
              objectTitle: "rjs-h2",
            }
          },
          device: {
            "ui:options": {
              title: "Unapproved/Uncleared Device:",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
            "ui:widget": "radio",
          },
          post_prior_to_approval_clearance: {
            "ui:options": {
              title: "Post Prior to Approval/Clearance:",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
            "ui:widget": "radio",
          },
        },
        post_prior_to_approval_clearance: {
          "ui:options": {
            title: "Post Prior to Approval/Clearance:",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        pediatric_postmarket_surveillance: {
          "ui:options": {
            title: "Pediatric Postmarket Surveillance:",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
      },
      us_ind: {
        "ui:options": {
          classNames: {
            fieldContainer: "rjs-full"
          }
        },
        us_fda_ind_ide: {
          "ui:options": {
            title: "U.S. FDA IND/IDE:",
            errorMessage: "FDA has not been selected.",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        fda_center: {
          "ui:options": {
            title: "FDA Center:",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-2-3",
              field: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        ind_ide_number: {
          "ui:options": {
            title: "IND/IDE Number:",
            placeholder: "Enter Value",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
        },
        ind_serial_number: {
          "ui:options": {
            title: "IND Serial Number:",
            placeholder: "Enter Value",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-2-3",
              field: "rjs-col-1-2",
            }
          },
        },
        availability_of_expanded_access: {
          "ui:options": {
            title: "Availability of Expanded Access:",
            placeholder: "--Select--",
            label: false,
            classNames: {
              fieldContainer: "rjs-full",
              field: "rjs-col-1-3",
            }
          },
          "ui:widget": "radio",
        },
        expanded_access_record: {
          "ui:options": {
            title: "Expanded Access Record:",
            placeholder: "Enter value",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
        },
      },
      human_subjects_protection_review: {
        "ui:options": {
          label: true,
          classNames: {
            fieldContainer: "rjs-full"
          }
        },
        board_status: {
          "ui:options": {
            title: "Human Subjects Protection Review Board Status:",
            placeholder: "--Select--",
            errorMessage: "Review board status is a required field",
            label: false,
            classNames: {
              fieldContainer: "rjs-full",
              field: "rjs-col-1-3",
            }
          },
        },
        approvalnumber: {
          "ui:options": {
            title: "Approval Number:",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
        },
        board_name: {
          "ui:options": {
            title: "Board Name:",
            placeholder: "Enter name",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
        },
        board_affiliation: {
          "ui:options": {
            title: "Board Affiliation:",
            placeholder: "Enter affiliation",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
        },
        board_contact: {
          "ui:options": {
            label: false,
            classNames: {
              fieldContainer: "rjs-full"
            }
          },
          phone: {
            "ui:options": {
              title: "Phone:",
              placeholder: "Enter phone number",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
          },
          extension: {
            "ui:options": {
              title: "Ext:",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
          },
          email: {
            "ui:options": {
              title: "Email:",
              placeholder: "Enter Email Id",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
          },
          address: {
            "ui:options": {
              title: "Address:",
              placeholder: "Enter address",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-2-3"
              }
            },
            "ui:widget": "textarea"
          },
        },
      },
      data: {
        "ui:options": {
          label: false,
          classNames: {
            fieldContainer: "rjs-full"
          }
        },
        data_monitoring_committee: {
          "ui:options": {
            title: "Data Monitoring Committee:",
            placeholder: "--Select--",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
      },
      fda: {
        "ui:options": {
          label: false,
          classNames: {
            fieldContainer: "rjs-full"
          }
        },
        fda_regulated_intervention: {
          "ui:options": {
            title: "FDA Regulated Intervention:",
            placeholder: "--Select--",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        section_801_clinical_trial: {
          "ui:options": {
            title: "Section 801 Clinical Trial:",
            placeholder: "--Select--",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
      },
    },
    study_timeline: {
      "ui:options": {
        title: "Study Timelines and Status",
        classNames: {
          objectTitleContainer: "rjs-h1-container",
          objectTitle: "rjs-h1",
          objectContainer: "pt-24 pr-24 pl-24",
          fieldContainer: "m-0 rjs-full"
        }
      },
      record_verification_date: {
        "ui:options": {
          title: "Record Verification Date",
          label: false,
          classNames: {
            objectTitle: "rjs-h2",
            fieldContainer: "rjs-full"
          }
        },
        select_month: {
          "ui:options": {
            title: "Select Month",
            placeholder: "Select Month",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-4"
            }
          }
        },
        year: {
          "ui:options": {
            title: "Year",
            placeholder: "Enter Year",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-4"
            }
          }
        }
      },
      recruitment: {
        "ui:options": {
          label: false,
          classNames: {
            fieldContainer: "rjs-full"
          }
        },
        recruitment_status: {
          "ui:options": {
            title: "Overall Recruitment Status",
            placeholder: "Select Status",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          }
        },
        study_stopped: {
          "ui:options": {
            title: "Why Study Stopped?",
            placeholder: "Enter Reason",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          }
        },
      },
      milestone_dates: {
        "ui:options": {
          title: "Milestone Dates",
          classNames: {
            fieldContainer: "rjs-full",
            objectTitle: "rjs-h2"
          }
        },
        study_start_date: {
          "ui:options": {
            title: "Study Start Date",
            classNames: {
              fieldContainer: "rjs-full",
              objectTitle: "rjs-h3"
            }
          },
          date: {
            "ui:options": {
              label: false,
              hideComment: true,
              title: "Date",
              disabled: true,
              classNames: {
                objectTitle: "rjs-col-1-3"
              }
            },
            "ui:widget": "date"
          },
          anticipated_actual_date: {
            "ui:options": {
              label: false,
              title: "Anticipated/Actual",
              hideComment: true,
              disabled: true,
              classNames: {
                objectTitle: "rjs-col-1-3"
              }
            },
            "ui:widget": "radio"
          }
        },
        primary_completion_date: {
          "ui:options": {
            title: "Primary Completion Date",
            classNames: {
              objectTitle: "rjs-h3",
            }
          },
          date: {
            "ui:options": {
              label: false,
              disabled: true,
              title: "Date",
              hideComment: true,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
            "ui:widget": "date"
          },
          anticipated_actual_date: {
            "ui:options": {
              label: false,
              disabled: true,
              title: "Anticipated/Actual",
              hideComment: true,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
            "ui:widget": "radio"
          }
        },
        study_completion_date: {
          "ui:options": {
            title: "Study Completion Date/Global end of trial date",
            classNames: {
              objectTitle: "rjs-h3",
            }
          },
          date: {
            "ui:options": {
              label: false,
              disabled: true,
              title: "Date",
              hideComment: true,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
            "ui:widget": "date"
          },
          anticipated_actual_date: {
            "ui:options": {
              label: false,
              title: "Anticipated/Actual",
              disabled: true,
              hideComment: true,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
            "ui:widget": "radio"
          }
        },
        estimated_recruitment_start_date_eea: {
          "ui:options": {
            title: "Estimated recruitment start date in EEA",
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-col-1-3"
            }
          },
          date: {
            "ui:options": {
              label: false,
              title: "Date",
              hideComment: true,
            },
            "ui:widget": "date"
          },
        },
        estimated_global_end_of_trial_date: {
          "ui:options": {
            title: "Estimated global end of trial date",
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-col-1-3"
            }
          },
          date: {
            "ui:options": {
              label: false,
              title: "Date",
              hideComment: true,
              disabled: true,
            },
            "ui:widget": "date"
          },
        }
      },
      signed_funding_contract_date: {
        "ui:options": {
          title: "Date when funding contract was signed",
          label: false,
          classNames: {
            objectTitle: "rjs-h3",
            fieldContainer: "rjs-col-1-3"
          }
        },
        planned: {
          "ui:options": {
            title: "Planned",
            label: false,
          },
          "ui:widget": "date"
        },
        actual: {
          "ui:options": {
            title: "Actual",
            label: false,
          },
          "ui:widget": "date"
        }
      },
      data_collection_start_date: {
        "ui:options": {
          title: "Start date of data collection",
          classNames: {
            objectTitle: "rjs-h3",
            fieldContainer: "rjs-col-1-3"
          }
        },
        planned: {
          "ui:options": {
            title: "Planned",
            label: false,
          },
          "ui:widget": "date"
        },
        actual: {
          "ui:options": {
            title: "Actual",
            label: false,
          },
          "ui:widget": "date"
        }
      },
      data_analysis_start_date: {
        "ui:options": {
          title: "Start date of data analysis",
          classNames: {
            objectTitle: "rjs-h3",
            fieldContainer: "rjs-col-1-3"
          }
        },
        planned: {
          "ui:options": {
            title: "Planned",
            label: false,
          },
          "ui:widget": "date"
        },
        actual: {
          "ui:options": {
            title: "Actual",
            label: false,
          },
          "ui:widget": "date"
        }
      },
      interim_report_date: {
        "ui:options": {
          title: "Planned of interim report, if expected",
          classNames: {
            objectTitle: "rjs-h3",
            fieldContainer: "rjs-col-1-3"
          }
        },
        planned: {
          "ui:options": {
            title: "Planned",
            label: false,
          },
          "ui:widget": "date"
        },
        actual: {
          "ui:options": {
            title: "Actual",
            label: false,
          },
          "ui:widget": "date"
        }
      },
      final_study_report_date: {
        "ui:options": {
          title: "Planned of final study report",
          classNames: {
            objectTitle: "rjs-h3",
            fieldContainer: "rjs-col-1-3"
          }
        },
        planned: {
          "ui:options": {
            title: "Planned",
            label: false,
          },
          "ui:widget": "date"
        },
        actual: {
          "ui:options": {
            title: "Actual",
            label: false,
          },
          "ui:widget": "date"
        }
      },
      funding_source: {
        "ui:options": {
          title: "Sources of funding",
          label: false,
          classNames: {
            objectTitle: "rjs-h2",
            fieldContainer: "rjs-full"
          }
        },
        pharmaceutical_companies: {
          "ui:options": {
            title: "Pharmaceutical companies",
            label: false,
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-col-1-2",
            }
          },
          names: {
            "ui:options": {
              title: "Name(s)",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-2",
              }
            },
          },
          funding: {
            "ui:options": {
              title: "Approximate % funding",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-2",
              }
            },
          }
        },
        charities: {
          "ui:options": {
            title: "Charities",
            label: false,
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-col-1-2",
            }
          },
          names: {
            "ui:options": {
              title: "Name(s)",
              label: false
            },
          },
          funding: {
            "ui:options": {
              title: "Approximate % funding",
              label: false
            },
          }
        },
        government_body: {
          "ui:options": {
            title: "Government Body",
            label: false,
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-col-1-2",
            }
          },
          names: {
            "ui:options": {
              title: "Name(s)",
              label: false,
            },
          },
          funding: {
            "ui:options": {
              title: "Approximate % funding",
              label: false
            },
          }
        },
        research_councils: {
          "ui:options": {
            title: "Research council",
            label: false,
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-col-1-2",
            }
          },
          names: {
            "ui:options": {
              title: "Name(s)",
              label: false
            },
          },
          funding: {
            "ui:options": {
              title: "Approximate % funding",
              label: false
            },
          }
        },
        eu_funding_scheme: {
          "ui:options": {
            title: "EU funding scheme",
            label: false,
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-full",
              field: "rjs-col-1-2",
            }
          },
          names: {
            "ui:options": {
              title: "Name(s)",
              label: false
            },
          },
          funding: {
            "ui:options": {
              title: "Approximate % funding",
              label: false
            },
          }
        },
        other_funding_sources: {
          "ui:options": {
            title: "Others",
            addButtonTitle: "Add Funding Source",
            label: false,
            addable: true,
            removable: true,
            orderable: true,
            classNames: arrayClassNames.primary
          },
          items: {
            names: {
              "ui:options": {
                title: "Name(s)",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              },
            },
            funding: {
              "ui:options": {
                title: "Approximate % funding",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              },
            }
          }
        },
      },
      expanded: {
        "ui:options": {
          classNames: arrayClassNames.primary
        },
        expanded_status: {
          "ui:options": {
            title: "Expanded Access Status",
            placeholder: "Select Status",
            label: false,
            classNames: {
              objectTitle: "rjs-h3",
            }
          }
        },
      }
    },
    study_description: {
      "ui:order": [
        "brief_summary",
        "brief_summary_translations",
        "detailed_description",
        "secondary_objective",
        "secondary_objective_translations",
        "*"
      ],
      "ui:options": {
        title: "Study Description and Conditions",
        classNames: {
          fieldContainer: "rjs-full",
          objectContainer: "rjs-gap-16 p-24",
          objectTitleContainer: "rjs-h1-container",
          objectTitle: "rjs-h1",
        }
      },
      trial_scope: {
        "ui:options": {
          title: "Trial Scope",
          label: false,
          orientation: "vertical",
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
        "ui:widget": "Multiselect"
      },
      ctis_trial_phase: {
        "ui:options": {
          title: "Trial Phase",
          hideFork: true,
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
      },
      brief_summary: {
        "ui:options": {
          title: "Brief Summary",
          placeholder: "Enter Brief Summary",
          errorMessage: "Please specify all required information.",
          label: false,
          classNames: {
            fieldContainer: "rjs-full"
          }
        },
        "ui:widget": "textarea"
      },
      brief_summary_translations: {
        "ui:options": {
          title: "Main Objective Languages",
          itemTitle: "Translation",
          addButtonTitle: "Add Translation",
          label: false,
          addable: true,
          removable: true,
          orderable: true,
          classNames: arrayClassNames.primary
        },
        items: {
          language: {
            "ui:options": {
              title: "Language",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          translation: {
            "ui:options": {
              title: "Translation",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-2-3"
              }
            },
            "ui:widget": "textarea"
          },
        },
      },
      detailed_description: {
        "ui:options": {
          title: "Detailed Description",
          placeholder: "Enter Detailed Description",
          errorMessage: "Please specify all required information.",
          label: false,
          classNames: {
            fieldContainer: "rjs-full"
          }
        },
        "ui:widget": "textarea"
      },
      secondary_objective: {
        "ui:options": {
          title: "Secondary objective (English)",
          placeholder: "Enter secondary objective",
          errorMessage: "Please specify all required information.",
          label: false,
          classNames: {
            fieldContainer: "rjs-full"
          }
        },
        "ui:widget": "textarea"
      },
      secondary_objective_translations: {
        "ui:options": {
          title: "Secondary Objectives Translation",
          itemTitle: "Translation",
          addButtonTitle: "Add Translation",
          label: false,
          addable: true,
          removable: true,
          orderable: true,
          classNames: arrayClassNames.primary
        },
        items: {
          language: {
            "ui:options": {
              title: "Language",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          translation: {
            "ui:options": {
              title: "Translation",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-2-3"
              }
            },
            "ui:widget": "textarea"
          },
        },
      },
      study_conditions: {
        "ui:options": {
          title: "Conditions",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-2"
          }
        },
        "ui:widget": "Multiselect"
      },
      study_keywords: {
        "ui:options": {
          title: "Keywords",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-2"
          }
        },
        "ui:widget": "SelectTag"
      },
      has_medical_condition: {
        "ui:options": {
          title: "Has Medical Condition?",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-2"
          }
        },
        "ui:widget": "radio"
      },
      low_intervention_trial: {
        "ui:options": {
          title: "Low intervention trial",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
        "ui:widget": "SingleCheckbox",
      },
      justification_for_low_intervention_trial: {
        "ui:options": {
          title: "Justification for the low intervention trial* Attachment of justification of low interventional clinical trial",
          label: false,
          classNames: {
            fieldContainer: "rjs-full"
          }
        },
        "ui:widget": "textarea"
      },
      medical_conditions: {
        "ui:options": {
          title: "Medical Conditions",
          addButtonTitle: "Add Condition",
          label: false,
          addable: true,
          removable: true,
          orderable: true,
          classNames: arrayClassNames.primary
        },
        items: {
          condition: {
            "ui:options": {
              title: "Medical condition(s) (English)",
              label: false,
              classNames: {
                fieldContainer: "rjs-full"
              }
            },
            "ui:widget": "textarea"
          },
          translations: {
            "ui:options": {
              title: "Translations",
              itemTitle: "Translation",
              addButtonTitle: "Add Translation",
              label: false,
              addable: true,
              removable: true,
              orderable: true,
              classNames: arrayClassNames.secondary
            },
            items: {
              language: {
                "ui:options": {
                  title: "Language",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              translation: {
                "ui:options": {
                  title: "Translation",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-full"
                  }
                },
                "ui:widget": "textarea"
              },
            },
          },
          is_medical_condition_rare: {
            "ui:options": {
              title: "Is the medical condition considered to be a rare disease?",
              label: false,
              classNames: {
                fieldContainer: "rjs-full",
              }
            },
            "ui:widget": "SingleCheckbox"
          },
        },
      },
      additional_medical_conditions: {
        "ui:options": {
          title: "Additional Medical Condition(s):",
          label: false,
          classNames: {
            fieldContainer: "rjs-full"
          }
        },
        "ui:widget": "textarea"
      },
      therapeutic_area: {
        "ui:options": {
          title: "Therapeutic area",
          label: false,
          orientation: "vertical",
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
        "ui:widget": "Multiselect"
      },
      meddra: {
        "ui:options": {
          title: "MedDRA",
          // itemTitle: "Translation",
          addButtonTitle: "Add MedDRA",
          label: false,
          addable: true,
          removable: true,
          orderable: true,
          classNames: arrayClassNames.primary
        },
        items: {
          term_name: {
            "ui:options": {
              title: "Term Name",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          level: {
            "ui:options": {
              title: "Level",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
          },
          classification_code: {
            "ui:options": {
              title: "Classification Code",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
          },
        },
      },
      principle_inclusion_criteria: {
        "ui:options": {
          title: "Principal inclusion criteria",
          addButtonTitle: "Add inclusion criteria",
          label: false,
          addable: true,
          removable: true,
          orderable: true,
          classNames: arrayClassNames.primary
        },
        items: {
          inclusion_criteria: {
            "ui:options": {
              title: "Inclusion criteria (English)",
              label: false,
              classNames: {
                fieldContainer: "rjs-full"
              }
            }
          },
          translations: {
            "ui:options": {
              title: "Translations",
              itemTitle: "Translation",
              addButtonTitle: "Add Translation",
              arrayItemTitle: "Translation",
              showIndex: true,
              label: false,
              addable: true,
              removable: true,
              orderable: true,
              classNames: arrayClassNames.secondary
            },
            items: {
              language: {
                "ui:options": {
                  title: "Language",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              translation: {
                "ui:options": {
                  title: "Translation",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-col-2-3"
                  }
                },
                "ui:widget": "textarea"
              },
            },
          },
        },
      },
      principle_exlusion_criteria: {
        "ui:options": {
          title: "Principle exclusion criteria",
          itemTitle: "Translation",
          addButtonTitle: "Add exclusion criteria",
          label: false,
          addable: true,
          removable: true,
          orderable: true,
          classNames: arrayClassNames.primary
        },
        items: {
          exclusion_criteria: {
            "ui:options": {
              title: "Exclusion criteria (English)",
              label: false,
              classNames: {
                fieldContainer: "rjs-full"
              }
            }
          },
          translations: {
            "ui:options": {
              title: "Translations",
              itemTitle: "Translation",
              addButtonTitle: "Add Translation",
              arrayItemTitle: "Translation",
              showIndex: true,
              label: false,
              addable: true,
              removable: true,
              orderable: true,
              classNames: arrayClassNames.secondary
            },
            items: {
              language: {
                "ui:options": {
                  title: "Language",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              translation: {
                "ui:options": {
                  title: "Translation",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-col-2-3"
                  }
                },
                "ui:widget": "textarea"
              },

            },
          },
        },
      },
      protocol_information: {
        "ui:options": {
          title: "Protocol Information",
          classNames: {
            objectTitle: "rjs-h2",
            fieldContainer: "rjs-full"
          }
        },
        periods: {
          "ui:options": {
            title: "Periods",
            itemTitle: "Period",
            addButtonTitle: "Add Period",
            label: false,
            addable: true,
            removable: true,
            orderable: true,
            classNames: arrayClassNames.secondary
          },
          items: {
            title: {
              "ui:options": {
                title: "Title",
                placeholder: "",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            description: {
              "ui:options": {
                title: "Description",
                placeholder: "",
                label: false,
                classNames: {
                  fieldContainer: "rjs-full"
                }
              },
              "ui:widget": "textarea"
            },
            allocation_method: {
              "ui:options": {
                title: "Allocation method",
                placeholder: "",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            binding_used: {
              "ui:options": {
                title: "Blinding used",
                placeholder: "",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            roles_blinded: {
              "ui:options": {
                title: "Roles blinded",
                placeholder: "",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              },
              "ui:widget": "Multiselect"
            },
            blinding_implementation_details: {
              "ui:options": {
                title: "Blinding implementation details",
                placeholder: "",
                label: false,
                classNames: {
                  fieldContainer: "rjs-full"
                }
              },
              "ui:widget": "textarea"
            },
            arms: {
              "ui:options": {
                title: "Arms",
                itemTitle: "Arm",
                addButtonTitle: "Add Arm",
                label: false,
                arrayItemTitle: "Arm",
                addable: true,
                removable: true,
                orderable: true,
                showIndex: true,
                classNames: arrayClassNames.primary
              },
              items: {
                title: {
                  "ui:options": {
                    label: false,
                    title: "Title",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  },
                },
                description: {
                  "ui:options": {
                    label: false,
                    title: "Description",
                    classNames: {
                      fieldContainer: "rjs-full"
                    }
                  },
                  "ui:widget": "textarea"
                }
              }
            }
          }
        },
      },
      scientific_advice_and_pip: {
        "ui:options": {
          title: "Scientific advice and Paediatric Investigation Plan (PIP)",
          classNames: {
            objectTitle: "rjs-h2",
            fieldContainer: "rjs-full"
          }
        },
        "ui:order": [
          "investigator_contact_details",
          "scientific_advice",
          "*"
        ],
        scientific_advice: {
          "ui:options": {
            title: "Scientific advice",
            addButtonTitle: "Add competent authority",
            label: false,
            addable: true,
            removable: true,
            orderable: true,
            classNames: arrayClassNames.secondary
          },
          items: {
            name: {
              "ui:options": {
                title: "Name",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            id: {
              "ui:options": {
                title: "ID",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            city: {
              "ui:options": {
                title: "City",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            country: {
              "ui:options": {
                title: "Country",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            address: {
              "ui:options": {
                title: "Address",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            postcode: {
              "ui:options": {
                title: "Postcode",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            phone: {
              "ui:options": {
                title: "Phone",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            email: {
              "ui:options": {
                title: "E-mail",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
          },
        },
        paediatric_investigation_plan: {
          "ui:options": {
            title: "Paediatric investigation plan",
            addButtonTitle: "Add PIP",
            label: false,
            addable: true,
            removable: true,
            orderable: true,
            classNames: arrayClassNames.primary
          },
          items: {
            ema_paediatric_investigation_number: {
              "ui:options": {
                title: "EMA paediatric investigation number",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
          },
        },
      },
      associated_clinical_trials: {
        "ui:options": {
          title: "Associated clinical trials",
          classNames: {
            objectTitle: "rjs-h2",
            fieldContainer: "rjs-full"
          }
        },
        associated_clinical_trials: {
          "ui:options": {
            title: "Associated clinical trials",
            itemTitle: "Identifier",
            addButtonTitle: "Associate clinical trial",
            label: false,
            addable: true,
            removable: true,
            orderable: true,
            classNames: arrayClassNames.secondary
          },
          items: {
            identifier: {
              "ui:options": {
                title: "Identifier",
                label: false,
                hideFork: true,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            full_title: {
              "ui:options": {
                title: "Full title",
                label: false,
                hideFork: true,
                classNames: {
                  fieldContainer: "rjs-full"
                }
              },
              "ui:widget": "textarea"
            },
          },
        },
      },
      sponsors: {
        "ui:options": {
          title: "Sponsors",
          classNames: {
            objectTitleContainer: "rjs-h1-container",
            objectTitle: "rjs-h2",
          }
        },
        sponsors: {
          "ui:options": {
            // title: "Sponsors",
            itemTitle: "Sponsor",
            addButtonTitle: "Add Sponsor",
            label: false,
            addable: true,
            removable: true,
            orderable: true,
            classNames: arrayClassNames.primary
          },
          items: {
            "ui:order": [
              "sponsor",
              "add_legal_representative",
              "legal_representative",
              "add_scientific_contact_point",
              "scientific_contact_point",
              "add_public_contact_point",
              "public_contact_point",
              "*"
            ],
            sponsor: {
              "ui:options": {
                title: "Sponsor",
                classNames: {
                  objectTitle: "rjs-h3"
                }
              },
              organisation_name: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Name",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              status: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Status",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              address: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Address",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              city: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Town/City",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              zip_code: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Post code",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              country: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Country",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              email: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Email",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              phone: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Phone",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
            },
            add_legal_representative: {
              "ui:options": {
                title: "Add legal representative",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              },
              "ui:widget": "SingleCheckbox"
            },
            legal_representative: {
              "ui:options": {
                title: "Legal representative",
                classNames: {
                  objectTitle: "rjs-h3"
                }
              },
              organisation_name: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Name",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              status: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Status",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              address: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "address",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              city: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Town/City",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              zip_code: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Post code",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              country: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Country",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              email: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Email",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              phone: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Phone",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              contact: {
                "ui:options": {
                  title: "Contact",
                  classNames: {
                    objectTitle: "rjs-h3"
                  }
                },
                first_name: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "First Name",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                last_name: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Last Name",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                email: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Email address",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                phone: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Phone number",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
              }
            },
            add_scientific_contact_point: {
              "ui:options": {
                title: "Add scientific contact point",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              },
              "ui:widget": "SingleCheckbox"
            },
            scientific_contact_point: {
              "ui:options": {
                title: "Scientific contact point",
                classNames: {
                  objectTitle: "rjs-h3"
                }
              },
              organisation_name: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Name",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              functional_contact_point_name: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Status",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              email: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Email",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              phone: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Phone",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
            },
            add_public_contact_point: {
              "ui:options": {
                title: "Add public contact point",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              },
              "ui:widget": "SingleCheckbox"
            },
            public_contact_point: {
              "ui:options": {
                title: "Public contact point",
                classNames: {
                  objectTitle: "rjs-h3"
                }
              },
              organisation_name: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Name",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              functional_contact_point_name: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Status",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              email: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Email",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              phone: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Phone",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
            },
            third_party: {
              "ui:options": {
                title: "Third party",
                itemTitle: "Sponsor",
                addButtonTitle: "Add third party",
                label: false,
                addable: true,
                removable: true,
                orderable: true,
                classNames: arrayClassNames.secondary
              },
              items: {
                organisation_name: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Name",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                country: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Country",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                address: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Address",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                city: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Town/City",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                zip_code: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Post code",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                phone: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Phone",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                email: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Email",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                duties: {
                  "ui:options": {
                    title: "Duties",
                    label: false,
                    orientation: "vertical",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  },
                  "ui:widget": "Multiselect"
                },
                other_duties: {
                  "ui:options": {
                    title: "Please explain if other duties: ",
                    label: false,
                    orientation: "vertical",
                    classNames: {
                      fieldContainer: "rjs-full"
                    }
                  },
                  "ui:widget": "textarea"
                }
              }
            }
          },
        },
        contact_point_for_union: {
          "ui:options": {
            title: "Contact point for union",
            classNames: {
              objectTitle: "rjs-h2",
            }
          },
          organisation_name: {
            "ui:options": {
              label: false,
              required: true,
              title: "Name",
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          address_1: {
            "ui:options": {
              label: false,
              required: true,
              title: "Address 1",
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          address_2: {
            "ui:options": {
              label: false,
              required: true,
              title: "Address 2",
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          address_3: {
            "ui:options": {
              label: false,
              required: true,
              title: "Address 3",
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          address_4: {
            "ui:options": {
              label: false,
              required: true,
              title: "Address 4",
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          city: {
            "ui:options": {
              label: false,
              required: true,
              title: "Town/City",
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          zip_code: {
            "ui:options": {
              label: false,
              required: true,
              title: "Post code",
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          country: {
            "ui:options": {
              label: false,
              required: true,
              title: "Country",
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          functional_contact_point_name: {
            "ui:options": {
              label: false,
              required: true,
              title: "Functional contact point name",
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          contact: {
            "ui:options": {
              title: "Contact",
              classNames: {
                objectTitle: "rjs-h2",
              }
            },
            first_name: {
              "ui:options": {
                label: false,
                required: true,
                title: "First name",
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            last_name: {
              "ui:options": {
                label: false,
                required: true,
                title: "Last name",
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            phone: {
              "ui:options": {
                label: false,
                required: true,
                title: "Phone",
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            email: {
              "ui:options": {
                label: false,
                required: true,
                title: "email",
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
          }
        }
      },
      products: {
        "ui:options": {
          classNames: {
            fieldContainer: "rjs-full"
          }
        },
        products: {
          "ui:options": {
            title: "Products",
            arrayItemTitle: "Role",
            addButtonTitle: "Add Role",
            showIndex: true,
            label: false,
            addable: true,
            removable: true,
            orderable: true,
            classNames: arrayClassNames.primary
          },
          items: {
            "ui:order": [
              "role",
              "description",
              "authorisation_number_manufacturing_and_import",
              "quality_justification_for_no_impd_upload",
              "safety_and_efficacy_justification_for_no_impd_upload",
              "reason_for_no_ampd_upload",
              "products",
              "*"
            ],
            role: {
              "ui:options": {
                title: "Role",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            products: {
              "ui:options": {
                title: "Products",
                addButtonTitle: "Add Product",
                arrayItemTitle: "Product",
                showIndex: true,
                label: false,
                addable: true,
                removable: true,
                orderable: true,
                classNames: arrayClassNames.primary
              },
              items: {
                product: {
                  "ui:options": {
                    title: "Product",
                    label: false,
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                eu_mp_number: {
                  "ui:options": {
                    title: "EU MP Number",
                    label: false,
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                pharmaceutical_form: {
                  "ui:options": {
                    title: "Pharmaceutical form",
                    label: false,
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                marketing_authorisation_number: {
                  "ui:options": {
                    title: "Marketing authorisation number",
                    label: false,
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                strength: {
                  "ui:options": {
                    title: "Strength",
                    label: false,
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                name_of_product: {
                  "ui:options": {
                    title: "Name of product",
                    label: false,
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                active_substance_name: {
                  "ui:options": {
                    title: "Active substance name",
                    label: false,
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                eu_substance_number: {
                  "ui:options": {
                    title: "EU substance number",
                    label: false,
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                atc_code: {
                  "ui:options": {
                    title: "ATC Code",
                    label: false,
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                atc_name: {
                  "ui:options": {
                    title: "ATC name",
                    label: false,
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                name: {
                  "ui:options": {
                    title: "Name",
                    label: false,
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                excluded_msc: {
                  "ui:options": {
                    title: "Excluded MSC",
                    label: false,
                    hideFork: true,
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  },
                  "ui:widget": "Multiselect"
                },
                product_properties: {
                  "ui:options": {
                    title: "Details for product",
                    classNames: {
                      objectTitle: "rjs-h2",
                    }
                  },
                  medicinal_product_details: {
                    "ui:options": {
                      title: "Medicinal product details",
                      classNames: {
                        fieldContainer: "rjs-full",
                        objectTitle: "rjs-h3",
                      }
                    },
                    is_specific_paediatric_formulation: {
                      "ui:options": {
                        title: "Is this a specific paediatric formulation?",
                        label: false,
                        required: true,
                        classNames: {
                          fieldContainer: "rjs-col-1-3"
                        }
                      }
                    },
                    sponsors_product_code: {
                      "ui:options": {
                        title: "Sponsor's product code",
                        label: false,
                        classNames: {
                          fieldContainer: "rjs-col-1-3"
                        }
                      }
                    }
                  },
                  product_characteristics: {
                    "ui:options": {
                      title: "Product characteristics",
                      classNames: {
                        objectTitle: "rjs-h3",
                        fieldContainer: "rjs-full"
                      }
                    },
                    medicinal_product_characteristics: {
                      "ui:options": {
                        title: "Medicinal product characteristics",
                        label: false,
                        classNames: {
                          fieldContainer: "rjs-col-1-3"
                        }
                      }
                    },
                    other_medical_product: {
                      "ui:options": {
                        title: "Other medicinal product",
                        label: false,
                        classNames: {
                          fieldContainer: "rjs-col-1-3"
                        }
                      }
                    }
                  },
                  dosage_and_administration_details: {
                    "ui:options": {
                      title: "Dosage and administration details",
                      classNames: {
                        objectTitle: "rjs-h3",
                      }
                    },
                    route_of_administration: {
                      "ui:options": {
                        title: "Route of administration",
                        required: true,
                        label: false,
                        classNames: {
                          fieldContainer: "rjs-col-1-3"
                        }
                      }
                    },
                    maximum_duration_of_treatmeant: {
                      "ui:options": {
                        title: "Maximum duration of treatment",
                        classNames: {
                          objectTitle: "rjs-h3",
                          fieldContainer: "rjs-full"
                        }
                      },
                      value: {
                        "ui:options": {
                          title: "Duration",
                          required: true,
                          label: false,
                          classNames: {
                            fieldContainer: "rjs-col-1-3"
                          }
                        }
                      },
                      duration: {
                        "ui:options": {
                          title: "Duration type",
                          required: true,
                          label: false,
                          classNames: {
                            fieldContainer: "rjs-col-1-3"
                          }
                        }
                      },
                    },
                    maximum_daily_dose_allowed: {
                      "ui:options": {
                        title: "Maximum daily dose allowed",
                        required: true,
                        label: false,
                        classNames: {
                          fieldContainer: "rjs-col-1-3"
                        }
                      }
                    },
                    daily_dose_unit_of_measure: {
                      "ui:options": {
                        title: "Daily dose unit of measure",
                        required: true,
                        label: false,
                        classNames: {
                          fieldContainer: "rjs-col-1-3"
                        }
                      }
                    },
                    maximum_total_dose_allowed: {
                      "ui:options": {
                        title: "Maximum total dose allowed",
                        required: true,
                        label: false,
                        classNames: {
                          fieldContainer: "rjs-col-1-3"
                        }
                      }
                    },
                    total_dose_use_of_measure: {
                      "ui:options": {
                        title: "Total dose unit of measure",
                        required: true,
                        label: false,
                        classNames: {
                          fieldContainer: "rjs-col-1-3"
                        }
                      }
                    }
                  },
                  information_about_modification_of_medicinal_product: {
                    "ui:options": {
                      title: "Information about the modification of the Medicinal Product ",
                      classNames: {
                        objectTitle: "rjs-h3",
                        fieldContainer: "rjs-full"
                      }
                    },
                    is_product_modified_in_relation_to_marketing_authorisation: {
                      "ui:options": {
                        title: "Has the medicinal product been modified in relation to its Marketing Authorisation?",
                        required: true,
                        label: false,
                        classNames: {
                          fieldContainer: "rjs-col-1-3"
                        }
                      }
                    },
                    description_of_modification: {
                      "ui:options": {
                        title: "Description of the modification",
                        required: true,
                        label: false,
                        classNames: {
                          fieldContainer: "rjs-col-1-3"
                        }
                      }
                    }
                  },
                  orphan_designation: {
                    "ui:options": {
                      title: "Orphan Designation",
                      classNames: {
                        objectTitle: "rjs-h3",
                        fieldContainer: "rjs-full"
                      }
                    },
                    product_have_orphan_drug_description: {
                      "ui:options": {
                        title: "Does this product have an orphan drug designation",
                        label: false,
                        classNames: {
                          fieldContainer: "rjs-col-1-3"
                        }
                      }
                    },
                    designation_number_for_orphan_drug: {
                      "ui:options": {
                        title: "Designation number for orphan drug",
                        required: true,
                        label: false,
                        classNames: {
                          fieldContainer: "rjs-col-1-3"
                        }
                      }
                    }
                  },
                  active_substance: {
                    "ui:options": {
                      title: "Active substance",
                      classNames: {
                        objectTitle: "rjs-h3",
                        fieldContainer: "rjs-full"
                      }
                    },
                    active_substance_other_descriptive_name: {
                      "ui:options": {
                        title: "Active substance other descriptive name",
                        label: false,
                        classNames: {
                          fieldContainer: "rjs-col-1-3"
                        }
                      }
                    },
                  },
                  advanced_therapy_medicinal_product: {
                    "ui:options": {
                      title: "Advanced Therapy Medicinal Product",
                      classNames: {
                        objectTitle: "rjs-h3",
                        fieldContainer: "rjs-full"
                      }
                    },
                    advanced_therapy: {
                      "ui:options": {
                        title: "Advanced Therapy",
                        addButtonTitle: "Add Advanced Therapy",
                        arrayItemTitle: "Advanced Therapy",
                        showIndex: true,
                        label: false,
                        addable: true,
                        removable: true,
                        orderable: true,
                        classNames: arrayClassNames.primary
                      },
                      items: {
                        advanced_therapy_classification: {
                          "ui:options": {
                            title: "Advanced therapy classification",
                            label: false,
                            classNames: {
                              fieldContainer: "rjs-col-1-3"
                            }
                          }
                        },
                        cat_reference_number: {
                          "ui:options": {
                            title: "CAT reference number",
                            label: false,
                            classNames: {
                              fieldContainer: "rjs-col-1-3"
                            }
                          }
                        },
                        somatic_cell_origin: {
                          "ui:options": {
                            title: "Somatic cell origin",
                            label: false,
                            classNames: {
                              fieldContainer: "rjs-col-1-3"
                            }
                          }
                        },
                        somatic_cell_type: {
                          "ui:options": {
                            title: "Somatic cell type",
                            label: false,
                            classNames: {
                              fieldContainer: "rjs-col-1-3"
                            }
                          }
                        },
                        species_origin_xenogeneic_cell: {
                          "ui:options": {
                            title: "Species origin for the xenogeneic cell",
                            label: false,
                            required: true,
                            classNames: {
                              fieldContainer: "rjs-col-1-3"
                            }
                          }
                        },
                        specify_type_differentiated_cells: {
                          "ui:options": {
                            title: "Specify type of differentiated cells",
                            label: false,
                            required: true,
                            classNames: {
                              fieldContainer: "rjs-col-1-3"
                            }
                          }
                        },
                        tissue_engineered_cell_type: {
                          "ui:options": {
                            title: "Tissue engineered cell type",
                            label: false,
                            classNames: {
                              fieldContainer: "rjs-col-1-3"
                            }
                          }
                        },
                        origin_of_engineered_tissue: {
                          "ui:options": {
                            title: "Origin of the engineered tissue",
                            label: false,
                            classNames: {
                              fieldContainer: "rjs-col-1-3"
                            }
                          }
                        },
                        cell_specification: {
                          "ui:options": {
                            title: "Cell specification",
                            label: false,
                            required: true,
                            classNames: {
                              fieldContainer: "rjs-col-1-3"
                            }
                          }
                        },
                        tissue_engineered_xenogeneic_species_origin: {
                          "ui:options": {
                            title: "Tissue Engineered xenogeneic species of origin",
                            label: false,
                            required: true,
                            classNames: {
                              fieldContainer: "rjs-col-1-3"
                            }
                          }
                        },
                        gene_interest: {
                          "ui:options": {
                            title: "Gene of interest",
                            label: false,
                            classNames: {
                              fieldContainer: "rjs-col-1-3"
                            }
                          }
                        },
                        gene_therapy_type: {
                          "ui:options": {
                            title: "Type of gene transfer product",
                            label: false,
                            classNames: {
                              fieldContainer: "rjs-col-1-3"
                            }
                          }
                        },
                        type_of_gene_transfer_product: {
                          "ui:options": {
                            title: "Gene therapy type",
                            label: false,
                            required: true,
                            classNames: {
                              fieldContainer: "rjs-col-1-3"
                            }
                          }
                        },
                        genetically_modified_cell_present: {
                          "ui:options": {
                            title: "Genetically modified cells present?",
                            label: false,
                            classNames: {
                              fieldContainer: "rjs-col-1-3"
                            }
                          }
                        },
                        additional_description: {
                          "ui:options": {
                            title: "Additional description",
                            label: false,
                            required: true,
                            classNames: {
                              fieldContainer: "rjs-col-1-3"
                            }
                          }
                        },
                        specify_type_of_cells: {
                          "ui:options": {
                            title: "Specify type of cells",
                            label: false,
                            classNames: {
                              fieldContainer: "rjs-col-1-3"
                            }
                          }
                        },
                        origin_of_genetically_modiefied_cells: {
                          "ui:options": {
                            title: "Origin of the genetically modified cells",
                            label: false,
                            required: true,
                            classNames: {
                              fieldContainer: "rjs-col-1-3"
                            }
                          }
                        }
                      }
                    }
                  },
                  device_associated_with_medicinal_product: {
                    "ui:options": {
                      title: "Device associated with medicinal product",
                      classNames: {
                        objectTitle: "rjs-h3",
                        fieldContainer: "rjs-full"
                      }
                    },
                    device: {
                      "ui:options": {
                        title: "Device",
                        addButtonTitle: "Add Device",
                        arrayItemTitle: "Device",
                        showIndex: true,
                        label: false,
                        addable: true,
                        removable: true,
                        orderable: true,
                        classNames: arrayClassNames.primary
                      },
                      items: {
                        product_used_in_combination_with_device: {
                          "ui:options": {
                            title: "Product used in combination with a device",
                            label: false,
                            classNames: {
                              fieldContainer: "rjs-col-1-3"
                            }
                          }
                        },
                        device_type: {
                          "ui:options": {
                            title: "Type of device",
                            label: false,
                            classNames: {
                              fieldContainer: "rjs-col-1-3"
                            }
                          }
                        },
                        device_has_ce_mark: {
                          "ui:options": {
                            title: "Device has CE mark",
                            label: false,
                            classNames: {
                              fieldContainer: "rjs-col-1-3"
                            }
                          },
                          "ui:widget": "SingleCheckbox"
                        },
                        device_trade_name: {
                          "ui:options": {
                            title: "Device Trade Name",
                            label: false,
                            classNames: {
                              fieldContainer: "rjs-full"
                            }
                          },
                          "ui:widget": "textarea"
                        },
                        device_notified_body: {
                          "ui:options": {
                            title: "Device notified body",
                            label: false,
                            classNames: {
                              fieldContainer: "rjs-full"
                            }
                          },
                          "ui:widget": "textarea"
                        },
                        description_of_device: {
                          "ui:options": {
                            title: "Description of the device",
                            label: false,
                            classNames: {
                              fieldContainer: "rjs-full"
                            }
                          },
                          "ui:widget": "textarea"
                        }
                      }
                    }
                  },
                }
              }
            },
            description: {
              "ui:options": {
                title: "Description of the Placebo:*",
                label: false,
                classNames: {
                  fieldContainer: "rjs-full"
                }
              },
              "ui:widget": "textarea"
            },
            authorisation_number_manufacturing_and_import: {
              "ui:options": {
                title: "Authorisation number of manufacturing and import",
                label: false,
                classNames: {
                  fieldContainer: "rjs-full"
                }
              },
              "ui:widget": "textarea"
            },
            quality_justification_for_no_impd_upload: {
              "ui:options": {
                title: "IMPD - Quality: Justification for no IMPD upload",
                label: false,
                classNames: {
                  fieldContainer: "rjs-full"
                }
              },
              "ui:widget": "textarea"
            },
            safety_and_efficacy_justification_for_no_impd_upload: {
              "ui:options": {
                title: "IMPD - Safety and Efficacy: Justification for no IMPD upload",
                label: false,
                classNames: {
                  fieldContainer: "rjs-full"
                }
              },
              "ui:widget": "textarea"
            },
            reason_for_no_ampd_upload: {
              "ui:options": {
                title: "Reason for no AMPD upload",
                label: false,
                classNames: {
                  fieldContainer: "rjs-full"
                }
              },
              "ui:widget": "textarea"
            }
          }
        },
        arms: {
          "ui:options": {
            label: false,
            hide: true,
            classNames: {
              objectTitle: "rjs-h3",
            }
          },
          "ui:widget": "SimpleTable"
        },
      },
    },
    study_scope: {
      "ui:order": ["study_phase", "patient_registry", "primary_purpose", "patient", "follow_up_duration", "duration_type", "additional_patient_registry_data", "*"],
      "ui:options": {
        title: "Study Scope and Design",
        classNames: {
          objectTitleContainer: "rjs-h1-container",
          objectTitle: "rjs-h1",
          objectContainer: "p-24",
          fieldContainer: "rjs-full"
        }
      },
      patient_registry: {
        "ui:options": {
          title: "Patient Registry",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
        "ui:widget": "CustomCheckboxGroup",
      },
      primary_purpose: {
        "ui:options": {
          title: "Primary Purpose",
          placeholder: "Select Purpose",
          errorMessage: "Primary Purpose has not been entered",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        }
      },
      study_phase: {
        "ui:options": {
          title: "Study Phase",
          placeholder: "Select Study Phase",
          errorMessage: "Phase cannot be N/A for a study of a U.S. FDA-regulated drug",
          tooltip: "Use N/A for trials that do not involve drug or biologic products",
          label: false,
          orientation: "vertical",
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
        "ui:widget": "radio",
      },
      interventional_study_model: {
        "ui:options": {
          title: "Interventional Study Model",
          placeholder: "Select Interventional Study Model",
          errorMessage: "Interventional Study Model has not been entered",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        }
      },
      model_description: {
        "ui:options": {
          title: "Model Description",
          placeholder: "Enter Study Model description",
          label: false,
          classNames: {
            fieldContainer: "rjs-full"
          }
        },
        "ui:widget": "textarea"
      },
      number_of_arms: {
        "ui:options": {
          title: "Number of Arms/Groups/Cohorts in the study",
          placeholder: "Enter Number of Arms",
          errorMessage: "Number of Arms has not been entered",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        }
      },
      masking: {
        "ui:options": {
          title: "Masking",
          tooltip: "Check all roles that are masked or check None",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
        "ui:widget": "CustomCheckboxGroup",
      },
      masking_description: {
        "ui:options": {
          title: "Masking Description",
          placeholder: "Enter Masking description",
          label: false,
          classNames: {
            fieldContainer: "rjs-full"
          }
        },
        "ui:widget": "textarea"
      },
      allocation: {
        "ui:options": {
          title: "Allocation",
          tooltip: "Select N/A for single-arm studies",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-2"
          }
        },
        "ui:widget": "radio",
      },
      enrollment_type: {
        "ui:options": {
          title: "Enrollment Type",
          placeholder: "Select Participant Type",
          errorMessage: "Enrollment Type has not been entered",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-2"
          }
        },
        "ui:widget": "radio"
      },
      enrollment_type_obs: {
        "ui:options": {
          title: "Enrollment Type",
          placeholder: "Select Participant Type",
          errorMessage: "Enrollment Type has not been entered",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-2"
          }
        },
        "ui:widget": "radio"
      },
      patient: {
        "ui:options": {
          title: "Patient",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
        "ui:widget": "CustomCheckboxGroup",
      },
      follow_up_duration: {
        "ui:options": {
          title: "Target Follow Up Duration",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
      },
      duration_type: {
        "ui:options": {
          title: "Duration",
          placeholder: "--Select--",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
      },
      additional_patient_registry_data: {
        "ui:options": {
          title: "Additional Patient Registry Data",
          placeholder: "[The AHRQ Registry of Patient Registries (RoPR) has been discontinued.]",
          label: false,
          classNames: {
            fieldContainer: "rjs-full"
          }
        },
        "ui:widget": "textarea"
      },
      observational_study_model: {
        "ui:options": {
          title: "Observational Study Model",
          placeholder: "Select Observational Study Model",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
      },
      time_perspective: {
        "ui:options": {
          title: "Time Perspective",
          placeholder: "Select Time Perspective",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
      },
      biospecimen: {
        "ui:options": {
          classNames: arrayClassNames.primary
        },
        biospeciment_retention: {
          "ui:options": {
            title: "Biospecimen Retention",
            placeholder: "Select Biospecimen Retention",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
        },
        biospecimen_description: {
          "ui:options": {
            title: "Biospecimen Description",
            placeholder: "Enter Description",
            label: false,
            classNames: {
              fieldContainer: "rjs-full"
            }
          },
          "ui:widget": "textarea"
        },
      },
      enrollment_number: {
        "ui:options": {
          title: "Enrollment Number/Number of Subjects",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
      },
      number_of_cohorts: {
        "ui:options": {
          title: "Number of Cohorts in the study",
          placeholder: "Enter Number of Cohorts",
          errorMessage: "Number of Cohorts has not been entered",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        }
      },
      selection: {
        "ui:options": {
          title: "Expanded Access Type",
          tooltip: "Check all that apply",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
        "ui:widget": "CustomCheckboxGroup",
      },
      scope_of_study: {
        "ui:options": {
          title: "What is the scope of the study?",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
        "ui:widget": "CustomCheckboxGroup",
      },
      primary_scope: {
        "ui:options": {
          title: "Primary Scope",
          label: false,
          classNames: {
            fieldContainer: "rjs-full"
          }
        },
        "ui:widget": "radio",
      },
      other_scope_description: {
        "ui:options": {
          title: "Please describe other (If applicable)",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-2-3"
          }
        },
      },
      design_of_study: {
        "ui:options": {
          title: "What is the design of the study?",
          label: false,
          orientation: "vertical",
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
        "ui:widget": "Multiselect",
      },
      other_design_description: {
        "ui:options": {
          title: "Please describe other (If applicable)",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-2-3"
          }
        },
      },
      countries_outside_eea: {
        "ui:options": {
          title: "Countries outside the European Economic Area",
          label: false,
          hideFork: true,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
        "ui:widget": "Multiselect",
      },
    },
    study_arms: {
      "ui:options": {
        title: "Arms and Interventions",
        classNames: {
          objectTitleContainer: "rjs-h1-container",
          objectTitle: "rjs-h1",
          objectContainer: "p-24",
          fieldContainer: "rjs-full",
          field: "rjs-full"
        }
      },
      arms: {
        "ui:options": {
          label: false,
          classNames: {
            objectTitle: "rjs-h3",
            fieldContainer: "rjs-full"
          }
        },
        "ui:widget": "SimpleTable"
      },
      interventions: {
        "ui:options": {
          label: false,
          classNames: {
            objectTitle: "rjs-h3",
            fieldContainer: "rjs-full",
            field: "rjs-full"
          }
        },
        "ui:widget": "SimpleTable"
      },
      crossref: {
        "ui:options": {
          label: false,
          classNames: {
            objectTitle: "rjs-h3",
            objectContainer: "rjs-object-container",
            fieldContainer: "rjs-full",
            field: "rjs-full"
          }
        },
        "ui:widget": "CrossReferenceTable"
      },
      has_drug_information: {
        "ui:options": {
          title: "Has Drug Information?",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
        "ui:widget": "radio"
      },
      study_drugs: {
        "ui:options": {
          title: "Study Drugs",
          label: false,
          addable: true,
          removable: true,
          orderable: true,
          addButtonTitle: "Add Drug",
          classNames: arrayClassNames.primary
        },
        items: {
          drug_type: {
            "ui:options": {
              title: "Drug Type",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
            "ui:widget": "radio"
          },
          atc_code: {
            "ui:options": {
              title: "ATC Code",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
          },
          substance_inn: {
            "ui:options": {
              title: "Substance INN",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
          },
          substance_inns: {
            "ui:options": {
              title: "Substance INN(s)",
              label: false,
              addButtonTitle: "Add Substance INN",
              classNames: arrayClassNames.tertiary
            },
          },
          product_name: {
            "ui:options": {
              title: "Product Name",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
          },
          country: {
            "ui:options": {
              title: "Country",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
          }
        }
      },
    },
    study_eligibilty: {
      "ui:order": ["*", "study_carried_with_established_data_source", "sources_of_data", "registered_data_sources", "unregistered_data_sources", "other_data_source"],
      "ui:options": {
        title: "Eligibility",
        classNames: {
          objectTitleContainer: "rjs-h1-container",
          objectTitle: "rjs-h1",
          objectContainer: "p-24",
          fieldContainer: "rjs-full"
        }
      },
      sex: {
        "ui:options": {
          title: "Sex:",
          placeholder: "--Select--",
          errorMessage: "Gender has not been selected.",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
        "ui:widget": "radio",
      },
      gender_based: {
        "ui:options": {
          label: false,
          classNames: {
            fieldContainer: "rjs-full"
          }
        },
        gender_based_1: {
          "ui:options": {
            title: "Gender Based",
            placeholder: "--Select--",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        gender_eligibility_description: {
          "ui:options": {
            title: "Gender Eligibility Description",
            placeholder: "Enter Gender Eligibility Description",
            label: false,
            classNames: {
              fieldContainer: "rjs-full"
            }
          },
          "ui:widget": "textarea"
        },
      },
      age_limits: {
        "ui:options": {
          title: "Age Limits",
          label: false,
          classNames: {
            objectTitle: "rjs-h2",
            fieldContainer: "rjs-full",
          }
        },
        minimum: {
          "ui:options": {
            classNames: {
              fieldContainer: "rjs-col-1-2",
            }
          },
          minimum_age_type: {
            "ui:options": {
              title: "Minimum:",
              placeholder: "--Select--",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-2",
              }
            },
          },
          minimum_age_value: {
            "ui:options": {
              title: "Enter Minimum Age",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-2",
              }
            },
          },
        },
        maximum: {
          "ui:options": {
            classNames: {
              fieldContainer: "rjs-col-1-2",
            }
          },
          maximum_age_type: {
            "ui:options": {
              title: "Maximum: ",
              placeholder: "--Select--",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-2",
              }
            },
          },
          maximum_age_value: {
            "ui:options": {
              title: "Enter Maximum Age",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-2",
              }
            },
          }
        }
      },
      accept_healthy_volunteers: {
        "ui:options": {
          title: "Accept Healthy Volunteers:",
          placeholder: "--Select--",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-2",
          }
        },
        "ui:widget": "radio",
      },
      is_clinical_study: {
        "ui:options": {
          title: "Is Clinical Study?",
          placeholder: "--Select--",
          label: false,
          hide: true,
          classNames: {
            fieldContainer: "rjs-col-1-2",
          }
        },
        "ui:widget": "radio",
      },
      eligibility_criteria: {
        "ui:options": {
          title: "Eligibility Criteria:",
          label: false,
          classNames: {
            fieldContainer: "rjs-full"
          }
        },
        "ui:widget": "textarea"
      },
      study_population_description: {
        "ui:options": {
          title: "Study Population Description",
          placeholder: "Enter Study Population Description",
          errorMessage: "Study Population Description has not been entered.",
          label: false,
          classNames: {
            fieldContainer: "rjs-full"
          }
        },
        "ui:widget": "textarea"
      },
      sampling_method: {
        "ui:options": {
          title: "Sampling Method:",
          placeholder: "--Select--",
          errorMessage: "Sampling Method has not been entered.",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-2"
          }
        },
      },
      population_under_study: {
        "ui:options": {
          title: "Population under study",
          label: false,
          classNames: {
            objectTitle: "rjs-h2",
            fieldContainer: "rjs-full"
          }
        },
        age: {
          "ui:options": {
            title: "Age",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": 'Multiselect'
        },
        sex: {
          "ui:options": {
            title: "Sex",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
        },
        other_population: {
          "ui:options": {
            title: "Other population",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
        },
        age_range_ctis: {
          "ui:options": {
            title: "Age range ",
            label: false,
            hideFork: true,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "Multiselect"
        },
        age_range_secondary_ctis: {
          "ui:options": {
            title: "Age range secondary identifier",
            label: false,
            hideFork: true,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "Multiselect"
        },
        clinical_trial_group: {
          "ui:options": {
            title: "Clinical trial group",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "Multiselect"
        },
        vulnerable_population: {
          "ui:options": {
            title: "Vulnerable population",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "SingleCheckbox"
        },
        recruitment_population_group: {
          "ui:options": {
            title: "Recruitment population group",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "Multiselect"
        },
        subjects_incapable_giving_consent: {
          "ui:options": {
            title: "Subjects incapable of giving consent personally",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "Multiselect"
        },
        emergency_situation_description: {
          "ui:options": {
            title: "Emergency situation description",
            label: false,
            classNames: {
              fieldContainer: "rjs-full"
            }
          },
        },
        other_description: {
          "ui:options": {
            title: "Other description",
            label: false,
            classNames: {
              fieldContainer: "rjs-full"
            }
          },
        },
      },
      additional_patient_information: {
        "ui:options": {
          title: "Additional information (e.g. number of patients per treatment group)",
          label: false,
          classNames: {
            fieldContainer: "rjs-full"
          }
        },
        "ui:widget": "textarea"
      },
      is_patients_followed_up: {
        "ui:options": {
          title: "Will patients be followed up?",
          label: false,
          classNames: {
            fieldContainer: "rjs-full"
          }
        },
        "ui:widget": "radio"
      },
      follow_up_description: {
        "ui:options": {
          title: "Please describe duration of follow up",
          label: false,
          classNames: {
            fieldContainer: "rjs-full"
          }
        },
        "ui:widget": "textarea"
      },
      study_carried_with_established_data_source: {
        "ui:options": {
          title: "Is this study being carried out with an established data source?",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
        "ui:widget": "radio"
      },
      sources_of_data: {
        "ui:options": {
          title: "Sources of data",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
        "ui:widget": "Multiselect"
      },
      registered_data_sources: {
        "ui:options": {
          title: "Data sources registered with ENCePP",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
        "ui:widget": "Multiselect",
      },
      unregistered_data_sources: {
        "ui:options": {
          title: "Data sources not registered with ENCePP",
          addButtonTitle: "Add Data Source",
          classNames: arrayClassNames.primary
        },
        items: {
          name: {
            "ui:options": {
              title: "Name",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
          },
          country: {
            "ui:options": {
              title: "Country",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
            "ui:widget": "Lookahead"
          }
        }
      },
      other_data_source: {
        "ui:options": {
          title: "Please describe",
          label: false,
          classNames: {
            fieldContainer: "rjs-full"
          }
        },
        "ui:widget": "textarea"
      },
    },
    study_locations: {
      "ui:options": {
        title: "Locations",
        classNames: {
          objectTitleContainer: "rjs-h1-container",
          objectTitle: "rjs-h1",
          objectContainer: "p-24"
        }
      },
      locations: {
        "ui:options": {
          addButtonTitle: "Add Location",
          label: false,
          addable: true,
          removable: true,
          orderable: true,
          classNames: arrayClassNames.primary
        },
        items: {
          countries: {
            "ui:options": {
              title: "Countries:",
              type: "string",
              placeholder: "--Select--",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
          },
          facility: {
            "ui:options": {
              title: "Facility:",
              label: false,
              classNames: {
                objectTitle: "rjs-h3",
                fieldContainer: "rjs-full",
              }
            },
            name: {
              "ui:options": {
                title: "Name:",
                placeholder: "Enter Name",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            city: {
              "ui:options": {
                title: "City:",
                placeholder: "Enter City",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            state_province: {
              "ui:options": {
                title: "State/Province:",
                placeholder: "Enter State/Province",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              },
            },
            zip_postalcode: {
              "ui:options": {
                title: "Zip/Postal Code:",
                placeholder: "Enter value",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              },
            },
          },
          facility_contacts: {
            "ui:options": {
              title: "Facility Contact",
              classNames: {
                objectTitle: "rjs-h3",
                fieldContainer: "rjs-full",
              }
            },
            facility_contacts_first_name: {
              "ui:options": {
                label: false,
                title: "First Name",
                placeholder: "First Name",
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            facility_contacts_middle_name: {
              "ui:options": {
                label: false,
                title: "M.I",
                placeholder: "Enter MI",
                classNames: {
                  fieldContainer: "rjs-col-1-4"
                }
              }
            },
            facility_contacts_last_name: {
              "ui:options": {
                label: false,
                title: "Last Name",
                placeholder: "Enter Last Name",
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            facility_contacts_degree: {
              "ui:options": {
                label: false,
                title: "Degree",
                placeholder: "Enter Degree",
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            facility_contacts_phone_number: {
              "ui:options": {
                label: false,
                title: "Phone Number",
                placeholder: "Enter Phone Number",
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            facility_contacts_ext: {
              "ui:options": {
                label: false,
                title: "Ext.",
                placeholder: "Enter Extension",
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            facility_contacts_email: {
              "ui:options": {
                label: false,
                title: "E-mail",
                placeholder: "Enter E-mail",
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            }
          },
          facility_contacts_backup: {
            "ui:options": {
              title: "Facility Contact Backup",
              classNames: {
                objectTitle: "rjs-h3",
                fieldContainer: "rjs-full",
              }
            },
            facility_contacts_backup_first_name: {
              "ui:options": {
                label: false,
                title: "First Name",
                placeholder: "First Name",
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            facility_contacts_backup_middle_name: {
              "ui:options": {
                label: false,
                title: "M.I",
                placeholder: "Enter MI",
                classNames: {
                  fieldContainer: "rjs-col-1-4"
                }
              }
            },
            facility_contacts_backup_last_name: {
              "ui:options": {
                label: false,
                title: "Last Name",
                placeholder: "Enter Last Name",
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            facility_contacts_backup_degree: {
              "ui:options": {
                label: false,
                title: "Degree",
                placeholder: "Enter Degree",
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            facility_contacts_backup_phone_number: {
              "ui:options": {
                label: false,
                title: "Phone Number",
                placeholder: "Enter Phone Number",
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            facility_contacts_backup_ext: {
              "ui:options": {
                label: false,
                title: "Ext.",
                placeholder: "Enter Extension",
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            facility_contacts_backup_email: {
              "ui:options": {
                label: false,
                title: "E-mail",
                placeholder: "Enter E-mail",
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            }
          },
          overall_study_investigator: {
            "ui:options": {
              title: "Investigators",
              label: false,
              addable: true,
              removable: true,
              orderable: true,
              addButtonTitle: "Add Investigator",
              classNames: arrayClassNames.primary
            },
            items: {
              overall_study_investigator_first_name: {
                "ui:options": {
                  title: "First Name",
                  placeholder: "Enter First Name",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              overall_study_investigator_middle_name: {
                "ui:options": {
                  title: "M.I",
                  placeholder: "Enter MI",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-col-1-4"
                  }
                }
              },
              overall_study_investigator_last_name: {
                "ui:options": {
                  title: "Last Name",
                  placeholder: "Enter Last Name",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              overall_study_investigator_degree: {
                "ui:options": {
                  title: "Degree",
                  placeholder: "Enter Degree",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              investigator_role: {
                "ui:options": {
                  title: "Role",
                  placeholder: "Select Role",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
            },
          },
        },
      }
    },
    study_ipd: {
      "ui:options": {
        title: "IPD Sharing Statements",
        classNames: {
          fieldContainer: "rjs-full",
          objectContainer: "rjs-gap-16 p-24",
          objectTitleContainer: "rjs-h1-container",
          objectTitle: "rjs-h1"
        }
      },
      plan_to_share_IPD: {
        "ui:options": {
          title: "Plan to Share IPD?",
          placeholder: "--Select--",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
        "ui:widget": "radio",
      },
      plan_description: {
        "ui:options": {
          title: "Plan Description",
          errorMessage: "IPD Description must have no more than 1000 characters",
          label: false,
          classNames: {
            fieldContainer: "rjs-full"
          }
        },
        "ui:widget": "textarea"
      },
      ipd_sharing_info: {
        "ui:options": {
          title: "Supporting Information Type",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
        "ui:widget": "CustomCheckboxGroup"
      },
      time_frame: {
        "ui:options": {
          title: "Time Frame",
          errorMessage: "IPD Sharing Time Frame must have no more than 1000 characters.",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-2-3"
          }
        },
        "ui:widget": "textarea"
      },
      access_criteria: {
        "ui:options": {
          title: "Access Criteria",
          errorMessage: "IPD Sharing Access Criteria must have no more than 1000 characters.",
          label: false,
          classNames: {
            fieldContainer: "rjs-full"
          }
        },
        "ui:widget": "textarea"
      },
      url_text: {
        "ui:options": {
          title: "URL",
          errorMessage: "IPD Sharing URL must have no more than 3999 characters.",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-2"
          }
        },
      },
    },
    study_outcome_measures: {
      "ui:options": {
        title: "Study Endpoints / Outcome Measures",
        classNames: {
          fieldContainer: "rjs-full",
          objectContainer: "rjs-gap-16 p-24",
          objectTitleContainer: "rjs-h1-container",
          objectTitle: "rjs-h1",
        }
      },
      outcome_measures: {
        "ui:options": {
          title: "Outcome Measures",
          addButtonTitle: "Add Outcome Measures",
          arrayItemTitle: "Outcome Measure",
          label: false,
          addable: true,
          removable: true,
          orderable: true,
          canCopy: true,
          canCopyOutcomeMeasure: true,
          showIndex: true,
          classNames: arrayClassNames.primary
        },
        items: {
          title: {
            "ui:options": {
              title: "Title",
              placeholder: "Enter Title",
              label: false,
              rows: 1,
              classNames: {
                fieldContainer: "rjs-full"
              }
            },
            "ui:widget": "textarea"
          },
          time_frame: {
            "ui:options": {
              title: "Time Frame",
              placeholder: "Enter Time Frame",
              label: false,
              classNames: {
                fieldContainer: "rjs-full"
              }
            }
          },
          description: {
            "ui:options": {
              title: "Description",
              placeholder: "Enter Description",
              label: false,
              classNames: {
                fieldContainer: "rjs-full"
              }
            },
            "ui:widget": "textarea"
          },
          type: {
            "ui:options": {
              title: "Type",
              label: false,
              orientation: "horizontal",
              classNames: {
                fieldContainer: "rjs-full"
              }
            },
            "ui:widget": "radio",
          },
          translations: {
            "ui:options": {
              title: "Translations",
              itemTitle: "Translation",
              addButtonTitle: "Add Translation",
              label: false,
              addable: true,
              removable: true,
              orderable: true,
              classNames: arrayClassNames.primary
            },
            items: {
              language: {
                "ui:options": {
                  title: "Language",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              translation: {
                "ui:options": {
                  title: "Translation",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-col-2-3"
                  }
                },
                "ui:widget": "textarea"
              },
            },
          }
        }
      },
      outcome_measures_ctis: {
        "ui:options": {
          title: "Outcome Measures - CTIS",
          addButtonTitle: "Add Outcome Measures",
          arrayItemTitle: "Outcome Measure",
          label: false,
          addable: true,
          removable: true,
          orderable: true,
          canCopyCtgOutcomeMeasures: true,
          // canCopy: true,
          showIndex: true,
          classNames: arrayClassNames.primary
        },
        items: {
          title: {
            "ui:options": {
              title: "Title",
              placeholder: "Enter Title",
              label: false,
              rows: 1,
              classNames: {
                fieldContainer: "rjs-full"
              }
            },
            "ui:widget": "textarea"
          },
          time_frame: {
            "ui:options": {
              title: "Time Frame",
              placeholder: "Enter Time Frame",
              label: false,
              classNames: {
                fieldContainer: "rjs-full"
              }
            }
          },
          description: {
            "ui:options": {
              title: "Description",
              placeholder: "Enter Description",
              label: false,
              classNames: {
                fieldContainer: "rjs-full"
              }
            },
            "ui:widget": "textarea"
          },
          type: {
            "ui:options": {
              title: "Type",
              label: false,
              orientation: "horizontal",
              classNames: {
                fieldContainer: "rjs-full"
              }
            },
            "ui:widget": "radio",
          },
          translations: {
            "ui:options": {
              title: "Translations",
              itemTitle: "Translation",
              addButtonTitle: "Add Translation",
              label: false,
              addable: true,
              removable: true,
              orderable: true,
              classNames: arrayClassNames.primary
            },
            items: {
              language: {
                "ui:options": {
                  title: "Language",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              translation: {
                "ui:options": {
                  title: "Translation",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-col-2-3"
                  }
                },
                "ui:widget": "textarea"
              },
            },
          }
        }
      },
      outcome_measures_eupas: {
        "ui:options": {
          title: "Outcome Measures - EUPAS",
          classNames: {
            fieldContainer: "rjs-full",
            objectTitle: "rjs-h2",
          }
        },
        primary_outcome_measures: {
          "ui:options": {
            classNames: {
              objectTitle: "rjs-h2",
              fieldContainer: "rjs-full",
            }
          },
          description: {
            "ui:options": {
              title: "Primary Outcome Measures",
              label: false,
              classNames: {
                fieldContainer: "rjs-full"
              }
            },
            "ui:widget": "textarea"
          }
        },
        secondary_outcome_measures: {
          "ui:options": {
            classNames: {
              objectTitle: "rjs-h2",
              fieldContainer: "rjs-full",
            }
          },
          title: "Primary Outcome Measures",
          description: {
            "ui:options": {
              title: "Secondary Outcome Measures",
              label: false,
              classNames: {
                fieldContainer: "rjs-full"
              }
            },
            "ui:widget": "textarea"
          }
        },
      },
      statistical_analysis_plan: {
        "ui:options": {
          title: "Data analysis plan",
          tooltip: "Please provide a brief summary of the analysis method    e.g. risk estimation, measures of risk, internal/external validity.",
          label: false,
          classNames: {
            fieldContainer: "rjs-full"
          }
        },
        "ui:widget": "textarea"
      }
    },
    study_reference: {
      "ui:options": {
        title: "Citations, References and URLs",
        classNames: {
          objectTitleContainer: "rjs-h1-container",
          objectTitle: "rjs-h1",
          objectContainer: "p-24"
        }
      },
      citations: {
        "ui:options": {
          title: "Citations",
          addButtonTitle: "Add Citation",
          label: false,
          addable: true,
          removable: true,
          orderable: true,
          classNames: arrayClassNames.primary
        },
        items: {
          pub_med_id: {
            "ui:options": {
              title: "PubMed ID",
              errorMessage: "PubMed ID not recognized",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          result_reference: {
            "ui:options": {
              title: "Results Reference",
              placeholder: "--Select--",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
            "ui:widget": "radio"
          },
          citation_text: {
            "ui:options": {
              title: "Enter Citation Text",
              errorMessage: "The citation description should be upto 2000 characters and cannot be editable",
              label: false,
              classNames: {
                fieldContainer: "rjs-full"
              }
            },
            "ui:widget": "textarea"
          },
        },
      },
      links: {
        "ui:options": {
          title: "Links",
          addButtonTitle: "Add Link",
          label: false,
          addable: true,
          removable: true,
          orderable: true,
          classNames: arrayClassNames.primary
        },
        items: {
          url_text: {
            "ui:options": {
              title: "URL",
              errorMessage: "IPD Sharing URL must have no more than 3999 characters.",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          description_text: {
            "ui:options": {
              title: "Enter Description",
              errorMessage: "Note : Link Description has not been entered",
              label: false,
              classNames: {
                fieldContainer: "rjs-full"
              }
            },
            "ui:widget": "textarea"
          },
        },
      },
      available: {
        "ui:options": {
          title: "Available IPD/Information",
          addButtonTitle: "Add Data/Information",
          label: false,
          addable: true,
          removable: true,
          orderable: true,
          classNames: arrayClassNames.primary
        },
        items: {
          "ui:order": ["data_info", "url_text", "identifier", "description", "comments"],
          data_info: {
            "ui:options": {
              title: "Data/Information Type",
              placeholder: "--Select--",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
          },
          url_text: {
            "ui:options": {
              title: "URL",
              errorMessage: "IPD Sharing URL must have no more than 3999 characters.",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          identifier: {
            "ui:options": {
              title: "Identifier",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          comments: {
            "ui:options": {
              title: "Comments",
              label: false,
              classNames: {
                fieldContainer: "rjs-full"
              }
            },
            "ui:widget": "textarea"
          },
          description: {
            "ui:options": {
              title: "Reason",
              placeholder: "Please specify the reason",
              label: false,
              classNames: {
                fieldContainer: "rjs-full"
              }
            }
          },
        },
      },
      is_requesting_encepp_seal: {
        "ui:options": {
          title: "Are you requesting the ENCePP seal for this study?",
          label: false,
          tooltip: "Please note that the ENCePP seal can only be requested before the study commences. Study timelines - Actual start date of data collection",
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
        "ui:widget": "radio"
      },
      make_full_protocol_public_when_study_ends: {
        "ui:options": {
          title: "Make protocol public now?",
          label: false,
          tooltip: "Please be aware that the uploaded protocol will be made public when the study ends (see Q.3 Study timelines: date of final study report)",
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
        "ui:widget": "radio"
      },
      study_publications: {
        "ui:options": {
          title: "Please list the 5 most relevant publications using data from your study",
          addButtonTitle: "Add Publication",
          label: false,
          addable: true,
          removable: true,
          orderable: true,
          classNames: arrayClassNames.primary
        },
        items: {
          url: {
            "ui:options": {
              title: "Link to web-publication (if available)",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          reference: {
            "ui:options": {
              title: "Reference Link",
              label: false,
              classNames: {
                fieldContainer: "rjs-full"
              }
            },
            "ui:widget": "textarea"
          },
        }
      }
    },
  },
}