import { useState, useEffect } from 'react';
import { cloneDeep, uniq } from 'lodash'
import { v4 as uuid } from 'uuid'
import { useShallow } from 'zustand/react/shallow'

// components
import PrimeField from 'components/PrimeField/PrimeField';
import Arm from '../Arm/Arm';
import Sas from '../Sas/Sas';
import CreateArmModal from '../../../RJBaselineChars/subcomponents/CreateArmModal/CreateArmModal';

// context
import useAuthoringDataStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringDataStore';
import useResultsTableViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableViewStore';
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';

// utils
import {
  moveLeftHelper,
  moveRightHelper
} from '../../helpers/helpers';
import useResultsTableArmsStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableArmsStore';
import useBaselineActions from '../../../RJBaselineChars/hooks/useBaselineActions';

const Arms = ({
  measure,
  measureIndex,
  outcomeData,
  setOutcomeData,
  schema,
  showUnitsAnalyzed
}) => {
  const {
    onCreateSubjectAnalysisSetInBaseline
  } = useBaselineActions()

  const {
    outcomeArmOptions,
    baselineSas,
  } = useResultsTableArmsStore()

  const {
    tableView
  } = useResultsTableViewStore()

  const {
    formData,
    setFormData,
  } = useAuthoringDataStore(
    useShallow(state => ({
      formData: state.formData,
      setFormData: state.setFormData,
    }))
  )

  const {
    readOnly
  } = useAuthoringViewStore(
    useShallow(state => ({ readOnly: state.readOnly }))
  )

  const [showAddArmDropdown, setShowAddArmDropdown] = useState(false)
  const [selectedArmType, setSelectedArmType] = useState(null);
  const [showModal, setShowModal] = useState(false)

  let armSchema = schema.properties[tableView].properties.arms.properties

  let armPropRows = Object.keys(armSchema).filter(key => {
    if (showUnitsAnalyzed) return true;
    else if (key === "num_units_analyzed") return false;
    return true;
  })

  const onAddArm = (selectedItem, createForAll) => {
    const filterDuplicateArms = (a, b) => {
      let ret = []
      b.forEach(bItem => {
        let foundItem = a.find(aItem => aItem.registry_arm_id === bItem.registry_arm_id);
        if (!foundItem) ret.push(bItem)
      })

      return ret;
    }

    const removeDuplicatesByKey = (arr, key) => {
      let seen = new Set();

      let ret = arr.filter(item => {
        let value = item[key];

        if (seen.has(value)) {
          return false;
        } else {
          seen.add(value);
          return true;
        }
      });

      return ret
    }

    const copyArms = (groups) => {
      let tempData = { ...outcomeData };

      const populateData = (registry, key, groups) => {
        let updatedGroups = [...groups]
        if (createForAll || tableView === registry) {
          let foundOMIndex = tempData[registry].outcome_measures.findIndex(item => item.id === measure.id);
          if (foundOMIndex !== -1) {
            if (tempData[registry].outcome_measures[foundOMIndex][key]) {
              updatedGroups = []
              groups.forEach(group => {
                let foundIndex = tempData[registry].outcome_measures[foundOMIndex][key].findIndex(item => item.id === group.id);
                if (foundIndex === -1) updatedGroups.push(group)
                else {
                  let newGroup = {
                    ...group,
                    id: uuid()
                  }
                  updatedGroups.push(newGroup)
                }
              })

              tempData[registry].outcome_measures[foundOMIndex][key] = [...tempData[registry].outcome_measures[foundOMIndex][key], ...updatedGroups];
            } else {
              tempData[registry].outcome_measures[foundOMIndex][key] = [...updatedGroups]
            }

            tempData[registry].outcome_measures[foundOMIndex].measure_rows.forEach(row => {
              row.categories.forEach(cat => {
                let values = cat.values ? [...cat.values] : [];
                updatedGroups.map(group => {
                  let foundIndex = values.findIndex(value => value.group_id === group.id);

                  if (foundIndex === -1) {
                    values.push({
                      group_id: group.id,
                      registry_arm_id: group.registry_arm_id,
                      value: null
                    })
                  }
                })

                cat.values = values
              })
            })
          }
        }
      }

      Object.keys(outcomeData).forEach(registry => {
        if (createForAll || tableView === registry) {
          if (registry === "eudract") {
            let [sets, arms] = createSubjectAnalysisSet(groups, tempData);
            populateData(registry, "arms", arms);
            populateData(registry, "subject_analysis_sets", sets);

            onCreateSubjectAnalysisSetInBaseline(sets[0])

          } else {
            populateData(registry, "arms", groups)
          }
        }
      })

      setOutcomeData(tempData)
    }

    const createSubjectAnalysisSet = (groups, tempData) => {
      let sets = [];
      let arms = []
      for (let group of groups) {
        let found = false;
        if (formData?.results?.participant_flow?.participant_flow_table?.eudract_periods) {
          for (let period of formData.results.participant_flow.participant_flow_table.eudract_periods) {
            for (let arm of period.arms) {
              let fullArmId = arm?.id.split(".")
              if (group.id.includes(fullArmId[fullArmId.length - 1])) {
                found = true;
                arms.push(group);
                break;
              }
            }
            if (found) break;
          }
        }
        if (found === false) {
          sets.push(group)
        }
      }
      return [sets, arms]
    }

    const formatArmIds = (arms) => {
      return arms.map(arm => {
        let oldArmIds = arm?.id?.split(".")
        let newId = arm?.id;
        if (oldArmIds?.[1] && oldArmIds?.[0]?.includes("Participant")) {
          newId = "OutcomeMeasure." + measure.id + "-OutcomeRptGroup." + oldArmIds[1];
        } else if (oldArmIds?.[0] && !oldArmIds?.[1]) {
          newId = "OutcomeMeasure." + measure.id + "-OutcomeRptGroup." + oldArmIds[0];
        }
        return {
          ...arm,
          id: newId
        }
      })
    }

    const isPeriodArms = () => {
      if (formData?.results?.participant_flow?.participant_flow_table?.eudract_periods) {
        let foundIndex = formData.results.participant_flow.participant_flow_table.eudract_periods.findIndex((period) => period.id === selectedItem.id);
        if (foundIndex !== -1) {
          let period = formData.results.participant_flow.participant_flow_table.eudract_periods[foundIndex]
          return formData.results.participant_flow.participant_flow_table.eudract_periods[foundIndex].arms.map(arm => {
            return {
              ...arm,
              registry_arm_id: `${period.id}:${arm.id}`
            }
          });
        }
      }
      return null;
    }

    switch (selectedItem.id) {
      case "copy-protocol":
        break;
      case "copy-pf":
        if (formData?.results?.participant_flow?.participant_flow_table?.global_arms) {
          let pfArms = cloneDeep(formData.results?.participant_flow.participant_flow_table.global_arms);
          let registryArms = [...measure.arms];
          let filteredArms = [...filterDuplicateArms(registryArms, pfArms)];

          copyArms(formatArmIds(filteredArms))
        }
        break;
      case "copy-bc": return;
      case "create-new":
        let id = uuid();
        let group = {
          id,
          registry_arm_id: `OutcomeMeasure.${measure.id}-OutcomeRptGroup.${id}`,
          title: "",
          description: "",
          is_subject_analysis_set: true,
          type: "",
          baseline_analysis_population: undefined,
          subjects_analyzed: undefined,
        }
        copyArms(formatArmIds([group]))
        break;
      default:
        let arms = isPeriodArms();

        if (arms) {
          let pfArms = []
          if (selectedItem) {
            let foundPeriodIndex = formData.results?.participant_flow?.participant_flow_table?.eudract_periods?.findIndex(period => period.id === selectedItem.id);
            if (foundPeriodIndex !== -1) {
              pfArms = cloneDeep(formData.results?.participant_flow?.participant_flow_table?.eudract_periods[foundPeriodIndex].arms);
            }
          }
          let registryArms = [...measure.arms];
          let filteredArms = [...filterDuplicateArms(registryArms, pfArms)];

          copyArms(formatArmIds(filteredArms), true)
        } else {
          let group = baselineSas.find(item => item.id.includes(selectedItem.id));
          if (group) {
            let formattedGroups = formatArmIds(cloneDeep([group]))
            let sets = measure.subject_analysis_sets || []
            let arms = measure.arms || []
            let filteredArms = filterDuplicateArms([...sets, ...arms], formattedGroups);

            copyArms(filteredArms, false)
          }
        }
        break;
    }
    onHideModal()
  }

  const handleLeft = (group) => {
    let tempData = { ...outcomeData };
    Object.keys(tempData).forEach(registry => {
      let foundOMIndex = tempData[registry].outcome_measures.findIndex(item => item.id === measure.id);
      if (foundOMIndex !== -1) {
        let om = tempData[registry].outcome_measures[foundOMIndex];
        let foundGroupIndex = om.arms.findIndex(item => item.registry_arm_id === group.registry_arm_id);
        if (foundGroupIndex !== -1 && foundGroupIndex !== 0) {
          moveLeftHelper(om, "arms", foundGroupIndex);
          om.measure_rows.forEach(row => {
            row.categories.forEach(category => {
              let tempValues = [...category.values];
              let foundIndex = tempValues.findIndex(item => item.registry_arm_id === group.registry_arm_id);

              if (foundIndex !== -1 && foundIndex !== 0) {
                let saved = tempValues[foundIndex - 1];
                tempValues[foundIndex - 1] = tempValues[foundIndex];
                tempValues[foundIndex] = saved;
                category.values = [...tempValues];
              }
            })
          })
        } else if (registry === "eudract" && foundGroupIndex === -1) {
          foundGroupIndex = om?.subject_analysis_sets?.findIndex(item => item.registry_arm_id === group.registry_arm_id);
          if (foundGroupIndex !== -1) {
            moveLeftHelper(om, "subject_analysis_sets", foundGroupIndex)
            om.measure_rows.forEach(row => {
              row.categories.forEach(category => {
                let tempValues = [...category.values];
                let foundIndex = tempValues.findIndex(item => item.registry_arm_id === group.registry_arm_id);

                if (foundIndex !== -1 && foundIndex !== 0) {
                  let saved = tempValues[foundIndex - 1];
                  tempValues[foundIndex - 1] = tempValues[foundIndex];
                  tempValues[foundIndex] = saved;
                  category.values = [...tempValues];
                }
              })
            })
          }
        }
      }
    })
    setOutcomeData(tempData)
  }

  const handleRight = (group) => {
    let tempData = { ...outcomeData };
    Object.keys(tempData).forEach(registry => {
      let foundOMIndex = tempData[registry].outcome_measures.findIndex(item => item.id === measure.id);
      if (foundOMIndex !== -1) {
        let om = tempData[registry].outcome_measures[foundOMIndex];
        let foundGroupIndex = om.arms.findIndex(item => item.registry_arm_id === group.registry_arm_id);
        if (foundGroupIndex !== -1 && foundGroupIndex !== om.arms.length - 1) {
          moveRightHelper(om, "arms", foundGroupIndex);
          om.measure_rows.forEach(row => {
            row.categories.forEach(category => {
              let tempValues = [...category.values];
              let foundIndex = tempValues.findIndex(item => item.registry_arm_id === group.registry_arm_id);

              if (foundIndex !== -1 && foundIndex !== category.values.length - 1) {
                let saved = tempValues[foundIndex + 1];
                tempValues[foundIndex + 1] = tempValues[foundIndex];
                tempValues[foundIndex] = saved;
                category.values = [...tempValues];
              }
            })
          })
        } else if (registry === "eudract" && foundGroupIndex === -1) {
          foundGroupIndex = om?.subject_analysis_sets?.findIndex(item => item.registry_arm_id === group.registry_arm_id);
          if (foundGroupIndex !== -1) {
            moveRightHelper(om, "subject_analysis_sets", foundGroupIndex);
            om.measure_rows.forEach(row => {
              row.categories.forEach(category => {
                let tempValues = [...category.values];
                let foundIndex = tempValues.findIndex(item => item.registry_arm_id === group.registry_arm_id);

                if (foundIndex !== -1 && foundIndex !== category.values.length - 1) {
                  let saved = tempValues[foundIndex + 1];
                  tempValues[foundIndex + 1] = tempValues[foundIndex];
                  tempValues[foundIndex] = saved;
                  category.values = [...tempValues];
                }
              })
            })
          }
        }
      }
    })
    setOutcomeData(tempData)
  }

  const handleDelete = (group, applyAll) => {
    let tempData = { ...outcomeData };
    Object.keys(tempData).forEach(registry => {
      if (applyAll === true || registry === tableView) {
        // let foundIndex = tempData[registry].arms.findIndex(item => item.id === group.id);
        let foundOMIndex = tempData[registry].outcome_measures.findIndex(item => item.id === measure.id);
        if (foundOMIndex !== -1) {
          let om = tempData[registry].outcome_measures[foundOMIndex];
          let foundIndex = om.arms.findIndex(item => item.registry_arm_id === group.registry_arm_id);
          if (foundIndex !== -1) om.arms.splice(foundIndex, 1);

          if (registry === "eudract" && om.subject_analysis_sets) {
            foundIndex = om?.subject_analysis_sets?.findIndex(item => item.registry_arm_id === group.registry_arm_id);
            if (foundIndex !== -1) om.subject_analysis_sets.splice(foundIndex, 1);

            // let reportingSetIndex = tempData[registry].subject_analysis_sets.findIndex(item => item.id === group.id);
            // if(reportingSetIndex !== -1) {
            //   tempData[registry].subject_analysis_sets.splice(reportingSetIndex, 1);
            // }
          }

          // delete baseline measure values
          om.measure_rows.forEach(row => {
            row?.categories?.forEach(category => {
              let tempValues = [];
              if (category.values) {
                tempValues = [...category.values]
              }
              let foundIndex = category?.values?.findIndex(item => item.registry_arm_id === group.registry_arm_id);

              if (foundIndex !== -1) {
                tempValues.splice(foundIndex, 1)
                category.values = [...tempValues];
              }
            })
          })
        }
      }
    });

    setOutcomeData(tempData);
  }

  const onShowModal = (selectedItem) => {
    setSelectedArmType(selectedItem)
    setShowModal(true)
  }

  const onHideModal = () => {
    setSelectedArmType(null)
    setShowModal(false)
  }

  const renderSubjectAnalysisSet = () => {
    return measure?.subject_analysis_sets?.filter(set => set).map((set, i) => {
      return (
        <Sas
          key={`${measure.id}-${set.id}`}
          set={set}
          sets={measure.subject_analysis_sets}
          measure={measure}
          handleLeft={handleLeft}
          handleRight={handleRight}
          colIndex={i}
          rowIndex={0}
          handleDelete={handleDelete}
          setOutcomeData={setOutcomeData}
          outcomeData={outcomeData}
          measureIndex={measureIndex} />
      )
    })
  }

  const getRowSpan = () => {
    if (tableView === "eudract") {
      return 2
    } else {
      return showUnitsAnalyzed ? 4 : 3
    }
  }

  const getColSpan = () => {
    if (measure?.arms?.length === 0 || measure?.subject_analysis_sets?.length > 0) {
      return 2
    }
    return 1;
  }

  return (
    <>
      {armPropRows.map((key, rI) => {
        let armsSchema = schema.properties[tableView].properties.arms.properties;

        return (
          <tr key={`${tableView}-arm-row-${key}-${rI}`}>
            <td
              className="header-column"
              colSpan={2}>
              {armSchema[key].title}
            </td>
            {measure?.arms?.map((arm, cI) => {
              return (
                <Arm
                  key={arm?.id}
                  arm={arm}
                  colIndex={cI}
                  rowIndex={rI}
                  arms={measure.arms}
                  fieldKey={key}
                  value={arm?.[key] || ""}
                  schema={armsSchema}
                  measure={measure}
                  measureIndex={measureIndex}
                  handleLeft={() => handleLeft(arm)}
                  handleRight={() => handleRight(arm)}
                  handleDelete={handleDelete}
                  outcomeData={outcomeData}
                  setOutcomeData={setOutcomeData}
                />
              )
            })}
            {rI === 0 && readOnly !== true &&
              <td
                className="text-center add-arm-action-col"
                style={{ maxWidth: 250 }}
                rowSpan={getRowSpan()}
                colSpan={getColSpan()}>
                {showAddArmDropdown
                  ? (
                    <PrimeField
                      schema={{
                        type: "dropdown",
                        placeholder: "Select arm",
                        items: outcomeArmOptions
                      }}
                      readOnly={readOnly}
                      onChange={e => {
                        let foundItem = outcomeArmOptions.find(item => item.id === e)
                        onShowModal(foundItem)
                      }}
                      value={null} />
                  )
                  : <i
                    onClick={() => setShowAddArmDropdown(true)}
                    className="fal fa-plus-circle add-arm-icon" />
                }
              </td>
            }
          </tr>
        )
      })}
      {measure?.subject_analysis_sets?.length > 0 &&
        <tr>
          <td
            className='header-column subject-analysis-set'
            colSpan={2}>
            Subject Analysis Sets
          </td>
          {renderSubjectAnalysisSet()}
        </tr>
      }
      <CreateArmModal
        onAddArm={onAddArm}
        showModal={showModal}
        setShowModal={onHideModal}
        selectedArmType={selectedArmType}
        source="om" />
    </>
  )
};

export default Arms;