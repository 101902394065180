import * as React from 'react'
import PropTypes from 'prop-types'
import useFileLoader from 'utilities/useFileLoader'
import './FileUpload.scss'

const FileUpload = ({
  onLoad,
  hasError,
  isDragging,
  className,
}) => {
  const {
    FileBrowseLink,
  } = useFileLoader({
    onLoad,
  })

  return <FileBrowseLink
    className={`file-upload${isDragging ? ' dragging' : ''}${className ? ` ${className}` : ''}`}
  >
    <div className={`file-loader${hasError ? ' error' : ''}`}>
      <i className="fal fa-cloud-upload" />
      <div className="file-loader-text">Drag &amp; drop to upload <br />
        or <span className="link">browse</span>*</div>
    </div>
  </FileBrowseLink>
}

FileUpload.propTypes = {
  onLoad: PropTypes.func,
}

export default FileUpload