// TODO: rename navbarData?
// TODO: rename items' "name" to "navLabel"
/*** Usage:
 *   * To set page title, set "title" || "name"
 *   * To set HTML title: set "htmlTitle" || "title" || "name"
 */
import React from 'react'

export default [
  {
    name: "My Prime",
    url: "/my-prime",
    icon: "far fa-th",
    isNew: true,
    hidePageTitle: true,
  },
  {
    name: "Requests",
    url: "/requests",
    icon: "fal fa-money-check-edit",
    isNew: true,
  },
  {
    name: "Files",
    url: "/files",
    icon: "fas fa-file-alt",
    isNew: true,
  },
  {
    name: "Studies",
    icon: "fal fa-clipboard-list-check",
    items: [
      {
        name: "View Studies",
        url: "/studies",
      },
      {
        name: "Create Study",
        url: "/create-study",
        permission: "canCreateStudy",
        // title: "Create Study", // will use name
        // htmlTitle: "Create Study", // will use name
        breadcrumbs: [
          {
            label: "Home",
            url: "/",
          },
          {
            label: "Create Study",
          },
        ]
      },
      {
        name: "Activities",
        title: "Manage Activities",
        url: "/activities",
        permission: "canEditAIs",
      },
      {
        name: "Action Items",
        title: "Manage Action Items",
        url: "/action-items",
        permission: "canEditAIs"
      },
      {
        name: "Site Management",
        url: "/site-management",
      },
      {
        name: "Protocol Maintenance",
        url: "/protocol-maintenance",
      },
      {
        name: "PRS Upload History",
        description: "All uploads and releases performed through the Prime application.",
        url: "/upload-history",
      }
    ],
  },
  {
    name: "Metrics",
    icon: "fal fa-analytics",
    items: [
      {
        name: "Activity Chart",
        url: "/metrics/activity-chart",
        description: <span>Activities grouped by actual or project completion date month. Click graph for activity details</span>
      },
      {
        name: "Compliance Chart",
        url: "/metrics/compliance-chart",
        description: "Activities grouped by compliance due date month. Click graph for activity details",
      },
      {
        name: "Activity",
        title: "Activity Dashboard",
        url: "/metrics/activity-dashboard",
        description: "All current activities divided by region and status",
      },
      {
        name: "Assessment",
        title: "Assessment Dashboard",
        url: "/metrics/assessment-dashboard",
        description: "All assessments and study scope categorized by region",
      },
      {
        name: "Completed Activities",
        url: "/metrics/completed-activities",
      },
      {
        name: "Assessments Due",
        url: "/metrics/assessments-due",
      },
      {
        name: "Activity Planning Dashboard",
        url: "/metrics/activity-planning",
        isNew: true,
      },
    ],
  },
  {
    name: "Reports",
    icon: "fal fa-file-chart-line",
    items: [
      {
        name: "All Reports",
        url: "/reports/all-reports"
      },
      {
        name: "Action Items",
        title: "Action Items Report",
        url: "/reports/action-items",
      },
      {
        name: "Activity",
        title: "Activity Report",
        url: "/reports/activity",
      },
      {
        name: "Activity State",
        title: "Activity State Report",
        url: "/reports/activity-state"
      },
      {
        name: "Assessment",
        title: "Assessment Report",
        url: "/reports/assessment"
      },
      {
        name: "Assessment Change",
        title: "Assessment Change Report",
        url: "/reports/assessment-change"
      },
      {
        name: "Bottleneck",
        title: "Bottleneck Report",
        url: "/reports/bottleneck"
      },
      {
        name: "Comment",
        title: "Comment Report",
        url: "/reports/comment"
      },
      {
        name: "Data Change",
        title: "Data Change Report",
        url: "/reports/data-change"
      },
      {
        name: "Data Import",
        title: "Data Import Report",
        url: "/reports/data-import"
      },
      // {
      //   name: "Illogical Data",
      //   title: "Illogical Data Report",
      //   url: "/reports/illogical-data"
      // },
      {
        name: "Maintenance",
        title: "Maintenance Report",
        url: "/reports/maintenance"
      },
      // {
      //   name: "Maintenance Trigger",
      //   title: "Maintenance Trigger Report",
      //   url: "/reports/maintenance-trigger"
      // },
      {
        name: "New Request",
        title: "New Request Report",
        url: "/reports/new-request"
      },
      {
        name: "New Study",
        title: "New Study Report",
        url: "/reports/new-study"
      },
      // {
      //   name: "Request Import",
      //   title: "Request Import Report",
      //   url: "/reports/request-import",
      // },
      {
        name: "Request",
        title: "Request Report",
        url: "/reports/request"
      },
      {
        name: "Study Site",
        title: "Study Site Report",
        url: "/reports/study-site"
      },
      // {
      //   name: "Study",
      //   title: "Study Report",
      //   url: "/reports/study"
      // },
    ],
  },
  {
    name: "Admin",
    icon: "fal fa-cog",
    items: [
      {
        name: "Workflow Builder",
        url: "/admin/workflow-builder",
        permission: "canEditPts"
      },
      {
        name: "Assessment Types",
        url: "/admin/assessment-types",
        permission: "canEditAts",
      },
      {
        name: "Configurations",
        url: "/admin/configurations",
        permission: "canEditConfigs"
      },
      {
        name: "Grid Presets",
        url: "/admin/grid-presets/",
        permission: "canEditPresets"
      },
      {
        name: 'PRS Site Masking and Upload Settings',
        url: "/admin/prs-site-masking",
        permission: "maskingCreate"
      },
      {
        name: "PRS Login Settings",
        url: "/admin/prs-login-settings",
        permission: "credentialsCreate"
      },
      {
        name: "Users",
        url: "/admin/users",
        permission: "canEditUsers",
      },
    ],
  },
]