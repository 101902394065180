export const matchRegistryValues = {
  eudract: {
    ctg: {
      title: {
        "Age categorical": "Age, Customized",
        "Age continuous": "Age, Continuous",
        "Gender categorical": "Sex/Gender, Customized"
      }
    }
  },
  ctg: {
    eudract: {
      title: {
        "Age, Categorical": "Age categorical",
        "Age, Continuous": "Age continuous",
        "Age, Customized": "Age categorical",
        "Sex: Female, Male": "Gender categorical",
        "Sex/Gender, Customized": "Gender customized",
        "Race (NIH/OMB)": "Race",
        "Ethnicity (NIH/OMB)": "Ethnicity",
        "Race/Ethnicity, Customized": "Race/Ethnicity",
        "Region of Enrollment": "Region of enrollment"
      }
    }
  }
}

export const registrySpecificBaselineMeasureId = {
  ctg: { // form
    eudract: { // to
      "ctg-specific-customized": "eudract-specific-categorical", // parameter type dependant
      "ctg-age-continuous": "eudract-age-continuous",
      "ctg-age-categorical": "eudract-specific-categorical",
      "ctg-age-customized": "eudract-age-categorical", // parameter type dependant

      "ctg-gender-categorical": "eudract-gender-categorical",
      "ctg-gender-customized": "eudract-gender-categorical", // parameter type dependant

      "ctg-race-categorical": "eudract-specific-categorical",
      "ctg-ethnicity-categorical": "eudract-specific-categorical",
      "ctg-ethnicity-customized": "eudract-specific-categorical", // parameter type dependant

      "ctg-region-categorical": "eudract-specific-categorical"
    }
  },
  eudract: { // from
    ctg: { // to
      "eudract-specific-categorical": "ctg-specific-customized",
      "eudract-specific-continuous": "ctg-specific-customized",
      "eudract-age-continuous": "ctg-age-continuous",
      "eudract-age-categorical": "ctg-age-customized",
      "eudract-gender-categorical": "ctg-gender-customized"
    }
  }
}

export const moveLeftHelper = (tempData, registry, key, i) => {
  let tempKeyData = [...tempData[registry][key]]
  let saved = tempKeyData[i-1];
  tempKeyData[i-1] = tempKeyData[i];
  tempKeyData[i] = saved;
  tempData[registry][key] = [...tempKeyData];
}

export const moveRightHelper = (tempData, registry, key, i) => {
  let tempKeyData = [...tempData[registry][key]]
  let saved = tempKeyData[i+1];
  tempKeyData[i+1] = tempKeyData[i];
  tempKeyData[i] = saved;
  tempData[registry][key] = [...tempKeyData];
}

export const getBaselineMeasureFieldType = (title, parameterType, dispersionType) => {
  if(title === "Number Analyzed") {
    return {
      participants: 0
    }
  }
  if(parameterType === "Count of Participants" || parameterType === "Subjects") {
    return {
      value: 0
    }
  }
  return {
    value: 0
  }
}

export const isStatisticalAnalysisSet = () => {
  
}