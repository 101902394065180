import useGetApi from "../useGetApi"
import useMutateApi from "../useMutateApi"

// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

// utils
import getUrl from "api/apiMap"

export const useGetSponsorTypes = () => {
  return useGetApi(getUrl(apiEndpoints.sponsors), queryKeys.sponsors)
}

// mutate({ body })
export const usePostSponsorType = () => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(apiEndpoints.sponsors),
    invalidateQueries: [queryKeys.sponsors],
    successMsg: "Successfully created sponsor type"
  })
}

// mutate({ id, body })
export const usePutSponsorType = () => {
  return useMutateApi({
    method: "put",
    apiEndpoint: getUrl(apiEndpoints.sponsors),
    invalidateQueries: [queryKeys.sponsors],
    successMsg: "Successfully updated sponsor type"
  })
}

// mutate({ id })
export const useDeleteSponsorType = () => {
  return useMutateApi({
    method: "delete",
    apiEndpoint: getUrl(apiEndpoints.sponsors),
    invalidateQueries: [queryKeys.sponsors],
    successMsg: "Successfully deleted sponsor type"
  })
}