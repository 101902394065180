import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useShallow } from 'zustand/react/shallow'

// components
import Modal from 'components/Modal/Modal';
import PrimeField from 'components/PrimeField/PrimeField';
import { Button } from 'components-design-system'
import Label from 'components/Label/Label';

// hooks
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';

const CreateArmModal = (props) => {
  const [createForAll, setCreateForAll] = useState(false);
  let { showModal, setShowModal, onAddArm, selectedArmType, source } = props;

  const { 
    formView,
  } = useAuthoringViewStore(
    useShallow(state => ({ 
      formView: state.formView,
    })),
  )

  const onSubmit = () => {
    if (formView.id === "global") onAddArm(selectedArmType, true);
    else onAddArm(selectedArmType, createForAll)
    setCreateForAll(false);
  }

  const renderNote = () => {
    let note = ``
    if (createForAll === false) {
      note += `Note:  If adding only to ${view.toUpperCase()}, this data may not appear in the Global view.`
    }
    
    if (source === "bc") {
      if (formView.id === "ctg" && createForAll) {
        note += `Note:  Adding an Arm to CTG Baseline Characteristics, adds a Subject Analysis Set to EudraCT Baseline Characteristics. To add an arm in EudraCT Baseline Characteristics, create the arm in EudraCT Subject Disposition and copy it to the Outcome Measure.`
      }
      if (formView.id === "eudra" && createForAll) {
        note += `Note:  Adding a Subject Analysis Set to EudraCT Baseline Characteristics, adds an Arm to CTG Baseline Characteristics.`
      }
    }

    if (source === "om") {
      if (formView.id === "ctg" && createForAll) {
        note += `Note:  Adding an Arm to CTG Outcome Measures, adds a Subject Analysis Set to EudraCT Outcome Measures. To add an arm in EudraCT Outcome Measures, create the arm in EudraCT Subject Disposition and copy it to the Outcome Measure.`
      }
      if (formView.id === "eudra" && createForAll) {
        note += `Note:  Adding a Subject Analysis Set to EudraCT Outcome Measures, adds an Arm to CTG Outcome Measures.`
      }
    }
    return <Label>{note}</Label>
  }

  let view = formView.id;
  if (formView.id === "eudra") view = "eudract";

  const renderTitle = () => {
    if(selectedArmType?.id === "create-new" && (source === "bc" || source === "om") && view === "eudract") return "Create Subject Analysis Set"
    else if(selectedArmType?.id === "create-new" && view === "ctg") return "Create Arm"
    else if(selectedArmType?.id === "create-overall-reporting-group" && source === "bc") return "Create Overall Reporting Group"
    // if ((source === "bc" || source === "om") && view === "eudract") 
    if(view === "eudract") return "Copy Arm(s)/Subject Analysis Set(s)"
    return "Copy Arm(s)"
  }

  return (
    <Modal
      title={renderTitle()}
      // description={`delete description`}
      open={showModal}
      onCancel={() => setShowModal(false)}
      footer={[]}>
      <ModalContent>
        {
          formView.id === "global"
            ? <ContentContainer>
              Note: If applicable, creating items from Global view will create items for all registries.
              <br />
              Adding a new arm creates a Subject Analysis Set in EudraCT results form. To update the subject analysis set type, please go to EudraCT results.
            </ContentContainer>
            : <ContentContainer>
              <PrimeField
                schema={{
                  type: "checkbox",
                  label: "Create for all registries",
                }}
                onChange={e => setCreateForAll(e)}
                value={createForAll} />
              <PrimeField
                schema={{
                  type: "checkbox",
                  label: `Create for ${view.toUpperCase()} only`,
                }}
                value={true} />
              <br />
              {renderNote()}
            </ContentContainer>
        }
        <ActionContainer>
          <Button
            onClick={() => setShowModal(false)}
            variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={() => onSubmit()}
            variant="primary">
            Create
          </Button>
        </ActionContainer>
      </ModalContent>
    </Modal>
  );
};

export default CreateArmModal;

const Container = styled.div`

`

const ContentContainer = styled.div`
  margin: auto;
  margin-bottom: 32px;
`

const ModalContent = styled.div`

`

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 12px;

  p {
    font-size: 12px !important;
  }
`