import React from 'react';
import { create } from 'zustand'

const initialState = {
  baselineData: {
    ctg: {
      arms: [],
      baseline_measures: []
    },
    eudract: {
      arms: [],
      baseline_measures: []
    }
  },
  showBcUnitsAnalyzed: false,
}

// this store is for arms and interventions + cross ref table
const useResultsBaselineStore = create((set) => ({
  ...initialState,
  setBaselineData: (baselineData) => set({ baselineData }),
  setShowBcUnitsAnalyzed: (showBcUnitsAnalyzed) => set({ showBcUnitsAnalyzed }),
  resetResultsBaselineStore: () => set(initialState),
}))

export {
  initialState
}

export default useResultsBaselineStore;