const title = {
  padding: "5px 14px 5px 23px",
  backgroundColor: "#E0E0E0",
  fontWeight: "600",
  fontSize: 14,
  color: "#383E47",
  marginBottom: "16px"
}


export const newSiteUISchema = {
  facility: {
    "ui:options": {
      label: false,
      title: "Address",
      styles: {
        objInnerContainer: {
          width: "100%",
        },
        objTitle: title
      }
    },
    name: {
      "ui:options": {
        label: false,
        title: "Facility Name + Address",
        placeholder: "Facility name",
        required: true,
        invalidText: "This field is required to release the record",
        styles: {
          field: {
            width: "50%",
            marginRight: "40%",
            marginLeft: 23,
            marginBottom: 12
          }
        }
      },
      "ui:widget": "AutocompleteWidget",
    },
    address: {
      "ui:options": {
        styles: {
          objContainer: {
            width: "100%"
          },
          objInnerContainer: {
            width: "100%",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            paddingLeft: "23px"
          },
        }
      },
      city: {
        "ui:options": {
          label: false,
          title: "City",
          required: true,
          invalidText: "This field is required to release the record",
          placeholder: "City",
          styles: {
            field: {
              width: "30%",
              marginRight: "2%",
              marginBottom: 12
            }
          }
        },
      },
      state: {
        "ui:options": {
          label: false,
          title: "State",
          placeholder: "State",
          invalidText: "This field is required to release the record",
          styles: {
            field: {
              width: "30%",
              marginRight: "2%",
              marginBottom: 12
            }
          }
        },
      },
      country: {
        "ui:options": {
          label: false,
          title: "Country",
          required: true,
          invalidText: "This field is required to release the record",
          placeholder: "Country",
          styles: {
            field: {
              width: "30%",
              marginRight: "2%",
              marginBottom: 12
            }
          }
        },
      },
      zip: {
        "ui:options": {
          label: false,
          title: "Zip/Postal Code",
          placeholder: "Zip/Postal Code",
          invalidText: "This field is required to release the record",
          styles: {
            field: {
              width: "30%",
              marginRight: "2%",
              marginBottom: 12
            }
          }
        },
      }
    }
  },
  site_id: {
    "ui:options": {
      label: false,
      title: "Site ID",
      placeholder: "Site ID",
      required: true,
      invalidText: "This field is required to release the record",
      styles: {
        objTitle: title,
        field: {
          width: "50%",
          // marginRight: "40%",
          marginLeft: 23,
          marginBottom: 12
        }
      }
    },
  },
  site_number: {
    "ui:options": {
      label: false,
      title: "Site Number",
      placeholder: "Site number",
      styles: {
        objTitle: title,
        field: {
          width: "50%",
          // marginRight: "40%",
          marginLeft: 23,
          marginBottom: 12
        }
      }
    },
  },
  site_active_date: {
    "ui:options": {
      label: false,
      title: "Site Activation Date",
      placeholder: "Site activation date",
      styles: {
        objTitle: title,
        field: {
          width: "50%",
          // marginRight: "40%",
          marginLeft: 23,
          marginBottom: 12
        }
      }
    },
    "ui:widget": "DateWidget",
  },
  status: {
    "ui:options": {
      label: false,
      title: "Recruiting Status",
      placeholder: "Recruiting status",
      // required: true,
      // invalidText: "This field is required to release the record",
      styles: {
        objTitle: title,
        field: {
          width: "50%",
          // marginRight: "40%",
          marginLeft: 23,
          marginBottom: 12
        }
      }
    },
  },
  contact: {
    "ui:options": {
      label: false,
      title: "Primary Contact",
      styles: {
        objInnerContainer: {
          width: "100%",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          paddingLeft: "23px"
        },
        objTitle: title
      }
    },
    first_name: {
      "ui:options": {
        label: false,
        title: "First Name",
        placeholder: "First name",
        styles: {
          field: {
            width: "30%",
            marginRight: "2%"
          }
        }
      },
    },
    middle_name: {
      "ui:options": {
        label: false,
        title: "MI",
        placeholder: "MI",
        styles: {
          field: {
            width: "15%",
            marginRight: "2%",
            marginBottom: 12
          }
        }
      },
    },      
    last_name: {
      "ui:options": {
        label: false,
        title: "Last Name",
        placeholder: "Last name",
        styles: {
          field: {
            width: "30%",
            marginRight: "2%",
            marginBottom: 12
          }
        }
      },
    },
    phone: {
      "ui:options": {
        label: false,
        title: "Contact Phone",
        placeholder: "Contact phone",
        styles: {
          field: {
            width: "50%",
            marginRight: "2%",
            marginBottom: 12
          }
        }
      },
    },
    phone_ext: {
      "ui:options": {
        label: false,
        title: "Extension",
        placeholder: "Ext",
        styles: {
          field: {
            width: "15%",
            marginBottom: 12
          }
        }
      },
    },
    email: {
      "ui:options": {
        label: false,
        title: "Email",
        placeholder: "Email",
        styles: {
          field: {
            width: "50%",
            marginRight: "40%",
            marginBottom: 12
          }
        }
      },
    },
    degrees: {
      "ui:options": {
        label: false,
        title: "Degree",
        placeholder: "Degree",
        styles: {
          field: {
            width: "50%",
            marginRight: "40%",
            marginBottom: 12
          }
        }
      },
    },
  },
  contact_backup: {
    "ui:options": {
      label: false,
      title: "Backup site contact",
      styles: {
        objInnerContainer: {
          width: "100%",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          paddingLeft: "23px"
        },
        objTitle: title
      }
    },
    first_name: {
      "ui:options": {
        label: false,
        title: "First Name",
        placeholder: "First name",
        styles: {
          field: {
            width: "30%",
            marginRight: "2%",
            marginBottom: 12
          }
        }
      },
    },
    middle_name: {
      "ui:options": {
        label: false,
        title: "MI",
        placeholder: "MI",
        styles: {
          field: {
            width: "15%",
            marginRight: "2%",
            marginBottom: 12
          }
        }
      },
    },      
    last_name: {
      "ui:options": {
        label: false,
        title: "Last Name",
        placeholder: "Last name",
        styles: {
          field: {
            width: "30%",
            marginRight: "2%",
            marginBottom: 12
          }
        }
      },
    },
    phone: {
      "ui:options": {
        label: false,
        title: "Contact Phone",
        placeholder: "Contact phone",
        styles: {
          field: {
            width: "50%",
            marginRight: "2%",
            marginBottom: 12
          }
        }
      },
    },
    phone_ext: {
      "ui:options": {
        label: false,
        title: "Extension",
        placeholder: "Ext",
        styles: {
          field: {
            width: "15%",
            marginBottom: 12
          }
        }
      },
    },
    email: {
      "ui:options": {
        label: false,
        title: "Email",
        placeholder: "Email",
        styles: {
          field: {
            width: "50%",
            marginRight: "40%",
            marginBottom: 12
          }
        }
      },
    },
    degrees: {
      "ui:options": {
        label: false,
        title: "Degree",
        placeholder: "Degree",
        styles: {
          field: {
            width: "50%",
            marginRight: "40%",
            marginBottom: 12
          }
        }
      },
    },
  },
  investigator: {
    "ui:options": {
      title: "Investigators",
      itemTitle: "Investigator",
      addButtonTitle: "Add Investigator",
      styles: {
        arrTitle: {
          background: "#E0E0E0",
          padding: "5px 14px 5px 23px",
          fontWeight: "600",
          fontSize: 14,
          color: "#383E47"
        },
        itemTitle: {
          fontFamily: "Open Sans",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: 14,
          color: "#000000",
          marginTop: 20,
          marginBottom: 9
        },
        addButton: { margin: 23 },
        deleteButton: {
          position: "absolute",
          right: 30,
          top: 24,
          color: "#D7542C",
          cursor: "pointer"
        },
        arrInnerContainer: {
          position: 'relative',
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          paddingLeft: 23,
          borderBottom: "1px solid #e4e4e4"
        }
      }
    },
    items: {
      "ui:options": {
        styles: {
          objInnerContainer: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          },
        }
      },
      mask: {
        "ui:options": {
          label: false,
          title: "Mask in CTG Submission",
          styles: {
            field: {
              width: "50%",
              marginRight: "40%",
              marginBottom: 12
            }
          }
        },
        "ui:widget": "CheckBoxWidget"
      },
      first_name: {
        "ui:options": {
          label: false,
          title: "First Name",
          placeholder: "First name",
          styles: {
            field: {
              width: "30%",
              marginRight: "2%",
              marginBottom: 12
            }
          }
        },
      },
      middle_name: {
        "ui:options": {
          label: false,
          title: "MI",
          placeholder: "MI",
          styles: {
            field: {
              width: "15%",
              marginRight: "2%",
              marginBottom: 12
            }
          }
        },
      },      
      last_name: {
        "ui:options": {
          label: false,
          title: "Last Name",
          placeholder: "Last name",
          styles: {
            field: {
              width: "30%",
              marginRight: "2%",
              marginBottom: 12
            }
          }
        },
      },
      role: {
        "ui:options": {
          label: false,
          title: "Role",
          placeholder: "Role",
          styles: {
            field: {
              width: "50%",
              marginRight: "40%",
              marginBottom: 12
            }
          }
        }
      },
      degrees: {
        "ui:options": {
          label: false,
          title: "Degree",
          placeholder: "Degree",
          styles: {
            field: {
              width: "50%",
              marginRight: "40%",
              marginBottom: 12
            }
          }
        }
      },
    }
  },
}