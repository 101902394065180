// Used by Site Management page and Study Overview Locations tab
import React, { useEffect, useState, memo, useMemo } from 'react';
import moment from 'moment';

// Components
import { Button } from 'components-design-system'


import "./HeaderActions.scss"
import "styles/global-animations.scss"

// context
import useAuthoringValidationActions from 'containers/studies/Study/subcomponents/Authoring/hooks/actions/useAuthoringValidationActions';
import useAppState from 'context/hooks/useAppState';

// helpers/utils
import usePrsUpload from 'utilities/usePrsUpload/usePrsUpload';

const HeaderActions = ({
  prsUploadStudyList,
  onRegenerate,
  setTriggerExport,
  isUploadDisabled,
  studyInfo,
  canUploadToPRS,
  source,
  useOnGetPrsUpload,
  useOnPostPrsUpload,
  showExport,
  showValidate,
  lastImportDate,
  callbackAfterUpload,
  uploadToPrsMsg = "Send all protocol maintenance updates to PRS. If you would like to release data directly to CTG check the checkbox below.",
  studyData
}) => {
  const {
    isUploading,
    UploadButton,
    UploadModals,
    CancelButton
  } = usePrsUpload({
    useOnGetPrsUpload,
    useOnPostPrsUpload,
    isUploadDisabled,
    prsUploadStudyList,
    uploadToPrsMsg,
    callbackAfterUpload
  })

  const [isRegenerating, setIsRegenerating] = useState(false)
  const [regenClassNames, setRegenClassNames] = useState(["fal fa-redo icons regen-icon"])

  const {
    onRegistryValidate,
  } = useAuthoringValidationActions(studyData?.authoringId)

  const { taskType } = useAppState()

  useEffect(() => {
    if (taskType === source + "-regenerate") {
      setRegenClassNames([...regenClassNames, "spin-active"])
      setIsRegenerating(true)
    } else {
      setRegenClassNames(["fal fa-redo icons regen-icon"])
      setIsRegenerating(false)
    }
  }, [taskType])

  const displayRegenButton = useMemo(() => {
    let date, tz, shortNameTZ, time
    if (lastImportDate) {
      date = new Date(lastImportDate).toLocaleString();
      tz = new Date(lastImportDate).toString().match(/\(([A-Za-z\s].*)\)/)[1];
      shortNameTZ = tz.split(" ").map(word => word[0]).join("")
      time = <span>{moment(lastImportDate).fromNow()}:<br />{date.toLocaleString()} {shortNameTZ}</span>
    }

    return (
      <Button
        variant="outlined"
        size="sm"
        style={{ marginLeft: 14, display: "flex", alignItems: "center" }}
        disabled={isRegenerating}
        onClick={onRegenerate}
        tooltip={time ? <>Last Genererated {time}</> : undefined}
      >
        <i className={regenClassNames.join(" ")} />
        Regenerate PRS Data
      </Button>
    )
  }, [taskType, isRegenerating, lastImportDate])

  return (
    <div className="header-actions">
      <div className="inner-header-actions">
        {showValidate && <Button
          variant={"icon"}
          size="sm"
          onClick={onRegistryValidate}
          tooltip={"Validate"}>
          <i className="fal fa-file-check" />
        </Button>}
        {showExport && <Button
          variant={"icon"}
          size="sm"
          onClick={() => setTriggerExport(true)}
          tooltip={"Export"}>
          <i className="fal fa-file-export"></i>
        </Button>}
        {displayRegenButton}
        {canUploadToPRS && UploadButton}
        {UploadModals}
      </div>
      {isUploading && CancelButton}
    </div>
  );
};

export default memo(HeaderActions);