import { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { cloneDeep, uniq } from 'lodash';
import { useShallow } from 'zustand/react/shallow'

// components
import PrimeField from 'components/PrimeField/PrimeField';
import Label from 'components/Label/Label';
import Arm from '../Arm/Arm';
import Sas from '../Sas/Sas';
import { Button } from 'components-design-system';
import CreateArmModal from '../CreateArmModal/CreateArmModal';
import CellValidation from '../../../components/CellValidation/CellValidation';

// utils
import { getBaselineMeasureFieldType } from '../../helpers/helpers';

// context
import useResultsTableArmsStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableArmsStore';
import useResultsTableViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableViewStore';
import useResultsBaselineStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsBaselineStore';
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';
import useAuthoringDataStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringDataStore';

const Arms = ({
  arms,
  sasArr,
  schema,
  baselineData,
  setBaselineData
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedArmType, setSelectedArmType] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const {
    formData,
  } = useAuthoringDataStore(
    useShallow(state => ({
      formData: state.formData,
    }))
  )

  const {
    readOnly
  } = useAuthoringViewStore(
    useShallow(state => ({
      readOnly: state.readOnly
    }))
  )

  const {
    baselineSas,
    setBaselineSas,
    baselineArmOptions
  } = useResultsTableArmsStore()

  const {
    tableView
  } = useResultsTableViewStore()

  const {
    showBcUnitsAnalyzed,
    setShowBcUnitsAnalyzed
  } = useResultsBaselineStore()

  const onAddArm = (selectedItem, createForAll) => {
    const filterDuplicateArms = (a, b) => {
      let ret = []
      b.forEach(bItem => {
        let foundItem = a.find(aItem => aItem.id === bItem.id);
        if (!foundItem) ret.push(bItem)
      })

      return ret;
    }

    const addToArms = (newArms) => {
      let ctgProps = baselineData["ctg"];
      let newBaselineMeasures = [];

      ctgProps?.baseline_measures?.forEach(bm => {
        let newBaselineMeasure = { ...bm };
        bm.rows.forEach(row => {
          row.categories.forEach(cat => {
            let values = [...cat.values];
            newArms.map(arm => {
              let foundIndex = values.findIndex(value => value.arm_id === arm.id);
              if (foundIndex === -1) {
                values.push({
                  arm_id: arm.id,
                  ...getBaselineMeasureFieldType(cat.title, bm.parameter_type, bm.dispersion_type),
                })
              }
            })
            cat.values = values;
          })
        })
        newBaselineMeasures.push(newBaselineMeasure)
      })

      let currArms = [];
      if (baselineData["ctg"].arms) {
        currArms = baselineData["ctg"].arms
      }

      ctgProps.arms = [...currArms, ...newArms];
      ctgProps.baseline_measures = [...newBaselineMeasures];

      return ctgProps;
    }

    const addToSubjectAnalysisSet = (newSet) => {
      let eudractProps = baselineData["eudract"];
      let newBaselineMeasures = [];

      eudractProps?.baseline_measures.forEach(bm => {
        let newBaselineMeasure = { ...bm };
        bm.rows.forEach(row => {
          row.categories.forEach(cat => {
            let values = [...cat.values];
            newSet.map(sas => {
              let foundIndex = values.findIndex(value => value.arm_id === sas.id);
              if (foundIndex === -1) {
                values.push({
                  arm_id: sas.id,
                  ...getBaselineMeasureFieldType(cat.title, bm.parameter_type, bm.dispersion_type),
                })
              }
            })
            cat.values = values;
          })
        })
        newBaselineMeasures.push(newBaselineMeasure)
      })

      let updatedSet = []
      if (baselineData["eudract"].subject_analysis_sets) {
        updatedSet = [...baselineData["eudract"].subject_analysis_sets, ...newSet]
      } else {
        updatedSet = [...newSet]
      }

      eudractProps.subject_analysis_sets = updatedSet;
      eudractProps.baseline_measures = [...newBaselineMeasures];

      setBaselineSas(uniq([...baselineSas, ...eudractProps.subject_analysis_sets], "id"))

      return eudractProps;
    }

    const doesOverallReportingGroupExist = () => {
      return baselineData[tableView]?.arms?.findIndex(arm => arm.title === "Overall Reporting Group" || arm.id === "overall-reporting-group") !== -1
    }

    const deleteArmColumns = (tempData) => {
      // delete existing arm ingo
      Object.keys(tempData).forEach(registry => {
        if (createForAll === true || registry === tableView) {
          tempData[registry].arms = [];
          tempData[registry].subject_analysis_sets = []

          // delete baseline measure values
          tempData[registry]?.baseline_measures?.forEach(bm => {
            bm.rows.forEach(row => {
              row.categories.forEach(category => {
                category.values = [];
              })
            })
          })
        }
      });
    }

    const getArmTemplate = (id, type = "arm") => { // type: "arm" || "sas"
      if (type === "arm") {
        return {
          id: "Baseline-BaselineReportingGroup." + id,
          registry_arm_id: "Baseline-BaselineReportingGroup." + id,
          title: "",
          description: "",
          baseline_analysis_population: 0
        }
      } else if (type === "sas") {
        return {
          id: "Baseline-BaselineReportingGroup." + id,
          registry_arm_id: "Baseline-BaselineReportingGroup." + id,
          title: "",
          description: "",
          baseline_analysis_population: 0,
          is_subject_analysis_set: true,
          type: "Full analysis"
        }
      }
    }

    const createCustomArms = (newArms, newSets) => {
      let tempData = { ...baselineData };

      if (doesOverallReportingGroupExist()) {
        deleteArmColumns(tempData)
      }

      let ctgProps = addToArms(newArms);
      let eudractProps = addToSubjectAnalysisSet(newSets);

      tempData = {
        ctg: {
          ...tempData.ctg,
          ...ctgProps
        },
        eudract: {
          ...tempData.eudract,
          ...eudractProps
        }
      }

      setBaselineData(tempData)
    }

    const copyArms = (newArms) => {
      let tempData = { ...baselineData };
      if (doesOverallReportingGroupExist()) {
        deleteArmColumns(tempData)
      }

      Object.keys(tempData).forEach(registry => {
        if (createForAll === true || registry === tableView) {
          let registryArms = [...tempData[registry].arms];
          let filteredArms = [...filterDuplicateArms(registryArms, newArms)];

          let baselineMeasures = tempData[registry].baseline_measures;
          let newBaselineMeasures = []
          baselineMeasures?.forEach(bm => {
            let newBaselineMeasure = { ...bm }

            bm.rows.forEach(row => {
              row.categories.forEach(category => {
                let values = [...category.values];
                filteredArms.map(arm => {
                  let foundIndex = values.findIndex(value => value.arm_id === arm.id);

                  if (foundIndex === -1) {
                    values.push({
                      arm_id: arm.id,
                      ...getBaselineMeasureFieldType(category.title, bm.parameter_type, bm.dispersion_type),
                    })
                  }
                })

                category.values = values;
              })
            })
            newBaselineMeasures.push(newBaselineMeasure)
          })

          let arms = [...tempData[registry].arms, ...filteredArms]

          tempData[registry] = {
            ...tempData[registry],
            arms,
            baseline_measures: [...newBaselineMeasures]
          }

          if(registry === "ctg") {
            let total = arms?.reduce((acc, arm) => {
              if (arm?.baseline_analysis_population) return acc + Number(arm.baseline_analysis_population)
              return acc;
            }, 0)
      
            tempData["ctg"].total_baseline_reporting_group = {
              subjects_analyzed: total
            }
          }
        }
      })

      setBaselineData(tempData)
    }

    const createReportingGroup = (arm) => {
      let tempData = { ...baselineData };

      deleteArmColumns(tempData)

      // create reporting group
      Object.keys(baselineData).forEach(key => {
        if (createForAll === true || key === tableView) {
          let filteredArms = [arm];

          let baselineMeasures = baselineData[key].baseline_measures;
          let newBaselineMeasures = []
          baselineMeasures?.forEach(bm => {
            let newBaselineMeasure = { ...bm }

            bm.rows.forEach(row => {
              row.categories.forEach(category => {
                let values = [...category.values];
                filteredArms.map(arm => {
                  let foundIndex = values.findIndex(value => value.arm_id === arm.id);

                  if (foundIndex === -1) {
                    values.push({
                      arm_id: arm.id,
                      ...getBaselineMeasureFieldType(category.title, bm.parameter_type, bm.dispersion_type),
                    })
                  }
                })

                category.values = values;
              })
            })
            newBaselineMeasures.push(newBaselineMeasure)
          })

          tempData[key] = {
            ...baselineData[key],
            arms: [...baselineData[key].arms, ...filteredArms],
            baseline_measures: [...newBaselineMeasures]
          }
        }
      })

      setBaselineData(tempData);
    }

    switch (selectedItem.id) {
      case "copy-all-pf":
        if (formData?.results?.participant_flow?.participant_flow_table?.global_arms) {
          let pfArms = formData.results?.participant_flow.participant_flow_table.global_arms.map(arm => {
            let oldArmIds = arm.id.split(".")
            let newId = arm.id;
            if (oldArmIds[1]) {
              newId = "Baseline-BaselineReportingGroup." + oldArmIds[1];
            }
            return {
              ...arm,
              baseline_analysis_population: 0,
              id: newId
            }
          })
          copyArms(pfArms);
          onHideModal()
        }
        return
      case "copy-all":
        return
      case "create-new":
        let id = uuid();
        let sas = getArmTemplate(id, "sas")
        let arm = getArmTemplate(id, "arm")

        if (tableView === "eudract") {
          createCustomArms([], [sas])
        } else {
          createCustomArms([arm], [sas])
        }

        setShowDropdown(false);
        onHideModal()
        return
      case "create-overall-reporting-group":
        let group = {
          id: "overall-reporting-group",
          title: "Overall Reporting Group",
          description: "",
          baseline_analysis_population: 0
        }
        createReportingGroup(group)
        onHideModal()
        break
      default:
        let foundPeriod = formData?.results?.participant_flow?.participant_flow_table?.eudract_periods?.find(period => period.id === selectedItem.id);
        if (foundPeriod) {

          let pfArms = cloneDeep(foundPeriod.arms.map((arm, colIndex) => {
            let oldArmIds = arm.id.split(".")
            let newId = arm.id;
            if (oldArmIds[1]) {
              newId = "Baseline-BaselineReportingGroup." + oldArmIds[1];
            }

            let startedParticipants = foundPeriod.milestone_list[0].participants[colIndex]?.value
            return {
              ...arm,
              baseline_analysis_population: startedParticipants,
              id: newId
            }
          }));

          copyArms(pfArms);
          onHideModal()
        }
        return
    }
  }

  const deleteUnitsAnalyzed = () => {
    let tempData = { ...baselineData }

    const updateParticipants = (registry, key, group) => {
      tempData[registry]?.baseline_measures?.forEach(bm => {
        bm.rows.forEach(row => {
          let catIndex = row.categories.findIndex(item => item.id === "num-analyzed");
          if (catIndex !== -1) {
            let colIndex = row.categories[catIndex].values.findIndex(cat => cat.arm_id === group.id);
            if (colIndex !== -1 && key === "num_units_analyzed") {
              if (row.categories[catIndex].values[colIndex].num_units_analyzed == group[key] ||
                (!row.categories[catIndex].values[colIndex].num_units_analyzed) && !group[key]) {
                row.categories[catIndex].values[colIndex].num_units_analyzed = null;
              }
            }
          }
        })
      })
    }

    tempData?.ctg?.arms?.forEach(arm => {
      if (arm?.num_units_analyzed) {
        updateParticipants("ctg", "num_units_analyzed", arm);
        arm["num_units_analyzed"] = null;
      }
    })

    setBaselineData(tempData)
    setShowBcUnitsAnalyzed(false)
  }

  const displayActions = () => {
    let colSpan = arms.length ? arms.length + 4 : 3

    return (
      <tr>
        <td
          className="disabled-row text-left"
          colSpan={colSpan}>
          {readOnly !== true &&
            <Button
              variant="primary-dashed"
              size="sm"
              onClick={showBcUnitsAnalyzed ? deleteUnitsAnalyzed : () => setShowBcUnitsAnalyzed(true)}>
              {showBcUnitsAnalyzed ? "Delete Units Analyzed -" : "Add Units Analyzed +"}
            </Button>
          }
        </td>
      </tr>
    )
  }

  const displayCTGArmTotals = (key, i) => {
    const onChangeTotalCTGParticipants = (e) => {
      let tempData = { ...baselineData };
      let total = arms?.reduce((acc, arm) => {
        if (arm?.baseline_analysis_population) return acc + Number(arm.baseline_analysis_population)
        return acc;
      }, 0)

      tempData["ctg"].total_baseline_reporting_group = {
        subjects_analyzed: total
      }
      setBaselineData(tempData);
    }

    if (key === "baseline_analysis_population") {
      let total = arms?.reduce((acc, arm) => {
        if (arm?.baseline_analysis_population) return acc + Number(arm.baseline_analysis_population)
        return acc;
      }, 0)

      return (
        <td className="total-col">
          <PrimeField
            schema={{
              type: "number",
              sublabel: "Total",
              orientation: "vertical",
              // size: "small",
            }}
            readOnly={readOnly}
            value={total}
            onChange={onChangeTotalCTGParticipants} />
        </td>
      )
    } else if (key === "num_units_analyzed") {
      let totalUnits = arms?.reduce((acc, arm) => {
        if (arm?.num_units_analyzed) return acc + Number(arm.num_units_analyzed)
        return acc;
      }, 0)
      return (
        <td className="total-col">
          <Label style={{ marginBottom: ".3rem" }}>Total: {totalUnits}</Label>
        </td>
      )
    } else if (i === 0) {
      return (
        <td className="cell-disabled total-col" rowSpan={2}></td>
      )
    }
    return null;
  }

  const onChangeTypeUnitsAnalyzed = (e) => {
    let tempData = { ...baselineData };
    tempData["ctg"].type_units_analyzed = e;
    setBaselineData(tempData);
  }

  const displayArmRows = () => {
    const displayArms = (fieldSchema, key, rowIndex) => {
      return arms.filter(() => {
        if (key === "num_units_analyzed") {
          return showBcUnitsAnalyzed
        } else {
          return true
        }
      }).map((arm, colIndex) => {
        return (
          <Arm
            key={arm?.id + colIndex + rowIndex}
            colIndex={colIndex}
            arm={arm}
            arms={arms}
            rowIndex={rowIndex}
            fieldKey={key}
            schema={fieldSchema}
            baselineData={baselineData}
            setBaselineData={setBaselineData} />
        )
      })
    }

    const rows = Object.keys(schema.arms.properties).filter((key) => {
      if (key === "num_units_analyzed") {
        return showBcUnitsAnalyzed
      } else {
        return true
      }
    }).map((key, rowIndex) => {
      let fieldSchema = schema.arms.properties[key];

      return (
        <tr key={`bm-arms-${tableView}-${key}-${rowIndex}`}>
          <td
            className="header-column"
            colSpan={2}>
            {fieldSchema.title}
          </td>
          {displayArms(fieldSchema, key, rowIndex)}
          {tableView === "ctg" && arms?.length > 0 && displayCTGArmTotals(key, rowIndex)}
          {rowIndex === 0 && readOnly !== true &&
            <td
              className='text-center add-arm-action-col'
              rowSpan={3}>
              {showDropdown
                ?
                <PrimeField
                  schema={{
                    items: baselineArmOptions.filter(option => {
                      if (tableView === "eudract" && option?.id === "copy-all-pf") return false
                      return true
                    }),
                    type: "dropdown",
                    size: "small",
                    placeholder: "Select Arm"
                  }}
                  readOnly={readOnly}
                  value={null}
                  onChange={e => onShowModal(e)} />
                : <i
                  onClick={() => setShowDropdown(!showDropdown)}
                  className="fal fa-plus-circle add-arm-icon" />
              }
            </td>
          }
        </tr>
      )
    })

    if (tableView === "ctg" && showBcUnitsAnalyzed) {
      let fieldSchema = schema.arms.type_units_analyzed;
      let armsLength = baselineData?.[tableView]?.arms?.length || 0

      rows.push(
        <tr key={`bm-arms-${tableView}-type-units-analyzed`}>
          <td
            colSpan={2}
            className="header-column">
            {fieldSchema.title}
          </td>
          <td colSpan={armsLength > 1 ? 2 : armsLength + 1}>
            <PrimeField
              value={baselineData?.["ctg"]?.type_units_analyzed || ""}
              schema={fieldSchema}
              readOnly={readOnly}
              onChange={(e) => onChangeTypeUnitsAnalyzed(e)} />
          </td>
        </tr>
      )
    }

    return rows;
  }

  const displaySubjectAnalysisSetRows = () => {
    let rows = [];

    Object.keys(schema.subject_analysis_sets.properties).forEach((key, rowIndex) => {
      let fieldSchema = schema.subject_analysis_sets.properties[key];
      rows.push(
        <tr key={`bm-subject-analysis-sets-${tableView}-${key}-${rowIndex}`}>
          <td
            className="header-column subject-analysis-set"
            colSpan={2}>
            {fieldSchema.title}
          </td>
          {baselineData[tableView]?.subject_analysis_sets?.map((sas, colIndex) => {
            if (sas) {
              return (
                <Sas
                  key={`bm-subject-analysis-sets-${tableView}-${key}-${rowIndex}-${colIndex}`}
                  sas={sas}
                  fieldKey={key}
                  sasArr={sasArr}
                  schema={fieldSchema}
                  rowIndex={rowIndex}
                  colIndex={colIndex}
                  baselineData={baselineData}
                  setBaselineData={setBaselineData} />
              )
            }
          })}
        </tr>
      )
    })

    return rows;
  }

  const onShowModal = (selectedItem) => {
    let foundItem = baselineArmOptions.find(item => item.id === selectedItem)
    setSelectedArmType(foundItem)
    setShowModal(true)
  }

  const onHideModal = () => {
    setSelectedArmType(null)
    setShowModal(false)
  }

  const displayCTGPopulationAnalysis = () => {
    let fieldSchema = schema.population_analysis_description;

    const onChange = (e) => {
      let tempData = { ...baselineData }
      tempData[tableView].population_analysis_description = e;
      setBaselineData(tempData);
    }

    return (
      <tr>
        <td
          colSpan={2}
          className="header-column">
          {fieldSchema?.title}
        </td>
        <td
          colSpan={2}>
          <PrimeField
            schema={fieldSchema}
            readOnly={readOnly}
            value={baselineData?.[tableView]?.population_analysis_description || ""}
            onChange={e => onChange(e)}
          />
          <CellValidation
            table="baseline"
            errorKey={`results_baseline_characteristics_baseline_characteristics_table_${tableView}_population_analysis_description`} />
        </td>
      </tr>
    )
  }

  return (
    <>
      {tableView === "ctg" && displayActions()}
      {displayArmRows()}
      {tableView === "eudract" && sasArr?.length > 0 && displaySubjectAnalysisSetRows()}
      {tableView === "ctg" && displayCTGPopulationAnalysis()}
      <CreateArmModal
        onAddArm={onAddArm}
        showModal={showModal}
        setShowModal={onHideModal}
        selectedArmType={selectedArmType}
        source="bc" />
    </>
  );
};

export default Arms;