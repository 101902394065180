import React from 'react'
import NotificationsList from '../../NotificationsList/NotificationsList'
import SidePanel from '../../../SidePanel/SidePanel'
import './NotificationsMenuItem.scss'

// apis
import { useUserInfo } from 'api/hooks'
// apis
import {
  useGetNotifications
} from 'api/hooks/notifications/useNotificationsApi'

const NotificationsMenuItem = ({
  notifPanelOpen,
  setNotifPanelOpen,
  fixedScrollAtHeight,
}) => {
  const { data: notifications } = useGetNotifications()

  const { data: userInfo } = useUserInfo()

  return <>
    <div
      className={`icon-wrapper notifications${notifications?.unread_count > 0 ? ' has-badge' : ''}`}
    >
      <i
        className="fal fa-bell"
        role="button"
        id="notifMenuLink"
        aria-haspopup="true"
        onClick={() => setNotifPanelOpen((val) => !val)}
      />
    </div >
    <SidePanel
      isOpen={notifPanelOpen}
      setIsOpen={setNotifPanelOpen}
      title="Notifications"
      width={350}
      fixedPosition="right"
      fixedScrollAtHeight={fixedScrollAtHeight}
    >
      <NotificationsList />
    </SidePanel>
  </>
}

NotificationsMenuItem.propTypes = {}

export default NotificationsMenuItem