import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'
import './AssessmentsTagsCellRenderer.scss'

const AssessmentsTagsCellRenderer = ({
  data,
  value,
  ...props
}) => {
  if (!value?.length) return null

  return <Tooltip
    overlayClassName="assess-tags-cell-render-tooltip"
    title={
      <>
        Tags:
        <ul>
          {value.map((tag) => <li key={tag}>{tag}</li>)}
        </ul>
      </>
    }>
    <div className="assessments-tags-cell-renderer">
      <i className="fas fa-tag" />
    </div>
  </Tooltip>
}

AssessmentsTagsCellRenderer.propTypes = {

}

export default AssessmentsTagsCellRenderer