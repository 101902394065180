import React, { useState, useEffect, memo} from 'react'
import styled from 'styled-components'
import { Tooltip } from 'antd'

const ContactComparator = props => {
  const { value, data } = props;
  const { field } = props.colDef
  const [display, setDisplay] = useState(null);

  useEffect(() => {
    let source = data.location_data.source;
    let mask = data.location_data.mask;
    let ctg = data.location_data.ctg;

    let sourceVal = null;
    let ctgVal = null;
    let maskVal = null;
    
    if(source && source[field]) {
      sourceVal = formatContact(source[field]);
    }
    if(ctg && ctg[field]) {
      ctgVal = formatContact(ctg[field]);
    }
    if(mask && mask[field]) {
      maskVal = formatContact(mask[field]);
    }

    let component = processValues(sourceVal, ctgVal, maskVal)
    setDisplay(component);

  }, [value, data]);

  const formatContact = ({
    first_name = "",
    middle_name = "",
    last_name = "",
    degrees = "",
    phone = "",
    phone_ext = "",
    email = "",
  }) => {
    let fullName = [first_name, middle_name, last_name].filter(item => item && item.length > 0).join(" ").trim();
    let fullNameDegree = [fullName, degrees].filter(item => item && item.length > 0).join(", ").trim();
    let phoneNumber = [phone, phone_ext].filter(item => item && item.toString().length > 0).join(" ext: ").trim();

    return [fullNameDegree, phoneNumber, email].filter(str => str && str.length > 0).join("\n");
  }

  const processValues = (source, ctg, mask) => {
    if((mask !== undefined && mask !== null) && ctg) {  // if MASK + CTG data is available
      if(mask == ctg) {  // if MASK + CTG data is equal
        return getComponent(mask, "masked", "Value Overriden Source Value: " + source + "; MASK value = CTG value", true);
      } else { // if MASK + CTG data is NOT equal
        let maskComponent = getComponent(mask, "masked", "Value Overriden Source Value: " + source, true);
        let ctgComponent = getComponent(ctg, "ctg-only", "CTG value");
        return [maskComponent, ctgComponent];
      }
    } else if(mask !== undefined && mask !== null) {  // only MASK data
      return getComponent(mask, "masked", "Value Overriden Source Value: " + source, true);
    } else if(ctg && source) { // if Source + CTG data is available
      if(ctg == source) { // if Source + CTG data is equal
        return getComponent(source, "equal", "Prime value = CTG value");
      } else { // if Source + CTG data is NOT equal
        let sourceComponent = getComponent(source, "source-only", "Prime value");
        let ctgComponent = getComponent(ctg, "ctg-only", "CTG Value");
        return [sourceComponent, ctgComponent];
      }
    } else if(source) { // if Source data only
      return getComponent(source, "source-only", "Prime value")
    } else if(ctg) { // if CTG data only
      return getComponent(ctg, "ctg-only", "CTG Value")
    }
    return null;
  }

  const getComponent = (value = "", type, tooltip, showMask = false) => {
    return <Tooltip
      title={<InfoContainer>
        {tooltip}
      </InfoContainer>}
    >
      <Text type={type}>
        {showMask && <MaskIcon className="fas fa-eye" />}
        {value}
      </Text>
    </Tooltip>
  }

  return (
    <Container>
      {display}
    </Container>
  )
}

export default memo(ContactComparator);

const getColor = (type) => {
  switch(type) {
    case "ctg-only": return "#D7542C";
    case "source-only": return "#70AD2E";
    case "masked": return "#8D8D8D";
    default: return "#383E47";
  }
}

const getTextDecoration = (type) => {
  if(type === "ctg-only") return "line-through";
  return "default";
}

const Container = styled.div`
  word-wrap: break-word;
`

const InfoContainer = styled.div`
  padding: 6px;
  font-size: 12px;
  white-space: normal;
  text-align: left;
`

const Text = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  // height: 14px;
  line-height: 14px;
  letter-spacing: 0.16px;
  text-decoration-line: ${props => getTextDecoration(props.type)};
  color: ${props => getColor(props.type)};
  white-space: pre-wrap;
  display: flex;
  flex-direction: row;
`

const MaskIcon = styled.i`
  color: #8D8D8D;
  margin-right: 6px;
`