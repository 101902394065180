import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'
import { debounce } from 'lodash';
import { Select } from 'components-design-system';

// apis
import { useStudySearchApi } from 'api/hooks/global';
import useAutoClose from 'utilities/useAutoClose';

// styles
import './useStudySearch.scss'

const useStudySearch = () => {
  const navigate = useNavigate()
  const studySearch = useStudySearchApi()
  // search for study ID
  const [studyResults, setStudyResults] = useState([])
  const [studyOptions, setStudyOptions] = useState([])

  // searchValue ensures dropdown is closed when no search string but options are available:
  const [searchValue, setSearchValue] = useState('')
  // dropdownVisible ensures dropdown is closed when user clicks outside component:
  const [dropdownVisible, setDropdownVisible] = useState(false)

  const dropdownRef = useRef();
  useAutoClose(dropdownRef, setDropdownVisible);

  const handleStudySearch = (value) => {
    let searchValue = value
    setSearchValue(value)
    if (!value) searchValue = null;
    debouncedSearch.current(searchValue)
  }

  // find all studies for study ID search
  const getStudiesForSearch = (value) => {
    if (typeof value === "string") {
      studySearch({ body: { filter_val: value } }, {
        onSuccess: res => {
          if (res?.data?.results) {
            setStudyResults(res.data.results)
            const studyIds = res.data.results.map((study) => ({
              value: study.id,
              label: study.study_id
            }))
            setStudyOptions(studyIds)
          }
        }
      })
    }
  }

  useEffect(() => {
    if(!searchValue || searchValue.length === 0) {
      setDropdownVisible(false)
    } else if (Array.isArray(studyOptions) && studyOptions.length > 0) {
      setDropdownVisible(true)
    }
  }, [studyOptions, searchValue])

  const debouncedSearch = useRef(debounce(getStudiesForSearch, 800));

  const StudySearch = (
    <div className="study-search-wrap" ref={dropdownRef}>
      <Select
        options={studyOptions}
        placeholder="Study ID, NCT ID, or EudraCT ID"
        size="large"
        onSearch={(value) => handleStudySearch(value)}
        value={null} // reset value when empty
        open={dropdownVisible}
        onChange={(value) => {
          navigate(`/study/${value}/`)
          setSearchValue('')
          setStudyOptions([])
          setDropdownVisible(false)
        }}
        className="study-search-select-wrap" // wrapperClassName
        selectClassname="study-search-select"
        showSearch
        allowClear={false}
        showArrow={false}
      />
      <i
        type="button"
        className="fas fa-search"
        aria-label="toggle menu"
      />
    </div>
  )

  return {
    StudySearch,
    studyResults,
    studyOptions,
    // getOpenState,
    searchValue,
    dropdownVisible,
    handleStudySearch
  }
};

export default useStudySearch;