import React, { useState } from 'react';
// import { Modal } from 'antd';
import Modal from 'components/Modal/Modal';
import PrimeField from 'components/PrimeField/PrimeField'

import fileDownload from 'utilities/fileDownload'
// import { getPrsPreview } from 'api/authoringStudy';

// apis
import { usePostPrsPreview } from 'api/hooks/studies/useAuthoringApi';

// context
import useAppState from 'context/hooks/useAppState';

const DownloadPreviewModal = ({
  authoringId,
  openDownloadPreview,
  setOpenDownloadPreview,
}) => {
  const [downloadFileType, setDownloadFileType] = useState("PDF")
  const [showValidations, setShowValidations] = useState(false)
  const postPrsPreview = usePostPrsPreview(authoringId)

  const {
    openToastNotification
  } = useAppState()

  const downloadCTGFile = async () => {
    try {
      let type = downloadFileType.toLowerCase()
      openToastNotification("info", "Note", "Retrieving document")
      setOpenDownloadPreview(false)
      postPrsPreview({
        body: {
          preview_type: type,
          show_validations: showValidations
        }
      }, {
        onSuccess: async (resp) => {
          if (resp.data) {
            try {
              let blob = await fetch(resp.data.file_url)
                .then((r) => r.blob())
              fileDownload(blob, `${type}_preview`, `application/${type}`)

            } catch (err) {
              console.log(err)
            }
          }
        }
      })
    } catch (err) {
      console.log("ERR: ", err)
    }
  }

  return (
    <Modal
      title="Download Preview"
      type='confirm'
      open={openDownloadPreview}
      setOpenModal={setOpenDownloadPreview}
      onCancel={() => setOpenDownloadPreview(false)}
      onOk={() => downloadCTGFile()}
      okText="Download"
      size="sm"
      hideImage={true}
    >
      <div className='download-preview-content-container'>
        <PrimeField
          schema={{
            type: "dropdown",
            id: "download-preview-file-type",
            className: "full",
            placeholder: "File type",
            items: ["PDF", "RTF"],
            onChange: (val) => {
              if (val === "RTF") setShowValidations(false)
              setDownloadFileType(val)
            },
            containerClassName: "field w-full"
          }}
          value={downloadFileType} />
        <PrimeField
          schema={{
            type: "checkbox",
            containerClassName: "field",
            className: "checkbox",
            label: "Include validations",
            onChange: (val) => setShowValidations(val),
          }}
          disabled={downloadFileType === "RTF"}
          value={showValidations} />
      </div>
    </Modal>
  );
};

export default DownloadPreviewModal;