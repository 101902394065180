import React, { useState, useEffect } from 'react'
import { Tooltip } from 'antd'

import './action-item-docs.scss'

const ActionItemDocs = (props) => {
  const [docCount, setDocCount] = useState(0)
  const [docs, setDocs] = useState([])

  useEffect(() => {
    if(props.value) {
      let docs = props.value.split(", ")
      setDocs(docs);
      setDocCount(docs.length)
    }
  }, [props.value])

  if(props?.value?.length > 0) {
    return (
      <Tooltip
        title={docs.join(", \n")}
      >
        <span className="action-item-text">{docCount}</span>
      </Tooltip>
    )
  }

  return <span className="action-item-text">0</span>
}

export default ActionItemDocs