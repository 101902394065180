import * as React from 'react'
import { TextInput } from 'components-design-system'
import PropTypes from 'prop-types'
import EmptyFavorites from '../EmptyFavorites/EmptyFavorites'
import FavoriteItem from '../FavoriteItem/FavoriteItem'
import './FavoritesTab.scss'

// api
import { useGetFavoriteStudies, useGetFavoriteRequests, } from 'api/hooks'

const FavoritesTab = ({
  type = 'studies',
  setIsOpen,
}) => {
  const {
    data: faveStudies,
    isLoading: studyFavesLoading,
  } = useGetFavoriteStudies()
  const [search, setSearch] = React.useState('')

  const {
    data: faveRequests,
    isLoading: requestFavesLoading,
  } = useGetFavoriteRequests()

  const favorites = React.useMemo(() => {
    const getSortedAndFiltered = (favorites) => {
      const key = type === 'studies' ? 'study_id' : 'request_id'
      return favorites
        ?.filter(study => study[key]
          .toLowerCase()
          .includes(search.toLowerCase()))
        ?.sort((a, b) => a?.[key]
          ?.localeCompare(b?.[key], 'en', { numeric: true }))
    }
    if (type === 'studies' && faveStudies) {
      return getSortedAndFiltered(faveStudies)
    } else if (type === 'requests' && faveRequests) {
      return getSortedAndFiltered(faveRequests)
    }
    return []
  }, [type, faveStudies, faveRequests, search])

  // reset search when switching tabs
  React.useEffect(() => {
    setSearch('')
  }, [type])

  if (studyFavesLoading || requestFavesLoading) return null;

  return <div className="favorites-tab">
    {favorites?.length > 0 ?
      <>
        <div className="favorite-input-container">
          <TextInput
            className="favorite-input"
            size="small"
            placeholder={`Search favorite ${type === 'studies' ? 'studies' : 'requests'}`}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            suffix={<i
              type="button"
              className="fal fa-search favorite-icon"
              aria-label="toggle menu"
            />}
            allowClear
          />
        </div>
        <div className="favorite-list">
          {favorites?.map((favorite) => <FavoriteItem
            key={favorite.id}
            type={type}
            favorite={favorite}
            setIsOpen={setIsOpen}
          />)}
        </div>
      </> :
      <EmptyFavorites type={type} />}
  </div>
}

FavoritesTab.propTypes = {
  type: PropTypes.oneOf(['studies', 'requests']),
  setIsOpen: PropTypes.func,
}

export default FavoritesTab