import { useState, useMemo } from 'react';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { useShallow } from 'zustand/react/shallow'

// components
import Modal from 'components/Modal/Modal';
import { Button } from 'components-design-system';
import AdverseEventsXMLImport from './subcomponents/AdverseEventsXMLImport/AdverseEventsXMLImport';
import AdverseProperties from './subcomponents/AdverseProperties/AdverseProperties';
import Arms from './subcomponents/Arms/Arms';
import CTGArmTotal from './subcomponents/CTGArmTotal/CTGArmTotal';
import EventList from './subcomponents/EventList/EventList';
import CellValidation from '../components/CellValidation/CellValidation';


// context
import useResultsTableViewStore from '../../../hooks/stores/useResultsTableViewStore';
import useAuthoringViewStore from '../../../hooks/stores/useAuthoringViewStore';
import useAuthoringStore from '../../../hooks/stores/useAuthoringStore';

// api
import { useGetAuthoringStudy } from 'api/hooks/studies/useAuthoringApi';

import { usePostAeManualImport } from 'api/hooks/etl/aeImportApi';

// utils
import jsonToFormData from 'utilities/jsonToFormData';

const RJAdverseEvents = (props) => {
  const {
    schema,
    adverseData,
    setAdverseData,
  } = props;

  const {
    authoringId,
    setIsUserLocked,
    setLockedFullName,
  } = useAuthoringStore(
    useShallow(state => ({
      authoringId: state.authoringId,
      setIsUserLocked: state.setIsUserLocked,
      setLockedFullName: state.setLockedFullName,
    }))
  )


  const {
    readOnly,
    formView,
  } = useAuthoringViewStore(
    useShallow(state => ({ 
      readOnly: state.readOnly,
      formView: state.formView
    }))
  )

  const {
    tableView
  } = useResultsTableViewStore()

  const { data: authoring } = useGetAuthoringStudy(authoringId)
  const postAeManualImport = usePostAeManualImport(authoringId)
  const [aeFile, setAeFile] = useStateWithCallbackLazy(null);
  const [openAeImportModal, setOpenAeImportModal] = useState(false);
  const [showAeImportErrors, setShowAeImportErrors] = useState(false)
  const [aeImportError, setAeImportError] = useState("")
  const [showImportSuccess, setShowImportSuccess] = useState(false);

  const handleFileload = (file) => {
    if (file?.name) {
      if (file.name.slice(file.name.length - 4).toLowerCase() !== ".xml") {
        setShowAeImportErrors(true)
        setAeImportError("Invalid file type, please upload an XML file.")
        return null;
      }
    }
    setAeFile(file)
  }

  const handleImport = () => {
    // TODO: make request
    // if success
    let formData = jsonToFormData({
      xml_file: aeFile,
      study_id: authoring.study_id,
      file_type: tableView
    })

    postAeManualImport({ body: formData }, {
      onSuccess: (res) => {
        if (res.status >= 300) {
          setShowAeImportErrors(true);
          setAeImportError(res.data?.error_msg);
        } else {
          setShowImportSuccess(true);
          setOpenAeImportModal(false);
        }
      },
      onError: (err) => {
        if (err?.response?.data?.error?.msg) {
          let errorMsg = err.response.data.error.msg
          setShowAeImportErrors(true);
          setAeImportError(errorMsg);
        }
      }
    })
  }

  const footer = useMemo(() => {
    let actions = []
    if (aeFile) {
      actions = [
        <Button
          variant="outlined"
          size="sm"
          onClick={() => {
            setAeFile(null)
            setOpenAeImportModal(false)
          }}
        >
          Cancel
        </Button>,
        <Button
          variant="primary-dashed"
          size="sm"
          onClick={() => {
            setAeFile(null)
          }}>
          Remove file
        </Button>,
        <Button
          size="sm"
          onClick={handleImport}
          disabled={showAeImportErrors}>
          Import
        </Button>,
      ]
    } else {
      actions = [
        <Button
          variant="outlined"
          size="sm"
          style={{
            cursor: "pointer"
          }}
          onClick={() => {
            setOpenAeImportModal(false)
            setShowAeImportErrors(false)
          }}>
          Cancel
        </Button>,
        <Button
          variant="primary-dashed"
          size="sm"
          style={{
            cursor: "pointer",
          }}
          onClick={() => { }}
          disabled={true}>
          Import
        </Button>
      ]
    }

    return actions
  }, [aeFile])

  return (
    <div className="results-table">
      {formView.id !== "global" && readOnly !== true && (
        <Button
          variant="primary-dashed"
          style={{ marginBottom: 15 }}
          onClick={() => setOpenAeImportModal(true)}
        >
          <i className="fal fa-cloud-upload"></i>
          <span style={{ marginLeft: 6 }}>Import XML</span>
        </Button>
      )}


      <table className="authoring-table-component" style={{ marginBottom: 24, alignSelf: "flex-start" }}>
        <tbody>
          <AdverseProperties
            schema={schema}
            adverseData={adverseData}
            setAdverseData={setAdverseData} />
        </tbody>
      </table>
      <table style={{ alignSelf: "flex-start" }}>
        <tbody>
          <Arms
            schema={schema}
            adverseData={adverseData}
            setAdverseData={setAdverseData} />
          {tableView === "ctg" &&
            <>
              <tr>
                <td
                  className="disabled-row"
                  style={{ fontWeight: "bold" }}
                  colSpan={adverseData?.[tableView]?.groups?.length + 1}
                >
                  All Cause Mortality
                </td>
              </tr>
              <CTGArmTotal
                type="all_cause_mortality_group_total"
                schema={schema}
                adverseData={adverseData}
                setAdverseData={setAdverseData} />
            </>
          }
          <EventList
            title="Serious adverse event"
            eventKey={"serious_adverse_event"}
            eventListKey={"serious_adverse_events"}

            schema={schema}
            adverseData={adverseData}
            setAdverseData={setAdverseData} />
          <EventList
            title="Non-serious adverse event"
            eventKey={"non_serious_adverse_event"}
            eventListKey={"non_serious_adverse_events"}
            schema={schema}
            adverseData={adverseData}
            setAdverseData={setAdverseData} />
        </tbody>
      </table>

      <CellValidation
        table="adverse"
        errorKey="results_adverse_events_adverse_events_table" />

      <Modal
        title="Adverse Events XML Import"
        onCancel={() => {
          setOpenAeImportModal(false)
          setAeFile(null)
        }}
        open={openAeImportModal}
        hideImage={true}
        className="ae-xml-upload-modal"
        footer={footer}
        width={500}>
        <AdverseEventsXMLImport
          aeFile={aeFile}
          setAeFile={setAeFile}
          openAeImportModal={openAeImportModal}
          handleFileload={handleFileload}
          showAeImportErrors={showAeImportErrors}
          setShowAeImportErrors={setShowAeImportErrors}
          aeImportError={aeImportError}
          setAeImportError={setAeImportError} />
      </Modal>
      <Modal
        open={showImportSuccess}
        title={"ETL load in process"}
        message={"The form is temporarily locked.  You will receive an email notification when upload is complete and form is unlocked."}
        footer={[
          <Button
            key="ok-btn"
            style={{
              margin: "auto",
              paddingLeft: 64,
              paddingRight: 64,
              marginTop: 24
            }}
            onClick={() => {
              setShowImportSuccess(false)
              setAeFile(null)
              setIsUserLocked(true)
              setLockedFullName("ETL Load")
            }}
          >OK
          </Button>
        ]}>

      </Modal>
    </div>
  );
};

export default RJAdverseEvents;