// components
import ArrayFieldItem from './ArrayFieldItem';

const ArrayFieldTemplate = (props) => {
  const { uiSchema, idSchema, schema } = props;
  const options = uiSchema["ui:options"];
  const jpath = idSchema.$id

  let title = "Title"
  let arrayItemTitle = "Item Title";
  let classNames = {}
  let showIndex = options?.showIndex;


  if (options) {
    title = options.title;
    arrayItemTitle = options.arrayItemTitle;
    classNames = options.classNames
  }

  return (
    <div
      id={idSchema.$id}
      className={`rjs-array-container ${classNames?.arrayContainer ? classNames.arrayContainer : ""}`}>
      <div className="rjs-array-title-container">
        <div className={`rjs-array-title ${classNames?.arrayTitle ? classNames.arrayTitle : ""}`}>
          {title}
        </div>
      </div>
      <div className={`rjs-array-items-container ${classNames?.arrayItemsContainer ? classNames.arrayItemsContainer : ""}`}>
        {props.items.map(element => {
          return (
            <ArrayFieldItem
              key={element.key}
              element={element}
              classNames={classNames}
              arrayItemTitle={arrayItemTitle}
              showIndex={showIndex} />
          )
        })}
      </div>
    </div>
  );
};

export default ArrayFieldTemplate;