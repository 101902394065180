export const moveLeftHelper = (array, i) => {
  let saved = array[i-1];
  array[i-1] = array[i];
  array[i] = saved;
}

export const moveRightHelper = (array, i) => {
  let saved = array[i+1];
  array[i+1] = array[i];
  array[i] = saved;
}