import React, { useState, useEffect, memo} from 'react'
import styled from 'styled-components'
import { Tooltip } from 'antd'

const AddressComparator = props => {
  const { value, data } = props;
  const { field } = props.colDef
  const [display, setDisplay] = useState(null);

  useEffect(() => {
    let source, mask, ctg = null;
    if(field === "name") {
      source = data.location_data.source && data.location_data.source.facility;
      ctg = data.location_data.ctg && data.location_data.ctg.facility;
      mask = data.location_data.mask && data.location_data.mask.facility;
    } else {
      source = data.location_data.source && data.location_data.source.facility && data.location_data.source.facility.address;
      mask = data.location_data.mask && data.location_data.mask.facility && data.location_data.mask.facility.address;
      ctg = data.location_data.ctg && data.location_data.ctg.facility && data.location_data.ctg.facility.address;
    }

    let sourceVal = null;
    let ctgVal = null;
    let maskVal = null;

    if(source && source[field] !== null) {
      sourceVal = source[field];
    }
    if(ctg && ctg[field] !== null) {
      ctgVal = ctg[field];
    }
    if(mask && mask[field] !== null) {
      maskVal = mask[field];
    }

    let component = processValues(sourceVal, ctgVal, maskVal)

    setDisplay(component);
  }, [value, data]);

  const processValues = (source, ctg, mask) => {
    if((mask !== undefined && mask !== null)  && ctg) {  // if MASK + CTG data is available
      if(mask == ctg) {  // if MASK + CTG data is equal
        return getComponent(mask, "masked", "Value Overriden Source Value: " + source + "; MASK value = CTG value", true);
      } else { // if MASK + CTG data is NOT equal
      let maskComponent = getComponent(mask, "masked", "Value Overriden Source Value: " + source, true);
      let ctgComponent = getComponent(ctg, "ctg-only", "CTG value");
      return [maskComponent, ctgComponent];
      }
    } else if(mask !== null) {  // only MASK data
      return getComponent(mask, "masked", "Value Overriden Source Value: " + source, true);
    } else if(ctg && source) { // if Source + CTG data is available
      if(ctg == source) { // if Source + CTG data is equal
        return getComponent(source, "equal", "Prime value = CTG value");
      } else { // if Source + CTG data is NOT equal
        let sourceComponent = getComponent(source, "source-diff", "Prime value");
        let ctgComponent = getComponent(ctg, "ctg-only", "CTG Value");
        return [sourceComponent, ctgComponent];
      }
    } else if(source) { // if Source data only
      return getComponent(source, "source-only", "Prime value")
    } else if(ctg) { // if CTG data only
      return getComponent(ctg, "ctg-only", "CTG Value")
    }
    return null;
  }

  const getComponent = (value = "", type, tooltip, showMask = false) => {
    return (
      <Tooltip
        title={<InfoContainer>
          {tooltip}
        </InfoContainer>}
      >
        <Text type={type}>
          {showMask && <MaskIcon className="fas fa-eye" />}
          {value}
        </Text>
      </Tooltip>
    )
  }

  return (
    <Container>
      {display}
    </Container>
  )
}

export default memo(AddressComparator);

const getColor = (type) => {
  switch(type) {
    case "ctg-only": return "#D7542C";
    case "source-only": return "#70AD2E";
    case "masked": return "#8D8D8D";
    default: return "#383E47";
  }
}

const getTextDecoration = (type) => {
  if(type === "ctg-only") return "line-through";
  return "default";
}

const Container = styled.div`
  word-wrap: break-word;
  white-space: pre-line;
`

const InfoContainer = styled.div`
  padding: 6px;
  text-align: left;
  font-size: 12px;
  white-space: normal;
`

const Text = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.16px;
  text-decoration-line: ${props => getTextDecoration(props.type)};
  color: ${props => getColor(props.type)};
  white-space: pre-line;
`

const MaskIcon = styled.i`
  color: #8D8D8D;
  margin-right: 6px;
`