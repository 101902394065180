import React, { useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow'

// components
import LabelMenu from '../components/LabelMenu/LabelMenu';
import { Skeleton } from 'antd';

// utils
import { registryValidMsgs } from 'utilities/registryValidations'

// context
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';
import useAuthoringCommentsStore from '../../hooks/stores/useAuthoringCommentsStore';
import useAuthoringActions from '../../hooks/actions/useAuthoringActions';
import useAuthoringValidationsStore from '../../hooks/stores/useAuthoringValidationsStore';

// styles
import './ObjectFieldTemplate.scss'

const ObjectFieldTemplate = (props) => {
  const [showMenu, setShowMenu] = useState(false);
  const [fieldComments, setFieldComments] = useState([]);
  const { properties, uiSchema, idSchema } = props;
  const [fieldLoading, setFieldLoading] = useState(false)

  const {
    registryValidations
  } = useAuthoringValidationsStore(
    useShallow(state => ({
      registryValidations: state.registryValidations,
    }))
  )

  const {
    scrollToField
  } = useAuthoringActions()

  const {
    openComment,
    setOpenComment,
    commentsMap,
  } = useAuthoringCommentsStore(
    useShallow(state => ({ 
      openComment: state.openComment,
      setOpenComment: state.setOpenComment,
      commentsMap: state.commentsMap,
    }))
  )

  const {
    formView,
    formSection
  } = useAuthoringViewStore(
    useShallow(state => ({ 
      formView: state.formView,
      formSection: state.formSection,
    }))
  )

  const options = uiSchema["ui:options"];

  let title = ""
  // let styles = {};
  let schemaClassNames = {}
  const jpath = idSchema.$id
  let canCopyOutcomeMeasures = options?.canCopyOutcomeMeasures;

  useEffect(() => {
    if (commentsMap) {
      if (commentsMap[jpath]) {
        let fieldComments = commentsMap[jpath];
        setFieldComments(fieldComments);
      } else {
        setFieldComments([]);
      }
    }
  }, [commentsMap]);

  useEffect(() => {
    if (openComment && jpath === openComment) {
      scrollToField(jpath);
      setOpenComment(null);
    }
  }, [openComment])


  if (options) {
    schemaClassNames = options?.classNames
    title = options.title
  }

  let objectContainerClassNames = [`rjs-object-container${options?.hide ? ' hidden' : ''}`]
  if (schemaClassNames?.objectContainer) objectContainerClassNames.push(schemaClassNames.objectContainer)


  if(fieldLoading) return <Skeleton active />

  return (
    <div
      id={jpath + "-container"}
      className={objectContainerClassNames.join(" ")}
      onMouseEnter={() => setShowMenu(true)}
      onMouseLeave={() => setShowMenu(false)}>
      {title && <div
        className="rjs-object-title-wrapper"
        id={jpath}>
        <div
          id={jpath + "-object-title-container"}
          className={`rjs-object-title-container ${schemaClassNames?.objectTitleContainer ? schemaClassNames.objectTitleContainer : ""}`}>
          <div className={`rjs-object-title ${schemaClassNames?.objectTitle ? schemaClassNames.objectTitle : ""}`}>
            {title}
          </div>
          {title && <LabelMenu
            hideFork={true}
            jpath={jpath}
            label={title}
            showMenu={showMenu}
            openComment={openComment}
            setShowMenu={setShowMenu}
            fieldComments={fieldComments}
            formView={formView}
            formSection={formSection}
            setFieldLoading={setFieldLoading}
            canCopyOutcomeMeasures={canCopyOutcomeMeasures}
            canCopyAndConsolidateOMs={jpath==="root_protocol_study_outcome_measures_outcome_measures_eupas"}
            formContext={props.formContext}
          />}
        </div>
      </div>}
      <div className={`rjs-object-inner-container ${schemaClassNames?.objectInnerContainer ? schemaClassNames.objectInnerContainer : ""}`}>
        {properties.map((prop, i) => {
          const { uiSchema } = prop.content.props;
          const options = uiSchema["ui:options"];

          let fieldClassNames = ["rjs-field-container"];

          if (options?.classNames?.fieldContainer) {
            fieldClassNames.push(options.classNames.fieldContainer)
          }

          if (options?.hide) return null;

          return (
            <div
              className={fieldClassNames.join(" ")}
              key={prop.name + "-" + "i"}
              id={prop.content.props.idSchema["$id"] + "-field-container"}
            >
              {prop.content}
            </div>
          )
        })}
      </div>
      {registryValidMsgs(registryValidations, jpath)}
    </div>
  );
};

export default ObjectFieldTemplate