import useGetApi from "../useGetApi"
import useMutateApi from "../useMutateApi"

// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

// utils
import getUrl from "api/apiMap"

export const useGetCommentTypes = () => {
  return useGetApi(getUrl(apiEndpoints.commentTypes), queryKeys.commentTypes)
}

// mutate({ body })
export const usePostCommentTypes = () => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(apiEndpoints.commentTypes),
    invalidateQueries: [queryKeys.commentTypes],
    successMsg: "Successfully created comment type"
  })
}

// mutate({ id, body })
export const usePutCommentTypes = () => {
  return useMutateApi({
    method: "put",
    apiEndpoint: getUrl(apiEndpoints.commentTypes),
    invalidateQueries: [queryKeys.commentTypes],
    successMsg: "Successfully updated commenty type"
  })
}

// mutate({ id })
export const useDeleteCommentTypes = () => {
  return useMutateApi({
    method: "delete",
    apiEndpoint: getUrl(apiEndpoints.commentTypes),
    invalidateQueries: [queryKeys.commentTypes],
    successMsg: "Successfully deleted comment type"
  })
}