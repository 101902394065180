import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import { Button } from 'components-design-system'

import './Preset.scss'


const getBackgroundColor = (isActive) => {
  if (isActive) return "#e0e0e0"
  return "transparent";
}

const getBorderLeft = (isActive) => {
  if (isActive) return "4px solid #9566AB";
  return "none";
}

const Preset = ({
  isActive,
  type,
  index,
  agGridApi,
  onLoad,
  setDefaultPreset,
  onOpenModal,
  preset,
  // present.json props:
}) => {
  const [pinnedLeftText, setPinnedLeftText] = useState("");
  const [pinnedRightText, setPinnedRightText] = useState("");
  const [filterText, setFilterText] = useState("");
  const [sortText, setSortText] = useState("");
  const [hoverTitle, setHoverTitle] = useState(false);

  const {
    column_state,
    filter_state,
    sort_state,
  } = preset.json

  useEffect(() => {
    if (agGridApi && column_state && filter_state && sort_state) {
      let hiddenCols = column_state && column_state.filter(col => col.hide === true && !col.colId.includes("_prs")).map((col) => getColName(col.colId));
      let pinnedLeftCols = column_state && column_state.filter(col => col.pinned == 'left').map((col) => getColName(col.colId));
      let pinnedRightCols = column_state && column_state.filter(col => col.pinned == 'right').map((col) => getColName(col.colId));
      let filterCols = filter_state && Object.keys(filter_state).map(key => getColName(key));
      let sortCols = sort_state && sort_state.map(col => getColName(col.colId));

      let hText = "";
      let pLeftText = "";
      let pRightText = "";
      let filterText = "";
      let sortText = "";

      if (hiddenCols.length > 0) {
        hText += 'Hidden (' + hiddenCols.length + '): ' + hiddenCols.join(', ');
      }
      if (pinnedLeftCols.length > 0) {
        pLeftText += 'Pinned Left (' + pinnedLeftCols.length + '): ' + pinnedLeftCols.join(', ');
      }
      if (pinnedRightCols.length > 0) {
        pRightText += 'Pinned Right (' + pinnedRightCols.length + '): ' + pinnedRightCols.join(', ');
      }
      if (filterCols.length > 0) {
        filterText += 'Filters (' + filterCols.length + '): ' + filterCols.join(', ');
      }
      if (sortCols.length > 0) {
        sortText += 'Sorts (' + sortCols.length + '): ' + sortCols.join(', ');
      }

      setPinnedLeftText(pLeftText);
      setPinnedRightText(pRightText);
      setFilterText(filterText);
      setSortText(sortText);
    }
  }, [agGridApi, column_state, filter_state, sort_state]);

  const getColName = (colId) => {
    let header = agGridApi.getColumnDef(colId);
    if (header && header.headerName) return header.headerName;
    return colId;
  }

  return (
    <div
      style={{
        borderLeft: getBorderLeft(isActive),
        backgroundColor: getBackgroundColor(isActive),
      }}
      className="grid-preset"
    >
      <div
        onMouseEnter={() => setHoverTitle(true)} onMouseLeave={() => setHoverTitle(false)}
        className="preset-title-wrap"
      >
        <div className="preset-title">{preset.name} {preset.is_default && "(default)"}</div>
        {hoverTitle && type === 'user' && <i
          className="fal fa-pencil-alt"
          onClick={() => onOpenModal("update-preset", preset)}
        />}
      </div>
      <div className="preset-config">
        <div className="preset-text">
          {pinnedLeftText}
        </div>
        <div className="preset-text">
          {pinnedRightText}
        </div>
        <div className="preset-text">
          {filterText}
        </div>
        <div className="preset-text">
          {sortText}
        </div>
      </div>
      <div className="preset-actions">
        {!preset.is_default && type === 'user' &&
          <Button
            variant="link"
            onClick={() => setDefaultPreset(index)}>
            Set as Default
          </Button>
        }
        {!isActive &&
          <Button
            style={{ marginLeft: 20 }}
            variant="link"
            onClick={() => onLoad(preset)}>
            Load
          </Button>
        }
        {type === 'user' &&
          <Button
            style={{ marginLeft: 20 }}
            variant="link"
            onClick={() => onOpenModal("delete-preset", preset)}>
            <i className="fal fa-trash-alt"></i>
          </Button>
        }
      </div>
    </div>
  )
}

Preset.propTypes = {
  type: PropTypes.oneOf(["user", "client"]),
}

export default Preset