import * as React from 'react'
import { useParams, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import PdfFullWindowModal from '../PdfFullWindowModal/PdfFullWindowModal'
import GridTypeA from 'components/grids/GridTypeA/GridTypeA'
import './FilesGrid.scss'

// assets
import emptyFilesImg from 'assets/images/empty-files.png'

// apis
import { useGetRequest } from 'api/hooks/requests/useRequestsApi'
import { useGetDocumentTypes } from 'api/hooks/admin/useDocumentTypesApi'

// utils
import useBadges from 'utilities/useBadges'

const noRowsOverlayComponent = () => {
  return <div className="empty-files-placeholder">
    <img src={emptyFilesImg} />
    There are no files on this request
  </div>
}

const FilesGrid = ({
  rowData,
  selectableColumn,
  search,

  // reviewer view props:
  onFileClick,
  isReviewerView = false,

  ...gridProps
}) => {
  const { requestId } = useParams()
  const { data: request } = useGetRequest(requestId, { enabled: !!requestId })
  const { data: fileTypes } = useGetDocumentTypes()
  const [selectedDocument, setSelectedDocument] = React.useState()
  const [openPdf, setOpenPdf] = React.useState(false)

  const getUniqueStates = (data) => {
    if (data) {
      return [...new Set(data.map((data) => data.state))]
    }
    return null
  }

  const { Badge } = useBadges(getUniqueStates(rowData))

  const getFileTypeName = (fileTypeId) => {
    if (fileTypes) {
      return fileTypes.find((fileType) => fileType.id === fileTypeId)?.name
    }
    return null
  }

  const handleFileClick = (file) => {
    setSelectedDocument(file)
    if (onFileClick) {
      onFileClick(file)
    }
    if (file.document_file) {
      if (file.document_file.toLowerCase().includes(".pdf")) {
        setOpenPdf(true)
      } else {
        window.open(file.document_file, '_blank')
      }
    }
  }

  let columnDefs = []

  // select rows to open bulk edit panel
  if (selectableColumn) {
    let selectCol = {
      headerName: "",
      sortable: false,
      filter: false,
      // selectable checkboxes
      // allow checkboxes in column:
      checkboxSelection: true,
      // hide header checkbox:
      headerCheckboxSelection: false,
      // hide non-selectable checkboxes
      showDisabledCheckboxes: false,
      width: 39,
    }
    // if selectableColumn is an object vs boolean, add property values to selectCol
    if (typeof selectableColumn === "object") {
      if (selectableColumn.headerCheckboxSelection === true) {
        selectCol.headerCheckboxSelection = true
      }
    }
    columnDefs.push(selectCol)
  }

  // flag column for reviewer view files assigned to user; subTask field is added to rowData for files assigned to user
  if (isReviewerView) {
    columnDefs.push({
      headerName: "",
      field: "subTask",
      cellRenderer: ({ value }) => {
        if (value) {
          if (value.is_complete) {
            return <i className='fas fa-check-circle' />
          }
          return <i className='fas fa-flag-alt' />
        } else {
          return null
        }
      },
      width: 45,
    })
  }

  const sharedColumnDefs = [
    {
      headerName: "",
      sortable: false,
      filter: false,
      cellRenderer: ({ data }) => {
        if (data.document_file) {
          return <a>
            <i className="fal fa-paperclip"
              onClick={() => handleFileClick(data)}
            />
          </a>
        } else if (data.document_link) {
          return <a
            href={data.document_link}
            target="_blank"
            onClick={() => handleFileClick(data)}
          >
            <i className="far fa-link" />
          </a>
        }
      },
      width: 46,
    },
    {
      field: "name",
      cellRenderer: ({ value, data }) => {
        if (isReviewerView) return value;
        if (request) {
          return <Link to={`/request/${request.id}/file/${data.id}`}>{value}</Link>
        } else {
          return <Link to={`/file/${data.id}`}>{value}</Link>
        }
      },
      flex: 2,
      minWidth: 234,
    },
    {
      headerName: "Type",
      field: "document_type_name",
      valueGetter: ({ data }) => {
        if (data.document_type_name) return data.document_type_name;
        if (data.document_type_id) return getFileTypeName(data.document_type_id);
      },
      flex: 2,
      minWidth: 234,
    },
    {
      headerName: "Version",
      field: "version_number",
      flex: 1,
      minWidth: 109,
    },
    {
      headerName: "Status",
      field: "state",
      cellRenderer: ({ value }) => {
        if (!value) return null;
        return <div style={{ display: "flex" }}>
          <Badge value={value} />
        </div>
      },
      flex: 1,
      minWidth: 140,
    },
    {
      headerName: "Studies",
      field: "authoringstudies_w_stu_ids",
      cellRenderer: "StudiesCellRenderer",
      cellRendererParams: {
        dataKey: "authoringstudies_w_stu_ids",
        idKey: "core_stu_id",
        isReviewerView,
      },
      valueGetter: ({ data }) => {
        if (data.authoringstudies_w_stu_ids) return data.authoringstudies_w_stu_ids.map(study => study.study_id).join(", ")
        return
      },
      flex: 2,
      minWidth: 297,
    },
    {
      headerName: "Requests",
      field: "requests", // "request_db_ids"
      cellRenderer: "RequestsCellRenderer",
      cellRendererParams: {
        isReviewerView,
      },
      flex: 2,
      minWidth: 160,
    },
  ]

  columnDefs = [...columnDefs, ...sharedColumnDefs]

  return <>
    <GridTypeA
      gridOptions={{ noRowsOverlayComponent }}
      search={search}
      rowData={rowData}
      columnDefs={columnDefs}
      className="files-grid"
      rowSelection={selectableColumn ? "multiple" : "none"}
      {...gridProps}
    />
    <PdfFullWindowModal
      open={openPdf}
      setOpen={setOpenPdf}
      file={selectedDocument}
      setFile={setSelectedDocument}
    />
  </>
}

FilesGrid.propTypes = {
  rowData: PropTypes.arrayOf(PropTypes.shape({
    state: PropTypes.string,
    subTask: PropTypes.object,
  })),
  // TODO: review selectableColumn and uncomment:
  selectableColumn: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      headerCheckboxSelection: PropTypes.bool,
    }),
  ]),
  search: PropTypes.string,
  isReviewerView: PropTypes.bool,
  // TODO: add gridProps prop types
}

export default FilesGrid