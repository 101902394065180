import { useState } from 'react';
import { v4 as uuid } from 'uuid'
import { useShallow } from 'zustand/react/shallow'


// components
import ResultsTableDeleteModal from '../../../components/ResultsTableDeleteModal/ResultsTableDeleteModal';
import RJTableCommentContainer from '../RJTableCommentContainer/RJTableCommentContainer';

// context
import useAuthoringViewStore from '../../../../hooks/stores/useAuthoringViewStore';
import useAuthoringCommentsStore from '../../../../hooks/stores/useAuthoringCommentsStore';

// styles
import './RowActions.scss'

const RowActions = ({
  canDelete,
  handleDelete,
  handleDown,
  handleUp,
  showActions,
  setShowActions,
  showDown,
  showUp,
  deleteModalTitle = "title",
  hideOptions = false,
  style = {},
  jpath,
  hideComment = false
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [openCommentsForm, setOpenCommentsForm] = useState(false)

  const {
    readOnly
  } = useAuthoringViewStore(
    useShallow(state => ({ readOnly: state.readOnly }))
  )

  const {
    commentsMap
  } = useAuthoringCommentsStore(
    useShallow(state => ({
      commentsMap: state.commentsMap
    }))
  )

  let classNames = ["arm-actions-container"]
  if (showActions) classNames.push("arm-actions-active")

  return (
    <div className="cell-actions">
      {canDelete && showActions && readOnly !== true && (
        <i
          onClick={() => setShowDeleteModal(true)}
          className="fal fa-trash-alt trash-icon" />
      )}

      {hideComment !== true && (
        <RJTableCommentContainer
          label={`Results row`}
          jpath={jpath}
          openCommentsForm={openCommentsForm}
          setOpenCommentsForm={setOpenCommentsForm}
          setShowMenu={setShowActions}
          showMenu={showActions || commentsMap[jpath] || openCommentsForm}
          style={{ marginBottom: 2 }} />
      )}

      {showDown && showActions && readOnly !== true &&
        <i
          className="fal fa-arrow-down arrow-icon"
          onClick={() => handleDown()} />
      }
      {showUp && showActions && readOnly !== true &&
        <i
          className="fal fa-arrow-up arrow-icon"
          onClick={() => handleUp()} />
      }

      <ResultsTableDeleteModal
        id={uuid()}
        header={deleteModalTitle}
        onSubmit={handleDelete}
        showModal={showDeleteModal}
        hideOptions={hideOptions}
        setShowModal={() => setShowDeleteModal(false)} />
    </div>
  );
};

export default RowActions;