import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

const ArmsInterventionsPreview = (props) => {
  let { schema = null, options, formContext } = props;
  let { formData } = formContext;
  const [title, setTitle] = useState("Arms");
  const [tableData, setTableData] = useState([]);

  const keys = props.id.split("_");
  const key = keys[keys.length - 1];

  useEffect(() => {
    if (formData?.protocol?.study_arms?.[key]) {
      let results = getAssignedInterventions();
      setTableData(results);
    }
  }, [])

  useEffect(() => {
    if (formData?.protocol?.study_type) {
      let studyType = formData.protocol.study_type;
      if (studyType === "Observational") {
        setTitle("Groups/Cohorts")
      } else {
        setTitle("Arms")
      }
    }
  }, [formData])

  const getAssignedInterventions = () => {
    if (formData?.protocol?.study_arms?.arms
      && formData?.protocol?.study_arms?.interventions
      && formData?.protocol?.study_arms?.crossref?.arms) {
      let {
        arms,
        interventions,
        crossref
      } = formData.protocol.study_arms;

      let results = arms?.map(arm => {
        let intArr = [];

        let foundArm = crossref.arms.find(item => item.id === arm.id);
        if (foundArm) {
          foundArm.interventions.forEach(int => {
            let foundItem = interventions.find(item => item.id === int);
            if (foundItem) intArr.push({ ...foundItem });
          })
        }

        return {
          arm,
          interventions: intArr
        }
      })

      return results;
    }

    if (formData?.protocol?.study_arms?.arms && !formData?.protocol?.study_arms?.interventions) {
      let {
        arms
      } = formData.protocol.study_arms;

      let results = arms?.map(arm => {
        return {
          arm,
          interventions: []
        }
      })

      return results;
    }
  }

  return (
    <Table>
      <thead>
        <th>{title}</th>
        <th>Assigned Interventions</th>
      </thead>
      <tbody>
        {tableData && tableData?.map((arm, i) => (
          <tr key={uuidv4()}>
            <td style={{ width: "50%" }}>
              <div>
                <RowTitle>{i + 1}. {arm.arm.type || `(Type)`}: {arm.arm.title || `(Title)`}</RowTitle>
                <RowDescription>{arm.arm.description || `(Description)`}</RowDescription>
              </div>
            </td>
            <td style={{ width: "50%" }}>
              <div>
                {arm?.interventions?.map((intervention, j) => (
                  <div key={uuidv4()} style={{ marginBottom: 24 }}>
                    <RowTitle>{intervention.type || `(Type)`}: {intervention.name || `(Title)`}</RowTitle>
                    <RowDescription>{intervention.description || `(Description)`}</RowDescription>
                    <RowDescription>Other names: {intervention?.other_intervention_names?.length && intervention?.other_intervention_names.join(", ") || `(Other Intervention Names)`}</RowDescription>
                  </div>
                ))}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ArmsInterventionsPreview;

const Table = styled.table`
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;

  td, th {
    border: 1px solid #444;
    text-align: left;
    padding: 8px;
  }

  th {
    font-weight: bold;
    background-color: rgb(238, 238, 254);
    text-align: center;
  }
`

const RowTitle = styled.p`
  font-weight: bold;
  margin-bottom: 0;
`

const RowDescription = styled.p`
  font-size: .9rem;
`

