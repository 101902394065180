// TODO: remove apiMap if no longer needed
const apiMap = {
  // new_url: old_url
  "/admin/api/v1/assessment-types": "/api/assessment-types/",
  "/admin/api/v1/checklist-templates": "/authoring/api/checklist-templates/",
  "/admin/api/v1/collaborator-types": "/a/collaborator-types-api/",
  "/admin/api/v1/comment-types": "/a/comment-types-api/",
  "/admin/api/v1/conditions": "/a/condition-api/",
  "/admin/api/v1/default-authoring-form": "/authoring/api/default-authoring-form",
  "/admin/api/v1/document-types": "/authoring/api/document-types/",
  "/admin/api/v1/global-site-settings": "/sites/api/global-settings/",
  "/admin/api/v1/grid-presets": "/admin/api/v1/grid-presets",
  "/admin/api/v1/protocol-maintenance-settings": "",
  "/admin/api/v1/prs-users/": "/sites/api/prs-users/",
  "/admin/api/v1/sponsors": "/a/sponsor-types-api/",
  "/auth/api/v1/token/verify/": "/auth/api/v1/token/verify/",
  "/core/api/v1/studies/": "/api/s/",
  "/core/api/v1/studies-grid/": "/s"
}

const getUrl = (url) => {
  let res = url;
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    if (process.env.REACT_APP_PRIME_BACKEND === "2") {
      if (apiMap[url]) {
        res = apiMap[url]
      } else {
        console.log(`There is no Prime 2.0 equivalent for API ${url}`)
      }
    }
  } else if (process.env.NODE_ENV === 'production') {
    res = `/backend${url}`
  }

  return res
}

export default getUrl