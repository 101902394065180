/** Adobe Embed component that simply shows a PDF preview */
import React from 'react'
import PropTypes from 'prop-types'
import AdobeEmbedSdkClient from 'classes/AdobeEmbedSdkClient'
import { useGetEnvVars } from 'api/hooks/enum'

const PdfLinkPreview = ({
  divId = "pdf-div",
  url,
  fileName,
  style = {},
}) => {
  const { data: envVars } = useGetEnvVars()

  React.useEffect(() => {
    if (url && envVars) {
      initEmbedWithURL()
    }
  }, [url, envVars])

  const initEmbedWithURL = () => {
    let options = {
      embedMode: "IN_LINE",
      defaultViewMode: "FIT_PAGE",
      showPageControls: false,
      enableLinearization: true, // show first page quickly
    }

    const viewSDKClient = new AdobeEmbedSdkClient(envVars?.REACT_APP_ADOBE_EMBED_CLIENT_ID)
    viewSDKClient
      .ready()
      .then(() => {
        viewSDKClient
          .previewFile(divId, {
            url,
            fileName,
          }, options)
          .then((res) => {})
      })
  }

  return <div id={divId} className="document-pdf" style={style} />
}

PdfLinkPreview.propTypes = {
  divId: PropTypes.string,
  url: PropTypes.string,
  fileName: PropTypes.string,
  style: PropTypes.object,
}

export default PdfLinkPreview