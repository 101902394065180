const GroupHeader = ({ title, show, setShow }) => {
  return (
    <div style={{
      display: "flex",
      alignItems: "center",
      marginBottom: 4
    }}>
      {show
        ? <i
          onClick={() => setShow(false)}
          className="fal fa-minus"
          style={{ cursor: "pointer" }} />
        : <i
          onClick={() => setShow(true)}
          className="fal fa-plus"
          style={{ cursor: "pointer" }} />
      }
      <div style={{
        marginBottom: 4,
        fontWeight: "bold",
        marginLeft: 12,
        fontSize: 14
      }}>
        {title}
      </div>
    </div >
  );
};

export default GroupHeader;