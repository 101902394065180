import React, { useState, useEffect, useMemo, forwardRef } from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { Tooltip } from 'antd'
import { motion, AnimatePresence } from 'framer-motion'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'

// custom
import primeLogoSrc from '../../assets/images/prime-by-xogene-logo-2x.png'
import pLogoSrc from '../../assets/images/p-prime-logo.png'
import useActiveSponsors from './ActiveSponsors/useActiveSponsors'
import './sidebar.scss'

// context
import useAppState from 'context/hooks/useAppState'

// api
import { useUserPermissions } from 'api/hooks'

/** Might bring these back... do not delete
 * Reports > Studies links to following API endpoints removed temporarily:
 * * /l/data-change-report
 * * /l/data-quality-report
 * * /l/new-study-report
 * 
 * 
 * * dataload/study-data-report.pug
 * * dataload/data-quality-report.pug
 * * dataload/new-study-report.pug
 */

import navbarData from 'constants/pageData'
import { WIDTH, COLORS } from 'constants/sidebar'

const Sidebar = forwardRef((props, ref) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [selectedNav, setSelectedNav] = useState('')
  const [permissions, setPermissions] = useState(null)
  const { sidebarOpen, setSidebarOpen, publicEnvVar } = useAppState()
  const { data: userPermissions } = useUserPermissions()
  const {
    Tags,
    openSponsorsModal,
    SponsorsModal,
  } = useActiveSponsors({
    tagMaxWidth: WIDTH.expanded - 53,
  })

  // show/hide new feature indicators
  const [showNew, setShowNew] = useState(true)

  // change the variable name "_01" when there is a new item added
  const NEW_FEATURES_SEEN = 'new_features_seen_01'

  // if localStorage set, hide "New" tags and dots
  useEffect(() => {
    if (localStorage.getItem(NEW_FEATURES_SEEN) == 'true') {
      setShowNew(false)
    } else {
      localStorage.setItem(NEW_FEATURES_SEEN, true)
    }
  }, [])

  const hasNewItem = (navItem) => {
    let hasNew = false
    if (navItem.isNew) return true;
    if (navItem.items) {
      navItem.items.forEach((item) => {
        if (!!item.isNew) hasNew = true;
      })
    }
    return hasNew
  }

  // animation props
  // TODO: change from framer-motion to css animations if any of the framer-motion animations are buggy

  const fadeIn = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: {
      duration: 0.3,
      delay: 0.3,
    },
  }

  const dropDown = {
    initial: { opacity: 0, height: 0 },
    animate: { opacity: 1, height: 'auto' },
    exit: { opacity: 0, height: 0 },
    transition: { damping: 300 },
  }

  const extraItemVariants = {
    open: {
      background: COLORS.extraItemsBg,
      transition: {
        duration: 0.5,
        delay: 0.3,
      }
    },
    closed: {
      background: COLORS.sidebarBg,
    }
  }

  // init
  // open menu to page's section when page loads
  useEffect(() => {
    if (currentMenuSectName) setSelectedNav(currentMenuSectName)
  }, [])

  useEffect(() => {
    if (!sidebarOpen) setSelectedNav('');
  }, [sidebarOpen])

  //storing state of the sidebar at client side
  useEffect(() => {
    localStorage.setItem("SIDEBAR_STATE", sidebarOpen);
  }, [sidebarOpen])

  useEffect(() => {
    getPermissions()
  }, [userPermissions])

  const getPermissions = () => {
    if (userPermissions) {
      let temp_permissions = {
        // Studies
        canCreateStudy: userPermissions["study.create"],
        canEditAIs: userPermissions["actionitem.edit"],
        // Admin
        canEditPts: userPermissions["project_type.edit"],
        canEditAts: userPermissions["assessment_type.edit"],
        canEditConfigs: userPermissions["configurations.edit"],
        canEditPresets: userPermissions["prime_grid_preset.edit"],
        maskingCreate: userPermissions["masking.create"],
        credentialsCreate: userPermissions["creds.create"],
        canEditUsers: userPermissions["user.edit"] || userPermissions["user.create"],
        // canConfigDatasources: permissions["datasource.configure"],
        // canCreateDataload: permissions["dataload.create"],
      }
      setPermissions(temp_permissions)
    }
  }

  const hasSectionPermissions = (navItem) => {
    if (!navItem.items) return true // My Prime
    return navItem.items.some((item) => !item?.permission || permissions[item?.permission])
  }

  const onClickParent = (navItem) => {
    // parent item expands subitems
    if (navItem.items) {
      setSidebarOpen(true)
      setSelectedNav((val) => val === navItem.name ? '' : navItem.name)
    }
    // parent item navigates to page
    else {
      navigate(navItem.url)
    }
  }

  // listener to update selected item when page not item in pageData
  const currentMenuSectPath = useMemo(() => {
    if (pathname.startsWith('/study/')) return '/studies';
    if (pathname.startsWith('/file/')) return '/files';
    if (pathname.startsWith('/activity/')) return '/activities';
    if (pathname.startsWith('/request/')) return '/requests';
    return pathname
  }, [pathname])

  // Menu item selection:
  const currentMenuSectName = useMemo(() => {
    // match location to navbarData item
    const pageSection = navbarData.find((navItem) => {
      if (navItem?.items) {
        return navItem?.items?.find((subnavItem) => subnavItem.url === currentMenuSectPath)
      }
      return navItem.url === currentMenuSectPath
    })?.name
    if (pageSection) return pageSection;
  }, [currentMenuSectPath])

  const navIsSelected = (navItem) => {
    // selectedNav set when page loads
    if (currentMenuSectName === navItem.name) {
      return true
    }
    return false
  }

  const subNavIsSelected = (item) => {
    if (item.url === currentMenuSectPath) return true;
    return false
  }

  const renderParentNavItem = (navItem) => {
    if (navItem.items) {
      return (
        <div
          onClick={() => onClickParent(navItem)}
          className="nav-item-parent"
          role="button"
        >
          <i
            className={`nav-item-icon ${navItem.icon}${navIsSelected(navItem) ? ' selected' : ''}${hasNewItem(navItem) && showNew ? ' new' : ''}${sidebarOpen ? ' expanded' : ''}`}
          />
          <div className="nav-item-parent-heading">
            <span>{navItem.name}</span>
            {navItem.items ?
              navItem.name === selectedNav ?
                <i className="far fa-angle-up" /> :
                <i className="far fa-angle-down" /> :
              null
            }
            {hasNewItem(navItem) && showNew &&
              <span className="new-tag">NEW</span>
            }
          </div>
        </div>
      )
    } else if (navItem.url) {
      return (
        <Link
          onClick={() => onClickParent(navItem)}
          to={navItem.url}
          className="nav-item-parent"
          role="button"
        >
          <i
            className={`nav-item-icon ${navItem.icon}${navIsSelected(navItem) ? ' selected' : ''}${hasNewItem(navItem) && showNew ? ' new' : ''}${sidebarOpen ? ' expanded' : ''}`}
          />
          <div className="nav-item-parent-heading">
            <span>{navItem.name}</span>
            {navItem.items ?
              navItem.name === selectedNav ?
                <i className="far fa-angle-up" /> :
                <i className="far fa-angle-down" /> :
              null
            }
            {hasNewItem(navItem) && showNew &&
              <span className="new-tag">NEW</span>
            }
          </div>
        </Link>
      )
    }

  }

  return <>
    <div
      ref={ref}
      className={`prime-sidebar-container${sidebarOpen ? ' open' : ' closed'}`}
    >
      <div className="top-elements">
        <div className="logo-wrapper">
          <div className="p-logo-wrap">
            <Link to="/"><img src={pLogoSrc} /></Link>
          </div>
          <div
            className="prime-logo-wrap"
          >
            <Link to="/"><img src={primeLogoSrc} /></Link>
          </div>
        </div>
        <div
          onClick={() => setSidebarOpen((val) => !val)}
          className={`expand-button${sidebarOpen ? ' expanded' : ''}`}
          role="button"
        >
          {sidebarOpen ?
            <>
              <i className="far fa-chevron-double-left" />
            </> :
            <>
              <i className="far fa-chevron-double-right" />
            </>
          }
        </div>
        <div
          className="nav-item-container"
        >
          {navbarData.map(navItem => {
            if (permissions && hasSectionPermissions(navItem)) {
              return <div
                key={navItem.name}
                className={`nav-item${navIsSelected(navItem) ? ' selected' : ''}${sidebarOpen ? ' expanded' : ''}`}
              >
                {/* TODO: re-write as react router Link when it is a link and doesn't just toggle the child links, eg, My Prime */}

                {/* <div
                  onClick={() => onClickParent(navItem)}
                  className="nav-item-parent"
                  role="button"
                >
                  <i
                    className={`nav-item-icon ${navItem.icon}${navIsSelected(navItem) ? ' selected' : ''}${hasNewItem(navItem) && showNew ? ' new' : ''}${sidebarOpen ? ' expanded' : ''}`}
                  />
                  <div className="nav-item-parent-heading">
                    <span>{navItem.name}</span>
                    {navItem.items ?
                      navItem.name === selectedNav ?
                        <i className="far fa-angle-up" /> :
                        <i className="far fa-angle-down" /> :
                      null
                    }
                    {hasNewItem(navItem) && showNew &&
                      <span className="new-tag">NEW</span>
                    }
                  </div>
                </div> */}
                {renderParentNavItem(navItem)}
                {navItem?.items && selectedNav === navItem.name &&
                  <div
                    className="sub-nav-items"
                  >
                    <ul>
                      {navItem.items.map((item) => {
                        subNavIsSelected(item)
                        if (!item.permission || permissions[item.permission]) return <li
                          key={item.name}
                          className={`subnav-item${!!item.isNew && showNew ? ' new' : ''}`}
                        >
                          <Link to={item.url}
                            className={subNavIsSelected(item) ? 'selected' : null}
                          >{item.name}</Link>
                        </li>
                        return null
                      })}
                    </ul>
                  </div>}
              </div>
            }
          })}
        </div>
        <div
          className={`active-sponsors${sidebarOpen ? ' expanded' : ''}`}
        >
          <div className="sponsors-heading">
            <Tooltip
              title="Add Sponsors"
              placement="topLeft"
            >
              <i
                onClick={openSponsorsModal}
                className="fas fa-plus"
                role="button"
              />
            </Tooltip>
            <div
              onClick={() => setSelectedNav((val) => val === 'Active Sponsors' ? '' : 'Active Sponsors')}
              className="sponsors-expand-btn"
              role="button"
            >
              <span className="sponsors-label">Active Sponsors</span>{selectedNav === 'Active Sponsors' ? <i className="far fa-angle-up" /> : <i className="far fa-angle-down" />}
            </div>
          </div>
          <AnimatePresence>
            {sidebarOpen && selectedNav === 'Active Sponsors' &&
              <motion.div
                {...dropDown}
              >
                <Tags />
              </motion.div>
            }
          </AnimatePresence>
        </div>
      </div>
      <div className="bottom-elements">
        <div
          className={`sidebar-extras${sidebarOpen ? ' expanded' : ''}`}
        >
          <motion.div
            variants={extraItemVariants}
            animate={sidebarOpen ? 'open' : 'closed'}
            onClick={() => window.open('https://xogene.freshdesk.com/support/home', '_blank')}
            className="know-base"
          >
            <i className="fal fa-graduation-cap" />
            {sidebarOpen &&
              <motion.div
                {...fadeIn}
                className="extra-label">
                Knowledge Base
              </motion.div>
            }
          </motion.div>
          <motion.div
            variants={extraItemVariants}
            animate={sidebarOpen ? 'open' : 'closed'}
            onClick={() => window.FreshWidget.show()}
            className="suggest-feature"
          >
            <i className="fal fa-lightbulb-on" />
            {sidebarOpen &&
              <motion.div
                {...fadeIn}
                className="extra-label">
                Suggest a Feature
              </motion.div>
            }
          </motion.div>
        </div>
        <div className="sidebar-copyright">
          &copy; {dayjs().format('YYYY')} Xogene Services LLC | V{publicEnvVar.REACT_APP_PRIME_VERSION}
        </div>
      </div>
    </div>
    <SponsorsModal />
  </>
})

Sidebar.propTypes = {
  navigate: PropTypes.func,
  pathname: PropTypes.string,
}

export default Sidebar