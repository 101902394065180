import React from 'react';

// styles
import './SuccessFailedCellRenderer.scss'

const SuccessFailedCellRenderer = ({ value }) => {
  const classNames = ["success-fail-cell-renderer"]
  if (!value) classNames.push("success")
  else classNames.push("fail")

  return (
    <div className={classNames.join(" ")}>
      {!value ? "Successful" : "Failed"}
    </div>
  );
};

export default SuccessFailedCellRenderer;