import React from 'react';
import FavoriteCellRenderer from '../FavoriteCellRenderer'

const FavoriteCommentCellRenderer = ({ value }) => {
  return <FavoriteCellRenderer
    value={value}
    readOnly
  />
};

export default FavoriteCommentCellRenderer;