import React, { useState, useEffect } from 'react'
import Modal from 'components/Modal/Modal'
import TextInput from '../../../TextInput/TextInput'
import { Button } from 'components-design-system'
import ActiveSponsorsCheckList from './ActiveSponsorsCheckList/ActiveSponsorsCheckList'
import ActiveSponsorsSelected from './ActiveSponsorsSelected/ActiveSponsorsSelected'

// apis
import { usePostGlobalSponsorFilter } from 'api/hooks/global'

// styles
import './ActiveSponsorsModal.scss'

const ActiveSponsorsModal = ({
  sponsors,
  activeSponsors, // sponsors user has added
  showModal,
  onHide,
}) => {
  const [warning, setWarning] = useState(false)
  const [selectedSponsors, setSelectedSponsors] = useState([])
  const [search, setSearch] = useState('')
  const postGlobalSponsorFilter = usePostGlobalSponsorFilter()

  useEffect(() => {
    if (activeSponsors?.[0] === "all") {
      setSelectedSponsors(sponsors)
    } else if (activeSponsors?.length > 0) {
      setSelectedSponsors(activeSponsors)
    }
  }, [activeSponsors])

  const handleSetSponsors = () => {
    const allSelected = sponsors.every((allSpons) => selectedSponsors.find((selSpons) => selSpons === allSpons))
    if (selectedSponsors.length !== 0) {
      postGlobalSponsorFilter({ body: { sponsors: allSelected ? ['all'] : selectedSponsors.map(sponsor => sponsor) } }, {
        onSuccess: () => {
          onHide()
          window.location.reload();
        }
      })
    } else {
      setWarning(true)
    }

  }

  const handleWarning = (val) => {
    setWarning(val)
  }

  const handleSearch = (e) => {
    setSearch(e.target.value)
  }

  return <>
    <Modal
      title="Global Sponsor Filter"
      open={showModal}
      onCancel={onHide}
      hideImage={true}
      className="global-sponsor-filter-modal"
      footer={<div className="modal-footer">
        <Button
          size="lg"
          style={{ marginRight: 24 }}
          variant="outlined"
          onClick={onHide}>
          Cancel
        </Button>
        <Button
          size="lg"
          variant="primary"
          disabled={!selectedSponsors || selectedSponsors.length === 0}
          onClick={handleSetSponsors}>
          Set Sponsors
        </Button>
      </div>}
      width={600}
    >
      <div className="modal-description">
        Set which sponsors you would like to see across Prime. At least one sponsor must be selected
      </div>
      <ActiveSponsorsSelected
        sponsors={sponsors}
        selectedSponsors={selectedSponsors}
        setSelectedSponsors={setSelectedSponsors}
      />
      <p className="search-title">Search Sponsors</p>
      <TextInput
        placeholder="Sponsor name"
        onChange={handleSearch}
        allowClear
      />
      <ActiveSponsorsCheckList
        sponsors={sponsors}
        selectedSponsors={selectedSponsors}
        setSelectedSponsors={setSelectedSponsors}
        search={search}
        handleWarning={handleWarning}
      />
      {warning && <div className="warning-container">
        <i className="fas fa-exclamation-circle" />
        <div className="warning-text">
          At least one sponsor must be selected
        </div>
      </div>}
    </Modal>
  </>
}

export default ActiveSponsorsModal