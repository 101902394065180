import React from 'react';

// styles
import './inner-modal.scss';

const InnerModal = ({
  children,
  footerActions,
  show,
  setShow,
  containerStyle,
  modalStyle,
  wrapClassName,
  className,
}) => {

  return <div
    className={`inner-modal-container ${show ? 'active-inner-modal' : ''}${wrapClassName ? ' ' + wrapClassName : ''}`}
    style={containerStyle}
  >
    <div
      className={`modal-content${className ? ' ' + className : ''}`}
      style={modalStyle}
    >
      <div className="modal-header">
        <i
          className="fal fa-times close-inner-modal"
          onClick={() => setShow(false)}
        />
      </div>
      <div className="modal-body">
        {children}
      </div>
      <div className="modal-footer">
        {footerActions}
      </div>
    </div>
  </div>
}

export default InnerModal