import * as React from 'react'
import { Popover } from 'antd'
import PropTypes from 'prop-types'
import './EllipsisPopover.scss'

const EllipsisPopover = ({
  title,
  content,
  display = "flex",
  overlayClassName,
  className,
}) => {
  return <Popover
    title={title}
    content={<div className={`ellipsis-popover-content${className ? ' ' + className : ''}`}>
      {content}
    </div>}
    placement="bottomRight"
    overlayClassName={`ellipsis-popover${overlayClassName ? ' ' + overlayClassName : ''} ${display}-display`}
  >
    {/* ellipsis hover button */}
    <div className="ellipsis-btn">
      <i className="far fa-ellipsis-h" />
    </div>
  </Popover>
}

EllipsisPopover.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  content: PropTypes.node,
  display: PropTypes.oneOf(['flex', 'block']), // "flex" wraps items around width of popover; "block" shows a row of one item and popover is as wide as the longest item
  overlayClassName: PropTypes.string,
  className: PropTypes.string, // class for popover content
}

export default EllipsisPopover