import React, { useEffect, useState } from 'react';

const InvestigatorFormatter = (props) => {
  const [investigator, setInvestigator] = useState("");

  useEffect(() => {
    if(props.value?.filter) {
      let filteredInvestigators = props?.value?.filter(item => {
        return item?.role?.includes(props.type)
      })
      if(filteredInvestigators?.[0]) {
        let str = formatContact(filteredInvestigators[0]);
        setInvestigator(str);
      }
    }
  }, [props.value])

  const formatContact = ({
    first_name = "",
    middle_name = "",
    last_name = "",
    degrees = "",
  }) => {
    let fullName = [first_name, middle_name, last_name].filter(item => item && item.length > 0).join(" ").trim();
    let fullNameDegree = [fullName, degrees].filter(item => item && item.length > 0).join(", ").trim();

    return fullNameDegree;
  }

  return (
    <div>
      {investigator}
    </div>
  );
};

export default InvestigatorFormatter;