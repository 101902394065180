import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd'
import './button.scss';

const Button = ({
  id,
  className,
  variant = 'primary',
  onClick = () => console.log('Warning: Button onClick missing!'),
  size = 'md',
  disabled = false,
  loading,
  style = {},
  wrapperStyle = {}, // for span around buttons with a tooltip
  children,
  tooltip, // if tooltip not needed leave undefined or set to null
  tooltipPlacement = "top",
}) => {

  const handleClick = (evt) => {
    if (!loading) { // and not disabled
      onClick(evt)
    }
  }

  let classNames = []

  if (loading) {
    classNames.push('loading')
  }

  if (variant === "icon") {
    classNames.push("icon-button-container")
    if (className) classNames.push(className);
    return (
      <Tooltip
        title={tooltip}
        placement={tooltipPlacement}
      >
        <div
          className={classNames.join(" ")}
          style={style}
          onClick={handleClick}>
          {children}
        </div>
      </Tooltip>
    )

  } else if (variant === "link" || variant === "link-danger") {
    classNames.push(`prime-link btn--${variant}`)

    if (className) {
      classNames.push(className)
    }

    if (disabled) {
      classNames.push('btn--link-disabled');
    }

    return (
      <span
        id={id}
        className={classNames.join(" ")}
        data-testid="button-component"
        style={style}
        onClick={handleClick}>
        {children}
      </span>
    )
  }

  classNames.push("prime--btn")

  // get button color
  switch (variant) {
    case "primary":
      classNames.push("btn--primary");
      break;
    case "dashed":
      classNames.push("btn--dashed");
      break;
    case "primary-dashed":
      classNames.push("btn--primary-dashed");
      break;
    case "primary-outlined":
      classNames.push("btn--primary-outlined");
      break;
    case "outlined":
      classNames.push("btn--outlined");
      break;
    case "outlined-bold":
      classNames.push("btn--outlined outlined-bold");
      break;
    case "success":
      classNames.push("btn--success");
      break;
    case "success-outlined":
      classNames.push("btn--success-outlined");
      break;
    case "success-load": // static reload icon // set loading to true to animate icon
      classNames.push("btn--success");
      break;
    // TODO: get rid of "danger" variant since not used by delete Modal?
    case "danger":
      classNames.push("btn--danger");
      break;
    case "danger-outlined":
      classNames.push("btn--danger-outlined");
      break;
    default:
      classNames.push("btn--primary");
  }

  // get button size
  switch (size) {
    case "sm":
      classNames.push("btn--sm");
      break;
    case "md":
      classNames.push("btn--md");
      break;
    case "lg":
      classNames.push("btn--lg");
      break;
    default: classNames.push("btn--md")
  }

  const renderLoadIcon = () => {
    if (loading || variant.endsWith('-load')) {
      return <span className="load-icon-wrap"><i
        className="fal fa-redo btn-loading-spinner"
        style={{ marginBottom: 1 }}
      />
      </span>
    }
    return null
  }

  if (className) {
    classNames.push(className)
  }

  if (disabled) {
    // btn--disabled conflicts with main app !important declaration
    classNames.push("btn-core--disabled")
  }

  if (tooltip) {
    return <span
      className="button-wrapper"
      style={wrapperStyle}
    >
      <Tooltip
        title={tooltip}
        placement={tooltipPlacement}
      > <button
        id={id}
        data-testid="button-component"
        className={classNames.join(" ")}
        style={style}
        disabled={disabled}
        onClick={handleClick}>
          {children}
        </button> </Tooltip>
      {renderLoadIcon()}
    </span>

  }

  return <button
    id={id}
    data-testid="button-component"
    className={classNames.join(" ")}
    style={style}
    disabled={disabled}
    onClick={handleClick}>
    {children}{renderLoadIcon()}
  </button>
}

Button.propTypes = {
    // TODO: get rid of "danger" variant since not used by delete Modal?
    variant: PropTypes.oneOf(["primary", "dashed", "primary-dashed", "primary-outlined", "outlined", "outlined-bold", "success", "success-outlined", "success-load", "danger", "danger-outlined", "icon", "link", "link-danger"]),
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  className: PropTypes.string,
  tooltip: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]), // including string
  tooltipPlacement: PropTypes.oneOf(["top", "left", "right", "bottom", "topLeft", "topRight", "bottomLeft", "bottomRight", "leftTop", "leftBottom", "rightTop", "rightBottom"]), // only relevent when tooltip is set
  loading: PropTypes.bool,
}

export default memo(Button);