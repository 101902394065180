import React from 'react'
import { Radio, Space } from 'antd'
import './RadioGroup.scss'

const RadioGroup = ({
  id,
  value,
  onChange,
  items = [],
  label,
  direction = "vertical",
  disabled
}) => {
  return (
    <div className="prime-radio-group-container">
      <p className="prime-field-label">{label}</p>
      <Radio.Group 
        id={id}
        onChange={e => onChange(e.target.value)} 
        value={value}
        disabled={disabled}>
        <Space
          direction={direction}>
          {items.map(item => {
            return (
              <Radio 
                key={item} 
                disabled={disabled}
                value={item}>
                {item}
              </Radio>
            )
          })}
        </Space>
      </Radio.Group>
    </div>
  );
};

export default RadioGroup;