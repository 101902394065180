import useGetApi from "../useGetApi"
import useMutateApi from "../useMutateApi"

// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

// utils
import getUrl from "api/apiMap"

export const useGetCollaboratorTypes = () => {
  return useGetApi(getUrl(apiEndpoints.collaboratorTypes), queryKeys.collaboratorTypes)
}

// mutate({ body })
export const usePostCollaboratorTypes = () => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(apiEndpoints.collaboratorTypes),
    invalidateQueries: [queryKeys.collaboratorTypes],
    successMsg: "Successfully created collaborator"
  })
}

// mutate({ id, body })
export const usePutCollaboratorTypes = () => {
  return useMutateApi({
    method: "put",
    apiEndpoint: getUrl(apiEndpoints.collaboratorTypes),
    invalidateQueries: [queryKeys.collaboratorTypes],
    successMsg: "Successfully updated collaborator"
  })
}

// mutate({ id })
export const useDeleteCollaboratorTypes = () => {
  return useMutateApi({
    method: "delete",
    apiEndpoint: getUrl(apiEndpoints.collaboratorTypes),
    invalidateQueries: [queryKeys.collaboratorTypes],
    successMsg: "Successfully deleted collaborator"
  })
}