import React from 'react'
import PropTypes from 'prop-types'
import RegsitryBadge from 'components/badges/RegsitryBadge/RegistryBadge'
import './RegistryCellRenderer.scss'

const RegistryCellRenderer = ({
  data,
  value,
  ...props
}) => {
  return <div className="registry-cell-renderer">
    <RegsitryBadge
      value={value}
    />
  </div>
}

RegistryCellRenderer.propTypes = {
  
}

export default RegistryCellRenderer