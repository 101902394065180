import * as React from 'react'
import { Popover, Divider } from 'antd'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import './AvatarPopover.scss'

const AvatarPopover = ({
  user,
  logout,
  children,
}) => {
  return <Popover
    placement="bottomRight"
    overlayClassName="avatar-popover"
    destroyTooltipOnHide={true}
    content={() => <>
      <div className="avatar-user-panel">
        <div className="avatar-panel-user-role">{user?.account_type_string}</div>
        <div className="avatar-panel-user-info">
          <div className="user-avatar">{user?.initials}</div>
          <div className="user-details">
            <div className="user-name">{user?.full_name}</div>
            <div className="user-email">{user?.email}</div>
          </div>
        </div>
      </div>
      <Divider className="avatar-popover-divider" />
      <div className="avatar-links-panel">
        <Link
          className="link-item"
          rel="noopener noreferrer"
          to="/account-settings"
        >
          <i className="link-item-icon fal fa-cog" />
          <div className="link-item-text">Settings</div>
        </Link>
        <Link
          className="link-item"
          rel="noopener noreferrer"
          to="/login"
          onClick={() => logout()}
        >
          <i className="link-item-icon fal fa-sign-out" />
          <div className="link-item-text">Logout</div>
        </Link>
      </div>
    </>}
  >
    {children}
  </Popover>
}

AvatarPopover.propTypes = {
  user: PropTypes.shape({
    initials: PropTypes.string,
    full_name: PropTypes.string,
    email: PropTypes.string,
  }),
  logout: PropTypes.func,
  children: PropTypes.node,
}

export default AvatarPopover