// TODO: convert into react-query hook when get new API?
import cubejs from '@cubejs-client/core';
import getUrl from 'api/apiMap';
import { useGetApi } from 'api/hooks';
import queryKeys from 'api/utils/queryKeys';

export const useCubeJSToken = () => {
  let cubeRes = useGetApi(getUrl("/core/api/v1/dashboard/cube-auth-token"), queryKeys.cubeToken)

  return {data: cubejs(
    cubeRes?.data?.token,
    {apiUrl: cubeRes?.data?.api_url ? cubeRes?.data?.api_url : "hello" }
  )}

}