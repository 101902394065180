import * as React from 'react'
import PropTypes from 'prop-types'
import { useParams, useOutletContext } from 'react-router-dom'

// components
import Panel from 'containers/requests/components/Panel/Panel'
import ActionItemsGrid from 'components/ActionItemsGrid/ActionItemsGrid'
import WorkflowTracker from 'components/WorkflowTracker/WorkflowTracker'
import Team from 'components/Team/Team'
import TimelineFeed from 'components/TimelineFeed/TimelineFeed'
import ApprovalRecords from '../../ApprovalRecords/ApprovalRecords'

// apis
import { useGetRequest } from 'api/hooks/requests/useRequestsApi'
import { useGetRequestRoles } from 'api/hooks/users/userRolesApi'
import {
  useGetRequestTeam,
  usePostRequestTeam
} from 'api/hooks/requests/useRequestTeam'
import { useGetRequestActionItems } from 'api/hooks/requests/useRequestActionItemsApi'
import { useGetRequestFeed } from 'api/hooks/requests/useRequestTimelineApi'
// styles
import './RequestWorkflow.scss'

const RequestWorkflow = ({

}) => {
  const { requestId } = useParams()
  const {
    onOpenPanel,
    onClosePanel,
    selectedActionItem,
    setSelectedActionItem
  } = useOutletContext()
  const { data: request } = useGetRequest(requestId)
  const { data: requestTeam = [] } = useGetRequestTeam(requestId)
  const { data: requestRoles = [] } = useGetRequestRoles(requestId)
  const { data: actionItems = [], refetch: refetchActionItems } = useGetRequestActionItems(requestId)
  const { data: requestFeed = [], refetch: refetchFeed } = useGetRequestFeed(requestId)
  const postRequestTeam = usePostRequestTeam(requestId)

  React.useEffect(() => {
    return () => {
      onClosePanel()
    }
  }, [])

  return <div className="request-workflow">
    <WorkflowTracker
      model={"request"}
      workflowId={request?.workflow_id} />
    <ActionItemsGrid
      type={"request"}
      onOpenPanel={() => onOpenPanel("action-items")}
      actionItems={actionItems}
      selectedActionItem={selectedActionItem}
      setSelectedActionItem={setSelectedActionItem}
      refetchActionItems={refetchActionItems} />
    <div className="panel-cols">
      <TimelineFeed
        type="request"
        timeline={requestFeed?.timeline}
        refetchTimeline={refetchFeed}
        timelineFeedParams={() => { }}
        setTimelineFeedParams={() => { }}
        modalMsg={`${request?.request_id} - ${request?.request_type_name}`}
        panelClassName="request-feed-panel"
      />

      <Team
        type={"request"}
        team={requestTeam}
        roles={requestRoles}
        postTeam={postRequestTeam}
        panelClassName="request-team-panel" />
      <ApprovalRecords 
        panelClassName="panel-col request-approval-panel"/>
    </div>
  </div>
}

RequestWorkflow.propTypes = {

}

export default RequestWorkflow