import { routes_of_administration, pharmaceutical_forms } from '../../../schemas/dataSchemas/enum/enum';

export const participantFlowDataSchema = {
  type: "object",
  properties: {
    eudract: {
      type: "object",
      properties: {
        pre_assignment_period: {
          type: "object",
          properties: {
            started_milestone_achievement: {
              type: "number",
              title: "Started",
              required: true
            },
            completed_milestone_achievement: {
              type: "number",
              title: "Completed",
              required: true
            },
          },
          not_completed_reason_details: {
            type: "array",
            enum: [
              "Adverse event, non-fatal",
              "Adverse event, serious fatal",
              "Consent withdrawn by subject",
              "Physician decision",
              "Pregnancy",
              "Protocol deviation",
              "Other (please specify)"
            ]
          }
        },
        period: {
          type: "object",
          properties: {
            title: {
              title: "Period title",
              type: "text",
            },
            baseline_period: {
              title: "Is this the baseline period?",
              type: "dropdown",
              enum: ["Yes", "No"]
            },
            allocation: {
              title: "Allocation method",
              type: "dropdown",
              enum: ["Randomized - controlled", "Non-randomized - controlled", "Not Applicable"],
              required: true,
            },
            blinded_type: {
              title: "Blinding used",
              type: "dropdown",
              enum: ["Double blind", "Single blind", "Not blinded"],
              required: true,
            },
            clinical_trial_roles: {
              title: "Roles blinded",
              type: "multiselect",
              enum: ["Subject", "Investigator", "Monitor", "Data analyst", "Carer", "Assessor"],
              required: true,
            },
            blinding_implementation_details: {
              title: "Blinding implementation details",
              type: "textarea",
            },
            mutually_exclusive_arms: {
              title: "Are the Arms mutually exclusive?",
              type: "dropdown",
              enum: ["Yes", "No"]
            },
          }
        },
        arms: {
          type: "array",
          properties: {
            title: {
              title: "Arm title",
              type: "text",
              required: true
            },
            description: {
              title: "Arm description",
              type: "textarea",
            },
            type: {
              title: "Arm type",
              type: "dropdown",
              items: ["Experimental", "Active comparator", "Placebo", "No intervention", "Other"],
              required: true
            },
            products: {
              title: "Products",
              type: "custom"
            },
            joined_reason_details: {
              title: "Subject joining reasons",
              type: "custom"
            }
          }
        },
        joined_reason_details: {
          type: "object",
          properties: {
            reason: {
              type: "dropdown",
              enum: ["Late recruitment", "Transferred in from other group/arm", "Other (please specify)"]
            },
            subjects: {
              type: "number"
            }
          }
        },
        products: {
          properties: {
            imp_name: {
              title: "IMP name",
              type: "text",
              required: true
            },
            imp_code: {
              title: "IMP code",
              type: "text"
            },
            other_names: {
              title: "Other names (Separate each with a comma)",
              type: "text"
            },
            routes_of_administration: {
              title: "Routes of administration",
              type: "multiselect",
              enum: routes_of_administration,
              required: true
            },
            pharmaceutical_forms: {
              title: "Pharmaceutical forms",
              type: "multiselect",
              enum: pharmaceutical_forms
            },
            dosage_and_administration_details: {
              title: "Dosage and administration details",
              type: "textarea",
              required: true,
            }
          }
        }
      }
    },
    ctg: {
      type: "object",
      properties: {
        period: {
          type: "object",
          properties: {
            title: {
              title: "Period title",
              type: "text",
            },
          }
        },
      }
    },
    templates: {
      period: {
        ctg: {
          title: "",
          milestone_list: [
            {
              title: "Started",
              required: true,
              comment: "",
              participants: []
            }, {
              title: "Completed",
              required: true,
              comment: "",
              participants: []
            }
          ],
          drop_withdrawn_reason_list: []
        },
        eudract: {
          title: "",
          arms: [],
          milestone_list: [
            {
              title: "Started",
              required: true,
              comment: "",
              participants: []
            }, {
              title: "Completed",
              required: true,
              comment: "",
              participants: []
            }
          ],
          drop_withdrawn_reason_list: []
        }
      },
      milestone: {
        title: "",
        required: false,
        comment: "",
        participants: []
      },
      participant: {
        arm_id: "",
        value: "",
        comment: ""
      }
    },
    global_arms: {
      type: "array",
      properties: {
        title: {
          title: "Arm/Group title",
          type: "textarea",
        },
        description: {
          title: "Arm/Group description",
          type: "textarea",
        },
      }
    },
  }
}