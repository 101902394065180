import React from 'react';
import { create } from 'zustand'

const initialState = {
  participantView: "ctg_periods",
  tableView: "ctg",
  participantRegistries: ["ctg_periods", "eudract_periods"]
}
// this store is used for different view
const useResultsTableViewStore = create((set) => ({
  ...initialState,
  setParticipantView: (view) => set({ participantView: view }),
  setTableView: (view) => set({ tableView: view }),
  resetResultsTableViewStore: () => set(initialState),
}))

export default useResultsTableViewStore;