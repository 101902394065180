// Used by ActivityDashDetailsGrid
import React from 'react'
import './StatusCellRenderer.scss'

const StatusCellRenderer = (props) => {
  let bgColor = "#8AB25B"
  switch(props.value) {
    case "Not at Risk":
      bgColor = '#8AB25B';
      break;
    case "At Risk":
      bgColor = '#DBA51F';
      break;
    case "Late":
      bgColor = '#D7542C';
      break;
    case "Planned":
      bgColor = '#18669E';
      break;
    case "Cancelled":
      bgColor = '#8D8D8D';
      break;
    case "On Hold":
      bgColor = '#8D8D8D';
      break;
    default:
      bgColor = 'white'
  }

  let style = {
    height: 10,
    width: 10, 
    borderRadius: 5,
    marginRight: 8,
    backgroundColor: bgColor,
  }

  return (
    <div className="status-cell-renderer">
      <div style={style}/>
      <span>
        {props.value}
      </span>
    </div>
  )
}

export default StatusCellRenderer