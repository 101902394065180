import React from 'react';
import { Tooltip } from 'antd'
import { capitalizeFirstLetter } from 'utilities/misc';

import './OptionBadge.scss'

const OptionBadge = ({
  type,
  style,
  tooltip,
}) => {

  let title = ""
  if (type === "document") {
    title = "D"
  } else if (type === "study") {
    title = "S"
  }else if (type === "dsrequest") {
    title = "R"
  }

  let tooltipContent = ""
  if(type) {
    if(type === "dsrequest") tooltipContent = capitalizeFirstLetter("request")
    else tooltipContent = capitalizeFirstLetter(type)
  }

  const renderTooltip = (
    <>
      {tooltip ?
        <>
          <b style={{ fontWeight: 600 }}>
            Values Changed:
          </b> {tooltip}
        </>
        : tooltipContent
      }
    </>
  );

  return (
    <Tooltip title={renderTooltip}>
      <div
        className={`badge-container ${type?.toLowerCase()}`}
        style={style}
      >
        {title}
      </div>
    </Tooltip>
  )
};

export default OptionBadge;