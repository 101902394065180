import { useState } from 'react';
import { useShallow } from 'zustand/react/shallow'

// components
import PrimeField from 'components/PrimeField/PrimeField';
import MeasureCategory from '../MeasureCategory/MeasureCategory';
import RowActions from '../../../components/RowActions/RowActions';

// context
import useResultsTableViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableViewStore';
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';

// utils
import { moveRowDownHelper, moveRowUpHelper } from '../../helpers/helpers';

const MeasureRow = ({
  row,
  rowIndex,
  measure,
  measureIndex,
  outcomeData,
  setOutcomeData,
  showUnitsAnalyzed
}) => {
  const [showActions, setShowActions] = useState(false);

  const {
    readOnly
  } = useAuthoringViewStore(
    useShallow(state => ({ readOnly: state.readOnly }))
  )

  const {
    tableView,
  } = useResultsTableViewStore()

  const handleTitle = (e) => {
    let tempData = { ...outcomeData }

    Object.keys(tempData).forEach(registry => {
      let foundOMIndex = tempData[registry].outcome_measures.findIndex(om => om.id === measure.id);
      let om = tempData[registry].outcome_measures[foundOMIndex];

      if (foundOMIndex !== -1) {
        om.measure_rows[rowIndex].title = e;
      }
    })

    setOutcomeData(tempData);
  }

  const handleDeleteRow = (applyAll) => {
    let tempData = { ...outcomeData }

    Object.keys(tempData).forEach(registry => {
      if (applyAll || registry === tableView) {
        let foundBMIndex = tempData[registry].outcome_measures.findIndex(om => om.id === measure.id);
        let om = tempData[registry].outcome_measures[foundBMIndex];

        if (foundBMIndex !== -1) {
          om.measure_rows.splice(rowIndex, 1);
        }
      }
    })

    setOutcomeData({ ...tempData });
  }

  const handleUp = () => {
    let tempData = { ...outcomeData }
    Object.keys(tempData).forEach(registry => {
      let foundOMIndex = outcomeData[registry].outcome_measures.findIndex(om => om.id === measure.id);
      if (foundOMIndex !== -1) {
        if (tempData[registry].outcome_measures[foundOMIndex]?.measure_rows) {
          let foundRowIndex = tempData[registry].outcome_measures[foundOMIndex]?.measure_rows.findIndex(measureRow => measureRow.id === row.id);
          if (foundRowIndex !== -1 && foundRowIndex !== 0) {
            moveRowUpHelper(tempData, registry, foundOMIndex, foundRowIndex);
          }
        }
      }
    })

    setOutcomeData({ ...tempData });
  }

  const handleDown = () => {
    let tempData = { ...outcomeData }
    Object.keys(tempData).forEach(registry => {
      let foundOMIndex = outcomeData[registry].outcome_measures.findIndex(om => om.id === measure.id);
      if (foundOMIndex !== -1) {
        if (tempData[registry].outcome_measures[foundOMIndex]?.measure_rows) {
          let foundRowIndex = tempData[registry].outcome_measures[foundOMIndex]?.measure_rows.findIndex(measureRow => measureRow.id === row.id);
          if (foundRowIndex !== -1 && foundRowIndex !== tempData[registry].outcome_measures[foundOMIndex].measure_rows.length - 1) {
            moveRowDownHelper(tempData, registry, foundOMIndex, foundRowIndex);
          }
        }
      }
    })

    setOutcomeData({ ...tempData });
  }

  return (
    <>
      {row.categories.map((category, catIndex) => {
        if (catIndex === 0) {
          return (
            <tr
              key={row?.id + "-" + category?.id}
              onMouseEnter={() => setShowActions(true)}
              onMouseLeave={() => setShowActions(false)}>
              <td
                className='col-sm'
                rowSpan={row.categories.length}>
                <PrimeField
                  schema={{
                    type: "textarea",
                    label: "Row Title"
                  }}
                  readOnly={readOnly}
                  onChange={handleTitle}
                  value={row.title || ""} />
                <RowActions
                  showActions={showActions}
                  setShowActions={setShowActions}
                  showDown={rowIndex !== measure.measure_rows.length - 1}
                  showUp={rowIndex !== 0}
                  handleUp={handleUp}
                  handleDown={handleDown}
                  handleDelete={handleDeleteRow}
                  canDelete={measure.measure_rows.length > 1}
                  deleteModalTitle="Delete row?"
                  jpath={`OutcomeRptGroup.om-${measure.id}-row-${row.id}`} />
              </td>
              <MeasureCategory
                row={row}
                measure={measure}
                measureIndex={measureIndex}
                category={category}
                catIndex={catIndex}
                rowIndex={rowIndex}
                showUnitsAnalyzed={showUnitsAnalyzed}
                outcomeData={outcomeData}
                setOutcomeData={setOutcomeData} />
            </tr>
          )
        } else {
          return (
            <tr key={row?.id + "-" + category?.id}>
              <MeasureCategory
                row={row}
                measure={measure}
                measureIndex={measureIndex}
                category={category}
                catIndex={catIndex}
                rowIndex={rowIndex}
                showUnitsAnalyzed={showUnitsAnalyzed}
                outcomeData={outcomeData}
                setOutcomeData={setOutcomeData} />
            </tr>
          )
        }
      })}
    </>
  );
};

export default MeasureRow;