import { useState, useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow'
import moment from 'moment';
import parse from "html-react-parser";

// components
import CommentFilterTag from 'components/CommentFilterTag/CommentFilterTag';

// context
import useAuthoringCommentsStore from '../../hooks/stores/useAuthoringCommentsStore';

// styles
import './CommentItem.scss'

const CommentItem = (props) => {
  const {
    tags,
    field_key,
    field_name,
    author_fullname,
    action_date,
    text,
    resolved,
  } = props;

  const {
    setOpenComment,
  } = useAuthoringCommentsStore(
    useShallow(state => ({ 
      setOpenComment: state.setOpenComment,
    }))
  )


  const [visible, setIsVisible] = useState(true)

  let name = "";
  let initials = "";

  if (author_fullname) {
    let names = author_fullname.split(" ");
    if (names[0] && names[0].length > 0) {
      name += names[0];
      initials += names[0][0]
    }
    if (names[1] && names[1].length > 0) {
      name += ` ${names[1][0]}.`
      initials += names[1][0]
    }
    name.toUpperCase();
    initials.toUpperCase();
  }

  return (
    <div
      className={`authoring-comment ${visible ? 'field-in-viewport' : ''}`}
      onClick={() => setOpenComment(field_key)}
    >
      <div className="comment-initials">
        {initials}
      </div>
      <div
        id={field_key + "-side-panel-comment"}
        className="comment-container"
      >
        <div className="flex justify-between">
          <div className='flex items-center'>
            <div className="author-header">
              {name}
            </div>
            <div className="date-text">
              {moment(action_date).format("MMM DD, YYYY")}
            </div>
          </div>
          <div className={`resolved-container ${resolved ? "comment-resolved" : ""}`}>
            {resolved && <i className="fal fa-check"></i>}
          </div>
        </div>
        <div className="flex items-center">
          <div className="field-name">
            {field_name.toUpperCase()}
          </div>
        </div>
        <div>
          <div className="comment-text html-parse-container">
            {parse(text)}
          </div>
        </div>
        <div className="flex" style={{ marginTop: 8 }}>
          <CommentFilterTag title={tags?.[0] && tags[0]} />
        </div>
      </div>
    </div>
  );
};

export default CommentItem;