import React, { useEffect, useState } from 'react'; 
import styled from 'styled-components';

const PRSValidationBadge = ({
  type = "error",
  num = 0,
  style = {}
}) => {
  const [iconClass, setIconClass] = useState("fas fa-do-not-enter");

  useEffect(() => {
    if(type === "error") setIconClass("fas fa-do-not-enter");
    if(type === "warning") setIconClass("fas fa-exclamation-triangle");
    if(type === "note") setIconClass("fas fa-info-circle");
    if(type === "non-site") setIconClass("fas fa-times-circle")
  }, [type])

  return (
    <Container style={style}>
      <Icon type={type} className={iconClass}/>
      <Text type={type}>{num} {type.charAt(0).toUpperCase() + type.slice(1)}</Text>
    </Container>
  );
};

const getColor = (type) => {
  switch(type) {
    case "error":
      return "#D6542D";
    case "warning":
      return "#DBA51F";
    case "non-site":
      return "#D6542D";
    default: 
      return "#18669E";
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Icon = styled.i`
  color: ${props => getColor(props.type)};
  margin-right: 6px;
`

const Text = styled.div`
  color: ${props => getColor(props.type)};
  margin: 0;
`
export default PRSValidationBadge;