import { useState, useEffect, useContext, Fragment } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

// context
import { PFContext } from '../ParticipantFlowPreview';

// components
import EudractArms from '../../components/EudractArms/EudractArms';

// styles
import './Period.scss';

const Period = ({
  arms,
  period,
  schema,
  unitType
}) => {
  let { view } = useContext(PFContext)
  let firstColumnWidth = 250;
  const [tables, setTables] = useState([])

  const displayPeriodProps = () => {
    return (
      <Fragment key={uuidv4()}>
        {Object.keys(schema.properties[view].properties.period.properties).map(key => {
          let value = period[key];

          if (key === "clinical_trial_roles" && Array.isArray(value)) {
            value = value.join(", ")
          }
          return (
            <div className="period-preview-property-container" key={uuidv4()}>
              <div className='period-preview-property-title'>
                {schema.properties[view].properties.period.properties[key].title}
              </div>
              <div className='period-preview-property-value'>
                {value}
              </div>
            </div>
          )
        })}
      </Fragment>
    )
  }

  const displayArms = (arms) => {
    return (
      <tr key={uuidv4()} style={{ backgroundColor: "rgb(228, 254, 224)", fontWeight: "bold" }}>
        <td className="arm-column" style={{ width: 250 }} />
        {arms?.map(arm => (
          <td
            key={uuidv4()}
            className="arm-column"
            style={{ minWidth: 125 }}>
            {arm.title}
          </td>
        ))}
      </tr>
    )
  }

  const displayRow = (row) => {
    return (
      <tr key={row.id}>
        <td style={{ backgroundColor: "rgb(228, 254, 224)", width: firstColumnWidth }}>
          <div style={{ fontWeight: "bold" }}>{row.title}</div>
          {row.comment && <div style={{ color: "#383E47", opacity: .75, marginTop: 6 }}>{row.comment}</div>}
        </td>
        {row?.participants?.map(participant => {
          let value = participant.value;
          let units_achieve = participant.units_achieve;
          if (value === "") value = "-";
          if (units_achieve === "") units_achieve = "-"


          return (
            <td key={participant.arm_id + row.id}>
              <div>{value}</div>
              {unitType && participant?.units_achieve && <div>{units_achieve} {unitType}</div>}
              {participant.comment && <div style={{ color: "#383E47", opacity: .75, marginTop: 6 }}>{participant.comment}</div>}
            </td>
          )
        })}
      </tr>
    )
  }

  const displayNotCompleted = (table) => {
    return (
      <tr>
        <td style={{ backgroundColor: "rgb(228, 254, 224)", width: firstColumnWidth }}>
          <div style={{ fontWeight: "bold" }}>Not Completed</div>
        </td>
        {table.arms.map((arm, index) => {
          let notCompleted = 0;
          let started = Number(table.period?.milestone_list?.[0]?.participants[index]?.value) || 0;
          let completed = Number(table.period?.milestone_list?.[table?.period?.milestone_list.length - 1]?.participants[index]?.value) || 0;
          notCompleted = started - completed;

          return (
            <td>{notCompleted}</td>
          )
        })}
      </tr>
    )
  }

  const displaySubjectsJoining = (tableArms) => {
    let isSubjectsJoining = false;

    tableArms.forEach(arm => {
      if (arm?.joined_reason_details?.length > 0) isSubjectsJoining = true;
    })

    if (isSubjectsJoining === false) return <></>
    return (
      <tr key={uuidv4()}>
        <td
          className="arm-column"
          style={{
            width: 150,
            backgroundColor: "rgb(228, 254, 224)",
            fontWeight: "bold"
          }}>
          Subject joining reasons
        </td>
        {tableArms?.map(arm => (
          <td
            key={uuidv4()}
            className="arm-column"
            style={{ minWidth: 125 }}>
            {arm?.joined_reason_details?.map((reason, i) => {
              return (
                <div
                  key={arm.id + "reason-" + i}
                  style={{
                    marginBottom: 8
                  }}>
                  <div>Reason: {reason.reason}</div>
                  <div>Details: {reason.reason_detail}</div>
                  <div>Subjects: {reason.subjects}</div>
                </div>
              )

            })}
          </td>
        ))}
      </tr>
    )
  }

  useEffect(() => {
    let startIndex = 0;
    let endIndex = 5;
    let tables = [];

    if (arms.length > endIndex) {
      while (arms.length > startIndex) {
        if (endIndex >= arms.length) endIndex = arms.length;
        let table = {
          arms: arms.slice(startIndex, endIndex),
          period: {
            milestone_list: period?.milestone_list?.map(milestone => {
              return {
                ...milestone,
                participants: milestone.participants.slice(startIndex, endIndex)
              }
            }),
            drop_withdrawn_reason_list: period?.drop_withdrawn_reason_list?.map(milestone => {
              return {
                ...milestone,
                participants: milestone.participants.slice(startIndex, endIndex)
              }
            }),
          }
        }
        tables.push(table);
        startIndex = endIndex;
        endIndex += 5;
      }
    } else {
      tables = [{
        arms,
        period
      }]
    }
    setTables(tables);
  }, [arms.length])

  return (
    <div className="period-preview-container">
      <div className="period-preview-title">
        Period Title: {period.title}
      </div>
      {view === "eudract" && displayPeriodProps()}
      {view === "eudract" && (
        <EudractArms
          arms={arms}
          schema={schema}
          view={view} />
      )}
      {tables?.map(table => (
        <table className="period-preview-table" key={uuidv4()}>
          <tbody>
            {displayArms(table.arms)}
            {table?.period?.milestone_list?.map(row => displayRow(row))}

            {displayNotCompleted(table)}

            {table?.period?.drop_withdrawn_reason_list?.map(row => displayRow(row))}
            {/* Subject joining reason */}
            {view === "eudract" && displaySubjectsJoining(table.arms)}
          </tbody>
        </table>
      ))}
    </div>
  );
};

export default Period;