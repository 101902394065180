// TODO: remove this context provider. Have components use react query hooks and helper functions instead
import { useState, createContext, useContext } from 'react';
import axios from 'axios';

export const UsersContext = createContext({});

export const UsersProvider = ({ children }) => {
  const [users, setUsers] = useState([]);
  const [userDropdownOptions, setUserDropdownOptions] = useState([]);

  // TODO: add is_locked and last_login, maybe "fullname", to API response
  const getUsers = () => {
    axios.get("/api/all-users").then(res => {
      let { users } = res.data.data;
      setUsers(users);
      setUserDropdownOptions(usersToString(users));
    })
  }

  const usersToString = (users) => {
    if (!users) return [];
    return users.map((user) => {
      return userToString(user);
    });
  }

  // TODO: rename to getUserToString
  const userToString = (user) => {
    let name = [];
    if (user?.first_name) name.push(user.first_name)
    if (user?.last_name) name.push(user.last_name)
    if (user?.email) name.push(`(${user.email})`)
    return name.join(" ");
  }

  // value is array of user strings
  const usersStrToUserIds = (value) => {
    let userIds = [];
    value?.forEach(val => {
      users.forEach(user => {
        if (val === userToString(user)) {
          userIds.push(user.id)
        }
      })
    })

    return userIds;
  }

  return (
    <UsersContext.Provider
      value={{
        users,
        setUsers,
        getUsers,
        userDropdownOptions,
        userToString,
        usersToString,
        usersStrToUserIds,
      }}>
      {children}
    </UsersContext.Provider>
  );
};

const useUsers = () => {
  return useContext(UsersContext)
};

export default useUsers;