import React, { useEffect, useState, memo } from 'react';
import styled, { css } from 'styled-components';

// animations
import { rotate } from '../../animations/keyframes';

// Component
import { Button } from 'components-design-system'

const PRSRecordStatus = (props) => {
  const [iconName, setIconName] = useState("");
  const [status, setStatus] = useState("Not Yet Started");

  useEffect(() => {
    if (props.data) {
      if (props.data.is_cancelled === true) {
        setStatus("Upload Cancelled");
      } else if (props.data.is_processing === true) {
        setStatus("Uploading");
      } else if (props.data.is_queued === true) {
        setStatus("Not Yet Started");
      } else if (props.data.upload_failed === true) {
        setStatus("Failed");
      } else if (props.data && props.data.upload_report && props.data.upload_report.recordStatus) {
        let { recordStatus } = props.data.upload_report;
        setStatus(recordStatus)
      }
    }
  }, [props.data]);

  useEffect(() => {
    switch (status) {
      case "Uploading":
        setIconName("far fa-redo");
        break;
      case "Not Yet Started":
        setIconName("fas fa-clock");
        break;
      case "Failed":
        setIconName("fas fa-minus-circle");
        break;
      case "Upload Cancelled":
        setIconName("far fa-ban");
        break;
      case "Release Cancelled":
        setIconName("far fa-ban");
        break;
      default:
        setIconName("fas fa-check-circle");
    }
  }, [status]);

  return (
    <Container>
      <Icon variant={status} className={iconName} />
      {status}
      {props.data.receipt_url && <Button variant="link" style={{ marginLeft: 16 }} onClick={() => window.open(props.data.receipt_url)}>View Receipt</Button>}
    </Container>
  );
};

export default memo(PRSRecordStatus);

const animationMixin = css`
  animation:  ${rotate} 2s linear infinite;
`

const getColor = (variant) => {
  switch (variant) {
    case "Not Yet Started": return "#A8A8A8";
    case "Uploading": return "#18669E";
    case "Failed": return "#D7542C";
    case "Upload Cancelled": return "#D7542C";
    case "Release Cancelled": return "#D7542C";
    default: return "#8AB25B"
  }
}

const Container = styled.div`

`

const Icon = styled.i`
  margin-right: 12px;
  color: ${props => getColor(props.variant)};
  ${props => props.variant === "Uploading" ? animationMixin : `animation: none;`}
`