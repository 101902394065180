import React from "react";
import dayjs from "dayjs";

const TimeCellRenderer = ({ value }) => {
  if(!value) return <div></div>
  return (
    <div>
      {dayjs(value).format('h:mm A')}
    </div>
  );
};

export default TimeCellRenderer;
