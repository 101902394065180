// move inside badges/
import React from 'react'
import { Tooltip } from 'antd'

// styles
import './status-badge.scss';

const StatusBadge = ({ type, size, tooltip }) => {
  let classNames = ["status-badge-container"];
  switch (type) {
    case "Completed":
      classNames.push("status-completed");
      break;
    case "In Progress":
      classNames.push("status-in-progress");
      break;
    case "Cancelled":
      classNames.push("status-cancelled");
      break;
    case "Late":
      classNames.push("status-cancelled");
      break;
    default:
      classNames.push("status-in-progress")
  }

  switch (size) {
    case "sm":
      classNames.push("sm-badge");
      break;
    case "md":
      break;
    case "lg":
      break;
    default:
  }

  return <Tooltip title={tooltip}>
    <div className={classNames.join(' ')}>
      {type}
    </div>
  </Tooltip>
}

export default StatusBadge