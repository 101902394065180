import React, {
  useMemo
} from 'react';

// components
import InputHOC from './InputHOC';

// styles
import './InputWrapper.scss'

const InputWrapperContainer = (WrappedComponent) => (props) => {

  // for some reason RJS ui:options have two different ways of surfacing in the props
  // this function formats ui:options 
  const formatOptions = (rjProps) => {
    let options = rjProps.uiSchema && rjProps.uiSchema["ui:options"] ? rjProps.uiSchema["ui:options"] : rjProps.options;
    let label = options.title;

    if (label === "data-schema") {
      label = rjProps.schema.title;
    }

    let res = {
      ...options,
      label
    }

    if (rjProps.schema.tooltip) {
      res.tooltip = rjProps.schema.tooltip
    }

    if (rjProps?.uiSchema?.["ui:options"]?.tooltip) {
      res.tooltip = rjProps.uiSchema["ui:options"].tooltip
    }

    if (rjProps?.schema?.uiSchema) {
      res = {
        ...res,
        ...rjProps.schema.uiSchema
      }
      if (rjProps.schema.label) {
        res.label = rjProps.schema.label
      }
    }

    return res;
  }

  return <InputHOC
    {...props}
    WrappedComponent={WrappedComponent}
    formatOptions={formatOptions} />
}

export default InputWrapperContainer;