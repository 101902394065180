import React from 'react';

// styles
import './document-name-type.scss';

const DocumentNameType = (props) => {
  let title = props.data?.name;
  let type = props.data?.document_type_name;

  return (
    <div className="document-name-cell-renderer-container">
      <div className="title">{title}</div>
      <div className="type">{type}</div>
    </div>
  );
};

export default DocumentNameType;