import React, { Fragment } from 'react'
import { useParams } from 'react-router-dom'
import PrimeField from 'components/PrimeField/PrimeField'
import { Button } from 'components-design-system'
import Modal from 'components/Modal/Modal'
import Tag from 'components/Tag/Tag'

import { useGetStudy, usePutStudy } from 'api/hooks/studies/useStudiesApi'
import './EditCountriesModal.scss'

const EditCountriesModal = ({
  showModal,
  onHide,
  variant,
  countryCodes,
  countries,
  studyId,
}) => {

  const { studyId: study_pk } = useParams()

  const {
    data: study,
  } = useGetStudy(study_pk)
  const [search, setSearch] = React.useState('')
  const [extended, setExtended] = React.useState(true)
  const [selectedCountries, setSelectedCountries] = React.useState([])
  const [buttonLoading, setButtonLoading] = React.useState(false)


  const putStudy = usePutStudy(study_pk)

  React.useEffect(() => {
    if (countryCodes) init();
  }, [variant])


  const init = () => {
    if (countryCodes) {
      setSelectedCountries(countryCodes.map((code) => countries.find(country => country.cca2 === code)))
    }
  }

  const onDeleteCountry = (country) => {
    setSelectedCountries(selectedCountries.filter(item => item.name.common !== country))
  }

  const handleCountry = (evt, country) => {
    let temp = [...selectedCountries]
    if (evt) {
      temp.push(country)
    } else {
      const foundIndex = temp.findIndex(item => item.area === country.area)
      if (foundIndex !== -1) temp.splice(foundIndex, 1)
    }
    setSelectedCountries(temp)
  }

  const handleCheckAll = (evt) => {
    let selectCountries = []
    if (evt) {
      Object.keys(countries)
        .forEach((code) => {
          selectCountries.push(countries[code])
        })
    }
    setSelectedCountries(selectCountries)
  }

  const onSaveChanges = async () => {
    let results = selectedCountries.filter(country => country).map(country => country.cca2)
    let request = {
      study_data: {
        ...study.study_data,
      }
    }

    if (variant === "actual") {
      request.study_data.actual_countries = results
    } else if (variant === "planned") {
      request.study_data.planned_countries = results
    }

    putStudy({
      id: `${study_pk}/`,
      body: request
    }, {
      onSuccess: () => {
        onHide(null)
      }
    })
  }

  let breaker = null

  if (!countries) {
    return null
  }

  return <Modal
    subtitle={studyId}
    title={`Edit Study - ${variant === "planned" ? "Planned Countries" : "Actual Countries"}`}
    message={`Select the ${variant} countries where this study will be conducted`}
    open={showModal}
    onCancel={onHide}
    footer={true}
    hideImage={true}
    width={540}
    className="edit-countries-modal"
  >
    <div className="edit-countries-modal-selected">
      <div className="edit-countries-modal-selected-head">
        <p className="edit-countries-modal-selected-heading">{`Selected ${variant === "planned" ? "Planned Countries" : "Actual Countries"} (${selectedCountries.length})`}</p>
        <p
          className="edit-countries-modal-selected-clear-all"
          onClick={() => handleCheckAll(false)}
        >
          Deselect All
        </p>
        <div
          className="edit-countries-modal-selected-chevron"
          onClick={() => setExtended((state) => !state)}
        >
          {extended
            ? <i
              className="fal fa-chevron-up"
              style={{ cursor: 'pointer' }}
            // onClick={onExtend} 
            />
            : <i
              className="fal fa-chevron-down"
              style={{ cursor: 'pointer' }}
            // onClick={onExtend} 
            />
          }
        </div>
      </div>
      {extended &&
        <div className="edit-countries-modal-selected-body">
          {selectedCountries.map(country => {
            return (
              <Tag
                key={country.ccn3}
                bgColor="#B3e6ff"
                text={country?.name?.common}
                onDelete={onDeleteCountry}
              />
            )
          })}
        </div>
      }
    </div>
    <PrimeField
      schema={{
        type: "text",
        label: "Search Countries",
        placeholder: "Country name"
      }}
      onChange={(evt) => setSearch(evt)}
    />
    <div className="edit-countries-modal-select">
      <div className="edit-countries-modal-check-item">
        <PrimeField
          schema={{
            type: "checkbox",
            label: "Select All Countries"
          }}
          value={selectedCountries.length === Object.keys(countries).length}
          onChange={(evt) => handleCheckAll(evt)}
        />
      </div>
      {countries.sort(function (a, b) {
        if (a.name.common < b.name.common) { return -1; }
        if (a.name.common > b.name.common) { return 1; }
        return 0;
      }).map(countryData => {
        let isHidden = false
        let shouldBreak = false
        let checkItemStyle = { paddingLeft: 16 }

        if (search && !countryData?.name?.common?.toLowerCase()?.includes(search?.toLowerCase())) {
          isHidden = true
          checkItemStyle.display = "none"
        }

        if (!isHidden) {
          if (breaker !== countryData?.name?.common[0]) {
            shouldBreak = true;
            breaker = countryData?.name?.common[0]
          }
        }

        return (
          <Fragment key={countryData.cca3}>
            {shouldBreak
              && <div className="edit-countries-modal-select-breaker">{countryData.name?.common?.[0]?.toUpperCase()} </div>
            }
            <div
              className="edit-countries-modal-check-item"
              style={checkItemStyle}>
              <PrimeField
                schema={{
                  type: "checkbox",
                  label: countryData?.name?.common
                }}
                value={selectedCountries.includes(countryData)}
                onChange={(evt) => handleCountry(evt, countryData)}
              />
            </div>
          </Fragment>
        )
      })}
    </div>
    <div className="edit-countries-modal-actions">
      <Button
        onClick={() => onHide(null)}
        variant="outlined">
        Cancel
      </Button>
      <Button
        onClick={() => onSaveChanges()}
        loading={buttonLoading}
        disabled={buttonLoading}
        variant="primary">
        Set {variant === "planned" ? "Planned Countries" : "Actual Countries"}
      </Button>
    </div>
  </Modal>
}

export default EditCountriesModal