import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import usePlacesAutocomplete, {
  getDetails
} from "use-places-autocomplete";
import useOnClickOutside from "react-cool-onclickoutside";
import './styles.css';
import PrimeField from 'components/PrimeField/PrimeField';

// hooks 
import useLocations from 'context/hooks/useLocations';

const RJAutocomplete = (props) => {
  const [isDisabled, setDisabled] = useState(false);
  const [requiredError, setRequiredError] = useState(false);
  const [invalidText, setInvalidText] = useState("This is a required field");
  const [isMounting, setIsMounting] = useState(true);
  const [currSiteId, setCurrSiteId] = useState(null);

  const options = props.options;
  const maskingConfigs = useLocations();
  
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ['establishment']
    },
    debounce: 300,
  });

  useEffect(() => {
    if(props.formContext && props.formContext.formData && props.formContext.formData.site_id) {
      setCurrSiteId(props.formContext.formData.site_id)
    }
  }, [props.formContext]);

  useEffect(() => {
    setIsMounting(true);
  }, [currSiteId])

  useEffect(() => {
    if(options.required) {
      if(props.value) {
        setValue(props.value)
      } else {
        setValue("")
      }
    } 
  }, [props.value])

  useEffect(() => {
    if(value && value.length > 0) {
      setInvalidText("");
      setRequiredError(false);
    } else {
      if(options.invalidText) {
        setInvalidText(options.invalidText)
      } else {
        setInvalidText("This is a required field.")
      }
      setRequiredError(true);
    }

  }, [value])

  useEffect(() => {
    if(maskingConfigs?.locationMaskSettings) {
      const { facility_mask } = maskingConfigs.locationMaskSettings;
      if(props.id.includes("facility")) {
        facility_mask ? setDisabled(true) : setDisabled(false)
      }
    }
  }, [maskingConfigs?.locationMaskSettings]);


  const ref = useOnClickOutside(() => {
    clearSuggestions();
  });

  const handleInput = (e) => {
    setIsMounting(false)
    setValue(e);
    props.onChange(e)
  };

  const handleSelect = ({ description }, placeId) => () => {
    clearSuggestions();

    const parameter = {
      placeId: placeId,
      fields: ["address_components", "name"],
    };

    getDetails(parameter)
      .then((details) => {
        props.formContext.onAutocompleteChange(details)
        setValue(details.name, false);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;
      return (
        <ListItem key={place_id} onClick={handleSelect(suggestion, place_id)} className="bx--list-box__menu-item">
          <strong className="bx--list-box__menu-item__option">{main_text}</strong>
        </ListItem>
      );
  });

  return (
    <Container ref={ref}>
      <PrimeField
        schema={{
          id: props.id,
          type: "text",
          label: "Facility Name",
          orientation: "vertical",
          placeholder: "Enter facility name"
        }}
        disabled={isDisabled}
        showError={requiredError}
        errorMessage={invalidText}
        value={value}
        onChange={handleInput}/>
      {status === "OK" && !isDisabled && !isMounting && <UnorderedList className="bx--list-box__menu" style={{ marginLeft: 39 }}>{renderSuggestions()}</UnorderedList>}
    </Container>
  );
};

export default RJAutocomplete;

const Container = styled.div`

`

const Input = styled.input`

`

const UnorderedList = styled.ul`

`

const ListItem = styled.li`
`