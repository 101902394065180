import { useState } from 'react';
import { useShallow } from 'zustand/react/shallow'

// components
import PrimeField from 'components/PrimeField/PrimeField';
import RowActions from '../../../components/RowActions/RowActions';
import CellValidation from '../../../components/CellValidation/CellValidation';

// context
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';

// utils
import { moveLeftHelper, moveRightHelper } from '../../helpers/helpers';

const Milestone = ({
  i,
  milestone,
  onChangeOtherMilestone,
  participantData,
  setParticipantData,
  errorKeyPrefix
}) => {
  const [showActions, setShowActions] = useState(false)
  const {
    readOnly
  } = useAuthoringViewStore(
    useShallow(state => ({ readOnly: state.readOnly }))
  )

  const handleDelete = () => {
    let tempData = { ...participantData };
    tempData.pre_assignment_period.other_milestones.splice(i, 1)
    setParticipantData(tempData)
  }

  const handleUp = () => {
    let tempData = { ...participantData };
    moveLeftHelper(tempData.pre_assignment_period.other_milestones, i)
    setParticipantData(tempData)
  }

  const handleDown = () => {
    let tempData = { ...participantData };
    moveRightHelper(tempData.pre_assignment_period.other_milestones, i)
    setParticipantData(tempData)
  }

  return (
    <tr
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}>
      <td className="header-column">
        <PrimeField
          schema={{
            placeholder: "Enter Milestone",
            type: "text",
            style: { width: "100%" }
          }}
          readOnly={readOnly}
          allowClear={false}
          value={milestone.title || ""}
          onChange={e => onChangeOtherMilestone(e, "title", i)}
        />
        <RowActions
          showActions={showActions}
          setShowActions={setShowActions}
          handleDelete={handleDelete}
          canDelete={true}
          hideOptions={true}
          showDown={participantData.pre_assignment_period.other_milestones.length - 1 !== i}
          showUp={i !== 0}
          handleUp={handleUp}
          handleDown={handleDown}
          deleteModalTitle="Delete milestone?"
          jpath={`ParticipantFlowGroup.pf-pre-period-milestone-${milestone.id}`} />
        <CellValidation
          table="participants"
          errorKey={`${errorKeyPrefix}_${i + 1}_title`} />
      </td>
      <td>
        <PrimeField
          schema={{
            placeholder: "Number of Subjects",
            type: "number",
            style: { width: "100%" }
          }}
          readOnly={readOnly}
          allowClear={false}
          value={milestone.subjects}
          onChange={e => onChangeOtherMilestone(e, "subjects", i)}
        />
        <CellValidation
          table="participants"
          errorKey={`${errorKeyPrefix}_${i + 1}_subjects`} />
      </td>
    </tr>
  );
};

export default Milestone;