// TODO: rename StudyOverview
import { useMemo, useState, useEffect } from 'react'
import { useParams, useNavigate, useLocation, Link } from "react-router-dom"

// components
import Team from 'components/Team/Team'
import TimelineFeed from 'components/TimelineFeed/TimelineFeed'
import GridTypeA from 'components/grids/GridTypeA/GridTypeA'

// apis
import { useGetStudy } from 'api/hooks/studies/useStudiesApi'
import { useGetStudyActivities } from 'api/hooks/studies/useActivitiesApi'
import { useUserPermissions } from 'api/hooks'
import { useUserSettings } from 'api/hooks'
import {
  useGetStudyTeam,
  usePostStudyTeam,
} from 'api/hooks/studies/studyTeamsApi'
import { useGetRoles } from 'api/hooks/users/userRolesApi'
import { useGetStudyFeed } from 'api/hooks/studies/useTimelineFeedApi'

// utils
import { dateColDef } from 'components/grids/columnTemplates'

// styles
import './Overview.scss'

const Overview = () => {
  const { studyId } = useParams()
  const navigate = useNavigate()
  const location = useLocation();
  const { data: studyData } = useGetStudy(studyId)
  const { data: activities = [] } = useGetStudyActivities(studyId)
  const { data: team } = useGetStudyTeam(studyId)
  const { data: userRoles } = useGetRoles()
  const postStudyTeam = usePostStudyTeam(studyId)
  const [timelineFeedParams, setTimelineFeedParams] = useState({
    labels: [],
    showActivity: false
  })
  const { data: studyFeed, refetch: refetchFeed } = useGetStudyFeed(studyId, timelineFeedParams)

  const COL_DEFS = useMemo(() => {
    return [
      {
        headerName: "Activity",
        field: "project_title",
        cellRenderer: params => {
          return (
            <Link
              className="prime-link"
              to={`/study/${studyId}/activity/${params.data.id}`}>
              {params.value}
            </Link>
          )
        },
        flex: 1,
        minWidth: 200,
      }, {
        headerName: "Registry",
        field: "registry_name",
        cellRenderer: "RegistryCellRenderer",
        flex: 1,
        minWidth: 120
      }, {
        headerName: "State",
        field: "curr_state",
        flex: 1,
        minWidth: 145,
        cellRenderer: "WorkflowBadge",
        cellRendererParams: {
          list: activities
        },
      }, {
        headerName: "As Of Date",
        field: "last_action_time",
        ...dateColDef
      }, {
        headerName: "Assessment",
        field: "assessment_title",
        cellRenderer: "studyOverviewActivitiesAssessmentCellRenderer",
        flex: 1,
        minWidth: 200,
      }, {
        headerName: "Due Date",
        field: "project_due_date",
        cellRenderer: 'Date',
        ...dateColDef
      }, {
        headerName: "Status",
        field: "status",
        cellRenderer: "StatusCellRenderer",
        flex: 1,
        minWidth: 120,
      }
    ]

  }, [activities])

  useEffect(() => {
    refetchFeed()
  }, [timelineFeedParams])

  return (
    <div className="study-overview-tab">
      <div className="study-overview-row">
        <div className="study-overview-panel">
          <div className="study-overview-header">
            <p className="study-overview-title">Ongoing Activities</p>
            <div className="study-overview-subtitle-wrap">
              <p className="study-overview-subtitle">
                Active activities. To see all activities go to the&nbsp;
              </p>
              <a
                className="study-overview-subtitle-action prime-link"
                onClick={() => navigate(location.pathname.replace("overview", "activities"))}
              >
                Activities Tab
              </a>
            </div>
          </div>
          <GridTypeA
            columnDefs={COL_DEFS}
            domLayout="autoHeight"
            rowData={activities?.filter(activity => activity.progress_status === "ongoing")}
          />
        </div>
      </div>
      <div
        className="study-overview-row"
        style={{ marginTop: 34 }}
      >
        <div className="study-verview-col1">
          <TimelineFeed
            type="study"
            timeline={studyFeed?.timeline}
            refetchTimeline={refetchFeed}
            timelineFeedParams={timelineFeedParams}
            setTimelineFeedParams={setTimelineFeedParams}
            modalMsg={studyData.study_id}
          />
        </div>
        <div className="study-verview-col2">
          <Team
            type={"study"}
            team={team}
            roles={userRoles}
            postTeam={postStudyTeam}
          />
        </div>
      </div>
    </div>
  )
}

export default Overview