export const sponsorTrialDataSchema = {
  type: "object",
  properties: {
    sponsor_trial_website: {
      type: "object",
      properties: {
        entry_criteria: {
          type: "string",
        },
        participation_duration: {
          type: "string",
        }
      }
    }
  }
}