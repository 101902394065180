import {
  useQueryClient,
} from '@tanstack/react-query'
import axios from 'axios'
import PropTypes from 'prop-types'

// context
import useAppState from 'context/hooks/useAppState'

const usePrefetchApi = (apiEnpoint, queryKey) => {
  const queryClient = useQueryClient()
  const {
    appState,
    appStateRef
  } = useAppState()

  const getApi = async () => {
    const res = await axios.get(apiEnpoint)
    if (res?.data) return res.data;
  }

  // NOTE: not sure if prefetchQuery takes options like "enabled", therefore not sure query will run after app is redy
  const prefetchApi = () => {
    queryClient.prefetchQuery(queryKey, getApi, {
      enabled: appStateRef?.current === "authenticated" ? true : false,
    })
  }

  return prefetchApi
}

usePrefetchApi.propTypes = {
  apiEnpoint: PropTypes.string.isRequired,
  queryKey: PropTypes.string.isRequired,
}

export default usePrefetchApi