// TODO: move most endpoints from "enum" folder to "dropdown" folder to mirror api documentation and rename to getDropdownApis.js
import axios from 'axios'
import useGetApi from '../useGetApi'
import usePrefetchApi from '../usePrefetchApi'

// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

// utils
import getUrl from "api/apiMap"

const AxiosPublic = axios.create({});

const useGetRegions = () => {
  return useGetApi(getUrl(apiEndpoints.regions), queryKeys.regions)
}

const usePrefetchRegions = () => {
  return usePrefetchApi(getUrl(apiEndpoints.regions), queryKeys.regions)
}

const useGetRegistries = () => {
  return useGetApi(getUrl(apiEndpoints.registries), queryKeys.registries)
}

const useGetCountries = () => {
  return useGetApi(apiEndpoints.countries, queryKeys.countries, {}, {}, true)
}

const useGetStudiesList = () => {
  return useGetApi(getUrl(apiEndpoints.studiesList), queryKeys.studies.list())
}

const useGetRequestList = () => {
  return useGetApi(getUrl(apiEndpoints.requestList), queryKeys.requests.list())
}

// TODO: move to admin/envVars/envVarsApi.js
const useGetEnvVars = () => {
  return useGetApi(getUrl(apiEndpoints.envVars), queryKeys.envVars)
}

// TODO: move to admin/envVars/envVarsPublicApis.js
const getPublicEnvVar = () => {
  return AxiosPublic.get(getUrl(apiEndpoints.publicEnvVar))
}

// TODO: move to tags/tagsApis.js
const useGetTags = (params) => {
  return useGetApi(getUrl(apiEndpoints.tags), queryKeys.tags, {}, params)
}

export {
  useGetRegions,
  usePrefetchRegions,
  useGetRegistries,
  useGetCountries,
  useGetStudiesList,
  useGetRequestList,

  useGetEnvVars,
  getPublicEnvVar,
  useGetTags,
}