import { useState } from 'react';
import { useShallow } from 'zustand/react/shallow'

// components
import Label from 'components/Label/Label';
import InputNumber from 'components/InputNumber/InputNumber';
import PrimeField from 'components/PrimeField/PrimeField';
import RJTableCommentContainer from '../../../components/RJTableCommentContainer/RJTableCommentContainer';
import CellValidation from '../../../components/CellValidation/CellValidation';

// context
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';
import useResultsTableViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableViewStore';
import useAuthoringCommentsStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringCommentsStore';

const EventCell = ({
  event,
  value,
  valueIndex,
  eventListKey,
  adverseData,
  setAdverseData,
  schema,
  rowIndex
}) => {
  const {
    commentsMap
  } = useAuthoringCommentsStore(
    useShallow(state => ({
      commentsMap: state.commentsMap
    }))
  )

  const {
    readOnly,
    formView
  } = useAuthoringViewStore(
    useShallow(state => ({
      readOnly: state.readOnly,
      formView: state.formView
    }))
  )

  const {
    tableView
  } = useResultsTableViewStore()

  const [openCommentsForm, setOpenCommentsForm] = useState(false)
  const [showActions, setShowActions] = useState(false)

  const globalFields = {
    occurences_causally_related_to_treatment: {
      title: "Occurrences causally related to treatment number",
      type: "number"
    },
    deaths: {
      title: "Fatalities number",
      type: "number"
    },
    deaths_causally_related_to_treatment: {
      title: "Fatalities causally related to treatment number",
      type: "number"
    }
  }

  let fieldSchema = schema.properties[tableView][eventListKey.slice(0, -1) + "_stat"].properties

  const onChange = (e, key) => {
    let tempData = { ...adverseData };

    Object.keys(tempData).forEach(registry => {
      let foundEventIndex = tempData[registry][eventListKey].findIndex(item => item.id === event.id);
      if (foundEventIndex !== -1) {
        let registryEvent = tempData[registry][eventListKey][foundEventIndex];
        let foundValueIndex = registryEvent.values.findIndex(item => item.registry_arm_id === value.registry_arm_id);

        if (foundValueIndex !== -1) {
          let foundValue = registryEvent.values[foundValueIndex];
          if (foundValue.hasOwnProperty(key)) {
            if (key === "num_events" && tableView === "ctg") {
              if (registry === "ctg") {
                foundValue[key] = e;
              } else if (registry === "eudract") {
                foundValue[key] = e;
              }
            } else if (key === "num_events" && tableView === "eudract") {
              if (registry === "eudract") foundValue[key] = e;;
            } else {
              foundValue[key] = e;
            }
          }
        }
      }
    })

    setAdverseData(tempData)
  }

  const displayGlobalFields = () => {
    const isExistingEudract = () => {
      let foundItem = adverseData["eudract"]["serious_adverse_events"].find(item => item.id === event.id);
      if (foundItem) return true;
      return false;
    }

    const getValue = (key) => {
      let foundEventIndex = adverseData["eudract"][eventListKey].findIndex(item => item.id === event.id);

      if (foundEventIndex !== -1) {
        let registryEvent = adverseData["eudract"][eventListKey][foundEventIndex];
        let foundValueIndex = registryEvent.values.findIndex(item => item.registry_arm_id === value.registry_arm_id);

        if (foundValueIndex !== -1) {
          let foundValue = registryEvent.values[foundValueIndex];
          return foundValue[key]
        }
      }
    }

    return (
      <>
        {Object.keys(globalFields).map((key, i) => {
          let val = getValue(key)

          return (
            <PrimeField
              key={key + i + event.id}
              schema={{
                type: globalFields[key].type,
                disabled: !isExistingEudract(),
                label: globalFields[key].title,
                orientation: "vertical",
              }}
              readOnly={readOnly}
              containerStyle={{ marginBottom: 12 }}
              onChange={e => onChange(e, key)}
              value={val} />
          )
        })}
      </>
    )
  }

  const commentStyle = {
    position: "absolute",
    right: 8,
    top: 8
  }

  let jpath = `ReportedEvents.ae-cell-value-${event.id}-${value.group_id}`

  return (
    <td
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}
      style={{ position: "relative", minWidth: 10 }}>
      {Object.keys(fieldSchema).map((key, i) => {
        return (
          <div
            key={i + key + event.id + valueIndex}
            style={{ display: "flex", gap: 5, marginBottom: 5, flexDirection: "column" }}>
            <Label >{fieldSchema[key].title}</Label>
            <InputNumber
              onChange={e => onChange(e, key)}
              readOnly={readOnly}
              value={value[key]} />
            <CellValidation
              table="adverse"
              errorKey={`results_adverse_events_adverse_events_table_${tableView}_${eventListKey}_${rowIndex + 1}_values_${valueIndex + 1}_${key}`}
              style={{ marginBottom: 16 }} />
          </div>
        )
      })}
      {formView.id === "global" && eventListKey === "serious_adverse_events" && displayGlobalFields()}
      <RJTableCommentContainer
        label={"Adverse Event Cell"}
        jpath={jpath}
        openCommentsForm={openCommentsForm}
        setOpenCommentsForm={setOpenCommentsForm}
        setShowMenu={setShowActions}
        showMenu={showActions || commentsMap[jpath] || openCommentsForm}
        style={commentStyle}
        modalStyle={{ top: 24 }} />
    </td>
  );
};

export default EventCell;