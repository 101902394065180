import { useState } from 'react';
import { isArray } from 'lodash';
import { useShallow } from 'zustand/react/shallow'

// components
import { Button } from 'components-design-system';
import Modal from 'components/Modal/Modal';
import PrimeField from 'components/PrimeField/PrimeField';
import Label from 'components/Label/Label';

// context
import useAuthoringViewStore from '../../../hooks/stores/useAuthoringViewStore';

// styles
import './member-states-concerned.scss'

const Msc = (props) => {
  const [selectedRow, setSelectedRow] = useState(null);

  const {
    protocolData,
    tableData,
    setTableData,
  } = props;

  const {
    readOnly,
    showPagePreview
  } = useAuthoringViewStore(
    useShallow(state => ({
      readOnly: state.readOnly,
      showPagePreview: state.showPagePreview
    }))
  )

  const getTotalEEASubjects = () => {
    if (tableData?.length === 0 || !tableData) return 0;

    let total = 0;
    tableData.forEach(item => {
      total += Number(item.subjects);
    })

    return total;
  }

  const getTotalSubjects = () => {
    if (!tableData || tableData.length === 0) return 0;

    let total = 0;
    tableData.forEach(item => {
      total += Number(item.subjects);
    })

    if (protocolData?.study_scope?.enrollment_number) {
      total += Number(protocolData.study_scope.enrollment_number)
    }

    return total;
  }

  const getRestOfWorldSubjects = () => {
    let total = 0;
    if (protocolData?.study_scope?.enrollment_number) {
      total = protocolData.study_scope.enrollment_number
    }
    return total;
  }

  const addMemberStates = () => {
    let item = {
      country: "",
      rms: tableData.length === 0 ? true : false,
      first_submission_date: "",
      subjects: 0
    };

    setTableData([
      ...tableData,
      item
    ])
  }

  const handleChange = (e, key, i) => {
    let item = tableData[i];
    let tempData = [...tableData];
    if (key === "country") {
      let exists = tableData.some(item => item.country === e);
      if (!exists) item[key] = e;
    } else if (key === "rms") {
      if (e === true) {
        tempData.forEach((item, i) => {
          tempData[i].rms = false;
        })
        item[key] = e;
      }
    } else {
      item[key] = e;
    }
    tempData.splice(i, 1, item);
    setTableData(tempData);
  }

  const onDeleteRow = () => {
    let tempData = [...tableData];
    tempData.splice(selectedRow, 1);
    setTableData(tempData);
    setSelectedRow(null);
  }

  return (
    <div className="results-table member-states-concerned-container">
      <table>
        <thead>
          <tr>
            <td className='header-column'>
              Member states concerned
            </td>
            <td className='header-column' style={{ width: 150, maxWidth: 150 }}>
              RMS
            </td>
            <td className='header-column'>
              First submissions date
            </td>
            <td className='header-column'>
              Subjects
            </td>
          </tr>
        </thead>
        <tbody>
          {tableData?.map((row, i) => {
            return (
              <MemberStateRow
                key={i}
                i={i}
                row={row}
                handleChange={handleChange}
                schema={props?.schema}
                readOnly={(readOnly || showPagePreview)}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow} />
            )
          })}
        </tbody>
      </table>
      {(readOnly !== true && showPagePreview !== true) && <Button
        onClick={addMemberStates}
        variant={"primary-dashed"}
        style={{
          marginTop: 16
        }}>
        <i
          className="fal fa-plus-circle"
          style={{
            marginTop: 2,
            marginRight: 8
          }} />
        Add member states
      </Button>}
      <div className="member-states-concerned-details-container">
        <div className="member-states-concerned-total-container">
          <Label>
            Countries outside the European Economic Area
          </Label>
          <div className="member-states-concerned-totals-container">
            <div className="member-states-concerned-total-subjects-container">
              <Label>Rest of the world subjects*</Label>
              <p className="member-states-concerned-total-item-value">{getRestOfWorldSubjects()}</p>
            </div>
          </div>
        </div>
        <div className="member-states-concerned-total-container">
          <Label>
            Estimated total population for the trial
          </Label>
          <div className="member-states-concerned-totals-container">
            <div className="member-states-concerned-total-subjects-container">
              <Label>EEA subjects</Label>
              <p className="member-states-concerned-total-item-value">{getTotalEEASubjects()}</p>
            </div>
            <div className="member-states-concerned-total-subjects-container">
              <Label>Rest of the world subjects*</Label>
              <p className="member-states-concerned-total-item-value">{getRestOfWorldSubjects()}</p>
            </div>
            <div className="member-states-concerned-total-subjects-container">
              <Label>Total subjects</Label>
              <p className="member-states-concerned-total-item-value">{getTotalSubjects()}</p>
            </div>
          </div>
        </div>
      </div>
      <Modal
        type="delete"
        title="Delete Member State"
        message="You can't undo this action."
        open={selectedRow !== null}
        onCancel={() => setSelectedRow(null)}
        onOk={() => onDeleteRow()}
      />
    </div>
  );
};

export default Msc;

const MemberStateRow = (props) => {
  const [showDelete, setShowDelete] = useState(false);
  const {
    i,
    row,
    readOnly,
    selectedRow,
    setSelectedRow,
    handleChange
  } = props

  return (
    <tr
      onMouseEnter={() => setShowDelete(true)}
      onMouseLeave={() => setShowDelete(false)}>
      <td>
        <div className="country-member-state-container">
          <PrimeField
            schema={{
              type: "dropdown",
              id: "country-member-state-field-" + i,
              className: "full",
              placeholder: "Country",
              items: props?.schema?.enum,
              onChange: (val) => handleChange(val, "country", i),
            }}
            readOnly={readOnly}
            value={row.country}
          />
          {readOnly !== true && <i
            onClick={() => setSelectedRow(i)}
            className={`fal fa-trash-alt delete-member-state ${showDelete && "show-delete-state"}`} />}
        </div>
      </td>
      <td>
        {readOnly !== true ? (
          <PrimeField
            schema={{
              type: "checkbox",
              className: "full",
              items: props?.schema?.enum,
              onChange: (val) => handleChange(val, "rms", i),
            }}
            readOnly={readOnly}
            value={row.rms}
          />
        ) : (
          row.rms === true ? "Proposed" : ""
        )}
      </td>
      <td>{row.first_submission_date}</td>
      <td style={{ position: "relative" }}>
        {readOnly !== true ? (
          <>
            <PrimeField
              schema={{
                type: "text",
                variant: "number",
                className: "full",
                placeholder: "Subjects",
                onChange: (val) => handleChange(val, "subjects", i),
              }}
              readOnly={readOnly}
              value={row.subjects}
            />
          </>
        ) : (
          row.subjects
        )}
      </td>
    </tr>
  )
}