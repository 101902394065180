import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types'
import dayjs from 'dayjs';
import { v4 as uuid } from 'uuid'

// components
import Label from '../Label/Label';
import TextInput from '../TextInput/TextInput';
import InputNumber from '../InputNumber/InputNumber';
import DatePicker from '../DatePicker/DatePicker';
import Combobox from '../Combobox/Combobox';
import Checkbox from '../Checkbox/Checkbox';
import Switch from '../Switch/Switch';
import RadioGroup from '../RadioGroup/RadioGroup';
import DateRangePicker from '../form-fields/DateRangePicker/DateRangePicker';
import { Select } from 'components-design-system'
import ReadOnlyField from 'components/ReadOnlyField/ReadOnlyField';
import { Tooltip } from 'antd';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage'

// api
import { useUserSettings } from 'api/hooks';
import { useGetCountries, useGetRegistries } from 'api/hooks/enum'

// constants
import { countries as countriesConstant } from 'constants/countries'

// styles
import './prime-field.scss';

const PrimeField = ({
  variant = null,
  schema,
  value,
  checked, // for Switch
  defaultValue,
  onChange = null,
  disabled,
  readOnly,
  ellipsis,
  allowClear = false,
  tooltip,
  errorMessage = "This is a required field.",
  showError = false,
  className,
  containerStyle = {},
  ...moreProps // defaultChecked (Switch)
}) => {
  const [apiItems, setApiItems] = useState(null)
  const [showField, setShowField] = useState(false);
  const [showFieldError, setShowFieldError] = useState(false);
  const { data: userSettings } = useUserSettings()
  const selectWrapId = React.useMemo(() => `${uuid().substring(0, 8)}-select-wrap`, [])
  const { data: countries = countriesConstant } = useGetCountries()
  const { data: registries = [] } = useGetRegistries()

  const isDisabled = disabled || schema.disabled

  const onTextareaCustomBlur = (evt) => {
    if (evt.target.value) {
      setShowField(true)
    } else {
      setShowField(false)
    }
  }

  useEffect(() => {
    if (readOnly) setShowField(true)
  }, [readOnly])

  useEffect(() => {
    validateData()
  }, [value, schema])

  useEffect(() => {
    if (schema?.api_url) {
      let apiUrl = schema.api_url;

      if (apiUrl === "https://restcountries.com/v3.1/all") {
        let items = countries.map(country => ({ id: country?.name?.common, name: country?.name?.common })).sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        })
        setApiItems(items);
      } else if (apiUrl === "/admin/api/v1/registries-list/") {
        setApiItems(registries);
      } else {
        axios.get(apiUrl).then(res => {
          setApiItems(res.data);
        })
      }
    }
  }, [schema])


  const validateData = () => {
    if (schema.required || schema.validations || showError) {
      if ((schema.required && (value === null || value === undefined || value === "" || value?.length === 0)) || showError) {
        setShowFieldError(true);
      } else {
        setShowFieldError(false);
      }
    } else if (showFieldError === true) {
      setShowFieldError(false);
    }
  }

  const displayField = () => {
    let handleChange = onChange;
    let primeValue = value;

    if (schema?.api_url) {
      handleChange = (value) => {
        let ids = namesToIds(value);
        onChange?.(ids);
      }

      if (Array.isArray(primeValue)) {
        primeValue = idsToNames(primeValue);
      }
    }

    if (!handleChange) {
      handleChange = schema.onChange;
    }

    let items = schema?.items;
    let options = []

    if (apiItems) {
      items = apiItems.filter(item => item).map(item => item?.[schema?.api_str_key])
    }

    let status = null;
    if (showFieldError) status = 'error';
    else status = null;

    if (readOnly) {
      primeValue = value || defaultValue 
      if(typeof value === "number") primeValue = value
      if(typeof defaultValue === "number") primeValue = defaultValue

      if (schema.type === "date" && primeValue) {
        primeValue = dayjs(primeValue).format(userSettings.date_format)
      }
      if (Array.isArray(primeValue)) {
        primeValue = primeValue.map(item => {
          if (typeof item === "string") return item
          if (item.label) return item.label
          return "Incorrect Key"
        }).join(", ")
      }

      return <ReadOnlyField
        placeholder={schema.placeholder}
        ellipsis={ellipsis}
      >
        {primeValue}
      </ReadOnlyField>
    }

    switch (schema.type) {
      case "text":
        return (
          <TextInput
            status={status || schema?.variant}
            id={schema.id}
            className={schema.className}
            placeholder={schema.placeholder}
            value={primeValue}
            onChange={e => handleChange(e.target.value)}
            readOnly={readOnly}
            style={schema.style}
            prefix={schema.prefix}
            size={schema.size}
            disabled={isDisabled}
            defaultValue={defaultValue}
            allowClear={allowClear}
            {...moreProps}
          />
        )
      case "number":
        return (
          <InputNumber
            status={status || schema?.variant}
            id={schema.id}
            className={schema.className}
            placeholder={schema.placeholder}
            value={primeValue}
            onChange={val => handleChange(val)}
            readOnly={readOnly}
            style={schema.style}
            prefix={schema.prefix}
            size={schema.size}
            defaultValue={defaultValue}
            allowClear={allowClear}
            disabled={isDisabled}
            {...moreProps}
          />
        )
      case "dropdown":
        options = items
        if (Array.isArray(items) && items.every((item) => typeof item === "string")) {
          options = items?.map(item => ({ value: item, label: item }))
        }
        if (!items) options = [];


        return <div
          id={selectWrapId}
        // style={{ position: "relative" }}
        >
          <Select
            id={schema.id}
            options={options}
            mode={schema.mode}
            placeholder={schema.placeholder}
            value={primeValue}
            defaultValue={defaultValue}
            disabled={isDisabled}
            allowClear={allowClear || schema.allowClear}
            onChange={handleChange}
            popupContainerId={selectWrapId}
            {...moreProps}
          />
        </div>
      case "multiselect":
        options = items
        if (Array.isArray(items) && items.every((item) => typeof item === "string")) {
          options = items?.map(item => ({ value: item, label: item }))
        }

        if (!items) items = [];

        if (primeValue === "" || !primeValue || primeValue.length === 0) primeValue = undefined

        return <div
          id={selectWrapId}
          style={{ position: "relative" }}
        >
          <Select
            mode={schema.mode ? schema.mode : "multiple"}
            id={schema.id}
            options={options}
            placeholder={schema.placeholder}
            value={primeValue}
            className={schema.className}
            disabled={isDisabled}
            allowClear={allowClear}
            onChange={handleChange}
            size={"medium"}
            defaultValue={defaultValue}
            popupContainerId={selectWrapId}
            {...moreProps}
          />
        </div>
      case "radio":
        return (
          <RadioGroup
            id={schema.id}
            key={schema.id}
            items={items}
            onChange={handleChange}
            value={primeValue}
            label={schema.placeholder}
            className={schema.className}
            disabled={isDisabled}
          />
        )
      case "combobox":
        if (primeValue && primeValue?.length > 0) {
          return (
            <Combobox
              id={schema.id}
              key={schema.id}
              items={items}
              defaultSelectedItem={primeValue}
              onSelect={handleChange}
              placeholder={schema.placeholder}
              className={schema.className}
              onBlur={(evt) => { }}
            />
          )
        } else {
          return (
            <Combobox
              id={schema.id}
              items={items}
              onSelect={handleChange}
              placeholder={schema.placeholder}
              className={schema.className}
              onBlur={(evt) => { }}
              readOnly={readOnly}
            />
          )
        }
      case "date":
        return (
          <DatePicker
            id={schema.id}
            placeholder={schema.placeholder}
            className={schema.className}
            value={primeValue}
            format={userSettings?.date_format}
            onChange={handleChange}
            disabled={isDisabled}
            readOnly={readOnly}
            allowClear={allowClear}
          />
        )
      case "daterange":
        return (
          <DateRangePicker
            id={schema.id}
            className={schema.className}
            placeholder={schema.placeholder}
            onChange={handleChange}
            value={primeValue}
            disabled={isDisabled} />
        )
      case "checkbox":
        return (
          <Checkbox
            {...schema}
            id={schema.id}
            className={schema.className}
            label={schema.label}
            disabled={isDisabled}
            onChange={e => {
              if (handleChange) {
                handleChange(e.target.checked)
              }
            }}
            checked={primeValue}
          />
        )
      case "textarea":
        return (
          <TextInput
            id={schema.id}
            status={status || schema?.variant}
            className={schema.className}
            placeholder={schema.placeholder}
            type="textarea"
            autoSize={true}
            // rows={schema.rows ? schema.rows : 2}
            value={primeValue}
            onChange={e => handleChange(e.target.value)}
            onBlur={onTextareaCustomBlur}
            readOnly={readOnly}
            defaultValue={defaultValue}
            disabled={isDisabled}
          />
        )
      case "textarea-custom":
        return showField ?
          <TextInput
            id={schema.id}
            className={schema.className}
            placeholder={schema.placeholder}
            type="textarea"
            rows={2}
            value={primeValue}
            onChange={e => handleChange(e.target.value)}
            onBlur={onTextareaCustomBlur}
            readOnly={readOnly}
            disabled={isDisabled}
          /> :
          <div
            id={schema.id}
            className={"edit-prime-field textarea-custom-closed"}
            onClick={() => setShowField(true)}
          >{schema.placeholder} <i className="far fa-pen" /></div>
      case "switch":
        // checked prop takes precedence over value prop
        if (checked !== undefined) primeValue = checked
        return <Switch
          label={schema.label}
          returnLabels={false}
          checked={primeValue}
          onChange={e => handleChange(e)}
          className={schema.className}
          readOnly={readOnly}
          style={schema.style}
          size={schema.size}
          disabled={isDisabled}
          {...moreProps}
        />
      case "single-number":
        return SingleNumber()
      case "password":
        return (
          <TextInput
            id={schema.id}
            type="password"
            className={schema.className}
            value={primeValue}
            placeholder={schema.placeholder}
            onChange={e => handleChange(e.target.value)}
            style={schema.style}
            prefix={schema.prefix}
            {...moreProps}
          />
        )
      default:
        return;
    }
  }

  const SingleNumber = () => {
    let handleChange = onChange;
    if (!handleChange) handleChange = schema.onChange

    const getValueVariant = () => {
      if (schema.variant === "count") {
        return value
      } else {
        return (
          <div className="value-variant">
            {value} of {schema.max}
          </div>
        )
      }
    }

    let disableAdd = false;
    let disableSubtract = false;

    if (typeof schema.min === "number" && value === schema.min) disableSubtract = true;
    if (typeof schema.max === "number" && value === schema.max) disableAdd = true;
    if (isDisabled === true) {
      disableSubtract = true;
      disableAdd = true
    }
    const onAdd = () => {
      let canUpdate = true;
      let newVal = value + 1;
      if (typeof schema.max === 'number' && schema.max <= value) canUpdate = false;
      if (canUpdate && disableAdd !== true) handleChange(newVal)
    }

    const onSubtract = () => {
      let canUpdate = true;
      let newVal = value - 1;
      if (typeof schema.min === 'number' && schema.min >= value) canUpdate = false;
      if (canUpdate && disableSubtract !== true) handleChange(newVal)
    }

    let classNames = ["single-number"];
    if (schema.className) classNames.push(schema.className)

    return (
      <div className={classNames.join(" ")}>
        <p className="number-title">{schema.placeholder}</p>
        <div className={`minus-button ${disableSubtract && "disable"}`} onClick={onSubtract}>
          <p className="minus">-</p>
        </div>
        <div className="value">
          {getValueVariant()}
        </div>
        <div className={`add-button ${disableAdd && "disable"}`} onClick={onAdd}>
          <p>+</p>
        </div>
      </div>
    )
  }

  const namesToIds = (value = []) => {
    let ret = []
    let val = Array.isArray(value) ? value : [value]

    val?.forEach(name => {
      let found = apiItems?.find(item => item[schema?.api_str_key] === name);
      if (found) ret.push(found.id)
    })

    return ret;
  }

  const idsToNames = (value = []) => {
    let ret = []
    let val = Array.isArray(value) ? value : [value]

    val.forEach(key => {
      let found = apiItems?.find(item => item.id === key);
      if (found) ret.push(found[schema?.api_str_key])
    })

    return ret;
  }

  let classNames = ["prime-field-container"];
  if (className) classNames.push(className)
  if (schema?.containerClassName) classNames.push(schema.containerClassName);

  if (schema.orientation === "vertical") {
    containerStyle = {
      ...containerStyle,
      display: "flex",
      flexDirection: "column",
      gap: 4
    }
  }

  const getContent = () => {
    return <div className={classNames.join(" ")} style={containerStyle}>
      {(schema.label && schema.type !== "checkbox" && schema.type !== "switch") && <Label style={{ marginBottom: 5 }}>{schema.label}</Label>}
      {displayField()}
      {schema.sublabel && <Label>{schema.sublabel}</Label>}
      {showFieldError &&
        <ErrorMessage text={errorMessage} />
      }
    </div>
  }

  if (tooltip) {
    return (
      <Tooltip
        placement="top"
        title={tooltip}>
        {getContent()}
      </Tooltip>
    )
  }

  return getContent()
}

PrimeField.propTypes = {
  schema: PropTypes.shape({
    type: PropTypes.oneOf(['text', 'number', 'dropdown', 'multiselect', 'radio', 'combobox', 'date', 'daterange', 'checkbox', 'textarea', 'textarea-custom', 'switch', 'single-number', 'password']),
    placeholder: PropTypes.string,
    items: PropTypes.array,
  }),
  onChange: PropTypes.func,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  showError: PropTypes.bool,
}

export default React.memo(PrimeField)