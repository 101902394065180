import * as React from 'react'
import PropTypes from 'prop-types'
import Modal from 'components/Modal/Modal'
import PdfFullWindow from 'components/pdfs/PdfFullWindow/PdfFullWindow'
import './PdfFullWindowModal.scss'

import usePdfAnnotations from 'utilities/usePdfAnnotations'

const PdfFullWindowModal = ({
  open,
  setOpen,
  file,
  setFile,
}) => {

  const {
    getServerUpdates,
    onAnnotationSelected,
    onAnnotationEvent,
    annotNotifHolder,
    AnnotModalError,
    setModalError,
  } = usePdfAnnotations(file?.id)

  if (!file) return null
  
  return <Modal
    open={open}
    onCancel={() => {
      setModalError(null)
      if (setFile) setFile(null);
      setOpen(false)
    }}
    footer={[]}
    hideImage={true}
    width="90vw"
    wrapClassName="pdf-full-win-modal"
  >
    <>
      {annotNotifHolder}
      <AnnotModalError />
      <PdfFullWindow
        divId={`pdf-${file?.id}`}
        selectedDocument={file}
        getServerUpdates={getServerUpdates}
        onAnnotationEvent={onAnnotationEvent}
        onAnnotationSelected={onAnnotationSelected}
      />
    </>
  </Modal>
}

PdfFullWindowModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  file: PropTypes.shape({
    id: PropTypes.number,
    file_id: PropTypes.string.isRequired,
  }),
  setFile: PropTypes.func,
}

export default PdfFullWindowModal