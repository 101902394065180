// utils
import { getWidget } from '../../shared/utils';
import { arrayClassNames, objectClassNames } from '../../classes';

const moreInfo = (registryId, preview) => {
  let returnObj = {
    "ui:options": {
      title: "More Information",
      classNames: objectClassNames.primary
    },
    certain_agreements: {
      "ui:options": {
        title: "Certain Agreements",
        label: false,
        classNames: {
          fieldContainer: "rjs-full",
          objectTitle: "rjs-h2"
        }
      },
      is_pis_employees: {
        "ui:options": {
          title: "Are all PIs Employees of Sponsor?",
          placeholder: "Please Select--",
          tooltip: "If all principal investigators are employees of the sponsor, select Yes.",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
      },
      results_disclosure: {
        "ui:options": {
          title: "[*] Results Disclosure Restriction on PI(s)?",
          placeholder: "Please Select--",
          tooltip: "If there are agreements with multiple non-employee PIs and there is a disclosure restriction on at least one PI, select Yes.",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
      },
      pi_disclosure: {
        "ui:options": {
          title: "PI Disclosure Restriction Type:",
          tooltip: "Indicate which type of restriction applies. If there are varying agreements with multiple PIs, choose the type below that represents the most restrictive of the agreements (e.g., the agreement with the greatest embargo time period).",
          label: false,
          orientation: "vertical",
          classNames: {
            fieldContainer: "rjs-full"
          }
        },
        "ui:widget": getWidget({ type: 'radio', preview }),
      },
      describe_agreement: {
        "ui:options": {
          title: "If the restriction type is Other disclosure agreement, please describe the agreement.",
          label: false,
          classNames: {
            fieldContainer: "rjs-full"
          }
        },
        "ui:widget": getWidget({ type: "textarea", preview }),
      },
    },
    results_poc: {
      "ui:options": {
        title: "Result Point of Contact",
        label: false,
        classNames: {
          fieldContainer: "rjs-full",
          objectTitle: "rjs-h2"
        }
      },
      name: {
        "ui:options": {
          title: "Name or Official Title:",
          tooltip: "Enter the specific person's name (e.g., Dr. Jane Smith) or a position title (e.g., Director of Clinical Trials).",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
      },
      organization_name: {
        "ui:options": {
          title: "Organization Name:",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
      },
      phone: {
        "ui:options": {
          title: "Phone:",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
      },
      ext: {
        "ui:options": {
          title: "Ext",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
      },
      email: {
        "ui:options": {
          title: "Email:",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
      },
    },
    global_protocol_amendments: {
      "ui:options": {
        title: "Substantial Protocol Amendments (Globally)",
        label: false,
        classNames: {
          fieldContainer: "rjs-full",
          objectTitle: "rjs-h2"
        }
      },
      amendments: {
        "ui:options": {
          title: "Were there any global substantial amendments to the protocol?",
          tooltip: "Amendments are considered substantial when they are likely to have an impact on the safety of the trial subjects, or to change the interpretation of the scientific documents in support of the conduct of the trial. Select 'Yes' if any substantial amendments to the protocol globally affected the trial, otherwise select 'No'.",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
        "ui:widget": getWidget({ type: 'radio', preview })
      },
      add_amendment: {
        "ui:options": {
          title: "Global Amendment Details",
          addButtonTitle: "Add global substantial protocol amendment",
          label: false,
          addable: true,
          removable: true,
          orderable: true,
          classNames: {
            arrayTitle: "rjs-h3",
            fieldContainer: "rjs-full",
            arrayContainer: "rjs-full rjs-bg-white p-24 rjs-border-radius",
            arrayItemsContainer: "flex flex-col gap-24",
            actionContainer: "flex justify-end"
          }
        },
        items: {
          amendment_date: {
            "ui:options": {
              title: "Amendment Date:",
              placeholder: "Enter date",
              tooltip: "Select the date when the substantial amendment was first approved by a regulatory authority.",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
            "ui:widget": getWidget({ type: "date", preview })
          },
          amendment_description: {
            "ui:options": {
              title: "Amendment Description:",
              placeholder: "Enter description",
              tooltip: "Enter a description and a reason for the main changes to the protocol.",
              label: false,
              classNames: {
                fieldContainer: "rjs-full"
              }
            },

            "ui:widget": getWidget({ type: "textarea", preview })
          },
        }
      },
    },
    global_protocol_interruptions: {
      "ui:options": {
        title: "Interruptions (Globally)",
        label: false,
        classNames: {
          fieldContainer: "rjs-full",
          objectTitle: "rjs-h2"
        }
      },
      interruptions: {
        "ui:options": {
          title: "Were there any global interruptions to the protocol?",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
        "ui:widget": getWidget({ type: 'radio', preview })
      },
      add_interruption: {
        "ui:options": {
          title: "Global Interruptions Details",
          tooltip: "Select 'Yes' if there were any interruptions that globally affected the trial, otherwise select 'No'.",
          addButtonTitle: "Add global interruption",
          label: false,
          addable: true,
          removable: true,
          orderable: true,
          classNames: {
            arrayTitle: "rjs-h3",
            fieldContainer: "rjs-full",
            arrayContainer: "rjs-full rjs-bg-white p-24 rjs-border-radius",
            arrayItemsContainer: "flex flex-col gap-24",
            actionContainer: "flex justify-end"
          }
        },
        items: {
          "ui:order": ["interruption_date", "restart", "interruption_description", "*"],
          interruption_date: {
            "ui:options": {
              title: "Interruption Date:",
              tooltip: "Select the date when the interruption took effect.",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
            "ui:widget": getWidget({ type: "date", preview })
          },
          interruption_description: {
            "ui:options": {
              title: "Interruption Description:",
              placeholder: "Enter description",
              tooltip: "Enter a description and reason for the interruption.",
              label: false,
              classNames: {
                fieldContainer: "rjs-full"
              }
            },
            "ui:widget": getWidget({ type: "textarea", preview })
          },
          restart: {
            "ui:options": {
              title: "Restart Date:",
              tooltip: "If applicable",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
            "ui:widget": getWidget({ type: "date", preview })
          },
        },
      },
    },
    limitations_caveats: {
      "ui:options": {
        title: "Limitations and Caveats",
        tooltip: "Describe any significant limitations of the trial (e.g. early termination leading to a small number of subjects analysed; technical problems with measurement leading to unreliable, or uninterpretable data).",
        label: false,
        classNames: {
          fieldContainer: "rjs-full"
        }
      },
      "ui:widget": getWidget({ type: "textarea", preview }),
    },
    online_references: {
      "ui:options": {
        title: "Online References",
        label: false,
        classNames: {
          fieldContainer: "rjs-full",
          objectTitle: "rjs-h2"
        }
      },
      pub_med_identifier: {
        "ui:options": {
          title: "Enter PubMed Identifier (PMID)",
          tooltip: "Enter the unique number (PubMed identifier) of a PubMed record related to this trial and click on add this link.",
          addButtonTitle: "Add identifier",
          label: false,
          addable: true,
          removable: true,
          orderable: false,
          label: false,
          classNames: {
            arrayTitle: "rjs-h3",
            fieldContainer: "rjs-full",
            arrayContainer: "rjs-full rjs-bg-white p-24 rjs-border-radius",
            arrayItemsContainer: "flex flex-col gap-24",
            actionContainer: "flex justify-end"
          }
        },
        items: {
          link: {
            "ui:options": {
              title: "Identifier:",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
          },
        },
      },
    },
  };

  if (registryId === 'ctg') {
    const uiOptionsHide = {
      "ui:options": { hide: true }
    };
    returnObj.global_protocol_amendments = uiOptionsHide;
    returnObj.global_protocol_interruptions = uiOptionsHide;
    returnObj.limitations_caveats["ui:options"].title = "Overall Limitations and Caveats:";
    returnObj.online_references = uiOptionsHide;

    // required fields
    if (returnObj?.global_protocol_amendments?.amendments?.["ui:options"]) {
      returnObj.global_protocol_amendments.amendments["ui:options"].required = true;
    }
    if (returnObj?.global_protocol_interruptions?.interruptions?.["ui:options"]) {
      returnObj.global_protocol_interruptions.interruptions["ui:options"].required = true;
    }
    if (returnObj?.certain_agreements?.is_pis_employees?.["ui:options"]) {
      returnObj.certain_agreements.is_pis_employees["ui:options"].required = true;
    }
    if (returnObj?.results_poc?.name?.["ui:options"]) {
      returnObj.results_poc.name["ui:options"].required = true;
      returnObj.results_poc.organization_name["ui:options"].required = true;
      returnObj.results_poc.phone["ui:options"].required = true;
      returnObj.results_poc.email["ui:options"].required = true;
    }
  };

  if (registryId === 'eudract') {
    const uiOptionsHide = {
      "ui:options": { hide: true }
    };
    returnObj.certain_agreements = uiOptionsHide;
    returnObj.results_poc = uiOptionsHide;

    // required fields
    if (returnObj?.global_protocol_amendments?.amendments?.["ui:options"]) {
      returnObj.global_protocol_amendments.amendments["ui:options"].required = true;
    }
    if (returnObj?.global_protocol_interruptions?.interruptions?.["ui:options"]) {
      returnObj.global_protocol_interruptions.interruptions["ui:options"].required = true;
    }
    if (returnObj?.certain_agreements?.is_pis_employees?.["ui:options"]) {
      returnObj.certain_agreements.is_pis_employees["ui:options"].required = true;
    }
    if (returnObj?.results_poc?.name?.["ui:options"]) {
      returnObj.results_poc.name["ui:options"].required = true;
      returnObj.results_poc.organization_name["ui:options"].required = true;
      returnObj.results_poc.phone["ui:options"].required = true;
      returnObj.results_poc.email["ui:options"].required = true;
    }
  };

  return returnObj;
};

export default moreInfo;