import React from 'react'
import Modal from '../../Modal/Modal'
import { Button } from 'components-design-system'
import './RefreshTeamConfirmationModal.scss'

const RefreshTeamConfirmationModal = ({
  subheader,
  showModal,
  onHide,
  handleSaveChanges
}) => {
  return <Modal
    title="Confirm Team Refresh"
    message={subheader}
    open={showModal}
    onCancel={onHide}
    footer={[]}
    hideImage={true}
    className="refresh-team-confirm-modal"
  >
    <div className="refresh-team-confirm-modal-content">
      <div className="refresh-team-confirm-modal-desc">
        <p
          className="refresh-team-confirm-modal-text"
          style={{ marginBottom: 16 }}
        >
          Refreshing the team will copy members from the overall study team to this activity.  Team members will be deleted from this activity if they are not part of  overall study team.
        </p>
        <p className="refresh-team-confirm-modal-text">
          When a team member is deleted, they will remain the assignee of any previously assigned activity states but will not be able to be selected as an assignee when making future changes.
        </p>
      </div>
      <div className="refresh-team-confirm-modal-actions">
        <Button
          onClick={onHide}
          variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleSaveChanges}
          variant="primary">
          Confirm
        </Button>
      </div>
    </div>
  </Modal>
}

export default RefreshTeamConfirmationModal