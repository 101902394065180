import React from 'react'
import { useParams } from 'react-router-dom'
import { Tooltip } from 'antd'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import MenuPopover from 'components/MenuPopover/MenuPopover'
import './ActivityCellRenderer.scss'

const ActivityCellRenderer = ({
  data,
  activity, // attached project
  activityTypes,
  permissions,
  toggleModal,
}) => {
  const { studyId } = useParams()
  // TODO: move to its own component file?
  const EllipsisMenu = ({
    toggleModalProps,
  }) => {
    return <MenuPopover
      title="ACTIVITY"
      items={[
        {
          icon: 'plus',
          text: 'Create',
          action: () => toggleModal(toggleModalProps),
        },
      ]}
      overlayClassName="menu-popover"
      width={140}
    />
  }

  const renderActivityLink = () => {
    if (activity) {
      return <Link className="activity-link" to={`/study/${studyId}/activity/${activity.id}`}>{activity.title}</Link>
    } else if (data?.project_title) {
      return <Link className="activity-link" to={`/study/${studyId}/activity/${data.id}`} target="_blank" rel="noopener noreferrer">{data?.project_title}</Link>
    }
  }

  const renderRecommTypeFlag = () => {
    // get all recommended field IDs (project_type_ids) that are not in attached_projects
    const getFilteredTypeIds = () => {
      let fields = []
      if (data?.project_type_ids?.length > 0) {
        // get IDs for attached_projects from activityTypes
        const attachedIds = data.attached_projects
          .map((attachObj) => activityTypes.find((typeObj) => typeObj.title === attachObj.title).id)

        // if the operator is "or", and one of the recommended activities has been attached, the requirements have been satisfied, therefore do not return any IDs
        if (data.operator === '|' && data?.attached_projects?.length && attachedIds.some((attachId) => data.project_type_ids.some((recId) => attachId === recId))) {
          fields = []
        }
        // do not include activities already attached
        else {
          fields = data.project_type_ids.filter((typeId) => !attachedIds.some((attachId) => attachId === typeId))
        }
      }
      return fields
    }

    if (activityTypes && getFilteredTypeIds()?.length) {
      const tooltip = <div className="activities-flag-tooltip-content">
        Recommended activities:<br />
        {getFilteredTypeIds().map((id) => activityTypes?.find((typeObj) => typeObj.id === id)?.title).join(data.operator === '|' ? ' or ' : ' and ')}
      </div>
      return <Tooltip
        title={tooltip}
        overlayClassName="activities-flag-tooltip"
      >
        <i className="fas fa-flag" />
      </Tooltip>
    }
    return null
  }

  return (
    <div className="activity-cell-renderer">
      <div className="activity-container">
        <div className="activities-flag-wrap">
          {renderActivityLink()}
          {data.result !== "Not Required" && data.result !== "Not Yet Assessed" && (!data.missing_fields || data?.missing_fields.length === 0) && renderRecommTypeFlag()}
        </div>
        {permissions && permissions["project.create"] === true &&
          <EllipsisMenu
            toggleModalProps={data}
          />
        }
      </div>
    </div>
  )
}

ActivityCellRenderer.propTypes = {
  data: PropTypes.shape({
    attached_projects: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
      })
    )
  }),
  activity: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
  }),
  activityTypes: PropTypes.array,
  permissions: PropTypes.object,
  toggleModal: PropTypes.func,
}

export default ActivityCellRenderer