import { useState, useEffect, useRef, useMemo } from 'react';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isBetween from 'dayjs/plugin/isBetween'

// components
import State from './subcomponents/State/State';
import { Tooltip } from 'antd'

// api
import { useUserSettings } from 'api/hooks';

// styles
import './pizza-tracker.scss';

dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(isBetween)

const MILESTONE_KEYS = [
  "activity_due_date",
  "actual_completion_date",
  "compliance_due_date",
  "fcsr",
  "fpi",
  "fsa",
  "lpo",
  "pcd",
  "pra",
  "projected_completion_date",
  "request_due_date"
]

const PizzaTracker = ({
  model,
  workflowTrackerData = [],
  viewSettings,
  putWorkflowTracker,
  readOnly
}) => {
  const {
    showAssignees,
    showDueDates,
    showDays,
  } = viewSettings
  const { data: userSettings } = useUserSettings()
  const [tempTrackerData, setTempTrackerData] = useState([]);
  const [trackerOverflowWidth, setTrackerOverflowWidth] = useState(400);
  const [marginTop, setMarginTop] = useState(70);

  const pizzaTrackerRef = useRef()
  const wrapRef1 = useRef(null);
  const wrapRef2 = useRef(null);
  const div1 = useRef(null);

  const milestones = useMemo(() => {
    if (workflowTrackerData?.parent_data) {
      let ret = []
      MILESTONE_KEYS.forEach(key => {
        if (workflowTrackerData.parent_data[key]) {
          ret.push({
            type: key,
            value: workflowTrackerData.parent_data[key]
          })
        }
      })

      return ret
    }
    return []
  }, [workflowTrackerData?.parent_data])

  useEffect(() => {

    if(!showDueDates || readOnly) {
      setMarginTop(70)
    } else {
      setMarginTop(190)
    }
  }, [showDueDates])

  useEffect(() => {
    let trackerData = getWidths(workflowTrackerData?.state_data?.tracker_data.slice(1))

    if (trackerData?.length > 0) {
      setTempTrackerData(trackerData)
    }
  }, [workflowTrackerData])

  useEffect(() => {
    if (trackerOverflowWidth) {
      let scroll = document.getElementById("pizza-tracker-remote-scroll");

      if (scroll) {
        scroll.style.width = `${trackerOverflowWidth}px`
      }
    }
  }, [trackerOverflowWidth, div1])

  useEffect(() => {
    if (tempTrackerData) {
      let lastState = tempTrackerData[tempTrackerData.length - 1];
      if (lastState?.milestones?.length > 1) {
        setMarginTop(marginTop + ((milestones.length - 1) * 50))
      }
    }
  }, [tempTrackerData])

  const getWidths = (trackerData) => {
    if (!trackerData) return []
    let totalWidth = pizzaTrackerRef.current?.clientWidth;
    let updatedTrackerData = [...trackerData];

    if (totalWidth) {

      let firstState = updatedTrackerData[0]
      let lastState = updatedTrackerData[updatedTrackerData.length - 1]

      let totalNumOfDays = dayjs(lastState.start_state_date).diff(firstState.start_state_date, "day");

      for (let i = 0; i < updatedTrackerData.length; i++) {
        let currState = updatedTrackerData[i];
        let duration = currState.goal_days === 0 ? 1 : currState.goal_days
        currState.widthPercent = (duration / totalNumOfDays) * 100;
      }
    }

    return updatedTrackerData
  }

  const formatPutRequest = (stateData) => {
    let assessmentPk = null

    let projectedCompletionDate = stateData[stateData.length - 1].end_state_date
    const lastState = stateData[stateData.length - 1]
    if (!lastState.end_state_date) {
      projectedCompletionDate = dayjs(lastState.start_state_date).add(lastState.goal_days, "day").format("YYYY-MM-DD")
    }

    let actionData = []

    stateData.forEach((state, i) => {
      let currState = { ...state }
      let startDate = state?.start_state_date;

      if (i !== 0 && i !== stateData.length - 1 && stateData?.[i - 1].end_state_date) {
        if (!dayjs(startDate).isSame(stateData?.[i - 1].end_state_date, 'day')) {
          startDate = stateData?.[i - 1].end_state_date
        }
      }

      let endDate = stateData?.[i + 1]?.start_state_date
      let goalDays = dayjs(endDate).diff(dayjs(startDate), "day")

      if (dayjs(state.start_state_date).diff(state.end_state_date, "day") < 0) {
        currState.end_state_date = state.start_state_date
      }

      if (goalDays < 0) {
        goalDays = 0;
        endDate = startDate
        let prevState = actionData?.[i - 1]

        if (prevState) {
          prevState.end_state_date = startDate;
          prevState.goal_days = dayjs(startDate,).diff(dayjs(prevState.start_state_date), "day")
        }
      }

      const ret = {
        ...currState,
        is_first_state: i === 0,
        is_last_state: i === stateData.length - 1,
        assign_to_ids: currState.assigned_to_ids || [],
        start_state_date: dayjs(startDate).format("YYYY-MM-DD"),
        end_state_date: dayjs(endDate).format("YYYY-MM-DD"),
        goal_days: goalDays || 0
      }
      actionData.push(ret)
    })

    let req = {
      model,
      projected_completion_date: projectedCompletionDate,
      action_data: actionData
    }

    if (assessmentPk) req.assessment_pk = assessmentPk
    if (workflowTrackerData?.parent_data?.actual_completion_date) req.actual_completion_date = workflowTrackerData.parent_data.actual_completion_date
    if (workflowTrackerData.parent_data.activity_due_date) req.activity_due_date = workflowTrackerData.parent_data.activity_due_date

    return req
  }

  const onSaveStateDates = (ghostState) => {
    let tempData = [...tempTrackerData]
    let foundIndex = tempData.findIndex(item => item.state_id === ghostState.state_id);
    let currState = tempData[foundIndex]

    const getDifferenceBetweenDays = (a, b) => {
      if (!a || !b) return -1
      return dayjs(a).diff(dayjs(b), "day");
    }

    const addDays = (date, days) => {
      return dayjs(date).add(days, "day").format("YYYY-MM-DD")
    }

    let ghostStateStartDate = dayjs(ghostState.start_state_date)
    let currStateStartDate = dayjs(currState.start_state_date)

    if (ghostStateStartDate.isAfter(currStateStartDate, 'day')) { // date moved forward
      let prevState = tempData[foundIndex - 1]

      if (prevState) {
        prevState.end_state_date = ghostState.start_state_date
        prevState.goal_days = Math.abs(getDifferenceBetweenDays(prevState.end_state_date, prevState.start_state_date))
      }

      currState.start_state_date = ghostState.start_state_date;
      let goal_days = getDifferenceBetweenDays(currState.end_state_date, currState.start_state_date)

      if (goal_days < 0) {
        goal_days = 0;
        currState.end_state_date = currState.start_state_date
        currState.goal_days = 0
      } else {
        currState.goal_days = goal_days
      }

      for (let i = foundIndex + 1; i < tempData.length; i++) {
        let nextState = tempData[i];
        nextState.start_state_date = tempData[i - 1].end_state_date;

        let checkDays = getDifferenceBetweenDays(nextState.end_state_date, nextState.start_state_date)

        if (checkDays < 0) {
          nextState.goal_days = 0
          nextState.end_state_date = nextState.start_state_date
        } else {
          nextState.goalDays = checkDays
        }

      }
    } else { // date moved back

      currState.start_state_date = ghostState.start_state_date;
      currState.goal_days = getDifferenceBetweenDays(currState.start_state_date, currState.end_state_date)

      if (currState.goal_days < 0) {
        currState.goal_days = 0
      }
      currState.end_state_date = addDays(currState.start_state_date, currState.goal_days)


      if (!currState.end_state_date) {
        currState.end_state_date = addDays(currState.start_state_date, currState.goal_days)
      }


      for (let i = foundIndex - 1; i >= 0; i--) {
        let prevState = tempData[i]
        let currState = tempData[i + 1]
        let goal_days = getDifferenceBetweenDays(currState.start_state_date, prevState.start_state_date)

        if (goal_days > 0) {
          prevState.end_state_date = currState.start_state_date;
          prevState.goal_days = goal_days
        } else {
          prevState.goal_days = 0
          prevState.start_state_date = currState.start_state_date
          prevState.end_state_date = currState.start_state_date
        }
      }
    }

    let request = formatPutRequest(tempData)

    putWorkflowTracker({
      body: request
    })
  }
  const onRevertStateDates = () => {
    setTempTrackerData(workflowTrackerData?.state_data?.tracker_data.slice(1))
  }

  const handleScroll = (event) => {
    const targetDiv = event.target;
    var scrolling = false;

    if (targetDiv === wrapRef1.current && wrapRef2.current) {
      if (scrolling) {
        scrolling = false;
        return true;
      }
      scrolling = true;

      wrapRef2.current.scrollLeft = targetDiv.scrollLeft;
    }
    else if (targetDiv === wrapRef2.current && wrapRef1.current) {
      if (scrolling) {
        scrolling = false;
        return true;
      }
      scrolling = true;

      wrapRef1.current.scrollLeft = targetDiv.scrollLeft;
    }
  };

  const tempMilestones = milestones.map(milestone => ({ ...milestone, eligible: true }))

  let foundToday = false;
  const today = useMemo(() => {
    return dayjs()
  }, [])

  return (
    <div className="pizza-tracker-wrapper">
      {tempTrackerData?.length > 8 &&
        <div id={`#wrapper-1-container`}>
          <div
            ref={wrapRef1}
            onScroll={handleScroll}
            id="wrapper1" //wrapper1
          >
            <div
              className="div1"
              id="pizza-tracker-remote-scroll"
              ref={div1}// div1
            >
            </div>

          </div>
          <Tooltip
            placement="bottom"
            title={"Scroll to view all states"}>
            <i className="fal fa-info-circle remote-scroll-info-icon"></i>
          </Tooltip>
        </div>
      }
      <div
        id="wrapper2" // wrapper2
        ref={wrapRef2}
        onScroll={handleScroll}
      >
        <div
          className={`div2 ${showDueDates === false && "div2-small"}`} // div2
          ref={pizzaTrackerRef}
          style={{ marginTop: showDueDates ? marginTop : "84px" }}
        >
          {tempTrackerData?.map((state, stateIndex) => {
            const stateMilestones = [];
            let isToday = false;

            tempMilestones.forEach((milestone, milestoneIndex) => {
              let milestoneDate = dayjs(milestone.value).format(userSettings?.date_format)

              if (milestone.eligible) {
                if (state.start_state_date && state.end_state_date) {
                  let startDate = dayjs(state.start_state_date).format(userSettings?.date_format)
                  let endDate = dayjs(state.end_state_date).format(userSettings?.date_format)

                  if (dayjs(milestoneDate).isBetween(startDate, endDate) || milestoneDate === startDate || milestoneDate === endDate) {
                    stateMilestones.push(milestone)
                    milestone.eligible = false
                  }
                } else if (stateIndex === 0 && dayjs(milestoneDate).isSameOrBefore(state.start_state_date)) {
                  stateMilestones.push(milestone)
                  milestone.eligible = false
                } else if (stateIndex === tempTrackerData.length - 1 && dayjs(milestone.value).isSameOrAfter(state.start_state_date)) {
                  stateMilestones.push(milestone)
                  milestone.eligible = false
                }
              }
            })

            if (foundToday === false) {
              if (state?.start_state_date && today.isSame(state?.start_state_date, 'day')) {
                isToday = true
              } else if (tempTrackerData?.[stateIndex + 1]?.start_state_date !== undefined && today.isSame(tempTrackerData?.[stateIndex + 1]?.start_state_date, 'day')) {
                isToday = true
              } else if (state?.start_state_date && tempTrackerData?.[stateIndex + 1]?.start_state_date && today.isBetween(state?.start_state_date, tempTrackerData?.[stateIndex + 1]?.start_state_date)) {
                isToday = true
              }

              if (isToday) {
                foundToday = true
              }
            }

            return (
              <State
                readOnly={readOnly}
                key={stateIndex + "-state"}
                pizzaTrackerRef={pizzaTrackerRef}
                pizzaTrackerWrapperRef={wrapRef2}
                tempTrackerData={tempTrackerData}
                setTempTrackerData={setTempTrackerData}
                index={stateIndex}
                isFirst={stateIndex === 0}
                isLast={tempTrackerData?.length === stateIndex + 1}
                isToday={isToday}
                state={state}
                onSaveStateDates={onSaveStateDates}
                onRevertStateDates={onRevertStateDates}
                showAssignees={showAssignees}
                showDueDates={showDueDates}
                showDays={showDays}
                trackerMarginTop={marginTop}
                setTrackerMarginTop={setMarginTop}
                milestones={stateMilestones} />
            )
          })}
        </div>
      </div>
    </div>
  )
}

PizzaTracker.propTypes = {

}

export default PizzaTracker