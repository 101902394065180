import React, { forwardRef, useMemo } from 'react'
import { Select as AntSelect, Form } from 'antd'
import ReadOnlyField from 'components/ReadOnlyField/ReadOnlyField'
import PropTypes from 'prop-types'
import './Select.scss'

const Select = forwardRef(({
  options: optionsProp,
  defaultValue,
  placeholder,
  onChange, // returns: value, option:Option | Array<Option>
  label, // optional label component
  labelLayout = "vertical",
  width,
  minWidth,
  allowClear = true,
  showArrow = true,
  // size = "medium",
  className: wrapperClassName,
  formItemClassName,
  selectClassname,
  popupClassName,
  popupContainerId,
  closeDropdownOnChange = false, // when true, closes dropdown when option is selected
  readOnly,
  value,
  mode,
  disabled,
  ...props // autoFocus, onSearch, notFoundContent
}, ref) => {
  const [open, setOpen] = React.useState(false)

  const options = useMemo(() => {
    if (
      optionsProp?.length > 0
    ) {
      // {id, name}
      if (optionsProp[0]?.id && optionsProp[0]?.name) {
        return optionsProp.map((option) => {
          return { value: option.id, label: option.name }
        })
      }
      // {id, text}
      if (optionsProp[0]?.id && optionsProp[0]?.text) {
        return optionsProp.map((option) => {
          return { value: option.id, label: option.text }
        })
      }
      // {value, label}
      if ((optionsProp[0]?.value !== null || optionsProp[0]?.value !== undefined) && optionsProp[0]?.label) {
        return optionsProp
      }
      // simple array
      if (typeof optionsProp[0] === 'string' && Array.isArray(optionsProp)) {
        return optionsProp?.map((option) => {
          return { value: option, label: option }
        })
      }
    }
    return []
  }, [optionsProp])

  // If options are other than in Ant Design shape {value, label}, eg, {id, text}, convert options object to that shape
  // TODO: do same for options with {id, name} shape
  const handleChange = (value, option) => {
    let opt = option
    // if shape {id, text}
    if (optionsProp?.[0]?.id && optionsProp?.[0]?.text) {
      // if mode is "multiple"
      // if empty, return []
      if (Array.isArray(option)) {
        opt = option.map((item) => {
          return { id: item.value, text: item.label }
        })
      }
      // if single-select
      else if (option) {
        opt = { id: option.value, text: option.label }
      }
    }
    onChange(value, opt)
    if (closeDropdownOnChange) setOpen(false);
  }

  let style = {}
  if (minWidth) {
    style.width = width
    style.minWidth = minWidth
  }

  let popupClassNames = ["prime-select-dropdown"]
  if (popupClassName) popupClassNames.push(popupClassName)

  const renderField = () => {
    return <div
      className={`prime-select-wrapper${wrapperClassName ? ' ' + wrapperClassName : ''}`}
      style={{ ...style }}
    >
      <AntSelect
        ref={ref}
        mode={mode}
        options={options}
        value={value}
        defaultValue={defaultValue}
        placeholder={placeholder}
        disabled={disabled || readOnly}
        onChange={handleChange}
        open={open}
        onDropdownVisibleChange={(visible) => setOpen(visible)}
        className={`prime-select${selectClassname ? ' ' + selectClassname : ''}${mode === 'multiple' ? ' prime-select-badge' : ''}`} // different styles for tags
        popupClassName={popupClassNames.join(" ")}
        allowClear={allowClear}
        showArrow={showArrow}
        showSearch
        optionFilterProp="label"
        getPopupContainer={() => document.getElementById(popupContainerId ? popupContainerId : "root")}
        {...props}
      />
    </div>
  }


  if (readOnly && mode !== 'multiple' && mode !== 'tags') {
    return <ReadOnlyField
      placeholder={placeholder}
      className={wrapperClassName}
      ellipsis
    >
      {value || defaultValue}
    </ReadOnlyField>
  }
  else if (!options) {
    return null
  }

  if (label) return <Form layout={labelLayout}>
    <Form.Item
      label={label}
      className={`prime-form-item${formItemClassName ? ' ' + formItemClassName : ''}`}
      colon={false}
    >
      {renderField()}
    </Form.Item>
  </Form>

  return renderField()
})

Select.propTypes = {
  options: PropTypes.oneOfType([
    // {id, name} // data shape
    PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      name: PropTypes.string,
    })),
    // {id, text} // data shape
    PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      text: PropTypes.string,
    })),
    // {value, label} // Ant Design Select shape
    PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      label: PropTypes.string,
    })),
    PropTypes.array,
  ]).isRequired,
  // "multiple" mode will add "prime-badge" class to wrapper div, which will add a different style to the select
  mode: PropTypes.oneOf(['multiple', 'tags']),
  // defaultValue could be either the options' "name" or "id", but should be id if that is what is going to be used when selected
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number), // id; mode set
    PropTypes.arrayOf(PropTypes.string), // id; mode set
  ]),
  // size: PropTypes.oneOf(['small', 'medium', 'large']),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  allowClear: PropTypes.bool,
  showArrow: PropTypes.bool,
  label: PropTypes.string,
  labelLayout: PropTypes.oneOf(['horizontal', 'vertical', 'inline']),
}

export default Select