import { useState, useContext, createContext } from 'react';

// context
import useAuthoringStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringStore';

// api
import {
  useGetChecklistTemplates,
  usePostChecklistTemplate,
  usePutChecklistTemplate,
  useDeleteChecklistTemplate,
} from 'api/hooks/admin/useChecklistTemplateApi'
import useDocuments from './useDocuments';

import {
  useGetStudyChecklists,
  useGetNonStudyChecklistDocuments,
  usePostStudyChecklist,
} from 'api/hooks/studies/useChecklistsApi';

import {
  useGetDocumentTypes
} from 'api/hooks/admin/useDocumentTypesApi'

const useChecklists = () => {
  return useContext(ChecklistContext);
};

const ChecklistContext = createContext({});

export const ChecklistProvider = ({ children }) => {
  const authoringId = useAuthoringStore(state => state.authoringId)

  const {
    setSelectedDocument
  } = useDocuments()

  const { data: checklistTemplates = [] } = useGetChecklistTemplates()
  const { data: documentTypes = [] } = useGetDocumentTypes()
  const postChecklistTemplate = usePostChecklistTemplate()
  const putChecklistTemplate = usePutChecklistTemplate()
  const deleteChecklistTemplate = useDeleteChecklistTemplate()


  // checklists
  const { data: studyChecklists = [] } = useGetStudyChecklists(authoringId)
  const { data: studyNonChecklistDocuments = [] } = useGetNonStudyChecklistDocuments(authoringId)
  const postStudyChecklist = usePostStudyChecklist(authoringId)
  const [selectedChecklist, setSelectedChecklist] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null)

  const formatChecklistRequest = (request, documentTypes) => {
    return {
      ...request,
      options: request.options.map(option => {
        let foundDocument = documentTypes.find(doc => doc.name === option.document_type);
        let optional_count = option.total_count - option.required_count;
        if (optional_count < 0) optional_count = 0;

        let updatedOption = {
          ...option,
          optional_count,
          document_type: foundDocument.id
        }
        delete updatedOption.total_count;
        return updatedOption
      })
    }
  }

  const documentTypeIdToName = (id, documentTypes) => {
    let found = documentTypes.find(doc => doc.id === id);
    return found?.name;
  }

  const checklistNameToId = (value, checklists) => {
    return checklists.find(item => item.name === value)?.id
  }

  const onPostStudyChecklist = (req) => {
    postStudyChecklist({
      body: req
    })
  }

  const onResetSelectedChecklist = () => {
    setSelectedDocument(null)
    setSelectedChecklist(null)
    setSelectedOption(null)
  }


  return (
    <ChecklistContext.Provider
      value={{
        selectedChecklist,
        setSelectedChecklist,
        selectedOption, 
        setSelectedOption,
        checklistTemplates,
        studyChecklists,
        studyNonChecklistDocuments,
        onPostStudyChecklist,
        checklistNameToId,
        formatChecklistRequest,
        onResetSelectedChecklist,
        documentTypeIdToName,
        documentTypes,
        postChecklistTemplate,
        putChecklistTemplate,
        deleteChecklistTemplate
      }}>
      {children}
    </ChecklistContext.Provider>
  );
};

export default useChecklists;