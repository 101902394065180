import { useEffect, useState, forwardRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow'
import styled from 'styled-components';
import Loader from 'components/Loading/Loading';
import parse from 'html-react-parser'

// assets
import PRSlogo from './RJS/assets/prs-logo.jpg';
import EUlogo from './RJS/assets/eudractFlag.png';

// RJS
import RJPreviewForm from './RJS/components/RJPreviewForm';
import Locations from './RJS/components/Locations';

// components
import Comments from './RJS/components/Comments/Comments';
import { formSchemas, previewFormSchemas } from 'constants/authoring';

// apis
import { usePostPrsPreview, useGetAuthoringStudy } from 'api/hooks/studies/useAuthoringApi';
import { useGetStudy } from 'api/hooks/studies/useStudiesApi';
import fileDownload from 'utilities/fileDownload';

// styles
import 'styles/authoring-print.scss';
import './PagePreview.scss';

// hooks
import { useGetSiteManagementLocations } from 'api/hooks/siteManagement/useSiteManagementApi';
import useAuthoringViewStore from '../../hooks/stores/useAuthoringViewStore';
import useAuthoringPrintStore from '../../hooks/stores/useAuthoringPrintStore';
import useAuthoringPrintActions from '../../hooks/actions/useAuthoringPrintActions';
import useAuthoringStore from '../../hooks/stores/useAuthoringStore';

const PagePreview = forwardRef(function PagePreview(props, ref) {
  const {
    authoring,
  } = props;

  const authoringId = useAuthoringStore(state => state.authoringId)
  const { refetch: refetchAuthoring } = useGetAuthoringStudy(authoringId)

  const {
    printCtgOnly,
    setPrintCtgOnly
  } = useAuthoringPrintStore(
    useShallow(state => ({
      printCtgOnly: state.printCtgOnly,
      setPrintCtgOnly: state.setPrintCtgOnly
    }))
  )

  const {
    onPrintPage
  } = useAuthoringPrintActions()

  const {
    formView,
    formSection
  } = useAuthoringViewStore(
    useShallow(state => ({
      formView: state.formView,
      formSection: state.formSection
    }))
  )

  const { studyId } = useParams();
  const { data: study } = useGetStudy(studyId)
  const { data: sites } = useGetSiteManagementLocations(study?.site_mgmt_study_id)

  const postPrsPreview = usePostPrsPreview(authoring?.id)
  const override_data = authoring?.override_data
  const forked_study_data = authoring?.study_data?.forked_study_data

  const [currDataSchema, setCurrDataSchema] = useState(null);
  const [currUISchema, setCurrUISchema] = useState(null);
  const [htmlPreview, setHtmlPreview] = useState("");
  const [loadingPrsPreview, setLoadingPrsPreview] = useState(false)

  useEffect(() => {
    if (formView && formSection) {
      let schema = { ...formSchemas[formSection.id].properties[formView.id] };
      if ((formView.id === "ctg" || formView.id === "global") && formSection.id === "protocol") {
        const ctgProtocolSchema = previewFormSchemas["protocol"].properties["ctg"];

        schema.schema = {
          ...schema.schema,
          properties: {
            ...ctgProtocolSchema.schema.properties,
          }
        }

        schema.uiSchema = {
          ...ctgProtocolSchema.uiSchema({ preview: true }),
        }
      } else if (formView.id === "ctg" || formView.id === "global") {
        const ctgProtocolSchema = previewFormSchemas["protocol"].properties["ctg"];
        const ctgResultsSchema = previewFormSchemas["results"].properties["ctg"];

        schema.schema = {
          ...schema.schema,
          properties: {
            ...ctgProtocolSchema.schema.properties,
            ...ctgResultsSchema.schema.properties,
          }
        }

        schema.uiSchema = {
          ...ctgProtocolSchema.uiSchema({ preview: true }),
          ...ctgResultsSchema.uiSchema({ preview: true }),
        }
      } else if (schema.uiSchema) {
        schema.uiSchema = schema.uiSchema({ preview: true })
      }

      if (schema?.schema && schema?.uiSchema) {
        setCurrDataSchema(schema.schema);
        setCurrUISchema(schema.uiSchema);
      } else {
        console.log('ERROR: schema not found:', {
          formView, formSection,
        });
      }
    };
  }, [formView, formSection]);

  useEffect(() => {
    if (formView.id === "ctg" && (formSection.id === "protocol" || formSection.id === "results")) {
      onGetPrsPreview()
    }
    refetchAuthoring()
  }, [])

  const onGetPrsPreview = async (downloadFileType = "html") => {
    setLoadingPrsPreview(true)

    postPrsPreview({ body: { preview_type: downloadFileType, show_validations: false } }, {
      onSuccess: async resp => {
        if (resp.data) {
          if (downloadFileType === "html") {
            setHtmlPreview(resp.data?.html_str)
            if (printCtgOnly) {
              onPrintPage()
            }
            setLoadingPrsPreview(false)
          } else {
            let blob = await fetch(resp.data?.xmlstr)
            fileDownload(blob, `${downloadFileType}_preview`, `application/${downloadFileType}`)
          }
        }
      },
      onError: (error) => {
        if (printCtgOnly) {
          setTimeout(() => {
            onPrintPage()
            setPrintCtgOnly(false)
          }, 1000)
        }
        setLoadingPrsPreview(false)
      }
    })
  }

  const displayLocations = () => {
    if (formView.id === "eupas") {
      return false;
    }
    return true
  }

  const displayHeader = () => {
    if (formView.id === "ctg") {
      let title = "Protocol Registration and Results Preview";
      if (formSection.id === "protocol") title = "Protocol Registration";

      return (
        <HeaderContainer>
          <HeaderLogo src={PRSlogo} alt="xogene-prs-logo" />
          <HeaderTitle>{title}</HeaderTitle>
        </HeaderContainer>
      )
    }

    if (contextState.formView.id === "eudract") {
      return (
        <>
          <HeaderContainer
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              paddingTop: 16,
              paddingBottom: 16,
              width: "100%",
              borderTop: "1px solid #000",
              borderBottom: "1px solid #000",
            }}>
            <HeaderLogo
              src={EUlogo}
              alt="xogene-eudract-logo" />
            <HeaderTitle
              style={{
                fontSize: 22,
                marginLeft: 16
              }}>
              EU Clinical Trials Register
            </HeaderTitle>
          </HeaderContainer>
          <HeaderSubtitle style={{ fontSize: 18, fontWeight: "bold" }}>
            Clinical trial results:
          </HeaderSubtitle>
        </>
      )
    }

    if (formView.id === "eupas") {
      return (
        <HeaderContainer>
          {/* <HeaderLogo src={PRSlogo} alt="xogene-prs-logo" /> */}
          <HeaderTitle>EU PAS Study</HeaderTitle>
        </HeaderContainer>
      )
    }

    if (formView.id === "ctis") {
      return (
        <HeaderContainer>
          {/* <HeaderLogo src={PRSlogo} alt="xogene-prs-logo" /> */}
          <HeaderTitle>CTIS Study</HeaderTitle>
        </HeaderContainer>
      )
    }

    return null;
  }

  const forkedFieldsSnakeCase = useMemo(() => {
    const snakeCase = {}
    if (forked_study_data) {
      Object.keys(forked_study_data).forEach(registry => {
        snakeCase[registry] = {}

        Object.keys(forked_study_data[registry]).forEach(forkedKey => {
          let newKey = forkedKey.replaceAll(".", "_")
          snakeCase[registry][newKey] = forked_study_data[registry][forkedKey]
        })
      })
    }

    return snakeCase
  }, [forked_study_data])

  const overrideDataSnakeCase = useMemo(() => {
    const snakeCase = {}

    if (override_data) {
      Object.keys(override_data).forEach(overrideKey => {
        let newKey = overrideKey.replaceAll(".", "_")
        snakeCase[newKey] = override_data[overrideKey]
      })
    }

    return snakeCase
  }, [override_data])

  const contextState = {
    authoring,
    formData: authoring?.study_data,
    formSchemas: previewFormSchemas,
    formSection,
    formView: formView?.id === "eudra" ? { id: "eudract" } : formView,
    readOnly: true,
    forkedFieldsSnakeCase,
    overrideDataSnakeCase
  };

  if (loadingPrsPreview) {
    return <Loader />
  }

  if (htmlPreview) {
    return (
      <div
        className="authoring-print-page prs-remote-preview"
        id="authoring-print-page-container"
      >
        {parse(htmlPreview)}
      </div>
    )
  }

  if (!authoring || formView.id === "ctg") {
    return <></>
  }

  return (
    <Container
      ref={ref}
      id="authoring-print-page-container"
      className="authoring-print-page">
      {displayHeader()}
      {currDataSchema && currUISchema &&
        <RJPreviewForm
          formData={authoring.study_data}
          schema={currDataSchema}
          uiSchema={currUISchema}
          formContext={contextState} />
      }
      {displayLocations() && <Locations sites={sites?.filter(loc => loc.only_on_ctg !== true)} formData={authoring.study_data} />}
      {formView?.id !== "global" && (
        <Comments />
      )}
    </Container>
  );
})

export default PagePreview;

const Container = styled.div`
  background-color: #fff;
  border-radius: 15px;
  padding: 24px;
  // width: 816px;
  white-space: pre-wrap;

  // @page {
  //   size: 8.5in 11in;  /* width height */
  // }

  .ant-modal-body {
    height: 80vh;
  }
`

const HeaderContainer = styled.div`

`

const HeaderLogo = styled.img`
  height: auto;
  width: 100px;
`

const HeaderTitle = styled.div`
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
`

const HeaderSubtitle = styled.div`

`