import * as React from 'react'
import { Tooltip } from 'antd'

// constants
import { STUDY_PHASE_SHORTCUT_MAP } from 'containers/studies/Study/subcomponents/Authoring/RJS/schemas/dataSchemas/enum/enum'

// styles
import "./PhaseBadge.scss"

const map = {
  "Early Phase 1 (or Phase 0)": "PH0",
  "Phase 1": "PH1",
  "Phase 1/Phase 2": "PH1/2",
  "Phase 2": "PH2",
  "Phase 2/Phase 3": "PH2/3",
  "Phase 3": "PH3",
  "Phase 4": "PH4",
}

const PhaseBadge = ({ value }) => {
  if (!value || value === "N/A") return null;

  return (
    <Tooltip
      title={value}>
      <div className="phase-badge">
        {STUDY_PHASE_SHORTCUT_MAP?.[value]}
      </div>
    </Tooltip>
  )
}

export default PhaseBadge