// images
import AnimationData from '../../animations/json/loading-animation.json'
import Lottie from 'react-lottie';
// animations
import './loading.scss';

const lottieOptions = {
  animationData: AnimationData,
  loop: true,
  autoplay: true,
}

const Loading = ({
  className,
  position = "absolute"
}) => {

  const classNames = [`loading-container${className ? ' ' + className : ''}`]
  if(position === 'relative') {
    classNames.push("loading-position-relative")
  }

  return (
    <div className={classNames.join(" ")}>
      <div style={{ width: '300px', height: '300px' }}>
        <Lottie options={lottieOptions} color="#9566AB" />
      </div>
    </div>
  );
}

export default Loading;