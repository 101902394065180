import useGetApi from "../useGetApi"
import useMutateApi from "../useMutateApi"

// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

// utils
import getUrl from "api/apiMap"

// STUDY
export const useGetStudyChecklists = (authoringId) => {
  return useGetApi(getUrl(`${apiEndpoints.checklists}${authoringId}/checklists/`), queryKeys.checklists.list(authoringId))
}

export const useGetNonStudyChecklistDocuments = (authoringId) => {
  return useGetApi(getUrl(`${apiEndpoints.checklists}${authoringId}/non-checklist-documents/`), queryKeys.checklists.nonChecklists(authoringId))
}

export const useGetStudyChecklistDetails = (authoringId, checklistId) => {
  return useGetApi(getUrl(`${apiEndpoints.checklists}${authoringId}/checklists/${checklistId}/`), queryKeys.checklists.detail(checklistId))
}

export const usePostStudyChecklist = (authoringId) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(`${apiEndpoints.checklists}${authoringId}/checklists/`),
    invalidateQueries: [queryKeys.checklists.list(authoringId), queryKeys.checklists.nonChecklists(authoringId)],
    successMsg: "Successfully created checklist"
  })
}

export const usePutStudyChecklist = (authoringId, checklistId) => {
  return useMutateApi({
    method: "put",
    apiEndpoint: getUrl(`${apiEndpoints.checklists}${authoringId}/checklists/${checklistId}/`),
    invalidateQueries: [queryKeys.checklists.list(authoringId), queryKeys.checklists.detail(checklistId), queryKeys.checklists.nonChecklists(authoringId)],
    successMsg: "Successfully updated checklist"
  })
}

export const useDeleteStudyChecklist = (authoringId, checklistId) => {
  return useMutateApi({
    method: "delete",
    apiEndpoint: getUrl(`${apiEndpoints.checklists}${authoringId}/checklists/`),
    invalidateQueries: [queryKeys.checklists.list(authoringId), queryKeys.checklists.nonChecklists(authoringId)],
    successMsg: "Successfully delete checklist"
  })
}

export const useAddDocumentToChecklist = (authoringId, checklistId, optionId) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(`${apiEndpoints.checklists}${authoringId}/checklists/${checklistId}/options/${optionId}/add-document/`),
    invalidateQueries: [queryKeys.checklists.list(authoringId), queryKeys.checklists.detail(checklistId), queryKeys.checklists.nonChecklists(authoringId)],
    successMsg: "Successfully updated checklist"
  })
}

export const useRemoveDocumentFromChecklist = (authoringId, checklistId, optionId) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(`${apiEndpoints.checklists}${authoringId}/checklists/${checklistId}/options/${optionId}/remove-document/`),
    invalidateQueries: [queryKeys.checklists.list(authoringId), queryKeys.checklists.detail(checklistId), queryKeys.checklists.nonChecklists(authoringId)],
    successMsg: "Successfully updated checklist"
  })
}