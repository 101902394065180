// Usage: if "onFilterChange" and "index" props sent, tag will work as filter button (and uses internal "active" prop to manage toggle state)

import React from 'react';
import './CommentFilterTag.scss'

const CommentFilterTag = ({
  title = "",
  onFilterChange,
  index,
  active = false, // internal state
}) => {

  const getClass = () => {
    switch (title) {
      case 'Data Source': return ' data-source';
      case 'Deletion': return ' deletion';
      case 'Guidance': return ' guidance';
      case 'Internal': return ' internal';
      case 'Justifications': return ' justifications';
      case 'Resolved': return ' resolved';
      case 'Review': return ' review';
      case 'Test': return ' test';
      default: return '';
    }
  }

  return (
    <div
      onClick={onFilterChange && index !== undefined ? () => onFilterChange(index, active) : null}
      className={`filter-button${getClass()}${active ? ' active' : ''}`}
    >
      {title}
    </div>
  );
};

export default CommentFilterTag