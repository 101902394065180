import React, { useState, useEffect, useContext, useMemo } from 'react'
import { useShallow } from 'zustand/react/shallow'
import styled from 'styled-components'
import DiffMatchPatch from 'diff-match-patch'
import parse from 'html-react-parser'

// components
import Label from 'components/LegacyLabel/Label'
import { Tooltip } from 'antd'

// apis
import { useGetAuthoringStudy } from 'api/hooks/studies/useAuthoringApi'

// context
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';
import useAuthoringVersionStore from '../../../hooks/stores/useAuthoringVersionStore'
import useAuthoringStore from '../../../hooks/stores/useAuthoringStore'

// utils
import { highlightHtmlString } from 'utilities/misc'

const RJReadOnly = (props) => {
  const { id, formSection, carbonProps, overrideJpath } = props;

  const {
    authoringId,
    lockedFullname,
    isUserLocked,
  } = useAuthoringStore(
    useShallow(state => ({
      authoringId: state.authoringId,
      lockedFullname: state.lockedFullname,
      isUserLocked: state.isUserLocked,
    }))
  )

  const {
    selectedVersion,
    versionData,
    versionValues,
  } = useAuthoringVersionStore(
    useShallow(state => ({
      selectedVersion: state.selectedVersion,
      versionData: state.versionData,
      versionValues: state.versionValues,
    }))
  )

  const {
    isReviewerView,
    formView,
    showVersionCompare
  } = useAuthoringViewStore(
    useShallow(state => ({
      isReviewerView: state.isReviewerView,
      formView: state.formView,
      showVersionCompare: state.showVersionCompare,
    }))
  )

  const { data: authoring } = useGetAuthoringStudy(authoringId)

  const [value, setValue] = useState("");
  const [verDiff, setVerDiff] = useState([]);
  const [htmlText, setHtmlText] = useState('');
  const jpath = id;

  useEffect(() => {
    if (typeof props?.value === "boolean") {
      if (props.value === true) setValue("Yes")
      else setValue("No")
    } else if (props.value) {
      if (Array.isArray(props.value)) {
        let value = props.value.filter(item => !item !== null && !item !== undefined)?.join(", ");
        setValue(value);
      } else if (typeof props.value === 'object' && props.value !== null) {

      } else {
        setValue(props.value);
      }
    }
  }, [props.value])

  const formatValue = () => {
    if (typeof props.value === "boolean") {
      if (props.value === true) return "Yes"
      else return "No"
    } else if (typeof props.value === 'object' && props.value !== null) {
      return null;
    } else if (props.value && Array.isArray(props.value)) {
      return props.value.filter(item => !item !== null && !item !== undefined)?.join(", ");
    }

    return props?.value
  }

  // On Study Data, previous version data to compare to current value.
  // On Reviewer view, if form-version URL param, version value to display. Live value if no version in URL params.
  const versionValue = useMemo(() => {
    if (isReviewerView && !selectedVersion) {
      let ret = formatValue()
      return ret;
    }

    const confirmEnding = (str, target) => {
      return str?.substr(-(target.length)) === target
    }

    const selectedVersNum = selectedVersion?.version_num;
    // if selected version is not current study version

    if (versionValues && selectedVersNum) {
      const selectedVersValues = versionValues?.[selectedVersNum];
      let regKey = formView?.id;
      if (regKey === "eudra") regKey = "eudract"

      let isForkedField = confirmEnding(jpath, `_${regKey}`)
      let originalPath = null

      if (formView?.id !== "global" && selectedVersValues?.[`${jpath}_${regKey}`]?.value) {
        return selectedVersValues[`${jpath}_${regKey}`]?.value
      } else if (selectedVersValues?.[jpath]) {
        return selectedVersValues?.[jpath]?.value;
      } else if (regKey !== "global" && isForkedField) {
        originalPath = jpath.replace(`_${regKey}`, "");
        if (selectedVersValues?.[originalPath]) {
          return selectedVersValues?.[originalPath]?.value;
        }
      } else if (formView?.id === "global" && id) {
        let ids = id?.split("_")
        let regKey = ids[ids?.length - 1]
        originalPath = jpath.replace(`_${regKey}`, "");

        if (selectedVersValues?.[originalPath]) {
          return selectedVersValues?.[originalPath]?.value;
        }
      }

      return null;
    };

    return null;
  }, [selectedVersion, versionValues, formSection, formView, versionData]);

  useEffect(() => {
    if (versionData && (value || versionValue || versionData?.override_data?.[overrideJpath])) {
      formatVersionComparison();
    }
  }, [value, selectedVersion, versionValue, versionData]);

  const formatVersionComparison = () => {
    let overrideValue = versionData?.override_data?.[overrideJpath];
    let compareValue = versionValue

    if (overrideValue) compareValue = overrideValue;

    if (selectedVersion && (compareValue || value)) {
      const val = value?.toString() || "";
      const dmp = new DiffMatchPatch();
      if (isArrayOfStrings(compareValue)) compareValue = compareValue.join(", ")
      let diff = dmp.diff_main(compareValue || "", val);
      dmp.diff_cleanupSemantic(diff)

      setVerDiff(diff);
    }
  };

  const isArrayOfStrings = (value) => {
    // Check if the value is an array
    if (Array.isArray(value)) {
      // Check if all elements in the array are strings
      return value.every(item => typeof item === 'string');
    }
    // If not an array, return false
    return false;
  }

  // set html text if verDiff
  useEffect(() => {
    if (verDiff) {
      let html = ''

      verDiff.forEach(text => {
        let type = text[0];
        let value = text[1];
        if (type === 0) {
          html += highlightHtmlString(value, 'highlight-identical');
        } else if (type === 1) {
          html += highlightHtmlString(value, 'highlight-new');
        } else if (type === -1) {
          html += highlightHtmlString(value, 'highlight-old');
        }
      });
      setHtmlText(html);
    }
  }, [verDiff]);

  const content = () => {
    let displayValue = value;

    if (isReviewerView) displayValue = formatValue();
    let _content = <Input id={`${id}-field`} className={`rj-read-only`}>
      {displayValue ? displayValue : <br />}
    </Input>;

    if (selectedVersion && showVersionCompare) {
      return (
        <Input id={`${id}-field`} className={`rj-read-only`}>
          {htmlText ? parse(htmlText) : <span>&nbsp;</span>}
        </Input>
      )
    }

    let message = <>This form is locked <span className="text-nowrap">by {authoring?.locked_by_fullname}</span>.</>;
    if (lockedFullname === "ETL Load") message = "This form is locked by ETL Load";

    // user locking
    if (isUserLocked && !isReviewerView) _content = <Tooltip
      title={message}
    >
      {_content}
    </Tooltip>

    return _content;
  };

  return (
    <Container style={carbonProps?.styles && carbonProps.styles.widget}>
      {props.labelText && <Label>{props.labelText}</Label>}
      {content()}
    </Container>
  );
};

export default RJReadOnly;

const Container = styled.div`
  white-space: normal;
  
  .highlight-identical,
  .highlight-old,
  .highlight-new {
    color: #60656C;
    word-break: break-word;
    white-space: pre-wrap;
    -moz-white-space: pre-wrap;  
  }
  
  .highlight-old {
    background-color: rgba(255, 0, 0, 0.2) !important;
    text-decoration: line-through;
  }

  .highlight-new {
    background-color: rgba(66, 190, 101, 0.2) !important;
  }
`
const Input = styled.div`
  margin-bottom: 8px;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: .16px;
  color: #383E47;
  padding: 8px 8px 6px 8px;
	border-radius: 3px;
	border: 1px solid #E0E0E0;
`