let hide = {
  "ui:options": {
    label: false,
    hide: true,
  }
}

export const getStyles = ({ preview }) => {

}

export const getPreviewStyles = (schema) => {
  schema.protocol = {
    ...schema.protocol,
    "ui:options": {
      ...schema.protocol["ui:options"],
      classNames: {
        ...schema.protocol["ui:options"].classNames,
        objectTitleContainer: "rjs-h1-container pt-24 pr-24 pb-24 m-0",
      }
    }
  }
  schema.protocol.protocol_id["ui:options"] = {
    ...schema.protocol.protocol_id["ui:options"],
    classNames: {
      fieldContainer: "w-50p pr-8 pb-8",
    }
  }
  schema.protocol.eupas_study_type["ui:options"] = {
    ...schema.protocol.eupas_study_type["ui:options"],
    classNames: {
      fieldContainer: "rjs-full pr-8 pb-8",
      field: "rjs-col-1-2 pr-8"
    }
  }

  if (schema?.protocol?.contact_information?.collaborators?.["ui:options"]) {
    schema.protocol.contact_information.collaborators["ui:options"].classNames = {
      fieldContainer: "rjs-full"
    }
  }

  if (schema?.protocol?.study_arms?.interventions) {
    schema.protocol.study_arms.interventions = {
      ...hide
    }
  }

  return schema
}

export const hideEupasProtocolFields = {
  study_identification: {
    is_requested_by_regulator: {
      ...hide
    },
    country_regulator: {
      ...hide
    },
    country_regulator_not_listed: {
      ...hide
    },
    is_study_required_by_risk_management_plan: {
      ...hide
    },
    regulatory_procedure_number: {
      ...hide
    },
    other_study_identification_numbers: {
      ...hide
    },
  },
  contact_information: {
    is_study_conducted_by_registered_encepp: {
      ...hide
    },
    is_study_collaboration_research_network: {
      ...hide
    },
    other_centers_study_conducted: {
      ...hide
    },
    country_study_is_conducted: {
      ...hide
    },
    investigator_contact_details: {
      ...hide
    },
    investigator_center: {
      ...hide
    },
    department_research_group: {
      ...hide
    },
    encepp_center_organization_affiliation: {
      ...hide
    },
    encepp_center_website: {
      ...hide
    },
    research_center_name: {
      ...hide
    },
    research_center_location: {
      ...hide
    },
    networks_registered_encepp: {
      ...hide
    },
    other_networks: {
      ...hide
    },
    number_of_centers: {
      ...hide
    },
    centers_registered_encepp: {
      ...hide
    },
    other_centers: {
      ...hide
    },
    national_country: {
      ...hide
    },
    international_countries: {
      ...hide
    }
  },
  study_timeline: {
    signed_funding_contract_date: {
      ...hide
    },
    data_collection_start_date: {
      ...hide
    },
    data_analysis_start_date: {
      ...hide
    },
    interim_report_date: {
      ...hide
    },
    final_study_report_date: {
      ...hide
    },
    funding_source: {
      ...hide
    }
  },
  study_scope: {
    scope_of_study: {
      ...hide
    },
    primary_scope: {
      ...hide
    },
    other_scope_description: {
      ...hide
    },
    design_of_study: {
      ...hide
    },
    other_design_description: {
      ...hide
    }
  },
  study_eligibility: {
    population_under_study: {
      ...hide
    },
    additional_patient_information: {
      ...hide
    },
    is_patients_followed_up: {
      ...hide
    },
    follow_up_description: {
      ...hide
    },
    study_carried_with_established_data_source: {
      ...hide
    },
    sources_of_data: {
      ...hide
    },
    registered_data_sources: {
      ...hide
    },
    unregistered_data_sources: {
      ...hide
    },
    other_data_source: {
      ...hide
    }
  },
  study_description: {
    has_medical_condition: {
      ...hide
    },
    medical_conditions: {
      ...hide
    },
    additional_medical_conditions: {
      ...hide
    },
  },
  study_arms: {
    has_drug_information: {
      ...hide
    },
    study_drugs: {
      ...hide
    },
    drug_type: {
      ...hide
    },
    atc_code: {
      ...hide
    },
    substance_inn: {
      ...hide
    },
    substance_inns: {
      ...hide
    },
    product_name: {
      ...hide
    },
    country: {
      ...hide
    }
  },
  study_reference: {
    is_requesting_encepp_seal: {
      ...hide,
    },
    make_full_protocol_public_when_study_ends: {
      ...hide,
    },
    study_publications: {
      ...hide,
    }
  },
  study_outcome_measures: {
    outcome_measures_ctis: {
      ...hide
    },
    outcome_measures_eupas: {
      ...hide
    },
    statistical_analysis_plan: {
      ...hide
    }
  }
}

export const hideCtgProtocolFields = {
  study_identification: {
    secondary_ids: {
      ...hide
    },
  },
  contact_information: {
    responsible_party: {
      ...hide
    },
    sponsor: {
      ...hide
    },
    investigator_name: {
      ...hide
    },
    investigator_official_title: {
      ...hide
    },
    investigator_affiliation: {
      ...hide
    },
    central_contacts: {
      ...hide
    },
    central_contacts_backup: {
      ...hide
    },
    overall_study_officials: {
      ...hide
    }
  },
  study_timeline: {
    record_verification_date: {
      ...hide
    },
    recruitment: {
      ...hide
    },
    milestone_dates: {
      ...hide
    },
    expanded: {
      ...hide
    }
  },
  study_scope: {
    patient_registry: {
      ...hide
    },
    primary_purpose: {
      ...hide
    },
    study_phase: {
      ...hide
    },
    interventional_study_model: {
      ...hide
    },
    model_description: {
      ...hide
    },
    number_of_arms: {
      ...hide
    },
    masking: {
      ...hide
    },
    masking_description: {
      ...hide
    },
    allocation: {
      ...hide
    },
    enrollment_type: {
      ...hide
    },
    enrollment_type_obs: {
      ...hide
    },
    patient: {
      ...hide
    },
    follow_up_duration: {
      ...hide
    },
    duration_type: {
      ...hide
    },
    additional_patient_registry_data: {
      ...hide
    },
    observational_study_model: {
      ...hide
    },
    time_perspective: {
      ...hide
    },
    biospecimen: {
      ...hide
    },
    number_of_cohorts: {
      ...hide
    },
    selection: {
      ...hide
    },
  },
  study_description: {
    detailed_description: {
      ...hide
    },
    study_conditions: {
      ...hide
    },
    study_keywords: {
      ...hide
    },
  },
  study_eligibility: {
    gender_based: {
      ...hide
    },
    age_limits: {
      ...hide
    },
    accept_healthy_volunteers: {
      ...hide
    },
    is_clinical_study: {
      ...hide
    },
    eligibility_criteria: {
      ...hide
    },
    study_population_description: {
      ...hide
    },
    sampling_method: {
      ...hide
    }
  },
  study_arms: {
    arms: {
      ...hide
    },
    interventions: {
      ...hide
    },
    crossref: {
      ...hide
    },
  },
  study_reference: {
    citations: {
      ...hide
    },
    links: {
      ...hide
    },
    available: {
      ...hide
    },
  }
}

export const hideCtisProtocolFields = {
  member_states_concerned: {
    ...hide
  },
  study_identification: {
    countries_outside_eea: {
      ...hide
    },
    associated_clinical_trials: {
      ...hide
    },
    brief_title_translation: {
      ...hide
    },
    official_title_translation: {
      ...hide
    },
    paediatric_investigation_plan: {
      ...hide
    },
    deferral_clinical_trial_information: {
      ...hide
    },
  },
  contact_information: {
    scientific_advice: {
      ...hide
    },
    paediatric_investigation_plan: {
      ...hide
    },
    investigator_contact_details: {
      lead_investigator: {
        address_4: {
          ...hide
        },
        functional_contact_point_name: {
          ...hide
        },
      },
      contact_ctis: {
        ...hide
      }
    }
  },
  study_timeline: {
    milestone_dates: {
      estimated_recruitment_start_date_eea: {
        ...hide
      },
      estimated_end_of_trial_date_eea: {
        ...hide
      },
      estimated_global_end_of_trial_date: {
        ...hide
      }
    }
  },
  study_description: {
    low_intervention_trial: {
      ...hide
    },
    justification_for_low_intervention_trial: {
      ...hide
    },
    justification_foctis_trial_phaser_low_intervention_trial: {
      ...hide
    },
    ctis_trial_phase: {
      ...hide
    },
    brief_summary_translations: {
      ...hide
    },
    secondary_objective: {
      ...hide
    },
    secondary_objective_translations: {
      ...hide
    },
    medical_conditions: {
      ...hide
    },
    therapeutic_area: {
      ...hide
    },
    meddra: {
      ...hide
    },
    trial_scope: {
      ...hide
    },
    principle_inclusion_criteria: {
      ...hide
    },
    principle_exlusion_criteria: {
      ...hide
    },

    protocol_information: {
      ...hide
    },
    scientific_advice_and_pip: {
      ...hide
    },
    sponsors: {
      ...hide
    },
    products: {
      ...hide
    },
    associated_clinical_trials: {
      ...hide
    },
  },
  study_scope: {
    trial_scope: {
      ...hide
    },
    ctis_trial_phase: {
      ...hide
    },
    periods: {
      ...hide
    },
    countries_outside_eea: {
      ...hide
    }
  },
  study_arms: {
    products: {
      ...hide
    }
  },
  study_eligibility: {
    principle_inclusion_criteria: {
      ...hide
    },
    principle_exlusion_criteria: {
      ...hide
    },
    population_under_study: {
      age_range_ctis: {
        ...hide
      },
      age_range_secondary_ctis: {
        ...hide
      },
      clinical_trial_group: {
        ...hide
      },
      vulnerable_population: {
        ...hide
      },
      recruitment_population_group: {
        ...hide
      },
      subjects_incapable_giving_consent: {
        ...hide
      },
      emergency_situation_description: {
        ...hide
      },
      other_description: {
        ...hide
      },
    }
  },
  study_outcome_measures: {
    outcome_measures_ctis: {
      translations: {
        ...hide
      }
    }
  },
  study_reference: {
    associated_clinical_trials: {
      ...hide
    },
  }
}

export const hideStudyDetailsFields = () => {
  return {
    unapproved_new_formulation_marketed_compound: {
      ...hide
    },
    availability_expanded_access: {
      ...hide
    },
    is_clinical_study: {
      ...hide
    },
    vendor: {
      ...hide
    },
    vendor_contact: {
      ...hide
    },
    acquired_from: {
      ...hide
    },
    transferred_to: {
      ...hide
    },
    proprietary_name: {
      ...hide
    },
    established_name: {
      ...hide
    },
    product_manufactured: {
      ...hide
    },
    compound_code: {
      ...hide
    },
    generic_name: {
      ...hide
    },
    brand_name: {
      ...hide
    },
    primary_cro: {
      ...hide
    },
    us_fda_regulated_drug_product: {
      ...hide
    },
    us_fda_regulated_device_product: {
      ...hide
    },
    secondary_ids: {
      ...hide,
    },
    general: {
      ...hide
    },
    status: {
      ...hide
    },
    milestone_dates: {
      "ui:options": {
        title: "Milestone Dates",
        classNames: {
          fieldContainer: "rjs-full",
          objectContainer: "pl-24 pr-24 pb-13",
          objectTitle: "rjs-h2 p-0 mt-8 mb-8",
        }
      },
      conception_date: {
        ...hide
      },
      pra: {
        ...hide
      },
      fpi: {
        "ui:options": {
          title: "Study Start Date",
          classNames: {
            objectTitle: "rjs-h3",
            fieldContainer: "rjs-col-1-2"
          }
        },
        baseline: {
          "ui:options": {
            title: "Baseline",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "date"
        },
        forecast: {
          "ui:options": {
            title: "Forecast",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "date"
        },
        actual: {
          "ui:options": {
            title: "Actual",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "date"
        },
      },
      lpo: {
        "ui:options": {
          title: "LPO/LPLV/SCD/Estimated global end of trial date",
          classNames: {
            objectTitle: "rjs-h3",
            fieldContainer: "rjs-col-1-2"
          }
        },
        baseline: {
          "ui:options": {
            title: "Baseline",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "date"
        },
        forecast: {
          "ui:options": {
            title: "Forecast",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "date"
        },
        actual: {
          "ui:options": {
            title: "Actual",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "date"
        },
      },
      pcd: {
        "ui:options": {
          title: "PCD",
          classNames: {
            objectTitle: "rjs-h3",
            fieldContainer: "rjs-col-1-2"
          }
        },
        baseline: {
          "ui:options": {
            title: "Baseline",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "date"
        },
        forecast: {
          "ui:options": {
            title: "Forecast",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "date"
        },
        actual: {
          "ui:options": {
            title: "Actual",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "date"
        },
      },
      csr: {
        ...hide
      },
      fsa: {
        ...hide
      },
      recruitment_end_date: {
        ...hide
      },
      first_patient_enrolled_date: {
        ...hide
      },
    },
    secondary_attributes: {
      ...hide
    },
    protocol_amendments: {
      ...hide
    },
    study_details_detailed_description: {
      ...hide
    }
  }
}

export const hideSecondaryAttributeFields = (props) => {
  let lpo_eu_eea = {
    "ui:options": {
      title: "Estimated end of trial date in EEA",
      classNames: {
        // fieldContainer: "rjs-full pl-24 pr-24 pb-24",
        objectContainer: "rjs-full",
        objectTitle: "rjs-h3",
        fieldContainer: "rjs-col-1-2"
      }
    },
    baseline: {
      ...hide
    },
    forecast: {
      "ui:options": {
        title: "Forecast",
        label: false,
        classNames: {
          fieldContainer: "rjs-col-1-2"
        }
      },
      "ui:widget": "date"
    },
    actual: {
      ...hide
    },
  }

  if (props?.isGlobal) {
    lpo_eu_eea = {
      "ui:options": {
        title: "Estimated end of trial date in EEA",
        classNames: {
          objectContainer: "rjs-full",
          objectTitle: "rjs-h3",
          fieldContainer: "rjs-col-1-2"
        }
      },
      baseline: {
        ...hide
      },
      forecast: {
        "ui:options": {
          title: "Forecast",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
        "ui:widget": "date"
      },
      actual: {
        ...hide
      },
    }
  }

  return {
    secondary_attributes: {
      "ui:options": {
        classNames: {
          fieldContainer: "rjs-full pl-24 pr-24 pb-24",
        }
      },
      usa: {
        ...hide
      },
      internal: {
        ...hide,
      },
      europe: {
        classNames: {
          fieldContainer: "rjs-full",
        },
        "ui:options": {
          classNames: {
            fieldContainer: "rjs-full",
          }
        },
        eu_marketing_authorization: {
          ...hide
        },
        ema_cta_date: {
          ...hide
        },
        ema_cta: {
          ...hide
        },
        pass_category: {
          ...hide
        },
        pediatric_study: {
          ...hide
        },
        eu_pip: {
          ...hide
        },
        date_of_latest_approval: {
          ...hide
        },
        part_of_an_agreed_pip: {
          ...hide
        },
        date_of_cta: {
          ...hide
        },
        date_of_results: {
          ...hide
        },
        eu_subsequent_marketing_authorization_application_date: {
          ...hide
        },
        marketing_authorization_holder_for_the_product_under_investigation: {
          ...hide
        },
        pass: {
          ...hide
        },
        eudract_interim_results_required: {
          ...hide
        },
        trial_ongoing_in_eu_eea_on_after_jan312025: {
          ...hide
        },
        sponsor_opted_for_submission_per_eu_directive: {
          ...hide
        },
        cta_submission_per_eu_directive_complete: {
          ...hide
        },
        intermediate_result_analysis_specified_in_protocol: {
          ...hide
        },
        date_of_initial_application_cta_submitted_in_eu_eea: {
          ...hide
        },
        lpo_interim_analysis: {
          ...hide
        },
        lpo_eu_eea
      },
      germany: {
        ...hide
      },
      spain: {
        ...hide
      },
      netherlands: {
        ...hide
      },
      mexico: {
        ...hide
      },
      south_korea: {
        ...hide
      },
      japan: {
        ...hide
      },
      united_kingdom: {
        ...hide
      },
      australia_and_newzealand: {
        ...hide
      },
      south_africa: {
        ...hide
      },
      switzerland: {
        ...hide
      },
      china: {
        ...hide
      }
    }
  }
}

export const hideStudyDetailsFieldsInCtis = () => {
  return {
    unapproved_new_formulation_marketed_compound: {
      ...hide
    },
    availability_expanded_access: {
      ...hide
    },
    is_clinical_study: {
      ...hide
    },
    vendor: {
      ...hide
    },
    vendor_contact: {
      ...hide
    },
    acquired_from: {
      ...hide
    },
    transferred_to: {
      ...hide
    },
    proprietary_name: {
      ...hide
    },
    established_name: {
      ...hide
    },
    product_manufactured: {
      ...hide
    },
    compound_code: {
      ...hide
    },
    generic_name: {
      ...hide
    },
    brand_name: {
      ...hide
    },
    primary_cro: {
      ...hide
    },
    us_fda_regulated_drug_product: {
      ...hide
    },
    us_fda_regulated_device_product: {
      ...hide
    },
    secondary_ids: {
      ...hide,
    },
    general: {
      ...hide
    },
    status: {
      ...hide
    },
    milestone_dates: {
      "ui:options": {
        title: "Milestone Dates",
        classNames: {
          fieldContainer: "rjs-full",
          objectContainer: "pl-24 pr-24 pb-13",
          objectTitle: "rjs-h2 p-0 mt-8 mb-8",
        }
      },
      conception_date: {
        ...hide
      },
      pra: {
        ...hide
      },
      fpi: {
        ...hide
      },
      lpo: {
        "ui:options": {
          title: "Estimated global end of trial date",
          classNames: {
            objectTitle: "rjs-h3",
            fieldContainer: "rjs-col-1-2"
          }
        },
        baseline: {
          ...hide
        },
        forecast: {
          "ui:options": {
            title: "Forecast",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-2"
            }
          },
          "ui:widget": "date"
        },
        actual: {
          ...hide
        },
      },
      pcd: {
        ...hide
      },
      csr: {
        ...hide
      },
      fsa: {
        ...hide
      },
      recruitment_end_date: {
        ...hide
      },
      first_patient_enrolled_date: {
        ...hide
      },
    },
    secondary_attributes: {
      ...hide
    },
    protocol_amendments: {
      ...hide
    },
    study_details_detailed_description: {
      ...hide
    }
  }
}