// NOTE: value cannot be the integer 0, must be string to work with Select component
const getPriorityOptions = () => {
  return [
    { value: "0", label: "Low" },
    { value: "1", label: "Medium" },
    { value: "2", label: "High" },
  ]
}

// TODO: review if null priority should be sent to server as -1
const getPriorityId = (value) => {
  let priority = -1
  switch (value) {
    case ("High"): priority = 2; break;
    case ("Medium"): priority = 1; break;
    case ("Low"): priority = 0; break;
    default: priority = -1
  }
  return priority
}

const getPriority = (index) => {
  let priority = ""
  switch (index) {
    case (2): priority = "High"; break;
    case (1): priority = "Medium"; break;
    case (0): priority = "Low"; break;
    default: priority = ""
  }
  return priority
}

export {
  getPriorityOptions,
  getPriorityId,
  getPriority,
}