import React, { createContext, Fragment } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { useShallow } from 'zustand/react/shallow'

// components
import Header from '../components/Header/Header';
import AdverseEvents from './AdverseEvents';
import CTGGroupTotals from './CTGGroupTotals';

// context
import useAuthoringDataStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringDataStore';
import useResultsTableViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableViewStore';

export const AEContext = createContext(null);

const AdversePreview = (props) => {
  let { schema = null, options, formContext } = props;
  const {
    formData,
  } = useAuthoringDataStore(
    useShallow(state => ({
      formData: state.formData,
    }))
  )

  const {
    tableView
  } = useResultsTableViewStore()

  if(tableView === "global") tableView = "ctg"

  let data = formData?.results?.adverse_events?.adverse_events_table?.[tableView];
  let bgColor = "rgb(235, 221, 253)";

  return (
    <AEContext.Provider value={{
      data,
      view: tableView,
      schema
    }}>
      <Table>
        <tbody>
          {Object?.keys(schema?.properties?.[tableView]?.properties)?.map(key => {
            let fieldSchema = schema.properties[tableView].properties;
            return (
              <tr key={uuidv4()}>
                <td style={{ 
                  backgroundColor: bgColor,
                  fontWeight: "bold",
                  width: 250
                }}>{fieldSchema?.[key]?.title}</td>
                <td>
                  {data?.[key] || ""}
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      <Header 
        title="Reporting Groups" 
        bgColor={bgColor}
        arms={formData?.results?.adverse_events?.adverse_events_table?.[tableView]?.groups || []}
        schema={schema}
        tableView={tableView}
        showAdverseEventsProps={true}/>
      <Table className="authoring-table-component">
        <tbody>
          {tableView === "ctg" &&
            <Fragment key={uuidv4()}>
              <tr>
                <td 
                  style={{ fontWeight: "bold" }}
                  colSpan={data?.groups?.length + 2}>
                  All Cause Mortality
                </td>
              </tr>
              <CTGGroupTotals type="all_cause_mortality_group_total" bgColor={bgColor}/>
            </Fragment>
          }
        </tbody>
      </Table>
      <AdverseEvents 
        title="Serious Adverse Events"
        totalType={"serious_adverse_event_group_total"}
        type={"serious_adverse_events"}
        statSchema={"serious_adverse_event_stat"}
        bgColor={bgColor}
        />
      <AdverseEvents 
        title="Non-Serious Adverse Events"
        totalType={"non_serious_adverse_event_group_total"}
        type={"non_serious_adverse_events"}
        statSchema={"non_serious_adverse_event_stat"}
        bgColor={bgColor}
        />
    </AEContext.Provider>
  );
};

export default AdversePreview;

const Container = styled.div`

`

const Table = styled.table`
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 16px;

  td, th {
    border: 1px solid #444;
    text-align: left;
    padding: 8px;
  }

  th {
    font-weight: bold;
    background-color: rgb(238, 238, 254);
    text-align: center;
  }
`