/** DataSharing.Requests.ActionItems API endpoint */
import useGetApi from "../useGetApi"

// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

// utils
import getUrl from "api/apiMap"

export const useGetRequestActionItems = (requestId) => {
  return useGetApi(`${getUrl(apiEndpoints.requests)}${requestId}/action-items/`, queryKeys.requests.actionItems(Number(requestId)))
}