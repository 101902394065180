import React, { useState } from "react"
import { Modal } from "antd"
import moment from 'moment'
import DecisionTree from "../DecisionTree/DecisionTree"
import AssessmentInputBadges from "../../badges/AssessmentInputBadges/AssessmentInputBadges"
import ResultsBadge from "../../badges/ResultsBadge/ResultsBadge"
import { useUserSettings } from "api/hooks"
import './DecisionTreeModal.scss'

const DecisionTreeModal = ({
  dt, // for both admin and  study assessments
  assessment, // core studies assessments api
  title, // data.assessment_name takes precedence
  showModal,
  onHide,
}) => {
  const { data: userSettings } = useUserSettings()
  const [isOverviewOpen, setIsOverviewOpen] = useState(true)
  const dateFormat = userSettings?.date_format

  if (!dt || !assessment) return null;

  const renderActivities = () => {
    if (assessment?.project_type_names?.length > 0) return assessment.project_type_names.join(assessment.operator === '|' ? ' or ' : ' and ')
    return null
  }

  return (
    <Modal
      title={assessment?.assessment_name || title}
      open={showModal}
      onCancel={onHide}
      className={`decision-tree-modal${isOverviewOpen ? ' overview-open' : ''}`}
      width="97vw"
      centered
      footer={null}
      destroyOnClose={true}
    >
      <div className="overview-header">
        <div>Overview</div>
        <i
          className={`fal fa-chevron-${isOverviewOpen ? 'up' : 'down'}`}
          onClick={() => setIsOverviewOpen(!isOverviewOpen)}
        />
      </div>
      <div className={`overview-content${isOverviewOpen ? ' open' : ' closed'}`}>
        <div className="results-wrapper">
          <div className="results-field run-date-container">
            <div className="overview-field-label -date-title">Run Date</div>
            <div className="run-date-date">{dateFormat ? moment(assessment.run_date).format(dateFormat) : assessment.run_date}</div>
          </div>
          <div className="results-field result-container">
            <div className="overview-field-label result-title">Result</div>
            <ResultsBadge value={assessment.result} outlined />
          </div>
          <div className="results-field due-date-container">
            <div className="overview-field-label due-date-title">Due Date</div>
            <div className="due-date-date">{assessment.assessment_due_date ? (dateFormat ? moment(assessment.assessment_due_date).format(dateFormat) : assessment.assessment_due_date) : ''}</div>
          </div>
          <div className="results-field activity-type-container">
            <div className="overview-field-label activity-type-title">Activity Type</div>
            <div className="all-activity-types">
              {renderActivities()}
            </div>
          </div>
          <div className="results-field">
            <div className="overview-field-label note-title">Note</div>
            <div>{assessment.note}</div>
          </div>
        </div>
        <div className="assessment-inputs">
          {assessment?.assessment_inputs?.length > 0 &&
            <>
              <div className="overview-field-label assessment-inputs-title">Assessment Inputs</div>
              <AssessmentInputBadges data={assessment} />
            </>
          }
        </div>
      </div>
      <div className="tree-container">
        <div className="decision-tree-container">
          {dt && <DecisionTree data={dt} />}
        </div>
      </div>
    </Modal>
  )
}

export default DecisionTreeModal
