import {
  ctgBaselineMeasures,
  eudractBaselineMeasures
} from './fieldSchemas';

export const baselineCharacteristicsDataSchema = {
  type: "object",
  title: "Baseline Characteristics",
  properties: {
    baseline_characteristics_table: {
      type: "string",
      properties: {
        ctg: {
          type: "object",
          properties: {
            arms: {
              type: "object",
              properties: {
                title: { 
                  title: "Arm/Group Title",
                  type: "textarea" 
                },
                  description: {
                  title: "Arm/Group Description",
                  type: "textarea"
                },
                baseline_analysis_population: {
                  title: "Overall Number of Participants",
                  placeholder: "",
                  type: "number",
                  value: null
                },
                num_units_analyzed: {
                  title: "Overall Number of Units Analyzed",
                  placeholder: "",
                  type: "number",
                  // value: 0
                },
              },
              type_units_analyzed: {
                type: "text",
                title: "Type of Units Analyzed"
              },
            }, 
            baseline_measure: {
              type: "object",
              anyOf: [
                ctgBaselineMeasures.studySpecific,
                ctgBaselineMeasures.ageContinuous,
                ctgBaselineMeasures.ageCategorical,
                ctgBaselineMeasures.ageCustomized,
                ctgBaselineMeasures.genderCategorical,
                ctgBaselineMeasures.genderCustomized,
                ctgBaselineMeasures.raceCategorical,
                ctgBaselineMeasures.ethnicityCategorical,
                ctgBaselineMeasures.raceEthnicityCustomized,
                ctgBaselineMeasures.raceEthnicityNotCollected,
                ctgBaselineMeasures.regionCategorical,
              ]
            },
            population_analysis_description: {
              title: "Baseline Analysis Population Description",
              type: "textarea"
            }
          }
        },
        eudract: {
          type: "object",
          properties: {
            arms: {
              type: "object",
              properties: {
                title: { 
                  title: "Reporting group title",
                  type: "textarea" 
                },
                description: {
                  title: "Reporting group description",
                  type: "textarea"
                },
                baseline_analysis_population: {
                  title: "Number of Subjects",
                  type: "number"
                }
              }
            },
            subject_analysis_sets: {
              type: "object",
              properties: {
                title: {
                  title: "Subject analysis set title",
                  type: "text"
                },
                type: {
                  title: "Subject analysis set type",
                  type: "dropdown",
                  enum: ["Full analysis", "Intention-to-treat", "Modified intention-to-treat", "Per protocol", "Safety analysis", "Sub-group analysis"]
                },
                description: {
                  title: "Subject analysis set description",
                  type: "textarea"
                },
                baseline_analysis_population: {
                  title: "Number of Subjects",
                  type: "number"
                }
              }
            },
            baseline_measure: {
              type: "object",
              anyOf: [
                eudractBaselineMeasures.customizedCategorical,
                eudractBaselineMeasures.customizedContinuous,
                eudractBaselineMeasures.ageCategorical,
                eudractBaselineMeasures.ageContinuous,
                eudractBaselineMeasures.genderCategorical,
              ]
            }
          }
        }
      },
      transformations: {
        ctgToEudract: {
          "Age, Categorical": "Age categorical",
          "Race and Ethnicity Not Collected": "Race and Ethnicity Not Collected",
          "Race/Ethnicity, Customized": "Race/Ethnicity, Customized",
          "Race (NIH/OMB)": "Race (NIH/OMB)",
          "Ethnicity (NIH/OMB)": "Ethnicity (NIH/OMB)",
        }
      }
    }
  },
}