import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

const Locations = ({ sites, formData }) => {
  const addressFormatter = (site) => {
    let address = [];
    if(site?.location_data?.source?.facility?.name) {
      address.push(`${site?.location_data?.source?.facility?.name}`);
    }
    if(site?.location_data?.source?.facility?.address) {
      let { city, state, country, zip } = site.location_data.source.facility.address;
      address = [ ...address, city, state, country, zip].filter(item => item && item.toString().length > 0).join(", ").trim();
    }
    return address;
  }

  const displayLocation = (site) => {
    let address = "";
    if(site?.location_data?.source?.facility?.address) {
      let { state, country } = site.location_data.source.facility.address;
      address = [country, state].filter(item => item && item.toString().length > 0).join(", ").trim();
    }
    return address;
  }

  const displayInvestivators = (site) => {
    let display = null;
    let investigators = site?.location_data?.source?.investigator || []
    if(investigators) {
      if(!Array.isArray(investigators)) investigators = [investigators];
      let subInvestigator = [];
      let priInvestigator = [];
      investigators?.forEach(investigator => {
        if(investigator?.role?.includes("Sub") || investigator?.role?.includes("sub")) {
          subInvestigator.push(investigator);
        } else if(investigator?.role?.includes("Principal") || investigator?.role?.includes("principal")) {
          priInvestigator.push(investigator);
        }
      });

      display = (
        <InvestigatorContainer>
          {priInvestigator.length > 0 && 
            <Investigators>
              <InvestigatorTitle>
                Principal Investigators
              </InvestigatorTitle>
              <div style={{ paddingLeft: 16 }}>
                {priInvestigator.map(item => {
                  return (
                    <Investigator  key={uuidv4()}>
                      {formatContact(item)}
                    </Investigator>
                  )
                })}
              </div>
            </Investigators>
          }

          {subInvestigator.length > 0 &&
            <Investigators>
              <InvestigatorTitle>
                Sub-principal Investigators
              </InvestigatorTitle>
                <div style={{ paddingLeft: 16 }}>
                  {subInvestigator.map(item => {
                    return (
                      <Investigator key={uuidv4()}>
                        {formatContact(item)}
                      </Investigator>
                    )
                  })}
                </div>
            </Investigators>
          }
        </InvestigatorContainer>
      )
    }

    return display;
  }

  const formatContact = ({
    first_name = "",
    middle_name = "",
    last_name = "",
    degrees = "",
  }) => {
    let fullName = [first_name, middle_name, last_name].filter(item => item && item.length > 0).join(" ").trim();
    let fullNameDegree = [fullName, degrees].filter(item => item && item.length > 0).join(", ").trim();

    return fullNameDegree;
  }

  const displayContacts = (site) => {
    const formatContact = (props) => {
      let fullName = [props?.first_name, props?.middle_name, props?.last_name].filter(item => item && item.length > 0).join(" ").trim();
      let fullNameDegree = [fullName, props?.degrees].filter(item => item && item.length > 0).join(", ").trim();
      let phoneNumber = [props?.phone, props?.phone_ext].filter(item => item && item.toString().length > 0).join(" ext: ").trim();
      let str = [fullNameDegree, phoneNumber, props?.email].filter(str => str && str.length > 0).join("\n");
      return str;
    }

    let primaryContact = formatContact(site?.location_data?.source?.contact);
    let backupContact = formatContact(site?.location_data?.source?.contact_backup);

    return (
      <>
        {primaryContact && primaryContact.length > 0 && 
          <div style={{ marginBottom: 12 }}>
            <div style={{ fontWeight: "bold" }}>Primary Contact</div>
            {primaryContact}
          </div>
        }
        {backupContact && backupContact.length > 0 && 
          <div>
            <div style={{ fontWeight: "bold" }}>Backup Site Contact</div>
            {backupContact}
          </div>
        }
      </>
    )
  }

  if(!sites || sites?.length === 0) return null;
  
  return (
    <Container>
      <Title>Locations</Title>
      {sites?.map((site, index) => {
        return (
          <SiteContainer key={uuidv4()}>
            <LocationContainer>
              {index + 1}. {displayLocation(site)}
            </LocationContainer>
            <FacilityContainer>
              {site?.location_data?.source?.facility?.facility_name}
            </FacilityContainer>
            <AddressContactContainer>
              <Address>
                {addressFormatter(site)}
              </Address>
              {formData?.protocol?.study_timeline?.recruitment?.recruitment_status === "Recruiting" && (
                <div>
                  <span style={{ fontWeight: "bold"}}>
                    Recruitment Status:&nbsp; 
                  </span> 
                  {site?.location_data?.source?.status || "-"}
                </div>
              )}
              {displayContacts(site)}
            </AddressContactContainer>
            {displayInvestivators(site)}
          </SiteContainer>
        )
      })}
    </Container>
  );
};

export default Locations;

const Container = styled.div`

`

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
`

const SiteContainer = styled.div`

`

const LocationContainer = styled.div`
  font-weight: bold;
  padding-left: 24px;
`

const FacilityContainer = styled.div`
  padding-left: 48px;
`

const AddressContactContainer = styled.div`
  padding-left: 72px;
`

const Address = styled.div`
  margin-left: -12px;
`

const InvestigatorContainer = styled.div`
  padding-left: 72px;
  margin-top: 12px;
`

const Investigator = styled.div`

`

const Investigators = styled.div`

`

const InvestigatorTitle = styled.div`
  font-weight: bold;
`