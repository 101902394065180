import Overview from "../subcomponents/Overview/Overview"
import Locations from "../subcomponents/Locations/Locations"
import AuthoringContainer from "../subcomponents/Authoring/AuthoringContainer"
import Registries from "../subcomponents/Registries/Registries"
import NewDocuments from '../subcomponents/Documents/Documents'
import Submissions from "../subcomponents/Submissions/Submissions"
import Activities from '../subcomponents/Activities/Activities'
import Assessments from "../subcomponents/Assessments/Assessments"
import Alerts from "../subcomponents/Alerts/Alerts"

export const STUDY_NAV_ROUTES = [
  {
    key: 'overview',
    index: true,
    title: 'Overview',
    path: 'overview',
    element: <Overview />
  },
  {
    key: 'study-data',
    title: 'Study Data',
    path: 'study-data/:authoringId',
    element: <AuthoringContainer />
  },
  {
    key: 'documents',
    title: 'Documents',
    path: 'documents',
    element: <NewDocuments />
  },
  {
    key: 'registries',
    title: 'Registries',
    path: 'registries',
    element: <Registries />
  },
  {
    key: 'activities',
    title: 'Activities',
    path: 'activities',
    element: <Activities />
  },
  {
    key: 'assessments',
    title: 'Assessments',
    path: 'assessments',
    element: <Assessments />
  },
  {
    key: 'locations',
    title: 'Locations',
    path: 'locations/:siteManagementId',
    element: <Locations />
  },
  {
    key: 'submissions',
    title: 'Submissions',
    path: 'submissions',
    element: <Submissions />
  },
  {
    key: 'alerts',
    title: 'Alerts',
    path: 'alerts',
    element: <Alerts />
  },
]
