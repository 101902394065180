import React from 'react'
import PropTypes from 'prop-types'
import './Tag.scss'

const Tag = ({
  text,
  onDelete,
  maxWidth,
  moreProps,
  disabled,
  readOnly,
  style = {}
}) => {
  let classNames = ['prime-tag']
  if (disabled) classNames.push('disabled')
  if (readOnly) classNames.push('read-only')

  let tagStyle = {}
  if (maxWidth) {
    tagStyle = {
      maxWidth,
      textOverflow: "ellipsis",
      ...style
    }
  }
  return <div
    className={classNames.join(' ')}
    style={tagStyle}
    {...moreProps}
  >
    {text}
    {(disabled !== true && readOnly !== true) && <i
      className="fal fa-times-circle prime-tag-icon"
      onClick={() => onDelete(text)}
    />}
  </div>
}

Tag.propTypes = {
  text: PropTypes.string,
  onDelete: PropTypes.func,
}

export default Tag