import useMutateApi from "../useMutateApi"

// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

// apis
import getUrl from "api/apiMap"

// authoring studies
export const usePostAeManualImport = (authoringId) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(`${apiEndpoints.aeImport}`),
    invalidateQueries: [queryKeys.aeImport(authoringId)],
    options: {
      'Content-Type': 'multipart/form-data',
    }
    // successMsg: 'Successfully updated study.'
  })
}