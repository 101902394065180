import { useState } from 'react';
import { v4 as uuid } from 'uuid'
import { cloneDeep } from 'lodash';
import { useShallow } from 'zustand/react/shallow'

// components
import Arm from '../Arm/Arm';
import PrimeField from 'components/PrimeField/PrimeField';
import ResultsTableCreateModal from '../../../../components/ResultsTableCreateModal/ResultsTableCreateModal';

// context
import useResultsTableArmsStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableArmsStore';
import useResultsTableViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableViewStore';
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';
import useAuthoringDataStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringDataStore';

const GlobalArms = ({
  participantData,
  setParticipantData,
  schema
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showAddArmModal, setShowAddArmModal] = useState(false);
  const [selectedArmOption, setSelectedArmOption] = useState(null);

  const {
    formData,
  } = useAuthoringDataStore(
    useShallow(state => ({
      formData: state.formData,
    }))
  )

  const {
    readOnly
  } = useAuthoringViewStore(
    useShallow(state => ({ readOnly: state.readOnly }))
  )

  const {
    participantArmOptions,
    protocolArms,
  } = useResultsTableArmsStore()

  const {
    participantView,
    participantRegistries,
  } = useResultsTableViewStore()

  const defaultArms = formData?.protocol?.study_arms?.arms || []

  const handleAddArm = (applyAll) => {
    const {
      global_arms = [],
      eudract_periods = [],
      ctg_periods = []
    } = participantData;

    const copyAll = () => {
      let tempData = { ...participantData };
      let newGlobalArms = cloneDeep(global_arms);

      defaultArms.forEach(defaultArm => {
        if (newGlobalArms.findIndex(globalArm => globalArm.id?.includes(defaultArm.id)) === -1) {
          newGlobalArms.push({
            ...defaultArm,
            id: "ParticipantFlow-ParticipantFlowGroup." + defaultArm.id,
            registry_arm_id: "ParticipantFlow-ParticipantFlowGroup." + defaultArm.id,
          });
        }
      })

      let newCtgPeriods = ctg_periods.map(period => {
        let milestone_list = period.milestone_list.map(milestone => {
          let participants = [...milestone.participants];
          defaultArms.forEach(arm => {
            if (global_arms.findIndex(globalArm => globalArm.id === arm.id) === -1) {
              let participant = {
                arm_id: arm.id,
                value: 0,
                comment: "",
                registry_arm_id: `${period.id}:${arm.registry_arm_id}`,
              }
              participants.push(participant)
            }
          })

          return {
            ...milestone,
            participants
          }
        })

        let drop_withdrawn_reason_list = period.drop_withdrawn_reason_list.map(reason => {
          let participants = [...reason.participants];
          defaultArms.forEach(arm => {
            if (global_arms.findIndex(globalArm => globalArm.id === arm.id) === -1) {
              let participant = {
                arm_id: arm.id,
                value: 0,
                comment: "",
                registry_arm_id: `${period.id}:${arm.registry_arm_id}`
              }
              participants.push(participant)
            }
          })

          return {
            ...reason,
            participants
          }
        })

        return {
          ...period,
          milestone_list,
          drop_withdrawn_reason_list
        }
      });

      tempData.global_arms = newGlobalArms;
      tempData.ctg_periods = newCtgPeriods;

      if (applyAll) {
        let newEuPeriods = eudract_periods.map(period => {
          let newPeriod = { ...period }
          newPeriod.arms = []
          if (period?.arms) newPeriod.arms = [...period.arms]

          defaultArms?.forEach(defaultArm => {
            if (period.arms.findIndex(periodArm => periodArm.id === defaultArm.id) === -1) {
              newPeriod.arms.push(defaultArm);
            }
          })

          let milestone_list = period.milestone_list.map(milestone => {
            let participants = [...milestone.participants];
            defaultArms.forEach(arm => {
              if (global_arms.findIndex(globalArm => globalArm.id === arm.id) === -1) {
                let participant = {
                  arm_id: arm.id,
                  value: 0,
                  comment: "",
                  registry_arm_id: `${period.id}:${arm.id}`
                }
                participants.push(participant)
              }
            })

            return {
              ...milestone,
              participants
            }
          })

          let drop_withdrawn_reason_list = period.drop_withdrawn_reason_list.map(reason => {
            let participants = [...reason.participants];
            defaultArms.forEach(arm => {
              if (global_arms.findIndex(globalArm => globalArm.id === arm.id) === -1) {
                let participant = {
                  arm_id: arm.id,
                  value: 0,
                  comment: "",
                  registry_arm_id: `${period.id}:${arm.id}`
                }
                participants.push(participant)
              }
            })

            return {
              ...reason,
              participants
            }
          })

          return {
            ...newPeriod,
            milestone_list,
            drop_withdrawn_reason_list
          }
        });

        tempData.eudract_periods = newEuPeriods;
      }

      setParticipantData(tempData);
    }

    const copyOne = (item) => {
      let tempData = { ...participantData };
      let newGlobalArms = [...global_arms];
      if (global_arms.findIndex(globalArm => globalArm.id === item?.id) === -1) {
        newGlobalArms.push(item);
      }

      let newCtgPeriods = ctg_periods.map(period => {
        let milestone_list = period.milestone_list.map(milestone => {
          let participants = [...milestone.participants];
          if (global_arms.findIndex(globalArm => globalArm.id === item?.id) === -1) {
            participants.push({
              arm_id: item.id,
              value: 0,
              comment: "",
              registry_arm_id: item.id
            })
          }

          return {
            ...milestone,
            participants
          }
        })

        let drop_withdrawn_reason_list = period.drop_withdrawn_reason_list.map(reason => {
          let participants = [...reason.participants];
          if (global_arms.findIndex(globalArm => globalArm.id === item?.id) === -1) {
            participants.push({
              arm_id: item.id,
              value: 0,
              comment: "",
              registry_arm_id: item.id
            })
          }

          return {
            ...reason,
            participants
          }
        })

        return {
          ...period,
          milestone_list,
          drop_withdrawn_reason_list
        }
      })

      if (applyAll) {
        let newEuPeriods = eudract_periods.map(period => {
          let newPeriod = { ...period }

          // newPeriod.arms = [...period.arms]
          let periodArms = period.arms || []

          newPeriod.arms = [...periodArms]

          if (periodArms.findIndex(periodArm => periodArm.id === item?.id) === -1) {
            newPeriod.arms.push({
              ...item,
              registry_arm_id: `${period.id}:${item.id}`
            });
          }

          let milestone_list = period.milestone_list.map(milestone => {
            let participants = [...milestone.participants];
            if (periodArms.findIndex(periodArm => periodArm.id === item?.id) === -1) {
              participants.push({
                arm_id: item.id,
                value: 0,
                comment: "",
                registry_arm_id: `${period.id}:${item.id}`
              })
            }

            return {
              ...milestone,
              participants
            }
          })

          let drop_withdrawn_reason_list = period.drop_withdrawn_reason_list.map(reason => {
            let participants = [...reason.participants];
            if (periodArms.findIndex(periodArm => periodArm.id === item?.id) === -1) {
              participants.push({
                arm_id: item.id,
                value: 0,
                comment: "",
                registry_arm_id: `${period.id}:${item.id}`
              })
            }

            return {
              ...reason,
              participants
            }
          })

          return {
            ...newPeriod,
            milestone_list,
            drop_withdrawn_reason_list
          }
        })
        tempData.eudract_periods = newEuPeriods;
      }

      tempData.global_arms = newGlobalArms;
      tempData.ctg_periods = newCtgPeriods;

      setParticipantData(tempData);
    }

    switch (selectedArmOption.id) {
      case "copy-all":
        copyAll()
        break;
      case "create-new":
        let id = uuid()
        let newArm = {
          id: "ParticipantFlow-ParticipantFlowGroup." + id,
          registry_arm_id: "ParticipantFlow-ParticipantFlowGroup." + id,
          title: "",
          type: "",
          description: ""
        }
        copyOne(newArm);
        break;
      default:
        if (selectedArmOption.from === "protocol") {
          let foundArm = protocolArms.find(pa => selectedArmOption.id.includes(pa.id))
          if (foundArm) {
            copyOne({
              ...foundArm
            });
          }
        }
    }
    setSelectedArmOption(null);
    setShowDropdown(false);
    // handleSubmitWithDelay()
  }

  const handleChange = (value, i, arm, key) => {
    let tempData = { ...participantData };

    let globalArm = tempData.global_arms[i];
    globalArm[key] = value;
    tempData.global_arms.splice(i, 1, globalArm);

    tempData.eudract_periods.forEach(euPeriod => {
      let armIndex = euPeriod.arms.findIndex(euArm => euArm.id === arm.id);
      if (armIndex !== -1) {
        euPeriod.arms[armIndex][key] = value;
      }
    })

    setParticipantData(tempData)
  }

  const handleLeft = (i) => {
    if (i === 0) {
      return;
    }

    const { global_arms, ctg_periods } = participantData;

    [global_arms[i], global_arms[i - 1]] = [global_arms[i - 1], global_arms[i]];

    const newCtgPeriods = ctg_periods.map(period => {
      const newMilestones = period.milestone_list.map(milestone => {
        const newParticipants = [...milestone.participants];
        [newParticipants[i], newParticipants[i - 1]] = [newParticipants[i - 1], newParticipants[i]];
        return { ...milestone, participants: newParticipants };
      });

      const newWithdrawnList = period.drop_withdrawn_reason_list.map(withdrawn => {
        const newParticipants = [...withdrawn.participants];
        [newParticipants[i], newParticipants[i - 1]] = [newParticipants[i - 1], newParticipants[i]];
        return { ...withdrawn, participants: newParticipants };
      });

      return { ...period, milestone_list: newMilestones, drop_withdrawn_reason_list: newWithdrawnList };
    });

    setParticipantData({
      ...participantData,
      global_arms,
      ctg_periods: newCtgPeriods
    });
  };

  const handleRight = (i) => {
    const { global_arms, ctg_periods } = participantData;

    // Swap the i-th and (i+1)-th elements of global_arms using destructuring assignment
    [global_arms[i], global_arms[i + 1]] = [global_arms[i + 1], global_arms[i]];

    // Iterate over ctg_periods using a for...of loop and modify its elements in place
    for (const period of ctg_periods) {
      for (const milestone of period.milestone_list) {
        const participants = milestone.participants;
        [participants[i], participants[i + 1]] = [participants[i + 1], participants[i]];
      }

      for (const withdrawn of period.drop_withdrawn_reason_list) {
        const participants = withdrawn.participants;
        [participants[i], participants[i + 1]] = [participants[i + 1], participants[i]];
      }
    }

    // Update the state with the modified data
    setParticipantData({
      ...participantData,
      global_arms,
      ctg_periods
    });
  }

  const handleDelete = (arm, applyAll) => {
    let tempData = { ...participantData };

    participantRegistries.map(registry => {
      if (applyAll || participantView === registry) {
        if (registry === "ctg_periods") {
          let foundArmIndex = tempData.global_arms.findIndex(item => item?.id === arm?.id);
          if (foundArmIndex !== -1) {
            tempData[registry].forEach(period => {
              period.milestone_list.forEach(milestone => {
                milestone.participants.splice(foundArmIndex, 1)
              })
              period.drop_withdrawn_reason_list.forEach(withdrawn => {
                withdrawn.participants.splice(foundArmIndex, 1)
              })
            })
            tempData.global_arms.splice(foundArmIndex, 1)
          }
        } else {
          tempData[registry].forEach(period => {
            let periodArmIndex = period.arms.findIndex(item => item?.id === arm?.id);

            if (periodArmIndex !== -1) {
              period.arms.splice(periodArmIndex, 1)
              period.milestone_list.forEach(milestone => {
                milestone.participants.splice(periodArmIndex, 1)
              })
              period.drop_withdrawn_reason_list.forEach(withdrawn => {
                withdrawn.participants.splice(periodArmIndex, 1)
              })
            }
          })
        }
      }
    })

    setParticipantData(tempData);
  };

  const onOpenArmModal = (item) => {
    setShowAddArmModal(true);
    setSelectedArmOption(item);
  }

  return (
    <table>
      <tbody>
        {Object.keys(schema.properties.global_arms.properties).map((key, rowIndex) => {
          return (
            <tr key={`global-arm-${rowIndex}`}>
              <td className="header-column">
                <div>{schema.properties.global_arms.properties[key].title}</div>
              </td>
              {participantData?.global_arms?.map((arm, colIndex) => {
                return (
                  <Arm
                    key={`global-arm-${arm?.id}-${rowIndex}`}
                    colIndex={colIndex}
                    rowIndex={rowIndex}
                    arm={arm}
                    fieldKey={key}
                    arms={participantData?.global_arms || []}
                    schema={schema.properties.global_arms.properties[key]}
                    handleChange={val => handleChange(val, colIndex, arm, key)}
                    handleLeft={handleLeft}
                    handleRight={handleRight}
                    handleDelete={handleDelete}
                    type="global" />
                )
              })}
              {!readOnly && rowIndex === 0 &&
                <td
                  className="text-center add-arm-action-col"
                  rowSpan={2}>
                  {showDropdown
                    ? (
                      <PrimeField
                        schema={{
                          type: "dropdown",
                          placeholder: "Select Arm/Group",
                          orientation: "vertical",
                          items: participantArmOptions,
                        }}
                        value={null}
                        readOnly={readOnly}
                        onChange={e => {
                          let foundItem = participantArmOptions.find(item => item.id === e)
                          onOpenArmModal(foundItem)
                        }} />
                    )
                    : <i
                      onClick={() => setShowDropdown(true)}
                      className="fal fa-plus-circle add-arm-icon" />
                  }
                  <ResultsTableCreateModal
                    header="Add Arm"
                    onSubmit={handleAddArm}
                    showModal={showAddArmModal}
                    setShowModal={() => setShowAddArmModal(false)} />
                </td>
              }
            </tr>
          )
        })}
      </tbody>
    </table>
  );
};

export default GlobalArms;