import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Tooltip } from 'antd'
import { useGetFavoriteStudies } from 'api/hooks'
import './NotificationsList.scss'

// apis
import {
  useGetNotifications,
  usePostMarkAllAsRead,
  usePostMarkAsRead,
  useDeleteNotification
} from 'api/hooks/notifications/useNotificationsApi'

const NotificationsList = () => {
  const {
    data: faveStudies,
  } = useGetFavoriteStudies()

  const [tab, setTab] = useState('all')
  const { data: notifications } = useGetNotifications()
  
  const postMarkAllAsRead = usePostMarkAllAsRead()
  const postMarkAsRead = usePostMarkAsRead()
  const deleteNotification = useDeleteNotification()

  const TODAY_TITLE = 'TODAY'
  const OLDER_TITLE = 'OLDER'

  const renderList = (list, listTitle) => {
    if (list?.length > 0) {
      return <div
        key={`${tab}-${listTitle}`}
        className="notif-data-container"
      >
        <div className="notif-day-header">{listTitle}</div>

        {/* notification items */}
        {list?.map((item) => {
          return <div key={item.id}
            className="single-notif-container"
            data-tabular-filter="read"
          >
            <div className="notif-user-circle">{item.actor_initials}</div>
            <div className="single-notif-items-container">
              <div className="notif-time-assigned">{item.created_at}</div>
              <div className="notif-assigned-by-who">{item.description}</div>
              <div className="notif-heart-and-link">

                {/* fave icon */}
                {renderFaveIcon(item.study_db_id)}

                <div className="notif-study-id-activity-name">
                  <Link to={`/study/${item.study_db_id}`}>{item.study_id}</Link> - <Link to={`/study/${item.study_db_id}/activity/${item.project_db_id}`}>{item.project_type__title}</Link>
                </div>
              </div>
              <div className="notif-priority-and-due-date">
                <div className="notif-priority">
                  <span className="notif-priority-word">Priority:</span><span className="notif-priority-variable">{item.priority}</span>
                </div>
                <div className="notif-due-date">
                  <span className="notif-priority-due-word">Due:</span>
                  <span className="notif-priority-due-date">{item.due_date}</span>
                </div>
              </div>
              <div className="notif-view-action-item">
                <Link to="/action-items">View Action Items</Link>
              </div>
            </div>
            <div className="action-items">

              {/* unread button */}
              {item.unread &&
                <div
                  className="notif-mark-read"
                  onClick={() => postMarkAsRead({ id: item.notif_id + "/" })}
                >
                  <Tooltip
                    title="Mark as read"
                    placement="left"
                  >
                    <span className="status-mark"></span>
                  </Tooltip>
                </div>
              }

              {/* remove-x button */}
              <div
                className="notif-close-x"
                onClick={() => deleteNotification({ id: item.notif_id + "/" })}
              >
                <Tooltip
                  title="Remove"
                  placement="left"
                >
                  <i className="fa fa-times" aria-hidden="true" />
                </Tooltip>
              </div>
            </div>
          </div>
        })}
      </div>
    }
    return null
  }

  const renderLists = () => {
    const {
      notification_list_today,
      notification_list_older,
    } = notifications;

    let unreadTodayList = notification_list_today.filter(notif => notif.unread)
    let unreadOlderList = notification_list_older.filter(notif => notif.unread)

    if (tab === 'all') {
      return [
        renderList(notification_list_today, TODAY_TITLE),
        renderList(notification_list_older, OLDER_TITLE)
      ]
    } else { // tab === 'unread'
      if (notification_list_today?.length || notification_list_older?.length) {
        return [
          renderList(unreadTodayList, TODAY_TITLE),
          renderList(unreadOlderList, OLDER_TITLE)
        ]
      } else { // no unread in unread tab
        return <div>You have no unread notification.</div>
      }
    }
  }

  const renderFaveIcon = (studyDbId) => {
    let icon = null

    if (faveStudies?.find((study) => study.id === studyDbId)) {
      icon = <i className="fas fa-heart favorite" /> // fave
      return <div className="notif-heart">
        {icon}
      </div>
    } else {
      // icon = <i className="fal fa-heart" /> // not fave
      return null
    }
  }

  if (!notifications) return <div className="list-notifications">You do not have any notifications</div>;

  return <div
    className="list-notifications"
  >
    {/* NOTIFICATIONS */}
    <div className="notif-header-btns">
      <div className="notifications-read-unread">
        <div className={`notif-unread-button notif-button${tab === 'unread' ? ' active' : ''}`}>
          <a onClick={() => setTab('unread')}>Unread</a>
        </div>
        <div className={`notif-all-button notif-button${tab === 'all' ? ' active' : ''}`}>
          <a onClick={() => setTab('all')}>All</a>
        </div>
      </div>

      <div className="notif-mark-all">
        <a
          onClick={() => postMarkAllAsRead({})}
        >Mark all as read</a>
      </div>

    </div>
    <ul className="live-notify-list">
      {renderLists()}
    </ul>
  </div>
}

export default NotificationsList