// TODO: organize

export default {
  // MISC
  countries: 'https://restcountries.com/v3.1/all',
  envVars: "/admin/api/v1/env-vars/",
  publicEnvVar: "/admin/api/v1/env-vars-public/",
  studySearch: "/core/api/v1/global-study-search",
  globalSearch: "/core/api/v1/global-search/",
  globalSponsorFilter: "/auth/api/v1/global-sponsor-filter/",
  notifications: "/core/api/v1/user/notifications/",

  // auth
  resetLoginPassword: "/auth/api/v1/users/password-reset/confirm/",

  // account settings
  subscriptionManager: "/admin/api/v1/subscription-manager/",

  // activities
  activities: "/activity/api/v1/activities/",

  // activity workflow
  activityTypes: '/admin/api/v1/project-types/',
  activityTypesList: '/admin/api/v1/project-types-list/',
  requestTypes: '/admin/api/v1/request-types/',
  requestFields: '/admin/api/v1/request-custom-fields/',
  requestTypesList: '/admin/api/v1/request-types-list/',

  // action items
  actionItems: "/actionitems/api/v1/items/",

  // admin
  pollTask: '/admin/api/v1/tasks/',
  cancelProtocolUpload: '/authoring/api/v1/tasks/prs-protocol-cancel-upload',
  cancelSitesUpload: '/sites/api/v1/tasks/prs-sites-cancel-upload/',
  assessmentSubTypes: '/admin/api/v1/assessment-subtypes/',
  assessmentTypes: '/admin/api/v1/assessment-types/',
  checklistTemplates: '/admin/api/v1/checklist-templates/',
  collaboratorTypes: '/admin/api/v1/collaborator-types/',
  commentTypes: '/admin/api/v1/comment-types/',
  conditions: '/admin/api/v1/conditions/',
  defaultAuthoringForm: '/admin/api/v1/default-authoring-form/',
  documentTypes: '/admin/api/v1/document-types/',
  globalSiteSettings: '/admin/api/v1/global-site-settings/',
  gridPresetsAdmin: '/admin/api/v1/grid-presets/',
  protocolMaintenanceSettings: '/admin/api/v1/protocol-maintenance-settings/',
  prsUsers: '/admin/api/v1/prs-users/',
  sponsors: '/admin/api/v1/sponsors/',
  tags: '/core/api/v1/tags/',
  requestSecondaryIdTypes: "/admin/api/v1/request-secondary-id-types/",
  requestCategories: "/admin/api/v1/request-categories/",
  requestSource: "/datasharing/api/v1/request-source-list/",
  // authoring
  authoring: "/authoring/api/v1/studies/",
  authoringLockedFields: '/admin/api/v1/locked-fields/',
  prsProtocolDownload: "/authoring/api/v1/tasks/prs-protocol-download",
  prsProtocolUpload: "/authoring/api/v1/tasks/prs-protocol-upload",
  
  // ETL
  aeImport: "/etl/api/v1/ae-manual-import/",
  sitesImportInfo: "/etl/api/v1/sites-etl-latest-import-info/",

  // checklists
  checklists: '/authoring/api/v1/studies/', // TODO: use "authoring" with same endpoint instead

  // core - studies
  studies: "/core/api/v1/studies/",
  studiesGrid: "/core/api/v1/studies-grid/",
  studiesList: "/core/api/v1/studies-list/",

  // Documents
  documentsGrid: "/documents/api/v1/documents-grid/",
  documents: "/documents/api/v1/documents/",
  extraDocumentFields: "/admin/api/v1/docs-json-schema/",
  reviewerDocuments: "/documents/api/v1/reviewer-documents/",
  globalDocsSearch: "/documents/api/v1/global-doc-search",

  // grids
  gridPresets: "/reports/api/v1/presets/grid/",
  gridFilters: "/reports/api/v1/presets/filter/",

  // site management
  siteManagementDetails: '/sites/api/v1/studies/',
  prsSitesUpload: "/sites/api/v1/tasks/prs-sites-upload/",

  activitiesAll: '/api/all-project-types',
  myPrime: '/core/api/v1/dashboard/my-prime-grid',

  // requests
  requests: '/datasharing/api/v1/requests/',
  requestList: '/datasharing/api/v1/request-list/',
  requestsGrid: '/datasharing/api/v1/requests-grid/',

  // Metrics
  activityDashboard: "/reports/api/v1/metrics/activity/",
  detailedActivityDashboard: "/reports/api/v1/metrics/detailed-activity/",
  assessmentDashboard: "/reports/api/v1/metrics/assessment/",
  detailedAssessmentDashboard: "/reports/api/v1/metrics/detailed-assessment/",

  // metrics reports
  manageActivities: "/reports/api/v1/activity/",
  manageActionItems: "/reports/api/v1/action-items/",

  // reports
  reports: "/reports/api/v1/all-reports/",
  reportRegen: "/reports/api/v1/handle-reports/",
  dataChangeReport: "/reports/api/v1/data-change/",
  newStudyReport: "/reports/api/v1/new-study/",
  requestReport: "/reports/api/v1/request/",

  // submissions
  submissions: '/authoring/api/v1/studies/', // TODO: use "authoring" with same endpoint instead
  submissionsUpload: '/authoring/api/v1/tasks/prs-submission-upload',

  // User
  userInfo: "/auth/api/v1/user-info/",
  users: "/auth/api/v1/users/",
  usersGrid: "/auth/api/v1/users-grid/",
  resetPassword: "/auth/api/v1/users/change-pwd",
  forgotPassword: "/auth/api/v1/users/password-reset/",
  pwdExpiryDeadline: "/auth/api/v1/users/pwd-expiry-deadline",

  // Dropdown
  usersList: "/auth/api/v1/users-list/",
  userPermissions: "/admin/api/v1/user-permissions/",
  userSettings: '/auth/api/v1/user-settings/',
  favoriteStudies: "/auth/api/v1/favorites/",
  favoriteRequests: "/auth/api/v1/favorite-requests/",

  // user roles
  roles: "/admin/api/v1/roles-list/",
  requestRoles: "/admin/api/v1/request-roles-list/",

  // upload history
  uploadHistory: '/authoring/api/v1/prs-submission-history/',

  // workflows
  workflow: "/workflow/api/v1/workflows/",

  // misc. lists for dropdowns
  allowedClients: '/admin/api/v1/allowed-clients-list/',
  emailTemplates: "/admin/api/v1/email-template-list/",
  registries: '/admin/api/v1/registries-list/',
  regions: '/admin/api/v1/regions-list/',
  viewTypes: "/admin/api/v1/view-types-list/",
  programName: '/core/api/v1/program-name-list/',
  programId: '/core/api/v1/program-id-list/',
}