import { useState } from 'react';
import { v4 as uuid } from 'uuid'
import { useShallow } from 'zustand/react/shallow'

// components
import MeasureCategory from '../MeasureCategory/MeasureCategory';
import PrimeField from 'components/PrimeField/PrimeField';
import Label from 'components/Label/Label';
import RowActions from '../../../components/RowActions/RowActions';

// context
import useResultsTableViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableViewStore';
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';

// enum
import { countries } from '../../../../schemas/dataSchemas/enum/enum';

const MeasureRow = ({
  rows,
  rowIndex,
  schema,
  measure,
  measureIndex,
  row,
  arms,
  baselineData,
  setBaselineData,
}) => {
  const [showActions, setShowActions] = useState(false);

  const {
    tableView
  } = useResultsTableViewStore()

  const {
    readOnly
  } = useAuthoringViewStore(
    useShallow(state => ({ 
      readOnly: state.readOnly,
    }))
  )

  const handleTitle = (e, oldVal) => {
    let tempData = { ...baselineData }

    Object.keys(tempData).forEach(registry => {
      let foundBMIndex = tempData[registry].baseline_measures.findIndex(bm => bm.id === measure.id);
      let bm = tempData[registry].baseline_measures[foundBMIndex];

      if (foundBMIndex != -1) {
        let foundRowIndex = bm.rows.findIndex(bmRow => bmRow.id === row.id);
        if (foundRowIndex !== -1) {
          bm.rows[foundRowIndex].title = e;
        }
      }
    })

    setBaselineData(tempData);
  }

  const handleDeleteRow = (applyAll) => {
    let tempData = { ...baselineData }

    Object.keys(tempData).forEach(registry => {
      if (applyAll || registry === tableView) {
        let foundBMIndex = tempData[registry].baseline_measures.findIndex(bm => bm.id === measure.id);
        let bm = tempData[registry].baseline_measures[foundBMIndex];

        if (foundBMIndex != -1) {
          let foundRowIndex = bm.rows.findIndex(bmRow => bmRow.id === row.id);
          if (foundRowIndex !== -1) {
            bm.rows.splice(foundRowIndex, 1);
          }
        }
      }
    })

    setBaselineData(tempData);
  }

  const displayRowActions = () => {
    let colSpan = 1;
    // let isRegionEnrollment = getRegionEnrollment()
    // if (tableView === "eudract" && isRegionEnrollment) colSpan = 2

    return (
      <tr
        onMouseEnter={() => setShowActions(true)}
        onMouseLeave={() => setShowActions(false)}
      >
        <td
          className="col-sm"
          colSpan={colSpan}
          rowSpan={row.categories.length + 1}>
          {/* {(measure?.rows.length > 1 || (isRegionEnrollment || measure.title.includes("Customized"))) && displayRowTitle()} */}
          {displayRowTitle()}
          <RowActions
            showActions={showActions}
            setShowActions={setShowActions}
            handleDelete={handleDeleteRow}
            canDelete={measure.rows.length > 1}
            deleteModalTitle="Delete row?"
            jpath={`BaselineReportingGroup.bc-${measure.id}-row-${row.id}`} />
        </td>
      </tr>
    )
  }

  const displayRowTitle = () => {
    if (measure.title === "Region of Enrollment") {
      return (
        <PrimeField
          schema={{
            type: "dropdown",
            items: countries,
            label: "Row Title"
          }}
          readOnly={readOnly}
          value={row.title || ""}
          onChange={handleTitle} />
      )
    } else {
      return (
        <PrimeField
          schema={{
            type: "textarea",
            label: "Row Title"
          }}
          readOnly={readOnly}
          value={row.title || ""}
          onChange={handleTitle} />
      )
    }
  }

  const displayGroupHeader = () => {
    const findGroup = (value) => {
      let group = null
      group = baselineData[tableView]?.subject_analysis_sets?.find(sas => sas.id === value.arm_id);
      if (group) { // SAS
        return (
          <td
            className="subject-analysis-set"
            key={uuid()}>
            <div className="measure-group-header">
              <Label>Group title sas: {group?.title}</Label>
              <Label>Number of Subjects: {group?.baseline_analysis_population}</Label>
            </div>
          </td>
        )
      } else { // ARM
        group = baselineData[tableView]?.arms?.find(arm => arm.id === value.arm_id);
        if (group) {
          return (
            <td key={uuid()}>
              <div className="measure-group-header">
                <Label>Group title: {group?.title}</Label>
                <Label>Number of Subjects: {group?.baseline_analysis_population}</Label>
              </div>
            </td>
          )
        }
      }
    }

    let totalArmParticipants = baselineData[tableView]?.arms?.reduce((acc, group) => {
      if (group.baseline_analysis_population) {
        return acc + Number(group.baseline_analysis_population);
      } else {
        return acc;
      }
    }, 0)

    if(isNaN(totalArmParticipants)) totalArmParticipants = 0

    let totalSasParticipants = baselineData[tableView]?.subject_analysis_sets?.reduce((acc, group) => {
      if (group.baseline_analysis_population) {
        return acc + Number(group.baseline_analysis_population);
      } else {
        return acc;
      }
    }, 0)

    if(isNaN(totalSasParticipants)) totalSasParticipants = 0

    return (
      <tr>
        <td
          className="header-column"
          colSpan={2}>
          Groups
        </td>
        {row?.categories?.[0]?.values?.map(value => {
          return findGroup(value)
        })}

        <td style={{ backgroundColor: "#f3f3f3" }}>
          <Label>
            Total Participants: {totalArmParticipants + totalSasParticipants}
          </Label>
        </td>
      </tr>
    )
  }

  const getRegionEnrollment = () => {
    // if (tableView === "eudract") {
    let foundCtgBaseline = baselineData?.ctg?.baseline_measures.find(bm => bm.id === measure.id)
    if (foundCtgBaseline?.baseline_measure_id === "ctg-region-categorical") {
      return true
    }
    // }
    return false
  }

  return (
    <>
      {rowIndex === 0 && tableView === "eudract" && displayGroupHeader()}
      {displayRowActions()}
      {
        row.categories.map((category, catIndex) => {
          return (
            <tr key={`bm-${rowIndex}-${catIndex}`}>
              <MeasureCategory
                schema={schema}
                measure={measure}
                measureIndex={measureIndex}
                rowIndex={rowIndex}
                category={category}
                catIndex={catIndex}
                rows={rows}
                row={row}
                numOfCategories={row.categories.length}
                baselineData={baselineData}
                setBaselineData={setBaselineData} />
            </tr>
          )
        })
      }
    </>
  );
};

export default MeasureRow;