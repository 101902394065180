import { useState, useMemo } from 'react';
import { cloneDeep } from 'lodash';
import { v4 as uuid } from 'uuid'
import { useShallow } from 'zustand/react/shallow'

// components
import Modal from 'components/Modal/Modal';
import ResultsTableCreateModal from '../../../../components/ResultsTableCreateModal/ResultsTableCreateModal';
import PrimeField from 'components/PrimeField/PrimeField';
import Arm from '../Arm/Arm';
import Product from '../Product/Product';

// context
import useAuthoringDataStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringDataStore';
import useResultsTableArmsStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableArmsStore';
import useResultsTableViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableViewStore';
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';

const PeriodArms = ({
  periodIndex,
  schema,
  period,
  participantData,
  setParticipantData
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showAddArmModal, setShowAddArmModal] = useState(false);
  const [selectedArmOption, setSelectedArmOption] = useState(null);

  const [openProduct, setOpenProduct] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedArm, setSelectedArm] = useState();

  const {
    formData,
  } = useAuthoringDataStore(
    useShallow(state => ({
      formData: state.formData,
    }))
  )

  const {
    formView,
    readOnly,
  } = useAuthoringViewStore(
    useShallow(state => ({ 
      readOnly: state.readOnly,
      formView: state.formView,
    }))
  )

  const {
    participantArmOptions,
    protocolArms
  } = useResultsTableArmsStore()
  const {
    participantRegistries,
    tableView,
    participantView,
  } = useResultsTableViewStore()


  const handleLeft = (i) => {
    let periodArms = [...period.arms];
    let saved = periodArms[i - 1];
    periodArms[i - 1] = periodArms[i];
    periodArms[i] = saved;

    let euPeriods = [...participantData.eudract_periods]
    let euPeriod = euPeriods[periodIndex];
    euPeriod.arms = [...periodArms];


    let milestones = [...euPeriod.milestone_list];
    milestones.forEach(milestone => {
      let participants = [...milestone.participants];
      let saved = participants[i - 1];
      participants[i - 1] = participants[i];
      participants[i] = saved;

      milestone.participants = participants;
    })

    let withdrawnList = [...euPeriod.drop_withdrawn_reason_list];
    withdrawnList.forEach(withdrawn => {
      let participants = [...withdrawn.participants];
      let saved = participants[i - 1];
      participants[i - 1] = participants[i];
      participants[i] = saved;

      withdrawn.participants = participants;
    })

    euPeriod.drop_withdrawn_reason_list = withdrawnList;
    euPeriod.milestone_list = milestones;

    euPeriods.splice(periodIndex, 1, euPeriod);

    setParticipantData({
      ...participantData,
      eudract_periods: euPeriods
    });
  }

  const handleRight = (i) => {
    let periodArms = [...period.arms];
    let saved = periodArms[i + 1];
    periodArms[i + 1] = periodArms[i];
    periodArms[i] = saved;

    let euPeriods = [...participantData.eudract_periods]
    let euPeriod = euPeriods[periodIndex];
    euPeriod.arms = [...periodArms];


    let milestones = [...euPeriod.milestone_list];
    milestones.forEach(milestone => {
      let participants = [...milestone.participants];
      let saved = participants[i + 1];
      participants[i + 1] = participants[i];
      participants[i] = saved;

      milestone.participants = participants;
    })

    let withdrawnList = [...euPeriod.drop_withdrawn_reason_list];
    withdrawnList.forEach(withdrawn => {
      let participants = [...withdrawn.participants];
      let saved = participants[i + 1];
      participants[i + 1] = participants[i];
      participants[i] = saved;

      withdrawn.participants = participants;
    })

    euPeriod.drop_withdrawn_reason_list = withdrawnList;
    euPeriod.milestone_list = milestones;

    euPeriods.splice(periodIndex, 1, euPeriod);

    setParticipantData({
      ...participantData,
      eudract_periods: euPeriods
    });
  }

  const handleDelete = (i, arm) => {
    let tempData = { ...participantData };

    if (formView.id === "global") {
      participantRegistries.map(registry => {
        if (registry === "ctg_periods") {
          let foundArmIndex = tempData.global_arms.findIndex(item => item?.id === arm?.id);
          if (foundArmIndex !== -1) {
            tempData[registry].forEach(period => {
              period.milestone_list.forEach(milestone => {
                milestone.participants.splice(foundArmIndex, 1)
              })
              period.drop_withdrawn_reason_list.forEach(withdrawn => {
                withdrawn.participants.splice(foundArmIndex, 1)
              })
            })
            tempData.global_arms.splice(foundArmIndex, 1)
          }
        } else {
          tempData[registry].forEach(period => {
            let periodArmIndex = period.arms.findIndex(item => item?.id === arm?.id);

            if (periodArmIndex !== -1) {
              period.arms.splice(periodArmIndex, 1)
              period.milestone_list.forEach(milestone => {
                milestone.participants.splice(periodArmIndex, 1)
              })
              period.drop_withdrawn_reason_list.forEach(withdrawn => {
                withdrawn.participants.splice(periodArmIndex, 1)
              })
            }
          })
        }
      })
      setParticipantData(tempData);
    } else if (formView.id === "eudra") {
      let newPeriod = { ...period };
      newPeriod.arms = [...period.arms];
      newPeriod.arms.splice(i, 1);

      let milestones = [...newPeriod.milestone_list];
      milestones.forEach(milestone => {
        milestone.participants.splice(i, 1)
      })

      let withdrawnList = [...newPeriod.drop_withdrawn_reason_list];
      withdrawnList.forEach(withdrawn => {
        withdrawn.participants.splice(i, 1)
      })

      newPeriod.drop_withdrawn_reason_list = withdrawnList;
      newPeriod.milestone_list = milestones;

      let euPeriods = [...participantData.eudract_periods];
      let euPeriodIndex = euPeriods.findIndex(item => item.id === newPeriod.id);
      euPeriods.splice(euPeriodIndex, 1, newPeriod);

      let tempData = {
        ...participantData,
        eudract_periods: euPeriods
      }

      setParticipantData(tempData);
    }
  }

  const handleChange = (value, arm, key) => {
    let tempData = { ...participantData };

    let foundIndex = tempData.global_arms.findIndex(item => item.id === arm.id);
    if (foundIndex !== -1) {
      tempData.global_arms[foundIndex][key] = value;
    }

    let foundPeriodIndex = tempData.eudract_periods.findIndex(item => item.id == period.id);
    if (foundPeriodIndex !== -1) {
      let euPeriod = tempData.eudract_periods[foundPeriodIndex];
      let armIndex = euPeriod.arms.findIndex(euArm => euArm.id === arm.id);
      if (armIndex !== -1) {
        euPeriod.arms[armIndex][key] = value;
      }
    }

    setParticipantData(tempData);
  }

  const onHandleAddArm = (applyAll) => {
    const addArmsToCTG = (tempData, newArms) => {
      let globalArms = [];
      if (tempData.global_arms) globalArms = cloneDeep(tempData.global_arms);

      newArms.forEach(newArm => {
        if (globalArms.findIndex(globalArm => globalArm.id === newArm.id) === -1) {
          globalArms.push(newArm);
        }
      })

      let newCtgPeriods = tempData.ctg_periods.map(period => {
        let milestone_list = period.milestone_list.map(milestone => {
          let participants = [...milestone.participants];
          newArms.forEach(arm => {
            if (tempData.global_arms.findIndex(globalArm => globalArm.id === arm.id) === -1) {
              let participant = {
                arm_id: arm.id,
                value: 0,
                comment: "",
                registry_arm_id: arm.id
              }
              participants.push(participant)
            }
          })

          return {
            ...milestone,
            participants
          }
        })

        let drop_withdrawn_reason_list = period.drop_withdrawn_reason_list.map(reason => {
          let participants = [...reason.participants];
          newArms.forEach(arm => {
            if (tempData.global_arms.findIndex(globalArm => globalArm.id === arm.id) === -1) {
              let participant = {
                arm_id: arm.id,
                value: 0,
                comment: "",
                registry_arm_id: arm.id
              }
              participants.push(participant)
            }
          })

          return {
            ...reason,
            participants
          }
        })

        return {
          ...period,
          milestone_list,
          drop_withdrawn_reason_list
        }
      })
      tempData.ctg_periods = newCtgPeriods;
      tempData.global_arms = globalArms;
    }

    const addArmsToEudract = (tempData, newArms) => {
      let periodIndex = tempData["eudract_periods"].findIndex(item => item.id === period.id);
      let uniqArms = [];

      newArms.forEach(arm => {
        if (!tempData["eudract_periods"][periodIndex].arms || tempData["eudract_periods"][periodIndex].arms?.findIndex(newArm => newArm.id === arm.id) === -1) {
          uniqArms.push({
            ...arm,
            registry_arm_id: `${period.id}:${arm.id}`
          });
        }
      })

      let currPeriod = tempData["eudract_periods"][periodIndex];
      let currPeriodArms = currPeriod.arms || []
      currPeriod.arms = [...currPeriodArms, ...uniqArms]

      currPeriod.milestone_list = currPeriod.milestone_list.map(milestone => {
        let participants = [...milestone.participants];
        uniqArms.forEach(arm => {
          let participant = {
            arm_id: arm.id,
            value: 0,
            comment: "",
            registry_arm_id: `${currPeriod.id}:${arm.id}`
          }
          participants.push(participant)
        })

        return {
          ...milestone,
          participants
        }
      })

      currPeriod.drop_withdrawn_reason_list = currPeriod.drop_withdrawn_reason_list.map(reason => {
        let participants = [...reason.participants];
        uniqArms.forEach(arm => {
          let participant = {
            arm_id: arm.id,
            value: 0, comment: "",
            registry_arm_id: `${currPeriod.id}:${arm.id}`
          }
          participants.push(participant)
        })

        return {
          ...reason,
          participants
        }
      })
    }

    const addArmsToAllEudractPeriods = (tempData, newArms) => {
      tempData["eudract_periods"].forEach(period => {
        let uniqArms = [];
        newArms.forEach(arm => {
          if (period.arms.findIndex(newArm => newArm.id === arm.id) === -1) {
            uniqArms.push({
              registry_arm_id: `${period.id}:${arm.id}`
            });
          }
        })

        period.arms = [...period.arms, ...uniqArms]

        period.milestone_list = period.milestone_list.map(milestone => {
          let participants = [...milestone.participants];
          uniqArms.forEach(arm => {
            let participant = {
              arm_id: arm.id,
              value: 0,
              comment: "",
              registry_arm_id: `${period.id}:${arm.id}`
            }
            participants.push(participant)
          })

          return {
            ...milestone,
            participants
          }
        })

        period.drop_withdrawn_reason_list = period.drop_withdrawn_reason_list.map(reason => {
          let participants = [...reason.participants];
          uniqArms.forEach(arm => {
            let participant = {
              arm_id: arm.id,
              value: 0,
              comment: "",
              registry_arm_id: `${period.id}:${arm.id}`
            }
            participants.push(participant)
          })

          return {
            ...reason,
            participants
          }
        })
      })
    }

    const addArms = (newArms, applyAllPeriods) => {
      let tempData = { ...participantData };

      participantRegistries.forEach(registry => {
        if (applyAll || participantView === registry) {
          if (registry === "ctg_periods") {
            addArmsToCTG(tempData, newArms);
          } else if (applyAllPeriods) {
            addArmsToAllEudractPeriods(tempData, newArms)
          } else {
            addArmsToEudract(tempData, newArms);
          }
        }
      })

      setParticipantData({ ...tempData });
    }

    switch (selectedArmOption.id) {
      case "copy-all":
        let allProtocolArms = formData?.protocol?.study_arms?.arms || []
        addArms(allProtocolArms.map(arm => ({
          ...arm,
          id: "ParticipantFlow-ParticipantFlowGroup." + arm.id,
          registry_arm_id: `${period.id}:${"ParticipantFlow-ParticipantFlowGroup." + arm.id}`
        })))
        break;
      case "create-new":
        let id = uuid();
        let newArm = {
          id: "ParticipantFlow-ParticipantFlowGroup." + id,
          title: "",
          type: "",
          description: "",
          registry_arm_id: `${period.id}:${"ParticipantFlow-ParticipantFlowGroup." + id}`
        }

        addArms([newArm]);
        break;
      default:
        if (selectedArmOption.from === "protocol") {
          let foundArm = protocolArms.find(pa => selectedArmOption.id.includes(pa.id))
          if (foundArm) {
            addArms([{
              ...foundArm,
              id: `ParticipantFlow-ParticipantFlowGroup.${foundArm.id}`,
              registry_arm_id: `${period.id}:ParticipantFlow-ParticipantFlowGroup.${foundArm.id}`
            }]);
          }
        }
    }
    setSelectedArmOption(null);
    setShowDropdown(false);
  }

  const onOpenArmModal = (item) => {
    setShowAddArmModal(true);
    setSelectedArmOption(item);
  }

  const onAddProduct = ({ product }) => {
    let newProduct = {
      id: `product-${uuid()}`,
      ...product
    }

    let euPeriods = participantData.eudract_periods;

    let foundPeriodIndex = euPeriods.findIndex(item => item.id == period.id);
    if (foundPeriodIndex !== -1) {
      let euPeriod = euPeriods[foundPeriodIndex];
      let armIndex = euPeriod.arms.findIndex(euArm => euArm.id === selectedArm.id);
      if (armIndex !== -1 && euPeriod.arms[armIndex].products) {
        euPeriod.arms[armIndex].products = [...euPeriod.arms[armIndex].products, newProduct];
      } else {
        euPeriod.arms[armIndex].products = [newProduct]
      }
    }

    setParticipantData({
      ...participantData,
      eudract_periods: euPeriods
    })
  }

  const onUpdateProduct = ({ product }) => {
    let euPeriods = participantData.eudract_periods
    let foundPeriodIndex = euPeriods.findIndex(item => item.id == period.id);
    if (foundPeriodIndex !== -1) {
      let euPeriod = euPeriods[foundPeriodIndex];
      let armIndex = euPeriod.arms.findIndex(euArm => euArm.id === selectedArm.id);
      if (armIndex !== -1) {
        let foundProductIndex = euPeriod.arms[armIndex].products.findIndex(item => item.id === product.id);
        if (foundProductIndex !== -1) euPeriod.arms[armIndex].products[foundProductIndex] = product;
      }
    }

    setParticipantData({
      ...participantData,
      eudract_periods: euPeriods
    })
  }

  const onDeleteProduct = (arm, index) => {
    let euPeriods = [...participantData.eudract_periods];
    let foundPeriodIndex = euPeriods.findIndex(item => item.id == period.id);
    if (foundPeriodIndex !== -1) {
      let euPeriod = euPeriods[foundPeriodIndex];
      let armIndex = euPeriod.arms.findIndex(euArm => euArm.id === arm.id);
      if (armIndex !== -1 && euPeriod.arms[armIndex].products) {
        euPeriod.arms[armIndex].products.splice(index, 1)
      }
    }

    setParticipantData({
      ...participantData,
      eudract_periods: euPeriods
    })
  }

  const headerStyle = {
    width: 250 - 24,
    minWidth: 250 - 24
  }

  if (tableView === "ctg" && formView.id !== "global") {
    return (
      <tr>
        <td className="header-column" style={headerStyle}>
          <div>Arms/Groups</div>
        </td>
        {participantData?.global_arms?.map((arm, colIndex) => {
          return (
            <td key={`arm-title-${colIndex}-${arm.id}`}>
              {arm.title || "(Arm)"}
            </td>
          )
        })}
      </tr>
    )
  }

  let armsSchema = schema.properties.eudract.properties.arms.properties;
  let euPeriod = participantData?.eudract_periods?.find(item => item.id === period.id)

  const onOpenProduct = ({ arm, product }) => {
    setOpenProduct(true)
    setSelectedArm(arm)
    setSelectedProduct(product)
  }

  const onCloseProduct = () => {
    setOpenProduct(false)
    setSelectedArm(null)
    setSelectedProduct(null)
  }

  const onAddReason = ({ arm, armIndex }) => {
    let newReason = {
      id: `reason-${uuid()}`,
      reason: "",
      reason_detail: "",
      subjects: null
    }

    let tempData = { ...participantData };

    let currArm = tempData.eudract_periods[periodIndex].arms[armIndex];
    if (!currArm.joined_reason_details) currArm.joined_reason_details = [];

    currArm.joined_reason_details.push(newReason)

    setParticipantData(tempData);
  }

  const onChangeReason = (key, val, armIndex, reasonIndex) => {
    let tempData = { ...participantData };
    let currArm = tempData.eudract_periods[periodIndex].arms[armIndex];
    currArm.joined_reason_details[reasonIndex][key] = val
    setParticipantData(tempData);
  }

  const onDeleteReason = (armIndex, reasonIndex) => {
    let tempData = { ...participantData };
    let currArm = tempData.eudract_periods[periodIndex].arms[armIndex];
    currArm.joined_reason_details.splice(reasonIndex, 1)
    setParticipantData(tempData);
  }

  let sortedEuPeriodArms = null

  if (euPeriod) {
    sortedEuPeriodArms = euPeriod.arms;

    if (formView.id === "global") {
      sortedEuPeriodArms = participantData.global_arms.map(gArm => {
        let ogArmIds = gArm.id.split(".")
        let ogArmId = ogArmIds[ogArmIds.length - 1]
        let foundIndex = euPeriod.arms.filter(arm => arm).findIndex(pArm => pArm.id.includes(ogArmId))
        if (foundIndex !== -1) {
          return euPeriod.arms[foundIndex]
        } else {
          return null
        }
      })
    }
  }

  if (sortedEuPeriodArms !== null || sortedEuPeriodArms !== undefined) {
    return (
      <>
        {Object.keys(armsSchema).map((key, rowIndex) => {
          return (
            <tr key={`arm-tr-${rowIndex}-key${period.id}`}>
              <td className="header-column" style={headerStyle}>
                <div>{armsSchema[key].title}</div>
              </td>
              {sortedEuPeriodArms?.map((arm, colIndex) => {
                return (
                  <Arm
                    key={`global-arm-${period?.id}-${arm?.id}`}
                    colIndex={colIndex}
                    rowIndex={rowIndex}
                    arm={arm}
                    fieldKey={key}
                    arms={participantData?.global_arms || []}
                    schema={armsSchema[key]}
                    handleChange={handleChange}
                    handleLeft={() => handleLeft(colIndex)}
                    handleRight={() => handleRight(colIndex)}
                    handleDelete={() => handleDelete(colIndex, arm)}
                    onDeleteProduct={onDeleteProduct}
                    onOpenProduct={onOpenProduct}
                    onAddReason={onAddReason}
                    onChangeReason={onChangeReason}
                    onDeleteReason={onDeleteReason}
                    periodIndex={periodIndex}

                  // addReason={addReason}
                  />
                )
              })}
              {rowIndex === 0 && readOnly !== true && (
                <td
                  className='text-center add-arm-action-col'
                  rowSpan={5}>
                  {showDropdown
                    ? (
                      <PrimeField
                        schema={{
                          type: "dropdown",
                          items: participantArmOptions,
                          placeholder: "Select Arm/Group",
                          orientation: "vertical"
                        }}
                        readOnly={readOnly}
                        value={null}
                        onChange={e => {
                          let foundItem = participantArmOptions.find(item => item.id === e)
                          onOpenArmModal(foundItem)
                        }} />
                    )
                    : <i
                      onClick={() => setShowDropdown(true)}
                      className="fal fa-plus-circle add-arm-icon" />
                  }
                </td>
              )}
            </tr >
          )
        })}
        <ResultsTableCreateModal
          header="Add Arm"
          onSubmit={onHandleAddArm}
          showModal={showAddArmModal}
          setShowModal={() => setShowAddArmModal(false)} />
        <Modal
          title={selectedProduct ? "Edit product" : "Add product"}
          message={`Arm: ${selectedArm?.title}`}
          open={openProduct}
          onCancel={onCloseProduct}
          footer={[]}
          width={500}
          hideImage={true}>
          <Product
            participantData={participantData}
            setParticipantData={setParticipantData}
            schema={schema}
            selectedArm={selectedArm}
            selectedProduct={selectedProduct}
            onCloseProduct={onCloseProduct}
            onAddProduct={onAddProduct}
            onUpdateProduct={onUpdateProduct} />
        </Modal>
      </>
    )
  }

  return <></>
};

export default PeriodArms;