import { useState, useEffect, useMemo, cloneElement } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

// components
import Panel from 'containers/requests/components/Panel/Panel';
import PrimeField from 'components/PrimeField/PrimeField';
import { Dropdown, Divider, Space, theme } from 'antd'
import IconButton from 'containers/requests/components/IconButton/IconButton';
import TimelineComment from './subcomponents/TimelineComment/TimelineComment';
import TimelineEvent from './subcomponents/TimelineEvent/TimelineEvent';
import Modal from 'components/Modal/Modal';
import CommentForm from './subcomponents/CommentForm/CommentForm';

// apis
import { useDeleteTimelineComment } from 'api/hooks/studies/useTimelineFeedApi';
import { useDeleteActivityComment } from 'api/hooks/activities/useActivitiesApi'
import { useDeleteRequestFeedComment } from 'api/hooks/requests/useRequestTimelineApi';
import { useUserPermissions } from 'api/hooks';
import { useDownloadAttachment } from 'api/hooks/documents/useDocumentsApi'

// assets
import emptyFeedLogo from 'assets/images/empty-feed.png'

// styles
import './TimelineFeed.scss';

const actionTitleItems = [
  {
    type: "cancelled",
    text: "Activity cancelled by"
  }, {
    type: "created",
    text: "Activity created by"
  }, {
    type: "hold",
    text: "Activity placed on hold by"
  }, {
    type: "unhold",
    text: "Hold Removed by"
  }, {
    type: "progressed",
    text: "State progressed to"
  }, {
    type: "forced",
    text: "State forced to"
  }
]
const actionTitlesMap = new Map();

actionTitleItems.forEach(title => {
  actionTitlesMap.set(title.type, title.text)
})

const { useToken } = theme;

const TimelineFeed = ({
  type,
  timeline,
  timelineFeedParams,
  setTimelineFeedParams,
  modalMsg,
  refetchTimeline,
  panelClassName
}) => {
  const { studyId, activityId, requestId } = useParams()
  const [selectedLabels, setSelectedLabels] = useState({})
  const [selectedComment, setSelectedComment] = useState(null)
  const [openCommentForm, setOpenCommentForm] = useState(null)
  const [openDeleteComment, setOpenDeleteComment] = useState(null)
  const [showAllComments, setShowAllComments] = useState(false)
  const [selectedAttachment, setSelectedAttachment] = useState(null)

  const [filterType, setFilterType] = useState("all")
  const { token } = useToken();

  // study apis
  const deleteStudyComment = useDeleteTimelineComment(studyId)

  // comment apis
  const deleteActivityComment = useDeleteActivityComment(activityId)

  // request apis
  const deleteRequestComment = useDeleteRequestFeedComment(requestId)

  const { data: downloadAttachment } = useDownloadAttachment(selectedAttachment)
  const { data: permissions } = useUserPermissions()

  const canUserCreate = permissions?.["project.comment.create"] === true || permissions?.["study.comment.create"]

  const deleteComment = useMemo(() => {
    if (type === "study") return deleteStudyComment
    else if (type === "activity") return deleteActivityComment
    else if (type === "request") return deleteRequestComment
  }, [selectedComment, type])

  // getting tags on data for activity versus all tags
  const tags = useMemo(() => {
    if (timeline) {
      let timelineTags = []
      timeline.forEach((item) => {
        if (item.tags) {
          timelineTags = [...timelineTags, ...item.tags]
        }
      })
      return [...new Set(timelineTags)]
    }
  }, [timeline])

  useEffect(() => {
    getTimelineItems(false)

  }, [showAllComments, selectedLabels])

  useEffect(() => {
    if (downloadAttachment) {
      window.open(downloadAttachment, '_blank')
      setSelectedAttachment(null)
    }
  }, [downloadAttachment])

  const getTimelineItems = async (getMore) => {
    const timelineFeedParams = {
      labels: selectedLabels,
      [type === "study" ? "showActivity" : "showStudy"]: showAllComments
    }

    setTimelineFeedParams(timelineFeedParams)
  }

  const onOpenCommentForm = (comment = null) => {
    setSelectedComment(comment)
    setOpenCommentForm(true)
  }

  const onCloseCommentForm = () => {
    setSelectedComment(null)
    setOpenCommentForm(false)
  }

  const onOpenDeleteComment = (comment = null) => {
    setSelectedComment(comment)
    setOpenDeleteComment(true)
  }

  const onCloseDeleteComment = () => {
    setOpenDeleteComment(false)
    setSelectedComment(null)
  }

  const onDeleteComment = () => {
    deleteComment({ id: selectedComment.id + "/" }, {
      onSuccess: () => {
        refetchTimeline()
        onCloseDeleteComment()
      }
    })
  }

  const onChangeLabels = (val) => {
    let selectedLabels = {}
    for (let i = 0; i < val.length; i++) {
      let currItem = val[i]
      selectedLabels[currItem] = currItem
    }
    setSelectedLabels(selectedLabels)
  }

  const handleScroll = (e) => {
    let bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight;
    if (bottom) {
      getTimelineItems(true)
    }
  }

  const renderEmptyFeed = (
    <div
      className="empty-feed">
      <img
        className="empty_feed_icon"
        src={emptyFeedLogo}
        alt="empty feed container" />
      <p className="empty-feed-label">
        There's no activity, yet
      </p>
    </div>
  )

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    width: 150
  };

  const menuStyle = {
    boxShadow: 'none',
  };

  const actions = useMemo(() => {
    const buttons = []
    if (canUserCreate) {
      buttons.push(<IconButton
        key="add-comment-btn"
        variant='primary-dashed'
        iconClassName="fal fa-comment-alt-lines"
        onClick={() => onOpenCommentForm()}
      >
        Add comment
      </IconButton>)
    }

    const items = [
      {
        key: '1',
        label: (
          <a onClick={() => setFilterType("all")}>
            All
          </a>
        ),
      },
      {
        key: '2',
        label: (
          <a onClick={() => setFilterType("comments")}>
            Comments
          </a>
        ),
      },
      {
        key: '3',
        label: (
          <a onClick={() => {
            setFilterType("all")
            setShowAllComments((val) => !val)
          }}>
            {showAllComments ? "Hide" : "Show"} {type === "study" ? "Activity comments/events" : "Study comments"}
          </a>
        ),
      },
      {
        key: '4',
        label: (
          <a onClick={() => setFilterType("history")}>
            History
          </a>
        ),
      }
    ]

    buttons.push(
      <Dropdown
        trigger={"hover"}
        menu={{
          items: items.filter(option => {
            if(type === "request" && option.key === "3") return false;
            return true
          })
        }}
        dropdownRender={(menu => {
          return (
            <div style={contentStyle} className="feed-panel-dropdown-menu">
              {cloneElement(menu, {
                style: menuStyle,
              })}
              <Divider
                style={{
                  margin: 0,
                }}
              />
              <Space
                style={{
                  width: "100%",
                  padding: 8,
                }}
              >
                <PrimeField
                  key="activity-tags"
                  schema={{
                    type: "multiselect",
                    id: "timeline-comments-labels-multiselect",
                    placeholder: "Tags",
                    items: tags || [],
                    onChange: (val) => onChangeLabels(val),
                    maxRows: 1,
                  }}
                  containerStyle={{ width: "100%" }}
                  className={"timeline-tags-select"}
                  allowClear
                  value={Object.keys(selectedLabels).map(key => key)}
                />
              </Space>
            </div>
          )
        })
        }
      >
        <div
          className="prime--btn btn--outlined btn--sm"
          size="sm"
          variant="outlined"
          style={{
            paddingBottom: 7.5,
            paddingTop: 8.5
          }}>
          <i
            className="far fa-ellipsis-v"
          />
        </div>
      </Dropdown>
    )

    return buttons
  }, [type, permissions, showAllComments, tags])

  return (
    <Panel
      title="FEED"
      iconClassName="fal fa-newspaper"
      actions={actions}
      className={`panel-col feed-panel ${panelClassName}`}
    >
      {timeline?.length > 0 ? (
        <div
          className="timeline-comments-content"
          onScroll={handleScroll}>
          {timeline.
            filter(timelineItem => {
              // if (type === "request") {
              if (filterType === "all") {
                return true
              } else if (filterType === "history" && timelineItem.item_type === "action") {
                return true
              } else if (filterType === "comments" && timelineItem.item_type === "comment") {
                return true
              }
              return false
              // }

              // return true
            }).
            filter((timelineItem) => {
              if (Object.keys(selectedLabels).length === 0) return true;
              if (timelineItem?.tags?.length > 0) {
                for (let i = 0; i < timelineItem.tags.length; i++) {
                  if (selectedLabels[timelineItem.tags[i]]) return true
                }
              }
              return false
            })
            .map((timelineItem) => {
              const isComment = timelineItem.item_type === 'comment' ? true : false
              return isComment ? (
                <TimelineComment
                  key={timelineItem.id + '-comment'}
                  comment={timelineItem}
                  onOpenCommentForm={onOpenCommentForm}
                  onOpenDeleteComment={onOpenDeleteComment}
                  setSelectedAttachment={setSelectedAttachment}
                />
              ) : (
                <TimelineEvent
                  key={timelineItem.id + '-event'}
                  actionTitlesMap={actionTitlesMap}
                  event={timelineItem}
                />
              )
            })}
        </div>
      ) : (
        renderEmptyFeed
      )}
      <Modal
        title={selectedComment ? "Edit Comment" : "New Comment"}
        message={modalMsg}
        open={openCommentForm}
        onCancel={onCloseCommentForm}
        hideImage={true}
        width={520}
        footer={[]}
      >
        <CommentForm
          timelineFeedParams={timelineFeedParams}
          type={type}
          selectedComment={selectedComment}
          onCloseCommentForm={onCloseCommentForm}
          refetchTimeline={refetchTimeline}
        />
      </Modal>
      <Modal
        open={openDeleteComment}
        onCancel={onCloseDeleteComment}
        onOk={onDeleteComment}
        title={`Delete comment`}
        message={"This action can't be undone."}
        type="delete"
        width={520}>
        <TimelineComment
          readOnly={true}
          comment={selectedComment}
          style={{ marginTop: 20, marginRight: 0 }} />
      </Modal>
    </Panel>
  )
}

TimelineFeed.propTypes = {
  timeline: PropTypes.arrayOf(PropTypes.shape({
    tags: PropTypes.array, // arrayOf(PropTypes.string)
    author_fullname: PropTypes.string,
    id: PropTypes.number,
  }))
}

export default TimelineFeed