/** User API endpoints */
import useGetApi from 'api/hooks/useGetApi'
import getUrl from 'api/apiMap'

// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

const useGetUserReviewActionItems = (user_pk, options) => {
  return useGetApi(getUrl(`${apiEndpoints.users}${user_pk}/review-action-items/`), queryKeys.actionItems.userReviewItems, options)
}

// TODO: move useGetUsersList with other Dropdown API endpoints
const useGetUsersList = (options) => {
  return useGetApi(getUrl(apiEndpoints.usersList), queryKeys.users.list())
}

export {
  useGetUserReviewActionItems,

  // TODO: move:
  useGetUsersList,
}