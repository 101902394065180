import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useShallow } from 'zustand/react/shallow'

// components
import PreviewWrapper from '../hoc/PreviewWrapper';

// hooks
import useAuthoringViewStore from '../../../../hooks/stores/useAuthoringViewStore';

const Preview = (props) => {
  const [value, setValue] = useState("");
  const {
    carbonProps
  } = props;

  const {
    forkedFieldsSnakeCase,
    overrideDataSnakeCase,
  } = props.formContext

  const {
    formView,
    showPagePreview,
  } = useAuthoringViewStore(
    useShallow(state => ({
      formView: state.formView,
      showPagePreview: state.showPagePreview,
    })),
  )

  useEffect(() => {
    let jpath = props.id
    if (props.id.indexOf("root_") === 0) jpath = props.id.slice(5)

    if (typeof props?.value === "boolean") {
      if (props.value === true) setValue("Yes")
      else setValue("No")
    } else if (overrideDataSnakeCase?.[jpath] || overrideDataSnakeCase?.[props.id]) {
      setValue(overrideDataSnakeCase?.[jpath] || overrideDataSnakeCase?.[props.id])
    } else if (forkedFieldsSnakeCase?.[formView.id]?.[jpath] || forkedFieldsSnakeCase?.[formView.id]?.[props.id]) {
      setValue(forkedFieldsSnakeCase[formView.id][jpath] || forkedFieldsSnakeCase?.[formView.id]?.[props.id]);
    } else if (props.value) {
      if (Array.isArray(props.value)) {
        let value = props?.value.filter(item => !item !== null && !item !== undefined)?.join(", ");
        setValue(value);
      } else if (typeof props.value === 'object' && props.value !== null) {

      } else {
        setValue(props.value);
      }
    }
  }, [props.value, showPagePreview, overrideDataSnakeCase, forkedFieldsSnakeCase])

  return (
    <Container
      className="rj-widget-container"
      style={carbonProps?.styles?.widgetContainer}>
      <TitleContainer
        className="rj-widget-title"
        style={carbonProps?.styles?.widgetTitle}>
        {carbonProps?.label || ""}
      </TitleContainer>
      <ValueContainer
        className="rj-widget-value"
        style={carbonProps?.styles?.widgetValue}>
        {value || "N/A"}
      </ValueContainer>
    </Container>
  );
};

export default PreviewWrapper(Preview);

const Container = styled.div`
  display: block;
`

const TitleContainer = styled.div`
  white-space: pre;
`

const ValueContainer = styled.div`

`