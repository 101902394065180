import { useState, useEffect, useMemo, useRef } from 'react';
import { useShallow } from 'zustand/react/shallow'
import styled from 'styled-components';
import { debounce, filter } from 'lodash';
import { useSearchParams } from 'react-router-dom'

// components
import CommentFilterTag from 'components/CommentFilterTag/CommentFilterTag';
import './CommentsPanel.scss'
import CommentItem from '../CommentItem/CommentItem';

// api hooks
import { useGetCommentTypes } from 'api/hooks/admin/useCommentTypesApi';
import { useGetAuthoringComments } from 'api/hooks/studies/useAuthoringApi';
import { useGetAuthoringReviewMetadata } from 'api/hooks/studies/useAuthoringReviewApi';
import useAuthoringViewStore from '../../hooks/stores/useAuthoringViewStore';
import useAuthoringStore from '../../hooks/stores/useAuthoringStore';

let defaultItems = [
  {
    title: "Resolved",
    active: false,
  },
]

const CommentsPanel = () => {
  const authoringId = useAuthoringStore(state => state.authoringId)

  const {
    isReviewerView,
    formView,
    formSection
  } = useAuthoringViewStore(
    useShallow(state => ({ 
      isReviewerView: state.isReviewerView,
      formView: state.formView,
      formSection: state.formSection,
    }))
  )

  const { data: comments = [] } = useGetAuthoringComments(authoringId)
  const { data: commentTypes } = useGetCommentTypes()
  const [searchParams, setSearchParams] = useSearchParams()
  const [filterItems, setFilterItems] = useState([]);
  const [visibleComments, setVisibleComments] = useState([])
  const [numOfCommentsAbove, setNumCommentsAbove] = useState(0);
  const [numOfCommentsBelow, setNumCommentsBelow] = useState(0);

  const commentsMapRef = useRef([])
  const orderedCommentsRef = useRef([])
  const setVisibleCommentsRef = useRef()
  const setNumCommentsAboveRef = useRef()
  const setNumCommentsBelowRef = useRef()
  const isElVisibleRef = useRef()
  const projId = useMemo(() => {
    return searchParams.get('proj-id')
  }, [])

  const taskId = useMemo(() => {
    return searchParams.get('task-id')
  }, [])
  const { data: authReviewData } = useGetAuthoringReviewMetadata(isReviewerView === true ? authoringId : null, isReviewerView === true ? { "project-id": projId, "actionitem-id": taskId } : {})

  const sortByDomPosition = (arr) => {
    return arr.sort((a, b) => {
      let aField = document.getElementById(a.field_key)
      let bField = document.getElementById(b.field_key)
      if (aField && bField) {
        if (aField.compareDocumentPosition(bField) & Node.DOCUMENT_POSITION_FOLLOWING) {
          return -1
        } else {
          return 1
        }
      } else if (aField) {
        return -1
      } else if (bField) {
        return -1
      }

      return 0;
    })
  }

  useEffect(() => {
    let containerClassName = "right-section"
    if (isReviewerView) containerClassName = "form-review"
    const container = document.getElementsByClassName(containerClassName)[0]
    setVisibleCommentsRef.current = setVisibleComments
    setNumCommentsAboveRef.current = setNumCommentsAbove
    setNumCommentsBelowRef.current = setNumCommentsBelow
    isElVisibleRef.current = isElVisible
    container.addEventListener("scroll", isInViewportDebounce)

    return () => {
      container.removeEventListener("scroll", isInViewportDebounce)
    }
  }, [formView?.id, formSection?.id])

  useEffect(() => {
    if (commentTypes) {
      let newItems = [...defaultItems, ...commentTypes.map(item => {
        return {
          id: item.id,
          title: item.name,
          active: false
        }
      })].filter((type) => {
        if (authReviewData && isReviewerView && commentTypes) {
          const foundIndex = authReviewData?.comment_types?.findIndex(id => type.id === id)
          if (foundIndex !== -1) {
            return true
          }
          return false
        }
        return true
      })

      setFilterItems(newItems)
    }

    return () => {
      setFilterItems([])
    }
  }, [commentTypes, formSection, authReviewData])

  const isInViewport = () => {
    let visibleComments = []

    if (commentsMapRef.current) {
      Object.keys(commentsMapRef.current).forEach(id => {
        if (isElVisibleRef.current(commentsMapRef.current[id].field_key) === true) {
          visibleComments.push(commentsMapRef.current[id])
        }
      })
    }

    visibleComments = sortByDomPosition(visibleComments)

    if (visibleComments.length > 0) {
      const numAbove = getNumOfCommentsAbove(visibleComments?.[0], orderedCommentsRef.current)
      const numBelow = getNumOfCommentsBelow(visibleComments?.[visibleComments.length - 1], orderedCommentsRef.current)

      setNumCommentsAboveRef.current(numAbove)
      setNumCommentsBelowRef.current(numBelow)

    } else {
      let first = getFirstVisibileElement()
      if (first) {
        let target = {
          id: "target",
          field_key: first.id
        }

        let newArr = [...orderedCommentsRef.current, target]
        newArr = sortByDomPosition(newArr)

        const numAbove = getNumOfCommentsAbove(target, newArr)
        const numBelow = getNumOfCommentsBelow(target, newArr)

        setNumCommentsAboveRef.current(numAbove)
        setNumCommentsBelowRef.current(numBelow)
      }
    }

    sortBySystemDateIfSameFieldKey(visibleComments)

    setVisibleCommentsRef.current(visibleComments)
  }
  let isInViewportDebounce = debounce(isInViewport, 200);

  const sortBySystemDateIfSameFieldKey = (arr) => {
    // Create an object to store items with the same "field_key"
    const fieldKeyMap = {};

    // Iterate through the array and group items by "field_key"
    arr.forEach((item) => {
      const fieldKey = item.field_key;
      if (!fieldKeyMap[fieldKey]) {
        fieldKeyMap[fieldKey] = [];
      }
      fieldKeyMap[fieldKey].push(item);
    });

    // Sort each group by "system_date" in descending order (recent dates first)
    for (const fieldKey in fieldKeyMap) {
      if (fieldKeyMap.hasOwnProperty(fieldKey)) {
        fieldKeyMap[fieldKey].sort((a, b) =>
          b.system_date.localeCompare(a.system_date)
        );
      }
    }

    // Rebuild the sorted array
    const sortedArray = [];
    for (const fieldKey in fieldKeyMap) {
      if (fieldKeyMap.hasOwnProperty(fieldKey)) {
        sortedArray.push(...fieldKeyMap[fieldKey]);
      }
    }

    // Update the original array in place
    arr.length = 0;
    arr.push(...sortedArray);
  }

  useEffect(() => {
    let ret = [];
    let isFilterActive = filterItems.some(filter => filter.active);

    if (!isFilterActive) {
      ret = comments.filter(comment => comment.resolved === false);
    } else {
      comments.forEach(comment => {
        const tagIsActive = filterItems.find((item) => {
          return item.title === comment?.tags?.[0] && item.active;
        });
        const resolvedIsActive = filterItems.find((item) => item.title === 'Resolved' && item.active);
        const itemIsResolved = comment.resolved;
        if (tagIsActive ||
          (itemIsResolved && resolvedIsActive)
        ) {
          ret.push(comment);
        }
      })
    }

    ret.sort((a, b) => (a.favorite === b.favorite) ? 0 : a.favorite ? -1 : 1);

    // sorts by index of element in dom
    ret = sortByDomPosition(ret)

    let finalRet = ret.filter(comment => {
      if (formSection.id === "results" && (
        comment.field_key.includes("ParticipantFlowGroup") ||
        comment.field_key.includes("BaselineReportingGroup") ||
        comment.field_key.includes("OutcomeRptGroup") ||
        comment.field_key.includes("ReportedEvents")
      )) return true
      return document.getElementById(comment.field_key);
    })

    if (isReviewerView) {
      finalRet = finalRet.filter(comment => {
        let isTypeIncluded = comment.tags.some(tag => {
          const found = filterItems.findIndex(type => type.title === tag) !== -1
          return found
        })
        return isTypeIncluded
      })
    }

    let map = {}

    finalRet.forEach(comment => {
      map[comment.id] = comment
    })

    orderedCommentsRef.current = finalRet
    commentsMapRef.current = map

    if (isElVisibleRef.current) isInViewportDebounce()
  }, [formView, formSection, filterItems, comments, isElVisibleRef.current])

  const onFilterChange = (i) => {
    const items = Array.from(filterItems);
    const { active } = items[i];
    items[i] = { ...items[i], active: !active };
    setFilterItems(items);
  }

  const getFirstVisibileElement = () => {
    let first;
    let firstOffY;

    let className = '.section-container .rjs-object-inner-container > *'
    if (formSection.id === "results") className = '.section-container > table > td > *'
    let allVisible = Array.from(document.querySelectorAll(className)).filter(elem => {
      return isElVisibleRef.current(null, elem)
    })

    for (const elem of allVisible) {

      const offY = elem.getBoundingClientRect().top + document.documentElement.scrollTop
      if (first == null || offY < firstOffY) {
        first = elem;
        firstOffY = offY;
      }
    }

    return first
  }

  const getNumOfCommentsAbove = (target, comments) => {
    if (target) {
      let foundIndex = comments.findIndex(comment => target.id === comment.id)
      if (foundIndex !== -1) {
        return foundIndex
      }
    }

    return 0
  }

  const getNumOfCommentsBelow = (target, comments) => {
    if (target) {
      let foundIndex = comments.findIndex(comment => target.id === comment.id)
      if (foundIndex !== -1) {
        return (comments.length - (foundIndex + 1))
      }
    }

    return 0
  }

  const isElVisible = (id, elem = null) => {
    let el = document.getElementById(id);

    let rect = elem ? elem.getBoundingClientRect() : el?.getBoundingClientRect()

    let windowHeight = (window?.innerHeight || document?.documentElement?.clientHeight);
    let isVisible = false
    if (rect) {
      isVisible = !(
        Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100)) < 1 ||
        Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < 1
      );
    }

    return isVisible
  }

  return (
    <div className={`comments-panel ${isReviewerView ? "comments-panel-reviewer " : ""}`}>
      {isReviewerView !== true &&
        <div className="filter-container">
          {filterItems.map((item, i) => {
            return <CommentFilterTag
              key={item.title}
              index={i}
              onFilterChange={onFilterChange}
              {...item}
            />
          })}
        </div>
      }
      <div className="comments-container">
        {numOfCommentsAbove !== 0 && <CommentOrderText><i className="fal fa-chevron-up" style={{ marginRight: 12 }}></i> {numOfCommentsAbove} Comments Above</CommentOrderText>}
        {visibleComments.map((comment, i) => {
          return (
            <CommentItem
              key={"authoring-comment-" + comment.id}
              {...comment} />
          )
        })}
        {numOfCommentsBelow !== 0 && <CommentOrderText><i className="fal fa-chevron-down" style={{ marginRight: 15 }}></i>{numOfCommentsBelow} Comments Below</CommentOrderText>}
      </div>
    </div>
  );
};

export default CommentsPanel

const CommentOrderText = styled.div`
  padding-left: 21px;
  font-size: 11px;
  letter-spacing: normal;
  color: #969A9F;  
`