/* Code taken from https://github.com/adobe/pdf-embed-api-samples/blob/master/More%20Samples/React%20Samples/src/samples/ViewSDKClient.js and modified */
// TODO: convert into funtional component and move to utilities or to /pdf folder
class AdobeEmbedSdkClient {
  constructor(clientId) {
    this.clientId = clientId
    this.readyPromise = new Promise((resolve) => {
      if (window.AdobeDC) {
        resolve()
      } else {
        /* Wait for Adobe Document Services PDF Embed API to be ready */
        document.addEventListener("adobe_dc_view_sdk.ready", () => {
          resolve()
        })
      }
    })
    this.adobeDCView = undefined
  }

  ready() {
    return this.readyPromise
  }

  registerProfile({
    name,
    firstName,
    lastName,
    email,
  }) {
    const profile = {
      userProfile: {
        name,
        firstName,
        lastName,
        email,
      }
    }
    this.adobeDCView.registerCallback(
      window.AdobeDC.View.Enum.CallbackType.GET_USER_PROFILE_API,
      () => {
        return new Promise((resolve, reject) => {
          resolve({
            code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
            data: profile
          })
        })
      }, {})
  }

  previewFile(
    divId,
    {
      url,
      fileName,
      fileId,
      showCommentsPanel = false,
    },
    viewerConfig,
  ) {
    const config = {
      /* Pass registered client id */
      clientId: this.clientId,
      /* locale code */
      // locale: "es-ES",
    }
    if (divId) { /* Optional only for Light Box embed mode */
      /* Pass the div id in which PDF should be rendered */
      config.divId = divId
    }
    /* Initialize the AdobeDC View object */
    this.adobeDCView = new window.AdobeDC.View(config)

    /* Invoke the file preview API on Adobe DC View object */
    const previewFilePromise = this.adobeDCView.previewFile({
      /* Information on how to access the file */
      content: {
        /* Location of file where it is hosted */
        location: {
          url,
          /*
          If the file URL requires some additional headers, then it can be passed as follows:-
          headers: [
            {
              key: "<HEADER_KEY>",
              value: "<HEADER_VALUE>",
            }
          ]
          */
        },
      },
      /* Pass meta data of file */
      metaData: {
        fileName: fileName ? fileName : " ",
        id: fileId,
      }
    }, viewerConfig)

    return previewFilePromise // must return adove viewer
      .then((adobeViewer) => {
        adobeViewer.getAnnotationManager()
          .then((annotationManager) => {
            // set annotationManager class member
            this.annotationManager = annotationManager

            if (showCommentsPanel) {
              annotationManager.setConfig({ showCommentsPanel })
                // .then(() => console.log('openCommentPanel SUCCESS'))
                .catch(error => console.log('openCommentPanel', error))
            }
          })
        return adobeViewer
      })
  }

  previewFileUsingFilePromise(divId, filePromise, fileName, viewerConfig = {}) {
    /* Initialize the AdobeDC View object */
    this.adobeDCView = new window.AdobeDC.View({
      /* Pass your registered client id */
      clientId: this.clientId,
      /* Pass the div id in which PDF should be rendered */
      divId,
    })

    /* Invoke the file preview API on Adobe DC View object */
    this.adobeDCView.previewFile({
      /* Pass information on how to access the file */
      content: {
        /* pass file promise which resolve to arrayBuffer */
        promise: filePromise,
      },
      /* Pass meta data of file */
      metaData: {
        fileName,
        id: "0d8f6de3-d3f3-4097-93c9-11fd08f48c59", // random required ID value
      }
    }, viewerConfig)
  }

  // automatically resolves to avoid "Saving..." message showing up on UI
  registerMockAutoSaveApi(callback) {
    const saveOptions = {
      autoSaveFrequency: 1, // default 0
      enableFocusPolling: true, // default false
      showSaveButton: false, // default true
    }

    this.adobeDCView.registerCallback(
      window.AdobeDC.View.Enum.CallbackType.SAVE_API,
      function (metaData, content, options) {
        return new Promise((resolve, reject) => {
          const resolveFunc = () => {
            resolve({
              code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
              data: {
                /* Updated file metadata after successful save operation */
                metaData,
              }
            })
          }
          resolveFunc()
          // callback(resolveFunc) // save resolve to be called later
        })
      },
      saveOptions
    )
  }

  registerEventsHandler() {
    /* Register the callback to receive the events */
    this.adobeDCView.registerCallback(
      /* Type of call back */
      window.AdobeDC.View.Enum.CallbackType.EVENT_LISTENER,
      /* call back function */
      (event) => {
        console.log(event)
      },
      /* options to control the callback execution */
      {
        /* Enable PDF analytics events on user interaction. */
        enablePDFAnalytics: true,
      }
    )
  }

  /**
   * Triggered when:
   * • comment/reply clicked on comments panel is clicked
   * • after annotation on PDF clicked, triggers ANNOTATION_SELECTED event
   * • after an annotation is added to UI, triggers event
   */
  registerSelectedEvent(callback) {
    this.annotationManager.registerEventListener(
      callback,
      {
        listenOn: [
          "ANNOTATION_SELECTED",
          // "ANNOTATION_CLICKED", // annotation on PDF clicked
        ]
      }
    )
  }

  registerAnnotEvent(callback) {
    const eventOptions = {
      // If no event is passed in listenOn, then all the annotation events will be received.
      listenOn: [
        "ANNOTATION_ADDED",
        "ANNOTATION_DELETED",
        "ANNOTATION_UPDATED",
      ]
    }
    this.annotationManager.registerEventListener(
      callback,
      eventOptions
    )
  }

  registerAppRenderingDone(callback) {
    const eventOptions = {
      listenOn: [
        window.AdobeDC.View.Enum.Events.APP_RENDERING_DONE,
      ],
      enableFilePreviewEvents: true,
    }
    this.adobeDCView.registerCallback(
      window.AdobeDC.View.Enum.CallbackType.EVENT_LISTENER,
      callback,
      eventOptions
    )
  }
}

export default AdobeEmbedSdkClient
