import React, { useState } from 'react';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';
import { useShallow } from 'zustand/react/shallow'

// components
import ArmActions from '../../../components/ArmActions/ArmActions';
import PrimeField from 'components/PrimeField/PrimeField';

// context
import useResultsTableArmsStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableArmsStore';
import useResultsTableViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableViewStore';
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';

// helpers
import { moveLeftHelper, moveRightHelper } from '../../helpers/helpers';

const Sas = ({
  sasArr,
  sas,
  schema,
  fieldKey,
  rowIndex,
  colIndex,
  baselineData,
  setBaselineData,
}) => {
  const { ref: visibleRef, inView: inViewport } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  });

  const [showActions, setShowActions] = useState(false);

  const {
    readOnly
  } = useAuthoringViewStore(
    useShallow(state => ({ readOnly: state.readOnly }))
  )

  const {
    baselineSas,
    setBaselineSas
  } = useResultsTableArmsStore()

  const {
    tableView
  } = useResultsTableViewStore()

  const onChange = (e, arm) => {
    let tempData = { ...baselineData }

    const updateParticipants = (registry, key, group) => {
      tempData[registry].baseline_measures.forEach(bm => {
        bm.rows.forEach(row => {
          let catIndex = row.categories.findIndex(item => item.id === "num-analyzed");
          if (catIndex !== -1) {
            let colIndex = row.categories[catIndex].values.findIndex(cat => cat.arm_id === group.id);
            if (colIndex !== -1) {
              if (row.categories[catIndex].values[colIndex].participants === group.baseline_analysis_population) {
                row.categories[catIndex].values[colIndex].participants = e;
              }
            }
          }
        })
      })
    }

    if (fieldKey === "baseline_analysis_population") {
      Object.keys(baselineData).forEach(registry => {
        if (registry === "ctg") {
          let armIndex = tempData[registry].arms.findIndex(item => item.id === arm.id);
          if (armIndex !== -1) {
            updateParticipants(registry, "arms", arm);
            tempData[registry].arms[armIndex][fieldKey] = e;
          }
        } else if (registry === "eudract") {
          let armIndex = tempData[registry].arms.findIndex(item => item.id === arm.id);
          if (armIndex !== -1) {
            updateParticipants(registry, "arms", arm);
            tempData[registry].arms[armIndex][fieldKey] = e;
          } else {
            armIndex = tempData[registry]?.subject_analysis_sets?.findIndex(item => item.id === arm.id);
            if (armIndex !== -1) {
              updateParticipants(registry, "subject_analysis_sets", arm);
              tempData[registry].subject_analysis_sets[armIndex][fieldKey] = e;
            }
          }
        }
      })
    } else {
      Object.keys(baselineData).forEach(registry => {
        if (registry === "ctg") {
          let armIndex = tempData[registry].arms.findIndex(item => item.id === arm.id);
          if (armIndex !== -1) {
            tempData[registry].arms[armIndex][fieldKey] = e
          }
        } else if (registry === "eudract") {
          let setIndex = tempData[registry]?.subject_analysis_sets?.findIndex(item => item.id === arm.id);
          if (setIndex !== -1) {
            tempData[registry].subject_analysis_sets[setIndex][fieldKey] = e
          }
        }
      })
    }

    let tempBaselineSas = [...baselineSas]
    let foundSasIndex = tempBaselineSas.findIndex(sas => sas.id === "arm.id")
    if (foundSasIndex !== -1) {
      tempBaselineSas[foundSasIndex][fieldKey] = e;
      setBaselineSas(tempBaselineSas)
    }

    setBaselineData(tempData);
  }

  const handleLeft = () => {
    let tempData = { ...baselineData };
    let foundSetIndex = tempData[tableView]?.subject_analysis_sets?.findIndex(item => item.id === sas.id);
    if (foundSetIndex !== -1 && foundSetIndex !== 0) {
      moveLeftHelper(tempData, tableView, "subject_analysis_sets", foundSetIndex)
    }

    // move baseline measure values
    tempData[tableView].baseline_measures.forEach(bm => {
      bm.rows.forEach(row => {
        row.categories.forEach(category => {
          let tempValues = [...category.values];
          let foundIndex = tempValues.findIndex(item => item.arm_id === sas.id);

          if (foundIndex !== -1 && foundIndex !== 0) {
            let saved = tempValues[foundIndex - 1];
            tempValues[foundIndex - 1] = tempValues[foundIndex];
            tempValues[foundIndex] = saved;
            category.values = [...tempValues];
          }
        })
      })
    })


    setBaselineData(tempData);
  }

  const handleRight = () => {
    let tempData = { ...baselineData };
    // tempData[tableView].subject_analysis_sets
    let foundSetIndex = tempData[tableView]?.subject_analysis_sets?.findIndex(item => item.id === sas.id);
    if (foundSetIndex !== -1 && foundSetIndex !== tempData[tableView].arms.length - 1) {
      moveRightHelper(tempData, tableView, "subject_analysis_sets", foundSetIndex)
    }

    // move baseline measure values
    tempData[tableView].baseline_measures.forEach(bm => {
      bm.rows.forEach(row => {
        row.categories.forEach(category => {
          let tempValues = [...category.values];
          let foundIndex = category.values.findIndex(item => item.arm_id === sas.id);

          if (foundIndex !== -1 && foundIndex !== category.values.length - 1) {
            let saved = tempValues[foundIndex + 1];
            tempValues[foundIndex + 1] = tempValues[foundIndex];
            tempValues[foundIndex] = saved;
            category.values = [...tempValues];
          }
        })
      })
    })

    setBaselineData(tempData);
  }

  const handleDelete = (sas, deleteAll) => {
    let tempData = { ...baselineData };
    Object.keys(tempData).forEach(registry => {
      if (deleteAll === true || registry === tableView) {
        // delete sas
        let foundIndex = tempData[registry]?.arms?.findIndex(item => item.id === sas.id);
        if (foundIndex !== -1 && foundIndex !== undefined) tempData[registry].arms.splice(foundIndex, 1);

        if (registry === "eudract") {
          foundIndex = tempData[registry]?.subject_analysis_sets?.findIndex(item => item.id === sas.id);
          if (foundIndex !== -1 && foundIndex !== undefined) tempData[registry].subject_analysis_sets.splice(foundIndex, 1);
        }

        // delete baseline measure values
        tempData[registry]?.baseline_measures?.forEach(bm => {
          bm.rows.forEach(row => {
            row.categories.forEach(category => {
              let tempValues = [...category.values];
              let foundIndex = category.values.findIndex(item => item.arm_id === sas.id);

              if (foundIndex !== -1) {
                tempValues.splice(foundIndex, 1)
                category.values = [...tempValues];
              }
            })
          })
        })
      }
    });

    setBaselineData(tempData);
  }

  let isManualOverride = fieldKey === "title" || fieldKey === "description"

  return (
    <td
      ref={visibleRef}
      className="text-left subject-analysis-set"
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}>
      {inViewport ? (
        <>
          <PrimeField
            schema={{
              type: schema.type,
              placeholder: schema.title,
              items: schema.enum,
              style: { width: "100%" }
            }}
            disabled={isManualOverride}
            readOnly={readOnly}
            value={sas[fieldKey] || ""}
            onChange={e => onChange(e, sas)} />
          {readOnly !== true &&
            <ArmActions
              arms={sasArr}
              arm={sas}
              handleLeft={rowIndex === 0 ? handleLeft : null}
              handleRight={rowIndex === 0 ? handleRight : null}
              canDelete={rowIndex === 0 ? true : false}
              handleDeleteArm={(sas, deleteAll) => handleDelete(sas, deleteAll)}
              colIndex={colIndex}
              showActions={showActions}
              jpath={`bc-sas-${sas.id}-${fieldKey}`}
              source={"baseline-characteristics"}
              manualOverride={isManualOverride}
              type={"sas"} />
          }
        </>
      ) : (
        <div style={{ padding: 10 }} />
      )}
    </td>
  );
};

export default Sas;

const TD = styled.td`
  position: relative;
  border: 1px solid #8d8d8d;
  padding: 10px;
  vertical-align: top;
  transition: all .4s;
  box-sizing: border-box;
  background-color: ${props => props.styles.colors.subjectAnalysis};
`


const ActionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  opacity: ${props => props.showActions ? "100%" : "0%"};
  transition: all .3s;
`

const ReorderContainer = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ArrowIcon = styled.i`
  color: #18669E;
  cursor: pointer;
  transition: all .5s;
  font-size: 12px;

  &:hover {
    font-weight: 800;
  }
`

const TrashIcon = styled.i`
  font-size: 12px;
  color: #D7542C;
  cursor: pointer;
  transition: all .3s;

  &:hover {
    font-weight: 800;
  }
`