import React, { useState, useEffect, useMemo, useRef } from "react"
import './useFileLoader.scss'

const useFileLoader = ({
  onLoad,
  onDrag,
  onStopDrag,
}) => {
  const [isDragging, setIsDragging] = useState(false)
  const [fileLoaded, setFileLoaded] = useState(null)
  const dropAreaRef = useRef(null)
  const fileInputRef = useRef(null)

  // adds file to document data to prepare for saving
  useEffect(() => {
    if (fileLoaded && onLoad) {
      onLoad(fileLoaded)
    }
  }, [fileLoaded])

  const handleClickBrowse = () => {
    const inputFile = fileInputRef?.current?.files[0]
    if (inputFile) setFileLoaded(inputFile);
  }

  const handleDropFile = (evt) => {
    const file = evt?.dataTransfer?.files[0]
    if (file) setFileLoaded(file);
  }

  // when dragging files to file upload area
  useEffect(() => {
    if (dropAreaRef.current) {
      const dropArea = dropAreaRef.current

      // ...prevent default behaviors  and stop events from bubbling up
      const preventDefaults = (e) => {
        e.preventDefault()
        e.stopPropagation()
      }
      (['dragenter', 'dragover', 'dragleave', 'drop']).forEach(eventName => {
        dropArea.addEventListener(eventName, preventDefaults, false)
      })

      // set dragging state to indicate file being dragged into area using styles
      const setDraggingTrue = () => {
        setIsDragging(true)
        if (onDrag) onDrag();
      }
      const setDraggingFalse = () => {
        setIsDragging(false)
        if (onStopDrag) onStopDrag();
      }

      ['dragenter', 'dragover'].forEach(eventName => {
        dropArea.addEventListener(eventName, setDraggingTrue, false)
      });
      ['dragleave', 'drop'].forEach(eventName => {
        dropArea.addEventListener(eventName, setDraggingFalse, false)
      })

      // handle dropping of files
      dropArea.addEventListener('drop', handleDropFile, false)

      return () => {
        ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
          dropArea.removeEventListener(eventName, preventDefaults, false)
        });
        ['dragenter', 'dragover'].forEach(eventName => {
          dropArea.removeEventListener(eventName, setDraggingTrue, false)
        });
        ['dragleave', 'drop'].forEach(eventName => {
          dropArea.removeEventListener(eventName, setDraggingFalse, false)
        })
        dropArea.removeEventListener('drop', handleDropFile, false)
      }
    }
  }, [dropAreaRef])

  // Adds ref needed to listen to drag/drop events and adds "dragging" and "dropped" classes to element
  const getFileLoadAreaProps = (areaProps) => {
    let classNames = [`file-load-area${isDragging ? ' dragging' : fileLoaded ? ' dropped' : ''}`]
    if (areaProps?.classNames) {
      classNames = [...classNames, areaProps.classNames.join(" ")]
    }
    return {
      className: classNames.join(" "),
      ref: dropAreaRef,
    }
  }

  // inline HOC for anything that needs to be a link to browse for a file
  // ...currently can only use one FileBrowseLink on a page, because there is only one ref created. To have more need to create dynamic ref's
  const FileBrowseLink = useMemo(() => {
    const randomNum = Math.floor(Math.random() * 100)
    const LinkComponent = ({ className, children }) => {
      return <>
        <label
          htmlFor={`file-browse-link-ref-${randomNum}`}
          className={`file-browse-link${className ? ` ${className}` : ''}`}
        >{children}</label>
        <input
          type="file"
          id={`file-browse-link-ref-${randomNum}`}
          ref={fileInputRef}
          className="file-browse-link__input"
          style={{ display: 'none' }}
          onChange={handleClickBrowse}
        />{/* accept="application/pdf" */}
      </>
    }
    return LinkComponent
  }, [])

  return {
    getFileLoadAreaProps,
    FileBrowseLink,
  }
}

export default useFileLoader