import { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie'
import Tag from 'components/Tag/Tag';

// components
import PrimeField from 'components/PrimeField/PrimeField';

// apis
import { useGetTags } from 'api/hooks/enum';

// styles
import './TagsField.scss'

const csrfCookie = Cookies.get('csrftoken');

const TagsField = (props) => {
  const {
    tags = [],
    setTags,
    disabled = false
  } = props;
  const [currTag, setCurrTag] = useState("")
  // const [tagOptions, setTagOptions] = useState([])

  const { data: tagOptions = [] } = useGetTags({ query: currTag })


  const onKeyDown = (evt) => {
    if (evt.keyCode === 13) {
      if (!checkDups(currTag) && currTag) {
        setTags([...tags, currTag])
      }
      setCurrTag("")
    }
  }

  const handleSearch = val => {
    setCurrTag(val)

    // axios.get("/api/tag-search",
    //   {
    //     params: {
    //       query: val
    //     }
    //   },
    // ).then(res => {
    //   let { tags = [] } = res?.data?.data
    //   setTagOptions(tags.map(tag => ({
    //     key: tag,
    //     label: (
    //       <a onClick={() => onTagDropdown(tag)}>
    //         {tag}
    //       </a>
    //     ),
    //   })))
    // })
  }

  const onTagDropdown = (tag) => {
    if (!checkDups(currTag) && currTag) {
      setTags([...tags, currTag])
    }
    setCurrTag("")
  }

  const onRemoveTag = (tag) => {
    let foundIndex = tags.findIndex(item => tag === item);
    let temp = tags.slice()
    temp.splice(foundIndex, 1);
    setTags([...temp])
  }

  const checkDups = (tag) => {
    return tags.some(item => tag === item)
  }

  return (
    <div>
      <p className="comment-form-tags-label">
        Tags: Assigning labels allows for easier filtering and categorization of comments
      </p>
      {/* {tags?.length > 0 && (
        <div className="activity-comment-modal-tags">
          {tags.map(tag => {
            return (
              <Tag
                key={tag}
                text={tag}
                onDelete={onRemoveTag} />
            )
          })}
        </div>
      )} */}
      <div
        onKeyDown={onKeyDown}
        style={{ position: 'relative' }}>
        <PrimeField
          schema={{
            type: "multiselect",
            mode: "tags",
            placeholder: "Type a label name here",
            items: tagOptions
          }}
          disabled={disabled}
          value={tags}
          onSearch={handleSearch}
          onChange={setTags} />
      </div>
    </div>
  );
};

export default TagsField;