import React from 'react'
import { Tooltip } from 'antd'

const Languages = (props) => {
  let temp = [];
  let tooltipVals = [];
  if (props?.value) {
    temp = [...props.value];
    tooltipVals = [...props.value]
  }

  return (
    <div style={{
      display: "flex",
      gap: 6,
      justifyContent: "start",
      alignItems: 'center'
    }}>
      {temp?.[0]}
      {tooltipVals?.length > 1 && (
        <Tooltip
          title={tooltipVals.slice(1).map(item => {
            return <div style={{ textAlign: 'left' }}>
              {item}
            </div>
          })}
        >
          <i
            className="fas fa-plus-circle"
            style={{ color: "#878C92" }} />
        </Tooltip>
      )}
    </div>
  );
};

export default Languages;