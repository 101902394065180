import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';

// components
import Label from 'components/Label/Label';

const RowTotal = (props) => {
  const [values, setValues] = useState(0)
  const [totalUnits, setTotalUnits] = useState(0)
  let { category, hideTotal } = props;
  // const { baselineData } = useContext(BCContext);

  const {
    measure,
    baselineData
  } = props

  const isCountUnits = measure?.parameter_type === "Count of Units"

  useEffect(() => {
    if (category?.values) {
      let values = category?.values?.map((val) => {
        let key = "value"
        if (isNaN(Number(val?.[key]))) return 0
        if (val[key]) return Number(val[key]);
        else if (val[key]) return Number(val[key]);
        return 0
      })

      let totalUnits = category?.values?.map((val) => {
        let key = "num_units_analyzed"
        if (isNaN(Number(val?.[key]))) return 0
        if (val[key]) return Number(val[key]);
        else if (val[key]) return Number(val[key]);
        return 0
      })

      setTotalUnits(totalUnits.reduce((acc, val) => acc + Number(val), 0));
      setValues(values.reduce((acc, val) => acc + Number(val), 0));
    }
  }, [baselineData])

  if (hideTotal) {
    return (
      <td className="total-col text-right">
        <Label>-</Label>
      </td>
    )
  }

  let label = "Total Participants: "
  if (isCountUnits) label = "Total units: "

  if (category.id === "num-analyzed" && isCountUnits) {
    return (
      <td className="total-col text-right">
        {isCountUnits && <Label style={{ marginBottom: ".3rem", width: "100%" }}>Total Participants:  {values}</Label>}
        <Label style={{ marginBottom: ".3rem", width: "100%" }}>{label} {totalUnits}</Label>
      </td>
    );
  }

  return (
    <td className="total-col text-right">
      <Label style={{ marginBottom: ".3rem", width: "100%" }}>{label} {values}</Label>
    </td>
  );
};

export default RowTotal;

const td = styled.td`
  min-width: 100px;
  position: relative;
  border: 1px solid #8d8d8d;
  padding: 10px;
  vertical-align: top;
  transition: all .4s;
  box-sizing: border-box;
`