import React, { useEffect, useState, memo } from 'react';
import styled, { css } from 'styled-components';

// animations
import { rotate } from '../../animations/keyframes';

// helpers
import { capitalizeFirstLetter } from '../../utilities/misc';

const PRSUploadStatus = (props) => {
  const [iconName, setIconName] = useState("");
  const [status, setStatus] = useState("Not Yet Started");

  useEffect(() => {
    if (props.data) {
      if (props.data.is_cancelled === true) {
        setStatus("Upload Cancelled");
      } else if (props.data.is_processing === true) {
        setStatus("Uploading");
      } else if (props.data.is_queued === true) {
        setStatus("Not Yet Started");
      } else if (props.data.upload_failed === true) {
        setStatus("Failed");
      } else if (props.data.upload_failed === false) {
        let { uploadStatus } = props.data.upload_report;
        if (uploadStatus?.[0] === "CHANGED_STUDY") {
          setStatus("Changed study")
        } else if (uploadStatus?.[1] === "UNCHANGED_STUDY") {
          setStatus("Changed study")
        } else if (uploadStatus === "CHANGED_STUDY") {
          setStatus("Changed study")
        } else if (uploadStatus === "UNCHANGED_STUDY") {
          setStatus("Changed study")
        } else {
          setStatus("Uploaded")
        }
      } else if (props.data && props.data.upload_report && props.data.upload_report.uploadStatus) {
        let { uploadStatus } = props.data.upload_report;
        if (uploadStatus && typeof uploadStatus === "string") {
          let title = uploadStatus.split("_").map(word => word.toLowerCase()).map(word => capitalizeFirstLetter(word)).join(" ");
          setStatus(title);
        } else {
          setStatus("Failed")
        }
      }
    }
  }, [props.data]);

  useEffect(() => {
    switch (status) {
      case "Uploading":
        setIconName("far fa-redo");
        break;
      case "Not Yet Started":
        setIconName("fas fa-clock");
        break;
      case "Failed":
        setIconName("fas fa-minus-circle");
        break;
      case "Error":
        setIconName("fas fa-minus-circle");
        break;
      case "Upload Cancelled":
        setIconName("far fa-ban");
        break;
      default:
        setIconName("fas fa-check-circle");
    }
  }, [status])

  return (
    <Container>
      <Icon variant={status} className={iconName} />
      {status}
    </Container>
  );
};

export default memo(PRSUploadStatus);

const animationMixin = css`
  animation:  ${rotate} 2s linear infinite;
`

const getColor = (variant) => {
  switch (variant) {
    case "Not Yet Started": return "#60656C";
    case "Uploading": return "#18669E";
    case "Failed": return "#D7542C";
    case "Error": return "#D7542C";
    case "Upload Cancelled": return "#D7542C";
    default: return "#8AB25B"
  }
}

const Container = styled.div`

`

const Icon = styled.i`
  margin-right: 12px;
  color: ${props => getColor(props.variant)};
  ${props => props.variant === "Uploading" ? animationMixin : `animation: none;`}
`