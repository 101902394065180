import { useMemo, useState } from 'react'
import { Button } from 'components-design-system'
import ReactCountryFlag from "react-country-flag"
import EditCountriesModal from '../EditCountriesModal/EditCountriesModal'
import './CountriesPanel.scss'

const CountriesPanel = ({
  permissions,
  countries,
  actualCountries,
  plannedCountries,
  studyId,
}) => {
  const [showModal, setShowModal] = useState(false)
  const [modalVariant, setModalVariant] = useState('actual')

  const countriesMap = useMemo(() => {
    const map = {}
    countries.forEach(country => {
      map[country.cca2] = country
    })
    return map
  })

  const toggleModal = (variant) => {
    setShowModal((state) => !state)
    setModalVariant(variant)
  }

  if (!actualCountries || !plannedCountries) {
    return <div>Loading...</div>
  }

  return <div className="countries-panel">
    <div className="countries-panel-head">
      <p className="countries-panel-title">Countries</p>
      <div className="countries-panel-actions">
        {permissions?.["study.edit"] === true &&
          <Button
            size="sm"
            onClick={() => toggleModal("planned")}
            variant="primary"
            className="edit-planned-btn"
          >
            Edit Planned Countries
          </Button>
        }
        {permissions?.["study.edit"] === true &&
          <Button
            size="sm"
            onClick={() => toggleModal("actual")}
            variant="primary">
            Edit Actual Countries
          </Button>
        }
      </div>
    </div>
    <div className="countries-panel-content">
      <div className="countries-panel-col">
        <div className="countries-panel-col-head">
          <p className="countries-panel-label">Planned</p>
        </div>
        <div className="flags-container">
          {plannedCountries
            ?.filter((item) => item)
            .map((code) => {
              let country = countriesMap?.[code]

              return (
                <div
                  key={`countries-panel-${code}`}
                  className="countries-panel-flag-wrap"
                >
                  <ReactCountryFlag
                    key={country}
                    style={{ width: '2em', height: '2em' }}
                    svg
                    countryCode={code}
                    className="country-flag"
                  />
                  <p className="countries-panel-label">
                    {country?.name?.common}
                  </p>
                </div>
              )
            })}
        </div>
      </div>
      <div className="countries-panel-col">
        <div className="countries-panel-col-head">
          <p className="countries-panel-label">Actual</p>
        </div>
        <div className="flags-container">
          {actualCountries?.filter(item => item).map(code => {
            let country = countriesMap?.[code]
            return (
              <div
                key={`countries-panel-${code}`}
                className="countries-panel-flag-wrap"
              >
                <ReactCountryFlag
                  style={{ width: '2em', height: '2em' }}
                  svg
                  countryCode={code}
                />
                <p className="countries-panel-label">
                  {country?.name?.common}
                </p>
              </div>
            )
          })}
        </div>
      </div>
    </div>
    {permissions?.["study.edit"] === true &&
      <EditCountriesModal
        showModal={showModal}
        onHide={toggleModal}
        variant={modalVariant} // TODO: evaluate if necessary
        countryCodes={modalVariant === 'planned' ? plannedCountries : actualCountries}
        countries={countries}
        studyId={studyId}
      // data={data}
      // studyDataApi={studyDataApi}
      // updateStudyData={updateStudyData}
      />
    }
  </div>
}

export default CountriesPanel