import { useState, useEffect, useMemo } from 'react';
import { useShallow } from 'zustand/react/shallow'

// components
import PrimeField from 'components/PrimeField/PrimeField';
import RJTableCommentContainer from '../../../components/RJTableCommentContainer/RJTableCommentContainer';
import CellValidation from '../../../components/CellValidation/CellValidation';

// context
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';
import useResultsTableViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableViewStore';
import useAuthoringCommentsStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringCommentsStore';

const CTGArmTotal = ({
  type,
  schema,
  adverseData,
  setAdverseData
}) => {
  const {
    tableView
  } = useResultsTableViewStore()

  const {
    readOnly,
    formView
  } = useAuthoringViewStore(
    useShallow(state => ({ 
      readOnly: state.readOnly,
      formView: state.formView
    }))
  )

  let fieldSchema = schema.properties[tableView]?.[type].properties;
  let groupSchema = schema.properties[tableView].groups.properties;

  const onChangeCTGTotal = (e, fieldKey, group) => {
    let tempData = { ...adverseData }

    const updateNumAtRisk = (registry, foundGroupIndex) => {
      let arrayKey = type.replace("_group_total", "s");
      tempData[registry]?.[arrayKey]?.forEach(event => {
        let foundValueIndex = event.values.findIndex(item => item.registry_arm_id === group.registry_arm_id);
        if (foundValueIndex !== -1) {
          let value = event.values[foundValueIndex];

          if (value["num_subjects"] === tempData[registry].groups[foundGroupIndex][fieldKey] ||
            (!value["num_subjects"] && !tempData[registry].groups[foundGroupIndex][fieldKey])) {
            value.num_subjects = e
          }
        }
      })
    }

    Object.keys(tempData).forEach(registry => {
      let foundGroupIndex = tempData[registry].groups.findIndex(item => item.registry_arm_id === group.registry_arm_id);
      if (foundGroupIndex !== -1 && schema.properties[registry].groups.properties[fieldKey]) {

        if (type !== "all_cause_mortality_group_total" &&
          (fieldKey === "part_at_risk_serious_events" || fieldKey === "part_at_risk_frequent_events")) updateNumAtRisk(registry, foundGroupIndex)
        tempData[registry].groups[foundGroupIndex][fieldKey] = e;
      }
    })

    setAdverseData(tempData)
  }

  const displayEudractTotalDeaths = () => {
    const handleChange = (val, key, groupId) => {
      let tempData = { ...adverseData }

      let foundIndex = tempData["eudract"].groups.findIndex(group => group.registry_arm_id === groupId)
      if (foundIndex !== -1) {
        tempData["eudract"].groups[foundIndex][key] = val;
      }

      setAdverseData(tempData)
    }

    return (
      <tr
        key={`group-total-global-eu-field`}>
        <td
          className="subject-analysis-set"
          style={{ fontWeight: "bold", maxWidth: 250 }}
        >
          Total number of deaths resulting from adverse events
        </td>
        {adverseData?.[tableView]?.groups.map((group, i) => {
          let disabled = true
          let foundItem = adverseData["eudract"].groups.find(euGroup => euGroup.registry_arm_id === group.registry_arm_id)
          if (foundItem) {
            disabled = false
          }

          return (
            <td
              key={`group-total-global-eu-field-${i}`}
              className="subject-analysis-set">
              <PrimeField
                schema={{
                  label: "Participants",
                  orientation: "vertical",
                  disabled,
                  type: "number",
                }}
                readOnly={readOnly}
                value={foundItem?.deaths_resulting_from_adverse_events}
                onChange={e => handleChange(e, "deaths_resulting_from_adverse_events", group.registry_arm_id)} />
              <CellValidation
                table="adverse"
                errorKey={`results_adverse_events_adverse_events_table_${tableView}_groups_${i + 1}_deaths_resulting_from_adverse_events`} />
            </td>
          )
        })}
      </tr>
    )
  }

  return (
    <>
      {Object.keys(fieldSchema).map(key => {
        return (
          <tr
            key={"group-total-" + key}
            className="subject-analysis-set">
            <td
              style={{ fontWeight: "bold", maxWidth: 250 }}>
              {fieldSchema[key].title}
            </td>
            {adverseData?.[tableView]?.groups?.map((group, colIndex) => {
              return (
                <ArmCell
                  key={`ae-group-totals-${type}-${group.id}-${key}`}
                  group={group}
                  fieldKey={key}
                  groupSchema={groupSchema}
                  onChangeCTGTotal={onChangeCTGTotal}
                  type={type}
                  colIndex={colIndex} />
              )
            })}
          </tr>
        )
      })}
      {formView.id === "global" && type === "all_cause_mortality_group_total" && displayEudractTotalDeaths()}
    </>
  )
};

export default CTGArmTotal;

const ArmCell = ({
  group,
  groupSchema,
  fieldKey,
  onChangeCTGTotal,
  type,
  colIndex
}) => {
  const [openCommentsForm, setOpenCommentsForm] = useState(false)
  const [showActions, setShowActions] = useState(false)

  const {
    tableView
  } = useResultsTableViewStore()

  const {
    commentsMap
  } = useAuthoringCommentsStore(
    useShallow(state => ({
      commentsMap: state.commentsMap
    }))
  )

  const {
    readOnly,
    formView
  } = useAuthoringViewStore(
    useShallow(state => ({ 
      readOnly: state.readOnly,
      formView: state.formView
    }))
  )

  const commentStyle = {
    position: "absolute",
    right: 8,
    top: 8
  }

  let jpath = `ReportedEvents.ae-group-totals-${type}-${group.id}-${fieldKey}`

  return (
    <td
      key={`group-total-${fieldKey}-${group.registry_arm_id}`}
      style={{ position: "relative" }}
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}>
      <PrimeField
        schema={{
          label: groupSchema[fieldKey].title,
          orientation: "vertical",
          type: groupSchema[fieldKey].type,
          items: groupSchema[fieldKey].enum,
        }}
        readOnly={readOnly}
        value={group[fieldKey]}
        onChange={e => onChangeCTGTotal(e, fieldKey, group)} />
      <CellValidation
        table="adverse"
        errorKey={`results_adverse_events_adverse_events_table_${tableView}_groups_${colIndex + 1}_${fieldKey}`} />
      <RJTableCommentContainer
        label={groupSchema[fieldKey].title}
        jpath={jpath}
        openCommentsForm={openCommentsForm}
        setOpenCommentsForm={setOpenCommentsForm}
        setShowMenu={setShowActions}
        showMenu={showActions || commentsMap[jpath] || openCommentsForm}
        style={commentStyle}
        modalStyle={{ top: 24 }} />
    </td>
  )
}