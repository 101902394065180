import React from 'react';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const RichTextEditor = ({
  value,
  setValue,
  isReviewerView,
  id
}) => {
  return (
    <Container>
      <ReactQuill 
        id={id}
        modules={RichTextEditor.modules}
        formats={RichTextEditor.formats}
        style={{
          height: '80%',
          fontSize: 12
        }}
        theme="snow" 
        value={value}
        onChange={setValue}/>
    </Container>
  );
};

export default RichTextEditor;

RichTextEditor.modules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{'list': 'ordered'}, {'list': 'bullet'}]
  ],
  clipboard: {
    matchVisual: false,
  }
}

RichTextEditor.formats = [
  'bold', 'italic', 'underline',
  'list', 'bullet',
]

const Container = styled.div`
  height: 100%;

  .ql-editor {
    white-space: normal;

    p {
      font-size: .85rem;
      white-space: normal;
    }
  }
`