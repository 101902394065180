import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { Tooltip } from 'antd'
import { useShallow } from 'zustand/react/shallow'

// components
import ConfirmDeleteArrayItem from '../components/ConfirmDeleteArrayItem/ConfirmDeleteArrayItem';
import ResultsTableCreateModal from '../components/ResultsTableCreateModal/ResultsTableCreateModal';

import { getJpathArray, getForkedJpathArray } from '../../utils';

// constants
import { formSchemas } from 'constants/authoring';

// omSchema
import { outcomeMeasuresDataSchema } from '../widgets/RJOutcomeMeasures/schemas/dataSchema'

// context
import useAuthoringViewStore from '../../hooks/stores/useAuthoringViewStore';
import useAuthoringDataStore from '../../hooks/stores/useAuthoringDataStore';

// styles
import './ArrayFieldItem.scss';

const ArrayFieldItem = ({
  element,
  classNames,
  arrayItemTitle,
  canCopy,
  showIndex,
  required,
  canCopyOutcomeMeasure,
  isDisabled,
  formContext,
  setFieldLoading
}) => {
  const {
    formData,
    setFormData,
  } = useAuthoringDataStore(
    useShallow(state => ({
      formData: state.formData,
      setFormData: state.setFormData,
    }))
  )

  const {
    readOnly,
    formView,
    formSection,
    refreshFormData,
    setRefreshFormData
  } = useAuthoringViewStore(
    useShallow(state => ({
      readOnly: state.readOnly,
      formView: state.formView,
      formSection: state.formSection,
      refreshFormData: state.refreshFormData,
      setRefreshFormData: state.setRefreshFormData,
    }))
  )

  const [showMenu, setShowMenu] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showCreateMeasure, setShowCreateMeasure] = useState(false);
  const [selectedMeasure, setSelectedMeasure] = useState(null);
  const [view, setView] = useState("ctg");

  useEffect(() => {
    if (formView) {
      let view = formView.id;
      if (view === "global") view = "ctg";
      else if (view === "eudra") view = "eudract"
      setView(view)
    }
  }, [formView])

  const copyItem = () => {
    // setFieldLoading(true)
    const regKey = formView.id;
    const sectId = formSection.id;
    const uiSchema = formSchemas[sectId].properties[regKey].uiSchema;

    const uiSchemaJpathArray = getJpathArray(element.children.props.idSchema['$id'].replace("root_", ""), typeof uiSchema === "function" ? uiSchema() : uiSchema);

    let tempData = cloneDeep(formData);
    let formObj = tempData;

    uiSchemaJpathArray.forEach(level => {
      formObj = formObj[level]
    })
    let copyItem = cloneDeep(formObj[element.index]);

    formObj.push(copyItem);
    setFormData(tempData);
    setRefreshFormData(!refreshFormData)
  }

  const copyOutcomeMeasure = () => {
    setShowCreateMeasure(true)
    const regKey = formView.id;
    const sectId = formSection.id;
    const uiSchema = formSchemas[sectId].properties[regKey].uiSchema;

    const uiSchemaJpathArray = getJpathArray(element.children.props.idSchema['$id'].replace("root_", ""), typeof uiSchema === "function" ? uiSchema() : uiSchema);

    let tempData = cloneDeep(formData);
    let formObj = tempData;

    uiSchemaJpathArray.forEach(level => {
      formObj = formObj[level]
    })
    let copyItem = cloneDeep(formObj[element.index]);

    setSelectedMeasure(copyItem)
  }

  const handleAddOutcomeMeasure = (applyAll) => {
    let omId = `om-${uuidv4()}`;
    let tempData = { ...formData }

    let omData = tempData?.results?.outcome_measures;

    if (!omData?.outcome_measures_table) {
      omData.outcome_measures_table = {
        ctg: {
          arms: [],
          outcome_measures: []
        },
        eudract: {
          arms: [],
          subject_analysis_sets: [],
          outcome_measures: []
        }
      }
    }
    if (omData.outcome_measures_table) {
      Object.keys(omData.outcome_measures_table).forEach(registry => {
        if (applyAll === true || registry === view) {
          let outcomeMeasure = { id: omId };
          let omSchema = outcomeMeasuresDataSchema.properties.outcome_measures_table.properties[registry].properties.outcome_measure.properties;

          Object.keys(omSchema).forEach(key => {
            if (selectedMeasure[key] || key === "measure_type") {
              let selectedKey = key;
              let schemaKey = key;

              if (key === "measure_type") selectedKey = "type"

              outcomeMeasure[schemaKey] = selectedMeasure[selectedKey];
            } else {
              outcomeMeasure[key] = omSchema[key].value || "";
            }
          })

          omData.outcome_measures_table[registry].outcome_measures = [...omData.outcome_measures_table[registry].outcome_measures, outcomeMeasure];
        }
      })
      setFormData(tempData);
    }
  }

  return (
    <div
      className={`rjs-array-item-container ${classNames?.arrayItemContainer ? classNames.arrayItemContainer : ""}`}
      onMouseEnter={() => setShowMenu(true)}
      onMouseLeave={() => setShowMenu(false)}>
      <div className={`rjs-array-item-title ${classNames?.arrayItemTitle ? classNames.arrayItemTitle : ""}`}>
        {showIndex && (
          <span >
            {element.index + 1}.
          </span>
        )}
        {arrayItemTitle}
      </div>
      <div className={`rjs-item-container ${classNames?.actionContainer ? classNames.actionContainer : ""}`}>
        {React.Children.map(element.children, child => {
          // Checking isValidElement is the safe way and avoids a typescript
          // error too.
          if (React.isValidElement(child)) {
            return React.cloneElement(child, { required, disabled: isDisabled });
          }
          return child;
        })}
      </div>
      {readOnly !== true && showMenu &&
        <div className={`rjs-array-item-action-container ${classNames?.actionContainer ? classNames.actionContainer : ""}`}>
          {canCopy && isDisabled !== true &&
            <Tooltip
              title="Duplicate outcome measure"
            >
              <i
                onClick={() => copyItem()}
                style={{ marginRight: 16 }}
                className="fal fa-clone rjs-icon" />
            </Tooltip>
          }
          {canCopyOutcomeMeasure && isDisabled !== true &&
            <Tooltip
              title="Copy outcome measure to results section"
            >
              <i
                className="fal fa-copy rjs-icon"
                onClick={() => copyOutcomeMeasure()}
                style={{ marginRight: 36 }} />
            </Tooltip>
          }
          {element.hasMoveDown && isDisabled !== true && (
            <i
              className="fal fa-chevron-down rjs-icon"
              style={{ marginRight: 6, fontWeight: 500 }}
              onClick={element.onReorderClick(element.index, element.index + 1)} />
          )}
          {element.hasMoveUp && isDisabled !== true && (
            <i
              className="fal fa-chevron-up rjs-icon"
              style={{ fontWeight: 500 }}
              onClick={element.onReorderClick(element.index, element.index - 1)} />
          )}
          {element.hasRemove &&
            showMenu &&
            readOnly !== true &&
            isDisabled !== true && (
              <i
                className="rjs-delete rjs-icon fal fa-trash-alt animate__animated animate__fadeIn animate__faster"
                onClick={() => setOpenModal(true)} />
            )
          }
        </div>
      }
      <ConfirmDeleteArrayItem
        openModal={openModal}
        setOpenModal={setOpenModal}
        element={element}
      />
      <ResultsTableCreateModal
        id="outcome-measures"
        header="Copy Outcome Measure"
        view={view}
        formView={formView.id}
        onSubmit={handleAddOutcomeMeasure}
        showModal={showCreateMeasure}
        setShowModal={setShowCreateMeasure} />
    </div>
  )
};

export default ArrayFieldItem;