import React from 'react';
import { cloneDeep, uniq } from 'lodash';

// components
import Cell from './subcomponents/Cell/Cell';
import CellValidation from '../components/CellValidation/CellValidation';

const RJCrossReferenceTable = ({
  arms,
  interventions,
  tableData,
  setTableData,
  schema,
  studyType
}) => {
  React.useEffect(() => {
    let tempTableData = cloneDeep(tableData)
    const armsToDelete = []
    const interToDelete = []

    tempTableData?.arms?.forEach(arm => {
      let foundIndex = arms.findIndex(a => a.id === arm.id)
      if (foundIndex === -1) armsToDelete.push(arm.id)
    })
    tempTableData?.interventions?.forEach(intervention => {
      let foundIndex = interventions.findIndex(a => a.id === intervention.id)
      if (foundIndex === -1) interToDelete.push(intervention.id)
    })

    if (armsToDelete?.length > 0) {
      armsToDelete.forEach(armId => {
        let foundIndex = tempTableData?.arms?.findIndex(arm => arm.id === armId)
        if (foundIndex !== -1) {
          tempTableData.arms.splice(foundIndex, 1)
        }
      })
    }

    if (interToDelete?.length > 0) {
      interToDelete.forEach(interId => {
        let foundIndex = tempTableData?.interventions?.findIndex(intervention => intervention.id === interId)
        if (foundIndex !== -1) {
          tempTableData.interventions.splice(foundIndex, 1)
        }
      })
    }

    tempTableData?.arms?.forEach(arm => {
      arm.interventions = uniq(arm.interventions)

      for(let i = 0; i < arm.interventions.length; i++) {
        let interventionId = arm.interventions[i]

        let foundIndex = tempTableData?.interventions?.findIndex(intervention => intervention.id === interventionId)


        if(foundIndex === -1) {
          arm.interventions.splice(i, 1)
          i--
        }
      }
    })

    tempTableData?.interventions?.forEach(intervention => {
      intervention.arms = uniq(intervention.arms)
      
      for(let i = 0; i < intervention.arms.length; i++) {
        let armId = intervention.arms[i]

        let foundIndex = tempTableData?.arms?.findIndex(arm => arm.id === armId)


        if(foundIndex === -1) {
          intervention.arms.splice(i, 1)
          i--
        }
      }
    })

    setTableData(tempTableData)
  }, [arms?.length, interventions?.length])

  const onChange = (e, row, col) => {
    if (e === true) {
      let arms = [...tableData.arms];
      let foundIndex = arms.findIndex(arm => arm.id === row.id);
      if (foundIndex !== -1) {
        let arm = arms[foundIndex];
        arm.interventions.push(col.id)
        arms.splice(foundIndex, 1, arm);
      } else {
        arms.push({
          id: row.id,
          interventions: [col.id]
        })
      }

      let interventions = [...tableData.interventions];
      foundIndex = interventions.findIndex(intervention => intervention.id === col.id);
      if (foundIndex !== -1) {
        let intervention = interventions[foundIndex];
        intervention.arms.push(row.id)
        interventions.splice(foundIndex, 1, intervention);
      } else {
        interventions.push({
          id: col.id,
          arms: [row.id]
        })
      }
      setTableData({
        arms,
        interventions
      })
    } else {
      let arms = [...tableData.arms];
      let foundIndex = arms.findIndex(arm => arm.id === row.id);
      if (foundIndex !== -1) {
        let arm = arms[foundIndex];
        let popIndex = arm.interventions.findIndex(intervention => intervention === col.id);

        if (popIndex !== -1) {
          arm.interventions.splice(popIndex, 1);
          arms.splice(foundIndex, 1, arm);
        }
      }

      let interventions = [...tableData.interventions];
      foundIndex = interventions.findIndex(intervention => intervention.id === col.id);
      if (foundIndex !== -1) {
        let intervention = interventions[foundIndex];
        let popIndex = intervention.arms.findIndex(arm => arm === row.id);

        if (popIndex !== -1) {
          intervention.arms.splice(popIndex, 1);
          interventions.splice(foundIndex, 1, intervention);
        }
      }
      setTableData({
        arms,
        interventions
      })
    }
  }

  return (
    <div style={{ width: "100%" }}>
      <div className="rjs-h2">
        {schema.title}
      </div>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th />
            <th
              className="disabled-row text-bold"
              colSpan={interventions.length}>Interventions/Exposures</th>
          </tr>
        </thead>
        <tbody style={{ width: "100%" }}>
          <tr>
            <td className="disabled-row text-bold">
              {studyType === "Interventional" ? "Arms" : "Groups/Cohorts"}
            </td>
            {interventions.map((intervention) => {
              return (
                <td
                  key={intervention.id}
                  className="header-column">
                  {intervention.type ? intervention.type : "(Intervention Type)"}: {intervention.name ? intervention.name : "(Intervention Name)"}
                </td>
              )
            })}
          </tr>
          {arms.map((arm, armIndex) => {
            return (
              <tr key={arm.id}>
                <td
                  className="header-column">
                  <div>{arm.type ? arm.type : "Arm/Group Type"}: {arm.title ? arm.title : "Arm/Group Title"}</div>
                  <div>{arm.description}</div>
                </td>
                {interventions.map((intervention) => {
                  return (
                    <Cell
                      key={intervention.id + arm.id}
                      arm={arm}
                      intervention={intervention}
                      onChange={onChange} t
                      tableData={tableData}
                    />
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <CellValidation
        table={"crossref"}
        errorKey={`protocol_study_arms_crossref`} />
    </div>
  );
};

export default RJCrossReferenceTable;