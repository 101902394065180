// TODO: rename to StudyLocationsGrid
import React from 'react';
import { isObject } from 'lodash';
import dayjs from 'dayjs';

// components
import GridTypeA from 'components/grids/GridTypeA/GridTypeA'

// Cell Renderers
import EditSite from './CellRenderers/EditSite'
import SiteID from './CellRenderers/SiteID'
import Comparator from './CellRenderers/Comparator'
import AddressComparator from './CellRenderers/AddressComparator'
// import InternalStatus from './CellRenderers/InternalStatus' // TODO: delete component if not used
// import DateComparator from './CellRenderers/DateComparator' // TODO: delete component if not used
import PRSValidation from 'components/ag-grid-cell-renderers/LocationsPRSValidation/PRSValidation';
import ContactComparator from './CellRenderers/ContactComparator'
import InvestigatorComparator from './CellRenderers/InvestigatorComparator'

// Tool Panel 
import Presets from 'components/Grid/Presets/Presets';

// Excel Style
import { excelStyles } from 'components/Grid/ExcelStyles/excelStyles';

// styles
import './styles.css';
import 'styles/ag-theme-prime.scss';

const StudyDetailsGrid = ({
  // user,
  search,
  rowData,
  study,
  onOpenModal,
  triggerExport,
  setTriggerExport,
  permissions,
  onSelectionChanged,
  userDateFormat, // TODO: pass prop or delete
}) => {
  const [gridApi, setGridApi] = React.useState(null);

  React.useEffect(() => {
    if (gridApi) {
      if (search === '' || !search) {
        gridApi.setQuickFilter('')
      } else {
        gridApi.setQuickFilter(search)
        // deselect b/c user may not be able to see rows that they may edit/delete after filtering by search terms:
        gridApi.deselectAll()
      }
    }
  }, [search])

  React.useEffect(() => {
    if (triggerExport === true) {
      onBtnExport()
      setTriggerExport(false);
    }
  }, [triggerExport])

  const getFieldValue = (field, data, loc, headerName) => {
    if (field.includes("_prs")) {
      field = field.replace("_prs", "")
    }
    if (field === "name") {
      if (data?.location_data?.[loc]?.facility?.name) {
        return data.location_data[loc].facility.name;
      }
    } else if (field === "city" || field === "state" || field === "zip" || field === "country") {
      if (data?.location_data?.[loc]?.facility?.address?.[field]) {
        return data.location_data[loc].facility.address[field];
      }
    } else if (field === "contact" || field === "contact_backup") {
      if (data?.location_data?.[loc]?.[field]) {
        return formatContact(data.location_data[loc][field])
      }
    } else if (field === "investigator" || field === "sub_investigator") {
      if (data?.location_data?.[loc]?.investigator) {
        let title = "Principal Investigator";
        if (headerName.includes("Sub-Investigator")) title = "Sub-Investigator";
        let investigators = []
        if (!Array.isArray(data.location_data[loc].investigator)) investigators = [data.location_data[loc].investigator];
        else investigators = data.location_data[loc].investigator;
        let persons = investigators.filter(person => person?.role?.includes(title));
        let personsToText = persons.map(person => {
          return formatInvestigator(person)
        }).join(", ")
        return personsToText;
      }
    } else if (data?.location_data?.[loc]?.[field]) {
      return data.location_data[loc][field];
    }
    return null
  }

  const sourceValueGetter = (params) => {
    let { field, headerName } = params.colDef;
    let maskVal = getFieldValue(field, params.data, "mask", headerName);
    let sourceVal = getFieldValue(field, params.data, "source", headerName);
    if (maskVal !== null && maskVal !== undefined) return maskVal;
    if (sourceVal) return sourceVal;
    return null;
  }

  const ctgValueGetter = (params) => {
    let { field, headerName } = params.colDef;
    return getFieldValue(field, params.data, "ctg", headerName);
  }

  const getSourceCellClasses = {
    equalBackground: (params) => {
      let { field, headerName } = params.colDef;
      let maskName = getFieldValue(field, params.data, "mask", headerName);
      let sourceName = getFieldValue(field, params.data, "source", headerName);
      let ctgName = getFieldValue(field, params.data, "ctg", headerName);
      if (maskName !== undefined && maskName !== null) {
        return maskName === ctgName
      }

      return (sourceName && ctgName) && sourceName == ctgName;
      // return ((sourceName && ctgName) && sourceName == ctgName) || (((maskName !== null && maskName !== undefined) && ctgName) && ((maskName) == ctgName));
    },
    greyBackground: (params) => {
      let { field, headerName } = params.colDef;
      let maskName = getFieldValue(field, params.data, "mask", headerName);
      return (maskName !== null && maskName !== undefined) ? true : false;
    },
    greenBackground: (params) => {
      let { field, headerName } = params.colDef;
      let sourceName = getFieldValue(field, params.data, "source", headerName);

      return sourceName ? true : false;
    },
  }

  const getCTGCellClasses = {
    equalBackground: (params) => {
      let { field, headerName } = params.colDef;
      let maskName = getFieldValue(field, params.data, "mask", headerName);
      let sourceName = getFieldValue(field, params.data, "source", headerName);
      let ctgName = getFieldValue(field, params.data, "ctg", headerName);
      if (maskName !== undefined && maskName !== null) {
        return maskName === ctgName
      }
      return (sourceName && ctgName) && sourceName === ctgName;
    },
    redBackground: (params) => {
      let { field, headerName } = params.colDef;
      let ctgName = getFieldValue(field, params.data, "ctg", headerName);
      return ctgName ? true : false;
    },
  }

  const formatContact = ({
    first_name = "",
    middle_name = "",
    last_name = "",
    degrees = "",
    phone = "",
    phone_ext = "",
    email = "",
  }) => {
    let fullName = [first_name, middle_name, last_name].filter(item => item && item.length > 0).join(" ").trim();
    let fullNameDegree = [fullName, degrees].filter(item => item && item.length > 0).join(", ").trim();
    let phoneNumber = [phone, phone_ext].filter(item => item && item.toString().length > 0).join(" ext: ").trim();
    return [fullNameDegree, phoneNumber, email].filter(item => item && item.length > 0).join(", ");
  }

  const formatInvestigator = ({
    first_name = "",
    middle_name = "",
    last_name = "",
    degrees = "",
  }) => {
    let fullName = [first_name, middle_name, last_name].filter(item => item && item.length > 0).join(" ").trim();
    let fullNameDegree = [fullName, degrees].filter(item => item && item.length > 0).join(", ").trim();
    return fullNameDegree;
  }

  const columnDefs = [
    {
      headerName: "",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      menuTabs: [],
      resizable: false,
      width: 32,
    },
    {
      field: "site_id",
      headerName: "",
      cellRenderer: SiteID,
      cellClass: "simpleField",
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        if (nodeA?.data?.only_on_ctg) return 1;
        else if (nodeA?.data?.only_on_source) return -1;
        return 0
      },
      valueSetter: (params) => {
        let res = [];
        if (params.data) {
          if (params.data.is_new) res.push("New");
          if (params.data.only_on_source) res.push("No CTG");
          if (params.data.only_on_ctg) res.push("No source");
          if (params.data.recruitment_status_changed) res.push("Recruitment status changed");
          return res.join(", ");
        }
        return null;
      },
      valueGetter: params => {
        let res = [];
        if (params.data) {
          if (params.data.is_new) res.push("New");
          if (params.data.only_on_source) res.push("No CTG");
          if (params.data.only_on_ctg) res.push("No source");
          if (params.data.recruitment_status_changed) res.push("Recruitment status changed");
          return res.join(", ");
        }
        return null;
      },
      width: 150,
    },
    {
      headerName: "Edit",
      field: "edit",
      cellRenderer: EditSite,
      cellRendererParams: {
        studyData: study,
        onOpenModal,
        permissions
      },
      width: 70,
    },
    {
      headerName: "Site ID",
      field: "site_rowid",
      cellClass: "simpleField",
      valueGetter: params => {
        if (params?.data?.location_data?.source?.site_rowid) {
          return params.data.location_data.source.site_rowid
        } else if (params?.data?.site_id) {
          return params.data.site_id
        }
        return ""
      },
      width: 150,
    },
    {
      headerName: "Site Number",
      field: "location_data.source.site_number",
      cellClass: "simpleField"
    },
    {
      headerName: "Site Activation Date",
      field: "location_data.source.site_active_date",
      cellClass: "simpleField",
      type: "date",
    },
    {
      headerName: "Recruitment Status",
      field: "status",
      cellRenderer: Comparator,
      valueGetter: params => sourceValueGetter(params),
      cellClassRules: getSourceCellClasses,
      width: 150,
    },
    {
      headerName: "Recruitment Status (PRS)",
      field: "status_prs",
      cellRenderer: Comparator,
      hide: true,
      filter: false,
      suppressMenu: true,
      suppressColumnsToolPanel: true,
      valueGetter: params => ctgValueGetter(params),
      cellClassRules: getCTGCellClasses,
      width: 150,
    },
    {
      headerName: "PRS Validation",
      field: "validations",
      valueGetter: params => {
        if (params?.data?.validations) {
          let validations = params?.data?.validations;
          if (isObject(validations) && !Array.isArray(validations) && validations !== null) {
            validations = [validations];
          }

          let errors = [];
          let nonSiteErrors = [];
          let warnings = [];
          let notes = [];


          if (validations && validations.length > 0) {
            validations.forEach(item => {
              if (item.severity === "ERROR") {
                if (item.site_db_id) {
                  errors.push(item)
                } else {
                  nonSiteErrors.push(item)
                }
              };
              if (item.severity === "WARNING") warnings.push(item);
              if (item.severity === "NOTE") notes.push(item);
            })

            let items = [];
            if (errors.length > 0) items.push(errors.length + " error(s)");
            if (warnings.length > 0) items.push(warnings.length + " warning(s)");
            if (notes.length > 0) items.push(notes.length + " note(s)");
            if (nonSiteErrors.length > 0) items.push(nonSiteErrors.length + " non-site errors");

            if (items && items.length > 0) return items.join(", ");


            return null;
          }
        }
        return null
      },
      cellRenderer: PRSValidation,
      cellRendererParams: {
        source: "study-details",
        onOpenModal,
        studyData: study
      },
      filter: false
    },
    {
      headerName: "Facility Name",
      field: "name",
      cellRenderer: AddressComparator,
      valueGetter: params => sourceValueGetter(params),
      cellClassRules: getSourceCellClasses,
      width: 250,
    },
    {
      headerName: "Facility Name (PRS)",
      field: "name_prs",
      hide: true,
      filter: false,
      suppressMenu: true,
      suppressColumnsToolPanel: true,
      valueGetter: params => ctgValueGetter(params),
      cellClassRules: getCTGCellClasses,
      width: 250,
    },
    {
      headerName: "City",
      field: "city",
      cellRenderer: AddressComparator,
      valueGetter: params => sourceValueGetter(params),
      cellClassRules: getSourceCellClasses,
      width: 150,
    },
    {
      headerName: "City (PRS)",
      field: "city_prs",
      hide: true,
      filter: false,
      suppressMenu: true,
      suppressColumnsToolPanel: true,
      valueGetter: params => ctgValueGetter(params),
      cellClassRules: getCTGCellClasses,
      width: 150,
    },
    {
      headerName: "State/Province",
      field: "state",
      cellRenderer: AddressComparator,
      valueGetter: params => sourceValueGetter(params),
      cellClassRules: getSourceCellClasses,
    },
    {
      headerName: "State/Province (PRS)",
      field: "state_prs",
      hide: true,
      filter: false,
      suppressMenu: true,
      suppressColumnsToolPanel: true,
      valueGetter: params => ctgValueGetter(params),
      cellClassRules: getCTGCellClasses
    },
    {
      headerName: "Country",
      field: "country",
      cellRenderer: AddressComparator,
      valueGetter: params => sourceValueGetter(params),
      cellClassRules: getSourceCellClasses,
    },
    {
      headerName: "Country (PRS)",
      field: "country_prs",
      hide: true,
      filter: false,
      suppressMenu: true,
      suppressColumnsToolPanel: true,
      valueGetter: params => ctgValueGetter(params),
      cellClassRules: getCTGCellClasses
    },
    {
      headerName: "Postal Code",
      field: "zip",
      cellRenderer: AddressComparator,
      valueGetter: params => sourceValueGetter(params),
      cellClassRules: getSourceCellClasses,
    },
    {
      headerName: "Postal Code (PRS)",
      field: "zip_prs",
      hide: true,
      filter: false,
      suppressMenu: true,
      suppressColumnsToolPanel: true,
      valueGetter: params => ctgValueGetter(params),
      cellClassRules: getCTGCellClasses
    },
    {
      headerName: "Site Contact",
      field: "contact",
      cellRenderer: ContactComparator,
      valueGetter: params => sourceValueGetter(params),
      cellClassRules: getSourceCellClasses,
      width: 250,
    },
    {
      headerName: "Site Contact (PRS)",
      field: "contact_prs",
      hide: true,
      filter: false,
      suppressMenu: true,
      suppressColumnsToolPanel: true,
      valueGetter: params => ctgValueGetter(params),
      cellClassRules: getCTGCellClasses,
      width: 250,
    },
    {
      headerName: "Backup Site Contact",
      field: "contact_backup",
      cellRenderer: ContactComparator,
      valueGetter: params => sourceValueGetter(params),
      cellClassRules: getSourceCellClasses,
      width: 250,
    },
    {
      headerName: "Backup Site Contact (PRS)",
      field: "contact_backup_prs",
      hide: true,
      filter: false,
      suppressMenu: true,
      suppressColumnsToolPanel: true,
      valueGetter: params => ctgValueGetter(params),
      cellClassRules: getCTGCellClasses,
      width: 250,
    },
    {
      headerName: "Principal Investigator",
      field: "investigator",
      cellRenderer: InvestigatorComparator,
      valueGetter: params => sourceValueGetter(params),
      cellClassRules: getSourceCellClasses,
    },
    {
      headerName: "Principal Investigator (PRS)",
      field: "investigator_prs",
      hide: true,
      filter: false,
      suppressMenu: true,
      suppressColumnsToolPanel: true,
      valueGetter: params => ctgValueGetter(params),
      cellClassRules: getCTGCellClasses,
    },
    {
      headerName: "Sub-Investigator",
      field: "sub_investigator",
      cellRenderer: InvestigatorComparator,
      valueGetter: params => sourceValueGetter(params),
      cellClassRules: getSourceCellClasses,
    },
    {
      headerName: "Sub-Investigator (PRS)",
      field: "sub_investigator_prs",
      hide: true,
      filter: false,
      suppressMenu: true,
      suppressColumnsToolPanel: true,
      valueGetter: params => ctgValueGetter(params),
      cellClassRules: getCTGCellClasses,
    },
  ]

  const defaultColDef = {
    resizable: true,
    sortable: true,
    wrapText: true,
    filter: true,
    filterParams: {
      buttons: ['clear'],
    },
    enablePivot: true,
    enableCharts: false,
    enableRowGroup: false,
    cellStyle: { "whiteSpace": "pre-line" },
    autoHeight: true,
    cellClassRules: {
      oddBackground: function (params) {
        return params.rowIndex % 2 == 0;
      },
    },
  }

  const gridOptions = {
    animateRows: true,
    floatingFilter: false,
    columnTypes: {
      date: {
        filter: "agDateColumnFilter",
        filterParams: {
          resetButton: true,
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            var cellDate, dateAsString;
            dateAsString = cellValue;
            if (dateAsString === null) {
              return 0;
            }

            if (userDateFormat?.date_format_moment) {
              cellDate = dayjs(dateAsString, userDateFormat.date_format_moment).toDate();
            } else {
              cellDate = dayjs(dateAsString, "YYYY-MM-DD").toDate();
            }
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          },
        },
        comparator: (valueA, valueB) => {
          var cellDateA, cellDateB;
          if (valueA === null && valueB === null || valueA === '' && valueB === '') {
            return 0;
          } else if (valueA === null || valueA === '') {
            return -1;
          } else if (valueB === null || valueB === '') {
            return 1;
          }

          if (userDateFormat?.date_format_moment) {
            cellDateA = dayjs(valueA, userDateFormat.date_format_moment).toDate();
            cellDateB = dayjs(valueB, userDateFormat.date_format_moment).toDate();
          } else {
            cellDateA = dayjs(valueA).utc().toDate();
            cellDateB = dayjs(valueB).utc().toDate();
          }

          return cellDateA - cellDateB;
        }
      }
    },
    // TODO: review tools panel to see if needed
    sideBar: {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressRowGroups: false
          }
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'presets',
          labelDefault: "Presets",
          labelKey: 'list',
          iconKey: 'list',
          toolPanel: Presets,
          toolPanelParams: {
            gridName: "study-details-grid-" + study?.study_id
          }
        }
      ],
      defaultToolPanel: '',
    },
    excelStyles,
  }

  const isRowSelectable = React.useMemo(() => {
    return ({ data }) => {
      return data ? !data.only_on_ctg : false
    }
  }, [])

  const onGridReady = (params) => {
    setGridApi(params.api);
  }

  const onBtnExport = () => {
    const params = {
      fileName: study.study_id ? study.study_id.toLowerCase() + "_sites_data" : "study_sites_data",
      columnKeys: [
        "site_rowid",
        "location_data.source.site_number",
        "location_data.source.site_active_date",
        "status",
        "status_prs",
        "validations",
        "name",
        "name_prs",
        "city",
        "city_prs",
        "state",
        "state_prs",
        "country",
        "country_prs",
        "zip",
        "zip_prs",
        "contact",
        "contact_prs",
        "contact_backup",
        "contact_backup_prs",
        "investigator",
        "investigator_prs",
        "sub_investigator",
        "sub_investigator_prs"
      ],
      headerRowHeight: 25,
      rowHeight: 20,
    }
    gridApi.exportDataAsExcel(params)
  };

  return <GridTypeA
    rowData={rowData}
    columnDefs={columnDefs}
    gridOptions={gridOptions}
    defaultColDef={defaultColDef}
    onGridReady={onGridReady}
    rowSelection="multiple"
    onSelectionChanged={onSelectionChanged}
    isRowSelectable={isRowSelectable}
    suppressRowClickSelection={true}
    showRowCount
    style={{ height: 500 }}
  />
}

export default React.memo(StudyDetailsGrid)