/** Used as a react router parent with Outlet component child.
 *  Does not take any props, but can push global props, 
 *    ie, html title, to Outlet.
*/
import React from 'react'
import {
  useLocation,
  Outlet,
} from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import Sidebar from '../../../components/Sidebar/Sidebar'
import Topbar from '../../../components/Topbar/Topbar'
import './MainGlobalLayout.scss'

// constants
import navData from 'constants/pageData'

const MainGlobalLayout = () => {
  const { pathname } = useLocation()
  const [htmlTitle, setHtmlTitle] = React.useState()

  // set page title from page data

  const getPageItem = () => {
    let foundItem = null
    navData.forEach((item) => {
      if (item.url === pathname) foundItem = item;
      item.items?.forEach((subItem) => {
        if (subItem.url === pathname) foundItem = subItem
      })
    })
    return foundItem
  }

  React.useEffect(() => {
    const pageItem = getPageItem()
    setHtmlTitle(pageItem?.htmlTitle || pageItem?.title || pageItem?.name)
  }, [pathname])

  return <>
    <Helmet>
      {/* htmlTitle is not updating when navigating through routes. Passing HTML title to child thru route context. */}
      <title>{htmlTitle}</title>
    </Helmet>
    <div className="main-global-layout">
      <div className="left-section">
        <Sidebar />
      </div>
      <div
        className={`right-section ${pathname.startsWith('/my-prime') && 'right-section-my-prime'}`}>
        <div className={`top-section ${pathname === '/my-prime' && 'my-prime-topbar'}`}><Topbar /></div>
        <Outlet context={{ htmlTitle }} />
      </div>
    </div>
  </>
}

export default MainGlobalLayout