import React from 'react';
import { useShallow } from 'zustand/react/shallow'
import { formatTableValidations } from 'utilities/registryValidations';


// context
import useAppState from 'context/hooks/useAppState';
import useAuthoringValidationsStore from '../stores/useAuthoringValidationsStore';
import useAuthoringViewStore from '../stores/useAuthoringViewStore';

// apis
import { usePostRegistryValidations } from 'api/hooks/studies/useAuthoringApi';

const useAuthoringValidationActions = (authoringId) => {
  const { openToastNotification } = useAppState()
  const postRegistryValidations = usePostRegistryValidations(authoringId)

  const {
    setValidations,
    setRegistryValidations,
    setSitesValidations,
    setResultsTableValidations,
  } = useAuthoringValidationsStore(
    useShallow(state => ({
      setValidations: state.setValidations,
      setRegistryValidations: state.setRegistryValidations,
      setSitesValidations: state.setSitesValidations,
      setResultsTableValidations: state.setResultsTableValidations
    }))
  )

  const {
    formView,
  } = useAuthoringViewStore(
    useShallow(state => ({
      formView: state.formView,
    }))
  )

  const clearValidations = () => {
    setValidations(null)
    setSitesValidations([])
    setRegistryValidations(null)
    setResultsTableValidations(null)
  }

  // REGISTRY VALIDATIONS
  const onRegistryValidate = async () => {
    openToastNotification("info", "Loading", "Retrieving validations in progress")
    let form_view = formView.id === "global" ? "ctg" : formView.id;

    postRegistryValidations({ body: { form_view } }, {
      onSuccess: res => {
        let validations = []

        if (res?.data?.validations) validations = [...res.data.validations]

        // create lookup table for validation messages
        if (validations.length > 0) {
          let validationMsgs = null;
          res.data.validations.forEach((msgItem) => {
            if (!validationMsgs) validationMsgs = {};
            if (!validationMsgs[`root_${msgItem.field_key}`]) {
              validationMsgs[`root_${msgItem.field_key}`] = [];
            }
            validationMsgs[`root_${msgItem.field_key}`].push(msgItem);
          });
          if (validationMsgs) setRegistryValidations(validationMsgs);
        };


        if (res?.data) {
          setValidations(res.data)
          let tableValidations = formatTableValidations(res.data.validations)
          setResultsTableValidations(tableValidations)
        }

        // validation messages that cannot be mapped to fields for side panel "Validations" tab
        if (res?.data?.unmapped_validations) {
          let sitesValidations = []
          res?.data?.unmapped_validations.forEach(validation => {
            if (validation.hasOwnProperty("site_db_id")) {
              sitesValidations.push(validation)
            }
          })

          setSitesValidations(sitesValidations)
        }
      }
    })
  };

  return {
    clearValidations,
    onRegistryValidate
  }
};

export default useAuthoringValidationActions;