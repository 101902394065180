import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import './ObjPill.scss'

const ObjPill = ({
  label,
  url,
  style = {}
}) => {
  const navigate = useNavigate()

  if(url) style.cursor = "pointer"

  return <span
    style={style}
    className="obj-pill"
    onClick={() => url ? navigate(url) : {}}
  >
    {label}
  </span>
}

ObjPill.propTypes = {
  label: PropTypes.string,
  url: PropTypes.string,
}

export default ObjPill