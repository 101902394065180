// TODO: move inside containers/requests/components/fields
import * as React from 'react'
import PropTypes from 'prop-types'
import './Field.scss'

const Field = ({
  label,
  className,
  children,
}) => {
  return <div className={`prime-field${className ? ' ' + className : ''}`}>
    <label>{label}</label>
    {children}
  </div>
}

Field.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
}

export default Field