import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import './StudyPill.scss'

const StudyPill = ({
  studyObj,
  idKey = 'id', // "core_stu_id" for documents-grid api
}) => {
  const navigate = useNavigate()

  return <span
    className="study-pill"
    onClick={() => navigate(`/study/${studyObj[idKey]}/overview`)}
  >
    {studyObj?.study_id}
  </span>
}

StudyPill.propTypes = {
  studyObj: PropTypes.shape({
    study_id: PropTypes.string,
  }),
  idKey: PropTypes.string, // "id" or "core_stu_id" for documents-grid api
}

export default StudyPill