import React, { useState, useEffect, memo, useMemo } from 'react';
import { useParams } from 'react-router-dom';

// Components
import ModalHeaderActions from '../EditSiteDetails/ModalHeaderActions';
import { Button } from 'components-design-system'
import FormContainer from '../SimpleRJSchema/FormContainer/FormContainer';

import './NewSiteDetails.scss'

// Templates
import ObjectTemplate from './RJSTempates/ObjectTemplate';
import ArrayTemplate from './RJSTempates/ArrayTemplate';

// Data Schema
import { newSiteSchema } from './DataSchema/newSiteSchema';

// UI Schema
import { newSiteUISchema } from './UISchema/newSiteUISchema';

// Context
import useLocations from 'context/hooks/useLocations'
// api
import { useGetAuthoringStudy } from 'api/hooks/studies/useAuthoringApi';
import { useGetStudy } from 'api/hooks/studies/useStudiesApi';
import {
  usePostSiteManagementLocations,
  usePutSiteManagementLocationMasking,
} from 'api/hooks/siteManagement/useSiteManagementApi';


const NewSiteDetailsModal = ({
  setOpenModal,
  openModal,
  siteIDSet = new Set(),
  siteManagementMaskingSetting
}) => {
  const {
    setLocationMaskSettings
  } = useLocations()

  const { studyId, siteManagementId } = useParams();
  const { data: study } = useGetStudy(studyId)
  const { data: authoring } = useGetAuthoringStudy(study?.authoring_study_id)
  const postSiteManagementLocations = usePostSiteManagementLocations(siteManagementId)
  const putSiteManagementLocationMasking = usePutSiteManagementLocationMasking(siteManagementId)

  const [formData, setFormData] = useState({});
  const [maskSettings, setMaskSettings] = useState({})

  const onChangeFormData = (value) => {
    setFormData(value)
  }

  const onAutocompleteChange = (data) => {
    let address = {
      city: "",
      country: "",
      state: "",
      zip: "",
    }

    data.address_components.forEach(item => {
      item.types.forEach(type => {
        switch (type) {
          case "country":
            address.country = item.long_name;
            break;
          case "postal_code":
            address.zip = item.long_name;
            break;
          case "administrative_area_level_1":
            address.state = item.long_name;
            break;
          case "locality":
            address.city = item.long_name;
          case "sublocality":
            address.city = item.long_name;
            break;
        }
      });
    });

    setFormData({
      ...formData,
      facility: {
        name: data.name,
        address: {
          ...address
        }
      }
    })
  }

  useEffect(() => {
    setFormData(null)
  }, [openModal])

  useEffect(() => {
    if (siteManagementMaskingSetting) {
      setMaskSettings(siteManagementMaskingSetting)
    }
  }, [siteManagementMaskingSetting])

  useEffect(() => {
    setLocationMaskSettings(maskSettings)
  }, [maskSettings])

  const closeModal = () => {
    setFormData(null)
    setOpenModal(false);
  }

  const onSubmit = () => {
    // onCreateSite(formData, );

    let siteID = formData.site_id;
    delete formData.site_id;

    let newSite = {
      ...formData,
      facility: {
        name: formData.facility.name ? formData.facility.name : "",
        address: {
          city: formData.facility.address.city ? formData.facility.address.city : "",
          state: formData.facility.address.state ? formData.facility.address.state : "",
          country: formData.facility.address.country ? formData.facility.address.country : "",
          zip: formData.facility.address.zip ? formData.facility.address.zip : "",
        }
      },
      site_rowid: siteID
    }

    postSiteManagementLocations({
      body: {
        site_id: siteID,
        location_data: newSite
      }
    }, {
      onSuccess: (resp) => {
        setOpenModal(false);
        console.log("here")
        console.log({ resp })
        putSiteManagementLocationMasking({
          id: resp?.data?.id + '/',
          body: {
            masking_config: {
              ...maskSettings,
              contact_backup_mask: maskSettings?.contact_mask,
            }
          }
        })
      }
    })

    setOpenModal(false);
    setFormData(null)
  }

  const onChangeMaskSettings = (key, e) => {
    let val = e.target.checked;

    let maskingConfig = {
      ...maskSettings,
      [key]: val
    }

    setMaskSettings(maskingConfig)
  }

  const formValidation = {
    country: {
      values: new Set(["United States", "US", "USA"]),
      required: new Set(["zip", "state"])
    },
    site_id: {
      required: siteIDSet
    },
  }

  const recruitmentStatus = authoring?.override_data?.["protocol.study_timeline.recruitment.recruitment_status"] || authoring?.study_data?.protocol?.study_timeline?.recruitment?.recruitment_status

  const disableSubmit = useMemo(() => {
    // if (!formData?.status) return true
    if (recruitmentStatus === "Recruiting" && !formData?.status) return true;
    return formData && formData.site_id && !siteIDSet.has(formData.site_id) ? false : true;


  }, [formData, siteIDSet, recruitmentStatus])

  return <div className="new-site-modal">
    <div className="new-site-modal-body">
      <ModalHeaderActions
        maskSettings={maskSettings}
        onChangeMaskSettings={onChangeMaskSettings}
      // maskConfigs={maskConfigs}
      />
      <div className="new-site-modal-content">
        <FormContainer
          schema={newSiteSchema}
          uiSchema={newSiteUISchema}
          formData={formData}
          formContext={{
            onAutocompleteChange,
            formValidation,
            formData,
            siteIDSet,
            recruitmentStatus
          }}
          ObjectFieldTemplate={ObjectTemplate}
          ArrayFieldTemplate={ArrayTemplate}
          onChange={onChangeFormData}
          //RJS Submits form on add array item for some reason, created a custom button for submission
          onSubmit={() => { }}>
        </FormContainer>
      </div>
    </div>
    <div className="new-site-modal-actions">
      <Button
        variant="outlined"
        onClick={() => closeModal()}
        style={{ marginRight: 16 }}>
        Cancel
      </Button>
      <Button
        disabled={disableSubmit}
        onClick={() => onSubmit()}>
        Submit
      </Button>
    </div>
  </div>
}

export default memo(NewSiteDetailsModal)