import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// Components
import { Button } from 'components-design-system'

const DeleteSite = ({
  setOpenModal,
  onOpenModal,
  onDeleteSite,
  gridProps,
  formData
}) => {
  const [facilityName, setFacilityName] = useState("");
  const [address, setAddress] = useState("");
  const [contact, setContact] = useState("");
  const [contactBackup, setContactBackup] = useState("");
  const [investigators, setInvestigators] = useState(0);
  const { data } = gridProps;

  useEffect(() => {
    if(data && formData && formData.facility) {
      const { 
        address, 
        name 
      } = formData.facility;
      if(name) setFacilityName(name);
      if(address) {
        const {
          city, 
          country, 
          state, 
          zip
        } = address;
        setAddress([city, state, zip, country].filter(item => item && item.length > 0).join(", "));
      }
    }

    if(data && formData && formData.contact) {
      const {
        first_name,
        middle_name,
        last_name,
        degrees,
        phone,
        phone_ext,
        email
      } = formData.contact
      let fullName = [first_name, middle_name, last_name].filter(item => item && item.length > 0).join(" ");
      let fullNameDegree = [fullName, degrees].filter(item => item && item.length > 0).join(", ");
      let phoneNumber = [phone, phone_ext].filter(item => item && item.length > 0).join(" ext: ");
      setContact([fullNameDegree, phoneNumber, email].filter(item => item && item.length > 0).join(" | "))
    }

    if(data && formData && formData.contact_backup) {
      const {
        first_name,
        middle_name,
        last_name,
        degrees,
        phone,
        phone_ext,
        email
      } = formData.contact_backup
      let fullName = [first_name, middle_name, last_name].filter(item => item && item.length > 0).join(" ");
      let fullNameDegree = [fullName, degrees].filter(item => item && item.length > 0).join(", ");
      let phoneNumber = [phone, phone_ext].filter(item => item && item.length > 0).join(" ext: ");
      setContactBackup([fullNameDegree, phoneNumber, email].filter(item => item && item.length > 0).join(" | "))
    }

    if(data && formData && formData.investigator) {
      setInvestigators(formData.investigator.length)
    }

  }, [data]);
  
  const onBack = () => {
    onOpenModal("edit-site", gridProps);
  }

  return (
    <Container>
      <Wrapper>
        <Row style={{ marginBottom: 23 }}>
          <Title>{facilityName}</Title>
          <Text>{address}</Text>
        </Row>
        {contact && <Row>
          <Subtitle>Primary Contact</Subtitle>
          <Text>{contact}</Text>
        </Row>}
        {contactBackup && <Row>
          <Subtitle>Backup Contact</Subtitle>
          <Text>{contactBackup}</Text>
        </Row>}
        <Row>
          <Text>Investigators: [{investigators}]</Text>
        </Row>
      </Wrapper>
      <ActionContainer>
        <Button 
          // size="lg" 
          variant="outlined" 
          onClick={() => onBack()}
          style={{ marginRight: 16 }}>
          Cancel
        </Button>
        <Button 
          // size="lg"
          variant="danger"
          onClick={() => onDeleteSite(gridProps)}>
          Delete
        </Button>
      </ActionContainer>
    </Container>
  );
};

export default DeleteSite;

const Container = styled.div`

`

const Wrapper = styled.div`
  padding: 20px;
  background: #F4F4F4;
  border-radius: 5px;
`

const Row = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`

const Title = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #383E47;
`

const Subtitle = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.16px;
  color: #383E47;
`

const Text = styled.span`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.16px;
  color: #383E47;
`

const ActionContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`