import React from 'react'
import { Tooltip } from 'antd'
import ResultsBadge from '../../badges/ResultsBadge/ResultsBadge'
import './AssessmentResultCellRenderer.scss'

// context
import useStudy from 'context/hooks/useStudy'
import useAssessments from 'context/hooks/useAssessments'

const AssessmentResultCellRenderer = ({
  data,
  value,
  resultsFilterLabels, // get classnames
  // setMissingFields,
  openDecisionTree,
  node,
}) => {
  const {
    setMissingFields
  } = useAssessments()

  const {
    onOpenPanel
  } = useStudy()
  const getClassName = () => {
    return Object.keys(resultsFilterLabels).find((mapKey) => resultsFilterLabels[mapKey] === value)
  }

  const handleMissingFields = (fields) => {
  
    onOpenPanel("missing-fields")
    node.setSelected(true)
    setMissingFields(fields)
  }

  return (
    <div className={`result-cell-renderer${getClassName() ? ' ' + getClassName() : ''}`}>
      {data?.missing_fields?.length > 0
        ? <Tooltip
          overlayClassName="result-cell-missing-fields-tooltip"
          title={<div>Enter missing Fields:
            <ul>
              {data.missing_fields?.map((fieldObj) => <li key={fieldObj?.field}>{fieldObj?.field_label || fieldObj?.field}</li>)}
            </ul>
          </div>}
          onClick={setMissingFields ? () => handleMissingFields(data.missing_fields) : null}
        >
          <div className="cell-render-content">
            <div className="missing-fields-wrapper">
              <i className="fas fa-exclamation-triangle" />
            </div>
          </div>
        </Tooltip>
        : <>
          <div className="cell-render-content">
            <ResultsBadge tooltip={data?.result.includes('(Manual)') ? data.result : null} value={data?.result} />
          </div>
          {!data.is_manual && !data.is_override &&
            <Tooltip title="Decision tree">
              <div className="decision-tree" onClick={() => openDecisionTree(data)}>
                <i className="fal fa-network-wired" />
              </div>
            </Tooltip>
          }
        </>
      }
    </div>
  )
}

export default AssessmentResultCellRenderer