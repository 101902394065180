import * as React from 'react'
import PropTypes from 'prop-types'
import Field from 'containers/requests/components/Field/Field'
import { Select } from 'components-design-system'
import PrimeField from 'components/PrimeField/PrimeField'

// utils
import useStudySearch from 'utilities/useStudySearch/useStudySearch'
import { CORE_FIELDS } from '../RequestAllFields/RequestAllFields'

// styles
import './RequestBaseFields.scss'

const tags = [
  "tag 1",
  "tag 2",
  "tag 3",
  "tag 4",
  "tag 5",
  "tag 6",
  "tag 7",
  "tag 8",
  "tag 9",
]

const RequestBaseFields = ({
  requestFormData,
  onChangeRequest = () => { },
  readOnly = false,
}) => {
  const {
    studyOptions,
    searchValue,
    handleStudySearch,
  } = useStudySearch()

  const getDefaultStudyOptions = () => {
    return requestFormData?.corestudies?.map((study) => {
      return ({ value: study.id, label: study.study_id })
    })
  }

  const getValue = (key) => {
    const keys = key.split(".");
    const fieldKey = keys.pop();
    const obj = keys.reduce((nestedObj, nestedKey) => nestedObj[nestedKey], requestFormData);
    return obj?.[fieldKey]
  }

  const renderStudiesField = (field, key) => {
    let value = requestFormData.corestudies.map(study => study.id)
    if (requestFormData?.request_data?.study_db_ids) value = requestFormData.request_data.study_db_ids
    let options = [...studyOptions, ...getDefaultStudyOptions()]

    return (
      <Field
        key="req-base-studies-field"
        label={field.label}
        className="md-field"
      >
        <Select
          options={options}
          value={value}
          searchValue={searchValue}
          onChange={(val) => onChangeRequest(key, val)}
          mode="multiple"
          onSearch={(value) => handleStudySearch(value)}
          readOnly={readOnly}
          allowClear
          showSearch
          className="studies-select"
        />
      </Field>
    )
  }

  return <div className="edit-request-info" style={{ width: "100%" }}>
    {Object.keys(CORE_FIELDS).filter(key => CORE_FIELDS[key].base === true).map(key => {
      const field = CORE_FIELDS[key]
      if (key === "request_data.study_db_ids") return renderStudiesField(field, key)

      let classNames = ["request-addtl-field"]
      if (field.baseClassName) classNames.push(field.baseClassName)
      return (
        <Field
          key={key}
          label={field.label}
          className={classNames.join(" ")}
        >
          <PrimeField
            schema={{
              type: field.type,
              placeholder: field.placeholder,
              items: field.items,
            }}
            onChange={(val) => onChangeRequest(key, val)}
            readOnly={true}
            defaultValue={getValue(key)}
            className={field.className}
          />
        </Field>
      )
    })}
  </div>
}

RequestBaseFields.propTypes = {
  requestFormData: PropTypes.shape({
    studies: PropTypes.arrayOf(PropTypes.object),
    name: PropTypes.string,
    req_date: PropTypes.string,
    create_date: PropTypes.string,
    type: PropTypes.string,
    requestor: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
  }),
  readOnly: PropTypes.bool,
}

export default RequestBaseFields