import React, {
  useMemo
} from 'react';
import { useShallow } from 'zustand/react/shallow'

// components
import RJLabel from '../components/RJLabel/RJLabel';
import RJReadOnly from '../components/RJReadOnly/RJReadOnly';
import ForkDeleteModal from '../components/ForkDeleteModal'
import { Skeleton } from 'antd'

// utils/helpers
import { registryValidMsgs } from 'utilities/registryValidations'
import { useInView } from 'react-intersection-observer';

// context
import useHOC from './useHOC';
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';
import useAuthoringPrintStore from '../../hooks/stores/useAuthoringPrintStore';

// styles
import './InputWrapper.scss'

const InputHOC = (props) => {
  const {
    value,
    WrappedComponent,
    formatOptions
  } = props;

  const {
    readOnly,
    formView,
    formSection,
    refreshFormData
  } = useAuthoringViewStore(
    useShallow(state => ({
      readOnly: state.readOnly,
      formView: state.formView,
      formSection: state.formSection,
      refreshFormData: state.refreshFormData,
    }))
  )

  const hoc = useHOC({
    WrappedComponent,
    props,
    formatOptions,
  })

  const {
    openDeleteForkedModal,
    showMenu,
    jpaths,
    invalid,
    forkedFields,
    fieldLoading,
    setFieldLoading,
    authoringId,
    isFieldLocked,
  } = hoc

  const jpath = props.id;

  let propsForFields = { ...props };

  const memoField = useMemo(() => (
    jpaths?.map((path) => {
      return <Field
        key={path}
        {...props}
        {...hoc}
        path={path}
        jpath={jpath}
        WrappedComponent={WrappedComponent}
        propsForFields={propsForFields}
      />
    })
  ), [
    value,
    showMenu,
    readOnly,
    formSection,
    formView,
    invalid,
    forkedFields,
    openDeleteForkedModal,
    isFieldLocked,
    fieldLoading,
    setFieldLoading,
    authoringId,
    refreshFormData
  ])

  return memoField;
}

export default React.memo(InputHOC);

const Field = (props) => {
  const {
    path,
    openDeleteForkedModal,
    setOpenDeleteForkedModal,
    showMenu,
    setShowMenu,
    options,
    registryKeys,
    jpaths,
    fieldComments,
    onForkField,
    handleDeleteForkModal,
    onDeleteForkedField,
    handleOnFocus,
    handleOnBlur,
    isFieldLocked,
    onOverrideChanged,
    displayLockedField,
    invalid,
    invalidText,
    forkedFields,
    overrideDataRef,
    registryValidations,
    lockedFields,
    fieldLoading,
    setFieldLoading,
    authoringId,
    jpath,
    WrappedComponent,
    propsForFields,
    _onChangeForkedField
  } = props

  const {
    readOnly,
    formView,
    formSection
  } = useAuthoringViewStore(
    useShallow(state => ({
      readOnly: state.readOnly,
      formView: state.formView,
      formSection: state.formSection,
    }))
  )

  const {
    isPrinting
  } = useAuthoringPrintStore(
    useShallow(state => ({
      isPrinting: state.isPrinting,
    }))
  )

  const { ref: visibleRef, inView: inViewport } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  });

  let isLocked = isFieldLocked(path);

  const forkedRegKey = useMemo(() => {
    return registryKeys?.find((key) => {
      return path.endsWith(`_${key}`);
    });

    return null;
  }, [registryKeys, path])

  const jpathArray = useMemo(() => {
    const splitPath = path.split("_");
    const regKey = splitPath[splitPath.length - 1];
    const sectId = formSection.id;

    return forkedFields?.[sectId]?.[regKey]?.[path]?.jpathArray;
  }, [forkedFields, path])

  const jpathDotNotation = useMemo(() => {
    if (jpathArray?.length > 0) {
      const lastItemKey = jpathArray.pop().replace(`_${forkedRegKey}`, "");
      jpathArray.push(lastItemKey);
      return jpathArray.join(".");
    }
    return null
  }, [path, forkedRegKey, formSection?.id, forkedFields, jpathArray])

  const regKeysForField = useMemo(() => {
    if (registryKeys) {
      let badgesForField = [...registryKeys];

      if (forkedRegKey && forkedFields?.[formSection.id]?.[forkedRegKey]?.[path]) {
        // if is forked field, will only have the one badge
        badgesForField = [forkedRegKey];
      } else {
        // if not forked field, remove all forked field badges
        badgesForField = registryKeys.reduce((acc, regKey) => {
          const pathKey = `${jpath}_${regKey}`;
          if (!forkedFields?.[formSection.id]?.[regKey]?.[pathKey]) {
            acc.push(regKey);
          };
          return acc;
        }, []);
      };
      return badgesForField
    }

    return null
  }, [registryKeys, forkedRegKey, JSON.stringify(forkedFields), formSection?.id, path])

  const isDisabled = useMemo(() => {
    return isLocked || options.disabled || props.disabled
  }, [isLocked, options?.disabled, props?.disabled])

  const isOverriden = useMemo(() => {
    return overrideDataRef.current?.[lockedFields?.[jpath]] !== undefined
  }, [isLocked, overrideDataRef.current, lockedFields, jpath])

  const isForked = useMemo(() => {
    return forkedRegKey && forkedFields?.[formSection.id]?.[forkedRegKey]?.[path] ? true : false
  }, [forkedRegKey, forkedFields, formSection.id, path])

  const value = useMemo(() => {
    let val = propsForFields?.value

    if (isOverriden) {
      val = overrideDataRef.current[lockedFields[jpath]]
    } else if (isForked) {
      val = forkedFields[formSection.id][forkedRegKey][path].value || null;
    }

    return val
  }, [propsForFields?.value, overrideDataRef.current, lockedFields, jpath, forkedFields])

  const onChange = useMemo(() => {
    if (isForked) {
      return (val) => _onChangeForkedField({ path, forkedRegKey, jpathDotNotation, val })
    } else if (isOverriden) {
      return onOverrideChanged
    } else {
      return propsForFields.onChange
    }
  }, [isOverriden, isForked, jpathDotNotation, forkedRegKey, propsForFields])

  let classNames = ["authoring-input-wrapper-container rjs-field"]
  if (options?.classNames?.field) classNames.push(options.classNames.field)
  if (fieldLoading) return <Skeleton.Input active={true} />

  return (
    <div
      id={path}
      ref={visibleRef}
      className={classNames.join(" ")}
      onMouseEnter={() => setShowMenu(true)}
      onMouseLeave={() => setShowMenu(false)}>
      {options.removeLabel !== true &&
        <RJLabel
          fieldComments={fieldComments}

          // for badges and fork button
          registryKeys={regKeysForField}
          jpaths={jpaths}
          onForkField={() => onForkField(path)}
          setOpenDeleteForkedModal={(isModalOpen) => handleDeleteForkModal(isModalOpen, path)}
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          jpath={path}
          setFieldLoading={setFieldLoading}
          formContext={props.formContext}
          {...options}
        />
      }
      {(inViewport || isPrinting) && (
        <>
          <div>
            {readOnly
              ? <RJReadOnly
                {...propsForFields}
                id={path}
                formView={formView}
                carbonProps={options}
                value={value}
                overrideJpath={lockedFields?.[jpath]}
              />
              : <>
                <WrappedComponent
                  {...propsForFields}
                  // jpath={path}
                  carbonProps={{
                    ...options,
                    disabled: isDisabled
                  }}
                  invalid={invalid}
                  invalidText={invalidText}
                  onFocus={handleOnFocus}
                  onBlur={handleOnBlur}
                  value={value}
                  onChange={onChange}
                />
                {registryValidMsgs(registryValidations, props.id, invalid)}
                <div className="input-wrapper-override-applied">
                  {options?.disabled !== true && displayLockedField(isLocked, path)}
                  {overrideDataRef.current?.[lockedFields?.[jpath]] !== undefined && !registryKeys?.find((key) => path.endsWith(key)) && (
                    <>
                      <i className="fad fa-check-circle"></i>
                      Override applied
                    </>
                  )}
                </div>
              </>
            }
          </div>
          <ForkDeleteModal
            onHide={() => setOpenDeleteForkedModal(false)}
            showModal={openDeleteForkedModal}
            onSubmit={onDeleteForkedField}
          />
        </>
      )}
    </div>
  )
}