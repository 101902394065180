import React from 'react';

// components
import PrimeField from 'components/PrimeField/PrimeField';
import Modal from 'components/Modal/Modal';
import { Button } from 'components-design-system';

// constants
import { submissionReasons, getSubmissionSchemas } from 'constants/submissions';

// api
import { useGetGlobalSiteSettings } from 'api/hooks/admin/usePrsSiteMaskingApi';

// styles
import './UploadModal.scss'

const initFormData = {
  document_id_list: [],
}

const UploadModal = ({
  formView,
  formSection,
  formVersion,
  ctgDocuments,
  authoring,
  openUploadModal,
  setOpenUploadModal,
  onSubmitToCtg
}) => {
  const [formData, setFormData] = React.useState(initFormData)
  const [agreeToSubmit, setAgreeToSubmit] = React.useState(false)
  const { data: globalSettings } = useGetGlobalSiteSettings();

  React.useEffect(() => {
    if (globalSettings?.release_to_ctg) {
      setFormData({
        ...formData,
        autorelease: true
      })
    }
  }, [globalSettings])

  const schema = {
    autorelease: {
      type: "checkbox",
      label: "Release record"
    },
    update_ver_date: {
      type: "checkbox",
      label: "Update record verification date"
    },
    submission_reason: {
      type: "dropdown",
      id: "submission_reason-field",
      className: "full",
      placeholder: "Reason for Submission *",
      required: true,
      items: submissionReasons,
      containerClassName: "field"
    },
    document_id_list: {
      type: "multiselect",
      id: "ctg-document-submission-field",
      className: "full",
      placeholder: "Documents for Submission",
      items: ctgDocuments?.map((item) => ({ value: item.id, label: item.name })),
      containerClassName: "field",
      disabled: ctgDocuments.length === 0
    }
  }

  const getIneligibleDocs = () => {
    if (ctgDocuments?.length > 0 && formData?.document_id_list && formData?.document_id_list?.length > 0) {
      return formData.document_id_list.reduce((acc, docId) => {
        const docObj = ctgDocuments.find((item) => item.id === docId)
        if (!docObj.document_date) {
          acc.push(docObj)
        }
        return acc
      }, [])
    }
    return []
  }

  const onChange = (key, val) => {
    setFormData({
      ...formData,
      [key]: val
    })
  }

  const onHide = () => {
    setFormData(initFormData)
    setOpenUploadModal(false)
  }

  const onSubmit = () => {
    const payload = {
      study_db_id: authoring.id,
      form_view: formView.id,
      form_section: formSection.id,
      form_version: formVersion.id,
      autorelease: formData.autorelease ? true : false,
      update_ver_date: formData.update_ver_date ? true : false,
      submission_reason: formData.submission_reason,
      document_id_list: formData?.document_id_list,
    }

    if (payload.form_section === 'delayedResultsCertification') payload.form_section = getSubmissionSchemas()['delayedResultsCertification'].submitToCTG;

    onSubmitToCtg(payload)
    onHide()
  }

  return (
    <Modal
      title={"Upload to PRS"}
      // message={this.props.projectOverview && (`${this.props.projectOverview.study_id} - ${this.props.projectOverview.project_title}`)}
      open={openUploadModal}
      onCancel={onHide}
      hideImage={true}
      footer={[]}
      width={520}
      className="upload-modal"
    >
      <div className="upload-modal-content">
        <div className="upload-modal-header">
          <div className="col-tile">
            <div className="col-label">Form View</div>
            <div className="col-field">{formView?.text}</div>
          </div>
          <div className="col-tile">
            <div className="col-label">Form Section</div>
            <div className="col-field">{formSection?.text}</div>
          </div>
          <div className="col-tile">
            <div className="col-label">Form Version</div>
            <div className="col-field">{formVersion?.text}</div>
          </div>
        </div>
        <div className="description-container">
          Send all updates to PRS. If you would like to release record to CTG check the checkbox below.
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
          {Object.keys(schema).map((key) => {
            let showError = false
            let errorMessage = ''
            if (key === 'document_id_list' && getIneligibleDocs().length > 0) {
              showError = true
              errorMessage = <>
                <div>Document submitted to PRS must have a Document Date. Please navigate to the Documents tab to populate the Document Date for the following:</div>
                <ul>
                  {getIneligibleDocs().map((item) => <li key={item.id}>{item.name}</li>)}
                </ul>
              </>
            }
            return < PrimeField
              key={key}
              schema={schema[key]}
              value={formData[key]}
              onChange={(val) => onChange(key, val)}
              showError={showError}
              errorMessage={errorMessage}
            />
          })}
        </div>
        {ctgDocuments?.length === 0 &&
          <div className="description-container field-description-container">
            No CTG documents have been uploaded.
          </div>
        }
        <PrimeField
          schema={{
            type: "checkbox",
            label: "I am in agreement to submit the finalized record to PRS",
          }}
          value={agreeToSubmit}
          onChange={setAgreeToSubmit}
        />
      </div>
      <div className="upload-modal-actions">
        <Button
          style={{ marginRight: 12 }}
          onClick={onHide}
          variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={onSubmit}
          variant="primary"
          disabled={!formData.submission_reason || !agreeToSubmit || getIneligibleDocs().length > 0}>
          Upload
        </Button>
      </div>
    </Modal>
  );
};

export default UploadModal;