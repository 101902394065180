// TODO: remove if not needed since default Ant Design Checkbox already has correct styles
import React from 'react'
import { Checkbox as AntCheckbox } from 'antd'
import PropTypes from 'prop-types'

// styles
import './checkbox.scss';

const Checkbox = ({
  label,
  id, // not an antd Checkbox prop
  defaultChecked,
  onChange, // returns (e:Event)
  className,
  disabled,
  ...otherProps // checked (if controlled)
}) => {
  const classNames = `prime-checkbox-input${className ? ' ' + className : ''}`

  return (
    <AntCheckbox
      id={id}
      defaultChecked={defaultChecked}
      onChange={onChange}
      className={classNames}
      disabled={disabled}
      {...otherProps}
    >
      {label}
    </AntCheckbox>
  )
}

Checkbox.propTypes = {
  defaultChecked: PropTypes.bool,
  checked: PropTypes.bool, // controlled component
  onChange: PropTypes.func,
  className: PropTypes.string,
}

export default Checkbox