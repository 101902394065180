/**
 * Registry key values:
 * * "ctg"
 * * "eudra"
 * * "eupas"
 */
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Tooltip } from 'antd';
import { v4 as uuid } from 'uuid';
import './RegistryBadges.scss';

const colors = {
  // ctg: "rgba(255, 116, 149, 0.5)",
  // eudra: "rgba(158, 207, 107, 0.5)",
  // eupas: "rgba(155, 101, 191, 0.5)",
  // ctis: "#BADBE7"
  default: {
    bg: "white",
    border: "#A0A4A8",
  },
  global: {
    bg: "#EFF8FF",
    border: "#78A9FF",
  },
  ctg: {
    bg: "#FFE3EA",
    border: "#FF7495",
  },
  eudra: {
    bg: "#ECF5E1",
    border: "#9ECF6B",
  },
  eupas: {
    bg: "#ECF5E1",
    border: "#9ECF6B",
  },
  ctis: {
    bg: "#F4F8F5",
    border: "#5A9C56"
  }
}

const getBackgroundColor = (type) => {
  switch (type) {
    case "eudra": return colors.eudra.bg;
    case "ctg": return colors.ctg.bg;
    case "eupas": return colors.eupas.bg;
    case "ctis": return colors.ctis.bg;
    default: return "#0D47A1";
  }
}

const getBorderColor = (type) => {
  switch (type) {
    case "eudra": return colors.eudra.border;
    case "ctg": return colors.ctg.border;
    case "eupas": return colors.eupas.border;
    case "ctis": return colors.ctis.border;
    default: return "#0D47A1";
  }
}

const RegistryBadges = ({
  keys = [],
}) => {

  const badges = useMemo(() => {
    if (keys?.length > 0) {
      return keys.map((type, index) => {
        // title
        let title = "";
        if (type === "eudra") title = "EUD";
        else title = type.toUpperCase();

        // tooltip text
        let tooltipText = "";
        if (type === "ctg") tooltipText = "ClinicalTrials.Gov";
        else if (type === "eudra") tooltipText = "EudraCT";
        else if (type === "eupas") tooltipText = "EUPAS";
        else if (type === "ctis") tooltipText = "CTIS";

        return (
          <Tooltip title={tooltipText} key={uuid()}>
            <div
              key={index}
              className="registry-badge"
              style={{
                backgroundColor: getBackgroundColor(type),
                border: `1px solid ${getBorderColor(type)}`,
                // color: `${getBorderColor(type)}`,
                marginLeft: index === 0 ? null : `7px`,
              }}
            >
              {title}
            </div>
          </Tooltip>
        );
      }, []);
    }
    return null;
  }, [keys]);

  return (
    //  className="animate__animated animate__fadeIn animate__faster"
    <div className="registry-badges-container">
      {badges}
    </div>
  );
};

export default RegistryBadges;