import { useContext, createContext, useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { useQueryClient } from '@tanstack/react-query'

// api
import {
  usePostForceWorkflow,
  usePostCancelWorkflow,
  usePostHoldWorkflow,
  usePostUnholdWorkflow
} from 'api/hooks/workflows/useWorkflowsApi';

export const WorkflowContext = createContext({})

export const WorkflowProvider = ({ children }) => {
  const queryClient = useQueryClient()

  const [workflowId, setWorkflowId] = useState(null)
  const [workflowModel, setWorkflowModel] = useState(null)

  const [workflowTrackerData, setWorkflowTrackerData] = useState({})
  const [selectedAction, setSelectedAction] = useState(null);
  const [nextState, setNextState] = useState(null);

  const [openForceModal, setOpenForceModal] = useState(null)
  const [openPushStatePanel, setOpenPushStatePanel] = useState(false)
  const [openEditStatesModal, setOpenEditStatesPanel] = useState(false)
  const [openCancelWorkflowModal, setOpenCancelWorkflowModal] = useState(false)
  const [team, setTeam] = useState([])

  const [parentQueries, setParentQueries] = useState([])
  const forceWorkflow = usePostForceWorkflow({ workflowId, workflowModel })
  const cancelWorkflow = usePostCancelWorkflow({ workflowId, workflowModel })
  const holdWorkflow = usePostHoldWorkflow({ workflowId, workflowModel })
  const unholdWorkflow = usePostUnholdWorkflow({ workflowId, workflowModel })

  const isWorkflowCancelled = useMemo(() => {
    return workflowTrackerData?.workflow_data?.is_cancelled
  }, [workflowTrackerData?.workflow_data?.is_cancelled])

  const isOnHold = useMemo(() => {
    return workflowTrackerData?.workflow_data?.is_onhold
  }, [workflowTrackerData?.workflow_data?.is_onhold])

  const isWorkflowComplete = useMemo(() => {
    if (workflowTrackerData?.state_data?.tracker_data) {
      const td = workflowTrackerData?.state_data?.tracker_data;
      return td[td.length - 1]?.is_current ? true : false
    }
  }, [workflowTrackerData?.state_data])

  const trackerData = useMemo(() => {
    return workflowTrackerData?.state_data?.tracker_data || []
  }, [workflowTrackerData?.state_data?.tracker_data])

  const currentState = useMemo(() => {
    if (workflowTrackerData?.state_data?.tracker_data) {
      const curr = workflowTrackerData?.state_data?.tracker_data.find(state => state.is_current);
      if (curr) return curr
    }
    return null
  }, [workflowTrackerData?.state_data?.tracker_data])

  const projectedCompletionDate = useMemo(() => {
    if (workflowTrackerData?.state_data?.tracker_data) {
      const tracker = workflowTrackerData.state_data.tracker_data
      let lastState = tracker[tracker.length - 1]
      return lastState.start_state_date
    }
    return null
  }, [workflowTrackerData?.state_data?.tracker_data])

  useEffect(() => {
    if (selectedAction) {
      getNextState()
    }
  }, [selectedAction, workflowTrackerData?.state_data?.tracker_data])

  const completedStates = useMemo(() => {
    if (workflowTrackerData?.state_data?.tracker_data) {
      return workflowTrackerData.state_data.tracker_data.filter(state => state.status === "Completed")
    }

    return []
  }, [workflowTrackerData?.state_data?.tracker_data])

  const getNextState = () => {
    if (selectedAction && workflowTrackerData?.state_data?.tracker_data) {
      let foundNextState = workflowTrackerData?.state_data?.tracker_data.find(state => state.state_id === selectedAction.next_state_id);
      if (foundNextState) setNextState(foundNextState);
    }
  }

  const formatRequest = ({
    formData,
    type = "activity",
    metadata,
    allowForms,
    documentsCheck,
    action
  }) => {
    let request = {
      model: type,
      action_id: selectedAction?.id,
      assign_to_ids: formData?.assign_to,
      inform_team: formData?.inform_team,
      create_action_item: formData.create_action_item ? true : false,
    }

    if (formData.tags?.length > 0 || formData.text?.length > 0) {
      request.comment_data = {
        tags: formData.tags,
        text: formData.text
      }
    }

    if (formData.create_action_item) {
      request.actionitem_data = {
        title: formData.title,
        due_date: dayjs(formData.due_date).format("YYYY-MM-DD"),
        priority: formData.priority,
        additional_comments: formData.additional_comments
      }
    }

    // review_approve_data added at formData init to keep it as close to the final request body as possible
    request.review_approve_data = { ...formData.review_approve_data }

    if (allowForms) {
      request.review_approve_data = {
        ...request.review_approve_data,
        study_id: metadata?.study_uid,
      }

      if (formData.form_compare_version) {
        request.review_approve_data = {
          ...request.review_approve_data,
          form_compare_version: formData.form_compare_version
        }
      }

      request.review_approve_data.form_types = formatFormTypes(request.review_approve_data.form_types)
    } else {
      // required empty fields when not sending forms:
      request.review_approve_data.form_types = []
      request.review_approve_data.comment_types = []
      request.review_approve_data.form_version = ""
    }

    // required empty field when not sending documents:
    if (!documentsCheck) {
      request.review_approve_data.documents = []
    }

    if (action.view_name === null) {
      delete request.review_approve_data
    }

    return request
  }

  const formatFormTypes = (value) => {
    let formTypes = []
    value.forEach(key => {
      let view = formTypeOptions[key][0];
      let section = formTypeOptions[key][1];
      formTypes.push({
        form_view: view,
        form_section: section
      })
    })

    return formTypes;
  }

  const formTypeOptions = {
    "Global Protocol": ["Global", "Protocol"],
    "Global Results": ["Global", "Results"],
    "Global Study Details": ["Global", "Study Details"],
    "Global Delayed Results Certification": ["Global", "Delayed Results Certification"],
    "Global Sponsor Trial Website": ["Global", "Sponsor Trial Website"],
    "CTG Protocol": ["CTG", "Protocol"],
    "CTG Results": ["CTG", "Results"],
    "CTG Delayed Results Certification": ["CTG", "Delayed Results Certification"],
    "EUPAS Protocol": ["EUPAS", "Protocol"],
    "CTIS Protocol": ["CTIS", "Protocol"],
    "EUDRACT Results": ["EudraCT", "Results"]
  }

  const invalidateParentQueries = () => {
    if (parentQueries) {
      parentQueries.forEach(query => {
        queryClient.invalidateQueries(query)
      })
    }
  }

  return (
    <WorkflowContext.Provider
      value={{
        selectedAction,
        setSelectedAction,
        nextState,
        setNextState,
        formTypeOptions,
        formatRequest,
        completedStates,
        workflowTrackerData,
        setWorkflowTrackerData,
        isWorkflowComplete,
        isWorkflowCancelled,
        isOnHold,
        trackerData,
        currentState,
        openCancelWorkflowModal,
        setOpenCancelWorkflowModal,
        openForceModal,
        setOpenForceModal,
        openPushStatePanel,
        setOpenPushStatePanel,
        openEditStatesModal,
        setOpenEditStatesPanel,
        workflowModel,
        setWorkflowModel,
        workflowId,
        setWorkflowId,

        invalidateParentQueries,
        parentQueries,
        setParentQueries,
        forceWorkflow,
        cancelWorkflow,
        holdWorkflow,
        unholdWorkflow,
        team,
        setTeam,
        projectedCompletionDate
      }}>
      {children}
    </WorkflowContext.Provider>
  );
};

const useWorkflowTracker = () => {
  return useContext(WorkflowContext)
};

export default useWorkflowTracker;