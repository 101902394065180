import dayjs from 'dayjs'

const getYearToday = () => {
  const today = new Date()
  return today.getFullYear()
}

// tested with cellValue in UTC format, eg, "2023-06-16T08:10:49.047277Z"
const filterParams = {
  resetButton: true,
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    if (cellValue == null) return -1;

    const valueDayJs = dayjs(cellValue).format("DD/MM/YYYY")
    var dateParts = valueDayJs.split('/')
    var jsDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0]),
    )

    // Unix Timestamp (milliseconds)
    if (filterLocalDateAtMidnight.getTime() === dayjs(cellValue).valueOf()) {
      return 0
    }

    if (jsDate < filterLocalDateAtMidnight) {
      return -1
    }
    if (jsDate > filterLocalDateAtMidnight) {
      return 1
    }
    return 0
  },
  // minValidYear: 2000,
  maxValidYear: getYearToday(),
  inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
  // defaultJoinOperator: 'OR',
  inRangeInclusive: true,
}

const dateColumn = {
  filter: 'agDateColumnFilter',
  filterParams,
}

export default dateColumn