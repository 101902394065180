// TODO: replace with xogene-component-library SidePanel
import React, { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import PropTypes from 'prop-types'
import './SidePanel.scss'

const fadeVariants = {
  closed: {
    opacity: 0,
    transition: { duration: 0.3, },
  },
  open: {
    opacity: 1,
    transition: { delay: 0.2 }
  },
}

const SidePanel = ({
  isOpen,
  setIsOpen,
  fixedPosition,
  title,
  subtitle,
  hasOverlay,
  width = 300,
  fixedScrollAtHeight,
  className = "",
  style,
  // scroll height when side panel should be fixed to top // 
  closeIconStyle = {},
  children,
}) => {

  // keep favorites side panel fixed to top when scroll window
  const [pageYOffset, setPageYOffset] = useState(0)

  useEffect(() => {
    if (fixedPosition && fixedScrollAtHeight) {
      const handleScroll = () => setPageYOffset(window.pageYOffset)
      window.addEventListener('scroll', handleScroll, { passive: true })
      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [fixedPosition, fixedScrollAtHeight])

  if (!isOpen) return null;

  return <AnimatePresence>
    {isOpen && <>
      {hasOverlay && <div
        className={`sidepanel-overlay`}
        onClick={() => setIsOpen(false)}
      />}
      <motion.aside
        initial={{ width: 0 }}
        animate={{ width }}
        exit={{
          width: 0,
          transition: { duration: 0.5 }
        }}
        transition={{ ease: "easeInOut" }}
        className={`side-panel${isOpen ? ' open' : ' closed'} ${className ? '' + className : ''}${fixedPosition ? ' fixed' : ''}${fixedPosition === 'left' ? ' fixed-left' : ''}${fixedPosition === 'right' ? ' fixed-right' : ''}`}
        style={{
          top: fixedScrollAtHeight && fixedPosition && fixedScrollAtHeight - pageYOffset > 0 ? fixedScrollAtHeight - pageYOffset : 0,
          ...style,
        }}
      >
        <motion.div
          initial="closed"
          animate="open"
          exit="closed"
          variants={fadeVariants}
          className="side-panel-motion"
        >
          {title && (
            <div className="title" style={subtitle && { marginBottom: 4 }}>
              {title}
            </div>
          )}
          {subtitle && (
            <div className="subtitle">
              {subtitle}
            </div>
          )}
          <div
            onClick={() => setIsOpen(false)}
          >
            <i
              className="fal fa-times close-side-panel-icon"
              style={closeIconStyle}
              aria-hidden="true"
            />
          </div>
          {children}
        </motion.div>
      </motion.aside>
    </>}
  </AnimatePresence>
}

SidePanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  fixedPosition: PropTypes.oneOf(['left', 'right']),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  fixedScrollAtHeight: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
  closeIconStyle: PropTypes.object,
  children: PropTypes.node,
}

export default SidePanel