import { memo } from 'react';
import styled from 'styled-components';

// Components
import { Button } from 'components-design-system'
import Checkbox from 'components/Checkbox/Checkbox';

const ModalHeaderActions = ({ 
  children, 
  onDelete, 
  maskSettings, 
  onChangeMaskSettings, 
  permissions 
}) => {
  return (
    <Container>
      <CheckboxContainer>
        <Checkbox
          style={{ margin: 0 }}
          checked={maskSettings?.facility_mask}
          onChange={e => onChangeMaskSettings("facility_mask", e)}
          label="Mask Address in CTG Submission" />
        <Checkbox
          style={{ margin: 0 }}
          checked={maskSettings?.contact_mask}
          onChange={e => onChangeMaskSettings("contact_mask", e)}
          label="Mask Contacts in CTG Submission" />
        <Checkbox
          style={{ margin: 0 }}
          checked={maskSettings?.investigator_mask}
          onChange={e => onChangeMaskSettings("investigator_mask", e)}
          label="Mask Investigators in CTG Submission" />
      </CheckboxContainer>
      <ActionContainer>
        {onDelete && permissions["study.site.delete"] &&
          <Button
            style={{
              float: "right",
              width: "10rem",
              display: "block",
              position: 'absolute',
              bottom: 16,
              right: 0
            }}
            size="sm"
            variant="danger"
            onClick={onDelete}>
            <i className="fas fa-trash-alt" style={{ marginRight: 12 }} />
            Delete Site
          </Button>
        }
      </ActionContainer>
    </Container>
  );
};

export default memo(ModalHeaderActions);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 8px;
`

const ActionContainer = styled.div`
  position: relative;
`
