import { useState, useEffect } from "react";

import PrimeField from "components/PrimeField/PrimeField";
import Note from "components/Note/Note";
import { Select } from "components-design-system";

// hoc 
import InputWrapper from '../../hoc/InputWrapper';

const RJDropdown = (props) => {
  const [items, setItems] = useState([]);
  let {
    id,
    carbonProps,
    options,
    value,
    onChange,
    invalid,
    invalidText,
  } = props;
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    if (options && options.enumOptions) {
      let newItems = options.enumOptions.map((option) => {
        return { id: option.value, text: option.value };
      });
      setItems(newItems);
    }
  }, [options]);

  useEffect(() => {
    if (value && typeof value !== 'object') {
      setSelectedItem({ id: value, text: value });
    }
  }, [value])

  return (
    <div
      id={`${id}-field-wrap`}
      style={{ position: "relative" }}
    >
      <Select
        id={`${id}-field`}
        disabled={carbonProps?.disabled}
        options={options.enumOptions}
        onChange={onChange}
        value={selectedItem?.text}
        getPopupContainer={() => document.getElementById(`${id}-field-wrap`)}
      />
      {invalid && <Note type="error" text={invalidText} style={{ marginTop: 4 }} />}
    </div>
  );
};

export default InputWrapper(RJDropdown);