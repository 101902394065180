import { create } from 'zustand'

const initialState = {
  openComment: null,
  commentsMap: {},
}

const useAuthoringCommentsStore = create((set) => ({
  ...initialState,
  setOpenComment: (openComment) => set({ openComment }),
  setCommentsMap: (commentsMap) => set({ commentsMap }),
  resetAuthoringCommentsStore: () => set(initialState),
}))

export default useAuthoringCommentsStore;