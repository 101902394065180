import { eupasCountryRegulator, studyScopes, eupasCenters } from '../enum/enum';
// import { widgetContainer } from '../../uiSchemas/styles';

import { countries, studyDesigns, contactTitles, eupasSourcesOfData, enceppRegisteredNetworks, enceppRegisteredDataSources, ctisLanguages } from '../enum/enum';
import { ctisProperties } from './ctisUtils';

export const eupasProperties = {
  study_identification: {
    is_requested_by_regulator: {
      type: "string",
      enum: ["Yes", "No", "Don't know"]
    },
    is_study_required_by_risk_management_plan: {
      type: "string",
      enum: [
        "Unselected",
        "Not Applicable",
        "EU RMP category 1 (imposed as condition of marketing authorisation)",
        "EU RMP category 2 (specific obligation of marketing authorisation)",
        "EU RMP category 3 (required)",
        "Non-EU RMP only"
      ]
    },
    regulatory_procedure_number: {
      type: "string",
    },
    other_study_identification_numbers: {
      type: "string",
    }
  },
  contact_information: {
    is_study_conducted_by_registered_encepp: {
      type: "string",
      enum: ["Yes", "No"]
    },
    is_study_collaboration_research_network: {
      type: "string",
      enum: ["Yes", "No"]
    },
    other_centers_study_conducted: {
      type: "string",
      enum: ["Not applicable (single center)", "Multiple centers"]
    },
    country_study_is_conducted: {
      type: "string",
      enum: ["National study", "International study"]
    },
    investigator_contact_details: {
      type: "object",
      properties: {
        lead_investigator: {
          type: "object",
          properties: {
            title: {
              type: "string",
              enum: contactTitles
            },
            first_name: {
              type: "string"
            },
            last_name: {
              type: "string"
            },
            address_1: {
              type: "string"
            },
            address_2: {
              type: "string"
            },
            address_3: {
              type: "string"
            },
            city: {
              type: "string"
            },
            zipcode: {
              type: "string"
            },
            country: {
              type: "string",
              enum: countries
            },
            phone_number: {
              type: "string"
            },
            phone_number_alt: {
              type: "string"
            },
            fax_number: {
              type: "string"
            },
            email: {
              type: "string"
            },
            confirm_email: {
              type: "string"
            },
            // ...ctisProperties.contact_information.investigator_contact_details.properties.lead_investigator.properties,
          }
        },
        scientific_contact: {
          type: "object",
          properties: {
            copy_lead_investigator: {
              type: "string"
            },
            title: {
              type: "string",
              enum: contactTitles
            },
            first_name: {
              type: "string"
            },
            last_name: {
              type: "string"
            },
            address_1: {
              type: "string"
            },
            address_2: {
              type: "string"
            },
            address_3: {
              type: "string"
            },
            city: {
              type: "string"
            },
            zipcode: {
              type: "string"
            },
            country: {
              type: "string",
              enum: countries
            },
            phone_number: {
              type: "string"
            },
            phone_number_alt: {
              type: "string"
            },
            fax_number: {
              type: "string"
            },
            email: {
              type: "string"
            },
            confirm_email: {
              type: "string"
            },
            // ...ctisProperties.contact_information.investigator_contact_details.properties.scientific_contact.properties,
          }
        },
        public_contact: {
          type: "object",
          properties: {
            copy_lead_investigator: {
              type: "string"
            },
            title: {
              type: "string",
              enum: contactTitles
            },
            first_name: {
              type: "string"
            },
            last_name: {
              type: "string"
            },
            address_1: {
              type: "string"
            },
            address_2: {
              type: "string"
            },
            address_3: {
              type: "string"
            },
            city: {
              type: "string"
            },
            zipcode: {
              type: "string"
            },
            country: {
              type: "string",
              enum: countries
            },
            phone_number: {
              type: "string"
            },
            phone_number_alt: {
              type: "string"
            },
            fax_number: {
              type: "string"
            },
            email: {
              type: "string"
            },
            confirm_email: {
              type: "string"
            },
            // ...ctisProperties.contact_information.investigator_contact_details.properties.public_contact.properties,
          }
        },
        // contact_ctis: ctisProperties.contact_information.investigator_contact_details.properties.contact_ctis
      }
    },
  },
  study_timeline: {
    signed_funding_contract_date: {
      type: "object",
      properties: {
        planned: {
          type: "string"
        },
        actual: {
          type: "string"
        }
      }
    },
    data_collection_start_date: {
      type: "object",
      properties: {
        planned: {
          type: "string"
        },
        actual: {
          type: "string"
        }
      }
    },
    data_analysis_start_date: {
      type: "object",
      properties: {
        planned: {
          type: "string"
        },
        actual: {
          type: "string"
        }
      }
    },
    interim_report_date: {
      type: "object",
      properties: {
        planned: {
          type: "string"
        },
        actual: {
          type: "string"
        }
      }
    },
    final_study_report_date: {
      type: "object",
      properties: {
        planned: {
          type: "string"
        },
        actual: {
          type: "string"
        }
      }
    },
    funding_source: {
      type: "object",
      properties: {
        pharmaceutical_companies: {
          type: "object",
          properties: {
            names: {
              type: "string"
            },
            funding: {
              type: "number"
            }
          }
        },
        charities: {
          type: "object",
          properties: {
            names: {
              type: "string"
            },
            funding: {
              type: "number"
            }
          }
        },
        government_body: {
          type: "object",
          properties: {
            names: {
              type: "string"
            },
            funding: {
              type: "number"
            }
          }
        },
        research_councils: {
          type: "object",
          properties: {
            names: {
              type: "string"
            },
            funding: {
              type: "number"
            }
          }
        },
        eu_funding_scheme: {
          type: "object",
          properties: {
            names: {
              type: "string"
            },
            funding: {
              type: "number"
            }
          }
        },
        other_funding_sources: {
          type: "array",
          minItems: 1,
          maxItems: 4,
          items: {
            type: "object",
            properties: {
              names: {
                type: "string"
              },
              funding: {
                type: "number"
              }
            }
          }
        }
      }
    }
  },
  study_description: {
    has_medical_condition: {
      type: "string",
      enum: ["Yes", "No"]
    },
    medical_conditions: {
      type: "array",
      items: {
        type: "object",
        onAddButtonTitle: "Add public title translation",
        properties: {
          condition: {
            type: "string"
          },
          translations: {
            type: "array",
            items: {
              type: "object",
              onAddButtonTitle: "Add public title translation",
              properties: {
                language: {
                  type: "string",
                  enum: ctisLanguages
                },
                translation: {
                  title: "Identifier",
                  type: "string",
                }
              },
            }
          },
          is_medical_condition_rare: {
            type: "array",
            uniqueItems: true,
            items: {
              type: "string",
              enum: [true, false],
            }
            // type: "array",
            // items: {
            //   type: "string",
            //   enum: [true],
            // }
          }
        }
      }
    },
    additional_medical_conditions: {
      type: "string"
    }
  },
  study_scope: {
    scope_of_study: {
      type: "array",
      uniqueItems: true,
      items: {
        type: "string",
        enum: studyScopes
      }
    },
    primary_scope: {
      type: "string",
      enum: studyScopes
    },
    other_scope_description: {
      type: "string"
    },
    design_of_study: {
      type: "array",
      uniqueItems: true,
      items: {
        type: "string",
        enum: studyDesigns
      }
    },
    other_design_description: {
      type: "string"
    },
  },
  study_eligibilty: {
    population_under_study: {
      type: "object",
      properties: {
        age: {
          type: "array",
          uniqueItems: true,
          items: {
            type: "string",
            enum: [
              "Preterm newborns",
              "Term newborns (0-27 days)",
              "Infants and toddlers (28 days - 23 months)",
              "Children (2 - 11 years)",
              "Adolescents (12 - 17 years)",
              "Adults (18 - 44 years)",
              "Adults (45 - 64 years)",
              "Adults (65 - 74 years)",
              "Adults (74 years and over)"
            ]
          }
        },
        other_population: {
          type: "array",
          uniqueItems: true,
          items: {
            type: "string",
            enum: ["Unselected", "Renal impaired", "Hepatic impaired", "Immunocompromised", "Pregnant women"]
          }
        }
      }
    },
    additional_patient_information: {
      type: "string"
    },
    is_patients_followed_up: {
      type: "string",
      enum: ["Yes", "Not applicable/no follow-up"]
    },
    study_carried_with_established_data_source: {
      type: "string",
      enum: ["Yes", "No"],
    },
    sources_of_data: {
      type: "array",
      uniqueItems: true,
      items: {
        type: "string",
        enum: eupasSourcesOfData
      }
    },
    other_data_source: {
      type: "string"
    }
  },
  study_arms: {
    has_drug_information: {
      type: "string",
      enum: ["Yes", "Not applicable (disease/epidemiology study)"]
    },
  },
  study_reference: {
    is_requesting_encepp_seal: {
      type: "string",
      enum: ["Yes", "No"]
    },
    make_full_protocol_public_when_study_ends: {
      type: "string",
      enum: ["Yes", "No"]
    },
    study_publications: {
      type: "array",
      minItems: 1,
      maxItems: 5,
      items: {
        type: "object",
        properties: {
          reference: {
            type: "string"
          },
          url: {
            type: "string"
          }
        }
      }
    }
  },
  study_outcome_measures: {
    outcome_measures_eupas: {
      type: "object",
      properties: {
        primary_outcome_measures: {
          type: "object",
          properties: {
            description: {
              type: "string",
            },
          }
        },
        secondary_outcome_measures: {
          type: "object",
          properties: {
            description: {
              type: "string",
            },
          }
        }
      }
    },
    statistical_analysis_plan: {
      type: "string"
    }
  }
}

export const eupasDependencies = {
  study_identification: {
    is_requested_by_regulator: {
      oneOf: [
        {
          properties: {
            is_requested_by_regulator: {
              enum: ["Yes"]
            },
            country_regulator: {
              enum: eupasCountryRegulator
            },
            country_regulator_not_listed: {
              type: "string"
            }
          }
        },
        {
          properties: {
            is_requested_by_regulator: {
              enum: ["No", "Don't know"]
            }
          }
        }
      ]
    }
  },
  contact_information: {
    is_study_conducted_by_registered_encepp: {
      oneOf: [
        {
          properties: {
            is_study_conducted_by_registered_encepp: {
              enum: ["Yes"]
            },
            investigator_center: {
              type: "string",
              enum: eupasCenters
            },
            department_research_group: {
              type: "string"
            },
            encepp_center_organization_affiliation: {
              type: "string"
            },
            encepp_center_website: {
              type: "string"
            }
          }
        },
        {
          properties: {
            is_study_conducted_by_registered_encepp: {
              enum: ["No"]
            },
            research_center_name: {
              type: "string"
            },
            research_center_location: {
              type: "string"
            }
          }
        }
      ]
    },
    is_study_collaboration_research_network: {
      oneOf: [
        {
          properties: {
            is_study_collaboration_research_network: {
              enum: ["Yes"]
            },
            networks_registered_encepp: {
              type: "string",
              enum: enceppRegisteredNetworks
            },
            other_networks: {
              type: "array",
              minItems: 1,
              maxItems: 4,
              items: {
                type: "string",
                label: "Network",
                uiSchema: {
                  required: false,
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              }
            }
          }
        },
        {
          properties: {
            is_study_collaboration_research_network: {
              enum: ["No"]
            }
          }
        }
      ]
    },
    other_centers_study_conducted: {
      oneOf: [
        {
          properties: {
            other_centers_study_conducted: {
              enum: ["Not applicable (single center)"]
            }
          }
        },
        {
          properties: {
            other_centers_study_conducted: {
              enum: ["Multiple centers"]
            },
            number_of_centers: {
              type: "number"
            },
            centers_registered_encepp: {
              type: "array",
              uniqueItems: true,
              items: {
                type: "string",
                enum: eupasCenters
              }
            },
            other_centers: {
              type: "array",
              minItems: 1,
              maxItems: 10,
              items: {
                type: "object",
                onAddButtonTitle: "Add Center",
                properties: {
                  name: {
                    type: "string"
                  },
                  location: {
                    type: "string"
                  }
                }
              }
            }
          }
        }
      ]
    },
    country_study_is_conducted: {
      oneOf: [
        {
          properties: {
            country_study_is_conducted: {
              enum: ["National study"]
            },
            national_country: {
              type: "string",
              enum: countries
            }
          }
        },
        {
          properties: {
            country_study_is_conducted: {
              enum: ["International study"]
            },
            international_countries: {
              type: "array",
              uniqueItems: true,
              items: {
                type: "string",
                enum: countries
              }
            }
          }
        },

      ]
    }
  },
  study_eligibilty: {
    is_patients_followed_up: {
      oneOf: [
        {
          properties: {
            is_patients_followed_up: {
              enum: ["Yes"]
            },
            follow_up_description: {
              type: "string"
            }
          }
        },
        {
          properties: {
            is_patients_followed_up: {
              enum: ["Not applicable/no follow-up"]
            }
          }
        }
      ]
    },
    study_carried_with_established_data_source: {
      oneOf: [
        {
          properties: {
            study_carried_with_established_data_source: {
              enum: ["Yes"]
            },
            registered_data_sources: {
              type: "array",
              uniqueItems: true,
              items: {
                type: "string",
                enum: enceppRegisteredDataSources
              }
            },
            unregistered_data_sources: {
              type: "array",
              minItems: 1,
              maxItems: 5,
              items: {
                type: "object",
                properties: {
                  name: {
                    type: "string"
                  },
                  country: {
                    type: "string",
                    enum: countries
                  }
                }
              }
            }
          }
        },
        {
          properties: {
            study_carried_with_established_data_source: {
              enum: ["No"]
            }
          }
        },
      ]
    }
  },
  study_description: {
    has_medical_condition: {
      oneOf: [
        {
          properties: {
            has_medical_condition: {
              enum: ["Yes"]
            },
            medical_conditions: {
              type: "array",
              items: {
                type: "object",
                // onAddButtonTitle: "Add public title translation",
                properties: {
                  condition: {
                    type: "string"
                  },
                  translations: {
                    type: "array",
                    items: {
                      type: "object",
                      // onAddButtonTitle: "Add public title translation",
                      properties: {
                        language: {
                          type: "string",
                          enum: ctisLanguages
                        },
                        translation: {
                          title: "Identifier",
                          type: "string",
                        }
                      },
                    }
                  },
                  is_medical_condition_rare: {
                    type: "string"
                  }
                }
              }
            },
            // medical_conditions: {
            //   type: "array",
            //   items: {
            //     type: "string",
            //     label: "Condition",
            //     uiSchema: {
            //       required: false,
            //       styles: {
            //         field: widgetContainer.xl,
            //         widgetContainer: widgetContainer.xl
            //       }
            //     }
            //   }
            // },
            additional_medical_conditions: {
              type: "string"
            }
          },
        },
        {
          properties: {
            has_medical_condition: {
              enum: ["No"]
            }
          }
        }
      ]
    }
  },
  study_arms: {
    has_drug_information: {
      oneOf: [
        {
          properties: {
            has_drug_information: {
              enum: ["Yes"]
            },
            study_drugs: {
              type: "array",
              minItems: 1,
              maxItems: 10,
              items: {
                type: "object",
                properties: {
                  drug_type: {
                    type: "string",
                    enum: ["Substance class", "Single-Constituent", "Multi-Constituent", "Brand Product Name"]
                  }
                },
                dependencies: {
                  drug_type: {
                    oneOf: [
                      {
                        properties: {
                          drug_type: {
                            enum: ["Substance class"]
                          },
                          atc_code: {
                            type: "string"
                          }
                        }
                      },
                      {
                        properties: {
                          drug_type: {
                            enum: ["Single-Constituent"]
                          },
                          substance_inn: {
                            type: "string"
                          }
                        }
                      },
                      {
                        properties: {
                          drug_type: {
                            enum: ["Multi-Constituent"]
                          },
                          substance_inns: {
                            type: "array",
                            minItems: 1,
                            maxItems: 10,
                            items: {
                              type: "string",
                              label: "Substance INN",
                              uiSchema: {
                                classNames: {
                                  fieldContainer: "rjs-col-1-3"
                                }
                              }
                            }
                          }
                        }
                      },
                      {
                        properties: {
                          drug_type: {
                            enum: ["Brand Product Name"]
                          },
                          product_name: {
                            type: "string"
                          },
                          country: {
                            type: "string",
                            enum: countries
                          },
                          substance_inns: {
                            type: "array",
                            items: {
                              type: "string",
                              uiSchema: {
                                classNames: {
                                  fieldContainer: "rjs-col-1-3"
                                }
                              }
                            }
                          }
                        }
                      },
                    ]
                  }
                }
              }
            }
          }
        },
        {
          properties: {
            has_drug_information: {
              enum: ["Not applicable (disease/epidemiology study)"]
            },
          },
        }
      ]
    }
  },
}