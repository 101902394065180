import React, { useState, useEffect, useContext } from "react";
import PrimeField from "components/PrimeField/PrimeField";

// hoc 
import InputWrapper from '../../hoc/InputWrapper';

// styles
import './RJSingleCheckbox.scss'

const RJSingleCheckbox = (props) => {
  let {
    id,
    carbonProps,
    options,
    value,
    onChange,
  } = props;
  let checked = false;
  if(value === false || value == true) checked = value;

  return (
    <div className="rjs-single-checkbox-widget">
      <PrimeField
        schema={{
          type: "checkbox",
          id: props.id + "-single-checkbox-field",
          label: options.title
        }}
        value={checked}
        onChange={onChange}/>
    </div>
  );
};

export default InputWrapper(RJSingleCheckbox);