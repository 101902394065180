import { useState, useEffect } from 'react';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { useShallow } from 'zustand/react/shallow'

// components
import RJAdverseEvents from './RJAdverseEvents';
import CellValidation from '../components/CellValidation/CellValidation';
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';
import useAuthoringVersionStore from '../../../hooks/stores/useAuthoringVersionStore';
import useAuthoringStore from '../../../hooks/stores/useAuthoringStore';

const AdverseWrapper = (props) => {
  const {
    onChange
  } = props

  const {
    formDataRef,
  } = props.formContext

  const formWidthClassName = useAuthoringStore(state => state.formWidthClassName)

  const {
    versionData,
    selectedVersion
  } = useAuthoringVersionStore(
    useShallow(state => ({
      versionData: state.versionData,
      selectedVersion: state.selectedVersion,
    }))
  )

  const {
    readOnly,
    showVersionCompare,
    formStatus,
    refreshFormData
  } = useAuthoringViewStore(
    useShallow(state => ({ 
      readOnly: state.readOnly,
      showVersionCompare: state.showVersionCompare,
      formStatus: state.formStatus,
      refreshFormData: state.refreshFormData,
    }))
  )

  // baseline component
  const [adverseData, setAdverseData] = useStateWithCallbackLazy({
    ctg: {
      time_frame: null,
      notes: null,
      source_vocabulary: null,
      dictionary_version: null,
      assessment_type: null,
      frequency_reporting_threshold: null,
      groups: [],
      serious_adverse_events: [],
      non_serious_adverse_events: []
    },
    eudract: {
      time_frame: null,
      notes: null,
      assessment_type: null,
      frequency_reporting_threshold: null,
      dictionary_name: null,
      other_dictionary_name: null,
      dictionary_version: null,
      groups: [],
      serious_adverse_events: [],
      non_serious_adverse_events: []
    }
  })
  const [tableMounting, setTableMounting] = useState(true)

  useEffect(() => {
    if (formStatus === "ready" && adverseData && readOnly === false && tableMounting !== true) {
      onChange(adverseData)
    }
  }, [adverseData])

  useEffect(() => {
    if (formDataRef?.results?.adverse_events?.adverse_events_table) {
      setAdverseData(formDataRef.results.adverse_events.adverse_events_table)
      setTableMounting(false)
    } else {
      setTableMounting(false)
    }

    return () => {
      setTableMounting(true)
    }
  }, [])

  useEffect(() => {
    if (formDataRef?.results?.adverse_events?.adverse_events_table) {
      setAdverseData(formDataRef.results.adverse_events.adverse_events_table)
      setTableMounting(false)
    }
  }, [refreshFormData])

  if (tableMounting) return <></>

  return (
    <div className={["form-table-print", formWidthClassName].join(" ")}>
      <RJAdverseEvents
        {...props}
        adverseData={adverseData}
        setAdverseData={setAdverseData} />
      {versionData && selectedVersion && showVersionCompare && (
        <div className="rjs-table-comparison-wrapper">
          <RJAdverseEvents
            {...props}
            adverseData={versionData?.study_data?.results?.adverse_events?.adverse_events_table}
            setAdverseData={() => { }} />
        </div>
      )}
      <CellValidation
        table="adverse"
        errorKey="results_adverse_events" />
    </div>
  );
};

export default AdverseWrapper;