import React, { useState } from "react"
import { useParams } from "react-router-dom"
import GridTypeA from 'components/grids/GridTypeA/GridTypeA'
import DecisionTreeModal from "components/react-flow/DecisionTreeModal/DecisionTreeModal"

// cell Renderers
import AssessmentLinkCellRenderer from "./AssessmentLinkCellRenderer/AssessmentLinkCellRenderer"
import AssessmentsTagsCellRenderer from "./AssessmentsTagsCellRenderer/AssessmentsTagsCellRenderer"
import AssessmentResultCellRenderer from "components/ag-grid-cell-renderers/AssessmentResultCellRenderer/AssessmentResultCellRenderer"
import DueDateCellRenderer from "./DueDateCellRenderer/DueDateCellRenderer"
import ActivityCellRendererTable from "./ActivityCellRendererTable/ActivityCellRendererTable"

// constants
import { resultsFilterLabels } from "constants/assessmentResults"

// api
import { useGetActivityTypes } from "api/hooks/activities/useActivitiesApi"
import { useGetAssessmentTypes } from "api/hooks/admin/useAssessmentTypesApi"
import { useGetStudyAssessment } from "api/hooks/studies/useAssessmentsApi"

const AssessmentsGrid = ({
  gridRef,
  rowData,
  onFilterChanged,
  permissions,
  toggleCreateActivityModal,
  setMissingFields,
}) => {
  const { studyId } = useParams()

  // activities
  const { data: activityTypes } = useGetActivityTypes()

  // decision tree modal
  const [treeModalOpen, setTreeModalOpen] = useState(false)
  // selected decision tree
  const { data: assessmentTypes } = useGetAssessmentTypes()
  const [selectedDt, setSelectedDt] = useState(null)
  // selected assessment metadata
  const [selectedAssessId, setSelectedAssessId] = useState()
  const { data: selectedAssessment } = useGetStudyAssessment(studyId, selectedAssessId)

  const getSelectedDt = (assessmentName) => {
    return assessmentTypes?.find((type) => type.name === assessmentName)?.dt
  }

  /**
   * Steps to open decision tree modal:
   * Get decision tree from assessment types (plural) api.
   * Get assessment's metadata from assessment (singular) api.
   */
  const openDecisionTree = async (assessItem) => {
    // load selected assessment for metadata
    setSelectedAssessId(assessItem.id)
    // set decision tree
    let dtData = getSelectedDt(assessItem.assessment_name)
    setSelectedDt(dtData)
    setTreeModalOpen(true)
  }

  const getRowHeight = ({
    data,
  }) => {
    if (data.activities) {
      const numRows = data.activities.length || 1
      return 35 * numRows
    }
  }

  const columnDefs = [
    {
      headerName: "Region",
      field: "region",
      width: 140,
    },
    {
      headerName: "Assessment",
      field: "assessment_name",
      cellRenderer: AssessmentLinkCellRenderer,
      cellRendererParams: { studyId },
      flex: 1,
      minWidth: 176,
    },
    {
      headerName: "Tags",
      field: "tags",
      cellRenderer: AssessmentsTagsCellRenderer,
      width: 93,
    },
    {
      headerName: "Type",
      field: "sub_type",
      width: 116,
    },
    {
      field: "registry",
      cellRenderer: "RegistryCellRenderer",
      width: 111,
      resizable: false,
    },
    {
      headerName: "Result",
      field: "result",
      cellRenderer: AssessmentResultCellRenderer,
      cellRendererParams: {
        resultsFilterLabels,
        setMissingFields,
        openDecisionTree,
      },
      cellStyle: { paddingTop: 4 },
      width: 187,
    },
    {
      headerName: "Due",
      field: "assessment_due_date",
      cellRenderer: DueDateCellRenderer,
      type: "date",
      width: 120,
    },
    {
      headerName: "Activity",
      field: "attached_projects",
      cellRenderer: ActivityCellRendererTable,
      cellStyle: { padding: 0 },
      cellRendererParams: {
        activityTypes,
        toggleModal: toggleCreateActivityModal,
        permissions,
      },
      valueGetter: ({ data, ...params }) => {
        if (data.attached_projects) return data.attached_projects.map((actObj) => actObj.title)
        return ''
      },
      width: 250,
    },
    {
      headerName: "Run Date",
      field: "run_date",
      type: "date",
      width: 120,
      hide: true,
    },
    {
      headerName: "Assessment Inputs",
      field: "assessment_inputs",
      filter: "agTextColumnFilter",
      width: 300,
      hide: true,
    },
    {
      headerName: "Result Issues",
      field: "res_issues",
      width: 300,
      hide: true,
    },
    {
      headerName: "State",
      field: "project_state",
      hide: true,
    },
    {
      headerName: "Status",
      field: "project_status",
      hide: true,
    },
  ]

  return <div className="assessments-grid-container">
    <GridTypeA
      gridRef={gridRef}
      rowData={rowData}
      columnDefs={columnDefs}
      onFilterChanged={onFilterChanged}
      // pagination={true}
      // paginationPageSize={13}
      getRowHeight={getRowHeight}
      // showRowCount
      domLayout="autoHeight"
    />
    {selectedDt && selectedAssessment &&
      <DecisionTreeModal
        dt={{ schema: selectedAssessment?.res_schema }}
        assessment={selectedAssessment}
        showModal={treeModalOpen}
        onHide={() => {
          setTreeModalOpen(false)
          setSelectedDt(null)
        }}
      />
    }
  </div>
}

export default AssessmentsGrid