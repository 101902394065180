import { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid'
import { useShallow } from 'zustand/react/shallow'

// components
import PrimeField from 'components/PrimeField/PrimeField';
import { Button } from 'components-design-system';

// context
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';

const Product = ({
  participantData,
  setParticipantData,
  selectedArm,
  selectedProduct,
  schema,
  onCloseProduct,
  onUpdateProduct,
  onAddProduct
}) => {
  const {
    readOnly
  } = useAuthoringViewStore(
    useShallow(state => ({ readOnly: state.readOnly }))
  )

  const [productListOptions, setProductListOptions] = useState([]);
  const [productData, setProductData] = useState({});
  const [productListDetails, setProductListDetails] = useState([]);
  const productSchema = schema.properties.eudract.properties.products.properties;

  useEffect(() => {
    setProductData(selectedProduct)
  }, [selectedProduct])

  // keeps track of all products for copy
  useEffect(() => {
    if (participantData?.eudract_periods?.length > 0) {
      let productListDetails = [];
      let productListOptions = []
      participantData.eudract_periods.forEach(period => {
        if (period?.arms?.length > 0) {
          period.arms.forEach(arm => {
            if (arm?.products?.length > 0) {
              arm.products.forEach(product => {
                let label = `${period.title} / ${arm.title} / ${product.imp_name}`
                productListOptions.push({
                  id: product.id,
                  text: label
                })
                productListDetails.push({
                  ...product
                })
              })
            }
          })
        }
      })

      setProductListDetails(productListDetails);
      setProductListOptions(productListOptions);
    }
  }, [participantData]);

  const onCopyProduct = (e) => {
    let foundProduct = productListDetails.find(product => product.id === e.id);

    if (foundProduct) {
      let copiedProduct = {
        ...foundProduct,
        id: `product-${uuid()}`
      }
      setProductData(copiedProduct)
    }
  }

  const handleChange = (e, key) => {
    let temp = { ...productData };
    temp[key] = e;
    setProductData(temp)
  }

  const onCloseModal = () => {
    onCloseProduct()
    setProductData({})
  }

  const onSubmit = () => {
    if (selectedProduct) onUpdateProduct({ selectedArm, product: productData })
    else onAddProduct({ selectedArm, product: productData })
    onCloseModal()
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 8, marginTop: 24 }}>
      {readOnly !== true &&
        <div>
          <PrimeField
            schema={{
              type: "dropdown",
              label: "Copy product",
              orientation: "vertical",
              items: productListOptions,
              style: { marginBottom: 12 }
            }}
            value={null}
            onChange={e => {
              let foundItem = productListOptions.find(item => item.id === e)
              onCopyProduct(foundItem)
            }}
            readOnly={readOnly}
          />

        </div>
      }
      {Object.keys(productSchema).map((key, i) => {
        return (
          <PrimeField
            key={productSchema[key].title + i}
            schema={{
              type: productSchema[key].type,
              label: productSchema[key].title,
              items: productSchema[key].enum,
              orientation: "vertical",
            }}
            readOnly={readOnly}
            value={productData?.[key] || ""}
            onChange={e => handleChange(e, key)} />
        )
      })}
      <div style={{ display: "flex", gap: 8, justifyContent: "center", marginTop: 24 }}>
        <Button
          onClick={() => onCloseModal()}
          variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={() => onSubmit()}
          variant="primary">
          Save
        </Button>
      </div>
    </div>
  );
};

export default Product;