import React from 'react';
import styled from 'styled-components';

const TableVersionComparisonWrapper = (props) => {
  const {
    className
  } = props;
  
  return (
    <Container className={className}>
      {props.children}
    </Container>
  );
};

export default TableVersionComparisonWrapper;

const Container = styled.div`
  padding: 24px;
  background-color: #F4F4F4;
  margin-top: 12px;
  margin-bottom: 24px;
`