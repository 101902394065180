import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'
import ActiveSponsorTag from '../ActiveSponsorTag/ActiveSponsorTag'
import './ActiveSponsorTags.scss'

const ActiveSponsorTags = ({
  tagMaxWidth,
  sponsors,
  activeSponsors,
  handleDeleteSponsor,
  handleDeleteAndSaveGroup,
  modalIsOpen,
  setModalIsOpen,
  handleDeleteAndSave
}) => {
  if (activeSponsors?.[0] === "all") {
    return (
      <div className="sponsor-tags">
        <Tooltip
          placement="bottom"
          title={<div className="global-sponsor-tooltip-container">
            {
              activeSponsors.map((sponsor) => {
                return (
                  <div
                    key={sponsor}
                    className="tooltip-sponsor-text"
                  >{sponsor}</div>
                )
              })
            }
          </div>}
        >
          <>
            <ActiveSponsorTag
              text={`All Sponsors (${sponsors.length})`}
              onDelete={() => setModalIsOpen((val) => !val)}
            />
          </>
        </Tooltip>
      </div>
    )
  }
  if (activeSponsors.length <= 4) {
    return <div className="sponsor-tags">
      {activeSponsors.map(sponsor => {
        return <Fragment key={sponsor}>
          <ActiveSponsorTag
            text={sponsor}
            onDelete={handleDeleteAndSave}
            disabled={activeSponsors.length === 1}
            maxWidth={tagMaxWidth}
          />
        </Fragment>
      })}
    </div>
  }
  return (
    <div className="sponsor-tags">
      {[0, 1, 2].map((index) => <Fragment
        key={activeSponsors[index]}
      >
        <ActiveSponsorTag
          text={activeSponsors[index]}
          onDelete={handleDeleteAndSave}
          maxWidth={tagMaxWidth}
        />
      </Fragment>)}
      <Tooltip
        placement="bottom"
        title={<div className="global-sponsor-tooltip-container">
          {
            activeSponsors.map((sponsor, index) => {
              if (index > 2) {
                return (
                  <div
                    key={sponsor}
                    className="tooltip-sponsor-text"
                  >{sponsor}</div>
                )
              }
            })
          }
        </div>}
      >
        <ActiveSponsorTag
          text={`+${activeSponsors.length - 3}`}
          onDelete={handleDeleteAndSaveGroup}
        />
      </Tooltip>
    </div>
  )
}

ActiveSponsorTags.propTypes = {
  tagMaxWidth: PropTypes.number,
  sponsors: PropTypes.arrayOf(PropTypes.object),
  activeSponsors: PropTypes.array,
  handleDeleteSponsor: PropTypes.func,
  handleDeleteAndSaveGroup: PropTypes.func,
}

export default ActiveSponsorTags