import React, { Fragment, useState, useEffect, useMemo } from 'react';
import ActiveSponsorTag from '../../ActiveSponsorTag/ActiveSponsorTag'
import './ActiveSponsorsSelected.scss'

const ActiveSponsorsSelected = ({
  sponsors,
  selectedSponsors,
  setSelectedSponsors,
}) => {
  const [extended, setExtended] = useState(true)

  const handleExtend = () => {
    setExtended((val) => !val)
  }

  const handleDeleteSponsor = (sponsor) => {
    const newSponsors = selectedSponsors.filter(item => item !== sponsor)
    setSelectedSponsors(newSponsors)
  }

  const handleDeleteAll = () => {
    setSelectedSponsors([])
  }

  const displaySponsors = useMemo(() => {
    if (selectedSponsors[0] === "all") return sponsors
    return selectedSponsors
  }, [selectedSponsors])

  return (
    <div className="global-sponsor-selected">
      <div className="global-sponsor-header-container">
        <div className="global-sponsor-header">Active Sponsors ({displaySponsors.length})</div>
        <div
          className="clear-all"
          onClick={handleDeleteAll}>
          Deselect All
        </div>
        <div className="chevron-container">
          {extended
            ? <i
              className="fal fa-chevron-up"
              onClick={handleExtend} />
            : <i
              className="fal fa-chevron-down"
              onClick={handleExtend} />
          }
        </div>
      </div>
      {extended &&
        <div className="global-sponsor-body-container">
          {displaySponsors.map(sponsor => {
            return <ActiveSponsorTag
              key={sponsor}
              text={sponsor}
              onDelete={() => handleDeleteSponsor(sponsor)}
            />
          })}
        </div>}
    </div>
  )
}

export default ActiveSponsorsSelected