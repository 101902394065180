import React, { useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { useShallow } from 'zustand/react/shallow'

// components
import PrimeField from 'components/PrimeField/PrimeField';
import SubmissionsActions from './subcomponents/SubmissionsActions/SubmissionsActions';
import UploadModal from './subcomponents/UploadModal/UploadModal';
import PagePreview from '../Authoring/subcomponents/PagePreview/PagePreview';
import GridTypeA from 'components/grids/GridTypeA/GridTypeA';
import Modal from 'components/Modal/Modal';
import PRSUploadProgress from 'components/PRSUploadProgress/PRSUploadProgress';
import DateCellRenderer from 'components/Grid/CellRenderer/Date';

// api
import { useGetStudy } from "api/hooks/studies/useStudiesApi";
import {
  useGetAuthoringStudy,
  useGetAuthoringVersion
} from "api/hooks/studies/useAuthoringApi";
import {
  useGetSubmissionsHistory,
  useGetApprovalHistory,
  usePostSubmission,
  useDownloadXml,
  useGetPrsSubmissionsUpload,
  usePostPrsSubmissionsUpload
} from 'api/hooks/studies/useSubmissionsApi';
import { useGetCtgDocuments } from 'api/hooks/studies/useDocumentsApi';

// cell renderers
import {
  FormViewCellRenderer,
  FormSectionCellRenderer,
  BooleanCellRenderer,
  LinkCellRenderer,
} from 'components/ag-grid-cell-renderers/SubmissionCellRenderers';
import NaCellRenderer from "components/ag-grid-cell-renderers/NaCellRenderer";
import TimeCellRenderer from "components/ag-grid-cell-renderers/TimeCellRenderer";
import DocumentsUploadStatus from 'components/ag-grid-cell-renderers/DocumentsUploadStatus/DocumentsUploadStatus';

// context
import useSubmissions from 'context/hooks/useSubmissions';
import useAppState from 'context/hooks/useAppState';
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';
import useAuthoringPrintStore from '../Authoring/hooks/stores/useAuthoringPrintStore';
import useAuthoringPrintActions from '../Authoring/hooks/actions/useAuthoringPrintActions';

// constants
import {
  getSubmissionRegistryTitles,
  getSubmissionSectionTitles,
  getSubmissionSchemas
} from 'constants/submissions'

// utils
import fileDownload from 'utilities/fileDownload';

import './Submissions.scss'

const registryTitles = getSubmissionRegistryTitles();
const sectionTitles = getSubmissionSectionTitles();

const SUBMISSIONS_COL_DEFS = [
  {
    headerName: "Date",
    field: "created_at",
    type: "dateColumn", // defined in GridTypeA
    cellRenderer: DateCellRenderer,
    width: 125,
    suppressSizeToFit: true
  },
  {
    headerName: "Type",
    field: "download_only",
    valueGetter: (params) => {
      let value = params.data.download_only
      if (value === undefined) return ""
      return value ? "Download" : "Submission"
    },
    width: 100
  },
  {
    headerName: "User",
    field: "created_by_fullname",
  },
  {
    headerName: "Registry",
    field: "form_view",
    cellRenderer: (params) => FormViewCellRenderer(params, registryTitles),
    width: 100
  },
  {
    headerName: "Section",
    field: "form_section",
    cellRenderer: (params) => FormSectionCellRenderer(params, sectionTitles),
    width: 100
  },
  {
    headerName: "Version",
    field: "form_version",
    filter: 'agNumberColumnFilter',
    cellRenderer: ({ value }) => {
      if (String(value)?.toLowerCase() === 'n/a') return value;
      else if (value < 0) return 'N/A'
      return Number(value).toFixed(1).toString();
    },
    width: 100
  },
  {
    headerName: "Reason for submission",
    field: "submission_reason",
    cellRenderer: NaCellRenderer
  },
  {
    headerName: "Document(s) submitted",
    field: "uploaded_docs",
    cellRenderer: DocumentsUploadStatus,
  },
  {
    headerName: "PRS upload status",
    field: "upload_status",
    cellRenderer: NaCellRenderer,
    width: 130
  },
  {
    headerName: "PRS record status",
    field: "record_status",
    cellRenderer: NaCellRenderer,
    width: 130
  },
  {
    headerName: "Auto release?",
    field: "autorelease",
    cellRenderer: BooleanCellRenderer,
    width: 100
  },
  {
    headerName: "Release receipt",
    field: "receipt_url",
    cellRenderer: LinkCellRenderer,
    width: 130
  },
]

const ACTION_HISTORY_COL_DEFS = [
  {
    headerName: "Date",
    field: "created_at",
    type: "dateColumn", // defined in GridTypeA
    cellRenderer: DateCellRenderer,
    suppressSizeToFit: true,
    flex: 1,
    minWidth: 115,
  },
  {
    headerName: "Time",
    field: "created_at",
    cellRenderer: TimeCellRenderer,
    suppressSizeToFit: true,
    flex: 1,
    minWidth: 102,
  },
  {
    headerName: "User",
    field: "created_by_fullname",
    flex: 1,
    minWidth: 101,
  },
  {
    headerName: "Form(s)",
    field: "forms",
    cellRenderer: "FormsCount",
    valueGetter: ({ data }) => {
      return [...data.forms]?.map((form) => {
        if (typeof form === 'string') form = JSON.parse(form);
        return `${form.form_view} ${form.form_section}`
      })?.join(", ")
    },
    comparator: (valueA, valueB) => {
      let countA = 0;
      let countB = 0;
      if (valueA) countA = valueA.split(", ").length;
      if (valueB) countB = valueB.split(", ").length;
      if (countA > countB) return 1;
      else if (countA < countB) return -1
      else return 0
    },
    flex: 1,
    minWidth: 132,
  },
  {
    headerName: "Documents(s)",
    field: "docs",
    cellRenderer: "DocsCount",
    valueGetter: ({ data }) => {
      return [...data.docs].map((doc) => {
        if (typeof doc === 'string') doc = JSON.parse(doc);
        return doc.document_name
      })?.join(', ')
    },
    comparator: (valueA, valueB) => {
      let countA = 0;
      let countB = 0;
      if (valueA) countA = valueA.split(", ").length;
      if (valueB) countB = valueB.split(", ").length;
      if (countA > countB) return 1;
      else if (countA < countB) return -1
      else return 0
    },
    flex: 1,
    minWidth: 150,
  },
  {
    headerName: "Version",
    field: "form_version",
    cellRenderer: ({ value }) => {
      if (value === null) return "N/A"
      if (String(value)?.toLowerCase() === 'N/A') return value;
      else if (value < 0) return 'N/A'
      return Number(value).toFixed(1).toString()
    },
    flex: 1,
    minWidth: 110,
  },
  {
    headerName: "Outcome",
    cellRenderer: ({ data }) => {
      if (data.is_approved) return "Approved";
      else if (data.is_rejected) return "Rejected";
      else if (data.is_onhold) return "On Hold";
      else return null;
    },
    flex: 1,
    minWidth: 122
  },
  {
    headerName: "eSign Failed?",
    field: "esign_failed",
    flex: 1,
    minWidth: 147,
  },
  {
    headerName: "Comments",
    field: "comments",
    flex: 1,
    minWidth: 250,
  }
]

const Submissions = () => {
  const {
    formVersion,
    isAutoRelease,
  } = useSubmissions()

  const {
    onPrintPage
  } = useAuthoringPrintActions()

  const {
    formView,
    formSection,
    showPagePreview,
    setShowPagePreview,
  } = useAuthoringViewStore(
    useShallow(state => ({
      formView: state.formView,
      formSection: state.formSection,
      showPagePreview: state.showPagePreview,
      setShowPagePreview: state.setShowPagePreview,
    }))
  )

  const {
    openToastNotification
  } = useAppState()

  const [searchSubmissions, setSearchSubmissions] = useState("")
  const [openUploadModal, setOpenUploadModal] = useState(false)
  const [openProgressModal, setOpenProgressModal] = useState(false)
  const { studyId } = useParams()
  const { data: study } = useGetStudy(studyId)
  const { data: authoring } = useGetAuthoringStudy(study?.authoring_study_id)
  const { data: submissions = [], refetch: refetchSubmissionHistory } = useGetSubmissionsHistory(study?.authoring_study_id)
  const { data: approvals = [] } = useGetApprovalHistory(study?.authoring_study_id)
  const { data: ctgDocuments = [] } = useGetCtgDocuments(study?.authoring_study_id)
  const { data: authoringVersionFormData } = useGetAuthoringVersion(study?.authoring_study_id, formVersion?.id)
  const postSubmission = usePostSubmission(study?.authoring_study_id)
  const downloadXml = useDownloadXml(study?.authoring_study_id)
  const [queueId, setQueueId] = useState(null)
  const postPrsSubmissionsUpload = usePostPrsSubmissionsUpload()

  const { data: pollingData } = useGetPrsSubmissionsUpload(queueId, {
    refetchInterval: queueId ? 1000 : null,
    enabled: queueId ? true : false
  })

  const onDownloadPdf = async () => {
    const req = {
      study_id: authoring.id,
      form_view: formView.id,
      form_section: formSection.id,
      form_version: Number(formVersion.id),
      download_only: true,
      // TODO: do not send organization_name when API updated to not require it
      organization_name: "Xogene",
    };
    setShowPagePreview(true)
    await new Promise(r => setTimeout(r, 800));

    onPrintPage()
    setTimeout(() => {
      setShowPagePreview(false)
    }, 2000)
    await postSubmission({ body: req })
  }

  const onDownloadXml = () => {
    const req = {
      study_id: authoring.id,
      form_view: formView.id,
      form_section: formSection.id,
      form_version: Number(formVersion.id),
      // TODO: do not send organization_name when API updated to not require it
      organization_name: "Xogene",
    };

    if (req.form_section === 'delayedResultsCertification') req.form_section = getSubmissionSchemas()['delayedResultsCertification'].submitToCTG;

    downloadXml({ body: req }, {
      onSuccess: (res) => {
        fileDownload(res.data.xml_str, `${authoring.study_id}_${req.form_view}_${formSection.id}.xml`, 'application/xml');
      }
    })
  }

  const onSubmitToCtg = (payload) => {
    openToastNotification("info", "Pending", "Submitting record in progress")
    postPrsSubmissionsUpload({
      body: payload
    }, {
      onSuccess: (resp) => {
        const { queue_id } = resp.data
        setQueueId(queue_id)
      }
    })
  }

  useEffect(() => {
    if (pollingData?.task_status?.complete === true && queueId) {
      setOpenProgressModal(true)
      onStopPolling(true)
      refetchSubmissionHistory()
    }
  }, [pollingData, queueId])

  const hasErrors = useMemo(() => {
    if ((pollingData?.upload_report?.failureMessage && pollingData?.upload_report?.validationMessages) || pollingData?.task_status?.state === "FAILURE") {
      return true
    }
    return false
  }, [pollingData])

  const onStopPolling = () => {
    setQueueId(null)
  }

  const getPrsSummaryGridDataFormat = (data) => {
    if (data) {
      let in_progress = data.in_progress;

      let formattedData = {
        autorelease: data.autorelease,
        in_progress,
        is_cancelled: null,
      };
      let upload_report = data?.upload_report ? data?.upload_report : {}
      if (!upload_report?.uploadStatus) upload_report.uploadStatus = ["ERROR", "ERROR"]

      if (pollingData?.task_status?.state === "FAILURE") {
        upload_report = {
          ...upload_report,
          uploadStatus: "ERROR",
          failureMessage: pollingData?.task_status.result || "N/A"
        }
      }

      let uploadRecord = {
        study_id: data.study_id,
        receipt_url: data.receipt_url,
        upload_report,
      };
      formattedData.upload_records = [uploadRecord];
      return formattedData;
    }

    return null
  };

  return (
    <div className="study-submissions-tab">
      <SubmissionsActions
        authoring={authoring}
        setOpenUploadModal={setOpenUploadModal}
        className="print-hide"
        onDownloadPdf={onDownloadPdf}
        onDownloadXml={onDownloadXml} />
      <div className="study-submission-panel print-hide">
        <div className="study-submission-head">
          <p className="study-submissions-title">Submission/Download History</p>
          <div className="study-submissions-search">
            <PrimeField
              schema={{
                type: "text",
                placeholder: "Search",
              }}
              value={searchSubmissions}
              onChange={setSearchSubmissions}
            />
          </div>
        </div>
        <GridTypeA
          paginationPageSize={15}
          columnDefs={SUBMISSIONS_COL_DEFS}
          domLayout='autoHeight'
          rowData={submissions}
          search={searchSubmissions}
          pagination={true}
        />
      </div>
      <div className="study-submission-panel print-hide">
        <p className="study-submissions-title">Approval History</p>
        <GridTypeA
          columnDefs={ACTION_HISTORY_COL_DEFS}
          rowData={approvals}
          domLayout='autoHeight'
          pagination={true}
          paginationPageSize={15}
        />
      </div>

      {/* upload modal */}
      <UploadModal
        formView={formView}
        formSection={formSection}
        formVersion={formVersion}
        ctgDocuments={ctgDocuments}
        authoring={authoring}
        openUploadModal={openUploadModal}
        setOpenUploadModal={() => setOpenUploadModal(false)}
        onDownloadPdf={onDownloadPdf}
        onSubmitToCtg={onSubmitToCtg}
      />

      {/* upload summary modal */}
      <Modal
        title={"Upload Summary"}
        message={"The upload is complete. Please see status of uploads below."}
        // size={(isAutoRelease && hasErrors !== "no-errors") ? "lg" : null}
        open={openProgressModal}
        hideImage={true}
        footer={[]}
        width={hasErrors ? 900 : 600}
        onCancel={() => setOpenProgressModal(false)}
      >
        <PRSUploadProgress
          isAutoRelease={isAutoRelease}
          uploadComplete={true} // uploadComplete
          hasErrors={hasErrors}
          data={getPrsSummaryGridDataFormat(pollingData)}
          setOpenModal={() => setOpenProgressModal(false)}
        />
      </Modal>

      {formView.id !== "ctg" && <div
        className="form-container"
        style={{ display: showPagePreview ? 'block' : 'none' }} // TODO: move to scss
      >
        <PagePreview
          authoring={authoringVersionFormData?.data}
          submission={true}
        />
      </div>}
    </div>
  );
};

export default Submissions;