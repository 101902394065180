import {
  study_identification,
  study_oversight,
  contact_information,
  study_outcome_measures,
  study_timeline,
  study_scope,
  study_reference,
  study_description,
  study_eligibilty,
  study_ipd,
  study_arms,
  ctis_form,
  member_states_concerned,
} from './fieldSchemas';

import {
  studyDetailsDataSchema
} from './studyDetailsDataSchema';

export const primeProtocolDataSchema = {
  type: "object",
  properties: {
    protocol: {
      type: "object",
      properties: {
        protocol_id: {
          title: "Protocol ID",
          type: "string",
        },
        study_type: {
          type: "string",
          title: "Study Type",
          default: "Interventional",
          enum: ["Interventional", "Observational", "Expanded Access"],
        },
        eupas_study_type: {
          type: "string",
          title: "EU PAS Study Type",
          enum: ["Active surveilance", "Observational study", "Clinical trial", "Other"],
        },
        ...studyDetailsDataSchema
      },
      dependencies: {
        study_type: {
          oneOf: [{
              properties: {
                study_type: {
                  enum: ["Interventional"]
                },
                study_identification,
                ctis_form,
                mscs: member_states_concerned,
                contact_information: contact_information.interventional_observational,
                study_oversight: study_oversight.interventional,
                study_timeline: study_timeline.interventional_observational,
                study_description: study_description,
                study_scope: study_scope.interventional,
                study_arms: study_arms.interventional,
                study_eligibilty: study_eligibilty.interventional,
                study_ipd: study_ipd.interventional_observational,
                study_outcome_measures,
                study_reference: study_reference.interventional,
              },
            },
            {
              properties: {
                study_type: {
                  type: "string",
                  enum: ["Observational"],
                },
                study_identification,
                ctis_form,
                mscs: member_states_concerned,
                contact_information: contact_information.interventional_observational,
                study_oversight: study_oversight.observational,
                study_timeline: study_timeline.interventional_observational,
                study_description: study_description,
                study_scope: study_scope.observational,
                study_arms: study_arms.observational,
                study_eligibilty: study_eligibilty.observational,
                study_ipd: study_ipd.interventional_observational,
                study_outcome_measures,
                study_reference: study_reference.observational,
              },
            },
            {
              properties: {
                study_type: {
                  type: "string",
                  enum: ["Expanded Access"],
                },
                study_identification,
                ctis_form,
                mscs: member_states_concerned,
                contact_information: contact_information.expanded_access,
                study_oversight: study_oversight.expanded_access,
                study_timeline: study_timeline.expanded_access,
                study_description: study_description,
                study_scope: study_scope.expanded_access,
                study_arms: study_arms.expanded_access,
                study_eligibilty: study_eligibilty.expanded_access,
                study_reference: study_reference.expanded_access,
              }
            },
          ]
        },
        eupas_study_type: {
          oneOf: [
            {
              properties: {
                eupas_study_type: {
                  enum: ["Active surveilance", "Observational study"],
                },
              }
            },
            {
              properties: {
                eupas_study_type: {
                  enum: ["Clinical trial"],
                },
                eudract_number: {
                  type: "string"
                }
              }
            },
            {
              properties: {
                eupas_study_type: {
                  enum: ["Other"],
                },
                other_study_type: {
                  type: "string"
                }
              }
            }
          ]
        }
      },
    },
  }
}