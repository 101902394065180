import React, { Fragment } from 'react'
import Checkbox from '../../../../Checkbox/Checkbox'
import './ActiveSponsorsCheckList.scss'

const ActiveSponsorsCheckList = ({
  sponsors,
  selectedSponsors,
  setSelectedSponsors,
  search,
  handleWarning,
}) => {

  const getAllSponsorsChecked = () => {
    return sponsors.every((sponsor) => selectedSponsors.find((selSpons) => selSpons === sponsor))
    // could also compare length of sponsors and selectedSponsors
  }

  const getSponsorChecked = (item) => {
    if (selectedSponsors?.length > 0) {
      return selectedSponsors.some((spons) => spons === item)
    }
    return false
  }

  const toggleCheckAll = () => {
    // toggle off
    if (getAllSponsorsChecked()) {
      setSelectedSponsors([])
    }
    // toggle on
    else {
      setSelectedSponsors(sponsors)
    }
    handleWarning(false)
  }

  const toggleCheckbox = (value, sponsor) => {
    if (value) {
      setSelectedSponsors([...selectedSponsors, sponsor])
      handleWarning(false)
    } else {
      setSelectedSponsors((spons) => spons.filter(item => item !== sponsor))
    }
  }

  let breaker = null

  return <>
    <div className="global-sponsors-multi-check">
      {!search
        && <div className="check-line-item">
          <Checkbox
            label="Select All Sponsors"
            checked={getAllSponsorsChecked()}
            onChange={toggleCheckAll}
          />
        </div>}
      {sponsors?.filter(sponsor => sponsor)?.map(item => {
        let isHidden = false
        let shouldBreak = false

        if (search && !item?.toLowerCase()?.includes(search?.toLowerCase())) {
          isHidden = true
        }

        if (!isHidden) {
          if (breaker !== item[0]) {
            shouldBreak = true
            breaker = item[0]
          }
        }

        const getCategory = () => {
          let val = item?.[0]?.toUpperCase()
          if (val === "(") return ""
          return val
        }


        return (
          <Fragment key={item}>
            {shouldBreak
              && <div className="breaker-wrapper">{getCategory()} </div>
            }
            <div
              className={`check-line-item${isHidden ? ' hidden' : ''}`}
            >
              <Checkbox
                label={item}
                checked={getSponsorChecked(item)}
                onChange={(evt) => toggleCheckbox(evt.target.checked, item)}
              />
            </div>
          </Fragment>
        )
      })}
    </div>
  </>
}

export default ActiveSponsorsCheckList