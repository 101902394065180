import React from 'react';

const ContactFormatter = (props) => {
  const formatContact = (props) => {
    let fullName = [props?.first_name, props?.middle_name, props?.last_name].filter(item => item && item.length > 0).join(" ").trim();
    let fullNameDegree = [fullName, props?.degrees].filter(item => item && item.length > 0).join(", ").trim();
    let phoneNumber = [props?.phone, props?.phone_ext].filter(item => item && item.toString().length > 0).join(" ext: ").trim();
    let str = [fullNameDegree, phoneNumber, props?.email].filter(str => str && str.length > 0).join("\n");
    return str;
  }

  return (
    <div>
      {formatContact(props?.value)}
    </div>
  );
};

export default ContactFormatter;