import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useShallow } from 'zustand/react/shallow'
import { cloneDeep, debounce, isEmpty } from 'lodash';

// components
import RJBaselineChars from './RJBaselineChars';
import CellValidation from '../components/CellValidation/CellValidation';

// context
import useResultsTableArmsStore, { initBaselineArmOptions } from '../../../hooks/stores/useResultsTableArmsStore';
import useResultsBaselineStore, { initialState as initBaselineState } from '../../../hooks/stores/useResultsBaselineStore';
import useAuthoringViewStore from '../../../hooks/stores/useAuthoringViewStore';
import useAuthoringVersionStore from '../../../hooks/stores/useAuthoringVersionStore';
import useAuthoringStore from '../../../hooks/stores/useAuthoringStore';
import useParticipantFlowStore from '../RJParticipantFlow/hooks/useParticipantFlowStore';

const BaselineWrapper = (props) => {
  const { ref: visibleRef, inView: inViewport } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  });

  const {
    onChange,
    formContext
  } = props

  const {
    formDataRef,
  } = props.formContext

  const {
    readOnly,
    showVersionCompare,
    formStatus,
    refreshFormData
  } = useAuthoringViewStore(
    useShallow(state => ({
      readOnly: state.readOnly,
      showVersionCompare: state.showVersionCompare,
      formStatus: state.formStatus,
      refreshFormData: state.refreshFormData,
    }))
  )
  const {
    participantData,
  } = useParticipantFlowStore(
    useShallow(state => ({
      participantData: state.participantData,
    }))
  )

  const formWidthClassName = useAuthoringStore(state => state.formWidthClassName)

  const {
    versionData,
    selectedVersion
  } = useAuthoringVersionStore(
    useShallow(state => ({
      versionData: state.versionData,
      selectedVersion: state.selectedVersion,
    }))
  )

  const {
    baselineData,
    setBaselineData,
    showBcUnitsAnalyzed,
    setShowBcUnitsAnalyzed,
    resetResultsBaselineStore,
  } = useResultsBaselineStore(
    useShallow(state => ({
      baselineData: state.baselineData,
      setBaselineData: state.setBaselineData,
      showBcUnitsAnalyzed: state.showBcUnitsAnalyzed,
      setShowBcUnitsAnalyzed: state.setShowBcUnitsAnalyzed,
      resetResultsBaselineStore: state.resetResultsBaselineStore,
    }))
  )

  const {
    baselinePeriod,
    setBaselinePeriod,
    setBaselineSas,
    setBaselineArmOptions,
  } = useResultsTableArmsStore(
    useShallow(state => ({
      baselinePeriod: state.baselinePeriod,
      setBaselinePeriod: state.setBaselinePeriod,
      setBaselineSas: state.setBaselineSas,
      setBaselineArmOptions: state.setBaselineArmOptions,
    }))
  )

  // baseline component
  const [tableMounting, setTableMounting] = useState(true)

  useEffect(() => {
    if (showBcUnitsAnalyzed === false) {
      let tempData = cloneDeep(baselineData)
      delete tempData.ctg.type_units_analyzed
      setBaselineData(tempData)
    }
  }, [showBcUnitsAnalyzed])


  useEffect(() => {
    if (baselineData?.ctg?.type_units_analyzed) {
      setShowBcUnitsAnalyzed(true)
    }
  }, [baselineData?.ctg?.type_units_analyzed])

  useEffect(() => {
    if (baselineData?.eudract?.subject_analysis_sets) {
      setBaselineSas(baselineData?.eudract?.subject_analysis_sets?.map(set => ({
        ...set,
        subjects_analyzed: set.baseline_analysis_population
      })) || [])
    }
  }, [JSON.stringify(baselineData?.eudract?.subject_analysis_sets)])

  useEffect(() => {
    if (formStatus === "ready" && baselineData && readOnly === false && tableMounting === false) {
      handleSubmitDebounce(baselineData)
    }
  }, [baselineData])

  let handleSubmitDebounce = debounce((data) => {
    onChange(data)
  }, 500);

  useEffect(() => {
    if (formContext?.formData?.results?.baseline_characteristics?.baseline_characteristics_table || (formContext?.formData?.results?.baseline_characteristics && isEmpty(formContext?.formData?.results?.baseline_characteristics))) {
      if (formContext?.formData?.results?.baseline_characteristics?.baseline_characteristics_table?.ctg?.type_units_analyzed) setShowBcUnitsAnalyzed(true)

      let baselineData = isEmpty(formContext?.formData?.results?.baseline_characteristics) ? {} : formContext.formData.results.baseline_characteristics.baseline_characteristics_table
      if (isEmpty(baselineData)) baselineData = cloneDeep(initBaselineState.baselineData)
      
      setBaselineData(baselineData)
      let sas = formContext.formData.results.baseline_characteristics?.baseline_characteristics_table?.eudract?.subject_analysis_sets || []
      setBaselineSas(sas)
      setTableMounting(false)
    } else {
      setTableMounting(false)
    }

    return () => {
      setTableMounting(true)
      resetResultsBaselineStore()
    }
  // }, [formContext?.formData])
  }, [])

  useEffect(() => {
    if (formDataRef?.results?.baseline_characteristics?.baseline_characteristics_table) {
      let baselineData = formDataRef.results.baseline_characteristics.baseline_characteristics_table
      if (isEmpty(baselineData)) baselineData = cloneDeep(initBaselineState.baselineData)
      setBaselineData(baselineData)
      let sas = formDataRef.results.baseline_characteristics.baseline_characteristics_table?.eudract?.subject_analysis_sets || []
      setBaselineSas(sas)
      setTableMounting(false)
    }
  }, [refreshFormData])

  useEffect(() => {
    if (formStatus === "ready") {
      findBaselinePeriod()
      onSetBaselineArmOptions()
    }
  }, [formStatus])

  useEffect(() => {
    onSetBaselineArmOptions()
  }, [baselinePeriod])

  const findBaselinePeriod = () => {
    if (participantData?.eudract_periods) {
      let baselinePeriod = participantData.eudract_periods.find(item => item?.baseline_period === "Yes");
      setBaselinePeriod(baselinePeriod)
    }
  }

  const onSetBaselineArmOptions = () => {
    if (baselinePeriod) {
      let items = [...initBaselineArmOptions]

      if (baselinePeriod) {
        let newDropdownItem = {
          id: baselinePeriod.id,
          text: `(Baseline) Period Arms: ${baselinePeriod.title ? baselinePeriod.title : "(Period title)"}`
        }
        items = [...items, newDropdownItem]
      }

      setBaselineArmOptions(items)
    } else {
      setBaselineArmOptions(initBaselineArmOptions)
    }
  }

  if (tableMounting) return <></>

  return (
    <div
      ref={visibleRef}
      className={["form-table-print", formWidthClassName].join(" ")}>
      {inViewport ? (
        <>
          <RJBaselineChars
            {...props}
            baselineData={baselineData}
            setBaselineData={setBaselineData} />
          {versionData && selectedVersion && showVersionCompare && (
            <div className="rjs-table-comparison-wrapper">
              <RJBaselineChars
                {...props}
                baselineData={versionData?.study_data?.results?.baseline_characteristics?.baseline_characteristics_table}
                setBaselineData={() => { }} />
            </div>
          )}
          <CellValidation
            table="baseline"
            errorKey="results_baseline_characteristics" />
          <CellValidation
            table="baseline"
            errorKey="results_baseline_characteristics_baseline_characteristics_table" />
        </>
      ) : (
        <div style={{ padding: 50 }} />
      )}
    </div>
  );
};

export default React.memo(BaselineWrapper);