import React, { useState, useEffect, useContext } from 'react';
import { useShallow } from 'zustand/react/shallow'
import styled from 'styled-components';
import PrimeField from 'components/PrimeField/PrimeField';
import Label from 'components/Label/Label';
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';

const RowTotalInput = (props) => {
  const [percentage, setPercentage] = useState("N/A");

  const {
    readOnly
  } = useAuthoringViewStore(
    useShallow(state => ({ readOnly: state.readOnly }))
  )
  let { measure, rowIndex, category, baselineData, setBaselineData } = props;

  const handleValue = (e, key) => {
    let tempData = { ...baselineData };
    Object.keys(tempData).forEach(registry => {
      let foundIndex = tempData[registry].baseline_measures.findIndex(item => item.id === measure.id);
      if (foundIndex !== -1) {
        let foundCatIndex = tempData[registry].baseline_measures[foundIndex].rows[rowIndex].categories.findIndex(item => item.id === category.id);
        if (foundCatIndex !== -1) {
          let cat = tempData[registry].baseline_measures[foundIndex].rows[rowIndex].categories[foundCatIndex];
          if (cat.total) {
            cat.total[key] = e;
          } else {
            cat.total = {
              [key]: e
            }
          }
        }
      }
    })
    setBaselineData(tempData)
  }

  useEffect(() => {
    if (measure.parameter_type === "Count of Participants" || measure.parameter_type === "Count of Units") {
      let percentage = "N/A";
      let total = getTotalNumAnalyzedParticipants()
      if (measure?.rows?.[rowIndex]?.categories[0]?.values[0] && category?.total?.value) {
        percentage = (Number(category.total.value) / total) * 100;
      }
      if (isNaN(percentage) || percentage === Infinity) percentage = 0;
      setPercentage(percentage);
    } else {
      setPercentage(0);
    }
  }, [category?.total?.value, measure.parameter_type])

  const getTotalNumAnalyzedParticipants = () => {
    if (props?.rows[0]?.categories[0]?.title === "Number Analyzed" && props?.rows[0]?.categories[0]?.values) {
      let key = measure.parameter_type === "Count of Units" ? "num_units_analyzed" : "value"

      let values = props?.rows[0]?.categories[0]?.values?.map((val) => {
        if (val?.[key]) return Number(val?.[key]);
        return 0
      })
      return values.reduce((acc, val) => acc + Number(val), 0)
    }

    return 0
  }

  const getFields = () => {
    if (measure.parameter_type === "Count of Participants" || measure.parameter_type === "Count of Units") {
      return (
        <div>
          <PrimeField
            schema={{
              type: "number",
              label: measure.parameter_type,
              orientation: "vertical",
              disabled: true,
            }}
            readOnly={readOnly}
            onChange={(e) => handleValue(e, "value")}
            value={category?.total?.value} />
          <Label style={{ marginTop: 6 }}>
            {percentage === "N/A" ? percentage : percentage.toFixed(2)}%
          </Label>
        </div>
      )
    } else if (measure.parameter_type === "Number") {
      return (
        <PrimeField
          schema={{
            type: "number",
            label: measure.parameter_type,
            orientation: "vertical",
          }}
          readOnly={readOnly}
          onChange={(e) => handleValue(e, "value")}
          value={category?.total?.value} />
      )
    } else if (measure.dispersion_type === "Standard Deviation") {
      return (
        <div className="field-container">
          <PrimeField
            schema={{
              type: "number",
              label: measure.parameter_type,
              orientation: "vertical",
            }}
            readOnly={readOnly}
            onChange={(e) => handleValue(e, "value")}
            value={category?.total?.value} />
          <PrimeField
            schema={{
              type: "number",
              label: measure.dispersion_type,
              orientation: "vertical",
              containerClassName: "dispersion-value-field"
            }}
            readOnly={readOnly}
            onChange={(e) => handleValue(e, "dispersion_value")}
            value={category?.total?.dispersion_value} />
        </div>
      )
    } else if (measure.dispersion_type === "Inter-Quartile Range" || measure.dispersion_type === "Full Range") {
      return (
        <div className="field-container">
          <PrimeField
            schema={{
              type: "number",
              label: measure.parameter_type,
              orientation: "vertical",
            }}
            readOnly={readOnly}
            onChange={(e) => handleValue(e, "value")}
            value={category?.total?.value} />
          <div className="range-container">
            <Label style={{ marginBottom: ".3rem", width: "100%" }}>{measure.dispersion_type}</Label>
            <div className="field-items">
              <PrimeField
                schema={{
                  type: "number",
                  label: measure.parameter_type,
                  orientation: "vertical",
                }}
                readOnly={readOnly}
                onChange={(e) => handleValue(e, "dispersion_value")}
                value={category?.total?.dispersion_value} />
              <Label style={{ marginBottom: 6 }}>to</Label>
              <PrimeField
                schema={{
                  type: "number",
                  orientation: "vertical",
                  style: { marginRight: 4 }
                }}
                readOnly={readOnly}
                onChange={(e) => handleValue(e, "dispersion_value_upper_bound")}
                value={category?.total?.dispersion_value_upper_bound} />
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <td className="total-col text-left">
      {getFields()}
    </td>
  );
};

export default RowTotalInput;

const TD = styled.td`
  width: 100px;
  position: relative;
  border: 1px solid #8d8d8d;
  padding: 10px;
  vertical-align: top;
  transition: all .4s;
  box-sizing: border-box;
`

const CountOfParticipantsPercentage = styled.div`
  margin-bottom: .3rem;
`