const cellBorder = {
  borderTop: {
    color: '#dbdbdb',
    lineStyle: 'Continuous',
    weight: 1,
  },
  borderBottom: {
    color: '#dbdbdb',
    lineStyle: 'Continuous',
    weight: 1,
  },
  borderLeft: {
    color: '#dbdbdb',
    lineStyle: 'Continuous',
    weight: 1,
  },
  borderRight: {
    color: '#dbdbdb',
    lineStyle: 'Continuous',
    weight: 1,
  },
}

export const excelStyles = [
  {
    id: "simpleField",
    alignment: {
      vertical: 'Center',
      horizontal: 'Left'
    },
    dataType: 'string'
  },
  {
    id: 'greenBackground',
    interior: {
      color: '#aaedbd',
      pattern: 'Solid',
    },
    alignment: {
      vertical: 'Center',
      horizontal: 'Left'
    },
    borders: cellBorder
  },
  {
    id: 'redBackground',
    interior: {
      color: '#ffb89c',
      pattern: 'Solid',
    },
    alignment: {
      vertical: 'Center',
      horizontal: 'Left'
    },
    borders: cellBorder
  },
  {
    id: 'greyBackground',
    interior: {
      color: '#dbdbdb',
      pattern: 'Solid',
    },
    alignment: {
      vertical: 'Center',
      horizontal: 'Left'
    },
    borders: cellBorder
  },
  {
    id: 'oddBackground',
    interiod: {
      color: "#888888",
      pattern: "solid"
    }
  },
  {
    id: 'equalBackground',
    interior: {
      color: '#ffffff',
      pattern: 'Solid',
    },
    alignment: {
      vertical: 'Center',
      horizontal: 'Left',
    },
    borders: cellBorder
  },
  {
    id: 'header',
    fontSize: 20,
    alignment: {
      vertical: 'Center',
      horizontal: 'Left'
    },
    interior: {
      color: '#dadada',
      pattern: 'Solid',
    },
    borders: {
      borderBottom: {
        color: '#2d3436',
        lineStyle: 'Continuous',
        weight: 1,
      },
      borderLeft: {
        color: '#2d3436',
        lineStyle: 'Continuous',
        weight: 1,
      },
      borderRight: {
        color: '#2d3436',
        lineStyle: 'Continuous',
        weight: 1,
      },
      borderTop: {
        color: '#2d3436',
        lineStyle: 'Continuous',
        weight: 1,
      },
    },
  },
];