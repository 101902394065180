import * as React from 'react'
import {
  useParams,
  useOutletContext,
} from 'react-router-dom'
import FilesGridTools from '../../../../files/components/FilesGrid/FilesGridTools/FilesGridTools'
import FilesGrid from '../../../../files/components/FilesGrid/FilesGrid'
import './RequestFiles.scss'

// api
import { useGetRequestDocuments } from 'api/hooks/requests/useRequestDocumentsApi'

const RequestFiles = () => {
  const { requestId } = useParams()
  const { data: requestFiles = [] } = useGetRequestDocuments(requestId, { refetchOnMount: true }) // refresh after delete file on Files page
  const { setAddFilesModalOpen } = useOutletContext()
  const [search, setSearch] = React.useState('')

  const onSelectionChanged = ({ api }) => {
    const selectedNodeData = api.getSelectedNodes().map((node) => node.data)
    console.log('Bulk remove files from request', selectedNodeData)
  }

  return <div className="request-files-tab">
    <FilesGridTools
      setSearch={setSearch}
      setAddFilesModalOpen={setAddFilesModalOpen}
    />
    <FilesGrid
      rowData={requestFiles}
      search={search}
      // selectableColumn={{ headerCheckboxSelection: true }} // TODO: uncomment when implemnt bulk editing
      rowMultiSelectWithClick={true}
      onSelectionChanged={onSelectionChanged}
    />
  </div>
}

export default RequestFiles