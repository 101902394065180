import React, { useEffect, useState, useContext, useMemo, useRef } from 'react';
import { useShallow } from 'zustand/react/shallow'
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

// components
import { Tooltip } from 'antd'
import RJCommentList from '../RJCommentList/RJCommentList';
import RegistryBadges from '../Badges/RegistryBadges/RegistryBadges';
import ResultsTableCreateModal from '../ResultsTableCreateModal/ResultsTableCreateModal';
import './LabelMenu.scss'

// constants
import { formSchemas, getSchemaRegistryTitle } from '../../../../../../../../constants/authoring';
import DeleteForkSvg from '../../../assets/DeleteForkSvg';
import { outcomeMeasuresDataSchema } from '../../widgets/RJOutcomeMeasures/schemas/dataSchema';

// hooks
import useAuthoringViewStore from '../../../hooks/stores/useAuthoringViewStore';
import useAuthoringCommentsStore from '../../../hooks/stores/useAuthoringCommentsStore';
import useAuthoringActions from '../../../hooks/actions/useAuthoringActions';
import useAuthoringDataStore from '../../../hooks/stores/useAuthoringDataStore';
import useAuthoringFieldStore from '../../../hooks/stores/useAuthoringFieldStore';
import useAuthoringVersionStore from '../../../hooks/stores/useAuthoringVersionStore';

const LabelMenu = ({
  showMenu,
  setShowMenu,
  jpath,
  label,
  style = {},

  // for badges and fork button
  registryKeys,
  jpaths,
  onForkField,
  setOpenDeleteForkedModal,
  // set by ArrayFieldTemplates
  //   and ObjectFieldTemplates
  hideFork = false,
  hideComment = false,
  fieldComments,
  canCopyOutcomeMeasures,
  canCopyCtgOutcomeMeasures,
  canCopyAndConsolidateOMs,
  setFieldLoading,
  formContext = {}
}) => {
  const {
    readOnly,
    showRegistryBadges,
    formView,
    formSection,
    refreshFormData,
    setRefreshFormData
  } = useAuthoringViewStore(
    useShallow(state => ({
      readOnly: state.readOnly,
      showRegistryBadges: state.showRegistryBadges,
      formView: state.formView,
      formSection: state.formSection,
      refreshFormData: state.refreshFormData,
      setRefreshFormData: state.setRefreshFormData,
    })),
  )

  const {
    scrollToField
  } = useAuthoringActions()

  const {
    openComment,
  } = useAuthoringCommentsStore(
    useShallow(state => ({
      openComment: state.openComment,
    })),
  )

  const {
    forkedFields,
  } = useAuthoringFieldStore(
    useShallow(state => ({
      forkedFields: state.forkedFields,
    }))
  )

  const {
    selectedVersion,
  } = useAuthoringVersionStore(
    useShallow(state => ({
      selectedVersion: state.selectedVersion,
    }))
  )

  const {
    formData,
    setFormData,
  } = useAuthoringDataStore(
    useShallow(state => ({
      formData: state.formData,
      setFormData: state.setFormData,
    }))
  )

  const [openCommentsForm, setOpenCommentsForm] = useState(false);
  const [showCreateMeasure, setShowCreateMeasure] = useState(false);
  const [view, setView] = useState("ctg");

  let openCommentRef = useRef(null);
  const commentListRef = useRef();
  const labelMenuRef = useRef();

  useEffect(() => {
    if (formView) {
      let view = formView.id;
      if (view === "global") view = "ctg";
      else if (view === "eudra") view = "eudract"
      setView(view)
    }
  }, [formView])

  const handleClickOutside = e => {
    if (commentListRef.current?.contains(e.target) || labelMenuRef.current?.contains(e.target)) {
      // inside click
      return;
    }

    if (openCommentRef.current === null && openCommentsForm === true) {
      let field = document.getElementById(jpath + "-field")
      let comment = document.getElementById(jpath + "-side-panel-comment")
      if (field) {
        field.classList.remove("highlighted-field");
      }
      if (comment) {
        comment.classList.add("highlighted-comment");
      }
      setOpenCommentsForm(false);
    }
    openCommentRef.current = null;
  };

  useEffect(() => {
    if (commentListRef?.current) {
      window.addEventListener("click", handleClickOutside);
    }

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [commentListRef, openCommentsForm])

  useEffect(() => {
    if (openComment === jpath) {
      setOpenCommentsForm(true)
    }
  }, [openComment, jpath])

  useEffect(() => {
    openCommentRef.current = openComment
  }, [openComment])

  /** functions for forked fields */

  const schemaRegistries = useMemo(() => {
    const regKeys = formSchemas[formSection.id]?.properties;
    if (regKeys) return Object.keys(regKeys);
    return null;
  }, [formSection]);

  // fields that have been forked, either the original field forked from or the forked field
  const hasBeenForked = useMemo(() => {
    let isForked = false;
    if (forkedFields && formView && schemaRegistries?.length > 1) {
      const forkedFieldFound = schemaRegistries.find((regKey) => {
        const forkedJpath = `${jpath}_${regKey}`;
        return forkedFields[formSection.id]?.[regKey]?.[jpath] || forkedFields[formSection.id]?.[regKey]?.[forkedJpath];
      });
      if (forkedFieldFound) isForked = true;
    };
    return isForked;
  }, [schemaRegistries, formView, formSection, jpaths, forkedFields]);

  // field is forked, eg, ends in "_ctg", in any view.
  const isForkedField = useMemo(() => {
    let isForked = false;
    if (schemaRegistries?.length > 1) {
      // get all registry keys, and if jpath ends in one of those
      const foundForked = schemaRegistries.find((rKey) => {
        return jpath.endsWith(`_${rKey}`);
      });
      if (foundForked) {
        isForked = true;
      }
    };
    return isForked;
  }, [schemaRegistries, jpath]);

  // eg, if view is "CTG" and field end is "_ctg"
  const isForkedFromCurrentView = useMemo(() => {
    let isForked = false;
    if (jpath.endsWith(`_${formView.id}`)) isForked = true;
    return isForked;
  }, [jpath, formView]);

  // Field that can be forked - should show forked button.
  const fieldIsForkable = useMemo(() => {
    let isForkable = false;
    if (
      // field has to be original field, not forked field
      !isForkedField &&
      // field can only be forked on a registry view (not global)
      formView?.id !== 'global' &&
      registryKeys?.length > 1
    ) {
      const registriesToFork = registryKeys.reduce((acc, regKey) => {
        const pathKey = `${jpath}_${regKey}`;
        if (regKey === 'global' || forkedFields?.[formSection.id]?.[regKey]?.[pathKey]) {
          return acc;
        };
        acc.push(regKey);
        return acc;
      }, []);
      // field has more than one registry that has not been forked
      if (registriesToFork?.length > 1) {
        isForkable = true;
      };
    }
    return isForkable;
  }, [registryKeys, forkedFields, formView, isForkedField]);

  /**
   * Show badges (using registryKeys):
   * * When show badges toggle "off":
   * * * on global view,
   *     show fields that have been forked,
   *     either  original or forked fields.
   * * * on registry views,
   *     show forked fields only,
   *     and on the registry view where it was forked.
   * * When show badges toggle "on".
   */

  const registryBadges = useMemo(() => {
    // console.log({
    //   eval: registryKeys &&
    //     (
    //       (formView?.id === 'global' && hasBeenForked) ||
    //       isForkedFromCurrentView ||
    //       showRegistryBadges
    //     ),
    //   registryKeys,
    //   formView: formView?.id === 'global',
    //   hasBeenForked,
    //   isForkedFromCurrentView,
    //   showRegistryBadges
    // })

    if (registryKeys &&
      (
        (formView?.id === 'global' && hasBeenForked) ||
        isForkedFromCurrentView ||
        showRegistryBadges
      )
    ) {
      return <RegistryBadges
        keys={registryKeys}
      />
    }
    return null;
  }, [registryKeys, formView, hasBeenForked, isForkedFromCurrentView, showRegistryBadges]);

  // show fork button on fields that can be forked
  const forkButton = useMemo(() => {
    if (
      (showMenu || openCommentsForm) &&
      hideFork === false &&
      !selectedVersion &&
      !readOnly &&
      fieldIsForkable
    ) {
      return (
        <Tooltip title={`Fork for ${formView.text}`}>
          <div
            className="menu-icon fal fa-code-merge"
            onClick={onForkField}
          />
        </Tooltip>
      )
    };
    return null;
  }, [showMenu, openCommentsForm, hideFork, selectedVersion, readOnly, registryKeys, formView]);

  const deleteForkedButton = useMemo(() => {
    if (isForkedField && !readOnly && hideFork !== true) {
      const splitPath = jpath.split('_');
      let registryId = null;
      if (splitPath?.length > 1) registryId = splitPath[splitPath.length - 1];
      let registryTitle = getSchemaRegistryTitle(formSection.id, registryId);

      return <>
        <Tooltip title={`Remove ${registryTitle} forked field`}>
          <DeleteForkSvgWrapper>
            <DeleteForkSvg
              onClick={() => setOpenDeleteForkedModal(true)}
            />
          </DeleteForkSvgWrapper>
        </Tooltip>
      </>
    }
    return null;
  }, [isForkedFromCurrentView, formSection, readOnly, hideFork]);

  const commentsButton = useMemo(() => {
    let numOfComments = 0
    if (fieldComments) numOfComments = fieldComments?.filter(comment => comment.field_key === jpath).length;

    if (
      (showMenu || openCommentsForm || numOfComments > 0)
    ) {

      return <div
        // hasComments={numOfComments > 0}
        className={`menu-icon ${numOfComments > 0 ? 'fas fa-comment-alt-dots' : 'fas fa-comment-alt-medical'}`}
        onClick={() => {
          scrollToField(jpath)
          setOpenCommentsForm(true)
        }} />
    }
    return null;
  }, [fieldComments, showMenu, openCommentsForm, selectedVersion]);

  const handleAddOutcomeMeasure = (applyAll) => {
    let tempData = { ...formData }

    let omData = tempData?.results?.outcome_measures;
    let protocolOutcomeMeasures = [];

    if (tempData?.protocol?.study_outcome_measures?.outcome_measures) {
      protocolOutcomeMeasures = tempData.protocol.study_outcome_measures.outcome_measures;
    }

    if (!omData?.outcome_measures_table) {
      omData.outcome_measures_table = {
        ctg: {
          arms: [],
          outcome_measures: []
        },
        eudract: {
          arms: [],
          subject_analysis_sets: [],
          outcome_measures: []
        }
      }
    }

    if (protocolOutcomeMeasures) {
      protocolOutcomeMeasures.forEach(pom => {
        let outcomeMeasure = {
          id: `om-${uuidv4()}`
        }
        Object.keys(omData.outcome_measures_table).forEach(registry => {
          let omSchema = outcomeMeasuresDataSchema.properties.outcome_measures_table.properties[registry].properties.outcome_measure.properties;

          if (applyAll === true || registry === view) {
            Object.keys(omSchema).forEach(key => {
              if (pom[key] || key === "measure_type") {
                let selectedKey = key;
                let schemaKey = key;

                if (key === "measure_type") selectedKey = "type"

                outcomeMeasure[schemaKey] = pom[selectedKey];
              } else {
                outcomeMeasure[key] = omSchema[key].value || "";
              }
            })

            omData.outcome_measures_table[registry].outcome_measures = [...omData.outcome_measures_table[registry].outcome_measures, outcomeMeasure];
          }
        })
      })

      setFormData(tempData);
    }
    setTimeout(() => {
      setRefreshFormData(!refreshFormData)
      setFieldLoading(false)
    }, 1000)
  }

  const copyOutcomeMeasure = () => {
    setShowCreateMeasure(true)
  }

  const copyCtgOutcomeMeasure = () => {
    setFieldLoading(true)
    let tempData = { ...formData }

    let protocolOutcomeMeasures = [];

    if (tempData?.protocol?.study_outcome_measures?.outcome_measures) {
      protocolOutcomeMeasures = tempData.protocol.study_outcome_measures.outcome_measures;
    }
    tempData.protocol.study_outcome_measures.outcome_measures_ctis = protocolOutcomeMeasures
    setFormData(tempData);
    setTimeout(() => {
      setRefreshFormData(!refreshFormData)
      setFieldLoading(false)
    }, 1000)
  }

  const copyAndConsolidateCtgOutcomeMeasure = () => {
    setFieldLoading(true)
    let tempData = { ...formData }

    let protocolOutcomeMeasures = [];
    const primary = []
    const secondary = []

    if (tempData?.protocol?.study_outcome_measures?.outcome_measures) {
      protocolOutcomeMeasures = tempData.protocol.study_outcome_measures.outcome_measures;
      protocolOutcomeMeasures.forEach(om => {
        if (om.type === "Primary") primary.push(om.title)
        if (om.type === "Secondary") secondary.push(om.title)
      })
    }

    tempData.protocol.study_outcome_measures.outcome_measures_eupas.primary_outcome_measures.description = primary.join(", ")
    tempData.protocol.study_outcome_measures.outcome_measures_eupas.secondary_outcome_measures.description = secondary.join(", ")
    setFormData(tempData);
    setTimeout(() => {
      setRefreshFormData(!refreshFormData)
      setFieldLoading(false)
    }, 1000)
  }

  const renderCopyOutcomeMeasures = () => {
    if (!showMenu) return null;
    return (
      <Tooltip title={"Copy all outcome measures from CTG Protocol"}>
        <i className="menu-icon fal fa-copy" onClick={copyOutcomeMeasure}></i>
      </Tooltip>
    )
  }

  const renderCopyCtgOutcomeMeasures = () => {
    if (!showMenu) return null;
    return (
      <Tooltip title={"Copy all outcome measures from CTG Protocol"}>
        <i className="menu-icon fal fa-copy" onClick={copyCtgOutcomeMeasure}></i>
      </Tooltip>
    )
  }

  const renderCopyAndConsolidateOMs = () => {
    if (!showMenu) return null;
    return (
      <Tooltip title={"Copy and consolidate outcome measures from CTG Protocol"}>
        <i className="menu-icon fal fa-copy" onClick={copyAndConsolidateCtgOutcomeMeasure}></i>
      </Tooltip>
    )
  }

  return (
    <div
      style={style}
      className="label-menu"
      ref={labelMenuRef}>
      {forkButton}
      {deleteForkedButton}
      {hideComment !== true && commentsButton}
      {canCopyOutcomeMeasures && !readOnly && renderCopyOutcomeMeasures()}
      {canCopyCtgOutcomeMeasures && !readOnly && renderCopyCtgOutcomeMeasures()}
      {canCopyAndConsolidateOMs && !readOnly && renderCopyAndConsolidateOMs()}
      <RegistryBadgeContainer show={registryBadges !== null}>
        {registryBadges}
      </RegistryBadgeContainer>
      {openCommentsForm &&
        <CommentListContainer
          ref={commentListRef}
          className="animate__animated animate__fadeIn animate__fadeInUp animate__faster"
        >
          <RJCommentList
            jpath={jpath}
            label={label}

            openForm={openCommentsForm}
            setOpenForm={setOpenCommentsForm}
            setShowMenu={setShowMenu} />
        </CommentListContainer>
      }
      <HiddenContainer>|</HiddenContainer>

      {canCopyOutcomeMeasures &&
        <ResultsTableCreateModal
          header="Copy Outcome Measures"
          onSubmit={handleAddOutcomeMeasure}
          showModal={showCreateMeasure}
          setShowModal={setShowCreateMeasure} />
      }
    </div>
  );
};

export default LabelMenu;

const HiddenContainer = styled.div`
  opacity: 0%;
  height: 12px;
`

const DeleteForkSvgWrapper = styled.span`
  margin-left: 10px;

  svg {
    width: 14px;
    height: auto;
    fill: #A8A8A8;
    cursor: pointer;

    &:hover {
      fill: #D7542C;
    }
  }
`

const CommentListContainer = styled.div`
  width: 440px;
  background-color: #fff;
  position: absolute;
  top: 100%;
  right: -207px;
  margin-top: 5px;
  z-index: 1000;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  max-height: 90vh;
  overflow: auto;
  border: 1px solid #eeeeee;
  border-radius: 10px;
`

const RegistryBadgeContainer = styled.div`
  margin: 0;
  margin-left: 7px; // same as Icon
  display: ${({ show }) => show ? `block` : `none`}
`