import { useState, useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { cloneDeep } from 'lodash';
import { useShallow } from 'zustand/react/shallow'

// components
import PrimeField from 'components/PrimeField/PrimeField';
import { Button } from 'components-design-system';
import PeriodArms from '../PeriodArms/PeriodArms';
import ResultsTableCreateModal from '../../../../components/ResultsTableCreateModal/ResultsTableCreateModal';
import RowActions from '../../../components/RowActions/RowActions';
import MilestoneTable from '../MilestoneTable/MilestoneTable';
import CellValidation from '../../../components/CellValidation/CellValidation';

// context
import useResultsTableViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableViewStore';
import useResultsTableArmsStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableArmsStore';
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';

const Period = ({
  periodIndex,
  participantData,
  setParticipantData,
  schema,
  period,
}) => {
  const [showActions, setShowActions] = useState(false)
  const [showAddMilestoneModal, setShowAddMilestoneModal] = useState(false);
  const milestoneTemplate = schema.properties.templates.milestone
  const participantTemplate = schema.properties.templates.participant

  const {
    tableView,
    participantRegistries,
    participantView
  } = useResultsTableViewStore()

  const {
    formView,
    readOnly
  } = useAuthoringViewStore(
    useShallow(state => ({
      readOnly: state.readOnly,
      formView: state.formView
    }))
  )

  const {
    setBaselinePeriod,
  } = useResultsTableArmsStore()

  let isEudractPeriod = useMemo(() => {
    return participantData?.eudract_periods?.find(item => item.id === period.id)
  }, [period?.id])

  const onChange = (e, key) => {
    let tempData = { ...participantData }

    participantRegistries.forEach(registry => {
      let foundIndex = participantData[registry].findIndex(item => item.id === period.id)

      if (foundIndex !== -1) {
        tempData[registry][foundIndex][key] = e;

        if (key === "baseline_period") {
          if (e === "Yes") {
            setBaselinePeriod(period)
            for (let i = 0; i < participantData.eudract_periods.length; i++) {
              let otherPeriods = participantData.eudract_periods[i]
              if (foundIndex !== i) {
                otherPeriods[key] = "No";
              }
            }
          } else {
            findBaselinePeriod(tempData)
          }
        }
      }
    })

    setParticipantData(tempData);
  }

  const handleUp = () => {
    let tempData = { ...participantData };

    participantRegistries.forEach(registry => {
      let foundIndex = tempData[registry].findIndex(item => item?.id === period.id);
      if (foundIndex !== 0) {
        let savedPeriod = tempData[registry][foundIndex - 1];
        tempData[registry][foundIndex - 1] = tempData[registry][foundIndex];
        tempData[registry][foundIndex] = savedPeriod;
      }
    })
    setParticipantData(tempData);
  }

  const handleDown = () => {
    let tempData = { ...participantData };

    participantRegistries.forEach(registry => {
      let foundIndex = tempData[registry].findIndex(item => item?.id === period.id);
      if (foundIndex !== tempData[registry].length - 1) {

        let savedPeriod = tempData[registry][foundIndex + 1];
        tempData[registry][foundIndex + 1] = tempData[registry][foundIndex];
        tempData[registry][foundIndex] = savedPeriod;
      }
    })
    setParticipantData(tempData);
  }

  const handleDelete = (applyAll) => {
    let tempData = { ...participantData };

    participantRegistries.forEach(registry => {
      if (applyAll || registry === participantView) {
        let foundIndex = tempData[registry].findIndex(item => item?.id === period.id);
        if (foundIndex !== -1) {
          tempData[registry].splice(foundIndex, 1)
        }
      }
    })
    findBaselinePeriod(tempData)

    setParticipantData(tempData);
  }

  const findBaselinePeriod = (data) => {
    let foundBaselinePeriod = data.eudract_periods.find(item => item?.baseline_period === "Yes")
    if (foundBaselinePeriod) {
      setBaselinePeriod(foundBaselinePeriod)
    } else {
      setBaselinePeriod(null)
    }
  }


  const renderProps = () => {
    let fieldSchema = schema.properties[tableView].properties.period.properties
    if (isEudractPeriod && formView.id === "global") {
      fieldSchema = schema.properties["eudract"].properties.period.properties
    }

    return Object.keys(fieldSchema).map(key => {
      let disabled = false;
      if (key === "clinical_trial_roles" &&
        period.blinded_type === "Not blinded") {
        disabled = true;
      } else if (key === "baseline_period") {
        let baselineExists = participantData.eudract_periods.some(item => item?.baseline_period === "Yes");
        if (baselineExists && period.baseline_period !== "Yes") disabled = true;
      } else if (key === "mutually_exclusive_arms") {
        if (!period.arms || period.arms.length < 2) {
          return null
        }
      }

      return (
        <div key={periodIndex + key}>
          <PrimeField
            schema={{
              type: fieldSchema[key].type,
              items: fieldSchema[key].enum,
              label: fieldSchema[key].title,
              orientation: "vertical",
              disabled
            }}
            containerStyle={fieldSchema[key].type === "dropdown" ? { width: 200 } : {}}
            readOnly={readOnly}
            value={period[key]}
            onChange={e => onChange(e, key)} />
          <CellValidation
            table="participants"
            errorKey={`results_participant_flow_participant_flow_table_${participantView}_${periodIndex + 1}_${key}`} />
        </div>
      )
    })
  }

  const addMilestone = (applyAll) => {
    let tempData = { ...participantData }
    let milestoneId = `milestone-${uuid()}`;

    participantRegistries.forEach(registry => {
      if (applyAll || registry === participantView) {
        if (registry === "ctg_periods") {
          let foundPeriodIndex = tempData[registry].findIndex(item => item?.id === period.id);
          if (foundPeriodIndex !== -1) {
            let templateCopy = cloneDeep(milestoneTemplate)
            let milestone = {
              ...templateCopy,
              id: milestoneId
            }

            tempData.global_arms.forEach(arm => {
              let newParticipant = {
                ...participantTemplate,
                registry_arm_id: `${period.id}:${arm.registry_arm_id}`,
                arm_id: arm.id
              }
              milestone.participants.push(newParticipant);
            })
            tempData[registry][foundPeriodIndex].milestone_list.splice(1, 0, milestone)
          }
        } else {
          let foundPeriodIndex = tempData[registry].findIndex(item => item?.id === period.id);
          if (foundPeriodIndex !== -1) {
            let templateCopy = cloneDeep(milestoneTemplate)
            let milestone = {
              ...templateCopy,
              id: milestoneId
            }
            tempData[registry][foundPeriodIndex].arms.forEach(arm => {
              let newParticipant = {
                ...participantTemplate,
                registry_arm_id: arm.registry_arm_id,
                arm_id: arm.id
              }
              milestone.participants.push(newParticipant);
            })
            tempData[registry][foundPeriodIndex].milestone_list.splice(1, 0, milestone)
          }
        }
      }
    })

    setParticipantData(tempData);
  }

  const getActionRowColSpan = () => {
    let colSpan = 1
    if (period?.["milestone_list"]?.[0]?.participants?.length > 0) {
      colSpan = period["milestone_list"][0].participants.length + 1
    }

    return colSpan
  }

  const addReason = (applyAll) => {
    let tempData = { ...participantData };
    let reasonId = `reason-${uuid()}`
    let required = true

    participantRegistries.forEach(registry => {
      if (applyAll || registry === participantView) {
        if (registry === "ctg_periods") {
          let foundPeriodIndex = tempData[registry].findIndex(item => item?.id === period.id);
          if (foundPeriodIndex !== -1) {
            let templateCopy = cloneDeep(milestoneTemplate)
            let withdrawnObj = {
              ...templateCopy,
              id: reasonId,
              title: "",
              required
            }
            tempData.global_arms.forEach(arm => {
              let newParticipant = {
                ...participantTemplate,
                arm_id: arm.id,
                registry_arm_id: arm.registry_arm_id
              }
              withdrawnObj.participants.push(newParticipant);
            })
            tempData[registry][foundPeriodIndex].drop_withdrawn_reason_list.push(withdrawnObj)
          }
        } else {
          let foundPeriodIndex = tempData[registry].findIndex(item => item?.id === period.id);
          if (foundPeriodIndex !== -1) {
            let templateCopy = cloneDeep(milestoneTemplate)
            let withdrawnObj = {
              ...templateCopy,
              id: reasonId,
              title: "",
              required
            }
            tempData[registry][foundPeriodIndex].arms.forEach(arm => {
              let newParticipant = {
                ...participantTemplate,
                arm_id: arm.id,
                registry_arm_id: arm.registry_arm_id
              }
              withdrawnObj.participants.push(newParticipant);
            })
            tempData[registry][foundPeriodIndex].drop_withdrawn_reason_list.push(withdrawnObj)
          }
        }
      }
    })

    setParticipantData(tempData)
  }

  return (
    <div
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}
      className="period-container">
      <RowActions
        showActions={showActions}
        setShowActions={setShowActions}
        showDown={periodIndex !== participantData[participantView].length}
        showUp={periodIndex !== 0}
        handleUp={handleUp}
        handleDown={handleDown}
        handleDelete={handleDelete}
        canDelete={true}
        deleteModalTitle="Delete period?"
        jpath={`ParticipantFlowGroup.pf-period-${period.id}`}
        style={{
          position: "absolute",
          width: "calc(100% - 48px)",
          top: 6
        }} />
      <div className="flex flex-col" style={{ gap: 8, maxWidth: 726, marginBottom: 16 }}>
        {renderProps()}
      </div>
      {!readOnly &&
        <Button
          size="sm"
          variant="primary-dashed"
          onClick={() => setShowAddMilestoneModal(true)}
          style={{ margin: "20px 0 20px 0" }}>
          <i
            className="fal fa-plus-circle"
            style={{
              // marginTop: 2,
              marginRight: 8
            }} />
          Add Milestone
        </Button>
      }

      <table>
        <tbody>
          <PeriodArms
            schema={schema}
            periodIndex={periodIndex}
            period={period}
            participantData={participantData}
            setParticipantData={setParticipantData} />
          <MilestoneTable
            key="milestone_list"
            type="milestone_list"
            schema={schema}
            periodIndex={periodIndex}
            period={period}
            participantData={participantData}
            setParticipantData={setParticipantData} />
          <MilestoneTable
            key="drop_withdrawn_reason_list"
            type="drop_withdrawn_reason_list"
            schema={schema}
            periodIndex={periodIndex}
            period={period}
            participantData={participantData}
            setParticipantData={setParticipantData} />
          <tr className="disabled-row">
            <td className="text-bold" colSpan={getActionRowColSpan()}>
              <div className="flex items-center" style={{ gap: 12 }}>
                <span>
                  Total for all reasons
                </span>
                {readOnly !== true &&
                  <span>
                    <Button
                      size="sm"
                      variant="primary-dashed"
                      onClick={addReason}>
                      <i
                        className="fal fa-plus-circle"
                        style={{
                          marginTop: 2,
                          marginRight: 8
                        }} />
                      Add reason
                    </Button>
                  </span>
                }
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      {/* Create Milestone */}
      <ResultsTableCreateModal
        header="Add Milestone"
        onSubmit={addMilestone}
        showModal={showAddMilestoneModal}
        setShowModal={() => setShowAddMilestoneModal(false)} />

      {/* Delete Period */}
      {/* <ResultsTableDeleteModal
        header="Delete Period"
        view={view}
        onSubmit={(applyAll) => onDelete(applyAll, period)}
        showModal={openModal}
        setShowModal={() => setOpenModal(false)} /> */}
    </div >
  );
};

export default Period;