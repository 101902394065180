// 12px height separator
import React from 'react'
import PropTypes from 'prop-types'
import './Separator.scss'

const Separator = ({
  marginTop = 0,
  marginBottom = 0,
}) => {
  return <div
    className="prime-separator"
    style={{
      marginTop,
      marginBottom,
    }}
  >
    .....
  </div>
}

Separator.propTypes = {
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
}

export default Separator