import React from 'react';
import styled from 'styled-components';

const StaticField = ({
  title,
  value,
  style = {}
}) => {
  return (
    <Container style={style}>
      <Title>{title}</Title>
      <Value>{value}</Value>
    </Container>
  );
};

export default StaticField;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Title = styled.div`
  margin-bottom: 3px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.32px;
  color: #6F6F6F;
`

const Value = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: #383E47;
`