import React, { useState } from 'react'
// TODO: replace downshift with antd components
import { useCombobox } from 'downshift'
import { Tooltip } from 'antd'
import ReadOnlyField from '../ReadOnlyField/ReadOnlyField'
import "./Combobox.scss"

const Combobox = ({
  items,
  defaultSelectedItem,
  onSelect,
  placeholder = null,
  className,
  onBlur,
  disabled,
  readOnly,
  tooltip,
}) => {
  const [inputItems, setInputItems] = useState(items)
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
  } = useCombobox({
    items: inputItems,
    defaultSelectedItem,
    onInputValueChange: ({ inputValue }) => {
      // input items that contain the input word
      let filteredItems = items.filter(item => item.toLowerCase().includes(inputValue.toLowerCase()))
      setInputItems(filteredItems)

      // only return an exact match
      if (items.find((item) => item === inputValue)) onSelect(inputValue)
    },
  })
  const classNames = ['prime-combobox'];

  if (className) classNames.push(className);
  if (disabled) classNames.push('disabled');

  if (readOnly) {
    return <ReadOnlyField>{defaultSelectedItem}</ReadOnlyField>
  }

  return <Tooltip
    placement="left"
    title={tooltip}
  >
    <div className={classNames.join(' ')}>
      <div {...getComboboxProps()}>
        <input
          {...getInputProps()}
          disabled={disabled}
          placeholder={placeholder}
          onBlur={onBlur}
        />
        {disabled !== true && <i
          {...getToggleButtonProps()}
          className="far fa-angle-down"
          aria-label="toggle menu"
        />}
      </div>
      <ul {...getMenuProps()}>
        {isOpen &&
          inputItems.map((item, index) => (
            <li
              key={`${item}${index}`}
              {...getItemProps({ item, index })}
            >
              {item}
            </li>
          ))}
      </ul>
    </div>
  </Tooltip>
}

export default Combobox