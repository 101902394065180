/**
 * Hook including:
 * • functions to keep the annotations on the server and those on the UI in sync
 * • toast notifications to show the user when there are server updates
 * • error modal when there is a conflict from a change on the UI and the server
 */
import React from 'react'
import { notification } from 'antd'
import { Button } from 'components-design-system'
import moment from 'moment' // TODO: replace with dayjs
import './usePdfAnnotations.scss'

// api
import {
  useGetDocAnnotations,
  usePostDocAnnotation,
  usePutDocAnnotation,
  useDeleteDocAnnotation,
  getDocAnnotations,
} from 'api/hooks/documents/useDocAnnotationsApi'

const usePdfAnnotations = (documentId) => {
  // when page loads save annotations versions to know if an annotation has been updated on the server
  // update map state when annotation on UI is updated to the one on the server
  const annotVersMap = React.useRef()
  const {
    data: annotations,
    refetch: refetchAnnotations,
  } = useGetDocAnnotations(documentId)
  const postDocAnnotation = usePostDocAnnotation(documentId)
  const putDocAnnotation = usePutDocAnnotation(documentId)
  const deleteDocAnnotation = useDeleteDocAnnotation(documentId)

  // adobe embed errors and notifications
  const [notifApi, annotNotifHolder] = notification.useNotification()
  const [modalError, setModalError] = React.useState()
  const [resolvePromise, setResolvePromise] = React.useState() // postpone removing deleted comment/reply from the UI

  // annotations utilties

  /**
   * Used to compare annotations on the UI with those saved to the server
   * "id" can be used for annotations added via Adobe PDF Embed, 
   * but need to use creator name and date for annotations already on PDF when imported into documents
   */
  const isAnnotation = (annotA, annotB) => {
    return annotA.id === annotB.id ||
      (
        annotA.created === annotB.created &&
        annotA.creator.name === annotB.creator.name
      )
  }

  // Used to compare annotation on the UI to those on the server to see if there were any updates
  const annotsAreDifferent = (annotA, annotB) => {
    const arraysAreSame = (arrA, arrB) =>
      arrA.length === arrB.length &&
      arrA.every((item, index) => item === arrB[index]);

    // numbers in boundingBox array shift by fractions even though relevant update didn't happen
    const boundingBoxSimilar = (arrA, arrB) =>
      arrA.length === arrB.length &&
      arrA.every((item, index) => Math.abs(item - arrB[index]) < 1);

    if (
      // bodyValue
      annotA.bodyValue !== annotB.bodyValue || // string
      // boundingBox
      (annotA.target?.selector?.boundingBox && // array
        !boundingBoxSimilar(annotA.target.selector.boundingBox, annotB.target.selector.boundingBox)) ||
      // quadPoints
      (annotA.target?.selector?.quadPoints && // array
        !arraysAreSame(annotA.target.selector.quadPoints, annotB.target.selector.quadPoints)) ||
      // strokeColor
      (annotA.target?.selector?.strokeColor && // string
        annotA.target.selector.strokeColor !== annotB.target.selector.strokeColor) ||
      // TODO: check that stylesheet is relevant
      // stylesheet
      (annotA?.stylesheet?.value && // string
        annotA.stylesheet.value !== annotB.stylesheet.value) ||
      // strokeWidth
      (annotA.target?.selector?.strokeWidth && // number
        annotA.target.selector.strokeWidth !== annotB.target.selector.strokeWidth) ||
      // opacity
      (annotA.target?.selector?.opacity && // number
        annotA.target.selector.opacity !== annotB.target.selector.opacity) ||
      // inkList
      // TODO: write recursive function to go thru an array of any length and check that all child arrays are eqal
      (annotA.target?.selector?.inkList?.[0] && // array
        !arraysAreSame(annotA.target.selector.inkList[0], annotB.target.selector.inkList[0])) ||
      (annotA.target?.selector?.inkList?.[1] && // array
        !arraysAreSame(annotA.target.selector.inkList[1], annotB.target.selector.inkList[1]))
    ) return true;
    return false
  }

  // get annotation from server or ui
  const getAnnotation = (annot, serverAnnots) => {
    return serverAnnots.find((servAnnot) => {
      return isAnnotation(servAnnot, annot)
    })
  }

  const annotIsOlder = (annotToUdate, withAnnot) => {
    return moment(annotToUdate.modified).isBefore(moment(withAnnot.modified)) && annotsAreDifferent(annotToUdate, withAnnot)
  }

  const getReplyParent = (replyAnnot, annots) => {
    return annots.find((annot) => annot.motivation === 'commenting' && replyAnnot.target.source === annot.id)
  }

  const getReplyParentOnServer = (replyAnnot, serverAnnots, uiAnnots) => {
    if (replyAnnot.motivation !== 'replying') return null;
    const parentAnnot = getReplyParent(replyAnnot, uiAnnots)
    if (!parentAnnot) return null;
    return getAnnotation(parentAnnot, serverAnnots)
  }

  const getChildReplies = (comment, annots) => {
    return annots.filter((annot) => comment.id === annot.target.source)
  }

  // notifications

  const openNotification = (msg, type = 'error') => {
    notifApi[type]({
      // message: "Error",
      description: msg,
      placement: "topRight",
    })
  }

  // get annotation from server to add to ui - when PDF preview loads
  const getServerUpdates = async (uiAnnots,
    {
      add = [],
      remove = [],
      update = [],
    } = {
        add: [],
        remove: [],
        update: [],
      }) => {
    return new Promise((resolve, reject) => {
      getDocAnnotations(documentId)
        .then((annotData) => {
          refetchAnnotations()
          const serverAnnots = [...annotData.annotations]
          const serverVersMap = annotData?.annot_version_map
          annotVersMap.current = serverVersMap

          // check for new annotations that need to be added to UI
          serverAnnots.forEach((servAnnot) => {
            // annotat)ions on server but not on UI
            if (!uiAnnots.some((uiAnnot) => {
              const onServerAndUi = isAnnotation(servAnnot, uiAnnot)
              if (onServerAndUi) {
                // update annotation on ui if they have differences and ui annotation is older than one on server
                if (annotsAreDifferent(uiAnnot, servAnnot) && annotIsOlder(uiAnnot, servAnnot)) {
                  update.push(servAnnot)
                }
                return true
              } else {
                return false
              }
            })) {
              add.push(servAnnot)
            }
          })

          // check for annotations that need to be deleted on UI
          uiAnnots.forEach((uiAnnot) => {
            if (
              // only need to remove the comment. the Adobe Embed deleteAnnotations api removes all of comment's replies
              uiAnnot.motivation === 'commenting' &&
              (!serverAnnots.some((servAnnot) => isAnnotation(uiAnnot, servAnnot)))
            ) {
              remove.push(uiAnnot)
            }
          })

          resolve({ add, remove, update })
        })
        .catch((error) => {
          console.log('getServerUpdates error', error)
          reject({
            status: 'ERROR',
            error,
          })
        })
    })
  }

  // NOTE: reply annotations never sent as "data", only comment
  const onAnnotationSelected = (data, uiAnnotations) => {
    return new Promise((resolve, reject) => {
      getDocAnnotations(documentId)
        .then((annotData) => {
          refetchAnnotations()
          const {
            annotations: serverAnnots,
            annot_version_map: versMapOnServer,
          } = annotData
          const annotOnServer = getAnnotation(data, serverAnnots)
          const uiAnnots = [...uiAnnotations]

          let add = []
          let remove = []
          let update = []

          const getAnnotContent = (annot) => {
            let annotContent = ''
            // use comment body value if exists
            if (annot.bodyValue) {
              annotContent = `"${annot.bodyValue}"`
            }
            // or use comment type
            else if (annot?.target?.selector?.subtype) {
              annotContent = annot.target.selector.subtype
            }
            return annotContent
          }

          const anotherUserNotification = (annotType = 'updated', annot, notifType = 'warning') => {
            let motivationType = 'Comment'
            if (annot.motivation === 'replying') motivationType = 'Reply'
            openNotification(`${motivationType} ${getAnnotContent(annot)} was ${annotType} by another user`, notifType)
          }

          const updatedNotification = (newAnnot, oldAnnot, notifType = 'warning') => {
            let motivationType = 'Comment'
            if (newAnnot.motivation === 'replying') motivationType = 'Reply'
            if (newAnnot.bodyValue || oldAnnot.bodyValue) {
              openNotification(`${motivationType} ${getAnnotContent(oldAnnot)} was updated to ${getAnnotContent(newAnnot)} by another user`, notifType)
            } else {
              anotherUserNotification('updated', newAnnot)
            }
          }

          const newReplyNotification = (parentAnnot) => {
            openNotification(`There is a new reply to comment ${getAnnotContent(parentAnnot)}. Refresh the page to see it.`, 'warning')
          }

          // if annotation still on server
          if (annotOnServer) {
            const versOnServer = versMapOnServer[annotOnServer.id]
            const versOnUi = annotVersMap.current[annotOnServer.id]
            // if annotation comment has been updated on server, show warning, and update it on ui
            if (versOnServer > versOnUi) {
              annotVersMap.current[annotOnServer.id] = versOnServer
              updatedNotification(annotOnServer, data)
              update.push(annotOnServer)
            }

            // find all replies on UI and update if updated or delete if deleted
            const childRepliesOnUi = getChildReplies(data, uiAnnots)
            childRepliesOnUi.forEach((reply) => {
              const replyOnServer = getAnnotation(reply, serverAnnots)
              // reply exists on server
              if (replyOnServer) {
                // update reply on ui if versions are different
                const replyVersOnServer = versMapOnServer[reply.id]
                const replyVersOnUi = annotVersMap.current[reply.id]
                if (replyVersOnServer > replyVersOnUi) {
                  annotVersMap.current[reply.id] = replyVersOnServer
                  updatedNotification(replyOnServer, reply)
                  update.push(replyOnServer)
                }
              }
              // delete reply if not on server
              else {
                let newVersMap = { ...annotVersMap.current }
                delete newVersMap[reply.id]
                annotVersMap.current = newVersMap
                anotherUserNotification('deleted', reply)
                remove.push(reply)
              }
            })
          }
          // annotation comment has been deleted, delete from ui
          else {
            let newVersMap = { ...annotVersMap.current }
            delete newVersMap[data.id]
            annotVersMap.current = newVersMap
            anotherUserNotification('deleted', data)
            remove.push(data)
          }

          // add new comments and replies
          serverAnnots.forEach((annot) => {
            const foundAnnot = uiAnnots.find((uiAnnot) => isAnnotation(annot, uiAnnot) || !annotsAreDifferent(annot, uiAnnot))
            if (!foundAnnot) {
              // add new annotation id to version map on ANNOTATION_ADDED event
              annotVersMap.current[annot.id] = versMapOnServer[annot.id]
              // Cannot add a reply on select b/c Adobe Embed will show error "We were unable to post your comment..."
              if (annot.motivation !== 'replying') {
                anotherUserNotification('added', annot, 'info')
                add.push(annot)
              }
              // for replies will only show notification
              else {
                const replyParent = getReplyParent(annot, uiAnnots)
                newReplyNotification(replyParent)
              }
            }
          })

          resolve({ add, remove, update })
        })
        .catch((err) => {
          console.log('onAnnotationSelected error', err)
          reject(err)
        })
    })
  }

  // annotation events callback after users modify ui
  // should update server or show user error and update ui with server data
  const onAnnotationEvent = (type, data, uiAnnotations) => {
    return new Promise((resolve, reject) => {
      // need all annotations instead of just the one that the user took action on b/c sometimes a reply needs the info of it's parent comment
      getDocAnnotations(documentId)
        .then((annotData) => {
          refetchAnnotations()
          const serverAnnots = [...annotData.annotations]
          const serverVersMap = annotData?.annot_version_map
          const uiAnnots = [...uiAnnotations]

          let add = []
          let remove = []
          let update = []
          let status = "SUCCESS"
          let errorMessage = null

          const resolveFunc = () => {
            resolve({
              status,
              errorMessage,
              add,
              remove,
              update,
            })
          }

          /** ANNOTATION_UPDATED */
          if (type === 'ANNOTATION_UPDATED') {
            const annotOnServer = getAnnotation(data, serverAnnots)
            // if updated annotion is on server

            if (annotOnServer) {
              // take action if annotations are different
              if (annotsAreDifferent(annotOnServer, data)) {
                const uiVersion = annotVersMap.current[annotOnServer.id]
                const servVersion = serverVersMap[annotOnServer.id]

                // update annotation on server if it is older (same or greater version number) than annot on ui
                if (servVersion <= uiVersion) {
                  let annotData = { ...data }
                  annotData.id = annotOnServer.id
                  putDocAnnotation({
                    body: {
                      version: uiVersion,
                      annotation: annotData,
                    }
                  }, {
                    onSuccess: (res) => {
                      let newVersionMap = { ...annotVersMap.current }
                      newVersionMap[annotOnServer.id] = res.data.annot_version_map[annotOnServer.id]
                      annotVersMap.current = newVersionMap
                    },
                    onError: (err) => {
                      console.log(`${type} ERROR`, err)
                    },
                  })
                  resolveFunc()
                }
                // if annotation on server has been modified before the changes that user is making, show error modal, undo annotation changes on UI when modal is confirmed > "ANNOTATION_UPDATED"
                else {
                  status = 'ERROR'
                  errorMessage = <>
                    <div>The annotation you tried to update <strong>has already been updated</strong> by another user.</div>
                    {data.bodyValue && <div>Your update: "<em>{data.bodyValue}</em>"</div>}
                  </>
                  // since updating to latest server annotation, update version to latest
                  let newVersionMap = { ...annotVersMap.current }
                  newVersionMap[annotOnServer.id] = serverVersMap[annotOnServer.id]
                  annotVersMap.current = newVersionMap

                  update.push(annotOnServer)
                  setModalError(errorMessage)
                  setResolvePromise(() => resolveFunc)
                }
              }
            }
            // if annotation to update has been deleted on the server, show error modal and delete updated annotation on UI when modal is confirmed > "ANNOTATION_DELETED"
            else {
              status = 'ERROR'
              errorMessage = <>
                <div>The annotation you tried to update <strong>has been deleted</strong> by another user.</div>
                <div>Your update: "<em>{data.bodyValue}</em>"</div>
              </>
              let annotToRemove = {}
              if (data.motivation === 'commenting') {
                annotToRemove = uiAnnots.find((uiAnnot) => {
                  return isAnnotation(uiAnnot, data)
                })
              } else { // "replying"
                if (getReplyParent(data, uiAnnots)) {
                  // if parent wasn't deleted only delete annotation updated
                  annotToRemove = data
                } else {
                  annotToRemove = getReplyParent(data, uiAnnots)
                }
              }
              remove.push(annotToRemove)
              setModalError(errorMessage)
              setResolvePromise(() => resolveFunc)
            }
          }

          /** ANNOTATION_DELETED */
          if (type === 'ANNOTATION_DELETED') {
            // only delete if annotation is on server
            let annotOnServer = getAnnotation(data, serverAnnots)
            // if deleting comment, server takes care of deleting all of its replies
            if (annotOnServer) {
              const version = serverVersMap[annotOnServer.id]
              deleteDocAnnotation({
                body: {
                  version,
                  annotation: annotOnServer,
                }
              }, {
                onSuccess: () => {
                  // delete annotation from annotations map
                  let newAnnotsVersMap = { ...annotVersMap.current }
                  delete newAnnotsVersMap[data.id]
                  annotVersMap.current = newAnnotsVersMap
                  // warning: not deleting a comment's replies from the version map if the comment is deleted
                },
                onError: (err) => {
                  console.log(`${type} ERROR`, err)
                }
              })
            }
            resolveFunc()
          }

          /** ANNOTATION_ADDED */
          // NOTE: after adding an annotation on ui, the selected event is triggered
          if (type === 'ANNOTATION_ADDED') {
            // if the annotation is not already on the server
            if (!getAnnotation(data, serverAnnots)) {
              // if reply's parent comment has been deleted on the server, on the ui remove reply parent comment, which will then delete all its child replies (resulting in an "ANNOTATION_DELETED"). And show modal error message
              if (data.motivation === 'replying' && !getReplyParentOnServer(data, serverAnnots, uiAnnots)) {
                status = 'ERROR'
                errorMessage = <>
                  <div>The comment you tried to reply to <strong>has been deleted</strong> by another user.</div>
                  <div>Your reply: "<em>{data.bodyValue}</em>"</div>
                </>
                setModalError(errorMessage)
                const replyParent = getReplyParent({ ...data }, uiAnnots)
                remove.push(replyParent)
                setResolvePromise(() => resolveFunc)
              }
              else {
                postDocAnnotation({
                  body: data,
                }, {
                  onSuccess: (res) => {
                    // refetchAnnotations()
                    // add annotation to annotations map
                    annotVersMap.current[data.id] = res.data.annot_version_map[data.id]
                    resolveFunc()
                  },
                  onError: (err) => {
                    console.log(`${type} ERROR`, err)
                  },
                })
              }
            }
          }
        })
        .catch((error) => {
          console.log('onAnnotationEvent error', error)
          reject({
            status: 'ERROR',
            error,
          })
        })
    })
  }

  const AnnotModalError = () => {
    if (!modalError) return null;
    return <div
      className="pdf-modal-error-wrap"
    // onClick={() => setModalError(null)}
    >
      <div className="pdf-modal-error">
        <div>{modalError}</div>
        <Button
          onClick={() => {
            setModalError(null)
            resolvePromise()
          }}
          className="pdf-error-btn"
        >Got it</Button>
      </div>
    </div>
  }

  return {
    annotations,
    getServerUpdates,
    onAnnotationSelected,
    onAnnotationEvent,
    annotNotifHolder,
    AnnotModalError,
    setModalError,
  }
}

export default usePdfAnnotations