import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useShallow } from 'zustand/react/shallow'

import useAuthoringDataStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringDataStore';

const AgeRangePreview = (props) => {
  const [fieldFormData, setFieldFormData] = useState({});

  const {
    formData,
  } = useAuthoringDataStore(
    useShallow(state => ({
      formData: state.formData,
    }))
  )

  let schema = {
    headers: [
      {
        key: "age_range",
        title: "Age range"
      },
      {
        key: "actual",
        title: "Actual number of subjects enrolled"
      }
    ],
    properties: {
      in_utero: {
        title: "In utero",
        properties: {
          required: true,
          type: "number"
        }
      },
      preterm_newborn_infants: {
        title: "Preterm newborn infants (gestational age < 37 wks)",
        properties: {
          actual: {
            required: true,
            type: "number"
          }
        }
      },
      newborns: {
        title: "Newborns (0-27 days)",
        properties: {
          actual: {
            required: true,
            type: "number"
          }
        }
      },
      infants_and_toddlers: {
        title: "Infants and toddlers (28 days-23 months)",
        properties: {
          actual: {
            required: true,
            type: "number"
          }
        }
      },
      children: {
        title: "Children (2-11 years)",
        properties: {
          actual: {
            required: true,
            type: "number"
          }
        }
      },
      adolescents: {
        title: "Adolescents (12-17 years)",
        properties: {
          actual: {
            required: true,
            type: "number"
          }
        }
      },
      adults: {
        title: "Adults (18-64 years)",
        properties: {
          actual: {
            required: true,
            type: "number"
          }
        }
      },
      elderly_65_to_84: {
        title: "From 65-84 years",
        properties: {
          actual: {
            required: true,
            type: "number"
          }
        }
      },
      elderly_over_85: {
        title: "85 years and over",
        properties: {
          actual: {
            required: true,
            type: "number"
          }
        }
      }
    }
  }

  useEffect(() => {
    if(formData?.results?.trial_info?.population?.population_age_group) {
      setFieldFormData(formData.results.trial_info.population.population_age_group)
    }
  }, [])

  const displayRows = () => {
    return Object.keys(schema.properties).map(key => {
      let fieldSchema = schema.properties[key];
      return (
        <TR key={"age-range-actual-"+ key}>
          <TD>{fieldSchema.title}</TD>
          <TD>{fieldFormData?.[key] ? fieldFormData[key] : ""}</TD>
        </TR>
      )
    })
  }

  return (
    <Container>
      <Table>
        <thead>
          {schema.headers.map((header, i) => {
            let minWidth = 200;

            return (
              <TH 
                key={`age-range-${header.key}`}
                style={{
                  minWidth: i === 0 ? 400 : minWidth
                }}>
                {header.title}
              </TH>
            )
          })}
        </thead>
        <tbody>
          {displayRows()}
        </tbody>
      </Table>
    </Container>
  );
};

export default AgeRangePreview;

const Container = styled.div`
  margin-top: 48px;
`

const Table = styled.table`

`

const TH = styled.th`
  border: 1px solid #8d8d8d;
  font-weight: bold;
  padding: 10px;  
  background-color: #F7FBFE;
`

const TR = styled.tr`
  position: relative;
`

const TD = styled.td`
  position: relative;
  border: 1px solid #8d8d8d;
  padding: 10px;
  vertical-align: top;
  transition: all .4s;
  box-sizing: border-box;
`