import React from 'react';
import { useShallow } from 'zustand/react/shallow'
import parse from "html-react-parser";
import dayjs from 'dayjs';
import { useUserSettings } from 'api/hooks';
import useAuthoringComments from '../../../../../hooks/actions/useAuthoringCommentActions';
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';
import './Comments.scss'

const Comments = (props) => {
  const { comments } = useAuthoringComments()
  const { data: userSettings } = useUserSettings()

  const {
    formView,
    formSection
  } = useAuthoringViewStore(
    useShallow(state => ({ 
      formView: state.formView,
      formSection: state.formSection,
    }))
  )

  const displayComments = () => {
    return comments.filter(comment => {
      if(formView.id === "eudra" && comment.field_key.startsWith("root_protocol")) return false; 
      else if((formView.id === "eupas" || formView.id === "ctis")  && comment.field_key.startsWith("root_result")) return false
      return true
    }).map(comment => (
      <tr>
        <td>{comment.field_name}</td>
        <td>{parse(comment.text)}</td>
        <td>{parse(comment.author_fullname)}</td>
        <td>{dayjs(comment.action_date).format(userSettings?.date_format)}</td>
        <td>{comment.resolved ? "Yes" : "No"}</td>
      </tr>
    ))
  }

  if (comments?.length === 0) return null

  return (
    <div className="page-preview-comments">
      <div className="header">Comments</div>
      <table>
        <thead>
          <tr>
            <th>Field Name</th>
            <th>Comment Text</th>
            <th>Added By</th>
            <th>Action Date</th>
            <th>Resolved</th>
          </tr>
        </thead>
        <tbody>
          {displayComments()}
        </tbody>
      </table>
    </div>
  );
};

export default Comments;