import { useState, useMemo } from 'react';
import { v4 as uuid } from 'uuid'
import { cloneDeep } from 'lodash';
import { useShallow } from 'zustand/react/shallow'

// components
import PrePeriod from './subcomponents/PrePeriod/PrePeriod';
import { Button } from 'components-design-system';
import ResultsTableCreateModal from '../../components/ResultsTableCreateModal/ResultsTableCreateModal';
import GlobalArms from './subcomponents/GlobalArms/GlobalArms';
import Period from './subcomponents/Period/Period';

// context
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';
import useResultsTableViewStore from '../../../hooks/stores/useResultsTableViewStore';

// utils
import { formSchemas } from 'constants/authoring';
import { getRegistryKeys } from '../../../utils';

const RJNewParticipantFlow = ({
  schema,
  participantData,
  setParticipantData
}) => {

  const {
    readOnly,
    formView
  } = useAuthoringViewStore(
    useShallow(state => ({
      readOnly: state.readOnly,
      formView: state.formView
    }))
  )

  const {
    tableView,
    participantView,
    participantRegistries
  } = useResultsTableViewStore()

  const [showAddPeriodModal, setShowAddPeriodModal] = useState(false);

  const handleAddPeriod = (applyAll) => {
    const id = `period-${uuid()}`;
    const startedMilestoneID = `milestone-${uuid()}`;
    const completedMilestoneID = `milestone-${uuid()}`;

    const template = cloneDeep(schema.properties.templates.period[tableView]);
    template.milestone_list[0].id = startedMilestoneID;
    template.milestone_list[1].id = completedMilestoneID;

    let tempData = { ...participantData };

    for (const registry of participantRegistries) {
      if (applyAll || registry === participantView) {
        const newPeriod = formatNewPeriod({ id, ...template }, registry);
        tempData[registry] = [...participantData[registry], newPeriod];
      } else {
        tempData[registry] = participantData[registry];
      }
    }

    setParticipantData(tempData);
  };

  const formatNewPeriod = (period, type) => {
    if (!participantData?.global_arms?.length) {
      return period;
    }

    const armParticipants = participantData.global_arms.map(arm => ({
      arm_id: arm.id,
      value: "",
      comment: "",
      registry_arm_id: formatArmIds(period.id, arm.id)
    }));

    const newMilestones = [];
    for (const milestone of period.milestone_list) {
      let newParticipants = cloneDeep(armParticipants)
      const participants = milestone.participants.concat(newParticipants);
      newMilestones.push({ ...milestone, participants });
    }

    const newDropWithdrawnReasons = [];
    for (const reason of period.drop_withdrawn_reason_list) {
      let newParticipants = cloneDeep(armParticipants)
      const participants = reason.participants.concat(newParticipants);
      newDropWithdrawnReasons.push({ ...reason, participants });
    }

    const newPeriod = {
      ...period,
      milestone_list: newMilestones,
      drop_withdrawn_reason_list: newDropWithdrawnReasons
    };

    if (type === "eudract_periods") {
      newPeriod.arms = participantData.global_arms.map(arm => ({
        ...arm,
        registry_arm_id: formatArmIds(period.id, arm.id)
      }))
    }

    return newPeriod;
  };

  const formatArmIds = (periodId, armId) => {
    let pIds = periodId.split(".")
    let pId = pIds[pIds.length - 1]

    let aIds = armId.split(".")
    let aId = aIds[aIds.length - 1]

    return `${pId}:${"ParticipantFlow-ParticipantFlowGroup." + aId}`
  }

  const displayNonDuplicateCTGPeriods = () => {
    let uniqCTGPeriods = [];
    if (participantData?.["ctg_periods"]) {
      participantData["ctg_periods"].forEach(period => {
        if (participantData["eudract_periods"]) {
          let foundEUPeriod = participantData["eudract_periods"].find(eu => eu.id === period.id);
          if (!foundEUPeriod) uniqCTGPeriods.push(period);
        } else {
          uniqCTGPeriods.push(period);
        }
      })
    }
    return uniqCTGPeriods.map((period, periodIndex) => {
      return (
        <Period
          key={`uniq-period-${periodIndex}`}
          periodIndex={periodIndex}
          participantData={participantData}
          setParticipantData={setParticipantData}
          schema={schema}
          period={period} />
      )
    })
  }

  const renderPeriods = useMemo(() => {
    return (
      <div className="flex flex-col" style={{ gap: 8 }}>
        {formView.id === "global" && displayNonDuplicateCTGPeriods()}
        {participantData?.[participantView]?.map((period, periodIndex) => {
          return (
            <Period
              key={`period-${period.id}-${periodIndex}-${tableView}`}
              periodIndex={periodIndex}
              participantData={participantData}
              setParticipantData={setParticipantData}
              schema={schema}
              period={period}
            />
          )
        })}
      </div>
    )
  }, [
    JSON.stringify(participantData?.[participantView]),
    JSON.stringify(participantData?.global_arms),
    participantView
  ])

  return (
    <div className="results-table">
      {participantView === "eudract_periods" && (
        <PrePeriod
          schema={schema}
          participantData={participantData}
          setParticipantData={setParticipantData} />
      )}
      {(participantView === "ctg_periods" || formView.id === "global") && (
        <GlobalArms
          schema={schema}
          participantData={participantData}
          setParticipantData={setParticipantData} />
      )}
      {renderPeriods}
      {readOnly !== true && (
        <Button
          size="sm"
          variant="outlined"
          onClick={() => setShowAddPeriodModal(true)}
          style={{ marginTop: 20 }}>
          Add Period +
        </Button>
      )}
      <ResultsTableCreateModal
        header="Add Period"
        onSubmit={handleAddPeriod}
        showModal={showAddPeriodModal}
        setShowModal={() => setShowAddPeriodModal(false)} />
    </div>
  );
};

export default RJNewParticipantFlow;