import { notification } from 'antd'

const useToastNotifications = () => {
  const [notifApi, toastContextHolder] = notification.useNotification()

  const openToastNotification = (type, message, description, placement) => {
    notifApi[type]({
      message: message || type,
      description,
      placement: placement || "topRight",
    })
  }

  return {
    openToastNotification,
    toastContextHolder,
  }
}

export default useToastNotifications