import React, { useState, useEffect } from 'react';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { useShallow } from 'zustand/react/shallow'
import { isEmpty } from 'lodash';

// components
import RJParticipantFlow from './RJParticipantFlow';
import CellValidation from '../components/CellValidation/CellValidation';

// utils
import { participantFlowDataSchema } from './schemas/dataSchema';
import useResultsTableArmsStore from '../../../hooks/stores/useResultsTableArmsStore';
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';
import useAuthoringVersionStore from '../../../hooks/stores/useAuthoringVersionStore';
import useAuthoringStore from '../../../hooks/stores/useAuthoringStore';
import useParticipantFlowStore from './hooks/useParticipantFlowStore';

const ParticipantWrapper = (props) => {
  const {
    onChange,
    formContext
  } = props

  const {
    formDataRef,
  } = props.formContext

  const formWidthClassName = useAuthoringStore(state => state.formWidthClassName)
  const {
    participantData,
    setParticipantData
  } = useParticipantFlowStore(
    useShallow(state => ({
      participantData: state.participantData,
      setParticipantData: state.setParticipantData,
    }))
  )

  const {
    versionData,
    selectedVersion
  } = useAuthoringVersionStore(
    useShallow(state => ({
      versionData: state.versionData,
      selectedVersion: state.selectedVersion,
    }))
  )

  const {
    formStatus,
    readOnly,
    showVersionCompare,
  } = useAuthoringViewStore(
    useShallow(state => ({
      readOnly: state.readOnly,
      formStatus: state.formStatus,
      showVersionCompare: state.showVersionCompare,
    }))
  )

  const {
    participantArmOptions,
    setProtocolArms,
    setParticipantArmOptions
  } = useResultsTableArmsStore()

  // baseline component
  const [tableMounting, setTableMounting] = useState(true)

  useEffect(() => {
    if (formStatus === "ready" && participantData && readOnly === false && tableMounting === false) {
      onChange(participantData)
    }
  }, [participantData])

  useEffect(() => {
    if (formDataRef?.results?.participant_flow?.participant_flow_table || isEmpty(formDataRef?.results?.participant_flow)) {
      let participantData = isEmpty(formDataRef?.results?.participant_flow) ? {} : { ...formDataRef.results.participant_flow.participant_flow_table }

      if (!formDataRef.results?.participant_flow?.participant_flow_table?.global_arms) participantData.global_arms = []
      if (!formDataRef.results?.participant_flow?.participant_flow_table?.eudract_periods) participantData.eudract_periods = []
      if (!formDataRef.results?.participant_flow?.participant_flow_table?.ctg_periods) participantData.ctg_periods = []
      setParticipantData(participantData)
      setTimeout(() => {
        setTableMounting(false)
      }, 800)
    } else if (formDataRef?.results?.participant_flow && !formDataRef.results?.participant_flow?.participant_flow_table) {
      let participantData = {
        global_arms: [],
        eudract_periods: [],
        ctg_periods: []
      }
      setParticipantData(participantData)
      setTimeout(() => {
        setTableMounting(false)
      }, 800)

    } else {
      setTableMounting(false)
    }

    return () => {
      setTableMounting(true)
    }
  }, [formStatus])

  useEffect(() => {
    if (formDataRef?.results?.participant_flow?.participant_flow_table) {
      let participantData = { ...formDataRef.results.participant_flow.participant_flow_table }

      if (!formDataRef.results.participant_flow.participant_flow_table?.global_arms) participantData.global_arms = []
      if (!formDataRef.results.participant_flow.participant_flow_table?.eudract_periods) participantData.eudract_periods = []
      if (!formDataRef.results.participant_flow.participant_flow_table?.ctg_periods) participantData.ctg_periods = []

      setParticipantData(participantData)
    }
  }, [JSON.stringify(formDataRef?.results?.participant_flow?.participant_flow_table?.global_arms?.map(arm => arm.id).join(",")), formStatus])

  useEffect(() => {
    if (formDataRef?.protocol?.study_arms?.arms) {
      let protocolArms = formDataRef.protocol.study_arms.arms.map(arm => {
        return {
          id: "ParticipantFlow-ParticipantFlowGroup." + arm.id,
          registry_arm_id: "ParticipantFlow-ParticipantFlowGroup." + arm.id,
          ...arm
        }
      })

      let tempOptions = [...participantArmOptions]
      protocolArms.forEach(arm => {
        let foundIndex = participantArmOptions.findIndex(option => option.id === arm.id);
        if (foundIndex === -1) {
          tempOptions.push({
            id: arm.id,
            text: "(Protocol) " + arm.title,
            from: "protocol"
          })
        } else {
          tempOptions.splice(foundIndex, 1, {
            id: arm.id,
            text: "(Protocol) " + arm.title,
            from: "protocol"
          })
        }
      })
      setProtocolArms(protocolArms)
      setParticipantArmOptions(tempOptions)
    }
  }, [JSON.stringify(formDataRef?.protocol?.study_arms?.arms)])

  if (tableMounting) return <></>

  return (
    <div className={["form-table-print", formWidthClassName].join(" ")}>
      <RJParticipantFlow
        {...props}
        participantData={participantData}
        setParticipantData={setParticipantData}
        schema={participantFlowDataSchema} />
      {versionData && selectedVersion && showVersionCompare && (
        <div className="rjs-table-comparison-wrapper">
          <RJParticipantFlow
            {...props}
            participantData={versionData?.study_data?.results?.participant_flow?.participant_flow_table}
            setParticipantData={() => { }}
            schema={participantFlowDataSchema} />
        </div>
      )}
      <CellValidation
        table="participants"
        errorKey="results_participant_flow" />
      <CellValidation
        table="participants"
        errorKey="results_participant_flow_participant_flow_table" />
    </div>
  );
};

export default React.memo(ParticipantWrapper);