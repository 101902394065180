import { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useShallow } from 'zustand/react/shallow'

// components
import CellActions from '../CellActions/CellActions';
import Label from 'components/Label/Label';
import CellValidation from '../../../components/CellValidation/CellValidation';
import InputNumber from 'components/InputNumber/InputNumber';

// context
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';

const Cell = ({
  cell,
  row,
  onChange,
  saveComment,
  jpath,
  type,
  errorKey
}) => {
  const {
    readOnly
  } = useAuthoringViewStore(
    useShallow(state => ({ readOnly: state.readOnly }))
  )

  const { ref: visibleRef, inView: inViewport } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  });

  const [showActions, setShowActions] = useState(false);

  return (
    <td
      ref={visibleRef}
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}>
      {inViewport ? (
        <>
          <div className="flex items-center" style={{ gap: 8 }}>
            <InputNumber
              id={`${jpath}-field-id`}
              readOnly={readOnly}
              value={cell?.value}
              onChange={e => onChange(e, row, cell)}
              placeholder="Participants"/>
            <CellActions
              showActions={showActions}
              setShowActions={setShowActions}
              comment={cell?.comment}
              saveComment={(comment) => saveComment(comment, row, cell)}
              jpath={jpath}
              type={type}
            />
          </div>
          {cell?.comment && (
            <Label style={{ marginTop: 6 }}>
              {cell?.comment}
            </Label>
          )}
          <CellValidation
            table="participants"
            errorKey={errorKey} />
        </>
      ) : (
        <div style={{ padding: 10 }} />
      )}
    </td>
  )
};

export default Cell;