import useGetApi from "../useGetApi"
import useMutateApi from "../useMutateApi"

// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

// utils
import getUrl from "api/apiMap"

export const useGetConditions = () => {
  return useGetApi(getUrl(apiEndpoints.conditions), queryKeys.conditions)
}

// mutate({ body })
export const usePostConditions = () => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(apiEndpoints.conditions),
    invalidateQueries: [queryKeys.conditions],
    successMsg: "Successfully created condition"
  })
}

// mutate({ id, body })
export const usePutConditions = () => {
  return useMutateApi({
    method: "put",
    apiEndpoint: getUrl(apiEndpoints.conditions),
    invalidateQueries: [queryKeys.conditions],
    successMsg: "Successfully updated condition"
  })
}

// mutate({ id })
export const useDeleteConditions = () => {
  return useMutateApi({
    method: "delete",
    apiEndpoint: getUrl(apiEndpoints.conditions),
    invalidateQueries: [queryKeys.conditions],
    successMsg: "Successfully deleted condition"
  })
}