import { useEffect, useState, useMemo } from 'react'
import {
  useLocation,
  useSearchParams,
  useNavigate
} from 'react-router-dom'
import moment from 'moment/moment'

// components
import PrimeField from 'components/PrimeField/PrimeField'
import { Button } from 'components-design-system'

// assets
import primeWhiteLogo from 'assets/images/prime-logo-white.png'
import globeLogo from 'assets/images/login-globe.png'
import loginBg from 'assets/images/login-bg.png'

// apis
import {
  usePostLoginResetPassword
} from 'api/hooks/auth/useAuthApis'

// context
import useAppState from 'context/hooks/useAppState'

// styles
import '../Login/login.scss'

const ForgotPasswordReset = () => {
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { publicEnvVar } = useAppState()

  const [newPassword, setNewPassword] = useState("")
  const [newPassword2, setNewPassword2] = useState("")
  const [isSubmitDisabled, setSubmitDisabled] = useState(true)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const postLoginResetPassword = usePostLoginResetPassword()

  const token = useMemo(() => {
    return searchParams.get('token')
  }, [searchParams])

  useEffect(() => {
    var regex = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{10,}$/);
    if (newPassword2 && newPassword2 !== newPassword) {
      setSubmitDisabled(true);
      setError("Your password does not match.");
    } else if (newPassword2 && newPassword && newPassword2 === newPassword) {
      if (regex.test(newPassword2)) {
        setSubmitDisabled(false);
        setError(null);
      } else if (isSubmitDisabled === true) {
        setSubmitDisabled(true);
        setError("Your password does not meet the required criteria.");
      }
    } else if (isSubmitDisabled === true) {
      setError(false);
      setSubmitDisabled(false);
    }
  }, [newPassword2, newPassword])

  const handleResetPassword = async () => {
    postLoginResetPassword({
      body: {
        password: newPassword2,
        token
      }
    }, {
      onSuccess: resp => {
        navigate("/login")
      },
      onError: resp => {
        console.log({ resp: resp.response?.data?.error?.msg })
        if (Array.isArray(resp?.response?.data?.error.details?.password)) {
          let errorMsg = resp.response.data.error.details.password.join(", ")
          setError(errorMsg);
        } else if (resp?.response?.data?.error.details?.msg) {
          setError(resp.response.data.error.details.msg);
        } else if(resp.response?.data?.error?.msg) {
          setError(resp.response.data.error.msg);
        }
      }
    })
  }

  const renderNotifications = () => {
    return (
      <>
        {error && (
          <div className="login-notification-container error-notif">
            <i className="fas fa-exclamation-triangle" />
            <div className="login-error-text">{error}</div>
          </div>
        )}
        {success && (
          <div className="login-notification-container success-notif">
            <i className="fad fa-check-circle" />
            <div className="login-success-text">{success}</div>
          </div>
        )}
      </>
    )
  }

  return (
    <div className="login-container">
      <img
        className="login-bg-img"
        src={loginBg}
        alt="xogene-prime-bg" />

      <div className="login-center-wrap">
        <div className="left-container">
          <div className="main-container">
            <img className="prime-logo" src={primeWhiteLogo} alt="prime-white-logo" />
            <img className="globe-logo" src={globeLogo} alt="globe-logo" />
            <h1 className="left-caption">Stay up to date with global requirements and take control of your compliance.</h1>
          </div>
          <div className="footer-container">
            © Copyright {moment().format('YYYY')}  Xogene Services LLC | V{publicEnvVar.REACT_APP_PRIME_VERSION}
          </div>
        </div>
        <div className="right-container">
          <div className="content-container">
            <div className="login-header">Reset your password</div>
            <div className="input-container">
              <PrimeField
                schema={{
                  type: "password",
                  placeholder: "New password",
                  prefix: <i className="fal fa-lock-alt login-input-icon" />,
                  style: { marginTop: 12 }
                }}
                value={newPassword}
                onChange={setNewPassword} />
              <PrimeField
                schema={{
                  type: "password",
                  placeholder: "Confirm password",
                  prefix: <i className="fal fa-lock-alt login-input-icon" />,
                  style: { marginTop: 12 }
                }}
                value={newPassword2}
                onChange={setNewPassword2} />
              <div className="helper-text">
                Password must use at least 10 characters and contain one uppercase letter, one lowercase letter, one special character, and one number.
              </div>
              <Button
                className="login-btn"
                disabled={isSubmitDisabled}
                onClick={handleResetPassword}
                style={{ marginTop: 24 }}>
                Update & Log in
              </Button>
              {renderNotifications()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordReset;