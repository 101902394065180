import React, { useRef } from 'react'
import Grid from 'components/Grid/Grid';
import moment from 'moment';
import './DocumentsGrid.scss';

// cell renderers
import LastModifiedBy from 'components/ag-grid-cell-renderers/LastModifiedBy/LastModifiedBy';
import DocumentIconRenderer from 'components/ag-grid-cell-renderers/DocumentIconCellRenderer/DocumentIconCellRenderer';
import DocumentNameTypeRenderer from 'components/ag-grid-cell-renderers/DocumentNameType/DocumentNameType';
import DocumentActionsRenderer from 'components/ag-grid-cell-renderers/DocumentActions/DocumentActions';

// context
import useDocuments from 'context/hooks/useDocuments';

// utils
import useAgGridResize from 'utilities/useAgGridResize';

const DocumentsGrid = ({
  gridApi,
  setGridApi,
  search,
  openRowPanel,
  openPdf,
}) => {
  const gridContainer = useRef(null);
  const {
    documents
  } = useDocuments()

  const gridBreakpoints = [
    {
      maxWidth: 1120,
      hide: [],
      show: ['Registries', 'Countries', 'Languages', 'Last modified by', 'Annotations']
    },
    {
      maxWidth: 600,
      hide: [],
      show: ['Last modified by']
    },
    {
      maxWidth: 400,
      hide: ['Last modified by', 'Annotations'],
      show: []
    }
  ]

  useAgGridResize(gridContainer, gridApi, gridBreakpoints);

  // TODO: remove if not using:
  const isPdf = (data) => {
    if (data) {
      const fileTypeSplit = data?.document_file?.split('.')
      let extensionIsPdf = fileTypeSplit?.[fileTypeSplit.length - 1]?.toLowerCase() === 'pdf';
      let includesPdf = data?.document_file?.includes(".pdf?Signature=") || data?.document_file?.includes(".pdfSignature")
      return (extensionIsPdf || includesPdf)
    }
    return false
  }

  const gridOptions = {
    columnDefs: [
      {
        headerName: "",
        field: "",
        cellRenderer: DocumentIconRenderer,
        cellRendererParams: openPdf,
        width: 50,
        filter: false,
        suppressSizeToFit: true,
        suppressColumnsToolPanel: true,
      },
      {
        headerName: 'Name/Type',
        field: 'name',
        minWidth: 250,
        cellRenderer: DocumentNameTypeRenderer,
        valueGetter: params => {
          let { data } = params
          return `${data?.name} ${data?.document_type_name}`;
        }
      },
      {
        headerName: 'Last modified by',
        field: 'last_modified',
        width: 130,
        cellRenderer: LastModifiedBy,
        type: "date",
        valueGetter: params => {
          let { data } = params;
          let date = null;
          if (data?.last_modified) date = moment(data.last_modified).format("YYYY-MM-DD");

          return date;
        }
      },
      {
        headerName: '',
        field: '',
        filter: false,
        cellRenderer: DocumentActionsRenderer,
        suppressSizeToFit: true,
        width: 100,
        suppressColumnsToolPanel: true
      }
    ],
    defaultColDef: {
      sortable: true,
      wrapText: true,
      filter: true,
      enableCharts: false,
      filterParams: {
        buttons: ['clear'],
      },
      cellStyle: { "whiteSpace": "normal !important" },
      autoHeight: true,
      cellClassRules: {
        oddBackground: function (params) {
          return params.rowIndex % 2 == 0
        },
      },
    },
    columnTypes: {
      date: {
        filter: 'agDateColumnFilter',
        filterParams: {
          resetButton: true,
          comparator: function (filterLocalDateAtMidnight, cellValue) {
            let cellDate = null;
            let dateAsString = "";
            dateAsString = cellValue;
            if (dateAsString === null) {
              return 0;
            }
            cellDate = moment(dateAsString).toDate();
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          }
        }
      }
    },
    suppressHorizontalScroll: true,
    getRowHeight: () => 62,
    onColumnVisible: (params) => {
      setTimeout(() => {
        params.api.sizeColumnsToFit()
      }, 200)
    },
    onColumnPinned: (params) => {
      setTimeout(() => {
        params.api.sizeColumnsToFit()
      }, 200)
    },
    onColumnMoved: (params) => {
      setTimeout(() => {
        params.api.sizeColumnsToFit()
      }, 200)
    }
  }

  const onGridReady = (params) => {
    setGridApi(params.api)
    params.api.sizeColumnsToFit()
  }

  return (
    <div ref={gridContainer} className="docs-grid">
      <Grid
        onGridReady={onGridReady}
        gridOptions={gridOptions}
        rowData={documents}
        gridApi={gridApi}
        search={search}
      />
    </div>
  )
}

export default DocumentsGrid