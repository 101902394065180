export const translateProps = {
  ctg: { // from
    eudract: { // to
      assessment_type: {
        "Systematic Assessment": "Systematic",
        "Non-systematic Assessment": "Non-systematic"
      }
    }
  },
  eudract: { // from 
    ctg: { // to
      assessment_type: {
        "Systematic": "Systematic Assessment",
        "Non-systematic": "Non-systematic Assessment",
      }
    }
  }
}

export const moveLeftHelper = (array, i) => {
  let saved = array[i-1];
  array[i-1] = array[i];
  array[i] = saved;
}

export const moveRightHelper = (array, i) => {
  let saved = array[i+1];
  array[i+1] = array[i];
  array[i] = saved;
}