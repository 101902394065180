// TODO: move all routes into app/AppRoutes file
import * as React from 'react'
import { Route, Routes, useLocation, Navigate } from 'react-router-dom'
import { LicenseManager } from 'ag-grid-enterprise'
import { Helmet } from 'react-helmet-async'

// pre-loaded components
import PersistLogin from '../components/PersistLogin/PersistLogin'
import RequireAuth from 'components/RequireAuth/RequireAuth'
import Login from '../containers/auth/Login/Login'
import ForgotPasswordReset from '../containers/auth/ForgotPasswordReset/ForgotPasswordReset'
import MainGlobalLayout from 'templates/layouts/MainGlobalLayout/MainGlobalLayout'
import Loading from '../components/Loading/Loading'

// styles
import 'antd/dist/reset.css'
import 'reactflow/dist/style.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'styles/global.scss'
import './App.scss'
import 'styles/fresh-widget.scss'
import 'styles/rjs.scss'

// context
import useAppState from '../context/hooks/useAppState'

// api
import { useUserInfo } from 'api/hooks'
import useAxiosPrivate from 'api/hooks/useAxiosPrivate'// apis
import { useGetEnvVars, getPublicEnvVar } from 'api/hooks/enum'

// navigation
import {
  STUDY_NAV_ROUTES
} from 'containers/studies/Study/navigation/navigation'
import requestNav from 'containers/requests/Request/requestNav'

// lazy loaded routes

const MyPrime = React.lazy(() => import('../containers/MyPrime/MyPrime'))

// requests
const Requests = React.lazy(() => import('containers/requests/Requests/Requests'))
const Request = React.lazy(() => import('containers/requests/Request/Request'))

// files
const Files = React.lazy(() => import('../containers/files/Files/Files'))
const File = React.lazy(() => import('containers/files/File/File'))

// studies
const Studies = React.lazy(() => import('../containers/studies/Studies/Studies'))
const Study = React.lazy(() => import('../containers/studies/Study/Study'))
const Assessment = React.lazy(() => import('../containers/Assessment/Assessment'))
const CreateStudy = React.lazy(() => import('../containers/studies/CreateStudy/CreateStudy'))
const Activities = React.lazy(() => import('../containers/activities/Activities/Activities'))
const Activity = React.lazy(() => import('../containers/activities/Activity/Activity'))
const Actions = React.lazy(() => import('../containers/actions/Actions'))
const SiteManagement = React.lazy(() => import('../containers/SiteManagement/SiteManagement'))
const ProtocolMaintenance = React.lazy(() => import('../containers/ProtocolMaintenance/ProtocolMaintenance'))
const UploadHistory = React.lazy(() => import('../containers/UploadHistory/UploadHistory'))

// metrics
const ActivityChart = React.lazy(() => import('../containers/ActivityChart/ActivityChart'))
const ComplianceChart = React.lazy(() => import('../containers/ComplianceChart/ComplianceChart'))
const ActivityDashboard = React.lazy(() => import('../containers/ActivityDashboard/ActivityDashboard'))
const AssessmentDashboard = React.lazy(() => import('../containers/AssessmentDashboard/AssessmentDashboard'))
const CompletedActivities = React.lazy(() => import('../containers/CompletedActivities/CompletedActivities'))
const AssessmentsDue = React.lazy(() => import('../containers/AssessmentsDue/AssessmentsDue'))
const ActivityPlanningDashboard = React.lazy(() => import('../containers/ActivityPlanningDashboard/ActivityPlanningDashboard'))

// reports
const AllReports = React.lazy(() => import('../containers/AllReports/AllReports'))
const ActionsReport = React.lazy(() => import('../containers/reports/ActionsReport/ActionsReport'))
const ActivityReport = React.lazy(() => import('../containers/reports/ActivityReport/ActivityReport'))
const ActivityStateReport = React.lazy(() => import('../containers/reports/ActivityStateReport/ActivityStateReport'))
const AssessmentReport = React.lazy(() => import('../containers/reports/AssessmentReport/AssessmentReport'))
const AssessmentChangeReport = React.lazy(() => import('../containers/reports/AssessmentChangeReport/AssessmentChangeReport'))
const AttachmentReport = React.lazy(() => import('../containers/reports/AttachmentReport/AttachmentReport'))
const BottleneckReport = React.lazy(() => import('../containers/reports/BottleneckReport/BottleneckReport'))
const CommentReport = React.lazy(() => import('../containers/reports/CommentReport/CommentReport'))
const DataChangeReport = React.lazy(() => import('../containers/reports/DataChangeReport/DataChangeReport'))
const DataImportReport = React.lazy(() => import('../containers/reports/DataImportReport/DataImportReport'))
const IllogicalDataReport = React.lazy(() => import('../containers/reports/IllogicalDataReport/IllogicalDataReport'))
const MaintenanceReport = React.lazy(() => import('../containers/reports/MaintenanceReport/MaintenanceReport'))
const MaintenanceTriggerReport = React.lazy(() => import('../containers/reports/MaintenanceTriggerReport/MaintenanceTriggerReport'))
const NewRequestReport = React.lazy(() => import('../containers/reports/NewRequestReport/NewRequestReport'))
const NewStudyReport = React.lazy(() => import('../containers/reports/NewStudyReport/NewStudyReport'))
const RequestReport = React.lazy(() => import('../containers/reports/RequestReport/RequestReport'))
const StudySiteReport = React.lazy(() => import('../containers/reports/StudySiteReport/StudySiteReport'))
const StudyReport = React.lazy(() => import('../containers/reports/StudyReport/StudyReport'))

// admin pages
const WorkflowBuilder = React.lazy(() => import('../containers/admin/WorkflowBuilder/WorkflowBuilder'))
const AssessmentTypes = React.lazy(() => import('../containers/admin/AssessmentTypes/AssessmentTypes'))
const Configurations = React.lazy(() => import('../containers/admin/Configurations/Configurations'))
const GridPresets = React.lazy(() => import('../containers/admin/GridPresets/GridPresets'))
const PRSSiteMaskingSettings = React.lazy(() => import('../containers/admin/PRSSiteMaskingSettings/PRSSiteMaskingSettings'))
const PRSLoginSettings = React.lazy(() => import('../containers/admin/PRSLoginSettings/PRSLoginSettings'))
const Users = React.lazy(() => import('../containers/admin/Users/Users'))

// account settings
const AccountSettings = React.lazy(() => import('../containers/AccountSettings/AccountSettings'))

const PageNotFound = React.lazy(() => import('../components/PageNotFound/PageNotFound'))

// standalone
const AuthoringReviewContainer = React.lazy(() => import('../containers/AuthoringReview/AuthoringReviewContainer'))
const RequestReview = React.lazy(() => import('../containers/requests/RequestReview/RequestReview'))

const App = () => {
  const location = useLocation()
  const { pathname } = location
  useAxiosPrivate()
  const { data: envVars, isInitialLoading: isEnvVarsLoading } = useGetEnvVars()

  const { isInitialLoading: isUserLoading, data: user } = useUserInfo()

  const {
    blurLoading,
    toastContextHolder,
    appState,
    setPublicEnvVars
  } = useAppState()

  // when the password expires redirect to sx login page
  React.useEffect(() => {
    getPublicEnvVar().then(res => {
      setPublicEnvVars(res.data)
    })
  }, [])

  React.useEffect(() => {
    if (envVars) {
      LicenseManager.setLicenseKey(envVars.REACT_APP_AG_GRID)
    }
  }, [envVars])

  // grey background for My Prime page
  // TODO: remove when we add grey backtround to all pages
  React.useEffect(() => {
    if (pathname.startsWith('/my-prime')) {
      document.body.classList.add('dark-grey-bg')
    } else if (pathname.startsWith('/request')) {
      document.body.classList.add('grey-bg')
    } else {
      document.body.classList.remove('dark-grey-bg')
      document.body.classList.remove('grey-bg')
    }
  }, [pathname])

  if (isUserLoading || isEnvVarsLoading || appState === "loading") return <Loading />

  return <>
    <Helmet>
      <title>Prime</title>
    </Helmet>
    {toastContextHolder}
    <React.Suspense fallback={<Loading />}>
      <div className={`prime-app${blurLoading ? ' blur' : ''}`}>
        <Routes location={location}>
          <Route exact path="login" element={<Login />} />
          <Route exact path="password-reset" element={<ForgotPasswordReset />} />
          <Route element={<PersistLogin />}>
            <Route path="*" element={<RequireAuth />}>
              <Route element={<MainGlobalLayout />}>
                {/* <Route path='*' element={<PageNotFound />} /> */}

                {/* ------------- MY PRIME------------- */}
                <Route index element={<Navigate replace to="/my-prime" />} />
                <Route path="my-prime" element={<MyPrime />} />

                {/* ------------- REQUESTS ------------- */}
                <Route path="requests" exact element={<Requests />} />
                {/* ---------- request details ---------- */}
                <Route path="request/:requestId" element={<Request />}>
                  <Route path="" element={<Navigate to="overview" />} />
                  {requestNav.map(route => <Route {...route} />)}
                </Route>
                {/* -------- request file details -------- */}
                <Route path="request/:requestId/file/:fileId" element={<File />} />

                {/* ------------- FILES ------------- */}
                <Route path="files" exact element={<Files />} />
                <Route path="file/:fileId" element={<File />} />

                {/* ------------- STUDIES ------------- */}
                <Route path="studies" exact element={<Studies />} />
                {/* ------------- study details ------------- */}
                <Route path="study/:studyId" element={<Study />}>
                  <Route path="" element={<Navigate to="overview" />} />
                  {STUDY_NAV_ROUTES.map(route => <Route {...route} />)}
                </Route>
                {/* ------------- assessment details ------------- */}
                <Route path="study/:studyId/assessment/:assessmentId" element={<Assessment />} />
                <Route path="create-study" element={<CreateStudy />} />
                <Route path="activities" element={<Activities />} />
                {/* ------------- activity details ------------- */}
                <Route path="study/:studyId/activity/:activityId" element={<Activity />} />
                <Route path="action-items" element={<Actions />} />
                <Route path="site-management" element={<SiteManagement />} />
                <Route path="protocol-maintenance" element={<ProtocolMaintenance />} />
                <Route path="upload-history" element={<UploadHistory />} />

                {/* ------------- METRICS------------- */}
                <Route path="metrics" >
                  <Route path="activity-chart" element={<ActivityChart />} />
                  <Route path="compliance-chart" element={<ComplianceChart />} />
                  <Route path="activity-dashboard" element={<ActivityDashboard />} />
                  <Route path="assessment-dashboard" element={<AssessmentDashboard />} />
                  <Route path="completed-activities" element={<CompletedActivities />} />
                  <Route path="assessments-due" element={<AssessmentsDue />} />
                  <Route path="activity-planning" element={<ActivityPlanningDashboard />} />
                </Route>

                {/* ------------- ADMIN ------------- */}
                <Route path="admin">
                  <Route path="workflow-builder" element={<WorkflowBuilder />} />
                  <Route path="assessment-types" element={<AssessmentTypes />} />
                  <Route path="configurations" element={<Configurations />} />
                  <Route path="grid-presets" element={<GridPresets />} />
                  <Route path="users" element={<Users />} />
                  <Route path="prs-site-masking" element={<PRSSiteMaskingSettings
                  />} />
                  <Route path="prs-login-settings" element={<PRSLoginSettings />} />
                </Route>

                {/* ------------- REPORTS ------------- */}
                {/* ----------- all reports ----------- */}
                <Route path="reports/all-reports" element={<AllReports />} />
                <Route path="reports">
                  <Route path="action-items" element={<ActionsReport />} />
                  <Route path="activity" element={<ActivityReport />} />
                  <Route path="activity-state" element={<ActivityStateReport />} />
                  <Route path="maintenance" element={<MaintenanceReport />} />
                  <Route path="assessment" element={<AssessmentReport />} />
                  <Route path="assessment-change" element={<AssessmentChangeReport />} />
                  {/* <Route path="attachment" element={<AttachmentReport />} /> */}
                  <Route path="bottleneck" element={<BottleneckReport />} />
                  <Route path="comment" element={<CommentReport />} />
                  <Route path="data-change" element={<DataChangeReport />} />
                  <Route path="data-import" element={<DataImportReport />} />
                  <Route path="illogical-data" element={<IllogicalDataReport />} />
                  <Route path="maintenance-trigger" element={<MaintenanceTriggerReport />} />
                  <Route path="new-request" element={<NewRequestReport />} />
                  <Route path="new-study" element={<NewStudyReport />} />
                  <Route path="request" element={<RequestReport />} />
                  <Route path="study-site" element={<StudySiteReport />} />
                  <Route path="study" element={<StudyReport />} />
                </Route>

                {/* ------------- ACCOUNT SETTINGS ------------- */}
                <Route path="account-settings" element={<AccountSettings />} />

                {/* ------------- AUTHORING REVIEW ------------- */}
                <Route path="study/:studyId/authoring-review" element={<AuthoringReviewContainer />} />

                {/* ------------- PAGE NOT FOUND ------------- */}
                <Route path="*" element={<PageNotFound />} />

              </Route>{/* MainGlobalLayout */}

              {/* ------------- REQUEST REVIEW ------------- */}
              <Route path="request/:requestId/request-review" element={<RequestReview />} />

            </Route> {/*RequireAuth*/}
          </Route> {/* PersistLogin */}
        </Routes>
      </div>
    </React.Suspense>
    {blurLoading && <Loading />}
  </>
}

export default App
