import { useState } from 'react';
import { useShallow } from 'zustand/react/shallow'

// components
import PrimeField from 'components/PrimeField/PrimeField';
import RowActions from '../../../components/RowActions/RowActions';
import Cell from '../Cell/Cell';

// context
import useResultsTableViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableViewStore';
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';

// helpers
import { moveCategoryUpHelper, moveCategoryDownHelper } from '../../helpers/helpers';

const MeasureCategory = ({
  measure,
  measureIndex,
  row,
  rowIndex,
  category,
  catIndex,
  outcomeData,
  setOutcomeData,
  showUnitsAnalyzed
}) => {
  const {
    tableView
  } = useResultsTableViewStore()
  const {
    readOnly
  } = useAuthoringViewStore(
    useShallow(state => ({ readOnly: state.readOnly }))
  )

  const [showActions, setShowActions] = useState(false)

  const jpath = `OutcomeRptGroup.outcome_measures_${row.id}-${category.id}`;

  const handleCategoryTitle = (e) => {
    let tempData = { ...outcomeData }

    Object.keys(tempData).forEach(registry => {
      let foundOMIndex = tempData[registry].outcome_measures.findIndex(om => om.id === measure.id);
      let om = tempData[tableView].outcome_measures[foundOMIndex];

      if (foundOMIndex !== -1) {
        let catIndex = om.measure_rows[rowIndex].categories.findIndex(item => item.id === category.id);

        if (catIndex !== -1) {
          for (let rI = 0; rI < om.measure_rows.length; rI++) {
            om.measure_rows[rI].categories[catIndex].title = e;
          }
        }
      }
    })

    setOutcomeData(tempData);
  }

  const handleUp = () => {
    let tempData = { ...outcomeData }
    Object.keys(tempData).forEach(registry => {
      let foundOMIndex = outcomeData[registry].outcome_measures.findIndex(om => om.id === measure.id);
      if (foundOMIndex !== -1) {
        if (tempData[registry].outcome_measures[foundOMIndex]?.measure_rows) {
          let foundRowIndex = tempData[registry].outcome_measures[foundOMIndex]?.measure_rows.findIndex(measureRow => measureRow.id === row.id);
          if (foundRowIndex !== -1) {
            let row = tempData[registry].outcome_measures[foundOMIndex].measure_rows[foundRowIndex];
            let foundCatIndex = row.categories.findIndex(cat => cat.id === category.id)
            if (foundCatIndex !== -1 && foundCatIndex !== 0) {
              for (let rI = 0; rI < tempData[registry].outcome_measures[foundOMIndex].measure_rows.length; rI++) {
                moveCategoryUpHelper(tempData, registry, foundOMIndex, rI, foundCatIndex);
              }
            }
          }
        }
      }
    })

    setOutcomeData(tempData);
  }

  const handleDown = () => {
    let tempData = { ...outcomeData }
    Object.keys(tempData).forEach(registry => {
      let foundOMIndex = outcomeData[registry].outcome_measures.findIndex(om => om.id === measure.id);
      if (foundOMIndex !== -1) {
        if (tempData[registry].outcome_measures[foundOMIndex]?.measure_rows) {
          let foundRowIndex = tempData[registry].outcome_measures[foundOMIndex]?.measure_rows.findIndex(measureRow => measureRow.id === row.id);
          if (foundRowIndex !== -1) {
            let row = tempData[registry].outcome_measures[foundOMIndex].measure_rows[foundRowIndex];
            let foundCatIndex = row.categories.findIndex(cat => cat.id === category.id)
            if (foundCatIndex !== -1 && foundCatIndex !== row.categories.length - 1) {
              for (let rI = 0; rI < tempData[registry].outcome_measures[foundOMIndex].measure_rows.length; rI++) {
                moveCategoryDownHelper(tempData, registry, foundOMIndex, rI, foundCatIndex);
              }
            }
          }
        }
      }
    })

    setOutcomeData(tempData);
  }

  const handleDelete = (applyAll) => {
    let tempData = { ...outcomeData }
    Object.keys(tempData).forEach(registry => {
      if (applyAll || registry === tableView) {
        let foundOMIndex = tempData[registry].outcome_measures.findIndex(om => om.id === measure.id);
        if (foundOMIndex !== -1) {
          let om = tempData[registry].outcome_measures[foundOMIndex];
          let catIndex = om.measure_rows[rowIndex].categories.findIndex(item => item.id === category.id);
          if (catIndex !== -1) {
            om.measure_rows.forEach(row => {
              row.categories.splice(catIndex, 1);
            })
          }
        }
      }
    })
    setOutcomeData(tempData);
  }

  const canShowArrowUp = () => {
    if (tableView === "eudract" && catIndex !== 0) {
      return true
    } else if (row.categories[0].title === "Number Analyzed" && catIndex > 1) {
      return true;
    } else if (catIndex !== 0 && !row.categories[0].title === "Number Analyzed") {
      return true;
    }
    return false;
  }

  const canShowArrowDown = () => {
    if (tableView === "eudract" && catIndex !== row.categories.length - 1) {
      return true
    } else if (catIndex !== measure.measure_rows.length - 1 && !row.categories[0].title === "Number Analyzed") {
      return true;
    } else if (row.categories[0].title === "Number Analyzed" && catIndex !== row.categories.length - 1) {
      return true;
    }
    return false;
  }

  return (
    <>
      <td
        onMouseEnter={() => setShowActions(true)}
        onMouseLeave={() => setShowActions(false)}
        className='col-sm'>
        {(row.categories.length > 2 || tableView === "eudract") && category.id !== "num-analyzed" && rowIndex === 0
          ? (
            <div
            >
              <PrimeField
                schema={{
                  type: "text",
                  label: "Category Title"
                }}
                readOnly={readOnly}
                value={category.title || ""}
                onChange={e => handleCategoryTitle(e)} />
              <RowActions
                showActions={showActions}
                setShowActions={setShowActions}
                showDown={canShowArrowDown()}
                showUp={canShowArrowUp()}
                handleUp={handleUp}
                handleDown={handleDown}
                handleDelete={handleDelete}
                canDelete={true}
                jpath={`om-${measure.id}-row-${row.id}-category-${category.id}`}
                deleteModalTitle="Delete category?" />
            </div>
          ) : (
            <div
              id={jpath}
              className="flex justify-between items-center">
              {category.title}
              <RowActions
                showActions={showActions}
                setShowActions={setShowActions}
                jpath={`om-${measure.id}-row-${row.id}-category-${category.id}`}
              />
            </div>
          )
        }
      </td>
      {category?.values?.map((value, valIndex) => {
        return (
          <Cell
            key={`cat-${rowIndex}-val-${catIndex}-${valIndex}`}
            showUnitsAnalyzed={showUnitsAnalyzed}
            value={value}
            valIndex={valIndex}
            measure={measure}
            measureIndex={measureIndex}
            row={row}
            rowIndex={rowIndex}
            category={category}
            catIndex={catIndex}
            outcomeData={outcomeData}
            setOutcomeData={setOutcomeData} />
        )
      })}
    </>
  );
};

export default MeasureCategory;