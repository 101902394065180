import { useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow'
import styled from 'styled-components';
import { uniqBy } from 'lodash';
import { getSectionRegistry } from 'utilities/study-views';

// components
import PrimeField from 'components/PrimeField/PrimeField';
import { Button } from 'components-design-system'
import { Form } from 'antd';
import RegistryButtons from '../../../Authoring/subcomponents/RegistryButtons/RegistryButtons';

// constants
import { getSubmissionSchemas, getSubmissionFormSections } from 'constants/submissions';

// context
import useSubmissions from 'context/hooks/useSubmissions';
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';
import useAuthoringStore from '../../../Authoring/hooks/stores/useAuthoringStore';

// apis
import { useUserPermissions } from 'api/hooks';
import { useGetAuthoringVersions } from 'api/hooks/studies/useAuthoringApi';

// styles
import './SubmissionsActions.scss'

const SubmissionsActions = ({
  setOpenUploadModal,
  onDownloadPdf,
  onDownloadXml
}) => {
  const { data: permissions } = useUserPermissions()
  const authoringId = useAuthoringStore(state => state.authoringId)
  const { data: versionResp } = useGetAuthoringVersions(authoringId)
  const versionList = versionResp?.versions || []
  const [formVersionItems, setFormVersionItems] = useState([])

  const {
    formVersion,
    setFormVersion,
  } = useSubmissions()

  const {
    formView,
    setFormView,
    formSection,
    setFormSection,
  } = useAuthoringViewStore(
    useShallow(state => ({ 
      formView: state.formView,
      setFormView: state.setFormView,
      formSection: state.formSection,
      setFormSection: state.setFormSection,
    }))
  )

  const displayDownloadButton = () => {
    if (formView.id === "eupas" || formView.id === "ctis") {
      return (
        <Button
          onClick={onDownloadPdf}
          key={"unhold-button1"}
          style={{ marginRight: 12 }}
          disabled={!formVersion}
          size="sm"
          variant="outlined"
        >
          Download PDF
        </Button>
      )
    } else if (formView.id !== "global") {

      return (
        <Button
          onClick={onDownloadXml}
          key={"unhold-button1"}
          style={{ marginRight: 12 }}
          disabled={!formVersion}
          size="sm"
          variant="outlined"
        >
          Download XML
        </Button>
      )
    }
  }

  useEffect(() => {
    const formVersionItems = uniqBy(versionList, "version_num").map((versItem) => {
      const versNumber = Number(versItem.version_num).toFixed(1);
      return { id: versItem.version_num, text: versNumber };
    })

    setFormVersionItems(formVersionItems)
    if (formVersionItems?.[0]) setFormVersion(formVersionItems[0])
  }, [versionList])

  return (
    <div
      id="sticky-form-navigator"
      className="submissions-actions"
    >
      <ContainerForPadding>
        <LeftContainer>
          <Form layout={"horizontal"} style={{ display: "flex", gap: 22, alignItems: "center" }}>
            <RegistryButtons
              formView={formView}
              setFormView={setFormView}
              formSection={formSection}
              formSchemas={getSubmissionSchemas()}
            />
            <Form.Item
              label={"Select Section"}
              className="study-form-action-bar-actions"
              colon={false}
            >
              <PrimeField
                schema={{
                  type: "dropdown",
                  items: getSubmissionFormSections()
                }}
                value={formSection?.text}
                style={{ width: 200}}
                onChange={(e) => {
                  let foundItem = getSubmissionFormSections().find(item => item.id === e)
                  if (foundItem) {
                    setFormSection(foundItem)
                    setFormView(getSectionRegistry(foundItem, formView, 'ctg'))
                  }
                }} />
            </Form.Item>

            <Form.Item
              label={"Select Version"}
              className="study-form-action-bar-actions"
              colon={false}
            >
              <PrimeField
                schema={{
                  type: "dropdown",
                  items: formVersionItems
                }}
                value={formVersion?.text}
                containerStyle={{ width: 70 }}
                onChange={(e) => {
                  let foundItem = formVersionItems.find(item => item.id === e)
                  setFormVersion(foundItem)
                }} />
            </Form.Item>
          </Form>
        </LeftContainer>
      </ContainerForPadding>
      <ButtonContainer>
        <RightContainer>
          {displayDownloadButton()}
          {permissions?.["study.form.submission"] && formView.id === "ctg" && <Button
            onClick={() => setOpenUploadModal(true)}
            key={"unhold-button2"}
            style={{ marginRight: 12 }}
            size="sm"
            variant="primary"
            disabled={formView?.id === 'eudra' || !formVersion}
          >
            Release to CTG
          </Button>}
        </RightContainer>
      </ButtonContainer>
    </div>
  );
};

export default SubmissionsActions;

const ContainerForPadding = styled.div`
  padding: 8px 18px;
`

const LeftContainer = styled.div`
  display: flex;
  align-items: flex-start;
`

const RightContainer = styled(ContainerForPadding)`
  display: flex;
  align-items: center;

  .bx--dropdown__wrapper {
    min-width: 250px;
  }
`

const ButtonContainer = styled.div`
  display: flex;

  button.bx--tooltip__trigger.bx--tooltip__trigger--definition {
    background-color: #fff;
    border: 1px solid #18669E;
    padding: 7px 18px;
    border-radius: 3px;
    margin-right: 10px;
    font-size: 14px;
    line-height: 18px;
    color: #18669E;

    &:hover {
      background-color: #18669E;
      color: white;
    }
  }
`