// TODO: move inside app/
import React from 'react';

// context
import { AuthProvider } from 'context/hooks/useAuth';
import { AppStateProvider } from 'context/hooks/useAppState'
import { UsersProvider } from 'context/hooks/useUsers'
import { WorkflowProvider } from 'context/hooks/useWorkflowTracker';
import { CubeProvider } from 'context/hooks/useCube'
import { ReactFlowProvider } from 'reactflow';
import { WorkflowBuilderProvider } from './hooks/useWorkflowBuilder';

const AppContextProviders = ({
  children
}) => {
  return (
    <AppStateProvider>
      <AuthProvider>
        <UsersProvider>
          <WorkflowProvider>
            <CubeProvider>
              <ReactFlowProvider>
                <WorkflowBuilderProvider>
                  {children}
                </WorkflowBuilderProvider>
              </ReactFlowProvider>
            </CubeProvider>
          </WorkflowProvider>
        </UsersProvider>
      </AuthProvider>
    </AppStateProvider>
  );
};

export default AppContextProviders;