import useGetApi from "../useGetApi"
import useMutateApi from "../useMutateApi"
import getUrl from "api/apiMap"
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

const useGetStudyTeam = (studyId) => {
  return useGetApi(`${getUrl(apiEndpoints.studies)}${studyId}/team/`, queryKeys.studies.team(Number(studyId)))
}

// mutate: { body }
const usePostStudyTeam = (studyId) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: `${getUrl(apiEndpoints.studies)}${studyId}/team/`,
    invalidateQueries: [queryKeys.studies.team(Number(studyId))],
    successMsg: 'Successfully updated team'
  })
}

export {
  useGetStudyTeam,
  usePostStudyTeam,
}