import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid'
import { useShallow } from 'zustand/react/shallow'

// components
import ReportingGroupList from './subcomponents/ReportingGroupList/ReportingGroupList';
import { Button } from 'components-design-system';
import ResultsTableCreateModal from '../../components/ResultsTableCreateModal/ResultsTableCreateModal';
import ResultsTableDeleteModal from '../../components/ResultsTableDeleteModal/ResultsTableDeleteModal';
import Measure from './subcomponents/Measure/Measure';

// context
import useAuthoringViewStore from '../../../hooks/stores/useAuthoringViewStore';
import useResultsTableViewStore from '../../../hooks/stores/useResultsTableViewStore';
import useAuthoringDataStore from '../../../hooks/stores/useAuthoringDataStore';

const RJOutcomeMeasures = (props) => {
  const {
    schema,
    outcomeData,
    setOutcomeData,
  } = props;

  const {
    formData,
    setFormData,
  } = useAuthoringDataStore(
    useShallow(state => ({
      formData: state.formData,
      setFormData: state.setFormData,
    }))
  )

  const {
    readOnly
  } = useAuthoringViewStore(
    useShallow(state => ({ readOnly: state.readOnly }))
  )

  const {
    tableView,
  } = useResultsTableViewStore()

  const [showCreateMeasure, setShowCreateMeasure] = useState(false);
  const [showDeleteMeasure, setShowDeleteMeasure] = useState(false);
  const [showCopyMeasure, setShowCopyMeasure] = useState(false);

  const [selectedMeasure, setSelectedMeasure] = useState(null);
  const [showMeasures, setShowMeasures] = useState(true);


  const onAddOutcomeMeasure = (applyAll) => {
    let tempData = { ...outcomeData };
    let omId = `om-${uuid()}`;

    let rowId = `row-${uuid()}`;
    let categoryIds = []

    for (let i = 0; i < 15; i++) {
      let cid = `Category-${uuid()}`;
      categoryIds.push(cid)
    }

    Object.keys(tempData).forEach(registry => {
      if (applyAll === true || registry === tableView) {
        let outcomeMeasure = { id: omId };
        let omSchema = schema.properties[registry].properties.outcome_measure.properties;

        Object.keys(omSchema).forEach(key => {
          outcomeMeasure[key] = omSchema[key].value || "";
        })

        // create measure row
        let row = {
          id: rowId,
          title: "",
          categories: []
        }
        let catIndex = 0;

        schema.properties[registry].properties.rows.categories.forEach(cat => {
          let values = [];
          let categoryProps = {};
          Object.keys(cat.properties).forEach(key => categoryProps[key] = 0);

          values = formData?.[registry]?.arms?.map(arm => {
            return {
              group_id: arm.id,
              registry_arm_id: arm.registry_arm_id,
              ...categoryProps
            }
          })

          if (cat.id !== "num-analyzed") {
            catIndex++
          }

          let category = {
            id: cat.id === "num-analyzed" ? "num-analyzed" : categoryIds[catIndex],
            title: cat.title,
            values
          }

          row.categories.push(category);
        })
        // add row to outcome measure
        outcomeMeasure.measure_rows = [row];


        tempData[registry].outcome_measures = [...tempData[registry].outcome_measures, outcomeMeasure];
      }
    })

    setOutcomeData(tempData);
  }

  const openCopyOutcomeMeasureModal = (measure) => {
    setSelectedMeasure(measure);
    setShowCopyMeasure(true);
  }

  const openDeleteMeasureModal = (measure) => {
    setSelectedMeasure(measure);
    setShowDeleteMeasure(true);
  }

  const renderMeasures = () => {
    return outcomeData?.[tableView]?.outcome_measures?.map((measure, i) => {
      return (
        <Measure
          key={measure.id}
          openCopyOutcomeMeasureModal={openCopyOutcomeMeasureModal}
          openDeleteMeasureModal={openDeleteMeasureModal}
          measure={measure}
          measureIndex={i}
          numOfMeasures={outcomeData[tableView].outcome_measures.length}
          outcomeData={outcomeData}
          setOutcomeData={setOutcomeData}
          schema={schema} />
      )
    })
  }

  const onDeleteOutcomeMeasure = (applyAll) => {
    let tempData = { ...outcomeData };

    Object.keys(tempData).forEach(registry => {
      if (applyAll === true || registry === tableView) {
        let foundIndex = tempData[registry].outcome_measures.findIndex(om => om.id === selectedMeasure.id);

        if (foundIndex !== -1) {
          tempData[registry].outcome_measures.splice(foundIndex, 1);
        }
      }
    })
    setOutcomeData(tempData);
    setShowDeleteMeasure(false);
    setSelectedMeasure(null);
  }

  return (
    <div className="results-table">
      {tableView === "eudract" && (
        <ReportingGroupList
          schema={schema}
          outcomeData={outcomeData}
          setOutcomeData={setOutcomeData} />
      )}
      <div style={{ display: "flex", flexDirection: "column", gap: 24 }}>
        {renderMeasures()}
      </div>

      {readOnly !== true &&
        <Button
          size="sm"
          variant="outlined"
          onClick={() => setShowCreateMeasure(true)}
          style={{
            marginTop: 24
          }}>
          <i
            className="fal fa-plus-circle"
            style={{
              marginTop: 2,
              marginRight: 8
            }} />
          Add Outcome Measure
        </Button>
      }

      <ResultsTableCreateModal
        id="outcome-measures"
        header="Create Outcome Measure"
        onSubmit={onAddOutcomeMeasure}
        showModal={showCreateMeasure}
        setShowModal={setShowCreateMeasure} />

      <ResultsTableDeleteModal
        id="outcome-measures"
        header="Delete Outcome Measure"
        onSubmit={onDeleteOutcomeMeasure}
        showModal={showDeleteMeasure}
        setShowModal={setShowDeleteMeasure} />
    </div>
  );
};

export default RJOutcomeMeasures;