import React from 'react';
import styled from 'styled-components';

// Components

const ObjectTemplate = (props) => {
  const { properties, uiSchema } = props;
  const options = uiSchema["ui:options"];
  const title = options && options.title;

  let styles = {};
  
  if(options && options.styles) {
    styles = options.styles;
  }
  
  return (
    <Container style={styles.objContainer}>
      <Title style={styles && styles.objTitle}>{title}</Title>
      <InnerContainer style={styles && styles.objInnerContainer}>
        {properties.map((prop, i) => {
          const { uiSchema } = prop.content.props;
          const options = uiSchema["ui:options"];

          let styles = {};
          if(options && options.styles) {
            styles = options.styles;
          }
          return (
            <FieldContainer 
              key={prop.name + "-" + "i"} 
              style={styles && styles.field}>
              {prop.content}
            </FieldContainer>
            )
        })}
      </InnerContainer>
    </Container>
  );
};

export default ObjectTemplate;

const Container = styled.div`

`

const InnerContainer = styled.div`
  
`

const Title = styled.div`
`

const FieldContainer = styled.div`
  
`