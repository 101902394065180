import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow'

// components
import LabelMenu from '../components/LabelMenu/LabelMenu';
import ArrayFieldItem from './ArrayFieldItem';
import { Popover, Tooltip, Skeleton } from 'antd'
import { Button } from 'components-design-system'

// utils/helpers
import { registryValidMsgs } from 'utilities/registryValidations'

// context
import useAuthoringViewStore from '../../hooks/stores/useAuthoringViewStore';
import useAuthoringCommentsStore from '../../hooks/stores/useAuthoringCommentsStore';
import useAuthoringActions from '../../hooks/actions/useAuthoringActions';
import useAuthoringValidationsStore from '../../hooks/stores/useAuthoringValidationsStore';
import useAuthoringDataStore from '../../hooks/stores/useAuthoringDataStore';
import useAuthoringStore from '../../hooks/stores/useAuthoringStore';

// styles
import './ArrayFieldTemplate.scss';

const ArrayFieldTemplate = (props) => {
  const [showMenu, setShowMenu] = useState(false);
  const [fieldComments, setFieldComments] = useState([]);
  const { uiSchema, idSchema, canAdd, onAddClick } = props;
  const [fieldLoading, setFieldLoading] = useState(false)
  const [isRootArray, setIsRootArray] = useState(false);

  const {
    isProtocolOmDisabled,
    setIsProtocolOmDisabled,
    isProtocolOmDisabledOverriden,
    setIsProtocolOmDisabledOverriden
  } = useAuthoringStore(
    useShallow(state => ({
      isProtocolOmDisabled: state.isProtocolOmDisabled,
      setIsProtocolOmDisabled: state.setIsProtocolOmDisabled,
      isProtocolOmDisabledOverriden: state.isProtocolOmDisabledOverriden,
      setIsProtocolOmDisabledOverriden: state.setIsProtocolOmDisabledOverriden
    })),
  )

  const {
    readOnly
  } = useAuthoringViewStore(
    useShallow(state => ({ readOnly: state.readOnly })),
  )

  const {
    openComment,
    setOpenComment,
    commentsMap
  } = useAuthoringCommentsStore(
    useShallow(state => ({
      openComment: state.openComment,
      setOpenComment: state.setOpenComment,
      commentsMap: state.commentsMap
    }))
  )

  const {
    registryValidations
  } = useAuthoringValidationsStore(
    useShallow(state => ({
      registryValidations: state.registryValidations,
    }))
  )

  const {
    formData,
  } = useAuthoringDataStore(
    useShallow(state => ({
      formData: state.formData,
    }))
  )

  const {
    scrollToField
  } = useAuthoringActions()

  const options = uiSchema["ui:options"];
  const jpath = idSchema.$id

  let title = "Title"
  let arrayItemTitle = "Item Title";
  let addButtonTitle = "Add Item";
  // let styles = {};
  let classNames = {}
  let canCopy = false;
  let canCopyOutcomeMeasure = false;
  let tooltip, tooltipUrl, tooltipUrlTitle;
  let showIndex = options?.showIndex;
  let canCopyCtgOutcomeMeasures = false

  useEffect(() => {
    if (commentsMap) {
      let jpath = idSchema.$id;
      if (commentsMap[jpath]) {
        let fieldComments = commentsMap[jpath];
        setFieldComments(fieldComments);
      } else {
        setFieldComments([]);
      }
    }
  }, [commentsMap]);

  useEffect(() => {
    if (openComment && jpath === openComment) {
      scrollToField(jpath);
      setOpenComment(null);
    }
  }, [openComment])

  if (options) {
    title = options.title;
    arrayItemTitle = options.arrayItemTitle;
    addButtonTitle = options.addButtonTitle;
    // styles = options.styles;
    classNames = options.classNames
    tooltip = options.tooltip;
    tooltipUrl = options.tooltipUrl;
    tooltipUrlTitle = options.tooltipUrlTitle;
    canCopy = options.canCopy;
    canCopyOutcomeMeasure = options.canCopyOutcomeMeasure;
    canCopyCtgOutcomeMeasures = options.canCopyCtgOutcomeMeasures;
  }

  useEffect(() => {
    if (jpath === "root_protocol_study_outcome_measures_outcome_measures") setIsRootArray(true)
  }, [jpath])

  useEffect(() => {
    if (jpath.includes("root_protocol_study_outcome_measures_outcome_measures") && isProtocolOmDisabledOverriden === false) {
      let ctgResultsOm = formData?.results?.outcome_measures?.outcome_measures_table?.ctg;
      if ((ctgResultsOm?.arms?.length > 0 || ctgResultsOm?.outcome_measures?.length > 0) && isProtocolOmDisabled === false) {
        setIsProtocolOmDisabled(true)
      } else if (isProtocolOmDisabled && (!ctgResultsOm || (ctgResultsOm?.arms?.length === 0 && ctgResultsOm?.outcome_measures?.length === 0))) {
        setIsProtocolOmDisabled(false)
      }
    }
  }, [formData?.results?.outcome_measures?.outcome_measures_table?.ctg])

  const onOverride = () => {
    setIsProtocolOmDisabled(false)
    setIsProtocolOmDisabledOverriden(true)
  }

  let content = (
    <div>
      <div className="tooltip-content">
        {tooltip}
      </div>
      {tooltipUrl &&
        <div className="tooltip__footer">
          <Link
            to={tooltipUrl}
            target="_blank"
            className="--link">
            {tooltipUrlTitle}
          </Link>
        </div>
      }
    </div>
  )

  const isArrayDisabled = isProtocolOmDisabled && isRootArray

  if (fieldLoading) return <Skeleton active />

  return (
    <Tooltip
      title={"[See Results Section.] Results data entry has begun, if this is not correct please delete results data or use the override option."}
      open={isProtocolOmDisabled && isRootArray && showMenu && isProtocolOmDisabledOverriden !== true}
      trigger={"hover"}
    >
      <div
        id={idSchema.$id}
        style={{
          display: options?.hide ? "none" : "auto"
        }}
        className={`rjs-array-container ${classNames?.arrayContainer ? classNames.arrayContainer : ""}`}
        onMouseEnter={() => setShowMenu(true)}
        onMouseLeave={() => setShowMenu(false)}>
        {title && <div className="rjs-array-title-container">
          <div className={`rjs-array-title ${classNames?.arrayTitle ? classNames.arrayTitle : ""}`}>
            {title}
            {tooltip &&
              <Popover
                content={content}
                trigger={"hover"}
              >
                <i className="fal fa-info-circle"></i>
              </Popover>
            }
            {isArrayDisabled && (
              <Tooltip
                title={"Manual override"}>
                <i
                  className="fal fa-pen-square override-disabled-icon"
                  onClick={onOverride} />
              </Tooltip>
            )}
          </div>
          {title && <LabelMenu
            hideFork={true}
            jpath={jpath}
            label={title}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            fieldComments={fieldComments}
            canCopyCtgOutcomeMeasures={canCopyCtgOutcomeMeasures}
            setFieldLoading={setFieldLoading}
            formContext={props.formContext}
          />}
        </div>}
        <div className={`rjs-array-items-container ${classNames?.arrayItemsContainer ? classNames.arrayItemsContainer : ""}`}>
          {props.items.map(element => {
            return (
              <ArrayFieldItem
                key={element.key}
                element={element}
                classNames={classNames}
                arrayItemTitle={arrayItemTitle}
                canCopy={canCopy}
                canCopyOutcomeMeasure={canCopyOutcomeMeasure}
                showIndex={showIndex}
                required={options?.required}
                isDisabled={isArrayDisabled}
                formContext={props.formContext}
                setFieldLoading={setFieldLoading} />
            )
          })}
        </div>
        {canAdd && readOnly !== true && (
          <Button
            className="rjs-add-button"
            variant="primary-dashed"
            size="sm"
            disabled={isArrayDisabled}
            onClick={() => onAddClick()}>
            <i
              className="fal fa-plus-circle"
              style={{
                marginTop: 2,
                marginRight: 8
              }} />
            {addButtonTitle}
          </Button>
        )}
        {registryValidMsgs(registryValidations, jpath)}
      </div>
    </Tooltip>
  );
};

export default ArrayFieldTemplate;