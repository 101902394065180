import React, { useState, useEffect, useMemo } from 'react';
import GridTypeA from 'components/grids/GridTypeA/GridTypeA';
import styled from 'styled-components';


// Cell Renderers
import PRSUploadStatus from '../ag-grid-cell-renderers/PRSUploadStatus';
import PRSRecordStatus from '../ag-grid-cell-renderers/PRSRecordStatus';
import PRSErrors from '../ag-grid-cell-renderers/PRSErrors/PRSErrors';

var rowHeight = 30;

const PRSSummaryGrid = ({
  data,
  isAutoRelease,
  uploadComplete,
  hasErrors,
}) => {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState([]);

  const gridOptions = useMemo(() => {
    return {
      columnDefs: [
        {
          headerName: 'Study ID',
          field: 'study_id',
          width: 100
        },
        {
          headerName: 'PRS Upload Status',
          field: 'prs_upload_status',
          cellRenderer: PRSUploadStatus,
          cellRendererParams: {
            parentData: data
          },
          flex: 1,
        },
        {
          headerName: 'PRS Record Status',
          field: 'ctg_release_status',
          cellRenderer: PRSRecordStatus,
          flex: 1,
          cellRendererParams: {
            parentData: data
          },
        },
        {
          headerName: 'Errors',
          field: 'errors',
          cellRenderer: PRSErrors,
          cellRendererParams: {
            parentData: data
          },
          hide: true,
          flex: 1
        },
      ],
      defaultColDef: {
        resizable: true,
        sortable: true,
        wrapText: true,
        cellStyle: { "whiteSpace": "normal" },
      },
      frameworkComponents: {
        PRSUploadStatus,
        PRSRecordStatus,
        PRSErrors
      }
    }
  }, [data])

  useEffect(() => {
    if (gridColumnApi && gridApi && hasErrors) {
      gridColumnApi.setColumnVisible('errors', true);
      gridApi.sizeColumnsToFit()
    }
  }, [gridColumnApi, gridApi, hasErrors])

  useEffect(() => {
    if (data) {
      setRowData(data.upload_records.map((record) => {
        let updatedRecord = { ...record }
        if (updatedRecord.upload_report === null && updatedRecord.upload_failed === false && data.is_cancelled) {
          updatedRecord.is_cancelled = true;
        }

        return {
          ...updatedRecord
        }
      }))
    } else {
      setRowData([])
    }
  }, [data])

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit()
  }

  const getRowHeight = (params) => {
    if (rowHeight !== null) {
      return rowHeight;
    }
  };

  return (
    <Container className="ag-theme-balham">
      <GridTypeA
        onGridReady={onGridReady}
        getRowHeight={getRowHeight}
        gridOptions={gridOptions}
        rowData={rowData}
      />
    </Container>
  );
};

export default PRSSummaryGrid;

const Container = styled.div`
  height: 100%;
  width: 100%;
`