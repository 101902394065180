// TODO: change name to assessmentsApi b/c it contains hooks but is not a hook
import useGetApi from "../../useGetApi"
import useMutateApi from '../../useMutateApi'

// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

// utils
import getUrl from 'api/apiMap'

export const useGetActivityTypes = () => {
  return useGetApi(getUrl(`${apiEndpoints.activityTypesList}`), queryKeys.activityTypesList)
}

export const useGetActivityType = (id) => {
  return useGetApi(getUrl(`${apiEndpoints.activityTypes}${id}/`), queryKeys.activityTypes.detail(id))
}

export const usePostActivityType = () => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(`${apiEndpoints.activityTypes}`),
    invalidateQueries: [queryKeys.activityTypesList],
    successMsg: 'Successfully created workflow'
  })
}

export const usePutActivityType = () => {
  return useMutateApi({
    method: "put",
    apiEndpoint: getUrl(`${apiEndpoints.activityTypes}`),
    invalidateQueries: [queryKeys.activityTypesList],
    successMsg: 'Successfully updated workflow'
  })
}