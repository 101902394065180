import { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useShallow } from 'zustand/react/shallow'

// components
import Label from 'components/Label/Label';
import PrimeField from 'components/PrimeField/PrimeField';
import DispersionRange from '../../../components/cells/DispersionRange/DispersionRange';
import DispersionInputs from '../../../components/cells/DispersionInputs/DispersionInputs';
import RJTableCommentContainer from '../../../components/RJTableCommentContainer/RJTableCommentContainer';
import CellValidation from '../../../components/CellValidation/CellValidation';

// context
import useResultsTableViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableViewStore';
import useResultsBaselineStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsBaselineStore';
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';
import useAuthoringCommentsStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringCommentsStore';

const Cell = ({
  schema,
  row,
  category,
  measure,
  measureIndex,
  catIndex,
  rowIndex,
  valIndex,
  value,
  baselineData,
  setBaselineData,
}) => {
  const { ref: visibleRef, inView: inViewport } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  });

  const isCountUnits = measure?.parameter_type === "Count of Units"

  const {
    showBcUnitsAnalyzed
  } = useResultsBaselineStore()

  const {
    tableView
  } = useResultsTableViewStore()

  const {
    readOnly
  } = useAuthoringViewStore(
    useShallow(state => ({
      readOnly: state.readOnly,
    }))
  )

  const {
    commentsMap
  } = useAuthoringCommentsStore(
    useShallow(state => ({
      commentsMap: state.commentsMap
    }))
  )

  const [openCommentsForm, setOpenCommentsForm] = useState(false)
  const [showActions, setShowActions] = useState(false)

  const handleValue = (e, armId, key) => {
    let tempData = { ...baselineData }

    Object.keys(tempData).forEach(registry => {
      let foundBMIndex = tempData[registry].baseline_measures.findIndex(bm => bm.id === measure.id);

      if (foundBMIndex !== -1) {
        let bm = tempData[registry].baseline_measures[foundBMIndex];
        let foundRowIndex = bm.rows.findIndex(bmRow => bmRow.id === row.id)
        if (foundRowIndex !== -1) {
          let catIndex = bm.rows[foundRowIndex].categories.findIndex(item => item.id === category.id);
          if (catIndex !== -1) {
            let foundValueIndex = bm.rows[foundRowIndex].categories[catIndex]?.values.findIndex(item => item.arm_id === armId);

            if (foundValueIndex !== -1 && foundValueIndex !== undefined) {
              let val = e;

              if (e === "NA" && registry === "eudract" && (bm.dispersion_type === "Inter-Quartile Range" || bm.dispersion_type === "Full Range") && key === "dispersion_value_lower_bound") val = -99999;
              else if (e === "NA" && registry === "eudract") val = 99999;

              if (e === "NA" && key === "dispersion_value_upper_bound" && registry === "eudract") {
                val = 99999;
              }

              if ((e === 99999 || e === 9999 || e === "9999" || e === "99999") && registry === "ctg") {
                if (bm.rows[foundRowIndex].categories[0].id === "num-analyzed" && bm.rows[foundRowIndex].categories[0].values[foundValueIndex].value === 0) {
                  val = "0";
                } else {
                  val = "NA"
                }
              }

              bm.rows[foundRowIndex].categories[catIndex].values[foundValueIndex][key] = val;

              let value = bm.rows[foundRowIndex].categories[catIndex].values[foundValueIndex]

              if (value.value !== "NA" && value.dispersion_value !== "NA" && value.dispersion_value_upper_bound !== "NA") {
                if (bm.rows[foundRowIndex].categories[catIndex].values[foundValueIndex].na_comment) {
                  delete bm.rows[foundRowIndex].categories[catIndex].values[foundValueIndex].na_comment;
                }
              }

              // CALCULATES TOTALS
              if (registry === "ctg" && (measure.parameter_type === "Count of Participants" || measure.parameter_type === "Count of Units")) {

                let total = bm.rows[foundRowIndex].categories[catIndex].values.reduce((acc, item) => {
                  let num = Number(item[key]) || 0
                  return acc + num
                  // if (measure.parameter_type === "Count of Units") {
                  //   // let num = Number(item.value) || 0
                  //   let num = Number(item.num_units_analyzed) || 0
                  //   let res = acc + num
                  //   return res
                  // } else {
                    // let num = Number(item[key]) || 0
                    // return acc + num
                  // }
                }, 0);

                if (bm.rows[foundRowIndex].categories[catIndex].total) {
                  bm.rows[foundRowIndex].categories[catIndex].total[key] = total;
                } else {
                  bm.rows[foundRowIndex].categories[catIndex].total = {
                    [key]: total
                  }
                }
              }
            }
          }
        }
      }
    })

    setBaselineData(tempData);
  }

  const onValueChange = (e, value, key) => {
    let tempData = { ...baselineData }

    let foundOMIndex = tempData[tableView].baseline_measures.findIndex(om => om.id === measure.id);
    if (foundOMIndex !== -1) {
      let om = tempData[tableView].baseline_measures[foundOMIndex];
      let catIndex = om.rows[rowIndex].categories.findIndex(item => item.id === category.id);
      if (catIndex !== -1) {

        let foundValueIndex = om.rows[rowIndex].categories[catIndex]?.values.findIndex(item => item.group_id === value.group_id);
        if (foundValueIndex !== -1 && foundValueIndex !== undefined) {
          om.rows[rowIndex].categories[catIndex].values[foundValueIndex][key] = e;
        }
      }
    }

    setBaselineData(tempData);
  }

  const getCtgParticipantFieldType = ({ value, catIndex, valIndex }) => {
    if (category.id === "num-analyzed") {
      let fieldSchema = schema.categories.find(cat => cat.id === category.id)
      if (fieldSchema?.properties?.value?.value === 0) {
        value.value = 0
      }

      return (
        <>
          <PrimeField
            schema={{
              type: fieldSchema?.properties?.value?.type,
              label: "Participants",
              orientation: "vertical",
            }}
            readOnly={readOnly}
            onChange={(e) => handleValue(e, value.arm_id, 'value')}
            value={!isNaN(value?.value) ? value.value : ""} />


          {showBcUnitsAnalyzed && measure.baseline_measure_id !== "not-collected" && measure.parameter_type === "Count of Units" &&
            <PrimeField
              schema={{
                type: "number",
                label: baselineData?.[tableView]?.type_units_analyzed,
                orientation: "vertical",
              }}
              containerStyle={{ marginTop: 16 }}
              readOnly={readOnly}
              onChange={(e) => handleValue(e, value.arm_id, 'num_units_analyzed')}
              value={value?.num_units_analyzed} />
          }
        </>
      )
    } else if (measure.parameter_type === "Count of Participants" || measure.parameter_type === "Count of Units") {
      let percentage = "N/A";

      if (measure.rows[rowIndex].categories[0].values[valIndex]) {
        let total = Number(measure.rows[rowIndex].categories[0].values[valIndex].value)
        if (isCountUnits) {
          total = Number(measure.rows[rowIndex].categories[0].values[valIndex].num_units_analyzed)
        }
        let divisor = value.value ? Number(value.value) : 0;
        percentage = (divisor / total) * 100;
      }

      if (isNaN(percentage)) percentage = 0;

      return (
        <div className="range-container" style={{ marginTop: 0, alignItems: "flex-end" }}>
          <PrimeField
            schema={{
              type: "text",
              label: measure.parameter_type,
              orientation: "vertical",
            }}
            readOnly={readOnly}
            onChange={(e) => handleValue(e, value.arm_id, "value")}
            value={value.value || ""} />
          <Label style={{ marginTop: 6 }}>
            {percentage === "N/A" ? percentage : percentage.toFixed(2)}%
          </Label>
        </div>
      )
    } else if (measure.parameter_type === "Number") {
      return (
        <PrimeField
          schema={{
            type: "text",
            label: measure.parameter_type,
            orientation: "vertical",
          }}
          readOnly={readOnly}
          onChange={(e) => handleValue(e, value.arm_id, "value")}
          value={value.value || ""} />
      )
    } else if (measure.dispersion_type === "Standard Deviation") {
      return (
        <DispersionInputs
          parameterType={measure.parameter_type}
          dispersionType={measure.dispersion_type}
          dispersionValue={value.dispersion_value}
          cell={value}
          cellArmId={value.arm_id}
          onChange={handleValue}
        />
      )
    } else if (measure.dispersion_type === "Inter-Quartile Range" || measure.dispersion_type === "Full Range") {
      return (
        <DispersionRange
          parameterType={measure.parameter_type}
          dispersionType={measure.dispersion_type}
          dispersionValue={value.dispersion_value}
          dispersionValueUpperBound={value?.dispersion_value_upper_bound}
          cell={value}
          cellArmId={value.arm_id}
          onChange={handleValue} />
      )
    }
  }

  const getEudractParticipantFieldType = ({ value, catIndex, valIndex }) => {
    if (measure.dispersion_type === "Standard Deviation") {
      return (
        <DispersionInputs
          parameterType={measure.parameter_type}
          dispersionType={measure.dispersion_type}
          dispersionValue={value.dispersion_value}
          cell={value}
          cellArmId={value.arm_id}
          onChange={handleValue}
        />
      )
    }
    if (measure.dispersion_type === "Inter-Quartile Range"
      || measure.dispersion_type === "Full Range") {
      return (
        <DispersionRange
          parameterType={measure.parameter_type}
          dispersionType={measure.dispersion_type}
          dispersionValue={value.dispersion_value}
          dispersionValueUpperBound={value?.dispersion_value_upper_bound}
          cell={value}
          cellArmId={value.arm_id}
          onChange={handleValue} />
      )
    }

    return (
      <PrimeField
        schema={{
          type: "text",
          label: "Subjects",
          orientation: "vertical",
        }}
        readOnly={readOnly}
        onChange={(e) => handleValue(e, value.arm_id, "value")}
        value={value.value || ""} />
    )
  }

  let jpath = `BaselineReportingGroup.bc-cell-value-${measure.id}-${row.id}-${category.id}-${value.arm_id}`

  const commentStyle = {
    position: "absolute",
    right: 8,
    top: 8
  }

  let isSas = baselineData?.eudract?.subject_analysis_sets?.find(set => set.id === value.arm_id)

  let classNames = ["text-left"]
  if (isSas && tableView === "eudract") {
    classNames.push("subject-analysis-set")
  }

  return (
    <td
      ref={visibleRef}
      className={classNames.join(" ")}
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}
      style={{ position: "relative" }}>
      {inViewport ? (
        <>
          {tableView === "ctg" && getCtgParticipantFieldType({ value, catIndex, valIndex })}
          {tableView === "eudract" && getEudractParticipantFieldType({ value, catIndex, valIndex })}
          {(value.value === "NA" || value.dispersion_value === "NA" || value.dispersion_value_upper_bound === "NA") && (
            <PrimeField
              schema={{
                type: "textarea",
                label: "Explain why a value is Not Available.",
                orientation: "vertical"
              }}
              containerStyle={{ marginTop: 8 }}
              readOnly={readOnly}
              value={value.na_comment || ""}
              onChange={e => onValueChange(e, value, "na_comment")} />
          )
          }
          <RJTableCommentContainer
            label={`Baseline characteristics field`}
            jpath={jpath}
            openCommentsForm={openCommentsForm}
            setOpenCommentsForm={setOpenCommentsForm}
            setShowMenu={setShowActions}
            showMenu={showActions || commentsMap[jpath] || openCommentsForm}
            style={commentStyle}
            modalStyle={{ top: 24 }} />
          <CellValidation
            table="baseline"
            errorKey={`results_baseline_characteristics_baseline_characteristics_table_${tableView}_baseline_measures_${measureIndex + 1}_rows_${rowIndex + 1}_categories_${catIndex + 1}_values_${valIndex + 1}_value`} />
        </>
      ) : (
        <div style={{ padding: 10 }} />
      )}
    </td>
  );
};

export default Cell;