import { useState } from 'react';
import { v4 as uuid } from 'uuid'
import { useShallow } from 'zustand/react/shallow'

// components
import PrimeField from 'components/PrimeField/PrimeField';
import MeasureRow from '../MeasureRow/MeasureRow';
import { Button } from 'components-design-system';
import ResultsTableCreateModal from '../../../../components/ResultsTableCreateModal/ResultsTableCreateModal';
import ResultsTableDeleteModal from '../../../../components/ResultsTableDeleteModal/ResultsTableDeleteModal';
import RJTableCommentContainer from '../../../components/RJTableCommentContainer/RJTableCommentContainer';
import CellValidation from '../../../components/CellValidation/CellValidation';

// context
import useResultsTableViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableViewStore';
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';
import useAuthoringCommentsStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringCommentsStore';

// helpers
import { moveLeftHelper, moveRightHelper } from '../../helpers/helpers';
import useResultsBaselineStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsBaselineStore';

const Measure = ({
  measure,
  measureIndex,
  arms,
  sasArr,
  index,
  schema,
  numOfMeasures,
  baselineData,
  setBaselineData,
}) => {
  const [showAddRowModal, setShowAddRowModal] = useState(false);
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  const [showEudractAddCategoryModal, setShowEudractAddCategoryModal] = useState(false);
  const [showDeleteMeasureModal, setShowDeleteMeasureModal] = useState(false);

  const {
    showBcUnitsAnalyzed,
  } = useResultsBaselineStore()

  const {
    tableView
  } = useResultsTableViewStore()

  const {
    readOnly
  } = useAuthoringViewStore(
    useShallow(state => ({ readOnly: state.readOnly }))
  )

  const transformBaselineMeasure = (tempData, type) => {
    let foundBMIndex = baselineData["eudract"].baseline_measures.findIndex(item => item.id === measure.id);
    if (foundBMIndex !== -1) {
      let updatedBM = { ...baselineData["eudract"].baseline_measures[foundBMIndex] }
      if (!updatedBM.baseline_measure_id.includes(type)) {
        updatedBM.baseline_measure_id = "eudract-specific-" + type;
        if (type === "continuous") {
          updatedBM.parameter_type = measure.parameter_type;
          updatedBM.dispersion_type = measure.dispersion_type;
        } else if (type === "categorical") {
          delete updatedBM.parameter_type
          delete updatedBM.dispersion_type
        }
      }
      tempData.eudract.baseline_measures.splice(foundBMIndex, 1, updatedBM)
    }
  }

  const onChangeProperties = (e, key) => {
    let val = e
    if (e.id) val = e.text;

    let tempData = { ...baselineData };

    Object.keys(tempData).forEach(registry => {
      let foundIndex = tempData[registry].baseline_measures.findIndex(bm => bm.id === measure.id);
      if (foundIndex !== -1) {
        if (key === "title" && (measure.baseline_measure_id === "eudract-specific-categorical" || measure.baseline_measure_id === "eudract-specific-continuous")) {
          if (tempData[registry].baseline_measures[foundIndex] !== "ctg-specific-customized" && registry === "ctg") {
            return;
          }
        }

        if (key === "parameter_type") {
          if (tempData[registry].baseline_measures[foundIndex][key] !== "Count of Participants" && val === "Count of Participants" && tableView === "ctg") { // If ctg continuous and now categorical
            transformBaselineMeasure(tempData, "categorical")
          }
          if (val !== "Count of Participants" && tableView === "ctg") { // If ctg is categorical and now continuous
            transformBaselineMeasure(tempData, "continuous")
          }

          if ((tempData[registry].baseline_measures[foundIndex][key] !== "Count of Participants"
            && tempData[registry].baseline_measures[foundIndex][key] !== "Number"
            && tempData[registry].baseline_measures[foundIndex][key] !== "Count of Units")
            && (val === "Count of Participants" || val === "Number" || val === "Count of Units")) {
            tempData[registry].baseline_measures[foundIndex].dispersion_type = "Not Applicable";
            // handleNAValues(tempData, registry, "Not Applicable")
          }

          if ((tempData[registry].baseline_measures[foundIndex][key] === "Count of Participants"
            || tempData[registry].baseline_measures[foundIndex][key] === "Number"
            || tempData[registry].baseline_measures[foundIndex][key] === "Count of Units")
            && (val !== "Count of Participants" && val !== "Number" && val !== "Count of Units")) {
            tempData[registry].baseline_measures[foundIndex].dispersion_type = "Standard Deviation";
            // handleNAValues(tempData, registry, "Standard Deviation")
          }
        }

        // if(key === "dispersion_type") handleNAValues(tempData, registry, val);
        if (key === "parameter_type" && e === "Count of Units" && registry === "eudract") val = "Count of Participants"
        tempData[registry].baseline_measures[foundIndex][key] = val
      }
    })

    setBaselineData(tempData);
  }

  const handleUp = () => {
    let tempData = { ...baselineData }
    Object.keys(tempData).forEach(registry => {
      let foundIndex = tempData[registry].baseline_measures.findIndex(item => item.id === measure.id);
      if (foundIndex !== -1 && foundIndex !== 0) {
        moveLeftHelper(tempData, registry, "baseline_measures", foundIndex);
      }
    })

    setBaselineData(tempData);
  }

  const handleDown = () => {
    let tempData = { ...baselineData }
    Object.keys(tempData).forEach(registry => {
      let foundIndex = tempData[registry].baseline_measures.findIndex(item => item.id === measure.id);
      if (foundIndex !== -1 && foundIndex !== tempData[registry].baseline_measures.length - 1) {
        moveRightHelper(tempData, registry, "baseline_measures", foundIndex);
      }
    })

    setBaselineData(tempData);
  }

  const addRow = (applyAll) => {
    let tempData = { ...baselineData };
    let rowId = `row-${uuid()}`

    let categoryIds = []

    for (let i = 0; i < 15; i++) {
      let cid = `Category-${uuid()}`;
      categoryIds.push(cid)
    }

    Object.keys(tempData).forEach(registry => {
      if (applyAll || registry === tableView) {
        let foundIndex = tempData[registry].baseline_measures.findIndex(bm => bm.id === measure.id);
        let catIndex = 0;

        if (foundIndex !== -1) {
          let bm = tempData[registry].baseline_measures[foundIndex];


          let rows = [...bm.rows];
          let newRow = {
            id: rowId,
            title: "",
            categories: rows[0].categories.map(category => {
              let values = [];
              if (category.id === "num-analyzed") {
                values = baselineData[registry].arms.map(item => {
                  return {
                    arm_id: item.id,
                    registry_arm_id: item.id,
                    value: item.baseline_analysis_population
                  }
                })
              } else {
                values = category.values.map(valueObj => {
                  let newVal = { arm_id: valueObj.arm_id };
                  Object.keys(valueObj).forEach(key => {
                    if (key !== "arm_id") {
                      newVal[key] = null
                    }
                  })
                  return newVal;
                })
              }

              if (category.id !== "num-analyzed") {
                catIndex++
              }

              return {
                id: category.id === "num-analyzed" ? "num-analyzed" : categoryIds[catIndex],
                title: category.title,
                values
              }
            })
          }

          rows.push(newRow);
          bm.rows = rows;
        }
      }
    })
    setBaselineData(tempData)
  }

  const addCategory = (applyAll) => {
    let tempData = { ...baselineData };
    let cid = `Category-${uuid()}`
    let rid = `row-${uuid()}`

    Object.keys(tempData).forEach(registry => {
      if (applyAll || registry === tableView) {
        let foundIndex = tempData[registry].baseline_measures.findIndex(bm => bm.id === measure.id);

        if (foundIndex !== -1) {
          let bm = tempData[registry].baseline_measures[foundIndex];
          let rows = [...bm.rows];

          if (rows.length === 0) {
            rows.push({
              id: rid,
              title: "",
              categories: []
            })
          }
          rows.forEach(row => {
            let values = tempData[registry].arms.map(arm => {
              return {
                arm_id: arm.id,
                value: null
              };
            })

            if (registry === "eudract" && tempData[registry].subject_analysis_sets) {
              let sasValues = tempData[registry].subject_analysis_sets.map(arm => {
                return {
                  arm_id: arm.id,
                  value: null
                };
              })
              values = [...values, ...sasValues];
            }

            let newCat = {
              id: cid,
              title: "",
              values
            }
            row.categories.push({ ...newCat })
          })
          bm.rows = rows;
        }
      }
    });
    setBaselineData(tempData)
  }

  const handleAddEudractCategory = (applyAll, options) => {
    let shouldCreateRow = options.createRowCtg;
    let shouldCreateCategory = options.createCategoryCtg;

    if (applyAll !== true) {
      if (measure.baseline_measure_id === "eudract-gender-categorical") {
        addCategory(applyAll)
        return;
      } else if (measure.baseline_measure_id === "eudract-age-categorical"
        || measure.baseline_measure_id === "eudract-specific-categorical") {
        if (measure.rows.length === 1 && measure.rows[0]?.categories?.length > 1) {
          addCategory(applyAll)
          return;
        }
      }
    }


    if (shouldCreateRow) {
      addRow(applyAll)
    } else if (shouldCreateCategory) {
      addCategory(applyAll)
    }
  }

  const handleDeleteMeasure = (applyAll) => {
    let tempData = { ...baselineData }
    Object.keys(tempData).forEach(registry => {
      if (applyAll || registry === tableView) {
        let foundIndex = tempData[registry].baseline_measures.findIndex(item => item.id === measure.id);
        let bmMeasures = [...tempData[registry].baseline_measures];
        if (foundIndex !== -1) {
          bmMeasures.splice(foundIndex, 1);
          tempData[registry].baseline_measures = bmMeasures;
        }
      }
    })

    setBaselineData(tempData);
  }

  const renderHeader = () => {
    const getColSpan = () => {
      if (arms?.length > 1 || sasArr?.length > 1) return 4
      return arms?.length + 3
    }

    return (
      <tr className="divider-row">
        <td colSpan={getColSpan()}>
          {readOnly !== true &&
            <div className="measure-actions">
              <div className="bc-reorder-actions">
                {index !== 0 &&
                  <i
                    className="fal fa-arrow-up arrow-icon"
                    style={{ marginRight: 16 }}
                    onClick={() => handleUp()} />
                }
                {index !== numOfMeasures - 1 &&
                  <i
                    className="fal fa-arrow-down arrow-icon"
                    onClick={() => handleDown()} />
                }
              </div>

              <i
                onClick={() => setShowDeleteMeasureModal(true)}
                className="fal fa-trash-alt trash-icon" />
            </div>
          }
        </td>
      </tr>
    )
  }

  const renderProperties = () => {
    let rows = [];

    if (schema?.properties) {
      Object.keys(schema?.properties).forEach((key) => {
        let fieldSchema = schema.properties[key];

        if (key === "parameter_type" && tableView === "ctg" && showBcUnitsAnalyzed) {
          let options = fieldSchema.enum;

          if (fieldSchema.enum) {
            options = [...fieldSchema.enum, "Count of Units"]
          } else {
            options = ["Count of Participants", "Count of Units"]
          }
          fieldSchema = {
            ...fieldSchema,
            readOnly: false,
            enum: options
          }
        }

        const getColSpan = () => {
          if (arms?.length > 1 || sasArr?.length > 1) return 2
          return arms?.length + 1
        }

        rows.push(
          <tr key={`${tableView}-${key}-props-${index}`}>
            <PropertyHeader
              measure={measure}
              fieldKey={key}
              fieldSchema={fieldSchema} />
            <td
              className="text-left"
              colSpan={getColSpan()}>
              <PrimeField
                schema={{
                  type: fieldSchema.type,
                  items: fieldSchema.enum,
                  disabled: key === "dispersion_type" && (measure.parameter_type === "Count of Participants" || measure.parameter_type === "Number" || measure.parameter_type === "Count of Units")
                }}
                readOnly={readOnly}
                onChange={e => onChangeProperties(e, key)}
                value={measure[key] || ""} />
              <CellValidation
                table="baseline"
                errorKey={`results_baseline_characteristics_baseline_characteristics_table_${tableView}_baseline_measures_${measureIndex + 1}_${key}`} />
            </td>
          </tr>
        )
      })
      return rows;
    }

    return rows;
  }

  const renderRows = () => {
    let editable = false;

    return measure?.rows?.map((row, i) => {
      return (
        <MeasureRow
          key={`${tableView}-cat-${i}`}
          arms={arms}
          rows={measure.rows}
          rowIndex={i}
          schema={schema.rows}
          measure={measure}
          measureIndex={measureIndex}
          row={row}
          baselineData={baselineData}
          setBaselineData={setBaselineData} />
      )
    })
  }

  const getRegionEnrollment = () => {
    let foundCtgBaseline = baselineData?.ctg?.baseline_measures.find(bm => bm.id === measure.id)
    if (foundCtgBaseline?.baseline_measure_id === "ctg-region-categorical") {
      return true
    }
    return false
  }

  const renderActions = (parameterType) => {

    const getAddRowButtonTitle = () => {
      if (getRegionEnrollment()) return "Add Region +";
      // else if (tableView === "eudract") return "Add Category +"

      return "Add Row +";
    }

    const canAddRow = () => {
      if (readOnly !== true) {
        if (schema.canAddCategories && tableView === "eudract" && !measure.title.includes("continuous") && measure.baseline_measure_id === "eudract-specific-categorical") return true;
        else if (tableView !== "eudract" && schema.canAddRow !== false) return true
      }
      return false;
    }

    const canAddCategory = () => {
      if (readOnly !== true) {

        if (tableView === "eudract" && getRegionEnrollment()) return false

        return true;
      }

      return false;
    }

    if (measure.baseline_measure_id === "not-collected") {
      return;
    }

    const getColSpan = () => {
      if (arms?.length > 1 || sasArr?.length > 1) return 4
      return arms?.length + 3
    }

    return (
      <tr className="disabled-row">
        <td
          colSpan={getColSpan()}>
          <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
            {readOnly !== true && <Button
              variant="primary-dashed"
              size="sm"
              style={{ marginRight: 12 }}
              onClick={() => setShowAddRowModal(true)}>
              {getAddRowButtonTitle()}
            </Button>}
            {canAddCategory() && <Button
              variant="primary-dashed"
              size="sm"
              onClick={() => setShowAddCategoryModal(true)}>
              Add Category +
            </Button>}
          </div>
        </td>
      </tr>
    )
  }

  return (
    <>
      {renderHeader()}
      {renderProperties()}
      {renderRows()}
      {renderActions(measure.parameter_type)}

      <ResultsTableDeleteModal
        id={`bc-delete-baseline-measure-${measure.id}`}
        header="Delete Baseline Measure"
        onSubmit={handleDeleteMeasure}
        showModal={showDeleteMeasureModal}
        registrySpecific={measure?.baseline_measure_id === "not-collected"}
        setShowModal={() => setShowDeleteMeasureModal(false)} />

      {/* add row */}
      <ResultsTableCreateModal
        id={`bc-${measure.id}-add-row`}
        header="Add Row"
        onSubmit={addRow}
        showModal={showAddRowModal}
        setShowModal={() => setShowAddRowModal(false)} />

      {/* add category */}
      <ResultsTableCreateModal
        id={`bc-${measure.id}-add-category`}
        header="Add Category"
        onSubmit={addCategory}
        showModal={showAddCategoryModal}
        setShowModal={() => setShowAddCategoryModal(false)} />
      {/* add eudract category */}
      <ResultsTableCreateModal
        id={`bc-${measure.id}-eudract-add-category`}
        header="Add Category"
        onSubmit={handleAddEudractCategory}
        showModal={showEudractAddCategoryModal}
        addEudractCategory={true}
        setShowModal={() => setShowEudractAddCategoryModal(false)} />
    </>
  );
};

export default Measure;

const PropertyHeader = ({
  measure,
  fieldSchema,
  fieldKey
}) => {
  const [openCommentsForm, setOpenCommentsForm] = useState(false)
  const [showActions, setShowActions] = useState(false)

  const {
    commentsMap
  } = useAuthoringCommentsStore(
    useShallow(state => ({
      commentsMap: state.commentsMap
    }))
  )

  let jpath = `BaselineReportingGroup.bc-measure-prop-${measure.id}-${fieldKey}`

  const commentStyle = {
    position: "absolute",
    right: 8,
    top: 8
  }

  return (
    <td
      className="header-column"
      colSpan={2}
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}
      style={{ position: "relative" }}>
      {fieldSchema.title}
      <RJTableCommentContainer
        label={fieldSchema.title}
        jpath={jpath}
        openCommentsForm={openCommentsForm}
        setOpenCommentsForm={setOpenCommentsForm}
        setShowMenu={setShowActions}
        showMenu={showActions || commentsMap[jpath] || openCommentsForm}
        style={commentStyle}
        modalStyle={{ top: 24 }} />
    </td>
  )
}