import { useState } from 'react';
import { useShallow } from 'zustand/react/shallow'

// components
import Label from 'components/Label/Label';
import PrimeField from 'components/PrimeField/PrimeField';
import RowTotal from '../RowTotal/RowTotal';
import RowTotalInput from '../RowTotalInput/RowTotalInput';
import RowActions from '../../../components/RowActions/RowActions';
import Cell from '../Cell/Cell';

// context
import useResultsTableViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableViewStore';
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';

const MeasureCategory = ({
  schema,
  measure,
  measureIndex,
  rowIndex,
  category,
  catIndex,
  rows,
  row,
  numOfCategories,
  baselineData,
  setBaselineData,
}) => {
  const {
    tableView
  } = useResultsTableViewStore()

  const {
    readOnly
  } = useAuthoringViewStore(
    useShallow(state => ({ readOnly: state.readOnly }))
  )

  const [showActions, setShowActions] = useState(false);

  const handleCategoryTitle = (e) => {
    let tempData = { ...baselineData }

    Object.keys(tempData).forEach(registry => {
      let foundBMIndex = tempData[registry].baseline_measures.findIndex(bm => bm.id === measure.id);
      let bm = tempData[registry].baseline_measures[foundBMIndex];

      if (foundBMIndex !== -1) {
        let foundRowIndex = bm.rows.findIndex(bmRow => bmRow.id === row.id)
        if (foundRowIndex !== -1) {
          let catIndex = bm.rows[foundRowIndex].categories.findIndex(item => item.id === category.id);
          if (catIndex !== -1) {
            for (let rI = 0; rI < bm.rows.length; rI++) {
              bm.rows[rI].categories[catIndex].title = e;
            }
          }
        }
      }
    })

    setBaselineData(tempData);
  }

  const canShowArrowUp = () => {
    if (tableView === "eudract" && catIndex !== 0) {
      return true
    } else if (row.categories[0].title === "Number Analyzed" && catIndex > 1) {
      return true;
    } else if (catIndex !== 0 && !row.categories[0].title === "Number Analyzed") {
      return true;
    }
    return false;
  }

  const canShowArrowDown = () => {
    if (tableView === "eudract" && catIndex !== row.categories.length - 1) {
      return true
    } else if (catIndex !== measure.rows.length - 1 && !row.categories[0].title === "Number Analyzed") {
      return true;
    } else if (row.categories[0].title === "Number Analyzed" && catIndex !== row.categories.length - 1) {
      return true;
    }
    return false;
  }

  const displayCategoryHeader = () => {
    // if (getRegionEnrollment()) return
    return (
      <td
        className="col-sm"
        colSpan={1}
        onMouseEnter={() => setShowActions(true)}
        onMouseLeave={() => setShowActions(false)}>
        {/* {((numOfCategories > 1 && category.id !== "num-analyzed")) && rowIndex === 0 */}
        {((numOfCategories > 1 && category.id !== "num-analyzed") || tableView === "eudract") && rowIndex === 0
          ? <div>
            <PrimeField
              schema={{
                type: "text",
                label: "Category Title"
              }}
              readOnly={readOnly}
              value={category.title || ""}
              onChange={e => handleCategoryTitle(e)} />
            <RowActions
              showActions={showActions}
              setShowActions={setShowActions}
              handleDelete={onDeleteCategory}

              canDelete={row.categories.length > 1}
              deleteModalTitle="Delete row?"
              // showDown={canShowArrowDown()}
              // showUp={canShowArrowUp()}
              // handleUp={handleUp}
              // handleDown={handleDown}
              jpath={`BaselineReportingGroup.bc-${measure.id}-row-${row.id}-category-${category.id}`} />
          </div>
          : <div
            id={jpath}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center"
            }}>
            <Label>{category?.title || ""}</Label>
            <RowActions
              showActions={showActions}
              setShowActions={setShowActions}
              jpath={`BaselineReportingGroup.bc-${measure.id}-row-${row.id}-category-${category.id}`} />
          </div>
        }
      </td>
    )
  }

  const displayValues = () => {
    return category.values.map((value, valIndex) => {
      return (
        <Cell
          key={`cell-value-${measure.id}-${row.id}-${category.id}-${value.arm_id}`}
          schema={schema}
          measure={measure}
          measureIndex={measureIndex}
          row={row}
          category={category}
          rowIndex={rowIndex}
          catIndex={catIndex}
          value={value}
          valIndex={valIndex}
          baselineData={baselineData}
          setBaselineData={setBaselineData} />
      )
    })
  }

  const onDeleteCategory = (applyAll) => {
    let tempData = { ...baselineData }
    Object.keys(tempData).forEach(registry => {
      if (applyAll || tableView === registry) {
        let foundBMIndex = tempData[registry].baseline_measures.findIndex(bm => bm.id === measure.id);
        if (foundBMIndex !== -1) {
          let bm = tempData[registry].baseline_measures[foundBMIndex];

          let foundRowIndex = bm.rows.findIndex(bmRow => bmRow.id === row.id)
          if (foundRowIndex !== -1) {
            let catIndex = bm.rows[foundRowIndex].categories.findIndex(item => item.id === category.id);
            if (catIndex !== -1) {
              bm.rows.forEach(row => {
                row.categories.splice(catIndex, 1)
              })
            }
          }
        }
      }
    })
    setBaselineData(tempData);
  }

  let jpath = `baseline_characteristics:${row.id}-${category.id}`;

  const displayCtgRowTotal = () => {
    return category.id === "num-analyzed"
      ? <RowTotal
        category={category}
        measure={measure}
        baselineData={baselineData} />
      : <RowTotalInput
        category={category}
        rowIndex={rowIndex}
        catIndex={catIndex}
        baselineData={baselineData}
        setBaselineData={setBaselineData}
        measure={measure}
        rows={rows} />
  }

  const displayEudractRowTotal = () => {
    return <RowTotal
      baselineData={baselineData}
      hideTotal={measure?.baseline_measure_id?.includes("continuous")}
      category={category} />
  }

  const getRegionEnrollment = () => {
    if (tableView === "eudract") {
      let foundCtgBaseline = baselineData?.ctg?.baseline_measures.find(bm => bm.id === measure.id)
      if (foundCtgBaseline?.baseline_measure_id === "ctg-region-categorical") {
        return true
      }
    }
    return false
  }

  const getCategoryOnly = () => {

  }

  return (
    <>
      {displayCategoryHeader()}
      {displayValues()}
      {tableView === "ctg" && displayCtgRowTotal()}
      {tableView === "eudract" && displayEudractRowTotal()}
    </>

  );
};

export default MeasureCategory;