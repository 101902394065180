import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'

// components
import ProtocolMaintenance from './subcomponents/ProtocolMaintenance/ProtocolMaintenance';
import ClinicalTrialsGov from './subcomponents/ClinicalTrialsGov/ClinicalTrialsGov';

// styles
import './Registries.scss'

// context
import { useGetStudy } from 'api/hooks/studies/useStudiesApi';

// api
import { 
  useGetStudyProtocolDataCompare,
  useGetStudyRegistryData,
 } from 'api/hooks/studies/useRegistries'

const Registries = () => {
  const { studyId } = useParams()
  const { data: study } = useGetStudy(studyId)
  const { data: registryData = [] } = useGetStudyRegistryData(study?.authoring_study_id)
  const { data: protocolDataCompare = [] } = useGetStudyProtocolDataCompare(study?.authoring_study_id)

  return (
    <div className="study-registries">
      <div className="row">
        <div className="panel-wrapper">
          <ProtocolMaintenance
            study={study}
            protocolDataCompare={protocolDataCompare}
            clinicalTrialsData={registryData} />
        </div>
        <div className="panel-wrapper">
          <ClinicalTrialsGov
            clinicalTrialsData={registryData} />
        </div>
      </div>
    </div>
  )
}

export default Registries