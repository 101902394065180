import { create } from 'zustand'

const initialState = {
  participantData: {
    global_arms: [],
    eudract_periods: [],
    ctg_periods: [],
  },
}

const useParticipantFlowStore = create((set) => ({
  ...initialState,
  setParticipantData: (participantData) => set({ participantData }),
  resetParticipantFlowStore: () => set(initialState),
}))

export {
  initialState
}

export default useParticipantFlowStore;