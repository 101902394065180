export default [
  {
    region: "oceania",
    registry: "ANZCTR",
    badge: "ANZCTR",
    country: "Australia, New Zealand"
  },
  // {
  //   region: "oceania",
  //   registry: "Australian New Zealand Clinical Trials Registry",
  //   badge: "ANZCTR",
  //   country: "Australia, New Zealand"
  // },
  {
    region: "asia",
    registry: "ChiCTR/The Platform",
    badge: "ChiCTR",
    country: "China"
  },
  // {
  //   region: "asia",
  //   registry: "Chinese Clinical Trial Registry",
  //   badge: "ChiCTR",
  //   country: "China"
  // },
  {
    region: "asia",
    registry: "Drug Clinical Trial Registration and Information Disclosure Platform",
    badge: "ChinaDrugTrials.org.cn",
    country: "China"
  },
  {
    region: "asia",
    registry: "Clinical Trials Registry",
    badge: "CTRI",
    country: "India"
  },
  {
    region: "asia",
    registry: "jrCT/JapicCTI",
    badge: "jRCT",
    country: "Japan"
  },
  // {
  //   region: "asia",
  //   registry: "Japan Registry for Clinical Trials",
  //   badge: "jRCT",
  //   country: "Japan"
  // },
  {
    region: "asia",
    registry: "CRIS",
    badge: "CRIS",
    country: "South Korea"
  },
  // {
  //   region: "asia",
  //   registry: "Clinical Research Information Service",
  //   badge: "CRIS",
  //   country: "South Korea"
  // },
  {
    region: "asia",
    registry: "MFDS",
    badge: "MFDS",
    country: "South Korea"
  },
  // {
  //   region: "asia",
  //   registry: "Ministry of Food and Drug Safety",
  //   badge: "MFDS",
  //   country: "South Korea"
  // },
  {
    region: "asia",
    registry: "HSA Clinical Trial Register",
    badge: "HSACTR",
    country: "Singapore"
  },
  {
    region: "asia",
    registry: "Taiwan Drug Clinical Trial Information Network",
    badge: "TDCTIN",
    country: "Taiwan"
  },
  {
    region: "asia",
    registry: "Philippine Health Research Registry",
    badge: "PHRR",
    country: "Phillipines"
  },
  {
    region: "asia",
    registry: "Thai Clinical Trials Registry",
    badge: "TCTR",
    country: "Thailand"
  },
  {
    region: "americas",
    registry: "ClinicalTrials.gov",
    badge: "CTG",
    country: "USA"
  },
  {
    region: "americas",
    registry: "Sponsor Website",
    badge: "Sponsor Website",
    country: "USA"
  },
  {
    region: "americas",
    registry: "Cuban Public Registry of Clinical Trials",
    badge: "RPCEC",
    country: "Cuba"
  },
  {
    region: "americas",
    registry: "National Registry of Clinical Trials",
    badge: "RNEC",
    country: "Mexico"
  },
  {
    region: "americas",
    registry: "National Registry of Health Research",
    badge: "ReNIS",
    country: "Argentina"
  },
  {
    region: "americas",
    registry: "Brazilian Registry of Clinical Trials",
    badge: "ReBEC",
    country: "Brazil"
  },
  {
    region: "americas",
    registry: "Peruvian Clinical Trial Registry",
    badge: "REPEC",
    country: "Peru"
  },
  // added Health Canada Database from test env
  {
    region: "americas",
    registry: "Health Canada Database",
    badge: "HCD",
    country: "Canada"
  },
  {
    region: "mideast",
    registry: "MyTrial",
    badge: "MyTrial",
    country: "Israel"
  },
  // {
  //   region: "mideast",
  //   registry: "Clinical Research Site - MyTrial",
  //   badge: "MyTrial",
  //   country: "Israel"
  // },
  {
    region: "mideast",
    registry: "Lebanon Clinical Trials Registry",
    badge: "LBCTR",
    country: "Lebanon"
  },
  {
    region: "mideast",
    registry: "Saudi Clinical Trials Registry",
    badge: "SCTR",
    country: "Saudi Arabia"
  },
  {
    region: "mideast",
    registry: "KAP",
    badge: "KAP",
    country: "Turkey"
  },
  // {
  //   region: "mideast",
  //   registry: "Clinical Research Portal",
  //   badge: "KAP",
  //   country: "Turkey"
  // },
  {
    region: "africa",
    registry: "SANCTR",
    badge: "SANCTR",
    country: "South Africa"
  },
  // {
  //   region: "africa",
  //   registry: "South African National Clinical Trials Register",
  //   badge: "SANCTR",
  //   country: "South Africa"
  // },
  {
    region: "europe",
    registry: "EudraCT",
    badge: "EudraCT",
    country: "General"
  },
  {
    region: "europe",
    registry: "ENCePP",
    badge: "ENCePP",
    country: "General"
  },
  // {
  //   region: "europe",
  //   registry: "European Union Drug Regulating Authorities Clinical Trials Database",
  //   badge: "EudraCT",
  //   country: "General"
  // },
  {
    region: "europe",
    registry: "EUCTIS",
    badge: "EUCTIS",
    country: "General"
  },
  // {
  //   region: "europe",
  //   registry: "EU Clinical Trial Information System",
  //   badge: "EUCTIS",
  //   country: "General"
  // },
  {
    region: "europe",
    registry: "EU PAS - ENCePP (European Network of Centres for Pharmacoepidemiology and Pharmacovigilance [PASS & PAES requirements])",
    badge: "EU PAS",
    country: "General"
  },
  {
    region: "europe",
    registry: "DRKS",
    badge: "DRKS",
    country: "Germany"
  },
  // {
  //   region: "europe",
  //   registry: "German Clinical Trials Register",
  //   badge: "DRKS",
  //   country: "Germany"
  // },
  {
    region: "europe",
    registry: "PharmNet.Bund",
    badge: "PharmNet.Bund",
    country: "Germany"
  },
  // NTR: added from data in test env
  {
    region: "europe",
    registry: "NTR",
    badge: "NTR",
    country: "Netherlands"
  },
  {
    region: "europe",
    registry: "CCMO",
    badge: "CCMO",
    country: "Netherlands"
  },
  // {
  //   region: "europe",
  //   registry: "Central Committee on Research Involving Human Subjects",
  //   badge: "CCMO",
  //   country: "Netherlands"
  // },
  {
    region: "europe",
    registry: "RNEC(Portugal)",
    badge: "RNEC",
    country: "Portugal"
  },
  // {
  //   region: "europe",
  //   registry: "National Registry for Clinical Studies",
  //   badge: "RNEC",
  //   country: "Portugal"
  // },
  // added CT.ru from test env
  {
    region: "europe",
    registry: "CT.ru",
    badge: "CT.ru",
    country: "Russia"
  },
  {
    region: "europe",
    registry: "State Register of Medicine",
    badge: "GRLS",
    country: "Russia"
  },
  {
    region: "europe",
    registry: "REeC",
    badge: "Reec",
    country: "Spain"
  },
  // {
  //   region: "europe",
  //   registry: "Spanish Registry of Clinical Studies",
  //   badge: "Reec",
  //   country: "Spain"
  // },
  {
    region: "europe",
    registry: "SNCTP",
    badge: "SNCTP",
    country: "Switzerland"
  },
  // {
  //   region: "europe",
  //   registry: "Swiss National Clinical Trials Portal",
  //   badge: "SNCTP",
  //   country: "Switzerland"
  // },
  {
    region: "europe",
    registry: "ISRCTN/HRA",
    badge: "ISRCTN",
    country: "United Kingdom"
  },
  // {
  //   region: "europe",
  //   registry: "International Standard Registered Clinical/Social Study Number",
  //   badge: "ISRCTN",
  //   country: "United Kingdom"
  // },
  {
    region: "mideast",
    registry: "Egyptian Clinical Trials Registry",
    badge: "Clinicaltrial.mohealth.gov.eg",
    country: "Egypt"
  },
  {
    region: "africa",
    registry: "Nigeria Clinical Trials Registry",
    badge: "NCTR",
    country: "Nigeria"
  },
  {
    region: "asia",
    registry: "Hong Kong University Clinical Trial Registry",
    badge: "HKUCTR",
    country: "Hong Kong"
  },
  {
    region: "asia",
    registry: "Scientific Center of Drug and Medical Technologies Expertise",
    badge: "SCDMTE",
    country: "Armenia"
  },
  {
    region: "asia",
    registry: "Indonesian Clinical Research Registry",
    badge: "ICRR",
    country: "Indonesia"
  },
  {
    region: "mideast",
    registry: "Iranian Registry of Clinical Trials",
    badge: "IRCT",
    country: "Iran"
  },
  {
    region: "africa",
    registry: "Pharmacy and Poisons Board",
    badge: "PPB",
    country: "Kenya"
  },
  {
    region: "asia",
    registry: "National Medical Research Register",
    badge: "NMRR",
    country: "Malaysia"
  },
  {
    region: "asia",
    registry: "Sri Lanka Clinical Trials Registry",
    badge: "SLCTR",
    country: "Srilanka"
  },
  {
    region: "africa",
    registry: "Tanzania Clinical Trials Registry",
    badge: "TzCTR",
    country: "Tanzania"
  }
]