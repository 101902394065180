const errorColor = '#D7542C'

export const primaryTheme = {
  token: {
    fontSize: 12,
    fontFamily: "Open Sans",

    /** 
     * Field input, placeholder, and icon color
     * Select component "multiple" Select items text and background color shade
     * Date calendar text and forward/back icons
     * Cursor color
     **/
    colorTextBase: "#383E47", // "black" text

    /** Collapse text color, Field input color,  "multiple" Select option item color */
    colorText: "#60656C", // "rgba(0, 0, 0, 0.88)"

    /** Collapse header text color */
    colorTextHeading: "#636668", // "rgba(0, 0, 0, 0.88)", // Control the font color of heading.

    colorBgContainer: "#FEFEFE", // Field background color

    /** Shade of fields' focus and active border and box-shadow color  */
    colorPrimary: "#5A9FB8", // "hyperlink" blue

    /** controlHeight: modifies the controls' "medium" size padding to affect controls' height
     * The "small" and "large" controls are 6px smaller/larger than the "medium" size
     **/
    controlHeight: 32,

    lineWidth: 1, // Border width of base components

    colorBorder: "#ADB0B4",
    borderRadius: 3,

    /** Card, Modal, Collapse modal border-radius */
    borderRadiusLG: 5,

    /** Checkbox, Select option items radius */
    // borderRadiusSM: 20, // 


    /** Select selected option background color */
    controlItemBgActive: '#F6F9FA',

    /** Collapse background */
    colorFillAlter: "white", // "#FBFCFD", // instead of transparent

    /** error colors */
    colorError: errorColor,
    colorErrorBorderHover: errorColor,

    /** Collapse arrow icon padding-inline-end */
    marginSM: 5,

    /** Collapse header right and left padding */
    padding: 13.78,
    /** Collapse header top and bottom padding */
    paddingSM: 8,

    // Segmented transition color when switch tabs, Modal background color
    // colorBgElevated: "#9566ab",

    // Segmented non-selected background color
    colorBgLayout: "transparent",

    /** Segmented hover color on non-active item
     * Select multi-select pill fill color
     **/
    // colorFillSecondary: "rgba(0, 0, 0, 0.06)",

    // Segmented time to move from one item to another
    motionDurationSlow: "0.1s", // "0.3s",

    // Tooltip background color
    colorBgSpotlight: "#1A3140",

    /** Weak action. Such as `allowClear` or Alert close button
     * Select mutli-select pills close icon color 
     **/
    colorIcon: "#383E47", // rgba(0, 0, 0, 0.45)
  },
}