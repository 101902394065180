import { useState, useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow'

// components
import PrimeField from 'components/PrimeField/PrimeField';

// context
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';

const Cell = ({
  arm,
  intervention,
  onChange,
  tableData
}) => {
  const {
    readOnly,
    showPagePreview
  } = useAuthoringViewStore(
    useShallow(state => ({ 
      readOnly: state.readOnly,
      showPagePreview: state.showPagePreview
    }))
  )
  
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    let isChecked = false;

    let foundIntervention = tableData?.interventions?.find(item => item.id === intervention.id)
    if (foundIntervention?.arms) {
      isChecked = foundIntervention.arms.some(armId => armId === arm.id);
    }

    setChecked(isChecked)
  }, [])

  const onCheck = (val) => {
    setChecked(val)
    onChange(val, arm, intervention)
  }

  return (
    <td
      key={intervention.id + arm.id}
      style={{
        verticalAlign: "middle",
      }}>
      <PrimeField
        schema={{
          id: arm.id + intervention.id,
          type: "checkbox",
          style: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }
        }}

        disabled={readOnly || showPagePreview}
        value={checked}
        containerStyle={{ margin: "auto" }}
        // onChange={e => onChange(e, arm, intervention)} 
        onChange={onCheck}
      />
    </td>
  );
};

export default Cell;