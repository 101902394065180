import { useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

import useRefreshToken from 'utilities/useRefreshToken';
import useAuth from 'context/hooks/useAuth';// apis
import {
  getCookie,
} from 'api/apiUtils'

// context
import useAppState from 'context/hooks/useAppState'

const useAxiosPrivate = () => {
  const {
    setTokens,
    refreshTokens,
  } = useRefreshToken();
  const { pathname } = useLocation()

  const {
    appStateRef
  } = useAppState()
  const { auth, setAuth } = useAuth();

  const pathnameRef = useRef()

  pathnameRef.current = useMemo(() => {
    return pathname
  }, [pathname])

  useEffect(() => {
    const requestIntercept = axios.interceptors.request.use(
      async config => {
        let accessToken = getCookie("xopri-jwt-actoken")
        let loginIdp = getCookie("LOGIN_IDP") || null

        if (accessToken) {
          config.headers['Authorization'] = `Bearer ${accessToken}`;
          config.headers['LOGIN_IDP'] = loginIdp;
        }

        return config;
      }, (error) => Promise.reject(error)
    );

    const responseIntercept = axios.interceptors.response.use(
      response => response,
      async (error) => {
        const prevRequest = error?.config;

        if (error.response?.data?.error?.status_code === 403 && !prevRequest._retry) {
          prevRequest._retry = true;
          if (appStateRef.current !== "refreshing-token") {
            let tokens = await refreshTokens();
            setTokens(tokens)
          }

          return axios(prevRequest);
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(requestIntercept);
      axios.interceptors.response.eject(responseIntercept);
    }
  }, [auth, refreshTokens])

  return axios
};

export default useAxiosPrivate;