import useGetApi from "../useGetApi"
import useMutateApi from "../useMutateApi"

// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

// utils
import getUrl from "api/apiMap"

export const useGetGlobalSiteSettings = () => {
  return useGetApi(getUrl(apiEndpoints.globalSiteSettings), queryKeys.globalSiteSettings)
}

// mutate({ body })
export const usePostGlobalSiteSettings = () => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(apiEndpoints.globalSiteSettings),
    invalidateQueries: [queryKeys.globalSiteSettings],
    successMsg: "Successfully updated global site settings"
  })
}

// mutate({ id, body })
export const usePutGlobalSiteSettings = () => {
  return useMutateApi({
    method: "put",
    apiEndpoint: getUrl(apiEndpoints.globalSiteSettings),
    invalidateQueries: [queryKeys.globalSiteSettings],
    successMsg: "Successfully updated global site settings"
  })
}

// mutate({ id })
export const useDeleteGlobalSiteSettings = () => {
  return useMutateApi({
    method: "delete",
    apiEndpoint: getUrl(apiEndpoints.globalSiteSettings),
    invalidateQueries: [queryKeys.globalSiteSettings],
    successMsg: "Successfully deleted global site settings"
  })
}