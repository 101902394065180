import useGetApi from "../useGetApi"
import useMutateApi from "../useMutateApi"

// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

// utils
import getUrl from "api/apiMap"

export const useGetStudyProtocolDataCompare = (authoringId) => {
  return useGetApi(getUrl(`${apiEndpoints.authoring}${authoringId}/protocol-data-compare`), queryKeys.registriesDataCompare.details(authoringId))
}

export const useGetStudyRegistryData = (authId) => {
  return useGetApi(getUrl(`${apiEndpoints.authoring}${authId}/protocol-reg-data`), queryKeys.registriesData.details(authId))
}

export const usePostPrsProtocolDownload = (authoringId) => {
  let url = `/authoring/api/v1/tasks/prs-protocol-download?stu-id=${authoringId}`
  let invalidateQueries = [...queryKeys.prsProtocol.list]
  let options = {}
  
  if (authoringId) invalidateQueries.push(queryKeys.prsProtocol.details(authoringId))
  if (authoringId) options = { params: { "stu-id": authoringId } }

  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(url),
    invalidateQueries,
    options: {
      params: {
        "stu-id": authoringId
      }
    }
  })
}

export const useGetPrsProtocolUpload = (queueId, options) => {
  return useGetApi(getUrl(apiEndpoints.prsProtocolUpload), [], options, {
    "queue-id": queueId
  })
}

export const usePostPrsProtocolUpload = () => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(apiEndpoints.prsProtocolUpload),
    invalidateQueries: [],
    successMsg: "PRS protocol upload in progress"
  })
}