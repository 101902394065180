import React from 'react';
import { useShallow } from 'zustand/react/shallow'

// components
import Label from 'components/Label/Label';
import PrimeField from 'components/PrimeField/PrimeField';

// context
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';

const DispersionInputs = ({
  parameterType,
  dispersionType,
  dispersionValue,
  cell,
  cellArmId,
  onChange,
}) => {
  const { readOnly } = useAuthoringViewStore(
    useShallow(state => ({
      readOnly: state.readOnly
    }))
  )

  return (
    <div className='flex flex-col' style={{ gap: 16 }}>
      <PrimeField
        schema={{
          type: "text",
          label: parameterType,
          orientation: "vertical",
        }}
        readOnly={readOnly}
        onChange={(e) => onChange(e, cellArmId, "value")}
        value={cell?.value} />
      <PrimeField
        schema={{
          type: "text",
          label: dispersionType,
          orientation: "vertical",
        }}
        readOnly={readOnly}
        onChange={(e) => onChange(e, cellArmId, "dispersion_value")}
        value={dispersionValue} />
    </div>
  );
};

export default DispersionInputs;