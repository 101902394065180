import React from 'react';
import { create } from 'zustand'

const useAuthoringValidationsStore = create((set) => ({
  validations: null,
  setValidations: (validations) => set({ validations }),

  registryValidations: null,
  setRegistryValidations: (registryValidations) => set({ registryValidations }),

  sitesValidations: null,
  setSitesValidations: (sitesValidations) => set({ sitesValidations }),

  resultsTableValidations: null,
  setResultsTableValidations: (resultsTableValidations) => set({ resultsTableValidations }),

}))

export default useAuthoringValidationsStore;