import React from 'react'
import './ActionItemStatus.scss'

// components
import StatusBadge from '../../StatusBadge/StatusBadge';

const ActionItemStatus = ({ data, value, tooltip }) => {
  if (data.is_cancelled) value = "Cancelled"
  else if (data.is_complete) value = "Completed";

  return <div className="action-status-cell-renderer">
    <StatusBadge type={value} tooltip={tooltip} />
  </div>
}

export default ActionItemStatus;