import React, { useState, useEffect, forwardRef, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query'
import { useShallow } from 'zustand/react/shallow'
import { isEmpty } from 'lodash';

// components
import Loader from 'components/Loading/Loading';
import FormActions from './subcomponents/FormActions/FormActions';
import SidePanelContainer from './subcomponents/SidePanelContainer/SidePanelContainer';
import RJForm from './RJS/RJForm';
import SitesGrid from './subcomponents/SitesGrid/SitesGrid';
import PagePreview from './subcomponents/PagePreview/PagePreview';

import Modal from 'components/Modal/Modal';
import { Button } from 'components-design-system'

// templates
import ObjectFieldTemplate from './RJS/templates/ObjectFieldTemplate';
import ArrayFieldTemplate from './RJS/templates/ArrayFieldTemplate';

// utils
import { getVersionValues } from './utils';
import queryKeys from 'api/utils/queryKeys';
import { formSchemas } from "constants/authoring";
import { getHiddenNodes, getRegistryForkedFields } from './utils';

// api data
import { validationData } from './api/test_site_panel';
import PrimeField from 'components/PrimeField/PrimeField';

// context
import useAuthoringViewStore from './hooks/stores/useAuthoringViewStore';
import useAppState from 'context/hooks/useAppState';

// apis
import { useUserPermissions } from 'api/hooks';
import { useGetSiteManagementLocations } from 'api/hooks/siteManagement/useSiteManagementApi';
import {
  useGetAuthoringVersion,
  useUnlockAuthoringForm,
} from 'api/hooks/studies/useAuthoringApi';
import { useGetLockedFields } from 'api/hooks/studies/useAuthoringApi';

// hooks
import { useAutosave } from 'react-autosave';
import useAuthoringComments from './hooks/actions/useAuthoringCommentActions';
import useAuthoringPrintStore from './hooks/stores/useAuthoringPrintStore';
import useAuthoringValidationsStore from './hooks/stores/useAuthoringValidationsStore';
import useAuthoringDataStore from './hooks/stores/useAuthoringDataStore';
import useAuthoringFieldStore from './hooks/stores/useAuthoringFieldStore';
import useAuthoringVersionStore from './hooks/stores/useAuthoringVersionStore';
import useAuthoringStore from './hooks/stores/useAuthoringStore';
import useAuthoringSaveActions from './hooks/actions/useAuthoringSaveActions';
import useResultsTableViewStore from './hooks/stores/useResultsTableViewStore';

// styles
import './Authoring.scss'
import 'styles/authoring-print.scss';
import './index.css';

const Authoring = forwardRef(function Authoring({ study, authoring }, ref) {
  const { sidebarOpen } = useAppState()
  const formDataRef = React.useRef()
  const forkedStudyDataRef = React.useRef()
  const overrideDataRef = React.useRef()

  const {
    authoringId,
    isUserLocked,
    setIsUserLocked,
    setShowSaveLock,
    formWidthClassName,
    setFormWidthClassName,
    enableAutoSave,
    setEnableAutoSave,
    showOverrideModal,
    setShowOverrideModal,
    overrideCallback,
  } = useAuthoringStore(
    useShallow(state => ({
      authoringId: state.authoringId,
      isUserLocked: state.isUserLocked,
      setIsUserLocked: state.setIsUserLocked,
      setShowSaveLock: state.setShowSaveLock,
      formWidthClassName: state.formWidthClassName,
      enableAutoSave: state.enableAutoSave,
      setEnableAutoSave: state.setEnableAutoSave,
      showOverrideModal: state.showOverrideModal,
      setShowOverrideModal: state.setShowOverrideModal,
      overrideCallback: state.overrideCallback,
      setFormWidthClassName: state.setFormWidthClassName,
    }))
  )

  const {
    formData,
    setFormData,
    forkedStudyData,
    setForkedStudyData,
    overrideData,
    setOverrideData,
    setAttachedDocuments,
  } = useAuthoringDataStore(
    useShallow(state => ({
      formData: state.formData,
      setFormData: state.setFormData,
      forkedStudyData: state.forkedStudyData,
      setForkedStudyData: state.setForkedStudyData,
      overrideData: state.overrideData,
      setOverrideData: state.setOverrideData,
      setAttachedDocuments: state.setAttachedDocuments,
    }))
  )

  const {
    isReviewerView,
    showPagePreview,
    setShowPagePreview,
    formView,
    formSection,
    formStatus,
    setFormStatus,
    currDataSchema,
    currUISchema,
    showPanel,
    setShowPanel,
    setCurrDataSchema,
    setCurrUISchema,
    setFormView,
    setFormSection,
    readOnly,
    refreshFormData,
  } = useAuthoringViewStore(
    useShallow(state => ({
      isReviewerView: state.isReviewerView,
      showPagePreview: state.showPagePreview,
      setShowPagePreview: state.setShowPagePreview,
      formView: state.formView,
      formSection: state.formSection,
      formStatus: state.formStatus,
      setFormStatus: state.setFormStatus,
      currDataSchema: state.currDataSchema,
      currUISchema: state.currUISchema,
      showPanel: state.showPanel,
      setShowPanel: state.setShowPanel,
      setCurrDataSchema: state.setCurrDataSchema,
      setCurrUISchema: state.setCurrUISchema,
      setFormView: state.setFormView,
      setFormSection: state.setFormSection,
      readOnly: state.readOnly,
      refreshFormData: state.refreshFormData
    }))
  )

  const {
    printCtgOnly,
    setPrintCtgOnly,
  } = useAuthoringPrintStore(
    useShallow(state => ({
      printCtgOnly: state.printCtgOnly,
      setPrintCtgOnly: state.setPrintCtgOnly,
    }))
  )

  const {
    hiddenFields,
    setHiddenFields,
    forkedFields,
    setForkedFields,
    setLockedFields
  } = useAuthoringFieldStore(
    useShallow(state => ({
      hiddenFields: state.hiddenFields,
      forkedFields: state.forkedFields,
      setHiddenFields: state.setHiddenFields,
      setForkedFields: state.setForkedFields,
      setLockedFields: state.setLockedFields,
    }))
  )

  const {
    validations,
    setRegistryValidations
  } = useAuthoringValidationsStore(
    useShallow(state => ({
      validations: state.validations,
      setRegistryValidations: state.setRegistryValidations,
    }))
  )

  const {
    selectedVersion,
    setSelectedVersion,
    versionData,
    setVersionData,
    versionValues,
    setVersionValues,
  } = useAuthoringVersionStore(
    useShallow(state => ({
      selectedVersion: state.selectedVersion,
      setSelectedVersion: state.setSelectedVersion,
      versionData: state.versionData,
      setVersionData: state.setVersionData,
      versionValues: state.versionValues,
      setVersionValues: state.setVersionValues,
    }))
  )

  const {
    setParticipantView,
    setTableView
  } = useResultsTableViewStore()

  const {
    handleSubmit
  } = useAuthoringSaveActions()

  const queryClient = useQueryClient()
  useAuthoringComments()

  // hooks
  const { data: permissions } = useUserPermissions()
  const { data: sites } = useGetSiteManagementLocations(study?.site_mgmt_study_id)
  const unlockAuthoringForm = useUnlockAuthoringForm(authoring?.id)
  const { data: authoringVersion, refetch: refetchAuthoringVersion } = useGetAuthoringVersion(authoring?.id, selectedVersion?.version_num)
  const { data: lockedFieldsResp = [] } = useGetLockedFields()

  useEffect(() => {
    if (printCtgOnly) {
      setShowPagePreview(true);
      setPrintCtgOnly(true);
    }
  }, [printCtgOnly])

  useEffect(() => {
    if (formView && formSection) {
      const schema = formSchemas[formSection.id].properties[formView.id];

      if (schema?.schema && schema?.uiSchema) {
        setCurrDataSchema(schema.schema);
        setCurrUISchema(typeof schema.uiSchema === "function" ? schema.uiSchema() : schema.uiSchema);
      } else {
        console.log('ERROR: schema not found:', {
          formView, formSection,
        });
      }
    };
    if (formView?.id !== "ctg") {
      setRegistryValidations(null)
    }
  }, [formView, formSection]);

  useEffect(() => {
    if (formSection) {
      const formSectId = formSection.id;
      if (!hiddenFields?.[formSectId]) {
        const hiddenNodes = {};
        for (const regKey in formSchemas[formSectId].properties) {
          if (regKey !== "global") {
            let uiSchema = formSchemas[formSectId].properties[regKey].uiSchema;

            if (typeof uiSchema === "function") uiSchema = uiSchema();
            const nodes = getHiddenNodes(uiSchema, 'root');
            if (nodes?.length > 0) {
              hiddenNodes[formSectId] = hiddenNodes[formSectId] || {};
              hiddenNodes[formSectId][regKey] = nodes;
            }
          }
        }
        setHiddenFields(hiddenNodes);
      };
    }
  }, [formSection?.id, JSON.stringify(hiddenFields)]);

  useEffect(() => {
    if (formView) {
      if (formView.id === "ctg" || formView.id === "global") {
        setTableView("ctg")
        setParticipantView("ctg_periods")
      } else if (formView.id === "eudra") {
        setTableView("eudract")
        setParticipantView("eudract_periods")
      }
    }
  }, [formView])

  useEffect(() => {
    if (isReviewerView !== true) {
      localStorage.setItem("SHOW_PANEL", showPanel);
    }
  }, [showPanel])

  useEffect(() => {
    if (showPanel && sidebarOpen) {
      setFormWidthClassName('form-open-sidebar-open-panel-inner')
    } else if (showPanel && !sidebarOpen) {
      setFormWidthClassName('form-close-sidebar-open-panel-inner')
    } else if (!showPanel && sidebarOpen) {
      setFormWidthClassName('form-open-sidebar-close-panel-inner')
    } else {
      setFormWidthClassName('form-close-sidebar-close-panel-inner')
    }
  }, [showPanel, sidebarOpen])

  // only needed at init to cache forkedFields
  // when field is forked, update forkedFields
  useEffect(() => {
    if (formSection) initSectForkedFields(formSection.id, formData);
  }, [formSection, versionData]);

  /** VERSIONING */
  // version data to revert to
  // versioned data lookup table to quickly retrieve values
  const [showSaveVersModal, setShowSaveVersModal] = useState(false);

  // PAGE PREVIEW
  const [checkOverrideModal, setCheckOverrideModal] = useState(false);

  useEffect(() => {
    if (lockedFieldsResp) {
      let res = {}
      lockedFieldsResp.forEach(field => {
        let jpath = "root_" + field.split(".").join("_") // format jpath by appending root_ and converting dot notation to underscore 
        res[jpath] = field
      })
      setLockedFields(res)
    }
  }, [lockedFieldsResp])

  useEffect(() => {
    if (authoring?.locked) {
      setShowSaveLock(authoring.locked)
    }

    return () => {
      onResetStates()
    }
  }, [])

  const onResetStates = () => {
    let forkedStudyData = {
      ctg: {},
      eudract: {}
    }
    setForkedStudyData(forkedStudyData)
    formDataRef.current = null
    forkedStudyDataRef.current = forkedStudyData
    overrideDataRef.current = {}
    setVersionValues({})
    setFormStatus("init")
    setEnableAutoSave(false)
    if (isReviewerView === false) {
      setFormView({ id: "global", text: "Global" })
      setFormSection({ id: "protocol", text: "Protocol" })
      setForkedFields(null)
      setSelectedVersion(null)
      setVersionData(null)
    }

    queryClient.invalidateQueries(queryKeys.authoringVersions.list(authoringId))
    queryClient.invalidateQueries(queryKeys.authoringStudies.detail(authoringId))
  }

  useAutosave({
    data: formData,
    interval: 1000,
    onSave: (data) => {
      if (permissions?.["study.edit"] && isUserLocked === false && isReviewerView !== true && showPagePreview !== true && formData && enableAutoSave) {
        handleSubmit({ formData: data })
      }
    }
  })

  useEffect(() => {
    if (isReviewerView) {
      setShowPanel(true)
    }
  }, [isReviewerView]);

  useEffect(() => {
    if (selectedVersion) refetchAuthoringVersion()
  }, [selectedVersion])

  /**
   * VERSION COMPARE: when user selects a version
   * cache versions to lookup table
   */
  useEffect(() => {
    if (selectedVersion && authoringVersion) {
      const selectedVersNum = selectedVersion.version_num;
      let versData = null;

      // find version values and cache
      // getStudyVersion(authoring.id, selectedVersNum).then(res => {
      versData = authoringVersion?.data?.study_data;

      if (versData) {
        setVersionValues({ ...versionValues, [selectedVersNum]: getVersionValues(versData) });
        setVersionData(authoringVersion.data);
      };
    }

  }, [selectedVersion, authoringVersion]);

  // INIT from StudyOverview authoring data
  useEffect(() => {
    onResetStates()
    if (authoring?.attached_documents) {
      setAttachedDocuments(authoring?.attached_documents);
    };

    if (authoring) {
      let studyData = { ...authoring.study_data };
      let forkedData = {
        ctg: {},
        eudract: {}
      }

      if (studyData.forked_study_data) {
        forkedData = { ...studyData.forked_study_data }
      }

      if (authoring.is_locked_for_user) {
        setIsUserLocked(true);
      };

      setForkedStudyData(forkedData)
      forkedStudyDataRef.current = forkedData
      setOverrideData(authoring?.override_data)
      overrideDataRef.current = authoring?.override_data
      initSectForkedFields(formSection?.id, studyData);

      formDataRef.current = studyData
      setFormData(studyData)
      setTimeout(async () => {
        setFormStatus("ready")
        await new Promise(r => setTimeout(r, 3000));
        setEnableAutoSave(true)
      }, 1000)
    }
  }, [authoring?.id]);

  /** FORKED FIELDS */
  const initSectForkedFields = (sectKey, _formData) => {
    if (_formData) {
      // for all registries, not incl global
      let registryKeys = [];
      for (const key in formSchemas[formSection.id].properties) {
        if (key !== "global") registryKeys.push(key);
      }

      // if more than one registry
      if (registryKeys?.length > 1) {
        let _forkedFields = { ...forkedFields } || {};
        if (!_forkedFields[sectKey]) _forkedFields[sectKey] = {};
        registryKeys.forEach((regKey) => {
          const regFields = getRegistryForkedFields(regKey, _formData[formSection.id], [`${formSection.id}`]);
          if (regFields && !isEmpty(regFields)) {
            _forkedFields[sectKey][regKey] = regFields;
            setForkedFields(_forkedFields);
          }
        });
      };
    };
  };

  /** EVENT HANDLERS */
  const handleUnlocking = () => {
    unlockAuthoringForm(authoring.id, {
      onSuccess: () => {
        setIsUserLocked(false);
        setShowSaveLock(false)
      }
    });
  };

  const revertToVersion = () => {
    if (versionData?.study_data) {
      setForkedFields(null);
      setFormData(versionData.study_data);
      formDataRef.current = versionData.study_data
      setOverrideData(versionData?.override_data)
      overrideDataRef.current = versionData?.override_data
      initSectForkedFields(formSection?.id, versionData.study_data);
      if (versionData.study_data?.forked_study_data) {
        setForkedStudyData(versionData.study_data?.forked_study_data)
        forkedStudyDataRef.current = versionData.study_data?.forked_study_data
      } else {
        let forkedStudyData = { ctg: {}, eudract: {} }
        setForkedStudyData(forkedStudyData)
        forkedStudyDataRef.current = forkedStudyData
      }
    } else {
      console.log('ERROR: no version data selected.')
    }
  }

  const confirmOverride = () => {
    setShowOverrideModal(false)

    if (checkOverrideModal) {
      localStorage.setItem("show-override-warning", false)
    }
    overrideCallback()
  }

  const renderAuthoringForm = useMemo(() => {
    const formContext = {
      formData,
      formDataRef: formDataRef.current,
      forkedStudyDataRef,
      overrideDataRef,
      setFormData,
      handleSubmit,
    }

    if (showPagePreview === true) {
      return <div id="page-preview-container">
        <PagePreview authoring={authoring} />
      </div>
    }

    return (
      <>
        <RJForm
          formData={formData}
          schema={currDataSchema}
          uiSchema={currUISchema}
          ObjectFieldTemplate={ObjectFieldTemplate}
          ArrayFieldTemplate={ArrayFieldTemplate}
          onChange={data => {
            formDataRef.current = data
            setFormData(data)
          }}
          handleSubmit={handleSubmit}
          formContext={formContext}
        />
        <div className="ag-doc-sites-container" style={{ display: "flex", flexDirection: "column" }}>
          {formSection.id === "protocol" && formView.id !== "eupas" && (
            <div
              className="doc-loc-panel-wrapper"
              id="locations-container">
              <div className="authoring-form-section-panel-h1">
                Locations
              </div>
              <SitesGrid
                study={study}
                sites={sites}
              />
            </div>
          )}
        </div>
      </>
    )
  }, [
    readOnly,
    formSection,
    formView,
    currDataSchema,
    currUISchema,
    showPagePreview,
    formWidthClassName,
    overrideData,
    selectedVersion,
    authoring,
    refreshFormData,
    formStatus,
    forkedStudyData
  ])

  const renderSidepanel = useMemo(() => {
    return (
      <SidePanelContainer
        validationData={validationData}
        unmappedValidations={validations?.unmapped_validations}
      />
    )
  }, [validationData, validations?.unmapped_validations])

  const renderFormActions = useMemo(() => {
    return <FormActions
      handleSubmit={handleSubmit}
      handleUnlocking={handleUnlocking}
      toggleShowSaveVersModal={() => setShowSaveVersModal(!showSaveVersModal)}
      revertToVersion={revertToVersion}
    />
  }, [isReviewerView, showSaveVersModal, versionData, formStatus])

  if (formStatus !== "ready" || !formView || !currDataSchema || !currUISchema || !authoring) return <Loader />

  let classNames = [`prime-authoring-container`]
  if (isReviewerView) classNames.push('reviewer-view')

  return (
    <div
      id="prime-authoring-container"
      className={classNames.join(" ")}
    >
      {!isReviewerView && renderFormActions}
      <div className="authoring-content-container" id="authoring-print-page-container">
        <div
          className="authoring-form-container"
          id="authoring-form-container"
          ref={ref}
        >
          {renderAuthoringForm}
        </div>
        <div className="authoring-sidepanel-container">
          {renderSidepanel}
        </div>
      </div>
      <Modal
        title={"Override data?"}
        message={"This field value is set by a source system. Manually entered data in this field will disconnect the source system feed into this field until the override is removed."}
        type="delete"
        open={showOverrideModal}
        onCancel={() => setShowOverrideModal(false)}
        footer={[]}
        width={400}>
        <div className="override-modal-content">
          <div className="override-modal-actions">
            <Button
              variant="outlined"
              size="sm"
              style={{
                marginRight: 10,
                borderColor: "#9B9EA3",
                color: "#9B9EA3",
                fontWeight: "600"
              }}
              onClick={() => setShowOverrideModal(false)}>
              Cancel
            </Button>
            <Button
              size="sm"
              onClick={confirmOverride}
              style={{
                backgroundColor: "#9566AB",
                borderColor: "#9566AB",
                fontWeight: "600"
              }}>
              Override
            </Button>
          </div>
          <PrimeField
            schema={{
              type: "checkbox",
              label: "Don't show this message again"
            }}
            value={checkOverrideModal}
            onChange={setCheckOverrideModal}
          />
        </div>
      </Modal>
    </div>
  );
});


export default Authoring;