import React from 'react';
import Label from 'components/Label/Label';

import './ProductCard.scss';

const ProductCard = ({
  arm,
  index,
  product,
  readOnly,
  showActions,
  onOpenProduct,
  onDeleteProduct
}) => {
  const productSchema = [
    {
      key: "imp_name",
      label: "IMP name"
    },
    {
      key: "imp_code",
      label: "IMP code"
    },
    {
      key: "other_names",
      label: "Other names"
    },
    {
      key: "routes_of_administration",
      label: "Routes of administration"
    },
    {
      key: "pharmaceutical_forms",
      label: "Pharmaceutical forms"
    },
    {
      key: "dosage_and_administration_details",
      label: "Dosage and administration details"
    }
  ]

  return (
    <div className="product-card-container">
      <div className='flex justify-between'>
        <Label>
          {index + 1}. Product
        </Label>
        {readOnly !== true && (
          <div
            style={{
              gap: 8,
              display: showActions ? "flex" : "none"
            }}>
            <i
              onClick={() => onOpenProduct({ arm, product })}
              className="fal fa-edit arrow-icon cursor-pointer" />
            <i
              onClick={() => onDeleteProduct(arm, index)}
              className="fal fa-trash-alt trash-icon cursor-pointer" />
          </div>
        )}
      </div>
      {productSchema.map((schema, i) => {
        let value = product[schema.key]
        if (Array.isArray(value)) value = value.join(", ")

        return (
          <div className="product-line-item" key={arm?.id + index + i}>
            <Label>{schema.label}</Label>
            <div className="product-line-text">{value}</div>
          </div>
        )
      })}
    </div>
  )
};

export default ProductCard;