import { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow'
import { scrollTo } from 'utilities/scrollTo';

// hooks
import useAuthoringViewStore from '../stores/useAuthoringViewStore';
import useAuthoringStore from '../stores/useAuthoringStore';

const unhighlightAllFields = () => {
  let highlightedFields = document.getElementsByClassName("highlighted-field")
  let highlightedComments = document.getElementsByClassName("highlighted-comment")

  for (let i = 0; i < highlightedFields.length; i++) {
    let element = highlightedFields[i]
    element.classList.remove("highlighted-field")
  }

  for (let i = 0; i < highlightedComments.length; i++) {
    let element = highlightedComments[i]
    element.classList.remove("highlighted-comment")
  }
}

const highlightSelectedField = (id) => {
  if (id) {
    let field = document.getElementById(id)
    let comment = document.getElementById(id + "-side-panel-comment")
    if (field) {
      field.classList.add("highlighted-field");
    }
    if (comment) {
      comment.classList.add("highlighted-comment");
    }
  }
}

const useAuthoringActions = () => {
  const {
    setShowOverrideModal,
    setOverrideCallback,
  } = useAuthoringStore(
    useShallow(state => ({
      setShowOverrideModal: state.setShowOverrideModal,
      setOverrideCallback: state.setOverrideCallback,
    }))
  )

  const {
    isReviewerView,
  } = useAuthoringViewStore(
    useShallow(state => ({
      isReviewerView: state.isReviewerView
    }))
  )

  const scrollToField = (id) => {
    const element = document.getElementById(id);
    const container = isReviewerView ? document.getElementById("authoring-component-wrapper") : document.getElementsByClassName("right-section")[0]

    unhighlightAllFields()
    highlightSelectedField(id + "-field")

    if (element) {
      scrollTo(container, element.offsetTop - 220, 400)
    }
  }

  const handleOverride = (cb) => {
    setShowOverrideModal(true)
    setOverrideCallback(cb)
  }

  return {
    scrollToField,
    handleOverride
  }
};

export default useAuthoringActions;