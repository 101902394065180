import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Components
import { Button } from 'components-design-system'

const PRSUploadErrorMessages = ({
  setOpenModal,
  data
}) => {

  const onSaveChanges = () => {

  }

  return (
    <Container>
      <ModalBody>
        <ErrorContainer>
          {data && data.map(item => {
            return <ErrorItem data={item} />
          })}
        </ErrorContainer>
      </ModalBody>
      <ActionContainer>
        <Button 
          size="lg" 
          variant="outlined" 
          onClick={() => setOpenModal(false)}
          style={{ marginRight: 16 }}>
          Cancel
        </Button>
        <Button 
          size="lg"
          onClick={() => onSaveChanges()}>
          Save Changes
        </Button>
      </ActionContainer>
    </Container>
  );
};

export default PRSUploadErrorMessages;

const ErrorItem = ({ data }) => {
  return (
    <ErrorItemContainer>
      <ErrorInfoContainer>
        <Text style={{ marginBottom: 10 }}>
          {data && data.study_id}
        </Text>
        {data && data.discrepencies.map((item, i) => {
          return (
            <DiscrepencyItem key={item.title + "-" + i + "-discrepency"}>
              <Label style={{ marginRight: 26 }}>{item.title}</Label>
              <Text>{item.value}</Text>
            </DiscrepencyItem>
          )
        })}
        <Error>
          <i 
            style={{ 
              color: "#D7542C",
              marginRight: 7,
            }} 
            className="fas fa-exclamation-circle" />
          {data && data.discrepencies.map((item, i) => {
            return (
              <Text style={{ color: "#D7542C" }}>{item.error}{data.discrepencies.length > i && ","}</Text>
            )
          })}
        </Error>
      </ErrorInfoContainer>

      <ErrorActionContainer>
        <Link style={{ color: "#9566AB" }}>Update</Link>
      </ErrorActionContainer>
    </ErrorItemContainer>
  )
}

const Container = styled.div`

`

const ModalBody = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ErrorContainer = styled.div`
  max-height: 350px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 16px 16px 0 16px;
  overflow-y: scroll;
`

const ActionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

const ErrorItemContainer = styled.div`
  min-height: 117px;
  width: 100%;
  background: #F4F4F4;
  border-radius: 5px;
  margin-bottom: 16px;
  padding: 16px 20px;
  display: flex;
  flex-direction: row;
  align-item: center;
`

const ErrorInfoContainer = styled.div`
  width: 90%;
`

const ErrorActionContainer = styled.div`
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Text = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.16px;
  color: #383E47;
`

const DiscrepencyItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Label = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: #525252;
`

const Error = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;;
  align-items: center;
  margin-top: 16px;
`