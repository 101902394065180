import { useState } from 'react'
import { Handle, Position, useStore, NodeProps, useReactFlow } from 'reactflow';

// styles
import './StateNode.scss'

export default function StateNode({ data, id, xPos, yPos }) {
  let handleStyle = {
    border: ".75px solid black",
    backgroundColor: "#fff",
    height: 2,
    width: 2,
    opacity: .35
  }

  const classNames = ["node"]
  if(data?.is_major_state) classNames.push("major-node")
  else classNames.push("minor-node")

  return (
    <div className={classNames.join(" ")}>
      <div className={"label"}>{data?.title}</div>
      <Handle
        id={id + "tt"}
        position={Position.Top}
        type="source"
        isConnectable={true}
        style={{
          top: 1,
          ...handleStyle
        }}
      />
      <Handle
        id={id + "rt"}
        type="source"
        position={Position.Right}
        style={{
          right: 1,
          ...handleStyle
        }}
        isConnectable={true}
      />
      <Handle
        id={id + "bt"}
        position={Position.Bottom}
        type="source"
        isConnectable={true}
        style={{
          bottom: 1,
          ...handleStyle
        }}
      />
      <Handle
        id={id + "lt"}
        position={Position.Left}
        type="source"
        isConnectable={true}
        style={{
          left: 1,
          ...handleStyle
        }}
      />
    </div>
  );
}
