import { useEffect, useRef} from 'react';
import { debounce } from 'lodash';

// hooks
import useSize from './useSize';

const useAgGridResize = (ref, gridApi, breakpoints) => {
  const size = useSize(ref);

  const resizeGrid = (bp, gridApi) => {
    setColumnsVisible(bp.hide, gridApi, false);
    setColumnsVisible(bp.show, gridApi, true);
    setTimeout(() => {
      gridApi.sizeColumnsToFit()
    }, 200)
  }

  const debounceGridResize = useRef(debounce(resizeGrid, 400));

  useEffect(() => {
    if(size?.width && gridApi) {
      for(let i = 0; i < breakpoints.length; i++) {
        let bp = breakpoints[i];
        if(size.width >= bp.maxWidth) {
          debounceGridResize.current(bp, gridApi);
          break;
        } 
      }
    }
  }, [size])

  const setColumnsVisible = (headers, gridApi, val) => {
    if(gridApi) {
      if(gridApi?.gridPanel?.columnApi) {
        let { columnApi } = gridApi?.gridPanel
        if(columnApi) {
          headers.forEach(header => {
            const modifiedCol = columnApi.getAllColumns().find(col => col.colDef.headerName == header);
            columnApi.setColumnVisible(modifiedCol, val); 
          })
        }
      } else if(gridApi?.columnModel) {
        let { gridColumns } = gridApi.columnModel;
        headers.forEach(header => {
          const modifiedCol = gridColumns.find(col => col.colDef.headerName == header);
          modifiedCol?.setVisible(val)
        })
      }
    }
  }
};

export default useAgGridResize;