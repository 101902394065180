// apis for study timeline comments (vs activity ones) on study overview page
// TODO: change to .js vs .jsx because no JSX is returned
import useMutateApi from "../useMutateApi"

// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

// apis
import useGetApi from "../useGetApi"
import getUrl from "api/apiMap"

// authoring studies
export const useGetStudyFeed = (id, params = {}) => {
  let paramsStr = `?showActivity=${params.showActivity}`
  if(params?.labels?.length > 0) {
    paramsStr += `&labels=${params.labels}`
  }
  
  return useGetApi(getUrl(`${apiEndpoints.studies}${id}/timeline/${paramsStr}`), queryKeys.timeline.details(id, params.showActivity, params.labels))
}

export const usePostTimelineComment = (id, params = {}) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(`${apiEndpoints.studies}${id}/comments/`),
    invalidateQueries: [queryKeys.timeline.details(id), queryKeys.timeline.details(id, params.showActivity, params.labels)],
    successMsg: "Successfully posted comment",
  })
}

export const usePutTimelineComment = (studyId, commentId, params = {}) => {
  return useMutateApi({
    method: "put",
    apiEndpoint: getUrl(`${apiEndpoints.studies}${studyId}/comments/${commentId}/`),
    invalidateQueries: [queryKeys.timeline.details(studyId), queryKeys.timeline.details(studyId, params.showActivity, params.labels)],
    successMsg: "Sucessfully updated comment",
  })
}

export const useDeleteTimelineComment = (commentId) => {
  return useMutateApi({
    method: "delete",
    apiEndpoint: getUrl(`${apiEndpoints.studies}${commentId}/comments/`),
    invalidateQueries: [queryKeys.timeline.details(commentId)],
    successMsg: "Sucessfully deleted comment",
  })
}