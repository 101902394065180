import React from 'react'
import { Tooltip } from 'antd';
import lookup from 'country-code-lookup'

const Countries = (props) => {
  let temp = [];
  let tooltipVals = [];
  if (props?.value) {
    temp = [...props.value];
    tooltipVals = [...props.value]
  }


  return (
    <div style={{
      display: "flex",
      gap: 4,
      justifyContent: "start",
      alignItems: 'center'
    }}>
      {temp.slice(0, 2).map(country => {
        let countryCode = lookup.byCountry(country);
        return (
          <i
            key={countryCode?.iso2}
            className={`flag-icon flag-icon-${countryCode?.iso2?.toLowerCase()}`}
            style={{ width: '14px', height: '14px', borderRadius: "7px" }} />
        )
      })}
      {tooltipVals.length > 2 && (
        <Tooltip
          title={tooltipVals.slice(2).map(item => {
            return <div
              key={item}
              style={{ textAlign: 'left' }}
            >
              {item}
            </div>
          })}
        >
          <i
            className="fas fa-plus-circle"
            style={{
              color: "#878C92"
            }} />
        </Tooltip>
      )}
    </div>
  );
};

export default Countries;