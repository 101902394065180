import axios from "axios";
// import cogoToast from 'cogo-toast';
// TODO: move getCookie function into this file?
// import getCookie from '../utilities/getCookie';

const formatResponse = (res) => {
  if (res) {
    return {
      status: res.status,
      statusText: res.statusText,
      data: res.data,
    };
  }
  return null;
};

const formatSystemError = (error) => {
  // cogoToast.error(<div>System error</div>, { position: "top-right" });
  /**
   * error.response.status: 405
   * error.response.statusText: "Method Not Allowed"
   */
  return { status: 'error', error };
};

const tagSearch = (value) => {
  return axios.get("/api/tag-search", {
    params: {
      query: value
    }
  }).then(res => {
    let { data } = res.data
    return data
  }).catch(err => {
    // cogoToast.error(
    //   <div>
    //     {err.message}
    //   </div>,
    //   { position: 'top-right' }
    // );
  })
}

const getCookie = (key) =>{
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the key we want?
      if (cookie.substring(0, key.length + 1) === (key + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(key.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

const setCookie = (key, value) => {
  document.cookie = `${key}=${value}; path=/; max-age=${60 * 60 * 24}; same-site=strict;${process.env.NODE_ENV === "production" ? "secure" : ""}`;

  // document.cookie = `${key}=${value}; path=/; max-age=${60 * 60 * 24}; same-site=strict; httponly;${process.env.NODE_ENV === "production" ? " secure;" : ""}`;
}

const deleteCookie = (key) => {
  document.cookie = key + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}

const handleError = (error) => {
  // cogoToast.error(
  //   <div>
  //     {error.message}
  //   </div>,
  //   { position: 'top-right' }
  // );
}

const csrfCookie = getCookie('csrftoken');

const options = {
  headers: {
    'X-CSRFTOKEN': csrfCookie,
  }
}

export {
  csrfCookie,
  options,
  // formatResponse,
  // formatSystemError,
  // tagSearch,
  getCookie,
  setCookie,
  deleteCookie,
  // handleError
}