import { useEffect, useState } from "react";
import styled from 'styled-components';

// Components
import PrimeField from "../../PrimeField/PrimeField";
import ValidationMessage from '../../ValidationMessage/ValidationMessage';

// hooks
import useLocations from "context/hooks/useLocations";

const RJTextInput = (props) => {
  const [isDisabled, setDisabled] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [invalidText, setInvalidText] = useState("This is a required field.");
  const [validation, setValidation] = useState(null);
  const [value, setValue] = useState(props.value);
  const [dynamicValidationKey, setDynamicValidationKey] = useState(null);
  const options = props.uiSchema["ui:options"];
  const title = options && options.title;
  const placeholder = options && options.placeholder;
  const maskingConfigs = useLocations();

  useEffect(() => {
    let { value, schema, uiSchema } = props;
    if (schema) {
      let { minLength, maxLength } = props.schema;
      if (minLength && value && value.length < minLength) {
        setInvalid(true);
      } else if (maxLength && value && value.length > maxLength) {
        setInvalid(true);
      } else {
        setInvalid(false);
      }
    }
    if (uiSchema) {
      if (uiSchema["ui:readonly"] || uiSchema["ui:disabled"]) {
        setDisabled(true)
      }
    }

    if(props.value) {
      setValue(props.value)
    } else {
      setValue("")
    }
  }, [props.value]);

  useEffect(() => {
    if(props.formContext.prsValidation) {
      if(props.formContext.prsValidation[props.id]) {
        let validation = props.formContext.prsValidation[props.id];
        setValidation(validation);
      }
    }

  }, [props.formContext.prsValidation]);

  useEffect(() => {
    if(options.required) {
      if(value && value.length > 0) {
        setInvalid(false)
        setInvalidText("")
      } else {
        setInvalid(true)
        if(options.invalidText) {
          setInvalidText(options.invalidText)
        } else {
          setInvalidText("This is a required field.")
        }
      }
    }
  }, [value])

  const onChange = (e) => {
    props.onChange(e.target.value)
  }

  useEffect(() => {
    if(maskingConfigs?.locationMaskSettings) {
      const {
        facility_mask,
        contact_mask,
        investigator_mask,
      } = maskingConfigs.locationMaskSettings;

      if(props.id.includes("facility")) {
        facility_mask ? setDisabled(true) : setDisabled(false)
      }
      if(props.id.includes("contact")) {
        contact_mask ? setDisabled(true) : setDisabled(false)
      }
      if(props.id.includes("investigator")) {
        investigator_mask ? setDisabled(true) : setDisabled(false)
      }
    }
  }, [maskingConfigs?.locationMaskSettings]);

  useEffect(() => {
    if(props.formContext && props.formContext.formValidation) {
      let { formValidation } = props.formContext;
      Object.keys(formValidation).forEach(key => {
        if(formValidation[key].required.has(props.label)) {
          setDynamicValidationKey(key)
        } else if(key === "site_id") {
          setDynamicValidationKey(key)
        }
      })
    }
  }, [props.formContext]);

  useEffect(() => {
    if(props.formContext.formData && dynamicValidationKey) {
      let { formValidation, formData } = props.formContext;
      if(dynamicValidationKey === "country") {
        if(formData && formData.facility && formData.facility.address && formData.facility.address.country) {
          if(formValidation[dynamicValidationKey].values.has(formData.facility.address.country)) {
            if(value && value.length > 0) {
              setInvalidText("");
              setInvalid(false);
            } else {
              if(options.invalidText) {
                setInvalidText(options.invalidText)
              } else {
                setInvalidText("This is a required field.")
              }
              setInvalid(true);
            }
          } else {
            setInvalidText("");
            setInvalid(false);
          }
        } 
      } else if(dynamicValidationKey === "site_id" && props.formContext.siteIDSet && props.label === "site_id") {
        if(props.formContext.siteIDSet.has(value)) {
          setInvalidText("Site ID already exists.")
          setInvalid(true);
        } else if(value && value.length > 0){
          setInvalidText("");
          setInvalid(false);
        }
      }
    }
  }, [props.formContext, dynamicValidationKey, value])

  return (
    <Container>
      <PrimeField
        id={props.id}
        schema={{
          type: "text",
          placeholder,
          required: options.required,
          containerClassName: "field"
        }}
        disabled={isDisabled}
        onChange={(e) => props.onChange(e)}
        value={props.value}/>
      {validation && <ValidationMessage value={validation.value} type={validation.type}/>}
    </Container>
  );
};

export default RJTextInput;

const Container = styled.div`
  
`