import { useContext, createContext } from 'react';
import { CubeProvider as CubejsCubeProvider} from '@cubejs-client/react'

// api
import { useCubeJSToken } from '../../api/cubejs/cubeapi';
export const CubeProvider = ({
  children
}) => {
  // const [cubejsApi, setCubejsApi] = useState(null);
  const { data: cubejsApi } = useCubeJSToken()


  return (
    <CubejsCubeProvider cubejsApi={cubejsApi}>
      {children}
    </CubejsCubeProvider>
  );
};

const CubeContext = createContext({});

const useCube = () => {
  return useContext(CubeContext);
}

export default useCube;