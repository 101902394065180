import React,{ useState, useEffect } from 'react';
import styled from 'styled-components';
import { Tooltip } from 'antd';

const SiteStatusBadge = ({
  variant = "",
  style = {},
  tooltipText = "",
  tooltipPlacement = "top",
  diagnolStyle
}) => {
  const [title, setTitle] = useState("NEW");
  const [iconClassName, setIconClassName] = useState("fal fa-times")

  useEffect(() => {
    switch(variant) {
      case "new": 
        setTitle("New");
        break;
      case "no-ctg": 
        setTitle("CTG");
        break;
      case "no-source": 
        setTitle("Source");
        break;
      case "recruitment":
        setTitle("Recruitment Status");
        setIconClassName("far fa-triangle");
        break;
      default: 
        setTitle("");
    }
  }, [variant])

  if(variant === "new") {
    return (
      <Tooltip 
        title={tooltipText}>
        <Container variant={variant} style={style}>
          <span>{title}</span>
        </Container>
      </Tooltip>
    );
  }

  if(variant === "recruitment") {
    return (
      <Tooltip 
        title={tooltipText}>
        <Container variant={variant} style={style}>
          <Icon className={iconClassName}/>
          <span>{title}</span>
        </Container>
      </Tooltip>
    );
  }

  return (
    <Tooltip 
      title={tooltipText}>
      <Container variant={variant} style={style}>
        <span>{(variant === "no-ctg" || variant === "no-source") && <DiagnolLine variant={variant} style={diagnolStyle}/>}</span>
        <span>{title}</span>
      </Container>
    </Tooltip>
  );
};

export default SiteStatusBadge;

const getColor = (variant) => {
  switch(variant) {
    case "new": return "#18669E";
    case "no-source": return "#D6542D";
    case "no-ctg": return "#D6542D";
    case "recruitment": return "#DBA51F";
    default: return "#383E47";
  }
}

const getBorderColor = (variant) => {
  switch(variant) {
    case "new": return "#18669E";
    case "no-source": return "#D6542D";
    case "no-ctg": return "#D6542D";
    case "recruitment": return "#DBA51F";
    default: return "#383E47";
  }
}

const getBackgroundColor = (variant) => {
  switch(variant) {
    case "new": return "#EAF2F5";
    case "no-source": return "#FBEEEA";
    case "no-ctg": return "#FBEEEA";
    case "recruitment": return "#FDF8E8";
    default: return "#fff";
  }
}

const Container = styled.div`
  position: relative;
  display: inline-block;
  padding: 0 5px;
  background: ${props => getBackgroundColor(props.variant)};;
  border-radius: 4px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 1px;
  color: ${props => getColor(props.variant)};
  border: 1px solid ${props => getBorderColor(props.variant)};
  white-space: nowrap;
`

const Icon = styled.i`
  color: #D7542C;
  margin-right: 2px;
` 

const InfoContainer = styled.div`
  padding: 6px;
  white-space: normal;
  text-align: left;
`

const DiagnolLine = styled.div`
  background-color: ${props => getBorderColor(props.variant)};
  height: 1px;
  width: 100%;
  position: absolute;
  transform: rotate(28deg);
  bottom: 12px;
  width: 47px;
  right: -4px;
`