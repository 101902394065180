// TODO: use src/components/files/FileThumbnail/FileThumbnail.jsx for the thumbnail
// TODO: use src/components/FileIcon/FileIcon.jsx for the icon
import React from 'react'
import moment from 'moment'

// components
import ThumbnailCard from '../ThumbnailCard/ThumbnailCard'
import PdfLinkPreview from 'components/pdfs/PdfLinkPreview/PdfLinkPreview'
import "./DocumentCard.scss"

// assets - thumbnails
import musicThumbnail from '../../assets/images/card-thumbnails/music-thumbnail.jpg'
import imageThumbnail from '../../assets/images/card-thumbnails/image-thumbnail.jpg'
import rtfThumbnail from '../../assets/images/card-thumbnails/rtf-thumbnail.jpg'
import wordThumbnail from '../../assets/images/card-thumbnails/word-doc-thumbnail.jpg'
import xmlThumbnail from '../../assets/images/card-thumbnails/xml-thumbnail.jpg'
import urlTumbnail from '../../assets/images/card-thumbnails/url-thumbnail.jpg'
import docGenericThumbnail from '../../assets/images/card-thumbnails/doc-generic-thumbnail.jpg'

// assets - icons
import jpgIcon from '../../assets/images/card-icons/jpg-icon.png'
import mp3Icon from '../../assets/images/card-icons/mp3-icon.png'
import mp4Icon from '../../assets/images/card-icons/mp4-icon.png'
import pdfIcon from '../../assets/images/card-icons/pdf-icon.png'
import pngIcon from '../../assets/images/card-icons/png-icon.png'
import rtfIcon from '../../assets/images/card-icons/rtf-icon.png'
import wordIcon from '../../assets/images/card-icons/word-doc-icon.png'
import xmlIcon from '../../assets/images/card-icons/xml-icon.png'
import urlIcon from '../../assets/images/card-icons/url-icon.png'
import docGenericIcon from '../../assets/images/card-icons/doc-generic-icon.png'

// utils
import convertFileSize from '../../utilities/convertFileSize'

const DocumentCard = ({
  document,
  onOpen, // click on header, including title
  onOpenPreview, // click on preview or icon
  isComplete,
}) => {
  const {
    name,
    document_type_name,
    document_link,
    document_file,
  } = document

  const getDocIcon = () => {
    if (document_file) {
      if (document_file.toLowerCase().includes('.jpg') || document_file?.toLowerCase().includes('.jepg')) {
        return jpgIcon
      } else if (document_file.toLowerCase()?.includes('.mp3')) {
        return mp3Icon
      } else if (document_file.toLowerCase()?.includes('.mp4')) {
        return mp4Icon
      } else if (document_file.toLowerCase()?.includes('.pdf')) { // includes PDF/A files
        return pdfIcon
      } else if (document_file.toLowerCase()?.includes('.png')) {
        return pngIcon
      } else if (document_file.toLowerCase()?.includes('.rtf')) {
        return rtfIcon
      } else if (document_file.toLowerCase()?.includes('.doc') || document_file.toLowerCase()?.includes('.docx')) {
        return wordIcon
      } else if (document_file.toLowerCase()?.includes('.xml')) {
        return xmlIcon
      }
    }
    // url "document"
    else if (!document_file && document_link) {
      return urlIcon
    }
    // other file formats
    return docGenericIcon
  }

  const getPreview = () => {
    const getImgEl = (thumbnailImg) => {
      return <img src={thumbnailImg} className="thumbnail-card" alt="document icon" />
    }

    if (document_file?.toLowerCase().includes(".pdf")) {
      return <PdfLinkPreview
        divId={`doc-${document.id}`}
        url={document_file}
        fileName={document_file}
      />
    }
    else if (document_file?.toLowerCase().includes(".mp3") || document_file?.toLowerCase().includes(".mp4")) {
      return getImgEl(musicThumbnail)
    }
    else if (document_file?.toLowerCase().includes(".jpg") || document_file?.toLowerCase().includes(".jpeg") || document_file?.toLowerCase().includes(".png")) {
      return getImgEl(imageThumbnail)
    }
    else if (document_file?.toLowerCase().includes(".rtf")) {
      return getImgEl(rtfThumbnail)
    }
    else if (document_file?.toLowerCase().includes(".doc") || document_file?.toLowerCase().includes(".docx")) {
      return getImgEl(wordThumbnail)
    }
    else if (document_file?.toLowerCase().includes(".xml")) {
      return getImgEl(xmlThumbnail)
    }
    // url link
    else if (!document_file && document_link) {
      return getImgEl(urlTumbnail)
    }
    // all other file formats
    else {
      return getImgEl(docGenericThumbnail)
    }
  }

  const getDetailSchema = () => {
    const rows = [
      {
        key: "last_modified",
        title: "Last Modified",
        type: "date",
      },
      {
        key: "last_modified_by_fullname",
        title: "Modified By"
      },
      {
        key: "file_size",
        title: "Size"
      },
    ]

    return rows.map((row) => {
      let detail = { ...row }
      if (detail.key === 'last_modified') {
        detail.value = moment(document?.[row.key]).format('D-MMM-YYYY')
      } else if (detail.key === 'file_size') {
        if (document?.[row.key]) {
          detail.value = convertFileSize(Number(document?.[row.key]), true)
        }
      } else {
        detail.value = document?.[row.key]
      }
      return detail
    })
  }

  const getHref = () => {
    // remove a previous view param from URL
    let noViewParamHref = window.location.href.split('&view=')[0]
    return `${noViewParamHref}&view=doc-${document.id}`
  }

  return <ThumbnailCard
    title={name}
    subTitle={document_type_name}
    icon={getDocIcon()}
    preview={getPreview()}
    detailSchema={getDetailSchema()}
    onOpen={() => onOpen(document)}
    onOpenPreview={() => onOpenPreview(document)}
    previewHref={getHref()}
    isComplete={isComplete}
  />
}

export default DocumentCard;