import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const PopulationOfTrialPreview = (props) => {
  const [formData, setFormData] = useState([]);
  const [totalWorldwide, setTotalWorldwide] = useState(0);
  const [totalEEA, setTotalEEA] = useState(0);

  let {
    formContext
  } = props;

  let schema = {
    headers: [
      {
        key: "country_name",
        title: "Trial Country"
      },
      {
        key: "subjects",
        title: "Actual number of subjects enrolled"
      }
    ],
    footers: [
      {
        key: "worldwide",
        title: "Total: worldwide"
      },
      {
        key: "eea-only",
        title: "Total: EEA only"
      }
    ]
  }

  let eeaSet = new Set([
    "Belgium",
    "Bulgaria",
    "Czechia",
    "Czech Republic",
    "Denmark",
    "Cyprus",
    "Latvia",
    "Lithuania",
    "Luxembourg",
    "Spain",
    "France",
    "Croatia",
    "Italy",
    "Poland",
    "Portugal",
    "Romania",
    "Slovenia",
    "Hungary",
    "Malta",
    "Netherlands",
    "Austria",
    "Iceland",
    "Liechtenstein",
    "Norway",
    "Slovakia",
    "Finland",
    "Sweden",
    "Germany",
    "Estonia",
    "Ireland",
    "Greece",
    "United Kingdom"
  ])

  useEffect(() => {
    if (formContext?.formData?.results?.trial_info?.population?.country_subject_counts) {
      setFormData(formContext.formData.results.trial_info.population.country_subject_counts)
    }
  }, [])

  // auto calculate totals
  useEffect(() => {
    if (formData?.length > 0) {
      let totalWorldwide = 0;
      let totalEEA = 0;

      formData.forEach(country => {
        totalWorldwide += Number(country.subjects);
        if (eeaSet.has(country.country_name)) {
          totalEEA += Number(country.subjects);
        }
      })

      setTotalWorldwide(totalWorldwide);
      setTotalEEA(totalEEA);
    }
  }, [formData])

  const onAddCountry = () => {
    let newCountry = {
      country_name: "",
      subjects: "",
    }

    let tempData = [...formData, newCountry];
    setFormData(tempData);
  }

  const displayRows = () => {
    return formData.map((country, i) => {
      return (
        <TR key={`trial-country-${i}`}>
          <TD>{country.country_name}</TD>
          <TD>{country.subjects}</TD>
        </TR>
      )
    })
  }

  return (
    <Container>
      <Table>
        <thead>
          {schema.headers.map((header, i) => {
            let minWidth = 200;
            return (
              <TH
                key={`age-range-${header.key}`}
                style={{
                  minWidth: i === 0 ? 400 : minWidth
                }}>
                {header.title}
              </TH>
            )
          })}
        </thead>
        <tbody>
          {displayRows()}
          {schema.footers.map((footer, i) => {
            return (
              <TR>
                <TD>
                  {footer.title}
                </TD>
                <TD>
                  {footer.key === "worldwide" && totalWorldwide}
                  {footer.key === "eea-only" && totalEEA}
                </TD>
              </TR>
            )
          })}
        </tbody>
      </Table>
    </Container>
  );
};

export default PopulationOfTrialPreview;

const Container = styled.div`
  margin-top: 16px;
`

const Table = styled.table`

`

const TH = styled.th`
  border: 1px solid #8d8d8d;
  font-weight: bold;
  font-size: 14px;
  padding: 10px;  
  background-color: #F7FBFE;
`

const TR = styled.tr`
  position: relative;
`

const TD = styled.td`
  position: relative;
  border: 1px solid #8d8d8d;
  padding: 10px;
  vertical-align: top;
  transition: all .4s;
  box-sizing: border-box;
`