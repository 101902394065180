import React from 'react';

const styleValidationText = (type, text, index, style = {}) => {
  if (type && text) {
    let color = "#D7542C"; // ERROR: red
    let icon = "do-not-enter";

    if (type?.toLowerCase() === "warning") {
      color = "#ECB81E"; // yellow
      icon = "exclamation-triangle";
      ;
    } else if (type?.toLowerCase() === "note") {
      color = "#18669E"; // blue
      icon = "info-circle";
    };

    return (
      <div
        key={index}
        style={{
          display: "flex",
          gap: 8,
          color,
          whiteSpace: "normal",
          ...style
        }}
      >
        <i
          className={`fas fa-${icon}`}
          style={{
            color,
            fontSize: ".75rem"
          }}
          color={color}
        />
        <p style={{ margin: 0, fontSize: 12 }}>
          {text}
        </p>
      </div>
    )
  };
  return null;
};

/**
 * 
 * @param {Object} registryValidations hash table
 * @param {String} jpath field jpath
 * @returns void
 */
const registryValidMsgs = (registryValidations, jpath) => {
  if (registryValidations?.[jpath]) {
    let msgs = [];
    registryValidations[jpath].forEach((msg) => {
      const validMessage = styleValidationText(msg.type, msg.text, msg.text);
      if (validMessage) msgs.push(validMessage);
    });

    return (
      <div style={{ marginTop: 4 }}>
        {msgs}
      </div>
    );
  }
  return null;
}
/**
 * 
 * @param {Object} registryValidations hash table
 * @param {String} table table type
 * @returns void
 */
const getTableValidations = (registryValidations, type) => {
  if (registryValidations) {
    let list = []
    Object.keys(registryValidations).forEach(key => {
      if (key.includes(type)) {
        list = [...list, ...registryValidations[key]]
      }
    })
    return list
  }
  return null;
}

const formatTableValidations = (errors) => {
  let participants = {};
  let outcome = {};
  let baseline = {};
  let adverse = {};
  let arms = {}
  let interventions = {}
  let crossref = {}

  const addErrorToMap = (obj, key, val) => {
    if (obj[key]) obj[key].push(val)
    else obj[key] = [val]

    return obj
  }

  errors.forEach(error => {
    if (error.field_key.includes("results_participant_flow_participant_flow_table")) {
      participants = addErrorToMap(participants, error.field_key, error)
    } else if (error.field_key.includes("results_baseline_characteristics_baseline_characteristics_table")) {
      baseline = addErrorToMap(baseline, error.field_key, error)
    } else if (error.field_key.includes("results_outcome_measures_outcome_measures_table")) {
      outcome = addErrorToMap(outcome, error.field_key, error)
    } else if (error.field_key.includes("results_adverse_events_adverse_events_table")) {
      adverse = addErrorToMap(adverse, error.field_key, error)
    } else if (error.field_key.includes("protocol_study_arms_arms")) {
      arms = addErrorToMap(arms, error.field_key, error)
    } else if (error.field_key.includes("protocol_study_arms_interventions")) {
      interventions = addErrorToMap(interventions, error.field_key, error)
    } else if (error.field_key.includes("protocol_study_arms_crossref")) {
      crossref = addErrorToMap(crossref, error.field_key, error)
    }
  })

  return {
    participants,
    outcome,
    baseline,
    adverse,
    arms,
    interventions,
    crossref
  };
}

export {
  styleValidationText,
  registryValidMsgs,
  getTableValidations,
  formatTableValidations
}
