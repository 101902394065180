import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'

// components
import Modal from 'components/Modal/Modal';
import State from './State/State';
import PrimeField from 'components/PrimeField/PrimeField';
import { Button } from 'components-design-system';
import { Tooltip } from 'antd'

// context
import useWorkflowTracker from 'context/hooks/useWorkflowTracker';
import useTeam from 'utilities/useTeam'

// apis
import { usePutWorkflowTracker } from 'api/hooks/workflows/useWorkflowsApi';
import { useUserSettings } from 'api/hooks';

// styles
import './EditWorkflowModal.scss'

const EditWorkflowModal = ({
  data = {},
  type,
  team
}) => {
  const {
    workflowId,
    workflowModel,
    openEditStatesModal,
    setOpenEditStatesPanel,
    workflowTrackerData,
    isOnHold,
    invalidateParentQueries
  } = useWorkflowTracker()

  // const team = []

  const { studyId } = useParams()
  const { data: userSettings } = useUserSettings()
  const putWorkflowTracker = usePutWorkflowTracker({ workflowId: Number(workflowId), workflowModel })
  const { teamUsersToStr } = useTeam()

  const [dueDateType, setDueDateType] = useState()
  const [dueDateValue, setDueDateValue] = useState()
  const [manualDueDateValue, setManualDueDateValue] = useState()
  const [stateData, setStateData] = useState([])
  const [assessmentDateItems, setAssessmentDateItems] = useState([])
  const [isOutOfScope, setIsOutOfScope] = useState(false)
  const [isOutOfScopeAssessmentName, setIsOutOfScopeAssessmentName] = useState('')
  const [selectedAssessment, setSelectedAssessment] = useState()

  const userItems = useMemo(() => {
    return teamUsersToStr(team)
  }, [team])

  useEffect(() => {
    if (workflowTrackerData?.state_data?.tracker_data) {
      init()
    }
  }, [workflowTrackerData?.state_data?.tracker_data])

  useEffect(() => {
    if(workflowModel === "request" && workflowTrackerData?.parent_data?.request_due_date) {
      setDueDateType('manual')
      setDueDateValue({ value: "manual-due-date", label: "Manual due date" })
      setManualDueDateValue(dayjs(workflowTrackerData?.parent_data?.request_due_date).format("YYYY-MM-DD"))
    }
  }, [workflowModel, workflowTrackerData?.parent_data])

  const init = () => {
    let assessmentDateItems = []
    if (workflowTrackerData?.parent_data?.attached_assessments) {
      assessmentDateItems = workflowTrackerData.parent_data.attached_assessments.map(assessment => {
        let dueDate = assessment.assessment_due_date
        if (!dueDate) dueDate = "N/A"
        else dueDate = dayjs(dueDate).format(userSettings?.date_format)

        return {
          value: assessment.assessment_pk,
          label: assessment.assessment_name + " Due Date - " + dueDate
        }
      })

      validateAssessmentDecision(workflowTrackerData?.parent_data?.attached_assessments, workflowTrackerData?.parent_data?.linked_assessment_dict?.assessment_pk)
    }

    setStateData(workflowTrackerData?.state_data?.tracker_data.slice(1))
    setAssessmentDateItems(assessmentDateItems)
  }

  /*** 
  this function validates linked assessment objects/manual dates and handles error message if assessment is out of scope
***/
  const validateAssessmentDecision = (assessments, selectedAssessmentUniqid) => {
    if (workflowModel === "activity") {
      const foundAssessment = assessments && assessments.find(assessment => assessment.assessment_pk === selectedAssessmentUniqid)

      if (foundAssessment) {
        let dueDate = foundAssessment.assessment_due_date
        if (!dueDate) dueDate = "N/A"
        else dueDate = dayjs(dueDate).format(userSettings?.date_format);

        const decision = foundAssessment.assessment_decision || workflowTrackerData?.parent_data?.linked_assessment_dict?.assessment_pk === selectedAssessmentUniqid

        if (decision) {
          setDueDateType('assessment')
          setIsOutOfScope(false)
          setSelectedAssessment(foundAssessment)
          setIsOutOfScopeAssessmentName('')
          setDueDateValue({
            value: foundAssessment.assessment_pk,
            label: foundAssessment.assessment_name + " Due Date - " + dueDate
          })
        } else {
          setDueDateType('manual')
          setDueDateValue({ value: "manual-due-date", label: "Manual due date" })
          setManualDueDateValue(workflowTrackerData.parent_data.activity_due_date)
          setSelectedAssessment(null)
          setIsOutOfScope(true)
          setIsOutOfScopeAssessmentName(foundAssessment.assessment_name)
        }
      } else {
        if (workflowTrackerData.parent_data?.activity_due_date) {
          setDueDateType('manual')
          setDueDateValue({ value: "manual-due-date", label: "Manual due date" })
          setManualDueDateValue(workflowTrackerData.parent_data.activity_due_date)
          setSelectedAssessment(null)
        } else {
          setDueDateType('assessment')
          setDueDateValue(null)
          setManualDueDateValue(null)
          setSelectedAssessment(null)
        }
      }
    }
  }

  useEffect(() => {
    if (workflowTrackerData?.state_data?.tracker_data) {
      init()
    }
  }, [workflowTrackerData?.state_data?.tracker_data])

  const handleChange = (key, value, index) => {
    let tempData = [...stateData]
    let currState = tempData[index]

    currState[key] = value

    const getDifferenceBetweenDays = (a, b) => {
      return dayjs(a).diff(dayjs(b), "days");
    }

    const addDays = (date, days) => {
      return dayjs(date).add(days, "day").format("YYYY-MM-DD")
    }

    if (key === "start_state_date") {
      let goalDays = getDifferenceBetweenDays(currState.end_state_date, value);
      if (goalDays <= 0) {
        goalDays = Math.abs(goalDays)
        currState.end_state_date = addDays(value, goalDays);

        for (let i = index + 1; i < tempData.length; i++) {
          let nextState = tempData[i];
          if (nextState.state_title === "On Hold") break
          nextState.start_state_date = tempData[i - 1].end_state_date;
          nextState.end_state_date = addDays(nextState.start_state_date, nextState.goal_days);
        }
      }
      currState.goal_days = goalDays;

    } else if (key === "end_state_date") {
      let goalDays = getDifferenceBetweenDays(value, currState.start_state_date);
      currState.goal_days = goalDays;

      if (goalDays <= 0) {
        goalDays = 0;
        currState.start_state_date = value
        currState.goal_days = goalDays;
        for (let i = index - 1; i >= 0; i--) {
          let prevState = tempData[i];
          prevState.end_state_date = tempData[i + 1].start_state_date;
          prevState.start_state_date = addDays(tempData[i + 1].start_state_date, (prevState.goal_days * -1));
        }

      } else {
        for (let i = index + 1; i < tempData.length; i++) {
          let nextState = tempData[i];
          if (nextState.state_title === "On Hold") break
          nextState.start_state_date = value;
          nextState.end_state_date = addDays(value, nextState.goal_days);
        }
      }
    } else if (key === "goal_days") {
      currState.end_state_date = addDays(currState.start_state_date, value)

      for (let i = index + 1; i < tempData.length; i++) {
        let nextState = tempData[i];
        if (nextState.state_title === "On Hold") break

        nextState.start_state_date = tempData[i - 1].end_state_date;
        nextState.end_state_date = addDays(nextState.start_state_date, nextState.goal_days);
      }

    }

    setStateData(tempData)
  }

  const onDueDateChange = (type, evt) => {
    if (type === "manual") {
      const md = dayjs(evt).toDate()
      setDueDateType('manual')
      setManualDueDateValue(dayjs(md).format("YYYY-MM-DD"))
      setIsOutOfScope(false)
    } else if (type === "assessment" && evt === "manual-due-date") {
      setDueDateType('manual')
      setDueDateValue({ value: "manual-due-date", label: "Manual due date" })
      setManualDueDateValue(null)
      setIsOutOfScope(false)
    } else {
      validateAssessmentDecision(workflowTrackerData.parent_data.attached_assessments, evt)
    }
  }

  const formatPostRequest = () => {
    let activityDueDate = null
    let assessmentPk = null
    if (dueDateType === 'manual') {
      activityDueDate = manualDueDateValue
    } else if (
      selectedAssessment?.assessment_pk &&
      selectedAssessment?.assessment_due_date
    ) {
      assessmentPk = selectedAssessment.assessment_pk
      activityDueDate = dayjs(selectedAssessment.assessment_due_date).format("YYYY-MM-DD");
    }

    let projectedCompletionDate = stateData[stateData.length - 1].end_state_date
    const lastState = stateData[stateData.length - 1]
    if (!lastState.end_state_date) {
      projectedCompletionDate = dayjs(lastState.start_state_date).add(lastState.goal_days, "day").format("YYYY-MM-DD")
    }

    let actionData = stateData.map((state, i) => {
      let currState = { ...state }
      const startDate = state?.start_state_date;
      const endDate = stateData?.[i + 1]?.start_state_date
      const goalDays = dayjs(endDate).diff(dayjs(startDate), "days")

      return {
        ...currState,
        is_first_state: i === 0,
        is_last_state: i === stateData.length - 1,
        assign_to_ids: currState.assigned_to_ids || [],
        start_state_date: dayjs(startDate).format("YYYY-MM-DD"),
        end_state_date: dayjs(endDate).format("YYYY-MM-DD"),
        goal_days: goalDays || 0
      }
    })

    let req = {
      model: workflowModel,
      projected_completion_date: projectedCompletionDate,
      action_data: actionData
    }

    if (activityDueDate) req.due_date = activityDueDate
    if (assessmentPk) req.assessment_id = assessmentPk
    if (workflowTrackerData?.parent_data?.actual_completion_date) req.actual_completion_date = workflowTrackerData.parent_data.actual_completion_date

    return req
  }

  const onSaveChanges = () => {
    let req = formatPostRequest()
    putWorkflowTracker({
      body: req
    }, {
      onSuccess: (resp) => {
        invalidateParentQueries()
        setOpenEditStatesPanel(false)
        if (type === "study") {
          window.open(`/study/${studyId}/activity/${data.activity_id}`, '_blank')
        }
      }
    })
  }

  return (
    <Modal
      title={"Edit States and Due Date"}
      message={`Adjust duration, start/end dates, and assignees. Edit one state at a time.`}
      open={openEditStatesModal}
      onCancel={() => setOpenEditStatesPanel(false)}
      okText="Save Changes"
      onOk={onSaveChanges}
      hideImage={true}
      width={1120}
    >
      <div className='edit-workflow-content'>
        <div className="states-container">
          {stateData.map((state, index) => {
            return (
              <State
                key={"state-" + index}
                index={index}
                isOnHold={isOnHold}
                holdStateIndex={stateData.findIndex(state => state.state_title === "On Hold" && state.is_current)}
                handleChange={handleChange}
                stateData={stateData}
                isLast={stateData.length - 1 === index}
                state={state}
                userItems={userItems}
                type={type} />
            )
          })
          }
        </div>

        <div className="workflow-dates">
          <div className="title">{workflowTrackerData?.parent_data?.actual_completion_date ? "Actual Completion Date" : "Projected Completion Date"}</div>
          <p className="subtitle">Determined by the end date of the last state</p>
          <p className="date">
            {workflowTrackerData?.parent_data?.actual_completion_date
              ? dayjs(workflowTrackerData.parent_data.actual_completion_date).format(userSettings?.date_format)
              : dayjs(stateData?.[stateData?.length - 1]?.start_state_date).format(userSettings?.date_format)
            }
          </p>
          <div className="title">Edit Due Date
            <Tooltip
              title={<div style={{ textAlign: 'left' }}>
                The date selected as the due date determines the overall status of an {type === "request" ? "request" : "activity"}
              </div>}
            >
              <i
                style={{ marginLeft: 7 }}
                className="fal fa-info-circle" />
            </Tooltip>
          </div>
          {workflowModel === "activity" && <p className="subtitle">Select which date to use as the due date. By choosing an assessment due date, any changes to that assessment result will update this activity’s due date. If a manual due date is entered, this due date will not be linked to any assessment due date.</p>}
        </div>

        <div className="workflow-due-dates">
          <PrimeField
            schema={{
              type: "dropdown",
              placeholder: "Select due date type",
              items: [...assessmentDateItems, { value: "manual-due-date", label: "Manual due date" }],
              label: "Due date type",
              orientation: "vertical"
            }}
            value={dueDateValue}
            onChange={(evt) => onDueDateChange("assessment", evt)}
            className="dropdowns"
          />
          {(dueDateType === 'manual') && (
            <PrimeField
              schema={{ type: "date" }}
              value={manualDueDateValue}
              onChange={(val) => setManualDueDateValue(val)}
              className="date-field"
            />
          )}
        </div>

        {isOutOfScope &&
          <div className="out-scope-msg">
            <i
              style={{ marginRight: 8 }}
              className="fas fa-info-circle" />
            The {isOutOfScopeAssessmentName} is no longer in scope so that date has been changed to a manual date
          </div>
        }
      </div>
    </Modal>
  );
};

export default EditWorkflowModal;