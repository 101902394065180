import React, {useState, useEffect, memo} from 'react';
import styled from 'styled-components';
import moment from 'moment';

const Date = (props) => {
  const [date, setDate] = useState(null);
  const [format, setFormat] = useState("DD-MM-YYYY");

  useEffect(() => {
    if(props.value) {
      setDate(props.value);
    } else {
      setDate(null);
    }
  }, [props.value])

  useEffect(() => {
    if(props.user && 
      props.user.dateFormat && 
      props.user.dateFormat.date_format_moment) {
        setFormat(props.user.dateFormat.date_format_moment);
      }
  }, [props.user])

  if(!date) {
    return <Container></Container>
  }

  return (
    <Container>
      {moment(props.value, "YYYY-MM-DD").format(format)}
    </Container>
  );
};

export default memo(Date);

const Container = styled.div`

`
