import { Fragment, useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid'
import { cloneDeep } from 'lodash';
import { useShallow } from 'zustand/react/shallow'

// components
import PrimeField from 'components/PrimeField/PrimeField';
import { Button } from 'components-design-system';
import MeasureData from '../MeasureData/MeasureData';
import ResultsTableCreateModal from '../../../../components/ResultsTableCreateModal/ResultsTableCreateModal';
import StatisticalAnalysis from '../StatisticalAnalysis/StatisticalAnalysis';
import RJTableCommentContainer from '../../../components/RJTableCommentContainer/RJTableCommentContainer';
import CellValidation from '../../../components/CellValidation/CellValidation';

// context
import useResultsTableViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableViewStore';
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';
import useAuthoringCommentsStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringCommentsStore';

// utils
import { translateProps } from '../../helpers/helpers';

const Measure = ({
  measureIndex,
  measure,
  outcomeData,
  setOutcomeData,
  numOfMeasures,
  openDeleteMeasureModal,
  schema
}) => {
  const {
    readOnly,
    isReviewerView
  } = useAuthoringViewStore(
    useShallow(state => ({
      readOnly: state.readOnly,
      isReviewerView: state.isReviewerView
    }))
  )

  const [showData, setShowData] = useState(false || isReviewerView || readOnly);
  const [showUnitsAnalyzed, setShowUnitsAnalyzed] = useState(true);
  const [showAddRowModal, setShowAddRowModal] = useState(false);
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  const [disableFields, setDisableFields] = useState(false);

  const {
    tableView,
  } = useResultsTableViewStore()

  useEffect(() => {
    if (tableView === "ctg" && outcomeData?.ctg?.outcome_measures?.findIndex(om => om.id === measure.id) !== -1) {
      checkCTGOverallNumberParticipantsAnalyzedForZeros()
    }
  }, [outcomeData, tableView])

  useEffect(() => {
    if (measure?.arms && measure.arms.length > 0 || measure.type_units_analyzed) {
      let showUnitsAnalyzed = measure.arms.some(arm => arm?.num_units_analyzed !== undefined && arm?.num_units_analyzed !== null) || measure?.type_units_analyzed;
      setShowUnitsAnalyzed(showUnitsAnalyzed)
    } else {
      setShowUnitsAnalyzed(false)
    }
  }, [])

  const checkCTGOverallNumberParticipantsAnalyzedForZeros = () => {
    if (measure?.arms && measure.arms.length > 0) {
      const allZeros = measure.arms.every(arm => arm && arm.subjects_analyzed == "0");

      let registries = ["ctg"]
      if (allZeros) {
        setDisableFields(true)
        if (measure.unit_of_measure !== "") handleChange("", "unit_of_measure", registries)
        if (measure.dispersion_type !== "") handleChange("", "dispersion_type", registries)
        if (measure.parameter_type !== "") handleChange("", "parameter_type", registries)
      } else if (disableFields === true) {
        setDisableFields(false)
      }
    }
  }

  const getColSpan = () => {
    if (measure?.arms?.length > 0 || measure?.subject_analysis_sets?.length > 0) {
      return 2
    }
    return 1
  }

  const handleUp = () => {
    let tempData = { ...outcomeData }
    Object.keys(tempData).forEach(registry => {
      let foundOMIndex = outcomeData[registry].outcome_measures.findIndex(item => item.id === measure.id);
      if (foundOMIndex !== -1 && foundOMIndex !== 0) {
        let tempKeyData = [...tempData[registry]["outcome_measures"]]
        let saved = tempKeyData[foundOMIndex - 1];
        tempKeyData[foundOMIndex - 1] = tempKeyData[foundOMIndex];
        tempKeyData[foundOMIndex] = saved;
        tempData[registry]["outcome_measures"] = [...tempKeyData];
      }
    })

    setOutcomeData({ ...tempData });
  }

  const handleDown = () => {
    let tempData = { ...outcomeData }
    Object.keys(tempData).forEach(registry => {
      let foundOMIndex = outcomeData[registry].outcome_measures.findIndex(item => item.id === measure.id);
      if (foundOMIndex !== -1 && foundOMIndex !== tempData[registry].outcome_measures.length - 1) {
        let tempKeyData = [...tempData[registry]["outcome_measures"]]
        let saved = tempKeyData[foundOMIndex + 1];
        tempKeyData[foundOMIndex + 1] = tempKeyData[foundOMIndex];
        tempKeyData[foundOMIndex] = saved;
        tempData[registry]["outcome_measures"] = [...tempKeyData];
      }
    })

    setOutcomeData({ ...tempData });
  }

  const handleNAValues = (tempData, registry, dispersionType) => {
    if (registry === "eudract") {
      if (dispersionType === "Inter-Quartile Range" || dispersionType === "Full Range" || dispersionType?.toLowerCase() === "confidence interval"
        || dispersionType === "inter-quartile range (Q1-Q3)" || dispersionType === "full-range (min-max)") {
        let foundIndex = tempData[registry].outcome_measures.findIndex((item) => item.id === measure.id);

        if (foundIndex !== -1) {
          tempData[registry].outcome_measures[foundIndex]?.measure_rows?.forEach(row => {
            row?.categories?.forEach(cat => {
              cat?.values?.forEach(value => {
                if (value?.dispersion_value === 99999) value.dispersion_value = -99999;
              })
            })
          })
        }
      } else {
        let foundIndex = tempData[registry].outcome_measures.findIndex((item) => item.id === measure.id);

        if (foundIndex !== -1) {
          tempData[registry].outcome_measures[foundIndex]?.measure_rows?.forEach(row => {
            row?.categories?.forEach(cat => {
              cat?.values?.forEach(value => {
                if (value?.dispersion_value === -99999) value.dispersion_value = 99999;
              })
            })
          })
        }
      }
    }
  }

  const handleChange = (e, key, registries = ["ctg", "eudract"]) => {
    let tempData = { ...outcomeData };
    registries.forEach(registry => {
      let foundIndex = tempData[registry].outcome_measures.findIndex((item) => item.id === measure.id);

      if (foundIndex !== -1) {
        if (key === "countable") {
          if (registry === "eudract") {
            if (e === "Countable") {
              tempData[registry].outcome_measures[foundIndex]["parameter_type"] = null;
              tempData[registry].outcome_measures[foundIndex]["dispersion_type"] = null;
              handleNAValues(tempData, registry, null)
            } else {
              tempData[registry].outcome_measures[foundIndex]["parameter_type"] = "number";
              tempData[registry].outcome_measures[foundIndex]["dispersion_type"] = "not applicable";
              handleNAValues(tempData, registry, "not applicable")
            }
            tempData[registry].outcome_measures[foundIndex][key] = e;
          } else {
            if (e === "Countable") {
              tempData[registry].outcome_measures[foundIndex]["parameter_type"] = "Count of Participants";
              tempData[registry].outcome_measures[foundIndex]["dispersion_type"] = "Not Applicable";
              handleNAValues(tempData, registry, "Not Applicable")
            } else tempData[registry].outcome_measures[foundIndex]["parameter_type"] = "Number";
          }
        } else if (key === "parameter_type") {
          if (e === "Count of Participants" || e === "Count of Units") {
            if (registry === "eudract") {
              tempData[registry].outcome_measures[foundIndex]["parameter_type"] = null;
              tempData[registry].outcome_measures[foundIndex]["dispersion_type"] = null;
              tempData[registry].outcome_measures[foundIndex]["countable"] = "Countable";
              handleNAValues(tempData, registry, null)
            } else {
              tempData[registry].outcome_measures[foundIndex][key] = e;
              tempData[registry].outcome_measures[foundIndex]["dispersion_type"] = "Not Applicable";
              handleNAValues(tempData, registry, "Not Applicable")
            }
          } else if (tableView === "ctg" && e !== "Count of Participants" && e !== "Count of Units" && e.toLowerCase() !== "number") {
            if (registry === "eudract") {
              tempData[registry].outcome_measures[foundIndex]["countable"] = "Measurable";
              tempData[registry].outcome_measures[foundIndex]["parameter_type"] = translateProps[tableView][registry][key][e]
              tempData[registry].outcome_measures[foundIndex]["dispersion_type"] = "standard deviation";
              handleNAValues(tempData, registry, "standard deviation")
            } else {
              tempData[registry].outcome_measures[foundIndex][key] = e;
              tempData[registry].outcome_measures[foundIndex]["dispersion_type"] = "Standard Deviation";
              handleNAValues(tempData, registry, "Standard Deviation")
            }
          } else if (e?.toLowerCase() === "number") {
            if (tableView === "eudract") tempData[registry].outcome_measures[foundIndex][key] = "number"
            else tempData[registry].outcome_measures[foundIndex][key] = "Number"
            if (registry === "eudract") tempData[registry].outcome_measures[foundIndex]["countable"] = "Measurable";

            if (tempData[registry].outcome_measures[foundIndex]?.["dispersion_type"]?.toLowerCase() !== "not applicable" ||
              tempData[registry].outcome_measures[foundIndex]?.["dispersion_type"]?.toLowerCase() !== "confidence interval") {
              if (registry === "ctg") {
                tempData[registry].outcome_measures[foundIndex]["dispersion_type"] = "Not Applicable";
                handleNAValues(tempData, registry, "Not Applicable")
              }
              else {
                tempData[registry].outcome_measures[foundIndex]["dispersion_type"] = "not applicable";
                handleNAValues(tempData, registry, "not applicable")
              }
            }
          } else if (translateProps?.[tableView]?.[registry]?.[key]?.[e] && tableView !== registry) {
            tempData[registry].outcome_measures[foundIndex][key] = translateProps[tableView][registry][key][e];
            if (tempData[registry].outcome_measures[foundIndex]["dispersion_type"]?.toLowerCase() === "not applicable") {
              if (registry === "eudract") {
                tempData[registry].outcome_measures[foundIndex]["dispersion_type"] = "standard deviation";
                handleNAValues(tempData, registry, "standard deviation")
              }
              else {
                tempData[registry].outcome_measures[foundIndex]["dispersion_type"] = "Standard Deviation";
                handleNAValues(tempData, registry, "Standard Deviation")
              }
            }
          } else {
            tempData[registry].outcome_measures[foundIndex][key] = e;
            if (tempData[registry].outcome_measures[foundIndex]["dispersion_type"]?.toLowerCase() === "not applicable") {
              if (registry === "eudract") {
                tempData[registry].outcome_measures[foundIndex]["dispersion_type"] = "standard deviation";
                handleNAValues(tempData, registry, "standard deviation")
              }
              else {
                tempData[registry].outcome_measures[foundIndex]["dispersion_type"] = "Standard Deviation";
                handleNAValues(tempData, registry, "Standard Deviation")
              }
            }
          }
        } else if (translateProps?.[tableView]?.[registry]?.[key]?.[e] && tableView !== registry) {
          tempData[registry].outcome_measures[foundIndex][key] = translateProps[tableView][registry][key][e];
          if (key === "dispersion_type") {
            handleNAValues(tempData, registry, e)
          }
        } else if (key === "description") {
          if (tableView === "eudract") {
            if (registry === "eudract") {
              tempData["eudract"].outcome_measures[foundIndex][key] = e;
            }
          } else {
            tempData[registry].outcome_measures[foundIndex][key] = e;
          }
        } else {
          tempData[registry].outcome_measures[foundIndex][key] = e;
          if (key === "dispersion_type") {
            handleNAValues(tempData, registry, e)
          }
        }
      }
    })

    setOutcomeData(tempData);
  }

  const handleAnticipatedDate = (e, key) => {
    let value = e.toString()
    let tempData = { ...outcomeData };
    let foundIndex = tempData[tableView].outcome_measures.findIndex((item) => item.id === measure.id);

    if (foundIndex !== -1) {
      let om = cloneDeep(tempData[tableView].outcome_measures[foundIndex]);
      if (key === "year") value = value.slice(0, 4)
      om["anticipated_result_posted_date"][key] = value
      tempData[tableView].outcome_measures.splice(foundIndex, 1, om)
    }
    
    setOutcomeData(tempData);
  }

  const getField = (schema, value, key) => {
    if (schema.type !== "object") {
      let schemaCopy = { ...schema };
      let disabled = false;

      if (schema.conditionals) {
        let conditionalKey = Object.keys(schema.conditionals)[0];
        if (schema.conditionals[conditionalKey][measure[conditionalKey]] &&
          schema.conditionals[conditionalKey][measure[conditionalKey]] !== measure[conditionalKey]) {
          schemaCopy.enum = schema.conditionals[conditionalKey][measure[conditionalKey]].enum;
        }
      }

      if (measure.countable === "Countable") {
        if (key === "parameter_type" || key === "dispersion_type") disabled = true;
      }

      return (
        <PrimeField
          key={`${measure.id}-data-field-${key}`}
          schema={{
            type: schemaCopy.type,
            items: schemaCopy.enum,
            disabled
          }}
          readOnly={readOnly}
          value={value}
          onChange={(e) => handleChange(e, key)} />
      )
    } else {
      let fields = Object.keys(schema.properties).map(key => {
        let fieldSchema = schema.properties[key];

        return (
          <PrimeField
            key={`${measure.id}-data-field-${key}`}
            schema={{
              type: fieldSchema.type,
              items: fieldSchema.enum,
              placeholder: fieldSchema.placeholder,
              style: { width: 120 }
            }}
            readOnly={readOnly}
            onChange={e => handleAnticipatedDate(e, key)}
            value={value[key]} />
        )
      })

      return (
        <div className="flex" style={{ gap: 8 }}>
          {fields}
        </div>
      )
    }
  }

  const renderMeasureProps = () => {
    let omSchema = schema.properties[tableView].properties.outcome_measure.properties;
    let keys = schema.properties[tableView].properties.outcome_measure.prop_keys
    const colStyle = {
      width: 500
    }

    return keys.map(key => {
      let title = omSchema[key]?.title;
      let showPercentage = false
      if (key === "dispersion_type") {
        if (measure["dispersion_type"] === "confidence interval") showPercentage = true;
      }

      if (measure.countable === "Countable" && key === "unit_of_measure") title = "Countable Unit";

      let errorKey = `results_outcome_measures_outcome_measures_table_${tableView}_outcome_measures_${measureIndex + 1}_${key}`

      return (
        <Fragment key={`outcome-measure-${tableView}-${key}`}>
          <tr>
            <PropertyHeader
              title={title}
              measure={measure}
              fieldKey={key}
              omSchema={omSchema} />
            <td
              className='text-left'
              colSpan={getColSpan()}
              style={showData ? {} : colStyle}>
              <div style={{ width: measure.arms.length === 0 ? "476px" : "auto" }}>
                {getField(omSchema[key], measure[key], key)}
              </div>
              <CellValidation
                table="outcome"
                errorKey={errorKey} />
            </td>
          </tr>
          {showPercentage && (
            <tr key={`${tableView}-om-data-${key}`}>
              <td
                colSpan={2}
                className="header-column">
                {omSchema["ci_pct_pevel"].title}
              </td>
              <td
                colSpan={getColSpan()}>
                <PrimeField
                  key={`${measure.id}-data-field-${key}`}
                  schema={{
                    type: "number"
                  }}
                  readOnly={readOnly}
                  onChange={e => handleChange(e, "ci_pct_pevel")}
                  value={measure["ci_pct_pevel"]} />
              </td>
            </tr>
          )}
        </Fragment>
      )
    })
  }

  const renderEditDataButtonText = () => {
    if (showData) {
      if (tableView === "ctg") {
        if (readOnly) return "Hide outcome measure data"
        return "Hide outcome measure data -"
      } else {
        if (readOnly) return "Hide end point data"
        return "Hide end point data -"
      }
    } else {
      if (tableView === "ctg") {
        if (readOnly) return "Show outcome measure data"
        return "Edit outcome measure data +"
      } else {

        if (readOnly) return "Show end point data"
        return "Collect end point values +"
      }
    }
  }


  const onToggleUnitsAnalyzed = (showUnitsAnalyzed) => {
    if (showUnitsAnalyzed === false) {
      let tempData = cloneDeep(outcomeData)

      Object.keys(tempData).forEach(registry => {
        let foundIndex = tempData[registry].outcome_measures.findIndex((item) => item.id === measure.id);
        if (foundIndex !== -1) {
          let om = tempData[registry].outcome_measures[foundIndex]
          om.arms.forEach(arm => {
            arm.num_units_analyzed = undefined
          })

          om.measure_rows?.forEach(row => {
            if (row.categories?.[0]?.id === "num-analyzed") {
              row.categories[0]?.values?.forEach(value => {
                value.num_units_analyzed = undefined
              })
            }
          })

          delete om.type_units_analyzed
        }
      })

      setTimeout(() => {
        setOutcomeData(tempData)
      }, 500)
    }
    
    setShowUnitsAnalyzed(showUnitsAnalyzed)
  }

  const renderMeasureData = () => {
    if (showData) {
      return (
        <MeasureData
          measure={measure}
          outcomeData={outcomeData}
          setOutcomeData={setOutcomeData}
          schema={schema}
          showUnitsAnalyzed={showUnitsAnalyzed}
          handleChange={handleChange}
          measureIndex={measureIndex}
          disableFields={disableFields} />
      )
    }
  }

  const renderActions = () => {
    return (
      <tr className="disabled-row">
        <td
          colSpan={measure?.arms?.length > 0 ? 4 : 3}>
          {disableFields && (
            <div style={{ marginBottom: 8 }}>
              No data displayed because Outcome Measure has zero total participants analyzed.
            </div>
          )}
          <div className="footer-actions">
            <Button
              size="sm"
              onClick={() => setShowAddRowModal(true)}
              variant="primary-dashed">
              Add Row +
            </Button>
            <Button
              size="sm"
              onClick={() => setShowAddCategoryModal(true)}
              variant="primary-dashed">
              Add Category +
            </Button>
          </div>
        </td>
      </tr>
    )
  }

  const addRow = (applyAll) => {
    let tempData = { ...outcomeData };
    let rowId = `row-${uuid()}`;
    let cid = `Category-${uuid()}`;

    Object.keys(tempData).forEach(registry => {
      if (applyAll || registry === tableView) {
        let foundIndex = tempData[registry].outcome_measures.findIndex(om => om.id === measure.id);

        if (foundIndex !== -1) {
          let om = tempData[registry].outcome_measures[foundIndex];
          let measureRows = [...om.measure_rows];

          let newRow = {}
          if (measureRows[0]) newRow = cloneDeep(measureRows[0])
          else {
            newRow = {
              id: rowId,
              title: "",
              categories: schema.properties[registry].properties.rows.categories.map(cat => {
                let values = [];
                if (cat.id === "num-analyzed") {
                  if (om.arms) {
                    values = om.arms.map(item => {
                      return {
                        group_id: item.id,
                        registry_arm_id: item.registry_arm_id,
                        value: item.subjects_analyzed
                      }
                    })
                  }
                } else {
                  if (om.arms) {
                    values = om?.arms.map(item => {
                      return {
                        group_id: item.id,
                        registry_arm_id: item.registry_arm_id,
                        value: ""
                      }
                    })
                  }
                }

                if (registry === "eudract") {
                  let sasValues = [];
                  if (om?.subject_analysis_sets) {
                    sasValues = om.subject_analysis_sets.map(arm => {
                      return {
                        group_id: arm.id,
                        registry_arm_id: arm.registry_arm_id,
                        value: ""
                      }
                    })
                  }
                  values = [...values, ...sasValues]
                }

                return {
                  id: cat.id === "num-analyzed" ? "num-analyzed" : cid,
                  title: cat.title,
                  values
                }
              })
            }
          }

          newRow.id = rowId
          newRow.title = ""
          newRow.categories = newRow.categories.map(cat => {
            return {
              ...cat,
              values: cat?.values?.map(values => {
                let newValues = values;
                newValues.value = ""
                if (newValues.hasOwnProperty('num_units_analyzed')) newValues.num_units_analyzed = ""
                return newValues
              }) || []
            }
          })

          measureRows.push(newRow);
          om.measure_rows = measureRows;
        }
      }
    })

    setOutcomeData(tempData)
  }

  const addCategory = (applyAll) => {
    let tempData = { ...outcomeData };
    let catId = `Category-${uuid()}`


    Object.keys(tempData).forEach(registry => {
      if (applyAll || registry === tableView) {
        let foundIndex = tempData[registry].outcome_measures.findIndex(om => om.id === measure.id);

        if (foundIndex !== -1) {
          let om = tempData[registry].outcome_measures[foundIndex];
          let measureRows = [...om.measure_rows];

          measureRows.forEach(row => {
            let newCat = {
              id: catId,
              title: "",
              values: om.arms.map(arm => {
                return {
                  group_id: arm.id,
                  registry_arm_id: arm.registry_arm_id,
                  value: null
                };
              })
            }
            if (registry === "eudract") {
              newCat.values = [...newCat.values, ...om.subject_analysis_sets.map(set => {
                return {
                  group_id: set.id,
                  registry_arm_id: set.registry_arm_id,
                  value: null
                };
              })]
            }
            row.categories.push({ ...newCat })
          })
          om.measure_rows = measureRows;
        }
      }
    });
    setOutcomeData(tempData)
  }

  return (
    <>
      <table>
        <tbody>
          <tr>
            <td
              className="divider-row"
              colSpan={measure?.arms?.length > 0 ? 4 : 3}>
              <div className='measure-actions'>
                <div className='text-white text-bold'>
                  {measureIndex + 1}. {measure.measure_type} {tableView === "ctg" ? "outcome measure" : "end point"}
                </div>

                {readOnly !== true &&
                  <div style={{ display: "flex", gap: 16 }}>
                    <div style={{ display: "flex", gap: 8 }}>
                      {measureIndex !== numOfMeasures - 1 &&
                        <i
                          className="fal fa-arrow-down"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleDown()} />
                      }
                      {measureIndex !== 0 &&
                        <i
                          className="fal fa-arrow-up"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleUp()} />
                      }
                    </div>
                    <i
                      onClick={() => openDeleteMeasureModal(measure)}
                      className="fal fa-trash-alt text-white cursor-pointer" />
                  </div>
                }
              </div>
            </td>
          </tr>
          {renderMeasureProps()}
          <tr className="disabled-row">
            <td
              colSpan={measure?.arms?.length > 0 ? 4 : 3}>
              <div className="footer-actions">
                <Button
                  size="sm"
                  onClick={() => setShowData(!showData)}
                  variant="primary-dashed">
                  {renderEditDataButtonText()}
                </Button>
                {showData && readOnly !== true && (
                  <Button
                    size="sm"
                    onClick={() => onToggleUnitsAnalyzed(!showUnitsAnalyzed)}
                    variant="primary-dashed">
                    {showUnitsAnalyzed ? "Delete Units Analyzed -" : "Add Units Analyzed +"}
                  </Button>
                )}
              </div>
            </td>
          </tr>
          {renderMeasureData()}
          {readOnly !== true && showData && renderActions()}
          {showData && (
            <StatisticalAnalysis
              schema={schema}
              measure={measure}
              outcomeData={outcomeData}
              setOutcomeData={setOutcomeData} />
          )}
        </tbody>
        <ResultsTableCreateModal
          id={`om-${measure.id}-add-row`}
          header="Add Row"
          onSubmit={addRow}
          showModal={showAddRowModal}
          setShowModal={() => setShowAddRowModal(false)} />

        {/* add category */}
        <ResultsTableCreateModal
          id={`om-${measure.id}-add-category`}
          header="Add Category"
          onSubmit={addCategory}
          showModal={showAddCategoryModal}
          setShowModal={() => setShowAddCategoryModal(false)} />
      </table>
      <CellValidation
        table="outcome"
        errorKey={`results_outcome_measures_outcome_measures_table_${tableView}_outcome_measures_${measureIndex + 1}`} />
    </>
  );
};

export default Measure;

const PropertyHeader = ({
  title,
  measure,
  omSchema,
  fieldKey
}) => {
  const [openCommentsForm, setOpenCommentsForm] = useState(false)
  const [showActions, setShowActions] = useState(false)

  const {
    commentsMap
  } = useAuthoringCommentsStore(
    useShallow(state => ({
      commentsMap: state.commentsMap
    }))
  )

  let jpath = `OutcomeRptGroup.om-measure-prop-${measure.id}-${fieldKey}`

  const commentStyle = {
    position: "absolute",
    right: 8,
    top: 8
  }

  return (
    <td
      className="header-column"
      colSpan={2}
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}
      style={{ position: "relative" }}>
      {omSchema[fieldKey].title}
      <RJTableCommentContainer
        label={title}
        jpath={jpath}
        openCommentsForm={openCommentsForm}
        setOpenCommentsForm={setOpenCommentsForm}
        setShowMenu={setShowActions}
        showMenu={showActions || commentsMap[jpath] || openCommentsForm}
        style={commentStyle}
        modalStyle={{ top: 24 }} />
    </td>
  )
}