import { useState } from 'react';
import { useShallow } from 'zustand/react/shallow'

// components
import PrimeField from 'components/PrimeField/PrimeField';

// context
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';

const SubjectJoinReason = ({
  arm,
  reason,
  onChangeReason,
  onDeleteReason
}) => {
  const {
    readOnly
  } = useAuthoringViewStore(
    useShallow(state => ({ readOnly: state.readOnly }))
  )
  
  const [showDelete, setShowDelete] = useState(false)
  let label = "Other reason";
  if (reason.reason === "Late recruitment") label = "Late recruitment reason";

  let showReasonDetail = reason.reason === "Late recruitment" || reason.reason === "Other (please specify)"

  return (
    <div
      className="flex flex-col"
      style={{
        position: "relative",
        gap: 8,
        borderBottom: "1px solid lightgrey",
        paddingBottom: "16px",
        marginBottom: 8
      }}
      onMouseEnter={() => setShowDelete(true)}
      onMouseLeave={() => setShowDelete(false)}>
      <PrimeField
        schema={{
          type: "dropdown",
          label: "Reason",
          items: ["Late recruitment", "Transferred in from other group/arm", "Other (please specify)"],
          orientation: "vertical"
        }}
        value={reason.reason}
        onChange={(val) => onChangeReason("reason", val)} />
      {showReasonDetail && <PrimeField
        schema={{
          type: "text",
          label,
          orientation: "vertical"
        }}
        readOnly={readOnly}
        value={reason.reason_detail}
        onChange={(val) => onChangeReason("reason_detail", val)}
      />}
      <PrimeField
        schema={{
          type: "number",
          label: "Subjects",
          orientation: "vertical"
        }}
        readOnly={readOnly}
        value={reason.subjects}
        onChange={(val) => onChangeReason("subjects", val)} />
      {showDelete && <i
        onClick={onDeleteReason}
        className="fal fa-trash-alt trash-icon cursor-pointer"
        style={{
          position: "absolute",
          bottom: 22,
          right: 0
        }} />}
    </div>
  );
};

export default SubjectJoinReason;