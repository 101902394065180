// utils
import { getWidget, getEupasPreviewStyles } from '../shared/utils';
import { getPreviewStyles } from './utils/utils';
import { hideStudyDetailsFields, hideCtgProtocolFields, hideCtisProtocolFields } from './utils/utils';

import {
  objectClassNames,
  arrayClassNames
} from '../classes';

let hide = {
  "ui:options": {
    hide: true,
  }
}

export const eupasProtocolUISchema = (configs) => {
  let preview = configs?.preview
  let formData = configs?.formData

  let returnObj = {
    protocol: {
      "ui:order": ["protocol_id", "study_type", "eupas_study_type", "eudract_number", "other_study_type", "*"],
      "ui:options": {
        title: "Study Identification and Information",
        classNames: {
          objectContainer: "rjs-gap-16",
          objectTitleContainer: "rjs-h1-container pt-24 pl-24 pr-24 pb-24 m-0",
          objectTitle: "rjs-h1",
          objectInnerContainer: "no-gap section-container flex-row gap-16 flex-wrap"
        }
      },
      member_states_concerned: hideCtisProtocolFields.member_states_concerned,
      eupas_study_type: {
        "ui:options": {
          title: "Study Type",
          placeholder: "Select Study Type",
          label: false,
          required: true,
          classNames: {
            fieldContainer: "rjs-full pl-24 pr-8 pb-8",
            field: "rjs-col-1-2 pr-8"
          }
        }
      },
      eudract_number: {
        "ui:options": {
          title: "EUDRACT number, if available",
          placeholder: "(YYYY-NNNNNN-CC)",
          label: false,
          classNames: {
            fieldContainer: "rjs-full pl-24 pr-8 pb-8",
            field: "rjs-col-1-2 pr-8"
          }
        }
      },
      other_study_type: {
        "ui:options": {
          title: "Other",
          placeholder: "Please Describe",
          label: false,
          required: true,
          classNames: {
            fieldContainer: "rjs-full pl-24 pr-8 pb-8",
            field: "rjs-col-1-2 pr-8"
          }
        }
      },
      study_identification: {
        "ui:order": ["protocol_id", "acronym", "brief_title", "official_title", "secondary_ids", "is_requested_by_regulator", "country_regulator", "country_regulator_not_listed", "*"],
        "ui:options": {
          classNames: {
            objectContainer: "pl-24 pr-24 pb-13",
          }
        },
        brief_title: {
          "ui:options": {
            label: false,
            title: "Brief Description",
            placeholder: "Enter Brief Description",
            validations: [
              {
                regex: /^.{0,2000}$/,
                message: "Brief Description must have no more than 2000 characters."
              },
            ],
            classNames: {
              fieldContainer: "rjs-full",
            },
          },
          "ui:widget": getWidget({ type: "textarea", preview })
        },
        acronym: {
          "ui:options": {
            title: "Study Title Acronym",
            label: false,
            log: true,
            validations: [
              {
                regex: /^.{0,50}$/,
                message: "(max. 50 characters incl. space)",
              },
            ],
            classNames: {
              fieldContainer: "rjs-col-1-2",
            },
          }
        },
        official_title: {
          "ui:options": {
            label: false,
            required: true,
            title: "Official Title",
            placeholder: "Enter Official Title",
            validations: [
              {
                regex: /^.{0,400}$/,
                message: "Official Title must have no more than 400 characters.",
              },
            ],
            classNames: {
              fieldContainer: "rjs-full",
            },
          },
          "ui:widget": getWidget({ type: "textarea", preview })
        },
        is_requested_by_regulator: {
          "ui:options": {
            title: "Was this study requested by a regulator",
            label: false,
            required: true,
            classNames: {
              fieldContainer: "rjs-col-1-2",
            },
          },
          "ui:widget": getWidget({ type: 'radio', preview })
        },
        country_regulator: {
          "ui:options": {
            title: "Country of Regulator",
            placeholder: "Select Countries",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-2",
            },
          },
          "ui:widget": getWidget({ type: 'Lookahead', preview })
        },
        country_regulator_not_listed: {
          "ui:options": {
            title: "Other country/ies",
            placeholder: "Specify country/ies not listed",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-2",
            },
          },
        },
        is_study_required_by_risk_management_plan: {
          "ui:options": {
            title: "Is the study required by a Risk Management Plan (RMP)?",
            required: true,
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-2",
            },
          },
        },
        regulatory_procedure_number: {
          "ui:options": {
            title: "Regulatory procedure number (RMP Category 1 and 2 studies only)?",
            label: false,
            classNames: {
              fieldContainer: "rjs-full",
            },
            validations: [
              {
                regex: /^.{0,400}$/,
                message: "Maximum 400 characters.",
              },
            ]
          },
          "ui:widget": getWidget({ type: "textarea", preview })
        },
        other_study_identification_numbers: {
          "ui:options": {
            title: "Other study registration identification numbers and URLs as applicable",
            label: false,
            classNames: {
              fieldContainer: "rjs-full",
            },
            validations: [
              {
                regex: /^.{0,400}$/,
                message: "Maximum 400 characters.",
              },
            ]
          },
          "ui:widget": getWidget({ type: "CopyTextArea", preview })
        },
        ...hideCtgProtocolFields.study_identification,
        ...hideCtisProtocolFields.study_identification
      },
      protocol_information: {
        ...hide
      },
      contact_information: {
        "ui:order": [
          "investigator_contact_details",
          "collaborators",
          "is_study_conducted_by_registered_encepp",
          "investigator_center",
          "encepp_center_organization_affiliation",
          "encepp_center_website",
          "research_center_name",
          "research_center_location",
          "*",
          "is_study_collaboration_research_network",
          "networks_registered_encepp",
          "other_networks",
          "other_centers_study_conducted",
          "number_of_centers",
          "centers_registered_encepp",
          "other_centers",
          "country_study_is_conducted",
          "national_country",
          "international_countries",
        ],
        "ui:options": {
          title: "Study Sponsor, Collaborators and Contact Information",
          classNames: objectClassNames.primary
        },
        collaborators: {
          ...hide
        },
        is_study_conducted_by_registered_encepp: {
          "ui:options": {
            title: "Is this study conducted/coordinated by a centre registered in the ENCePP inventory of research centres?",
            label: false,
            required: true,
            tooltip: "If the study is conducted/coordinated by a centre registered with ENCePP, the contact details questionnaire will be pre-populated with contact details held for that centre. You may change the investigator contact details as required, but not the name of the organisation/affiliation. Note that the record for the centre in the resources database remains unaffected.",
            classNames: {
              fieldContainer: "rjs-full",
              field: "rjs-col-1-2",
            }
          },
          "ui:widget": getWidget({ type: "radio", preview })
        },
        investigator_center: {
          "ui:options": {
            title: "Center to which the investigator belongs",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-2",
            }
          },
          "ui:widget": getWidget({ type: "Lookahead", preview })
        },
        department_research_group: {
          "ui:options": {
            title: "Department/Research group",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-2",
            }
          },
        },
        encepp_center_organization_affiliation: {
          "ui:options": {
            title: "Organisation/affiliation",
            label: false,
            required: true,
            classNames: {
              fieldContainer: "rjs-col-1-2",
            }
          },
        },
        encepp_center_website: {
          "ui:options": {
            title: "Website/Homepage",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-2",
            }
          },
        },
        research_center_name: {
          "ui:options": {
            title: "Please specify name",
            required: true,
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-2",
            }
          },
        },
        research_center_location: {
          "ui:options": {
            title: "Please specify location",
            label: false,
            required: true,
            classNames: {
              fieldContainer: "rjs-col-1-2",
            }
          },
        },
        is_study_collaboration_research_network: {
          "ui:options": {
            title: "Is this study being carried out with the collaboration of a research network?",
            label: false,
            required: true,
            classNames: {
              fieldContainer: "rjs-col-1-2",
            }
          },
          "ui:widget": getWidget({ type: "radio", preview })
        },
        networks_registered_encepp: {
          "ui:options": {
            title: "Networks registered with ENCePP",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-2",
            }
          },
          "ui:widget": getWidget({ type: "Multiselect", preview })
        },
        other_networks: {
          "ui:options": {
            title: "Other networks",
            addButtonTitle: "Add Network",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-2",
            }
          },
        },
        other_centers_study_conducted: {
          "ui:options": {
            title: "Other centers where this study is being conducted",
            label: false,
            required: true,
            classNames: {
              fieldContainer: "rjs-col-1-2",
            }
          },
          "ui:widget": getWidget({ type: "radio", preview })
        },
        number_of_centers: {
          "ui:options": {
            title: "In total how many centres are involved in this Study?",
            label: false,
            required: true,
            classNames: {
              fieldContainer: "rjs-col-1-2",
            }
          },
        },
        centers_registered_encepp: {
          "ui:options": {
            title: "Centers registered with ENCePP",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-2",
            }
          },
          "ui:widget": getWidget({ type: "Multiselect", preview }),
        },
        other_centers: {
          "ui:options": {
            title: "Other Centers",
            addButtonTitle: "Add Center",
            label: false,
            addable: true,
            removable: true,
            orderable: true,
            classNames: arrayClassNames.primary
          },
          items: {
            name: {
              "ui:options": {
                label: false,
                title: "Name",
                validations: [],
                classNames: {
                  fieldContainer: "rjs-col-1-2",
                },
              },
            },
            location: {
              "ui:options": {
                label: false,
                title: "Location",
                validations: [],
                classNames: {
                  fieldContainer: "rjs-col-1-2",
                },
              },
            }
          }
        },
        country_study_is_conducted: {
          "ui:options": {
            title: "Countries in which this study is being conducted",
            label: false,
            required: true,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": getWidget({ type: "radio", preview })
        },
        national_country: {
          "ui:options": {
            title: "Please select a country",
            label: false,
            required: true,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
        },
        international_countries: {
          "ui:options": {
            title: "Please select countries",
            label: false,
            required: true,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": getWidget({ type: "Multiselect", preview }),
        },
        investigator_contact_details: {
          "ui:options": {
            title: "EU PAS Contact Information",
            classNames: {
              objectTitle: "rjs-h2",
            }
          },
          lead_investigator: {
            "ui:options": {
              title: "Details of (Primary) lead investigator",
              classNames: {
                field: "rjs-full",
                objectTitle: "rjs-h3",
              }
            },
            title: {
              "ui:options": {
                label: false,
                required: true,
                title: "Title",
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            first_name: {
              "ui:options": {
                label: false,
                required: true,
                title: "First Name",
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            last_name: {
              "ui:options": {
                label: false,
                required: true,
                title: "Last Name",
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            address_1: {
              "ui:options": {
                label: false,
                required: true,
                title: "Address Line 1",
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            address_2: {
              "ui:options": {
                label: false,
                title: "Address Line 2",
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            address_3: {
              "ui:options": {
                label: false,
                title: "Address Line 3",
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            city: {
              "ui:options": {
                label: false,
                required: true,
                title: "City",
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            zipcode: {
              "ui:options": {
                label: false,
                title: "Postcode",
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            country: {
              "ui:options": {
                label: false,
                required: true,
                title: "Country",
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            phone_number: {
              "ui:options": {
                label: false,
                required: true,
                title: "Phone number (incl. country code)",
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            phone_number_alt: {
              "ui:options": {
                label: false,
                title: "Alternative phone number",
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            fax_number: {
              "ui:options": {
                label: false,
                title: "Fax number",
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            email: {
              "ui:options": {
                label: false,
                required: true,
                title: "Email address",
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            confirm_email: {
              "ui:options": {
                label: false,
                required: true,
                title: "Confirm email address",
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            ...hideCtisProtocolFields.contact_information.investigator_contact_details.lead_investigator
          },
          scientific_contact: {
            "ui:options": {
              title: "Scientific Enquiries",
              classNames: {
                objectTitle: "rjs-h3",
              }
            },
            copy_lead_investigator: {
              "ui:options": {
                label: false,
                title: "Please tick the box if you wish the (Primary) lead investigator to be the contact person",
                classNames: {
                  field: "flex flex-row-reverse justify-start items-center gap-8",
                  fieldContainer: "rjs-full",
                }
              },
              "ui:widget": getWidget({ type: "CopyContact", preview })
            },
            title: {
              "ui:options": {
                label: false,
                required: true,
                title: "Title",
                defaultValue: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            first_name: {
              "ui:options": {
                label: false,
                required: true,
                title: "First Name",
                defaultValue: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            last_name: {
              "ui:options": {
                label: false,
                required: true,
                defaultValue: false,
                title: "Last Name",
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            address_1: {
              "ui:options": {
                label: false,
                required: true,
                defaultValue: false,
                title: "Address Line 1",
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            address_2: {
              "ui:options": {
                label: false,
                defaultValue: false,
                title: "Address Line 2",
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            address_3: {
              "ui:options": {
                label: false,
                defaultValue: false,
                title: "Address Line 3",
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            city: {
              "ui:options": {
                label: false,
                required: true,
                defaultValue: false,
                title: "City",
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            zipcode: {
              "ui:options": {
                label: false,
                defaultValue: false,
                title: "Postcode",
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            country: {
              "ui:options": {
                label: false,
                required: true,
                title: "Country",
                defaultValue: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            phone_number: {
              "ui:options": {
                label: false,
                required: true,
                defaultValue: false,
                title: "Phone number (incl. country code)",
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            phone_number_alt: {
              "ui:options": {
                label: false,
                defaultValue: false,
                title: "Alternative phone number",
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            fax_number: {
              "ui:options": {
                label: false,
                defaultValue: false,
                title: "Fax number",
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            email: {
              "ui:options": {
                label: false,
                required: true,
                defaultValue: false,
                title: "Email address",
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            confirm_email: {
              "ui:options": {
                label: false,
                defaultValue: false,
                required: true,
                title: "Confirm email address",
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            organisation_name: {
              ...hide
            },
            functional_contact_point_name: {
              ...hide
            },
          },
          public_contact: {
            "ui:options": {
              title: "Public Enquiries",
              classNames: {
                field: "rjs-full",
                objectTitle: "rjs-h3",
              }
            },
            copy_lead_investigator: {
              "ui:options": {
                label: false,
                title: "Please tick the box if you wish the (Primary) lead investigator to be the contact person",
                classNames: {
                  field: "flex flex-row-reverse justify-start items-center gap-8",
                  fieldContainer: "rjs-full",
                }
              },
              "ui:widget": getWidget({ type: "CopyContact", preview })
            },
            title: {
              "ui:options": {
                label: false,
                required: true,
                title: "Title",
                defaultValue: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            first_name: {
              "ui:options": {
                label: false,
                defaultValue: false,
                required: true,
                title: "First Name",
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            last_name: {
              "ui:options": {
                label: false,
                defaultValue: false,
                required: true,
                title: "Last Name",
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            address_1: {
              "ui:options": {
                label: false,
                defaultValue: false,
                required: true,
                title: "Address Line 1",
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            address_2: {
              "ui:options": {
                label: false,
                defaultValue: false,
                title: "Address Line 2",
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            address_3: {
              "ui:options": {
                label: false,
                defaultValue: false,
                title: "Address Line 3",
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            city: {
              "ui:options": {
                label: false,
                defaultValue: false,
                required: true,
                title: "City",
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            zipcode: {
              "ui:options": {
                label: false,
                defaultValue: false,
                title: "Postcode",
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            country: {
              "ui:options": {
                label: false,
                defaultValue: false,
                required: true,
                title: "Country",
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            phone_number: {
              "ui:options": {
                label: false,
                defaultValue: false,
                required: true,
                title: "Phone number (incl. country code)",
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            phone_number_alt: {
              "ui:options": {
                label: false,
                defaultValue: false,
                title: "Alternative phone number",
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            fax_number: {
              "ui:options": {
                label: false,
                defaultValue: false,
                title: "Fax number",
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            email: {
              "ui:options": {
                label: false,
                defaultValue: false,
                required: true,
                title: "Email address",
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            confirm_email: {
              "ui:options": {
                label: false,
                defaultValue: false,
                required: true,
                title: "Confirm email address",
                classNames: {
                  fieldContainer: "rjs-col-1-3",
                }
              }
            },
            organisation_name: {
              ...hide
            },
            functional_contact_point_name: {
              ...hide
            },
          },
        },
        scientific_advice: hideCtisProtocolFields.contact_information.scientific_advice,
        ...hideCtgProtocolFields.contact_information,
        paediatric_investigation_plan: {
          ...hide
        },
      },
      study_timeline: {
        "ui:options": {
          title: "Study Timelines and Status",
          classNames: objectClassNames.primary
        },
        signed_funding_contract_date: {
          "ui:options": {
            title: "Date when funding contract was signed",
            label: false,
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-col-1-3"
            }
          },
          planned: {
            "ui:options": {
              title: "Planned",
              label: false,
            },
            "ui:widget": getWidget({ type: "date", preview })
          },
          actual: {
            "ui:options": {
              title: "Actual",
              label: false,
            },
            "ui:widget": getWidget({ type: "date", preview })
          }
        },
        data_collection_start_date: {
          "ui:options": {
            title: "Start date of data collection",
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-col-1-3"
            }
          },
          planned: {
            "ui:options": {
              title: "Planned",
              label: false,
            },
            "ui:widget": getWidget({ type: "date", preview })
          },
          actual: {
            "ui:options": {
              title: "Actual",
              label: false,
            },
            "ui:widget": getWidget({ type: "date", preview })
          }
        },
        data_analysis_start_date: {
          "ui:options": {
            title: "Start date of data analysis",
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-col-1-3"
            }
          },
          planned: {
            "ui:options": {
              title: "Planned",
              label: false,
            },
            "ui:widget": getWidget({ type: "date", preview })
          },
          actual: {
            "ui:options": {
              title: "Actual",
              label: false,
            },
            "ui:widget": getWidget({ type: "date", preview })
          }
        },
        interim_report_date: {
          "ui:options": {
            title: "Planned of interim report, if expected",
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-col-1-3"
            }
          },
          planned: {
            "ui:options": {
              title: "Planned",
              label: false,
            },
            "ui:widget": getWidget({ type: "date", preview })
          },
          actual: {
            "ui:options": {
              title: "Actual",
              label: false,
            },
            "ui:widget": getWidget({ type: "date", preview })
          }
        },
        final_study_report_date: {
          "ui:options": {
            title: "Planned of final study report",
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-col-1-3"
            }
          },
          planned: {
            "ui:options": {
              title: "Planned",
              label: false,
            },
            "ui:widget": getWidget({ type: "date", preview })
          },
          actual: {
            "ui:options": {
              title: "Actual",
              label: false,
            },
            "ui:widget": getWidget({ type: "date", preview })
          }
        },
        funding_source: {
          "ui:options": {
            title: "Sources of funding",
            label: false,
            classNames: {
              objectTitle: "rjs-h2",
              fieldContainer: "rjs-full"
            }
          },
          pharmaceutical_companies: {
            "ui:options": {
              title: "Pharmaceutical companies",
              label: false,
              classNames: {
                objectTitle: "rjs-h3",
                fieldContainer: "rjs-full",
              }
            },
            names: {
              "ui:options": {
                title: "Name(s)",
                label: false,
              },
            },
            funding: {
              "ui:options": {
                title: "Approximate % funding",
                label: false,
              },
            }
          },
          charities: {
            "ui:options": {
              title: "Charities",
              label: false,
              classNames: {
                objectTitle: "rjs-h3",
                fieldContainer: "rjs-full",
              }
            },
            names: {
              "ui:options": {
                title: "Name(s)",
                label: false,
              },
            },
            funding: {
              "ui:options": {
                title: "Approximate % funding",
                label: false,
              },
            }
          },
          government_body: {
            "ui:options": {
              title: "Government Body",
              label: false,
              classNames: {
                objectTitle: "rjs-h3",
                fieldContainer: "rjs-full",
              }
            },
            names: {
              "ui:options": {
                title: "Name(s)",
                label: false,
              },
            },
            funding: {
              "ui:options": {
                title: "Approximate % funding",
                label: false,
              },
            }
          },
          research_councils: {
            "ui:options": {
              title: "Research council",
              label: false,
              classNames: {
                objectTitle: "rjs-h3",
                fieldContainer: "rjs-full",
              }
            },
            names: {
              "ui:options": {
                title: "Name(s)",
                label: false,
              },
            },
            funding: {
              "ui:options": {
                title: "Approximate % funding",
                label: false,
              },
            }
          },
          eu_funding_scheme: {
            "ui:options": {
              title: "EU funding scheme",
              label: false,
              classNames: {
                objectTitle: "rjs-h3",
                fieldContainer: "rjs-full",
              }
            },
            names: {
              "ui:options": {
                title: "Name(s)",
                label: false,
              },
            },
            funding: {
              "ui:options": {
                title: "Approximate % funding",
                label: false,
              },
            }
          },
          other_funding_sources: {
            "ui:options": {
              title: "Others",
              addButtonTitle: "Add Funding Source",
              label: false,
              addable: true,
              removable: true,
              orderable: true,
              classNames: arrayClassNames.primary
            },
            items: {
              names: {
                "ui:options": {
                  title: "Name(s)",
                  hideFork: true,
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-col-1-3",
                  }
                },
              },
              funding: {
                "ui:options": {
                  title: "Approximate % funding",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-col-1-3",
                  }
                },
              }
            }
          },
        },
        ...hideCtgProtocolFields.study_timeline,
      },
      study_description: {
        "ui:order": ["brief_summary", "detailed_description", "*"],
        "ui:options": {
          title: "Study Description and Conditions",
          classNames: objectClassNames.primary
        },
        brief_summary: {
          "ui:options": {
            title: "What is the main objective of the study?",
            errorMessage: "Please specify all required information.",
            label: false,
            required: true,
            classNames: {
              fieldContainer: "rjs-full"
            },
            validations: [
              {
                regex: /^.{0,400}$/,
                message: "Maximum 400 characters."
              },
            ],
          },
          "ui:widget": getWidget({ type: "textarea", preview })
        },
        has_medical_condition: {
          "ui:options": {
            title: "Has Medical Condition?",
            label: false,
            required: true,
            classNames: {
              fieldContainer: "rjs-col-1-2"
            }
          },
          "ui:widget": getWidget({ type: 'radio', preview }),
        },
        medical_conditions: {
          "ui:options": {
            title: "Medical condition(s)",
            addButtonTitle: "Add Medical Condition",
            label: false,
            required: true,
            classNames: arrayClassNames.primary
          },
          items: {
            condition: {
              "ui:options": {
                title: "Condition",
                label: false,
                classNames: {
                  fieldContainer: "rjs-full"
                }
              },
              "ui:widget": getWidget({ type: "textarea", preview })
            },
            translations: {
              "ui:options": {
                title: "Translations",
                itemTitle: "Translation",
                addButtonTitle: "Add Translation",
                label: false,
                hide: true,
                addable: true,
                removable: true,
                orderable: true,
                classNames: arrayClassNames.primary
              },
              items: {
                language: {
                  "ui:options": {
                    title: "Language",
                    label: false,
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                translation: {
                  "ui:options": {
                    title: "Translation",
                    label: false,
                    classNames: {
                      fieldContainer: "rjs-full"
                    }
                  },
                  "ui:widget": getWidget({ type: "textarea", preview })
                },
              },
            },
            is_medical_condition_rare: {
              ...hide
            },
          },
        },
        additional_medical_conditions: {
          "ui:options": {
            title: "Additional Medical Condition(s)",
            label: false,
            classNames: {
              fieldContainer: "rjs-full"
            },
            validations: [
              {
                regex: /^.{0,400}$/,
                message: "Maximum 400 characters."
              },
            ],
          },
          "ui:widget": getWidget({ type: "textarea", preview })
        },
        brief_summary_translations: {
          ...hide
        },
        secondary_objective: {
          ...hide
        },
        secondary_objective_translations: {
          ...hide
        },
        therapeutic_area: {
          ...hide
        },
        meddra: {
          ...hide
        },
        trial_scope: {
          ...hide
        },
        ctis_trial_phase: {
          ...hide
        },
        low_intervention_trial: {
          ...hide
        },
        justification_for_low_intervention_trial: {
          ...hide
        },
        principle_exlusion_criteria: {
          ...hide
        },
        principle_inclusion_criteria: {
          ...hide
        },
        justification_for_low_intervention_trial: {
          ...hide
        },
        protocol_information: {
          ...hide
        },
        scientific_advice_and_pip: {
          ...hide
        },
        sponsors: {
          ...hide
        },
        products: {
          ...hide
        },
        associated_clinical_trials: {
          ...hide
        },
        ...hideCtgProtocolFields.study_description
      },
      study_scope: {
        "ui:order": ["enrollment_number", "scope_of_study", "other_scope_description", "primary_scope", "*"],
        "ui:options": {
          title: "Study Scope and Design",
          classNames: objectClassNames.primary
        },
        enrollment_number: {
          "ui:options": {
            title: "Enter total number of subjects",
            label: false,
            required: true,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
        },
        scope_of_study: {
          "ui:options": {
            title: "What is the scope of the study?",
            label: false,
            required: true,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": getWidget({ type: "CustomCheckboxGroup", preview }),
        },
        primary_scope: {
          "ui:options": {
            title: "Primary Scope",
            label: false,
            required: true,
            classNames: {
              fieldContainer: "rjs-full"
            }
          },
          "ui:widget": getWidget({ type: 'radio', preview }),
        },
        other_scope_description: {
          "ui:options": {
            title: "Please describe other (If applicable)",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-2-3"
            }
          },
        },
        design_of_study: {
          "ui:options": {
            title: "What is the design of the study?",
            label: false,
            required: true,
            orientation: "vertical",
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": getWidget({ type: "Multiselect", preview }),
        },
        other_design_description: {
          "ui:options": {
            title: "Please describe other (If applicable)",
            label: false,
            required: true,
            classNames: {
              fieldContainer: "rjs-col-2-3"
            },
            validations: [
              {
                regex: /^.{0,400}$/,
                message: "Maximum 400 characters."
              },
            ],
          },
        },
        ...hideCtgProtocolFields.study_scope,
        ...hideCtisProtocolFields.study_scope,
      },
      study_arms: {
        "ui:options": {
          title: "Study Drug or IMP Information",
          classNames: objectClassNames.primary
        },
        has_drug_information: {
          "ui:options": {
            title: "Has Drug Information?",
            label: false,
            required: true,
            classNames: {
              fieldContainer: "rjs-full"
            }
          },
          "ui:widget": getWidget({ type: 'radio', preview }),
        },
        study_drugs: {
          "ui:options": {
            title: "Study Drugs",
            label: false,
            addable: true,
            removable: true,
            orderable: true,
            addButtonTitle: "Add Drug",
            classNames: arrayClassNames.primary
          },
          items: {
            drug_type: {
              "ui:options": {
                title: "Drug Type",
                label: false,
                required: true,
                classNames: {
                  fieldContainer: "rjs-full"
                }
              },
              "ui:widget": getWidget({ type: 'radio', preview }),
            },
            atc_code: {
              "ui:options": {
                title: "ATC Code",
                label: false,
                required: true,
                tooltip: "Search ATC codes, for valid condition values",
                tooltipUrl: "https://www.whocc.no/atc_ddd_index/",
                tooltipUrlTitle: "Search ATC codes",
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              },
            },
            substance_inn: {
              "ui:options": {
                title: "Substance INN",
                label: false,
                required: true,
                tooltip: "Search Substance INNs for valid values",
                tooltipUrl: "https://www.who.int/teams/health-product-and-policy-standards/inn/inn-lists",
                tooltipUrlTitle: "Search Substance INNs",
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              },
            },
            substance_inns: {
              "ui:options": {
                title: "Substance INN(s)",
                label: false,
                required: true,
                tooltip: "Search Substance INNs for valid values",
                tooltipUrl: "https://www.who.int/teams/health-product-and-policy-standards/inn/inn-lists",
                tooltipUrlTitle: "Search Substance INNs",
                addButtonTitle: "Add Substance INN",
                classNames: arrayClassNames.tertiary
              },
            },
            product_name: {
              "ui:options": {
                title: "Product Name",
                label: false,
                required: true,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              },
            },
            country: {
              "ui:options": {
                title: "Country",
                label: false,
                required: true,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              },
            }
          }
        },
        products: {
          ...hide
        },
        ...hideCtgProtocolFields.study_arms
      },
      study_eligibilty: {
        "ui:order": ["*", "study_carried_with_established_data_source", "sources_of_data",
          "other_data_source",
          "registered_data_sources", "unregistered_data_sources",],
        "ui:options": {
          title: "Eligibility",
          classNames: objectClassNames.primary
        },
        sex: {
          "ui:options": {
            title: "Sex:",
            placeholder: "--Select--",
            errorMessage: "Gender has not been selected.",
            label: false,
            required: true,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": getWidget({ type: 'radio', preview }),
        },
        population_under_study: {
          "ui:options": {
            title: "Population under study",
            label: false,
            classNames: {
              fieldContainer: "rjs-full"
            }
          },
          age: {
            "ui:options": {
              title: "Age",
              required: true,
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
            "ui:widget": getWidget({ type: 'Multiselect', preview }),
          },
          other_population: {
            "ui:options": {
              title: "Other population",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
          },
          ...hideCtisProtocolFields.study_eligibility.population_under_study
        },
        additional_patient_information: {
          "ui:options": {
            title: "Additional information (e.g. number of patients per treatment group)",
            label: false,
            classNames: {
              fieldContainer: "rjs-full"
            },
            validations: [
              {
                regex: /^.{0,400}$/,
                message: "Maximum 400 characters."
              },
            ],
          },
          "ui:widget": getWidget({ type: "textarea", preview })
        },
        is_patients_followed_up: {
          "ui:options": {
            title: "Will patients be followed up?",
            label: false,
            required: true,
            classNames: {
              fieldContainer: "rjs-full"
            }
          },
          "ui:widget": getWidget({ type: 'radio', preview }),
        },
        follow_up_description: {
          "ui:options": {
            title: "Please describe duration of follow up",
            label: false,
            classNames: {
              fieldContainer: "rjs-full"
            }
          },
          "ui:widget": getWidget({ type: "textarea", preview })
        },
        study_carried_with_established_data_source: {
          "ui:options": {
            title: "Is this study being carried out with an established data source?",
            label: false,
            required: true,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": getWidget({ type: 'radio', preview }),
        },
        sources_of_data: {
          "ui:options": {
            title: "Sources of data",
            label: false,
            required: true,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": getWidget({ type: "Multiselect", preview }),
        },
        registered_data_sources: {
          "ui:options": {
            title: "Data sources registered with ENCePP",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": getWidget({ type: "Multiselect", preview }),
        },
        unregistered_data_sources: {
          "ui:options": {
            title: "Data sources not registered with ENCePP",
            addButtonTitle: "Add Data Source",
            classNames: arrayClassNames.primary
          },
          items: {
            name: {
              "ui:options": {
                title: "Name",
                label: false,
                required: true,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              },
            },
            country: {
              "ui:options": {
                title: "Country",
                label: false,
                required: true,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              },
              "ui:widget": getWidget({ type: "Lookahead", preview }),
            }
          }
        },
        other_data_source: {
          "ui:options": {
            title: "Please describe",
            label: false,
            required: true,
            classNames: {
              fieldContainer: "rjs-full"
            },
            validations: [
              {
                regex: /^.{0,400}$/,
                message: "Maximum characters reached. Must have no more than 400 characters."
              },
            ],
          },
          "ui:widget": getWidget({ type: "textarea", preview })
        },
        principle_inclusion_criteria: {
          ...hide
        },
        principle_exlusion_criteria: {
          ...hide
        },
        ...hideCtgProtocolFields.study_eligibility
      },
      study_outcome_measures: {
        "ui:options": {
          title: "Study Endpoints / Outcome Measures",
          classNames: objectClassNames.primary
        },
        outcome_measures: {
          ...hide
        },
        outcome_measures_ctis: {
          ...hide
        },
        outcome_measures_eupas: {
          "ui:options": {
            title: "Outcome Measures",
            classNames: {
              fieldContainer: "rjs-full",
              objectTitle: "rjs-h2",
            }
          },
          primary_outcome_measures: {
            "ui:options": {
              classNames: {
                objectTitle: "rjs-h2",
                fieldContainer: "rjs-full",
              }
            },
            description: {
              "ui:options": {
                title: "Primary Outcome Measures",
                label: false,
                classNames: {
                  fieldContainer: "rjs-full"
                }
              },
              "ui:widget": "textarea"
            }
          },
          secondary_outcome_measures: {
            "ui:options": {
              classNames: {
                objectTitle: "rjs-h2",
                fieldContainer: "rjs-full",
              }
            },
            title: "Primary Outcome Measures",
            description: {
              "ui:options": {
                title: "Secondary Outcome Measures",
                label: false,
                classNames: {
                  fieldContainer: "rjs-full"
                }
              },
              "ui:widget": "textarea"
            }
          },
        },
        statistical_analysis_plan: {
          "ui:options": {
            title: "Data analysis plan",
            tooltip: "Please provide a brief summary of the analysis method    e.g. risk estimation, measures of risk, internal/external validity.",
            label: false,
            required: true,
            classNames: {
              fieldContainer: "rjs-full"
            },
            validations: [
              {
                regex: /^.{0,1000}$/,
                message: "Maximum 1000 characters."
              },
            ],
          },
          "ui:widget": getWidget({ type: "textarea", preview })
        }
      },
      study_reference: {
        "ui:options": {
          title: "References",
          classNames: {
            objectTitleContainer: "rjs-h1-container",
            objectTitle: "rjs-h1",
            objectContainer: "p-24"
          }
        },
        is_requesting_encepp_seal: {
          "ui:options": {
            title: "Are you requesting the ENCePP seal for this study?",
            label: false,
            tooltip: "Please note that the ENCePP seal can only be requested before the study commences. Study timelines - Actual start date of data collection",
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": getWidget({ type: 'radio', preview }),
        },
        make_full_protocol_public_when_study_ends: {
          "ui:options": {
            title: "Make protocol public now?",
            label: false,
            tooltip: "Please be aware that the uploaded protocol will be made public when the study ends (see Q.3 Study timelines: date of final study report)",
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": getWidget({ type: 'radio', preview }),
        },
        study_publications: {
          "ui:options": {
            title: "Please list the 5 most relevant publications using data from your study",
            addButtonTitle: "Add Publication",
            label: false,
            addable: true,
            removable: true,
            orderable: true,
            classNames: arrayClassNames.primary
          },
          items: {
            url: {
              "ui:options": {
                title: "Link to web-publication (if available)",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            reference: {
              "ui:options": {
                title: "Reference Link",
                label: false,
                classNames: {
                  fieldContainer: "rjs-full"
                }
              },
              "ui:widget": getWidget({ type: "textarea", preview })
            },
          }
        },
        associated_clinical_trials: {
          ...hide
        },
        ...hideCtgProtocolFields.study_reference
      },
      ...hideStudyDetailsFields(),
      study_ipd: {
        ...hide
      },
      study_oversight: {
        ...hide
      },
      sponsors: {
        ...hide
      },
      milestone_dates: {
        ...hide
      },
      study_type: {
        ...hide
      },
      ctis_form: {
        ...hide
      },
      mscs: {
        ...hide
      },
      protocol_id: {
        ...hide
      },
    }
  }

  if (preview === true) {
    getPreviewStyles(returnObj, formData);
  }

  return returnObj;
}