import { create } from 'zustand'

const initialState = {
  formData: null,
  overrideData: {},
  forkedStudyData: {
    ctg: {},
    eudract: {}
  },
  attachedDocuments: [],
}

const useAuthoringDataStore = create((set) => ({
  ...initialState,
  setFormData: (formData) => set({ formData }),
  setOverrideData: (overrideData) => set({ overrideData }),
  setForkedStudyData: (forkedStudyData) => set({ forkedStudyData }),
  setAttachedDocuments: (attachedDocuments) => set({ attachedDocuments }),
  resetAuthoringDataStore: () => set(initialState),
}))

export default useAuthoringDataStore;