import React, { useState, useEffect, useContext, createContext, useMemo } from "react"
import { useSearchParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { useShallow } from 'zustand/react/shallow'

// context
import useAuthoringStore from "containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringStore"
import useStudy from "./useStudy";
import useAuthoringViewStore from "containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore";

// apis
import {
  useGetStudyDocuments,
  usePostStudyDocument,
} from "api/hooks/studies/useDocumentsApi"
import {
  useGetDocument,
  usePutDocument,
  useDeleteDocument,
  usePostReviewerDocument,
} from "api/hooks/documents/useDocumentsApi"
import { useGlobalDocsSearch } from "api/hooks/documents/documentsSearchApis"

// utils
import jsonToFormData from "utilities/jsonToFormData";
import queryKeys from "api/utils/queryKeys";
import {
  getFileLinkError,
  getSavePayload,
  getPutData,
} from "containers/files/fileUtils"

export const DocumentProvider = ({ children }) => {
  const queryClient = useQueryClient()
  const [searchParams] = useSearchParams() // task-id
  const authoringId = useAuthoringStore(state => state.authoringId)

  const {
    isReviewerView
  } = useAuthoringViewStore(
    useShallow(state => ({
      isReviewerView: state.isReviewerView,
    }))
  )

  
  // TODO: rename documents to studyDocuments to distinguish from all documents or, even better, do not store any react-query vars in any context. We should only be storing app state in context.
  const { data: documents } = useGetStudyDocuments(authoringId)
  const [selectedDocument, setSelectedDocument] = useState(null)
  const { data: selectedDocDetails } = useGetDocument(selectedDocument?.id)
  const [documentFormData, setDocumentFormData] = useState()
  const [openPdf, setOpenPdf] = useState(false)
  const [dropFile, setDropFile] = useState(null)
  const {
    onOpenPanel,
    onClosePanel
  } = useStudy()
  const postStudyDocument = usePostStudyDocument(authoringId)
  const postReviewerDocument = usePostReviewerDocument(searchParams.get('task-id'))
  const putDocument = usePutDocument()
  const deleteDocument = useDeleteDocument()
  const globalDocsSearch = useGlobalDocsSearch()
  const [fieldsDirty, setFieldsDirty] = React.useState(false)
  const [saveIsDisabled, setSaveIsDisabled] = useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  // fields required by server but not by UI
  const getPostInitState = () => {
    let state = {
      extra_data: {},
    }
    if (isReviewerView) {
      state = {
        ...state,
        authoringstudy_db_ids: [authoringId],
      }
    }
    return state
  }

  // action = object
  const formErrorReducer = (state, errObject) => {
    let newState = { ...errObject }
    if (Object.keys(errObject).length > 0) {
      setSaveIsDisabled(true)
    } else {
      setSaveIsDisabled(false)
    }
    return newState
  }
  const [documentFormDataErrors, setDocumentFormDataErrors] = React.useReducer(formErrorReducer, {})

  // set documentFormData when selectedDocDetails changes (selectedDocDetails will populate when selectedDocument changes)
  useEffect(() => {
    if (selectedDocDetails) {
      setDocumentFormData(getPutData(selectedDocDetails))
    } else {
      setDocumentFormData(getPostInitState())
    }
    setFieldsDirty(false)
    setDocumentFormDataErrors({})
  }, [selectedDocDetails])

  // update fields

  const changeFieldValue = (field, value) => {
    setFieldsDirty(true)
    setDocumentFormData({
      ...documentFormData,
      [field]: value
    })
  }

  const changeExtraFieldValue = (field, value) => {
    setFieldsDirty(true)
    setDocumentFormData({
      ...documentFormData,
      extra_data: {
        ...documentFormData.extra_data,
        [field]: value
      }
    })
  }

  /** ERROR VALIDATION */

  const requiredFields = useMemo(() => {
    let reqFields = ['name']
    if (selectedDocument || isReviewerView) {
      // /authoring/.../documents/ api
      reqFields.push('document_type_id')
    } else {
      // /documents/... api
      reqFields.push('document_type')
    }
    return reqFields
  }, [selectedDocument, isReviewerView])

  const REQ_FIELD_ERROR = 'This field is required'

  const deleteFieldErr = (fName, errs) => {
    if (errs[fName]) {
      delete errs[fName]
    }
  }

  const getUniqueDocNameErrors = (fieldName, value, errs) => {
    return new Promise(async (resolve, reject) => {
      globalDocsSearch({
        body: { filter_val: value },
      }, {
        onSuccess: (res) => {
          if (res?.data?.results &&
            res.data.results.length > 0 &&
            !!res.data.results.find((doc) => doc.name == value && doc.name != selectedDocument?.name)) {
            errs[fieldName] = 'Give document a unique name'
          } else {
            deleteFieldErr(fieldName, errs)
          }
          resolve(errs)
        },
      })
    })
  }

  const getFieldErrors = (fieldName, value, errors) => {
    return new Promise(async (resolve, reject) => {
      let errs = { ...errors }

      if (fieldName === 'name') {
        if (!value) {
          errs[fieldName] = REQ_FIELD_ERROR
        }
        else {
          errs = await getUniqueDocNameErrors(fieldName, value, errs)
        }
      }

      if (
        fieldName === 'document_type' ||
        fieldName === 'document_type_id'
      ) {
        if (!value) {
          errs[fieldName] = REQ_FIELD_ERROR
        } else {
          deleteFieldErr(fieldName, errs)
        }
      }

      // "file": document_file & document_link
      if (
        fieldName === 'document_file' ||
        fieldName === 'document_link'
      ) {
        const fileError = getFileLinkError({
          documentFile: fieldName === 'document_file' ? value : documentFormData?.document_file,
          documentLink: fieldName === 'document_link' ? value : documentFormData?.document_link,
        })
        if (fileError) {
          errs.file = fileError
        } else if (errs?.file) {
          delete errs.file
        }
      }

      resolve(errs)
    })
  }

  // set errors for each field
  const validateFieldValue = (fieldName, value) => {
    getFieldErrors(fieldName, value, documentFormDataErrors)
      .then((errors) => {
        setDocumentFormDataErrors(errors)
        return errors
      })
  }

  const getStudyErrors = (data) => {
    return new Promise(async (resolve, reject) => {
      let errors = {}

      for (const key in data) {
        errors = await getFieldErrors(key, data[key], errors)
        validateFieldValue(key, data[key])
      }

      requiredFields.forEach((fieldName) => {
        if (!data.hasOwnProperty(fieldName) || !data?.[fieldName]) {
          errors[fieldName] = REQ_FIELD_ERROR
        }
      })

      const fileLinkErr = getFileLinkError({
        documentFile: data?.document_file,
        documentLink: data?.document_link,
      })
      if (fileLinkErr) errors.file = fileLinkErr;

      if (Object.keys(errors).length > 0) {
        reject(errors)
      } else {
        resolve(errors)
      }
    })
  }

  /** CRUD FUNCTIONS */

  const onCreateDocument = async () => {
    setIsLoading(true)
    try {
      // check for errors
      await getStudyErrors(documentFormData)
      const payload = getSavePayload({ ...documentFormData })
      const req = {
        body: jsonToFormData(payload),
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }

      if (isReviewerView) {
        postReviewerDocument((req), {
          onSuccess: () => {
            queryClient.invalidateQueries(queryKeys.documents.ctgDocuments(authoringId))
            onClosePanel()
            setDocumentFormData(getPostInitState())
            if (payload.checklist_id) {
              queryClient.invalidateQueries(queryKeys.checklists.nonChecklists(authoringId))
              queryClient.invalidateQueries(queryKeys.checklists.detail(payload.checklist_id))
            }
          },
          onSettled: () => {
            setIsLoading(false)
          },
        })
      } else {
        postStudyDocument(req, {
          onSuccess: () => {
            queryClient.invalidateQueries(queryKeys.documents.ctgDocuments(authoringId))
            onClosePanel()
            setDocumentFormData(getPostInitState())
            if (payload.checklist_id) {
              // TODO: add query key invalidation to usePostStudyDocument:
              queryClient.invalidateQueries(queryKeys.checklists.nonChecklists(authoringId))
              queryClient.invalidateQueries(queryKeys.checklists.detail(payload.checklist_id))
            }
          },
          onSettled: () => {
            setIsLoading(false)
          },
        })
      }
    } catch (errors) {
      // if there are errors, set errors and don't submit
      setDocumentFormDataErrors(errors)
      setIsLoading(false)
    }
  }

  const onUpdateDocument = async () => {
    setIsLoading(true)
    try {
      // check for errors
      await getStudyErrors(documentFormData)
      const payload = getSavePayload({ ...documentFormData })
      const req = {
        id: `${selectedDocument.id}/`,
        body: jsonToFormData(payload)
      }

      putDocument(req, {
        onSuccess: () => {
          onClosePanel()
          queryClient.invalidateQueries(queryKeys.documents.ctgDocuments(authoringId))
          setDocumentFormData(getPutData(selectedDocDetails))
        },
        onSettled: () => {
          setIsLoading(false)
        }
      })
    } catch (errors) {
      // if there are errors, set errors and don't submit
      setDocumentFormDataErrors(errors)
      setIsLoading(false)
    }
  }

  // send argument "docId" to set document ID in mutate function as { id: "<id>/"} (versus sending ID when defining deleteDocument function)
  const onDeleteDocument = (docId) => {
    return new Promise(async (resolve, reject) => {
      const doc = { ...selectedDocument }
      setSelectedDocument(null)
      let reqBody = {}
      if (docId) reqBody = { id: `${docId}/` };
      deleteDocument(reqBody, {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKeys.documents.ctgDocuments(authoringId))
          resolve()
        },
        onError: (err) => {
          onClosePanel(doc)
          reject(err)
        },
      })
    })
  }

  // for post data
  const resetDocumentFormData = () => {
    setDocumentFormData(getPostInitState())
    setDropFile(null)
  }

  /** PDF */

  const onOpenPdf = (doc) => {
    setSelectedDocument(doc)
    setOpenPdf(true)
  }

  const onSavePdf = (file, documentData) => {
    return new Promise(async (resolve, reject) => {
      let { document, annotationManager } = documentData

      let payload = {
        ...selectedDocument,
        id: document.id,
        name: document.name,
        document_type: document.document_type,
        document_file: file,
      }

      annotationManager?.getAnnotations()
        .then(res => {
          let annotationsCount = 0
          if (res.length) annotationsCount = res.length
          payload.annotations_count = annotationsCount
          // updatePdf(file, payload)
          resolve()
        })
    })
  }

  useEffect(() => {
    if (dropFile) {
      changeFieldValue('document_file', dropFile)
    }
  }, [dropFile])

  const onDropDocument = (document_file) => {
    if (document_file) {
      onOpenPanel('document-details')
    }
    setDropFile(document_file)
  }

  return (
    <DocumentContext.Provider
      value={{
        // data from server
        documents,
        selectedDocDetails,
        selectedDocument,
        setSelectedDocument,

        // prepare data to submit
        documentFormData,
        setDocumentFormData,
        changeFieldValue,
        changeExtraFieldValue,
        dropFile,
        setDropFile,
        onDropDocument,
        validateFieldValue,
        documentFormDataErrors,
        setDocumentFormDataErrors,
        resetDocumentFormData,

        // UI
        fieldsDirty,
        saveIsDisabled,
        setSaveIsDisabled,
        isLoading,
        setIsLoading,
        // pdf-related
        openPdf,
        setOpenPdf,
        onOpenPdf,

        // submit data
        onCreateDocument,
        onUpdateDocument,
        onDeleteDocument,
        onSavePdf,
      }}>
      {children}
    </DocumentContext.Provider>
  )
}

const DocumentContext = createContext({});

const useDocuments = () => {
  return useContext(DocumentContext);
}

export default useDocuments;