import React, { useState, useEffect } from 'react';

// components
import PrimeField from 'components/PrimeField/PrimeField';
import { Button } from 'components-design-system'
import Note from 'components/Note/Note';
import { useShallow } from 'zustand/react/shallow'

// hoc
import InputWrapper from '../../hoc/InputWrapper';
import useAuthoringDataStore from '../../../hooks/stores/useAuthoringDataStore';

const RJTextArea = (props) => {
  let {
    id,
    carbonProps,
    invalid,
    invalidText,
    value,
    onChange,
    onFocus,
    onBlur,
  } = props;

  const [formValue, setFormValue] = useState("")

  const {
    formData,
    setFormData,
  } = useAuthoringDataStore(
    useShallow(state => ({
      formData: state.formData,
      setFormData: state.setFormData,
    }))
  )

  useEffect(() => {
    if(value) setFormValue(value)
  }, [])

  const copyValues = () => {
    let study_data = {
      ...formData,
    }

    let concatIds = [];

    if (study_data?.protocol?.study_identification?.secondary_ids) {
      study_data.protocol.study_identification.secondary_ids.forEach(item => {
        if (item && item.id && item.id.length > 0) {
          concatIds.push(item.id);
        }
      })
    }

    let value = concatIds.join(", ")

    study_data.protocol.study_identification.other_study_identification_numbers = value

    setFormData(study_data);
    setFormValue(value)
  }

  const handleChange = (e) => {
    setFormValue(e)
    onChange(e)
  }

  let variant

  if (invalid) {
    variant = "error"
  }

  return (
    <>
      <PrimeField
        schema={{
          id: id + "-field",
          className: "rjs-widget",
          style: carbonProps?.styles && carbonProps.styles.widget,
          rows: carbonProps.rows,
          type: "textarea",
          disabled: carbonProps.disabled,
          variant,
        }}
        value={formValue}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />

      <Button
        variant={"primary-dashed"}
        size="sm"
        style={{ marginTop: 12 }}
        onClick={() => copyValues()}>
        Copy Secondary ID's from CTG Protocol
      </Button>
      {invalid && <Note type="error" text={invalidText} style={{ marginTop: 4 }} />}
    </>
  );
};

export default InputWrapper(RJTextArea);