import useGetApi from "../useGetApi"
import useMutateApi from "../useMutateApi"

// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

// utils
import getUrl from "api/apiMap"

/** assessment-types */

/** GET requests */

const useGetAssessmentTypes = () => {
  return useGetApi(getUrl(apiEndpoints.assessmentTypes), queryKeys.assessmentTypes.all)
}

const useGetAssessmentType = (id) => {
  return useGetApi(getUrl(`${apiEndpoints.assessmentTypes}${id}/`), queryKeys.assessmentTypes.detail(id))
}

/** MUTATE requests */

// mutate: { body }
const usePostAssessmentType = () => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(apiEndpoints.assessmentTypes),
    invalidateQueries: [queryKeys.assessmentTypes.all, queryKeys.assessments.all],
    successMsg: 'Successfully created assessment type'
  })
}

// mutate({ id, body })
const usePutAssessmentType = () => {
  return useMutateApi({
    method: "put",
    apiEndpoint: getUrl(apiEndpoints.assessmentTypes),
    invalidateQueries: [queryKeys.assessmentTypes.all, queryKeys.assessments.all],
    successMsg: 'Successfully updated assessment type'
  })
}

// mutate({ body })
const usePostAssessDecisionTree = (id) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(`${apiEndpoints.assessmentTypes}${id}/decision-tree/`),
    invalidateQueries: [queryKeys.assessmentTypes.all, queryKeys.assessments.all],
    successMsg: "Successfully created new version",
  })
}

// assessment-subtypes

const useGetAssessmentSubtypes = () => {
  return useGetApi(getUrl(apiEndpoints.assessmentSubTypes), queryKeys.assessmentSubTypes)
}

export {
  useGetAssessmentTypes,
  useGetAssessmentType,
  usePostAssessmentType,
  useGetAssessmentSubtypes,
  usePostAssessDecisionTree,
  usePutAssessmentType,
}