import { useState } from 'react';
import { v4 as uuid } from 'uuid'
import { useShallow } from 'zustand/react/shallow'

// components
import { Button } from 'components-design-system'
import EventItem from '../EventItem/EventItem';
import CTGArmTotal from '../CTGArmTotal/CTGArmTotal';
import ResultsTableCreateModal from '../../../../components/ResultsTableCreateModal/ResultsTableCreateModal';

// context
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';
import useResultsTableViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableViewStore';

const EventList = ({
  title,
  eventKey,
  eventListKey,
  schema,
  adverseData,
  setAdverseData
}) => {
  const {
    tableView
  } = useResultsTableViewStore()

  const {
    readOnly
  } = useAuthoringViewStore(
    useShallow(state => ({ readOnly: state.readOnly }))
  )

  const onAddEvent = (applyAll) => {
    let tempData = { ...adverseData };
    let id = `ae-${uuid()}`;

    const populateValues = (registry, adverseEvent) => {
      if (applyAll || registry === tableView) {
        let values = adverseData[registry]?.groups.map(group => {
          let value = {
            group_id: group.id,
            registry_arm_id: group.registry_arm_id,
          };

          Object.keys(schema.properties[registry][eventKey + "_stat"].properties).forEach(key => {
            let val = null;
            if (key === "num_subjects") {
              if (registry === "ctg" && group.part_at_risk_frequent_events) val = group.part_at_risk_frequent_events
              if (registry === "eudract" && group.part_at_risk_serious_events) val = group.part_at_risk_serious_events
            }
            value[key] = val;
          })
          return value;
        })

        return values;
      }
    }

    Object.keys(tempData).forEach(registry => {
      if (applyAll || registry === tableView) {
        let adverseEvent = { id }
        Object.keys(schema.properties[registry]?.[eventKey].properties).forEach(key => {
          let value = null;
          let fieldSchema = schema.properties[registry]?.[eventKey].properties;

          if (fieldSchema[key].value) value = fieldSchema[key].value;
          else if (key === "assessment_type" || (key === "source_vocabulary" && registry === "ctg")) {
            value = adverseData[registry][key];
          } else if (key === "dictionary_version") {
            value = adverseData[registry][key];
          }
          adverseEvent[key] = value;
        })

        adverseEvent.values = populateValues(registry, adverseEvent)
        tempData[registry]?.[eventListKey].push(adverseEvent);
      }
    })

    setAdverseData(tempData)
  }

  const displayEvents = () => {
    return adverseData?.[tableView]?.[eventListKey].map((event, rowIndex) => {
      return (
        <EventItem
          key={`non_serious-adverse-event-${event.id}`}
          eventListKey={eventListKey}
          event={event}
          events={adverseData?.[tableView]?.[eventListKey]}
          rowIndex={rowIndex}
          adverseData={adverseData}
          setAdverseData={setAdverseData}
          schema={schema} />
      )
    })
  }

  const [showEvents, setShowEvents] = useState(true);
  const [showAddEventModal, setShowAddEventModal] = useState(false);

  const numOfEvents = adverseData?.[tableView]?.[eventListKey] ? adverseData[tableView]?.[eventListKey].length : 0;

  return (
    <>
      <tr className='disabled-row'>
        <td
          colSpan={adverseData?.[tableView]?.groups.length + 1}>
          <div className="flex" style={{ gap: 8 }}>
            {showEvents
              ? <i
                onClick={() => setShowEvents(false)}
                className="fal fa-minus cursor-pointer" />
              : <i
                onClick={() => setShowEvents(true)}
                className="fal fa-plus cursor-pointer" />
            }
            <div>{title + "s"} ({numOfEvents})</div>
          </div>
        </td>
      </tr>
      {tableView === "ctg" && (
        <CTGArmTotal
          type={`${eventKey}_group_total`}
          schema={schema}
          adverseData={adverseData}
          setAdverseData={setAdverseData} />
      )}
      {showEvents && displayEvents()}
      {readOnly !== true &&
        <tr className='disabled-row'>
          <td colSpan={adverseData?.[tableView]?.groups.length + 1}>
            <Button
              size="sm"
              variant={"primary-dashed"}
              onClick={() => setShowAddEventModal(true)}>
              <i
                className="fal fa-plus-circle"
                style={{
                  marginTop: 2,
                  marginRight: 8
                }} />
              Add {title.toLowerCase()}
            </Button>
          </td>
        </tr>
      }
      {/* add row */}
      <ResultsTableCreateModal
        header={`Add ${title.toLowerCase()}`}
        onSubmit={onAddEvent}
        showModal={showAddEventModal}
        setShowModal={() => setShowAddEventModal(false)} />
    </>
  );
};

export default EventList;