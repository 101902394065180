import React from 'react';
import { create } from 'zustand'

const initialState = {
  versionData: null,
  versionValues: {},
  selectedVersion: null,
}

const useAuthoringVersionStore = create((set) => ({
  ...initialState,
  setVersionData: (versionData) => set({ versionData }),
  setVersionValues: (versionValues) => set({ versionValues }),
  setSelectedVersion: (selectedVersion) => set({ selectedVersion }),
  resetAuthoringVersionStore: () => set(initialState),
}))

export default useAuthoringVersionStore;