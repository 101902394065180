import React, { useState } from 'react';
import styled from 'styled-components';

// components
import { styleValidationText } from 'utilities/registryValidations';

import './UnmappedValidationMsg.scss';

const UnmappedValidationMsg = ({
	message,
}) => {
	const [collapsed, setCollapsed] = useState(true)

	let url = message.source
	if (!message.source && message.location) {
		url = message.location
	}

	return <div
		className="unmapped-validation-item-container"
		onClick={() => setCollapsed(!collapsed)}
	>
		{styleValidationText(message.severity ? message.severity : message.type, message.message, message.id)}
		<i
			className={`fas fa-caret-${collapsed ? 'down' : 'up'}`}
		/>
		<div
			style={{
				display: collapsed ? 'none' : 'block',
				overflowWrap: "break-word"
			}}
			collapsed={collapsed}
		>{url}</div>
	</div>
}

export default UnmappedValidationMsg