import React from 'react';

// components
import { Button } from 'components-design-system'

// styles
import './empty-state-checklist.scss';

// assets
import emptyStateIcon from '../../assets/images/empty-state-checklist.png';

const EmptyStateChecklist = ({ onAddChecklist, permissions }) => {
  return (
    <div className="empty-checklist-container">
      <img 
        className="empty-checklist-icon"
        src={emptyStateIcon} 
        alt="empty-state-checklist"/>

      <div className="title-container">
        <h3>Start creating checklists</h3>
        <p>Organize efficiently the documents for your transparency needs</p>
      </div>
      {(permissions?.["checklisttemplate.create"] || permissions?.["study.checklist.create"]) && (
        <Button
          variant="primary-dashed"
          onClick={onAddChecklist}
          style={{ marginTop: 40 }}>
          <i className="fal fa-tasks" style={{ marginRight: 6 }}/> Add checklist
        </Button>
      )}
    </div>
  );
};

export default EmptyStateChecklist;