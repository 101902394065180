import React, { useRef } from 'react';
import { create } from 'zustand'

const useAuthoringPrintStore = create((set) => ({
  printCtgOnly: null,
  setPrintCtgOnly: (printCtgOnly) => set({ printCtgOnly }),
  isPrinting: false,
  setIsPrinting: (isPrinting) => set({ isPrinting }),
}))

export default useAuthoringPrintStore;