import React, { useState, useEffect } from 'react'

//components
import { Button } from 'components-design-system'
import Tabs from 'components/Tabs/Tabs';
import PdfFullWindowModal from 'containers/files/components/PdfFullWindowModal/PdfFullWindowModal';
import DocumentsGrid from './subcomponents/DocumentsGrid/DocumentsGrid'
import DocumentCard from 'components/DocumentCard/DocumentCard';
import DocumentChecklists from './subcomponents/DocumentChecklists/DocumentChecklists';
import FileUpload from 'components/files/FileUpload/FileUpload'

// context
import useStudy from 'context/hooks/useStudy';
import useDocuments from 'context/hooks/useDocuments';
import useChecklists from 'context/hooks/useChecklists';

// utils
import useFileLoader from 'utilities/useFileLoader'

// apis
import { useUserPermissions } from 'api/hooks';

import './Documents.scss'

const Documents = () => {
  const {
    openPanel,
    onOpenPanel,
    onClosePanel,
  } = useStudy()

  const {
    openPdf,
    setOpenPdf,
    documents,
    selectedDocument,
    setSelectedDocument,
    onDropDocument,
    resetDocumentFormData,
    onOpenPdf,
  } = useDocuments()

  const {
    studyChecklists,
    setSelectedChecklist,
    setSelectedOption
  } = useChecklists()

  const [isDragging, setIsDraggin] = React.useState()

  const {
    getFileLoadAreaProps,
  } = useFileLoader({
    onLoad: onDropDocument,
    onDrag: () => setIsDraggin(true),
    onStopDrag: () => setIsDraggin(false),
  })

  const { data: permissions } = useUserPermissions()

  const [viewType, setViewType] = useState("list");
  const [currView, setCurrView] = useState("documents");
  const [gridApi, setGridApi] = useState(null)

  const nav = [
    {
      id: "documents",
      title: "Documents",
      action: (tab) => setCurrView(tab.id)
    },
    {
      id: "checklists",
      title: "Checklists",
      action: (tab) => setCurrView(tab.id)
    },
  ]

  useEffect(() => {
    onClosePanel()
  }, [currView])

  useEffect(() => {
    if (openPanel === false) {
      setSelectedDocument(null)
      setSelectedChecklist(null)
      setSelectedOption(null)
      resetDocumentFormData()
    }
  }, [openPanel])

  // called from document row
  const openRowPanel = (rowData) => {
    onOpenPanel("document-details")
    setSelectedDocument(rowData)
  }

  const showAddChecklistBtn = () => {
    return (permissions?.["study.checklist.create"] && currView === "checklists" && (documents?.length !== 0 || studyChecklists?.length !== 0))
  }

  const openAddDocument = () => {
    setSelectedDocument(null)
    onOpenPanel('document-details')
  }

  const openAddChecklist = () => {
    onOpenPanel("add-checklist")
  }

  const onOpenCardPreview = (document) => {
    if (document.document_file?.toLowerCase().includes(".pdf")) {
      onOpenPdf(document)
    } else {
      const file = document.document_link || document.document_file
      return window.open(file, '_blank')
    }
  }

  let height = documents?.length * 63 + 40

  if ((documents?.length === 0 || !documents) && height) {
    height += 63
  }

  return (
    <div className="documents-container">
      <div className="header-container">
        <Tabs
          active={currView}
          tabs={nav}
        />
        <div className="action-container">
          {currView === "documents" && (
            <>
              <div className='view-type-container'>
                {viewType === "list" ? (
                  <i className="fas fa-th-large" onClick={() => setViewType("grid")}></i>
                ) : (
                  <i className="fas fa-th-list" onClick={() => setViewType("list")}></i>
                )}
              </div>
            </>
          )}

          {permissions?.["document.create"] &&
            <Button
              variant="primary-dashed"
              onClick={openAddDocument}>
              <i className="fal fa-file-plus" style={{ marginRight: 6 }} />
              Add document
            </Button>
          }

          {showAddChecklistBtn() && (
            <Button
              variant="primary-dashed"
              onClick={openAddChecklist}
              style={{ marginLeft: 12 }}>
              <i className="fal fa-tasks" style={{ marginRight: 6 }} /> Add checklist
            </Button>
          )}
        </div>
      </div>
      <div className="main-container">
        {currView === "documents" && viewType === "list" &&
          <div className="docs-content-container">
            <DocumentsGrid
              documents={documents}
              gridApi={gridApi}
              setGridApi={setGridApi}
              openRowPanel={openRowPanel}
              openPdf={() => { }}
            />
            {permissions?.["document.edit"] &&
              <div
                {...getFileLoadAreaProps({ classNames: ["file-upload-container"] })}
              >
                <FileUpload
                  onLoad={onDropDocument}
                  isDragging={isDragging}
                />
              </div>
            }
          </div>
        }
        {currView === "documents" && viewType === "grid" && (
          <div className="document-cards-container">
            {documents?.map(doc => {
              return (
                <DocumentCard
                  key={doc.id}
                  document={doc}
                  onOpen={openRowPanel}
                  onOpenPreview={onOpenCardPreview}
                />
              )
            })}
          </div>
        )}
        {currView === "checklists" && (
          <DocumentChecklists
            onDropDocument={onDropDocument}
            openRowPanel={openRowPanel}
          />
        )}
      </div>
      <PdfFullWindowModal
        open={openPdf}
        setOpen={setOpenPdf}
        file={selectedDocument}
        setFile={setSelectedDocument}
      />
    </div>
  );
};

export default Documents;