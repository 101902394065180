import { useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import IconButton from 'containers/requests/components/IconButton/IconButton'
import { Button } from 'components-design-system';

// components
import Panel from 'containers/requests/components/Panel/Panel';
import TeamModal from './TeamModal/TeamModal';
import RefreshTeamConfirmationModal from './RefreshTeamConfirmationModal/RefreshTeamConfirmationModal';

// api
import { useGetUsersList } from 'api/hooks/users/useUsersApi'
import { useRefreshActivityTeam } from 'api/hooks/activities/useActivityTeamApi';
import { useUserPermissions } from 'api/hooks';

// assets
import empty_team_icon from 'assets/images/empty_team.png'

// styles
import './Team.scss';

const Team = ({
  type = "",
  postTeam = () => { },
  team = [],
  roles = [],
  title = "",
  panelClassName = ""
}) => {
  const {
    studyId,
    activityId
  } = useParams()
  const { data: permissions } = useUserPermissions()
  const { data: users } = useGetUsersList()

  const refreshActivityTeam = useRefreshActivityTeam(studyId, activityId)

  const [selectedRole, setSelectedRole] = useState(null)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [showTeamModal, setShowTeamModal] = useState(false)

  useEffect(() => {
    if (showTeamModal === false) setSelectedRole(null);
  }, [showTeamModal])

  const sortedTeam = useMemo(() => {
    return team?.sort((a, b) =>
      a.role_name.toLowerCase() > b.role_name.toLowerCase() ? 1 : -1
    )?.filter((team) => team?.members?.length > 0)
  }, [team])

  const hasPermissions = useMemo(() => {
    switch (type) {
      case "study": return permissions?.["study.edit"];
      case "activity": return permissions?.["workflow.edit"] === true;
      case "request": return permissions?.["study.edit"] === true; // TODO: UPDATE LATER IF NEEDED
      default: return []
    }
  }, [permissions, type])

  const actions = useMemo(() => {
    let buttons = []
    if (hasPermissions) {
      if (type === "activity") {
        buttons.push(<Button
          key="refresh-team-btn"
          size="sm"
          style={{ paddingTop: 9.5, paddingBottom: 6.5 }}
          tooltip={"Any changes that were made to the team at the study level will be reflected on this activity"}
          onClick={() => setShowConfirmationModal(true)}
          variant="outlined"
        >
          <i className="fal fa-redo"></i>
        </Button>)
      }
      buttons.push(<IconButton
        key="add-team-member-btn"
        variant='primary-dashed'
        iconClassName="fal fa-user-plus"
        onClick={() => setShowTeamModal(true)}
      >
        Add team
      </IconButton>)
    }

    return buttons
  }, [hasPermissions, type])

  const onPostTeam = async (payload) => {
    return postTeam({
      body: payload
    })
  }

  const onRefreshTeam = () => {
    refreshActivityTeam({}, {
      onSuccess: () => {
        setShowConfirmationModal(false)
      }
    })
  }

  const handleOpenRole = (role) => {
    setSelectedRole(role)
    setShowTeamModal((state) => !state)
  }

  const renderEmptyTeam = (
    <div
      className="empty-team">
      <img
        className="empty_team_icon"
        src={empty_team_icon}
        alt="empty team container" />
      <p className="empty-team-label">
        Add your team members
      </p>
    </div>
  )

  const renderTeam = (
    <div className="team-roles-list-container">
      {sortedTeam.map((team) => {
        return <div
          className="team-role-container"
          key={team.role_name}
        >
          <div className="team-role-header-container">
            <div className="role-title">{team.role_name}</div>
            <i
              className="fal fa-edit"
              onClick={() => handleOpenRole(team)} />
          </div>
          <div className="role-member-list">
            {team.members
              .sort((a, b) =>
                a.person_fullname.toLowerCase() > b.person_fullname.toLowerCase() ? 1 : -1
              )
              .map((member) => (
                <p
                  key={`${member.person_id}-${team.role_id}`}
                  className="team-role-member"
                >
                  {member.person_fullname}
                </p>
              ))}
          </div>
        </div>
      })}
    </div>
  )

  return (
    <Panel
      title="MY TEAM"
      iconClassName="fal fa-user-friends"
      actions={actions}
      className={`panel-col team-panel ${panelClassName}`}
    >
      <div className="team-container">
        {team.length > 0 ? (
          renderTeam
        ) : (
          renderEmptyTeam
        )}
      </div>
      {hasPermissions && showTeamModal && (
        <TeamModal
          show={showTeamModal}
          setShow={setShowTeamModal}
          users={users}
          selectedRole={selectedRole}
          team={team}
          roles={roles}
          onPostTeam={onPostTeam}
        />
      )}
      {hasPermissions === true && type === "activity" &&
        <RefreshTeamConfirmationModal
          subheader={title}
          handleSaveChanges={onRefreshTeam}
          showModal={showConfirmationModal}
          onHide={() => setShowConfirmationModal(false)}
        />
      }
    </Panel>
  );
};

export default Team;