import useGetApi from "../useGetApi"
import useMutateApi from "../useMutateApi"

// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

// utils
import getUrl from "api/apiMap"

// ADMIN
export const useGetChecklistTemplates = () => {
  return useGetApi(getUrl(apiEndpoints.checklistTemplates), queryKeys.checklistTemplates)
}

export const usePostChecklistTemplate = () => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(apiEndpoints.checklistTemplates),
    invalidateQueries: [queryKeys.checklistTemplates],
    successMsg: "Successfully created checklist template"
  })
}

export const usePutChecklistTemplate = () => {
  return useMutateApi({
    method: "put",
    apiEndpoint: getUrl(apiEndpoints.checklistTemplates),
    invalidateQueries: [queryKeys.checklistTemplates],
    successMsg: "Successfully updated checklist template"
  })
}

export const useDeleteChecklistTemplate = () => {
  return useMutateApi({
    method: "delete",
    apiEndpoint: getUrl(apiEndpoints.checklistTemplates),
    invalidateQueries: [queryKeys.checklistTemplates],
    successMsg: "Successfully deleted checklist template"
  })
}