import React from 'react';

// styles
import './empty-document-row.scss';

// context
import useStudy from 'context/hooks/useStudy';
import useChecklists from 'context/hooks/useChecklists';
import useDocuments from 'context/hooks/useDocuments';

// utils
import useFileLoader from 'utilities/useFileLoader';

const EmptyDocumentRow = ({
  checklist,
  option,
  permissions
}) => {
  const {
    onOpenPanel
  } = useStudy()
  const {
    onDropDocument
  } = useDocuments()

  const {
    setSelectedOption,
    setSelectedChecklist
  } = useChecklists()

  let docTypeName = option?.document_type_name;

  const {
    FileBrowseLink,
  } = useFileLoader({
    onLoad: (file) => {
      onDropDocument(file)
      setSelectedOption(option)
      setSelectedChecklist(checklist)
      onOpenPanel("document-details")
    }
  })

  if (option?.is_required) docTypeName += '*'

  return (
    // <FileBrowseLink>
    <div
      className="empty-row"
      style={{ cursor: permissions?.["study.checklist.addremove"] ? "pointer" : "auto" }}
      onClick={() => {
        if (permissions?.["study.checklist.addremove"]) {
          setSelectedOption(option)
          setSelectedChecklist(checklist)
          onOpenPanel("checklist-document-upload")
        }
      }}>
      <div className="icon-container">
        <i className="fal fa-cloud-upload" />
      </div>
      <div className="content-container">
        <div className="empty-row-header">
          <p className="empty-row-title">Upload document</p>
          <p className="empty-row-document-type">{docTypeName}</p>
        </div>
      </div>
    </div>
    // </FileBrowseLink>
  );
};

export default EmptyDocumentRow;