import React from 'react';
import styled from 'styled-components';

// components
import Modal from './Modal/Modal';
import { Button } from 'components-design-system'

const ConfirmModal = ({
  openModal,
  setOpenModal,
  header,
  content,
  onAction,
  actionLabel,
  actionVariant,
}) => {

  const handleAction = () => {
    setOpenModal(false);
    onAction();
  };

  return (
    <Modal
      title={header}
      open={openModal}
      onCancel={() => setOpenModal(false)}
      footer={[]}
      hideImage={true}>
      {content}
      <ActionContainer>
        <Button 
          variant="outlined"
          onClick={() => setOpenModal(false)}
          style={{ marginRight: 12 }}>
          Cancel
        </Button>
        <Button 
          variant={actionVariant}
          onClick={handleAction}>
          {actionLabel || 'Continue'}
        </Button>
      </ActionContainer>
    </Modal>
  );
};
 
export default ConfirmModal;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 18px;
`