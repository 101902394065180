import { useEffect, useState } from 'react';
import moment from 'moment';
import PrimeField from 'components/PrimeField/PrimeField';
import { Button } from 'components-design-system'
import Modal from 'components/Modal/Modal';

// utils
import {
  getNextMinorVersNumber,
  getNextMajorVersNumber,
} from '../../utils';

// styles
import './VersionSaveModal.scss'

const VersionSaveModal = ({
  type,
  open,
  onHide,
  authoring,
  onSubmit,
  onCloseMenu
}) => {
  const [versionNotes, setVersionNotes] = useState("");

  const handleSubmit = () => {
    onSubmit({ 
      versionNotes,
      unlockOnSave: true,
      is_major_version: type === "major" ? true : false,
      is_minor_version: type === "minor" ? true : false,
    });
    setVersionNotes(null);
    onHide();
    onCloseMenu()
  };

  useEffect(() => {
    setVersionNotes("")
  }, [open])

  return <>
    <Modal
      subtitle={authoring.study_id}
      title={`Save ${type === "minor" ? "Minor" : "Major"} Version ${type === "minor" ? getNextMinorVersNumber(authoring.version) : getNextMajorVersNumber(authoring.version)}`}
      message={`Saving a new version will save the state of the form and allow for version comparison.`}
      open={open}
      onCancel={onHide}
      hideImage={true}
      footer={[]}
    >
      <div className="version-save-modal-container">
        <div className="version-save-row">
          <strong>Date: </strong>
          <span>{moment().format("MM/DD/YYYY")}</span>
        </div>
        <PrimeField
          schema={{
            type: "textarea",
            label: "Version notes",
            placeholder: "This version"
          }}
          value={versionNotes}
          onChange={e => setVersionNotes(e)}
          />
      </div>

      <div className="version-save-modal-actions">
        <Button
          style={{ marginRight: 12 }}
          onClick={onHide}
          variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="primary">
          Save Version
        </Button>
      </div>
    </Modal>
  </>
};

export default VersionSaveModal;