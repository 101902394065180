import React from 'react'
import PropTypes from 'prop-types'
import './ReadOnlyField.scss'

const ReadOnlyField = ({
  placeholder,
  className,
  children,
  ellipsis,
}) => {
  const classNames = ['read-only-field']
  if (className) classNames.push(className)
  if (!children) classNames.push('empty')
  if (ellipsis) classNames.push('ellipsis')

  const renderContents = () => {
    if (children || children === 0) return children;
    if (placeholder) return placeholder;
    return <span>&nbsp;</span>
  }

  return <div className={classNames.join(' ')}>
    {renderContents()}
  </div>
}

ReadOnlyField.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  ellipsis: PropTypes.bool,
}

export default ReadOnlyField