export const sponsorTrialUISchema = {
  sponsor_trial_website: {
    "ui:options": {
      title: "Sponsor Trial Website",
      classNames: {
        objectContainer: "p-24 rjs-gap-16",
        objectTitleContainer: "rjs-h1-container",
        objectTitle: "rjs-h1",
        fieldContainer: "rjs-full",
      }
    },
    entry_criteria: {
      "ui:options": {
        title: "Entry Criteria:",
        label: false,
        classNames: {
          fieldContainer: "rjs-full",
        }
      },
      "ui:widget": "textarea"
    },
    participation_duration: {
      "ui:options": {
        title: "Participation Duration",
        label: false,
        classNames: {
          fieldContainer: "rjs-full",
        }
      },
      "ui:widget": "textarea"
    },
  }
}