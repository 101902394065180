// TODO: organize, maybe in alphabetical order?

const queryKeys = {
  // Variables for new API endpoints:
  envVars: ["env-vars"],
  publicEnvVar: ["prime-version-env-var"],
  pollTask: taskId => ["task", taskId],
  allowedClients: ["allowed-clients"],
  assessmentSubTypes: ['assessment-subtypes'],
  assessmentTypes: {
    all: ['assessment-types'],
    detail: (id) => [...queryKeys.assessmentTypes.all, id],
  },
  checklistTemplates: ['checklist-templates'],
  collaboratorTypes: ['collaborator-types'],
  requestSecondaryIdTypes: ['request-secondary-id-types'],
  commentTypes: ['comment-types'],
  conditions: ['conditions'],
  defaultAuthoringForm: ['default-authoring-form'],
  documentTypes: ['document-types'],
  globalSiteSettings: ['global-site-settings'],
  globalSponsorFilter: ['global-sponsor-filter'],

  // TODO: merge gridPresets with gridPresetsAdmin?
  gridPresets: {
    all: ["presets"],
    grid: (id) => [...queryKeys.gridPresets.all, id],
    gridDetails: (id) => [...queryKeys.gridPresets.all, "details", id]
  },
  gridFilters: ['filter-presets'],
  gridPresetsAdmin: ['grid-presets-admin'],
  protocolMaintenanceSettings: ['protocol-maintenance-settings'],
  prsUsers: ['prs-users'],
  sponsors: {
    all: ['sponsors'],
  },
  sponsors: ['sponsors'],
  tasks: ['tasks'],

  // enum
  regions: ['regions'],
  registries: ['registries'],

  // requests
  requests: {
    all: ['requests'],
    detail: id => [...queryKeys.requests.all, 'detail', id],
    team: id => [...queryKeys.requests.detail(id), 'team'],
    timeline: id => [...queryKeys.requests.detail(id), 'timeline'],
    actionItems: (reqId) => [...queryKeys.requests.detail(reqId), 'action-items'],
    list: () => [...queryKeys.requests.all, 'list'],
    review: (reqId) => [...queryKeys.requests.all, 'request-review', reqId],
    approval: (reqId) => ['request-approval-records', reqId]
  },
  requestFields: {
    all: ['request-fields'],
    detail: id => [...queryKeys.requestFields.all, "detail", id]
  },
  requestTypes: {
    all: ['request-types'],
    detail: id => [...queryKeys.requestTypes.all, "detail", id],
    schemaDetail: id => [...queryKeys.requestTypes.all, "schemaDetail", id]
  },
  requestCategories: {
    all: ['request-categories'],
    detail: id => [...queryKeys.requestCategories.all, "detail", id]
  },
  requestSource: ['request-source'],
  // see documents below for request documents

  // studies
  // TODO: move study activities to acitivity key below
  studies: {
    activities: studyId => [...queryKeys.studies.detail(studyId), 'activities'],
    timeline: studyId => [...queryKeys.studies.detail(studyId), 'activities', "timeline"],
    all: ['studies'],
    header: (id) => ['studies', id, "header"],
    list: () => [...queryKeys.studies.all, 'list'],
    details: () => [...queryKeys.studies.all, 'detail'],
    detail: (id) => [...queryKeys.studies.details(), id],
    teams: ['study-teams'],
    team: (id) => [...queryKeys.studies.teams, id],
    // assessments: use "assessments" below
  },

  // site management
  siteManagement: {
    list: ["site-management"],
    details: ["site-management", "details"],
    detail: (siteManagementId) => [...queryKeys.siteManagement.details, Number(siteManagementId)],
    locations: (siteManagementId) => [...queryKeys.siteManagement.list, 'site-management-locations', Number(siteManagementId)],
    location: (siteManagementId) => [...queryKeys.siteManagement.locations(siteManagementId), "location"],
  },
  siteManagementDetails: ['site-management'],
  siteManagementMasking: ['site-management-masking'],
  siteManagementLocationMasking: ['site-management-location-masking'],

  // authoring
  authoringStudies: {
    all: ['authoring-studies'],
    detail: (id) => [...queryKeys.authoringStudies.all, "detail", id]
  },
  authoringUnlock: (id) => ['authoring-unlock', id],
  authoringVersions: {
    list: (authoringId) => ['authoring-versions', authoringId],
    details: () => ['authoring-versions', 'details'],
    detail: (versNum) => [...queryKeys.authoringVersions.details(), versNum]
  },
  authoringComments: {
    all: ['authoring-comments'],
    list: (authoringId) => [...queryKeys.authoringComments.all, authoringId],
    details: () => [...queryKeys.authoringComments.all, 'details'],
    detail: (authoringId) => [...queryKeys.authoringComments.all, authoringId],
  },
  authoringLockedFields: ["authoring-locked-fields"],
  authoringLockOverrides: (id) => ['authoring-lock-overrides', id],
  programName: ['program-name'],
  programId: ['program-id'],
  // authoring-review
  authoringReview: {
    metadata: (studyId, projectId, actionItemId) => ['authoring-review', studyId, 'project', projectId, "action-item", actionItemId],
    completeTask: (studyId, projectId, actionitem_pk) => [...queryKeys.authoringReview.metadata(Number(studyId), Number(projectId), actionitem_pk), "complete"],
    completeSubtask: (studyId, projectId, actionitem_pk, subactionitem_pk) => [...queryKeys.authoringReview.metadata(Number(studyId), Number(projectId), actionitem_pk), "subaction", subactionitem_pk, "complete"]
  },

  /** DOCUMENTS
   * all documents, including study (authoring), request ones, and independent of requests and studies
   */
  documents: {
    all: ['documents'],
    details: (documentId) => [...queryKeys.documents.all, documentId],
    downloadAttachment: (url) => ["download-attachment", url],

    // authoring documents
    authoringList: (authoringId) => [...queryKeys.documents.all, authoringId],
    ctgDocuments: (authoringId) => ['documents', 'ctg-documents', authoringId],

    // requests documents
    requestList: (reqId) => [...queryKeys.documents.all, "request", reqId]
  },
  documentAnnotations: (documentId) => ['document-annotations', documentId],
  extraDocumentFields: {
    all: ["extra-document-fields"]
  },

  /** ETL */
  aeImport: (id) => ["ae-import", id],
  etlImport: {
    all: ["etl-import"],
    sites: () => [...queryKeys.etlImport.all, "sites"],
  },

  // authoring-checklists
  checklists: {
    all: ['checklists'],
    list: (authoringId) => [...queryKeys.checklists.all, authoringId],
    details: () => [...queryKeys.checklists.all, 'details'],
    detail: (checklistId) => [...queryKeys.checklists.details(), checklistId],
    nonChecklists: (authoringId) => ['non-checklists', authoringId],
  },
  // authoring-submissions
  submissions: {
    approvals: (authoringId) => ['approvals', authoringId],
    all: ["submissions"],
    list: (authoringId) => [...queryKeys.submissions.all, authoringId],
  },
  authoringValidations: {
    details: (authoringId) => ["authoring-validations", authoringId]
  },
  qaComments: {
    details: (authoringId) => ["qa-comments", authoringId]
  },
  prsPreview: {
    details: (authoringId) => ["prs-preview", authoringId]
  },
  registriesDataCompare: {
    details: (authoringId) => ["protocol-data-compare", authoringId]
  },
  registriesData: {
    details: (studyId) => ["protocol-reg-data", studyId]
  },
  prsProtocol: {
    list: ["prs-protocol"],
    details: (authoringId) => [...queryKeys.prsProtocol.list, "details", authoringId]
  },

  activities: {
    all: ['activities'],
    study: (id) => [...queryKeys.activities.all, "study", id],
    activity: (studyId, activityId) => [...queryKeys.activities.study(studyId), "activity", activityId],
    activityTracker: (studyId, activityId, workflowId) => [...queryKeys.activities.activity(studyId, activityId), "tracker", workflowId],
    // actionItems: see below
    activityTimeline: (studyId, activityId, showAllComments, labels) => [...queryKeys.activities.activity(studyId, activityId), "timeline", "show-all-comments", showAllComments, "labels", labels],
    activityTeam: (studyId, activityId) => [...queryKeys.activities.activity(studyId, activityId), "team"],
    quick: () => [...queryKeys.activities.all, 'quick'], // /api/all-project-types
    dashboard: () => [...queryKeys.activities.all, 'dashboard'], // /api/project-dashboard
    metricsSummary: () => [...queryKeys.activities.all, 'metrics-summary'],
  },

  actionItems: {
    all: ["actionitems"],
    activity: (studyId, activityId) => [...queryKeys.actionItems.all, "study", studyId, "activity", activityId],
    item: (actionItemId) => [...queryKeys.actionItems.all, "item", actionItemId],
    userReviewItems: ['user-review-action-items'],
  },
  activityStateReport: ['activity-state-report'],
  activityTypes: {
    detail: id => [...queryKeys.activityTypesList, id]
  },

  activityTypesList: ["activity-types"],

  // following could be added to "studies" keys
  assessments: {
    all: ['assessments'], // run all assessments
    study: (studyId) => [...queryKeys.assessments.all, studyId],
    list: (studyId, deactivated = false) => [...queryKeys.assessments.study(studyId), "list", "deactivated", deactivated],
    details: (studyId, assessId) => [...queryKeys.assessments.study(studyId), "details", assessId],
    history: (studyId, assessId) => [...queryKeys.assessments.study(studyId), "history", assessId],
    metricsSummary: () => [...queryKeys.assessments.all, 'metrics-summary'],
  },

  dataChangeReport: ['data-change-report'],
  dataImportReport: ['data-import-report'],
  illogicalDataReport: ['illogical-data-report'],
  maintenanceTriggerReport: ['maintenance-trigger-report'],
  myPrime: ['my-prime'],
  cubeToken: ['cube-token'],
  pollingTask: ["polling-task"],
  study: ['study'],
  studySiteReport: ['study-site-report'],
  subtypes: ['subtypes'],
  tags: ['tags'],
  timeline: {
    details: (id, showAllComments, labels) => ['timeline', id, "show-all-comments", showAllComments, "labels", labels]
  },
  notifications: ["notifications"],
  // users
  users: {
    all: ['users'],
    details: (id) => [...queryKeys.users.all, id],
    actionItems: id => [...queryKeys.users.all, id, "action-items"],
    list: () => [...queryKeys.users.all, 'list'], // /api/all-users
  },
  // TODO: move user inside "users":
  user: ['user'],
  userInfo: ['user-info'],
  userPermissions: ['user-permissions'],
  userSettings: ['user-settings'],
  userRoles: {
    all: ['user-roles'],
    request: ['request-roles']
  },
  favoriteStudies: ['favorite-studies'],
  favoriteRequests: ['favorite-requests'],

  // enums
  countries: ['countries'],
  viewTypes: ['view-types'],
  emailTemplates: ['email-templates'],
  projectTypes: ["project-types"],

  // reports
  reports: {
    all: ["reports"],
    regen: model => [...queryKeys.reports.all, "regen", model],
    type: type => [...queryKeys.reports.all, "type", type]
  },
  // accounts settings
  subscriptionManager: {
    all: ["subscriptions"]
  },

  // upload history
  uploadHistory: ['upload-history'],

  // workflows
  workflows: {
    all: ['workflows'],
    detail: id => [...queryKeys.workflows.all, "detail", id]
  }
}

export default queryKeys