// utils
import { getWidget } from '../../shared/utils';
import { arrayClassNames, objectClassNames } from '../../classes';

const trialInfo = (registryId, preview) => {
  let returnObj = {
    "ui:options": {
      title: "Trial Information",
      classNames: objectClassNames.primary
    },
    trial_id: {
      "ui:options": {
        title: "Trial Identification",
        label: false,
        classNames: {
          fieldContainer: "rjs-full",
          objectTitle: "rjs-h2"
        }
      },
      eu_num: {
        "ui:options": {
          title: "EudraCT Number",
          placeholder: "Prepopulated",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        }
      },
      reg_num: {
        "ui:options": {
          title: "Sponsor Protocol Code",
          placeholder: "Enter Sponsor Protocol Code",
          tooltip: "Update the sponsor protocol code, if necessary. The sponsor protocol code should be the same as the one specified in the trial protocol and /or the clinical study report.",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        }
      },
      official_title: {
        "ui:options": {
          title: "Full Title Of Trial",
          placeholder: "Enter Full Title",
          errorMessage: "Official Title must have no more than 2000 characters",
          tooltip: "Update the full title of the trial, if necessary. The title of the trial should be the same as the one specified in the trial protocol and /or the clinical study report.",
          label: false,
          classNames: {
            fieldContainer: "rjs-full"
          }
        },
        "ui:widget": getWidget({ type: "textarea", preview }),
      },
    },
    additional_study_identifiers: {
      "ui:options": {
        title: "Additional Study Identifiers",
        label: false,
        classNames: {
          fieldContainer: "rjs-full",
          objectTitle: "rjs-h2"
        }
      },
      isrctn_num: {
        "ui:options": {
          title: "ISRCTN number",
          placeholder: "Enter ISRCTN number",
          errorMessage: "ISRCTN number must have no more than 50 characters",
          tooltip: "If the trial is registered on the Current Controlled Trials website, enter the International Standard Randomised Controlled Trial Number (ISRCTN) using the following format: ISRCTNxxxxxxxx where 'x' is a number (0-9 inclusive). Otherwise, leave this blank.",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        }
      },
      nct_id: {
        "ui:options": {
          title: "ClinicalTrials.gov identifier (NCT number)",
          placeholder: "Enter NCT ID",
          errorMessage: "NCT ID must have no more than 50 characters",
          tooltip: "Provide the US National Clinical Trial (NCT) unique identifier in the format of NCT followed by an 8-digit number, e.g.: NCT00000419, if available. Otherwise, leave this blank.",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        }
      },
      who_id: {
        "ui:options": {
          title: "WHO Universal Trial Number (UTN)",
          placeholder: "Enter WHO Identifier",
          errorMessage: "WHO Identifier must have no more than 50 characters",
          tooltip: "Enter the WHO Universal Trial Reference number if the trial is registered on the WHO Clincal Trials portal. Otherwise, leave this blank. For more information visit the WHO ICTRP website.",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        }
      },
    },
    secondary_ids: {
      "ui:options": {
        title: "Secondary ID's",
        addButtonTitle: "Add Secondary ID",
        label: false,
        addable: true,
        removable: true,
        orderable: false,
        classNames: arrayClassNames.primary
      },
      items: {
        other_identifier_name: {
          "ui:options": {
            title: "Other Identifier Name",
            placeholder: "Enter Other Identifier Name",
            errorMessage: "Other identifier name must have no more than 50 characters",
            tooltip: "If other identifiers for this trial are available, click in the left-hand field and enter the name of the identifier, then enter the identification code in the right-hand field. Otherwise, leave this blank. To add addional fields, click the + button. To delete any fields added in error click the X button.",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          }
        },
        other_identifier_code: {
          "ui:options": {
            title: "Other Identifier Code",
            placeholder: "Enter Other Identifier Code",
            errorMessage: "Other identifier code must have no more than 50 characters",
            tooltip: "IIf other identifiers for this trial are available, click in the left-hand field and enter the name of the identifier, then enter the identification code in the right-hand field. Otherwise, leave this blank. To add addional fields, click the + button. To delete any fields added in error click the X button.",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          }
        },
      }
    },
    sponsor: {
      "ui:options": {
        title: "Sponsor Details",
        addButtonTitle: "Add Sponsor",
        label: false,
        addable: true,
        removable: true,
        orderable: false,
        classNames: arrayClassNames.primary
      },
      items: {
        name: {
          "ui:options": {
            title: "Name Of Organisation",
            placeholder: "Enter Organisation Name",
            errorMessage: "Enter the name of the organisation responsible for addressing general questions from members of the public.",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          }
        },
        address: {
          "ui:options": {
            title: "Street Address",
            placeholder: "Enter Address",
            errorMessage: "The street address for the sponsor is incomplete. Provide the street address.",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          }
        },
        city: {
          "ui:options": {
            title: "Town/City",
            placeholder: "Enter Town/City",
            errorMessage: "The sponsors town/city is incomplete. Provide the name of the sponsor's'town/city.",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          }
        },
        zip_code: {
          "ui:options": {
            title: "Postcode",
            placeholder: "Enter Post Code",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          }
        },
        country: {
          "ui:options": {
            title: "Country",
            placeholder: "--Select--",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          }
        },
        scientific_contact: {
          "ui:options": {
            label: false,
            title: "Scientific Contact Point",
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-full",
            }
          },
          name: {
            "ui:options": {
              title: "Name Of Organisation",
              placeholder: "Enter Organisation Name",
              errorMessage: "The organisation name is incomplete for the scientific contact point. Provide an organisation name for the scientific contact point.",
              tooltip: "Enter the name of the organisation responsible for addressing scientific and/or technical questions.",
              label: false,
              classNames: {
                fieldContainer: "rjs-full"
              }
            },
            "ui:widget": getWidget({ type: "textarea", preview }),
          },
          title: {
            "ui:options": {
              title: "Functional Contact Point Name",
              placeholder: "Enter Contact Name",
              errorMessage: "The functional contact point name is incomplete for the scientific contact point. Provide the functional contact point name for the scientific contact point.",
              tooltip: "Enter the name of the functional contact point for scientific and/or technical questions (e.g. clinical trial disclosure desk).",
              label: false,
              classNames: {
                fieldContainer: "rjs-full"
              }
            },
            "ui:widget": getWidget({ type: "textarea", preview }),
          },
          area_code: {
            "ui:options": {
              title: "Country Code",
              placeholder: "Enter Country Code",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
          },
          phone_number: {
            "ui:options": {
              title: "Telephone Number",
              placeholder: "Enter Phone Number",
              errorMessage: "No email address or telephone number has been provided for the scientific contact point. Specify an email address, telephone number, or both must be specified.",
              tooltip: "Please provide at least the telephone number, email or both for each contact.",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
          },
          email: {
            "ui:options": {
              title: "Email Address",
              placeholder: "Enter Email",
              errorMessage: "No email address or telephone number has been provided for the scientific contact point. Specify an email address, telephone number, or both must be specified.",
              tooltip: "Please provide at least the telephone number, email or both for each contact.",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
        },
        public_contact: {
          "ui:options": {
            label: false,
            title: "Public Contact Point",
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-full",
            }
          },
          name: {
            "ui:options": {
              title: "Name Of Organisation",
              placeholder: "Enter Organisation Name",
              errorMessage: "The organisation name is incomplete for the public contact point. Provide an organisation name for the public contact point.",
              tooltip: "Enter the name of the organisation responsible for addressing general questions from members of the public.",
              label: false,
              classNames: {
                fieldContainer: "rjs-full"
              }
            },
            "ui:widget": getWidget({ type: "textarea", preview }),
          },
          title: {
            "ui:options": {
              title: "Functional Contact Point Name",
              placeholder: "Enter Contact Name",
              errorMessage: "The functional contact point name is incomplete for the public contact point. Provide the functional contact point name for the public contact point.",
              tooltip: "Enter the name of the functional contact point for general questions from members of the public (e.g. clinical trial disclosure desk)",
              label: false,
              classNames: {
                fieldContainer: "rjs-full"
              }
            },
            "ui:widget": getWidget({ type: "textarea", preview }),
          },
          area_code: {
            "ui:options": {
              title: "Country Code",
              placeholder: "Enter Country Code",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
          },
          phone_number: {
            "ui:options": {
              title: "Telephone Number",
              placeholder: "Enter Phone Number",
              errorMessage: "No email address or telephone number has been provided for the scientific contact point. Specify an email address, telephone number, or both must be specified.",
              tooltip: "Please provide at least the telephone number, email or both for each contact.",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
          },
          email: {
            "ui:options": {
              title: "Email Address",
              placeholder: "Enter Email",
              errorMessage: "No email address or telephone number has been provided for the scientific contact point. Specify an email address, telephone number, or both must be specified.",
              tooltip: "Please provide at least the telephone number, email or both for each contact.",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
          },
        }
      }
    },
    paediatric_regulatory_details: {
      "ui:options": {
        title: "Paediatric Regulatory Details",
        label: false,
        classNames: {
          fieldContainer: "rjs-full",
          objectTitle: "rjs-h2"
        }
      },
      trial_part: {
        "ui:options": {
          title: "Is trial part of an agreed paediatric investigation plan (PIP)?",
          tooltip: "Select 'Yes' for a trial which is part of an agreed paediatric investigation plan (PIP),  otherwise select 'No'. An agreed paediatric investigation plan is a development plan agreed with the European Medicines Agency (EMA), aimed at ensuring the necessary data is obtained to evaluate a medicine for use in children. For more information, visit the EMA's paediatric medicine webpages.",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
        "ui:widget": getWidget({ type: 'radio', preview })
      },
      ema_plan: {
        "ui:options": {
          title: "EMA paediatric investigation plan(s)",
          placeholder: "Enter PIP",
          tooltip: "Enter the EMA paediatric investigation plan number(s) (PIP) using the following format: EMEA-xxxxxx-PIPxx-xx, where x is a number (0-9 inclusive).",
          errorMessage: "The PIP numbers are missing. Enter the PIP number(s) or indicate if the trial was not part of a paediatric investigation plan",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        }
      },
    },
    article_45: {
      "ui:options": {
        title: "Does Article 45 of REGULATION (EC) No 1901/2006 apply to this trial?",
        tooltip: "Select 'Yes' for a trial which is in the scope of Article 45 of said Regulation, otherwise select 'No'. Article 45 of Regulation (EC) 1901/2006 (Paediatric Regulation) applies to paediatric studies in respect of products authorised in the European Union, which were completed by 26 January 2007.",
        label: false,
        classNames: {
          fieldContainer: "rjs-col-1-3"
        }
      },
      "ui:widget": getWidget({ type: 'radio', preview })
    },
    article_46: {
      "ui:options": {
        title: "Does Article 46 of REGULATION (EC) No 1901/2006 apply to this trial?",
        tooltip: "Select 'Yes' for a trial which is in scope of Article 46 of said Regulation, otherwise select 'No'. Article 46 of Regulation (EC) 1901/2006 (Paediatric Regulation) applies to marketing authorisation holder sponsored paediatric studies in respect of products authorised in the European Union, which were completed after 26 January 2007.",
        label: false,
        classNames: {
          fieldContainer: "rjs-col-1-3"
        }
      },
      "ui:widget": getWidget({ type: 'radio', preview })
    },
    result_analysis_stage: {
      "ui:options": {
        title: "Result Analysis Stage",
        label: false,
        classNames: {
          fieldContainer: "rjs-full",
          objectTitle: "rjs-h2"
        }
      },
      analysis_stage: {
        "ui:options": {
          title: "Analysis Stage",
          tooltip: "If you are reporting on an interim analysis, select 'interim'. An interim analysis is an analysis intended to compare treatment arms with respect to efficacy or safety at any time prior to the formal completion of a trial . Otherwise select 'final'.",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
        "ui:widget": getWidget({ type: 'radio', preview })
      },
      analysis_date: {
        "ui:options": {
          title: "Date of Interim/Final Analysis",
          placeholder: "Enter Date",
          errorMessage: "The analysis stage date has not been specified. Enter the date on which the analysis of the results data was performed.",
          tooltip: "Select the date for the cut-off data point for the reported analysis.",
          label: false,
        },
        "ui:widget": getWidget({ type: "date", preview })
      },
      primary_completion_data: {
        "ui:options": {
          label: true,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
        is_primary_completion_data: {
          "ui:options": {
            title: "Is this the analysis of the primary completion data?",
            tooltip: "Select 'Yes' if you are reporting data collected up to the primary completion date, otherwise select 'No'. The primary completion date is the date that the final subject was examined or received an intervention for the purposes of final collection of data for the primary end point.",
            label: false,
            classNames: {
              fieldContainer: "rjs-full"
            }
          },
          "ui:widget": getWidget({ type: 'radio', preview })
        },
        primary_completion_date: {
          "ui:options": {
            title: "Primary completion date",
            tooltip: "Enter the final date on which data was collected.",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": getWidget({ type: "date", preview })
        },
      },
      global_end_date: {
        "ui:options": {
          label: true,
          classNames: {
            fieldContainer: "rjs-full"
          }
        },
        is_trial_date_reached: {
          "ui:options": {
            title: "Global end of trial date reached?",
            tooltip: "The global end of trial date is when the last subject in the trial was examined, or received an intervention globally. Select 'Yes' if the global end of the trial has been reached, otherwise select 'No'.",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": getWidget({ type: 'radio', preview })
        },
        trial_date: {
          "ui:options": {
            title: "Global end of trial date",
            placeholder: "Enter Global End Trial Date",
            errorMessage: "The global completion date is missing. If the question 'Global end of trial date reached?' is yes, provide the global completion date.",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": getWidget({ type: 'date', preview })
        },
        trial_end: {
          "ui:options": {
            title: "Was the trial ended prematurely?",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": getWidget({ type: "radio", preview })
        },
      },
    },
    general_info: {
      "ui:options": {
        title: "General Information About The Trial",
        label: false,
        classNames: {
          fieldContainer: "rjs-full",
          objectTitle: "rjs-h2"
        }
      },
      trial_objectives: {
        "ui:options": {
          title: "Main objective of the trial",
          tooltip: "Enter a description for the main objective(s) of the trial",
          label: false,
          classNames: {
            fieldContainer: "rjs-full"
          }
        },
        "ui:widget": getWidget({ type: "textarea", preview }),
      },
      actual_recruitment_start_date: {
        "ui:options": {
          title: "Actual start date of recruitment",
          errorMessage: "The actual start date of trial is missing. Provide the actual start date of recruitment.",
          tooltip: "Select the date when the recruitment of subjects began. The recruitment date is to be defined by the sponsor and can correspond to the date the subjects were consented, enrolled or screened in the trial.",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
        "ui:widget": getWidget({ type: "date", preview })
      },
      follow_up: {
        "ui:options": {
          label: true,
          classNames: {
            fieldContainer: "rjs-full",
            objectTitle: "rjs-h3"
          }
        },
        is_planned: {
          "ui:options": {
            title: "Long term follow-up planned?",
            tooltip: "Select 'Yes' if long-term monitoring of patients is planned, otherwise select 'No'.",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": getWidget({ type: 'radio', preview })
        },
        rationale: {
          "ui:options": {
            title: "Long term follow-up rationale",
            tooltip: "Select the main reason(s) for long-term follow up.",
            errorMessage: "The long-term follow up rationale(s) are missing. If the question 'Long-term follow up planned?' is yes then select at least one long term follow up rationale.",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": getWidget({ type: "CustomCheckboxGroup", preview })
        },
        range: {
          "ui:options": {
            title: "Long term follow-up duration?",
            placeholder: "--Select--",
            errorMessage: "The units for the long-term follow up duration is missing. If the question 'Long-term follow up planned?' is yes, then select the units for the long term follow up duration.",
            type: "number",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          }
        },
        duration_type: {
          "ui:options": {
            title: "Long term follow-up duration type",
            errorMessage: "The units for the long-term follow up duration is missing. If the question 'Long-term follow up planned?' is yes, then select the units for the long term follow up duration.",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": getWidget({ type: 'radio', preview })
        },
      },
      independent: {
        "ui:options": {
          title: "Independent data monitoring committee (IDMC) involvement?",
          tooltip: "The sponsor may establish an independent data-monitoring committee (IDMC) to assess at intervals, the progress of a clinical trial, safety data, critical efficacy endpoints, and recommend to the sponsor to continue, modify, or stop a trial. Select 'Yes' if such a committee was in place, or otherwise select 'No'. (See:  http://www.emea.europa.eu/docs/en_GB/document_library/Scientific_guideline/2009/09/WC500003635.pdf)",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
        "ui:widget": getWidget({ type: 'radio', preview })
      },
      protection: {
        "ui:options": {
          title: "Protection of trial subjects",
          placeholder: "Enter Text",
          tooltip: "Enter a description for any specific measures that were put in place to protect trial subjects, for example measures to minimise pain and distress.",
          label: false,
          classNames: {
            fieldContainer: "rjs-full"
          }
        },
        "ui:widget": getWidget({ type: "textarea", preview }),
      },
      background: {
        "ui:options": {
          title: "Background therapy",
          placeholder: "Enter Text",
          tooltip: "Describe any treatments that are not test or comparator products used across all arms/groups in the trial.",
          label: false,
          classNames: {
            fieldContainer: "rjs-full"
          }
        },
        "ui:widget": getWidget({ type: "textarea", preview }),
      },
      evidence: {
        "ui:options": {
          title: "Evidence for comparator(s)",
          placeholder: "Enter Text",
          tooltip: "Provide a rationale for the use of the comparators used in the trial. If evidence is available for its use in the context of the trial design, provide the details below.",
          label: false,
          classNames: {
            fieldContainer: "rjs-full"
          }
        },
        "ui:widget": getWidget({ type: "textarea", preview }),
      },
    },
    population: {
      "ui:options": {
        title: "Population Of Trial Subjects",
        label: false,
        classNames: {
          fieldContainer: "rjs-full",
          objectTitle: "rjs-h2"
        }
      },
      country_subject_counts: {
        "ui:options": {
          tooltip: "Describe any treatments that are not test or comparator products used across all arms/groups in the trial.",
          label: false,
          classNames: {
            fieldContainer: "rjs-full"
          }
        },
        "ui:widget": getWidget({ type: "TrialCountries", preview })
      },
      population_age_group: {
        "ui:options": {
          tooltip: "Describe any treatments that are not test or comparator products used across all arms/groups in the trial.",
          label: false,
          classNames: {
            fieldContainer: "rjs-full"
          }
        },
        "ui:widget": getWidget({ type: "AgeRange", preview })
      },
    },
  };
  if (registryId === 'ctg') {
    returnObj = { "ui:options": { hide: true } };
  };

  if (registryId === "eudract") {
    // required fields
    returnObj.article_45["ui:options"].required = true;
    returnObj.article_46["ui:options"].required = true;

    if (returnObj?.trial_id?.official_title?.["ui:options"]) {
      returnObj.trial_id.official_title["ui:options"].required = true;
    }

    if (returnObj?.sponsor?.items?.name?.["ui:options"]) {
      returnObj.sponsor.items.name["ui:options"].required = true;
      returnObj.sponsor.items.address["ui:options"].required = true;
      returnObj.sponsor.items.city["ui:options"].required = true;
      returnObj.sponsor.items.country["ui:options"].required = true;

      if (returnObj?.sponsor?.items?.scientific_contact?.name["ui:options"]) {
        returnObj.sponsor.items.scientific_contact.name["ui:options"].required = true;
        returnObj.sponsor.items.scientific_contact.title["ui:options"].required = true;
      }
      if (returnObj?.sponsor?.items?.public_contact?.name["ui:options"]) {
        returnObj.sponsor.items.public_contact.name["ui:options"].required = true;
        returnObj.sponsor.items.public_contact.title["ui:options"].required = true;
      }
    }

    if (returnObj?.paediatric_regulatory_details?.trial_part?.["ui:options"]) {
      returnObj.paediatric_regulatory_details.trial_part["ui:options"].required = true;
    }

    if (returnObj?.result_analysis_stage?.analysis_stage?.["ui:options"]) {
      returnObj.result_analysis_stage.analysis_stage["ui:options"].required = true;
      returnObj.result_analysis_stage.analysis_date["ui:options"].required = true;

      if (returnObj?.result_analysis_stage?.primary_completion_data?.is_primary_completion_data["ui:options"]) {
        returnObj.result_analysis_stage.primary_completion_data.is_primary_completion_data["ui:options"].required = true;
      }
      if (returnObj?.result_analysis_stage?.global_end_date?.is_trial_date_reached["ui:options"]) {
        returnObj.result_analysis_stage.global_end_date.is_trial_date_reached["ui:options"].required = true;
      }
    }

    if (returnObj?.general_info?.trial_objectives?.["ui:options"]) {
      returnObj.general_info.trial_objectives["ui:options"].required = true;
      returnObj.general_info.actual_recruitment_start_date["ui:options"].required = true;
      returnObj.general_info.independent["ui:options"].required = true;
      returnObj.general_info.protection["ui:options"].required = true;

      if (returnObj?.general_info?.follow_up?.is_planned["ui:options"]) {
        returnObj.general_info.follow_up.is_planned["ui:options"].required = true;
      }
    }
  }
  return returnObj;
};

export default trialInfo;