import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import PrimeField from "../../PrimeField/PrimeField";

const RJPassword = (props) => {
  const [isDisabled, setDisabled] = useState(false);
  const [showWarning, setWarning] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [value, setValue] = useState(props.value);
  const [invalidText, setInvalidText] = useState("This is a required field.");
  const options = props.uiSchema["ui:options"]
  const title = options.title;
  const placeholder = options.placeholder;

  useEffect(() => {
    let { value, schema, uiSchema } = props;
    if (schema) {
      let { minLength, maxLength } = props.schema;
      if (minLength && value && value.length < minLength) {
        setWarning(true);
      } else if (maxLength && value && value.length > maxLength) {
        setWarning(true);
      } else {
        setWarning(false);
      }
    }
    if(uiSchema) {
      if(uiSchema["ui:readonly"] || uiSchema["ui:disabled"]) {
        setDisabled(true)
      }
    }
    setValue(props.value)
  }, [props.value]);

  useEffect(() => {
    if(options.required) {
      if(value && value.length > 0) {
        setInvalid(false)
        setInvalidText("")
      } else {
        setInvalid(true)
        setInvalidText("This is a required field.")
      }
    }
  }, [value])

  return (
    <Container>
      <PrimeField
        id={props.id}
        schema={{
          type: "password",
          placeholder,
          required: true,
          containerClassName: "field"
        }}
        onChange={(e) => props.onChange(e)}
        value={props.value}/>
    </Container>
  );
};

export default RJPassword;

const Container = styled.div`
  
`