import {
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import useGetApi from "../useGetApi"
import useMutateApi from "../useMutateApi"
import {
  useGetActivityTeam,
  usePostActivityTeam,
  useRefreshActivityTeam,
} from "./useActivityTeamApi"
import axios from 'axios'

// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

// utils
import getUrl from "api/apiMap"
import { getCookie } from 'api/apiUtils'

const cookieHeaders = {
  "X-CSRFToken": getCookie('csrftoken'),
}

const useGetActivities = (studyId) => {
  return useGetApi(`${getUrl(apiEndpoints.studies)}${studyId}/activities/`, queryKeys.activities.study(studyId))
}

const useGetActivity = (studyId, activityId) => {
  return useGetApi(`${getUrl(apiEndpoints.studies)}${studyId}/activities/${activityId}/`, queryKeys.activities.activity(studyId, activityId))
}

const usePutActivity = (studyId, activityId) => {
  return useMutateApi({
    method: "put",
    apiEndpoint: `${getUrl(apiEndpoints.studies)}${studyId}/activities/${activityId}/`,
    invalidateQueries: [queryKeys.activities.activity(studyId, activityId)],
    successMsg: 'Successfully updated activity'
  })
}

// tracker

// getting 500 error
const useGetActivityTracker = (studyId, activityId) => {
  return useGetApi(`${getUrl(apiEndpoints.studies)}${studyId}/activities/${activityId}/tracker/`, queryKeys.activities.activityTracker(studyId, activityId))
}

// timeline

const useGetActivityFeed = (studyId, activityId, params = {}) => {
  let paramsStr = `?showStudy=${params.showStudy}`
  if(params?.labels?.length > 0) {
    paramsStr += `&labels=${params.labels}`
  }

  return useGetApi(`${getUrl(apiEndpoints.studies)}${studyId}/activities/${activityId}/timeline/${paramsStr}`, queryKeys.activities.activityTimeline(studyId, activityId, params.showStudy, params.labels))
}

const usePostActivityComment = (studyId, activityId, params = {}) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: `${getUrl(apiEndpoints.activities)}${activityId}/comments/`,
    invalidateQueries: [queryKeys.activities.all, queryKeys.activities.activityTimeline(studyId, activityId, params.showStudy, params.labels)], // TODO: limit to just study queries?
    successMsg: 'Successfully created comment'
  })
}

const usePutActivityComment = (studyId, activityId, commentId, params = {}) => {
  return useMutateApi({
    method: "put",
    apiEndpoint: `${getUrl(apiEndpoints.activities)}${activityId}/comments/${commentId}/`,
    invalidateQueries: [queryKeys.activities.all, queryKeys.activities.activityTimeline(studyId, activityId, params.showStudy, params.labels)], // TODO: limit to just study queries?
    successMsg: 'Successfully updated comment'
  })
}

const useDeleteActivityComment = (activityId, params = {}) => {
  return useMutateApi({
    method: "delete",
    apiEndpoint: getUrl(`${apiEndpoints.activities}${activityId}/comments/`),
    invalidateQueries: [queryKeys.activities.all], // TODO: limit to just study queries?
    successMsg: "Sucessfully deleted comment",
  })
}

// enum list
const useGetActivityTypes = () => {
  return useGetApi(getUrl(`${apiEndpoints.activityTypesList}`), queryKeys.activityTypesList)
}

export {
  useGetActivities,
  useGetActivity,
  usePutActivity,
  // tracker
  useGetActivityTracker,
  // timeline (events | comments)
  useGetActivityFeed,
  usePostActivityComment,
  usePutActivityComment,
  useDeleteActivityComment,
  // team
  useGetActivityTeam,
  usePostActivityTeam,
  useRefreshActivityTeam,
  // activities list
  useGetActivityTypes
}