// TODO: review if using this provider
import React, { createContext, useContext, useState } from 'react'

const NotificationsContext = createContext({})

const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState(null)
  const [notifUnreadCount, setNotifUnreadCount] = useState(null)

  return <NotificationsContext.Provider
    value={{
      notifications,
      setNotifications,
      notifUnreadCount,
    }}
  >
    {children}
  </NotificationsContext.Provider>
}

// hook with all provider functions and variables
const useNotifications = () => {
  return useContext(NotificationsContext)
}

export default useNotifications

export {
  NotificationsContext,
  NotificationsProvider,
}