// styles
import './RegistryButtons.scss'

// constants
import { getSchemaRegistryTitle } from 'constants/authoring';

/**
 * @param {Object} props:
 *        * formView: determines the selected state
 *        * setFormView: changes selected state
 *        * registries: send specific registries
 *            For authoring view: limited # from URL params.
 *        * formSchemas: to get all section registries.
 *        * formSection: eg, {id: 'protocol', text: 'Protocol'}
 *            Used to get all registries and for button labels.
 * @returns JSX component
 */
const RegistryButtons = ({
  formView,
  setFormView,
  formSection,
  registries,
  formSchemas,
  showPagePreview,
  validationErrorsExist,
  setRegistryValidations = () => {}
}) => {

  const onRegistryBtnClick = (regId) => {
    if (formSection && formView
      // only if more than one button
      && registries?.length > 1) {
      const text = getSchemaRegistryTitle(formSection?.id, regId);
      let formViewObject = { id: regId, text };
      setFormView(formViewObject);
      setRegistryValidations(null)
    }
  };

  const renderValidationResults = (title) => {
    if (validationErrorsExist === true && title === formView.text.toUpperCase()) return <i className="fal fa-exclamation-triangle" />
    else if (validationErrorsExist === false && title === formView.text.toUpperCase()) return <i className="fal fa-check-circle" />
    return
  }

  if (formView && formSection) {
    if (!registries && formSection?.id && formSchemas) {
      registries = [];
      const sectionSchemas = formSchemas[formSection.id].properties;
      for (const regKey in sectionSchemas) {
        if (showPagePreview && regKey !== "global") registries.push(regKey);
        else if (!showPagePreview) registries.push(regKey);
      }
    }

    const regButtons = registries?.map((regId) => {
      let isActive = false
      let title = getSchemaRegistryTitle(formSection?.id, regId)?.toUpperCase()
      isActive = regId === formView.id;
      return <button
        key={regId}
        className={`registry-button ${regId}${regId === formView.id ? ' is-active' : ''}`}
        onClick={() => onRegistryBtnClick(regId)}
      >
        {title}

        {renderValidationResults(title)}
      </button>
    })

    return <div className="registry-buttons">
      {regButtons}
    </div>
  }

  return null
}

export default RegistryButtons