// json data schemas
import { primeProtocolDataSchema } from "../containers/studies/Study/subcomponents/Authoring/RJS/schemas/dataSchemas/protocol/primeProtocolDataSchema";
import { primeResultsDataSchema } from "../containers/studies/Study/subcomponents/Authoring/RJS/schemas/dataSchemas/results/primeResultsDataSchema";
import { delayedResultsDataSchema } from "../containers/studies/Study/subcomponents/Authoring/RJS/schemas/dataSchemas/delayedResults/delayedResultsDataSchema";
import { sponsorTrialDataSchema } from '../containers/studies/Study/subcomponents/Authoring/RJS/schemas/dataSchemas/sponsorTrialWebsite/sponsorTrialWebsiteDataSchema';

// ui schemas
import { primeProtocolUISchema } from "../containers/studies/Study/subcomponents/Authoring/RJS/schemas/uiSchemas/protocol/primeProtocolUISchema";
import { ctgProtocolUISchema } from "../containers/studies/Study/subcomponents/Authoring/RJS/schemas/uiSchemas/protocol/ctgProtocolUISchema";
import { eupasProtocolUISchema } from "../containers/studies/Study/subcomponents/Authoring/RJS/schemas/uiSchemas/protocol/eupasProtocolUISchema";
import { ctisProtocolUISchema } from "../containers/studies/Study/subcomponents/Authoring/RJS/schemas/uiSchemas/protocol/ctisProtocolUISchema";

import { secondaryAttributesUISchema } from "../containers/studies/Study/subcomponents/Authoring/RJS/schemas/uiSchemas/protocol/secondaryAttributesUISchema";
import { primeResultsUISchema } from "../containers/studies/Study/subcomponents/Authoring/RJS/schemas/uiSchemas/results/primeResultsUISchema";
import { ctgResultsUISchema } from "../containers/studies/Study/subcomponents/Authoring/RJS/schemas/uiSchemas/results/ctgResultsUISchema";
import { eudraResultsUISchema } from "../containers/studies/Study/subcomponents/Authoring/RJS/schemas/uiSchemas/results/eudraResultsUISchema";
import { delayedResultsUISchema } from "../containers/studies/Study/subcomponents/Authoring/RJS/schemas/uiSchemas/delayedResults/delayedResultsUISchema";
import { sponsorTrialUISchema } from "../containers/studies/Study/subcomponents/Authoring/RJS/schemas/uiSchemas/sponsorTrialWebsite/sponsorTrialWebsiteUISchema";

const formSchemas = {
  protocol: {
    title: "Protocol",
    properties: {
      global: {
        title: "Global",
        schema: primeProtocolDataSchema,
        uiSchema: primeProtocolUISchema
      },
      ctg: {
        title: "CTG",
        schema: primeProtocolDataSchema,
        uiSchema: ctgProtocolUISchema,
      },
      eupas: {
        title: "EUPAS",
        schema: primeProtocolDataSchema,
        uiSchema: eupasProtocolUISchema
      },
      ctis: {
        title: "CTIS",
        schema: primeProtocolDataSchema,
        uiSchema: ctisProtocolUISchema
      }
    }
  },
  results: {
    title: "Results",
    properties: {
      global: {
        title: "Global",
        schema: primeResultsDataSchema,
        uiSchema: primeResultsUISchema
      },
      ctg: {
        title: "CTG",
        schema: primeResultsDataSchema,
        uiSchema: ctgResultsUISchema
      },
      eudra: {
        title: "EudraCT",
        submitToCTG: "eudract",
        schema: primeResultsDataSchema,
        uiSchema: eudraResultsUISchema
      }
    }
  },
  secondaryAttributes: {
    title: "Study Details",
    properties: {
      global: {
        title: "Global",
        schema: primeProtocolDataSchema,
        uiSchema: secondaryAttributesUISchema
      }
    }
  },
  delayedResultsCertification: {
    title: "Delayed Results Certification",
    submitToCTG: "delayed_results_certification",
    properties: {
      global: {
        title: "Global",
        schema: delayedResultsDataSchema,
        uiSchema: delayedResultsUISchema
      },
      ctg: {
        title: "CTG",
        schema: delayedResultsDataSchema,
        uiSchema: delayedResultsUISchema
      }
    }
  },
  sponsorTrialWebsite: {
    title: "Sponsor Trial Website",
    properties: {
      global: {
        title: "Global",
        schema: sponsorTrialDataSchema,
        uiSchema: sponsorTrialUISchema
      },
    }
  }
};

const previewFormSchemas = {
  protocol: {
    title: "Protocol",
    properties: {
      ctg: {
        title: "CTG",
        schema: primeProtocolDataSchema,
        uiSchema: ctgProtocolUISchema,
      },
      eupas: {
        title: "EUPAS",
        schema: primeProtocolDataSchema,
        uiSchema: eupasProtocolUISchema
      },
      ctis: {
        title: "CTIS",
        schema: primeProtocolDataSchema,
        uiSchema: ctisProtocolUISchema
      }
    }
  },
  results: {
    title: "Results",
    properties: {
      ctg: {
        title: "CTG",
        schema: primeResultsDataSchema,
        uiSchema: ctgResultsUISchema
      },
      eudra: {
        title: "EudraCT",
        submitToCTG: "eudract",
        schema: primeResultsDataSchema,
        uiSchema: eudraResultsUISchema
      }
    }
  },
};

// query string params sent in reviewer view
const reviewerUrlParams = {
  projId: "proj-id",
  taskId: "task-id",
  // "ctg", "eudract"
  docs: "docs",
  formView: "form-view",
  // "results", "protocol", "secondaryAttributes","delayedResultsCertification"
  formSection: "form-section",
  viewType: "view-type",
  // 1.0, 5.1, "None"
  formVersion: "form-version",
}

// params on the reviewer/approver page and how they map to formSchemas

const sectionUrlParamMap = {
  'study+details': 'secondaryAttributes',
  'delayed+results+certification': 'delayedResultsCertification',
  'sponsor+trial+website': 'sponsorTrialWebsite',
}

const sectionTitleKeyMap = {
  "Results": "results",
  "Protocol": "protocol",
  "Study Details": "secondaryAttributes",
  "Delayed Results Certification": "delayedResultsCertification",
  "Sponsor Trial Website": "sponsorTrialWebsite"
}

const viewTitleKeyMap = {
  "Global": "global",
  "CTG": "ctg",
  "EUPAS": "eupas",
  "CTIS": "ctis",
  "EudraCT": "eudra"
}

const registryUrlParamMap = {
  eudract: 'eudra',
}

const protocolGlobal = [
  {
    jpath: "root_protocol_study_identification-container",
    title: "Study Identification and Information"
  },
  {
    jpath: "root_protocol_contact_information-container",
    title: "Study Sponsor, Collaborators and Contact Information"
  },
  {
    jpath: "root_protocol_study_oversight-container",
    title: "Oversight"
  },
  {
    jpath: "root_protocol_study_timeline-container",
    title: "Study Timelines and Status"
  },
  {
    jpath: "root_protocol_ctis_form-container",
    title: "Form"
  },
  {
    jpath: "root_protocol_mscs-container",
    title: "MSCs"
  },
  {
    jpath: "root_protocol_study_description-container",
    title: "Study Description and Conditions"
  },
  {
    jpath: "root_protocol_study_scope-container",
    title: "Study Scope and Design"
  },
  {
    jpath: "root_protocol_study_arms-container",
    title: "Arms and Interventions"
  },
  {
    jpath: "root_protocol_study_eligibilty-container",
    title: "Eligibility"
  },
  {
    jpath: "root_protocol_study_ipd-container",
    title: "IPD Sharing Statements"
  },
  {
    jpath: "root_protocol_study_outcome_measures-container",
    title: "Study Endpoints/Outcome Measures"
  },
  {
    jpath: "root_protocol_study_reference-container",
    title: "Citations, References and URLs"
  },
  {
    jpath: "locations-container",
    title: "Locations"
  },
  // {
  //   jpath: "documents-container",
  //   title: "Documents"
  // },
]

const protocolCtg = [
  {
    jpath: "root_protocol_study_identification-container",
    title: "Study Identification and Information"
  },
  {
    jpath: "root_protocol_contact_information-container",
    title: "Study Sponsor, Collaborators and Contact Information"
  },
  {
    jpath: "root_protocol_study_oversight-container",
    title: "Oversight"
  },
  {
    jpath: "root_protocol_study_timeline-container",
    title: "Study Timelines and Status"
  },
  {
    jpath: "root_protocol_study_description-container",
    title: "Study Description and Conditions"
  },
  {
    jpath: "root_protocol_study_scope-container",
    title: "Study Scope and Design"
  },
  {
    jpath: "root_protocol_study_arms-container",
    title: "Arms and Interventions"
  },
  {
    jpath: "root_protocol_study_eligibilty-container",
    title: "Eligibility"
  },
  {
    jpath: "root_protocol_study_ipd-container",
    title: "IPD Sharing Statements"
  },
  {
    jpath: "root_protocol_study_outcome_measures-container",
    title: "Study Endpoints/Outcome Measures"
  },
  {
    jpath: "root_protocol_study_reference-container",
    title: "Citations, References and URLs"
  },
  {
    jpath: "locations-container",
    title: "Locations"
  },
  // {
  //   jpath: "documents-container",
  //   title: "Documents"
  // },
]

const protocolEupas = [
  {
    jpath: "root_protocol_study_identification-container",
    title: "Study Identification and Information"
  },
  {
    jpath: "root_protocol_contact_information-container",
    title: "Study Sponsor, Collaborators and Contact Information"
  },
  {
    jpath: "root_protocol_study_timeline-container",
    title: "Study Timelines and Status"
  },
  {
    jpath: "root_protocol_study_description-container",
    title: "Study Description and Conditions"
  },
  {
    jpath: "root_protocol_study_scope-container",
    title: "Study Scope and Design"
  },
  {
    jpath: "root_protocol_study_arms-container",
    title: "Study Drug or IMP Information"
  },
  {
    jpath: "root_protocol_study_eligibilty-container",
    title: "Eligibility"
  },
  {
    jpath: "root_protocol_study_outcome_measures-container",
    title: "Study Endpoints/Outcome Measures"
  },
  {
    jpath: "root_protocol_study_reference-container",
    title: "Citations, References and URLs"
  },
  // {
  //   jpath: "documents-container",
  //   title: "Documents"
  // },
]

const protocolCtis = [
  {
    jpath: "root_protocol_study_identification-container",
    title: "Study Identification and Information"
  },
  {
    jpath: "root_protocol_secondary_attributes-container",
    title: "Study Timelines and Status"
  },
  {
    jpath: "root_protocol_ctis_form-container",
    title: "Form"
  },
  {
    jpath: "root_protocol_mscs-container",
    title: "MSCs"
  },
  {
    jpath: "root_protocol_study_description-container",
    title: "Trial Information"
  },
  {
    jpath: "root_protocol_study_eligibilty-container",
    title: "Eligibility"
  },
  {
    jpath: "root_protocol_study_reference-container",
    title: "References"
  },
  {
    jpath: "root_protocol_study_scope-container",
    title: "Countries outside the European Economic Area"
  },
  {
    jpath: "root_protocol_study_outcome_measures-container",
    title: "Study Endpoints/Outcome Measures"
  },
  {
    jpath: "locations-container",
    title: "Locations"
  },
  // {
  //   jpath: "documents-container",
  //   title: "Documents"
  // },
]

const resultsGlobal = [
  {
    jpath: "root_results_participant_flow-container",
    title: "Participant Flow"
  },
  {
    jpath: "root_results_baseline_characteristics-container",
    title: "Baseline Characteristics"
  },
  {
    jpath: "root_results_outcome_measures-container",
    title: "Outcome Measures"
  },
  {
    jpath: "root_results_adverse_events-container",
    title: "Adverse Events"
  },
  {
    jpath: "root_results_more_info-container",
    title: "More Information"
  },
  {
    jpath: "root_results_trial_info-container",
    title: "Trial Information"
  },
  // {
  //   jpath: "documents-container",
  //   title: "Documents"
  // },
]

const resultsCtg = [
  {
    jpath: "root_results_participant_flow-container",
    title: "Participant Flow"
  },
  {
    jpath: "root_results_baseline_characteristics-container",
    title: "Baseline Characteristics"
  },
  {
    jpath: "root_results_outcome_measures-container",
    title: "Outcome Measures"
  },
  {
    jpath: "root_results_adverse_events-container",
    title: "Adverse Events"
  },
  {
    jpath: "root_results_more_info-container",
    title: "More Information"
  },
  // {
  //   jpath: "documents-container",
  //   title: "Documents"
  // },
]


const resultsEudract = [
  {
    jpath: "root_results_trial_info-container",
    title: "Trial Information"
  },
  {
    jpath: "root_results_participant_flow-container",
    title: "Subject Disposition"
  },
  {
    jpath: "root_results_baseline_characteristics-container",
    title: "Baseline Characteristics"
  },
  {
    jpath: "root_results_outcome_measures-container",
    title: "End Points"
  },
  {
    jpath: "root_results_adverse_events-container",
    title: "Adverse Events"
  },
  {
    jpath: "root_results_more_info-container",
    title: "More Information"
  },
  // {
  //   jpath: "documents-container",
  //   title: "Documents"
  // },
]

const studyDetails = [
  {
    jpath: "root_protocol_study_eligibilty-container",
    title: "Study Details"
  },
  {
    jpath: "root_protocol_general-container",
    title: "General"
  },
  {
    jpath: "root_protocol_milestone_dates-field-container",
    title: "Milestone Dates"
  },
  {
    jpath: "root_protocol_secondary_attributes-field-container",
    title: "Secondary Attributes"
  },
  {
    jpath: "root_protocol_protocol_amendments-field-container",
    title: "Protocol Amendments"
  },
  // {
  //   jpath: "documents-container",
  //   title: "Documents"
  // },
]

const formNav = {
  protocol: {
    global: protocolGlobal,
    ctg: protocolCtg,
    eupas: protocolEupas,
    ctis: protocolCtis
  },
  results: {
    global: resultsGlobal,
    ctg: resultsCtg,
    eudra: resultsEudract
  },
  secondaryAttributes: {
    global: studyDetails,
  }
}

/**
 * @param {String} sectionId eg, "results", "protocol"
 * @returns string
 */
const getSchemaSectionTitle = (sectionId) => {
  return formSchemas[sectionId]?.title;
};

const getFormSections = () => {
  return Object.keys(formSchemas).map((sectId) => {
    return { id: sectId, text: getSchemaSectionTitle(sectId) };
  });
};

/**
 * @param {Object} formSection format: {id: "results", text: "Results" }
 * @param {String} registryId eg, "ctg", "eudra"
 * @returns string
 */
const getSchemaRegistryTitle = (sectionId, registryId) => {
  return formSchemas[sectionId]?.properties?.[registryId]?.title;
};

export {
  formSchemas,
  previewFormSchemas,
  reviewerUrlParams,
  sectionUrlParamMap,
  registryUrlParamMap,
  // helpers
  getFormSections,
  getSchemaSectionTitle,
  getSchemaRegistryTitle,
  formNav,
  sectionTitleKeyMap,
  viewTitleKeyMap
}