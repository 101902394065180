import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import useRefreshToken from '../../utilities/useRefreshToken';
import Loading from '../Loading/Loading'
import useAppState from "context/hooks/useAppState";

const PersistLogin = () => {
  const {
    refreshTokens,
    isAccessTokenExpired,
  } = useRefreshToken();

  const { appStateRef, setAppState, isMounted, setIsMounted } = useAppState();

  useEffect(() => {
    if (isMounted === false) {
      const verifyRefreshToken = async () => {
        try {
          if (isAccessTokenExpired()) {
            let tokens = await refreshTokens()
          } else {
            appStateRef.current = "authenticated"
            setAppState("authenticated")
          }
          return
        }
        catch (err) {
          console.error(err);
        }
        finally {
          setIsMounted(true)
        }
      }

      verifyRefreshToken();
    }
  }, [isMounted])

  return (
    isMounted === true
      ? <Outlet />
      : <Loading />
  )
}

export default PersistLogin