import { create } from 'zustand'

const initialState = {
  hiddenFields: null,
  forkedFields: null,
  lockedFields: {},
}

const useAuthoringFieldStore = create((set) => ({
  ...initialState,
  setHiddenFields: (hiddenFields) => set({ hiddenFields }),
  setForkedFields: (forkedFields) => set({ forkedFields }),
  setLockedFields: (lockedFields) => set({ lockedFields }),
  resetAuthoringFieldStore: () => set(initialState),
}))

export default useAuthoringFieldStore;