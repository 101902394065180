// force a file to download
const fileDownload = (data, name = 'file', type = 'text/plain') => {
  const { URL: { createObjectURL, revokeObjectURL }, setTimeout } = window;

  const blob = new Blob([data], { type });
  const url = createObjectURL(blob);

  const anchor = document.createElement('a');
  anchor.setAttribute('href', url);
  anchor.setAttribute('download', name);
  anchor.click();
  
  setTimeout(() => { revokeObjectURL(url) }, 100);
};

module.exports = fileDownload;