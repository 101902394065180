import { useState, useEffect, useMemo, useRef } from 'react';
import { useParams, Link } from "react-router-dom"
import dayjs from 'dayjs';

// components
import GridTypeA from 'components/grids/GridTypeA/GridTypeA';
import { Button } from 'components-design-system';
import PrimeField from 'components/PrimeField/PrimeField';
import CreateActivityModal from './subcomponents/CreateActivityModal';
import ActivityStatusBox from 'components/ActivityStatusBox/ActivityStatusBox';
import EditWorkflowModal from 'components/WorkflowTracker/EditWorkflowModal/EditWorkflowModal';
import ActivitiesTimeline from 'components/charts/ActivitiesTimeline/ActivitiesTimeline';
import ObjPill from 'components/badges/ObjPill/ObjPill'

// apis
import { useGetStudyActivities, usePostStudyActivity, useGetStudyActivitiesTimeline } from 'api/hooks/studies/useActivitiesApi';
import { useUserPermissions } from 'api/hooks';
import { useGetStudy } from 'api/hooks/studies/useStudiesApi';
import { useGetActivityTypes } from 'api/hooks/activities/useActivitiesApi';
import { useGetStudyTeam } from 'api/hooks/studies/studyTeamsApi';
import { useGetStudyHeader } from 'api/hooks/studies/useStudiesApi';

import { useGetWorkflow } from 'api/hooks/workflows/useWorkflowsApi';

// context
import useWorkflowTracker from 'context/hooks/useWorkflowTracker';

// cell renderers
import ActivityCellRenderer from '../Assessments/AssessmentsGrid/ActivityCellRenderer/ActivityCellRenderer';
import { dateColDef } from 'components/grids/columnTemplates';

// styles
import './Activities.scss'

const Activities = () => {
  const { studyId } = useParams()
  const { data: study } = useGetStudy(studyId)
  const { data: activities = [] } = useGetStudyActivities(studyId)
  const { data: permissions = {} } = useUserPermissions(studyId)
  const { data: team } = useGetStudyTeam(studyId)
  const { data: headerData } = useGetStudyHeader(studyId)
  const { data: activityTypes = [] } = useGetActivityTypes()
  const { data: timeline = [] } = useGetStudyActivitiesTimeline(studyId)
  const postStudyActivity = usePostStudyActivity(studyId)
  const onTime = useRef(0)
  const atRisk = useRef(0)
  const late = useRef(0)
  const [filters, setFilters] = useState({
    hidePreviousActivities: true,
    hideCancelledActivities: false,
    hideCompletedAndFutureActivities: false
  })

  const COL_DEFS = useMemo(() => {
    return [
      {
        headerName: "Activity",
        field: "project_title",
        cellRenderer: ActivityCellRenderer,
        width: 220,
        flex: 1,
      }, {
        headerName: "Registry",
        field: "registry_name",
        cellRenderer: "RegistryCellRenderer",
        width: 120,
      }, {
        headerName: "State",
        field: "curr_state",
        width: 150,
        suppressSizeToFit: true,
        cellRenderer: "WorkflowBadge",
        cellRendererParams: {
          list: activities
        },
      }, {
        headerName: "As Of Date",
        field: "last_action_time",
        ...dateColDef
      }, {
        headerName: "Assessment",
        field: "attached_assessments",
        flex: 1,
        cellRenderer: params => {
          return <div className="attached-assessments-pill-container">
            {params.value.map((assess, index) => {
              return (
                <ObjPill
                  key={`/study/${studyId}/assessment/${assess.assessment_pk}`}
                  url={`/study/${studyId}/assessment/${assess.assessment_pk}`}
                  label={assess.assessment_name} />
              )
            })}
          </div>
        }
      }, {
        headerName: "Due Date",
        field: "project_due_date",
        ...dateColDef
      }, {
        headerName: "Status",
        field: "status",
        cellRenderer: "StatusCellRenderer",
        flex: 1,
      }
    ]
  }, [activities])

  const formattedTimeline = useMemo(() => {
    let res = []

    timeline.forEach(activity => {
      res.push({
        project_id: activity.id,
        project_title: activity.project_title,
        study_db_id: studyId,
        study_id: study.study_id,
        start_date: dayjs(activity.start_date).format("YYYY-MM-DD"),
        end_date: dayjs(activity.end_date).format("YYYY-MM-DD"),
        status: activity.status,
        milestones: [
          {
            title: "Compliance Due Date",
            date: activity.milestones.compliance_due_date
          },
          {
            title: "Activity Due Date",
            date: activity.milestones.project_due_date
          }
        ]
      })
    })

    return res
  }, [timeline])

  const [openCreateActivity, setOpenCreateActivity] = useState(false)
  const [newActivityIds, setNewActivityIds] = useState(null)

  const {
    workflowTrackerData,
    setWorkflowTrackerData,
    setWorkflowId,
    setWorkflowModel,
    setOpenEditStatesPanel
  } = useWorkflowTracker()

  const { data: workflowTrackerDataResp, isLoading: isWorkflowLoading } = useGetWorkflow({
    workflowId: newActivityIds?.workflowId,
    workflowModel: "activity"
  })

  useEffect(() => {
    if (workflowTrackerDataResp && isWorkflowLoading === false) {
      setWorkflowId(newActivityIds?.workflowId)
      setWorkflowModel("activity")
      setWorkflowTrackerData({
        ...workflowTrackerDataResp,
        state_data: {
          ...workflowTrackerDataResp.state_data,
          tracker_data: workflowTrackerDataResp.state_data.tracker_data.map(state => {
            return {
              ...state,
              start_state_date: state?.start_state_date?.split("T")?.[0],
              end_state_date: state?.end_state_date?.split("T")?.[0],
            }
          })
        }
      })
      setOpenEditStatesPanel(true)
    }
  }, [isWorkflowLoading])

  const onChangeFilters = (key, value) => {
    setFilters({
      ...filters,
      [key]: value
    })
  }

  const keys = ["conception_date", "pra", "fsa", "fpi", "lpo", "pcd", "fcsr"]
  const labels = {
    conception_date: "Conception date",
    fcsr: "CSR Completed",
  }

  const milestones = useMemo(() => {
    if (headerData) {
      return keys.map(key => {
        let milestone = headerData[key]

        return {
          key,
          label: labels[key] ? labels[key] : key.toUpperCase(),
          values: {
            ...milestone
          }
        }

      })
    }
    return []
  }, [headerData])

  const filteredRowData = useMemo(() => {
    if (activities) {
      const filteredData = activities?.map(activity => ({ ...activity, study_id: Number(studyId) })).filter(activity => {
        if (filters.hideCancelledActivities && activity.curr_state === "Cancelled") return false
        else if (filters.hideCompletedAndFutureActivities && activity.progress_status === "completed") return false
        return true
      }) || []

      onTime.current = 0;
      atRisk.current = 0;
      late.current = 0;

      filteredData.forEach(activity => {
        if (activity.status === "Not at Risk") onTime.current++
        else if (activity.status === "At Risk") atRisk.current++
        else if (activity.status === "Late") late.current++
      })

      return filteredData
    }

    return
  }, [activities, filters])

  const onCreateActivity = (formData) => {
    let request = {
      ...formData
    }

    request.project_type_id = activityTypes.find(item => item.title === request.project_type_id).id

    if (!request.comment_text || request.comment_text === "") delete request.comment_text
    postStudyActivity({ body: request }, {
      onSuccess: (resp) => {
        setNewActivityIds({
          activityId: resp.data.project_id,
          workflowId: resp.data.workflow_id
        })
        setOpenCreateActivity(false)
      }
    })
  }

  let activityStatusBoxStyle = {
    height: 124,
    width: 149,
    marginRight: 22,
    justifyContent: 'space-between',
    paddingTop: 15,
    paddingBottom: 26
  }

  return (
    <div className="study-activities">
      <div className="study-activities-panel">
        <div className="study-activities-head">
          <div className="study-activities-titles">
            <p className="study-activities-title">Activities</p>
            <p className="study-activities-subtitle">All activities for {study?.study_id} in Prime and historical activities from CTG.</p>
          </div>
          {permissions?.["project.create"] === true &&
            <Button
              size="sm"
              onClick={() => setOpenCreateActivity(true)}
              variant="primary">
              Create Activity
            </Button>
          }
        </div>
        <div className="study-activities-status">
          <ActivityStatusBox
            name={"On Time Activities"}
            variant={"on-time"}
            value={onTime.current}
            style={activityStatusBoxStyle} />
          <ActivityStatusBox
            name={"At Risk Activities"}
            variant={"at-risk"}
            value={atRisk.current}
            style={activityStatusBoxStyle} />
          <ActivityStatusBox
            name={"Late Activities"}
            variant={"late"}
            value={late.current}
            style={activityStatusBoxStyle} />
          <div className="study-activities-status-checkboxes">
            <PrimeField
              schema={{
                type: "checkbox",
                label: "Hide Previous Activities Pulled from CTG  (not in Prime)"
              }}
              value={filters.hidePreviousActivities}
              onChange={val => onChangeFilters("hidePreviousActivities", val)} />
            <PrimeField
              schema={{
                type: "checkbox",
                label: "Hide Cancelled Activities"
              }}
              value={filters.hideCancelledActivities}
              onChange={val => onChangeFilters("hideCancelledActivities", val)} />
            <PrimeField
              schema={{
                type: "checkbox",
                label: "Hide Completed and Future Activities"
              }}
              value={filters.hideCompletedAndFutureActivities}
              onChange={val => onChangeFilters("hideCompletedAndFutureActivities", val)} />
          </div>
        </div>
      </div>
      {/* <div> */}
      <GridTypeA
        rowData={filteredRowData}
        columnDefs={COL_DEFS}
        domLayout='autoHeight'
      />
      {/* </div> */}
      <div
        className="study-activities-head"
        style={{ marginTop: 34 }}
      >
        <div className="study-activities-titles">
          <p className="study-activities-title">Timeline</p>
          <p className="study-activities-subtitle">All activities for {study?.study_id} visually represented along a timeline</p>
        </div>
      </div>
      <ActivitiesTimeline
        milestones={milestones}
        timeline={formattedTimeline} />
      <CreateActivityModal
        studyId={studyId}
        open={openCreateActivity}
        onCreateActivity={onCreateActivity}
        onCancel={() => setOpenCreateActivity(false)} />
      {newActivityIds && workflowTrackerData && <EditWorkflowModal
        data={{
          workflow_id: newActivityIds?.workflowId,
          activity_id: newActivityIds?.activityId,
          study_uid: study.study_id
        }}
        team={team}
        type="study"
      />}
    </div>
  );
};

export default Activities;