
import React, { useRef } from 'react';
import { InputNumber as AntInputNumber } from 'antd'

import ReadOnlyField from '../ReadOnlyField/ReadOnlyField'

const InputNumber = ({
  size = "medium",
  type = "text",
  readOnly,
  defaultValue, // to force value change
  placeholder, // read-only fieldName
  readOnlyPlaceholder,
  onChange, // returns SyntheticBaseEvent // evt.target.value
  rows = 2, // "textarea"
  focusOnMount = false,
  tooltip = null, // { title, align }
  className,
  ellipsis = false,
  allowClear,
  value, // default true for antd Input // will cause antd TextArea to overflow outside its container
  ...moreProps // id, value (controlled), allowClear, onBlur, prefix, style
}) => {
  const inputRef = useRef(null)
  const classNames = ['prime-text-input']
  if (className) classNames.push(className);
  if (readOnly) classNames.push('read-only');

  // call parent function
  const handleChange = (value) => {
    if (onChange) {
      onChange(value) // value
    }
  }

  if (readOnly) {
    let displayValue = ""
    if (value !== null && value !== undefined) displayValue = value
    else if (defaultValue !== null && defaultValue !== undefined) displayValue = defaultValue

    return <ReadOnlyField
      placeholder={readOnlyPlaceholder || placeholder}
      ellipsis={ellipsis}
    >
      {displayValue}
    </ReadOnlyField>
  }

  return (
    <AntInputNumber
      ref={inputRef}
      size={size}
      className={classNames.join(' ')}
      value={value}
      defaultValue={defaultValue}
      placeholder={placeholder}
      onChange={handleChange}
      {...moreProps} />
  );
};

export default React.memo(InputNumber);