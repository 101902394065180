import React, { useEffect, useState, useRef } from 'react';
import dayjs from 'dayjs';

import isElementsOverlapping from 'utilities/isElementsOverlapping';

// apis
import { useUserSettings } from 'api/hooks';

// styles
import './milestone-marker.scss'

const MilestoneMarker = (props) => {
  const {
    milestones,
    milestone,
    offset,
    state,
    stateIndex,
    tempTrackerData,
    milestoneMarkerIndex,
    milestoneOffsets,
    isLast,
    milestoneIndex,
  } = props;
  const dateLabelRef = useRef()
  const [left, setLeft] = useState(true);
  const [height, setHeight] = useState(60)
  const overlappingHeight = 125
  const { data: userSettings } = useUserSettings()

  let markerRef = useRef()

  let leftPosition = {
    markerTop: {
      left: -4
    },
    dateContainer: {
      left: -98
    },
    dateText: {
      textAlign: "right"
    }
  }

  useEffect(() => {
    if (stateIndex !== 0 && (state.action_id === tempTrackerData?.[stateIndex]?.action_id && tempTrackerData.length - 2 === stateIndex)
      && dayjs(state.end_state_date).format("YYYY-MM-DD") === dayjs(milestone.value).format("YYYY-MM-DD")) {
      setLeft(false);
    }
  }, [state])

  useEffect(() => {
    if (milestones.length > 0) {
      let isOdd = milestoneIndex % 2 === 1 ? true : false
      let milestoneHeight = 60 + (milestoneIndex * 30)

      if (isOdd) {
        setLeft(false)
      }
      setHeight(milestoneHeight)

    }
  }, [milestones, milestone, state])

  useEffect(() => {
    if (isLast && overlappingHeight === 125 && state.milestones?.length > 1) {
      let currHeight = overlappingHeight + (50 * milestoneIndex);

      setHeight(currHeight)
      setLeft(false)
    }
  }, [milestones, milestone, state])

  let calcOffset = offset
  if (isLast) {
    calcOffset = 0
  }

  return (
    <div
      id={`milestone-marker-${stateIndex}-${milestoneMarkerIndex}`}
      className="milestone-marker"
      style={{
        height: height,
        left: `${calcOffset}%`,
        borderLeft: "1px solid #92959A"
      }}>
      <div className="milestone-marker-top-container">
        <div
          className="milestone-marker-top"
          style={left ? leftPosition.markerTop : {}}
        >
        </div>
      </div>
      <div
        className={`milestone-date milestone-date-${stateIndex}`}
        ref={markerRef}
        style={left ? leftPosition.dateContainer : {}}
        id={milestone.id}>
        <div
          className="date-label-title"
          style={left ? leftPosition.dateText : {}}>
          {milestone.type.replaceAll("_", " ").toUpperCase()}
        </div>
        <div
          className="date-label"
          ref={dateLabelRef}
          style={left ? leftPosition.dateText : {}}>
          {dayjs(milestone.value).format(userSettings?.date_format)}
        </div>
      </div>
    </div>
  );
};

export default MilestoneMarker;