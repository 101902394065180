import { useEffect, useState, useMemo } from 'react';
import { Popover } from 'antd'

import './PRSErrors.scss';

const iconProps = {
  info: {
    color: "#18669E",
    icon: "info-circle"
  },
  warning: {
    color: "#ECB81E",
    icon: "exclamation-triangle",
  },
  error: {
    color: "#D7542C",
    icon: "do-not-enter"
  }
}

const PRSErrors = ({ data, parentData }) => {
  const [error, setError] = useState("N/A")
  const [messages, setMessages] = useState([])

  useEffect(() => {
    if (data?.upload_report) {
      let uploadStatus = data.upload_report.uploadStatus;
      let recordStatus = data.upload_report.recordStatus;
      let validationMessages = data.upload_report.validationMessages;
      let failureMessage = data.upload_report.failureMessage;

      let messages = []

      if (validationMessages?.validationMessage) {
        messages = Array.isArray(validationMessages.validationMessage) ? validationMessages.validationMessage : [validationMessages.validationMessage]
      }

      if (messages.length > 0 && failureMessage) {
        let failureMsg = {
          severity: "ERROR",
          message: failureMessage,
          source: "Server"
        }
        messages = [failureMessage, ...messages]
        // let foundErrors = messages.some(msg => msg.severity === "ERROR");

        // if (foundErrors) {
        setMessages(messages)
        // if (failureMessage) {
        //   setError(failureMessage)
        // } else {
        setError("Record was not released due to error(s).")
        // }
        // }
      } else if (uploadStatus === "ERROR") {
        if (failureMessage) {
          setError(failureMessage)
        } else {
          setError("Record was not released because the record was not modified in the submission.")
        }
      }
    } else if (data?.upload_failed && parentData?.task_status?.result) {
      setError(parentData?.task_status?.result)
    }
  }, [data])

  const getStatus = useMemo(() => {
    let statuses = []
    let errors = []
    let warnings = []
    let notes = []

    if (messages) {
      messages.forEach(message => {
        if (message.severity === "ERROR") {
          errors.push(message)
        } else if (message.severity === "WARNING") {
          warnings.push(message)
        } else if (message.severity === "NOTE") {
          notes.push(message)
        }
      })
    }

    if (errors.length > 0) {
      statuses.push({
        type: "Error(s)",
        icon: <i className={`fas fa-${iconProps.error.icon}`} style={{ color: iconProps.error.color, fontSize: ".75rem" }} />,
        messages: errors
      })
    }
    if (warnings.length > 0) {
      statuses.push({
        type: "Warning(s)",
        icon: <i className={`fas fa-${iconProps.warning.icon}`} style={{ color: iconProps.warning.color, fontSize: ".75rem" }} />,
        messages: warnings
      })
    }
    if (notes.length > 0) {
      statuses.push({
        type: "Note(s)",
        icon: <i className={`fas fa-${iconProps.info.icon}`} style={{ color: iconProps.info.color, fontSize: ".75rem" }} />,
        messages: notes
      })
    }

    return statuses
  }, [messages])

  if (error === "Record was not released due to error(s).") {
    return (
      <Popover content={(
        <div className="prs-upload-status-popover-container">
          {getStatus?.map(status => {
            return (
              <div>
                <div style={{ fontWeight: "600" }}>
                  {status.icon} {status.type}
                </div>
                <ul>
                  {status.messages.map(message => {
                    return (
                      <li>
                        <div>
                          <div style={{ fontWeight: "600" }}>Source: {message.source}</div>
                          <div>Error: {message?.message}</div>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </div>
            )
          })}
        </div>
      )}>
        <div style={{ textDecoration: "underline" }}>Record was not released due to {messages.length} error(s).</div>
      </Popover>
    )
  }

  return (
    <div>
      {error}
    </div>
  )
};

export default PRSErrors;