import React, { useState, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

// components
import Header from '../components/Header/Header';
import StatisticalAnalysisItem from './StatisticalAnalysisItem';

const OutcomeMeasure = (props) => {
  const {
    index, 
    data,
    bgColor,
    schema,
    view,
    formData
  } = props;
  const [tables, setTables] = useState([]);

  useEffect(() => {
    if(data) {
      let startIndex = 0;
      let endIndex = 5;
      let tables = [];

      let allGroups = []
      if(data?.arms) {
        allGroups = [...data.arms]
      }

      if(data?.subject_analysis_sets) {
        allGroups = [ ...allGroups, ...data.subject_analysis_sets]
      }

      if(allGroups.length > endIndex) {
        while(allGroups.length > startIndex) {
          if(endIndex >= allGroups.length) endIndex = allGroups.length;
          let table = {
            ...data,
            arms: allGroups.slice(startIndex, endIndex),
            measure_rows: data.measure_rows.map(row => {
              return {
                ...row,
                categories: row.categories.map(category => {
                  return {
                    ...category,
                    values: category.values.slice(startIndex, endIndex)
                  }
                })
              }
            })
          }
  
          tables.push(table);
          startIndex = endIndex;
          endIndex += 5;
        }
      } else {
        tables = [{
          ...data,
          arms: allGroups
        }]
      }
      setTables(tables)
    }
  }, [data])

  const displayCTGProps = () => {
    return schema.properties[view].properties.outcome_measure.prop_keys.filter(key => key !== "type").map((key) => {
      let obj = schema.properties[view].properties.outcome_measure.properties;
      let value = data?.[key]

      if(data?.[key] && typeof data[key] === "object") {
        value = [];
        Object.keys(data[key]).forEach(nestedKey => {
          value.push(data[key][nestedKey]);
        })
        value.join(" ")
      }

      return (
        <tr key={`om-${index}-${key}`}>
          <td style={{ width: 250, fontWeight: "bold", backgroundColor: bgColor }}>{obj[key]?.title}</td>
          <td>
            {value}
          </td>
        </tr>
      )
    })
  }

  const getCellValue = (category, cell, row, colIndex) => {
    let value = `${cell.value || "-"}`;
    if(category.title === "Number Analyzed") {
      value += " participants";
      return value;
    }
    if(data?.parameter_type?.toLowerCase() === "number" || data?.countable === "Countable") {
      if(data?.dispersion_type?.toLowerCase() === "not applicable" || data?.countable === "Countable") {
        return value
      } else if(data?.dispersion_type?.toLowerCase() === "confidence interval") {
        value += ` (${(cell?.dispersion_value ? cell?.dispersion_value : "-")} to ${(cell?.dispersion_value_upper_bound ? cell?.dispersion_value_upper_bound : "-")})`
        return value
      }
    }

    if(data.parameter_type !== "Count of Participants" && data.parameter_type !== "Number") {
      if(data?.dispersion_type?.toLowerCase() === "standard deviation"
        || data?.dispersion_type?.toLowerCase() === "standard error"
        || data?.dispersion_type?.toLowerCase() === "geometric coefficient of variation"
        || data?.countable === "Countable") {
        value += ` (${(cell.dispersion_value ? cell.dispersion_value : "-")})`
      } else {
        value += ` (${(cell.dispersion_value ? cell.dispersion_value : "-")} to ${(cell.dispersion_value_upper_bound? cell.dispersion_value_upper_bound : "-")})`
      }
    } else if(row.categories?.[0]?.title === "Number Analyzed" 
    && row.categories?.[0]?.values[colIndex].value 
    && cell.value
    && typeof Number(cell.value) === "number" 
    && Number(cell.value) !== NaN) {
      let percent = 0;
      percent = (Number(cell.value) / Number(row.categories[0].values[colIndex].value)) * 100;
      if(percent === NaN) percent = 0
      value += ` (${percent?.toFixed(2)}%)`
    }

    return value;
  }

  const getMeasureType = () => {
    let value = `${data.parameter_type}`;
    if(data.countable === "Countable" && view === "eudract") {
      return "Countable"
    }
    if(data.parameter_type !== "Count of Participants" && data.parameter_type !== "Number") {
      value += ` (${data.dispersion_type || "NA"})`
    }

    if(data?.dispersion_type?.toLowerCase() === "confidence interval") {
      value = `${data.parameter_type} (${data?.ci_pct_pevel}% ${data.dispersion_type})`
    }

    return value;
  }

  let firstColumnWidth = 250

  const displayMeasureProps = (table) => {
    let colSpan = 0;

    if(table?.arms) {
      colSpan += table.arms.length
    }
    if(table?.subject_analysis_sets) {
      colSpan += table.subject_analysis_sets.length
    }

    return (
      <>
        {view === "ctg" && 
          <tr style={{ backgroundColor: bgColor }}>
            <td colSpan={2} style={{ fontWeight: "bold", width: firstColumnWidth }}>
              Analysis Population Description
            </td>
            <td colSpan={colSpan}>
              {data?.population_analysis_description}
            </td>
          </tr>
        }
        {data?.type_units_analyzed && (
          <tr style={{ backgroundColor: bgColor }}>
            <td colSpan={2} style={{ fontWeight: "bold", width: firstColumnWidth }}>
              Type of Units Analyzed
            </td>
            <td colSpan={colSpan}>
              {data?.type_units_analyzed}
            </td>
          </tr>
        )}
      </>
    )
  }

  // if units analyzed exists
  let showNumUnitsAnalyzed = false;
  if(data.arms) {
    showNumUnitsAnalyzed = !data?.arms?.some(arm => !arm.num_units_analyzed)
  }

  return (
    <Container>
      <Title>{`${index}. ${data.measure_type} Outcome Measure`}</Title>
      <OutcomeMeasureContainer>
        <Table>
          <tbody>
            {displayCTGProps()}
          </tbody>
        </Table>
        <HeaderContainer>
          <Header
            title={"Reporting Groups"}
            bgColor={bgColor}
            arms={data?.arms || []}
            subjectAnalysisSets={data?.subject_analysis_sets || null}
            source="outcome"
            data={data}
            view={view}
            formData={formData}/>
        </HeaderContainer>
        {tables?.map((table, i) => {
          return (
            <Table key={`om-preview-${index}-${i}`}>   
              <tbody>
                {displayMeasureProps(table)}
                <tr style={{ backgroundColor: bgColor, fontWeight: "bold"}}>
                  <td colSpan={2}/>
                  {table?.arms &&
                    table.arms.map(arm => (
                      <td key={arm.id}>
                        {arm.title}
                      </td>
                    ))
                  }
                </tr>
                {view === "eudract" && (
                  <tr style={{ backgroundColor: bgColor }}>
                    <td colSpan={2} style={{ fontWeight: "bold", width: firstColumnWidth }}>Subjects Analyzed</td>
                    {table?.arms &&
                      table.arms.map(arm => (
                        <td key={`${arm.id}-subjects-analyzed`}>
                          {arm.subjects_analyzed}
                          {arm.subjects_analyzed == 0 && (
                            <>
                              <br/>
                              {arm.comment}
                            </>
                          )}
                        </td>
                      ))
                    }
                  </tr>
                )}
                {view === "ctg" && (
                  <tr style={{ backgroundColor: bgColor }}>
                    <td colSpan={2} style={{ fontWeight: "bold", width: firstColumnWidth }}>
                      Overall Number of Participants Analyzed
                    </td>
                    {table?.arms &&
                      table.arms.map(arm => (
                        <td key={`${arm.id}-subjects-analyzed`}>
                          {arm?.subjects_analyzed}
                        </td>
                      ))
                    }
                  </tr>
                )}
                {view === "ctg" && showNumUnitsAnalyzed && (
                  <tr style={{ backgroundColor: bgColor }}>
                    <td colSpan={2} style={{ fontWeight: "bold", width: firstColumnWidth }}>
                      Overall Number of Units Analyzed
                    </td>
                    {table?.arms &&
                      table.arms.map(arm => (
                        <td key={`${arm.id}-subjects-analyzed`}>
                          {arm.num_units_analyzed}
                        </td>
                      ))
                    }
                  </tr>
                )}
                {table?.measure_rows?.map((row) => {
                  return (
                    <Fragment key={uuidv4()}>
                      {row?.categories?.map((category, i) => {

                        return (
                          <tr key={uuidv4()}>
                            {i === 0 && (
                              <th 
                                style={{ backgroundColor: bgColor, width: firstColumnWidth }}
                                colSpan={1} 
                                rowSpan={row.categories.length}>
                                <MeasureTitle>{row.title}</MeasureTitle>
                                <MeasureProperties><p style={{ fontWeight: "bold", fontSize: ".7rem", marginBottom: 0  }}>Measure Type:</p> {getMeasureType()}</MeasureProperties>
                                <MeasureProperties><p style={{ fontWeight: "bold", fontSize: ".7rem", marginBottom: 0  }}>Unit of Measure:</p> {table.unit_of_measure}</MeasureProperties>
                              </th>
                            )}
                            {(table?.arms?.length > 0 || table?.subject_analysis_sets?.length > 0) && 
                              <>
                                <td 
                                  colSpan={1} 
                                  style={{ backgroundColor: bgColor, width: firstColumnWidth }}>
                                  {category.title}
                                </td>
                                {category?.values?.map((cell, j) => {
                                  let value = getCellValue(category, cell, row, j)
                                  return (
                                    <td  key={uuidv4()}>
                                      <div>{value}</div>
                                      {cell.na_comment && 
                                      (value === "NA" || cell.dispersion_value === "NA" || cell.dispersion_value_upper_bound === "NA") && (
                                        <div>{cell.na_comment}</div>
                                      )}
                                    </td>
                                  )
                                })}
                              </>
                            }
                          </tr>
                        )
                      })}
                    </Fragment>
                  )
                })}
              </tbody>
            </Table>
        )})}

        {/* Statistical Analysis Sets */}
        <StatisticalAnalysisContainer>
          {data?.outcome_measure_analysis?.map((item, index) => (
            <StatisticalAnalysisItem 
              key={`${data.id}-statistical-analysis-item-${index}`}
              index={index}
              data={item} 
              schema={schema}
              view={view}
              bgColor={bgColor}
              outcomeMeasure={data}/>
          ))}
        </StatisticalAnalysisContainer>
      </OutcomeMeasureContainer>
    </Container>
  );
};

export default OutcomeMeasure;

const Table = styled.table`
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 16px;

  td, th {
    border: 1px solid #444;
    text-align: left;
    padding: 8px 12px;
  }

  th {
    font-weight: bold;
    background-color: rgb(238, 238, 254);
    text-align: center;
  }
`

const Container = styled.div`
  margin-top: 16px;
`

const OutcomeMeasureContainer = styled.div`
  border: 1px solid #aaa;
  border-radius: 15px;
  padding: 8px;
`

const Title = styled.h6`
  font-weight: bold;
  margin-bottom: 8px;
`


const MeasureTitle = styled.div`
  font-weight: bold;
  margin-bottom: 12px;
`

const MeasureProperties = styled.div`
  font-size: .7rem;
  font-weight: normal;
  margin-top: 2px;
`

const StatisticalAnalysisContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const HeaderContainer = styled.div`
  margin-bottom: 16px;
`