import { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useShallow } from 'zustand/react/shallow'

// components
import PrimeField from 'components/PrimeField/PrimeField';
import ArmActions from '../../../components/ArmActions/ArmActions';

// context
import useAuthoringDataStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringDataStore';
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';

const Sas = ({
  set,
  sets,
  outcomeData,
  setOutcomeData,
  measure,
  colIndex,
  rowIndex,
  handleLeft,
  handleRight,
  handleDelete,
  measureIndex
}) => {
  const {
    formData,
  } = useAuthoringDataStore(
    useShallow(state => ({
      formData: state.formData,
    }))
  )

  const {
    readOnly
  } = useAuthoringViewStore(
    useShallow(state => ({ readOnly: state.readOnly }))
  )

  const { ref: visibleRef, inView: inViewport } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  });

  const [showActions, setShowActions] = useState(false)

  const onChange = (e, set, fieldKey) => {
    let tempData = { ...outcomeData };

    Object.keys(tempData).forEach(registry => {
      let foundOMIndex = tempData[registry].outcome_measures.findIndex(item => item.id === measure.id);

      if (foundOMIndex !== -1) {
        if (registry === "eudract") {
          let foundSasIndex = tempData[registry].outcome_measures[foundOMIndex]?.subject_analysis_sets?.findIndex(item => item.registry_arm_id === set.registry_arm_id);
          if (foundSasIndex !== -1) {
            tempData[registry].outcome_measures[foundOMIndex].subject_analysis_sets[foundSasIndex][fieldKey] = e;
          }
        } else {
          let foundArmIndex = tempData[registry].outcome_measures[foundOMIndex]?.arms?.findIndex(item => item.registry_arm_id === set.registry_arm_id);
          if (foundArmIndex !== -1) {
            tempData[registry].outcome_measures[foundOMIndex].arms[foundArmIndex][fieldKey] = e;
          }
        }
      }
    })



    setOutcomeData(tempData)
  }

  const getBCGroup = () => {
    let foundSas = formData.results?.baseline_characteristics?.baseline_characteristics_table?.eudract?.arms?.find(arm => {
      if (arm) {
        let armIdArr = arm?.id?.split(".");
        let armId = armIdArr?.[armIdArr.length - 1];

        let temp = set.id.split("OutcomeRptGroup");
        let groupId = temp[temp.length - 1];
        if (groupId[0] === ".") groupId = groupId.slice(1);

        if (groupId === armId) return arm;
      }
    })

    if (!foundSas) {
      foundSas = formData.results?.baseline_characteristics?.baseline_characteristics_table?.eudract?.subject_analysis_sets?.find(sas => {
        if (sas) {
          let armIdArr = sas?.id?.split(".");
          let armId = armIdArr?.[armIdArr.length - 1];

          let temp = set.id.split("OutcomeRptGroup");
          let groupId = temp[temp.length - 1];
          if (groupId[0] === ".") groupId = groupId.slice(1);

          if (groupId === armId) {
            return sas;
          }
        }
      })
    }

    if (foundSas) return foundSas
    return {
      title: set.title,
      description: set.description,
      baseline_analysis_population: set.baseline_analysis_population
    }
  }

  let groupProps = getBCGroup();

  return (
    <td
      ref={visibleRef}
      className="subject-analysis-set"
      style={{ maxWidth: 250 }}
      colSpan={1}
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}
    >
      {inViewport ? (
        <>
          <div>{groupProps.title}: {groupProps.description}</div>
          <div>Subjects Started in Arm: {groupProps.baseline_analysis_population}</div>
          <PrimeField
            schema={{
              type: "number",
              label: "Subjects Analyzed",
              orientation: 'vertical',
            }}
            containerStyle={{ marginBottom: 12 }}
            readOnly={readOnly}
            onChange={e => onChange(e, set, "subjects_analyzed")}
            value={set?.subjects_analyzed} />
          <PrimeField
            schema={{
              type: "textarea",
              label: "Comment",
              orientation: 'vertical',
              placeholder: "Note: The comment is mandatory when the number of subjects analysed is zero",
              style: { marginTop: 8 }
            }}
            readOnly={readOnly}
            onChange={e => onChange(e, set, "comment")}
            value={set.comment || ""} />
          <ArmActions
            arms={sets}
            arm={set}
            colIndex={colIndex}
            showActions={showActions}
            handleLeft={rowIndex === 0 ? () => handleLeft(set) : null}
            handleRight={rowIndex === 0 ? () => handleRight(set) : null}
            canDelete={rowIndex === 0 ? true : false}
            handleDeleteArm={handleDelete}
            disableDeleteAll={false}
            jpath={`om-${measure.id}-sas-${set.id}`}
            periodIndex={measureIndex} />
        </>
      ) : (
        <div style={{ padding: 10 }} />
      )}
    </td>
  );
};

export default Sas;