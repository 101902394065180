import { useState } from 'react';
import { useShallow } from 'zustand/react/shallow'

// components
import CommentModal from '../CommentModal/CommentModal';
import RJTableCommentContainer from '../../../components/RJTableCommentContainer/RJTableCommentContainer';

// content
import useAuthoringCommentsStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringCommentsStore';

const CellActions = ({
  comment,
  saveComment,
  showActions,
  setShowActions,
  jpath,
  type,
  hideComment = false
}) => {
  const [openModal, setOpenModal] = useState(false)
  const [openCommentsForm, setOpenCommentsForm] = useState(false);

  const {
    commentsMap
  } = useAuthoringCommentsStore(
    useShallow(state => ({
      commentsMap: state.commentsMap
    }))
  )

  let style = {
    color: "#9566AB",
    cursor: "pointer"
  }

  if (comment) {
    style = {
      ...style,
      fontWeight: "700",
    }
  }

  if(hideComment === true) return

  return (
    <div className="flex items-center" style={{ gap: 8, position: "relative" }} id={jpath + "-cell"}>
      {(showActions || comment) && type !== "drop_withdrawn_reason_list" && <i
        className="fal fa-info-circle"
        style={style}
        onClick={() => setOpenModal(true)} />}
      <RJTableCommentContainer
        label={`Participant flow field`}
        jpath={jpath + "-cell"}
        openCommentsForm={openCommentsForm}
        setOpenCommentsForm={setOpenCommentsForm}
        setShowMenu={setShowActions}
        showMenu={showActions || commentsMap[jpath] || openCommentsForm}
        style={{ marginBottom: 2 }} />
      <CommentModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        saveComment={saveComment}
        selectedComment={comment} />
    </div>
  );
};

export default CellActions;