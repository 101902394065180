import { useState } from 'react';
import { useShallow } from 'zustand/react/shallow'

// components
import PrimeField from 'components/PrimeField/PrimeField';
import RowActions from '../../../components/RowActions/RowActions';

// context
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';

// utils
import { moveLeftHelper, moveRightHelper } from '../../helpers/helpers';

const Withdrawn = ({
  withdrawn,
  onChangeWithdrawn,
  i,
  participantData,
  setParticipantData,
  schema,
}) => {
  const [showActions, setShowActions] = useState(false)
  const {
    readOnly
  } = useAuthoringViewStore(
    useShallow(state => ({ readOnly: state.readOnly }))
  )

  const handleUp = () => {
    let tempData = { ...participantData };
    moveLeftHelper(tempData.pre_assignment_period.not_completed_reason_details, i)
    setParticipantData(tempData)
  }

  const handleDown = () => {
    let tempData = { ...participantData };
    moveRightHelper(tempData.pre_assignment_period.not_completed_reason_details, i)
    setParticipantData(tempData)
  }

  const handleDelete = () => {
    let tempData = { ...participantData };
    tempData.pre_assignment_period.not_completed_reason_details.splice(i, 1)
    setParticipantData(tempData)
  }

  return (
    <tr
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}>
      <td className="header-column">
        <PrimeField
          schema={{
            type: "dropdown",
            items: schema.properties.eudract.properties.pre_assignment_period.not_completed_reason_details.enum
          }}
          readOnly={readOnly}
          value={withdrawn.title || ""}
          onChange={e => onChangeWithdrawn(e, "title", i)} />
        {withdrawn.title === "Other (please specify)" &&
          <PrimeField
            schema={{
              placeholder: "Reason",
              type: "text",
              style: { marginTop: 8 }
            }}
            readOnly={readOnly}
            value={withdrawn.other_name || ""}
            onChange={e => onChangeWithdrawn(e, "other_name", i)} />
        }
        <RowActions
          showActions={showActions}
          setShowActions={setShowActions}
          handleDelete={handleDelete}
          canDelete={true}
          hideOptions={true}
          showDown={participantData.pre_assignment_period.not_completed_reason_details.length - 1 !== i}
          showUp={i !== 0}
          handleUp={handleUp}
          handleDown={handleDown}
          deleteModalTitle="Delete reason?" 
          jpath={`ParticipantFlowGroup.pf-pre-period-withdrawn-${withdrawn.id}`}/>
      </td>
      <td>
        <PrimeField
          schema={{
            placeholder: "Number of Subjects",
            type: "number",
            style: { width: "100%" }
          }}
          readOnly={readOnly}
          value={withdrawn.subjects}
          onChange={e => onChangeWithdrawn(e, "subjects", i)}
        />
      </td>
    </tr>
  );
};

export default Withdrawn;