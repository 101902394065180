import React, { useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow'
import styled from 'styled-components';

// components
import Modal from 'components/Modal/Modal';
import RJCommentForm from '../RJCommentForm/RJCommentForm';
import RJCommentItem from '../RJCommentItem/RJCommentItem';
import { Button } from 'components-design-system'

// hooks
import useAuthoringStore from '../../../hooks/stores/useAuthoringStore';
import { useUserPermissions } from 'api/hooks';
import { useDeleteAuthoringComment } from 'api/hooks/studies/useAuthoringApi';
import useAuthoringCommentsStore from '../../../hooks/stores/useAuthoringCommentsStore';

const RJCommentList = ({
  jpath,
  label,
  setOpenForm,
  setShowMenu,
}) => {

  const authoringId = useAuthoringStore(state => state.authoringId)

  const {
    commentsMap,
  } = useAuthoringCommentsStore(
    useShallow(state => ({ 
      commentsMap: state.commentsMap,
    }))
  )

  const { data: permissions } = useUserPermissions()
  const deleteAuthoringComment = useDeleteAuthoringComment(authoringId)

  const [editComment, setEditComment] = useState(null);
  const [deleteComment, setDeleteComment] = useState(null);
  const [addComment, setAddComment] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const handleEdit = (comment) => {
    setEditComment(comment)
    setAddComment(true)
  }

  const handleDelete = (comment) => {
    setDeleteComment(comment);
    setShowDelete(true)
  }

  const onDeleteComment = () => {
    deleteAuthoringComment({ id: deleteComment.id + "/" }, {
      onSuccess: () => {
        setShowMenu(false);
        setDeleteComment(null);
        setShowDelete(false);
      }
    })
  }

  return (
    <Container>
      <CommentListContainer>
        {commentsMap?.[jpath]?.map((comment, i) => {
          return (
            <RJCommentItem
              key={comment?.id + comment?.text}
              index={i}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              comment={comment} />
          )
        })}
      </CommentListContainer>

      {addComment &&
        <RJCommentForm
          jpath={jpath}
          label={label}
          editComment={editComment}
          setEditComment={setEditComment}
          setShowMenu={setShowMenu}
          setOpenForm={setOpenForm}
          setAddComment={setAddComment} />
      }

      <Button
        variant="link"
        style={{
          paddingRight: 24,
          paddingBottom: 24,
          float: "right",
          fontSize: 12,
          display: permissions["study.authoringcomment.create"] && addComment === false ? 'block' : 'none'
        }}
        onClick={() => setAddComment(true)}>
        Add Comment
      </Button>

      <Modal
        title={"Delete Comment"}
        message={"Are you sure you want to delete this comment? This action can not be undone."}
        open={showDelete}
        onCancel={() => setShowDelete(false)}
        footer={[]}
        width={500}
        type="delete-alt"
        className={"study-overview-authoring-delete-modal"}
        style={{ zIndex: 9 }}>
        <ModalContent>
          <RJCommentItem
            index={-1}
            handleEdit={() => { }}
            handleDelete={() => { }}
            showMenu={false}
            comment={deleteComment} />
        </ModalContent>
        <ActionContainer>
          <Button
            onClick={() => {
              setShowDelete(false);
              setDeleteComment(null);
            }}
            variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={() => onDeleteComment()}
            variant="danger">Delete</Button>
        </ActionContainer>
      </Modal>
    </Container>
  );
};

export default RJCommentList;

const Container = styled.div`
  border-radius: 10px;
`

const CommentListContainer = styled.div`
  padding: 20px 20px 0px 20px;
`

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2px;
  margin-top: 24px;
`

const ModalContent = styled.div`
  width: 100%;
  margin-top: 26px;
  padding: 16px;
`