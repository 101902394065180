import React from 'react'
import { Modal as AntModal } from 'antd'
import PropTypes from 'prop-types'
import { Button } from 'components-design-system'

// assets
import confirmImg from 'assets/images/modal-confirm.png'
import createImg from 'assets/images/modal-create.png'
import createSmImg from 'assets/images/modal-create-sm.png'
import editImg from 'assets/images/modal-edit.png'
import readOnlyImg from 'assets/images/modal-read-only.png'
import reviewImg from 'assets/images/modal-review.jpg'
import deleteImg from 'assets/images/modal-delete.png'
import deleteAltImg from 'assets/images/modal-delete-alt.png'
import reminderImg from 'assets/images/reminder.png'
import './Modal.scss'

const Modal = ({
  type,
  title,
  message,
  onOk,
  okText,
  cancelText,
  disabled = false,
  hideImage,
  children,

  // props pushed to AntModal:
  open = false,
  visible = false, // depracated for prop "open"
  onCancel,
  footer,
  width = 366,
  className,
  destroyOnClose = true,
  imgSrc,
  ...moreProps // onChange (value, options)
}) => {

  const modalImage = () => {
    if (type === '') return null;
    let img = confirmImg
    if(imgSrc) {
      img = imgSrc
    } else if (type === 'create') {
      img = createImg
    } else if (type === 'create-sm') {
      img = createSmImg
    } else if (type === 'edit') {
      img = editImg
    } else if (type === 'read-only') {
      img = readOnlyImg
    } else if (type === 'review') {
      img = reviewImg
    } else if (type === 'delete') {
      img = deleteImg
    } else if (type === 'delete-alt') {
      img = deleteAltImg
    } else if(type === "bell") {
      img = reminderImg
    }
    return <img src={img}
      alt={title}
      className={`modal-image ${type}-modal`}
    />
  }

  const onOkText = () => {
    if (okText) {
      return okText
    } else if (type === 'delete') {
      return 'Delete'
    } else if (type === 'create') {
      return 'Save'
    } else {
      return 'Submit'
    }
  }

  const getWidth = () => {
    if (width) return width;
    if (type === 'delete') {
      return 310
    }
  }

  let antFooter = [
    <Button
      key="cancel-btn"
      variant="primary-outlined"
      size="sm"
      className="close-btn"
      onClick={onCancel}
    >{cancelText ? cancelText : 'Cancel'}</Button>,
    <Button
      key="ok-btn"
      size="sm"
      variant="primary"
      className="ok-btn"
      onClick={onOk}
      disabled={disabled}
    >{onOkText()}</Button>
  ]

  if (footer || footer === null) {
    antFooter = footer;
  }

  let classNames = ['prime-modal']

  if (type) {
    classNames.push(type)
  } else {
    classNames.push('no-type')
  }
  if (className) classNames.push(className);
  if (footer?.length === 0) classNames.push('no-modal-footer');
  if (type === "create-sm") classNames.push('is-image-small');

  return <AntModal
    title={null}
    open={open || visible}
    onCancel={onCancel}
    footer={antFooter}
    width={getWidth()}
    className={classNames.join(" ")}
    destroyOnClose={destroyOnClose}
    {...moreProps}
  >
    <div className="modal-header">
      {hideImage !== true && modalImage()}
      <div className="prime-modal-title">{title}</div>
    </div>
    {message &&
      <div className="modal-message">{message}</div>
    }
    {children}
  </AntModal>
}

Modal.propTypes = {
  type: PropTypes.oneOf(["confirm", "delete", "delete-alt", "create", "create-sm", "edit", "review", "bell", "read-only"]),
  title: PropTypes.string,
  message: PropTypes.node,
  onOk: PropTypes.func,
  okText: PropTypes.node,
  cancelText: PropTypes.node,
  disabled: PropTypes.bool,
  hideImage: PropTypes.bool,
  isImageSmall: PropTypes.bool,
  children: PropTypes.node,
  open: PropTypes.bool,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  footer: PropTypes.node,
  width: PropTypes.oneOfType([
    PropTypes.number, // 90
    PropTypes.string, // "90vw"
  ]),
  className: PropTypes.string,
  isImageSmall: PropTypes.bool,
}

export default Modal