import { useState } from 'react';
import { useShallow } from 'zustand/react/shallow'

// components
import PrimeField from 'components/PrimeField/PrimeField';
import RJTableCommentContainer from '../../../components/RJTableCommentContainer/RJTableCommentContainer';
import CellValidation from '../../../components/CellValidation/CellValidation';

// context
import useResultsTableViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableViewStore';
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';
import useAuthoringCommentsStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringCommentsStore';

// helpers
import { translateProps } from '../../helpers/helpers';

const AdverseProperties = ({
  schema,
  adverseData,
  setAdverseData
}) => {
  const {
    tableView
  } = useResultsTableViewStore()
  const {
    readOnly
  } = useAuthoringViewStore(
    useShallow(state => ({ readOnly: state.readOnly }))
  )

  const onChange = (e, key) => {
    let tempData = { ...adverseData }
    Object.keys(tempData).forEach(registry => {
      let fieldSchema = schema.properties[registry].properties;
      if (key === "assessment_type") {
        if (registry !== tableView) tempData[registry][key] = translateProps[tableView][registry][key][e];
        else tempData[registry][key] = e;
      } else if (key === "source_vocabulary" && (e === "MedDRA" || e === "SNOMED CT")) {
        tempData[registry][key] = e;
        tempData["eudract"]["dictionary_name"] = e;
        tempData["eudract"]["other_dictionary_name"] = null;
      } else if (key === "source_vocabulary") {
        tempData["ctg"][key] = e;
        tempData["eudract"]["other_dictionary_name"] = e;
        tempData["eudract"]["dictionary_overridden"] = "Yes";
        tempData["eudract"]["dictionary_name"] = "Other (specify)";
      } else if (key === "other_dictionary_name") {
        if (tableView === "eudract") {
          tempData[registry][key] = e;
          tempData["ctg"]["source_vocabulary"] = e;
        }
      } else if (fieldSchema[key]) {
        tempData[registry][key] = e;
      }

      if (tableView === "eudract" && adverseData[tableView]["dictionary_name"] !== "Other (specify)") {
        tempData[tableView]["other_dictionary_name"] = null;
        tempData["ctg"]["source_vocabulary"] = tempData["eudract"]["dictionary_name"]
      }
    })

    setAdverseData(tempData);
  }

  return Object.keys(schema.properties[tableView].properties).map(key => {
    let fieldSchema = schema.properties[tableView].properties;
    let disabled = false;

    if (tableView === "eudract" &&
      adverseData?.[tableView]?.["dictionary_name"] !== "Other (specify)" &&
      key === "other_dictionary_name") {
      disabled = true;
    }

    const errorKey = `results_adverse_events_adverse_events_table_${tableView}_${key}`

    return (
      <tr key={`${tableView}-ae-prop-${key}`}>
        <PropertyHeader
          title={fieldSchema[key].title}
          fieldKey={key} />
        <td style={{ width: "600px" }}>
          <PrimeField
            schema={{
              type: fieldSchema[key].type,
              items: fieldSchema[key].enum,
            }}
            disabled={disabled}
            readOnly={readOnly}
            onChange={e => onChange(e, key)}
            value={adverseData?.[tableView]?.[key] || ""} />
          <CellValidation
            table="adverse"
            errorKey={errorKey} />
        </td>
      </tr>
    )
  })
};

export default AdverseProperties;

const PropertyHeader = ({
  title,
  fieldKey
}) => {
  const [openCommentsForm, setOpenCommentsForm] = useState(false)
  const [showActions, setShowActions] = useState(false)

  const {
    commentsMap
  } = useAuthoringCommentsStore(
    useShallow(state => ({
      commentsMap: state.commentsMap
    }))
  )

  let jpath = `ReportedEvents.ae-prop-${fieldKey}`

  const commentStyle = {
    position: "absolute",
    right: 8,
    top: 8
  }

  return (
    <td
      className="header-column"
      colSpan={2}
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}
      style={{ position: "relative" }}>
      {title}
      <RJTableCommentContainer
        label={title}
        jpath={jpath}
        openCommentsForm={openCommentsForm}
        setOpenCommentsForm={setOpenCommentsForm}
        setShowMenu={setShowActions}
        showMenu={showActions || commentsMap[jpath] || openCommentsForm}
        style={commentStyle}
        modalStyle={{ top: 24 }} />
    </td>
  )
}