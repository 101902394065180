import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';

// components
import UnmappedValidationMsg from '../UnmappedValidationMsg/UnmappedValidationMsg'

const ValidationPanel = ({
  unmappedValidations,
}) => {

  const unmappedMessages = useMemo(() => {
    if (unmappedValidations) {
      const msgs = [...unmappedValidations].map((item, index) => {
        item.id = index
        return item
      })
      return <UnmappedMsgsStyled>{msgs?.map((msg) => {
        return <UnmappedValidationMsg
          key={msg.id}
          message={msg}
        />
      })}
      </UnmappedMsgsStyled>
    }
    return null
  }, [unmappedValidations])

  return (
    <Container>
      <ValidationContainer>
        {unmappedMessages}
      </ValidationContainer>
    </Container>
  );
};

export default ValidationPanel;

const Container = styled.div``

const UnmappedMsgsStyled = styled.div`
  height: 75vh;
  overflow: auto;
  font-size: 12px;
  color: #393939;
  margin: 12px;
  margin-top: 0;
`

const ValidationContainer = styled.div``