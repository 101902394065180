import * as React from 'react'
import PropTypes from 'prop-types'
import './Panel.scss'

const Panel = ({
  title,
  actions,
  children,
  iconClassName,
  className,
  noWrap = false,
}) => {
  if(noWrap) return children
  return <div className={`panel${className ? ' ' + className : ''}`}>
    {actions &&
      <div className="panel-actions">{actions}</div>
    }
    <div className="panel-header">
      {iconClassName && <i className={iconClassName} />}
      <div className="panel-title">{title}</div>
    </div>
    <div className="panel-content">
      {children}
    </div>
  </div>
}

Panel.propTypes = {

}

export default Panel