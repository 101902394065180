import React from 'react';
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs';
// import { parse } from 'comment-json';

// components
import GridTypeA from 'components/grids/GridTypeA/GridTypeA';
import { useGetRequestApprovalRecords } from 'api/hooks/requests/requestReviewApis';
import Panel from 'containers/requests/components/Panel/Panel';

const ApprovalRecords = ({
  panelClassName
}) => {
  const { requestId } = useParams()
  const { data: records = [] } = useGetRequestApprovalRecords(requestId)

  const columnDefs = [
    {
      headerName: "Date",
      field: "created_at",
      type: "date",
      cellRenderer: "Date",
      filter: 'agDateColumnFilter',
      width: 120
    },
    {
      headerName: "Time",
      field: "created_at",
      cellRenderer: params => {
        if (params.value) return dayjs(params.value).format("hh:mm A")
      },
      width: 90
    },
    {
      headerName: "Name",
      field: "created_by_fullname",
      cellRenderer: "UserFullnameCellRenderer",
      width: 80,
    },
    {
      headerName: "Files",
      field: "docs",
      cellRenderer: ({ value }) => {
        if (!value) return null
        return value?.map((doc) => {
          if (typeof doc === 'string') doc = JSON.parse(doc);
          return doc.document_name
        }).join(', ')
      }
    },
    {
      headerName: "Outcome",
      field: "",
      width: 100,
      cellRenderer: (params) => {
        if (params.data.is_approved) return "Approved"
        else if (params.data.is_rejected) return "Rejected"
        else if (params.data.is_onhold) return "On Hold"
      }
    },
    {
      headerName: "eSign",
      field: "esign_failed",
      cellRenderer: "SuccessFailedCellRenderer",
      width: 120,
    },
    {
      headerName: "Comments",
      field: "comments",
      width: 150
    },
  ]

  return (
    <Panel
      title="APPROVAL HISTORY"
      iconClassName="fas fa-history"
      className={panelClassName}
    >
      <GridTypeA
        columnDefs={columnDefs}
        rowData={records} />
    </Panel>
  );
};

export default ApprovalRecords;