// TODO: change name to assessmentsApi b/c it contains hooks but is not a hook
import useGetApi from "../useGetApi"
import useMutateApi from '../useMutateApi'

// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

// utils
import getUrl from 'api/apiMap'

const useGetStudyAssessments = (studyId, deactivated = false) => {
  return useGetApi(`${getUrl(apiEndpoints.studies)}${studyId}/assessments/?deactivated=${deactivated ? 'true' : 'false'}`, queryKeys.assessments.list(studyId, deactivated))
}

const usePostRunAllAssessments = (studyId) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(`${apiEndpoints.studies}${studyId}/assessments/run-all`),
    invalidateQueries: [queryKeys.assessments.all],
    successMsg: "Running assessments",
  })
}

const useGetStudyAssessment = (studyId, assessId) => {
  return useGetApi(`${getUrl(apiEndpoints.studies)}${studyId}/assessments/${assessId}/`, queryKeys.assessments.details(studyId, assessId))
}

const useGetAssessmentHistory = (studyId, assessId) => {
  return useGetApi(`${getUrl(apiEndpoints.studies)}${studyId}/assessments/${assessId}/history/`, queryKeys.assessments.history(studyId, assessId))
}

const usePostRunAssessment = (studyId, assessId) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: `${getUrl(apiEndpoints.studies)}${studyId}/assessments/${assessId}/run`,
    invalidateQueries: [queryKeys.assessments.details(studyId, assessId), queryKeys.assessments.details(studyId, assessId)],
    successMsg: "Successfully ran assessment",
  })
}

const usePostSaveAssessment = (studyId, assessId) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: `${getUrl(apiEndpoints.studies)}${studyId}/assessments/${assessId}/save`,
    invalidateQueries: [queryKeys.assessments.details(studyId, assessId), queryKeys.assessments.details(studyId, assessId)],
    successMsg: "Successfully saved assessment",
  })
}

const usePostOverrideAssessment = (studyId, assessId) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: `${getUrl(apiEndpoints.studies)}${studyId}/assessments/${assessId}/override`,
    invalidateQueries: [queryKeys.assessments.study(studyId, assessId)],
    successMsg: "Successfully overriden assessment",
  })
}

const usePostAssessmentActivity = (studyId, assessId) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: `${getUrl(apiEndpoints.studies)}${studyId}/assessments/${assessId}/create-attach-project`,
    invalidateQueries: [queryKeys.assessments.all],
    successMsg: "Successfully created assessment activity",
  })
}

const usePutMissingFields = (authId, deactivated) => {
  return useMutateApi({
    method: "put",
    apiEndpoint: `${getUrl(apiEndpoints.authoring)}${authId}/partial-update`,
    invalidateQueries: [queryKeys.assessments.all, queryKeys.assessments.list(authId, deactivated)],
  })
}

export {
  // assessments
  useGetStudyAssessments,
  usePostRunAllAssessments,
  // assessment
  useGetStudyAssessment,
  useGetAssessmentHistory,
  usePostRunAssessment,
  usePostSaveAssessment,
  usePostOverrideAssessment,
  usePostAssessmentActivity,
  usePutMissingFields
}
