import { useState } from 'react';
import { useShallow } from 'zustand/react/shallow'

// components
import DeleteArmModal from '../../RJBaselineChars/subcomponents/DeleteArmModal/DeleteArmModal';
import RJTableCommentContainer from '../RJTableCommentContainer/RJTableCommentContainer';
import ArmOverrideModal from '../ArmOverrideModal/ArmOverrideModal';
import ArmReorderModal from '../ArmReorderModal/ArmReorderModal';

// context
import useAuthoringViewStore from '../../../../hooks/stores/useAuthoringViewStore';
import useAuthoringCommentsStore from '../../../../hooks/stores/useAuthoringCommentsStore';

// styles
import './ArmActions.scss'

const ArmActions = ({
  canDelete,
  disableDeleteAll,
  handleDeleteArm,
  handleLeft,
  handleRight,
  showActions,
  setShowActions,
  colIndex,
  arms,
  arm,
  jpath,
  manualOverride,
  source,
  type = "arm",
  periodIndex,
  showReorderModal = true
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [openCommentsForm, setOpenCommentsForm] = useState(false)
  const [openEditArm, setOpenEditArm] = useState(false)
  const [openReorderArm, setOpenReorderArm] = useState(false)
  const [direction, setDirection] = useState(null);

  const {
    readOnly
  } = useAuthoringViewStore(
    useShallow(state => ({ readOnly: state.readOnly })),
  )

  const {
    commentsMap
  } = useAuthoringCommentsStore(
    useShallow(state => ({
      commentsMap: state.commentsMap
    }))
  )

  let classNames = ["arm-actions-container"]
  if (showActions) classNames.push("arm-actions-active")

  const onReorder = (direction) => {
    setDirection(direction)
    setOpenReorderArm(true)
  }

  if (readOnly) {
    return <></>
  }

  return (
    <div className="cell-actions">
      {showActions && manualOverride && (
        <i
          className="fal fa-edit"
          style={{ fontSize: 11, cursor: "pointer" }}
          onClick={() => setOpenEditArm(true)} />
      )}
      {canDelete && showActions && (
        <i
          onClick={() => setShowDeleteModal(true)}
          className="fal fa-trash-alt trash-icon" />)
      }
      {jpath && <RJTableCommentContainer
        label={`Arm Cell`}
        jpath={jpath}
        openCommentsForm={openCommentsForm}
        setOpenCommentsForm={setOpenCommentsForm}
        setShowMenu={setShowActions}
        showMenu={showActions || commentsMap[jpath] || openCommentsForm}
        style={{ marginBottom: 2 }} />}

      {colIndex !== 0 && showActions && handleLeft &&
        <i
          className="fal fa-arrow-left arrow-icon"
          style={{ marginRight: 16 }}
          onClick={() => showReorderModal ? onReorder("left") : handleLeft()} />
      }
      {colIndex !== arms.length - 1 && showActions && handleRight &&
        <i
          className="fal fa-arrow-right arrow-icon"
          onClick={() => showReorderModal ? onReorder("right") : handleRight()} />
      }
      <ArmOverrideModal
        open={openEditArm}
        onCancel={() => setOpenEditArm(false)}
        arm={arm}
        source={source} 
        periodIndex={periodIndex}/>
      <ArmReorderModal
        open={openReorderArm}
        onCancel={() => setOpenReorderArm(false)}
        direction={direction}
        setDirection={setDirection}
        arm={arm}
        source={source} 
        colIndex={colIndex}
        periodIndex={periodIndex}/>

      <DeleteArmModal
        arm={arm}
        type={type}
        disableDeleteAll={disableDeleteAll}
        handleDelete={handleDeleteArm}
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal} />
    </div>
  );
};

export default ArmActions;