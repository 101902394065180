import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useShallow } from 'zustand/react/shallow'

// components
import { Button } from 'components-design-system'
import PrimeField from 'components/PrimeField/PrimeField';
import CountryRow from './CountryRow/CountryRow';
import TableVersionComparisonWrapper from '../../components/TableVersionComparisonWrapper/TableVersionComparisonWrapper';

// hooks
import useAuthoringDataStore from '../../../hooks/stores/useAuthoringDataStore';
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';
import useAuthoringVersionStore from '../../../hooks/stores/useAuthoringVersionStore';

const RJTrialCountries = (props) => {
  const [trialCountriesFormData, setTrialCountriesFormData] = useState([]);
  const [totalWorldwide, setTotalWorldwide] = useState(0);
  const [totalEEA, setTotalEEA] = useState(0);
  const [compareTotalWorldwide, setCompareTotalWorldwide] = useState(0);
  const [compareTotalEEA, setCompareTotalEEA] = useState(0);

  const {
    versionData,
    selectedVersion
  } = useAuthoringVersionStore(
    useShallow(state => ({
      versionData: state.versionData,
      selectedVersion: state.selectedVersion,
    }))
  )

  const {
    formData,
  } = useAuthoringDataStore(
    useShallow(state => ({
      formData: state.formData,
    }))
  )

  const {
    readOnly,
    showVersionCompare
  } = useAuthoringViewStore(
    useShallow(state => ({ 
      readOnly: state.readOnly,
      showVersionCompare: state.showVersionCompare,
    }))
  )

  let { id } = props;

  let schema = {
    headers: [
      {
        key: "country_name",
        title: "Trial Country"
      },
      {
        key: "subjects",
        title: "Actual number of subjects enrolled"
      }
    ],
    footers: [
      {
        key: "worldwide",
        title: "Total: worldwide"
      },
      {
        key: "eea-only",
        title: "Total: EEA only"
      }
    ]
  }

  let eeaSet = new Set([
    "Belgium",
    "Bulgaria",
    "Czechia",
    "Czech Republic",
    "Denmark",
    "Cyprus",
    "Latvia",
    "Lithuania",
    "Luxembourg",
    "Spain",
    "France",
    "Croatia",
    "Italy",
    "Poland",
    "Portugal",
    "Romania",
    "Slovenia",
    "Hungary",
    "Malta",
    "Netherlands",
    "Austria",
    "Iceland",
    "Liechtenstein",
    "Norway",
    "Slovakia",
    "Finland",
    "Sweden",
    "Germany",
    "Estonia",
    "Ireland",
    "Greece",
    "United Kingdom"
  ])

  useEffect(() => {
    if (formData?.results?.trial_info?.population?.country_subject_counts) {
      setTrialCountriesFormData(formData.results.trial_info.population.country_subject_counts)
    }
  }, [])

  // auto calculate totals
  useEffect(() => {
    if (trialCountriesFormData?.length > 0) {
      let totalWorldwide = 0;
      let totalEEA = 0;

      trialCountriesFormData.forEach(country => {
        totalWorldwide += Number(country.subjects);
        if (eeaSet.has(country.country_name)) {
          totalEEA += Number(country.subjects);
        }
      })

      setTotalWorldwide(totalWorldwide);
      setTotalEEA(totalEEA);
    }
  }, [trialCountriesFormData])

  const onChange = (e, i, key) => {
    let tempData = [...trialCountriesFormData];
    tempData[i][key] = e;
    setTrialCountriesFormData(tempData);
    console.log('TrialCountries useEffect >> onChange')
    props.onChange(tempData);
  }

  const onAddCountry = () => {
    let newCountry = {
      country_name: "",
      subjects: "",
    }

    let tempData = [...trialCountriesFormData, newCountry];
    setTrialCountriesFormData(tempData);
  }

  const onDeleteCountry = (i) => {
    let tempData = [...trialCountriesFormData];
    tempData.splice(i, 1)
    setTrialCountriesFormData(tempData);
    console.log('TrialCountries useEffect >> onChange')
    props.onChange(tempData);
  }

  const displayRows = () => {
    return trialCountriesFormData.map((country, i) => {
      if (readOnly === true) {
        return (
          <TR key={`trial-country-${i}`}>
            <TD>
              {country?.country_name}
            </TD>
            <TD>{country?.subjects}</TD>
          </TR>
        )
      }
      return (
        <TR key={`trial-country-${i}`}>
          <TD>
            <CountryRow
              schema={props.schema}
              country={country}
              onChange={onChange}
              onDeleteCountry={onDeleteCountry}
              i={i} />
          </TD>
          <TD>
            <PrimeField
              schema={{
                variant: "number",
                type: "text",
                style: { width: "100%" },
                size: "small"
              }}
              value={country?.subjects}
              onChange={e => onChange(e, i, "subjects")} />
          </TD>
        </TR>
      )
    })
  }

  useEffect(() => {
    let compareData = versionData?.study_data?.results?.trial_info?.population?.country_subject_counts;

    if (compareData?.length > 0) {
      let compareTotalWorldwide = 0;
      let compareTotalEEA = 0;

      compareData.forEach(country => {
        compareTotalWorldwide += Number(country.subjects);
        if (eeaSet.has(country.country_name)) {
          compareTotalEEA += Number(country.subjects);
        }
      })

      setCompareTotalWorldwide(compareTotalWorldwide);
      setCompareTotalEEA(compareTotalEEA);
    }
  }, [versionData])

  const displayCompareRows = () => {
    let compareData = versionData?.study_data?.results?.trial_info?.population?.country_subject_counts;
    if (!compareData) return []

    return compareData?.map((country, i) => {
      return (
        <TR key={`trial-country-compare-${i}`}>
          <TD>
            {country?.country_name}
          </TD>
          <TD>{country?.subjects}</TD>
        </TR>
      )
    })
  }

  return (
    <Container className="results-table" id={id + "-field"}>
      <table>
        <thead>
          <tr>
            {schema.headers.map((header, i) => {
              let minWidth = 200;
              return (
                <TH
                  className="text-bold"
                  key={`age-range-${header.key}`}
                  style={{
                    minWidth: i === 0 ? 400 : minWidth
                  }}>
                  {header.title}
                </TH>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {displayRows()}
          {schema.footers.map((footer, i) => {
            return (
              <TR key={`age-range-footer-${i}`}>
                <TD style={{ fontWeight: "bold" }}>
                  {footer.title}
                </TD>
                <TD style={{ fontWeight: "bold" }}>
                  {footer.key === "worldwide" && totalWorldwide}
                  {footer.key === "eea-only" && totalEEA}
                </TD>
              </TR>
            )
          })}
        </tbody>
      </table>
      {readOnly !== true &&
        <Button
          variant="primary-dashed"
          size="sm"
          onClick={() => onAddCountry()}
          style={{
            marginTop: 16
          }}>
          <i
            className="fal fa-plus-circle"
            style={{
              marginTop: 2,
              marginRight: 8
            }} />
          Add Country
        </Button>
      }

      {versionData && selectedVersion && showVersionCompare && (
        <TableVersionComparisonWrapper>
          <table>
            <thead>
              <tr>
                {schema.headers.map((header, i) => {
                  let minWidth = 200;
                  return (
                    <TH
                      key={`age-range-compare-${header.key}`}
                      style={{
                        minWidth: i === 0 ? 400 : minWidth
                      }}>
                      {header.title}
                    </TH>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {displayCompareRows()}
              {schema.footers.map((footer, i) => {
                return (
                  <TR key={`age-range-footer-compare-${i}`}>
                    <TD style={{ fontWeight: "bold" }}>
                      {footer.title}
                    </TD>
                    <TD style={{ fontWeight: "bold" }}>
                      {footer.key === "worldwide" && compareTotalWorldwide}
                      {footer.key === "eea-only" && compareTotalEEA}
                    </TD>
                  </TR>
                )
              })}
            </tbody>
          </table>
        </TableVersionComparisonWrapper>
      )}
    </Container>
  );
};

export default RJTrialCountries;

const Container = styled.div`
  margin-top: 16px;
`

const Table = styled.table`

`

const TH = styled.th`
  border: 1px solid #8d8d8d;
  font-weight: bold;
  font-size: 14px;
  padding: 10px;  
  background-color: #F7FBFE;
`

const TR = styled.tr`
  position: relative;
`

const TD = styled.td`
  position: relative;
  border: 1px solid #8d8d8d;
  padding: 10px;
  vertical-align: top;
  transition: all .4s;
  box-sizing: border-box;
  font-size: 14px;
  font-size: 14px;
`