import { useState, useEffect, useMemo } from 'react'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'

// components
import { Tooltip } from 'antd'

// styles
import './action-items-actions.scss'

dayjs.extend(isToday)

const ActionItemActions = ({
  data,
  permissions,
  onOpenPanel,
  setOpenDeleteActionItemModal,
  setSelectedActionItem,
  onToggleSendReminderModal,
  type,
  disableReminderSet,
  itemForReminder
}) => {
  const onOpenSidePanel = () => {
    setSelectedActionItem(data)
    onOpenPanel()
  }

  const onDeleteActionItem = () => {
    setSelectedActionItem(data)
    setOpenDeleteActionItemModal(true)
  }

  // NOTE: comment/uncomment the 2 return statements below to show the reminder button
  const canSendReminder = useMemo(() => {
    if(permissions["actionitem.reminder"] === false) return false

    if(disableReminderSet.has(data.id)) return false
    return data.allow_send_reminder && data.assigned_to_users.length > 0
    // return data.assigned_to_users.length > 0
  }, [data, disableReminderSet, itemForReminder])

  const showWrite = useMemo(() => {
    if (type === "my-prime") return false
    return true
  }, [type])

  
    return (
      <div className="action-item-actions-container">
        {canSendReminder ? (
          <Tooltip
            title={"Send Reminder"}>
            <i className="fal fa-bell action-item-grid-action-icon" onClick={() => onToggleSendReminderModal(data)}></i>
          </Tooltip>
        ) : (
          <Tooltip
            title={"The assignee(s) have already been notified today, try again tomorrow"}>
            <i className="fal fa-bell-slash" style={{ cursor: "not-allowed" }}></i>
          </Tooltip>
        )}
        {showWrite && permissions?.["actionitem.edit"] && <i className="fal fa-info-circle action-item-grid-action-icon" onClick={onOpenSidePanel} />}
        {showWrite && permissions?.["actionitem.delete"] && <i className="fal fa-trash-alt action-item-grid-action-icon" onClick={onDeleteActionItem}></i>}
      </div>
    )
  // }

  return
}

export default ActionItemActions