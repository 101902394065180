import { QueryCache, QueryClient, MutationCache } from '@tanstack/react-query'
import useToastNotifications from 'utilities/useToastNotifications'
import { capitalizeFirstLetter } from 'utilities/misc'

const defaultQueryClientOptions = {
  queries: {
    // staleTime: 1000 * 60 * 5, // detaults to 0
    // cacheTime: 900000, // default 5 minutes
    refetchOnMount: false,
    refetchOnReconnect: false,
    // refetchOnWindowFocus: process.env.NODE_ENV === 'production',
    refetchOnWindowFocus: false,
    // retry: process.env.NODE_ENV === 'production' ? 3 : false, // defaults 3
    retry: 3, // defaults 3
  },
  // mutations: {
  //   onError: (error) => error,
  // },
}

// query client for testing
export const generateQueryClient = () => {
  return new QueryClient({
    defaultOptions: {
      ...defaultQueryClientOptions,
      retry: false,
    },
  })
}

const useQueryClient = () => {
  const {
    openToastNotification,
    toastContextHolder,
  } = useToastNotifications()

  const errorHandler = (error) => {
    // server error
    let errorMsg = ''

    if (error?.response?.status === 307 || error?.response?.status === 401 || error?.response?.status === 403 || error?.response?.status === 418) return;
    // custom error
    if (error?.response?.data?.error) {
      const errorData = error.response.data.error

      // // TODO: review if redundant:
      // if (errorData?.status_code === 401 || errorData?.status_code === 403) {
      //   return
      // }
      if (errorData.details.length === 0 && errorData?.msg) {
        errorMsg = errorData.msg
      } if (errorData?.details) {
        if (errorData?.details?.study_id?.[0]) {
          errorMsg = capitalizeFirstLetter(errorData.details.study_id[0])
        } else {
          let issues = []
          for (const property in errorData.details) {
            const value = errorData.details[property]
            if (Array.isArray(value)) {
              issues.push(<li><strong>{property}:</strong> {capitalizeFirstLetter(value[0])}</li>)
            } else {
              issues.push(<li><strong>{property}:</strong> {capitalizeFirstLetter(value)}</li>)
            }
            errorMsg = <ul>{issues}</ul>
          }
        }
      } else if (errorData?.msg) {
        errorMsg = errorData.msg
      }
    }
    // react query error
    else if (typeof error === 'string') {
      errorMsg = error
    }
    // JS Error
    else if (error instanceof Error) {
      errorMsg = error.message
    }

    console.log({ errorMsg })
    openToastNotification('error', "Error", errorMsg);
  }

  const queryClient = new QueryClient({
    defaultOptions: defaultQueryClientOptions,
    // the QueryCache error handler is only triggered after all retries have failed
    queryCache: new QueryCache({
      onError: (error, query) => {
        errorHandler(error, query)
        console.log('QueryCache onError', {
          error,
          query,
        })
      },
    }),
    mutationCache: new MutationCache({
      onError: (error, payload) => {
        errorHandler(error, payload)
      },
    }),
  })

  return {
    toastContextHolder,
    queryClient,
  }
}

export default useQueryClient