import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { AgGridReact } from 'ag-grid-react'
import HeaderActions from 'components/HeaderActions/HeaderActions'
import './ProtocolMaintenance.scss'

// cell renderers
import Compare from '../CellRenderers/Compare'

// context
import useAppState from 'context/hooks/useAppState'
import useAuthoringStore from '../../../Authoring/hooks/stores/useAuthoringStore'

// api
import { useUserPermissions } from 'api/hooks';
import { useQueryClient } from '@tanstack/react-query'
import queryKeys from 'api/utils/queryKeys'
import { useGetAuthoringStudy } from 'api/hooks/studies/useAuthoringApi'
import {
  usePostPrsProtocolDownload,
  useGetPrsProtocolUpload,
  usePostPrsProtocolUpload
} from 'api/hooks/studies/useRegistries'

const ProtocolMaintenance = ({
  study,
  protocolDataCompare,
  clinicalTrialsData,
}) => {
  const { studyId } = useParams()
  const queryClient = useQueryClient()
  const {
    setPollingTaskId,
    setTaskType,
    setPollingCallback
  } = useAppState()

  const authoringId = useAuthoringStore(state => state.authoringId)


  const { data: authoring } = useGetAuthoringStudy(authoringId)
  const { data: permissions } = useUserPermissions()

  const [queueId, setQueueId] = useState(null)

  const postPrsProtocolDownload = usePostPrsProtocolDownload(study?.authoring_study_id)
  const postPrsProtocolUpload = usePostPrsProtocolUpload()

  const { data: pollingData } = useGetPrsProtocolUpload(queueId, {
    refetchInterval: queueId ? 1000 : null,
    enabled: queueId ? true : false
  })

  const [openCancelModal, setOpenCancelModal] = useState(false)
  const [uploadComplete, setUploadComplete] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isAutoRelease, setIsAutoRelease] = useState(false);


  useEffect(() => {
    if (pollingData?.in_progress === false && queueId) {
      onStopPolling()
    }
  }, [pollingData])

  const gridOptions = {
    columnDefs: [
      {
        headerName: 'Field',
        field: 'field_label',
      },
      {
        headerName: 'Prime',
        field: 'prime_val',
        cellRenderer: 'Compare'
      },
      {
        headerName: 'CTG',
        field: 'prs_val',
        cellRenderer: 'Compare'
      },
    ],
    defaultColDef: {
      resizable: true,
      sortable: true,
      wrapText: true,
      filter: true,
      filterParams: {
        buttons: ['clear'],
      },
      enablePivot: true,
      enableCharts: false,
      enableRowGroup: false,
      cellStyle: { "whiteSpace": "normal !important" },
      autoHeight: true,
    },
    frameworkComponents: {
      Compare
    }
  }

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit()
  }

  const onRegenerate = () => {
    postPrsProtocolDownload({}, {
      onSuccess: resp => {
        setPollingTaskId(resp?.data?.task_id)
        setTaskType("study-registry-regenerate")
        setPollingCallback((pollingData) => {
          queryClient.invalidateQueries(queryKeys.registriesData.details(studyId))
          queryClient.invalidateQueries(queryKeys.registriesDataCompare.details(study?.authoring_study_id))
        })
      }
    })
  }

  const onStopPolling = () => {
    setUploadComplete(true);
    setIsUploading(false)
    setQueueId(null)
  }

  return <div className="registries-protocol-maintenance">
    <div className="reg-pm-title-wrap">
      <div className="reg-pm-title">
        Protocol Maintenance
      </div>
      <HeaderActions
        prsUploadStudyList={[Number(study?.authoring_study_id)]}
        useOnGetPrsUpload={useGetPrsProtocolUpload}
        useOnPostPrsUpload={usePostPrsProtocolUpload}
        canUploadToPRS={permissions?.["study.protocolmaintenance.upload"]}
        onRegenerate={onRegenerate}
        metadata={{}}
        source={"study-registry"} 
        lastImportDate={authoring?.ctg_protocol_download_date}/>
    </div>
    <div
      className="ag-theme-balham reg-pm-grid-wrap"
      id="ag-theme-prime-disabled"
    >
      <AgGridReact
        onGridReady={onGridReady}
        gridOptions={gridOptions}
        domLayout='autoHeight'
        rowData={protocolDataCompare || []}
      />
    </div>
  </div>
}

export default ProtocolMaintenance