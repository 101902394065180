import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import EllipsisPopover from 'components/EllipsisPopover/EllipsisPopover'
import ObjPill from 'components/badges/ObjPill/ObjPill'
import './ActivitiesCellRenderer.scss'

const ActivitiesCellRenderer = ({ value, data }) => {
  if (Array.isArray(value)) {
    if (value.length === 0) return null;
    return <div className="activities-cell-renderer">
      {value.map((activity, index) => {
        return (
          <ObjPill
            key={`/study/${data.id}/activity/${activity.id}`}
            url={`/study/${data.id}/activity/${activity.id}`}
            label={activity.title} />
        )
      })}
    </div>
  }
}

ActivitiesCellRenderer.propTypes = {
  value: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    request_id: PropTypes.string,
  })),
}

export default ActivitiesCellRenderer