import useGetApi from "../useGetApi"
import useMutateApi from "../useMutateApi"
import getUrl from "api/apiMap"
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

/** TODO: move the following to authoring/studiesApis.js */

// NOTE: use dropdown useGetStudiesList instead
export const useGetAuthoringStudies = () => {
  return useGetApi(getUrl(apiEndpoints.authoring), queryKeys.authoringStudies.all)
}

export const useGetAuthoringStudy = (authoringId) => {
  return useGetApi(getUrl(`${apiEndpoints.authoring}${authoringId}/`), queryKeys.authoringStudies.detail(authoringId))
}

export const usePutAuthoringStudy = (authoringId) => {
  return useMutateApi({
    method: "put",
    apiEndpoint: getUrl(`${apiEndpoints.authoring}`),
    // invalidateQueries: [queryKeys.authoringStudies.detail(authoringId), queryKeys.authoringVersions.list(authoringId)],
    // successMsg: 'Successfully updated study.'
  })
}

// unlock form
export const useUnlockAuthoringForm = (authoringId) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(`${apiEndpoints.authoring}${authoringId}/unlock/`),
    invalidateQueries: [queryKeys.authoringUnlock(authoringId)],
    successMsg: 'Successfully unlocked form.'
  })
}

/** versions */

export const useGetAuthoringVersions = (authoringId) => {
  return useGetApi(getUrl(`${apiEndpoints.authoring}${authoringId}/versions/`), queryKeys.authoringVersions.list(authoringId))
}

export const useGetAuthoringVersion = (authoringId, verNum) => {
  return useGetApi(verNum ? getUrl(`${apiEndpoints.authoring}${authoringId}/versions/${verNum}/`) : null, queryKeys.authoringVersions.detail(verNum))
}


/** TODO: move following to authoring/studyCommentsApis.js */

export const useGetAuthoringComments = (authoringId) => {
  return useGetApi(getUrl(`${apiEndpoints.authoring}${authoringId}/comments/`), queryKeys.authoringComments.list(authoringId))
}

export const usePostAuthoringComment = (authoringId) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(`${apiEndpoints.authoring}${authoringId}/comments/`),
    invalidateQueries: [queryKeys.authoringComments.details(), queryKeys.authoringComments.list(authoringId)]
  })
}

export const usePutAuthoringComment = (authoringId) => {
  return useMutateApi({
    method: "put",
    apiEndpoint: getUrl(`${apiEndpoints.authoring}${authoringId}/comments/`),
    invalidateQueries: [queryKeys.authoringComments.list(authoringId)]
  })
}

export const useDeleteAuthoringComment = (authoringId) => {
  return useMutateApi({
    method: "delete",
    apiEndpoint: getUrl(`${apiEndpoints.authoring}${authoringId}/comments/`),
    invalidateQueries: [queryKeys.authoringComments.detail(authoringId)]
  })
}

export const useFavoriteAuthoringComment = (authoringId) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(`${apiEndpoints.authoring}${authoringId}/comments/`),
    invalidateQueries: [queryKeys.authoringComments.detail(authoringId), queryKeys.authoringComments.list(authoringId)]
  })
}

export const useResolveAuthoringComment = (authoringId) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(`${apiEndpoints.authoring}${authoringId}/comments/`),
    invalidateQueries: [queryKeys.authoringComments.detail(authoringId), queryKeys.authoringComments.list(authoringId)]
  })
}

/** move useGetLockedFields to admin/lockedFieldsApis.js */
export const useGetLockedFields = () => {
  return useGetApi(getUrl(apiEndpoints.authoringLockedFields), queryKeys.authoringLockedFields)
}

/** move following to authoring/submissionsApis.js */

// prs preview
export const usePostPrsPreview = (authoringId) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(`${apiEndpoints.authoring}${authoringId}/prs-preview`),
    invalidateQueries: [queryKeys.prsPreview.details(authoringId)]
  })
}

// qa comments report 
export const usePostQaCommentsPage = (authoringId) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(`${apiEndpoints.authoring}${authoringId}/qa-comments-page`),
    invalidateQueries: [queryKeys.qaComments.details(authoringId)]
  })
}

// registry validations
export const usePostRegistryValidations = (authoringId) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(`${apiEndpoints.authoring}${authoringId}/validate`),
    invalidateQueries: [queryKeys.authoringValidations.details(authoringId)],
    successMsg: 'Successfully retrieved validation messages'
  })
}


/** TODO: move the following to dropdown/dropdownApis.js */

export const useGetProgramIDList = () => {
  return useGetApi(getUrl(apiEndpoints.programId), queryKeys.programId)
}

export const useGetProgramNameList = () => {
  return useGetApi(getUrl(apiEndpoints.programName), queryKeys.programName)
}
