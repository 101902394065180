import React, {
  useEffect,
  useState,
  useMemo
} from 'react';
import { useShallow } from 'zustand/react/shallow'
import { debounce } from 'lodash';

// components
import { Tooltip } from 'antd'
import cloneDeep from 'lodash/cloneDeep';

// utils
import { getRegistryKeys, getJpathArray, getForkedJpathArray } from '../../utils';
import { formSchemas } from 'constants/authoring';
import useAuthoringActions from '../../hooks/actions/useAuthoringActions';

// store
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';
import useAuthoringCommentsStore from '../../hooks/stores/useAuthoringCommentsStore';
import useAuthoringValidationsStore from '../../hooks/stores/useAuthoringValidationsStore';
import useAuthoringFieldStore from '../../hooks/stores/useAuthoringFieldStore';
import useAuthoringDataStore from '../../hooks/stores/useAuthoringDataStore';
import useAuthoringStore from '../../hooks/stores/useAuthoringStore';

// apis
import { useUserPermissions } from 'api/hooks';

const useHOC = ({
  props,
  formatOptions,
  WrappedComponent
}) => {
  const { data: permissions } = useUserPermissions()
  const authoringId = useAuthoringStore(state => state.authoringId)

  const {
    formDataRef,
    forkedStudyDataRef,
    overrideDataRef,
    handleSubmit
  } = props.formContext

  const {
    forkedStudyData,
    setForkedStudyData,
    setOverrideData,
  } = useAuthoringDataStore(
    useShallow(state => ({
      forkedStudyData: state.forkedStudyData,
      setForkedStudyData: state.setForkedStudyData,
      setOverrideData: state.setOverrideData,
    }))
  )

  // const formData = useAuthoringDataStore(state => state.formData)
  const setFormData = useAuthoringDataStore(state => state.setFormData)

  const {
    registryValidations
  } = useAuthoringValidationsStore(
    useShallow(state => ({
      registryValidations: state.registryValidations,
    }))
  )

  const {
    handleOverride,
    scrollToField
  } = useAuthoringActions()

  const {
    openComment,
    setOpenComment,
    commentsMap
  } = useAuthoringCommentsStore(
    useShallow(state => ({
      openComment: state.openComment,
      setOpenComment: state.setOpenComment,
      commentsMap: state.commentsMap,
    }))
  )

  const {
    formView,
    formSection
  } = useAuthoringViewStore(
    useShallow(state => ({
      formView: state.formView,
      formSection: state.formSection,
    }))
  )

  const {
    hiddenFields,
    forkedFields,
    setForkedFields,
    lockedFields,
  } = useAuthoringFieldStore(
    useShallow(state => ({
      hiddenFields: state.hiddenFields,
      forkedFields: state.forkedFields,
      setForkedFields: state.setForkedFields,
      lockedFields: state.lockedFields,
    }))
  )

  const [showMenu, setShowMenu] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [invalidText, setInvalidText] = useState("");
  const [openDeleteForkedModal, setOpenDeleteForkedModal] = useState(false);
  const [forkPathToDelete, setForkPathToDelete] = useState(null);
  const [forkTouchedValue, setForkTouchedValue] = useState(null);
  const [forkedFieldPath, setForkedFieldPath] = useState(null)
  const [fieldLoading, setFieldLoading] = useState(false)

  const jpath = props.id;
  const { value } = props

  let options = formatOptions(props);

  let handleSubmitDebounce = debounce((data) => {
    handleSubmit(data)
  }, 400);

  /**
   * Registry keys that will appear as badges.
   * Only fields that can be forked will have mutiple keys.
  */
  const registryKeys = useMemo(() => {
    return getRegistryKeys({
      formSection,
      formSchemas,
      hiddenFields,
      jpath,
    });
  }, [formSection, hiddenFields, authoringId]);

  /**
   * FORKED FIELDS
   ***************
   * Array of jpaths for fields that will show on current view.
   * Always including original field's jpath and any forked field paths.
   * When a field is forked, there will be more than one jpath on global view;
   *   On a registry view, there will only be one jpath, either the original or a forked field path.
   * */
  const jpaths = useMemo(() => {
    const paths = [];

    if (forkedFields && registryKeys?.length > 1) {
      registryKeys.forEach((rKey) => {
        const pathKey = `${jpath}_${rKey}`;
        if (forkedFields[formSection.id]?.[rKey]?.[pathKey] && (formView.id === "global" || formView.id === rKey)) {
          paths.push(pathKey);
        }
      });
    }

    if (formView?.id === "global" || paths.length === 0) {
      paths.push(jpath);
    }

    return paths;
  }, [forkedFields, formView, authoringId, forkedStudyData]);


  const fieldComments = useMemo(() => {
    if (commentsMap) {
      if (commentsMap[jpath]) {
        let fieldComments = commentsMap[jpath];
        return fieldComments
      } else {
        return []
      }
    }
  }, [commentsMap])

  useEffect(() => {
    if (openComment && jpath === openComment) {
      scrollToField(jpath);
      setTimeout(() => {
        setOpenComment(null);
      }, 1000)
    }
  }, [openComment])

  const validate = () => {
    for (const validation of options.validations) {
      const tempValue = value?.replace ? value.replace(/(\r\n|\n|\r)/gm, "") : value;

      if (!validation.regex.test(tempValue)) {
        setInvalid(true);
        setInvalidText(validation.message);
        return; // Early return if validation fails
      }
    }

    // If the loop completes without breaking, reset invalid state
    if (invalid) {
      setInvalid(false);
      setInvalidText("");
    }
  };

  useEffect(() => {
    const overrideVal = overrideDataRef?.current?.[lockedFields?.[jpath]];

    if (registryValidations) {
      setInvalid(false);
      setInvalidText("");
    } else {
      const isRequired = props.required || options.required;
      const isFieldEmpty = !value || value === "" || (value && value.length === 0);

      if (isRequired && lockedFields?.[jpath] && isFieldLocked(jpath) === false && (!overrideVal || overrideVal === "" || (overrideVal && overrideVal.length === 0))) {
        setInvalid(true);
        setInvalidText("This is a required field.");
      } else if ((props?.schema?.uiSchema?.required !== false || props.required) && isRequired && isFieldEmpty) {
        setInvalid(true);
        setInvalidText("This is a required field.");
      } else if (options.validations && options.validations.length > 0) {
        validate();
      } else if (value && value.length > 0 && invalid === true) {
        setInvalid(false);
        setInvalidText("");
      } else if (!options.validations && invalid === true) {
        setInvalid(false);
        setInvalidText("");
      }
    }
  }, [value, options.validations, registryValidations, overrideDataRef.current]);

  const onForkField = (path) => {
    // set forkedFields state with new forked field item
    let _forkedFields = {};
    let regKey = formView.id;
    const sectId = formSection.id;
    if (forkedFields) _forkedFields = { ...forkedFields };
    _forkedFields[sectId] = {};
    if (forkedFields?.[sectId]) _forkedFields[sectId] = { ...forkedFields[sectId] };
    _forkedFields[sectId][regKey] = {};
    if (forkedFields?.[sectId]?.[regKey]) _forkedFields[sectId][regKey] = { ...forkedFields[sectId][regKey] };

    let uiSchema = formSchemas[sectId].properties[regKey].uiSchema;
    if (typeof uiSchema === "function") {
      uiSchema = uiSchema()
    }

    const uiSchemaJpathArray = getJpathArray(jpath.replace("root_", ""), uiSchema);
    const jpathArray = getForkedJpathArray(uiSchemaJpathArray, regKey);
    let fieldKey = uiSchemaJpathArray.join(".")

    let tempVal = value;

    if (overrideDataRef.current?.[fieldKey]) {
      tempVal = overrideDataRef.current[fieldKey]
    }

    const fieldPath = `${jpathArray.join("_")}`;
    _forkedFields[sectId][regKey][`root_${fieldPath}`] = {
      jpathArray,
      value: tempVal, // original field value
    };
    setForkedFields(_forkedFields);
    // save value to form data
    // saveForkfieldData(jpathArray, tempVal);

    /*---------- Updated Forking Implementation ------------*/

    if (regKey === "eudra") regKey = "eudract"

    setForkedFieldPath(fieldKey);
  }

  const handleDeleteForkModal = (isModalOpen, path) => {
    setOpenDeleteForkedModal(isModalOpen);
    setForkPathToDelete(path);
  };

  // can delete forked field wherever it appears
  const onDeleteForkedField = () => {
    const sectId = formSection.id;

    // get suffix from path to delete
    const splitPath = forkPathToDelete.split("_");
    let regKey = splitPath[splitPath.length - 1];
    const _forkedFields = { ...forkedFields };
    _forkedFields[sectId] = { ...forkedFields[sectId] };
    _forkedFields[sectId][regKey] = { ...forkedFields[sectId][regKey] };
    delete _forkedFields[sectId][regKey][forkPathToDelete];

    setForkedFields(_forkedFields);

    let uiSchema = formSchemas[sectId].properties[regKey].uiSchema;
    if (typeof uiSchema === "function") {
      uiSchema = uiSchema()
    }

    const uiSchemaJpathArray = getJpathArray(jpath.replace("root_", ""), uiSchema);
    let fieldKey = uiSchemaJpathArray.join(".")

    // set form data and save
    const dataJpathArray = getJpathArray(jpath.replace("root_", ""), uiSchema);
    const jpathArray = getForkedJpathArray(dataJpathArray, regKey);

    let dataRef = { ...formDataRef };
    if (jpathArray?.length) {
      jpathArray.forEach((part, index) => {
        if (index !== jpathArray.length - 1) {
          if (!dataRef[part]) dataRef[part] = {};
          dataRef = dataRef[part];
        } else {
          delete dataRef[part]
        }
      });
    };

    regKey = regKey === "eudra" ? "eudract" : regKey
    onDeleteForkedStudyData(fieldKey, regKey);

    console.log("hoc setFormData onDelete Fork >>")
    const formData = cloneDeep(formDataRef)
    setFormData(formData)

    // close modal
    setForkPathToDelete(null);
    setOpenDeleteForkedModal(false);
  }

  const onDeleteForkedStudyData = (fieldKey, regKey) => {
    if (fieldKey && regKey) {
      let updatedData = { ...forkedStudyDataRef.current }
      delete updatedData[regKey]?.[fieldKey];
      forkedStudyDataRef.current = updatedData
      setForkedStudyData(updatedData)
    }
  };

  /**
   * Puzzle: saveForkfieldData calls debouncing functions many times, even tho they are debouncing!
   * Hack: for forked field text inputs and text areas,
   *   will save data only on blur.
   */
  const handleOnFocus = (evt) => {
    if (WrappedComponent.name === 'RJTextInput' ||
      WrappedComponent.name === 'RJTextArea') {
      // not checking if forked field, because calculation may be too expensive
      setForkTouchedValue(evt.target.value);
    }
  };

  const handleOnBlur = (evt) => {
    if (WrappedComponent.name === 'RJTextInput' ||
      WrappedComponent.name === 'RJTextArea') {
      const isForkedField = () => {
        // will find both original and forked field in global view
        if (jpaths?.length > 1) return true;

        // on registry view, only forked field
        if (jpaths?.length === 1) {
          return registryKeys?.find((key) => jpaths[0].endsWith(key))
        }
        return false
      }
    }
  }

  const isFieldLocked = (path) => {

    if (lockedFields?.[path]) {
      if (overrideDataRef.current?.[lockedFields?.[path]] === undefined) {
        return true;
      }
    }
    return false;
  }

  const unlockField = () => {
    const callback = () => {
      if (permissions["study.authoringdata.override"]) {
        let overrideData = {
          ...overrideDataRef.current,
          [lockedFields[jpath]]: ""
        }

        setOverrideData(overrideData)
        overrideDataRef.current = overrideData
      }

      // if current field value is null and json path is not available - set the json path and field value to
      if (value === undefined) {
        let paths = lockedFields[jpath].split(".");
        let curr = { ...formDataRef };
        for (let i = 0; i < paths.length; i++) {
          let path = paths[i];
          if (i === paths.length - 1) {
            curr[path] = null
          } else {
            if (curr[path]) {
              curr = curr[path]
            } else {
              curr = {}
            }
          }
        }
      }
    }

    let showOverrideWarning = localStorage.getItem("show-override-warning");

    if (showOverrideWarning === "false") {
      callback()
    } else {
      handleOverride(callback)
    }
  }

  const revertOverride = async (path) => {
    setFieldLoading(true)
    const temp = { ...overrideDataRef.current };
    delete temp[lockedFields[path]];

    setOverrideData(temp);
    overrideDataRef.current = temp

    handleSubmitDebounce({
      formData: formDataRef,
      overrideData: temp
    });

    await new Promise(r => setTimeout(r, 500));
    setFieldLoading(false)
  };

  const onOverrideChanged = (val) => {
    const temp = { ...overrideDataRef.current };
    temp[lockedFields[jpath]] = val;
    setOverrideData(temp);
    overrideDataRef.current = temp

    handleSubmitDebounce({
      formData: formDataRef,
      overrideData: temp
    });
  };

  const displayLockedField = (isLocked, path) => {
    if (overrideDataRef.current?.[lockedFields?.[path]] !== undefined) {
      return (
        <div
          className="input-wrapper-override-lock"
          onClick={() => revertOverride(path)}
          style={{ marginBottom: 2 }}>
          <Tooltip
            title="Revert data"
          >
            <i className="far fa-undo"></i>
          </Tooltip>
        </div>
      )
    } else if (isLocked) {
      let isAllowed = permissions["study.authoringdata.override"];

      return (
        <div
          className="input-wrapper-override-lock"
          style={{ position: "absolute" }}
          onClick={() => isAllowed ? unlockField() : {}}>
          <Tooltip
            title={isAllowed ? "Override field" : "You don’t have permission to perform this action"}
          >
            <i className="fas fa-lock-alt"></i>
          </Tooltip>
        </div>
      )
    }
    return null
  }

  let _onChangeForkedField = debounce((props) => {
    _onChangeForkedField_(props)
  }, 200);

  const _onChangeForkedField_ = ({ path, forkedRegKey, jpathDotNotation, val }) => {
    const sectId = formSection.id;
    let regKey = forkedRegKey
    const _forkedFields = { ...forkedFields };
    _forkedFields[sectId][forkedRegKey][path].value = val;
    setForkedFields(_forkedFields);

    const jpathArray = forkedFields[sectId][forkedRegKey][path].jpathArray;
    let dataRef = { ...formDataRef };
    if (jpathArray?.length) {
      jpathArray.forEach((part, index) => {
        if (index !== jpathArray.length - 1) {
          if (!dataRef[part]) dataRef[part] = {};
          dataRef = dataRef[part];
        } else {
          let newKey = `${part}_${forkedRegKey}`
          dataRef[newKey] = val
        }
      });
    };

    if (formDataRef?.forked_study_data?.[regKey]) {
      formDataRef.forked_study_data[regKey][jpathDotNotation] = val
    } else {
      formDataRef.forked_study_data[regKey] = {
        [jpathDotNotation]: val
      }
    }

    regKey = forkedRegKey === "eudra" ? "eudract" : forkedRegKey

    let forkedStudyData = getForkedStudyData({ jpathDotNotation, forkedRegKey: regKey, val })
    setForkedStudyData(forkedStudyData)

    const formData = cloneDeep(formDataRef)
    setFormData(formData)
  }

  const getForkedStudyData = ({ jpathDotNotation, forkedRegKey, val }) => {
    if (jpathDotNotation && forkedRegKey) {
      let tempForkedData = { ...forkedStudyDataRef.current }

      if (tempForkedData[forkedRegKey]) {
        tempForkedData[forkedRegKey] = {
          ...tempForkedData[forkedRegKey],
          [jpathDotNotation]: val
        }
      } else {
        tempForkedData[forkedRegKey] = {
          [jpathDotNotation]: val
        }
      }

      forkedStudyDataRef.current = tempForkedData
      return tempForkedData
    }
  }

  return {
    invalid,
    setInvalid,
    invalidText,
    setInvalidText,
    showMenu,
    setShowMenu,
    options,
    registryKeys,
    jpaths,
    fieldComments,
    validate,
    onDeleteForkedStudyData,
    onForkField,
    handleDeleteForkModal,
    onDeleteForkedField,
    handleOnFocus,
    handleOnBlur,
    isFieldLocked,
    unlockField,
    revertOverride,
    onOverrideChanged,
    displayLockedField,
    openDeleteForkedModal,
    setOpenDeleteForkedModal,
    formSection,
    formView,
    forkedFields,
    overrideDataRef,
    registryValidations,
    lockedFields,
    fieldLoading,
    setFieldLoading,
    authoringId,
    _onChangeForkedField,
  }
};

export default useHOC;