// utils
import { getWidget, getCtisPreviewStyles } from '../shared/utils';
import { getPreviewStyles } from './utils/utils';
import { hideStudyDetailsFields, hideEupasProtocolFields, hideSecondaryAttributeFields, hideStudyDetailsFieldsInCtis } from './utils/utils';
import {
  objectClassNames,
  arrayClassNames
} from '../classes';

let hide = {
  "ui:options": {
    hide: true
  }
}

export const ctisProtocolUISchema = (configs) => {
  let preview = configs?.preview
  let formData = configs?.formData

  let returnObj = {
    protocol: {
      "ui:order": [
        "protocol_id",
        "study_type",
        "eupas_study_type",
        "other_study_type",
        "study_identification", // 1
        "secondary_attributes",
        "milestone_dates",
        "study_timeline",      // 2
        "ctis_form",                 // 3
        "mscs",                 // 4
        "study_description",    // 5
        "study_eligibilty",     // 6
        "protocol_information",
        "contact_information",
        "study_reference",
        "study_scope",
        "sponsors",
        "study_arms",           // 7
        "*"],
      "ui:options": {
        title: "Study Identification and Information",
        classNames: {
          objectContainer: "rjs-gap-16",
          objectTitleContainer: "rjs-h1-container pt-24 pl-24 pr-24 pb-24 m-0",
          objectTitle: "rjs-h1",
          objectInnerContainer: "no-gap section-container"
        }
      },
      protocol_id: {
        "ui:options": {
          label: false,
          title: "Protocol code",
          placeholder: "Enter protocol code",
          classNames: {
            fieldContainer: "rjs-full pl-24 pr-8 pb-8",
            field: "rjs-col-1-2"
          }
        }
      },
      mscs: {
        "ui:options": {
          title: "MSCs",
          classNames: {
            objectTitleContainer: "rjs-h1-container",
            objectContainer: "rjs-object-container p-24",
            objectTitle: "rjs-h1",
            fieldContainer: "rjs-full",
          }
        },
        member_states_concerned: {
          "ui:options": {
            title: "Member States Concerned",
            classNames: {
              objectTitle: "rjs-h2",
              fieldContainer: "rjs-full",
            }
          },
          member_states_concerned_table: {
            "ui:options": {
              label: false,
              classNames: {
                fieldContainer: "rjs-full",
              }
            },
            "ui:widget": getWidget({ type: "MemberStatesConcerned", preview })
          }
        },
      },
      study_identification: {
        "ui:order": ["brief_title", "brief_title_translation", "official_title", "official_title_translation", "acronym", "protocol_id", "secondary_ids", "*"],
        "ui:options": {
          classNames: {
            objectContainer: "pl-24 pr-24",
          }
        },
        brief_title: {
          "ui:options": {
            label: false,
            title: "Full Title",
            placeholder: "Enter Full Title",
            tooltip: "Full scientific title of the clinical trial as specified in the study protocol submitted.",
            classNames: {
              fieldContainer: "rjs-full",
            },
          },
          "ui:widget": getWidget({ type: "textarea", preview })
        },
        brief_title_translation: {
          "ui:options": {
            title: "Full Title Translation",
            itemTitle: "Translation",
            addButtonTitle: "Add Translation",
            label: false,
            addable: true,
            removable: true,
            orderable: true,
            classNames: arrayClassNames.primary
          },
          items: {
            language: {
              "ui:options": {
                title: "Language",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            translation: {
              "ui:options": {
                title: "Translation",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-2-3"
                }
              },
              "ui:widget": getWidget({ type: "textarea", preview })
            },
          },
        },
        official_title: {
          "ui:options": {
            label: false,
            title: "Public Title",
            placeholder: "Enter Official Title",
            tooltip: "Full scientific title of the clinical trial as specified in the study protocol submitted.",
            classNames: {
              fieldContainer: "rjs-full",
            },
          },
          "ui:widget": getWidget({ type: "textarea", preview })
        },
        official_title_translation: {
          "ui:options": {
            title: "Public Title Translation",
            itemTitle: "Translation",
            addButtonTitle: "Add Translation",
            label: false,
            addable: true,
            removable: true,
            orderable: true,
            classNames: arrayClassNames.primary
          },
          items: {
            language: {
              "ui:options": {
                title: "Language",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            translation: {
              "ui:options": {
                title: "Translation",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-2-3"
                }
              },
              "ui:widget": getWidget({ type: "textarea", preview })
            },
          },
        },
        secondary_ids: {
          "ui:options": {
            title: "Secondary ID's",
            itemTitle: "Identifier",
            addButtonTitle: "Add Secondary ID",
            label: false,
            addable: true,
            removable: true,
            orderable: true,
            classNames: arrayClassNames.primary
          },
          items: {
            "ui:order": ["secondary_id_type", "id", "domain", "*"],
            secondary_id_type: {
              "ui:options": {
                label: false,
                title: "Identifier Type",
                hideFork: true,
                validations: [],
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              },
            },
            id: {
              "ui:options": {
                label: false,
                hideFork: true,
                title: "data-schema", // data-schema title will pull title from data schema
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            domain: {
              "ui:options": {
                label: false,
                hideFork: true,
                title: "data-schema", // data-schema title will pull title from data schema
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
          },
        },
        acronym: {
          ...hide
        },
        ...hideEupasProtocolFields.study_identification,
      },
      ctis_form: {
        "ui:options": {
          title: "Form",
          classNames: {
            objectTitleContainer: "rjs-h1-container",
            objectContainer: "rjs-object-container p-24",
            objectTitle: "rjs-h1",
            fieldContainer: "rjs-full",
          }
        },
        deferral_clinical_trial_information: {
          "ui:order": [
            "short_title",
            "justification_for_trial_category",
            "main_characteristics",
            "notifications",
            "subject_information_sheet",
            "subject_information_sheet_years",
            "subject_information_sheet_months",
            "protocol_summary_scientific_advice",
            "protocol_summary_scientific_advice_years",
            "protocol_summary_scientific_advice_months",
            "impd_sande_sections_investigator_brochure",
            "impd_sande_sections_investigator_brochure_years",
            "impd_sande_sections_investigator_brochure_months",
            "responses_rfi",
            "responses_rfi_years",
            "responses_rfi_months",
            "clinical_trial_results_summary_intermediate_data_analysis",
            "clinical_trial_results_summary_intermediate_data_analysis_months",
            "clinical_trial_results_summmray_lay_person_summary",
            "clinical_trial_results_summmray_lay_person_summary_months",
            "*"
          ],
          "ui:options": {
            title: "Deferral of clinical trial information",
            classNames: {
              fieldContainer: "rjs-full",
              objectTitle: "rjs-h2"
            }
          },
          short_title: {
            "ui:options": {
              title: "Short title / Trial category",
              tooltip: `Category applied to the trial that will determine the publishing rules. More information can be consulted on https://www.ema.europa.eu/en/documents/other/appendix-disclosure-rules-functional-specifications-eu-portal-eu-database-be-audited_en.pdf. NOTE: The action of updating from Category 1 or Catergory 2 to Category 3 will reset the publication dates to the default 'Date of decision' for that category and will also remove any deferrals`,
              label: false,
              log: true,
              classNames: {
                fieldContainer: "rjs-col-1-2"
              }
            }
          },
          justification_for_trial_category: {
            "ui:options": {
              title: "Justification for trial category / Trial category",
              tooltip: "Category applied to the trial that will determine the publishing rules. More information can be consulted on https://www.ema.europa.eu/en/documents/other/appendix-disclosure-rules-functional-specifications-eu-portal-eu-database-be-audited_en.pdf",
              placeholder: "",
              label: false,
              log: true,
              classNames: {
                fieldContainer: "rjs-col-1-2"
              }
            }
          },
          main_characteristics: {
            "ui:options": {
              title: "Main characteristics",
              placeholder: "--Select--",
              label: false,
              classNames: {
                fieldContainer: "rjs-full"
              }
            },
            "ui:widget": "radio"
          },
          notifications: {
            "ui:options": {
              title: "Notifications",
              placeholder: "--Select--",
              label: false,
              classNames: {
                fieldContainer: "rjs-full"
              }
            },
            "ui:widget": "radio"
          },
          subject_information_sheet: {
            "ui:options": {
              title: "Subject information sheet",
              placeholder: "--Select--",
              label: false,
              classNames: {
                fieldContainer: "rjs-full",
                field: "rjs-col-1-2",
              }
            },
            "ui:widget": "radio"
          },
          protocol_summary_scientific_advice: {
            "ui:options": {
              title: "Protocol and Summary of Scientific Advice",
              placeholder: "--Select--",
              label: false,
              classNames: {
                fieldContainer: "rjs-full",
                field: "rjs-col-1-2",
              }
            },
            "ui:widget": "radio"
          },
          impd_sande_sections_investigator_brochure: {
            "ui:options": {
              title: "IMPD SandE sections and Investigator Brochure",
              placeholder: "--Select--",
              label: false,
              classNames: {
                fieldContainer: "rjs-full",
                field: "rjs-col-1-2",
              }
            },
            "ui:widget": "radio"
          },
          responses_rfi: {
            "ui:options": {
              title: "Responses to RFI",
              placeholder: "--Select--",
              label: false,
              classNames: {
                fieldContainer: "rjs-full",
                field: "rjs-col-1-2",
              }
            },
            "ui:widget": "radio"
          },
          clinical_trial_results_summary_intermediate_data_analysis: {
            "ui:options": {
              title: "Clinical trial results summary for an intermediate data analysis",
              placeholder: "--Select--",
              label: false,
              classNames: {
                fieldContainer: "rjs-full"
              }
            },
            "ui:widget": "radio"
          },
          clinical_trial_results_summmray_lay_person_summary: {
            "ui:options": {
              title: "Clinical trial results summary and lay person summary",
              placeholder: "--Select--",
              label: false,
              classNames: {
                fieldContainer: "rjs-full"
              }
            },
            "ui:widget": "radio"
          },
          subject_information_sheet_years: {
            "ui:options": {
              title: "Years",
              placeholder: "--Select--",
              label: false,
              type: "number",
              classNames: {
                fieldContainer: "rjs-col-1-4"
              }
            },
          },
          subject_information_sheet_months: {
            "ui:options": {
              title: "Months after the end of trial",
              placeholder: "--Select--",
              label: false,
              type: "number",
              classNames: {
                fieldContainer: "rjs-col-1-4"
              }
            },
          },
          protocol_summary_scientific_advice_years: {
            "ui:options": {
              title: "Years",
              type: "number",
              placeholder: "--Select--",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-4"
              }
            },
          },
          protocol_summary_scientific_advice_months: {
            "ui:options": {
              title: "Months after the end of trial",
              type: "number",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-4"
              }
            },
          },
          impd_sande_sections_investigator_brochure_years: {
            "ui:options": {
              title: "Years",
              placeholder: "--Select--",
              label: false,
              type: "number",
              classNames: {
                fieldContainer: "rjs-col-1-4"
              }
            },
          },
          impd_sande_sections_investigator_brochure_months: {
            "ui:options": {
              title: "Months after the end of trial",
              placeholder: "--Select--",
              type: "number",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-4"
              }
            },
          },
          responses_rfi_years: {
            "ui:options": {
              title: "Years",
              placeholder: "--Select--",
              label: false,
              type: "number",
              classNames: {
                fieldContainer: "rjs-col-1-4"
              }
            },
          },
          responses_rfi_months: {
            "ui:options": {
              title: "Months after the end of trial",
              placeholder: "--Select--",
              label: false,
              type: "number",
              classNames: {
                fieldContainer: "rjs-col-1-4"
              }
            },
          },
          clinical_trial_results_summary_intermediate_data_analysis_months: {
            "ui:options": {
              title: "Months after the end of trial",
              placeholder: "--Select--",
              label: false,
              type: "number",
              classNames: {
                fieldContainer: "rjs-col-1-4"
              }
            },
          },
          clinical_trial_results_summmray_lay_person_summary_months: {
            "ui:options": {
              title: "Months after the end of trial",
              placeholder: "--Select--",
              label: false,
              type: "number",
              classNames: {
                fieldContainer: "rjs-col-1-4"
              }
            },
          },
          justification_deferral_published_decision: {
            "ui:options": {
              title: "Justification for deferral published at decision",
              placeholder: "--Select--",
              label: false,
              type: "number",
              classNames: {
                fieldContainer: "rjs-col-1-4"
              }
            },
            "ui:widget": "textarea"
          }
        },
      },
      study_outcome_measures: {
        "ui:options": {
          title: "Study Endpoints / Outcome Measures",
          classNames: {
            objectContainer: "rjs-gap-16 p-24",
            objectTitleContainer: "rjs-h1-container",
            objectTitle: "rjs-h1",
            fieldContainer: "rjs-full",
          }
        },
        outcome_measures: {
          ...hide
        },
        outcome_measures_ctis: {
          "ui:options": {
            title: "Outcome Measures",
            addButtonTitle: "Add Outcome Measures",
            arrayItemTitle: "Outcome Measure",
            label: false,
            addable: true,
            removable: true,
            orderable: true,
            // canCopy: true,
            canCopyCtgOutcomeMeasures: true,
            showIndex: true,
            classNames: arrayClassNames.primary
          },
          items: {
            title: {
              "ui:options": {
                title: "Title",
                placeholder: "Enter Title",
                hideFork: true,
                label: false,
                rows: 1,
                classNames: {
                  fieldContainer: "rjs-full"
                }
              },
              "ui:widget": getWidget({ type: "textarea", preview })
            },
            time_frame: {
              "ui:options": {
                title: "Time Frame",
                placeholder: "Enter Time Frame",
                label: false,
                hide: true,
                classNames: {
                  fieldContainer: "rjs-full"
                }
              }
            },
            description: {
              "ui:options": {
                title: "Description",
                placeholder: "Enter Description",
                hide: true,
                label: false,
                classNames: {
                  fieldContainer: "rjs-full"
                }
              },
              "ui:widget": "textarea"
            },
            type: {
              "ui:options": {
                title: "Type",
                label: false,
                hideFork: true,
                required: true,
                orientation: "horizontal",
                classNames: {
                  fieldContainer: "rjs-full"
                }
              },
              "ui:widget": getWidget({ type: "radio", preview })
            },
            translations: {
              "ui:options": {
                title: "Translations",
                itemTitle: "Translation",
                addButtonTitle: "Add Translation",
                label: false,
                addable: true,
                removable: true,
                orderable: true,
                classNames: arrayClassNames.secondary
              },
              items: {
                language: {
                  "ui:options": {
                    title: "Language",
                    hideFork: true,
                    label: false,
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                translation: {
                  "ui:options": {
                    title: "Translation",
                    label: false,
                    hideFork: true,
                    classNames: {
                      fieldContainer: "rjs-col-2-3"
                    }
                  },
                  "ui:widget": getWidget({ type: "textarea", preview })
                },
              },
            }
          }
        },
        outcome_measures_eupas: {
          ...hide
        },
        statistical_analysis_plan: {
          ...hide
        }
      },
      study_timeline: {
        "ui:options": {
          classNames: {
            objectContainer: "pr-24 pl-24 pb-24",
            fieldContainer: "m-0 rjs-full"
          }
        },
        milestone_dates: {
          "ui:order": ["estimated_recruitment_start_date_eea", "primary_completion_date", "study_completion_date", "*"],
          "ui:options": {
            // title: "Milestone Dates",
            classNames: {
              fieldContainer: "rjs-full",
              // objectTitle: "rjs-h2"
            }
          },
          study_start_date: {
            ...hide
          },
          primary_completion_date: {
            ...hide
          },
          study_completion_date: {
            ...hide
          },
          estimated_recruitment_start_date_eea: {
            "ui:options": {
              title: "Estimated recruitment start date in EEA",
              classNames: {
                objectTitle: "rjs-h3",
                fieldContainer: "rjs-col-1-2"
              }
            },
            date: {
              "ui:options": {
                label: false,
                hideComment: true,
                title: "Date",
                classNames: {
                  fieldContainer: "rjs-col-1-2"
                }
              },
              "ui:widget": getWidget({ type: "date", preview })
            },
          },
        },
        funding_source: {
          "ui:options": {
            title: "Source of monetary support or material support",
            label: false,
            classNames: {
              objectTitle: "rjs-h2",
              fieldContainer: "rjs-full"
            }
          },
          pharmaceutical_companies: {
            ...hide
          },
          charities: {
            ...hide
          },
          government_body: {
            ...hide
          },
          research_councils: {
            ...hide
          },
          eu_funding_scheme: {
            ...hide
          },
          other_funding_sources: {
            "ui:options": {
              // title: "Others",
              addButtonTitle: "Add Source",
              label: false,
              addable: true,
              removable: true,
              orderable: true,
              classNames: arrayClassNames.primary
            },
            items: {
              names: {
                "ui:options": {
                  title: "Organisation name",
                  hideFork: true,
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-col-1-3",
                  }
                },
              },
              funding: {
                "ui:options": {
                  title: "Approximate % funding",
                  hide: true,
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-col-1-3",
                  }
                },
              }
            }
          },
        },
        signed_funding_contract_date: {
          ...hide
        },
        data_collection_start_date: {
          ...hide,
        },
        data_analysis_start_date: {
          ...hide
        },
        interim_report_date: {
          ...hide
        },
        final_study_report_date: {
          ...hide
        },
        record_verification_date: {
          ...hide
        },
        recruitment: {
          ...hide
        },
        expanded: {
          ...hide
        }
      },
      study_description: {
        "ui:options": {
          title: "Trial Information (Part 1)",
          classNames: {
            objectTitleContainer: "rjs-h1-container",
            objectTitle: "rjs-h1",
            objectContainer: "p-24"
          }
        },
        "ui:order": [
          "low_intervention_trial",
          "justification_for_low_intervention_trial",
          "ctis_trial_phase",
          "medical_conditions",
          "therapeutic_area",
          "trial_scope",
          "brief_summary",
          "brief_summary_translations",
          "secondary_objective",
          "secondary_objective_translations",
          "meddra",
          "principle_inclusion_criteria",
          "principle_exlusion_criteria",
          "*"
        ],
        trial_scope: {
          "ui:options": {
            title: "Trial Scope",
            label: false,
            orientation: "vertical",
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": getWidget({ type: "Multiselect", preview }),
        },
        ctis_trial_phase: {
          "ui:options": {
            title: "Trial Phase",
            hideFork: true,
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
        },
        brief_summary: {
          "ui:options": {
            title: "Main objective (English)",
            placeholder: "Enter main objective",
            tooltip: "Full scientific title of the clinical trial as specified in the study protocol submitted.",
            errorMessage: "Please specify all required information.",
            label: false,
            classNames: {
              fieldContainer: "rjs-full"
            }
          },
          "ui:widget": getWidget({ type: "textarea", preview })
        },
        brief_summary_translations: {
          "ui:options": {
            title: "Main Objective Languages",
            itemTitle: "Translation",
            addButtonTitle: "Add Translation",
            label: false,
            addable: true,
            removable: true,
            orderable: true,
            classNames: arrayClassNames.primary
          },
          items: {
            language: {
              "ui:options": {
                title: "Language",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            translation: {
              "ui:options": {
                title: "Translation",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-2-3"
                }
              },
              "ui:widget": getWidget({ type: "textarea", preview })
            },
          },
        },
        secondary_objective: {
          "ui:options": {
            title: "Secondary objective (English)",
            placeholder: "Enter secondary objective",
            errorMessage: "Please specify all required information.",
            label: false,
            classNames: {
              fieldContainer: "rjs-full"
            }
          },
          "ui:widget": getWidget({ type: "textarea", preview })
        },
        secondary_objective_translations: {
          "ui:options": {
            title: "Secondary Objectives Translation",
            itemTitle: "Translation",
            addButtonTitle: "Add Translation",
            label: false,
            addable: true,
            removable: true,
            orderable: true,
            classNames: arrayClassNames.primary
          },
          items: {
            language: {
              "ui:options": {
                title: "Language",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            translation: {
              "ui:options": {
                title: "Translation",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-2-3"
                }
              },
              "ui:widget": getWidget({ type: "textarea", preview })
            },
          },
        },
        low_intervention_trial: {
          "ui:options": {
            title: "Low intervention trial",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": getWidget({ type: "SingleCheckbox", preview }),
        },
        justification_for_low_intervention_trial: {
          "ui:options": {
            title: "Justification for the low intervention trial* Attachment of justification of low interventional clinical trial",
            label: false,
            classNames: {
              fieldContainer: "rjs-full"
            }
          },
          "ui:widget": getWidget({ type: "textarea", preview })
        },
        medical_conditions: {
          "ui:options": {
            title: "Medical Conditions",
            // itemTitle: "Translation",
            addButtonTitle: "Add Condition",
            label: false,
            addable: true,
            removable: true,
            orderable: true,
            classNames: arrayClassNames.primary
          },
          items: {
            condition: {
              "ui:options": {
                title: "Medical condition(s) (English)",
                required: true,
                hideFork: true,
                label: false,
                classNames: {
                  fieldContainer: "rjs-full"
                }
              },
              "ui:widget": getWidget({ type: "textarea", preview })
            },
            translations: {
              "ui:options": {
                title: "Translations",
                itemTitle: "Translation",
                addButtonTitle: "Add Translation",
                label: false,
                hideFork: true,
                addable: true,
                removable: true,
                orderable: true,
                classNames: arrayClassNames.secondary
              },
              items: {
                language: {
                  "ui:options": {
                    title: "Language",
                    label: false,
                    hideFork: true,
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                translation: {
                  "ui:options": {
                    title: "Translation",
                    label: false,
                    hideFork: true,
                    classNames: {
                      fieldContainer: "rjs-full"
                    }
                  },
                  "ui:widget": getWidget({ type: "textarea", preview })
                },
              },
            },
            is_medical_condition_rare: {
              "ui:options": {
                title: "Is the medical condition considered to be a rare disease?",
                label: false,
                hideFork: true,
                classNames: {
                  fieldContainer: "rjs-full",
                }
              },
              "ui:widget": getWidget({ type: "SingleCheckbox", preview })
            },
          },
        },
        therapeutic_area: {
          "ui:options": {
            title: "Therapeutic area",
            label: false,
            orientation: "vertical",
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": getWidget({ type: "Multiselect", preview }),
        },
        meddra: {
          "ui:options": {
            title: "MedDRA",
            // itemTitle: "Translation",
            addButtonTitle: "Add MedDRA",
            label: false,
            addable: true,
            removable: true,
            orderable: true,
            classNames: arrayClassNames.primary
          },
          items: {
            term_name: {
              "ui:options": {
                title: "Term Name",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            level: {
              "ui:options": {
                title: "Level",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              },
            },
            classification_code: {
              "ui:options": {
                title: "Classification Code",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              },
            },
          },
        },
        principle_inclusion_criteria: {
          "ui:options": {
            title: "Principal inclusion criteria",
            addButtonTitle: "Add inclusion criteria",
            label: false,
            addable: true,
            removable: true,
            orderable: true,
            classNames: arrayClassNames.primary
          },
          items: {
            inclusion_criteria: {
              "ui:options": {
                title: "Inclusion criteria (English)",
                label: false,
                classNames: {
                  fieldContainer: "rjs-full"
                }
              }
            },
            translations: {
              "ui:options": {
                title: "Translations",
                itemTitle: "Translation",
                addButtonTitle: "Add Translation",
                arrayItemTitle: "Translation",
                showIndex: true,
                label: false,
                addable: true,
                removable: true,
                orderable: true,
                classNames: arrayClassNames.secondary
              },
              items: {
                language: {
                  "ui:options": {
                    title: "Language",
                    label: false,
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                translation: {
                  "ui:options": {
                    title: "Translation",
                    label: false,
                    classNames: {
                      fieldContainer: "rjs-col-2-3"
                    }
                  },
                  "ui:widget": getWidget({ type: "textarea", preview })
                },

              },
            },
          },
        },
        principle_exlusion_criteria: {
          "ui:options": {
            title: "Principle exclusion criteria",
            itemTitle: "Translation",
            addButtonTitle: "Add exclusion criteria",
            label: false,
            addable: true,
            removable: true,
            orderable: true,
            classNames: arrayClassNames.primary
          },
          items: {
            exclusion_criteria: {
              "ui:options": {
                title: "Exclusion criteria (English)",
                label: false,
                classNames: {
                  fieldContainer: "rjs-full"
                }
              }
            },
            translations: {
              "ui:options": {
                title: "Translations",
                itemTitle: "Translation",
                addButtonTitle: "Add Translation",
                arrayItemTitle: "Translation",
                showIndex: true,
                label: false,
                addable: true,
                removable: true,
                orderable: true,
                classNames: arrayClassNames.secondary
              },
              items: {
                language: {
                  "ui:options": {
                    title: "Language",
                    label: false,
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                translation: {
                  "ui:options": {
                    title: "Translation",
                    label: false,
                    classNames: {
                      fieldContainer: "rjs-col-2-3"
                    }
                  },
                  "ui:widget": getWidget({ type: "textarea", preview })
                },

              },
            },
          },
        },
        protocol_information: {
          "ui:options": {
            title: "Protocol Information",
            classNames: {
              objectTitle: "rjs-h2",
              fieldContainer: "rjs-full"
            }
          },
          periods: {
            "ui:options": {
              title: "Periods",
              itemTitle: "Period",
              addButtonTitle: "Add Period",
              label: false,
              addable: true,
              removable: true,
              orderable: true,
              classNames: arrayClassNames.secondary
            },
            items: {
              title: {
                "ui:options": {
                  title: "Title",
                  placeholder: "",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              description: {
                "ui:options": {
                  title: "Description",
                  placeholder: "",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-full"
                  }
                },
                "ui:widget": getWidget({ type: "textarea", preview })
              },
              allocation_method: {
                "ui:options": {
                  title: "Allocation method",
                  placeholder: "",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              binding_used: {
                "ui:options": {
                  title: "Blinding used",
                  placeholder: "",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              roles_blinded: {
                "ui:options": {
                  title: "Roles blinded",
                  placeholder: "",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                },
                "ui:widget": getWidget({ type: "Multiselect", preview })
              },
              blinding_implementation_details: {
                "ui:options": {
                  title: "Blinding implementation details",
                  placeholder: "",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-full"
                  }
                },
                "ui:widget": getWidget({ type: "textarea", preview })
              },
              arms: {
                "ui:options": {
                  title: "Arms",
                  itemTitle: "Arm",
                  addButtonTitle: "Add Arm",
                  label: false,
                  arrayItemTitle: "Arm",
                  addable: true,
                  removable: true,
                  orderable: true,
                  showIndex: true,
                  classNames: arrayClassNames.primary
                },
                items: {
                  title: {
                    "ui:options": {
                      label: false,
                      title: "Title",
                      validations: [],
                      classNames: {
                        fieldContainer: "rjs-col-1-3"
                      }
                    },
                  },
                  description: {
                    "ui:options": {
                      label: false,
                      title: "Description",
                      validations: [],
                      classNames: {
                        fieldContainer: "rjs-full"
                      }
                    },
                    "ui:widget": getWidget({ type: "textarea", preview })
                  }
                }
              }
            }
          },
        },
        scientific_advice_and_pip: {
          "ui:options": {
            title: "Scientific advice and Paediatric Investigation Plan (PIP)",
            classNames: {
              objectTitle: "rjs-h2",
              fieldContainer: "rjs-full"
            }
          },
          "ui:order": [
            "investigator_contact_details",
            "scientific_advice",
            "*"
          ],
          scientific_advice: {
            "ui:options": {
              title: "Scientific advice",
              addButtonTitle: "Add competent authority",
              label: false,
              addable: true,
              removable: true,
              orderable: true,
              classNames: arrayClassNames.secondary
            },
            items: {
              name: {
                "ui:options": {
                  title: "Name",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              id: {
                "ui:options": {
                  title: "ID",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              city: {
                "ui:options": {
                  title: "City",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              country: {
                "ui:options": {
                  title: "Country",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              address: {
                "ui:options": {
                  title: "Address",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              postcode: {
                "ui:options": {
                  title: "Postcode",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              phone: {
                "ui:options": {
                  title: "Phone",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              email: {
                "ui:options": {
                  title: "E-mail",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
            },
          },
          paediatric_investigation_plan: {
            "ui:options": {
              title: "Paediatric investigation plan",
              addButtonTitle: "Add PIP",
              label: false,
              addable: true,
              removable: true,
              orderable: true,
              classNames: arrayClassNames.secondary
            },
            items: {
              ema_paediatric_investigation_number: {
                "ui:options": {
                  title: "EMA paediatric investigation number",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
            },
          },
        },
        associated_clinical_trials: {
          "ui:options": {
            title: "Associated clinical trials",
            classNames: {
              objectTitle: "rjs-h2",
              fieldContainer: "rjs-full"
            }
          },
          associated_clinical_trials: {
            "ui:options": {
              title: "Associated clinical trials",
              itemTitle: "Identifier",
              addButtonTitle: "Associate clinical trial",
              label: false,
              addable: true,
              removable: true,
              orderable: true,
              classNames: arrayClassNames.secondary
            },
            items: {
              identifier: {
                "ui:options": {
                  title: "Identifier",
                  label: false,
                  hideFork: true,
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              full_title: {
                "ui:options": {
                  title: "Full title",
                  label: false,
                  hideFork: true,
                  classNames: {
                    fieldContainer: "rjs-full"
                  }
                },
                "ui:widget": getWidget({ type: "textarea", preview })
              },
            },
          },
        },
        sponsors: {
          "ui:options": {
            title: "Sponsors",
            classNames: {
              // objectTitleContainer: "rjs-h1-container",
              objectTitle: "rjs-h2",
            }
          },
          sponsors: {
            "ui:options": {
              // title: "Sponsors",
              itemTitle: "Sponsor",
              addButtonTitle: "Add Sponsor",
              label: false,
              addable: true,
              removable: true,
              orderable: true,
              classNames: arrayClassNames.primary
            },
            items: {
              "ui:order": [
                "sponsor",
                "add_legal_representative",
                "legal_representative",
                "add_scientific_contact_point",
                "scientific_contact_point",
                "add_public_contact_point",
                "public_contact_point",
                "*"
              ],
              sponsor: {
                "ui:options": {
                  title: "Sponsor",
                  classNames: {
                    objectTitle: "rjs-h3"
                  }
                },
                organisation_name: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Name",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                status: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Status",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                address: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Address",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                city: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Town/City",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                zip_code: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Post code",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                country: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Country",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                email: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Email",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                phone: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Phone",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
              },
              add_legal_representative: {
                "ui:options": {
                  title: "Add legal representative",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                },
                "ui:widget": getWidget({ type: "SingleCheckbox", preview }),
              },
              legal_representative: {
                "ui:options": {
                  title: "Legal representative",
                  classNames: {
                    objectTitle: "rjs-h3"
                  }
                },
                organisation_name: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Name",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                status: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Status",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                address: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "address",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                city: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Town/City",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                zip_code: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Post code",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                country: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Country",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                email: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Email",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                phone: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Phone",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                contact: {
                  "ui:options": {
                    title: "Contact",
                    classNames: {
                      objectTitle: "rjs-h3"
                    }
                  },
                  first_name: {
                    "ui:options": {
                      label: false,
                      required: true,
                      title: "First Name",
                      classNames: {
                        fieldContainer: "rjs-col-1-3"
                      }
                    }
                  },
                  last_name: {
                    "ui:options": {
                      label: false,
                      required: true,
                      title: "Last Name",
                      classNames: {
                        fieldContainer: "rjs-col-1-3"
                      }
                    }
                  },
                  email: {
                    "ui:options": {
                      label: false,
                      required: true,
                      title: "Email address",
                      classNames: {
                        fieldContainer: "rjs-col-1-3"
                      }
                    }
                  },
                  phone: {
                    "ui:options": {
                      label: false,
                      required: true,
                      title: "Phone number",
                      classNames: {
                        fieldContainer: "rjs-col-1-3"
                      }
                    }
                  },
                }
              },
              add_scientific_contact_point: {
                "ui:options": {
                  title: "Add scientific contact point",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                },
                "ui:widget": getWidget({ type: "SingleCheckbox", preview }),
              },
              scientific_contact_point: {
                "ui:options": {
                  title: "Scientific contact point",
                  classNames: {
                    objectTitle: "rjs-h3"
                  }
                },
                organisation_name: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Name",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                functional_contact_point_name: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Status",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                email: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Email",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                phone: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Phone",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
              },
              add_public_contact_point: {
                "ui:options": {
                  title: "Add public contact point",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                },
                "ui:widget": getWidget({ type: "SingleCheckbox", preview }),
              },
              public_contact_point: {
                "ui:options": {
                  title: "Public contact point",
                  classNames: {
                    objectTitle: "rjs-h3"
                  }
                },
                organisation_name: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Name",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                functional_contact_point_name: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Status",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                email: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Email",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                phone: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Phone",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
              },
              third_party: {
                "ui:options": {
                  title: "Third party",
                  itemTitle: "Sponsor",
                  addButtonTitle: "Add third party",
                  label: false,
                  addable: true,
                  removable: true,
                  orderable: true,
                  classNames: arrayClassNames.primary
                },
                items: {
                  organisation_name: {
                    "ui:options": {
                      label: false,
                      required: true,
                      title: "Name",
                      classNames: {
                        fieldContainer: "rjs-col-1-3"
                      }
                    }
                  },
                  country: {
                    "ui:options": {
                      label: false,
                      required: true,
                      title: "Country",
                      classNames: {
                        fieldContainer: "rjs-col-1-3"
                      }
                    }
                  },
                  address: {
                    "ui:options": {
                      label: false,
                      required: true,
                      title: "Address",
                      classNames: {
                        fieldContainer: "rjs-col-1-3"
                      }
                    }
                  },
                  city: {
                    "ui:options": {
                      label: false,
                      required: true,
                      title: "Town/City",
                      classNames: {
                        fieldContainer: "rjs-col-1-3"
                      }
                    }
                  },
                  zip_code: {
                    "ui:options": {
                      label: false,
                      required: true,
                      title: "Post code",
                      classNames: {
                        fieldContainer: "rjs-col-1-3"
                      }
                    }
                  },
                  phone: {
                    "ui:options": {
                      label: false,
                      required: true,
                      title: "Phone",
                      classNames: {
                        fieldContainer: "rjs-col-1-3"
                      }
                    }
                  },
                  email: {
                    "ui:options": {
                      label: false,
                      required: true,
                      title: "Email",
                      classNames: {
                        fieldContainer: "rjs-col-1-3"
                      }
                    }
                  },
                  duties: {
                    "ui:options": {
                      title: "Duties",
                      label: false,
                      orientation: "vertical",
                      classNames: {
                        fieldContainer: "rjs-col-1-3"
                      }
                    },
                    "ui:widget": getWidget({ type: "Multiselect", preview }),
                  },
                  other_duties: {
                    "ui:options": {
                      title: "Please explain if other duties: ",
                      label: false,
                      orientation: "vertical",
                      classNames: {
                        fieldContainer: "rjs-full"
                      }
                    },
                    "ui:widget": getWidget({ type: "textarea", preview }),
                  }
                }
              }
            },
          },
          contact_point_for_union: {
            "ui:options": {
              title: "Contact point for union",
              classNames: {
                objectTitle: "rjs-h2",
              }
            },
            organisation_name: {
              "ui:options": {
                label: false,
                required: true,
                title: "Name",
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            address_1: {
              "ui:options": {
                label: false,
                required: true,
                title: "Address 1",
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            address_2: {
              "ui:options": {
                label: false,
                required: true,
                title: "Address 2",
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            address_3: {
              "ui:options": {
                label: false,
                required: true,
                title: "Address 3",
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            address_4: {
              "ui:options": {
                label: false,
                required: true,
                title: "Address 4",
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            city: {
              "ui:options": {
                label: false,
                required: true,
                title: "Town/City",
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            zip_code: {
              "ui:options": {
                label: false,
                required: true,
                title: "Post code",
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            country: {
              "ui:options": {
                label: false,
                required: true,
                title: "Country",
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            functional_contact_point_name: {
              "ui:options": {
                label: false,
                required: true,
                title: "Functional contact point name",
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            contact: {
              "ui:options": {
                title: "Contact",
                classNames: {
                  objectTitle: "rjs-h2",
                }
              },
              first_name: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "First name",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              last_name: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Last name",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              phone: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Phone",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              email: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "email",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
            }
          }
        },
        products: {
          "ui:options": {
            title: "Products",
            classNames: {
              fieldContainer: "rjs-full",
              objectTitle: "rjs-h2",
            }
          },
          products: {
            "ui:options": {
              // title: "Products",
              arrayItemTitle: "Role",
              addButtonTitle: "Add Role",
              showIndex: true,
              label: false,
              addable: true,
              removable: true,
              orderable: true,
              classNames: arrayClassNames.primary
            },
            items: {
              "ui:order": [
                "role",
                "description",
                "authorisation_number_manufacturing_and_import",
                "quality_justification_for_no_impd_upload",
                "safety_and_efficacy_justification_for_no_impd_upload",
                "reason_for_no_ampd_upload",
                "products",
                "*"
              ],
              role: {
                "ui:options": {
                  title: "Role",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              products: {
                "ui:options": {
                  title: "Products",
                  addButtonTitle: "Add Product",
                  arrayItemTitle: "Product",
                  showIndex: true,
                  label: false,
                  addable: true,
                  removable: true,
                  orderable: true,
                  classNames: arrayClassNames.secondary
                },
                items: {
                  product: {
                    "ui:options": {
                      title: "Product",
                      label: false,
                      classNames: {
                        fieldContainer: "rjs-col-1-3"
                      }
                    }
                  },
                  eu_mp_number: {
                    "ui:options": {
                      title: "EU MP Number",
                      label: false,
                      classNames: {
                        fieldContainer: "rjs-col-1-3"
                      }
                    }
                  },
                  pharmaceutical_form: {
                    "ui:options": {
                      title: "Pharmaceutical form",
                      label: false,
                      classNames: {
                        fieldContainer: "rjs-col-1-3"
                      }
                    }
                  },
                  marketing_authorisation_number: {
                    "ui:options": {
                      title: "Marketing authorisation number",
                      label: false,
                      classNames: {
                        fieldContainer: "rjs-col-1-3"
                      }
                    }
                  },
                  strength: {
                    "ui:options": {
                      title: "Strength",
                      label: false,
                      classNames: {
                        fieldContainer: "rjs-col-1-3"
                      }
                    }
                  },
                  name_of_product: {
                    "ui:options": {
                      title: "Name of product",
                      label: false,
                      classNames: {
                        fieldContainer: "rjs-col-1-3"
                      }
                    }
                  },
                  active_substance_name: {
                    "ui:options": {
                      title: "Active substance name",
                      label: false,
                      classNames: {
                        fieldContainer: "rjs-col-1-3"
                      }
                    }
                  },
                  eu_substance_number: {
                    "ui:options": {
                      title: "EU substance number",
                      label: false,
                      classNames: {
                        fieldContainer: "rjs-col-1-3"
                      }
                    }
                  },
                  atc_code: {
                    "ui:options": {
                      title: "ATC Code",
                      label: false,
                      classNames: {
                        fieldContainer: "rjs-col-1-3"
                      }
                    }
                  },
                  atc_name: {
                    "ui:options": {
                      title: "ATC name",
                      label: false,
                      classNames: {
                        fieldContainer: "rjs-col-1-3"
                      }
                    }
                  },
                  name: {
                    "ui:options": {
                      title: "Name",
                      label: false,
                      classNames: {
                        fieldContainer: "rjs-col-1-3"
                      }
                    }
                  },
                  excluded_msc: {
                    "ui:options": {
                      title: "Excluded MSC",
                      label: false,
                      hideFork: true,
                      classNames: {
                        fieldContainer: "rjs-col-1-3"
                      }
                    },
                    "ui:widget": getWidget({ type: "Multiselect", preview }),
                  },
                  product_properties: {
                    "ui:options": {
                      title: "Details for product",
                      classNames: {
                        objectTitle: "rjs-h2",
                      }
                    },
                    medicinal_product_details: {
                      "ui:options": {
                        title: "Medicinal product details",
                        classNames: {
                          fieldContainer: "rjs-full",
                          objectTitle: "rjs-h3",
                        }
                      },
                      is_specific_paediatric_formulation: {
                        "ui:options": {
                          title: "Is this a specific paediatric formulation?",
                          label: false,
                          required: true,
                          classNames: {
                            fieldContainer: "rjs-col-1-3"
                          }
                        }
                      },
                      sponsors_product_code: {
                        "ui:options": {
                          title: "Sponsor's product code",
                          label: false,
                          classNames: {
                            fieldContainer: "rjs-col-1-3"
                          }
                        }
                      }
                    },
                    product_characteristics: {
                      "ui:options": {
                        title: "Product characteristics",
                        classNames: {
                          objectTitle: "rjs-h3",
                          fieldContainer: "rjs-full"
                        }
                      },
                      medicinal_product_characteristics: {
                        "ui:options": {
                          title: "Medicinal product characteristics",
                          label: false,
                          classNames: {
                            fieldContainer: "rjs-col-1-3"
                          }
                        }
                      },
                      other_medical_product: {
                        "ui:options": {
                          title: "Other medicinal product",
                          label: false,
                          classNames: {
                            fieldContainer: "rjs-col-1-3"
                          }
                        }
                      }
                    },
                    dosage_and_administration_details: {
                      "ui:options": {
                        title: "Dosage and administration details",
                        classNames: {
                          objectTitle: "rjs-h3",
                        }
                      },
                      route_of_administration: {
                        "ui:options": {
                          title: "Route of administration",
                          required: true,
                          label: false,
                          label: false,
                          classNames: {
                            fieldContainer: "rjs-col-1-3"
                          }
                        }
                      },
                      maximum_duration_of_treatmeant: {
                        "ui:options": {
                          title: "Maximum duration of treatment",
                          classNames: {
                            objectTitle: "rjs-h3",
                            fieldContainer: "rjs-full"
                          }
                        },
                        value: {
                          "ui:options": {
                            title: "Duration",
                            required: true,
                            label: false,
                            classNames: {
                              fieldContainer: "rjs-col-1-3"
                            }
                          }
                        },
                        duration: {
                          "ui:options": {
                            title: "Duration type",
                            required: true,
                            label: false,
                            classNames: {
                              fieldContainer: "rjs-col-1-3"
                            }
                          }
                        },
                      },
                      maximum_daily_dose_allowed: {
                        "ui:options": {
                          title: "Maximum daily dose allowed",
                          required: true,
                          label: false,
                          classNames: {
                            fieldContainer: "rjs-col-1-3"
                          }
                        }
                      },
                      daily_dose_unit_of_measure: {
                        "ui:options": {
                          title: "Daily dose unit of measure",
                          required: true,
                          label: false,
                          classNames: {
                            fieldContainer: "rjs-col-1-3"
                          }
                        }
                      },
                      maximum_total_dose_allowed: {
                        "ui:options": {
                          title: "Maximum total dose allowed",
                          required: true,
                          label: false,
                          classNames: {
                            fieldContainer: "rjs-col-1-3"
                          }
                        }
                      },
                      total_dose_use_of_measure: {
                        "ui:options": {
                          title: "Total dose unit of measure",
                          required: true,
                          label: false,
                          classNames: {
                            fieldContainer: "rjs-col-1-3"
                          }
                        }
                      }
                    },
                    information_about_modification_of_medicinal_product: {
                      "ui:options": {
                        title: "Information about the modification of the Medicinal Product ",
                        classNames: {
                          objectTitle: "rjs-h3",
                          fieldContainer: "rjs-full"
                        }
                      },
                      is_product_modified_in_relation_to_marketing_authorisation: {
                        "ui:options": {
                          title: "Has the medicinal product been modified in relation to its Marketing Authorisation?",
                          required: true,
                          label: false,
                          classNames: {
                            fieldContainer: "rjs-col-1-3"
                          }
                        }
                      },
                      description_of_modification: {
                        "ui:options": {
                          title: "Description of the modification",
                          required: true,
                          label: false,
                          classNames: {
                            fieldContainer: "rjs-col-1-3"
                          }
                        }
                      }
                    },
                    orphan_designation: {
                      "ui:options": {
                        title: "Orphan Designation",
                        classNames: {
                          objectTitle: "rjs-h3",
                          fieldContainer: "rjs-full"
                        }
                      },
                      product_have_orphan_drug_description: {
                        "ui:options": {
                          title: "Does this product have an orphan drug designation",
                          label: false,
                          classNames: {
                            fieldContainer: "rjs-col-1-3"
                          }
                        }
                      },
                      designation_number_for_orphan_drug: {
                        "ui:options": {
                          title: "Designation number for orphan drug",
                          required: true,
                          label: false,
                          classNames: {
                            fieldContainer: "rjs-col-1-3"
                          }
                        }
                      }
                    },
                    active_substance: {
                      "ui:options": {
                        title: "Active substance",
                        classNames: {
                          objectTitle: "rjs-h3",
                          fieldContainer: "rjs-full"
                        }
                      },
                      active_substance_other_descriptive_name: {
                        "ui:options": {
                          title: "Active substance other descriptive name",
                          label: false,
                          classNames: {
                            fieldContainer: "rjs-col-1-3"
                          }
                        }
                      },
                    },
                    advanced_therapy_medicinal_product: {
                      "ui:options": {
                        title: "Advanced Therapy Medicinal Product",
                        classNames: {
                          objectTitle: "rjs-h3",
                          fieldContainer: "rjs-full"
                        }
                      },
                      advanced_therapy: {
                        "ui:options": {
                          title: "Advanced Therapy",
                          addButtonTitle: "Add Advanced Therapy",
                          arrayItemTitle: "Advanced Therapy",
                          showIndex: true,
                          label: false,
                          addable: true,
                          removable: true,
                          orderable: true,
                          classNames: arrayClassNames.secondary
                        },
                        items: {
                          advanced_therapy_classification: {
                            "ui:options": {
                              title: "Advanced therapy classification",
                              label: false,
                              classNames: {
                                fieldContainer: "rjs-col-1-3"
                              }
                            }
                          },
                          cat_reference_number: {
                            "ui:options": {
                              title: "CAT reference number",
                              label: false,
                              classNames: {
                                fieldContainer: "rjs-col-1-3"
                              }
                            }
                          },
                          somatic_cell_origin: {
                            "ui:options": {
                              title: "Somatic cell origin",
                              label: false,
                              classNames: {
                                fieldContainer: "rjs-col-1-3"
                              }
                            }
                          },
                          somatic_cell_type: {
                            "ui:options": {
                              title: "Somatic cell type",
                              label: false,
                              classNames: {
                                fieldContainer: "rjs-col-1-3"
                              }
                            }
                          },
                          species_origin_xenogeneic_cell: {
                            "ui:options": {
                              title: "Species origin for the xenogeneic cell",
                              label: false,
                              required: true,
                              classNames: {
                                fieldContainer: "rjs-col-1-3"
                              }
                            }
                          },
                          specify_type_differentiated_cells: {
                            "ui:options": {
                              title: "Specify type of differentiated cells",
                              label: false,
                              required: true,
                              classNames: {
                                fieldContainer: "rjs-col-1-3"
                              }
                            }
                          },
                          tissue_engineered_cell_type: {
                            "ui:options": {
                              title: "Tissue engineered cell type",
                              label: false,
                              classNames: {
                                fieldContainer: "rjs-col-1-3"
                              }
                            }
                          },
                          origin_of_engineered_tissue: {
                            "ui:options": {
                              title: "Origin of the engineered tissue",
                              label: false,
                              classNames: {
                                fieldContainer: "rjs-col-1-3"
                              }
                            }
                          },
                          cell_specification: {
                            "ui:options": {
                              title: "Cell specification",
                              label: false,
                              required: true,
                              classNames: {
                                fieldContainer: "rjs-col-1-3"
                              }
                            }
                          },
                          tissue_engineered_xenogeneic_species_origin: {
                            "ui:options": {
                              title: "Tissue Engineered xenogeneic species of origin",
                              label: false,
                              required: true,
                              classNames: {
                                fieldContainer: "rjs-col-1-3"
                              }
                            }
                          },
                          gene_interest: {
                            "ui:options": {
                              title: "Gene of interest",
                              label: false,
                              classNames: {
                                fieldContainer: "rjs-col-1-3"
                              }
                            }
                          },
                          gene_therapy_type: {
                            "ui:options": {
                              title: "Type of gene transfer product",
                              label: false,
                              classNames: {
                                fieldContainer: "rjs-col-1-3"
                              }
                            }
                          },
                          type_of_gene_transfer_product: {
                            "ui:options": {
                              title: "Gene therapy type",
                              label: false,
                              required: true,
                              classNames: {
                                fieldContainer: "rjs-col-1-3"
                              }
                            }
                          },
                          genetically_modified_cell_present: {
                            "ui:options": {
                              title: "Genetically modified cells present?",
                              label: false,
                              classNames: {
                                fieldContainer: "rjs-col-1-3"
                              }
                            }
                          },
                          additional_description: {
                            "ui:options": {
                              title: "Additional description",
                              label: false,
                              required: true,
                              classNames: {
                                fieldContainer: "rjs-col-1-3"
                              }
                            }
                          },
                          specify_type_of_cells: {
                            "ui:options": {
                              title: "Specify type of cells",
                              label: false,
                              classNames: {
                                fieldContainer: "rjs-col-1-3"
                              }
                            }
                          },
                          origin_of_genetically_modiefied_cells: {
                            "ui:options": {
                              title: "Origin of the genetically modified cells",
                              label: false,
                              required: true,
                              classNames: {
                                fieldContainer: "rjs-col-1-3"
                              }
                            }
                          }
                        }
                      }
                    },
                    device_associated_with_medicinal_product: {
                      "ui:options": {
                        title: "Device associated with medicinal product",
                        classNames: {
                          objectTitle: "rjs-h3",
                          fieldContainer: "rjs-full"
                        }
                      },
                      device: {
                        "ui:options": {
                          title: "Device",
                          addButtonTitle: "Add Device",
                          arrayItemTitle: "Device",
                          showIndex: true,
                          label: false,
                          addable: true,
                          removable: true,
                          orderable: true,
                          classNames: arrayClassNames.secondary
                        },
                        items: {
                          product_used_in_combination_with_device: {
                            "ui:options": {
                              title: "Product used in combination with a device",
                              label: false,
                              classNames: {
                                fieldContainer: "rjs-col-1-3"
                              }
                            }
                          },
                          device_type: {
                            "ui:options": {
                              title: "Type of device",
                              label: false,
                              classNames: {
                                fieldContainer: "rjs-col-1-3"
                              }
                            }
                          },
                          device_has_ce_mark: {
                            "ui:options": {
                              title: "Device has CE mark",
                              label: false,
                              classNames: {
                                fieldContainer: "rjs-col-1-3"
                              }
                            },
                            "ui:widget": getWidget({ type: "SingleCheckbox", preview }),
                          },
                          device_trade_name: {
                            "ui:options": {
                              title: "Device Trade Name",
                              label: false,
                              classNames: {
                                fieldContainer: "rjs-full"
                              }
                            },
                            "ui:widget": getWidget({ type: "textarea", preview })
                          },
                          device_notified_body: {
                            "ui:options": {
                              title: "Device notified body",
                              label: false,
                              classNames: {
                                fieldContainer: "rjs-full"
                              }
                            },
                            "ui:widget": getWidget({ type: "textarea", preview })
                          },
                          description_of_device: {
                            "ui:options": {
                              title: "Description of the device",
                              label: false,
                              classNames: {
                                fieldContainer: "rjs-full"
                              }
                            },
                            "ui:widget": getWidget({ type: "textarea", preview })
                          }
                        }
                      }
                    },
                  }
                }
              },
              description: {
                "ui:options": {
                  title: "Description of the Placebo:*",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-full"
                  }
                },
                "ui:widget": getWidget({ type: "textarea", preview })
              },
              authorisation_number_manufacturing_and_import: {
                "ui:options": {
                  title: "Authorisation number of manufacturing and import",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-full"
                  }
                },
                "ui:widget": getWidget({ type: "textarea", preview })
              },
              quality_justification_for_no_impd_upload: {
                "ui:options": {
                  title: "IMPD - Quality: Justification for no IMPD upload",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-full"
                  },
                  "ui:widget": getWidget({ type: "textarea", preview })
                },
                "ui:widget": getWidget({ type: "textarea", preview })
              },
              safety_and_efficacy_justification_for_no_impd_upload: {
                "ui:options": {
                  title: "IMPD - Safety and Efficacy: Justification for no IMPD upload",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-full"
                  },
                },
                "ui:widget": getWidget({ type: "textarea", preview })
              },
              reason_for_no_ampd_upload: {
                "ui:options": {
                  title: "Reason for no AMPD upload",
                  label: false,
                  classNames: {
                    fieldContainer: "rjs-full"
                  },
                }
              },
            }
          },
          arms: {
            ...true
          },
          interventions: {
            ...hide
          },
          crossref: {
            ...hide
          },
          has_drug_information: {
            ...hide
          },
          study_drugs: {
            ...hide
          },
        },
        has_medical_condition: {
          ...hide
        },
        study_keywords: {
          ...hide
        },
        detailed_description: {
          ...hide
        },
        study_keywords: {
          ...hide
        },
        study_conditions: {
          ...hide
        },
        additional_medical_conditions: {
          ...hide
        }
      },
      study_scope: {
        "ui:options": {
          title: "Countries outside the European Economic Area",
          classNames: {
            objectTitleContainer: "rjs-h1-container",
            objectTitle: "rjs-h1",
            objectContainer: "p-24",
            fieldContainer: "rjs-full"
          }
        },
        "ui:order": [
          "countries_outside_eea",
          "enrollment_number",
          "*"
        ],
        countries_outside_eea: {
          "ui:options": {
            title: "Countries outside the European Economic Area",
            label: false,
            hideFork: true,
            classNames: {
              fieldContainer: "rjs-col-1-2"
            }
          },
          "ui:widget": getWidget({ type: "Multiselect", preview }),
        },
        enrollment_number: {
          "ui:options": {
            title: "Rest of the world subjects*",
            label: false,
            required: true,
            classNames: {
              fieldContainer: "rjs-col-1-2"
            }
          },
        },
        patient_registry: {
          ...hide
        },
        primary_purpose: {
          ...hide
        },
        study_phase: {
          ...hide
        },
        interventional_study_model: {
          ...hide
        },
        model_description: {
          ...hide
        },
        number_of_arms: {
          ...hide
        },
        masking: {
          ...hide
        },
        masking_description: {
          ...hide
        },
        allocation: {
          ...hide
        },
        enrollment_type: {
          ...hide
        },
        enrollment_type_obs: {
          ...hide
        },
        patient: {
          ...hide
        },
        follow_up_duration: {
          ...hide
        },
        duration_type: {
          ...hide
        },
        additional_patient_registry_data: {
          ...hide
        },
        observational_study_model: {
          ...hide
        },
        time_perspective: {
          ...hide
        },
        biospecimen: {
          ...hide
        },
        number_of_cohorts: {
          ...hide
        },
        selection: {
          ...hide
        },
        scope_of_study: {
          ...hide
        },
        primary_scope: {
          ...hide
        },
        other_scope_description: {
          ...hide
        },
        design_of_study: {
          ...hide
        },
        other_design_description: {
          ...hide
        },
      },
      study_eligibilty: {
        "ui:options": {
          title: "Eligibility Criteria",
          classNames: {
            objectTitleContainer: "rjs-h1-container",
            objectTitle: "rjs-h1",
            objectContainer: "p-24"
          }
        },
        sex: {
          "ui:options": {
            title: "Gender:",
            placeholder: "--Select--",
            errorMessage: "Gender has not been selected.",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": getWidget({ type: "radio", preview })
        },
        population_under_study: {
          "ui:options": {
            title: "Population under study",
            label: false,
            classNames: {
              fieldContainer: "rjs-full",
              objectTitle: "rjs-h2",
            }
          },
          age_range_ctis: {
            "ui:options": {
              title: "Age range",
              label: false,
              hideFork: true,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
            "ui:widget": getWidget({ type: "Multiselect", preview }),
          },
          age_range_secondary_ctis: {
            "ui:options": {
              title: "Age range secondary identifier",
              label: false,
              hideFork: true,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
            "ui:widget": getWidget({ type: "Multiselect", preview }),
          },
          clinical_trial_group: {
            "ui:options": {
              title: "Clinical trial group",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
            "ui:widget": getWidget({ type: "Multiselect", preview }),
          },
          vulnerable_population: {
            "ui:options": {
              title: "Vulnerable population",
              required: true,
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
            "ui:widget": getWidget({ type: "SingleCheckbox", preview }),
          },
          recruitment_population_group: {
            "ui:options": {
              title: "Recruitment population group",
              label: false,
              required: true,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
            "ui:widget": getWidget({ type: "Multiselect", preview }),
          },
          subjects_incapable_giving_consent: {
            "ui:options": {
              title: "Subjects incapable of giving consent personally",
              label: false,
              required: true,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
            "ui:widget": getWidget({ type: "Multiselect", preview }),
          },
          emergency_situation_description: {
            "ui:options": {
              title: "Emergency situation description",
              label: false,
              required: true,
              classNames: {
                fieldContainer: "rjs-full"
              }
            },
          },
          other_description: {
            "ui:options": {
              title: "Other description",
              label: false,
              required: true,
              classNames: {
                fieldContainer: "rjs-full"
              }
            },
          },
          age: {
            ...hide
          },
          sex: {
            ...hide
          },
          other_population: {
            ...hide
          }
        },
        gender_based: {
          ...hide
        },
        age_limits: {
          ...hide
        },
        accept_healthy_volunteers: {
          ...hide
        },
        is_clinical_study: {
          ...hide
        },
        eligibility_criteria: {
          ...hide
        },
        study_population_description: {
          ...hide
        },
        sampling_method: {
          ...hide
        },
        additional_patient_information: {
          ...hide
        },
        is_patients_followed_up: {
          ...hide
        },
        follow_up_description: {
          ...hide
        },
        study_carried_with_established_data_source: {
          ...hide
        },
        sources_of_data: {
          ...hide
        },
        registered_data_sources: {
          ...hide
        },
        unregistered_data_sources: {
          ...hide
        },
        other_data_source: {
          ...hide
        }
      },
      study_reference: {
        "ui:options": {
          title: "References",
          classNames: {
            objectTitleContainer: "rjs-h1-container",
            objectTitle: "rjs-h1",
            objectContainer: "p-24 rjs-full",
            fieldContainer: "rjs-full"
          }
        },
        study_publications: {
          "ui:options": {
            title: "Online references",
            addButtonTitle: "Add link",
            label: false,
            addable: true,
            removable: true,
            orderable: true,
            classNames: arrayClassNames.primary
          },
          items: {
            reference: {
              "ui:options": {
                title: "Reference to publication",
                label: false,
                hideFork: true,
                classNames: {
                  fieldContainer: "rjs-full"
                }
              },
            },
            url: {
              ...hide
            }
          }
        },
        citations: {
          ...hide
        },
        links: {
          ...hide
        },
        available: {
          ...hide
        },
        is_requesting_encepp_seal: {
          ...hide
        },
        make_full_protocol_public_when_study_ends: {
          ...hide
        }
      },
      sponsors: {
        "ui:options": {
          title: "Sponsors",
          classNames: {
            objectTitleContainer: "rjs-h1-container",
            objectTitle: "rjs-h1",
            objectContainer: "p-24"
          }
        },
        sponsors: {
          "ui:options": {
            title: "Sponsors",
            itemTitle: "Sponsor",
            addButtonTitle: "Add Sponsor",
            label: false,
            addable: true,
            removable: true,
            orderable: true,
            classNames: arrayClassNames.primary
          },
          items: {
            "ui:order": [
              "sponsor",
              "add_legal_representative",
              "legal_representative",
              "add_scientific_contact_point",
              "scientific_contact_point",
              "add_public_contact_point",
              "public_contact_point",
              "*"
            ],
            sponsor: {
              "ui:options": {
                title: "Sponsor",
                classNames: {
                  objectTitle: "rjs-h3"
                }
              },
              organisation_name: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Name",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              status: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Status",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              address: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Address",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              city: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Town/City",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              zip_code: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Post code",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              country: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Country",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              email: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Email",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              phone: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Phone",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
            },
            add_legal_representative: {
              "ui:options": {
                title: "Add legal representative",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              },
              "ui:widget": getWidget({ type: "SingleCheckbox", preview }),
            },
            legal_representative: {
              "ui:options": {
                title: "Legal representative",
                classNames: {
                  objectTitle: "rjs-h3"
                }
              },
              organisation_name: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Name",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              status: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Status",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              address: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "address",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              city: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Town/City",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              zip_code: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Post code",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              country: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Country",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              email: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Email",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              phone: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Phone",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              contact: {
                "ui:options": {
                  title: "Contact",
                  classNames: {
                    objectTitle: "rjs-h3"
                  }
                },
                first_name: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "First Name",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                last_name: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Last Name",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                email: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Email address",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                phone: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Phone number",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
              }
            },
            add_scientific_contact_point: {
              "ui:options": {
                title: "Add scientific contact point",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              },
              "ui:widget": getWidget({ type: "SingleCheckbox", preview }),
            },
            scientific_contact_point: {
              "ui:options": {
                title: "Scientific contact point",
                classNames: {
                  objectTitle: "rjs-h3"
                }
              },
              organisation_name: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Name",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              functional_contact_point_name: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Status",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              email: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Email",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              phone: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Phone",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
            },
            add_public_contact_point: {
              "ui:options": {
                title: "Add public contact point",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              },
              "ui:widget": getWidget({ type: "SingleCheckbox", preview }),
            },
            public_contact_point: {
              "ui:options": {
                title: "Public contact point",
                classNames: {
                  objectTitle: "rjs-h3"
                }
              },
              organisation_name: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Name",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              functional_contact_point_name: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Status",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              email: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Email",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              phone: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Phone",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
            },
            third_party: {
              "ui:options": {
                title: "Third party",
                itemTitle: "Sponsor",
                addButtonTitle: "Add third party",
                label: false,
                addable: true,
                removable: true,
                orderable: true,
                classNames: arrayClassNames.primary
              },
              items: {
                organisation_name: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Name",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                country: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Country",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                address: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Address",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                city: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Town/City",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                zip_code: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Post code",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                phone: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Phone",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                email: {
                  "ui:options": {
                    label: false,
                    required: true,
                    title: "Email",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                duties: {
                  "ui:options": {
                    title: "Duties",
                    label: false,
                    orientation: "vertical",
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  },
                  "ui:widget": getWidget({ type: "Multiselect", preview }),
                },
                other_duties: {
                  "ui:options": {
                    title: "Please explain if other duties: ",
                    label: false,
                    orientation: "vertical",
                    classNames: {
                      fieldContainer: "rjs-full"
                    }
                  },
                  "ui:widget": getWidget({ type: "textarea", preview }),
                }
              }
            }
          },
        },
        contact_point_for_union: {
          "ui:options": {
            title: "Contact point for union",
            classNames: {
              objectTitle: "rjs-h3",
            }
          },
          organisation_name: {
            "ui:options": {
              label: false,
              required: true,
              title: "Name",
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          address_1: {
            "ui:options": {
              label: false,
              required: true,
              title: "Address 1",
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          address_2: {
            "ui:options": {
              label: false,
              required: true,
              title: "Address 2",
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          address_3: {
            "ui:options": {
              label: false,
              required: true,
              title: "Address 3",
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          address_4: {
            "ui:options": {
              label: false,
              required: true,
              title: "Address 4",
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          city: {
            "ui:options": {
              label: false,
              required: true,
              title: "Town/City",
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          zip_code: {
            "ui:options": {
              label: false,
              required: true,
              title: "Post code",
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          country: {
            "ui:options": {
              label: false,
              required: true,
              title: "Country",
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          functional_contact_point_name: {
            "ui:options": {
              label: false,
              required: true,
              title: "Functional contact point name",
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          contact: {
            "ui:options": {
              title: "Contact",
              classNames: {
                objectTitle: "rjs-h3",
              }
            },
            first_name: {
              "ui:options": {
                label: false,
                required: true,
                title: "First name",
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            last_name: {
              "ui:options": {
                label: false,
                required: true,
                title: "Last name",
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            phone: {
              "ui:options": {
                label: false,
                required: true,
                title: "Phone",
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            email: {
              "ui:options": {
                label: false,
                required: true,
                title: "email",
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
          }
        }
      },
      ...hideStudyDetailsFields(),
      ...hideStudyDetailsFieldsInCtis(),
      milestone_dates: {
        ...hideStudyDetailsFieldsInCtis().milestone_dates,
        "ui:options": {
          classNames: {
            fieldContainer: "rjs-full",
            objectContainer: "pl-24 pr-24",
          }
        },
      },
      secondary_attributes: {
        ...hideSecondaryAttributeFields().secondary_attributes,
        "ui:options": {
          title: "Study Timelines and Status",
          classNames: {
            objectTitleContainer: "rjs-h1-container",
            objectTitle: "rjs-h1",
            objectContainer: "pt-24 pr-24 pl-24",
            fieldContainer: "m-0 rjs-full"
          }
        },
        europe: {
          "ui:options": {
            title: "Milestone dates",
            classNames: {
              fieldContainer: "rjs-full",
              objectTitle: "rjs-h2 p-0 mt-8 mb-8",
            }
          },
          ...hideSecondaryAttributeFields().secondary_attributes.europe,

        }
      },
      study_type: {
        ...hide
      },
      other_study_type: {
        ...hide,
      },
      eudract_number: {
        ...hide
      },
      eupas_study_type: {
        ...hide
      },
      study_type: {
        ...hide
      },
      contact_information: {
        ...hide
      },
      study_oversight: {
        ...hide
      },
      study_ipd: {
        ...hide
      },
      study_arms: {
        ...hide
      },
    }
  }

  if (preview === true) {
    getPreviewStyles(returnObj, formData);
  }

  return returnObj;
}