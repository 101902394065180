import React, { useState, useEffect } from "react";
import styled from 'styled-components';

// Components 
import PrimeField from "components/PrimeField/PrimeField";

// Context 
import useLocations from "context/hooks/useLocations";

const RJDropdown = (props) => {
  const [items, setItems] = useState([]);
  const [isDisabled, setDisabled] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [invalid, setInvalid] = useState(false);
  const [invalidText, setInvalidText] = useState("")
  const maskingConfigs = useLocations();
  const options = props.uiSchema["ui:options"];
  const title = options.title;
  const placeholder = options.placeholder;

  useEffect(() => {
    let { value, schema, uiSchema } = props;
    if(value) setSelectedItem(value);
    if(uiSchema) {
      if(uiSchema["ui:readonly"] || uiSchema["ui:disabled"]) {
        setDisabled(true)
      }
    }
  }, [props.value]);

  useEffect(() => {
    if(options.required) {
      if(selectedItem) {
        setInvalid(false)
        setInvalidText("")
      } else {
        setInvalid(true)
        if(options.invalidText) {
          setInvalidText(options.invalidText)
        } else {
          setInvalidText("This is a required field.")
        }
      }
    }
  }, [selectedItem])

  useEffect(() => {
    if(props?.formContext?.recruitmentStatus === "Recruiting" && props.id === "root_status") {
      if(!selectedItem || selectedItem.length === 0) {
        setInvalid(true)
        setInvalidText("This is a required field.")
      } else if(invalid === true) {
        setInvalid(false)
        setInvalidText("")
      }
    }

  }, [props?.formContext?.recruitmentStatus, selectedItem])

  useEffect(() => {
    if(maskingConfigs?.locationMaskSettings) {
      const { investigator_mask } = maskingConfigs.locationMaskSettings;
      if(props.id.includes("investigator")) {
        investigator_mask ? setDisabled(true) : setDisabled(false)
      }
    }
  }, [maskingConfigs?.locationMaskSettings])

  return (
    <Container>
      <PrimeField
        schema={{
          id: props.id,
          type: "dropdown",
          placeholder,
          items: props.options.enumOptions,
        }}

        status={invalid && "error"}
        disabled={isDisabled}
        errorMessage={invalidText}
        showError={invalid}
        value={props.value}
        onChange={props.onChange}/>
    </Container>
  );
};

export default RJDropdown;

const Container = styled.div`
  
`

const TooltipContainer = styled.div`

`