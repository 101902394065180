import React from 'react';

// styles
import './banner.scss';

const Banner = ({
  onHide = () => {},
  variant = "warning",
  children
}) => {
  return (
    <div className={`banner-container ${variant}`}>
      <div className="close-banner-container" onClick={onHide}>
        <i className="fal fa-times"></i>
      </div>
      {children}
    </div>
  );
};

export default Banner;