export const intersperse = (arr, sep) => {
  if (arr.length === 0) {
    return [];
  }
  return arr.slice(1).reduce(function(xs, x, i) {
    return xs.concat([sep, x]);
  }, [arr[0]]);
}

export const capitalizeFirstLetter = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

export const lowerCaseFirstLetter = (string) => {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export const strToCamelCase = (str, splitValue) => {
  let arr = str.split(splitValue).map(word => capitalizeFirstLetter(word));
  let newStr = arr.join("")
  return lowerCaseFirstLetter(newStr);

} 

export const customSort = ({data, sortBy, sortField}) => {
  const sortByObject = sortBy.reduce((obj, item, index) => {
    return {
      ...obj,
      [item]: index
    }
  }, {})

  return data.sort((a, b) => sortByObject[a[sortField]] - sortByObject[b[sortField]])
}

export const objToFormData = (data) => {
  let formData = new FormData();
  Object.keys(data).forEach(prop => {
    formData.append(prop, data[prop])
  })
  return formData
}

export const highlightHtmlString = (text, className) => {
  // if has text break, add html break later
  let arr = text.split('\n');
  // if class passed, surrond with span.class
  if (className) {
    arr = arr.map((txt) => `<span class='${className}'>${txt}</span>`);
  };
  if (arr.length > 0) {
    return arr.join('<br />');
  } else {
    return arr.join('');
  }
};