import { useState, useContext, useEffect, createContext } from 'react';


const useAssessments = () => {
  return useContext(AssessmentsContext);
};

const AssessmentsContext = createContext({});

export const AssessmentsProvider = ({ children }) => {
  const [missingFields, setMissingFields] = useState(null)
  const [selectedAssessment, setSelectedAssessment] = useState(null)

  return (
    <AssessmentsContext.Provider
      value={{
        selectedAssessment, 
        setSelectedAssessment,
        missingFields, 
        setMissingFields
      }}>
      {children}
    </AssessmentsContext.Provider>
  );
};

export default useAssessments;