import { useState, Fragment, useRef } from 'react';
import { useShallow } from 'zustand/react/shallow'

// components
import PrimeField from 'components/PrimeField/PrimeField';
import { Button } from 'components-design-system';
import RJReadOnly from '../../../../components/RJReadOnly/RJReadOnly';
import EventItemHeader from '../EventItemHeader/EventItemHeader';
import EventCell from '../EventCell/EventCell';
import CellValidation from '../../../components/CellValidation/CellValidation';

// context
import useResultsTableViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableViewStore';
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';
import useAuthoringPrintStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringPrintStore';

// utils
import { translateProps } from '../../helpers/helpers';
import { useInView } from 'react-intersection-observer';

const EventItem = ({
  event,
  eventListKey,
  rowIndex,
  events,
  adverseData,
  setAdverseData,
  schema
}) => {
  const { ref: visibleRef, inView: inViewport } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  });

  const {
    isPrinting
  } = useAuthoringPrintStore(
    useShallow(state => ({ isPrinting: state.isPrinting }))
  )

  const {
    readOnly
  } = useAuthoringViewStore(
    useShallow(state => ({ readOnly: state.readOnly }))
  )

  const {
    tableView
  } = useResultsTableViewStore()

  const [expand, setExpand] = useState(false);

  let fieldSchema = schema.properties[tableView].serious_adverse_event.properties;

  const onChange = (e, key) => {
    let tempData = { ...adverseData };

    Object.keys(tempData).forEach(registry => {
      let foundEventIndex = tempData[registry][eventListKey].findIndex(item => item.id === event.id);
      let tempKey = eventListKey.slice(0, -1)
      let fieldSchema = schema.properties[registry][tempKey].properties;

      if (foundEventIndex !== -1) {
        let adverseEvent = tempData[registry][eventListKey][foundEventIndex];
        if (key === "assessment_type") {
          if (registry !== tableView) adverseEvent[key] = translateProps[tableView][registry][key][e];
          else adverseEvent[key] = e;
        } else if (key === "source_vocabulary" && (e === "MedDRA" || e === "SNOMED CT")) {
          adverseEvent[key] = e;
          let foundEUDEventIndex = tempData["eudract"][eventListKey].findIndex(item => item.id === event.id);
          if (foundEUDEventIndex !== -1) {
            tempData["eudract"][eventListKey][foundEUDEventIndex]["dictionary_name"] = e;
            tempData["eudract"][eventListKey][foundEUDEventIndex]["other_dictionary_name"] = null;
          }
        } else if (key === "source_vocabulary") {
          adverseEvent[key] = e;
          let foundEUDEventIndex = tempData["eudract"][eventListKey].findIndex(item => item.id === event.id);
          if (foundEUDEventIndex !== -1) {
            tempData["eudract"][eventListKey][foundEUDEventIndex]["dictionary_overridden"] = "Yes";
            tempData["eudract"][eventListKey][foundEUDEventIndex]["other_dictionary_name"] = e;
            tempData["eudract"][eventListKey][foundEUDEventIndex]["dictionary_name"] = "Other (specify)";
          }
        } else if (key === "dictionary_overridden" && e === "No") {
          if (registry === "eudract") {
            adverseEvent["dictionary_name"] = null;
            adverseEvent["other_dictionary_name"] = null;
            adverseEvent[key] = e;
            let foundCTGEventIndex = tempData["ctg"][eventListKey].findIndex(item => item.id === event.id);

            if (foundCTGEventIndex !== -1) {
              tempData["ctg"][eventListKey][foundCTGEventIndex]["source_vocabulary"] = tempData["ctg"]["source_vocabulary"]
            }
          }
        } else if (fieldSchema[key]) {
          adverseEvent[key] = e;
        }

        if (tableView === "eudract" && adverseEvent["dictionary_name"] !== "Other (specify)" && key !== "dictionary_overridden") {
          adverseEvent["other_dictionary_name"] = null;
          let foundCTGEventIndex = tempData["ctg"][eventListKey].findIndex(item => item.id === event.id);
          if (foundCTGEventIndex !== -1) {
            tempData["ctg"][eventListKey][foundCTGEventIndex]["source_vocabulary"] = adverseEvent["dictionary_name"]
          }
        }
      }
    })

    setAdverseData(tempData);
  }

  const displayCollapsed = () => {
    let key1 = "organ_system_name"
    let key2 = "term";

    return (
      <>
        <EventItemHeader
          rowIndex={rowIndex}
          event={event}
          events={events}
          eventListKey={eventListKey}
          adverseData={adverseData}
          setAdverseData={setAdverseData}>
          {readOnly !== true
            ? <>
              <PrimeField
                schema={{
                  type: fieldSchema[key1].type,
                  items: fieldSchema[key1].enum,
                  label: fieldSchema[key1].title,
                }}
                readOnly={readOnly}
                value={event[key1]}
                containerStyle={{ marginBottom: 12 }}
                onChange={e => onChange(e, key1)} />
              <CellValidation
                table="adverse"
                errorKey={`results_adverse_events_adverse_events_table_${tableView}_${eventListKey}_${rowIndex + 1}_${key1}`}
                style={{ marginBottom: 16 }} />
              <PrimeField
                schema={{
                  type: fieldSchema[key2].type,
                  items: fieldSchema[key2].enum,
                  label: fieldSchema[key2].title,
                }}
                readOnly={readOnly}
                containerStyle={{ marginBottom: 12 }}
                value={event[key2]}
                onChange={e => onChange(e, key2)} />
              <CellValidation
                table="adverse"
                errorKey={`results_adverse_events_adverse_events_table_${tableView}_${eventListKey}_${rowIndex + 1}_${key2}`}
                style={{ marginBottom: 16 }} />
            </>
            : <>
              <RJReadOnly
                labelText={fieldSchema[key1].title}
                compare={false}
                value={event[key1]} />
              <RJReadOnly
                labelText={fieldSchema[key2].title}
                compare={false}
                value={event[key2]} />
            </>
          }
          <Button
            variant="link"
            onClick={() => setExpand(true)}>
            Expand
          </Button>
        </EventItemHeader>
        {event?.values?.map((value, valueIndex) => {
          return (
            <EventCell
              key={`${tableView}-event-${event.id}-${valueIndex}`}
              event={event}
              value={value}
              valueIndex={valueIndex}
              eventListKey={eventListKey}
              adverseData={adverseData}
              setAdverseData={setAdverseData}
              schema={schema} 
              rowIndex={rowIndex}/>
          )
        })}
      </>
    )
  }

  const displayExpanded = () => {
    let fields = Object.keys(fieldSchema);
    if (tableView === "eudract") {
      if (event.dictionary_overridden === "No") {
        fields = Object.keys(fieldSchema).filter(key => (
          key !== "dictionary_name" &&
          key !== "dictionary_version" &&
          key !== "other_dictionary_name"
        ))
      }
    }

    return (
      <>
        <EventItemHeader
          events={events}
          event={event}
          rowIndex={rowIndex}
          adverseData={adverseData}
          setAdverseData={setAdverseData}
          eventListKey={eventListKey}
          style={{ width: 600 }}>
          {fields.map(key => {
            let disabled = false;
            if (key === "other_dictionary_name" &&
              tableView === "eudract" &&
              event.dictionary_overridden === "Yes" &&
              event.dictionary_name !== "Other (specify)") {
              disabled = true;
            }
            return (
              <Fragment key={`event-item-${event.id}-${key}`}>
                {readOnly !== true
                  ? <PrimeField
                    schema={{
                      type: fieldSchema[key].type,
                      items: fieldSchema[key].enum,
                      label: fieldSchema[key].title,
                      readOnly,
                      disabled
                    }}
                    containerStyle={{ marginBottom: 12 }}
                    value={event[key]}
                    onChange={e => onChange(e, key)} />
                  : <RJReadOnly
                    labelText={fieldSchema[key].title}
                    compare={false}
                    value={event[key]} />
                }
                <CellValidation
                  table="adverse"
                  errorKey={`results_adverse_events_adverse_events_table_${tableView}_${eventListKey}_${rowIndex + 1}_${key}`}
                  style={{ marginBottom: 16 }} />
              </Fragment>
            )
          })}
          <Button
            variant="link"
            onClick={() => setExpand(false)}>
            Collapse
          </Button>
        </EventItemHeader>
        {event?.values?.map((value, valueIndex) => {
          return (
            <EventCell
              key={`${tableView}-event-${event.id}-${valueIndex}`}
              event={event}
              value={value}
              valueIndex={valueIndex}
              eventListKey={eventListKey}
              adverseData={adverseData}
              setAdverseData={setAdverseData}
              schema={schema} 
              rowIndex={rowIndex}/>
          )
        })}
      </>
    )
  }

  return (
    <tr ref={visibleRef}>
      {(inViewport || events?.length < 10 || isPrinting) ? (
        <>
          {expand
            ? displayExpanded()
            : displayCollapsed()
          }
        </>

      ) : (
        event.values.map((skel, i) => {
          return <td key={"skel - " + i} style={{ padding: 10 }} />
        })
      )
      }
    </tr>
  );
};

export default EventItem;