import React from 'react'
import PropTypes from 'prop-types'
import './ErrorMessage.scss'

const ErrorMessage = ({
  text,
  className,
}) => {
  if (!text) return null;

  return <div className={`prime-error-message${className ? ' ' + className : ''}`}>
    <i className="fal fa-exclamation-circle" />
    <div className="prime-error-text">
      {text}
    </div>
  </div>
}

ErrorMessage.propTypes = {
  text: PropTypes.string,
}

export default ErrorMessage