import React, { useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid'

const Compare = (props) => {
  const container = useRef(null);

  useEffect(() => {
    if(container?.current?.offsetHeight && props.data.field_label === "Intervention") {
      let height = container.current.offsetHeight;
      props.api.forEachNode((rowNode, i) => {
        if(rowNode.rowIndex === props.node.rowIndex) {
          rowNode.setRowHeight(height)
          props.api.onRowHeightChanged()
        }
      })
    }
  }, [container])

  const getFormatInterventions = (interventions) => {
    return interventions.map((int, i) => {
      return (
        <Intervention key={uuidv4()}>
          <Title>{`${int.name}: ${int.type}`}</Title>
          <Description>{int.description}</Description>
        </Intervention>
      )
    })
  }

  const displayValue = () => {
    if(typeof props.value === 'object' && props.value !== null) {
      if(props.data.field_label === "Intervention") {
        return (
          <InterventionsContainer>
            {getFormatInterventions(props.value)}
          </InterventionsContainer>
        )
      }
    } else {
      return props.value
    }
  }

  return (
    <Container ref={container}>
      {displayValue()}
    </Container>
  );
};

export default Compare;

const Container = styled.div`
  white-space: pre-line;
`

const InterventionsContainer = styled.div`
  white-space: pre-line;
`

const Intervention = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  white-space: pre-line;
  margin-bottom: 16px;
`

const Title = styled.div`
  font-weight: bold;
  height: 1.2rem;
  white-space: pre-line;
  height: auto;
  line-height: 1.1rem;
`

const Description = styled.div`
  font-size: .9rem;
  line-height: 1.2rem;
  margin-top: 6px;
  margin-bottom: 12px;
`