import React from 'react';

// styles
import './title-subtitle.scss';

const TitleSubtitle = (props) => {

  let title = props.data[props.title];
  let subtitle = props.data[props.subtitle];

  return (
    <div className="title-subtitle-cell-renderer-container">
      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>
    </div>
  );
};

export default TitleSubtitle;