import { useState } from 'react';
import { useShallow } from 'zustand/react/shallow'
import { useInView } from 'react-intersection-observer';

// components
import PrimeField from 'components/PrimeField/PrimeField';
import ArmActions from '../../../components/ArmActions/ArmActions';
import CellValidation from '../../../components/CellValidation/CellValidation';

// context
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';
import useResultsTableViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableViewStore';

// utils
import { moveLeftHelper, moveRightHelper } from '../../helpers/helpers';

const Arm = ({
  rowIndex,
  colIndex,
  arm,
  arms,
  schema,
  fieldKey,
  baselineData,
  setBaselineData,
}) => {
  const { ref: visibleRef, inView: inViewport } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  });

  const {
    readOnly,
    formView
  } = useAuthoringViewStore(
    useShallow(state => ({
      readOnly: state.readOnly,
      formView: state.formView
    }))
  )

  const {
    tableView
  } = useResultsTableViewStore()

  const [showActions, setShowActions] = useState(false)

  const onChange = (val) => {
    let tempData = { ...baselineData }

    const updateParticipants = (registry, key, group) => {
      tempData[registry].baseline_measures.forEach(bm => {
        bm.rows.forEach(row => {
          let catIndex = row.categories.findIndex(item => item.id === "num-analyzed");
          if (catIndex !== -1) {
            let colIndex = row.categories[catIndex].values.findIndex(cat => cat.arm_id === group.id);
            if (colIndex !== -1) {
              if (key === "num_units_analyzed") {
                if (row.categories[catIndex].values[colIndex].num_units_analyzed == group[key] ||
                  (!row.categories[catIndex].values[colIndex].num_units_analyzed) && !group[key]) {
                  row.categories[catIndex].values[colIndex].num_units_analyzed = val;
                }
              } else if (row.categories[catIndex].values[colIndex].value === group[key]) {
                if (bm.baseline_measure_id !== "not-collected") {
                  row.categories[catIndex].values[colIndex].value = val;
                } else {
                  row.categories[catIndex].values[colIndex].value = 0;
                }
              }
            }
          }
        })
      })
    }

    if (fieldKey === "baseline_analysis_population" || "num_units_analyzed") {
      Object.keys(baselineData).forEach(registry => {
        if (registry === "ctg") {
          let armIndex = tempData[registry].arms.findIndex(item => item.id === arm.id);
          if (armIndex !== -1) {
            updateParticipants(registry, fieldKey, arm);


            let total = tempData[registry].arms?.reduce((acc, arm) => {
              if (tempData[registry].arms[armIndex].id === arm.id) return acc + Number(val)
              else if (arm?.baseline_analysis_population) return acc + Number(arm.baseline_analysis_population)
              return acc;
            }, 0)

            tempData["ctg"].total_baseline_reporting_group = {
              subjects_analyzed: total
            }

            tempData[registry].arms[armIndex][fieldKey] = val;
          }
        } else if (registry === "eudract" && fieldKey !== "num_units_analyzed") {
          let armIndex = tempData[registry].arms.findIndex(item => item.id === arm.id);

          if (armIndex !== -1) {
            updateParticipants(registry, "arms", arm);
            tempData[registry].arms[armIndex][fieldKey] = val;
          } else {
            let subjectAnalysisSetArray = tempData[registry]?.subject_analysis_sets || []
            armIndex = subjectAnalysisSetArray.findIndex(item => item.id === arm.id);
            if (armIndex !== -1) {
              updateParticipants(registry, "subject_analysis_sets", arm);
              tempData[registry].subject_analysis_sets[armIndex][fieldKey] = val;
            }
          }
        }
      })
    } else {
      Object.keys(baselineData).forEach(registry => {
        if (registry === "ctg") {
          let armIndex = tempData[registry].arms.findIndex(item => item.id === arm.id);
          if (armIndex !== -1) {
            tempData[registry].arms[armIndex][fieldKey] = val
          }
        } else if (registry === "eudract" && fieldKey !== "num_units_analyzed") {
          let setIndex = tempData[registry]?.subject_analysis_sets?.findIndex(item => item.id === arm.id);
          if (setIndex !== -1) {
            tempData[registry].subject_analysis_sets[setIndex][fieldKey] = val
          }
        }
      })
    }

    setBaselineData(tempData);
  }

  const handleLeft = () => {
    let tempData = { ...baselineData };
    Object.keys(tempData).forEach(registry => {

      // move arms
      let foundIndex = tempData[registry].arms.findIndex(item => item.id === arm.id);
      if (foundIndex !== -1 && foundIndex !== 0) {
        moveLeftHelper(tempData, registry, "arms", foundIndex)
      }

      // move baseline measure values
      tempData[registry]?.baseline_measures?.forEach(bm => {
        bm.rows.forEach(row => {
          row.categories.forEach(category => {
            let tempValues = [...category.values];
            let foundIndex = tempValues.findIndex(item => item.arm_id === arm.id);

            if (foundIndex !== -1 && foundIndex !== 0) {
              let saved = tempValues[foundIndex - 1];
              tempValues[foundIndex - 1] = tempValues[foundIndex];
              tempValues[foundIndex] = saved;
              category.values = [...tempValues];
            }
          })
        })
      })
    })

    setBaselineData(tempData)
  }

  const handleRight = () => {
    let tempData = { ...baselineData };
    Object.keys(tempData).forEach(registry => {
      // move arms
      let foundIndex = tempData[registry].arms.findIndex(item => item.id === arm.id);
      if (foundIndex !== -1 && foundIndex !== tempData[registry].arms.length - 1) {
        moveRightHelper(tempData, registry, "arms", foundIndex)
      }

      // move baseline measure values
      tempData[registry]?.baseline_measures?.forEach(bm => {
        bm.rows.forEach(row => {
          row.categories.forEach(category => {
            let tempValues = [...category.values];
            let foundIndex = category.values.findIndex(item => item.arm_id === arm.id);

            if (foundIndex !== -1 && foundIndex !== category.values.length - 1) {
              let saved = tempValues[foundIndex + 1];
              tempValues[foundIndex + 1] = tempValues[foundIndex];
              tempValues[foundIndex] = saved;
              category.values = [...tempValues];
            }
          })
        })
      })
    })

    setBaselineData(tempData)
  }

  const handleDeleteArm = (arm, deleteAll) => {
    let tempData = { ...baselineData };
    Object.keys(tempData).forEach(registry => {
      if (deleteAll === true || registry === tableView) {
        let foundIndex = tempData[registry].arms.findIndex(item => item.id === arm.id);
        if (foundIndex !== -1) tempData[registry].arms.splice(foundIndex, 1);

        if (registry === "eudract" && tempData[registry].subject_analysis_sets) {
          foundIndex = tempData[registry].subject_analysis_sets?.findIndex(item => item.id === arm.id);
          if (foundIndex !== -1) tempData[registry].subject_analysis_sets.splice(foundIndex, 1);
        }

        // delete baseline measure values
        tempData[registry]?.baseline_measures?.forEach(bm => {
          bm.rows.forEach(row => {
            row.categories.forEach(category => {
              let tempValues = [...category.values];
              let foundIndex = category.values.findIndex(item => item.arm_id === arm.id);

              if (foundIndex !== -1) {
                tempValues.splice(foundIndex, 1)
                category.values = [...tempValues];
              }
            })
          })
        })
      }
    });

    setBaselineData(tempData);
  }

  const canDelete = () => {
    // if (isGroupInBaselinePeriod(formData, arm) && formView.id !== "ctg") return false;
    return true;
  }

  const disableDeleteAll = () => {
    // if (isGroupInBaselinePeriod(formData, arm)) {
    //   return true;
    // }
    return false
  }

  let classNames = ["text-left"]
  if (tableView === "eudract") {
    classNames.push("disabled-row")
  }

  let isManualOverride = fieldKey === "title" || fieldKey === "description"

  return (
    <td
      ref={visibleRef}
      className={classNames.join(" ")}
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}>
      {inViewport ? (
        <>
          <PrimeField
            schema={{
              ...schema,
              style: { width: "100%" },
              size: "small",
              className: tableView === "eudract" ? "disabled-row" : ""
            }}
            disabled={(tableView === "eudract" && fieldKey !== "baseline_analysis_population") || isManualOverride}
            readOnly={readOnly}
            value={arm[fieldKey]}
            onChange={val => onChange(val)} />
          {readOnly !== true && (
            <ArmActions
              arms={arms}
              arm={arm}
              colIndex={colIndex}
              showActions={showActions}
              handleLeft={rowIndex === 0 ? handleLeft : null}
              handleRight={rowIndex === 0 ? handleRight : null}
              canDelete={rowIndex === 0 ? canDelete() : false}
              handleDeleteArm={handleDeleteArm}
              disableDeleteAll={disableDeleteAll()}
              jpath={`bc-arm-${arm.id}-${fieldKey}`}
              source={"baseline-characteristics"}
              manualOverride={isManualOverride} />
          )}
          <CellValidation
            table="baseline"
            errorKey={`results_baseline_characteristics_baseline_characteristics_table_${formView.id}_arms_${colIndex + 1}_${fieldKey}`} />
        </>
      ) : (
        <div style={{ padding: 10 }} />
      )}
    </td>
  );
};

export default Arm;