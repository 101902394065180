import * as React from 'react'
import PropTypes from 'prop-types'
import TextInput from 'components/TextInput/TextInput'
import './GridSearchInput.scss'

const GridSearchInput = ({
  setSearch,
  placeholder,
  defaultValue = '',
}) => {
  return <TextInput
    placeholder={placeholder}
    defaultValue={defaultValue}
    onChange={(evt) => setSearch(evt.target.value)}
    suffix={<i className="fas fa-search" />}
    className="grid-search-input"
  />
}

GridSearchInput.propTypes = {
  search: PropTypes.string,
  placeholder: PropTypes.string,
}

export default GridSearchInput