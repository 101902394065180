import React from 'react'
import Preset from './Preset'
import PresetFooterActions from './PresetFooterActions/PresetFooterActions'
import './PrimePresets.scss'

const PrimePresets = ({
  presets,
  agGridApi,
  onLoad,
  onOpenModal,
  activePreset,
  setDefaultPreset,
  onResetPreset,
}) => {
  if (!presets || presets?.length === 0) return null;

  return <div className="prime-preset">
    {presets.map((preset, i) => {
      return <Preset
        key={i}
        type="prime"
        index={i}
        agGridApi={agGridApi}
        setDefaultPreset={setDefaultPreset}
        onLoad={onLoad}
        onOpenModal={onOpenModal}
        preset={preset}
        isActive={activePreset?.id === preset.id}
      />
    })}
    <PresetFooterActions
      onResetPreset={onResetPreset}
    />
  </div>
}

export default PrimePresets