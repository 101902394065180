import * as React from 'react'
import {
  useParams,
  Link,
  useNavigate,
  useOutletContext,
} from 'react-router-dom'
import dayjs from 'dayjs'
import { useQueryClient } from '@tanstack/react-query'

// components
import Modal from 'components/Modal/Modal'
import Panel from 'containers/requests/components/Panel/Panel'
import IconButton from 'containers/requests/components/IconButton/IconButton'
import RequestAllFields from './RequestAllFields/RequestAllFields'
import FilesGridTools from 'containers/files/components/FilesGrid/FilesGridTools/FilesGridTools'
import FilesGrid from 'containers/files/components/FilesGrid/FilesGrid'
import RequestFieldPanelWrapper from 'containers/requests/components/fields/RequestFieldPanelWrapper/RequestFieldPanelWrapper'
import WorkflowTracker from 'components/WorkflowTracker/WorkflowTracker'
import { Button } from 'components-design-system'

// apis
import {
  useGetRequest,
  usePutRequest,
  usePostStudyDataSync
} from 'api/hooks/requests/useRequestsApi'
import { useGetRequestDocuments } from 'api/hooks/requests/useRequestDocumentsApi'
import queryKeys from 'api/utils/queryKeys'

// context
import useWorkflowTracker from 'context/hooks/useWorkflowTracker'

// styles
import './RequestOverview.scss'

const RequestOverview = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { requestId } = useParams()
  const { data: request } = useGetRequest(requestId)
  const { data: requestFiles = [] } = useGetRequestDocuments(requestId)
  const { projectedCompletionDate } = useWorkflowTracker()
  const putRequest = usePutRequest(request?.id)
  const postStudyDataSync = usePostStudyDataSync(request?.id)

  const { setAddFilesModalOpen } = useOutletContext()

  const [requestFormData, setRequestFormData] = React.useState(null)
  const [selectedStudies, setSelectedStudies] = React.useState([])
  const [search, setSearch] = React.useState('')
  const [editInfoModalOpen, setEditInfoModalOpen] = React.useState(false)

  React.useEffect(() => {
    if (request) {
      setRequestFormData(request)
      if (request.corestudies) {
        setSelectedStudies(request.corestudies.map(study => ({ value: study.id, label: study.study_id })))
      }
    }

    return () => {
      setRequestFormData(null)
    }
  }, [request])

  const onSelectionChanged = ({ api }) => {
    const selectedNodeData = api.getSelectedNodes().map((node) => node.data)
    console.log('Bulk remove files from request', selectedNodeData)
  }

  const onChangeRequest = (key, val) => {
    const keys = key.split(".");
    const fieldKey = keys.pop();
    let tempData = { ...requestFormData }
    const obj = keys.reduce((nestedObj, nestedKey) => nestedObj[nestedKey], tempData);
    obj[fieldKey] = val;

    setRequestFormData(tempData);
  };

  const onSaveChanges = () => {
    let studyDbIds = []
    if (selectedStudies) studyDbIds = selectedStudies.map(study => study.value);

    putRequest({
      body: {
        request_data: {
          ...requestFormData.request_data,
          custom_data: requestFormData.request_data?.custom_data || {},
        },
        due_date: requestFormData.due_date,
        request_category_id: requestFormData.request_category_id,
        study_db_ids: studyDbIds,
        projected_completion_date: dayjs(projectedCompletionDate).format("YYYY-MM-DD")
      }
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.workflows.detail(request?.workflow_id), {}, { model: "request" })
        setEditInfoModalOpen(false)
      }
    })
  }

  return <>
    <div className="request-overview">
      <Panel
        title="REQUEST INFO"
        iconClassName="fal fa-info-circle"
        actions={
          <IconButton
            className="request-action-btn"
            onClick={() => setEditInfoModalOpen(true)}
            iconClassName="fal fa-edit"
          >
            Edit info
          </IconButton>
        }
        className="request-info-panel"
      >
        <RequestFieldPanelWrapper
          onClick={() => setEditInfoModalOpen(true)}
          request={request}
        />
      </Panel>

      <Panel
        title="WORKFLOW"
        iconClassName="fal fa-project-diagram"
        actions={
          <IconButton
            onClick={() => navigate(`/request/${requestId}/workflow`)}
            iconClassName="fal fa-arrow-right"
            className="request-action-btn"
          >
            Go to workflow
          </IconButton>
        }
        className="request-workflow-panel"
      >
        {/* Workflow */}
        <WorkflowTracker
          model={"request"}
          showSettings={false}
          showDays={true}
          readOnly={true}
          workflowId={request?.workflow_id} />
      </Panel>

      <Panel
        title={<Link to={`/request/${requestId}/files`}>{requestFiles?.length} FILES</Link>}
        iconClassName="fal fa-file-alt"
        actions={
          <FilesGridTools
            setSearch={setSearch}
            setAddFilesModalOpen={setAddFilesModalOpen}
          />
        }
        className="request-files-panel"
      >
        <FilesGrid
          rowData={requestFiles}
          search={search}
          // selectableColumn={{ headerCheckboxSelection: true }} // TODO: uncomment when implemnt bulk editing
          rowMultiSelectWithClick={true}
          onSelectionChanged={onSelectionChanged}
          domLayout='autoHeight'
        />
    </Panel>
    </div>
    <Modal
      type="edit"
      title="Edit request info"
      open={editInfoModalOpen}
      onCancel={() => setEditInfoModalOpen(false)}
      width="80vw"
      okText="Save"
      onOk={onSaveChanges}
      className='request-edit-modal'
      footer={[]}
    >
      <RequestAllFields
        requestFormData={requestFormData}
        onChangeRequest={onChangeRequest}
        selectedStudies={selectedStudies}
        setSelectedStudies={setSelectedStudies}
      />
      <div className="actions">
        <Button
          key="cancel-btn"
          variant="primary-outlined"
          size="sm"
          className="close-btn"
          onClick={() => setEditInfoModalOpen(false)}
        >{'Close'}</Button>
        <Button
          key="ok-btn"
          size="sm"
          variant="primary"
          className="ok-btn"
          onClick={onSaveChanges}
        >{"Save"}</Button>
        <Button
          key="ok-btn-sync"
          size="sm"
          variant="primary"
          className="ok-btn"
          disabled={request?.corestudies?.length === 0}
          tooltip={request?.corestudies?.length === 0 ? "Study data can only be synced after a study has been linked and saved to the request." : "NOTE: Any unsaved data will be removed.  Replace existing values for Product ID(s), Product name(s), Therapeutic area(s), Indication(s), Program name(s) with current values in linked studies."}
          style={{ paddingTop: 9 }}
          onClick={() => postStudyDataSync({})}
        >
          <i className="fal fa-sync" style={{ marginBottom: 1.5 }} />
        </Button>
      </div>
    </Modal>
  </>
}

export default RequestOverview