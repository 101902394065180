import { useContext, createContext, useState, useEffect, useMemo } from "react";
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import {
  useNodesState,
  useEdgesState,
  MarkerType,
  useReactFlow
} from 'reactflow';

// apis
import { useGetActivityTypes, useGetActivityType, usePostActivityType, usePutActivityType } from 'api/hooks/admin/workflowApis/useActivityTypeApis';
import { useGetRequestTypes, useGetRequestType, usePostRequestType, usePutRequestType } from 'api/hooks/admin/workflowApis/useRequestTypeApis';
import { useGetRequestFields } from 'api/hooks/admin/useRequestFieldsApi';
import buildErrorDetailsNotif from 'utilities/buildErrorDetailsNotif'

// context
import useAppState from "./useAppState";

const initialNodes = []

const initialEdges = []

const stateMap = {
  0: "Xogene",
  1: "Client",
  2: "Registry",
  "Xogene": 0,
  "Client": 1,
  "Registry": 2
}

export const WorkflowBuilderProvider = ({ children }) => {
  const { setViewport } = useReactFlow();
  const { openToastNotification } = useAppState()
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const { data: activityTypes = [] } = useGetActivityTypes()
  const { data: requestTypes = [] } = useGetRequestTypes()
  const { data: requestFields = [] } = useGetRequestFields()
  const [workflowTypeId, setWorkflowTypeId] = useState(null)
  const [workflowType, setWorkflowType] = useState(null)

  const { data: selectedActivityType } = useGetActivityType(workflowType === "activity" ? workflowTypeId : null)
  const { data: selectedRequestType } = useGetRequestType(workflowType === "request" ? workflowTypeId : null)

  const postActivityType = usePostActivityType()
  const putActivityType = usePutActivityType(workflowType === "activity" ? workflowTypeId : null)
  const postRequestType = usePostRequestType()
  const putRequestType = usePutRequestType(workflowType === "request" ? workflowTypeId : null)

  const postWorkflow = useMemo(() => {
    if (workflowType === "activity") return postActivityType;
    if (workflowType === "request") return postRequestType;
  }, [workflowType])

  const putWorkflow = useMemo(() => {
    if (workflowType === "activity") return putActivityType;
    if (workflowType === "request") return putRequestType;
  }, [workflowType])

  const [workflow, setWorkflow] = useStateWithCallbackLazy({
    title: "",
    workflow_data: {
      actions: [],
      states: []
    }
  })

  useEffect(() => {
    if (workflowType === "activity" && selectedActivityType) {
      setWorkflow({
        ...selectedActivityType
      })
    } else if (workflowType === "request" && selectedRequestType) {
      setWorkflow({
        ...selectedRequestType
      })
    }
  }, [selectedActivityType, selectedRequestType, workflowType])

  useEffect(() => {
    if (workflow?.workflow_data?.workflow_type_id) {
      let nodes = formatNodes()
      let edges = formatEdges()
      setNodes(nodes)
      setEdges(edges)
    }
  }, [workflow])

  const getLastTwoChars = (str) => {
    if (str.length < 2) {
      // Handle case where the string has less than 2 characters
      return str;
    } else {
      // Get the substring starting from the second-to-last character
      return str.substring(str.length - 2);
    }
  }

  const formatRequest = () => {
    let states = nodes.map((node, i) => {
      return {
        id: node.data.id ? node.data.id : (i + 1) * -1,
        title: node.data.title,
        description: node.data.description,
        list_order: node.data.list_order,
        is_major_state: node.data.is_major_state,
        allow_rereview: node.data.allow_rereview,
        state_type: stateMap?.[node.data?.state_type],
        should_display: node.data.should_display,
        chart_location: `${Math.floor(node.position.x)} ${Math.floor(node.position.y)}`,
        icon: "fa-file"
      }
    })

    let actions = edges.map((edge) => {
      let foundStartIndex = nodes.findIndex(node => node.id === edge.source)
      let foundEndIndex = nodes.findIndex(node => node.id === edge.target)

      let res = {
        start_state: nodes[foundStartIndex]?.data?.id ? nodes[foundStartIndex]?.data?.id : (foundStartIndex + 1) * -1,
        end_state: nodes[foundEndIndex]?.data?.id ? nodes[foundEndIndex]?.data?.id : (foundEndIndex + 1) * -1,
        ...edge.data,
        from_port: getLastTwoChars(edge.sourceHandle),
        to_port: getLastTwoChars(edge.targetHandle)
      }

      if (res.form_section === null) res.form_section = []
      if (res.form_view === null) res.form_view = []

      return res
    })

    let request = {
      title: workflow.title,
      request_source: workflow.request_source,
      workflow_data: {
        workflow_type: workflow?.workflow_data?.workflow_type_id,
        states,
        actions
      }
    }

    if (workflowType === "activity") {
      request.code = workflow.code;
      request.registry = workflow.registry;
    } else if (workflowType === "request") {
      request.ui_config_fields = workflow.ui_config_fields || []
    }

    return request
  }

  const onSave = () => {
    postWorkflow({
      body: formatRequest()
    }, {
      onSuccess: (resp) => {
        console.log(resp)
      },
      onError: (err) => {
        if (err?.response?.data?.error?.details?.workflow_data) {
          openToastNotification("error", "Error", buildErrorDetailsNotif(err.response.data.error.details.workflow_data))
        }
      }
    })
  }

  const onUpdate = () => {
    putWorkflow({
      id: `${workflowTypeId}/`,
      body: formatRequest()
    }, {
      onSuccess: (resp) => {
      },
      onError: (err) => {
        if (err?.response?.data?.error?.details?.workflow_data) {
          openToastNotification("error", "Error", buildErrorDetailsNotif(err.response.data.error.details.workflow_data))
        }
      }
    })
  }

  const formatNodes = () => {
    if (!workflow?.workflow_data?.states || workflow?.workflow_data?.states.length === 0) return []
    let maxId = Math.max(...workflow.workflow_data.states.map(state => Number(state.list_order)))
    let minId = Math.min(...workflow.workflow_data.states.map(state => Number(state.list_order)))

    return workflow.workflow_data.states.map((state, i) => {
      let positions = state?.chart_location?.split(" ");
      let position = {
        x: 0,
        y: 0
      }

      if (positions?.[0] && positions?.[1]) {
        position = {
          x: Number(positions[0]),
          y: Number(positions[1])
        }
      }

      let updatedNode = {}

      if (state.list_order === minId) {
        updatedNode = {
          id: state.id.toString(),
          position,
          type: "shapeNode",
          data: {
            shape: 'circle',
            width: 100,
            height: 100,
            color: '#5A9C56',
            ...state,
            state_type: stateMap?.[state?.state_type]
          }
        }
      } else if (state.list_order === maxId) {
        updatedNode = {
          id: state.id.toString(),
          position,
          type: "shapeNode",
          data: {
            shape: 'round-rect',
            width: 100,
            height: 50,
            title: 'State',
            color: '#D7542C',
            ...state,
            state_type: stateMap?.[state?.state_type]
          }
        }
      } else {
        updatedNode = {
          id: state.id.toString(),
          type: "stateNode",
          position,
          data: {
            ...state,
            state_type: stateMap?.[state?.state_type]
          }
        }
      }

      return updatedNode
    })
  }

  const formatEdges = () => {
    if (!workflow?.workflow_data?.actions || workflow?.workflow_data?.actions.length === 0) return []
    return workflow.workflow_data.actions.map(action => {
      let updatedEdge = {
        id: action.id,
        target: action.end_state.toString(),
        source: action.start_state.toString(),
        targetHandle: action.end_state.toString() + action.to_port,
        sourceHandle: action.start_state.toString() + action.from_port,
        label: action.button_text,
        style: { strokeWidth: 1, stroke: '#9566AB' },
        labelBgPadding: [8, 4],
        labelBgBorderRadius: 4,
        markerEnd: {
          type: MarkerType.ArrowClosed,
          color: '#9566AB',
        },
        data: {
          ...action,
        }
      }

      return updatedEdge;
    })
  }

  const resetWorkflow = () => {
    setWorkflow({
      title: "",
      actions: [],
      states: []
    })
    setNodes([])
    setEdges([])
  }

  const onExportWorkflowType = () => {
    if (reactFlowInstance) {
      const flow = reactFlowInstance.toObject();
      let activity = {
        title: workflow.title,
        flow
      }
      let str = JSON.stringify(activity);
      const element = document.createElement("a");
      const file = new Blob([str], { type: 'text/plain' });
      element.href = URL.createObjectURL(file);
      element.download = activity?.title ? activity.title : "activity_type.txt";
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    }
  }

  const onImportWorkflowType = (e) => {
    const reader = new FileReader()
    reader.onload = async (e) => {
      const text = (e.target.result)
      let activity = JSON.parse(text)
      restoreFlow(activity);
    };
    reader.readAsText(e.file.originFileObj)

    const getLastTwoChars = (str) => {
      if (str.length < 2) {
        // Handle case where the string has less than 2 characters
        return str;
      } else {
        // Get the substring starting from the second-to-last character
        return str.substring(str.length - 2);
      }
    }

    const formatFlowToPrimeNodes = (nodes = []) => {
      return nodes.map((node, i) => {
        return {
          id: node.data.id ? node.data.id : (i + 1) * -1,
          title: node.data.title,
          description: node.data.description,
          list_order: node.data.list_order,
          is_major_state: node.data.is_major_state,
          allow_rereview: node.data.allow_rereview,
          state_type: stateMap?.[node.data?.state_type],
          should_display: node.data.should_display,
          chart_location: `${Math.floor(node.position.x)} ${Math.floor(node.position.y)}`,
          icon: "fa-file"
        }
      })
    }

    const formatFlowToPrimeEdges = (edges = []) => {
      return edges.map((edge) => {
        let foundStartIndex = nodes.findIndex(node => node.id === edge.source)
        let foundEndIndex = nodes.findIndex(node => node.id === edge.target)
        let res = {
          start_state: nodes[foundStartIndex]?.data?.id ? nodes[foundStartIndex]?.data?.id : (foundStartIndex + 1) * -1,
          end_state: nodes[foundEndIndex]?.data?.id ? nodes[foundEndIndex]?.data?.id : (foundEndIndex + 1) * -1,
          ...edge.data,
          from_port: getLastTwoChars(edge.sourceHandle),
          to_port: getLastTwoChars(edge.targetHandle)
        }

        if (res.form_section === null) res.form_section = []
        if (res.form_view === null) res.form_view = []

        return res
      })
    }

    const restoreFlow = async (activity) => {
      let { flow } = activity;

      if (flow) {
        const { x = 0, y = 0, zoom = 1 } = flow.viewport;
        const primeNodes = formatFlowToPrimeNodes(flow.nodes)
        const primeEdges = formatFlowToPrimeEdges(flow.edges)

        setWorkflow({
          title: "",
          workflow_data: {
            states: primeNodes,
            actions: primeEdges,
          }
        })

        setNodes(flow.nodes || []);
        setEdges(flow.edges || []);
        setViewport({ x, y, zoom });
      }
    };
  }

  return (
    <ActivityContext.Provider
      value={{
        reactFlowInstance,
        setReactFlowInstance,
        nodes,
        setNodes,
        onNodesChange,
        edges,
        setEdges,
        onEdgesChange,
        activityTypes,
        requestTypes,
        workflow,
        setWorkflow,
        workflowTypeId,
        setWorkflowTypeId,
        onExportWorkflowType,
        onImportWorkflowType,
        workflowType,
        setWorkflowType,
        resetWorkflow,
        onUpdate,
        onSave,
        stateMap
      }}>
      {children}
    </ActivityContext.Provider>
  )
}

const ActivityContext = createContext({});

const useWorkflowBuilder = () => {
  return useContext(ActivityContext);
}

export default useWorkflowBuilder;