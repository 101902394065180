// usually for menus, click anywhere outside to close it
import { useEffect, useCallback } from 'react'

const useAutoClose = (ref, setIsOpen) => {
  const handleClosure = useCallback((event) => {
    if (ref?.current) {
      !ref.current.contains(event.target) && setIsOpen(false)
    }
  }, [setIsOpen, ref])
  
  useEffect(() => {
    if (ref?.current) {
      window.addEventListener('click', handleClosure)
      window.addEventListener('focusin', handleClosure)

      return () => {
        window.removeEventListener('click', handleClosure)
        window.removeEventListener('focusin', handleClosure)
      }
    }
  }, [handleClosure, ref])
}

export default useAutoClose