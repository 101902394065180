// API for admin, aka Prime, aka client, presets
import useGetApi from "../useGetApi"
import useMutateApi from "../useMutateApi"

// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

// utils
import getUrl from "api/apiMap"

export const useGetPresets = () => {
  return useGetApi(getUrl(apiEndpoints.gridPresetsAdmin), queryKeys.gridPresetsAdmin)
}

// currently returning 404 error
export const useGetPreset = (id) => { 
  return useGetApi(getUrl(`${apiEndpoints.gridPresetsAdmin}${id}/`), queryKeys.gridPresets.grid(id))
}

export const usePostPreset = () => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(apiEndpoints.gridPresetsAdmin),
    invalidateQueries: [queryKeys.gridPresetsAdmin],
    successMsg: "Successfully created grid preset"
  })
}

// mutate({ body })
export const usePutPreset = (id) => {
  return useMutateApi({
    method: "put",
    apiEndpoint: getUrl(`${apiEndpoints.gridPresetsAdmin}${id}/`),
    invalidateQueries: [queryKeys.gridPresetsAdmin],
    successMsg: "Successfully updated grid preset"
  })
}

export const useDeletePreset = () => {
  return useMutateApi({
    method: "delete",
    apiEndpoint: getUrl(apiEndpoints.gridPresetsAdmin),
    invalidateQueries: [queryKeys.gridPresetsAdmin],
    successMsg: "Successfully deleted grid preset"
  })
}