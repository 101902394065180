import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// // components
import Modal from 'components/Modal/Modal';
import PrimeField from 'components/PrimeField/PrimeField';
import { Button } from 'components-design-system'

const CommentModal = ({
  openModal,
  setOpenModal,
  saveComment,
  selectedComment
}) => {
  const [comment, setComment] = useState("");

  useEffect(() => {
    if (selectedComment) {
      setComment(selectedComment)
    }
  }, [selectedComment])

  return (
    <Modal
      title={`Add/Edit Comment`}
      open={openModal}
      onCancel={() => setOpenModal(false)}
      footer={[]}
      hideImage={true}
      >
      <PrimeField
        schema={{
          type: "textarea",
          placeholder: "Enter comment",
          style: { marginBottom: "1rem"}
        }}
        defaultValue={comment || ""}
        onChange={val => setComment(val)}/>
      <ActionContainer>
        <Button
          variant="outlined"
          onClick={() => setOpenModal(false)}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            saveComment(comment)
            setOpenModal(false)
            }}>
          Save Comment
        </Button>
      </ActionContainer>
    </Modal>
  );
};

export default CommentModal;


const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 48px;
  gap: 12px;
`