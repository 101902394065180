import { useRef, useMemo } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { getCookie, setCookie } from '../api/apiUtils';
import getUrl from 'api/apiMap';
import useAppState from 'context/hooks/useAppState';
import { isExpired } from 'react-jwt';

const accessTokenKey = "xopri-jwt-actoken"
const refreshTokenKey = "xopri-jwt-retoken"

const useRefreshToken = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const pathname = location.pathname
  const { setAppState, appStateRef, openToastNotification } = useAppState()

  const pathnameRef = useRef()

  pathnameRef.current = useMemo(() => {
    return pathname + location.search
  }, [pathname])

  const getAccessToken = () => {
    return getCookie(accessTokenKey)
  }

  const getRefreshToken = () => {
    return getCookie(refreshTokenKey)
  }

  const setTokens = (tokens) => {
    setCookie(accessTokenKey, tokens?.access)
    setCookie(refreshTokenKey, tokens?.refresh)
  }

  const isAccessTokenExpired = () => {
    let accessToken = getAccessToken()
    if (accessToken) return isExpired(getAccessToken())
    return true
  }

  const isRefreshTokenExpired = () => {
    let refreshToken = getRefreshToken()
    if (refreshToken) return isExpired(getRefreshToken())
    return true
  }

  const getRefreshedToken = () => {
    if (appStateRef.current === "unauthenticated") return
    appStateRef.current = "refreshing-token"

    return axios.post(getUrl('/auth/api/v1/token/refresh/'), {
      refresh: getRefreshToken()
    }).then(resp => {
      appStateRef.current = "authenticated"
      return resp
    }).catch(err => {
      if (err.response.data.error.status_code === 401) {
        setNextUrlLocalStorage(pathnameRef.current)
        navigate("/login", { state: { from: pathnameRef.current } })
        if (pathnameRef.current !== "/" && appStateRef.current !== "unauthenticated") openToastNotification("error", "Timeout", "Session expired")
        appStateRef.current = "unauthenticated"
        setAppState("unauthenticated")
      }
    })
  }

  const refreshTokens = async () => {
    const rToken = getRefreshToken()

    if (!rToken) {
      setNextUrlLocalStorage(pathnameRef.current)
      navigate("/login", { state: { from: pathnameRef.current } })
      if (pathnameRef.current !== "/" && appStateRef.current !== "unauthenticated") openToastNotification("error", "Timeout", "Session expired")
      appStateRef.current = "unauthenticated"
      setAppState("unauthenticated")
      return null
    }
    const resp = await getRefreshedToken()

    if (resp?.data?.access && resp?.data?.refresh) {
      const {
        access,
        refresh
      } = resp.data
      setTokens({
        access,
        refresh
      })

      appStateRef.current = "authenticated"
      setAppState("authenticated")

      return {
        access,
        refresh
      }
    }

    return null
  }

  const setNextUrlLocalStorage = (url) => {
    if (url && url !== "/" && !url.includes("saml") && !url.includes("/password-reset") && !url.includes("login")) {
      localStorage.setItem("prime-next-url", url)
    }
  }

  return {
    refreshTokens,
    isAccessTokenExpired,
    isRefreshTokenExpired,
    setTokens,
    getRefreshedToken,
    setNextUrlLocalStorage
  };
};

export default useRefreshToken;