import React, { useEffect, useState } from 'react';

// assets
import jpgIcon from '../../../assets/images/card-icons/jpg-icon.png'
import mp3Icon from '../../../assets/images/card-icons/mp3-icon.png'
import mp4Icon from '../../../assets/images/card-icons/mp4-icon.png'
import pdfIcon from '../../../assets/images/card-icons/pdf-icon.png'
import pngIcon from '../../../assets/images/card-icons/png-icon.png'
import rtfIcon from '../../../assets/images/card-icons/rtf-icon.png'
import wordIcon from '../../../assets/images/card-icons/word-doc-icon.png'
import xmlIcon from '../../../assets/images/card-icons/xml-icon.png'
import urlIcon from '../../../assets/images/card-icons/url-icon.png'
import docGenericIcon from '../../../assets/images/card-icons/doc-generic-icon.png'

// context
import useDocuments from 'context/hooks/useDocuments';

// styles
import './DocumentIconCellRenderer.scss';

const DocumentIcon = ({
  data,
}) => {
  const {
    documents,
    onOpenPdf
  } = useDocuments()
  const [documentDetails, setDocumentDetails] = useState(null)
  let icon = pdfIcon;
  let type = null;

  useEffect(() => {
    if (documents) {
      let found = documents?.find(doc => doc.document_type_name === data.document_type_name && doc.name === data.document_name)
      if (found) {
        setDocumentDetails(found)
      }
    }
  }, [documents])

  const getDocIcon = (doc) => {
    let extension = doc?.document_file;
    if(extension) extension = extension.toLowerCase();

    if (extension?.includes(".jpg") || extension?.includes(".jpeg")) {
      return jpgIcon;
    }
    else if (extension?.includes(".mp3")) {
      return mp3Icon;
    }
    else if (extension?.includes(".mp4")) {
      return mp4Icon;
    }
    else if (extension?.includes(".pdf")) {
      type = "pdf"
      return pdfIcon;
    }
    else if (extension?.includes(".png")) {
      return pngIcon;
    }
    else if (extension?.includes(".rtf")) {
      return rtfIcon;
    }
    else if (extension?.includes(".doc") || extension?.includes(".docx")) {
      // type = "word"
      return wordIcon;
    }
    else if (extension?.includes(".xml")) {
      return xmlIcon;
    }
    else if (!doc?.document_file && doc?.document_link) {
      type = "url"
      return urlIcon;
    }
    else {
      return docGenericIcon;
    }
  }

  if (data?.file_id) {
    icon = getDocIcon(data)
  }

  if (!data.document_file && data.document_link) {
    icon = urlIcon
  }

  if (documents) {
    let found = documents?.find(doc => doc.document_type_name === data.document_type_name && doc.name === data.document_name)
    if (found) {
      icon = getDocIcon(found)
    }
  }

  const handleIconClick = () => {
    let docData = data;
    if(documentDetails) docData = documentDetails;

    if (type === "pdf") onOpenPdf(docData);
    else if (type === "url") {
      let url = docData.document_link
      if (!url) {
        let found = documents?.find(doc => doc.document_type_name === docData.document_type_name && doc.name === docData.document_name)
        if (found) {
          url = found.document_link;
        }
      }
      window.open(url, '_blank');
    }
    // all other file types; open file or download
    else {
      window.open(docData.document_file, '_blank');
    }
  }

  return (
    <div className="document-icon-cell-renderer-container"
      onClick={handleIconClick}
    >
      <img className="icon" src={icon} alt="doc-icon" />
    </div>
  );
};

export default DocumentIcon;