import dayjs from 'dayjs';
import { useUserSettings } from 'api/hooks';

// styles
import './ghost-state.scss';

const GhostState = (props) => {
  const {
    ghostState,
    showGhostState,
    dateFormat,
    index,
    isLast,
    // TODO; remove props not being used
    setGhostState,
    setShowGhostState,
  } = props;
  const { data: userSettings } = useUserSettings()

  const { start_state_date, } = ghostState



  let style = {}
  if (index === 0) {
    style = {
      marginLeft: 100
    }
  } else if (isLast) {
    style = {
      left: -90
    }
  }

  return (
    <div className={`ghost-state-container ${showGhostState && 'show'} show`} style={style}>
      <div className="date-line"></div>
      <p className="ghost-date no-text-highlight">{dayjs(start_state_date).format(userSettings?.date_format)}</p>
    </div>
  )
}

export default GhostState;