import RequestOverview from './tabs/RequestOverview/RequestOverview'
import RequestFiles from './tabs/RequestFiles/RequestFiles'
import RequestWorkflow from './tabs/RequestWorkflow/RequestWorkflow'

const requestNav = [
  {
    key: 'overview',
    index: true,
    title: 'Overview',
    path: 'overview',
    element: <RequestOverview />
  },
  {
    key: 'files',
    title: 'Files',
    path: 'files',
    element: <RequestFiles />
  },
  {
    key: 'workflow',
    title: 'Workflow',
    path: 'workflow',
    element: <RequestWorkflow />
  },
]

export default requestNav