import apiEndpoints from "api/utils/apiEndpoints"
import fileDownload from "utilities/fileDownload"

const downloadPdf = async (document) => {
  const fileName = document.document_file
    .substring(document.document_file.lastIndexOf('/') + 1)
    .split('?')[0]
  const blob = await fetch(`${apiEndpoints.documents}${document.id}/download/`)
    .then((r) => r.blob())
  fileDownload(blob, fileName, 'application/pdf')
}

export default downloadPdf