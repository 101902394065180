import apiEndpoints from 'api/utils/apiEndpoints'
import queryKeys from 'api/utils/queryKeys'
import getUrl from 'api/apiMap'
import useGetApi from '../useGetApi'

const useGetRoles = (options = {}) => {
  return useGetApi(getUrl(apiEndpoints.roles), queryKeys.userRoles.all)
}

const useGetRequestRoles = (options = {}) => {
  return useGetApi(getUrl(apiEndpoints.requestRoles), queryKeys.userRoles.request)
}

export {
  useGetRoles,
  useGetRequestRoles,
}