import React from 'react';
import ReactCountryFlag from "react-country-flag"

// styles
import './language-to-flag.scss';

const LanguageToFlag = (props) => {
  return (
    <div className="language-to-flag-cell-renderer-container">
      <ReactCountryFlag 
        style={{ width: '20px', height: '12px' }}
        svg
        countryCode={"US"} />
    </div>
  );
};

export default LanguageToFlag;