import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import moment from "moment";
import DatePicker from "components/DatePicker/DatePicker";
import "./styles.css";

const RJDatePicker = (props) => {
  const [isDisabled, setDisabled] = useState(false);
  const options = props.uiSchema["ui:options"]

  let { uiSchema } = props;

  useEffect(() => {
    if (uiSchema) {
      if (uiSchema["ui:readonly"] || uiSchema["ui:disabled"]) {
        setDisabled(true)
      }
    }
  }, [props.value]);

  return (
    <Container>
      <DatePicker
        className="rj-date-picker"
        value={props.value}
        disabled={isDisabled}
        onChange={props.onChange} />
    </Container>
  );
};

export default RJDatePicker;

const Container = styled.div`
 width: 100%;
`

const TooltipContainer = styled.div`

`