import React from 'react';

// styles
import './link.scss';

const Link = ({
  action,
  title,
}) => {
  let _title = "-";
  if(_title) _title = title;

  if(title === "-") {
    return (
      <div 
        className="link-cell-renderer-container">
        -
      </div>
    )
  }

  return (
    <div 
      className="link-cell-renderer-container"
      onClick={action}>
      <p className="title">{_title}</p>
    </div>
  );
};

export default Link;