// import { 
//   arrayTitle,
//   previewStyles, 
//   widgetContainer
// } from "../styles"

export const getWidget = ({ type, preview }) => {
  // console.log(type)
  if(preview === true) {
    switch(type) {
      case "SimpleTable":
        return "SimpleTable";
      case "CrossReferenceTable":
        return "CrossReferenceTable";
      case "ParticipantFlow":
        return "ParticipantFlow"
      case "BaselineCharacteristics":
        return "BaselineCharacteristics";
      case "OutcomeMeasures":
        return "OutcomeMeasures";
      case "AdverseEvents":
        return "AdverseEvents"
      case "TrialCountries":
        return "PopulationOfTrialPreview"
      case "AgeRange":
        return "AgeRangePreview"
      case "MemberStatesConcerned": 
        return "MemberStatesConcerned"
      default: 
        return "Preview"
    }
  }

  switch(type) {
    case 'date':
      return 'date';
    case "radio":
      return 'radio';
    case "Lookahead":
      return "Lookahead";
    case "CustomCheckboxGroup":
      return "CustomCheckboxGroup";
    case "SimpleTable":
      return "SimpleTable";
    case "CrossReferenceTable":
      return "CrossReferenceTable";
    case "ParticipantFlow":
      return "ParticipantFlow"
    case "BaselineCharacteristics":
      return "BaselineCharacteristics";
    case "OutcomeMeasures":
      return "OutcomeMeasures";
    case "AdverseEvents":
      return "AdverseEvents"
    case "TrialCountries":
      return "TrialCountries"
    case "AgeRange":
      return "AgeRange"
    case "CopyContact": 
      return "CopyContact"
    case "Multiselect": 
      return "Multiselect"
    case "CopyTextArea": 
      return "CopyTextArea"
    case "SingleCheckbox": 
      return "SingleCheckbox"
    case "MemberStatesConcerned": 
      return "MemberStatesConcerned"
    case "SelectTag": 
      return "SelectTag"
    default: 
      return "textarea"
  }
}

// export const getPreviewStyles = (obj, formData) => {
//   if(obj?.protocol) {
//     obj.protocol["ui:options"] = {
//       title: "",
//       styles: { },
//     }
  
//     obj.protocol.protocol_id["ui:options"] = {
//       ...obj.protocol.protocol_id["ui:options"],
//       title: "ID: ",
//       styles: {
//         ...previewStyles.flexRow
//       }
//     }
  
//     obj.protocol.study_type["ui:options"] = {
//       ...obj.protocol.study_type["ui:options"],
//       title: "Study Type: ",
//       styles: {
//         ...previewStyles.flexRow
//       }
//     }
  
//     obj.protocol.study_identification = {
//       ...obj.protocol.study_identification,
//       "ui:order": ["brief_title", "protocol_id", "acronym", "official_title", "*"],
//       "ui:options": {
//         ...obj.protocol.study_identification["ui:options"],
//         styles: { },
//       },
//       brief_title: {
//         ...obj.protocol.study_identification.brief_title,
//         "ui:options": {
//           ...obj.protocol.study_identification.brief_title["ui:options"],
//           styles: {
//             widgetContainer: {
//               ...previewStyles.fullWidth,
//               textAlign: "center",
//               fontSize: 18,
//               marginTop: 16,
//               marginBottom: 16
//             },
//             widgetTitle: {
//               display: "none"
//             },
//             widgetValue: {
//               fontWeight: "600"
//             }
//           }
//         }
//       },
//       acronym: {
//         ...obj.protocol.study_identification.acronym,
//         "ui:options": {
//           ...obj.protocol.study_identification.acronym["ui:options"],
//           title: "Acronym (if any): ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       official_title: {
//         ...obj.protocol.study_identification.official_title,
//         "ui:options": {
//           ...obj.protocol.study_identification.official_title["ui:options"],
//           title: "Official Title: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       secondary_ids: {
//         ...obj.protocol.study_identification.secondary_ids,
//         "ui:options": {
//           ...obj.protocol.study_identification.secondary_ids["ui:options"],
//           showIndex: true,
//           arrayItemTitle: "Identifier",
//           styles: {
//             arrayTitle
//           }
//         },
//         items: {
//           ...obj.protocol.study_identification.secondary_ids.items,
//           "ui:options": {
//             ...obj.protocol.study_identification.secondary_ids.items["ui:options"],
//             styles: {
//               objectInnerContainer: {
//                 display: "flex",
//                 flexDirection: "column"
//               },
//               arrayItemContainer: {
//                 marginBottom: 6
//               }
//             }
//           },
//           secondary_id_type: {
//             ...obj.protocol.study_identification.secondary_ids.items.secondary_id_type,
//             "ui:options": { 
//               ...obj.protocol.study_identification.secondary_ids.items.secondary_id_type["ui:options"],
//               title: "        Identifier Type: ",
//               styles: {
//                 ...previewStyles.flexRow
//               }
//             }
//           },
//           id: {
//             ...obj.protocol.study_identification.secondary_ids.items.id,
//             "ui:options": { 
//               ...obj.protocol.study_identification.secondary_ids.items.id["ui:options"],
//               title: "        Identifier: ",
//               styles: {
//                 ...previewStyles.flexRow
//               }
//             }
//           },
//           domain: {
//             ...obj.protocol.study_identification.secondary_ids.items.domain,
//             "ui:options": { 
//               ...obj.protocol.study_identification.secondary_ids.items.domain["ui:options"],
//               title: "data-schema",
//               styles: {
//                 ...previewStyles.flexRow
//               }
//             }
//           },
//         }
//       }
//     }
  
//     obj.protocol.contact_information = {
//       ...obj.protocol.contact_information,
//       "ui:options": {
//         ...obj.protocol.contact_information["ui:options"],
//         styles: {
//           ...previewStyles.sectionHeader
//         },
//       },
//       responsible_party: {
//         ...obj.protocol.contact_information.responsible_party,
//         "ui:options": {
//           ...obj.protocol.contact_information.responsible_party["ui:options"],
//           title: "Responsible Party: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       sponsor: {
//         ...obj.protocol.contact_information.sponsor,
//         "ui:options": {
//           ...obj.protocol.contact_information.sponsor["ui:options"],
//           title: "Sponsor: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       investigator_name: {
//         ...obj.protocol.contact_information.investigator_name,
//         "ui:options": {
//           ...obj.protocol.contact_information.investigator_name["ui:options"],
//           title: "Investigator Name: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       investigator_official_title: {
//         ...obj.protocol.contact_information.investigator_official_title,
//         "ui:options": {
//           ...obj.protocol.contact_information.investigator_official_title["ui:options"],
//           title: "Investigator Official Title: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       investigator_affiliation: {
//         ...obj.protocol.contact_information.investigator_affiliation,
//         "ui:options": {
//           ...obj.protocol.contact_information.investigator_affiliation["ui:options"],
//           title: "Investigator Affiliation: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       collaborators: {
//         ...obj.protocol.contact_information.collaborators,
//         "ui:options": {
//           ...obj.protocol.contact_information.collaborators["ui:options"],
//           showIndex: true,
//           // arrayItemTitle: "Collaborator",
//           styles: {
//             arrayTitle,
//             ...previewStyles.singleArrayItem
//           }
//         },
//         items: {
//           ...obj.protocol.contact_information.collaborators.items,
//           "ui:options": {
//             ...obj.protocol.contact_information.collaborators.items["ui:options"],
//             styles: {
//               objectInnerContainer: {
//                 display: "flex",
//                 flexDirection: "column"
//               },
//               arrayItemContainer: {
//                 marginBottom: 6
//               }
//             }
//           },
//           collaborator: {
//             ...obj.protocol.contact_information.collaborators.items.collaborator,
//             "ui:options": { 
//               ...obj.protocol.contact_information.collaborators.items.collaborator["ui:options"],
//               title: "        ",
//               styles: {
//                 ...previewStyles.flexRow
//               }
//             }
//           },
//         }
//       },
//       central_contacts: {
//         ...obj.protocol.contact_information.central_contacts,
//         central_contacts_first_name: {
//           ...obj.protocol.contact_information.central_contacts.central_contacts_first_name,
//           "ui:options": {
//             ...obj.protocol.contact_information.central_contacts.central_contacts_first_name["ui:options"],
//             styles: {
//               ...obj.protocol.contact_information.central_contacts.central_contacts_first_name["ui:options"].styles,
//               ...previewStyles.boldLabel,

//             }
//           }
//         },
//         central_contacts_middle_name: {
//           ...obj.protocol.contact_information.central_contacts.central_contacts_middle_name,
//           "ui:options": {
//             ...obj.protocol.contact_information.central_contacts.central_contacts_middle_name["ui:options"],
//             styles: {
//               ...obj.protocol.contact_information.central_contacts.central_contacts_middle_name["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         },  
//         central_contacts_last_name: {
//           ...obj.protocol.contact_information.central_contacts.central_contacts_last_name,
//           "ui:options": {
//             ...obj.protocol.contact_information.central_contacts.central_contacts_last_name["ui:options"],
//             styles: {
//               ...obj.protocol.contact_information.central_contacts.central_contacts_last_name["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         },
//         central_contacts_degree: {
//           ...obj.protocol.contact_information.central_contacts.central_contacts_degree,
//           "ui:options": {
//             ...obj.protocol.contact_information.central_contacts.central_contacts_degree["ui:options"],
//             styles: {
//               ...obj.protocol.contact_information.central_contacts.central_contacts_degree["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         },
//         central_contacts_phone_number: {
//           ...obj.protocol.contact_information.central_contacts.central_contacts_phone_number,
//           "ui:options": {
//             ...obj.protocol.contact_information.central_contacts.central_contacts_phone_number["ui:options"],
//             styles: {
//               ...obj.protocol.contact_information.central_contacts.central_contacts_phone_number["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         },
//         central_contacts_ext: {
//           ...obj.protocol.contact_information.central_contacts.central_contacts_ext,
//           "ui:options": {
//             ...obj.protocol.contact_information.central_contacts.central_contacts_ext["ui:options"],
//             styles: {
//               ...obj.protocol.contact_information.central_contacts.central_contacts_ext["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         },
//         central_contacts_email: {
//           ...obj.protocol.contact_information.central_contacts.central_contacts_email,
//           "ui:options": {
//             ...obj.protocol.contact_information.central_contacts.central_contacts_email["ui:options"],
//             styles: {
//               ...obj.protocol.contact_information.central_contacts.central_contacts_email["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         },
//       },
//       central_contacts_backup: {
//         ...obj.protocol.contact_information.central_contacts_backup,
//         central_contacts_backup_first_name: {
//           ...obj.protocol.contact_information.central_contacts_backup.central_contacts_backup_first_name,
//           "ui:options": {
//             ...obj.protocol.contact_information.central_contacts_backup.central_contacts_backup_first_name["ui:options"],
//             styles: {
//               ...obj.protocol.contact_information.central_contacts_backup.central_contacts_backup_first_name["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         },
//         central_contacts_backup_middle_name: {
//           ...obj.protocol.contact_information.central_contacts_backup.central_contacts_backup_middle_name,
//           "ui:options": {
//             ...obj.protocol.contact_information.central_contacts_backup.central_contacts_backup_middle_name["ui:options"],
//             styles: {
//               ...obj.protocol.contact_information.central_contacts_backup.central_contacts_backup_middle_name["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         },  
//         central_contacts_backup_last_name: {
//           ...obj.protocol.contact_information.central_contacts_backup.central_contacts_backup_last_name,
//           "ui:options": {
//             ...obj.protocol.contact_information.central_contacts_backup.central_contacts_backup_last_name["ui:options"],
//             styles: {
//               ...obj.protocol.contact_information.central_contacts_backup.central_contacts_backup_last_name["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         },
//         central_contacts_backup_degree: {
//           ...obj.protocol.contact_information.central_contacts_backup.central_contacts_backup_degree,
//           "ui:options": {
//             ...obj.protocol.contact_information.central_contacts_backup.central_contacts_backup_degree["ui:options"],
//             styles: {
//               ...obj.protocol.contact_information.central_contacts_backup.central_contacts_backup_degree["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         },
//         central_contacts_backup_phone_number: {
//           ...obj.protocol.contact_information.central_contacts_backup.central_contacts_backup_phone_number,
//           "ui:options": {
//             ...obj.protocol.contact_information.central_contacts_backup.central_contacts_backup_phone_number["ui:options"],
//             styles: {
//               ...obj.protocol.contact_information.central_contacts_backup.central_contacts_backup_phone_number["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         },
//         central_contacts_backup_ext: {
//           ...obj.protocol.contact_information.central_contacts_backup.central_contacts_backup_ext,
//           "ui:options": {
//             ...obj.protocol.contact_information.central_contacts_backup.central_contacts_backup_ext["ui:options"],
//             styles: {
//               ...obj.protocol.contact_information.central_contacts_backup.central_contacts_backup_ext["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         },
//         central_contacts_backup_email: {
//           ...obj.protocol.contact_information.central_contacts_backup.central_contacts_backup_email,
//           "ui:options": {
//             ...obj.protocol.contact_information.central_contacts_backup.central_contacts_backup_email["ui:options"],
//             styles: {
//               ...obj.protocol.contact_information.central_contacts_backup.central_contacts_backup_email["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         },
//       },
//       overall_study_officials: {
//         ...obj.protocol.contact_information.overall_study_officials,
//         "ui:options": {
//           ...obj.protocol.contact_information.overall_study_officials["ui:options"],
//           showIndex: true,
//           styles: {
//             ...obj.protocol.contact_information.overall_study_officials["ui:options"].styles,
//             arrayTitle
//           }
//         },
//         items: {
//           ...obj.protocol.contact_information.overall_study_officials.items,
//           overall_study_officials_first_name: {
//             ...obj.protocol.contact_information.overall_study_officials.items.overall_study_officials_first_name,
//             "ui:options": {
//               ...obj.protocol.contact_information.overall_study_officials.items.overall_study_officials_first_name["ui:options"],
//               styles: {
//                 ...obj.protocol.contact_information.overall_study_officials.items.overall_study_officials_first_name["ui:options"].styles,
//                 ...previewStyles.boldLabel
//               }
//             }
//           },
//           overall_study_officials_middle_name: {
//             ...obj.protocol.contact_information.overall_study_officials.items.overall_study_officials_middle_name,
//             "ui:options": {
//               ...obj.protocol.contact_information.overall_study_officials.items.overall_study_officials_middle_name["ui:options"],
//               styles: {
//                 ...obj.protocol.contact_information.overall_study_officials.items.overall_study_officials_middle_name["ui:options"].styles,
//                 ...previewStyles.boldLabel
//               }
//             }
//           },
//           overall_study_officials_last_name: {
//             ...obj.protocol.contact_information.overall_study_officials.items.overall_study_officials_last_name,
//             "ui:options": {
//               ...obj.protocol.contact_information.overall_study_officials.items.overall_study_officials_last_name["ui:options"],
//               styles: {
//                 ...obj.protocol.contact_information.overall_study_officials.items.overall_study_officials_last_name["ui:options"].styles,
//                 ...previewStyles.boldLabel
//               }
//             }
//           },
//           overall_study_officials_degree: {
//             ...obj.protocol.contact_information.overall_study_officials.items.overall_study_officials_degree,
//             "ui:options": {
//               ...obj.protocol.contact_information.overall_study_officials.items.overall_study_officials_degree["ui:options"],
//               styles: {
//                 ...obj.protocol.contact_information.overall_study_officials.items.overall_study_officials_degree["ui:options"].styles,
//                 ...previewStyles.boldLabel
//               }
//             }
//           },
//           overall_study_officials_organization_affiliation: {
//             ...obj.protocol.contact_information.overall_study_officials.items.overall_study_officials_organization_affiliation,
//             "ui:options": {
//               ...obj.protocol.contact_information.overall_study_officials.items.overall_study_officials_organization_affiliation["ui:options"],
//               styles: {
//                 ...obj.protocol.contact_information.overall_study_officials.items.overall_study_officials_organization_affiliation["ui:options"].styles,
//                 ...previewStyles.boldLabel
//               }
//             }
//           },
//           official_role: {
//             ...obj.protocol.contact_information.overall_study_officials.items.official_role,
//             "ui:options": {
//               ...obj.protocol.contact_information.overall_study_officials.items.official_role["ui:options"],
//               styles: {
//                 ...obj.protocol.contact_information.overall_study_officials.items.official_role["ui:options"].styles,
//                 ...previewStyles.boldLabel,
//                 widgetContainer: {
//                   marginBottom: 16
//                 }
//               }
//             }
//           },
//         }
//       }
//     }
  
//     obj.protocol.study_oversight = {
//       ...obj.protocol.study_oversight,
//       "ui:options": {
//         ...obj.protocol.study_oversight["ui:options"],
//         styles: {
//           ...previewStyles.sectionHeader
//         },
//       },
//       us_drug: {
//         ...obj.protocol.study_oversight.us_drug,
//         us_fda_drug: {
//           ...obj.protocol.study_oversight.us_drug.us_fda_drug,
//           "ui:options": {
//             ...obj.protocol.study_oversight.us_drug.us_fda_drug["ui:options"],
//             styles: {
//               ...obj.protocol.study_oversight.us_drug.us_fda_drug["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         },
//         productexported_from_us: {
//           ...obj.protocol.study_oversight.us_drug.productexported_from_us,
//           "ui:options": {
//             ...obj.protocol.study_oversight.us_drug.productexported_from_us["ui:options"],
//             styles: {
//               ...obj.protocol.study_oversight.us_drug.productexported_from_us["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         },
//       },
//       us_device: {
//         ...obj.protocol.study_oversight.us_device,
//         us_fda_device: {
//           ...obj.protocol.study_oversight.us_device.us_fda_device,
//           "ui:options": {
//             ...obj.protocol.study_oversight.us_device.us_fda_device["ui:options"],
//             styles: {
//               ...obj.protocol.study_oversight.us_device.us_fda_device["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         },
//         unapproved_uncleared_device: {
//           ...obj.protocol.study_oversight.us_device.unapproved_uncleared_device,
//           device: {
//             ...obj.protocol.study_oversight.us_device.unapproved_uncleared_device.device,
//             "ui:options": {
//               ...obj.protocol.study_oversight.us_device.unapproved_uncleared_device.device["ui:options"],
//               styles: {
//                 ...obj.protocol.study_oversight.us_device.unapproved_uncleared_device.device["ui:options"].styles,
//                 ...previewStyles.boldLabel
//               }
//             }
//           },
//         },
//         post_prior_to_approval_clearance: {
//           ...obj.protocol.study_oversight.us_device.post_prior_to_approval_clearance,
//           "ui:options": {
//             ...obj.protocol.study_oversight.us_device.post_prior_to_approval_clearance["ui:options"],
//             styles: {
//               ...obj.protocol.study_oversight.us_device.post_prior_to_approval_clearance["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         },
//         pediatric_postmarket_surveillance: {
//           ...obj.protocol.study_oversight.us_device.pediatric_postmarket_surveillance,
//           "ui:options": {
//             ...obj.protocol.study_oversight.us_device.pediatric_postmarket_surveillance["ui:options"],
//             styles: {
//               ...obj.protocol.study_oversight.us_device.pediatric_postmarket_surveillance["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         },
//       },
//       us_ind: {
//         ...obj.protocol.study_oversight.us_ind,
//         us_fda_ind_ide: {
//           ...obj.protocol.study_oversight.us_ind.us_fda_ind_ide,
//           "ui:options": {
//             ...obj.protocol.study_oversight.us_ind.us_fda_ind_ide["ui:options"],
//             styles: {
//               ...obj.protocol.study_oversight.us_ind.us_fda_ind_ide["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         },
//         fda_center: {
//           ...obj.protocol.study_oversight.us_ind.fda_center,
//           "ui:options": {
//             ...obj.protocol.study_oversight.us_ind.fda_center["ui:options"],
//             styles: {
//               ...obj.protocol.study_oversight.us_ind.fda_center["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         },
//         ind_ide_number: {
//           ...obj.protocol.study_oversight.us_ind.ind_ide_number,
//           "ui:options": {
//             ...obj.protocol.study_oversight.us_ind.ind_ide_number["ui:options"],
//             styles: {
//               ...obj.protocol.study_oversight.us_ind.ind_ide_number["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         },
//         ind_serial_number: {
//           ...obj.protocol.study_oversight.us_ind.ind_serial_number,
//           "ui:options": {
//             ...obj.protocol.study_oversight.us_ind.ind_serial_number["ui:options"],
//             styles: {
//               ...obj.protocol.study_oversight.us_ind.ind_serial_number["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         },
//         availability_of_expanded_access: {
//           ...obj.protocol.study_oversight.us_ind.availability_of_expanded_access,
//           "ui:options": {
//             ...obj.protocol.study_oversight.us_ind.availability_of_expanded_access["ui:options"],
//             styles: {
//               ...obj.protocol.study_oversight.us_ind.availability_of_expanded_access["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         },
//         expanded_access_record: {
//           ...obj.protocol.study_oversight.us_ind.expanded_access_record,
//           "ui:options": {
//             ...obj.protocol.study_oversight.us_ind.expanded_access_record["ui:options"],
//             styles: {
//               ...obj.protocol.study_oversight.us_ind.expanded_access_record["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         },
//       },
//       human_subjects_protection_review: {
//         ...obj.protocol.study_oversight.human_subjects_protection_review,
//         board_status: {
//           ...obj.protocol.study_oversight.human_subjects_protection_review.board_status,
//           "ui:options": {
//             ...obj.protocol.study_oversight.human_subjects_protection_review.board_status["ui:options"],
//             styles: {
//               ...obj.protocol.study_oversight.human_subjects_protection_review.board_status["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         },
//         approvalnumber: {
//           ...obj.protocol.study_oversight.human_subjects_protection_review.approvalnumber,
//           "ui:options": {
//             ...obj.protocol.study_oversight.human_subjects_protection_review.approvalnumber["ui:options"],
//             styles: {
//               ...obj.protocol.study_oversight.human_subjects_protection_review.approvalnumber["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         },
//         board_name: {
//           ...obj.protocol.study_oversight.human_subjects_protection_review.board_name,
//           "ui:options": {
//             ...obj.protocol.study_oversight.human_subjects_protection_review.board_name["ui:options"],
//             styles: {
//               ...obj.protocol.study_oversight.human_subjects_protection_review.board_name["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         },
//         board_affiliation: {
//           ...obj.protocol.study_oversight.human_subjects_protection_review.board_affiliation,
//           "ui:options": {
//             ...obj.protocol.study_oversight.human_subjects_protection_review.board_affiliation["ui:options"],
//             styles: {
//               ...obj.protocol.study_oversight.human_subjects_protection_review.board_affiliation["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         },
//         board_contact: {
//           ...obj.protocol.study_oversight.human_subjects_protection_review.board_contact,
//           phone: {
//             ...obj.protocol.study_oversight.human_subjects_protection_review.board_contact.phone,
//             "ui:options": {
//               ...obj.protocol.study_oversight.human_subjects_protection_review.board_contact.phone["ui:options"],
//               styles: {
//                 ...obj.protocol.study_oversight.human_subjects_protection_review.board_contact.phone["ui:options"].styles,
//                 ...previewStyles.boldLabel
//               }
//             }
//           },
//           extension: {
//             ...obj.protocol.study_oversight.human_subjects_protection_review.board_contact.extension,
//             "ui:options": {
//               ...obj.protocol.study_oversight.human_subjects_protection_review.board_contact.extension["ui:options"],
//               styles: {
//                 ...obj.protocol.study_oversight.human_subjects_protection_review.board_contact.extension["ui:options"].styles,
//                 ...previewStyles.boldLabel
//               }
//             }
//           },
//           email: {
//             ...obj.protocol.study_oversight.human_subjects_protection_review.board_contact.email,
//             "ui:options": {
//               ...obj.protocol.study_oversight.human_subjects_protection_review.board_contact.email["ui:options"],
//               styles: {
//                 ...obj.protocol.study_oversight.human_subjects_protection_review.board_contact.email["ui:options"].styles,
//                 ...previewStyles.boldLabel
//               }
//             }
//           },
//           address: {
//             ...obj.protocol.study_oversight.human_subjects_protection_review.board_contact.address,
//             "ui:options": {
//               ...obj.protocol.study_oversight.human_subjects_protection_review.board_contact.address["ui:options"],
//               styles: {
//                 ...obj.protocol.study_oversight.human_subjects_protection_review.board_contact.address["ui:options"].styles,
//                 ...previewStyles.boldLabel
//               }
//             }
//           }
//         }
//       },
//       data: {
//         ...obj.protocol.study_oversight.data,
//         data_monitoring_committee: {
//           ...obj.protocol.study_oversight.data.data_monitoring_committee,
//           "ui:options": {
//             ...obj.protocol.study_oversight.data.data_monitoring_committee["ui:options"],
//             styles: {
//               ...obj.protocol.study_oversight.data.data_monitoring_committee["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         },
//       },
//       fda: {
//         ...obj.protocol.study_oversight.fda,
//         fda_regulated_intervention: {
//           ...obj.protocol.study_oversight.fda.fda_regulated_intervention,
//           "ui:options": {
//             ...obj.protocol.study_oversight.fda.fda_regulated_intervention["ui:options"],
//             styles: {
//               ...obj.protocol.study_oversight.fda.fda_regulated_intervention["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         },
//         section_801_clinical_trial: {
//           ...obj.protocol.study_oversight.fda.section_801_clinical_trial,
//           "ui:options": {
//             ...obj.protocol.study_oversight.fda.section_801_clinical_trial["ui:options"],
//             styles: {
//               ...obj.protocol.study_oversight.fda.section_801_clinical_trial["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         },
//       }
//     }
  
//     obj.protocol.study_timeline = {
//       ...obj.protocol.study_timeline,
//       "ui:options": {
//         ...obj.protocol.study_timeline["ui:options"],
//         styles: {
//           ...previewStyles.sectionHeader
//         },
//       },
//       record_verification_date: {
//         ...obj.protocol.study_timeline.record_verification_date,
//         "ui:options": {
//           ...obj.protocol.study_timeline.record_verification_date["ui:options"],
//           styles: {
//             ...obj.protocol.study_timeline.record_verification_date["ui:options"].styles,
//             objectTitle: {
//               fontWeight: 600
//             }
//           }
//         },
//         select_month: {
//           ...obj.protocol.study_timeline.record_verification_date.select_month,
//           "ui:options": {
//             ...obj.protocol.study_timeline.record_verification_date.select_month["ui:options"],
//             styles: {
//               ...obj.protocol.study_timeline.record_verification_date.select_month["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         },
//         year: {
//           ...obj.protocol.study_timeline.record_verification_date.year,
//           "ui:options": {
//             ...obj.protocol.study_timeline.record_verification_date.year["ui:options"],
//             styles: {
//               ...obj.protocol.study_timeline.record_verification_date.year["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         }
//       },
//       recruitment: {
//         ...obj.protocol.study_timeline.recruitment,
//         recruitment_status: {
//           ...obj.protocol.study_timeline.recruitment.recruitment_status,
//           "ui:options": {
//             ...obj.protocol.study_timeline.recruitment.recruitment_status["ui:options"],
//             styles: {
//               ...obj.protocol.study_timeline.recruitment.recruitment_status["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         },
//         study_stopped: {
//           ...obj.protocol.study_timeline.recruitment.study_stopped,
//           "ui:options": {
//             ...obj.protocol.study_timeline.recruitment.study_stopped["ui:options"],
//             styles: {
//               ...obj.protocol.study_timeline.recruitment.study_stopped["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         }
//       },
//       milestone_dates: {
//         ...obj.protocol.study_timeline.milestone_dates,
//         "ui:options": {
//           ...obj.protocol.study_timeline.milestone_dates["ui:options"],
//           styles: {
//             ...obj.protocol.study_timeline.milestone_dates["ui:options"].styles,
//             objectTitle: arrayTitle
//           }
//         },
//         study_start_date: {
//           ...obj.protocol.study_timeline.milestone_dates.study_start_date,
//           "ui:options": {
//             ...obj.protocol.study_timeline.milestone_dates.study_start_date["ui:options"],
//             styles: {
//               ...obj.protocol.study_timeline.milestone_dates.study_start_date["ui:options"].styles,
//               objectContainer: { marginBottom: 8 }
//             }
//           },
//           date: {
//             ...obj.protocol.study_timeline.milestone_dates.study_start_date.date,
//             "ui:options": {
//               ...obj.protocol.study_timeline.milestone_dates.study_start_date.date["ui:options"],
//               styles: {
//                 ...obj.protocol.study_timeline.milestone_dates.study_start_date.date["ui:options"].styles,
//                 ...previewStyles.boldLabel
//               }
//             }
//           },
//           anticipated_actual_date: {
//             ...obj.protocol.study_timeline.milestone_dates.study_start_date.anticipated_actual_date,
//             "ui:options": {
//               ...obj.protocol.study_timeline.milestone_dates.study_start_date.anticipated_actual_date["ui:options"],
//               styles: {
//                 ...obj.protocol.study_timeline.milestone_dates.study_start_date.anticipated_actual_date["ui:options"].styles,
//                 ...previewStyles.boldLabel
//               }
//             }
//           }
//         },
//         primary_completion_date: {
//           ...obj.protocol.study_timeline.milestone_dates.primary_completion_date,
//           "ui:options": {
//             ...obj.protocol.study_timeline.milestone_dates.primary_completion_date["ui:options"],
//             styles: {
//               ...obj.protocol.study_timeline.milestone_dates.primary_completion_date["ui:options"].styles,
//               objectContainer: { marginBottom: 8 }
//             }
//           },
//           date: {
//             ...obj.protocol.study_timeline.milestone_dates.primary_completion_date.date,
//             "ui:options": {
//               ...obj.protocol.study_timeline.milestone_dates.primary_completion_date.date["ui:options"],
//               styles: {
//                 ...obj.protocol.study_timeline.milestone_dates.primary_completion_date.date["ui:options"].styles,
//                 ...previewStyles.boldLabel
//               }
//             }
//           },
//           anticipated_actual_date: {
//             ...obj.protocol.study_timeline.milestone_dates.primary_completion_date.anticipated_actual_date,
//             "ui:options": {
//               ...obj.protocol.study_timeline.milestone_dates.primary_completion_date.anticipated_actual_date["ui:options"],
//               styles: {
//                 ...obj.protocol.study_timeline.milestone_dates.primary_completion_date.anticipated_actual_date["ui:options"].styles,
//                 ...previewStyles.boldLabel
//               }
//             }
//           }
//         },
//         study_completion_date: {
//           ...obj.protocol.study_timeline.milestone_dates.study_completion_date,
//           "ui:options": {
//             ...obj.protocol.study_timeline.milestone_dates.study_completion_date["ui:options"],
//             styles: {
//               ...obj.protocol.study_timeline.milestone_dates.study_completion_date["ui:options"].styles,
//               objectContainer: { marginBottom: 8 }
//             }
//           },
//           date: {
//             ...obj.protocol.study_timeline.milestone_dates.study_completion_date.date,
//             "ui:options": {
//               ...obj.protocol.study_timeline.milestone_dates.study_completion_date.date["ui:options"],
//               styles: {
//                 ...obj.protocol.study_timeline.milestone_dates.study_completion_date.date["ui:options"].styles,
//                 ...previewStyles.boldLabel
//               }
//             }
//           },
//           anticipated_actual_date: {
//             ...obj.protocol.study_timeline.milestone_dates.study_completion_date.anticipated_actual_date,
//             "ui:options": {
//               ...obj.protocol.study_timeline.milestone_dates.study_completion_date.anticipated_actual_date["ui:options"],
//               styles: {
//                 ...obj.protocol.study_timeline.milestone_dates.study_completion_date.anticipated_actual_date["ui:options"].styles,
//                 ...previewStyles.boldLabel
//               }
//             }
//           }
//         }
//       },
//       expanded: {
//         ...obj.protocol.study_timeline.expanded,
//         expanded_status: {
//           ...obj.protocol.study_timeline.expanded.expanded_status,
//           "ui:options": {
//             ...obj.protocol.study_timeline.expanded.expanded_status["ui:options"],
//             styles: {
//               ...obj.protocol.study_timeline.expanded.expanded_status["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         },
//       }
//     }
  
//     obj.protocol.study_description = {
//       ...obj.protocol.study_description,
//       "ui:options": {
//         ...obj.protocol.study_description["ui:options"],
//         styles: {
//           ...obj.protocol.study_description["ui:options"].styles,
//           objectContainer: {},
//           ...previewStyles.sectionHeader
//         },
//       },
//       brief_summary: {
//         ...obj.protocol.study_description.brief_summary,
//         "ui:options": {
//           ...obj.protocol.study_description.brief_summary["ui:options"],
//           title: "Brief Summary: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       detailed_description: {
//         ...obj.protocol.study_description.detailed_description,
//         "ui:options": {
//           ...obj.protocol.study_description.detailed_description["ui:options"],
//           title: "Detailed Description: ",
//           styles: {
//             ...previewStyles.flexRow,
//             field: {
//               marginBottom: 24
//             }
//           }
//         }
//       },
//       study_conditions: {
//         ...obj.protocol.study_description.study_conditions,
//         "ui:options": {
//           ...obj.protocol.study_description.study_conditions["ui:options"],
//           showIndex: true,
//           styles: {
//             ...obj.protocol.study_description.study_conditions["ui:options"].styles,
//             arrayTitle: {
//               ...obj.protocol.study_description.study_conditions["ui:options"].styles.arrayTitle,
//               fontWeight: "600"
//             },
//             ...previewStyles.singleArrayItem
//           }
//         },
//         items: {
//           ...obj.protocol.study_description.study_conditions.items,
//           condition: {
//             ...obj.protocol.study_description.study_conditions.items.condition,
//             "ui:options": {
//               ...obj.protocol.study_description.study_conditions.items.condition["ui:options"],
//               styles: {
//                 ...obj.protocol.study_description.study_conditions.items.condition["ui:options"].styles,
//                 widgetContainer: {
//                   ...obj.protocol.study_description.study_conditions.items.condition["ui:options"].styles,
//                   marginLeft:16
//                 },
//               }
//             }
//           }
//         }
//       },
//       study_keywords: {
//         ...obj.protocol.study_description.study_keywords,
//         "ui:options": {
//           ...obj.protocol.study_description.study_keywords["ui:options"],
//           showIndex: true,
//           styles: {
//             ...obj.protocol.study_description.study_keywords["ui:options"].styles,
//             arrayTitle: {
//               ...obj.protocol.study_description.study_keywords["ui:options"].styles.arrayTitle,
//               fontWeight: "600"
//             },
//             ...previewStyles.singleArrayItem
//           }
//         },
//         items: {
//           ...obj.protocol.study_description.study_keywords.items,
//           keyword: {
//             ...obj.protocol.study_description.study_keywords.items.keyword,
//             "ui:options": {
//               ...obj.protocol.study_description.study_keywords.items.keyword["ui:options"],
//               styles: {
//                 ...obj.protocol.study_description.study_keywords.items.keyword["ui:options"].styles,
//                 widgetContainer: {
//                   ...obj.protocol.study_description.study_keywords.items.keyword["ui:options"].styles,
//                   marginLeft: 16
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
  
//     obj.protocol.study_scope = {
//       ...obj.protocol.study_scope,
//       "ui:options": {
//         ...obj.protocol.study_scope["ui:options"],
//         styles: {
//           ...previewStyles.sectionHeader
//         },
//       },
//       patient_registry: {
//         ...obj.protocol.study_scope.patient_registry,
//         "ui:options": {
//           ...obj.protocol.study_scope.patient_registry["ui:options"],
//           title: "Patient Registry: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       primary_purpose: {
//         ...obj.protocol.study_scope.primary_purpose,
//         "ui:options": {
//           ...obj.protocol.study_scope.primary_purpose["ui:options"],
//           title: "Primary Purpose: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       study_phase: {
//         ...obj.protocol.study_scope.study_phase,
//         "ui:options": {
//           ...obj.protocol.study_scope.study_phase["ui:options"],
//           title: "Study Phase: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       interventional_study_model: {
//         ...obj.protocol.study_scope.interventional_study_model,
//         "ui:options": {
//           ...obj.protocol.study_scope.interventional_study_model["ui:options"],
//           title: "Interventional Study Model: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       model_description: {
//         ...obj.protocol.study_scope.model_description,
//         "ui:options": {
//           ...obj.protocol.study_scope.model_description["ui:options"],
//           title: "Model Description: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       number_of_arms: {
//         ...obj.protocol.study_scope.number_of_arms,
//         "ui:options": {
//           ...obj.protocol.study_scope.number_of_arms["ui:options"],
//           title: "Number of Arms: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       masking: {
//         ...obj.protocol.study_scope.masking,
//         "ui:options": {
//           ...obj.protocol.study_scope.masking["ui:options"],
//           title: "Masking: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       masking_description: {
//         ...obj.protocol.study_scope.masking_description,
//         "ui:options": {
//           ...obj.protocol.study_scope.masking_description["ui:options"],
//           title: "Masking Description: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       allocation: {
//         ...obj.protocol.study_scope.allocation,
//         "ui:options": {
//           ...obj.protocol.study_scope.allocation["ui:options"],
//           title: "Allocation: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       enrollment_number: {
//         ...obj.protocol.study_scope.enrollment_number,
//         "ui:options": {
//           ...obj.protocol.study_scope.enrollment_number["ui:options"],
//           title: "Enrollment Number: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       enrollment_type: {
//         ...obj.protocol.study_scope.enrollment_type,
//         "ui:options": {
//           ...obj.protocol.study_scope.enrollment_type["ui:options"],
//           title: "Enrollment Type: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       enrollment_type_obs: {
//         ...obj.protocol.study_scope.enrollment_type_obs,
//         "ui:options": {
//           ...obj.protocol.study_scope.enrollment_type_obs["ui:options"],
//           title: "Enrollment Type: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       follow_up_duration: {
//         ...obj.protocol.study_scope.follow_up_duration,
//         "ui:options": {
//           ...obj.protocol.study_scope.follow_up_duration["ui:options"],
//           title: "Target Follow Up Duration: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       duration_type: {
//         ...obj.protocol.study_scope.duration_type,
//         "ui:options": {
//           ...obj.protocol.study_scope.duration_type["ui:options"],
//           title: "Duration: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       additional_patient_registry_data: {
//         ...obj.protocol.study_scope.additional_patient_registry_data,
//         "ui:options": {
//           ...obj.protocol.study_scope.additional_patient_registry_data["ui:options"],
//           title: "Additional Patient Registry Data: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       observational_study_model: {
//         ...obj.protocol.study_scope.observational_study_model,
//         "ui:options": {
//           ...obj.protocol.study_scope.observational_study_model["ui:options"],
//           title: "Observational Study Model: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       time_perspective: {
//         ...obj.protocol.study_scope.time_perspective,
//         "ui:options": {
//           ...obj.protocol.study_scope.time_perspective["ui:options"],
//           title: "Time Perspective: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       biospecimen: {
//         ...obj.protocol.study_scope.biospecimen,
//         biospeciment_retention: {
//           ...obj.protocol.study_scope.biospecimen.biospeciment_retention,
//           "ui:options": {
//             ...obj.protocol.study_scope.biospecimen.biospeciment_retention["ui:options"],
//             title: "Biospecimen Retention: ",
//             styles: {
//               ...previewStyles.flexRow
//             }
//           }
//         },
//         biospecimen_description: {
//           ...obj.protocol.study_scope.biospecimen.biospecimen_description,
//           "ui:options": {
//             ...obj.protocol.study_scope.biospecimen.biospecimen_description["ui:options"],
//             title: "Biospecimen Description: ",
//             styles: {
//               ...previewStyles.flexRow
//             }
//           }
//         }
//       },
//       // number_of_subjects: {
//       //   ...obj.protocol.study_scope.number_of_subjects,
//       //   "ui:options": {
//       //     ...obj.protocol.study_scope.number_of_subjects["ui:options"],
//       //     title: "Number of Participants: ",
//       //     styles: {
//       //       ...previewStyles.flexRow
//       //     }
//       //   }
//       // },
//       number_of_cohorts: {
//         ...obj.protocol.study_scope.number_of_cohorts,
//         "ui:options": {
//           ...obj.protocol.study_scope.number_of_cohorts["ui:options"],
//           title: "Number of Cohorts in the Study: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       selection: {
//         ...obj.protocol.study_scope.selection,
//         "ui:options": {
//           ...obj.protocol.study_scope.selection["ui:options"],
//           title: "Expanded Access Type: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//     }
  
//     // TODO: ARMS
//     obj.protocol.study_arms = {
//       ...obj.protocol.study_arms,
//       "ui:options": {
//         ...obj.protocol.study_arms["ui:options"],
//         styles: {
//           ...previewStyles.sectionHeader,
//           objectInnerContainer: {
//             display: "flex",
//             flexWrap: "wrap",
//           }
//         },
//       },
//       arms: {
//         ...obj.protocol.study_arms.arms,
//         "ui:options": {
//           ...obj.protocol.study_arms.arms["ui:options"],
//           styles: {
//             ...obj.protocol.study_arms.arms["ui:options"].styles,
//             field: {
//               width: "100%"
//             }
//           }
//         }
//       },
//       interventions: {
//         "ui:options": {
//           label: false,
//           hide: true
//         }
//       },
//       crossref: {
//         "ui:options": {
//           label: false,
//           hide: true
//         }
//       }
//     }
  
//     obj.protocol.study_eligibilty = {
//       ...obj.protocol.study_eligibilty,
//       "ui:options": {
//         ...obj.protocol.study_eligibilty["ui:options"],
//         styles: {
//           ...previewStyles.sectionHeader
//         },
//       },
//       sex: {
//         ...obj.protocol.study_eligibilty.sex,
//         "ui:options": {
//           ...obj.protocol.study_eligibilty.sex["ui:options"],
//           title: "Sex: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       gender_based: {
//         ...obj.protocol.study_eligibilty.gender_based,
//         "ui:options": {
//           ...obj.protocol.study_eligibilty.gender_based["ui:options"],
//           styles: {
//             ...obj.protocol.study_eligibilty.gender_based["ui:options"].styles,
//             objectTitle: {
//               fontWeight: 600
//             }
//           }
//         },
//         gender_based_1: {
//           ...obj.protocol.study_eligibilty.gender_based.gender_based_1,
//           "ui:options": {
//             ...obj.protocol.study_eligibilty.gender_based.gender_based_1["ui:options"],
//             title: "Gender Based: ",
//             styles: {
//               ...obj.protocol.study_eligibilty.gender_based.gender_based_1["ui:options"].styles,
//               ...previewStyles.flexRow
//             }
//           }
//         },
//         gender_eligibility_description: {
//           ...obj.protocol.study_eligibilty.gender_based.gender_eligibility_description,
//           "ui:options": {
//             ...obj.protocol.study_eligibilty.gender_based.gender_eligibility_description["ui:options"],
//             title: "Gender Eligibility Description: ",
//             styles: {
//               ...obj.protocol.study_eligibilty.gender_based.gender_eligibility_description["ui:options"].styles,
//               ...previewStyles.flexRow
//             }
//           }
//         }
//       },
//       age_limits: {
//         ...obj.protocol.study_eligibilty.age_limits,
//         "ui:options": {
//           ...obj.protocol.study_eligibilty.age_limits["ui:options"],
//           styles: {
//             ...obj.protocol.study_eligibilty.age_limits["ui:options"].styles,
//             objectTitle: {
//               fontWeight: 600,
//             }
//           }
//         },
//         minimum: {
//           ...obj.protocol.study_eligibilty.age_limits.minimum,
//           minimum_age_type: {
//             ...obj.protocol.study_eligibilty.age_limits.minimum.minimum_age_type,
//             "ui:options": {
//               ...obj.protocol.study_eligibilty.age_limits.minimum.minimum_age_type["ui:options"],
//               title: "Minimum Age Type: ",
//               styles: {
//                 ...obj.protocol.study_eligibilty.age_limits.minimum.minimum_age_type["ui:options"].styles,
//                 ...previewStyles.flexRow
//               }
//             }
//           },
//           minimum_age_value: {
//             ...obj.protocol.study_eligibilty.age_limits.minimum.minimum_age_value,
//             "ui:options": {
//               ...obj.protocol.study_eligibilty.age_limits.minimum.minimum_age_value["ui:options"],
//               title: "Minimum Age: ",
//               styles: {
//                 ...obj.protocol.study_eligibilty.age_limits.minimum.minimum_age_value["ui:options"].styles,
//                 ...previewStyles.flexRow
//               }
//             }
//           }
//         },
//         maximum: {
//           ...obj.protocol.study_eligibilty.age_limits.maximum,
//           maximum_age_type: {
//             ...obj.protocol.study_eligibilty.age_limits.maximum.maximum_age_type,
//             "ui:options": {
//               ...obj.protocol.study_eligibilty.age_limits.maximum.maximum_age_type["ui:options"],
//               title: "Maximum Age Type: ",
//               styles: {
//                 ...obj.protocol.study_eligibilty.age_limits.maximum.maximum_age_type["ui:options"].styles,
//                 ...previewStyles.flexRow
//               }
//             }
//           },
//           maximum_age_value: {
//             ...obj.protocol.study_eligibilty.age_limits.maximum.maximum_age_value,
//             "ui:options": {
//               ...obj.protocol.study_eligibilty.age_limits.maximum.maximum_age_value["ui:options"],
//               title: "Maximum Age: ",
//               styles: {
//                 ...obj.protocol.study_eligibilty.age_limits.maximum.maximum_age_value["ui:options"].styles,
//                 ...previewStyles.flexRow
//               }
//             }
//           }
//         },
//       },
//       accept_healthy_volunteers: {
//         ...obj.protocol.study_eligibilty.accept_healthy_volunteers,
//         "ui:options": {
//           ...obj.protocol.study_eligibilty.accept_healthy_volunteers["ui:options"],
//           title: "Accept Healthy Volunteers: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       eligibility_criteria: {
//         ...obj.protocol.study_eligibilty.eligibility_criteria,
//         "ui:options": {
//           ...obj.protocol.study_eligibilty.eligibility_criteria["ui:options"],
//           title: "Eligibility Criteria: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       study_population_description: {
//         ...obj.protocol.study_eligibilty.study_population_description,
//         "ui:options": {
//           ...obj.protocol.study_eligibilty.study_population_description["ui:options"],
//           title: "Study Population Description: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       sampling_method: {
//         ...obj.protocol.study_eligibilty.sampling_method,
//         "ui:options": {
//           ...obj.protocol.study_eligibilty.sampling_method["ui:options"],
//           title: "Sampling Method: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//     }
  
//     obj.protocol.study_ipd = {
//       ...obj.protocol.study_ipd,
//       "ui:options": {
//         ...obj.protocol.study_ipd["ui:options"],
//         styles: {
//           ...previewStyles.sectionHeader
//         },
//       },
//       plan_to_share_IPD: {
//         ...obj.protocol.study_ipd.plan_to_share_IPD,
//         "ui:options": {
//           ...obj.protocol.study_ipd.plan_to_share_IPD["ui:options"],
//           title: "Plan to Share IPD: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       plan_description: {
//         ...obj.protocol.study_ipd.plan_description,
//         "ui:options": {
//           ...obj.protocol.study_ipd.plan_description["ui:options"],
//           title: "Plan Description: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       ipd_sharing_info: {
//         ...obj.protocol.study_ipd.ipd_sharing_info,
//         "ui:options": {
//           ...obj.protocol.study_ipd.ipd_sharing_info["ui:options"],
//           title: "Supporting Information Type: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       time_frame: {
//         ...obj.protocol.study_ipd.time_frame,
//         "ui:options": {
//           ...obj.protocol.study_ipd.time_frame["ui:options"],
//           title: "Time Frame: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       access_criteria: {
//         ...obj.protocol.study_ipd.access_criteria,
//         "ui:options": {
//           ...obj.protocol.study_ipd.access_criteria["ui:options"],
//           title: "Access Criteria: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       url_text: {
//         ...obj.protocol.study_ipd.url_text,
//         "ui:options": {
//           ...obj.protocol.study_ipd.url_text["ui:options"],
//           title: "URL: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//     }
  
//     if(formData?.results?.outcome_measures?.outcome_measures_table) {
//       obj.protocol.study_outcome_measures = {
//         ...obj.protocol.study_outcome_measures,
//         "ui:options": {
//           hide: true
//         },
//       }
//     } else {
//       obj.protocol.study_outcome_measures = {
//         ...obj.protocol.study_outcome_measures,
//         "ui:options": {
//           ...obj.protocol.study_outcome_measures["ui:options"],
//           styles: {
//             ...previewStyles.sectionHeader
//           },
//         },
//         outcome_measures: {
//           ...obj.protocol.study_outcome_measures.outcome_measures,
//           "ui:options": {
//             ...obj.protocol.study_outcome_measures.outcome_measures["ui:options"],
//             styles: {
//               ...obj.protocol.study_outcome_measures.outcome_measures["ui:options"].styles,
//               arrayTitle
//             }
//           },
//           items: {
//             ...obj.protocol.study_outcome_measures.outcome_measures.items,
//             title: {
//               ...obj.protocol.study_outcome_measures.outcome_measures.items.title,
//               "ui:options": {
//                 ...obj.protocol.study_outcome_measures.outcome_measures.items.title["ui:options"],
//                 styles: {
//                   ...obj.protocol.study_outcome_measures.outcome_measures.items.title["ui:options"].styles,
//                   ...previewStyles.boldLabel
//                 }
//               }
//             },
//             time_frame: {
//               ...obj.protocol.study_outcome_measures.outcome_measures.items.time_frame,
//               "ui:options": {
//                 ...obj.protocol.study_outcome_measures.outcome_measures.items.time_frame["ui:options"],
//                 styles: {
//                   ...obj.protocol.study_outcome_measures.outcome_measures.items.time_frame["ui:options"].styles,
//                   ...previewStyles.boldLabel
//                 }
//               }
//             },
//             description: {
//               ...obj.protocol.study_outcome_measures.outcome_measures.items.description,
//               "ui:options": {
//                 ...obj.protocol.study_outcome_measures.outcome_measures.items.description["ui:options"],
//                 styles: {
//                   ...obj.protocol.study_outcome_measures.outcome_measures.items.description["ui:options"].styles,
//                   ...previewStyles.boldLabel
//                 }
//               }
//             },
//             type: {
//               ...obj.protocol.study_outcome_measures.outcome_measures.items.type,
//               "ui:options": {
//                 ...obj.protocol.study_outcome_measures.outcome_measures.items.type["ui:options"],
//                 styles: {
//                   ...obj.protocol.study_outcome_measures.outcome_measures.items.type["ui:options"].styles,
//                   ...previewStyles.boldLabel
//                 }
//               }
//             },
//           }
//         }
//       }
//     }
  
//     obj.protocol.study_reference = {
//       ...obj.protocol.study_reference,
//       "ui:options": {
//         ...obj.protocol.study_reference["ui:options"],
//         styles: {
//           ...previewStyles.sectionHeader
//         },
//       },
//       citations: {
//         ...obj.protocol.study_reference.citations,
//         "ui:options": {
//           ...obj.protocol.study_reference.citations["ui:options"],
//           showIndex: true,
//           styles: {
//             ...obj.protocol.study_reference.citations["ui:options"].styles,
//             arrayTitle
//           }
//         },
//         items: {
//           ...obj.protocol.study_reference.citations.items,
//           pub_med_id: {
//             ...obj.protocol.study_reference.citations.items.pub_med_id,
//             "ui:options": {
//               ...obj.protocol.study_reference.citations.items.pub_med_id["ui:options"],
//               styles: {
//                 ...obj.protocol.study_reference.citations.items.pub_med_id["ui:options"].styles,
//                 ...previewStyles.boldLabel
//               }
//             }
//           },
//           result_reference: {
//             ...obj.protocol.study_reference.citations.items.result_reference,
//             "ui:options": {
//               ...obj.protocol.study_reference.citations.items.result_reference["ui:options"],
//               styles: {
//                 ...obj.protocol.study_reference.citations.items.result_reference["ui:options"].styles,
//                 ...previewStyles.boldLabel
//               }
//             }
//           },
//           citation_text: {
//             ...obj.protocol.study_reference.citations.items.citation_text,
//             "ui:options": {
//               ...obj.protocol.study_reference.citations.items.citation_text["ui:options"],
//               styles: {
//                 ...obj.protocol.study_reference.citations.items.citation_text["ui:options"].styles,
//                 ...previewStyles.boldLabel
//               }
//             }
//           },
//         }
//       },
//       links: {
//         ...obj.protocol.study_reference.links,
//         "ui:options": {
//           ...obj.protocol.study_reference.links["ui:options"],
//           showIndex: true,
//           styles: {
//             ...obj.protocol.study_reference.links["ui:options"].styles,
//             arrayTitle
//           }
//         },
//         items: {
//           ...obj.protocol.study_reference.links.items,
//           url_text: {
//             ...obj.protocol.study_reference.links.items.url_text,
//             "ui:options": {
//               ...obj.protocol.study_reference.links.items.url_text["ui:options"],
//               styles: {
//                 ...obj.protocol.study_reference.links.items.url_text["ui:options"].styles,
//                 ...previewStyles.boldLabel
//               }
//             }
//           },
//           description_text: {
//             ...obj.protocol.study_reference.links.items.description_text,
//             "ui:options": {
//               ...obj.protocol.study_reference.links.items.description_text["ui:options"],
//               styles: {
//                 ...obj.protocol.study_reference.links.items.description_text["ui:options"].styles,
//                 ...previewStyles.boldLabel
//               }
//             }
//           },
//         }
//       },
//       available: {
//         ...obj.protocol.study_reference.available,
//         "ui:options": {
//           ...obj.protocol.study_reference.available["ui:options"],
//           showIndex: true,
//           styles: {
//             ...obj.protocol.study_reference.available["ui:options"].styles,
//             arrayTitle
//           }
//         },
//         items: {
//           ...obj.protocol.study_reference.available.items,
//           data_info: {
//             ...obj.protocol.study_reference.available.items.data_info,
//             "ui:options": {
//               ...obj.protocol.study_reference.available.items.data_info["ui:options"],
//               styles: {
//                 ...obj.protocol.study_reference.available.items.data_info["ui:options"].styles,
//                 ...previewStyles.boldLabel
//               }
//             }
//           },
//           url_text: {
//             ...obj.protocol.study_reference.available.items.url_text,
//             "ui:options": {
//               ...obj.protocol.study_reference.available.items.url_text["ui:options"],
//               styles: {
//                 ...obj.protocol.study_reference.available.items.url_text["ui:options"].styles,
//                 ...previewStyles.boldLabel
//               }
//             }
//           },
//           identifier: {
//             ...obj.protocol.study_reference.available.items.identifier,
//             "ui:options": {
//               ...obj.protocol.study_reference.available.items.identifier["ui:options"],
//               styles: {
//                 ...obj.protocol.study_reference.available.items.identifier["ui:options"].styles,
//                 ...previewStyles.boldLabel
//               }
//             }
//           },
//           comments: {
//             ...obj.protocol.study_reference.available.items.comments,
//             "ui:options": {
//               ...obj.protocol.study_reference.available.items.comments["ui:options"],
//               styles: {
//                 ...obj.protocol.study_reference.available.items.comments["ui:options"].styles,
//                 ...previewStyles.boldLabel
//               }
//             }
//           },
//           description: {
//             ...obj.protocol.study_reference.available.items.description,
//             "ui:options": {
//               ...obj.protocol.study_reference.available.items.description["ui:options"],
//               styles: {
//                 ...obj.protocol.study_reference.available.items.description["ui:options"].styles,
//                 ...previewStyles.boldLabel
//               }
//             }
//           },
//         }
//       }
//     }
//   }

//   if(obj.results) {
//     obj.results.participant_flow = {
//       ...obj.results.participant_flow,
//       "ui:options": {
//         ...obj.results.participant_flow["ui:options"],
//         styles: {
//           ...previewStyles.sectionHeader
//         },
//       },
//       protocol_enrollment: {
//         ...obj.results.participant_flow.protocol_enrollment,
//         "ui:options": {
//           ...obj.results.participant_flow.protocol_enrollment["ui:options"],
//           title: "Protocol Enrollment: ",
//           styles: {
//             ...obj.results.participant_flow.protocol_enrollment["ui:options"].styles,
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       recruitment_details: {
//         ...obj.results.participant_flow.recruitment_details,
//         "ui:options": {
//           ...obj.results.participant_flow.recruitment_details["ui:options"],
//           title: "Recruitment Details: ",
//           styles: {
//             ...obj.results.participant_flow.recruitment_details["ui:options"].styles,
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       pre_assignment_details: {
//         ...obj.results.participant_flow.pre_assignment_details,
//         "ui:options": {
//           ...obj.results.participant_flow.pre_assignment_details["ui:options"],
//           title: "Pre-assignment Details (optional):",
//           styles: {
//             ...obj.results.participant_flow.pre_assignment_details["ui:options"].styles,
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       type_units_analyzed: {
//         ...obj.results.participant_flow.type_units_analyzed,
//         "ui:options": {
//           ...obj.results.participant_flow.type_units_analyzed["ui:options"],
//           title: "Type Units Analyzed: ",
//           styles: {
//             ...obj.results.participant_flow.type_units_analyzed["ui:options"].styles,
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       participant_flow_table: {
//         ...obj.results.participant_flow.participant_flow_table,
//         "ui:options": {
//           ...obj.results.participant_flow.participant_flow_table["ui:options"],
//           styles: {
            
//           }
//         }
//       }
//     }

//     obj.results.baseline_characteristics = {
//       ...obj.results.baseline_characteristics,
//       "ui:options": {
//         ...obj.results.baseline_characteristics["ui:options"],
//         styles: {
//           ...previewStyles.sectionHeader
//         },
//       },
//     }

//     obj.results.outcome_measures = {
//       ...obj.results.outcome_measures,
//       "ui:options": {
//         ...obj.results.outcome_measures["ui:options"],
//         styles: {
//           ...previewStyles.sectionHeader
//         },
//       },
//     }

//     obj.results.adverse_events = {
//       ...obj.results.adverse_events,
//       "ui:options": {
//         ...obj.results.adverse_events["ui:options"],
//         styles: {
//           ...previewStyles.sectionHeader
//         },
//       },
//     }

//     obj.results.more_info = {
//       ...obj.results.more_info,
//       "ui:options": {
//         ...obj.results.more_info["ui:options"],
//         styles: {
//           ...previewStyles.sectionHeader
//         },
//       },
//       certain_agreements: {
//         ...obj.results.more_info.certain_agreements,
//         "ui:options": {
//           ...obj.results.more_info.certain_agreements["ui:options"],
//           styles: { 
//             arrayTitle
//           }
//         },
//         is_pis_employees: {
//           ...obj.results.more_info.certain_agreements.is_pis_employees,
//           "ui:options": {
//             ...obj.results.more_info.certain_agreements.is_pis_employees["ui:options"],
//             title: "Are all PIs Employees of Sponsor: ",
//             styles: {
//               ...obj.results.more_info.certain_agreements.is_pis_employees["ui:options"].styles,
//               ...previewStyles.flexRow
//             }
//           }
//         },
//         results_disclosure: {
//           ...obj.results.more_info.certain_agreements.results_disclosure,
//           "ui:options": {
//             ...obj.results.more_info.certain_agreements.results_disclosure["ui:options"],
//             title: "[*] Results Disclosure Restriction on PI(s): ",
//             styles: {
//               ...obj.results.more_info.certain_agreements.results_disclosure["ui:options"].styles,
//               ...previewStyles.flexRow
//             }
//           }
//         },
//         pi_disclosure: {
//           ...obj.results.more_info.certain_agreements.pi_disclosure,
//           "ui:options": {
//             ...obj.results.more_info.certain_agreements.pi_disclosure["ui:options"],
//             title: "PI Disclosure Restriction Type: ",
//             styles: {
//               ...obj.results.more_info.certain_agreements.pi_disclosure["ui:options"].styles,
//               ...previewStyles.flexRow
//             }
//           }
//         },
//         describe_agreement: {
//           ...obj.results.more_info.certain_agreements.describe_agreement,
//           "ui:options": {
//             ...obj.results.more_info.certain_agreements.describe_agreement["ui:options"],
//             title: "Agreement Description: ",
//             styles: {
//               ...obj.results.more_info.certain_agreements.describe_agreement["ui:options"].styles,
//               ...previewStyles.flexRow
//             }
//           }
//         },
//       },
//       results_poc: {
//         ...obj.results.more_info.results_poc,
//         name: {
//           ...obj.results.more_info.results_poc.name,
//           "ui:options": {
//             ...obj.results.more_info.results_poc.name["ui:options"],
//             styles: {
//               ...obj.results.more_info.results_poc.name["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         },
//         organization_name: {
//           ...obj.results.more_info.results_poc.organization_name,
//           "ui:options": {
//             ...obj.results.more_info.results_poc.organization_name["ui:options"],
//             styles: {
//               ...obj.results.more_info.results_poc.organization_name["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         },  
//         phone: {
//           ...obj.results.more_info.results_poc.phone,
//           "ui:options": {
//             ...obj.results.more_info.results_poc.phone["ui:options"],
//             styles: {
//               ...obj.results.more_info.results_poc.phone["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         },
//         ext: {
//           ...obj.results.more_info.results_poc.ext,
//           "ui:options": {
//             ...obj.results.more_info.results_poc.ext["ui:options"],
//             styles: {
//               ...obj.results.more_info.results_poc.ext["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         },
//         email: {
//           ...obj.results.more_info.results_poc.email,
//           "ui:options": {
//             ...obj.results.more_info.results_poc.email["ui:options"],
//             styles: {
//               ...obj.results.more_info.results_poc.email["ui:options"].styles,
//               ...previewStyles.boldLabel
//             }
//           }
//         },
//       },
//       limitations_caveats: {
//         ...obj.results.more_info.limitations_caveats,
//         "ui:options": {
//           ...obj.results.more_info.limitations_caveats["ui:options"],
//           title: "Limitations and Caveats: ",
//           styles: { 
//             ...obj.results.more_info.limitations_caveats["ui:options"].styles,
//             ...previewStyles.flexRow
//           }
//         },
//       }
//     }
//   }
// }

// export const getEudractPreviewStyles = (obj, formData) => {
//   if(obj.results) {
//     obj.results.trial_info = {
//       ...obj.results.trial_info,
//       "ui:options": {
//         ...obj.results.trial_info["ui:options"],
//         styles: {
//           ...previewStyles.sectionHeader
//         },
//       },
//       trial_id: {
//         ...obj.results.trial_info.trial_id,
//         "ui:options": {
//           ...obj.results.trial_info.trial_id["ui:options"],
//           styles: {
//             ...previewStyles.eudractSubsectionHeaderFirst
//           }
//         },
//         eu_num: {
//           ...obj.results.trial_info.trial_id.eu_num,
//           "ui:options": {
//             ...obj.results.trial_info.trial_id.eu_num["ui:options"],
//             styles: {
//               ...previewStyles.eudractRow
//             }
//           }
//         },
//         reg_num: {
//           ...obj.results.trial_info.trial_id.reg_num,
//           "ui:options": {
//             ...obj.results.trial_info.trial_id.reg_num["ui:options"],
//             styles: {
//               ...previewStyles.eudractRow
//             }
//           }
//         },
//         official_title: {
//           ...obj.results.trial_info.trial_id.official_title,
//           "ui:options": {
//             ...obj.results.trial_info.trial_id.official_title["ui:options"],
//             styles: {
//               ...previewStyles.eudractRowLast
//             }
//           }
//         }
//       },
//       additional_study_identifiers: {
//         ...obj.results.trial_info.additional_study_identifiers,
//         "ui:options": {
//           ...obj.results.trial_info.additional_study_identifiers["ui:options"],
//           styles: {
//             ...previewStyles.eudractSubsectionHeader
//           }
//         },
//         isrctn_num: {
//           ...obj.results.trial_info.additional_study_identifiers.isrctn_num,
//           "ui:options": {
//             ...obj.results.trial_info.additional_study_identifiers.isrctn_num["ui:options"],
//             styles: {
//               ...previewStyles.eudractRow
//             }
//           }
//         },
//         nct_id: {
//           ...obj.results.trial_info.additional_study_identifiers.nct_id,
//           "ui:options": {
//             ...obj.results.trial_info.additional_study_identifiers.nct_id["ui:options"],
//             styles: {
//               ...previewStyles.eudractRow
//             }
//           }
//         },
//         who_id: {
//           ...obj.results.trial_info.additional_study_identifiers.who_id,
//           "ui:options": {
//             ...obj.results.trial_info.additional_study_identifiers.who_id["ui:options"],
//             styles: {
//               ...previewStyles.eudractRowLast
//             }
//           }
//         }
//       },
//       secondary_ids: {
//         ...obj.results.trial_info.secondary_ids,
//         "ui:options": {
//           ...obj.results.trial_info.secondary_ids["ui:options"],
//           hide: formData?.results?.trial_info?.secondary_ids?.length > 0 ? false : true,
//           arrayItemTitle: "Identifier",
//           showIndex: true,
//           styles: {
//             ...obj.results.trial_info.secondary_ids["ui:options"].styles,
//             ...previewStyles.eudractArrayContainer,
//             ...previewStyles.eudractSubsectionHeaderFirst
//           }
//         },
//         items: {
//           "ui:options": {
//             ...obj.results.trial_info.secondary_ids.items["ui:options"],
//             styles: {

//             }
//           },
//           other_identifier_name: {
//             "ui:options": {
//               ...obj.results.trial_info.secondary_ids.items.other_identifier_name["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRow
//               }
//             }
//           },
//           other_identifier_code: {
//             "ui:options": {
//               ...obj.results.trial_info.secondary_ids.items.other_identifier_code["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRowLast
//               }
//             }
//           }
//         }
//       },
//       sponsor: {
//         ...obj.results.trial_info.sponsor,
//         "ui:options": {
//           ...obj.results.trial_info.sponsor["ui:options"],
//           // hide: formData?.results?.trial_info?.sponsor?.length > 0 ? false : true,
//           arrayItemTitle: "Sponsor",
//           showIndex: true,
//           styles: {
//             ...obj.results.trial_info.sponsor["ui:options"].styles,
//             ...previewStyles.eudractSubsectionHeaderFirst,
//             ...previewStyles.eudractArrayContainer,
//           }
//         },
//         items: {
//           "ui:options": {
//             ...obj.results.trial_info.sponsor.items["ui:options"],
//             styles: {

//             }
//           },
//           name: {
//             "ui:options": {
//               ...obj.results.trial_info.sponsor.items.name["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRow
//               }
//             }
//           },
//           address: {
//             "ui:options": {
//               ...obj.results.trial_info.sponsor.items.address["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRow
//               }
//             }
//           },
//           city: {
//             "ui:options": {
//               ...obj.results.trial_info.sponsor.items.city["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRow
//               }
//             }
//           },
//           zip_code: {
//             "ui:options": {
//               ...obj.results.trial_info.sponsor.items.zip_code["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRow
//               }
//             }
//           },
//           country: {
//             "ui:options": {
//               ...obj.results.trial_info.sponsor.items.country["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRowLast
//               }
//             }
//           },
//           scientific_contact: {
//             ...obj.results.trial_info.sponsor.items.scientific_contact,
//             "ui:options": {
//               ...obj.results.trial_info.sponsor.items.scientific_contact["ui:options"],
//               styles: {
//                 ...previewStyles.eudractSubsectionHeader
//               }
//             },
//             name: {
//               ...obj.results.trial_info.sponsor.items.scientific_contact.name,
//               "ui:options": {
//                 ...obj.results.trial_info.sponsor.items.scientific_contact.name["ui:options"],
//                 styles: {
//                   ...previewStyles.eudractRow
//                 }
//               }
//             },
//             title: {
//               ...obj.results.trial_info.sponsor.items.scientific_contact.title,
//               "ui:options": {
//                 ...obj.results.trial_info.sponsor.items.scientific_contact.title["ui:options"],
//                 styles: {
//                   ...previewStyles.eudractRow
//                 }
//               }
//             },
//             area_code: {
//               ...obj.results.trial_info.sponsor.items.scientific_contact.area_code,
//               "ui:options": {
//                 ...obj.results.trial_info.sponsor.items.scientific_contact.area_code["ui:options"],
//                 styles: {
//                   ...previewStyles.eudractRow
//                 }
//               }
//             },
//             phone_number: {
//               ...obj.results.trial_info.sponsor.items.scientific_contact.phone_number,
//               "ui:options": {
//                 ...obj.results.trial_info.sponsor.items.scientific_contact.phone_number["ui:options"],
//                 styles: {
//                   ...previewStyles.eudractRow
//                 }
//               }
//             },
//             email: {
//               ...obj.results.trial_info.sponsor.items.scientific_contact.email,
//               "ui:options": {
//                 ...obj.results.trial_info.sponsor.items.scientific_contact.email["ui:options"],
//                 styles: {
//                   ...previewStyles.eudractRowLast
//                 }
//               }
//             }
//           },
//           public_contact: {
//             ...obj.results.trial_info.sponsor.items.public_contact,
//             "ui:options": {
//               ...obj.results.trial_info.sponsor.items.public_contact["ui:options"],
//               styles: {
//                 ...previewStyles.eudractSubsectionHeader
//               }
//             },
//             name: {
//               ...obj.results.trial_info.sponsor.items.public_contact.name,
//               "ui:options": {
//                 ...obj.results.trial_info.sponsor.items.public_contact.name["ui:options"],
//                 styles: {
//                   ...previewStyles.eudractRow
//                 }
//               }
//             },
//             title: {
//               ...obj.results.trial_info.sponsor.items.public_contact.title,
//               "ui:options": {
//                 ...obj.results.trial_info.sponsor.items.public_contact.title["ui:options"],
//                 styles: {
//                   ...previewStyles.eudractRow
//                 }
//               }
//             },
//             area_code: {
//               ...obj.results.trial_info.sponsor.items.public_contact.area_code,
//               "ui:options": {
//                 ...obj.results.trial_info.sponsor.items.public_contact.area_code["ui:options"],
//                 styles: {
//                   ...previewStyles.eudractRow
//                 }
//               }
//             },
//             phone_number: {
//               ...obj.results.trial_info.sponsor.items.public_contact.phone_number,
//               "ui:options": {
//                 ...obj.results.trial_info.sponsor.items.public_contact.phone_number["ui:options"],
//                 styles: {
//                   ...previewStyles.eudractRow
//                 }
//               }
//             },
//             email: {
//               ...obj.results.trial_info.sponsor.items.public_contact.email,
//               "ui:options": {
//                 ...obj.results.trial_info.sponsor.items.public_contact.email["ui:options"],
//                 styles: {
//                   ...previewStyles.eudractRow
//                 }
//               }
//             }
//           }
//         }
//       },
//       paediatric_regulatory_details: {
//         ...obj.results.trial_info.paediatric_regulatory_details,
//         "ui:options": {
//           ...obj.results.trial_info.paediatric_regulatory_details["ui:options"],
//           styles: {
//             ...previewStyles.eudractSubsectionHeaderFirst
//           }
//         },
//         trial_part: {
//           ...obj.results.trial_info.paediatric_regulatory_details.trial_part,
//           "ui:options": {
//             ...obj.results.trial_info.paediatric_regulatory_details.trial_part["ui:options"],
//             styles: {
//               ...previewStyles.eudractRow
//             }
//           }
//         },
//         ema_plan: {
//           ...obj.results.trial_info.paediatric_regulatory_details.ema_plan,
//           "ui:options": {
//             ...obj.results.trial_info.paediatric_regulatory_details.ema_plan["ui:options"],
//             styles: {
//               ...previewStyles.eudractRow
//             }
//           }
//         }
//       },
//       article_45: {
//         ...obj.results.trial_info.article_45,
//         "ui:options": {
//           ...obj.results.trial_info.article_45["ui:options"],
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       article_46: {
//         ...obj.results.trial_info.article_46,
//         "ui:options": {
//           ...obj.results.trial_info.article_46["ui:options"],
//           styles: {
//             ...previewStyles.eudractRowLast
//           }
//         }
//       },
//       result_analysis_stage: {
//         ...obj.results.trial_info.result_analysis_stage,
//         "ui:options": {
//           ...obj.results.trial_info.result_analysis_stage["ui:options"],
//           styles: {
//             ...previewStyles.eudractSubsectionHeader
//           }
//         },
//         analysis_stage: {
//           ...obj.results.trial_info.result_analysis_stage.analysis_stage,
//           "ui:options": {
//             ...obj.results.trial_info.result_analysis_stage.analysis_stage["ui:options"],
//             styles: {
//               ...previewStyles.eudractRow
//             }
//           }
//         },
//         analysis_date: {
//           ...obj.results.trial_info.result_analysis_stage.analysis_date,
//           "ui:options": {
//             ...obj.results.trial_info.result_analysis_stage.analysis_date["ui:options"],
//             styles: {
//               ...previewStyles.eudractRow
//             }
//           }
//         },
//         primary_completion_data: {
//           ...obj.results.trial_info.result_analysis_stage.primary_completion_data,
//           "ui:options": {
//             ...obj.results.trial_info.result_analysis_stage.primary_completion_data["ui:options"],
//             styles: {
//               // ...previewStyles.eudractRow
//             }
//           },
//           is_primary_completion_data: {
//             ...obj.results.trial_info.result_analysis_stage.primary_completion_data.is_primary_completion_data,
//             "ui:options": {
//               ...obj.results.trial_info.result_analysis_stage.primary_completion_data.is_primary_completion_data["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRow
//               }
//             }
//           },
//           primary_completion_date: {
//             ...obj.results.trial_info.result_analysis_stage.primary_completion_data.primary_completion_date,
//             "ui:options": {
//               ...obj.results.trial_info.result_analysis_stage.primary_completion_data.primary_completion_date["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRow
//               }
//             }
//           }
//         },
//         global_end_date: {
//           ...obj.results.trial_info.result_analysis_stage.global_end_date,
//           "ui:options": {
//             ...obj.results.trial_info.result_analysis_stage.global_end_date["ui:options"],
//             styles: {
//               // ...previewStyles.eudractRow
//             }
//           },
//           is_trial_date_reached: {
//             ...obj.results.trial_info.result_analysis_stage.global_end_date.is_trial_date_reached,
//             "ui:options": {
//               ...obj.results.trial_info.result_analysis_stage.global_end_date.is_trial_date_reached["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRowLast
//               }
//             }
//           },
//           trial_date: {
//             ...obj.results.trial_info.result_analysis_stage.global_end_date.trial_date,
//             "ui:options": {
//               ...obj.results.trial_info.result_analysis_stage.global_end_date.trial_date["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRow
//               }
//             }
//           },
//           trial_end: {
//             ...obj.results.trial_info.result_analysis_stage.global_end_date.trial_end,
//             "ui:options": {
//               ...obj.results.trial_info.result_analysis_stage.global_end_date.trial_end["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRowLast
//               }
//             }
//           },
//         }
//       },
//       general_info: {
//         ...obj.results.trial_info.general_info,
//         "ui:options": {
//           ...obj.results.trial_info.general_info["ui:options"],
//           styles: {
//             ...previewStyles.eudractSubsectionHeader
//           }
//         },
//         trial_objectives: {
//           ...obj.results.trial_info.general_info.trial_objectives,
//           "ui:options": {
//             ...obj.results.trial_info.general_info.trial_objectives["ui:options"],
//             styles: {
//               ...previewStyles.eudractRow
//             }
//           }
//         },
//         actual_recruitment_start_date: {
//           ...obj.results.trial_info.general_info.actual_recruitment_start_date,
//           "ui:options": {
//             ...obj.results.trial_info.general_info.actual_recruitment_start_date["ui:options"],
//             styles: {
//               ...previewStyles.eudractRow
//             }
//           }
//         },
//         follow_up: {
//           ...obj.results.trial_info.general_info.follow_up,
//           "ui:options": {
//             ...obj.results.trial_info.general_info.follow_up["ui:options"],
//             styles: {
//               // ...previewStyles.eudractRow
//             }
//           },
//           is_planned: {
//             ...obj.results.trial_info.general_info.follow_up.is_planned,
//             "ui:options": {
//               ...obj.results.trial_info.general_info.follow_up.is_planned["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRow
//               }
//             }
//           },
//           rationale: {
//             ...obj.results.trial_info.general_info.follow_up.rationale,
//             "ui:options": {
//               ...obj.results.trial_info.general_info.follow_up.rationale["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRow
//               }
//             }
//           },
//           range: {
//             ...obj.results.trial_info.general_info.follow_up.range,
//             "ui:options": {
//               ...obj.results.trial_info.general_info.follow_up.range["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRow
//               }
//             }
//           },
//           duration_type: {
//             ...obj.results.trial_info.general_info.follow_up.duration_type,
//             "ui:options": {
//               ...obj.results.trial_info.general_info.follow_up.duration_type["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRow
//               }
//             }
//           },
//         },
//         independent: {
//           ...obj.results.trial_info.general_info.independent,
//           "ui:options": {
//             ...obj.results.trial_info.general_info.independent["ui:options"],
//             styles: {
//               ...previewStyles.eudractRow
//             }
//           }
//         },
//         protection: {
//           ...obj.results.trial_info.general_info.protection,
//           "ui:options": {
//             ...obj.results.trial_info.general_info.protection["ui:options"],
//             styles: {
//               ...previewStyles.eudractRow
//             }
//           }
//         },
//         background: {
//           ...obj.results.trial_info.general_info.background,
//           "ui:options": {
//             ...obj.results.trial_info.general_info.background["ui:options"],
//             styles: {
//               ...previewStyles.eudractRow
//             }
//           }
//         },
//         evidence: {
//           ...obj.results.trial_info.general_info.evidence,
//           "ui:options": {
//             ...obj.results.trial_info.general_info.evidence["ui:options"],
//             styles: {
//               ...previewStyles.eudractRowLast
//             }
//           }
//         },
//       }
//     }

//     obj.results.participant_flow = {
//       ...obj.results.participant_flow,
//       "ui:options": {
//         ...obj.results.participant_flow["ui:options"],
//         styles: {
//           ...previewStyles.sectionHeader
//         },
//       },
//       protocol_enrollment: {
//         ...obj.results.participant_flow.protocol_enrollment,
//         "ui:options": {
//           hide: true,
//           ...obj.results.participant_flow.protocol_enrollment["ui:options"],
//           title: "Protocol Enrollment: ",
//           styles: {
//             ...obj.results.participant_flow.protocol_enrollment["ui:options"].styles,
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       recruitment_details: {
//         ...obj.results.participant_flow.recruitment_details,
//         "ui:options": {
//           ...obj.results.participant_flow.recruitment_details["ui:options"],
//           title: "Recruitment Details: ",
//           styles: {
//             ...obj.results.participant_flow.recruitment_details["ui:options"].styles,
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       pre_assignment_details: {
//         ...obj.results.participant_flow.pre_assignment_details,
//         "ui:options": {
//           ...obj.results.participant_flow.pre_assignment_details["ui:options"],
//           title: "Pre-assignment Details (optional):",
//           styles: {
//             ...obj.results.participant_flow.pre_assignment_details["ui:options"].styles,
//             ...previewStyles.eudractRowLast
//           }
//         }
//       },
//       // type_units_analyzed: {
//       //   ...obj.results.participant_flow.type_units_analyzed,
//       //   "ui:options": {
//       //     ...obj.results.participant_flow.type_units_analyzed["ui:options"],
//       //     title: "Protocol Enrollment: ",
//       //     styles: {
//       //       ...obj.results.participant_flow.type_units_analyzed["ui:options"].styles,
//       //       ...previewStyles.flexRow
//       //     }
//       //   }
//       // },
//       participant_flow_table: {
//         ...obj.results.participant_flow.participant_flow_table,
//         "ui:options": {
//           ...obj.results.participant_flow.participant_flow_table["ui:options"],

//         }
//       }
//     }

//     obj.results.baseline_characteristics = {
//       ...obj.results.baseline_characteristics,
//       "ui:options": {
//         ...obj.results.baseline_characteristics["ui:options"],
//         styles: {
//           ...previewStyles.sectionHeader
//         },
//       },
//     }

//     obj.results.outcome_measures = {
//       ...obj.results.outcome_measures,
//       "ui:options": {
//         ...obj.results.outcome_measures["ui:options"],
//         styles: {
//           ...previewStyles.sectionHeader
//         },
//       },
//     }

//     obj.results.adverse_events = {
//       ...obj.results.adverse_events,
//       "ui:options": {
//         ...obj.results.adverse_events["ui:options"],
//         styles: {
//           ...previewStyles.sectionHeader
//         },
//       },
//     }

//     obj.results.more_info = {
//       ...obj.results.more_info,
//       "ui:options": {
//         ...obj.results.more_info["ui:options"],
//         styles: {
//           ...previewStyles.sectionHeader
//         },
//       },
//       global_protocol_amendments: {
//         ...obj.results.more_info.global_protocol_amendments,
//         "ui:options": {
//           ...obj.results.more_info.global_protocol_amendments["ui:options"],
//           styles: {
//             ...previewStyles.eudractSubsectionHeaderFirst
//           }
//         },
//         amendments: {
//           ...obj.results.more_info.global_protocol_amendments.amendments,
//           "ui:options": {
//             ...obj.results.more_info.global_protocol_amendments.amendments["ui:options"],
//             styles: {
//               ...previewStyles.eudractRowLast
//             }
//           }
//         },
//         add_amendment: {
//           ...obj.results.more_info.global_protocol_amendments.add_amendment,
//           "ui:options": {
//             ...obj.results.more_info.global_protocol_amendments.add_amendment["ui:options"],
//             arrayItemTitle: "Amendment",
//             showIndex: true,
//             styles: {
//               ...obj.results.more_info.global_protocol_amendments.add_amendment["ui:options"].styles,
//               ...previewStyles.eudractArrayContainer,
//               ...previewStyles.eudractSubsectionHeaderFirst,
//               arrayTitle: {
//                 fontWeight: "bold",
//                 fontSize: 15,
//                 marginTop: 6,
//                 marginBottom: 4
//               }
//             }
//           },
//           items: {
//             "ui:options": {
//               ...obj.results.more_info.global_protocol_amendments.add_amendment.items["ui:options"],
//               styles: {
  
//               }
//             },
//             amendment_date: {
//               "ui:options": {
//                 ...obj.results.more_info.global_protocol_amendments.add_amendment.items.amendment_date["ui:options"],
//                 styles: {
//                   ...previewStyles.eudractRow
//                 }
//               }
//             },
//             amendment_description: {
//               "ui:options": {
//                 ...obj.results.more_info.global_protocol_amendments.add_amendment.items.amendment_description["ui:options"],
//                 styles: {
//                   ...previewStyles.eudractRowLast
//                 }
//               }
//             }
//           }
//         },
//       },
//       global_protocol_interruptions: {
//         ...obj.results.more_info.global_protocol_interruptions,
//         "ui:options": {
//           ...obj.results.more_info.global_protocol_interruptions["ui:options"],
//           styles: {
//             ...previewStyles.eudractSubsectionHeaderFirst
//           }
//         },
//         interruptions: {
//           ...obj.results.more_info.global_protocol_interruptions.interruptions,
//           "ui:options": {
//             ...obj.results.more_info.global_protocol_interruptions.interruptions["ui:options"],
//             styles: {
//               ...previewStyles.eudractRowLast
//             }
//           }
//         },
//         add_interruption: {
//           ...obj.results.more_info.global_protocol_interruptions.add_interruption,
//           "ui:options": {
//             ...obj.results.more_info.global_protocol_interruptions.add_interruption["ui:options"],
//             arrayItemTitle: "Amendment",
//             showIndex: true,
//             styles: {
//               ...obj.results.more_info.global_protocol_interruptions.add_interruption["ui:options"].styles,
//               ...previewStyles.eudractArrayContainer,
//               ...previewStyles.eudractSubsectionHeaderFirst,
//               arrayTitle: {
//                 fontWeight: "bold",
//                 fontSize: 15,
//                 marginTop: 6,
//                 marginBottom: 4
//               }
//             }
//           },
//           items: {
//             "ui:options": {
//               ...obj.results.more_info.global_protocol_interruptions.add_interruption.items["ui:options"],
//               styles: {
  
//               }
//             },
//             interruption_date: {
//               "ui:options": {
//                 ...obj.results.more_info.global_protocol_interruptions.add_interruption.items.interruption_date["ui:options"],
//                 styles: {
//                   ...previewStyles.eudractRow
//                 }
//               }
//             },
//             interruption_description: {
//               "ui:options": {
//                 ...obj.results.more_info.global_protocol_interruptions.add_interruption.items.interruption_description["ui:options"],
//                 styles: {
//                   ...previewStyles.eudractRowLast
//                 }
//               }
//             },
//             restart: {
//               "ui:options": {
//                 ...obj.results.more_info.global_protocol_interruptions.add_interruption.items.restart["ui:options"],
//                 styles: {
//                   ...previewStyles.eudractRowLast
//                 }
//               }
//             },
//           }
//         },
//       },
//       limitations_caveats: {
//         ...obj.results.more_info.limitations_caveats,
//         "ui:options": {
//           ...obj.results.more_info.limitations_caveats["ui:options"],
//           styles: {
//             ...previewStyles.eudractRowLast
//           }
//         }
//       },
//       online_references: {
//         ...obj.results.more_info.online_references,
//         "ui:options": {
//           ...obj.results.more_info.online_references["ui:options"],
//           styles: {
//             objectTitleContainer: {
//               marginTop: 16,
//             },
//             objectTitle: {
//               paddingTop: 3,
//               paddingBottom: 3,
//               fontSize: 15,
//               fontWeight: "bold",
//               width: "100%",
//             }
//           }
//         },
//         pub_med_identifier: {
//           ...obj.results.more_info.online_references.pub_med_identifier,
//           "ui:options": {
//             ...obj.results.more_info.online_references.pub_med_identifier["ui:options"],
//             arrayItemTitle: "PMID",
//             title: "PubMed Identifier (PMID)",
//             showIndex: true,
//             styles: {
//               ...obj.results.more_info.online_references.pub_med_identifier["ui:options"].styles,
//               ...previewStyles.eudractArrayContainer,
//               ...previewStyles.eudractSubsectionHeaderFirst,
//               arrayTitle: {
//                 fontWeight: "bold",
//                 fontSize: 15,
//                 marginTop: 6,
//                 marginBottom: 4
//               }
//             }
//           },
//           items: {
//             "ui:options": {
//               ...obj.results.more_info.online_references.pub_med_identifier.items["ui:options"],
//               styles: {
  
//               }
//             },
//             link: {
//               "ui:options": {
//                 ...obj.results.more_info.online_references.pub_med_identifier.items.link["ui:options"],
//                 title: "Identifier",
//                 styles: {
//                   ...previewStyles.eudractRow
//                 }
//               }
//             },
//           }
//         }
//       }
//     }
//   }
// }

// export const getCtisPreviewStyles = (obj, formData) => {
//   if(obj?.protocol) {
//     obj.protocol["ui:options"] = {
//       title: "",
//       styles: { },
//     }
  
//     obj.protocol.protocol_id["ui:options"] = {
//       ...obj.protocol.protocol_id["ui:options"],
//       title: "Protocol code: ",
//       styles: {
//         ...previewStyles.flexRow
//       }
//     }

//     obj.protocol.study_identification = {
//       ...obj.protocol.study_identification,
//       // "ui:order": ["brief_title", "protocol_id", "acronym", "official_title", "*"],
//       "ui:options": {
//         ...obj.protocol.study_identification["ui:options"],
//         styles: { },
//       },
//       brief_title: {
//         ...obj.protocol.study_identification.brief_title,
//         "ui:options": {
//           ...obj.protocol.study_identification.brief_title["ui:options"],
//           styles: {
//             widgetContainer: {
//               ...previewStyles.fullWidth,
//               textAlign: "center",
//               fontSize: 18,
//               marginTop: 16,
//               marginBottom: 16
//             },
//             widgetTitle: {
//               display: "none"
//             },
//             widgetValue: {
//               fontWeight: "600"
//             }
//           }
//         }
//       },
//       brief_title_translation: {
//         ...obj.protocol.study_identification.brief_title_translation,
//         "ui:options": {
//           ...obj.protocol.study_identification.brief_title_translation["ui:options"],
//           styles: {
//             ...obj.protocol.study_identification.brief_title_translation["ui:options"].styles,
//             arrayTitle
//           }
//         },
//         items: {
//           ...obj.protocol.study_identification.brief_title_translation.items,
//           language: {
//             ...obj.protocol.study_identification.brief_title_translation.items.language,
//             "ui:options": {
//               ...obj.protocol.study_identification.brief_title_translation.items.language["ui:options"],
//               styles: {
//                 ...obj.protocol.study_identification.brief_title_translation.items.language["ui:options"].styles,
//                 ...previewStyles.boldLabel
//               }
//             }
//           },
//           translation: {
//             ...obj.protocol.study_identification.brief_title_translation.items.translation,
//             "ui:options": {
//               ...obj.protocol.study_identification.brief_title_translation.items.translation["ui:options"],
//               styles: {
//                 ...obj.protocol.study_identification.brief_title_translation.items.translation["ui:options"].styles,
//                 ...previewStyles.boldLabel
//               }
//             }
//           },
//         }
//       },
//       acronym: {
//         ...obj.protocol.study_identification.acronym,
//         "ui:options": {
//           ...obj.protocol.study_identification.acronym["ui:options"],
//           title: "Acronym (if any): ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       official_title: {
//         ...obj.protocol.study_identification.official_title,
//         "ui:options": {
//           ...obj.protocol.study_identification.official_title["ui:options"],
//           title: "Official Title: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       official_title_translation: {
//         ...obj.protocol.study_identification.official_title_translation,
//         "ui:options": {
//           ...obj.protocol.study_identification.official_title_translation["ui:options"],
//           styles: {
//             ...obj.protocol.study_identification.official_title_translation["ui:options"].styles,
//             arrayTitle
//           }
//         },
//         items: {
//           ...obj.protocol.study_identification.official_title_translation.items,
//           language: {
//             ...obj.protocol.study_identification.official_title_translation.items.language,
//             "ui:options": {
//               ...obj.protocol.study_identification.official_title_translation.items.language["ui:options"],
//               styles: {
//                 ...obj.protocol.study_identification.official_title_translation.items.language["ui:options"].styles,
//                 ...previewStyles.boldLabel
//               }
//             }
//           },
//           translation: {
//             ...obj.protocol.study_identification.official_title_translation.items.translation,
//             "ui:options": {
//               ...obj.protocol.study_identification.official_title_translation.items.translation["ui:options"],
//               styles: {
//                 ...obj.protocol.study_identification.official_title_translation.items.translation["ui:options"].styles,
//                 ...previewStyles.boldLabel
//               }
//             }
//           },
//         }
//       },
//       secondary_ids: {
//         ...obj.protocol.study_identification.secondary_ids,
//         "ui:options": {
//           ...obj.protocol.study_identification.secondary_ids["ui:options"],
//           showIndex: true,
//           arrayItemTitle: "Identifier",
//           styles: {
//             arrayTitle
//           }
//         },
//         items: {
//           ...obj.protocol.study_identification.secondary_ids.items,
//           "ui:options": {
//             ...obj.protocol.study_identification.secondary_ids.items["ui:options"],
//             styles: {
//               objectInnerContainer: {
//                 display: "flex",
//                 flexDirection: "column"
//               },
//               arrayItemContainer: {
//                 marginBottom: 6
//               }
//             }
//           },
//           secondary_id_type: {
//             ...obj.protocol.study_identification.secondary_ids.items.secondary_id_type,
//             "ui:options": { 
//               ...obj.protocol.study_identification.secondary_ids.items.secondary_id_type["ui:options"],
//               title: "        Identifier Type: ",
//               styles: {
//                 ...previewStyles.flexRow
//               }
//             }
//           },
//           id: {
//             ...obj.protocol.study_identification.secondary_ids.items.id,
//             "ui:options": { 
//               ...obj.protocol.study_identification.secondary_ids.items.id["ui:options"],
//               title: "        Identifier: ",
//               styles: {
//                 ...previewStyles.flexRow
//               }
//             }
//           },
//           domain: {
//             ...obj.protocol.study_identification.secondary_ids.items.domain,
//             "ui:options": { 
//               ...obj.protocol.study_identification.secondary_ids.items.domain["ui:options"],
//               title: "data-schema",
//               styles: {
//                 ...previewStyles.flexRow
//               }
//             }
//           },
//         }
//       }
//     }

//     obj.protocol.study_timeline = {
//       ...obj.protocol.study_timeline,
//       "ui:options": {
//         ...obj.protocol.study_timeline["ui:options"],
//         styles: {
//           ...previewStyles.sectionHeader
//         },
//       },
//       milestone_dates: {
//         ...obj.protocol.study_timeline.milestone_dates,
//         "ui:options": {
//           ...obj.protocol.study_timeline.milestone_dates["ui:options"],
//           styles: {
//             ...obj.protocol.study_timeline.milestone_dates["ui:options"].styles,
//             objectTitle: arrayTitle
//           }
//         },
//         estimated_recruitment_start_date_eea: {
//           ...obj.protocol.study_timeline.milestone_dates.estimated_recruitment_start_date_eea,
//           "ui:options": {
//             ...obj.protocol.study_timeline.milestone_dates.estimated_recruitment_start_date_eea["ui:options"],
//             styles: {
//               ...obj.protocol.study_timeline.milestone_dates.estimated_recruitment_start_date_eea["ui:options"].styles,
//               objectContainer: { marginBottom: 8 },
//               objectTitle: { fontWeight: 600 }
//             }
//           },
//           date: {
//             ...obj.protocol.study_timeline.milestone_dates.estimated_recruitment_start_date_eea.date,
//             "ui:options": {
//               ...obj.protocol.study_timeline.milestone_dates.estimated_recruitment_start_date_eea.date["ui:options"],
//               styles: {
//                 ...obj.protocol.study_timeline.milestone_dates.estimated_recruitment_start_date_eea.date["ui:options"].styles,
//                 ...previewStyles.boldLabel
//               }
//             }
//           }
//         },
//         estimated_end_of_trial_date_eea: {
//           ...obj.protocol.study_timeline.milestone_dates.estimated_end_of_trial_date_eea,
//           "ui:options": {
//             ...obj.protocol.study_timeline.milestone_dates.estimated_end_of_trial_date_eea["ui:options"],
//             styles: {
//               ...obj.protocol.study_timeline.milestone_dates.estimated_end_of_trial_date_eea["ui:options"].styles,
//               objectContainer: { marginBottom: 8 },
//               objectTitle: { fontWeight: 600 }
//             }
//           },
//           date: {
//             ...obj.protocol.study_timeline.milestone_dates.estimated_end_of_trial_date_eea.date,
//             "ui:options": {
//               ...obj.protocol.study_timeline.milestone_dates.estimated_end_of_trial_date_eea.date["ui:options"],
//               styles: {
//                 ...obj.protocol.study_timeline.milestone_dates.estimated_end_of_trial_date_eea.date["ui:options"].styles,
//                 ...previewStyles.boldLabel
//               }
//             }
//           }
//         },
//         estimated_global_end_of_trial_date: {
//           ...obj.protocol.study_timeline.milestone_dates.estimated_global_end_of_trial_date,
//           "ui:options": {
//             ...obj.protocol.study_timeline.milestone_dates.estimated_global_end_of_trial_date["ui:options"],
//             styles: {
//               ...obj.protocol.study_timeline.milestone_dates.estimated_global_end_of_trial_date["ui:options"].styles,
//               objectContainer: { marginBottom: 8 },
//               objectTitle: { fontWeight: 600 }
//             }
//           },
//           date: {
//             ...obj.protocol.study_timeline.milestone_dates.estimated_global_end_of_trial_date.date,
//             "ui:options": {
//               ...obj.protocol.study_timeline.milestone_dates.estimated_global_end_of_trial_date.date["ui:options"],
//               styles: {
//                 ...obj.protocol.study_timeline.milestone_dates.estimated_global_end_of_trial_date.date["ui:options"].styles,
//                 ...previewStyles.boldLabel
//               }
//             }
//           }
//         },
//       },
//       funding_source: {
//         ...obj.protocol.study_timeline.funding_source,
//         "ui:options": {
//           ...obj.protocol.study_timeline.funding_source["ui:options"],
//           styles: {
//             ...obj.protocol.study_timeline.funding_source["ui:options"].styles,
//             objectTitle: arrayTitle
//           }
//         },
//         other_funding_sources: {
//           ...obj.protocol.study_timeline.funding_source.other_funding_sources,
//           "ui:options": {
//             ...obj.protocol.study_timeline.funding_source.other_funding_sources["ui:options"],
//             showIndex: true,
//             arrayItemTitle: "Funding source",
//             styles: {
//               arrayTitle
//             }
//           },
//           items: {
//             ...obj.protocol.study_timeline.funding_source.other_funding_sources.items,
//             "ui:options": {
//               ...obj.protocol.study_timeline.funding_source.other_funding_sources.items["ui:options"],
//               styles: {
//                 objectInnerContainer: {
//                   display: "flex",
//                   flexDirection: "column"
//                 },
//                 arrayItemContainer: {
//                   marginBottom: 6
//                 }
//               }
//             },
//             names: {
//               ...obj.protocol.study_timeline.funding_source.other_funding_sources.items.names,
//               "ui:options": { 
//                 ...obj.protocol.study_timeline.funding_source.other_funding_sources.items.names["ui:options"],
//                 title: "        Organisation name: ",
//                 styles: {
//                   ...previewStyles.flexRow
//                 }
//               }
//             }
//           }
//         }
//       }
//     }

//     obj.protocol.ctis_form = {
//       ...obj.protocol.ctis_form,
//       "ui:options": {
//         ...obj.protocol.ctis_form["ui:options"],
//         styles: {
//           ...previewStyles.sectionHeader
//         },
//       },
//       deferral_clinical_trial_information: {
//         ...obj.protocol.ctis_form.deferral_clinical_trial_information,
//         "ui:options": {
//           ...obj.protocol.ctis_form.deferral_clinical_trial_information["ui:options"],
//           styles: {
//             ...obj.protocol.ctis_form.deferral_clinical_trial_information["ui:options"].styles,
//             objectTitle: arrayTitle
//           }
//         },
//         short_title: {
//           ...obj.protocol.ctis_form.deferral_clinical_trial_information.short_title,
//           "ui:options": {
//             ...obj.protocol.ctis_form.deferral_clinical_trial_information.short_title["ui:options"],
//             title: "Short title / Trial category: ",
//             styles: {
//               ...previewStyles.flexRow
//             }
//           }
//         },
//         justification_for_trial_category: {
//           ...obj.protocol.ctis_form.deferral_clinical_trial_information.justification_for_trial_category,
//           "ui:options": {
//             ...obj.protocol.ctis_form.deferral_clinical_trial_information.justification_for_trial_category["ui:options"],
//             title: "Justification for trial category / Trial category: ",
//             styles: {
//               ...previewStyles.flexRow
//             }
//           }
//         },
//         main_characteristics: {
//           ...obj.protocol.ctis_form.deferral_clinical_trial_information.main_characteristics,
//           "ui:options": {
//             ...obj.protocol.ctis_form.deferral_clinical_trial_information.main_characteristics["ui:options"],
//             title: "Main characteristics: ",
//             styles: {
//               ...previewStyles.flexRow
//             }
//           }
//         },
//         notifications: {
//           ...obj.protocol.ctis_form.deferral_clinical_trial_information.notifications,
//           "ui:options": {
//             ...obj.protocol.ctis_form.deferral_clinical_trial_information.notifications["ui:options"],
//             title: "Notifications: ",
//             styles: {
//               ...previewStyles.flexRow
//             }
//           }
//         },
//         subject_information_sheet: {
//           ...obj.protocol.ctis_form.deferral_clinical_trial_information.subject_information_sheet,
//           "ui:options": {
//             ...obj.protocol.ctis_form.deferral_clinical_trial_information.subject_information_sheet["ui:options"],
//             title: "Subject information sheet: ",
//             styles: {
//               ...previewStyles.flexRow
//             }
//           }
//         },
//         protocol_summary_scientific_advice: {
//           ...obj.protocol.ctis_form.deferral_clinical_trial_information.protocol_summary_scientific_advice,
//           "ui:options": {
//             ...obj.protocol.ctis_form.deferral_clinical_trial_information.protocol_summary_scientific_advice["ui:options"],
//             title: "Protocol and Summary of Scientific Advice: ",
//             styles: {
//               ...previewStyles.flexRow
//             }
//           }
//         },
//         impd_sande_sections_investigator_brochure: {
//           ...obj.protocol.ctis_form.deferral_clinical_trial_information.impd_sande_sections_investigator_brochure,
//           "ui:options": {
//             ...obj.protocol.ctis_form.deferral_clinical_trial_information.impd_sande_sections_investigator_brochure["ui:options"],
//             title: "IMPD SandE sections and Investigator Brochure: ",
//             styles: {
//               ...previewStyles.flexRow
//             }
//           }
//         },
//         responses_rfi: {
//           ...obj.protocol.ctis_form.deferral_clinical_trial_information.responses_rfi,
//           "ui:options": {
//             ...obj.protocol.ctis_form.deferral_clinical_trial_information.responses_rfi["ui:options"],
//             title: "Responses to RFI: ",
//             styles: {
//               ...previewStyles.flexRow
//             }
//           }
//         },
//         clinical_trial_results_summary_intermediate_data_analysis: {
//           ...obj.protocol.ctis_form.deferral_clinical_trial_information.clinical_trial_results_summary_intermediate_data_analysis,
//           "ui:options": {
//             ...obj.protocol.ctis_form.deferral_clinical_trial_information.clinical_trial_results_summary_intermediate_data_analysis["ui:options"],
//             title: "Clinical trial results summary for an intermediate data analysis: ",
//             styles: {
//               ...previewStyles.flexRow
//             }
//           }
//         },
//         clinical_trial_results_summmray_lay_person_summary: {
//           ...obj.protocol.ctis_form.deferral_clinical_trial_information.clinical_trial_results_summmray_lay_person_summary,
//           "ui:options": {
//             ...obj.protocol.ctis_form.deferral_clinical_trial_information.clinical_trial_results_summmray_lay_person_summary["ui:options"],
//             title: "Clinical trial results summary and lay person summary: ",
//             styles: {
//               ...previewStyles.flexRow
//             }
//           }
//         },
//         subject_information_sheet_years: {
//           ...obj.protocol.ctis_form.deferral_clinical_trial_information.subject_information_sheet_years,
//           "ui:options": {
//             ...obj.protocol.ctis_form.deferral_clinical_trial_information.subject_information_sheet_years["ui:options"],
//             title: "Years: ",
//             styles: {
//               ...previewStyles.flexRow
//             }
//           }
//         },
//         subject_information_sheet_months: {
//           ...obj.protocol.ctis_form.deferral_clinical_trial_information.subject_information_sheet_months,
//           "ui:options": {
//             ...obj.protocol.ctis_form.deferral_clinical_trial_information.subject_information_sheet_months["ui:options"],
//             title: "Months after the end of trial: ",
//             styles: {
//               ...previewStyles.flexRow
//             }
//           }
//         },
//         protocol_summary_scientific_advice_years: {
//           ...obj.protocol.ctis_form.deferral_clinical_trial_information.protocol_summary_scientific_advice_years,
//           "ui:options": {
//             ...obj.protocol.ctis_form.deferral_clinical_trial_information.protocol_summary_scientific_advice_years["ui:options"],
//             title: "Years: ",
//             styles: {
//               ...previewStyles.flexRow
//             }
//           }
//         },
//         protocol_summary_scientific_advice_months: {
//           ...obj.protocol.ctis_form.deferral_clinical_trial_information.protocol_summary_scientific_advice_months,
//           "ui:options": {
//             ...obj.protocol.ctis_form.deferral_clinical_trial_information.protocol_summary_scientific_advice_months["ui:options"],
//             title: "Months after the end of trial: ",
//             styles: {
//               ...previewStyles.flexRow
//             }
//           }
//         },
//         impd_sande_sections_investigator_brochure_years: {
//           ...obj.protocol.ctis_form.deferral_clinical_trial_information.impd_sande_sections_investigator_brochure_years,
//           "ui:options": {
//             ...obj.protocol.ctis_form.deferral_clinical_trial_information.impd_sande_sections_investigator_brochure_years["ui:options"],
//             title: "Years: ",
//             styles: {
//               ...previewStyles.flexRow
//             }
//           }
//         },
//         impd_sande_sections_investigator_brochure_months: {
//           ...obj.protocol.ctis_form.deferral_clinical_trial_information.impd_sande_sections_investigator_brochure_months,
//           "ui:options": {
//             ...obj.protocol.ctis_form.deferral_clinical_trial_information.impd_sande_sections_investigator_brochure_months["ui:options"],
//             title: "Months after the end of trial: ",
//             styles: {
//               ...previewStyles.flexRow
//             }
//           }
//         },
//         responses_rfi_years: {
//           ...obj.protocol.ctis_form.deferral_clinical_trial_information.responses_rfi_years,
//           "ui:options": {
//             ...obj.protocol.ctis_form.deferral_clinical_trial_information.responses_rfi_years["ui:options"],
//             title: "Years: ",
//             styles: {
//               ...previewStyles.flexRow
//             }
//           }
//         },
//         responses_rfi_months: {
//           ...obj.protocol.ctis_form.deferral_clinical_trial_information.responses_rfi_months,
//           "ui:options": {
//             ...obj.protocol.ctis_form.deferral_clinical_trial_information.responses_rfi_months["ui:options"],
//             title: "Months after the end of trial: ",
//             styles: {
//               ...previewStyles.flexRow
//             }
//           }
//         },
//         clinical_trial_results_summary_intermediate_data_analysis_months: {
//           ...obj.protocol.ctis_form.deferral_clinical_trial_information.clinical_trial_results_summary_intermediate_data_analysis_months,
//           "ui:options": {
//             ...obj.protocol.ctis_form.deferral_clinical_trial_information.clinical_trial_results_summary_intermediate_data_analysis_months["ui:options"],
//             title: "Months after the end of trial: ",
//             styles: {
//               ...previewStyles.flexRow
//             }
//           }
//         },
//         clinical_trial_results_summmray_lay_person_summary_months: {
//           ...obj.protocol.ctis_form.deferral_clinical_trial_information.clinical_trial_results_summmray_lay_person_summary_months,
//           "ui:options": {
//             ...obj.protocol.ctis_form.deferral_clinical_trial_information.clinical_trial_results_summmray_lay_person_summary_months["ui:options"],
//             title: "Months after the end of trial: ",
//             styles: {
//               ...previewStyles.flexRow
//             }
//           }
//         },
//         justification_deferral_published_decision: {
//           ...obj.protocol.ctis_form.deferral_clinical_trial_information.justification_deferral_published_decision,
//           "ui:options": {
//             ...obj.protocol.ctis_form.deferral_clinical_trial_information.justification_deferral_published_decision["ui:options"],
//             title: "Justification for deferral published at decision: ",
//             styles: {
//               ...previewStyles.flexRow
//             }
//           }
//         },

//       }
//     }

//     obj.protocol.mscs = {
//       ...obj.protocol.mscs,
//       "ui:options": {
//         ...obj.protocol.mscs["ui:options"],
//         styles: {
//           ...previewStyles.sectionHeader
//         },
//       },
//     }

//     obj.protocol.study_description = {
//       ...obj.protocol.study_description,
//       "ui:options": {
//         ...obj.protocol.study_description["ui:options"],
//         styles: {
//           ...previewStyles.sectionHeader
//         },
//       },
//       trial_scope: {
//         ...obj.protocol.study_description.trial_scope,
//         "ui:options": {
//           ...obj.protocol.study_description.trial_scope["ui:options"],
//           title: "Trial Scope: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       ctis_trial_phase: {
//         ...obj.protocol.study_description.ctis_trial_phase,
//         "ui:options": {
//           ...obj.protocol.study_description.ctis_trial_phase["ui:options"],
//           title: "Trial Phase: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       brief_summary: {
//         ...obj.protocol.study_description.brief_summary,
//         "ui:options": {
//           ...obj.protocol.study_description.brief_summary["ui:options"],
//           title: "Main objective (English): ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       brief_summary_translations: {
//         ...obj.protocol.study_description.brief_summary_translations,
//         "ui:options": {
//           ...obj.protocol.study_description.brief_summary_translations["ui:options"],
//           title: "Main Objective Languages: ", 
//           styles: {
//             ...obj.protocol.study_description.brief_summary_translations["ui:options"].styles,
//             arrayTitle
//           }
//         },
//         items: {
//           ...obj.protocol.study_description.brief_summary_translations.items,
//           language: {
//             ...obj.protocol.study_description.brief_summary_translations.items.language,
//             "ui:options": {
//               ...obj.protocol.study_description.brief_summary_translations.items.language["ui:options"],
//               styles: {
//                 ...obj.protocol.study_description.brief_summary_translations.items.language["ui:options"].styles,
//                 ...previewStyles.boldLabel
//               }
//             }
//           },
//           translation: {
//             ...obj.protocol.study_description.brief_summary_translations.items.translation,
//             "ui:options": {
//               ...obj.protocol.study_description.brief_summary_translations.items.translation["ui:options"],
//               styles: {
//                 ...obj.protocol.study_description.brief_summary_translations.items.translation["ui:options"].styles,
//                 ...previewStyles.boldLabel
//               }
//             }
//           },
//         }
//       },
//       secondary_objective: {
//         ...obj.protocol.study_description.secondary_objective,
//         "ui:options": {
//           ...obj.protocol.study_description.secondary_objective["ui:options"],
//           title: "Secondary objective (English): ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       secondary_objective_translations: {
//         ...obj.protocol.study_description.secondary_objective_translations,
//         "ui:options": {
//           ...obj.protocol.study_description.secondary_objective_translations["ui:options"],
//           title: "Secondary Objectives Translation: ", 
//           styles: {
//             ...obj.protocol.study_description.secondary_objective_translations["ui:options"].styles,
//             arrayTitle
//           }
//         },
//         items: {
//           ...obj.protocol.study_description.secondary_objective_translations.items,
//           language: {
//             ...obj.protocol.study_description.secondary_objective_translations.items.language,
//             "ui:options": {
//               ...obj.protocol.study_description.secondary_objective_translations.items.language["ui:options"],
//               styles: {
//                 ...obj.protocol.study_description.secondary_objective_translations.items.language["ui:options"].styles,
//                 ...previewStyles.boldLabel
//               }
//             }
//           },
//           translation: {
//             ...obj.protocol.study_description.secondary_objective_translations.items.translation,
//             "ui:options": {
//               ...obj.protocol.study_description.secondary_objective_translations.items.translation["ui:options"],
//               styles: {
//                 ...obj.protocol.study_description.secondary_objective_translations.items.translation["ui:options"].styles,
//                 ...previewStyles.boldLabel
//               }
//             }
//           },
//         }
//       },
//       low_intervention_trial: {
//         ...obj.protocol.study_description.low_intervention_trial,
//         "ui:options": {
//           ...obj.protocol.study_description.low_intervention_trial["ui:options"],
//           title: "Trial Phase: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       justification_for_low_intervention_trial: {
//         ...obj.protocol.study_description.justification_for_low_intervention_trial,
//         "ui:options": {
//           ...obj.protocol.study_description.justification_for_low_intervention_trial["ui:options"],
//           title: "Trial Phase: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       therapeutic_area: {
//         ...obj.protocol.study_description.therapeutic_area,
//         "ui:options": {
//           ...obj.protocol.study_description.therapeutic_area["ui:options"],
//           title: "Trial Phase: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       medical_conditions: {
//         ...obj.protocol.study_description.medical_conditions,
//         "ui:options": {
//           ...obj.protocol.study_description.medical_conditions["ui:options"],
//           showIndex: true,
//           arrayItemTitle: "Medical conditions: ",
//           styles: {
//             arrayTitle
//           }
//         },
//         items: {
//           ...obj.protocol.study_description.medical_conditions.items,
//           "ui:options": {
//             ...obj.protocol.study_description.medical_conditions.items["ui:options"],
//             styles: {
//               objectInnerContainer: {
//                 display: "flex",
//                 flexDirection: "column"
//               },
//               arrayItemContainer: {
//                 marginBottom: 6
//               }
//             }
//           },
//           condition: {
//             ...obj.protocol.study_description.medical_conditions.items.condition,
//             "ui:options": { 
//               ...obj.protocol.study_description.medical_conditions.items.condition["ui:options"],
//               title: "        Condition: ",
//               styles: {
//                 ...previewStyles.flexRow
//               }
//             }
//           },
//           translations: {
//             ...obj.protocol.study_description.medical_conditions.items.translations,
//             "ui:options": {
//               ...obj.protocol.study_description.medical_conditions.items.translations["ui:options"],
//               showIndex: true,
//               arrayItemTitle: "Translations: ",
//               styles: {
//                 arrayTitle
//               }
//             },
//             items: {
//               ...obj.protocol.study_description.medical_conditions.items.translations.items,
//               "ui:options": {
//                 ...obj.protocol.study_description.medical_conditions.items.translations.items["ui:options"],
//                 styles: {
//                   objectInnerContainer: {
//                     display: "flex",
//                     flexDirection: "column"
//                   },
//                   arrayItemContainer: {
//                     marginBottom: 6
//                   }
//                 }
//               },
//               language: {
//                 ...obj.protocol.study_description.medical_conditions.items.translations.items.language,
//                 "ui:options": { 
//                   ...obj.protocol.study_description.medical_conditions.items.translations.items.language["ui:options"],
//                   title: "        Language: ",
//                   styles: {
//                     ...previewStyles.flexRow
//                   }
//                 }
//               },
//               translation: {
//                 ...obj.protocol.study_description.medical_conditions.items.translations.items.translation,
//                 "ui:options": { 
//                   ...obj.protocol.study_description.medical_conditions.items.translations.items.translation["ui:options"],
//                   title: "        Translation: ",
//                   styles: {
//                     ...previewStyles.flexRow
//                   }
//                 }
//               },
//             }
//           },
//           is_medical_condition_rare: {
//             ...obj.protocol.study_description.medical_conditions.items.is_medical_condition_rare,
//             "ui:options": { 
//               ...obj.protocol.study_description.medical_conditions.items.is_medical_condition_rare["ui:options"],
//               title: "        Is the medical condition considered to be a rare disease?",
//               styles: {
//                 ...previewStyles.flexRow
//               }
//             }
//           },
//         }
//       },
//       meddra: {
//         ...obj.protocol.study_description.meddra,
//         "ui:options": {
//           ...obj.protocol.study_description.meddra["ui:options"],
//           showIndex: true,
//           arrayItemTitle: "Medical conditions: ",
//           styles: {
//             arrayTitle
//           }
//         },
//         items: {
//           ...obj.protocol.study_description.meddra.items,
//           "ui:options": {
//             ...obj.protocol.study_description.meddra.items["ui:options"],
//             styles: {
//               objectInnerContainer: {
//                 display: "flex",
//                 flexDirection: "column"
//               },
//               arrayItemContainer: {
//                 marginBottom: 6
//               }
//             }
//           },
//           term_name: {
//             ...obj.protocol.study_description.meddra.items.term_name,
//             "ui:options": { 
//               ...obj.protocol.study_description.meddra.items.term_name["ui:options"],
//               title: "        Term Name: ",
//               styles: {
//                 ...previewStyles.flexRow
//               }
//             }
//           },
//           level: {
//             ...obj.protocol.study_description.meddra.items.level,
//             "ui:options": { 
//               ...obj.protocol.study_description.meddra.items.level["ui:options"],
//               title: "        Level: ",
//               styles: {
//                 ...previewStyles.flexRow
//               }
//             }
//           },
//           classification_code: {
//             ...obj.protocol.study_description.meddra.items.classification_code,
//             "ui:options": { 
//               ...obj.protocol.study_description.meddra.items.classification_code["ui:options"],
//               title: "        Classification Code: ",
//               styles: {
//                 ...previewStyles.flexRow
//               }
//             }
//           },
//         }
//       },
//       principle_inclusion_criteria: {
//         ...obj.protocol.study_description.principle_inclusion_criteria,
//         "ui:options": {
//           ...obj.protocol.study_description.principle_inclusion_criteria["ui:options"],
//           showIndex: true,
//           arrayItemTitle: "Principal inclusion criteria: ",
//           styles: {
//             arrayTitle
//           }
//         },
//         items: {
//           ...obj.protocol.study_description.principle_inclusion_criteria.items,
//           "ui:options": {
//             ...obj.protocol.study_description.principle_inclusion_criteria.items["ui:options"],
//             styles: {
//               objectInnerContainer: {
//                 display: "flex",
//                 flexDirection: "column"
//               },
//               arrayItemContainer: {
//                 marginBottom: 6
//               }
//             }
//           },
//           inclusion_criteria: {
//             ...obj.protocol.study_description.principle_inclusion_criteria.items.inclusion_criteria,
//             "ui:options": { 
//               ...obj.protocol.study_description.principle_inclusion_criteria.items.inclusion_criteria["ui:options"],
//               title: "        Inclusion criteria (English): ",
//               styles: {
//                 ...previewStyles.flexRow
//               }
//             }
//           },
//           translations: {
//             ...obj.protocol.study_description.principle_inclusion_criteria.items.translations,
//             "ui:options": {
//               ...obj.protocol.study_description.principle_inclusion_criteria.items.translations["ui:options"],
//               showIndex: true,
//               arrayItemTitle: "Translations: ",
//               styles: {
//                 arrayTitle
//               }
//             },
//             items: {
//               ...obj.protocol.study_description.principle_inclusion_criteria.items.translations.items,
//               "ui:options": {
//                 ...obj.protocol.study_description.principle_inclusion_criteria.items.translations.items["ui:options"],
//                 styles: {
//                   objectInnerContainer: {
//                     display: "flex",
//                     flexDirection: "column"
//                   },
//                   arrayItemContainer: {
//                     marginBottom: 6
//                   }
//                 }
//               },
//               language: {
//                 ...obj.protocol.study_description.principle_inclusion_criteria.items.translations.items.language,
//                 "ui:options": { 
//                   ...obj.protocol.study_description.principle_inclusion_criteria.items.translations.items.language["ui:options"],
//                   title: "        Language: ",
//                   styles: {
//                     ...previewStyles.flexRow
//                   }
//                 }
//               },
//               translation: {
//                 ...obj.protocol.study_description.principle_inclusion_criteria.items.translations.items.translation,
//                 "ui:options": { 
//                   ...obj.protocol.study_description.principle_inclusion_criteria.items.translations.items.translation["ui:options"],
//                   title: "        Translation: ",
//                   styles: {
//                     ...previewStyles.flexRow
//                   }
//                 }
//               },
//             }
//           },
//         }
//       },
//       principle_exlusion_criteria: {
//         ...obj.protocol.study_description.principle_exlusion_criteria,
//         "ui:options": {
//           ...obj.protocol.study_description.principle_exlusion_criteria["ui:options"],
//           showIndex: true,
//           arrayItemTitle: "Principle exclusion criteria: ",
//           styles: {
//             arrayTitle
//           }
//         },
//         items: {
//           ...obj.protocol.study_description.principle_exlusion_criteria.items,
//           "ui:options": {
//             ...obj.protocol.study_description.principle_exlusion_criteria.items["ui:options"],
//             styles: {
//               objectInnerContainer: {
//                 display: "flex",
//                 flexDirection: "column"
//               },
//               arrayItemContainer: {
//                 marginBottom: 6
//               }
//             }
//           },
//           exclusion_criteria: {
//             ...obj.protocol.study_description.principle_exlusion_criteria.items.exclusion_criteria,
//             "ui:options": { 
//               ...obj.protocol.study_description.principle_exlusion_criteria.items.exclusion_criteria["ui:options"],
//               title: "        Exclusion criteria (English): ",
//               styles: {
//                 ...previewStyles.flexRow
//               }
//             }
//           },
//           translations: {
//             ...obj.protocol.study_description.principle_exlusion_criteria.items.translations,
//             "ui:options": {
//               ...obj.protocol.study_description.principle_exlusion_criteria.items.translations["ui:options"],
//               showIndex: true,
//               arrayItemTitle: "Translations: ",
//               styles: {
//                 arrayTitle
//               }
//             },
//             items: {
//               ...obj.protocol.study_description.principle_exlusion_criteria.items.translations.items,
//               "ui:options": {
//                 ...obj.protocol.study_description.principle_exlusion_criteria.items.translations.items["ui:options"],
//                 styles: {
//                   objectInnerContainer: {
//                     display: "flex",
//                     flexDirection: "column"
//                   },
//                   arrayItemContainer: {
//                     marginBottom: 6
//                   }
//                 }
//               },
//               language: {
//                 ...obj.protocol.study_description.principle_exlusion_criteria.items.translations.items.language,
//                 "ui:options": { 
//                   ...obj.protocol.study_description.principle_exlusion_criteria.items.translations.items.language["ui:options"],
//                   title: "        Language: ",
//                   styles: {
//                     ...previewStyles.flexRow
//                   }
//                 }
//               },
//               translation: {
//                 ...obj.protocol.study_description.principle_exlusion_criteria.items.translations.items.translation,
//                 "ui:options": { 
//                   ...obj.protocol.study_description.principle_exlusion_criteria.items.translations.items.translation["ui:options"],
//                   title: "        Translation: ",
//                   styles: {
//                     ...previewStyles.flexRow
//                   }
//                 }
//               },
//             }
//           },
//         }
//       },
//       protocol_information: {
//         ...obj.protocol.study_description.protocol_information,
//         "ui:options": {
//           ...obj.protocol.study_description.protocol_information["ui:options"],
//         },
//         periods: {
//           ...obj.protocol.study_description.protocol_information.periods,
//           "ui:options": {
//             ...obj.protocol.study_description.protocol_information.periods["ui:options"],
//             showIndex: true,
//             arrayItemTitle: "Periods: ",
//             styles: {
//               arrayTitle
//             }
//           },
//           items: {
//             ...obj.protocol.study_description.protocol_information.periods.items,
//             "ui:options": {
//               ...obj.protocol.study_description.protocol_information.periods.items["ui:options"],
//               styles: {
//                 objectInnerContainer: {
//                   display: "flex",
//                   flexDirection: "column"
//                 },
//                 arrayItemContainer: {
//                   marginBottom: 6
//                 }
//               }
//             },
//             title: {
//               ...obj.protocol.study_description.protocol_information.periods.items.title,
//               "ui:options": { 
//                 ...obj.protocol.study_description.protocol_information.periods.items.title["ui:options"],
//                 title: "        Title: ",
//                 styles: {
//                   ...previewStyles.flexRow
//                 }
//               }
//             },
//             description: {
//               ...obj.protocol.study_description.protocol_information.periods.items.description,
//               "ui:options": { 
//                 ...obj.protocol.study_description.protocol_information.periods.items.description["ui:options"],
//                 title: "        Description: ",
//                 styles: {
//                   ...previewStyles.flexRow
//                 }
//               }
//             },
//             allocation_method: {
//               ...obj.protocol.study_description.protocol_information.periods.items.allocation_method,
//               "ui:options": { 
//                 ...obj.protocol.study_description.protocol_information.periods.items.allocation_method["ui:options"],
//                 title: "        Allocation method: ",
//                 styles: {
//                   ...previewStyles.flexRow
//                 }
//               }
//             },
//             binding_used: {
//               ...obj.protocol.study_description.protocol_information.periods.items.binding_used,
//               "ui:options": { 
//                 ...obj.protocol.study_description.protocol_information.periods.items.binding_used["ui:options"],
//                 title: "        Blinding used: ",
//                 styles: {
//                   ...previewStyles.flexRow
//                 }
//               }
//             },
//             roles_blinded: {
//               ...obj.protocol.study_description.protocol_information.periods.items.roles_blinded,
//               "ui:options": { 
//                 ...obj.protocol.study_description.protocol_information.periods.items.roles_blinded["ui:options"],
//                 title: "        Roles blinded: ",
//                 styles: {
//                   ...previewStyles.flexRow
//                 }
//               }
//             },
//             blinding_implementation_details: {
//               ...obj.protocol.study_description.protocol_information.periods.items.blinding_implementation_details,
//               "ui:options": { 
//                 ...obj.protocol.study_description.protocol_information.periods.items.blinding_implementation_details["ui:options"],
//                 title: "        Blinding implementation details: ",
//                 styles: {
//                   ...previewStyles.flexRow
//                 }
//               }
//             },
//             arms: {
//               ...obj.protocol.study_description.protocol_information.periods.items.arms,
//               "ui:options": {
//                 ...obj.protocol.study_description.protocol_information.periods.items.arms["ui:options"],
//                 showIndex: true,
//                 arrayItemTitle: "Arms: ",
//                 styles: {
//                   arrayTitle
//                 }
//               },
//               items: {
//                 ...obj.protocol.study_description.protocol_information.periods.items.arms.items,
//                 "ui:options": {
//                   ...obj.protocol.study_description.protocol_information.periods.items.arms.items["ui:options"],
//                   styles: {
//                     objectInnerContainer: {
//                       display: "flex",
//                       flexDirection: "column"
//                     },
//                     arrayItemContainer: {
//                       marginBottom: 6
//                     }
//                   }
//                 },
//                 title: {
//                   ...obj.protocol.study_description.protocol_information.periods.items.arms.items.title,
//                   "ui:options": { 
//                     ...obj.protocol.study_description.protocol_information.periods.items.arms.items.title["ui:options"],
//                     title: "        Title: ",
//                     styles: {
//                       ...previewStyles.flexRow
//                     }
//                   }
//                 },
//                 description: {
//                   ...obj.protocol.study_description.protocol_information.periods.items.arms.items.description,
//                   "ui:options": { 
//                     ...obj.protocol.study_description.protocol_information.periods.items.arms.items.description["ui:options"],
//                     title: "        Description: ",
//                     styles: {
//                       ...previewStyles.flexRow
//                     }
//                   }
//                 },
//               }
//             },

//           }
//         },
//       },
//       scientific_advice_and_pip: {
//         ...obj.protocol.study_description.scientific_advice_and_pip,
//         "ui:options": {
//           ...obj.protocol.study_description.scientific_advice_and_pip["ui:options"],
//         },

//         scientific_advice: {
//           ...obj.protocol.study_description.scientific_advice_and_pip.scientific_advice,
//           "ui:options": {
//             ...obj.protocol.study_description.scientific_advice_and_pip.scientific_advice["ui:options"],
//             showIndex: true,
//             arrayItemTitle: "Scientific Advice: ",
//             styles: {
//               arrayTitle
//             }
//           },
//           items: {
//             ...obj.protocol.study_description.scientific_advice_and_pip.scientific_advice.items,
//             "ui:options": {
//               ...obj.protocol.study_description.scientific_advice_and_pip.scientific_advice.items["ui:options"],
//               styles: {
//                 objectInnerContainer: {
//                   display: "flex",
//                   flexDirection: "column"
//                 },
//                 arrayItemContainer: {
//                   marginBottom: 6
//                 }
//               }
//             },
//             name: {
//               ...obj.protocol.study_description.scientific_advice_and_pip.scientific_advice.items.name,
//               "ui:options": { 
//                 ...obj.protocol.study_description.scientific_advice_and_pip.scientific_advice.items.name["ui:options"],
//                 title: "        Name: ",
//                 styles: {
//                   ...previewStyles.flexRow
//                 }
//               }
//             },
//             id: {
//               ...obj.protocol.study_description.scientific_advice_and_pip.scientific_advice.items.id,
//               "ui:options": { 
//                 ...obj.protocol.study_description.scientific_advice_and_pip.scientific_advice.items.id["ui:options"],
//                 title: "        Id: ",
//                 styles: {
//                   ...previewStyles.flexRow
//                 }
//               }
//             },
//             city: {
//               ...obj.protocol.study_description.scientific_advice_and_pip.scientific_advice.items.city,
//               "ui:options": { 
//                 ...obj.protocol.study_description.scientific_advice_and_pip.scientific_advice.items.city["ui:options"],
//                 title: "        City: ",
//                 styles: {
//                   ...previewStyles.flexRow
//                 }
//               }
//             },
//             country: {
//               ...obj.protocol.study_description.scientific_advice_and_pip.scientific_advice.items.country,
//               "ui:options": { 
//                 ...obj.protocol.study_description.scientific_advice_and_pip.scientific_advice.items.country["ui:options"],
//                 title: "        Country: ",
//                 styles: {
//                   ...previewStyles.flexRow
//                 }
//               }
//             },
//             address: {
//               ...obj.protocol.study_description.scientific_advice_and_pip.scientific_advice.items.address,
//               "ui:options": { 
//                 ...obj.protocol.study_description.scientific_advice_and_pip.scientific_advice.items.address["ui:options"],
//                 title: "        Address: ",
//                 styles: {
//                   ...previewStyles.flexRow
//                 }
//               }
//             },
//             postcode: {
//               ...obj.protocol.study_description.scientific_advice_and_pip.scientific_advice.items.postcode,
//               "ui:options": { 
//                 ...obj.protocol.study_description.scientific_advice_and_pip.scientific_advice.items.postcode["ui:options"],
//                 title: "        Postcode: ",
//                 styles: {
//                   ...previewStyles.flexRow
//                 }
//               }
//             },
//             phone: {
//               ...obj.protocol.study_description.scientific_advice_and_pip.scientific_advice.items.phone,
//               "ui:options": { 
//                 ...obj.protocol.study_description.scientific_advice_and_pip.scientific_advice.items.phone["ui:options"],
//                 title: "        Phone: ",
//                 styles: {
//                   ...previewStyles.flexRow
//                 }
//               }
//             },
//             email: {
//               ...obj.protocol.study_description.scientific_advice_and_pip.scientific_advice.items.email,
//               "ui:options": { 
//                 ...obj.protocol.study_description.scientific_advice_and_pip.scientific_advice.items.email["ui:options"],
//                 title: "        E-mail: ",
//                 styles: {
//                   ...previewStyles.flexRow
//                 }
//               }
//             },
//             // organisation_type: {
//             //   ...obj.protocol.study_description.scientific_advice_and_pip.scientific_advice.items.organisation_type,
//             //   "ui:options": { 
//             //     ...obj.protocol.study_description.scientific_advice_and_pip.scientific_advice.items.organisation_type["ui:options"],
//             //     title: "        Organisation type: ",
//             //     styles: {
//             //       ...previewStyles.flexRow
//             //     }
//             //   }
//             // },
//             // type: {
//             //   ...obj.protocol.study_description.scientific_advice_and_pip.scientific_advice.items.type,
//             //   "ui:options": { 
//             //     ...obj.protocol.study_description.scientific_advice_and_pip.scientific_advice.items.type["ui:options"],
//             //     title: "        Type: ",
//             //     styles: {
//             //       ...previewStyles.flexRow
//             //     }
//             //   }
//             // },
//             // status: {
//             //   ...obj.protocol.study_description.scientific_advice_and_pip.scientific_advice.items.status,
//             //   "ui:options": { 
//             //     ...obj.protocol.study_description.scientific_advice_and_pip.scientific_advice.items.status["ui:options"],
//             //     title: "        Status: ",
//             //     styles: {
//             //       ...previewStyles.flexRow
//             //     }
//             //   }
//             // },
//             // legal_representative: {
//             //   ...obj.protocol.study_description.scientific_advice_and_pip.scientific_advice.items.legal_representative,
//             //   "ui:options": { 
//             //     ...obj.protocol.study_description.scientific_advice_and_pip.scientific_advice.items.legal_representative["ui:options"],
//             //     title: "        Legal representative: ",
//             //     styles: {
//             //       ...previewStyles.flexRow
//             //     }
//             //   }
//             // },
//             // scientific_contact_point: {
//             //   ...obj.protocol.study_description.scientific_advice_and_pip.scientific_advice.items.scientific_contact_point,
//             //   "ui:options": { 
//             //     ...obj.protocol.study_description.scientific_advice_and_pip.scientific_advice.items.scientific_contact_point["ui:options"],
//             //     title: "        Scientific contact point: ",
//             //     styles: {
//             //       ...previewStyles.flexRow
//             //     }
//             //   }
//             // },
//             // public_contact_point: {
//             //   ...obj.protocol.study_description.scientific_advice_and_pip.scientific_advice.items.public_contact_point,
//             //   "ui:options": { 
//             //     ...obj.protocol.study_description.scientific_advice_and_pip.scientific_advice.items.public_contact_point["ui:options"],
//             //     title: "        Public contact point: ",
//             //     styles: {
//             //       ...previewStyles.flexRow
//             //     }
//             //   }
//             // },
//             // third_parties: {
//             //   ...obj.protocol.study_description.scientific_advice_and_pip.scientific_advice.items.third_parties,
//             //   "ui:options": { 
//             //     ...obj.protocol.study_description.scientific_advice_and_pip.scientific_advice.items.third_parties["ui:options"],
//             //     title: "        Third parties: ",
//             //     styles: {
//             //       ...previewStyles.flexRow
//             //     }
//             //   }
//             // },
//           }
//         },

//         paediatric_investigation_plan: {
//           ...obj.protocol.study_description.scientific_advice_and_pip.paediatric_investigation_plan,
//           "ui:options": {
//             ...obj.protocol.study_description.scientific_advice_and_pip.paediatric_investigation_plan["ui:options"],
//             showIndex: true,
//             arrayItemTitle: "Scientific Advice: ",
//             styles: {
//               arrayTitle
//             }
//           },
//           items: {
//             ...obj.protocol.study_description.scientific_advice_and_pip.paediatric_investigation_plan.items,
//             "ui:options": {
//               ...obj.protocol.study_description.scientific_advice_and_pip.paediatric_investigation_plan.items["ui:options"],
//               styles: {
//                 objectInnerContainer: {
//                   display: "flex",
//                   flexDirection: "column"
//                 },
//                 arrayItemContainer: {
//                   marginBottom: 6
//                 }
//               }
//             },
//             ema_paediatric_investigation_number: {
//               ...obj.protocol.study_description.scientific_advice_and_pip.paediatric_investigation_plan.items.ema_paediatric_investigation_number,
//               "ui:options": { 
//                 ...obj.protocol.study_description.scientific_advice_and_pip.paediatric_investigation_plan.items.ema_paediatric_investigation_number["ui:options"],
//                 title: "        EMA paediatric investigation number: ",
//                 styles: {
//                   ...previewStyles.flexRow
//                 }
//               }
//             },

//           }
//         },
//       },
//       associated_clinical_trials: {
//         ...obj.protocol.study_description.associated_clinical_trials,
//         "ui:options": {
//           ...obj.protocol.study_description.associated_clinical_trials["ui:options"],
//         },

//         associated_clinical_trials: {
//           ...obj.protocol.study_description.associated_clinical_trials.associated_clinical_trials,
//           "ui:options": {
//             ...obj.protocol.study_description.associated_clinical_trials.associated_clinical_trials["ui:options"],
//             showIndex: true,
//             arrayItemTitle: "Scientific Advice: ",
//             styles: {
//               arrayTitle
//             }
//           },
//           items: {
//             ...obj.protocol.study_description.associated_clinical_trials.associated_clinical_trials.items,
//             "ui:options": {
//               ...obj.protocol.study_description.associated_clinical_trials.associated_clinical_trials.items["ui:options"],
//               styles: {
//                 objectInnerContainer: {
//                   display: "flex",
//                   flexDirection: "column"
//                 },
//                 arrayItemContainer: {
//                   marginBottom: 6
//                 }
//               }
//             },
//             identifier: {
//               ...obj.protocol.study_description.associated_clinical_trials.associated_clinical_trials.items.identifier,
//               "ui:options": { 
//                 ...obj.protocol.study_description.associated_clinical_trials.associated_clinical_trials.items.identifier["ui:options"],
//                 title: "        Identifier: ",
//                 styles: {
//                   ...previewStyles.flexRow
//                 }
//               }
//             },
//             full_title: {
//               ...obj.protocol.study_description.associated_clinical_trials.associated_clinical_trials.items.full_title,
//               "ui:options": { 
//                 ...obj.protocol.study_description.associated_clinical_trials.associated_clinical_trials.items.full_title["ui:options"],
//                 title: "        Full title: ",
//                 styles: {
//                   ...previewStyles.flexRow
//                 }
//               }
//             },

//           }
//         },
//       },

//       sponsors: {
//         ...obj.protocol.study_description.sponsors,
//         "ui:options": {
//           ...obj.protocol.study_description.sponsors["ui:options"],
//         },

//         sponsors: {
//           ...obj.protocol.study_description.sponsors.sponsors,
//           "ui:options": {
//             ...obj.protocol.study_description.sponsors.sponsors["ui:options"],
//             showIndex: true,
//             arrayItemTitle: "Scientific Advice: ",
//             styles: {
//               arrayTitle
//             }
//           },
//           items: {
//             ...obj.protocol.study_description.sponsors.sponsors.items,
//             "ui:options": {
//               ...obj.protocol.study_description.sponsors.sponsors.items["ui:options"],
//               styles: {
//                 objectInnerContainer: {
//                   display: "flex",
//                   flexDirection: "column"
//                 },
//                 arrayItemContainer: {
//                   marginBottom: 6
//                 }
//               }
//             },
//             sponsor: {
//               ...obj.protocol.study_description.sponsors.sponsors.items.sponsor,
//               organisation_name: {
//                 ...obj.protocol.study_description.sponsors.sponsors.items.sponsor.organisation_name,
//                 "ui:options": {
//                   ...obj.protocol.study_description.sponsors.sponsors.items.sponsor.organisation_name["ui:options"],
//                   styles: {
//                     ...obj.protocol.study_description.sponsors.sponsors.items.sponsor.organisation_name["ui:options"].styles,
//                     ...previewStyles.boldLabel,
      
//                   }
//                 }
//               },
//               status: {
//                 ...obj.protocol.study_description.sponsors.sponsors.items.sponsor.status,
//                 "ui:options": {
//                   ...obj.protocol.study_description.sponsors.sponsors.items.sponsor.status["ui:options"],
//                   styles: {
//                     ...obj.protocol.study_description.sponsors.sponsors.items.sponsor.status["ui:options"].styles,
//                     ...previewStyles.boldLabel,
      
//                   }
//                 }
//               },
//               address: {
//                 ...obj.protocol.study_description.sponsors.sponsors.items.sponsor.address,
//                 "ui:options": {
//                   ...obj.protocol.study_description.sponsors.sponsors.items.sponsor.address["ui:options"],
//                   styles: {
//                     ...obj.protocol.study_description.sponsors.sponsors.items.sponsor.address["ui:options"].styles,
//                     ...previewStyles.boldLabel,
      
//                   }
//                 }
//               },
//               city: {
//                 ...obj.protocol.study_description.sponsors.sponsors.items.sponsor.city,
//                 "ui:options": {
//                   ...obj.protocol.study_description.sponsors.sponsors.items.sponsor.city["ui:options"],
//                   styles: {
//                     ...obj.protocol.study_description.sponsors.sponsors.items.sponsor.city["ui:options"].styles,
//                     ...previewStyles.boldLabel,
      
//                   }
//                 }
//               },
//               zip_code: {
//                 ...obj.protocol.study_description.sponsors.sponsors.items.sponsor.zip_code,
//                 "ui:options": {
//                   ...obj.protocol.study_description.sponsors.sponsors.items.sponsor.zip_code["ui:options"],
//                   styles: {
//                     ...obj.protocol.study_description.sponsors.sponsors.items.sponsor.zip_code["ui:options"].styles,
//                     ...previewStyles.boldLabel,
      
//                   }
//                 }
//               },
//               country: {
//                 ...obj.protocol.study_description.sponsors.sponsors.items.sponsor.country,
//                 "ui:options": {
//                   ...obj.protocol.study_description.sponsors.sponsors.items.sponsor.country["ui:options"],
//                   styles: {
//                     ...obj.protocol.study_description.sponsors.sponsors.items.sponsor.country["ui:options"].styles,
//                     ...previewStyles.boldLabel,
      
//                   }
//                 }
//               },
//               email: {
//                 ...obj.protocol.study_description.sponsors.sponsors.items.sponsor.email,
//                 "ui:options": {
//                   ...obj.protocol.study_description.sponsors.sponsors.items.sponsor.email["ui:options"],
//                   styles: {
//                     ...obj.protocol.study_description.sponsors.sponsors.items.sponsor.email["ui:options"].styles,
//                     ...previewStyles.boldLabel,
      
//                   }
//                 }
//               },
//               phone: {
//                 ...obj.protocol.study_description.sponsors.sponsors.items.sponsor.phone,
//                 "ui:options": {
//                   ...obj.protocol.study_description.sponsors.sponsors.items.sponsor.phone["ui:options"],
//                   styles: {
//                     ...obj.protocol.study_description.sponsors.sponsors.items.sponsor.phone["ui:options"].styles,
//                     ...previewStyles.boldLabel,
      
//                   }
//                 }
//               },
//             },
//             add_legal_representative: {
//               ...obj.protocol.study_description.sponsors.sponsors.items.add_legal_representative,
//               "ui:options": {
//                 ...obj.protocol.study_description.sponsors.sponsors.items.add_legal_representative["ui:options"],
//                 title: "Add legal representative: ",
//                 styles: {
//                   ...previewStyles.flexRow
//                 }
//               }
//             },
//             legal_representative: {
//               ...obj.protocol.study_description.sponsors.sponsors.items.legal_representative,
//               organisation_name: {
//                 ...obj.protocol.study_description.sponsors.sponsors.items.legal_representative.organisation_name,
//                 "ui:options": {
//                   ...obj.protocol.study_description.sponsors.sponsors.items.legal_representative.organisation_name["ui:options"],
//                   styles: {
//                     ...obj.protocol.study_description.sponsors.sponsors.items.legal_representative.organisation_name["ui:options"].styles,
//                     ...previewStyles.boldLabel,
      
//                   }
//                 }
//               },
//               status: {
//                 ...obj.protocol.study_description.sponsors.sponsors.items.legal_representative.status,
//                 "ui:options": {
//                   ...obj.protocol.study_description.sponsors.sponsors.items.legal_representative.status["ui:options"],
//                   styles: {
//                     ...obj.protocol.study_description.sponsors.sponsors.items.legal_representative.status["ui:options"].styles,
//                     ...previewStyles.boldLabel,
      
//                   }
//                 }
//               },
//               address: {
//                 ...obj.protocol.study_description.sponsors.sponsors.items.legal_representative.address,
//                 "ui:options": {
//                   ...obj.protocol.study_description.sponsors.sponsors.items.legal_representative.address["ui:options"],
//                   styles: {
//                     ...obj.protocol.study_description.sponsors.sponsors.items.legal_representative.address["ui:options"].styles,
//                     ...previewStyles.boldLabel,
      
//                   }
//                 }
//               },
//               city: {
//                 ...obj.protocol.study_description.sponsors.sponsors.items.legal_representative.city,
//                 "ui:options": {
//                   ...obj.protocol.study_description.sponsors.sponsors.items.legal_representative.city["ui:options"],
//                   styles: {
//                     ...obj.protocol.study_description.sponsors.sponsors.items.legal_representative.city["ui:options"].styles,
//                     ...previewStyles.boldLabel,
      
//                   }
//                 }
//               },
//               zip_code: {
//                 ...obj.protocol.study_description.sponsors.sponsors.items.legal_representative.zip_code,
//                 "ui:options": {
//                   ...obj.protocol.study_description.sponsors.sponsors.items.legal_representative.zip_code["ui:options"],
//                   styles: {
//                     ...obj.protocol.study_description.sponsors.sponsors.items.legal_representative.zip_code["ui:options"].styles,
//                     ...previewStyles.boldLabel,
      
//                   }
//                 }
//               },
//               country: {
//                 ...obj.protocol.study_description.sponsors.sponsors.items.legal_representative.country,
//                 "ui:options": {
//                   ...obj.protocol.study_description.sponsors.sponsors.items.legal_representative.country["ui:options"],
//                   styles: {
//                     ...obj.protocol.study_description.sponsors.sponsors.items.legal_representative.country["ui:options"].styles,
//                     ...previewStyles.boldLabel,
      
//                   }
//                 }
//               },
//               email: {
//                 ...obj.protocol.study_description.sponsors.sponsors.items.legal_representative.email,
//                 "ui:options": {
//                   ...obj.protocol.study_description.sponsors.sponsors.items.legal_representative.email["ui:options"],
//                   styles: {
//                     ...obj.protocol.study_description.sponsors.sponsors.items.legal_representative.email["ui:options"].styles,
//                     ...previewStyles.boldLabel,
      
//                   }
//                 }
//               },
//               phone: {
//                 ...obj.protocol.study_description.sponsors.sponsors.items.legal_representative.phone,
//                 "ui:options": {
//                   ...obj.protocol.study_description.sponsors.sponsors.items.legal_representative.phone["ui:options"],
//                   styles: {
//                     ...obj.protocol.study_description.sponsors.sponsors.items.legal_representative.phone["ui:options"].styles,
//                     ...previewStyles.boldLabel,
      
//                   }
//                 }
//               },
//               contact: {
//                 ...obj.protocol.study_description.sponsors.sponsors.items.legal_representative.contact,
//                 first_name: {
//                   ...obj.protocol.study_description.sponsors.sponsors.items.legal_representative.contact.first_name,
//                   "ui:options": {
//                     ...obj.protocol.study_description.sponsors.sponsors.items.legal_representative.contact.first_name["ui:options"],
//                     styles: {
//                       ...obj.protocol.study_description.sponsors.sponsors.items.legal_representative.contact.first_name["ui:options"].styles,
//                       ...previewStyles.boldLabel,
        
//                     }
//                   }
//                 },
//                 last_name: {
//                   ...obj.protocol.study_description.sponsors.sponsors.items.legal_representative.contact.last_name,
//                   "ui:options": {
//                     ...obj.protocol.study_description.sponsors.sponsors.items.legal_representative.contact.last_name["ui:options"],
//                     styles: {
//                       ...obj.protocol.study_description.sponsors.sponsors.items.legal_representative.contact.last_name["ui:options"].styles,
//                       ...previewStyles.boldLabel,
        
//                     }
//                   }
//                 },
//                 email: {
//                   ...obj.protocol.study_description.sponsors.sponsors.items.legal_representative.contact.email,
//                   "ui:options": {
//                     ...obj.protocol.study_description.sponsors.sponsors.items.legal_representative.contact.email["ui:options"],
//                     styles: {
//                       ...obj.protocol.study_description.sponsors.sponsors.items.legal_representative.contact.email["ui:options"].styles,
//                       ...previewStyles.boldLabel,
        
//                     }
//                   }
//                 },
//                 phone: {
//                   ...obj.protocol.study_description.sponsors.sponsors.items.legal_representative.contact.phone,
//                   "ui:options": {
//                     ...obj.protocol.study_description.sponsors.sponsors.items.legal_representative.contact.phone["ui:options"],
//                     styles: {
//                       ...obj.protocol.study_description.sponsors.sponsors.items.legal_representative.contact.phone["ui:options"].styles,
//                       ...previewStyles.boldLabel,
        
//                     }
//                   }
//                 },
//               }
//             },
//             add_scientific_contact_point: {
//               ...obj.protocol.study_description.sponsors.sponsors.items.add_scientific_contact_point,
//               "ui:options": {
//                 ...obj.protocol.study_description.sponsors.sponsors.items.add_scientific_contact_point["ui:options"],
//                 title: "Add scientific contact point: ",
//                 styles: {
//                   ...previewStyles.flexRow
//                 }
//               }
//             },
//             scientific_contact_point: {
//               ...obj.protocol.study_description.sponsors.sponsors.items.scientific_contact_point,
//               organisation_name: {
//                 ...obj.protocol.study_description.sponsors.sponsors.items.scientific_contact_point.organisation_name,
//                 "ui:options": {
//                   ...obj.protocol.study_description.sponsors.sponsors.items.scientific_contact_point.organisation_name["ui:options"],
//                   styles: {
//                     ...obj.protocol.study_description.sponsors.sponsors.items.scientific_contact_point.organisation_name["ui:options"].styles,
//                     ...previewStyles.boldLabel,
      
//                   }
//                 }
//               },
//               functional_contact_point_name: {
//                 ...obj.protocol.study_description.sponsors.sponsors.items.scientific_contact_point.functional_contact_point_name,
//                 "ui:options": {
//                   ...obj.protocol.study_description.sponsors.sponsors.items.scientific_contact_point.functional_contact_point_name["ui:options"],
//                   styles: {
//                     ...obj.protocol.study_description.sponsors.sponsors.items.scientific_contact_point.functional_contact_point_name["ui:options"].styles,
//                     ...previewStyles.boldLabel,
      
//                   }
//                 }
//               },
//               email: {
//                 ...obj.protocol.study_description.sponsors.sponsors.items.scientific_contact_point.email,
//                 "ui:options": {
//                   ...obj.protocol.study_description.sponsors.sponsors.items.scientific_contact_point.email["ui:options"],
//                   styles: {
//                     ...obj.protocol.study_description.sponsors.sponsors.items.scientific_contact_point.email["ui:options"].styles,
//                     ...previewStyles.boldLabel,
      
//                   }
//                 }
//               },
//               phone: {
//                 ...obj.protocol.study_description.sponsors.sponsors.items.scientific_contact_point.phone,
//                 "ui:options": {
//                   ...obj.protocol.study_description.sponsors.sponsors.items.scientific_contact_point.phone["ui:options"],
//                   styles: {
//                     ...obj.protocol.study_description.sponsors.sponsors.items.scientific_contact_point.phone["ui:options"].styles,
//                     ...previewStyles.boldLabel,
      
//                   }
//                 }
//               },
//             },
//             add_public_contact_point: {
//               ...obj.protocol.study_description.sponsors.sponsors.items.add_public_contact_point,
//               "ui:options": {
//                 ...obj.protocol.study_description.sponsors.sponsors.items.add_public_contact_point["ui:options"],
//                 title: "Add public contact point: ",
//                 styles: {
//                   ...previewStyles.flexRow
//                 }
//               }
//             },
//             public_contact_point: {
//               ...obj.protocol.study_description.sponsors.sponsors.items.public_contact_point,
//               organisation_name: {
//                 ...obj.protocol.study_description.sponsors.sponsors.items.public_contact_point.organisation_name,
//                 "ui:options": {
//                   ...obj.protocol.study_description.sponsors.sponsors.items.public_contact_point.organisation_name["ui:options"],
//                   styles: {
//                     ...obj.protocol.study_description.sponsors.sponsors.items.public_contact_point.organisation_name["ui:options"].styles,
//                     ...previewStyles.boldLabel,
      
//                   }
//                 }
//               },
//               functional_contact_point_name: {
//                 ...obj.protocol.study_description.sponsors.sponsors.items.public_contact_point.functional_contact_point_name,
//                 "ui:options": {
//                   ...obj.protocol.study_description.sponsors.sponsors.items.public_contact_point.functional_contact_point_name["ui:options"],
//                   styles: {
//                     ...obj.protocol.study_description.sponsors.sponsors.items.public_contact_point.functional_contact_point_name["ui:options"].styles,
//                     ...previewStyles.boldLabel,
      
//                   }
//                 }
//               },
//               email: {
//                 ...obj.protocol.study_description.sponsors.sponsors.items.public_contact_point.email,
//                 "ui:options": {
//                   ...obj.protocol.study_description.sponsors.sponsors.items.public_contact_point.email["ui:options"],
//                   styles: {
//                     ...obj.protocol.study_description.sponsors.sponsors.items.public_contact_point.email["ui:options"].styles,
//                     ...previewStyles.boldLabel,
      
//                   }
//                 }
//               },
//               phone: {
//                 ...obj.protocol.study_description.sponsors.sponsors.items.public_contact_point.phone,
//                 "ui:options": {
//                   ...obj.protocol.study_description.sponsors.sponsors.items.public_contact_point.phone["ui:options"],
//                   styles: {
//                     ...obj.protocol.study_description.sponsors.sponsors.items.public_contact_point.phone["ui:options"].styles,
//                     ...previewStyles.boldLabel,
      
//                   }
//                 }
//               },
//             },
//             third_party: {
//               ...obj.protocol.study_description.sponsors.sponsors.items.third_party,
//               "ui:options": {
//                 ...obj.protocol.study_description.sponsors.sponsors.items.third_party["ui:options"],
//                 showIndex: true,
//                 arrayItemTitle: "Scientific Advice: ",
//                 styles: {
//                   arrayTitle
//                 }
//               },
//               items: {
//                 ...obj.protocol.study_description.sponsors.sponsors.items.third_party.items,
//                 "ui:options": {
//                   ...obj.protocol.study_description.sponsors.sponsors.items["ui:options"],
//                   styles: {
//                     objectInnerContainer: {
//                       display: "flex",
//                       flexDirection: "column"
//                     },
//                     arrayItemContainer: {
//                       marginBottom: 6
//                     }
//                   }
//                 },
//                 country: {
//                   ...obj.protocol.study_description.sponsors.sponsors.items.third_party.items.country,
//                   "ui:options": {
//                     ...obj.protocol.study_description.sponsors.sponsors.items.third_party.items.country["ui:options"],
//                     styles: {
//                       ...obj.protocol.study_description.sponsors.sponsors.items.third_party.items.country["ui:options"].styles,
//                       ...previewStyles.boldLabel,
        
//                     }
//                   }
//                 },
//                 address: {
//                   ...obj.protocol.study_description.sponsors.sponsors.items.third_party.items.address,
//                   "ui:options": {
//                     ...obj.protocol.study_description.sponsors.sponsors.items.third_party.items.address["ui:options"],
//                     styles: {
//                       ...obj.protocol.study_description.sponsors.sponsors.items.third_party.items.address["ui:options"].styles,
//                       ...previewStyles.boldLabel,
        
//                     }
//                   }
//                 },
//                 city: {
//                   ...obj.protocol.study_description.sponsors.sponsors.items.third_party.items.city,
//                   "ui:options": {
//                     ...obj.protocol.study_description.sponsors.sponsors.items.third_party.items.city["ui:options"],
//                     styles: {
//                       ...obj.protocol.study_description.sponsors.sponsors.items.third_party.items.city["ui:options"].styles,
//                       ...previewStyles.boldLabel,
        
//                     }
//                   }
//                 },
//                 zip_code: {
//                   ...obj.protocol.study_description.sponsors.sponsors.items.third_party.items.zip_code,
//                   "ui:options": {
//                     ...obj.protocol.study_description.sponsors.sponsors.items.third_party.items.zip_code["ui:options"],
//                     styles: {
//                       ...obj.protocol.study_description.sponsors.sponsors.items.third_party.items.zip_code["ui:options"].styles,
//                       ...previewStyles.boldLabel,
        
//                     }
//                   }
//                 },
//                 phone: {
//                   ...obj.protocol.study_description.sponsors.sponsors.items.third_party.items.phone,
//                   "ui:options": {
//                     ...obj.protocol.study_description.sponsors.sponsors.items.third_party.items.phone["ui:options"],
//                     styles: {
//                       ...obj.protocol.study_description.sponsors.sponsors.items.third_party.items.phone["ui:options"].styles,
//                       ...previewStyles.boldLabel,
        
//                     }
//                   }
//                 },
//                 email: {
//                   ...obj.protocol.study_description.sponsors.sponsors.items.third_party.items.email,
//                   "ui:options": {
//                     ...obj.protocol.study_description.sponsors.sponsors.items.third_party.items.email["ui:options"],
//                     styles: {
//                       ...obj.protocol.study_description.sponsors.sponsors.items.third_party.items.email["ui:options"].styles,
//                       ...previewStyles.boldLabel,
        
//                     }
//                   }
//                 },
//                 duties: {
//                   ...obj.protocol.study_description.sponsors.sponsors.items.third_party.items.duties,
//                   "ui:options": {
//                     ...obj.protocol.study_description.sponsors.sponsors.items.third_party.items.duties["ui:options"],
//                     styles: {
//                       ...obj.protocol.study_description.sponsors.sponsors.items.third_party.items.duties["ui:options"].styles,
//                       ...previewStyles.boldLabel,
        
//                     }
//                   }
//                 },
//                 other_duties: {
//                   ...obj.protocol.study_description.sponsors.sponsors.items.third_party.items.other_duties,
//                   "ui:options": {
//                     ...obj.protocol.study_description.sponsors.sponsors.items.third_party.items.other_duties["ui:options"],
//                     styles: {
//                       ...obj.protocol.study_description.sponsors.sponsors.items.third_party.items.other_duties["ui:options"].styles,
//                       ...previewStyles.boldLabel,
        
//                     }
//                   }
//                 },
                
//               }
//             }
//           }
//         },
//         contact_point_for_union: {
//           ...obj.protocol.study_description.sponsors.contact_point_for_union,
//           organisation_name: {
//             ...obj.protocol.study_description.sponsors.contact_point_for_union.organisation_name,
//             "ui:options": {
//               ...obj.protocol.study_description.sponsors.contact_point_for_union.organisation_name["ui:options"],
//               styles: {
//                 ...obj.protocol.study_description.sponsors.contact_point_for_union.organisation_name["ui:options"].styles,
//                 ...previewStyles.boldLabel,
//               }
//             }
//           },
//           address_1: {
//             ...obj.protocol.study_description.sponsors.contact_point_for_union.address_1,
//             "ui:options": {
//               ...obj.protocol.study_description.sponsors.contact_point_for_union.address_1["ui:options"],
//               styles: {
//                 ...obj.protocol.study_description.sponsors.contact_point_for_union.address_1["ui:options"].styles,
//                 ...previewStyles.boldLabel,
//               }
//             }
//           },
//           address_2: {
//             ...obj.protocol.study_description.sponsors.contact_point_for_union.address_2,
//             "ui:options": {
//               ...obj.protocol.study_description.sponsors.contact_point_for_union.address_2["ui:options"],
//               styles: {
//                 ...obj.protocol.study_description.sponsors.contact_point_for_union.address_2["ui:options"].styles,
//                 ...previewStyles.boldLabel,
//               }
//             }
//           },
//           address_3: {
//             ...obj.protocol.study_description.sponsors.contact_point_for_union.address_3,
//             "ui:options": {
//               ...obj.protocol.study_description.sponsors.contact_point_for_union.address_3["ui:options"],
//               styles: {
//                 ...obj.protocol.study_description.sponsors.contact_point_for_union.address_3["ui:options"].styles,
//                 ...previewStyles.boldLabel,
//               }
//             }
//           },
//           address_4: {
//             ...obj.protocol.study_description.sponsors.contact_point_for_union.address_4,
//             "ui:options": {
//               ...obj.protocol.study_description.sponsors.contact_point_for_union.address_4["ui:options"],
//               styles: {
//                 ...obj.protocol.study_description.sponsors.contact_point_for_union.address_4["ui:options"].styles,
//                 ...previewStyles.boldLabel,
//               }
//             }
//           },
//           city: {
//             ...obj.protocol.study_description.sponsors.contact_point_for_union.city,
//             "ui:options": {
//               ...obj.protocol.study_description.sponsors.contact_point_for_union.city["ui:options"],
//               styles: {
//                 ...obj.protocol.study_description.sponsors.contact_point_for_union.city["ui:options"].styles,
//                 ...previewStyles.boldLabel,
//               }
//             }
//           },
//           zip_code: {
//             ...obj.protocol.study_description.sponsors.contact_point_for_union.zip_code,
//             "ui:options": {
//               ...obj.protocol.study_description.sponsors.contact_point_for_union.zip_code["ui:options"],
//               styles: {
//                 ...obj.protocol.study_description.sponsors.contact_point_for_union.zip_code["ui:options"].styles,
//                 ...previewStyles.boldLabel,
//               }
//             }
//           },
//           country: {
//             ...obj.protocol.study_description.sponsors.contact_point_for_union.country,
//             "ui:options": {
//               ...obj.protocol.study_description.sponsors.contact_point_for_union.country["ui:options"],
//               styles: {
//                 ...obj.protocol.study_description.sponsors.contact_point_for_union.country["ui:options"].styles,
//                 ...previewStyles.boldLabel,
//               }
//             }
//           },
//           functional_contact_point_name: {
//             ...obj.protocol.study_description.sponsors.contact_point_for_union.functional_contact_point_name,
//             "ui:options": {
//               ...obj.protocol.study_description.sponsors.contact_point_for_union.functional_contact_point_name["ui:options"],
//               styles: {
//                 ...obj.protocol.study_description.sponsors.contact_point_for_union.functional_contact_point_name["ui:options"].styles,
//                 ...previewStyles.boldLabel,
//               }
//             }
//           },
//           contact: {
//             ...obj.protocol.study_description.sponsors.contact_point_for_union.contact,
//             first_name: {
//               ...obj.protocol.study_description.sponsors.contact_point_for_union.contact.first_name,
//               "ui:options": {
//                 ...obj.protocol.study_description.sponsors.contact_point_for_union.contact.first_name["ui:options"],
//                 styles: {
//                   ...obj.protocol.study_description.sponsors.contact_point_for_union.contact.first_name["ui:options"].styles,
//                   ...previewStyles.boldLabel,
    
//                 }
//               }
//             },
//             last_name: {
//               ...obj.protocol.study_description.sponsors.contact_point_for_union.contact.last_name,
//               "ui:options": {
//                 ...obj.protocol.study_description.sponsors.contact_point_for_union.contact.last_name["ui:options"],
//                 styles: {
//                   ...obj.protocol.study_description.sponsors.contact_point_for_union.contact.last_name["ui:options"].styles,
//                   ...previewStyles.boldLabel,
    
//                 }
//               }
//             },
//             email: {
//               ...obj.protocol.study_description.sponsors.contact_point_for_union.contact.email,
//               "ui:options": {
//                 ...obj.protocol.study_description.sponsors.contact_point_for_union.contact.email["ui:options"],
//                 styles: {
//                   ...obj.protocol.study_description.sponsors.contact_point_for_union.contact.email["ui:options"].styles,
//                   ...previewStyles.boldLabel,
    
//                 }
//               }
//             },
//             phone: {
//               ...obj.protocol.study_description.sponsors.contact_point_for_union.contact.phone,
//               "ui:options": {
//                 ...obj.protocol.study_description.sponsors.contact_point_for_union.contact.phone["ui:options"],
//                 styles: {
//                   ...obj.protocol.study_description.sponsors.contact_point_for_union.contact.phone["ui:options"].styles,
//                   ...previewStyles.boldLabel,
    
//                 }
//               }
//             },
//           }
//         },
//       },

//       products: {
//         ...obj.protocol.study_description.products,
//         "ui:options": {
//           ...obj.protocol.study_description.products["ui:options"],
//         },

//         products: {
//           ...obj.protocol.study_description.products.products,
//           "ui:options": {
//             ...obj.protocol.study_description.products.products["ui:options"],
//             showIndex: true,
//             arrayItemTitle: "Products: ",
//             styles: {
//               arrayTitle
//             }
//           },
//           items: {
//             ...obj.protocol.study_description.products.products.items,
//             "ui:options": {
//               ...obj.protocol.study_description.products.products.items["ui:options"],
//               styles: {
//                 objectInnerContainer: {
//                   display: "flex",
//                   flexDirection: "column"
//                 },
//                 arrayItemContainer: {
//                   marginBottom: 6
//                 }
//               }
//             },
//             role: {
//               ...obj.protocol.study_description.products.products.items.role,
//               "ui:options": {
//                 ...obj.protocol.study_description.products.products.items.role["ui:options"],
//                 styles: {
//                   ...obj.protocol.study_description.products.products.items.role["ui:options"].styles,
//                   ...previewStyles.boldLabel,
//                 }
//               }
//             },
//             products: {
//               ...obj.protocol.study_description.products.products.items.products,
//               "ui:options": {
//                 ...obj.protocol.study_description.products.products.items.products["ui:options"],
//                 showIndex: true,
//                 arrayItemTitle: "Products: ",
//                 styles: {
//                   arrayTitle
//                 }
//               },
//               items: {
//                 ...obj.protocol.study_description.products.products.items.products.items,
//                 "ui:options": {
//                   ...obj.protocol.study_description.products.products.items.products.items["ui:options"],
//                   styles: {
//                     objectInnerContainer: {
//                       display: "flex",
//                       flexDirection: "column"
//                     },
//                     arrayItemContainer: {
//                       marginBottom: 6
//                     }
//                   }
//                 },
//                 product: {
//                   ...obj.protocol.study_description.products.products.items.products.items.product,
//                   "ui:options": {
//                     ...obj.protocol.study_description.products.products.items.products.items.product["ui:options"],
//                     styles: {
//                       ...obj.protocol.study_description.products.products.items.products.items.product["ui:options"].styles,
//                       ...previewStyles.boldLabel,
        
//                     }
//                   }
//                 },
//                 eu_mp_number: {
//                   ...obj.protocol.study_description.products.products.items.products.items.eu_mp_number,
//                   "ui:options": {
//                     ...obj.protocol.study_description.products.products.items.products.items.eu_mp_number["ui:options"],
//                     styles: {
//                       ...obj.protocol.study_description.products.products.items.products.items.eu_mp_number["ui:options"].styles,
//                       ...previewStyles.boldLabel,
        
//                     }
//                   }
//                 },
//                 pharmaceutical_form: {
//                   ...obj.protocol.study_description.products.products.items.products.items.pharmaceutical_form,
//                   "ui:options": {
//                     ...obj.protocol.study_description.products.products.items.products.items.pharmaceutical_form["ui:options"],
//                     styles: {
//                       ...obj.protocol.study_description.products.products.items.products.items.pharmaceutical_form["ui:options"].styles,
//                       ...previewStyles.boldLabel,
        
//                     }
//                   }
//                 },
//                 marketing_authorisation_number: {
//                   ...obj.protocol.study_description.products.products.items.products.items.marketing_authorisation_number,
//                   "ui:options": {
//                     ...obj.protocol.study_description.products.products.items.products.items.marketing_authorisation_number["ui:options"],
//                     styles: {
//                       ...obj.protocol.study_description.products.products.items.products.items.marketing_authorisation_number["ui:options"].styles,
//                       ...previewStyles.boldLabel,
        
//                     }
//                   }
//                 },
//                 strength: {
//                   ...obj.protocol.study_description.products.products.items.products.items.strength,
//                   "ui:options": {
//                     ...obj.protocol.study_description.products.products.items.products.items.strength["ui:options"],
//                     styles: {
//                       ...obj.protocol.study_description.products.products.items.products.items.strength["ui:options"].styles,
//                       ...previewStyles.boldLabel,
        
//                     }
//                   }
//                 },
//                 name_of_product: {
//                   ...obj.protocol.study_description.products.products.items.products.items.name_of_product,
//                   "ui:options": {
//                     ...obj.protocol.study_description.products.products.items.products.items.name_of_product["ui:options"],
//                     styles: {
//                       ...obj.protocol.study_description.products.products.items.products.items.name_of_product["ui:options"].styles,
//                       ...previewStyles.boldLabel,
        
//                     }
//                   }
//                 },
//                 active_substance_name: {
//                   ...obj.protocol.study_description.products.products.items.products.items.active_substance_name,
//                   "ui:options": {
//                     ...obj.protocol.study_description.products.products.items.products.items.active_substance_name["ui:options"],
//                     styles: {
//                       ...obj.protocol.study_description.products.products.items.products.items.active_substance_name["ui:options"].styles,
//                       ...previewStyles.boldLabel,
        
//                     }
//                   }
//                 },
//                 eu_substance_number: {
//                   ...obj.protocol.study_description.products.products.items.products.items.eu_substance_number,
//                   "ui:options": {
//                     ...obj.protocol.study_description.products.products.items.products.items.eu_substance_number["ui:options"],
//                     styles: {
//                       ...obj.protocol.study_description.products.products.items.products.items.eu_substance_number["ui:options"].styles,
//                       ...previewStyles.boldLabel,
        
//                     }
//                   }
//                 },
//                 atc_code: {
//                   ...obj.protocol.study_description.products.products.items.products.items.atc_code,
//                   "ui:options": {
//                     ...obj.protocol.study_description.products.products.items.products.items.atc_code["ui:options"],
//                     styles: {
//                       ...obj.protocol.study_description.products.products.items.products.items.atc_code["ui:options"].styles,
//                       ...previewStyles.boldLabel,
        
//                     }
//                   }
//                 },
//                 atc_name: {
//                   ...obj.protocol.study_description.products.products.items.products.items.atc_name,
//                   "ui:options": {
//                     ...obj.protocol.study_description.products.products.items.products.items.atc_name["ui:options"],
//                     styles: {
//                       ...obj.protocol.study_description.products.products.items.products.items.atc_name["ui:options"].styles,
//                       ...previewStyles.boldLabel,
        
//                     }
//                   }
//                 },
//                 name: {
//                   ...obj.protocol.study_description.products.products.items.products.items.name,
//                   "ui:options": {
//                     ...obj.protocol.study_description.products.products.items.products.items.name["ui:options"],
//                     styles: {
//                       ...obj.protocol.study_description.products.products.items.products.items.name["ui:options"].styles,
//                       ...previewStyles.boldLabel,
        
//                     }
//                   }
//                 },
//                 excluded_msc: {
//                   ...obj.protocol.study_description.products.products.items.products.items.excluded_msc,
//                   "ui:options": {
//                     ...obj.protocol.study_description.products.products.items.products.items.excluded_msc["ui:options"],
//                     styles: {
//                       ...obj.protocol.study_description.products.products.items.products.items.excluded_msc["ui:options"].styles,
//                       ...previewStyles.boldLabel,
        
//                     }
//                   }
//                 },
//                 product_properties: {
//                   ...obj.protocol.study_description.products.products.items.products.items.product_properties,
//                   medicinal_product_details: {
//                     ...obj.protocol.study_description.products.products.items.products.items.product_properties.medicinal_product_details,
//                     is_specific_paediatric_formulation: {
//                       ...obj.protocol.study_description.products.products.items.products.items.product_properties.medicinal_product_details.is_specific_paediatric_formulation,
//                       "ui:options": {
//                         ...obj.protocol.study_description.products.products.items.products.items.product_properties.medicinal_product_details.is_specific_paediatric_formulation["ui:options"],
//                         styles: {
//                           ...obj.protocol.study_description.products.products.items.products.items.product_properties.medicinal_product_details.is_specific_paediatric_formulation["ui:options"].styles,
//                           ...previewStyles.boldLabel,
            
//                         }
//                       }
//                     },
//                     sponsors_product_code: {
//                       ...obj.protocol.study_description.products.products.items.products.items.product_properties.medicinal_product_details.sponsors_product_code,
//                       "ui:options": {
//                         ...obj.protocol.study_description.products.products.items.products.items.product_properties.medicinal_product_details.sponsors_product_code["ui:options"],
//                         styles: {
//                           ...obj.protocol.study_description.products.products.items.products.items.product_properties.medicinal_product_details.sponsors_product_code["ui:options"].styles,
//                           ...previewStyles.boldLabel,
            
//                         }
//                       }
//                     },
//                   },
//                   product_characteristics: {
//                     ...obj.protocol.study_description.products.products.items.products.items.product_properties.product_characteristics,
//                     medicinal_product_characteristics: {
//                       ...obj.protocol.study_description.products.products.items.products.items.product_properties.product_characteristics.medicinal_product_characteristics,
//                       "ui:options": {
//                         ...obj.protocol.study_description.products.products.items.products.items.product_properties.product_characteristics.medicinal_product_characteristics["ui:options"],
//                         styles: {
//                           ...obj.protocol.study_description.products.products.items.products.items.product_properties.product_characteristics.medicinal_product_characteristics["ui:options"].styles,
//                           ...previewStyles.boldLabel,
            
//                         }
//                       }
//                     },
//                     other_medical_product: {
//                       ...obj.protocol.study_description.products.products.items.products.items.product_properties.product_characteristics.other_medical_product,
//                       "ui:options": {
//                         ...obj.protocol.study_description.products.products.items.products.items.product_properties.product_characteristics.other_medical_product["ui:options"],
//                         styles: {
//                           ...obj.protocol.study_description.products.products.items.products.items.product_properties.product_characteristics.other_medical_product["ui:options"].styles,
//                           ...previewStyles.boldLabel,
            
//                         }
//                       }
//                     },
//                   },
//                   dosage_and_administration_details: {
//                     ...obj.protocol.study_description.products.products.items.products.items.product_properties.dosage_and_administration_details,
//                     route_of_administration: {
//                       ...obj.protocol.study_description.products.products.items.products.items.product_properties.dosage_and_administration_details.route_of_administration,
//                       "ui:options": {
//                         ...obj.protocol.study_description.products.products.items.products.items.product_properties.dosage_and_administration_details.route_of_administration["ui:options"],
//                         styles: {
//                           ...obj.protocol.study_description.products.products.items.products.items.product_properties.dosage_and_administration_details.route_of_administration["ui:options"].styles,
//                           ...previewStyles.boldLabel,
            
//                         }
//                       }
//                     },
//                     maximum_duration_of_treatmeant: {
//                       ...obj.protocol.study_description.products.products.items.products.items.product_properties.dosage_and_administration_details.maximum_duration_of_treatmeant,
//                       "ui:options": {
//                         ...obj.protocol.study_description.products.products.items.products.items.product_properties.dosage_and_administration_details.maximum_duration_of_treatmeant["ui:options"],
//                         styles: {
//                           ...obj.protocol.study_description.products.products.items.products.items.product_properties.dosage_and_administration_details.maximum_duration_of_treatmeant["ui:options"].styles,
//                           ...previewStyles.boldLabel,
            
//                         }
//                       }
//                     },
//                     maximum_daily_dose_allowed: {
//                       ...obj.protocol.study_description.products.products.items.products.items.product_properties.dosage_and_administration_details.maximum_daily_dose_allowed,
//                       "ui:options": {
//                         ...obj.protocol.study_description.products.products.items.products.items.product_properties.dosage_and_administration_details.maximum_daily_dose_allowed["ui:options"],
//                         styles: {
//                           ...obj.protocol.study_description.products.products.items.products.items.product_properties.dosage_and_administration_details.maximum_daily_dose_allowed["ui:options"].styles,
//                           ...previewStyles.boldLabel,
            
//                         }
//                       }
//                     },
//                     daily_dose_unit_of_measure: {
//                       ...obj.protocol.study_description.products.products.items.products.items.product_properties.dosage_and_administration_details.daily_dose_unit_of_measure,
//                       "ui:options": {
//                         ...obj.protocol.study_description.products.products.items.products.items.product_properties.dosage_and_administration_details.daily_dose_unit_of_measure["ui:options"],
//                         styles: {
//                           ...obj.protocol.study_description.products.products.items.products.items.product_properties.dosage_and_administration_details.daily_dose_unit_of_measure["ui:options"].styles,
//                           ...previewStyles.boldLabel,
            
//                         }
//                       }
//                     },
//                     maximum_total_dose_allowed: {
//                       ...obj.protocol.study_description.products.products.items.products.items.product_properties.dosage_and_administration_details.maximum_total_dose_allowed,
//                       "ui:options": {
//                         ...obj.protocol.study_description.products.products.items.products.items.product_properties.dosage_and_administration_details.maximum_total_dose_allowed["ui:options"],
//                         styles: {
//                           ...obj.protocol.study_description.products.products.items.products.items.product_properties.dosage_and_administration_details.maximum_total_dose_allowed["ui:options"].styles,
//                           ...previewStyles.boldLabel,
            
//                         }
//                       }
//                     },
//                     total_dose_use_of_measure: {
//                       ...obj.protocol.study_description.products.products.items.products.items.product_properties.dosage_and_administration_details.total_dose_use_of_measure,
//                       "ui:options": {
//                         ...obj.protocol.study_description.products.products.items.products.items.product_properties.dosage_and_administration_details.total_dose_use_of_measure["ui:options"],
//                         styles: {
//                           ...obj.protocol.study_description.products.products.items.products.items.product_properties.dosage_and_administration_details.total_dose_use_of_measure["ui:options"].styles,
//                           ...previewStyles.boldLabel,
            
//                         }
//                       }
//                     },
//                   },
//                   information_about_modification_of_medicinal_product: {
//                     ...obj.protocol.study_description.products.products.items.products.items.product_properties.information_about_modification_of_medicinal_product,
//                     is_product_modified_in_relation_to_marketing_authorisation: {
//                       ...obj.protocol.study_description.products.products.items.products.items.product_properties.information_about_modification_of_medicinal_product.is_product_modified_in_relation_to_marketing_authorisation,
//                       "ui:options": {
//                         ...obj.protocol.study_description.products.products.items.products.items.product_properties.information_about_modification_of_medicinal_product.is_product_modified_in_relation_to_marketing_authorisation["ui:options"],
//                         styles: {
//                           ...obj.protocol.study_description.products.products.items.products.items.product_properties.information_about_modification_of_medicinal_product.is_product_modified_in_relation_to_marketing_authorisation["ui:options"].styles,
//                           ...previewStyles.boldLabel,
            
//                         }
//                       }
//                     },
//                     description_of_modification: {
//                       ...obj.protocol.study_description.products.products.items.products.items.product_properties.information_about_modification_of_medicinal_product.description_of_modification,
//                       "ui:options": {
//                         ...obj.protocol.study_description.products.products.items.products.items.product_properties.information_about_modification_of_medicinal_product.description_of_modification["ui:options"],
//                         styles: {
//                           ...obj.protocol.study_description.products.products.items.products.items.product_properties.information_about_modification_of_medicinal_product.description_of_modification["ui:options"].styles,
//                           ...previewStyles.boldLabel,
            
//                         }
//                       }
//                     },
//                   },
//                   orphan_designation: {
//                     ...obj.protocol.study_description.products.products.items.products.items.product_properties.orphan_designation,
//                     product_have_orphan_drug_description: {
//                       ...obj.protocol.study_description.products.products.items.products.items.product_properties.orphan_designation.product_have_orphan_drug_description,
//                       "ui:options": {
//                         ...obj.protocol.study_description.products.products.items.products.items.product_properties.orphan_designation.product_have_orphan_drug_description["ui:options"],
//                         styles: {
//                           ...obj.protocol.study_description.products.products.items.products.items.product_properties.orphan_designation.product_have_orphan_drug_description["ui:options"].styles,
//                           ...previewStyles.boldLabel,
            
//                         }
//                       }
//                     },
//                     designation_number_for_orphan_drug: {
//                       ...obj.protocol.study_description.products.products.items.products.items.product_properties.orphan_designation.designation_number_for_orphan_drug,
//                       "ui:options": {
//                         ...obj.protocol.study_description.products.products.items.products.items.product_properties.orphan_designation.designation_number_for_orphan_drug["ui:options"],
//                         styles: {
//                           ...obj.protocol.study_description.products.products.items.products.items.product_properties.orphan_designation.designation_number_for_orphan_drug["ui:options"].styles,
//                           ...previewStyles.boldLabel,
            
//                         }
//                       }
//                     },
//                   },
//                   active_substance: {
//                     ...obj.protocol.study_description.products.products.items.products.items.product_properties.active_substance,
//                     active_substance_other_descriptive_name: {
//                       ...obj.protocol.study_description.products.products.items.products.items.product_properties.active_substance.active_substance_other_descriptive_name,
//                       "ui:options": {
//                         ...obj.protocol.study_description.products.products.items.products.items.product_properties.active_substance.active_substance_other_descriptive_name["ui:options"],
//                         styles: {
//                           ...obj.protocol.study_description.products.products.items.products.items.product_properties.active_substance.active_substance_other_descriptive_name["ui:options"].styles,
//                           ...previewStyles.boldLabel,
            
//                         }
//                       }
//                     },
//                   },
//                   advanced_therapy_medicinal_product: {
//                     ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product,
//                     advanced_therapy: {
//                       ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy,
//                       "ui:options": {
//                         ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy["ui:options"],
//                         showIndex: true,
//                         arrayItemTitle: "Products: ",
//                         styles: {
//                           arrayTitle
//                         }
//                       },
//                       items: {
//                         ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items,
//                         "ui:options": {
//                           ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items["ui:options"],
//                           styles: {
//                             objectInnerContainer: {
//                               display: "flex",
//                               flexDirection: "column"
//                             },
//                             arrayItemContainer: {
//                               marginBottom: 6
//                             }
//                           }
//                         },
//                         advanced_therapy_classification: {
//                           ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.advanced_therapy_classification,
//                           "ui:options": {
//                             ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.advanced_therapy_classification["ui:options"],
//                             styles: {
//                               ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.advanced_therapy_classification["ui:options"].styles,
//                               ...previewStyles.boldLabel,
                
//                             }
//                           }
//                         },
//                         cat_reference_number: {
//                           ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.cat_reference_number,
//                           "ui:options": {
//                             ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.cat_reference_number["ui:options"],
//                             styles: {
//                               ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.cat_reference_number["ui:options"].styles,
//                               ...previewStyles.boldLabel,
                
//                             }
//                           }
//                         },
//                         somatic_cell_origin: {
//                           ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.somatic_cell_origin,
//                           "ui:options": {
//                             ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.somatic_cell_origin["ui:options"],
//                             styles: {
//                               ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.somatic_cell_origin["ui:options"].styles,
//                               ...previewStyles.boldLabel,
                
//                             }
//                           }
//                         },
//                         somatic_cell_type: {
//                           ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.somatic_cell_type,
//                           "ui:options": {
//                             ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.somatic_cell_type["ui:options"],
//                             styles: {
//                               ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.somatic_cell_type["ui:options"].styles,
//                               ...previewStyles.boldLabel,
                
//                             }
//                           }
//                         },
//                         species_origin_xenogeneic_cell: {
//                           ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.species_origin_xenogeneic_cell,
//                           "ui:options": {
//                             ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.species_origin_xenogeneic_cell["ui:options"],
//                             styles: {
//                               ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.species_origin_xenogeneic_cell["ui:options"].styles,
//                               ...previewStyles.boldLabel,
                
//                             }
//                           }
//                         },
//                         specify_type_differentiated_cells: {
//                           ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.specify_type_differentiated_cells,
//                           "ui:options": {
//                             ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.specify_type_differentiated_cells["ui:options"],
//                             styles: {
//                               ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.specify_type_differentiated_cells["ui:options"].styles,
//                               ...previewStyles.boldLabel,
                
//                             }
//                           }
//                         },
//                         tissue_engineered_cell_type: {
//                           ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.tissue_engineered_cell_type,
//                           "ui:options": {
//                             ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.tissue_engineered_cell_type["ui:options"],
//                             styles: {
//                               ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.tissue_engineered_cell_type["ui:options"].styles,
//                               ...previewStyles.boldLabel,
                
//                             }
//                           }
//                         },
//                         origin_of_engineered_tissue: {
//                           ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.origin_of_engineered_tissue,
//                           "ui:options": {
//                             ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.origin_of_engineered_tissue["ui:options"],
//                             styles: {
//                               ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.origin_of_engineered_tissue["ui:options"].styles,
//                               ...previewStyles.boldLabel,
                
//                             }
//                           }
//                         },
//                         cell_specification: {
//                           ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.cell_specification,
//                           "ui:options": {
//                             ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.cell_specification["ui:options"],
//                             styles: {
//                               ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.cell_specification["ui:options"].styles,
//                               ...previewStyles.boldLabel,
                
//                             }
//                           }
//                         },
//                         tissue_engineered_xenogeneic_species_origin: {
//                           ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.tissue_engineered_xenogeneic_species_origin,
//                           "ui:options": {
//                             ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.tissue_engineered_xenogeneic_species_origin["ui:options"],
//                             styles: {
//                               ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.tissue_engineered_xenogeneic_species_origin["ui:options"].styles,
//                               ...previewStyles.boldLabel,
                
//                             }
//                           }
//                         },
//                         gene_interest: {
//                           ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.gene_interest,
//                           "ui:options": {
//                             ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.gene_interest["ui:options"],
//                             styles: {
//                               ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.gene_interest["ui:options"].styles,
//                               ...previewStyles.boldLabel,
                
//                             }
//                           }
//                         },
//                         gene_therapy_type: {
//                           ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.gene_therapy_type,
//                           "ui:options": {
//                             ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.gene_therapy_type["ui:options"],
//                             styles: {
//                               ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.gene_therapy_type["ui:options"].styles,
//                               ...previewStyles.boldLabel,
                
//                             }
//                           }
//                         },
//                         type_of_gene_transfer_product: {
//                           ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.type_of_gene_transfer_product,
//                           "ui:options": {
//                             ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.type_of_gene_transfer_product["ui:options"],
//                             styles: {
//                               ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.type_of_gene_transfer_product["ui:options"].styles,
//                               ...previewStyles.boldLabel,
                
//                             }
//                           }
//                         },
//                         genetically_modified_cell_present: {
//                           ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.genetically_modified_cell_present,
//                           "ui:options": {
//                             ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.genetically_modified_cell_present["ui:options"],
//                             styles: {
//                               ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.genetically_modified_cell_present["ui:options"].styles,
//                               ...previewStyles.boldLabel,
                
//                             }
//                           }
//                         },
//                         specify_type_of_cells: {
//                           ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.specify_type_of_cells,
//                           "ui:options": {
//                             ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.specify_type_of_cells["ui:options"],
//                             styles: {
//                               ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.specify_type_of_cells["ui:options"].styles,
//                               ...previewStyles.boldLabel,
                
//                             }
//                           }
//                         },
//                         origin_of_genetically_modiefied_cells: {
//                           ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.origin_of_genetically_modiefied_cells,
//                           "ui:options": {
//                             ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.origin_of_genetically_modiefied_cells["ui:options"],
//                             styles: {
//                               ...obj.protocol.study_description.products.products.items.products.items.product_properties.advanced_therapy_medicinal_product.advanced_therapy.items.origin_of_genetically_modiefied_cells["ui:options"].styles,
//                               ...previewStyles.boldLabel,
                
//                             }
//                           }
//                         },
//                       }
//                     }
//                   },
//                   device_associated_with_medicinal_product: {
//                     ...obj.protocol.study_description.products.products.items.products.items.product_properties.device_associated_with_medicinal_product,
//                     device: {
//                       ...obj.protocol.study_description.products.products.items.products.items.product_properties.device_associated_with_medicinal_product.device,
//                       "ui:options": {
//                         ...obj.protocol.study_description.products.products.items.products.items.product_properties.device_associated_with_medicinal_product.device["ui:options"],
//                         showIndex: true,
//                         arrayItemTitle: "Products: ",
//                         styles: {
//                           arrayTitle
//                         }
//                       },
//                       items: {
//                         ...obj.protocol.study_description.products.products.items.products.items.product_properties.device_associated_with_medicinal_product.device.items,
//                         "ui:options": {
//                           ...obj.protocol.study_description.products.products.items.products.items.product_properties.device_associated_with_medicinal_product.device.items["ui:options"],
//                           styles: {
//                             objectInnerContainer: {
//                               display: "flex",
//                               flexDirection: "column"
//                             },
//                             arrayItemContainer: {
//                               marginBottom: 6
//                             }
//                           }
//                         },
//                         product_used_in_combination_with_device: {
//                           ...obj.protocol.study_description.products.products.items.products.items.product_properties.device_associated_with_medicinal_product.device.items.product_used_in_combination_with_device,
//                           "ui:options": {
//                             ...obj.protocol.study_description.products.products.items.products.items.product_properties.device_associated_with_medicinal_product.device.items.product_used_in_combination_with_device["ui:options"],
//                             styles: {
//                               ...obj.protocol.study_description.products.products.items.products.items.product_properties.device_associated_with_medicinal_product.device.items.product_used_in_combination_with_device["ui:options"].styles,
//                               ...previewStyles.boldLabel,
                
//                             }
//                           }
//                         },
//                         device_type: {
//                           ...obj.protocol.study_description.products.products.items.products.items.product_properties.device_associated_with_medicinal_product.device.items.device_type,
//                           "ui:options": {
//                             ...obj.protocol.study_description.products.products.items.products.items.product_properties.device_associated_with_medicinal_product.device.items.device_type["ui:options"],
//                             styles: {
//                               ...obj.protocol.study_description.products.products.items.products.items.product_properties.device_associated_with_medicinal_product.device.items.device_type["ui:options"].styles,
//                               ...previewStyles.boldLabel,
                
//                             }
//                           }
//                         },
//                         device_has_ce_mark: {
//                           ...obj.protocol.study_description.products.products.items.products.items.product_properties.device_associated_with_medicinal_product.device.items.device_has_ce_mark,
//                           "ui:options": {
//                             ...obj.protocol.study_description.products.products.items.products.items.product_properties.device_associated_with_medicinal_product.device.items.device_has_ce_mark["ui:options"],
//                             styles: {
//                               ...obj.protocol.study_description.products.products.items.products.items.product_properties.device_associated_with_medicinal_product.device.items.device_has_ce_mark["ui:options"].styles,
//                               ...previewStyles.boldLabel,
                
//                             }
//                           }
//                         },
//                         device_trade_name: {
//                           ...obj.protocol.study_description.products.products.items.products.items.product_properties.device_associated_with_medicinal_product.device.items.device_trade_name,
//                           "ui:options": {
//                             ...obj.protocol.study_description.products.products.items.products.items.product_properties.device_associated_with_medicinal_product.device.items.device_trade_name["ui:options"],
//                             styles: {
//                               ...obj.protocol.study_description.products.products.items.products.items.product_properties.device_associated_with_medicinal_product.device.items.device_trade_name["ui:options"].styles,
//                               ...previewStyles.boldLabel,
                
//                             }
//                           }
//                         },
//                         device_notified_body: {
//                           ...obj.protocol.study_description.products.products.items.products.items.product_properties.device_associated_with_medicinal_product.device.items.device_notified_body,
//                           "ui:options": {
//                             ...obj.protocol.study_description.products.products.items.products.items.product_properties.device_associated_with_medicinal_product.device.items.device_notified_body["ui:options"],
//                             styles: {
//                               ...obj.protocol.study_description.products.products.items.products.items.product_properties.device_associated_with_medicinal_product.device.items.device_notified_body["ui:options"].styles,
//                               ...previewStyles.boldLabel,
                
//                             }
//                           }
//                         },
//                         description_of_device: {
//                           ...obj.protocol.study_description.products.products.items.products.items.product_properties.device_associated_with_medicinal_product.device.items.description_of_device,
//                           "ui:options": {
//                             ...obj.protocol.study_description.products.products.items.products.items.product_properties.device_associated_with_medicinal_product.device.items.description_of_device["ui:options"],
//                             styles: {
//                               ...obj.protocol.study_description.products.products.items.products.items.product_properties.device_associated_with_medicinal_product.device.items.description_of_device["ui:options"].styles,
//                               ...previewStyles.boldLabel,
                
//                             }
//                           }
//                         },
//                       }
//                     }
//                   }
//                 },
//               }
//             },
//             description: {
//               ...obj.protocol.study_description.products.products.items.description,
//               "ui:options": {
//                 ...obj.protocol.study_description.products.products.items.description["ui:options"],
//                 styles: {
//                   ...obj.protocol.study_description.products.products.items.description["ui:options"].styles,
//                   ...previewStyles.boldLabel,
//                 }
//               }
//             },
//             authorisation_number_manufacturing_and_import: {
//               ...obj.protocol.study_description.products.products.items.authorisation_number_manufacturing_and_import,
//               "ui:options": {
//                 ...obj.protocol.study_description.products.products.items.authorisation_number_manufacturing_and_import["ui:options"],
//                 styles: {
//                   ...obj.protocol.study_description.products.products.items.authorisation_number_manufacturing_and_import["ui:options"].styles,
//                   ...previewStyles.boldLabel,
//                 }
//               }
//             },
//             quality_justification_for_no_impd_upload: {
//               ...obj.protocol.study_description.products.products.items.quality_justification_for_no_impd_upload,
//               "ui:options": {
//                 ...obj.protocol.study_description.products.products.items.quality_justification_for_no_impd_upload["ui:options"],
//                 styles: {
//                   ...obj.protocol.study_description.products.products.items.quality_justification_for_no_impd_upload["ui:options"].styles,
//                   ...previewStyles.boldLabel,
//                 }
//               }
//             },
//             safety_and_efficacy_justification_for_no_impd_upload: {
//               ...obj.protocol.study_description.products.products.items.safety_and_efficacy_justification_for_no_impd_upload,
//               "ui:options": {
//                 ...obj.protocol.study_description.products.products.items.safety_and_efficacy_justification_for_no_impd_upload["ui:options"],
//                 styles: {
//                   ...obj.protocol.study_description.products.products.items.safety_and_efficacy_justification_for_no_impd_upload["ui:options"].styles,
//                   ...previewStyles.boldLabel,
//                 }
//               }
//             },
//             reason_for_no_ampd_upload: {
//               ...obj.protocol.study_description.products.products.items.reason_for_no_ampd_upload,
//               "ui:options": {
//                 ...obj.protocol.study_description.products.products.items.reason_for_no_ampd_upload["ui:options"],
//                 styles: {
//                   ...obj.protocol.study_description.products.products.items.reason_for_no_ampd_upload["ui:options"].styles,
//                   ...previewStyles.boldLabel,
//                 }
//               }
//             },
//           }
//         },
//       },
//     }

//     obj.protocol.study_eligibilty = {
//       ...obj.protocol.study_eligibilty,
//       "ui:options": {
//         ...obj.protocol.study_eligibilty["ui:options"],
//         styles: {
//           ...previewStyles.sectionHeader
//         },
//       },
//       sex: {
//         ...obj.protocol.study_eligibilty.sex,
//         "ui:options": {
//           ...obj.protocol.study_eligibilty.sex["ui:options"],
//           title: "Sex: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       population_under_study: {
//         ...obj.protocol.study_eligibilty.population_under_study,
//         "ui:options": {
//           ...obj.protocol.study_eligibilty.population_under_study["ui:options"],
//           styles: {
//             ...obj.protocol.study_eligibilty.population_under_study["ui:options"].styles,
//             ...previewStyles.boldLabel,
//             objectTitle: {
//               fontWeight: 600
//             },
//             objectInnerContainer: {
//               flexDirection: "column"
//             }
//           }
//         },
//         age_range_ctis: {
//           ...obj.protocol.study_eligibilty.population_under_study.age_range_ctis,
//           "ui:options": {
//             ...obj.protocol.study_eligibilty.population_under_study.age_range_ctis["ui:options"],
//             title: "Age range: ",
//             styles: {
//               ...previewStyles.flexRow
//             }
//           }
//         },
//         age_range_secondary_ctis: {
//           ...obj.protocol.study_eligibilty.population_under_study.age_range_secondary_ctis,
//           "ui:options": {
//             ...obj.protocol.study_eligibilty.population_under_study.age_range_secondary_ctis["ui:options"],
//             title: "Age range secondary identifier: ",
//             styles: {
//               ...previewStyles.flexRow
//             }
//           }
//         },
//         clinical_trial_group: {
//           ...obj.protocol.study_eligibilty.population_under_study.clinical_trial_group,
//           "ui:options": {
//             ...obj.protocol.study_eligibilty.population_under_study.clinical_trial_group["ui:options"],
//             title: "Clinical trial group: ",
//             styles: {
//               ...previewStyles.flexRow
//             }
//           }
//         },
//         vulnerable_population: {
//           ...obj.protocol.study_eligibilty.population_under_study.vulnerable_population,
//           "ui:options": {
//             ...obj.protocol.study_eligibilty.population_under_study.vulnerable_population["ui:options"],
//             title: "Vulnerable population: ",
//             styles: {
//               ...previewStyles.flexRow
//             }
//           }
//         },
//         recruitment_population_group: {
//           ...obj.protocol.study_eligibilty.population_under_study.recruitment_population_group,
//           "ui:options": {
//             ...obj.protocol.study_eligibilty.population_under_study.recruitment_population_group["ui:options"],
//             title: "Recruitment population group: ",
//             styles: {
//               ...previewStyles.flexRow
//             }
//           }
//         },
//         subjects_incapable_giving_consent: {
//           ...obj.protocol.study_eligibilty.population_under_study.subjects_incapable_giving_consent,
//           "ui:options": {
//             ...obj.protocol.study_eligibilty.population_under_study.subjects_incapable_giving_consent["ui:options"],
//             title: "Subjects incapable of giving consent personally: ",
//             styles: {
//               ...previewStyles.flexRow
//             }
//           }
//         },
//         emergency_situation_description: {
//           ...obj.protocol.study_eligibilty.population_under_study.emergency_situation_description,
//           "ui:options": {
//             ...obj.protocol.study_eligibilty.population_under_study.emergency_situation_description["ui:options"],
//             title: "mergency situation description: ",
//             styles: {
//               ...previewStyles.flexRow
//             }
//           }
//         },
//         other_description: {
//           ...obj.protocol.study_eligibilty.population_under_study.other_description,
//           "ui:options": {
//             ...obj.protocol.study_eligibilty.population_under_study.other_description["ui:options"],
//             title: "Other description: ",
//             styles: {
//               ...previewStyles.flexRow
//             }
//           }
//         },
//       },
//     }

//     obj.protocol.study_reference = {
//       ...obj.protocol.study_reference,
//       "ui:options": {
//         ...obj.protocol.study_reference["ui:options"],
//         styles: {
//           ...previewStyles.sectionHeader
//         },
//         study_publications: {
//           ...obj.protocol.study_reference.study_publications,
//           "ui:options": {
//             ...obj.protocol.study_reference.study_publications["ui:options"],
//             styles: {
//               ...obj.protocol.study_reference.study_publications["ui:options"].styles,
//               arrayTitle
//             }
//           },
//           items: {
//             ...obj.protocol.study_reference.study_publications.items,
//             reference: {
//               ...obj.protocol.study_reference.study_publications.items.reference,
//               "ui:options": {
//                 ...obj.protocol.study_reference.study_publications.items.reference["ui:options"],
//                 styles: {
//                   ...obj.protocol.study_reference.study_publications.items.reference["ui:options"].styles,
//                   ...previewStyles.boldLabel
//                 }
//               }
//             }
//           }
//         },
//       },
//     }

//     obj.protocol.study_scope = {
//       ...obj.protocol.study_scope,
//       "ui:options": {
//         ...obj.protocol.study_scope["ui:options"],
//         styles: {
//           ...previewStyles.sectionHeader
//         },
//       },
//       countries_outside_eea: {
//         ...obj.protocol.study_scope.countries_outside_eea,
//         "ui:options": {
//           ...obj.protocol.study_scope.countries_outside_eea["ui:options"],
//           title: "Countries outside the European Economic Area: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//       enrollment_number: {
//         ...obj.protocol.study_scope.enrollment_number,
//         "ui:options": {
//           ...obj.protocol.study_scope.enrollment_number["ui:options"],
//           title: "Rest of the world subjects: ",
//           styles: {
//             ...previewStyles.flexRow
//           }
//         }
//       },
//     }

//     obj.protocol.study_outcome_measures = {
//       ...obj.protocol.study_outcome_measures,
//       "ui:options": {
//         ...obj.protocol.study_outcome_measures["ui:options"],
//         styles: {
//           ...previewStyles.sectionHeader
//         },
//       },

//       outcome_measures: {
//         ...obj.protocol.study_outcome_measures.outcome_measures,
//         "ui:options": {
//           ...obj.protocol.study_outcome_measures.outcome_measures["ui:options"],
//           title: "Main Objective Languages: ", 
//           styles: {
//             ...obj.protocol.study_outcome_measures.outcome_measures["ui:options"].styles,
//             arrayTitle
//           }
//         },
//         items: {
//           ...obj.protocol.study_outcome_measures.outcome_measures.items,
//           title: {
//             ...obj.protocol.study_outcome_measures.outcome_measures.items.title,
//             "ui:options": {
//               ...obj.protocol.study_outcome_measures.outcome_measures.items.title["ui:options"],
//               styles: {
//                 ...obj.protocol.study_outcome_measures.outcome_measures.items.title["ui:options"].styles,
//                 ...previewStyles.boldLabel
//               }
//             }
//           },
//           type: {
//             ...obj.protocol.study_outcome_measures.outcome_measures.items.type,
//             "ui:options": {
//               ...obj.protocol.study_outcome_measures.outcome_measures.items.type["ui:options"],
//               styles: {
//                 ...obj.protocol.study_outcome_measures.outcome_measures.items.type["ui:options"].styles,
//                 ...previewStyles.boldLabel
//               }
//             }
//           },
//           translations: {
//             ...obj.protocol.study_outcome_measures.outcome_measures.items.translations,
//             "ui:options": {
//               ...obj.protocol.study_outcome_measures.outcome_measures.items.translations["ui:options"],
//               title: "Translations: ", 
//               styles: {
//                 ...obj.protocol.study_outcome_measures.outcome_measures.items.translations["ui:options"].styles,
//                 arrayTitle
//               }
//             },
//             items: {
//               ...obj.protocol.study_outcome_measures.outcome_measures.items.translations.items,
//               language: {
//                 ...obj.protocol.study_outcome_measures.outcome_measures.items.translations.items.language,
//                 "ui:options": {
//                   ...obj.protocol.study_outcome_measures.outcome_measures.items.translations.items.language["ui:options"],
//                   styles: {
//                     ...obj.protocol.study_outcome_measures.outcome_measures.items.translations.items.language["ui:options"].styles,
//                     ...previewStyles.boldLabel
//                   }
//                 }
//               },
//               translation: {
//                 ...obj.protocol.study_outcome_measures.outcome_measures.items.translations.items.translation,
//                 "ui:options": {
//                   ...obj.protocol.study_outcome_measures.outcome_measures.items.translations.items.translation["ui:options"],
//                   styles: {
//                     ...obj.protocol.study_outcome_measures.outcome_measures.items.translations.items.translation["ui:options"].styles,
//                     ...previewStyles.boldLabel
//                   }
//                 }
//               },
//             }
//           },
//         }
//       },
//     }
//   }
// }

// export const getEupasPreviewStyles = (obj, formData) => {
//   if(obj.protocol) {
//     obj.protocol["ui:options"] = {
//       title: "Study Identification and Information",
//       styles: { 
//         ...previewStyles.sectionHeader
//       },
//     }

//     obj.protocol.eupas_study_type["ui:options"] = {
//       ...obj.protocol.eupas_study_type["ui:options"],
//       title: "Study Type: ",
//       styles: {
//         ...previewStyles.eudractRow
//       }
//     }
    
//     obj.protocol.eudract_number["ui:options"] = {
//       ...obj.protocol.eudract_number["ui:options"],
//       styles: {
//         ...previewStyles.eudractRow
//       }
//     }

//     obj.protocol.other_study_type["ui:options"] = {
//       ...obj.protocol.other_study_type["ui:options"],
//       styles: {
//         ...previewStyles.eudractRow
//       }
//     }

//     obj.protocol.study_identification = {
//       ...obj.protocol.study_identification,
//       "ui:order": ["official_title", "acronym","brief_title", "*"],
//       "ui:options": {
//         ...obj.protocol.study_identification["ui:options"],
//         styles: { },
//       },
//       brief_title: {
//         ...obj.protocol.study_identification.brief_title,
//         "ui:options": {
//           ...obj.protocol.study_identification.brief_title["ui:options"],
//           title: "Brief description of the study: ",
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       acronym: {
//         ...obj.protocol.study_identification.acronym,
//         "ui:options": {
//           ...obj.protocol.study_identification.acronym["ui:options"],
//           title: "Study Title Acronym: ",
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       official_title: {
//         ...obj.protocol.study_identification.official_title,
//         "ui:options": {
//           ...obj.protocol.study_identification.official_title["ui:options"],
//           title: "Official Title: ",
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       is_requested_by_regulator: {
//         ...obj.protocol.study_identification.is_requested_by_regulator,
//         "ui:options": {
//           ...obj.protocol.study_identification.is_requested_by_regulator["ui:options"],
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       country_regulator: {
//         ...obj.protocol.study_identification.country_regulator,
//         "ui:options": {
//           ...obj.protocol.study_identification.country_regulator["ui:options"],
//           title: "Country of Regulator: ",
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       country_regulator_not_listed: {
//         ...obj.protocol.study_identification.country_regulator_not_listed,
//         "ui:options": {
//           ...obj.protocol.study_identification.country_regulator_not_listed["ui:options"],
//           title: "Other country/ies: ",
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       is_study_required_by_risk_management_plan: {
//         ...obj.protocol.study_identification.country_regulator,
//         "ui:options": {
//           ...obj.protocol.study_identification.country_regulator["ui:options"],
//           title: "Is the study required by a Risk Management Plan (RMP)?: ",
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       regulatory_procedure_number: {
//         ...obj.protocol.study_identification.regulatory_procedure_number,
//         "ui:options": {
//           ...obj.protocol.study_identification.regulatory_procedure_number["ui:options"],
//           title: "Regulatory procedure number (RMP Category 1 and 2 studies only)?: ",
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       other_study_identification_numbers: {
//         ...obj.protocol.study_identification.other_study_identification_numbers,
//         "ui:options": {
//           ...obj.protocol.study_identification.other_study_identification_numbers["ui:options"],
//           title: "Other study registration identification numbers and URLs as applicable: ",
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//     }

//     obj.protocol.contact_information = {
//       ...obj.protocol.contact_information,
//       "ui:options": {
//         ...obj.protocol.contact_information["ui:options"],
//         styles: {
//           ...previewStyles.sectionHeader
//         },
//       },
//       is_study_conducted_by_registered_encepp: {
//         ...obj.protocol.contact_information.is_study_conducted_by_registered_encepp,
//         "ui:options": {
//           ...obj.protocol.contact_information.is_study_conducted_by_registered_encepp["ui:options"],
//           title: "Is this study conducted/coordinated by a centre registered in the ENCePP inventory of research centres?: ",
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       investigator_center: {
//         ...obj.protocol.contact_information.investigator_center,
//         "ui:options": {
//           ...obj.protocol.contact_information.investigator_center["ui:options"],
//           title: "Center to which the investigator belongs: ",
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       department_research_group: {
//         ...obj.protocol.contact_information.department_research_group,
//         "ui:options": {
//           ...obj.protocol.contact_information.department_research_group["ui:options"],
//           title: "Department/Research group: ",
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       encepp_center_organization_affiliation: {
//         ...obj.protocol.contact_information.encepp_center_organization_affiliation,
//         "ui:options": {
//           ...obj.protocol.contact_information.encepp_center_organization_affiliation["ui:options"],
//           title: "Organisation/affiliation: ",
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       encepp_center_website: {
//         ...obj.protocol.contact_information.encepp_center_website,
//         "ui:options": {
//           ...obj.protocol.contact_information.encepp_center_website["ui:options"],
//           title: "Website/Homepage: ",
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       research_center_name: {
//         ...obj.protocol.contact_information.research_center_name,
//         "ui:options": {
//           ...obj.protocol.contact_information.research_center_name["ui:options"],
//           title: "Please specify name: ",
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       research_center_location: {
//         ...obj.protocol.contact_information.research_center_location,
//         "ui:options": {
//           ...obj.protocol.contact_information.research_center_location["ui:options"],
//           title: "Please specify location: ",
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       is_study_collaboration_research_network: {
//         ...obj.protocol.contact_information.is_study_collaboration_research_network,
//         "ui:options": {
//           ...obj.protocol.contact_information.is_study_collaboration_research_network["ui:options"],
//           title: "Is this study being carried out with the collaboration of a research network?: ",
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       networks_registered_encepp: {
//         ...obj.protocol.contact_information.networks_registered_encepp,
//         "ui:options": {
//           ...obj.protocol.contact_information.networks_registered_encepp["ui:options"],
//           title: "Please specify location: ",
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       other_networks: {
//         ...obj.protocol.contact_information.other_networks,
//         "ui:options": {
//           ...obj.protocol.contact_information.other_networks["ui:options"],
//           title: "Other networks: ",
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       other_centers_study_conducted: {
//         ...obj.protocol.contact_information.other_centers_study_conducted,
//         "ui:options": {
//           ...obj.protocol.contact_information.other_centers_study_conducted["ui:options"],
//           title: "Other centers where this study is being conducted: ",
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       number_of_centers: {
//         ...obj.protocol.contact_information.number_of_centers,
//         "ui:options": {
//           ...obj.protocol.contact_information.number_of_centers["ui:options"],
//           title: "In total how many centres are involved in this Study?: ",
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       centers_registered_encepp: {
//         ...obj.protocol.contact_information.centers_registered_encepp,
//         "ui:options": {
//           ...obj.protocol.contact_information.centers_registered_encepp["ui:options"],
//           title: "Centres registered with ENCePP: ",
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       other_centers: {
//         ...obj.protocol.contact_information.other_centers,
//         "ui:options": {
//           ...obj.protocol.contact_information.other_centers["ui:options"],
//           title: "In total how many centres are involved in this Study?: ",
//           styles: {
//             ...obj.protocol.contact_information.other_centers["ui:options"].styles,
//             ...previewStyles.eudractArrayContainer,
//             ...previewStyles.eudractSubsectionHeaderFirst
//           }
//         },
//         items: {
//           "ui:options": {
//             ...obj.protocol.contact_information.other_centers.items["ui:options"],
//             styles: {

//             }
//           },
//           name: {
//             "ui:options": {
//               ...obj.protocol.contact_information.other_centers.items.name["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRow
//               }
//             }
//           },
//           location: {
//             "ui:options": {
//               ...obj.protocol.contact_information.other_centers.items.location["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRow
//               }
//             }
//           }
//         }
//       },
//       national_country: {
//         ...obj.protocol.contact_information.national_country,
//         "ui:options": {
//           ...obj.protocol.contact_information.national_country["ui:options"],
//           title: "Please select a country: ",
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       international_countries: {
//         ...obj.protocol.contact_information.international_countries,
//         "ui:options": {
//           ...obj.protocol.contact_information.international_countries["ui:options"],
//           title: "Please select countries: ",
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       country_study_is_conducted: {
//         ...obj.protocol.contact_information.country_study_is_conducted,
//         "ui:options": {
//           ...obj.protocol.contact_information.country_study_is_conducted["ui:options"],
//           title: "Countries in which this study is being conducted: ",
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       investigator_contact_details: {
//         ...obj.protocol.contact_information.investigator_contact_details,
//         lead_investigator: {
//           ...obj.protocol.contact_information.investigator_contact_details.lead_investigator,
//           "ui:options": {
//             ...obj.protocol.contact_information.investigator_contact_details.lead_investigator["ui:options"],
//             styles: {
//               ...obj.protocol.contact_information.investigator_contact_details.lead_investigator["ui:options"].styles,
//               objectInnerContainer: {},
//               ...previewStyles.eudractSubsectionHeader
//             }
//           },
//           ...getEupasContactStyles(obj, "lead_investigator")
//         },
//         scientific_contact: {
//           ...obj.protocol.contact_information.investigator_contact_details.scientific_contact,
//           "ui:options": {
//             ...obj.protocol.contact_information.investigator_contact_details.scientific_contact["ui:options"],
//             styles: {
//               ...obj.protocol.contact_information.investigator_contact_details.scientific_contact["ui:options"].styles,
//               objectInnerContainer: {},
//               ...previewStyles.eudractSubsectionHeader
//             }
//           },
//           copy_lead_investigator: {
//             ...obj.protocol.contact_information.investigator_contact_details.scientific_contact.copy_lead_investigator,
//             "ui:options": {
//               ...obj.protocol.contact_information.investigator_contact_details.scientific_contact.copy_lead_investigator["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRow
//               }
//             }
//           },
//           ...getEupasContactStyles(obj, "scientific_contact")
//         },
//         public_contact: {
//           ...obj.protocol.contact_information.investigator_contact_details.public_contact,
//           "ui:options": {
//             ...obj.protocol.contact_information.investigator_contact_details.public_contact["ui:options"],
//             styles: {
//               ...obj.protocol.contact_information.investigator_contact_details.public_contact["ui:options"].styles,
//               objectInnerContainer: {},
//               ...previewStyles.eudractSubsectionHeader
//             }
//           },
//           copy_lead_investigator: {
//             ...obj.protocol.contact_information.investigator_contact_details.public_contact.copy_lead_investigator,
//             "ui:options": {
//               ...obj.protocol.contact_information.investigator_contact_details.public_contact.copy_lead_investigator["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRow
//               }
//             }
//           },
//           ...getEupasContactStyles(obj, "public_contact")
//         }
//       },
//       collaborators: {
//         ...obj.protocol.contact_information.collaborators,
//         "ui:options": {
//           ...obj.protocol.contact_information.collaborators["ui:options"],
//           showIndex: true,
//           // arrayItemTitle: "Collaborator",
//           styles: {
//             arrayTitle,
//             ...previewStyles.singleArrayItem
//           }
//         },
//         items: {
//           ...obj.protocol.contact_information.collaborators.items,
//           "ui:options": {
//             ...obj.protocol.contact_information.collaborators.items["ui:options"],
//             styles: {
//               objectInnerContainer: {
//                 display: "flex",
//                 flexDirection: "column"
//               },
//               arrayItemContainer: {
//                 marginBottom: 6
//               }
//             }
//           },
//           collaborator: {
//             ...obj.protocol.contact_information.collaborators.items.collaborator,
//             "ui:options": { 
//               ...obj.protocol.contact_information.collaborators.items.collaborator["ui:options"],
//               title: "",
//               styles: {
//                 ...previewStyles.flexRow
//               }
//             }
//           }
//         }
//       }
//     }

//     obj.protocol.study_timeline = {
//       ...obj.protocol.study_timeline,
//       "ui:options": {
//         ...obj.protocol.study_timeline["ui:options"],
//         styles: {
//           ...previewStyles.sectionHeader
//         },
//       },
//       signed_funding_contract_date: {
//         ...obj.protocol.study_timeline.signed_funding_contract_date,
//         "ui:options": {
//           ...obj.protocol.study_timeline.signed_funding_contract_date["ui:options"],
//           styles: {
//             ...obj.protocol.study_timeline.signed_funding_contract_date["ui:options"].styles,
//             objectInnerContainer: {},
//             ...previewStyles.eudractSubsectionHeader
//           }
//         },
//         planned: {
//           ...obj.protocol.study_timeline.signed_funding_contract_date.planned,
//           "ui:options": {
//             ...obj.protocol.study_timeline.signed_funding_contract_date.planned["ui:options"],
//             styles: {
//               ...previewStyles.eudractRow
//             }
//           }
//         },
//         actual: {
//           ...obj.protocol.study_timeline.signed_funding_contract_date.actual,
//           "ui:options": {
//             ...obj.protocol.study_timeline.signed_funding_contract_date.actual["ui:options"],
//             styles: {
//               ...previewStyles.eudractRow
//             }
//           }
//         }
//       },
//       data_collection_start_date: {
//         ...obj.protocol.study_timeline.data_collection_start_date,
//         "ui:options": {
//           ...obj.protocol.study_timeline.data_collection_start_date["ui:options"],
//           styles: {
//             ...obj.protocol.study_timeline.data_collection_start_date["ui:options"].styles,
//             objectInnerContainer: {},
//             ...previewStyles.eudractSubsectionHeader
//           }
//         },
//         planned: {
//           ...obj.protocol.study_timeline.data_collection_start_date.planned,
//           "ui:options": {
//             ...obj.protocol.study_timeline.data_collection_start_date.planned["ui:options"],
//             styles: {
//               ...previewStyles.eudractRow
//             }
//           }
//         },
//         actual: {
//           ...obj.protocol.study_timeline.data_collection_start_date.actual,
//           "ui:options": {
//             ...obj.protocol.study_timeline.data_collection_start_date.actual["ui:options"],
//             styles: {
//               ...previewStyles.eudractRow
//             }
//           }
//         }
//       },
//       data_analysis_start_date: {
//         ...obj.protocol.study_timeline.data_analysis_start_date,
//         "ui:options": {
//           ...obj.protocol.study_timeline.data_analysis_start_date["ui:options"],
//           styles: {
//             ...obj.protocol.study_timeline.data_analysis_start_date["ui:options"].styles,
//             objectInnerContainer: {},
//             ...previewStyles.eudractSubsectionHeader
//           }
//         },
//         planned: {
//           ...obj.protocol.study_timeline.data_analysis_start_date.planned,
//           "ui:options": {
//             ...obj.protocol.study_timeline.data_analysis_start_date.planned["ui:options"],
//             styles: {
//               ...previewStyles.eudractRow
//             }
//           }
//         },
//         actual: {
//           ...obj.protocol.study_timeline.data_analysis_start_date.actual,
//           "ui:options": {
//             ...obj.protocol.study_timeline.data_analysis_start_date.actual["ui:options"],
//             styles: {
//               ...previewStyles.eudractRow
//             }
//           }
//         }
//       },
//       interim_report_date: {
//         ...obj.protocol.study_timeline.interim_report_date,
//         "ui:options": {
//           ...obj.protocol.study_timeline.interim_report_date["ui:options"],
//           styles: {
//             ...obj.protocol.study_timeline.interim_report_date["ui:options"].styles,
//             objectInnerContainer: {},
//             ...previewStyles.eudractSubsectionHeader
//           }
//         },
//         planned: {
//           ...obj.protocol.study_timeline.interim_report_date.planned,
//           "ui:options": {
//             ...obj.protocol.study_timeline.interim_report_date.planned["ui:options"],
//             styles: {
//               ...previewStyles.eudractRow
//             }
//           }
//         },
//         actual: {
//           ...obj.protocol.study_timeline.interim_report_date.actual,
//           "ui:options": {
//             ...obj.protocol.study_timeline.interim_report_date.actual["ui:options"],
//             styles: {
//               ...previewStyles.eudractRow
//             }
//           }
//         }
//       },
//       final_study_report_date: {
//         ...obj.protocol.study_timeline.final_study_report_date,
//         "ui:options": {
//           ...obj.protocol.study_timeline.final_study_report_date["ui:options"],
//           styles: {
//             ...obj.protocol.study_timeline.final_study_report_date["ui:options"].styles,
//             objectInnerContainer: {},
//             ...previewStyles.eudractSubsectionHeader
//           }
//         },
//         planned: {
//           ...obj.protocol.study_timeline.final_study_report_date.planned,
//           "ui:options": {
//             ...obj.protocol.study_timeline.final_study_report_date.planned["ui:options"],
//             styles: {
//               ...previewStyles.eudractRow
//             }
//           }
//         },
//         actual: {
//           ...obj.protocol.study_timeline.final_study_report_date.actual,
//           "ui:options": {
//             ...obj.protocol.study_timeline.final_study_report_date.actual["ui:options"],
//             styles: {
//               ...previewStyles.eudractRow
//             }
//           }
//         }
//       },
//       funding_source: {
//         ...obj.protocol.study_timeline.funding_source,
//         pharmaceutical_companies: {
//           ...obj.protocol.study_timeline.funding_source.pharmaceutical_companies,
//           "ui:options": {
//             ...obj.protocol.study_timeline.funding_source.pharmaceutical_companies["ui:options"],
//             styles: {
//               ...obj.protocol.study_timeline.funding_source.pharmaceutical_companies["ui:options"].styles,
//               objectInnerContainer: {},
//               ...previewStyles.eudractSubsectionHeader
//             }
//           },
//           names: {
//             ...obj.protocol.study_timeline.funding_source.pharmaceutical_companies.names,
//             "ui:options": {
//               ...obj.protocol.study_timeline.funding_source.pharmaceutical_companies.names["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRow
//               }
//             }
//           },
//           funding: {
//             ...obj.protocol.study_timeline.funding_source.pharmaceutical_companies.funding,
//             "ui:options": {
//               ...obj.protocol.study_timeline.funding_source.pharmaceutical_companies.funding["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRow
//               }
//             }
//           }
//         },
//         charities: {
//           ...obj.protocol.study_timeline.funding_source.charities,
//           "ui:options": {
//             ...obj.protocol.study_timeline.funding_source.charities["ui:options"],
//             styles: {
//               ...obj.protocol.study_timeline.funding_source.charities["ui:options"].styles,
//               objectInnerContainer: {},
//               ...previewStyles.eudractSubsectionHeader
//             }
//           },
//           names: {
//             ...obj.protocol.study_timeline.funding_source.charities.names,
//             "ui:options": {
//               ...obj.protocol.study_timeline.funding_source.charities.names["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRow
//               }
//             }
//           },
//           funding: {
//             ...obj.protocol.study_timeline.funding_source.charities.funding,
//             "ui:options": {
//               ...obj.protocol.study_timeline.funding_source.charities.funding["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRow
//               }
//             }
//           }
//         },
//         government_body: {
//           ...obj.protocol.study_timeline.funding_source.government_body,
//           "ui:options": {
//             ...obj.protocol.study_timeline.funding_source.government_body["ui:options"],
//             styles: {
//               ...obj.protocol.study_timeline.funding_source.government_body["ui:options"].styles,
//               objectInnerContainer: {},
//               ...previewStyles.eudractSubsectionHeader
//             }
//           },
//           names: {
//             ...obj.protocol.study_timeline.funding_source.government_body.names,
//             "ui:options": {
//               ...obj.protocol.study_timeline.funding_source.government_body.names["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRow
//               }
//             }
//           },
//           funding: {
//             ...obj.protocol.study_timeline.funding_source.government_body.funding,
//             "ui:options": {
//               ...obj.protocol.study_timeline.funding_source.government_body.funding["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRow
//               }
//             }
//           }
//         },
//         research_councils: {
//           ...obj.protocol.study_timeline.funding_source.research_councils,
//           "ui:options": {
//             ...obj.protocol.study_timeline.funding_source.research_councils["ui:options"],
//             styles: {
//               ...obj.protocol.study_timeline.funding_source.research_councils["ui:options"].styles,
//               objectInnerContainer: {},
//               ...previewStyles.eudractSubsectionHeader
//             }
//           },
//           names: {
//             ...obj.protocol.study_timeline.funding_source.research_councils.names,
//             "ui:options": {
//               ...obj.protocol.study_timeline.funding_source.research_councils.names["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRow
//               }
//             }
//           },
//           funding: {
//             ...obj.protocol.study_timeline.funding_source.research_councils.funding,
//             "ui:options": {
//               ...obj.protocol.study_timeline.funding_source.research_councils.funding["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRow
//               }
//             }
//           }
//         },
//         eu_funding_scheme: {
//           ...obj.protocol.study_timeline.funding_source.eu_funding_scheme,
//           "ui:options": {
//             ...obj.protocol.study_timeline.funding_source.eu_funding_scheme["ui:options"],
//             styles: {
//               ...obj.protocol.study_timeline.funding_source.eu_funding_scheme["ui:options"].styles,
//               objectInnerContainer: {},
//               ...previewStyles.eudractSubsectionHeader
//             }
//           },
//           names: {
//             ...obj.protocol.study_timeline.funding_source.eu_funding_scheme.names,
//             "ui:options": {
//               ...obj.protocol.study_timeline.funding_source.eu_funding_scheme.names["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRow
//               }
//             }
//           },
//           funding: {
//             ...obj.protocol.study_timeline.funding_source.eu_funding_scheme.funding,
//             "ui:options": {
//               ...obj.protocol.study_timeline.funding_source.eu_funding_scheme.funding["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRow
//               }
//             }
//           }
//         },
//         other_funding_sources: {
//           "ui:options": {
//             ...obj.protocol.study_timeline.funding_source.other_funding_sources["ui:options"],
//             styles: {
//               arrayTitle: previewStyles.eudractSubsectionHeader.objectTitle,
//               field: widgetContainer.full
//             }
//           },
//           items: {
//             ...obj.protocol.study_timeline.funding_source.other_funding_sources.items,
//             "ui:options": {
//               ...obj.protocol.study_timeline.funding_source.other_funding_sources.items["ui:options"],
//               styles: {

//               }
//             },
//             names: {
//               ...obj.protocol.study_timeline.funding_source.other_funding_sources.items.names,
//               "ui:options": {
//                 ...obj.protocol.study_timeline.funding_source.other_funding_sources.items.names["ui:options"],
//                 styles: {
//                   ...previewStyles.eudractRow
//                 }
//               }
//             },
//             funding: {
//               ...obj.protocol.study_timeline.funding_source.other_funding_sources.items.funding,
//               "ui:options": {
//                 ...obj.protocol.study_timeline.funding_source.other_funding_sources.items.funding["ui:options"],
//                 styles: {
//                   ...previewStyles.eudractRow
//                 }
//               }
//             }
//           }
//         }
//       },
//     }

//     obj.protocol.study_description = {
//       ...obj.protocol.study_description,
//       "ui:options": {
//         ...obj.protocol.study_description["ui:options"],
//         styles: {
//           ...previewStyles.sectionHeader
//         },
//       },
//       brief_summary: {
//         ...obj.protocol.study_description.brief_summary,
//         "ui:options": {
//           ...obj.protocol.study_description.brief_summary["ui:options"],
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       has_medical_condition: {
//         "ui:options": {
//           ...obj.protocol.study_description.has_medical_condition["ui:options"],
//           title: "Has Medical Condition?: ",
//           styles: {
//             ...previewStyles.eudractRowLast
//           }
//         },
//       },
//       medical_conditions: {
//         ...obj.protocol.study_description.medical_conditions,
//         "ui:options": {
//           ...obj.protocol.study_description.medical_conditions["ui:options"],
//           title: "Medical condition(s): ",
//           arrayItemTitle: "Condition: ",
//           showIndex: true,
//           styles: {
//             arrayTitle: previewStyles.eudractSubsectionHeader.objectTitle,
//             // field: widgetContainer.full
//             ...previewStyles.eupasSingleArray
//           }
//         },
//       },
//       additional_medical_conditions: {
//         "ui:options": {
//           ...obj.protocol.study_description.additional_medical_conditions["ui:options"],
//           title: "Additional Medical Condition(s): ", 
//           styles: {
//             ...previewStyles.eudractRowLast
//           }
//         }
//       }
//     }

//     obj.protocol.study_scope = {
//       ...obj.protocol.study_scope,
//       "ui:options": {
//         ...obj.protocol.study_scope["ui:options"],
//         styles: {
//           ...previewStyles.sectionHeader
//         },
//       },
//       enrollment_number: {
//         ...obj.protocol.study_scope.enrollment_number,
//         "ui:options": {
//           ...obj.protocol.study_scope.enrollment_number["ui:options"],
//           title: "Total number of subjects: ",
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       scope_of_study: {
//         ...obj.protocol.study_scope.scope_of_study,
//         "ui:options": {
//           ...obj.protocol.study_scope.scope_of_study["ui:options"],
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       primary_scope: {
//         ...obj.protocol.study_scope.primary_scope,
//         "ui:options": {
//           ...obj.protocol.study_scope.primary_scope["ui:options"],
//           title: "Primary Scope: ",
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       other_scope_description: {
//         ...obj.protocol.study_scope.other_scope_description,
//         "ui:options": {
//           ...obj.protocol.study_scope.other_scope_description["ui:options"],
//           title: "Please describe other (If applicable): ",
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       design_of_study: {
//         ...obj.protocol.study_scope.design_of_study,
//         "ui:options": {
//           ...obj.protocol.study_scope.design_of_study["ui:options"],
//           title: "What is the design of the study? ",
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       other_design_description: {
//         ...obj.protocol.study_scope.other_design_description,
//         "ui:options": {
//           ...obj.protocol.study_scope.other_design_description["ui:options"],
//           title: "Please describe other (If applicable): ",
//           styles: {
//             ...previewStyles.eudractRowLast
//           }
//         }
//       },
//     }

//     obj.protocol.study_arms = {
//       ...obj.protocol.study_arms,
//       "ui:options": {
//         ...obj.protocol.study_arms["ui:options"],
//         styles: {
//           ...previewStyles.sectionHeader
//         },
//       },
//       has_drug_information: {
//         "ui:options": {
//           ...obj.protocol.study_arms.has_drug_information["ui:options"],
//           styles: {
//             ...previewStyles.eudractRowLast
//           }
//         }
//       },
//       study_drugs: {
//         ...obj.protocol.study_arms.study_drugs,
//         "ui:options": {
//           ...obj.protocol.study_arms.study_drugs["ui:options"],
//           showIndex: true,
//           styles: {
//             arrayTitle: previewStyles.eudractSubsectionHeader.objectTitle,
//             field: widgetContainer.full
//           },
//         },
//         items: {
//           ...obj.protocol.study_timeline.funding_source.other_funding_sources.items,
//           "ui:options": {
//             ...obj.protocol.study_timeline.funding_source.other_funding_sources.items["ui:options"],
//             styles: {

//             }
//           },
//           drug_type: {
//             "ui:options": {
//               ...obj.protocol.study_arms.study_drugs.items.drug_type["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRow
//               }
//             }
//           },
//           substance_inn: {
//             "ui:options": {
//               ...obj.protocol.study_arms.study_drugs.items.substance_inn["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRow
//               }
//             }
//           },
//           substance_inns: {
//             ...obj.protocol.study_arms.study_drugs.items.substance_inns,
//             "ui:options": {
//               ...obj.protocol.study_arms.study_drugs.items.substance_inns["ui:options"],
//               showIndex: true,
//               arrayItemTitle: "Substance INN: ",
//               styles: {
//                 arrayTitle: {
//                   fontWeight: "bold"
//                 },
//                 ...previewStyles.eupasSingleArray
//                 // arrayTitle: previewStyles.eudractSubsectionHeader.objectTitle,
//                 // field: widgetContainer.full
//               },
//             }
//           },
//           product_name: {
//             "ui:options": {
//               ...obj.protocol.study_arms.study_drugs.items.product_name["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRow
//               }
//             }
//           },
//           country: {
//             "ui:options": {
//               ...obj.protocol.study_arms.study_drugs.items.country["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRowLast
//               }
//             }
//           },
//         }
//       }
//     }

//     obj.protocol.study_eligibilty = {
//       ...obj.protocol.study_eligibilty,
//       "ui:options": {
//         ...obj.protocol.study_eligibilty["ui:options"],
//         styles: {
//           ...previewStyles.sectionHeader
//         },
//       },
//       sex: {
//         ...obj.protocol.study_eligibilty.sex,
//         "ui:options": {
//           ...obj.protocol.study_eligibilty.sex["ui:options"],
//           title: "Sex: ",
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       population_under_study: {
//         ...obj.protocol.study_eligibilty.population_under_study,
//         "ui:options": {
//           ...obj.protocol.study_eligibilty.population_under_study["ui:options"],
//           styles: {
//             ...obj.protocol.study_eligibilty.population_under_study["ui:options"].styles,
//             objectInnerContainer: {},
//             ...previewStyles.eudractSubsectionHeader
//           }
//         },
//         age: {
//           ...obj.protocol.study_eligibilty.population_under_study.age,
//           "ui:options": {
//             ...obj.protocol.study_eligibilty.population_under_study.age["ui:options"],
//             styles: {
//               ...previewStyles.eudractRow
//             }
//           }
//         },
//         other_population: {
//           ...obj.protocol.study_eligibilty.population_under_study.other_population,
//           "ui:options": {
//             ...obj.protocol.study_eligibilty.population_under_study.other_population["ui:options"],
//             styles: {
//               ...previewStyles.eudractRowLast
//             }
//           }
//         }
//       },
//       additional_patient_information: {
//         ...obj.protocol.study_eligibilty.additional_patient_information,
//         "ui:options": {
//           ...obj.protocol.study_eligibilty.additional_patient_information["ui:options"],
//           title: "Additional information (e.g. number of patients per treatment group): ",
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       is_patients_followed_up: {
//         ...obj.protocol.study_eligibilty.is_patients_followed_up,
//         "ui:options": {
//           ...obj.protocol.study_eligibilty.is_patients_followed_up["ui:options"],
//           title: "Will patients be followed up? ",
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       follow_up_description: {
//         ...obj.protocol.study_eligibilty.follow_up_description,
//         "ui:options": {
//           ...obj.protocol.study_eligibilty.follow_up_description["ui:options"],
//           title: "Please describe duration of follow up: ",
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       study_carried_with_established_data_source: {
//         ...obj.protocol.study_eligibilty.study_carried_with_established_data_source,
//         "ui:options": {
//           ...obj.protocol.study_eligibilty.study_carried_with_established_data_source["ui:options"],
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       sources_of_data: {
//         ...obj.protocol.study_eligibilty.sources_of_data,
//         "ui:options": {
//           ...obj.protocol.study_eligibilty.sources_of_data["ui:options"],
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       registered_data_sources: {
//         ...obj.protocol.study_eligibilty.registered_data_sources,
//         "ui:options": {
//           ...obj.protocol.study_eligibilty.registered_data_sources["ui:options"],
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       unregistered_data_sources: {
//         "ui:options": {
//           ...obj.protocol.study_eligibilty.unregistered_data_sources["ui:options"],
//           styles: {
//             arrayTitle: previewStyles.eudractSubsectionHeader.objectTitle,
//             field: widgetContainer.full
//           }
//         },
//         items: {
//           ...obj.protocol.study_eligibilty.unregistered_data_sources.items,
//           "ui:options": {
//             ...obj.protocol.study_eligibilty.unregistered_data_sources.items["ui:options"],
//             styles: {

//             }
//           },
//           name: {
//             ...obj.protocol.study_eligibilty.unregistered_data_sources.items.name,
//             "ui:options": {
//               ...obj.protocol.study_eligibilty.unregistered_data_sources.items.name["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRow
//               }
//             }
//           },
//           country: {
//             ...obj.protocol.study_eligibilty.unregistered_data_sources.items.country,
//             "ui:options": {
//               ...obj.protocol.study_eligibilty.unregistered_data_sources.items.country["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRow
//               }
//             }
//           }
//         }
//       },

//       other_data_source: {
//         ...obj.protocol.study_eligibilty.other_data_source,
//         "ui:options": {
//           ...obj.protocol.study_eligibilty.other_data_source["ui:options"],
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       }
//     }

//     obj.protocol.study_outcome_measures = {
//       ...obj.protocol.study_outcome_measures,
//       "ui:options": {
//         ...obj.protocol.study_outcome_measures["ui:options"],
//         styles: {
//           ...previewStyles.sectionHeader
//         },
//       },
//       outcome_measures: {
//         "ui:options": {
//           ...obj.protocol.study_outcome_measures.outcome_measures["ui:options"],
//           styles: {
//             arrayTitle: previewStyles.eudractSubsectionHeader.objectTitle,
//             field: widgetContainer.full
//           }
//         },
//         items: {
//           ...obj.protocol.study_outcome_measures.outcome_measures.items,
//           "ui:options": {
//             ...obj.protocol.study_outcome_measures.outcome_measures.items["ui:options"],
//             styles: {

//             }
//           },
//           description: {
//             ...obj.protocol.study_outcome_measures.outcome_measures.items.description,
//             "ui:options": {
//               ...obj.protocol.study_outcome_measures.outcome_measures.items.description["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRow
//               }
//             }
//           },
//           type: {
//             ...obj.protocol.study_outcome_measures.outcome_measures.items.type,
//             "ui:options": {
//               ...obj.protocol.study_outcome_measures.outcome_measures.items.type["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRow
//               }
//             }
//           }
//         }
//       },
//       statistical_analysis_plan: {
//         ...obj.protocol.study_outcome_measures.statistical_analysis_plan,
//         "ui:options": {
//           ...obj.protocol.study_outcome_measures.statistical_analysis_plan["ui:options"],
//           styles: {
//             ...previewStyles.eudractRowLast
//           }
//         }
//       }
//     }
    
//     obj.protocol.study_reference = {
//       ...obj.protocol.study_reference,
//       "ui:options": {
//         ...obj.protocol.study_reference["ui:options"],
//         styles: {
//           ...previewStyles.sectionHeader
//         },
//       },
//       is_requesting_encepp_seal: {
//         ...obj.protocol.study_reference.is_requesting_encepp_seal,
//         "ui:options": {
//           ...obj.protocol.study_reference.is_requesting_encepp_seal["ui:options"],
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       make_full_protocol_public_when_study_ends: {
//         ...obj.protocol.study_reference.make_full_protocol_public_when_study_ends,
//         "ui:options": {
//           ...obj.protocol.study_reference.make_full_protocol_public_when_study_ends["ui:options"],
//           styles: {
//             ...previewStyles.eudractRow
//           }
//         }
//       },
//       study_publications: {
//         "ui:options": {
//           ...obj.protocol.study_reference.study_publications["ui:options"],
//           styles: {
//             arrayTitle: previewStyles.eudractSubsectionHeader.objectTitle,
//             field: widgetContainer.full
//           }
//         },
//         items: {
//           ...obj.protocol.study_reference.study_publications.items,
//           "ui:options": {
//             ...obj.protocol.study_reference.study_publications.items["ui:options"],
//             styles: {

//             }
//           },
//           url: {
//             ...obj.protocol.study_reference.study_publications.items.url,
//             "ui:options": {
//               ...obj.protocol.study_reference.study_publications.items.url["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRow
//               }
//             }
//           },
//           reference: {
//             ...obj.protocol.study_reference.study_publications.items.reference,
//             "ui:options": {
//               ...obj.protocol.study_reference.study_publications.items.reference["ui:options"],
//               styles: {
//                 ...previewStyles.eudractRow
//               }
//             }
//           }
//         }
//       },
//     }
//   }
// }

// const getEupasContactStyles = (obj, section) => {
//   return {
//     title: {
//       ...obj.protocol.contact_information.investigator_contact_details[section].title,
//       "ui:options": {
//         ...obj.protocol.contact_information.investigator_contact_details[section].title["ui:options"],
//         styles: {
//           ...previewStyles.eudractRow
//         }
//       }
//     },
//     first_name: {
//       ...obj.protocol.contact_information.investigator_contact_details[section].first_name,
//       "ui:options": {
//         ...obj.protocol.contact_information.investigator_contact_details[section].first_name["ui:options"],
//         styles: {
//           ...previewStyles.eudractRow
//         }
//       }
//     },
//     last_name: {
//       ...obj.protocol.contact_information.investigator_contact_details[section].last_name,
//       "ui:options": {
//         ...obj.protocol.contact_information.investigator_contact_details[section].last_name["ui:options"],
//         styles: {
//           ...previewStyles.eudractRow
//         }
//       }
//     },
//     address_1: {
//       ...obj.protocol.contact_information.investigator_contact_details[section].address_1,
//       "ui:options": {
//         ...obj.protocol.contact_information.investigator_contact_details[section].address_1["ui:options"],
//         styles: {
//           ...previewStyles.eudractRow
//         }
//       }
//     },
//     address_2: {
//       ...obj.protocol.contact_information.investigator_contact_details[section].address_2,
//       "ui:options": {
//         ...obj.protocol.contact_information.investigator_contact_details[section].address_2["ui:options"],
//         styles: {
//           ...previewStyles.eudractRow
//         }
//       }
//     },
//     address_3: {
//       ...obj.protocol.contact_information.investigator_contact_details[section].address_3,
//       "ui:options": {
//         ...obj.protocol.contact_information.investigator_contact_details[section].address_3["ui:options"],
//         styles: {
//           ...previewStyles.eudractRow
//         }
//       }
//     },
//     city: {
//       ...obj.protocol.contact_information.investigator_contact_details[section].city,
//       "ui:options": {
//         ...obj.protocol.contact_information.investigator_contact_details[section].city["ui:options"],
//         styles: {
//           ...previewStyles.eudractRow
//         }
//       }
//     },
//     zipcode: {
//       ...obj.protocol.contact_information.investigator_contact_details[section].zipcode,
//       "ui:options": {
//         ...obj.protocol.contact_information.investigator_contact_details[section].zipcode["ui:options"],
//         styles: {
//           ...previewStyles.eudractRow
//         }
//       }
//     },
//     country: {
//       ...obj.protocol.contact_information.investigator_contact_details[section].country,
//       "ui:options": {
//         ...obj.protocol.contact_information.investigator_contact_details[section].country["ui:options"],
//         styles: {
//           ...previewStyles.eudractRow
//         }
//       }
//     },
//     phone_number: {
//       ...obj.protocol.contact_information.investigator_contact_details[section].phone_number,
//       "ui:options": {
//         ...obj.protocol.contact_information.investigator_contact_details[section].phone_number["ui:options"],
//         styles: {
//           ...previewStyles.eudractRow
//         }
//       }
//     },
//     phone_number_alt: {
//       ...obj.protocol.contact_information.investigator_contact_details[section].phone_number_alt,
//       "ui:options": {
//         ...obj.protocol.contact_information.investigator_contact_details[section].phone_number_alt["ui:options"],
//         styles: {
//           ...previewStyles.eudractRow
//         }
//       }
//     },
//     fax_number: {
//       ...obj.protocol.contact_information.investigator_contact_details[section].fax_number,
//       "ui:options": {
//         ...obj.protocol.contact_information.investigator_contact_details[section].fax_number["ui:options"],
//         styles: {
//           ...previewStyles.eudractRow
//         }
//       }
//     },
//     email: {
//       ...obj.protocol.contact_information.investigator_contact_details[section].email,
//       "ui:options": {
//         ...obj.protocol.contact_information.investigator_contact_details[section].email["ui:options"],
//         styles: {
//           ...previewStyles.eudractRow
//         }
//       }
//     },
//     confirm_email: {
//       ...obj.protocol.contact_information.investigator_contact_details[section].confirm_email,
//       "ui:options": {
//         ...obj.protocol.contact_information.investigator_contact_details[section].confirm_email["ui:options"],
//         styles: {
//           ...previewStyles.eudractRowLast
//         }
//       }
//     },
//   }
// }