import React, { useState, useEffect} from 'react';
import styled from 'styled-components';

const CommentTag = ({
  type = "",
  style = {}
}) => {
  const [title, setTitle] = useState("");

  useEffect(() => {
    if(type) {
      setTitle(type);
    }
  }, [type])

  return (
    <Container 
      type={type}
      style={style}>
      {title}
    </Container>
  );
};

export default CommentTag;

const getBackgroundColor = (type) => {
  switch(type) {
    case "Review": return "rgba(179, 230, 255, 0.1)"
    case "Guidance": return "rgba(175, 235, 183, 0.1)"
    case "Data Source": return "rgba(255, 186, 123, 0.1);"
    case "QA Comment": return "rgba(230, 214, 255, 0.1);"
    case "Internal": return "rgba(255, 241, 240, 0.7)"
    default: return;
  }
}

const getBorderColor = (type) => {
  switch(type) {
    case "Review": return "#B3E6FF"
    case "Guidance": return "#AFEBB7"
    case "Data Source": return "#FFC888"
    case "QA Comment": return "#E6D6FF"
    case "Internal": return "#ffa39e"
    default: return;
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  padding: 2px 8px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.32px;
  color: #000000;
  background-color: ${props => getBackgroundColor(props.type)};
  border: 1px solid ${props => getBorderColor(props.type)};
  border-radius: 15px;
`