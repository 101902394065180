import { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useShallow } from 'zustand/react/shallow'

// components
import InputNumber from 'components/InputNumber/InputNumber';
import PrimeField from 'components/PrimeField/PrimeField';
import Label from 'components/Label/Label';
import DispersionRange from '../../../components/cells/DispersionRange/DispersionRange';
import DispersionInputs from '../../../components/cells/DispersionInputs/DispersionInputs';
import RJTableCommentContainer from '../../../components/RJTableCommentContainer/RJTableCommentContainer';

// context
import useResultsTableViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableViewStore';
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';
import useAuthoringCommentsStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringCommentsStore';

const Cell = ({
  showUnitsAnalyzed,
  value,
  valIndex,
  measure,
  row,
  rowIndex,
  category,
  catIndex,
  outcomeData,
  setOutcomeData,
  measureIndex
}) => {

  const {
    commentsMap
  } = useAuthoringCommentsStore(
    useShallow(state => ({
      commentsMap: state.commentsMap
    }))
  )

  const {
    readOnly,
    formView
  } = useAuthoringViewStore(
    useShallow(state => ({
      readOnly: state.readOnly,
      formView: state.formView
    }))
  )

  const {
    tableView
  } = useResultsTableViewStore()

  const { ref: visibleRef, inView: inViewport } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  });

  const [openCommentsForm, setOpenCommentsForm] = useState(false)
  const [showActions, setShowActions] = useState(false)

  const showPercentage = (item) => {
    return item.parameter_type !== "Number"
  }

  const handleValue = (e, registry_arm_id, key) => {
    let tempData = { ...outcomeData }

    Object.keys(tempData).forEach(registry => {
      let foundOMIndex = tempData[registry].outcome_measures.findIndex(om => om.id === measure.id);
      if (foundOMIndex !== -1) {
        let om = tempData[registry].outcome_measures[foundOMIndex];
        let catIndex = om.measure_rows[rowIndex].categories.findIndex(item => item.id === category.id);

        if (catIndex !== -1 && om.measure_rows[rowIndex].categories[catIndex]?.values) {
          let categoryValues = om.measure_rows[rowIndex].categories[catIndex].values
          let foundValueIndex = categoryValues.findIndex(item => item.registry_arm_id === registry_arm_id);
          if (foundValueIndex !== -1 && foundValueIndex !== undefined) {
            let val = e;
            if (e === "NA" && registry === "eudract" && (om.dispersion_type === "Inter-Quartile Range" || om.dispersion_type === "Full Range" || om.dispersion_type?.toLowerCase() === "confidence interval"
              || om.dispersion_type === "inter-quartile range (Q1-Q3)" || om.dispersion_type === "full-range (min-max)") && key === "dispersion_value_lower_bound") val = -99999;
            else if (e === "NA" && registry === "eudract") val = 99999;
            if (e === "NA" && key === "dispersion_value_upper_bound" && registry === "eudract") {
              val = 99999;
            }
            categoryValues[foundValueIndex][key] = val;

            let value = categoryValues[foundValueIndex]
            if (value.value !== "NA" && value.dispersion_value !== "NA" && value.dispersion_value_upper_bound !== "NA") {
              if (categoryValues[foundValueIndex].na_comment) {
                delete categoryValues[foundValueIndex].na_comment;
              }
            }
          }
        }
      }
    })

    setOutcomeData(tempData);
  }

  const handleCTGChange = (e, value, key) => {
    let tempData = { ...outcomeData }

    let foundOMIndex = tempData["ctg"].outcome_measures.findIndex(om => om.id === measure.id);
    if (foundOMIndex !== -1) {
      let om = tempData["ctg"].outcome_measures[foundOMIndex];
      let catIndex = om.measure_rows[rowIndex].categories.findIndex(item => item.id === category.id);
      if (catIndex !== -1) {

        let foundValueIndex = om.measure_rows[rowIndex].categories[catIndex]?.values.findIndex(item => item.registry_arm_id === value.registry_arm_id);
        if (foundValueIndex !== -1 && foundValueIndex !== undefined) {
          om.measure_rows[rowIndex].categories[catIndex].values[foundValueIndex][key] = e;
        }
      }
    }

    setOutcomeData(tempData);
  }

  const findEuArmSas = (value) => {
    const arms = outcomeData?.eudract?.outcome_measures?.[measureIndex]?.arms
    const sass = outcomeData?.eudract?.outcome_measures?.[measureIndex]?.subject_analysis_sets

    let valueArmIds = value.registry_arm_id.split(".")
    let valueArmId = valueArmIds[valueArmIds.length - 1]

    let foundArm = arms.find(arm => arm.registry_arm_id.includes(valueArmId))
    let foundSas = sass.find(sas => sas.registry_arm_id.includes(valueArmId))

    if (foundArm) {
      return foundArm
    } else if (foundSas) {
      return foundSas
    }
  }

  const getValueField = ({ value, catIndex, valIndex }) => {
    let paramType = measure?.parameter_type || ""
    let disperType = measure?.dispersion_type || ""

    if (paramType) paramType = paramType.toLowerCase()
    if (disperType) disperType = disperType.toLowerCase()

    if (category.id === "num-analyzed") {
      return (
        <div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Label style={{ marginBottom: 5 }}>Participants</Label>
            <InputNumber
              readOnly={readOnly}
              onChange={(e) => handleValue(e, value.registry_arm_id, 'value')}
              value={value?.value} />
          </div>
          {showUnitsAnalyzed &&
            <PrimeField
              schema={{
                type: "number",
                label: measure.type_units_analyzed,
                orientation: "vertical",
                label: "Units analyzed"
              }}
              readOnly={readOnly}
              containerStyle={{ marginTop: 12 }}
              onChange={(e) => handleValue(e, value.registry_arm_id, 'num_units_analyzed')}
              value={value?.num_units_analyzed || ""} />
          }
        </div>
      )
    } else if (
      (!paramType || paramType === "count of participants" || paramType === "number" || paramType === "count of units") &&
      disperType !== "confidence interval"
    ) {
      let percentage = "N/A";
      if (row?.categories[0]?.values[valIndex]) {
        let total = Number(row?.categories?.[0]?.values[valIndex].value)
        let divisor = value.value ? Number(value.value) : 0;
        if (tableView === "eudract") {
          let group = findEuArmSas(value)
          total = Number(group?.subjects_analyzed) || 0
        }
        percentage = (divisor / total) * 100;
      }

      if (isNaN(percentage)) percentage = 0;

      return (
        <div style={{ marginTop: 0, alignItems: "flex-end" }}>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <Label style={{ marginBottom: 5 }}>{formView.id === "eudra" ? "Subjects" : "Participants"}</Label>
            <InputNumber
              readOnly={readOnly}
              onChange={(e) => handleValue(e, value.registry_arm_id, 'value')}
              value={value?.value} />
          </div>

          {showPercentage(measure) && <Label style={{ marginTop: 6 }}>
            {percentage === "N/A" ? percentage : percentage.toFixed(2)}%
          </Label>}
        </div>
      )
    } else if (disperType === "standard deviation"
      || disperType === "standard error"
      || disperType === "geometric coefficient of variation") {
      return (
        <DispersionInputs
          parameterType={measure.parameter_type}
          dispersionType={measure.dispersion_type}
          dispersionValue={value.dispersion_value}
          cell={value}
          cellArmId={value.registry_arm_id}
          onChange={handleValue}
        />
      )
    } else {
      return (
        <DispersionRange
          parameterType={measure.parameter_type}
          dispersionType={measure.dispersion_type}
          dispersionValue={value.dispersion_value}
          dispersionValueUpperBound={value?.dispersion_value_upper_bound}
          cell={value}
          cellArmId={value.registry_arm_id}
          onChange={handleValue} />
      )
    }
  }

  const displayGroupHeader = () => {
    let foundArm = measure.arms?.find(arm => arm.id === value.group_id)
    let foundSas = measure.subject_analysis_sets.find(sas => sas.id === value.group_id)

    let title = foundArm?.title || foundSas?.title || "(Title)"
    let description = foundArm?.description || foundSas?.description || "(Description)"

    return (
      <Label style={{ marginBottom: 12 }}>{title}: {description}</Label>
    )
  }

  let jpath = `OutcomeRptGroup.om-cell-value-${measure.id}-${row.id}-${category.id}-${value.group_id}`

  const commentStyle = {
    position: "absolute",
    right: 8,
    top: 8
  }

  let isSas = measure?.subject_analysis_sets?.find(sas => sas.id === value.group_id)
  let classNames = [];

  if (isSas) {
    classNames.push("subject-analysis-set")
  }

  return (
    <td
      ref={visibleRef}
      style={{
        maxWidth: 150,
        position: "relative"
      }}
      className={classNames.join(" ")}
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}>
      {inViewport ? (
        <>
          {catIndex === 0 && rowIndex === 0 && tableView === "eudract" && displayGroupHeader()}
          {getValueField({ value, catIndex, valIndex })}
          {(value.value === "NA" || value.dispersion_value === "NA" || value.dispersion_value_upper_bound === "NA") && (
            <PrimeField
              schema={{
                type: "textarea",
                label: "Explain why a value is Not Available.",
                orientation: "vertical",
              }}
              readOnly={readOnly}
              containerStyle={{ marginTop: 16 }}
              value={value.na_comment || ""}
              onChange={e => handleCTGChange(e, value, "na_comment")} />
          )}
          <RJTableCommentContainer
            label={`Outcome measure field`}
            jpath={jpath}
            openCommentsForm={openCommentsForm}
            setOpenCommentsForm={setOpenCommentsForm}
            setShowMenu={setShowActions}
            showMenu={showActions || commentsMap[jpath] || openCommentsForm}
            style={commentStyle}
            modalStyle={{ top: 24 }} />
        </>
      ) : (
        <div style={{ padding: 10 }} />
      )}
    </td>
  );
};

export default Cell;