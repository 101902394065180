import React from 'react'
import PropTypes from 'prop-types'
import ActivityCellRenderer from '../ActivityCellRenderer/ActivityCellRenderer'
import "./ActivityCellRendererTable.scss"

const ActivityCellRendererTable = ({
  data, // attached_projects
  activityTypes,
  toggleModal,
  permissions,
}) => {
  return <div className="activity-cell-renderer-table">
    {data.attached_projects?.length > 0
      ? data.attached_projects.map((activity) => {
        return <div key={activity.id} className="activity-table-row">
          <div className="activity-table-cell">
            <ActivityCellRenderer
              data={data} // for recommended types
              activity={activity}
              activityTypes={activityTypes}
              permissions={permissions} // create activity
              toggleModal={toggleModal}
            />
          </div>
        </div>
      })
      // even tho there is no attached project show a row (with a possible recommended activity icon)
      : <div className="activity-table-row">
        <div className="activity-table-cell">
          <ActivityCellRenderer
            data={data} // for recommended types
            activity={null}
            activityTypes={activityTypes}
            permissions={permissions}
            toggleModal={toggleModal}
          />
        </div>
      </div>
    }
  </div>
}

ActivityCellRendererTable.propTypes = {
  data: PropTypes.shape({
    attached_projects: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        uniqid: PropTypes.string,
      })
    )
  })
}

export default ActivityCellRendererTable