import React from 'react';
import { create } from 'zustand'

export const initParticipantArmOptions = [
  {
    id: "copy-all",
    text: "Copy All Protocol Arm/Groups"
  },
  {
    id: "create-new",
    text: "Create New Arm/Group"
  },
]

export const initBaselineArmOptions = [
  {
    id: "copy-all-pf",
    text: "Copy Participant Flow Arms"
  },
  // {
  //   id: "copy-all",
  //   text: "Copy All Arm/Groups"
  // },
  {
    id: "create-new",
    text: "Create New Arm/Subject Analysis Set"
  },
  {
    id: "create-overall-reporting-group",
    text: "Create Overall Reporting Group"
  }
]

export const initOutcomeArmOptions = [
  // {
  //   id: "copy-protocol",
  //   text: "Copy Protocol Arms"
  // },
  {
    id: "copy-pf",
    text: "Copy Participant Flow Arms (CTG ONLY)"
  },
  // {
  //   id: "copy-bc",
  //   text: "Copy Baseline Characteristics Arms"
  // },
  {
    id: "create-new",
    text: "Create New Arm/Group"
  },
]

export const initAdverseArmOptions = [
  // {
  //   id: "copy-protocol",
  //   text: "Copy Protocol Arms"
  // },
  {
    id: "copy-pf",
    text: "Copy Participant Flow Arms"
  },
  // {
  //   id: "copy-bc",
  //   text: "Copy Baseline Characteristics Arms"
  // },
  {
    id: "create-new",
    text: "Create New Arm/Group"
  },
]

const initialState = {
  baselinePeriod: null,
  protocolArms: [],
  participantArmOptions: initParticipantArmOptions,
  baselineArmOptions: initBaselineArmOptions,
  outcomeArmOptions: initOutcomeArmOptions,
  baselineSas: [],
}

const useResultsTableArmsStore = create((set) => ({
  ...initialState,
  setBaselinePeriod: (baselinePeriod) => set({ baselinePeriod }),
  setProtocolArms: (protocolArms) => set({ protocolArms }),
  setParticipantArmOptions: (participantArmOptions) => set({ participantArmOptions }),
  setBaselineArmOptions: (baselineArmOptions) => set({ baselineArmOptions }),
  setOutcomeArmOptions: (outcomeArmOptions) => set({ outcomeArmOptions }),
  setAdverseArmOptions: (adverseArmOptions) => set({ adverseArmOptions }),
  setBaselineSas: (baselineSas) => set({ baselineSas }),
  resetResultsTableArmsStore: () => set(initialState),
}))

export default useResultsTableArmsStore;