import React, { Fragment } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

const StatisticalAnalysisItem = ({
  index,
  data,
  schema,
  view,
  bgColor,
  outcomeMeasure
}) => {
  let analysisSchema = schema.properties[view].properties.outcome_measure.properties.outcome_measure_analysis.properties;

  const displayValue = (fieldKey, fieldSchema) => {
    if (fieldSchema.type === "multiselect") {
      let items = fieldSchema.enum ? fieldSchema.enum : [];
      if (fieldKey === "outcome_reporting_groups") {
        items = getReportingGroupItems()
      }

      let selectedItems = []


      if (data[fieldKey]) {
        data[fieldKey].forEach(item => {
          let foundItem = items.find(option => option.id === item.arm_id);
          if (foundItem) {
            selectedItems.push(foundItem.text)
          }
        })
      }

      return <p key={uuidv4()} style={{ margin: 0 }}>{selectedItems.join(`, \n`)}</p>
    }

    let value = data[fieldKey]

    if (fieldKey === "ci_num_sides" || fieldKey === "ci_pct_value" || fieldKey === "ci_upper_limit" || fieldKey === "ci_lower_limit") {
      if ((fieldKey === "ci_num_sides" || fieldKey === "ci_pct_value") && data["ci_num_sides"] && data["ci_pct_value"]) {
        if (fieldKey === "ci_num_sides") return;
        value = `(${data["ci_num_sides"] ? data["ci_num_sides"] : "-"}) ${data[fieldKey] ? data[fieldKey] : "-"}%`
      } else if (fieldKey === "ci_upper_limit" || fieldKey === "ci_lower_limit") {
        if ((data["ci_upper_limit"] === undefined || data["ci_upper_limit"] === "" || data["ci_upper_limit"] === null) && (data["ci_lower_limit"] === undefined || data["ci_lower_limit"] === "" || data["ci_upper_limit"] === null)) {
          return;
        }
        if (data["ci_num_sides"] === "1-Sided") {
          if (data["ci_lower_limit"]) {
            if (fieldKey === "ci_lower_limit") {
              value = `${data["ci_lower_limit"] ? data["ci_lower_limit"] : "-"}`
            } else {
              return
            }
          } else if (data["ci_upper_limit"]) {
            if (fieldKey === "ci_upper_limit") {
              value = `${data["ci_upper_limit"] ? data["ci_upper_limit"] : "-"}`
            } else {
              return
            }
          }
        } else {
          if (fieldKey === "ci_lower_limit") {
            value = `${data[fieldKey] ? data[fieldKey] : "-"} to ${data["ci_upper_limit"] ? data["ci_upper_limit"] : "-"}`
          } else if (fieldKey === "ci_upper_limit") {
            return;
          }
        }
      }
    } else if (fieldKey === "dispersion_type") {
      if (data[fieldKey]) {
        value = `Type: ${data[fieldKey]}`
      }
    } else if (fieldKey === "dispersion_value") {
      if (data[fieldKey]) {
        value = `Value: ${data[fieldKey]}`
      }
    }

    return (
      <p key={uuidv4()}>{value}</p>
    )
  }

  const getReportingGroupItems = () => {
    let items = []
    if (outcomeMeasure.arms) {
      let arms = outcomeMeasure.arms.map(item => ({ id: item.id, text: `${item.title}` }))
      items = [...arms]
    }

    if (outcomeMeasure.subject_analysis_sets) {
      let subject_analysis_sets = outcomeMeasure.subject_analysis_sets.map(item => ({ id: item.id, text: `${item.title}` }))
      items = [...items, ...subject_analysis_sets]
    }
    return items
  }

  const displaySubjectsInAnalysis = () => {
    let total = 0;

    data?.outcome_reporting_groups?.forEach(group => {
      outcomeMeasure?.arms?.forEach(arm => {
        if (arm.id === group.arm_id) {
          if (arm.subjects_analyzed) {
            total += Number(arm?.subjects_analyzed) || 0
          }
        }
      })

      outcomeMeasure?.subject_analysis_sets?.forEach(arm => {
        if (arm.id === group.arm_id) {
          if (arm.subjects_analyzed) {
            total += Number(arm?.subjects_analyzed) || 0
          }
        }
      })
    })

    return (
      <tr>
        <td style={{
          backgroundColor: bgColor,
          width: 250
        }}>
          Subjects in this analysis
        </td>
        <td>
          <FieldContainer>
            {total}
          </FieldContainer>
        </td>
      </tr>
    )
  }

  return (
    <Container>
      <Title>
        {index + 1}. Statistical Analysis Set
      </Title>
      <StatisticalAnalysisItemContainer>
        <Table>
          <tbody>
            {Object.keys(analysisSchema).map((sectionKey, i) => {
              return (
                <Fragment key={uuidv4()}>
                  <tr key={sectionKey + index + i}>
                    <td style={{
                      backgroundColor: bgColor,
                      fontWeight: "bold",
                      width: 250
                    }}
                      colSpan={2}>
                      {analysisSchema[sectionKey].title}
                    </td>
                  </tr>
                  {Object.keys(analysisSchema?.[sectionKey]?.properties)?.map((rowKey, j) => (
                    <Fragment key={rowKey + index + j}>
                      <tr>
                        <td style={{
                          backgroundColor: bgColor,
                          width: 250
                        }}>
                          {analysisSchema?.[sectionKey]?.properties?.[rowKey]?.title}
                        </td>
                        <td>
                          <FieldContainer>
                            {Object.keys(analysisSchema[sectionKey]?.properties?.[rowKey]?.properties).map(fieldKey => {
                              let fieldSchema = analysisSchema[sectionKey].properties[rowKey].properties[fieldKey]
                              return displayValue(fieldKey, fieldSchema)
                            })}
                          </FieldContainer>
                        </td>
                      </tr>
                      {view === "eudract" && rowKey === "outcome_reporting_groups_container" && displaySubjectsInAnalysis()}
                    </Fragment>
                  ))}
                </Fragment>
              )
            })}
          </tbody>
        </Table>
      </StatisticalAnalysisItemContainer>
    </Container>
  );
};

export default StatisticalAnalysisItem;

const Container = styled.div`
  
`

const Title = styled.h6`
  font-weight: bold;
  margin-bottom: 8px;
`

const StatisticalAnalysisItemContainer = styled.div`
  padding-left: 24px;
`

const Table = styled.table`
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 16px;

  td, th {
    border: 1px solid #444;
    text-align: left;
    padding: 8px 12px;
  }

  th {
    font-weight: bold;
    background-color: rgb(238, 238, 254);
    text-align: center;
  }
`

const FieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 10px;
`
