import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow'

// components
import Authoring from './Authoring';

// api hooks
import { useGetStudy } from 'api/hooks/studies/useStudiesApi';
import {
  useGetAuthoringStudy,
} from 'api/hooks/studies/useAuthoringApi';
import useAuthoringPrintStore from './hooks/stores/useAuthoringPrintStore';

const AuthoringContainer = () => {
  const { studyId } = useParams();
  const { data: study, isLoading: isStudyLoading } = useGetStudy(studyId)
  const { data: authoring, isLoading: isAuthoringLoading } = useGetAuthoringStudy(study?.authoring_study_id)

  return (
    <Authoring
      study={study}
      authoring={authoring} />
  );
};

export default AuthoringContainer;