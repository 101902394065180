import React from 'react';
import { Button } from 'components-design-system'

import './preset-modal-content.scss'

const DeletePreset = ({
  setOpenModal,
  data,
  deletePreset
}) => {
  return <div className="preset-modal-content">
    <div className="actions">
      <Button
        size="sm"
        variant="outlined"
        onClick={() => setOpenModal(false)}
      >
        Cancel
      </Button>
      <Button
        size="sm"
        variant="danger"
        onClick={() => deletePreset(data)}>
        Delete
      </Button>
    </div>
  </div>
}

export default DeletePreset