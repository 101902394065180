import {
  formSchemas,
  getSchemaRegistryTitle,
} from '../constants/authoring';

/**
 * Using formSchemas constant,
 * given target section and optional current registry view,
 *   return current registry view if section contains that registry.
 * If target section does not contain current registry,
 *   return default registry view.
 * `section` and `currenRegisry` in format: { id, text }
 * @param {Object} section
 * @param {Object} currentRegistry optional current registry view
 * @param {String} defaultRegId default registry key, if no registry
 * @returns registry view {Object}
 */
const getSectionRegistry = (section, currentRegistry, defaultRegId = 'global') => {
  if (section) {
    // find section in schema
    let sectSchema = null;
    for (const schema in formSchemas) {
      sectSchema = formSchemas[section.id];
    };

    // find registry in section
    let regId = defaultRegId;
    if (currentRegistry) {
      regId = Object.keys(sectSchema.properties).find((key) => key === currentRegistry.id);
      // if current registry not in current section
      if (!regId) regId = defaultRegId;
    }
    return {
      id: regId,
      text: getSchemaRegistryTitle(section.id, regId)
    };
  };
  return null;
};

export {
  getSectionRegistry,
}