const resultsFiltersMap = {
  "Mandatory": "mandatory",
  "Mandatory (Manual)": "mandatory",
  "Opted In": "mandatory",
  "In Scope": "mandatory", // not rcv'd from back end

  "Not Required": "not-required",
  "Not Required (Manual)": "not-required",
  "Opted Out": "not-required",
  "Out of Scope": "not-required", // not rcv'd from back end

  "Voluntary": "voluntary",
  "Optional": "voluntary", // not rcv'd from back end

  "Insufficient Data": "insufficient-data", // not rcv'd from back end
  "Not Yet Assessed": "insufficient-data", // item.missing_fields?.length > 0
}

const resultsFilterLabels = {
  'all': 'All',
  'mandatory': 'Mandatory',
  'not-required': 'Not required',
  'voluntary': 'Voluntary',
  'insufficient-data': 'Insufficient data',
}

export {
  resultsFiltersMap,
  resultsFilterLabels,
}