export const isArmIdMatch = (a, b) => {
  let bIds = b.split(".")
  return a.includes(bIds[bIds.length - 1]);
}

export const getBaselinePeriod = (formData) => {
  if(formData?.results?.participant_flow?.participant_flow_table?.eudract_periods) {
    return formData.results.participant_flow.participant_flow_table.eudract_periods.find(period => period?.baseline_period === "Yes");
  }

  return null;
}

export const isGroupInBaselinePeriod = (formData, group) => {
  if(formData?.results?.participant_flow?.participant_flow_table?.eudract_periods) {
    let baselinePeriod = formData.results.participant_flow.participant_flow_table.eudract_periods.find(period => period?.baseline_period === "Yes");

    if(baselinePeriod?.arms.find(arm => {
      let periodArmIds = arm.id.split(".")
      let compareGroup = group.id.split(".")
      if(periodArmIds[periodArmIds.length - 1] === compareGroup[compareGroup.length - 1]) {
        return true;
      }
      return false;
    })) {
      return true;
    }
  }

   return false;
}