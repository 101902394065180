import React from 'react';
import { useShallow } from 'zustand/react/shallow'
import Label from 'components/Label/Label';
import useAuthoringDataStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringDataStore';

// styles
import './MscPreview.scss'

const MscPreview = (props) => {
  const {
    formData,
  } = useAuthoringDataStore(
    useShallow(state => ({
      formData: state.formData,
    }))
  )

  const getTotalEEASubjects = () => {
    if (props.value?.length === 0 || !props.value) return 0;

    let total = 0;
    props.value.forEach(item => {
      total += Number(item.subjects);
    })

    return total;
  }

  const getTotalSubjects = () => {
    if (!props.value || props.value.length === 0) return 0;

    let total = 0;
    props.value.forEach(item => {
      total += Number(item.subjects);
    })

    if (formData?.protocol?.study_scope?.enrollment_number) {
      total += Number(formData?.protocol.study_scope.enrollment_number)
    }

    return total;
  }

  const getRestOfWorldSubjects = () => {
    let total = 0;
    if (formData?.protocol?.study_scope?.enrollment_number) {
      total = formData?.protocol.study_scope.enrollment_number
    }
    return total;
  }

  return (
    <div className="msc-result-table member-states-concerned-container">
      <table>
        <thead>
          <tr>
            <td className='header-column'>
              Member states concerned
            </td>
            <td className='header-column' style={{ width: 150, maxWidth: 150 }}>
              RMS
            </td>
            <td className='header-column'>
              First submissions date
            </td>
            <td className='header-column'>
              Subjects
            </td>
          </tr>
        </thead>
        <tbody>
          {props?.value?.map((row, i) => {
            return (
              <tr key={i}>
                <td>
                  <div className="country-member-state-container">
                    {row.country}
                  </div>
                </td>
                <td>
                  <div className="country-member-state-container">
                    {row.rms ? "Yes" : "No"}
                  </div>
                </td>

                <td>
                  {row.first_submission_date}
                </td>
                <td style={{ position: "relative" }}>
                  {row.subjects}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className="member-states-concerned-details-container">
        <div className="member-states-concerned-total-container">
          <Label>
            Countries outside the European Economic Area
          </Label>
          <div className="member-states-concerned-totals-container">
            <div className="member-states-concerned-total-subjects-container">
              <Label>Rest of the world subjects*</Label>
              <p className="member-states-concerned-total-item-value">{getRestOfWorldSubjects()}</p>
            </div>
          </div>
        </div>
        <div className="member-states-concerned-total-container">
          <Label>
            Estimated total population for the trial
          </Label>
          <div className="member-states-concerned-totals-container">
            <div className="member-states-concerned-total-subjects-container">
              <Label>EEA subjects</Label>
              <p className="member-states-concerned-total-item-value">{getTotalEEASubjects()}</p>
            </div>
            <div className="member-states-concerned-total-subjects-container">
              <Label>Rest of the world subjects*</Label>
              <p className="member-states-concerned-total-item-value">{getRestOfWorldSubjects()}</p>
            </div>
            <div className="member-states-concerned-total-subjects-container">
              <Label>Total subjects</Label>
              <p className="member-states-concerned-total-item-value">{getTotalSubjects()}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MscPreview;