import React, { useState, useEffect } from 'react';

// Components
import { Button } from 'components-design-system'
import PrimeField from 'components/PrimeField/PrimeField';
import './preset-modal-content.scss'

const AddPreset = ({
  setOpenModal,
  // data,
  presets,
  savePreset
}) => {
  const [presetName, setPresetName] = useState("");
  const [isInvalid, setInvalid] = useState(false);

  useEffect(() => {
    if (presetName) {
      let foundIndex = presets?.findIndex(preset => preset.name.toLowerCase() === presetName.toLowerCase());
      if (foundIndex !== -1) {
        setInvalid(true);
      } else {
        setInvalid(false);
      }
    }
  }, [presetName]);

  return <div className="preset-modal-content">
    <div className="modal-content">
      <PrimeField
        schema={{
          label: "Preset name",
          orientation: "vertical",
          type: "text",
          placeholder: "Enter preset name"
        }}
        showError={isInvalid}
        errorMessage={"A preset with that name already exists, please select a different name."}
        value={presetName}
        onChange={setPresetName} />
      {/* <TextInput 
          id="preset-title-input"
          value={presetName}
          labelText="Preset Name"
          placeholder="Enter Preset Name"
          invalid={isInvalid}
          invalidText="A preset with that name already exists, please select a different name."
          onChange={e => setPresetName(e.target.value)}/> */}
    </div>
    <div className="actions">
      <Button
        size="sm"
        variant="outlined"
        onClick={() => setOpenModal(false)}
      >
        Cancel
      </Button>
      <Button
        size="sm"
        disabled={isInvalid}
        variant="primary"
        onClick={() => savePreset(presetName)}>
        Save
      </Button>
    </div>
  </div>
}

export default AddPreset