import React from 'react'
import styled from 'styled-components'
import { Tooltip } from 'antd'

import './VerificationBadge.scss'

const map = {
  "Verified": {
    className: "verified",
    label: "V"
  },
  "Unverified": {
    className: "unverified",
    label: "U"
  },
  "Archived": {
    className: "archived",
    label: "A"
  },
}

const VerificationBadge = (props) => {
  const renderTooltip = () => {
    return (
      <div>
        <div>{props.value}</div>
      </div>
    )
  }

  let classNames = ["verification-badge"]
  if(map?.[props.value]?.className) classNames.push(map[props.value].className)

  return (
    <Tooltip
      title={renderTooltip()}>
      <div className={classNames.join(" ")}>
        {map?.[props.value]?.label}
      </div>
    </Tooltip>
  )
}

export default VerificationBadge