import { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

// components
import { Button } from 'components-design-system';
import PRSUpload from 'components/PRSUpload/PRSUpload';
import PRSUploadErrorMessages from 'components/PRSUploadErrorMessages/PRSUploadErrorMessages';
import PRSUploadProgress from 'components/PRSUploadProgress/PRSUploadProgress';
import CancelPRSUpload from 'components/CancelPRSUpload/CancelPRSUpload';
import Modal from 'components/Modal/Modal';

// utils
import { getErrors } from 'utilities/prs-upload';
import getUrl from 'api/apiMap';
import apiEndpoints from 'api/utils/apiEndpoints';
import {
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

// context
import useAppState from 'context/hooks/useAppState';

import './usePrsUpload.scss';

const usePrsUpload = ({
  useOnGetPrsUpload,
  useOnPostPrsUpload,
  isUploadDisabled,
  prsUploadStudyList,
  gridRef,
  uploadToPrsMsg = "Send all site updates to PRS. If you would like to release data directly to CTG check the checkbox below.",
  callbackAfterUpload = null
}) => {
  const { pathname } = useLocation()
  const {
    openToastNotification
  } = useAppState()

  const [queueId, setQueueId] = useState(null)
  const onPostPrsUpload = useOnPostPrsUpload();
  const queryClient = useQueryClient()
  const {
    data: pollingData
  } = useOnGetPrsUpload(queueId, {
    refetchInterval: queueId ? 1000 : null,
    enabled: queueId ? true : false
  })
  const [isAutoRelease, setIsAutoRelease] = useState(false)

  // modals
  const [openModal, setOpenModal] = useState(false);
  const [openProgressModal, setProgressModal] = useState(false);
  const [modalConfig, setModalConfig] = useState({});
  const [uploadComplete, setUploadComplete] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false)

  const onOpenModal = (variant, data) => {
    switch (variant) {
      case "prs-upload":
        setModalConfig({
          header: "Upload to PRS",
          subheader: uploadToPrsMsg,
          size: "sm",
          children: <PRSUpload
            setOpenModal={setOpenModal}
            onOpenModal={onOpenModal}
            onUploadToPRS={onUploadToPRS} />
        })
        setOpenModal(true);
        break;
      case "prs-upload-progress":
        setModalConfig({
          header: "Upload in Progress",
          subheader: "See progress of individual study upload below",
          children: <PRSUploadProgress data={pollingData} setOpenModal={setOpenModal} />
        })
        setOpenModal(true);
        break;
      case "prs-upload-error-messages":
        setModalConfig({
          header: "CTG Upload Error",
          subheader: "There are are [4] values that must be updated in order to upload to CTG.",
          children: <PRSUploadErrorMessages data={data} setOpenModal={setOpenModal} />
        })
        setOpenModal(true);
        break;
      case "cancel-upload-confirmation":
        setModalConfig({
          header: "Cancel Upload?",
          subheader: "Are you sure you would like to cancel this upload? Some studies may have already uploaded, any remaining studies will not be uploaded to PRS or publicly released.",
          size: "sm",
          children: <CancelPRSUpload
            onCancelUpload={onCancelUpload}
            setOpenModal={setOpenModal} />
        })
        setOpenModal(true);
        break;
      default: return;
    }
  }

  useEffect(() => {
    if (pollingData?.in_progress === false && queueId) {
      if(callbackAfterUpload) callbackAfterUpload(pollingData)
      onStopPolling()
    }
  }, [pollingData])

  const onUploadToPRS = (autoRelease, verifyDate) => {
    let studyList = prsUploadStudyList || []
    let selectedNodes = gridRef?.current?.api?.getSelectedNodes()
    if (selectedNodes?.length > 0) {
      studyList = selectedNodes.map(rowNode => rowNode.data.study_db_id)
    }

    setIsAutoRelease(autoRelease)
    let req = {
      stu_id_list: studyList,
      autorelease: autoRelease,
      update_ver_date: verifyDate
    }

    onPostPrsUpload({
      body: req
    }, {
      onSuccess: (resp) => {
        let {
          queue_id,
          task_id
        } = resp.data
        setQueueId(queue_id)
      }
    })

    setUploadComplete(false);
    setIsUploading(true);
    setOpenModal(false);
  }

  const hasErrors = useMemo(() => {
    if (pollingData && pollingData.upload_records) {
      for (let i = 0; i < pollingData.upload_records.length; i++) {
        const record = pollingData.upload_records[i]
        if (record.failureMessage) {
          return true
        }
        let validations = record?.upload_report?.validationMessages?.validationMessage
        for (let j = 0; j < validations?.length; j++) {
          let msg = validations[i]
          if (msg?.severity === "ERROR") return true
        }
      }
    } else if (pollingData?.task_status?.state === "FAILURE") {

      return true
    }
    return false
  }, [pollingData])

  const UploadModals = (
    <div className="prs-upload-hook-container">
      {openModal && <Modal
        title={modalConfig.header}
        message={modalConfig.subheader}
        onCancel={() => setOpenModal(false)}
        size={modalConfig.size}
        hideImage={true}
        open={openModal}
        footer={[]}>
        {modalConfig.children}
      </Modal>}
      <Modal
        title={uploadComplete ? "Upload Summary" : "Upload in Progress"}
        open={openProgressModal}
        hideImage={true}
        onCancel={() => setProgressModal(false)}
        footer={[]}
        width={hasErrors || pollingData?.task_status?.state === "FAILURE" ? 900 : 600}>
        <div className="subheading">{uploadComplete ? "The upload is complete. Please see status of uploads below." : "See progress of individual study upload below"}</div>
        <PRSUploadProgress
          isAutoRelease={isAutoRelease}
          uploadComplete={uploadComplete}
          hasErrors={hasErrors || pollingData?.task_status?.state === "FAILURE"}
          data={pollingData}
          setOpenModal={setProgressModal} />
        <div style={{ marginTop: 16 }}>
          {isUploading && <Button variant="link-danger" onClick={() => onOpenModal("cancel-upload-confirmation")}>Cancel Upload</Button>}
        </div>
      </Modal>
    </div>
  )

  const onCancelUpload = () => {
    setIsCancelling(true)

    let url = pathname.includes("/locations/") || pathname.includes("/site-management") ? apiEndpoints.cancelSitesUpload : apiEndpoints.cancelProtocolUpload

    return axios.get(getUrl(url), {
      params: {
        "queue-id": queueId
      }
    })
      .then(resp => {
        queryClient.setQueryData([], () => ({
          ...resp.data
        }))
        onStopPolling()
        openToastNotification("info", "NOTE", "Upload cancelled")
      })
  }

  const onStopPolling = () => {
    setProgressModal(false)
    setUploadComplete(true);
    setIsUploading(false)
    setOpenModal(false);
    setQueueId(null)
    setIsCancelling(false)
    setProgressModal(true)
  }

  const UploadButton = (
    <Button
      disabled={isUploadDisabled}
      size="sm"
      style={{ display: "flex", alignItems: "center" }}
      onClick={() => isUploading ? setProgressModal(true) : onOpenModal("prs-upload")}>
      {isUploading
        ? <><i className="fal fa-redo icons spin-active" /> Upload in Progress</>
        : <><i className="fal fa-cloud-upload icons" /> Upload to PRS</>
      }
    </Button>
  )

  const CancelButton = (
    <div
      className={`cancel-prs-upload-btn ${isCancelling ? "is-cancelling-prs-upload" : ""}`}
      onClick={() => onOpenModal("cancel-upload-confirmation")}>
      Cancel Upload
    </div>
  )

  return {
    isUploading,
    UploadModals,
    UploadButton,
    CancelButton
  }
}

export default usePrsUpload