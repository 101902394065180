import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { useShallow } from 'zustand/react/shallow'

// components
import StudyDetailsHeader from './SiteDetails/StudyDetailsHeader'
import CountriesPanel from './CountriesPanel/CountriesPanel'
import StudyDetailsContent from './SiteDetails/StudyDetailsContent'
import Loading from 'components/Loading/Loading'

// api
import { useUserPermissions } from 'api/hooks'
import {
  useGetSiteManagementStudy,
  useGetSiteManagementLocations,
  useDownloadPrsSites
} from 'api/hooks/siteManagement/useSiteManagementApi'
import { useGetStudy } from 'api/hooks/studies/useStudiesApi'
import { useGetCountries } from 'api/hooks/enum'

// utils
import { isEqual, transform, isArray, isObject } from 'lodash'
import { countries as countriesConstant } from 'constants/countries'

// context
import useAppState from 'context/hooks/useAppState'
import useAuthoringValidationsStore from '../Authoring/hooks/stores/useAuthoringValidationsStore'

// constants
import queryKeys from 'api/utils/queryKeys'

// styles
import './Locations.scss'

const Locations = () => {
  
  const {
    setPollingTaskId,
    setTaskType,
    setPollingCallback,
  } = useAppState()

  const {
    validations,
    setSitesValidations
  } = useAuthoringValidationsStore(
    useShallow(state => ({
      validations: state.validations,
      setSitesValidations: state.setSitesValidations,
    }))
  )
  
  

  const { studyId, siteManagementId } = useParams()
  const { data: permissions } = useUserPermissions()
  const { data: countries = countriesConstant } = useGetCountries()
  const queryClient = useQueryClient()

  const downloadPrsSites = useDownloadPrsSites(siteManagementId)
  const {
    isLoading: isStudyLoading,
    data: study,
  } = useGetStudy(studyId)
  const {
    data: siteManagementLocations,
  } = useGetSiteManagementLocations(siteManagementId)

  const {
    data: siteManagementStudy
  } = useGetSiteManagementStudy(siteManagementId)

  const studyData = study?.study_data

  const [siteList, setSiteList] = useState([]);
  // const [studyInfo, setStudyInfo] = useState(null);
  const [lastUploadToCTGDate, setLastUploadToCTGDate] = useState();
  const [triggerExport, setTriggerExport] = useState(false);


  const [locationStats, setLocationStats] = useState({
    total_prime: 0,
    total_ctg: 0,
    prime_ctg_differences: 0,
    ctg_submission_differences: 0
  })

  const [disabledUpload, setDisabledUpload] = useState(false);
  const [nonSiteErrors, setNonSiteErrors] = useState([])

  useEffect(() => {
    if (siteManagementStudy) {
      if ((siteManagementStudy.only_on_ctg && !siteManagementStudy.only_on_source) ||
        (!siteManagementStudy.only_on_ctg && siteManagementStudy.only_on_source) ||
        (siteManagementStudy.only_on_ctg && siteManagementStudy.only_on_source)) {
        setDisabledUpload(true);
      }
    }
  }, [siteManagementStudy])

  useEffect(() => {
    init()
  }, [siteManagementLocations])

  useEffect(() => {
    if (validations) {
      const nonSiteErrors = []

      if (validations?.unmapped_validations) {
        getSiteValidations(validations.unmapped_validations, nonSiteErrors)
      }

      if (validations?.validations) {
        getSiteValidations(validations.validations, nonSiteErrors)
      }

      setNonSiteErrors(nonSiteErrors)
    }
  }, [validations])

  const getSiteValidations = (arr, nonSiteErrors) => {
    arr.forEach(validation => {
      if (!validation.hasOwnProperty("site_db_id")) {
        nonSiteErrors.push(validation)
      }
    })
  }

  const getValidationsAfterUpload = (pollingData) => {
    if (pollingData?.upload_records?.[0]?.upload_report?.validationMessages?.validationMessage) {
      const nonSiteErrors = []
      const validations = pollingData.upload_records[0].upload_report.validationMessages.validationMessage
      getSiteValidations(validations, nonSiteErrors)
      setSitesValidations(validations)
      setNonSiteErrors(nonSiteErrors)
    }
  }

  const init = async () => {
    let sortedData = getDifferenceCount(siteManagementLocations);
    setSiteList(sortedData)
  }

  const getDifferenceCount = (data) => {
    let locationStats = {
      total_prime: {
        count: 0,
        studies: []
      },
      total_ctg: {
        count: 0,
        studies: []
      },
      prime_ctg_differences: {
        count: 0,
        studies: []
      },
      ctg_submission_differences: {
        count: 0,
        studies: []
      }
    }

    let newSites = [];
    let recruitmentSites = [];
    let diffSites = [];

    let noSourceSites = [];
    let otherSites = []

    if (data && data.length > 0 && Array.isArray(data)) {
      data?.forEach(location => {
        if (location.location_data) {
          let { source, ctg, mask } = location.location_data;
          let verifyCTG = null;
          let verifySource = null;

          // Finds SM and CTG differences
          if (source && ctg) {
            let sZip = "";
            let sContactPhone = "";
            let sContactExt = "";
            let sBackupContactPhone = "";
            let sBackupContactExt = "";

            let cZip = "";
            let cContactPhone = "";
            let cContactExt = "";
            let cBackupContactPhone = "";
            let cBackupContactExt = "";


            if (source.facility && source.facility.address && source.facility.address.zip) sZip = parseInt(source.facility.address.zip);
            if (source.contact && source.contact.phone) sContactPhone = source.contact.phone.toString();
            if (source.contact && source.contact.phone_ext) sContactExt = source.contact.phone_ext.toString();
            if (source.contact_backup && source.contact_backup.phone) sBackupContactPhone = source.contact_backup.phone.toString();
            if (source.contact_backup && source.contact_backup.phone_ext) sBackupContactExt = source.contact_backup.phone_ext.toString();

            if (ctg.facility && ctg.facility.address && ctg.facility.address.zip) cZip = parseInt(ctg.facility.address.zip);
            if (ctg.contact && ctg.contact.phone) cContactPhone = ctg.contact.phone.toString();
            if (ctg.contact && ctg.contact.phone_ext) cContactExt = ctg.contact.phone_ext.toString();
            if (ctg.contact_backup && ctg.contact_backup.phone) cBackupContactPhone = ctg.contact_backup.phone.toString();
            if (ctg.contact_backup && ctg.contact_backup.phone_ext) cBackupContactExt = ctg.contact_backup.phone_ext.toString();

            verifySource = {
              facility: {
                name: source.facility && source.facility.name ? source.facility.name : "",
                city: "",
                state: "",
                country: "",
                ...source.facility.address,
                zip: sZip
              },
              contact: {
                first_name: "",
                last_name: "",
                middle_name: "",
                email: "",
                degrees: "",
                ...source.contact,
                phone: sContactPhone,
                phone_ext: sContactExt
              },
              contact_backup: {
                first_name: "",
                last_name: "",
                middle_name: "",
                email: "",
                degrees: "",
                ...source.contact_backup,
                phone: sBackupContactPhone,
                phone_ext: sBackupContactExt
              },
              investigator: source.investigator,
              status: source.status
            };

            verifyCTG = {
              facility: {
                name: ctg.facility && ctg.facility.name ? ctg.facility.name : "",
                city: "",
                state: "",
                country: "",
                ...ctg.facility.address,
                zip: cZip
              },
              contact: {
                first_name: "",
                last_name: "",
                middle_name: "",
                email: "",
                degrees: "",
                ...ctg.contact,
                phone: cContactPhone,
                phone_ext: cContactExt
              },
              contact_backup: {
                first_name: "",
                last_name: "",
                middle_name: "",
                email: "",
                degrees: "",
                ...ctg.contact_backup,
                phone: cBackupContactPhone,
                phone_ext: cBackupContactExt
              },
              investigator: ctg.investigator ? ctg.investigator : [],
              status: ctg.status
            };

            Object.keys(verifySource).map(key => {
              let field = verifySource[key];
              if (key !== "investigator") {
                if (typeof field === 'object' && field !== null) {
                  Object.keys(field).map(fKey => {
                    if (field[fKey] === null) verifySource[key][fKey] = "";
                    else if (field[fKey]?.length > 0) verifySource[key][fKey] = verifySource[key][fKey].trim()
                  })
                } else {
                  if (field === null) verifySource[key] = "";
                  else if (field?.length > 0) verifySource[key] = verifySource[key].trim();

                }
              } else if (key === "investigator" && field && field.length !== 0) {
                if (!Array.isArray(field)) field = [field];
                let newInvestigator = field.map(obj => {
                  let newObj = {
                    "first_name": "",
                    "last_name": "",
                    "middle_name": "",
                    "degrees": "",
                    ...obj
                  }
                  Object.keys(newObj).forEach(fKey => {
                    if (newObj[fKey] === null) newObj[fKey] = ""
                    else if (newObj[fKey]?.length > 0) newObj[fKey] = newObj[fKey].trim()
                  })
                  return newObj
                })
                verifySource[key] = newInvestigator;
              }
            })

            Object.keys(verifyCTG).map(key => {
              let field = verifyCTG[key];
              if (key !== "investigator") {
                if (typeof field === 'object' && field !== null) {
                  Object.keys(field).map(fKey => {
                    if (field[fKey] === null) verifyCTG[key][fKey] = "";
                    else if (field[fKey]?.length > 0) verifyCTG[key][fKey] = verifyCTG[key][fKey].trim()
                  })
                } else {
                  if (field === null) verifyCTG[key] = "";
                  else if (field?.length > 0) verifyCTG[key] = verifyCTG[key].trim();
                }
              } else if (key === "investigator" && field && field.length !== 0) {
                if (!Array.isArray(field)) field = [field];
                let newInvestigator = field.map(obj => {
                  let newObj = {
                    "first_name": "",
                    "last_name": "",
                    "middle_name": "",
                    "degrees": "",
                    ...obj
                  }
                  Object.keys(newObj).forEach(fKey => {
                    if (newObj[fKey] === null) newObj[fKey] = ""
                    else if (newObj[fKey]?.length > 0) newObj[fKey] = newObj[fKey].trim()
                  })
                  return newObj
                })
                verifyCTG[key] = newInvestigator;
              }
            })
          }

          if (verifySource && !verifySource.investigator) verifySource.investigator = []

          if (!isEqual(verifySource, verifyCTG)) {
            let res = difference(verifySource, verifyCTG)
            diffSites.push(location)
            locationStats.prime_ctg_differences.count++
            locationStats.prime_ctg_differences.studies.push({ ...location })
          }
          if (location.is_new) {
            newSites.push(location)
          }

          if (location.recruitment_status_changed) {
            recruitmentSites.push(location)
          } else


            if (location.only_on_ctg) {
              noSourceSites.push(location)
            } else {
              otherSites.push(location)
            }

          // Finds SM and Submission differences
          if (compareSMvsSubmissionCount(verifySource, verifyCTG, mask)) {
            locationStats.ctg_submission_differences.count++
            locationStats.ctg_submission_differences.studies.push({ ...location })
          }

          if (location.only_on_ctg === false) {
            locationStats.total_prime.count++
            locationStats.total_prime.studies.push(location)
          }

          if (location.only_on_source === false) {
            locationStats.total_ctg.count++
            locationStats.total_ctg.studies.push(location)
          }
        }
      })
    }
    let sortedData = [...newSites, ...recruitmentSites, ...diffSites, ...otherSites, ...noSourceSites];
    setLocationStats(locationStats)

    return sortedData;
  }

  const compareSMvsSubmissionCount = (source, ctg, mask) => {
    if (ctg) {
      let submission = { ...source }
      let verifyMask = {
        ...mask,
      }

      if (mask) {
        if (mask.address) {
          let facility = {}
          Object.keys(mask.address).forEach(key => {
            if (key === "facility") facility.name = mask.address.facility.name
            else facility[key] = mask.address[key]
          })
          verifyMask.facility = facility;
        }

        if (mask?.investigator && mask.investigator.length > 0) {
          let isInvestigatorNull = true;

          mask.investigator.forEach(investigator => {
            Object.keys(investigator).forEach(key => {
              if (key !== "role" && investigator[key].length > 0) isInvestigatorNull = false;
            })
          })
          if (isInvestigatorNull) {
            verifyMask.investigator = [];
          }
        }
        submission = { ...source, ...verifyMask }
      }

      if (!submission?.investigator) submission.investigator = [];

      if (!isEqual(submission, ctg)) {
        return true
      }
    }
    return false;
  }

  const onRegenerate = () => {
    downloadPrsSites({}, {
      onSuccess: resp => {
        setPollingCallback(() => (pollData) => {
          queryClient.invalidateQueries(queryKeys.siteManagement.list)
        })
        setPollingTaskId(resp?.data?.task_id)
        setTaskType("site-management-study-regenerate")
      }
    })
  }

  const difference = (origObj, newObj) => {
    function changes(newObj, origObj) {
      let arrayIndexCounter = 0
      return transform(newObj, function (result, value, key) {
        if (!isEqual(value, origObj[key])) {
          let resultKey = isArray(origObj) ? arrayIndexCounter++ : key
          result[resultKey] = (isObject(value) && isObject(origObj[key])) ? changes(value, origObj[key]) : value
        }
      })
    }
    return changes(newObj, origObj)
  }

  if (isStudyLoading) {
    return <Loading />
  }

  return <div className="study-locations-container">
    <div className="study-locations-row">
      <CountriesPanel
        permissions={permissions}
        countries={countries}
        actualCountries={studyData?.actual_countries}
        plannedCountries={studyData?.planned_countries}
        studyId={study?.study_id}
      />
    </div>
    <div className="study-details-header-wrap">
      <StudyDetailsHeader
        disabledUpload={disabledUpload}
        onRegenerate={onRegenerate}
        lastUploadToCTGDate={lastUploadToCTGDate}
        setTriggerExport={setTriggerExport}
        nonSiteErrors={nonSiteErrors}
        siteManagementStudy={siteManagementStudy}
        getValidationsAfterUpload={getValidationsAfterUpload} />
    </div>
    <div className="study-details-content-wrap">
      <StudyDetailsContent
        siteList={siteList}
        siteManagementStudy={siteManagementStudy}
        setTriggerExport={setTriggerExport}
        triggerExport={triggerExport}
        locationStats={locationStats}
      />
    </div>
    {/* </ContentContainer> */}
  </div>
}

export default Locations