import React, { useEffect, useState } from 'react'
import { Tooltip } from 'antd'
import './EditSite.scss'

const EditSite = ({
  onOpenModal,
  ...props
}) => {
  const { data } = props
  const [disabled, setDisabled] = useState(false);
  const [variant, setVariant] = useState("");

  useEffect(() => {
    if (data) {
      if (data.only_on_ctg) {
        setDisabled(true)
        setVariant("no-source")
      }
    }
  }, [data])

  const handleOpenModal = (evt) => {
    // evt.stopImmediatePropagation() // this stops the edit checkbox from opening the modal
    if (!disabled) onOpenModal("edit-site", props)
  }

  if (disabled) {
    return <div className={`study-loc-edit-site-cell-rend ${disabled ? ' is-disabled' : ''}`}>
      <Tooltip
        title={<div className="edit-site-title">
          This site will not be submitted since it is not present in Site Management.
        </div>}
      >
        <i
          className="fas fa-pen-square edit-site-icon"
          disabled={disabled}
          onClick={handleOpenModal} />
      </Tooltip>
    </div>
  }
  if (props.permissions["study.site.edit"])
    return <div className={`study-loc-edit-site-cell-rend ${disabled ? ' is-disabled' : ''}`}>
      <i
        className="fas fa-pen-square edit-site-icon"
        disabled={disabled}
        onClick={handleOpenModal}
      />
    </div>
  return null
}

export default EditSite