import { useState, useEffect, useContext, createContext, useRef, useMemo } from 'react'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import axios from 'axios'
import useToastNotifications from 'utilities/useToastNotifications'
import {
  useQueryClient,
} from '@tanstack/react-query'
import useRefreshToken from 'utilities/useRefreshToken';

// apis
import { usePollTask } from 'api/hooks'
import queryKeys from 'api/utils/queryKeys'
import getUrl from 'api/apiMap'
import apiEndpoints from 'api/utils/apiEndpoints'

const AppStateContext = createContext({})

export const AppStateProvider = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const [blurLoading, setBlurLoading] = useState(false)
  const [sidebarOpen, setSidebarOpen] = useState(localStorage.getItem('SIDEBAR_STATE') === "true" ? true : false)
  const [refetchStudiesGrid, setRefetchStudiesGrid] = useState(false)
  const [publicEnvVar, setPublicEnvVars] = useState("3.0")
  const [isMounted, setIsMounted] = useState(false);
  const { setNextUrlLocalStorage } = useRefreshToken()
  const queryClient = useQueryClient()

  // polling
  const [pollingTaskId, setPollingTaskId] = useState(null)
  const [taskType, setTaskType] = useState(null)
  const [pollingCallback, setPollingCallback] = useState(null)

  /*
    App state
      1. initializing
      2. authorizing
      3. ready
  */
  const [appState, setAppState] = useState("initializing")

  const appStateRef = useRef("init")
  const { data: pollingData } = usePollTask(pollingTaskId, {
    refetchInterval: pollingTaskId ? 1000 : null,
    enabled: pollingTaskId ? true : false,
    notifyOnChangeProps: ["data", "tracked"]
  })

  const {
    openToastNotification,
    toastContextHolder,
  } = useToastNotifications()

  useEffect(() => {
    if (pollingData?.complete) {
      setPollingTaskId(null)
      setTaskType(null)
      if (pollingData?.success) {
        if (pollingData?.result?.success_msg) {
          openToastNotification("success", "Success", pollingData?.result.success_msg)
        } else {
          let successMsg = pollingData?.result || "Task Complete"
          openToastNotification("success", "Complete", successMsg)
        }

        if (pollingData?.result?.error_msg) {
          openToastNotification("error", "Error", pollingData.result.error_msg)
        }

        if (typeof pollingCallback === 'function') {
          pollingCallback(pollingData)
          setPollingCallback(null)
        }
      } else if (pollingData?.state === "FAILURE") {
        openToastNotification("error", "Error", pollingData?.result)
      }
      queryClient.removeQueries(queryKeys.pollingTask)

    }
  }, [pollingData])

  useEffect(() => {
    if (location?.pathname) {
      let nextUrl = location.pathname
      if (location.search) nextUrl += location.search;
      setNextUrlLocalStorage(nextUrl)
    }
  }, [])

  useEffect(() => {
    if (appState === "authenticated") {
      let nextUrl = localStorage.getItem("prime-next-url")
      if (nextUrl) {
        navigate(nextUrl)
        localStorage.removeItem("prime-next-url")
      }
    }
  }, [appState])

  const onCancelTask = () => {
    return axios.get(getUrl(apiEndpoints.cancelTask), {
      params: {
        "queue-id": pollingTaskId
      }
    })
      .then(resp => {
        setPollingTaskId(null)
        setTaskType(null)
        openToastNotification("info", "NOTE", "Task cancelled")
      })
  }

  return <AppStateContext.Provider
    value={{
      // body blur for loading:
      blurLoading,
      setBlurLoading,
      // side bar control:
      sidebarOpen,
      setSidebarOpen,
      // notifications:
      openToastNotification,
      toastContextHolder,
      appState,
      setAppState,
      appStateRef,
      refetchStudiesGrid,
      setRefetchStudiesGrid,
      publicEnvVar,
      setPublicEnvVars,
      // polling:
      pollingTaskId,
      setPollingTaskId,
      taskType,
      setTaskType,
      pollingData,
      setPollingCallback,
      isMounted,
      setIsMounted,
      onCancelTask
    }}
  >
    {children}
  </AppStateContext.Provider>
}

const useAppState = () => {
  return useContext(AppStateContext)
}

export default useAppState