import { useState, useEffect } from 'react';
import { Form, DatePicker } from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types'

// api
import { useUserSettings } from 'api/hooks';

// styles
import './DateRangePicker.scss'

const { RangePicker } = DatePicker;

const DateRangePicker = ({
  defaultValue,
  value,
  onChange,
  label,
  labelLayout = "vertical",
  formItemClassName,
  ...props // className, value (controlled)
}) => {
  const [values, setValues] = useState()
  const { data: userSettings } = useUserSettings()

  useEffect(() => {
    if (value?.[0] && value?.[1]) {
      let newVal = [null, null]
      newVal[0] = dayjs(value[0], "YYYY-MM-DD")
      newVal[1] = dayjs(value[1], "YYYY-MM-DD")
      setValues(newVal)
    }
  }, [value])

  const handleChange = (evt) => {
    if (evt?.[0] && evt?.[1]) {
      onChange([evt[0].format("YYYY-MM-DD"), evt[1].format("YYYY-MM-DD")])
    } else {
      onChange(evt)
    }
  }

  const renderField = () => {
    return <RangePicker
      defaultValue={defaultValue}
      format={userSettings?.date_format}
      onChange={handleChange}
      value={values}
      {...props}
    />
  }

  if (label) return <Form layout={labelLayout}>
    <Form.Item
      label={label}
      className={`prime-form-item${formItemClassName ? ' ' + formItemClassName : ''}`}
      colon={false}
    >
      {renderField()}
    </Form.Item>
  </Form>

  return renderField()
}

DateRangePicker.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.array, // ["YYYY-MM-DD", "YYYY-MM-DD"]
  defaultValue: PropTypes.array, // ["YYYY-MM-DD", "YYYY-MM-DD"]
  labelLayout: PropTypes.oneOf(['horizontal', 'vertical', 'inline']),
}

export default DateRangePicker