// TODO: move to /components-design-system
import React, { useRef, useEffect } from 'react'
import { Input, InputNumber } from 'antd'
import PropTypes from 'prop-types'
import ReadOnlyField from '../ReadOnlyField/ReadOnlyField'
import './TextInput.scss'

const { TextArea } = Input

const TextInput = ({
  size = "medium",
  type = "text",
  readOnly,
  defaultValue,
  placeholder, // read-only fieldName
  readOnlyPlaceholder,
  onChange, // returns SyntheticBaseEvent // evt.target.value
  rows = 2, // "textarea"
  focusOnMount = false,
  tooltip = null, // { title, align } // TODO: add tooltip capabilities again
  className,
  ellipsis = false,
  allowClear, // default true for antd Input // will cause antd TextArea to overflow outside its container
  ...moreProps // id, value (controlled), allowClear, onBlur, prefix, style, status ("error")
}) => {
  const inputRef = useRef(null)
  const classNames = ['prime-text-input']
  if (className) classNames.push(className);
  if (readOnly) classNames.push('read-only');

  // call parent function
  const handleChange = (evt) => {

    if (onChange) {
      onChange(evt) // evt.target.id, evt.target.value
    }
  }

  // when set focusOnMount 
  useEffect(() => {
    if (focusOnMount) inputRef?.current?.focus({ cursor: 'end' })
  }, [])

  const renderInput = () => {
    if (readOnly) {
      return <ReadOnlyField
        placeholder={readOnlyPlaceholder || placeholder}
        ellipsis={ellipsis}
      >
        {defaultValue}
      </ReadOnlyField>
    }

    if (type === "password") {
      return <Input.Password
        ref={inputRef}
        size={size}
        className={classNames.join(' ')}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={handleChange}
        {...moreProps}
      />
    } else if (type === 'textarea') {
      return <TextArea
        ref={inputRef}
        placeholder={placeholder}
        onChange={handleChange}
        size={size}
        className={classNames.join(' ')}
        defaultValue={defaultValue}
        autoSize={null} // currently buggy, when set creates an empty textarea input at the top of the page
        rows={rows}
        allowClear={allowClear}
        {...moreProps}
      />
    } else if (type === 'number') {
      return (
        <InputNumber
          ref={inputRef}
          size={size}
          className={classNames.join(' ')}
          defaultValue={defaultValue}
          placeholder={placeholder}
          onChange={handleChange}
          allowClear={allowClear || true}
          {...moreProps} />
      )
    }

    return <Input
      ref={inputRef}
      size={size}
      className={classNames.join(' ')}
      defaultValue={defaultValue}
      placeholder={placeholder}
      onChange={handleChange}
      allowClear={allowClear || true}
      {...moreProps}
    />
  }

  return renderInput()
}

TextInput.proTypes = {
  type: PropTypes.oneOf(['text', 'password', 'textarea']),
  autoSize: PropTypes.oneOf([ // textarea
    PropTypes.bool,
    PropTypes.shape({
      minRows: PropTypes.number,
      maxRows: PropTypes.number,
    }),
  ]),
  allowClear: PropTypes.bool,
  readOnly: PropTypes.bool,
  ellipsis: PropTypes.bool, // for readOnly
  defaultValue: PropTypes.string,
}

export default React.memo(TextInput)