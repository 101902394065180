import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { useShallow } from 'zustand/react/shallow'
import { useSearchParams } from 'react-router-dom'

// components
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import PrimeField from 'components/PrimeField/PrimeField';
import { Button } from 'components-design-system'

// hooks
import { useGetCommentTypes } from 'api/hooks/admin/useCommentTypesApi';
import {
  usePostAuthoringComment,
  usePutAuthoringComment
} from 'api/hooks/studies/useAuthoringApi';
import { useGetAuthoringReviewMetadata } from 'api/hooks/studies/useAuthoringReviewApi';
import useAuthoringViewStore from '../../../hooks/stores/useAuthoringViewStore';
import useAuthoringStore from '../../../hooks/stores/useAuthoringStore';

const RJCommentForm = ({
  jpath,
  label,
  editComment,
  setEditComment,
  setOpenForm,
  setShowMenu,
  // comments,
  setAddComment,
}) => {
  const { data: commentTypes = [] } = useGetCommentTypes()
  const [searchParams, setSearchParams] = useSearchParams()
  const authoringId = useAuthoringStore(state => state.authoringId)

  const {
    isReviewerView
  } = useAuthoringViewStore(
    useShallow(state => ({
      isReviewerView: state.isReviewerView,
    }))
  )

  const postAuthoringComment = usePostAuthoringComment(authoringId)
  const putAuthoringComment = usePutAuthoringComment(authoringId)

  const [comment, setComment] = useState("");
  const [commentType, setCommentType] = useState("Review");
  const [items, setItems] = useState([])

  const [isDisabled, setIsDisabled] = useState(false);
  const projId = useMemo(() => {
    return searchParams.get('proj-id')
  }, [])

  const taskId = useMemo(() => {
    return searchParams.get('task-id')
  }, [])
  const { data: authReviewData } = useGetAuthoringReviewMetadata(isReviewerView === true ? authoringId : null, isReviewerView === true ? { "project-id": projId, "actionitem-id": taskId } : {})

  useEffect(() => {
    if (commentTypes) {
      let items = commentTypes.map(type => ({ id: type.name, text: type.name }))
      setItems(items);
    }
  }, [commentTypes])

  useEffect(() => {
    if (editComment) {
      setComment(editComment.text)
      if (editComment.tags && editComment.tags[0]) {
        let type = editComment.tags[0]
        setCommentType(type)
      }
    } else {
      setComment("")
    }
  }, [editComment])

  useEffect(() => {
    if (!commentType || comment.length === 0 || comment === "<p><br></p>") {
      setIsDisabled(true)
    } else if (commentType && comment.length > 0 && isDisabled === true) {
      setIsDisabled(false);
    }
  }, [commentType, comment])

  const onPost = () => {
    let newComment = {
      tags: [commentType],
      field_key: jpath,
      field_name: label,
      text: comment,
      resolved: false,
      favorite: false,
    }

    postAuthoringComment({ body: newComment }, {
      onSuccess: () => {
        setComment("");
        setCommentType(null);
        setAddComment(false);
        // refetchAuthoringComments()
      }
    })
  }

  const onClose = () => {
    setOpenForm(false);
    setShowMenu(false);
  }

  const onSaveChanges = () => {
    let updatedComment = {
      ...editComment,
      text: comment,
      tags: [commentType]
    }

    putAuthoringComment({ id: updatedComment.id + "/", body: updatedComment }, {
      onSuccess: () => {
        setComment("");
        setCommentType(null);
        setEditComment(null);
        setAddComment(false);
      }
    })
  }

  const onCancelEdit = () => {
    setAddComment(false)
    setEditComment(null)
  }

  const commentOptions = useMemo(() => {
    if(commentTypes) {
      return commentTypes?.filter(type => {
        if(isReviewerView) {
          let foundIndex = authReviewData.comment_types.findIndex(id => type.id === id)
          if (foundIndex !== -1) return true
          return false
        } else {
          return true
        }
      })?.map(type => type.name)
    }
    return []
  }, [isReviewerView, commentTypes])

  return (
    <Container>
      <CommentTypeDropdown id="authoring-comment-type-field">
        Comment type: &nbsp;
        <div style={{ width: 200 }}>
          <PrimeField
            schema={{
              id: "authoring-comment-type-field",
              type: "dropdown",
              items: commentOptions,
            }}
            getPopupContainer={() => document.getElementById('authoring-comment-type-field')}
            value={commentType}
            onChange={e => setCommentType(e)} />
        </div>
      </CommentTypeDropdown>
      <div style={{ width: "100%", height: 200 }}>
        <RichTextEditor
          id={"rich-text-editor-without-value"}
          value={comment}
          style={{ borderRadius: 5 }}
          setValue={setComment}
          isReviewerView={isReviewerView} />
      </div>
      {editComment
        ? <ActionContainer>
          <Button
            style={{ marginRight: 12 }}
            variant="outlined"
            size="sm"
            onClick={() => onCancelEdit()}>
            Cancel
          </Button>
          <Button
            size="sm"
            disabled={isDisabled}
            onClick={() => onSaveChanges()}>
            Save Changes
          </Button>
        </ActionContainer>
        : <ActionContainer>
          <Button
            style={{ marginRight: 12 }}
            variant="outlined"
            size="sm"
            onClick={() => onClose()}>
            Close
          </Button>
          <Button
            size="sm"
            disabled={isDisabled}
            onClick={() => onPost()}>
            Post
          </Button>
        </ActionContainer>
      }
    </Container>
  );
};

export default RJCommentForm;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-top: 1px solid #E0E0E0;
  padding: 18px 10px;
`

const CommentTypeDropdown = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 12px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 0.32px;
  color: #525252;

  .bx--list-box__menu {
    z-index: 10;
  }
`

const ActionContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`

const TextArea = styled.textarea`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
`