import { useState, useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow'
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import CommentsPanel from '../CommentsPanel/CommentsPanel';
import ValidationPanel from '../ValidationPanel/ValidationPanel';

// hooks
import useAuthoringViewStore from '../../hooks/stores/useAuthoringViewStore';

// styles
import './SidePanelContainer.scss'

const SidePanelContainer = ({
  validationData,
  unmappedValidations,
}) => {

  const {
    isReviewerView,
    showPanel,
    setShowPanel,
  } = useAuthoringViewStore(
    useShallow(state => ({
      isReviewerView: state.isReviewerView,
      showPanel: state.showPanel,
      setShowPanel: state.setShowPanel,
    }))
  )

  const [view, setView] = useStateWithCallbackLazy("comments");
  const [scrollPosition, setScrollPosition] = useState(0)
  const [isSticky, setIsSticky] = useState(false)
  const navbarOffsetTop = 180

  useEffect(() => {
    let container = document.getElementsByClassName("right-section")[0]
    container.addEventListener("scroll", (evt) => {
      setScrollPosition(container.scrollTop);
    }, { passive: true });

    return () => container.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if(unmappedValidations?.length > 0) {
      setShowPanel(true)
      setView("validations")
    }
  }, [unmappedValidations])

  const handleScroll = (evt) => {
    setScrollPosition(window.scrollY);
  }

  useEffect(() => {
    if (scrollPosition >= navbarOffsetTop && isSticky === false) {
      setIsSticky(true)
    } else if (scrollPosition < navbarOffsetTop && isSticky) {
      setIsSticky(false)
    }
  }, [scrollPosition])


  if (showPanel === false) {
    let classNames = [`side-panel-collapsed`]
    if (isReviewerView) classNames.push('reviewer-view')
    // else if (isSticky) classNames.push("is-sticky")
    return (
      <div
        onClick={() => setShowPanel(true)}
        className={classNames.join(" ")}
      >
        {/* <div className="side-panel-collapsed-content"> */}
        <i className="far fa-comment-alt-dots" />
        {/* </div> */}
      </div>
    )
  }

  let classNames = ["side-panel-container"]
  if (showPanel !== true) classNames.push('hide')
  if (isReviewerView) classNames.push('reviewer-view')
  else if (scrollPosition > navbarOffsetTop) classNames.push("is-sticky")

  return (
    <div
      id="sticky-side-panel"
      className={classNames.join(" ")}
    // ref={panelRef}
    >
      <div
        className="hide-panel-container"
        onClick={() => setShowPanel(false)}
      >
        <div className="fal fa-chevron-right hide-icon" />
      </div>
      <div
        id="sidepanel-action-container"
        className="action-container"
      >
        <div
          onClick={() => setView('comments')}
          className={`action-container-header${view === 'comments' ? ' active' : ''}`}
        >
          <i className="fal fa-comment-alt-dots" style={{ marginRight: 6 }} />
          Comments
        </div>
        {!isReviewerView && <div
          onClick={() => setView('validations')}
          className={`action-container-header${view === 'validations' ? ' active' : ''}`}
        >
          <i className="fal fa-exclamation-circle" style={{ marginRight: 6 }} />
          Validations
        </div>}
      </div>
      {view === "comments" && (
        <CommentsPanel
        />
      )}
      {view === "validations" && (
        <ValidationPanel
          style={{ float: "none" }}
          unmappedValidations={unmappedValidations}
          validationData={validationData}
        />
      )}
    </div>
  );
};

export default SidePanelContainer;