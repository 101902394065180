import React from 'react'
import styled from 'styled-components'
import Modal from "components/Modal/Modal"
import { Button } from 'components-design-system'
import deleteForkImg from 'assets/images/study-data-fork-delete.png'

const ForkDeleteModal = ({
  onHide,
  showModal,
  onSubmit,
}) => {
  return <Modal
    open={showModal}
    type="delete-alt"
    footer={[]}
    onCancel={onHide}
    className="fork-delete-modal"
  >
    <ReviewModalContent>
      <ReviewModalHeader>
        Delete fork?
      </ReviewModalHeader>
      <ReviewModalText>
        <div>Are you sure you want to delete it?</div>
        <div>You can't undo this action.</div>
      </ReviewModalText>
      <ReviewModalActions>
        <Button
          onClick={onHide}
          style={{
            color: "#9B9EA3",
            backgroundColor: "white",
            borderColor: "#AFB2B5",
            fontWeight: "600",
          }}>
          Cancel
        </Button>
        <Button
          onClick={onSubmit}
          customStyle={{
            backgroundColor: "#9566AB",
            borderColor: "#9566AB",
            fontWeight: "600",
          }}>
          Delete
        </Button>
      </ReviewModalActions>
    </ReviewModalContent>
  </Modal>
}

const ReviewModalContent = styled.div`
  color: #383E47;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  font-family: Open Sans;
  font-style: normal;
  text-align: center;
`

const ReviewModalImg = styled.img`
  width: 219px;
`

const ReviewModalHeader = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  margin-top: 6px;
`

const ReviewModalText = styled.div`
  font-size: 14px;
  line-height: 19px;
  margin-top: 7px;
  margin-bottom: 33px;
  width: 240px;
`

const ReviewModalActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-bottom: 17px;
`

export default ForkDeleteModal