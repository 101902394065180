// TODO: compare with api hooks in api/hooks/actionItems/useActionItemsApi.jsx and delete the other file
// TODO: rename to actionItemsApi.js
import useGetApi from "../useGetApi"
import useMutateApi from "../useMutateApi"

// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

// utils
import getUrl from "api/apiMap"

const useGetActionItem = (actionItemId) => {
  return useGetApi(`${getUrl(apiEndpoints.actionItems)}/${actionItemId}/`, queryKeys.actionItems.item(actionItemId))
}

const usePostActionItem = () => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(apiEndpoints.actionItems),
    invalidateQueries: [queryKeys.actionItems.all],
    successMsg: "Successfully created action item"
  })
}

const usePutActionItem = () => {
  return useMutateApi({
    method: "put",
    apiEndpoint: `${getUrl(apiEndpoints.actionItems)}`,
    invalidateQueries: [queryKeys.actionItems.all],
    successMsg: "Successfully updated action item",
  })
}

const useDeleteActionItem = (actionItemId) => {
  return useMutateApi({
    method: "delete",
    apiEndpoint: `${getUrl(apiEndpoints.actionItems)}/${actionItemId}/`,
    invalidateQueries: [queryKeys.actionItems.all],
    successMsg: "Successfully deleted the action item",
  })
}

const useSendReminder = () => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(apiEndpoints.actionItems),
    invalidateQueries: [queryKeys.actionItems.all],
    successMsg: "Your reminder has been sent. Please note that action item reminders can only be sent once per day."
  })
}

const useCompleteSubActionItem = (actionitem_pk, subactionitem_pk) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: `${getUrl(apiEndpoints.actionItems)}${actionitem_pk}/subactionitem/${subactionitem_pk}/complete/`,
     // invalidate requests b/c request metadata has action items in subtask_data
    invalidateQueries: [queryKeys.actionItems.all, queryKeys.requests.all],
    successMsg: "Successfully completed item",
  })
}

const useCompleteActionItem = (actionitem_pk) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: `${getUrl(apiEndpoints.actionItems)}${actionitem_pk}/complete/`,
    invalidateQueries: [queryKeys.actionItems.all],
    successMsg: "Successfully completed item",
  })
}

export {
  usePostActionItem,
  useGetActionItem,
  usePutActionItem,
  useDeleteActionItem,
  useSendReminder,
  useCompleteSubActionItem,
  useCompleteActionItem,
}