export const delayedResultsUISchema = {
  delayed_results_certification: {
    "ui:options": {
      title: "Delayed Results Certification",
      classNames: {
        objectContainer: "p-24 rjs-gap-16",
        objectTitleContainer: "rjs-h1-container",
        objectTitle: "rjs-h1",
        fieldContainer: "rjs-full",
      }
    },
    result_type: {
      "ui:options": {
        title: "Delayed Results Type",
        label: false,
        required: true,
        classNames: {
          fieldContainer: "rjs-full",
          field: "rjs-col-1-3"
        }
      }
    },
    intervention_name: {
      "ui:options": {
        label: false,
        title: "Intervention Name (s)",
        classNames: {
          fieldContainer: "rjs-col-1-2",
        }
      }
    },
    application_number: {
      "ui:options": {
        label: false,
        title: "FDA Application Number(s)",
        classNames: {
          fieldContainer: "rjs-col-1-2",
        }
      }
    },
    month: {
      "ui:options": {
        title: "Month",
        label: false,
        classNames: {
          fieldContainer: "rjs-col-1-4",
        }
      }
    },
    day: {
      "ui:options": {
        title: "Day",
        label: false,
        classNames: {
          fieldContainer: "rjs-col-1-4",
        }
      }
    },
    year: {
      "ui:options": {
        title: "Year",
        label: false,
        classNames: {
          fieldContainer: "rjs-col-1-4",
        }
      }
    },
    explaination: {
      "ui:options": {
        label: false,
        title: "Explanation",
        classNames: {
          fieldContainer: "rjs-full",
        }
      },
      "ui:widget": "TextareaWidget"
    },
    appeal_delayed_results_decision: {
      "ui:options": {
        label: false,
        title: "Appeal Delayed Results Decision: Enter Justification",
        tooltip: "This field does not update PRS, if you have a good cause extension that is rejected, you will need to manually add this explanation to PRS",
        validations: [
          {
            regex: /^.{0,999}$/,
            message: "Appeal delayed results decision must not have more than 999 characters",
          },
        ],
        classNames: {
          fieldContainer: "rjs-full",
        }
      },
      "ui:widget": "TextareaWidget"
    }
  },
}