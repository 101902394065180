import React, { useState, useEffect, useContext } from "react";
import { useShallow } from 'zustand/react/shallow'
import PrimeField from "components/PrimeField/PrimeField";

// hoc
import InputWrapper from '../../hoc/InputWrapper';

// hooks
import useAuthoringDataStore from "../../../hooks/stores/useAuthoringDataStore";
import useAuthoringViewStore from '../../../hooks/stores/useAuthoringViewStore';

const RJCopyEUPASContactCheckbox = (props) => {
  let [currChecked, setCurrChecked] = useState(false);
  let [prevChecked, setPrevChecked] = useState(null);
  let [isLoading, setIsLoading] = useState(true);
  let {
    carbonProps,
    value,
  } = props;
  const {
    formData,
    setFormData,
  } = useAuthoringDataStore(
    useShallow(state => ({
      formData: state.formData,
      setFormData: state.setFormData,
    }))
  )

  const {
    refreshFormData,
    setRefreshFormData
  } = useAuthoringViewStore(useShallow(state => state))

  useEffect(() => {
    if (!value) {
      setPrevChecked(false)
      setCurrChecked(false)
    } else {
      setPrevChecked(true)
      setCurrChecked(true)
    }

    setTimeout(() => {
      setIsLoading(false)
    }, 2000)
  }, [])

  const handleChange = (val) => {
    setCurrChecked(val)
    if (isLoading === false) {
      if (val === true && prevChecked === false) {
        copyValues(val)
      } else {
        resetValues(val)
      }
    }
    setPrevChecked(val)
  }

  const resetValues = (val) => {
    let key;
    if (props.id.includes("scientific")) {
      key = "scientific_contact";
    } else if (props.id.includes("public")) {
      key = "public_contact"
    }

    let study_data = {
      ...formData,
    }

    if (study_data.protocol.contact_information.investigator_contact_details[key]) {
      study_data.protocol.contact_information.investigator_contact_details[key] = {
        copy_lead_investigator: val,
        title: "Unselected"
      }
    }

    setFormData(study_data)
  }

  const copyValues = (val) => {
    // setFormStatus("loading")
    let key;
    if (props.id.includes("scientific")) {
      key = "scientific_contact";
    } else if (props.id.includes("public")) {
      key = "public_contact"
    }

    let study_data = {
      ...formData,
    }

    let leadInvestigator = {
      ...study_data.protocol.contact_information.investigator_contact_details.lead_investigator
    }

    if (study_data.protocol.contact_information.investigator_contact_details[key]) {
      study_data.protocol.contact_information.investigator_contact_details[key] = {
        ...study_data.protocol.contact_information.investigator_contact_details[key],
        copy_lead_investigator: val,
        ...leadInvestigator
      }
    }

    setFormData(study_data)
    setRefreshFormData(!refreshFormData)
  }

  return (
    <PrimeField
      schema={{
        type: "checkbox",
        disabled: carbonProps.disabled
      }}
      containerStyle={{ marginBottom: 5 }}
      value={currChecked}
      onChange={handleChange} />
  );
};

export default InputWrapper(RJCopyEUPASContactCheckbox);

