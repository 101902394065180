import useGetApi from "../useGetApi"
import useMutateApi from "../useMutateApi"

// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

// utils
import getUrl from "api/apiMap"

export const useGetRequestFields = () => {
  return useGetApi(getUrl(apiEndpoints.requestFields), queryKeys.requestFields.all)
}

export const useGetRequestSource = () => {
  return useGetApi(getUrl(apiEndpoints.requestSource), queryKeys.requestSource)
}

export const usePostRequestField = () => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(apiEndpoints.requestFields),
    invalidateQueries: [queryKeys.requestFields.all],
    successMsg: "Successfully created request field"
  })
}


export const useGetRequestField = (id) => {
  return useGetApi(getUrl(`${apiEndpoints.requestFields}${id}/`), queryKeys.requestFields.detail(id))
}

export const usePutRequestField = (id) => {
  return useMutateApi({
    method: "put",
    apiEndpoint: getUrl(`${apiEndpoints.requestFields}${id}/`),
    invalidateQueries: [queryKeys.requestFields.all],
    successMsg: "Successfully updated request field"
  })
}

export const useDeleteRequestField = (id) => {
  return useMutateApi({
    method: "delete",
    apiEndpoint: getUrl(`${apiEndpoints.requestFields}`),
    invalidateQueries: [queryKeys.requestFields.all],
    successMsg: "Successfully deleted request field"
  })
}