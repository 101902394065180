export const objectClassNames = {
  primary: {
    fieldContainer: "rjs-full",
    objectTitleContainer: "rjs-h1-container",
    objectTitle: "rjs-h1",
    objectContainer: "p-24"
  }
}

export const arrayClassNames = {
  primary: {
    arrayTitle: "rjs-h2",
    fieldContainer: "rjs-full",
    arrayContainer: "rjs-border-radius",
    arrayItemsContainer: "rjs-array-items-type-1",
    arrayItemContainer: "rjs-array-item-type-1",
    actionContainer: "flex justify-end",
    arrayItemTitle: "rjs-h3"
  },
  secondary: {
    arrayTitle: "rjs-h3",
    fieldContainer: "rjs-full",
    arrayContainer: "rjs-border-radius",
    arrayItemsContainer: "rjs-array-items-type-1",
    arrayItemContainer: "rjs-array-item-type-1",
    actionContainer: "flex justify-end",
    arrayItemTitle: "rjs-h3"
  },
  tertiary: {
    arrayTitle: "rjs-h3",
    fieldContainer: "rjs-full",
    arrayContainer: "rjs-border-radius",
    arrayItemsContainer: "rjs-array-items-type-1",
    arrayItemContainer: "rjs-array-item-type-1 flex gap-16",
    actionContainer: "flex justify-start",
    arrayItemTitle: "rjs-h3"
  }
}