import { useState, useEffect, useContext, createContext } from "react";

import { getSectionRegistry } from 'utilities/study-views'

export const SubmissionsProvider = ({ children }) => {
  const [formVersion, setFormVersion] = useState(null)
  const [formVersionItems, setFormVersionItems] = useState([])

  return (
    <SubmissionsContext.Provider
      value={{
        formVersion,
        setFormVersion,
        formVersionItems,
        setFormVersionItems,
      }}>
      {children}
    </SubmissionsContext.Provider>
  )
}
const SubmissionsContext = createContext({});

const useSubmissions = () => {
  return useContext(SubmissionsContext);
}

export default useSubmissions;