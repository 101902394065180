// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

// apis
import useGetApi from "../useGetApi"
import useMutateApi from "../useMutateApi"
import getUrl from "api/apiMap"

export const useGetSubmissionsHistory = (authoringId) => {
  return useGetApi(getUrl(`${apiEndpoints.submissions}${authoringId}/submission-history/`), queryKeys.submissions.list(authoringId))
}

export const usePostSubmission = (authoringId) => {
  return useMutateApi({
    method: 'post',
    apiEndpoint: getUrl(`${apiEndpoints.authoring}${authoringId}/submission-history/`),
    invalidateQueries: [queryKeys.submissions.list(authoringId), queryKeys.uploadHistory],
    successMsg: "Successfully completed submission/download"
  })
}

export const useGetApprovalHistory = (authoringId) => {
  return useGetApi(getUrl(`${apiEndpoints.submissions}${authoringId}/approval-records/`), queryKeys.submissions.approvals(authoringId))
}

export const usePostApprovalHistory = (authoringId) => {
  return useMutateApi({
    method: 'post',
    apiEndpoint: getUrl(`${apiEndpoints.submissions}${authoringId}/approval-records/`),
    invalidateQueries: [queryKeys.submissions.approvals(authoringId)],
    successMsg: "Successfully completed approval"
  })
}

export const useGetPrsSubmissionsUpload = (queueId, options) => {
  return useGetApi(getUrl(apiEndpoints.submissionsUpload), [], options, {
    "queue-id": queueId
  })
}

export const usePostPrsSubmissionsUpload = () => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(apiEndpoints.submissionsUpload),
    invalidateQueries: [],
    // successMsg: "PRS submission upload in progress"
  })
}

export const useDownloadXml = (authoringId) => {
  return useMutateApi({
    method: 'post',
    apiEndpoint: getUrl(`${apiEndpoints.authoring}${authoringId}/xml-download`),
    invalidateQueries: [queryKeys.submissions.list(authoringId)],
    successMsg: "Successfully completed XML download"
  })
}