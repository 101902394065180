import './Note.scss';

const Note = ({
  type = "error",
  text = "",
  style = {}
}) => {

  let iconClassName ="fal fa-exclamation-circle"
  let classNames = ["note-container"]

  if(type === "error") {
    classNames.push("error-note")
  }

  return (
    <div className={classNames.join(" ")} style={style}>
      <i className={[iconClassName, "note-icon"].join(" ")}/>
      <p className="note-text">{text}</p>
    </div>
  );
};

export default Note;