import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components';
import { Popover } from 'antd';

// Components
import LabelMenu from '../LabelMenu/LabelMenu';

// styles
import './RJLabel.scss';

const RJLabel = ({
  // fieldComments
  fieldComments,

  label,
  tooltip,
  tooltipUrl,
  tooltipUrlTitle,
  showMenu,
  setShowMenu,
  jpath,

  // for badges and fork
  registryKeys,
  jpaths,
  onForkField,
  setOpenDeleteForkedModal,
  hideFork,
  hideComment,
  setFieldLoading,
  formContext
}) => {

  let content = (
    <div style={{ maxWidth: 300, wordBreak: "break-word" }}>
      {tooltip}
      <div className="tooltip__footer">
        <Link
          to={tooltipUrl}
          target="_blank"
          className="--link"
        >
          {tooltipUrlTitle}
        </Link>
      </div>
    </div>
  )
  return (
    <Container
      className="rj-label-container"
      label={label}>
      <p className="rj-label">
        {label}
      </p>
      {tooltip && (
        <Popover
          content={content}
          title={null}>
          <i className="fal fa-info-circle" style={{ marginLeft: 8, marginBottom: 5, color: "#B9BCBF" }} />
        </Popover>
      )}
      <LabelMenu
        jpath={jpath}
        label={label}
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        fieldComments={fieldComments}
        registryKeys={registryKeys}
        jpaths={jpaths}
        onForkField={onForkField}
        setOpenDeleteForkedModal={setOpenDeleteForkedModal}
        hideFork={hideFork}
        hideComment={hideComment}
        setFieldLoading={setFieldLoading}
        formContext={formContext}
      />
    </Container>
  );
};

export default RJLabel;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: ${props => props.label ? "auto" : ".5rem"};
`

const TooltipContent = styled.div`
  line-height: 1.2rem;
`

const TooltipFooter = styled.div`
  margin-top: 16px;
`