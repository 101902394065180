// cell renderers specific to Submissions
import React from "react";

/** Submission/Download History */

// will display N/A if download XML
const BooleanCellRenderer = ({ value, data }) => {
  const isXmlDownload = data.download_only;
  let displayVal = "";
  if (value === undefined || isXmlDownload) {
    displayVal = "N/A";
  } else if (value && value === true){
    displayVal = "Yes";
  } else if (value === false) {
    displayVal = "No";
  }
  return (
    <div>
      {displayVal}
    </div>
  )
}

// download_only
const TypeCellRenderer = (props) => {
  const { value } = props;
  if (value === undefined) return <div></div>;
  let returnVal = 'Submission';
  if (value === true) returnVal = 'Download'
  return (
    <div>
      {returnVal}
    </div>
  );
};

const FormViewCellRenderer = ({ value }, registryTitles) => {
  if (value.toLowerCase() === 'n/a') return value;
  // 'eudra' in schema, 'eudract' needed for API
  if (value === 'eudract') value = 'eudra';
  if(!value) return <div></div>
  return (
    <div>
      {registryTitles[value]}
    </div>
  );
};

const FormSectionCellRenderer = ({ value }, sectionTitles) => {
  if (value.toLowerCase() === 'n/a') return value;
  // 'delayedResultsCertification' in schema, 'delayed_results_certification' needed for API
  if (value === 'delayed_results_certification') value = 'delayedResultsCertification';
  if (!value) return <div></div>;
  return (
    <div>
      {sectionTitles[value]}
    </div>
  );
};

// value is an array of document type indeces
const UploadDocsCellRenderer = (props) => {
  const { value } = props;
  if (!value || value?.length === 0) return <div>N/A</div>;
  // TODO: map index values to document types
  // const displayVal = value.split(',');
  if(Array.isArray(value)) {
    return (
      <div>
        {value?.join(", ")}
      </div>
    )
  }
  
  return (
    <div>
      {value}
    </div>
  );
};

const LinkCellRenderer = ({ value }) => {
  if (!value) return <div>N/A</div>
  return (
    <div>
      <a href={value} target="_blank">View</a>
    </div>
  )
}

export {
  BooleanCellRenderer,
  TypeCellRenderer,
  FormViewCellRenderer,
  FormSectionCellRenderer,
  UploadDocsCellRenderer,
  LinkCellRenderer,
};
