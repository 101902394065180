import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

// components
import PizzaTracker from 'components/PizzaTracker/PizzaTracker';
import Loading from 'components/Loading/Loading'

// apis
import {
  useGetWorkflow,
  usePutWorkflowTracker
} from 'api/hooks/workflows/useWorkflowsApi';
import { useGetRequest } from 'api/hooks/requests/useRequestsApi'

// context
import useWorkflowTracker from 'context/hooks/useWorkflowTracker'

// styles
import './WorkflowTracker.scss';

const WorkflowTracker = ({
  model,
  isReview = false,
  workflowId,
  showSettings = true,
  showAssignees = false,
  showDueDates = false,
  showDays = false,
  readOnly = false
}) => {
  const {
    workflowTrackerData,
    setWorkflowTrackerData
  } = useWorkflowTracker()

  const [viewSettings, setViewSettings] = useState({
    showAssignees,
    showDueDates,
    showDays
  })

  useEffect(() => {
    if (isReview === false) {
      const viewSettings = localStorage.getItem(`${model}-workflow-view-settings`);
      if (viewSettings) setViewSettings(JSON.parse(viewSettings))
    }
  }, [])

  const {
    data: workflowTrackerDataResp,
    isLoading: isWorkflowTrackerLoading
  } = useGetWorkflow({
    workflowId,
    workflowModel: 'request'
  })

  const putWorkflowTracker = usePutWorkflowTracker({
    workflowId,
    workflowModel: "request"
  })

  useEffect(() => {
    if (workflowTrackerDataResp) {
      setWorkflowTrackerData({
        ...workflowTrackerDataResp,
        state_data: {
          ...workflowTrackerDataResp.state_data,
          tracker_data: workflowTrackerDataResp.state_data.tracker_data.map(state => {
            return {
              ...state,
              start_state_date: state?.start_state_date?.split("T")?.[0],
              end_state_date: state?.end_state_date?.split("T")?.[0],
            }
          })
        }
      })
    }
  }, [workflowTrackerDataResp])

  const onChangeViewSettings = (key, val) => {
    let settings = {
      ...viewSettings,
      [key]: val
    }
    setViewSettings(settings)
    localStorage.setItem('request-workflow-view-settings', JSON.stringify(settings));
  }

  const viewSchema = {
    showAssignees: {
      label: "Assignees",
    },
    showDueDates: {
      label: "Due Dates",
    },
    showDays: {
      label: "Show days",
    }
  }

  return (
    <div className="workflow-tracker-container">
      {showSettings && <div className="workflow-tracker-header">
        {Object.keys(viewSchema).map(key => {
          return (
            <div
              key={key}
              className="settings-item"
              onClick={() => onChangeViewSettings(key, !viewSettings[key])}>
              {viewSettings[key]
                ? <i className="far fa-eye settings-item-off" />
                : <i className="far fa-eye-slash" />
              }
              {viewSchema[key].label}
            </div>
          )
        })}
      </div>
      }

      {isWorkflowTrackerLoading ? (
        <Loading />
      ) : (
        <PizzaTracker
          model={model}
          workflowTrackerData={workflowTrackerData}
          readOnly={readOnly}
          viewSettings={{
            showAssignees: viewSettings.showAssignees,
            showDueDates: viewSettings.showDueDates,
            showDays: viewSettings.showDays,
          }}
          putWorkflowTracker={putWorkflowTracker}
        />
      )}
    </div>
  );
};

export default WorkflowTracker;