import React from 'react';

// Components
import PRSSummaryGrid from '../PRSSummaryGrid/PRSSummaryGrid';
import { Button } from 'components-design-system'

// styles
import './PRSUploadProgress.scss'

const PRSUploadProgress = ({
  setOpenModal,
  data,
  isAutoRelease,
  uploadComplete,
  hasErrors,
}) => {

  return (
    <div className="prs-upload-progress-container">
      <div className="prs-upload-progress-body">
        <PRSSummaryGrid
          data={data}
          isAutoRelease={isAutoRelease}
          uploadComplete={uploadComplete}
          hasErrors={hasErrors} />
      </div>
      <div className="action-container">
        <Button
          onClick={() => setOpenModal(false)}>
          Close
        </Button>
      </div>
    </div>
  );
};

export default PRSUploadProgress;