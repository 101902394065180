import { useState, useEffect, useContext, createContext } from 'react'

const LocationsContext = createContext(null);

export const LocationsProvider = ({ children }) => {
  const [locationMaskSettings, setLocationMaskSettings] = useState({})

  return (
    <LocationsContext.Provider
      value={{
        locationMaskSettings, 
        setLocationMaskSettings
      }}>
      {children}
    </LocationsContext.Provider>
  )
}

const useLocations = () => {
  return useContext(LocationsContext)
}

export default useLocations;