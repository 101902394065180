import { useState } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid'
import { useShallow } from 'zustand/react/shallow'

// components
import { Popover } from 'antd';
import { Button } from 'components-design-system';
import Cell from './subcomponents/Cell/Cell';

// context
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';
import useProtocolTableArmsStore from '../../../hooks/stores/useProtocolTableArmsStore';

const RJSimpleTable = ({
  schema,
  tableData,
  setTableData,
  tableType
}) => {

  const {
    readOnly
  } = useAuthoringViewStore(
    useShallow(state => ({ readOnly: state.readOnly }))
  )

  const {
    setArms,
    setInterventions
  } = useProtocolTableArmsStore()

  const displayHeader = (fieldSchema) => {
    let { tooltip, tooltipUrl, tooltipUrlTitle, title } = fieldSchema;

    let content = (
      <div style={{ width: 250 }}>
        <div>
          {tooltip}
        </div>
        {tooltipUrl &&
          <div className="tooltip__footer">
            <Link to={tooltipUrl} target="_blank" className="--link">
              {tooltipUrlTitle}
            </Link>
          </div>
        }
      </div>
    )

    return (
      <div>
        {tooltip
          ? <Popover
            content={content}
            trigger="hover">
            {title}
            <i className="fal fa-info-circle" style={{ marginLeft: 12 }}></i>
          </Popover>
          : title
        }
      </div>
    )
  }

  const handleAdd = () => {
    let obj = {
      id: uuid()
    };
    Object.keys(schema.properties).forEach(key => {
      obj[key] = null
    })
    let newTable = [...tableData, obj];
    
    setTableData(newTable);
    if (tableType === "arms") {
      setArms([...newTable])
    } else if (tableType === "interventions") {
      setInterventions([...newTable])
    }
  }

  const onChange = (e, key, colIndex) => {
    const newTable = tableData.slice();
    const newItem = { ...newTable[colIndex], [key]: e };
    newTable[colIndex] = newItem;
    setTableData(newTable);
    if (tableType === "arms") {
      setArms([...newTable])
    } else if (tableType === "interventions") {
      setInterventions([...newTable])
    }
  }

  return (
    <div className="results-table">
      <div className="rjs-h2">
        {schema.title}
      </div>
      <table>
        <tbody>
          {schema?.properties && Object.keys(schema?.properties)?.map((key, rowIndex) => {
            let fieldSchema = schema.properties[key]
            return (
              <tr
                key={`${schema.title}-${key}-${rowIndex}`}>
                <td className="header-column">
                  {displayHeader(fieldSchema)}
                </td>
                {tableData?.map((cell, colIndex) => {
                  return (
                    <Cell
                      key={`simple-table-${key}-${rowIndex}-${colIndex}`}
                      fieldSchema={fieldSchema}
                      fieldKey={key}
                      cell={cell}
                      onChange={e => onChange(e, key, colIndex)}
                      colIndex={colIndex}
                      rowIndex={rowIndex}
                      tableData={tableData}
                      setTableData={setTableData}
                      tableType={tableType} />
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      {!readOnly &&
        <Button
          style={{ marginTop: 20, marginBottom: 20 }}
          variant="primary-dashed"
          size="sm"
          onClick={() => handleAdd()}>
          <i
            className="fal fa-plus-circle"
            style={{
              marginTop: 2,
              marginRight: 8
            }} />
          {schema.addButtonTitle}
        </Button>
      }
    </div>
  );
};

export default RJSimpleTable;