import React, { useState, useEffect, useContext, Fragment } from 'react';
import styled from 'styled-components';

// components
import CTGGroupTotals from './CTGGroupTotals';

// context
import { AEContext } from './AdversePreview';

const AdverseEvents = ({
  title,
  type,
  totalType,
  statSchema,
  bgColor
}) => {
  const { 
    data,
    view,
    schema,
  } = useContext(AEContext);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    if(data?.[type]) {
      let map = new Map();

      data[type].forEach(event => {
        if(map.has(event.organ_system_name)) {
          let eventsArr = map.get(event.organ_system_name);
          eventsArr.push({ ...event })
          map.set(event.organ_system_name, eventsArr)
        } else {
          map.set(event.organ_system_name, [{ ...event }])
        }
      })

      let events = [];
      map.forEach((value, key) => {
        events.push({
          title: key,
          rows: value
        })
      })
      setEvents(events);
    }
  }, [data?.[type]])

  if(view === "eupas") {
    return null
  }

  if(view === "eudract" && schema.properties?.[view]) {
    return (
      <Container>
        <Table  className="authoring-table-component">
          <tbody>
            <tr>
              <td 
                style={{ fontWeight: "bold" }}
                colSpan={(data?.groups?.length || 0) + 2}>
                {title}
              </td>
            </tr>
            <tr>
              <td 
                style={{ backgroundColor: "#e2cdfc", fontWeight: "bold" }}
                colSpan={2}>
                Groups
              </td>
              {data?.groups?.map(group => {
                return (
                  <td>
                    <div style={{ fontWeight: "bold" }}>
                      {group.title}
                    </div>
                  </td>
                )
              })}
            </tr>
            
            {events.map((event, i) => (
              <Fragment key={`${type}-${event.title}-${i}`}>
                <tr>
                  <td 
                    style={{ backgroundColor: "#e2cdfc", fontWeight: "bold" }}
                    colSpan={data.groups.length + 2}>
                    {event.title}
                  </td>
                </tr>
                {event.rows.map((row, j) => (
                  <tr key={`${type}-${event.title}-${i}-${j}`}>
                    <td 
                      style={{ backgroundColor: bgColor, textAlign: "left" }}
                      colSpan={2}>
                      <div><strong>Term:</strong> {row.term}</div>
                      <div><strong>Description:</strong> {row.notes}</div>
                    </td>
                    {row.values.map((cell, k) => {
                      return (
                        <td key={`${type}-${event.title}-${i}-${j}-${k}`}>

                          {Object?.keys(schema?.properties?.[view]?.[statSchema]?.properties)?.map(key => {
                            let fieldSchema = schema.properties[view][statSchema].properties;

                            return (
                              <div style={{ marginBottom: "2px", display: "flex", alignItems: "flex-end"}}>
                                <span>{fieldSchema[key].title}: <strong>{cell[key]}</strong></span> 
                              </div>
                            )
                          })}
                        </td>
                      )
                    })}
                  </tr>
                ))}
              </Fragment>
            ))}
          </tbody>
        </Table>
      </Container>
    )
  }

  return (
    <Container>
      <Table  className="authoring-table-component">
        <tbody>
          <tr>
            <td 
              style={{ fontWeight: "bold" }}
              colSpan={(data?.groups?.length || 0) + 2}>
              {title}
            </td>
          </tr>
          {view === "ctg" && <CTGGroupTotals type={totalType} bgColor={bgColor}/>}
          {events.map((event, i) => (
            <Fragment key={`${type}-${event.title}-${i}`}>
              <tr>
                <td 
                  style={{ backgroundColor: "#e2cdfc", fontWeight: "bold" }}
                  colSpan={data.groups.length + 2}>
                  {event.title}
                </td>
              </tr>
              {event.rows.map((row, j) => (
                <tr key={`${type}-${event.title}-${i}-${j}`}>
                  <td 
                    style={{ backgroundColor: bgColor, textAlign: "right" }}
                    colSpan={2}>
                    {row.term}
                  </td>
                  {row.values.map((cell, k) => {
                    let percentage = 0;
                    if(cell.num_subjects_affected && cell.num_subjects) {
                      percentage = (Number(cell.num_subjects_affected) / Number(cell.num_subjects)) * 100;
                    }
                    let value = `${cell.num_subjects_affected} / ${cell.num_subjects} (${percentage.toFixed(2)}%)`
                    return (
                      <td key={`${type}-${event.title}-${i}-${j}-${k}`}>
                        {value}
                      </td>
                    )
                  })}
                </tr>
              ))}
            </Fragment>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default AdverseEvents;

const Container = styled.div`
  margin-top: 16px;
`

const Table = styled.table`
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 16px;

  td, th {
    border: 1px solid #444;
    text-align: left;
    padding: 8px;
  }

  th {
    font-weight: bold;
    background-color: rgb(238, 238, 254);
    text-align: center;
  }
`