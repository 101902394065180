import React, { useState, useEffect } from 'react'

// components
import InnerModal from 'components/InnerModal/InnerModal';
import ReadOnlyField from 'components/ReadOnlyField/ReadOnlyField'
import { Button } from 'components-design-system'
import { Select } from 'components-design-system'

// assets
import deleteImg from 'assets/images/modal-delete.png'

// content
import useUsers from 'context/hooks/useUsers';

// styles
import '../Team.scss'

const TeamModal = ({
  team,
  users,
  selectedRole,
  show,
  setShow,
  onPostTeam,
  roles
}) => {
  const { userToString } = useUsers()
  const [formData, setFormData] = React.useState({
    role_id: null,
    person_ids: []
  })
  const [showDelete, setShowDelete] = useState(false)

  const handleChange = (val, key) => {
    setFormData({ ...formData, [key]: val })
  }

  // when edit role, add role ID to form data from selected role
  // NOTE: other useEffect below will add person IDs
  useEffect(() => {
    if (selectedRole?.members?.length > 0) {
      handleChange(selectedRole.role_id, 'role_id')
    }
  }, [selectedRole])

  // when user selects role, add team participants already associated to role
  useEffect(() => {
    if (formData.role_id) {
      const foundTeam = team.find((group) => group.role_id === formData.role_id)
      if (foundTeam) {
        handleChange(foundTeam.members.map((person) => person.person_id), 'person_ids')
      }
    }
  }, [formData.role_id])

  const getUserOptions = (users) => {
    return users.map((user) => {
      return { value: user.id, label: userToString(user) }
    })
  }

  const getRoleOptions = (roles) => {
    if (roles) {
      return roles.map((role) => {
        return { value: role.id, label: role.name }
      })
    }
    return []
  }

  const handleSubmitRole = () => {
    onPostTeam({ ...formData })
      .then((resp) => {
        setShow(false)
        setShowDelete(false)
      })
  }

  const handleDeleteRole = () => {
    const payload = { ...formData, person_ids: [] }
    setFormData(payload)
    onPostTeam(payload)
      .then((resp) => {
        setShow(false)
        setShowDelete(false)
      })
  }

  const isSaveDisabled = () => {
    // when editing existing role
    if (selectedRole) {
      const getSelectedPersons = (roles) => {
        if (roles?.members) {
          return roles.members.map((role) => {
            return role.person_id
          })
        }
        return null
      }
      const compareArrays = (a, b) =>
        a.length === b.length &&
        a.every((element, index) => element === b[index]);

      // if changes with person IDs
      if (compareArrays(formData.person_ids, getSelectedPersons(selectedRole))) {
        return true
      }
      return false
    }
    // when add new role
    else {
      if (formData?.person_ids.length == 0 || !formData.role_id) {
        return true
      }
      return false
    }
  }

  const renderFooterActions = () => {
    if (showDelete === false) {
      return <div className="add-team-modal-footer-actions">
        <Button
          variant="outlined"
          size="sm"
          onClick={() => setShow(false)}>
          Cancel
        </Button>
        <Button
          variant="primary"
          size="sm"
          style={{ width: "100%" }}
          disabled={isSaveDisabled()}
          onClick={() => handleSubmitRole()}>
          Save
        </Button>
        {selectedRole &&
          <i
            className="fal fa-trash-alt delete-role-icon"
            onClick={() => setShowDelete(true)}>
          </i>
        }
      </div>
    }
    return <div className="add-team-modal-footer-actions">
      <Button
        variant="outlined"
        size="sm"
        onClick={() => setShowDelete(false)}>
        Cancel
      </Button>
      <Button
        variant="primary"
        size="sm"
        style={{ width: "100%" }}
        onClick={handleDeleteRole}>
        Delete
      </Button>
    </div>
  }

  return (
    <InnerModal
      show={show}
      setShow={setShow}
      containerStyle={{ borderRadius: 13 }}
      modalStyle={{ maxWidth: 261 }}
      wrapClassName="add-team-modal"
      className="add-team-modal-content"
      footerActions={renderFooterActions()}
    >
      {!showDelete ?
        <div className="add-team-modal-fields">
          {selectedRole ?
            <ReadOnlyField
              placeholder={selectedRole.role_name}
            /> :
            <div id="role-wrap" style={{ position: "relative" }}>
              <Select
                placeholder="Role"
                options={getRoleOptions(roles)}
                onChange={(val) => handleChange(val, "role_id")}
                className="role-id-select"
                popupContainerId="role-wrap"
              />
            </div>
          }
          <div id="persons-wrap" style={{ position: "relative" }}>
            <Select
              mode="multiple"
              placeholder="Team members"
              value={formData.person_ids}
              options={getUserOptions(users)}
              onChange={(val) => handleChange(val, "person_ids")}
              // maxRows={4} // TODO: commented out b/c causing console errors. Add max rows
              className="person-id-select"
              popupContainerId="persons-wrap"
            />
          </div>
        </div> :
        <div className="delete-role-container">
          <img
            className="delete-role-img"
            src={deleteImg} alt="delete-role"
          />
          <div className="delete-role-title">Delete study role?</div>
          <div className="delete-role-subtitle">Are you sure you want to delete it? You can't undo this action.</div>
        </div>
      }
    </InnerModal>
  );
};

export default TeamModal;