import { useState, useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow'
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';
import useAuthoringVersionStore from '../../../hooks/stores/useAuthoringVersionStore';
import useAuthoringStore from '../../../hooks/stores/useAuthoringStore';

// components
import Msc from './Msc';

const MSCWrapper = (props) => {
  const {
    id,
    onChange,
    value
  } = props;

  const {
    formData,
  } = props.formContext
  
  const formWidthClassName = useAuthoringStore(state => state.formWidthClassName)

  const {
    versionData,
    selectedVersion,
  } = useAuthoringVersionStore(
    useShallow(state => ({
      versionData: state.versionData,
      selectedVersion: state.selectedVersion,
    }))
  )


  const {
    readOnly,
    showVersionCompare,
    formStatus
  } = useAuthoringViewStore(
    useShallow(state => ({ 
      readOnly: state.readOnly,
      showVersionCompare: state.showVersionCompare,
      formStatus: state.formStatus,
    }))
  )

  const [tableData, setTableData] = useStateWithCallbackLazy([])
  const [tableMounting, setTableMounting] = useStateWithCallbackLazy(true)

  useEffect(() => {
    if (formStatus === "ready" && tableData && readOnly === false && tableMounting === false) {
      console.log("MSC Change")
      onChange(tableData)
    }
  }, [tableData])

  useEffect(() => {
    if (props.value) {
      setTableData(props.value, () => {
        setTableMounting(false)
      })
    } else {
      setTableData([], () => {
        setTableMounting(false)
      })
    }

    return () => {
      setTableMounting(true)
    }
  }, [])

  if (tableMounting) return <></>

  return (
    <div className={["form-table-print", formWidthClassName].join(" ")}>
      <Msc
        {...props}
        protocolData={formData.protocol}
        tableData={tableData}
        setTableData={setTableData} />
      {versionData && selectedVersion && showVersionCompare &&(
        <div className="rjs-table-comparison-wrapper">
          <Msc
            {...props}
            protocolData={versionData?.study_data?.protocol}
            tableData={versionData?.study_data?.protocol?.mscs?.member_states_concerned?.member_states_concerned_table}
            setTableData={() => { }} />
        </div>
      )}
    </div>
  );
};

export default MSCWrapper;