// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

// apis
import getUrl from "api/apiMap"
import useGetApi from "../useGetApi"
import useMutateApi from "../useMutateApi"

export const useGetSiteManagementStudies = () => {
  return useGetApi(getUrl(`${apiEndpoints.siteManagementDetails}`), queryKeys.siteManagement.list)
}
export const useGetSiteManagementStudy = (siteManagementId) => {
  return useGetApi(getUrl(`${apiEndpoints.siteManagementDetails}${siteManagementId}/`), queryKeys.siteManagement.detail(siteManagementId))
}

// mutate({ body })
export const usePostSiteManagementStudy = (siteManagementId) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: apiEndpoints.siteManagementDetails,
    invalidateQueries: [queryKeys.siteManagement.details(siteManagementId)],
  })
}

// mutate({ id, body })
export const usePutSiteManagementStudy = (siteManagementId) => {
  return useMutateApi({
    method: "",
    apiEndpoint: apiEndpoints.siteManagementDetails,
    invalidateQueries: [queryKeys.siteManagement.details(siteManagementId)],
    successMsg: "Successfully updated study data"
  })
}

// LOCATIONS
export const useGetSiteManagementLocations = (siteManagementId) => {
  return useGetApi(getUrl(`/sites/api/v1/studies/${siteManagementId}/locations/`), queryKeys.siteManagement.locations(siteManagementId))
}

// mutate({ body })
export const usePostSiteManagementLocations = (siteManagementId) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(`/sites/api/v1/studies/${siteManagementId}/locations/`),
    invalidateQueries: [queryKeys.siteManagement.locations(siteManagementId)],
    successMsg: "Successfully created site"
  })
}

// mutate({ id, body })
export const usePutSiteManagementLocation = (siteManagementId) => {
  return useMutateApi({
    method: "put",
    apiEndpoint: getUrl(`/sites/api/v1/studies/${siteManagementId}/locations/`),
    invalidateQueries: [queryKeys.siteManagement.locations(siteManagementId)],
    successMsg: "Successfully updated site"
  })
}

// mutate({ id })
export const useDeleteSiteManagementLocation = (siteManagementId) => {
  return useMutateApi({
    method: "delete",
    apiEndpoint: getUrl(`/sites/api/v1/studies/${siteManagementId}/locations/`),
    invalidateQueries: [queryKeys.siteManagement.locations(siteManagementId)],
    successMsg: "Successfully deleted site"
  })
}

// mutate({ id, body })
export const useBulkPutSiteManagementLocations = (siteManagementId) => {
  return useMutateApi({
    method: "put",
    apiEndpoint: getUrl(`/sites/api/v1/studies/${siteManagementId}/bulk/locations/`),
    invalidateQueries: [queryKeys.siteManagement.locations(siteManagementId)],
    successMsg: "Successfully updated site"
  })
}

export const useBulkDeleteSiteManagementLocations = (siteManagementId) => {
  return useMutateApi({
    method: "delete",
    apiEndpoint: getUrl(`/sites/api/v1/studies/${siteManagementId}/bulk/locations/`),
    invalidateQueries: [queryKeys.siteManagement.locations(siteManagementId)],
    successMsg: "Successfully deleted site"
  })
}

// SITE MANAGEMENT MASKING
export const useGetSiteManagementMasking = (siteManagementId) => {
  return useGetApi(getUrl(`/sites/api/v1/studies/${siteManagementId}/mask-config/`), queryKeys.siteManagementMasking)
}

// mutate({ id, body })
export const usePutSiteManagementMasking = (siteManagementId) => {
  return useMutateApi({
    method: "put",
    apiEndpoint: getUrl(`/sites/api/v1/studies/${siteManagementId}/mask-config/`),
    invalidateQueries: [queryKeys.siteManagementMasking, queryKeys.siteManagement.locations(siteManagementId)],
    successMsg: "Successfully updated masking preferences"
  })
}

// LOCATIONS MASKING
export const useGetSiteManagementLocationMasking = (siteManagementId, locationId) => {
  return useGetApi(getUrl(`/sites/api/v1/studies/${siteManagementId}/site-masks-config/${locationId}/`), queryKeys.siteManagement.location(siteManagementId))
}

// mutate({ id, body })
export const usePutSiteManagementLocationMasking = (siteManagementId) => {
  return useMutateApi({
    method: "put",
    apiEndpoint: getUrl(`/sites/api/v1/studies/${siteManagementId}/site-masks-config/`),
    invalidateQueries: [queryKeys.siteManagementLocationMasking, queryKeys.siteManagement.location(siteManagementId)],
    successMsg: "Successfully updated masking preferences"
  })
}

export const useDownloadPrsSites = (siteManagementId) => {
  let url = `/sites/api/v1/tasks/prs-sites-download/`
  if (siteManagementId) url += `?stu-id=${siteManagementId}`

  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(url),
    invalidateQueries: [queryKeys.siteManagement.locations(siteManagementId)],
    infoMsg: "Regenerating PRS data"
  })
}

export const useGetPrsSitesUpload = (queueId, options) => {
  return useGetApi(getUrl(apiEndpoints.prsSitesUpload), [], options, {
    "queue-id": queueId
  })
}

export const usePostPrsSiteUpload = () => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(apiEndpoints.prsSitesUpload),
    invalidateQueries: [],
    infoMsg: "Sites upload in progress"
  })
}