import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

const RJCommentActions = ({
  show = true,
  comment,
  handleDelete,
  handleEdit,
  openMenu,
  setOpenMenu,
  index,
  permissions
}) => {

  const onEdit = () => {
    handleEdit(comment);
    setOpenMenu(false);
  }

  if(!permissions) {
    return null;
  }

  return (
    <Container show={show}>
      {(permissions["study.authoringcomment.edit"] || comment.author === permissions.user_id) && 
        <Option 
          onClick={() => onEdit()}>
          <Icon 
            className="far fa-edit" 
            type="edit"/>
          Edit
        </Option>
      }
      {(permissions["study.authoringcomment.delete"] || comment.author === permissions.user_id) && 
        <OptionLast 
          onClick={() => handleDelete(comment)}>
          <Icon 
            className="far fa-trash-alt" 
            type="delete" />
            Delete
        </OptionLast>
      }
    </Container>
  );
};

export default RJCommentActions;

const getIconColor = (type) => {
  switch(type) {
    case "edit": return "#18669E";
    case "delete": return "#D7542C";
  }
}

const Container = styled.div`
  width: 80px;
  background-color: #fff;
  position: absolute;
  top: 20px;
  right: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  flex-direction: column;
  ${({ show }) => !show && 'display: none'}
`

const Option = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 10px;
  font-family: Open Sans;
  font-style: normal;
  font-size: 14px;
  font-weight: normal;
  color: #393939;
  transition: all .3s;
  cursor: pointer;

  &:hover {
    background-color: #d4d4d4;
  }
`

const OptionLast = styled(Option)`
  border-top: 1px solid #d4d4d4;
`

const Icon = styled.i`
  margin-right: 5px;
  color: ${props => getIconColor(props.type)};
  font-size: 14px;
`