// TODO: change name to assessmentsApi b/c it contains hooks but is not a hook
import useGetApi from "../../useGetApi"
import useMutateApi from '../../useMutateApi'

// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

// utils
import getUrl from 'api/apiMap'

export const useGetRequestTypes = () => {
  return useGetApi(getUrl(`${apiEndpoints.requestTypesList}`), queryKeys.requestTypes.all)
}

export const useGetRequestAdditionaFields = (id) => {
  return useGetApi(getUrl(`${apiEndpoints.requestTypes}${id}/schema`), queryKeys.requestTypes.schemaDetail(id))
}

export const useGetRequestType = (id) => {
  return useGetApi(getUrl(`${apiEndpoints.requestTypes}${id}/`), queryKeys.requestTypes.detail(id))
}

export const usePostRequestType = () => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(`${apiEndpoints.requestTypes}`),
    invalidateQueries: [queryKeys.requestTypesList],
    successMsg: 'Successfully created workflow'
  })
}

export const usePutRequestType = () => {
  return useMutateApi({
    method: "put",
    apiEndpoint: getUrl(`${apiEndpoints.requestTypes}`),
    invalidateQueries: [queryKeys.requestTypesList],
    successMsg: 'Successfully updated workflow'
  })
}