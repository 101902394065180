import React from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

const PreAssignmentPreview = ({
  data,
  schema
}) => {

  return (
    <Container>
      <Title>Pre-assignment Period Milestones</Title>
      <PropertyContainer>
        <PropertyTitle>
          Number of subjects started
        </PropertyTitle>
        <PropertyValue>
          {data?.pre_assignment_period?.started_milestone_achievement || ""}
        </PropertyValue>
      </PropertyContainer>
      {data?.pre_assignment_period?.other_milestones?.map(milestone => (
        <PropertyContainer key={uuidv4()}>
          <PropertyTitle>
            Intermediate milestone: Number of subjects
          </PropertyTitle>
          <PropertyValue>
            {milestone?.title}: {milestone?.subjects}
          </PropertyValue>
        </PropertyContainer>
      ))}
      <PropertyContainer>
        <PropertyTitle>
          Number of subjects completed
        </PropertyTitle>
        <PropertyValue>
          {data?.pre_assignment_period?.completed_milestone_achievement || ""}
        </PropertyValue>
      </PropertyContainer>
      <Title>Pre-assignment subject non-completion reasons</Title>
      {data?.pre_assignment_period?.not_completed_reason_details?.map(milestone => (
        <PropertyContainer key={uuidv4()}>
          <PropertyTitle>
            Reason: Number of subjects
          </PropertyTitle>
          <PropertyValue>
            {milestone?.title} {milestone.other_name && `- ${milestone.other_name}`}: {milestone?.subjects}
          </PropertyValue>
        </PropertyContainer>
      ))}
    </Container>
  );
};

export default PreAssignmentPreview;

const Container = styled.div`

`

const Title = styled.div`
  font-size: 15px;
  font-weight: bold;  
`

const PropertyContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-top: 1px solid #000;
`

const PropertyTitle = styled.div`
  width: 30%;
  font-weight: 600;
  padding: 3px 3px 3px 1px;
  border-right: 1px solid #000;
  word-break: break-word;
  display: inline-block;
  overflow-wrap: break-word;
  white-wpace: pre-line;
`

const PropertyValue = styled.div`
  padding: 3px 3px 3px 3px;
  white-space: ;pre-line;
`