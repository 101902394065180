// factory that takes an array of values and maps them to an list of color values
import React from 'react'
import PropTypes from 'prop-types'
import BadgeComp from 'components/badges/Badge/Badge'

const badgeStyles = [
  { color: "#C973A2", bgColor: "#F5EAF1" }, // red
  { color: "#4FAFC3", bgColor: "#E7F8FC" }, // blue light
  { color: "#27AB39", bgColor: "#E9F7EB" }, // green bright
  { color: "#E7AB12", bgColor: "#FDF7E7" }, // yellow
  { color: "#836BC9", bgColor: "#F3F0FA" }, // purple
  { color: "#D74979", bgColor: "#F8EBEF" }, // pink
  { color: "#2D9F7D", bgColor: "#EAF5F2" }, // green dull
  { color: "#0D7691", bgColor: "#E7F1F4" }, // blue dark
  { color: "#DE7009", bgColor: "#FCF1E6" }, // orange
  { color: "#AC5942", bgColor: "#F7EEEC" }, // brown
]

const useBadges = (uniqueValues) => {
  const badgeColorMap = React.useMemo(() => {
    let map = null
    if (uniqueValues && Array.isArray(uniqueValues)) {
      map = {}
      let colorIndex = 0
      for (let i = 0; i < uniqueValues.length; i++) {
        map[uniqueValues[i]] = badgeStyles[colorIndex]
        colorIndex = colorIndex + 1 === badgeStyles.length ? 0 : colorIndex + 1
      }
      return map
    }
  }, [uniqueValues])

  const Badge = ({ value }) => {
    if (badgeColorMap) {
      return <BadgeComp
        color={badgeColorMap?.[value]?.color}
        bgColor={badgeColorMap?.[value]?.bgColor}
        value={value}
      />
    }
    return null
  }

  return {
    Badge,
  }
}

useBadges.proptypes = {
  uniqueValues: PropTypes.arrayOf(PropTypes.string),
}

export default useBadges