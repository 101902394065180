import * as React from 'react'
import PropTypes from 'prop-types'
import './Badge.scss'

const Badge = ({
  color = "",
  bgColor = "#EAEBEB",
  className,
  value,
}) => {
  let classNames = ["prime-badge"]
  if (className) classNames.push(className);

  return <div
    style={{
      color,
      background: bgColor,
      border: `1px solid ${color}`,
    }}
    className={classNames.join(' ')}
  >
    {value}
  </div>
}

Badge.propTypes = {
  color: PropTypes.string,
  bgColor: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
}

export default Badge