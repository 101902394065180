import React, { useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';

import './grid.scss';

const Grid = ({
  ref,
  columnDefs,
  onGridReady,
  gridOptions,
  rowData,
  gridApi,
  search,
  onSortChanged,
  onFilterChanged,
  onRowDragMove,
  getRowNodeId,
  variant = "primary",
  pagination = false,
  paginationPageSize = null,
  containerStyle = {}
}) => {

  useEffect(() => {
    if (gridApi) {
      if(search) {
        gridApi.setQuickFilter(search)
      } else {
        gridApi.setQuickFilter("")
      }
    }
  }, [search, gridApi])

  let classNames = ["ag-theme-balham"];

  switch(variant) {
    case "secondary":
      classNames.push("secondary-grid-container")
      break;
    default: 
      classNames.push("primary-grid-container")
  }

  return (
    <div className={classNames.join(" ")} style={containerStyle}>
      <AgGridReact
        ref={ref}
        columnDefs={columnDefs}
        domLayout='autoHeight'
        animateRows={true}
        onGridReady={onGridReady}
        gridOptions={gridOptions}
        rowData={rowData}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        onRowDragMove={onRowDragMove}
        getRowNodeId={getRowNodeId}
        pagination={pagination}
        paginationPageSize={paginationPageSize}
      />
    </div>
  );
};

export default Grid;