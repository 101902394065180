// TODO: rename SiteDetails
import React, { useEffect, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { useParams } from 'react-router-dom';
import { Tooltip } from 'antd'

// Components
import Checkbox from 'components/Checkbox/Checkbox';
import PrimeField from 'components/PrimeField/PrimeField';
import { Button } from 'components-design-system'
import Modal from 'components/Modal/Modal'
import StaticField from 'components/StaticField/StaticField';
import BulkEditPanel from '../BulkEditPanel/BulkEditPanel'
import StudyDetailsGrid from './StudyDetailsGrid';
import NewSiteDetails from 'components/NewSiteDetails/NewSiteDetails';
import EditSiteDetails from 'components/EditSiteDetails/EditSiteDetails';
import DeleteSite from 'components/DeleteSite/DeleteSite';
import PRSValidationMessages from 'components/PRSValidationMessages/PRSValidationMessages';
import ExternalBoxFilter from 'components/ExternalBoxFilter/ExternalBoxFilter';
import './StudyDetailsContent.scss'

// hooks
import { useUserPermissions } from 'api/hooks';
import {
  usePostSiteManagementLocations,
  usePutSiteManagementLocation,
  useDeleteSiteManagementLocation,
  useGetSiteManagementMasking,
  usePutSiteManagementMasking,
  useGetSiteManagementLocations,
} from 'api/hooks/siteManagement/useSiteManagementApi';
import { useGetStudy } from 'api/hooks/studies/useStudiesApi';
import useAuthoringValidationsStore from '../../Authoring/hooks/stores/useAuthoringValidationsStore';

const StudyDetailsContent = ({
  siteManagementStudy,
  setTriggerExport,
  triggerExport,
  locationStats
}) => {
  const { studyId, siteManagementId } = useParams()
  const { data: permissions } = useUserPermissions()
  const { data: study } = useGetStudy(studyId)
  const {
    sitesValidations,
  } = useAuthoringValidationsStore(
    useShallow(state => ({
      sitesValidations: state.sitesValidations,
    }))
  )

  const { data: siteManagementMasking } = useGetSiteManagementMasking(siteManagementId)
  const { data: siteManagementLocations } = useGetSiteManagementLocations(siteManagementId)

  // apis
  const postSiteManagementLocations = usePostSiteManagementLocations(siteManagementId)
  const putSiteManagementMasking = usePutSiteManagementMasking(siteManagementId)
  const putSiteManagementLocation = usePutSiteManagementLocation(siteManagementId)
  const deleteSiteManagementLocation = useDeleteSiteManagementLocation(siteManagementId)

  const [search, setSearch] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [modalConfig, setModalConfig] = useState({});
  const [disabledAdd, setDisabledAdd] = useState(false);

  const [siteManagementMaskingSetting, setSiteManagementMaskingSetting] = useState({})

  const [siteIDSet, setSiteIDSet] = useState(new Set());
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [rowData, setRowData] = useState(siteManagementLocations);

  // edit/delete rows
  const [selectedRows, setSelectedRows] = useState([])
  const [gridApi, setGridApi] = useState()

  useEffect(() => {
    if (siteManagementMasking) {
      let { masking_config } = siteManagementMasking;
      if (masking_config) {
        setSiteManagementMaskingSetting({
          ...masking_config
        })
      }
    }
  }, [siteManagementMasking])

  useEffect(() => {
    if (siteManagementLocations && siteManagementLocations.length > 0) {
      let siteIDSet = new Set();
      siteManagementLocations.forEach(site => {
        siteIDSet.add(site.site_id)
      })
      setSiteIDSet(siteIDSet);
    }
  }, [siteManagementLocations])

  useEffect(() => {
    if (selectedFilter) {
      switch (selectedFilter) {
        case "total_prime":
          formatRowData(siteManagementLocations);
          break;
        case "total_ctg":
          formatRowData(locationStats.total_ctg.studies);
          break;
        case "prime_ctg_differences":
          formatRowData(locationStats.prime_ctg_differences.studies);
          break;
        case "ctg_submission_differences":
          formatRowData(locationStats.ctg_submission_differences.studies);
          break;

        default:
          formatRowData(siteManagementLocations);
      }
    } else {
      formatRowData(siteManagementLocations)
    }
  }, [selectedFilter, siteManagementLocations, sitesValidations])

  const formatRowData = (sites) => {
    if (sitesValidations?.length > 0) {
      let valMap = {}

      sitesValidations.forEach(validation => {
        if (valMap[validation.site_db_id]) {
          valMap[validation.site_db_id].push(validation)
        } else {
          valMap[validation.site_db_id] = [validation]
        }
      })

      let newRowData = sites.map(site => {
        return {
          ...site,
          validations: valMap[site.id] ? valMap[site.id] : []
        }
      })
      setRowData(newRowData)
    } else {
      setRowData(sites)
    }
  }

  const onOpenModal = (variant, gridProps, data, messages) => {
    switch (variant) {
      case "add-site":
        setModalConfig({
          label: study.study_id,
          header: "New Site",
          subheader: study.study_id,
          children: <NewSiteDetails
            openModal={openModal}
            siteIDSet={siteIDSet}
            setOpenModal={setOpenModal}
            siteManagementMaskingSetting={siteManagementMaskingSetting}
            onCreateSite={onCreateSite} />
        })
        setOpenModal(true);
        break;
      case "edit-site":
        setModalConfig({
          header: "Edit Site Details",
          subheader: study.study_id,
          children: <EditSiteDetails
            messages={messages}
            onOpenModal={onOpenModal}
            setOpenModal={setOpenModal}
            onEditSite={onEditSite}
            gridProps={gridProps}
          />
        })
        setOpenModal(true);
        break;
      case "delete-site":
        setModalConfig({
          label: study.study_id,
          header: "Delete Site",
          subheader: "Are you sure you would like to delete this site? This cannot be undone.",
          size: "sm",
          children: <DeleteSite
            onOpenModal={onOpenModal}
            setOpenModal={setOpenModal}
            onDeleteSite={onDeleteSite}
            gridProps={gridProps}
            formData={data} />
        })
        setOpenModal(true);
        break;
      case "prs-validation-messages":
        let count = 0;
        messages.forEach(item => {
          if (item.severity === "ERROR") count++;
        })
        setModalConfig({
          header: "PRS Validation Messages",
          subheader: "There are are [" + count + "] errors that must be updated in order to release record to CTG. Errors must be addressed. Warnings and notes should be addressed as needed. To refresh PRS validation messages, studies must be resubmitted.",
          children: <PRSValidationMessages
            gridProps={gridProps}
            studyData={data}
            messages={messages}
            onOpenModal={onOpenModal}
            setOpenModal={setOpenModal}
            origin={"locations"}
            hideSubmit={true}
          />
        })
        setOpenModal(true);
        break;
      default: return;
    }
  }

  const onCreateSite = async (createSite, maskingConfig) => {
    let siteID = createSite.site_id;
    delete createSite.site_id;

    let newSite = {
      ...createSite,
      facility: {
        name: createSite.facility.name ? createSite.facility.name : "",
        address: {
          city: createSite.facility.address.city ? createSite.facility.address.city : "",
          state: createSite.facility.address.state ? createSite.facility.address.state : "",
          country: createSite.facility.address.country ? createSite.facility.address.country : "",
          zip: createSite.facility.address.zip ? createSite.facility.address.zip : "",
        }
      },
      site_rowid: siteID
    }

    postSiteManagementLocations({
      body: {
        site_id: siteID,
        location_data: newSite
      }
    }, {
      onSuccess: () => {
        setOpenModal(false);
      }
    })
  }

  const onEditSite = (gridProps, newData, maskingConfig) => {
    let updatedData = {
      ...gridProps.data,
      location_data: newData,
      site_id: newData.site_id
    }

    delete updatedData.location_data.site_id;
    delete updatedData.location_data.validation_messages;

    putSiteManagementLocation({
      id: gridProps.data.id + "/",
      body: updatedData
    }, {
      onSuccess: () => {
        setOpenModal(false);
      }
    })
  }

  const onDeleteSite = (gridProps) => {
    deleteSiteManagementLocation({ id: gridProps.data.id + "/" }, {
      onSuccess: () => {
        setOpenModal(false);
      }
    })
  }

  const onSiteManagementMaskingChange = (key, e) => {
    let val = e.target.checked;

    let maskingConfig = {
      ...siteManagementMaskingSetting,
      [key]: val
    }

    setSiteManagementMaskingSetting(maskingConfig)

    const formatMaskingReq = (req) => {
      let resp = {
        contact_mask: req.contact_mask,
        contact_backup_mask: req.contact_mask,
        facility_mask: req.facility_mask,
        investigator_mask: req.investigator_mask,
      }

      return { masking_config: resp }
    }

    let reqBody = formatMaskingReq(maskingConfig)

    putSiteManagementMasking({
      id: null,
      body: reqBody
    })
  }

  const renderTooltip = (tooltipProps) => {
    let text = "No edits can be made as this study does not exist in Source and will not be able to be uploaded.";

    return <div id="disabbled-add-new-site-tooltip" {...tooltipProps}>
      <div className="new-site-tooltip">
        {text}
      </div>
    </div>
  }

  let filterSchema = {
    total_prime: {
      title: "Total Sites in Prime",
      value: locationStats.total_prime.count,
      style: { marginRight: 16 },
    },
    total_ctg: {
      title: "Total Sites in CTG",
      value: locationStats.total_ctg.count,
      style: { marginRight: 16 },
    },
    prime_ctg_differences: {
      title: "Source (Prime) and CTG Differences",
      value: locationStats.prime_ctg_differences.count,
      style: { marginRight: 16 },
    },
    ctg_submission_differences: {
      title: "CTG and Submission Differences",
      value: locationStats.ctg_submission_differences.count,
    }
  }

  const handleFilter = (key) => {
    setSelectedFilter(key)
  }

  const onSelectionChanged = ({ api }) => {
    setGridApi(api) // set gridAPi the first time something is selected
    setSelectedRows(api.getSelectedRows())
  }

  const renderFilters = () => {
    return Object.keys(filterSchema).map(key => {
      return (
        <ExternalBoxFilter
          key={key}
          {...filterSchema[key]}
          onClick={() => handleFilter(key)}
          active={key === selectedFilter} />)
    })
  }

  // TODO: move JS styles to .scss
  return <div className="site-details-content">
    <div className="site-details-header">
      <div className="justify-content-md-between">
        <div style={{ display: 'flex', flexDirection: "row", justifyContent: "flex-start", alignItems: "flexStart" }}>
          <div style={{ display: "flex", flexDirection: "column", padding: "0 15px" }}>
            <div className="static-field-title">Overall Recruitment Status</div>
            <div className="static-field-content">
              <StaticField title={"Prime"} value={siteManagementStudy?.study_data?.overall_status ? siteManagementStudy.study_data.overall_status : "N/A"} style={{ marginRight: 34 }} />
              <StaticField title={"CTG"} value={siteManagementStudy?.ctg_study_data?.overall_status ? siteManagementStudy.ctg_study_data.overall_status : "N/A"} />
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", padding: "0 15px", marginLeft: "18px" }}>
            <Checkbox
              style={{ margin: 0 }}
              checked={siteManagementMaskingSetting.facility_mask}
              onChange={e => onSiteManagementMaskingChange("facility_mask", e)}
              label="Mask Address in CTG Submission" />
            <Checkbox
              style={{ margin: 0 }}
              checked={siteManagementMaskingSetting.contact_mask}
              onChange={e => onSiteManagementMaskingChange("contact_mask", e)}
              label="Mask Contact in CTG Submission" />
            <Checkbox
              style={{ margin: 0 }}
              checked={siteManagementMaskingSetting.investigator_mask}
              onChange={e => onSiteManagementMaskingChange("investigator_mask", e)}
              label="Mask Investigators in CTG Submission" />
          </div>
        </div>
        {permissions["study.site.create"] &&
          <div className="locations-grid-actions">
            {disabledAdd
              ? <Tooltip
                title={props => renderTooltip(props)}
              >
                <div style={{ display: 'inline-block', float: "right", cursor: 'not-allowed' }}
                >
                  <Button
                    variant="primary-dashed"
                    style={{ float: 'right', pointerEvents: "none" }}
                    disabled={disabledAdd}
                    onClick={() => onOpenModal("add-site")}>
                    <i className="fal fa-map-marker-alt" style={{ marginRight: 5 }}></i>
                    Add New Site
                  </Button>
                </div>
              </Tooltip>
              : <Button
                variant="primary-dashed"
                style={{ float: 'right' }}
                onClick={() => onOpenModal("add-site")}>
                <i className="fal fa-map-marker-alt" style={{ marginRight: 5 }}></i>
                Add New Site
              </Button>
            }
          </div>
        }
      </div>
      <div className="justify-content-md-between" style={{ marginTop: 16 }}>
        <div md={8}>
          <div className="status-box-wrap">
            {renderFilters()}
          </div>
        </div>
        <PrimeField
          schema={{
            type: "text",
            placeholder: "Search using any data value",
            style: { width: 400, marginTop: 16 }
          }}
          value={search}
          onChange={e => setSearch(e)}
        />
      </div>
    </div>
    <BulkEditPanel
      study={study}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      gridApi={gridApi}
    />
    <StudyDetailsGrid
      search={search}
      rowData={rowData}
      study={study}
      onOpenModal={onOpenModal}
      setTriggerExport={setTriggerExport}
      triggerExport={triggerExport}
      permissions={permissions}
      onSelectionChanged={onSelectionChanged}
    />
    <Modal
      title={modalConfig.header}
      message={modalConfig.subheader}
      onCancel={() => setOpenModal(false)}
      hideImage={true}
      footer={[]}
      width={720}
      size={modalConfig.size}
      open={openModal}
      destroyOnClose={true}
    >
      {modalConfig.children}
    </Modal>
  </div>
}

export default StudyDetailsContent