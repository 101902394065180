import useGetApi from "../useGetApi"
import useMutateApi from "../useMutateApi"

// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

// utils
import getUrl from "api/apiMap"

export const useGetRequestFeed = (requestId) => {
  return useGetApi(`${getUrl(apiEndpoints.requests)}${requestId}/timeline/`, queryKeys.requests.timeline(Number(requestId)))
}

export const usePostRequestFeedComment = (requestId) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(`${apiEndpoints.requests}${requestId}/comments/`),
    invalidateQueries: [queryKeys.requests.timeline(Number(requestId))],
    successMsg: "Successfully posted comment",
  })
}

export const usePutRequestFeedComment = (requestId, commentId) => {
  return useMutateApi({
    method: "put",
    apiEndpoint: getUrl(`${apiEndpoints.requests}${requestId}/comments/${commentId}/`),
    invalidateQueries: [queryKeys.requests.timeline(Number(requestId))],
    successMsg: "Sucessfully updated comment",
  })
}

export const useDeleteRequestFeedComment = (requestId) => {
  return useMutateApi({
    method: "delete",
    apiEndpoint: getUrl(`${apiEndpoints.requests}${requestId}/comments/`),
    invalidateQueries: [queryKeys.requests.timeline(Number(requestId))],
    successMsg: "Sucessfully deleted comment",
  })
}