import React, { createContext } from 'react';
import styled from 'styled-components';
import { useShallow } from 'zustand/react/shallow'

// components
import Header from '../components/Header/Header';
import PreAssignmentPreview from './PreAssignmentPreview';
import Period from './Period/Period';

// schemas
import { participantFlowDataSchema } from '../../../../../RJS/widgets/RJParticipantFlow/schemas/dataSchema';

// context
import useAuthoringDataStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringDataStore';
import useResultsTableViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableViewStore';

export const PFContext = createContext(null);

const ParticipantFlowPreview = (props) => {
  let { schema = null, options, formContext } = props;

  const {
    formData,
  } = useAuthoringDataStore(
    useShallow(state => ({
      formData: state.formData,
    }))
  )

  const {
    tableView
  } = useResultsTableViewStore()

  return (
    <PFContext.Provider value={{
      view: tableView,
      data: formData?.results?.participant_flow.participant_flow_table,
    }}>
      {tableView === "ctg" && (
        <Header 
          title="Reporting Groups" 
          bgColor={"rgb(228, 254, 224)"}
          arms={formData?.results?.participant_flow?.participant_flow_table?.global_arms || []}/>
      )}
      {tableView === "eudract" && (
        <PreAssignmentPreview 
          data={formData?.results?.participant_flow.participant_flow_table}
          schema={participantFlowDataSchema}/>
      )}
      <PeriodList>
        {formData?.results?.participant_flow.participant_flow_table?.[tableView + "_periods"] && (
          formData?.results?.participant_flow.participant_flow_table?.[tableView + "_periods"].map(period => {
            let arms = []
            if(tableView === "ctg") {
              arms = formData?.results?.participant_flow?.participant_flow_table?.global_arms || []
            } else {
              arms = period?.arms || []
            }
            
            return (
              <Period 
                arms={arms}
                key={period.id}
                schema={participantFlowDataSchema}
                period={period}
                unitType={formData?.results?.participant_flow?.type_units_analyzed}/>
            )
          })
        )}
      </PeriodList>
      
    </PFContext.Provider>
  );
};

export default ParticipantFlowPreview;

const PeriodList = styled.div`
  
`