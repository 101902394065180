import React from 'react';
import styled from 'styled-components';

// Components
import { Button } from 'components-design-system'

const CancelPRSUpload = ({
  setOpenModal,
  onCancelUpload
}) => {

  const onCancel = () => {
    onCancelUpload()
  }

  return (
    <Container>
      <ActionContainer>
        <Button
          onClick={() => setOpenModal(false)}
          variant="outlined">
          Back
        </Button>
        <Button
          onClick={() => onCancel()}
          variant="danger">
          Cancel Upload
        </Button>
      </ActionContainer>
    </Container>
  );
};

export default CancelPRSUpload;

const Container = styled.div`

`

const ModalBody = styled.div`
  margin-bottom: 30px;
`

const Text = styled.div`
  text-align: center;
`

const ActionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 6px;
`