import useGetApi from "../useGetApi"
import useMutateApi from "../useMutateApi"

// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

// utils
import getUrl from "api/apiMap"

const useStudySearchApi = () => {
  return useMutateApi({
    method: "post",
    apiEndpoint: `${getUrl(apiEndpoints.studySearch)}`,
    invalidateQueries: [], // TODO: limit to just study queries
    // successMsg: 'Successfully created assessment type'
  })
}

const useGlobalSearchApi = () => {
  return useMutateApi({
    method: "post",
    apiEndpoint: `${getUrl(apiEndpoints.globalSearch)}`,
    invalidateQueries: [], // TODO: limit to just study queries
    // successMsg: 'Successfully created assessment type'
  })
}

const useGetGlobalSponsorFilter = () => {
  return useGetApi(getUrl(apiEndpoints.globalSponsorFilter), queryKeys.globalSponsorFilter)
}

const usePostGlobalSponsorFilter = () => {
  return useMutateApi({
    method: "post",
    apiEndpoint: `${getUrl(apiEndpoints.globalSponsorFilter)}`,
    invalidateQueries: [queryKeys.globalSponsorFilter], // TODO: limit to just study queries
    successMsg: 'Successfully updated sponsor filter'
  })
}

export {
  useStudySearchApi,
  useGetGlobalSponsorFilter,
  usePostGlobalSponsorFilter,
  useGlobalSearchApi,
}