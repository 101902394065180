import useGetApi from "../useGetApi"
import useMutateApi from "../useMutateApi"
import axios from 'axios'

// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

// utils
import getUrl from "api/apiMap"

export const usePostResetUserPassword = () => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(apiEndpoints.resetPassword),
    invalidateQueries: [],
    successMsg: "Successfully reset password"
  })
}

export const usePostForgotPassword = () => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(apiEndpoints.forgotPassword),
    invalidateQueries: [],
    successMsg: "Password reset sent to your email"
  })
}

export const usePostLoginResetPassword = () => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(apiEndpoints.resetLoginPassword),
    invalidateQueries: [],
    successMsg: "Successfully reset password"
  })
}