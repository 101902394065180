import moreInfo from './shared/moreInfo';
import trialInfo from './shared/trialInfo';

import { getWidget } from '../shared/utils';

const hide = {
  "ui:options": {
    hide: true,
  },
}
export const ctgResultsUISchema = (configs) => {
  let preview = configs?.preview;

  let returnObj = {
    results: {
      "ui:order": ["participant_flow", "baseline_characteristics", "outcome_measures", "adverse_events", "*"],
      "ui:options": {
        classNames: {
          objectInnerContainer: "section-container",
        }
      },
      participant_flow: {
        "ui:options": {
          title: "Participant Flow",
          classNames: {
            objectTitleContainer: "rjs-h1-container",
            objectTitle: "rjs-h1",
            objectContainer: "p-24",
            fieldContainer: "rjs-full"
          }
        },
        protocol_enrollment: {
          ...hide
        },
        recruitment_details: {
          "ui:options": {
            title: "Recruitment Details",
            label: false,
            classNames: {
              fieldContainer: "rjs-full",
            },
          },
          "ui:widget": "textarea"
        },
        pre_assignment_details: {
          "ui:options": {
            title: "Pre-assignment Details (Optional)",
            label: false,
            classNames: {
              fieldContainer: "rjs-full",
            },
          },
          "ui:widget": "textarea"
        },
        type_units_analyzed: {
          "ui:options": {
            title: "Type of Units Assigned",
            label: false,
            classNames: {
              fieldContainer: "rjs-full",
              field: "rjs-col-1-3"
            },
          },
          "ui:widget": "TypeUnitsAssigned"
        },
        participant_flow_table: {
          "ui:options": {
            label: false,
          },
          "ui:widget": getWidget({ type: "ParticipantFlow", preview })
        }
      },
      baseline_characteristics: {
        "ui:options": {
          title: "Baseline Characteristics",
          classNames: {
            objectTitleContainer: "rjs-h1-container",
            objectTitle: "rjs-h1",
            objectContainer: "p-24",
            fieldContainer: "rjs-full"
          }
        },
        baseline_characteristics_table: {
          "ui:options": {
            label: false,
          },
          "ui:widget": getWidget({ type: "BaselineCharacteristics", preview })
        }
      },
      outcome_measures: {
        "ui:options": {
          title: "Outcome Measures",
          canCopyOutcomeMeasures: true,
          classNames: {
            objectTitleContainer: "rjs-h1-container",
            objectTitle: "rjs-h1",
            objectContainer: "p-24",
            fieldContainer: "rjs-full"
          }
        },
        outcome_measures_table: {
          "ui:options": {
            label: false,
          },
          "ui:widget": getWidget({ type: "OutcomeMeasures", preview })
        }
      },
      adverse_events: {
        "ui:options": {
          title: "Adverse Events",
          classNames: {
            objectTitleContainer: "rjs-h1-container",
            objectTitle: "rjs-h1",
            objectContainer: "p-24",
            fieldContainer: "rjs-full"
          }
        },
        adverse_events_table: {
          "ui:options": {
            label: false,
          },
          "ui:widget": getWidget({ type: "AdverseEvents", preview })
        }
      },
      more_info: moreInfo('ctg', preview),
      trial_info: trialInfo('ctg', preview),
    }
  }

  // if (preview === true) {
  //   getPreviewStyles(returnObj);
  // }

  return returnObj;
}