import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import PrimeField from '../../PrimeField/PrimeField';

const RJTextArea = (props) => {
  const [isDisabled, setDisabled] = useState(false);
  const [showWarning, setWarning] = useState(false);
  const options = props.uiSchema["ui:options"]
  const title = options.title;
  const placeholder = options.placeholder;

  useEffect(() => {
    let { value, schema, uiSchema } = props;
    if (schema) {
      let { minLength, maxLength } = props.schema;
      if (minLength && value && value.length < minLength) {
        setWarning(true);
      } else if (maxLength && value && value.length > maxLength) {
        setWarning(true);
      } else {
        setWarning(false);
      }
    }

    if(uiSchema) {
      if(uiSchema["ui:readonly"] || uiSchema["ui:disabled"]) {
        setDisabled(true)
      }
    }
  }, [props.value]);

  return (
    <Container>
      <PrimeField
        id={props.id}
        schema={{
          type: "textarea",
          placeholder,
          required: true,
          containerClassName: "field"
        }}
        onChange={(e) => props.onChange(e)}
        value={props.value}/>
      {/* <TextArea
        id={props.id}
        disabled={isDisabled}
        labelText={title}
        value={props.value}
        placeholder={placeholder}
        invalid={showWarning}
        invalidText={props.schema.errorMessage}
        onChange={(e) => props.onChange(e.target.value)}
      /> */}
    </Container>
  );
};

export default RJTextArea;

const Container = styled.div`

`
