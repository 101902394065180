import React from 'react'
import './preset-modal-content.scss'

import { Button } from 'components-design-system'

const DeletePresetSuccess = ({
  setOpenModal
}) => {
  return <div className="preset-modal-content">
    <div className="actions">
      <Button
        size="sm"
        onClick={() => setOpenModal(false)}>
        Okay
      </Button>
    </div>
  </div>
}

export default DeletePresetSuccess