import React from 'react'
import PropTypes from 'prop-types'
import './AssessmentInputBadges.scss'

const AssessmentInputBadges = ({
  data,
}) => {
  const assessmentInputs = () => {
    // could come as string - /api/s/{study_id}/assessments
    // or array - /api/a/{study_id}/
    if (typeof data.assessment_inputs === 'string') {
      return data.assessment_inputs.split(',')
    }
    return data.assessment_inputs
  }

  return <div className="assessment-badges">
    {data.is_override == false && data.is_manual == false ?
      assessmentInputs()?.length && Array.isArray(assessmentInputs())
        ? assessmentInputs().map((input) => {
          const splitString = input.split(': ')
          if (splitString?.length === 2) {
            return <div
              className="assessment-input-badge"
              key={splitString[0]}
            >
              <div className="assess-badge-type">{splitString[0]}</div>
              <div
                className="assess-badge-text"
              >{splitString[1]}</div>
            </div>
          }
          return null
        })
        : null :
      <div className="assessment-badges-empty">
        This assessment result was manually overridden
      </div>
    }
  </div>
}

AssessmentInputBadges.propTypes = {
  data: PropTypes.object,
}

export default AssessmentInputBadges