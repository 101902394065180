import React, {useState, useEffect} from 'react';
import dayjs from 'dayjs';

// components
import { Button } from 'components-design-system'

// styles
import './confirm-changes-modal.scss';

const ConfirmChangesModal = (props) => {
  const [showModal, setShowModal] = useState(false);

  const {
    state,
    ghostState,
    onSaveStateDates,
    onRevertStateDates,
    openConfirmDateChangeModal,
    setConfirmDateChangeModal,
    dateFormat,
    index,
    isLast,
    closeGhostState
  } = props;

  let {
    start_state_date,
    end_state_date
  } = ghostState

  useEffect(() => {
    if(openConfirmDateChangeModal) {
      if(dayjs(ghostState.start_state_date).format("YYYY-MM-DD") !== dayjs(state.start_state_date).format("YYYY-MM-DD") 
      || dayjs(ghostState.end_state_date).format("YYYY-MM-DD") !== dayjs(state.end_state_date).format("YYYY-MM-DD")) {
        setShowModal(true)
      } else {
        setConfirmDateChangeModal(false)
        closeGhostState()
      }
    }
  }, [openConfirmDateChangeModal])

  if(showModal === false) {
    return null;
  }

  let style = {

  }

  if(index === 0) {
    style = {
      marginLeft: 98
    }
  } else if(isLast) {
    style = {
      left: -120
    }
  }


  return (
    <div className={`confirm-state-dates-container ${openConfirmDateChangeModal && 'show'}`} style={style}>
      <div className="confirm-state-dates-header no-text-highlight">
        Are you sure you want to save changes?
      </div>
      <div className="confirm-state-dates-action-container">
        <Button 
          className="no-text-highlight"
          variant={"outlined"}
          onClick={() => {
            setConfirmDateChangeModal(false)
            onRevertStateDates()
          }}>
          Cancel
        </Button>
        <Button
          style={{ width: "100%" }}
          className="no-text-highlight"
          onClick={() => {
            onSaveStateDates(ghostState)
            setConfirmDateChangeModal(false)
          }}>
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default ConfirmChangesModal;