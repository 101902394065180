import React from 'react';
import { create } from 'zustand'

const initialState = {
  readOnly: true,
  isReviewerView: false,
  showVersionCompare: false,
  showPagePreview: false,
  formView: { id: "global", text: "Global" },
  formSection: { id: "protocol", text: "Protocol" },
  formStatus: "init",
  showRegistryBadges: false,
  currDataSchema: null,
  currUISchema: null,
  showPanel: localStorage.getItem('SHOW_PANEL') === "true" ? true : false,
  refreshFormData: false,
}

const useAuthoringViewStore = create((set) => ({
  ...initialState,
  setReadOnly: (readOnly) => set({ readOnly }),
  setIsReviewerView: (isReviewerView) => set({ isReviewerView }),
  setShowVersionCompare: (showVersionCompare) => set({ showVersionCompare }),
  setShowPagePreview: (showPagePreview) => set({ showPagePreview }),
  setFormView: (formView) => set({ formView }),
  setFormSection: (formSection) => set({ formSection }),
  setFormStatus: (formStatus) => set({ formStatus }),
  setShowRegistryBadges: (showRegistryBadges) => set({ showRegistryBadges }),
  setCurrDataSchema: (currDataSchema) => set({ currDataSchema }),
  setCurrUISchema: (currUISchema) => set({ currUISchema }),
  setShowPanel: (showPanel) => set({ showPanel }),
  setRefreshFormData: (refreshFormData) => set({ refreshFormData }),
  resetAuthoringViewStore: () => set(initialState),
}))

export default useAuthoringViewStore;