import useGetApi from "../useGetApi"
import useMutateApi from "../useMutateApi"

// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

// utils
import getUrl from "api/apiMap"

export const useGetRequestCategories = () => {
  return useGetApi(getUrl(apiEndpoints.requestCategories), queryKeys.requestCategories.all)
}

// mutate({ body })
export const usePostRequestCategory = () => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(apiEndpoints.requestCategories),
    invalidateQueries: [queryKeys.requestCategories.all],
    successMsg: "Successfully created request category"
  })
}

// mutate({ id, body })
export const usePutRequestCategory = () => {
  return useMutateApi({
    method: "put",
    apiEndpoint: getUrl(apiEndpoints.requestCategories),
    invalidateQueries: [queryKeys.requestCategories.all],
    successMsg: "Successfully updated request category"
  })
}

// mutate({ id })
export const useDeleteRequestCategory = () => {
  return useMutateApi({
    method: "delete",
    apiEndpoint: getUrl(apiEndpoints.requestCategories),
    invalidateQueries: [queryKeys.requestCategories.all],
    successMsg: "Successfully deleted request category"
  })
}