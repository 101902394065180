import React, { useEffect } from 'react'
import dayjs from 'dayjs'
import getUrl from 'api/apiMap'
import useGetApi from '../useGetApi'
import useMutateApi from '../useMutateApi'
import useWorkflowTracker from 'context/hooks/useWorkflowTracker'

// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

const useGetRequests = () => {
  return useGetApi(getUrl(apiEndpoints.requests), queryKeys.requests.all)
}

const usePostRequest = () => {
  return useMutateApi({
    method: 'post',
    apiEndpoint: getUrl(apiEndpoints.requests),
    invalidateQueries: [queryKeys.requests.all],
    successMsg: 'Successfully created request',
  })
}

// options: { enabled: false }
const useGetRequest = (reqId, options) => {
  return useGetApi(getUrl(`${apiEndpoints.requests}${reqId}/`), queryKeys.requests.detail(Number(reqId)), options)
}

const usePutRequestPayload = (request) => {
  const {
    projectedCompletionDate,
  } = useWorkflowTracker()

  const [requestPayload, setRequestPayload] = React.useState()

  useEffect(() => {
    if (request) {
      let payload = {}
      payload = { ...request }

      const studyDbIds = request.corestudies.map((study) => study.id)
      payload.study_db_ids = studyDbIds
      delete payload.corestudies

      if (payload.actual_completion_date === null) {
        payload.actual_completion_date = ''
      }

      payload.request_data = { ...request?.request_data }
      payload.request_data.requestor = request.request_data.requestor // TODO: review b/c not on docs
      payload.request_data.study_data = { ...request?.request_data?.study_data }
      payload.request_data.custom_data = { ...request?.request_data?.custom_data }
      payload.request_data.secondary_ids = [...request?.request_data?.secondary_ids]

      const completionDate = dayjs(projectedCompletionDate).format("YYYY-MM-DD")
      if (completionDate && completionDate !== 'Invalid Date') {
        payload.projected_completion_date = completionDate
      } else {
        delete payload.projected_completion_date
        // payload.projected_completion_date = ''
      }

      delete payload.created_at
      delete payload.id

      // TODO: Fields not on docs: delete from or include wth payload:
      delete payload.request_id
      delete payload.request_type_name
      delete payload.status
      delete payload.workflow_id

      console.log('usePutRequestPayload', {
        request,
        payload,
        reqId: request?.id,
      })
      setRequestPayload(payload)
    }
  }, [request])

  const updateRequestPayload = (field, value) => {
    let payload = { ...requestPayload }
    payload[field] = value
    setRequestPayload(payload)
    return payload
  }

  return {
    requestPayload,
    updateRequestPayload,
  }
}

const usePutRequest = (id) => {
  return useMutateApi({
    method: 'put',
    apiEndpoint: getUrl(`${apiEndpoints.requests}${id}/`),
    invalidateQueries: [queryKeys.requests.detail(Number(id))],
    successMsg: 'Successfully updated request',
  })
}

// toggle request's favorite boolean value
const usePostFavoriteRequest = (requestId) => {
  return useMutateApi({
    method: 'post',
    apiEndpoint: getUrl(`${apiEndpoints.requests}${requestId}/favorite/`),
    invalidateQueries: [queryKeys.requests.all, queryKeys.favoriteRequests],
    successMsg: 'Successfully updated favorite request',
  })
}

const usePostStudyDataSync = (id) => {
  return useMutateApi({
    method: 'post',
    apiEndpoint: getUrl(`${apiEndpoints.requests}${id}/stu-data-sync/`),
    invalidateQueries: [queryKeys.requests.detail(Number(id))],
    successMsg: 'Sync successful',
  })
}

export {
  useGetRequests,
  usePostRequest,
  useGetRequest,
  usePutRequestPayload,
  usePutRequest,
  usePostFavoriteRequest,
  usePostStudyDataSync,
}