import {
  countries,
} from '../enum/enum';

export const studyDetailsDataSchema = {
  study_details_detailed_description: {
    type: "string"
  },
  vendor: {
    type: "string",
  },
  vendor_contact: {
    type: "string",
  },
  acquired_from: {
    type: "string",
  },
  transferred_to: {
    type: "string",
  },
  unapproved_new_formulation_marketed_compound: {
    type: "string",
  },
  proprietary_name: {
    type: "string",
  },
  established_name: {
    type: "string",
  },
  product_manufactured: {
    type: "string",
  },
  compound_code: {
    type: "string",
  },
  generic_name: {
    type: "string",
  },
  brand_name: {
    type: "string",
  },
  primary_cro: {
    type: "string",
  },
  availability_expanded_access: {
    type: "string",
  },
  us_fda_regulated_drug_product: {
    type: "string",
  },
  us_fda_regulated_device_product: {
    type: "string",
  },
  secondary_ids: {
    type: "array",
    items: {
      type: "object",
      onAddButtonTitle: "Add Secondary ID",
      properties: {
        secondary_id_type: {
          type: "string",
          enum: ["US NIH Grant/Contract Award Number", "Other Grant/Funding Number", "Registry Identifier",
            "EudraCT Number", "WHO Universal Trial Number (UTN)", "ClinicalTrials.gov identifier (NCT number)", "ISRCTN number", "Other Identifier"
          ]
        },
        id: {
          title: "Identifier",
          type: "string",
        }
      },
      dependencies: {
        secondary_id_type: {
          oneOf: [
            // {
            //   properties: {
            //     secondary_id_type: {
            //       enum: ["US NIH Grant/Contract Award Number"]
            //     },
            //     id: {
            //       title: "US NIH Grant/Contract Award Number",
            //       type: "string",
            //     }
            //   }
            // },
            {
              properties: {
                secondary_id_type: {
                  enum: ["Other Grant/Funding Number"]
                },
                // id: {
                //   title: "Other Grant/Funding Number",
                //   type: "string",
                // },
                domain: {
                  title: "Grantor or Funder",
                  type: "string",
                },
              }
            },
            {
              properties: {
                secondary_id_type: {
                  enum: ["Registry Identifier"]
                },
                // id: {
                //   title: "Registry Identifier",
                //   type: "string",
                // },
                domain: {
                  title: "Registry Name",
                  type: "string",
                }
              }
            },
            // {
            //   properties: {
            //     secondary_id_type: {
            //       enum: ["EudraCT Number"]
            //     },
            //     id: {
            //       title: "EudraCT Number",
            //       type: "string",
            //     }
            //   }
            // },
            {
              properties: {
                secondary_id_type: {
                  enum: ["Other Identifier"]
                },
                // id: {
                //   title: "Other Identifier",
                //   type: "string",
                // },
                domain: {
                  title: "Issuing Organization",
                  type: "string",
                }
              }
            },
          ]
        }
      }
    }
  },
  general: {
    type: "object",
    properties: {
      countries: {
        type: "string",
        enum: countries
      },
      indication: {
        type: "string",
      },
      remarks: {
        type: "string",
      },
      // eudract_number: {
      //   type: "string"
      // },
      intervention_type: {
        type: "string",
        enum: ["Drug", "Device", "Biological/Vaccine", "Procedure/Surgery", "Radiation", "Behavioral", "Genetic", "Dietary Supplement", "Combination Product", "Diagnostic Test", "Other"]
      },
      product_id: {
        type: "string",
      },
      product_name: {
        type: "string",
      },
      program_id: {
        type: "string",
      },
      program_name: {
        type: "string",
      },
      therapeutic: {
        type: "string",
      },
      countries_manufactured: {
        type: "string",
        enum: countries
      },
      age_group: {
        type: "string"
      },
      // minimum_age_value: {
      //   type: "string",
      // },
      // maximum_age_value: {
      //   type: "string",
      // },
    }
  },
  status: {
    type: "object",
    properties: {
      study_status: {
        type: "string",
      },
      record_status: {
        type: "string",
        enum: ["Archived", "Unverified", "Verified"],
      },
    }
  },
  milestone_dates: {
    type: "object",
    properties: {
      conception_date: {
        type: "string"
      },
      pra: {
        type: "object",
        properties: {
          baseline: {
            type: "string"
          },
          forecast: {
            type: "string"
          },
          actual: {
            type: "string"
          },
        }
      },
      fpi: {
        type: "object",
        properties: {
          baseline: {
            type: "string"
          },
          forecast: {
            type: "string"
          },
          actual: {
            type: "string"
          },
        }
      },
      lpo: {
        type: "object",
        properties: {
          baseline: {
            type: "string"
          },
          forecast: {
            type: "string"
          },
          actual: {
            type: "string"
          },
        }
      },
      pcd: {
        type: "object",
        properties: {
          baseline: {
            type: "string"
          },
          forecast: {
            type: "string"
          },
          actual: {
            type: "string"
          },
        }
      },
      csr: {
        type: "object",
        properties: {
          baseline: {
            type: "string"
          },
          forecast: {
            type: "string"
          },
          actual: {
            type: "string"
          },
        }
      },
      fsa: {
        type: "object",
        properties: {
          baseline: {
            type: "string"
          },
          forecast: {
            type: "string"
          },
          actual: {
            type: "string"
          },
        }
      },
      recruitment_end_date: {
        type: "object",
        properties: {
          forecast: {
            type: "string"
          },
          actual: {
            type: "string"
          },
        }
      },
      first_patient_enrolled_date: {
        type: "object",
        properties: {
          forecast: {
            type: "string"
          },
          actual: {
            type: "string"
          },
        }
      }
    }
  },
  secondary_attributes: {
    type: "object",
    properties: {
      internal: {
        type: "object",
        properties: {
          first_product_indication_approval_date_in_any_country: {
            type: "object",
            properties: {
              baseline: {
                type: "string"
              },
              forecast: {
                type: "string"
              },
              actual: {
                type: "string"
              },
            }
          },
          product_indication_first_approval_date: {
            type: "object",
            properties: {
              baseline: {
                type: "string"
              },
              forecast: {
                type: "string"
              },
              actual: {
                type: "string"
              },
            }
          },
          fcsr_live: {
            type: "object",
            properties: {
              forecast: {
                type: "string"
              },
            }
          },
          fpi_live: {
            type: "object",
            properties: {
              forecast: {
                type: "string"
              },
            }
          },
          fsa_live: {
            type: "object",
            properties: {
              forecast: {
                type: "string"
              },
            }
          },
          lpi_live: {
            type: "object",
            properties: {
              forecast: {
                type: "string"
              },
            }
          },
          lpo_live: {
            type: "object",
            properties: {
              forecast: {
                type: "string"
              },
            }
          },
          lpop_live: {
            type: "object",
            properties: {
            forecast: {
                type: "string"
              },
            }
          },
          pca_live: {
            type: "object",
            properties: {
            forecast: {
                type: "string"
              },
            }
          },
          pra_live: {
            type: "object",
            properties: {
              forecast: {
                type: "string"
              },
            }
          },
          tlgs_live: {
            type: "object",
            properties: {
             forecast: {
                type: "string"
              },
            }
          },
          first_patient_consented_live: {
            type: "object",
            properties: {
             forecast: {
                type: "string"
              },
            }
          },
          first_patient_consented: {
            type: "object",
            properties: {
              forecast: {
                type: "string"
              },
              actual: {
                type: "string"
              },
            }
          },
          lpi: {
            type: "object",
            properties: {
              forecast: {
                type: "string"
              },
              actual: {
                type: "string"
              },
            }
          },
          pca: {
            type: "object",
            properties: {
              forecast: {
                type: "string"
              },
              actual: {
                type: "string"
              },
            }
          },
          tlgs: {
            type: "object",
            properties: {
              forecast: {
                type: "string"
              },
              actual: {
                type: "string"
              },
            }
          },
          gov_approval: {
            type: "object",
            properties: {
              forecast: {
                type: "string"
              },
              actual: {
                type: "string"
              },
            }
          },
          alternate_id: {
            type: "string",
          },
          affiliate_val: {
            type: "string",
          },
        /*  countries_product_authorized_in: {
            type: "string",
            enum: countries
          },*/
          primary_site_location: {
            type: "string",
          },
          redacted_documents: {
            type: "string",
          },
          result_preparation: {
            type: "string",
          },
          lsfd: {
            type: "object",
            properties: {
              baseline: {
                type: "string"
              },
            }
          },
          long_term_follow_up_lplv: {
            type: "object",
            properties: {
              baseline: {
                type: "string"
              },
            }
          },
          in_scope_for_data_sharing: {
            enum: ["Yes", "No", "NA"]
          },
          journal_publication: {
            type: "string"
          },
          publication_plan: {
            type: "string"
          },
          document_redaction_required: {
            enum: ["Yes", "No", "NA"]
          },
          redaction_required_for_publication: {
            enum: ["Yes", "No", "NA"]
          },
          product_approval_date_on_before_lpo: {
            enum: ["Yes", "No", "NA"]
          },
          product_indication_approved_in_any_country: {
            enum: ["Yes", "No", "NA"]
          },
          product_discontinued: {
            enum: ["Yes", "No", "NA"]
          },
          product_discontinued_date: {
            type: "object",
            properties: {
              baseline: {
                type: "string"
              },
              forecast: {
                type: "string"
              },
              actual: {
                type: "string"
              },
            }
          },
        }
      },
      usa: {
        type: "object",
        properties: {
          is_product_fda_approved: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
          product_indication_approved_by_fda_and_ema_on_or_before_lpo: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
          fda_product_approval_date: {
            type: "object",
            properties: {
              baseline: {
                type: "string"
              },
            }
          },
          // ctg_delay_certification_submitted_date: {
          //   type: "object",
          //   properties: {
          //     baseline: {
          //       type: "string"
          //     },
          //   }
          // },
          // us_ind_ide: {
          //   type: "string",
          //   enum: ["Yes", "No", "NA"]
          // },
          us_imp: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
          // us_ide: {
          //   type: "string",
          //   enum: ["Yes", "No", "NA"]
          // },
          section_801: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
          is_ctg_delay_certification_submitted: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
          // manufactured: {
          //   type: "string",
          //   enum: ["Yes", "No", "NA"]
          // },
          manufactured_in_us: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
          maintenance_amendment_required: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
          ctg_maintenance_required: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
          protocol_amendment_required: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
        /*  product_discontinuation_date_on_before_lpo: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          }, */
          FDA_approval_of_drug_for_the_indication_received_after_1_year_of_PCD: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
          /*LPO_1_year_after_the_date_of_filing_of_delayed_results: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },*/
          product_fda_any: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
         /* is_drug_fda_approved_any_use_before_PCD: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },*/
          /*is_drug_fda_approved_any_use_before_LPO: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },*/
          /*is_fda_approval_of_drug_for_the_indication_received_after_1_year_of_LPO: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },*/
          /*product_and_indication_approved_by_FDA_before_PCD: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },*/
          /*product_for_the_indication_studied_approved_by_FDA_before_LPO: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },*/
          /*SCD_1_year: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
          SCD_2_year: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
          is_pcd: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },*/
          type_results: {
            type: "string",
            enum: ["Primary Results", "Final Results"]
          },
          /*product_clinical: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },*/
          fda_market_approval: {
            type: "string",
          },
          manufacturer: {
            type: "string",
          },
          fda_product_approval_date_any_use: {
            type: "object",
            properties: {
              baseline: {
                type: "string"
              }
            }
          },
          ctg_interim_results_required: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
          /*sponsor_is_manufacturer: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },*/
          /*expanded_access_already_registered: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },*/
          /*expanded_access_available_at_registration_of_act: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },*/
          fda_product_approval_date: {
            type: "object",
            properties: {
              baseline: {
                type: "string"
              },
              forecast: {
                type: "string"
              },
              actual: {
                type: "string"
              },
            }
          },
          product_indication_latest_approval_date_fda_ema: {
            type: "object",
            properties: {
              baseline: {
                type: "string"
              },
              forecast: {
                type: "string"
              },
              actual: {
                type: "string"
              },
            }
          },
          delayed_certification_filed_date: {
            type: "object",
            properties: {
               baseline: {
                 type: "string"
               },
               forecast: {
                 type: "string"
               },
              actual: {
                type: "string"
              },
            }
          },
          /*expanded_access_availability_date: {
            type: "object",
            properties: {
               baseline: {
                 type: "string"
               },
               forecast: {
                 type: "string"
               },
              actual: {
                type: "string"
              },
            }
          },
        }*/
        },
      },
      europe: {
        type: "object",
        properties: {
          eu_marketing_authorization: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
          ema_cta_date: {
            type: "object",
            properties: {
              baseline: {
                type: "string"
              },
              forecast: {
                type: "string"
              },
              actual: {
                type: "string"
              },
            }
          },
          ema_cta: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
          pass_category: {
            type: "string",
          },
          pediatric_study: {
             type: "string",
             enum: ["Yes", "No", "NA"]
          },
          eu_pip: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
          date_of_latest_approval: {
            type: "object",
            properties: {
              baseline: {
                type: "string"
              }
            }
          },
          part_of_an_agreed_pip: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
          /*marketing_holder: {
            type: "string",
            enum: ["Yes", "No"]
          },*/
          date_of_cta: {
            type: "object",
            properties: {
              baseline: {
                type: "string"
              }
            }
          },
          date_of_results: {
            type: "object",
            properties: {
              baseline: {
                type: "string"
              }
            }
          },
          /*eu_marketing_authorization_application_date: {
            type: "object",
            properties: {
              baseline: {
                type: "string"
              },
              forecast: {
                type: "string"
              },
              actual: {
                type: "string"
              },
            }
          },*/
          eu_subsequent_marketing_authorization_application_date: {
            type: "object",
            properties: {
              baseline: {
                type: "string"
              },
              forecast: {
                type: "string"
              },
              actual: {
                type: "string"
              },
            }
          },
          marketing_authorization_holder_for_the_product_under_investigation: {
            type: "string"
          },
          pass: {
            enum: ["Yes", "No", "NA"]
          },
          eudract_interim_results_required: {
            enum: ["Yes", "No", "NA"]
          },
          trial_ongoing_in_eu_eea_on_after_jan312025: {
            enum: ["Yes", "No", "NA"]
          },
          sponsor_opted_for_submission_per_eu_directive: {
            enum: ["Yes", "No", "NA"]
          },
          cta_submission_per_eu_directive_complete: {
            enum: ["Yes", "No", "NA"]
          },
          intermediate_result_analysis_specified_in_protocol: {
            enum: ["Yes", "No", "NA"]
          },
          date_of_initial_application_cta_submitted_in_eu_eea: {
            type: "object",
            properties: {
              baseline: {
                type: "string"
              }
            }
          },
          lpo_interim_analysis: {
            type: "object",
            properties: {
              baseline: {
                type: "string"
              }
            }
          },
          lpo_eu_eea: {
            type: "object",
            properties: {
              baseline: {
                type: "string"
              },
              forecast: {
                type: "string"
              },
              actual: {
                type: "string"
              },
            }
          },
        }
      },
      germany: {
        type: "object",
        properties: {
          confirmatory_study: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
          germany_cta_date: {
            type: "object",
            properties: {
              baseline: {
                type: "string"
              },
              forecast: {
                type: "string"
              },
              actual: {
                type: "string"
              },
            }
          },
          germany_cta: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
          mma_smaa_approval_date: {
            type: "object",
            properties: {
              baseline: {
                type: "string"
              },
              forecast: {
                type: "string"
              },
              actual: {
                type: "string"
              },
            }
          },
          product_authorized_in_eu_germany: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
          /*product_authorized_in_EU_or_Germany_before_LPO: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },*/
          product_authorized_in_eu_germany_date: {
            type: "object",
            properties: {
              baseline: {
                type: "string"
              }
            }
          },
          /*product_authorized_in_eu_germany_before_jan272022: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },*/
          /*product_authorized_eu_germany_before_july272021: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },*/
          mma_smaa_submitted_to_germany: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
          /*product_authorization_date_in_eu_germany_before_jan012011: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },*/
        }
      },
      spain: {
        type: "object",
        properties: {
          /*aemps_authorization_date: {
            type: "object",
            properties: {
              baseline: {
                type: "string"
              }
            }*/
          aemps_authorization: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
          post_authorization_study: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
          rec_authorization: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
          rec_authorization_date: {
            type: "object",
            properties: {
              date_baseline_rec: {
                type: "string"
              }
            }
          },
          registered_on_reec: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
          with_prospective_follow_up_drugs: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
          results_published_in_any_scientific_journal_or_other_media: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
          involves_medicinal_product: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
          date_of_publication: {
            type: "object",
            properties: {
              baseline: {
                type: "string"
              }
            }
          },
        },        
      },
      netherlands: {
        type: "object",
        properties: {
          involves_dutch_people: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
          /*study_protocol_submitted_to_ccmo_on_after_mar152002: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          }*/
        }
      },
      mexico: {
        type: "object",
        properties: {
          cofepris_approval: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
          cofepris_approval_date: {
            type: "object",
            properties: {
              baseline: {
                type: "string"
              }
            }
          },
        }
      },
      south_korea: {
        type: "object",
        properties: {
          study_funded_by_korean_ministry_of_health_welfare: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
          study_registered_on_cris: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
          /*study_approved_by_mfds_before_2019: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },*/
        }
      },
      japan: {
        type: "object",
        properties: {
          bioequivalence_study: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
          product_approved_in_any_country: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
          /*product_approved_in_any_country_before_lpo: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },*/
          /*submission: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },*/
          date_of_approval: {
            type: "object",
            properties: {
              baseline: {
                type: "string"
              }
            }
          },
          trial_notification_submission_date: {
            type: "object",
            properties: {
              baseline: {
                type: "string"
              }
            }
          },
        }
      },
      united_kingdom: {
        type: "object",
        properties: {
         /* study_approved: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },*/
          clinical_trial: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
         /* ctimp_submitted_on_after_jan012022: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },*/
          is_research_study: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
          /*final_report_submitted_before_sep152021: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },*/
          final_report_submission_date: {
          type: "object",
            properties: {
              baseline: {
                type: "string"
              }
          }
          },
          study_approval_uk_research_ethics_committee_date: {
          type: "object",
            properties: {
              baseline: {
                type: "string"
              }
          }
          },
          ctimp_submission_date: {
          type: "object",
            properties: {
              baseline: {
                type: "string"
              }
          }
          }
        }
      },
      australia_and_newzealand: {
        type: "object",
        properties: {
          study_registered_on_anzctr: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
        }
      },
      /*canada: {
        type: "object",
        properties: {
          study_authorized_in_canada_on_after_may292013: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
        }
      }, */
      /*portugal: {
        type: "object",
        properties: {
          study_submitted_to_nca_infarmed_on_after_jan012017: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
          study_submitted_to_nca_infarmed_before_dec052016: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
        }
      },*/
      south_africa: {
        type: "object",
        properties: {
          study_approved_by_south_african_research_ethics_committee: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
        }
      },
      switzerland: {
        type: "object",
        properties: {
          /*study_submitted_to_swiss_ethics_committee_before_jan012014: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },*/
          study_received_approval_from_swiss_ethics_committee: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
        }
      },
      /*turkey: {
        type: "object",
        properties: {
          *//*study_information_submitted_to_turkish_agency_on_after_dec012019: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },*//*
          study_information_submitted_to_turkish_agency_in_after_2016: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
        }
      },*/
      china: {
        type: "object",
        properties: {
          new_drug_application_submitted_to_chinese_authority: {
            type: "string",
            enum: ["Yes", "No", "NA"]
          },
        }
      },
    }
  },
  protocol_amendments: {
    title: "Protocol Amendments",
    type: "object",
    properties: {
      protocol_amendment: {
        type: "array",
        items: {
          type: "object",
          onAddButtonTitle: "Add Protocol Amendment",
          properties: {
            number: {
              type: "string",
            },
            type: {
              type: "string",
            },
            date: {
              type: "string",
            },
            reason: {
              type: "string",
            },
            comments: {
              type: "string",
            },
          }
        }
      }
    }
  }
}