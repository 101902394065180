import React from "react";
import { Tooltip } from "antd";

// if value is null, enter "N/A" instead of blank value
const NaCellRenderer = (props) => {
  const { value } = props;
  if (!value) return <div>N/A</div>;
  let str = value;
  if (value === "UNCHANGED_STUDY") {
    str = "Unchanged Study"
  } else if (value === "CHANGED_STUDY") {
    str = "Changed Study"
  } else if (value.includes("ERROR")) {
    str = (
      <Tooltip
        title={props.data?.prs_failure_message}>
        <i className="fal fa-exclamation-circle" style={{ color: "#D7542C"}}></i>
      </Tooltip>
    )
  }

  return (
    <div>
      {str}
    </div>
  );
};

export default NaCellRenderer;
