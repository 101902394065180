import React, { Fragment } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

const Measure = ({
  data,
  bmId,
  formData,
  bgColor,
  arms,
  showTotal,
  view,
  typeOfUnits,
  firstColumnWidth,
  baselineData
}) => {
  const getCellValue = (category, cell, row, colIndex) => {
    if (view === "eudract" && !data.parameter_type && !data.dispersion_type) {
      return cell.value
    }

    let value = `${cell.value}`;

    if ((cell?.value === "" || cell?.value === undefined || cell?.value === null)) {
      value = "-"
    }

    if (category.title === "Number Analyzed") {
      value += " participants"
    } else if (data.parameter_type !== "Count of Participants" && data.parameter_type !== "Number" && data.parameter_type !== "Count of Units") {
      if (data.dispersion_type === "Standard Deviation") {
        value += ` (${(cell.dispersion_value || "-")})`
      } else {
        value += ` (${(cell.dispersion_value || "-")} to ${(cell.dispersion_value_upper_bound || "")})`
      }
    } else if (row.categories?.[0]?.title === "Number Analyzed"
      && row.categories?.[0]?.values[colIndex].value
      && cell.value
      && typeof Number(cell.value) === "number"
      && Number(cell.value) !== NaN) {
      let percent = 0;
      percent = (Number(cell.value) / Number(row.categories[0].values[colIndex].value)) * 100;
      if (percent === NaN) percent = 0
      value += ` (${percent?.toFixed(2)}%)`
    }
    return value;
  }

  const getTotalValue = (category, row) => {
    let foundBM = formData.baseline_measures.find(bm => bm.id === bmId);

    if (foundBM) {
      let foundRow = foundBM.rows.find(bmRow => bmRow.id === row.id);

      if (foundRow) {
        let foundCat = foundRow.categories.find(bmCat => bmCat.id === category.id);
        if (foundCat) {
          if (foundCat.title === "Number Analyzed") {
            // value += " participants"
            let value = foundCat.values.reduce((acc, item) => {
              if (item?.value) return acc + Number(item.value)
              return acc
            }, 0)
            return value + " participants";
          }

          if (foundCat?.total?.value || typeof foundCat?.total?.value === "number") {
            let value = `${foundCat.total.value}`;

            if (data.parameter_type !== "Count of Participants" && data.parameter_type !== "Number" && data.parameter_type !== "Count of Units") {
              if (data.dispersion_type === "Standard Deviation") {
                if (foundCat?.total?.dispersion_value === "" || !foundCat?.total?.dispersion_value) {
                  value += ` (${"-"})`
                } else {
                  value += ` (${Number(foundCat?.total?.dispersion_value)})`
                }
              } else {
                if (foundCat?.total?.dispersion_value === "" || !foundCat?.total?.dispersion_value) {
                  value += ` (${"-"}`
                } else {
                  value += ` (${Number(foundCat?.total?.dispersion_value)} to `
                }

                if (foundCat?.total?.dispersion_value_upper_bound === "" || !foundCat?.total?.dispersion_value_upper_bound) {
                  value += `to - )`
                } else if (foundCat?.total?.dispersion_value_upper_bound) {
                  value += `${Number(foundCat?.total?.dispersion_value_upper_bound)})`
                }
              }
            } else {
              if (typeof Number(value) === "number") {
                if (foundRow?.categories?.[0]?.title === "Number Analyzed") {
                  let total = foundRow.categories?.[0]?.values?.reduce((acc, item) => {
                    if (item?.value) return acc + Number(item.value)
                    return acc
                  }, 0)

                  let percent = (Number(value) / total) * 100;
                  value += ` (${percent?.toFixed(2)}%)`
                }
              }
            }

            return value;
          }
        }
      }
    }

    return "-"
  }

  const getEudractTotalValue = (category, row) => {
    let foundBM = formData.baseline_measures.find(bm => bm.id === bmId);

    if (foundBM) {
      let foundRow = foundBM.rows.find(bmRow => bmRow.id === row.id);

      if (foundRow) {
        let foundCat = foundRow.categories.find(bmCat => bmCat.id === category.id);
        if (foundCat) {
          if (data.dispersion_type && data.parameter_type) {
            return "-"
          }

          if (foundCat?.values) {
            let value = foundCat.values.reduce((acc, val) => {
              if (val.value) return acc + Number(val.value)
              return acc
            }, 0)
            return value
          }
        }
      }
    }
    return "-"
  }

  const getMeasureType = () => {
    let value = `${data.parameter_type}`;
    if (data.parameter_type !== "Count of Participants"
      && data.parameter_type !== "Number") {
      value += ` (${data.dispersion_type})`
    }
    return value;
  }

  const getCategoryTitle = (category, row) => {
    if (view === "eudract" && data?.rows?.length > 1) {
      return `${row.title} ${category.title}`
    }

    if (category?.title !== "Number Analyzed" && data?.title === "Region of Enrollment") {
      return row?.title || category.title
    }
    return category?.title || ""
  }

  const getRegionEnrollment = () => {
    let foundCtgBaseline = baselineData?.ctg?.baseline_measures.find(bm => bm.id === data.id)
    if (foundCtgBaseline) {
      return true
    }
    return false
  }

  return (
    <>
      {data?.rows?.map((row, rowIndex) => {
        return (
          <Fragment key={uuidv4()}>
            {row?.categories?.map((category, i) => {
              let rowColSpan = 1;
              let rowRowSpan = row.categories.length
              let catColSpan = 1;
              let catRowSpan = 1;
              let hideCategory = false

              if (view === "eudract" && data?.rows?.length > 1) {
                rowRowSpan = data.rows.length
              }


              return (
                <tr key={uuidv4()} rowSpan={rowRowSpan}>
                  {i === 0 && rowIndex === 0 && (
                    <th
                      style={{ backgroundColor: bgColor, width: firstColumnWidth }}
                      colSpan={catColSpan}
                      rowSpan={rowRowSpan}
                    >
                      <MeasureTitle>
                        {data.title || "(Title)"}
                        <br />
                        <Text style={{ fontWeight: "bold" }}>{view === "eudract" ? "" : row.title}</Text>
                        <Text>{data?.measure_description}</Text>
                      </MeasureTitle>
                      {(view === "ctg" || (view === "eudract" && data.parameter_type && data.dispersion_type)) &&
                        <Text>
                          <p style={{ fontWeight: "bold", fontSize: ".7rem", marginBottom: 0 }}>
                            Measure Type:
                          </p>
                          {getMeasureType()}
                        </Text>
                      }
                      <Text><p style={{ fontWeight: "bold", fontSize: ".7rem", marginBottom: 0 }}>Unit of Measure:</p> {data.unit_of_measure}</Text>
                    </th>
                  )}
                  {!hideCategory && <td
                    colSpan={1}
                    style={{ backgroundColor: bgColor, width: firstColumnWidth }}>
                    {getCategoryTitle(category, row)}
                  </td>}
                  {category?.values?.map((cell, j) => {

                    let value = getCellValue(category, cell, row, j)
                    return (
                      <td key={uuidv4()}>
                        {value}

                        {(data.baseline_measure_id !== "not-collected" && cell?.num_units_analyzed) && `\n${cell.num_units_analyzed} ${typeOfUnits ? typeOfUnits : "(Units)"}`}
                      </td>
                    )
                  })}
                  {showTotal && <td>
                    {view === "ctg" && getTotalValue(category, row)}
                    {view === "eudract" && getEudractTotalValue(category, row)}
                  </td>}
                </tr>
              )
            })}
          </Fragment>
        )
      })}
      {data.measure_analysis_population_description && (
        <tr>
          <td
            colSpan={2}
            style={{ backgroundColor: bgColor }}>
            Measure Analysis Population Description
          </td>
          <td colSpan={arms.length + 1}>
            {data.measure_analysis_population_description}
          </td>
        </tr>
      )}
    </>
  );
};

export default Measure;

const MeasureTitle = styled.div`
  font-weight: bold;
  margin-bottom: 12px;
`

const Text = styled.div`
  font-size: .7rem;
  font-weight: normal;
  margin-top: 2px;
`