export const newSiteSchema = {
  type: "object",
  properties: {
    facility: {
      type: "object",
      properties: {
        name: {
          type: "string",
        },
        address: {
          type: "object",
          properties: {
            city: {
              type: "string",
            },
            state: {
              type: "string",
            },
            zip: {
              type: "string",
            },
            country: {
              type: "string",
            },
            
          }
        }
      }
    },
    site_id: {
      type: "string"
    },
    site_number: {
      type: "string"
    },
    site_active_date: {
      type: "string"
    },
    status: {
      type: "string",
      enum: ["Not yet recruiting", "Recruiting", "Enrolling by invitation", "Active, not recruiting", "Completed", "Suspended", "Terminated", "Withdrawn"],
    },
    contact :{
      type: "object",
      properties: {
        first_name: {
          type: "string",
        },
        middle_name: {
          type: "string",
        },
        last_name: {
          type: "string",
        },
        phone: {
          type: "string",
        },
        phone_ext: {
          type: "string",
        },
        email: {
          type: "string",
        },
        degrees: {
          type: "string"
        }
      }
    },
    contact_backup: {
      type: "object",
      properties: {
        first_name: {
          type: "string",
        },
        middle_name: {
          type: "string",
        },
        last_name: {
          type: "string",
        },
        phone: {
          type: "string",
        },
        phone_ext: {
          type: "string",
        },
        email: {
          type: "string",
        },
        degrees: {
          type: "string"
        }
      }
    },
    investigator: {
      type: "array",
      items: {
        type: "object",
        properties: {
          first_name: {
            type: "string",
          },
          middle_name: {
            type: "string",
          },
          last_name: {
            type: "string",
          },
          role: {
            type: "string",
            enum: ["Principal Investigator", "Sub-Investigator"],
          },
          degrees: {
            type: "string",
          }
        }
      }
    }
  }
}