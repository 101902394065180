import { keyframes } from 'styled-components';

export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const spinPulse = keyframes`
  0% {
    transform: rotate(160deg);
    opacity: 0;
  }
  50% {
    transform: rotate(145deg);
    opacity: 1;
  }
  100% {
    transform: rotate(-320deg);
    opacity: 0;
  }
`;

export const spinOffPulse = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;