import React, { useState, useEffect } from 'react';
import PrimeField from 'components/PrimeField/PrimeField'

// api
import { useGetSponsorTypes } from 'api/hooks/admin/useSponsorApi';
import { useGetCollaboratorTypes } from 'api/hooks/admin/useCollaboratorTypesApi';
import { useGetConditions } from 'api/hooks/admin/useConditionApi';

// hoc 
import InputWrapper from '../../hoc/InputWrapper';

const RJLookahead = (props) => {
  const [items, setItems] = useState([]);
  let {
    id,
    carbonProps,
    options,
    value,
    onChange,
  } = props;
  const [selectedItem, setSelectedItem] = useState(null);

  const { data: sponsors = [] } = useGetSponsorTypes()
  const { data: collaborators = [] } = useGetCollaboratorTypes()
  const { data: conditions = [] } = useGetConditions()

  useEffect(() => {
    if (id.includes("_condition") && conditions) {
      let newItems = conditions?.map((option) => {
        return { id: option.name, text: option.name };
      });
      setItems(newItems);
    } else if (id.includes("_sponsor") && sponsors) {
      let newItems = sponsors?.map((option) => {
        return { id: option.name, text: option.name };
      });
      setItems(newItems);
    } else if (id.includes("_collaborator") && collaborators) {
      let newItems = collaborators?.map((option) => {
        return { id: option.name, text: option.name };
      });
      setItems(newItems);
    } else if (options && options.enumOptions) {
      let newItems = options.enumOptions.map((option) => {
        return { id: option.value, text: option.value };
      });
      setItems(newItems);
    }
  }, [options]);

  useEffect(() => {
    if (value && typeof value !== 'object') {
      setSelectedItem(value);
    }
  }, [value])

  const handleChange = (val) => {
    console.log('Lookahead useEffect >> onChange')
    onChange(val);
  }

  return <PrimeField
    schema={{
      id: id + "-field",
      type: "dropdown",
      disabled: carbonProps?.disabled,
      placeholder: carbonProps?.placeholder || "",
      items: items ? items.map(text => text) : [],
    }}
    value={selectedItem}
    onChange={handleChange}
  />
}

export default InputWrapper(RJLookahead)