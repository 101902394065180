import { useMemo } from 'react';
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official';
import HC_more from 'highcharts/highcharts-more';
import HighchartsCustomEvents from 'highcharts-custom-events'
import * as AnnotationsModule from 'highcharts/modules/annotations';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import MilestoneDateBadge from 'components/badges/MilestoneDateBadge/MilestoneDateBadge';

// api
import { useUserSettings } from 'api/hooks';

// styles
import './ActivitiesTimeline.scss'

dayjs.extend(utc)
HC_more(Highcharts);
AnnotationsModule(Highcharts)
HighchartsCustomEvents(Highcharts)

const ActivitiesTimeline = (props) => {
  const {
    milestones = [],
    timeline = []
  } = props;

  const { data: userSettings } = useUserSettings()

  const renderColumnRangeLabel = (data) => {
    return `
      <div class="columnrange-label">
        <div class="title">${data?.key?.study_id}</div>
        <div>|</div>
        <div class="subtitle">${data?.key?.project_title}</div>
      </div>
    `
  }

  const dataLabelProps = (charCount) => {
    return {
      enabled: true,
      formatter: function (e) {
        if (e.align === "right") {
          return renderColumnRangeLabel(this)
        }
      },
      y: -18,
      x: charCount * 5 + 30,
    }
  }

  const additionalDataProps = (charCount) => {
    return {
      dataLabels: {
        enabled: true,
        formatter: function (e) {
          if (e.align === "right") {
            return renderColumnRangeLabel(this)
          }
        },
        y: -18,
        x: charCount * 5 + 30,
      },
      events: {
        click: function () {
          const { study_db_id, project_id } = this.category
          window.open(`/study/${study_db_id}/activity/${project_id}`, '_blank')
          return true
        }
      }
    }
  }

  const statusLabelProp = (charCount) => {
    return {
      enabled: true,
      formatter: function (e) {
        if (e.align === "right") {
          return renderColumnRangeLabel(this)
        }
      },
      y: -15,
      x: charCount * 4 + 25,
    }
  }

  const timelineData = useMemo(() => {
    const result = {
      minDate: '4000-01-01',
      maxDate: '1000-01-01',
      onTime: [],
      atRisk: [],
      late: [],
      notStarted: [],
      activityDueDates: [],
      complianceDueDates: [],
      categories: []
    }

    if (timeline?.length > 0) {
      result.categories.push({
        project_title: "Study Milestones",
        status: "",
        project_id: "",
        study_db_id: "",
        study_id: "",
      })

      timeline.forEach((activity, i) => {
        let { minDate, maxDate } = result;
        const index = i + 1

        // gets min max date range
        if (dayjs(activity.start_date).isBefore(minDate)) result.minDate = activity.start_date
        if (dayjs(activity.end_date).isAfter(maxDate)) result.maxDate = activity.end_date

        // categories - x-axis
        result.categories.push(activity)

        let charCount = activity.project_title.length + activity.study_id.length + 1
        // let charCount = activity.status.length

        // get activity data
        switch (activity.status) {
          case "Not at Risk":
            result.onTime.push({
              x: index,
              low: dayjs(activity.start_date).valueOf(),
              high: dayjs(activity.end_date).valueOf(),
              // color: "#27AB39",
              color: "#E9F7EB",
              borderColor: "#27AB39",
              // dataLabels: statusLabelProp(charCount)
              ...additionalDataProps(charCount),

            })
            break;
          case "At Risk":
            result.atRisk.push({
              x: index,
              low: dayjs(activity.start_date).valueOf(),
              high: dayjs(activity.end_date).valueOf(),
              // color: "#E7AB12",
              color: "#FDF7E7",
              borderColor: "#E7AB12",
              // dataLabels: statusLabelProp(charCount)
              ...additionalDataProps(charCount),
            })
            break;
          case "Late":
            result.late.push({
              x: index,
              low: dayjs(activity.start_date).valueOf(),
              high: dayjs(activity.end_date).valueOf(),
              // color: "#D6542D",
              color: "#FBEEEA",
              borderColor: "#D6542D",
              // dataLabels: statusLabelProp(charCount)
              ...additionalDataProps(charCount),
            })
            break;
          case "Not Yet Started":
            result.notStarted.push({
              x: index,
              low: dayjs(activity.start_date).valueOf(),
              high: dayjs(activity.end_date).valueOf(),
              color: "#EEF3F6",
              // color: "#FBEEEA",
              borderColor: "#8A8E93",
              // dataLabels: statusLabelProp(charCount)
              ...additionalDataProps(charCount),
            })
            break;
          default:
        }

        // get milestone data
        activity.milestones.forEach(milestone => {
          if (milestone.date) {
            switch (milestone.title) {
              case "Compliance Due Date":
                result.complianceDueDates.push({
                  x: index,
                  y: dayjs(milestone.date).valueOf(),
                  date: dayjs(milestone.date).format(userSettings?.dateFormat),
                  color: "#E50D0D",
                })
                break;

              case "Activity Due Date":
                result.activityDueDates.push({
                  x: index,
                  y: dayjs(milestone.date).valueOf(),
                  date: dayjs(milestone.date).format(userSettings?.dateFormat),
                  color: "#47C8DA"
                })
                break;
              default:
            }
          }
        })
      })

      return result
    }
    return result
  }, [timeline])

  const milestoneData = useMemo(() => {
    const result = []
    if (milestones.length > 0) {
      milestones.forEach(milestone => {
        let date = milestone.values.actual || milestone.values.forecast || milestone.values.baseline

        if (date) {
          result.push({
            x: 0,
            y: dayjs(date).valueOf(),
            date: dayjs(date).format(userSettings?.dateFormat),
            color: "#E50D0D",
            custom: {
              ...milestone
            }
          })
        }
      })
    }
    return result
  }, [milestones])

  if (!userSettings?.date_format) return null

  const renderActivityTooltip = (data) => {
    const {
      project_title,
      start_date,
      end_date,
      status
    } = data.key

    return `
      <div class="activity-timeline-tooltip">
        <div>${project_title} (${status})</div>
        <div>Start date: ${dayjs(start_date).format(userSettings?.date_format)}</div>
        <div>End date: ${dayjs(end_date).format(userSettings?.date_format)}</div>
      </div>
    `
  }

  const renderMilestoneTooltip = (data) => {
    const {
      name,
    } = data.series

    const {
      date
    } = data.point

    return `
      <div class="milestone-tooltip">
        <div>${data.key.project_title}</div>
        <div>${name}</div>
        <div>${dayjs(date).format(userSettings?.date_format)}</div>
      </div>
    `
  }

  const renderStudyMilestoneTooltip = (data) => {
    const {
      name,
    } = data.series

    const {
      date,
      custom
    } = data.point

    let dateType = "Actual"
    if (custom.values.actual) {
      dateType = "Actual"
    } else if (custom.values.forecast) {
      dateType = "Forecast"
    } else {
      dateType = "Baseline"
    }

    return `
      <div class="milestone-tooltip">
        <div>${name}: ${custom.label}</div>
        <div>
          <div>${dateType}: ${dayjs(date).format(userSettings?.date_format)} </div>
        </div>
      </div>
    `
  }

  const axisLabel = (data) => {
    return `
      <div class="x-axis-label">
        <p class="title">${data?.value?.name}</p>
        <p class="subtitle">${data?.value?.study_id}</p>
      </div>
    `
  }

  const options = {
    chart: {
      type: 'columnrange',
      inverted: true,
      backgroundColor: "transparent",
      zoomType: 'y',
      height: (timelineData.categories.length * 90)
    },
    title: {
      text: ""
    },
    xAxis: {
      min: 0,
      max: timelineData.categories.length - 1,
      className: "x-axis-label-container",
      categories: timelineData.categories,
      labels: {
        enabled: false,
        useHTML: true,
        formatter: function () {
          return axisLabel(this)
        },
        events: {
          click: function () {
            if (this.pos !== 0) {
              const category = this.axis.categories[this.pos]
              window.open(`/study/${category.study_db_id}/activity/${category.project_id}`, '_blank')
            }
            return true
          }
        }
      },
    },
    yAxis: {
      title: { text: '' },
      type: 'datetime',
      min: dayjs(timelineData.minDate).valueOf(),
      max: dayjs(timelineData.maxDate).valueOf(),
      tickInterval: 2629743833.3,
      dateTimeLabelFormats: {
        second: "%H:%M:%S",
        minute: "%H:%M:%S",
        hour: "%H:%M:%S",
        day: "%H:%M:%S",
        week: "%H:%M:%S",
        month: "%H:%M:%S",
        year: "%H:%M:%S"
      },
      labels: {
        formatter: function () {
          return Highcharts.dateFormat('%b %Y', this.value);
        }
      }
    },
    scrollbar: {
      enabled: false
    },
    legend: {
      enabled: true
    },
    credits: { enabled: false },
    exporting: { enabled: false },
    plotOptions: {
      scatter: {
        marker: {
          // lineWidth: 1,
          radius: 6,
        }
      },
      columnrange: {
        grouping: false,
        borderRadius: 4,
        borderWidth: 2,
      },
      series: {
        cursor: 'pointer',
        pointWidth: 17,
        pointPadding: 0,
        groupPadding: 0,
      },
    },
    legend: {
      enabled: true,
      backgroundColor: "#eeeeee",
      itemDistance: 8,
      itemStyle: {
        cursor: "pointer",
        fontSize: "12px"
      },
      itemHoverStyle: {
        color: "#333333"
      },
      verticalAlign: "top",
      backgroundColor: "transparent"
    },
    colors: ["#8AB25B", "#EDB81B", "#D7542C", "#60656C", "#47C8DA", "#E50D0D", "#E50D0D", "#9566AB"],
    tooltip: {
      enabled: true,
      useHTML: true,
      followPointer: true,
      hideDelay: 100,
      className: "activity-timeline-tooltip-container",
      backgroundColor: "#1A3140",
      borderRadius: 5,
      borderColor: "#1A3140",
      positioner: function (labelWidth, labelHeight, point) {
        var tooltipX, tooltipY;
        tooltipX = point.plotX;
        tooltipY = point.plotY - 3;
        return {
          x: tooltipX,
          y: tooltipY
        };
      },
      formatter: function () {
        switch (this.series.name) {
          case 'Not at Risk': return renderActivityTooltip(this)
          case 'At Risk': return renderActivityTooltip(this)
          case 'Late': return renderActivityTooltip(this)
          case 'Not Yet Started': return renderActivityTooltip(this)
          case "Activity Due Date": return renderMilestoneTooltip(this)
          case "Compliance Due Date": return renderMilestoneTooltip(this)
          case "Milestone": return renderStudyMilestoneTooltip(this)
          default:
            return ''
        }
      }
    },
    series: [
      {
        name: "Not at Risk",
        type: "columnrange",
        data: timelineData.onTime,
      },
      {
        name: "At Risk",
        type: "columnrange",
        data: timelineData.atRisk
      },
      {
        name: "Late",
        type: "columnrange",
        data: timelineData.late
      },
      {
        name: "Not Yet Started",
        type: "columnrange",
        data: timelineData.notStarted
      },
      {
        name: 'Activity Due Date',
        type: 'scatter',
        data: timelineData.activityDueDates,
        marker: {
          symbol: 'triangle-down',
          radius: 6,
        }
      },
      {
        name: 'Compliance Due Date',
        type: 'scatter',
        data: timelineData.complianceDueDates,
        marker: {
          symbol: 'diamond',
        }
      },
      {
        name: 'Milestone',
        type: 'scatter',
        data: milestoneData,
        marker: {
          symbol: 'circle',
          radius: 4,
          fillColor: '#9566AB'
        }
      },
    ]
  }

  return (
    <div className="activities-timeline-container">
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    </div>
  );
};

export default ActivitiesTimeline;