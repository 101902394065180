import { useState, useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow'
import { useStateWithCallbackLazy } from 'use-state-with-callback';

// components
import RJCrossReferenceTable from './RJCrossReferenceTable';

// context
import useProtocolTableArmsStore from '../../../hooks/stores/useProtocolTableArmsStore';
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';
import useAuthoringVersionStore from '../../../hooks/stores/useAuthoringVersionStore';
import useAuthoringStore from '../../../hooks/stores/useAuthoringStore';

const CrossReferenceWrapper = (props) => {
  const {
    id,
    onChange,
    formContext
  } = props

  const {
    formDataRef,
  } = formContext

  const formWidthClassName = useAuthoringStore(state => state.formWidthClassName)

  const {
    readOnly,
    formStatus,
    showVersionCompare
  } = useAuthoringViewStore(
    useShallow(state => ({ 
      readOnly: state.readOnly,
      formStatus: state.formStatus,
      showVersionCompare: state.showVersionCompare,
    }))
  )

  const {
    versionData,
    selectedVersion
  } = useAuthoringVersionStore(
    useShallow(state => ({
      versionData: state.versionData,
      selectedVersion: state.selectedVersion,
    }))
  )

  const {
    arms,
    interventions,
  } = useProtocolTableArmsStore()

  const [tableData, setTableData] = useStateWithCallbackLazy(null)
  const [tableMounting, setTableMounting] = useStateWithCallbackLazy(true)

  useEffect(() => {
    if (formStatus === "ready" && tableData && readOnly === false && tableMounting === false) {
      console.log("Cross Ref Change")
      onChange(tableData)
    }
  }, [tableData])

  useEffect(() => {
    if (formDataRef?.protocol?.study_arms?.crossref && formDataRef?.protocol?.study_arms?.crossref.arms && formDataRef?.protocol?.study_arms?.crossref?.interventions) {
      setTableData(formDataRef.protocol.study_arms.crossref)
    } else {
      setTableData({
        arms: [],
        interventions: []
      })
    }

    return () => {
      setTableMounting(true)
    }
  }, [])

  useEffect(() => {
    if (tableData !== null) setTableMounting(false)
  }, [tableMounting, tableData])

  if (tableMounting) return <></>

  return (
    <div className={`results-table form-table-print ${formWidthClassName}`}>
      <RJCrossReferenceTable
        {...props}
        arms={arms}
        studyType={formDataRef?.protocol?.study_type}
        interventions={interventions}
        tableData={tableData}
        setTableData={setTableData} />
      {versionData && selectedVersion && showVersionCompare && (
        <div className="rjs-table-comparison-wrapper">
          <RJCrossReferenceTable
            {...props}
            arms={versionData?.study_data.protocol?.study_arms?.arms || []}
            studyType={versionData?.study_data?.protocol?.study_type}
            interventions={versionData?.study_data.protocol?.study_arms?.interventions || []}
            tableData={versionData?.study_data?.protocol?.study_arms?.crossref}
            setTableData={() => { }} />
        </div>
      )}
    </div>
  );
};

export default CrossReferenceWrapper;