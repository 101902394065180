import React, { useState, useEffect, useContext, Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';
// context
import { AEContext } from './AdversePreview';

const CTGGroupTotals = ({ type, bgColor }) => {
  const { 
    data,
    view,
    schema,
  } = useContext(AEContext);
  const [values, setValues] = useState([])
  let fieldSchema = schema.properties[view][type].properties;

  useEffect(() => {
    let values = []
    Object.keys(fieldSchema).forEach((key, i) => {

      data?.groups.map((group, j) => {
        if(values[j]) {
          values[j].push(group[key])
        } else {
          values[j] = [ group[key] ]
        }
      })
    })
    let results = []
    values.forEach(arr => {
      let percentage = 0;
      if(arr[0] && arr[1]) {
        percentage = (Number(arr[0]) / Number(arr[1])) * 100;
      }
      results.push(`${arr.join(" / ")} (${percentage.toFixed(2)}%)`)
    })
    setValues(results);
  }, [data])

  return (
    <Fragment>
      <tr style={{ backgroundColor: bgColor, fontWeight: "bold"}}>
        <td colSpan={2} style={{ width: 250 }}/>
        {data?.groups &&
          data.groups.map(arm => (
            <td key={uuidv4()}>
              {arm.title}
            </td>
          ))
        }
      </tr>
      <tr>
        <td 
          colSpan={2} 
          style={{ backgroundColor: bgColor, width: 250, fontWeight: "bold"  }}>
          Total
        </td>
        {values.map(item => {
          return <td key={uuidv4()}>{item}</td>
        })}
      </tr>
    </Fragment>
  );
};

export default CTGGroupTotals;