import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import PropTypes from 'prop-types'
import { getCookie } from 'api/apiUtils';
import { isExpired } from 'react-jwt';

// context
import useAppState from 'context/hooks/useAppState'

const AxiosPublic = axios.create({});

const useGetApi = (apiEnpoint, queryKey, options = {}, params = {}, isPublic) => {
  const accessToken = getCookie("xopri-jwt-actoken")
  const {
    appState,
    appStateRef,
    isMounted
  } = useAppState()

  const getApi = async () => {
    if (isPublic) {
      let res = await AxiosPublic.get(apiEnpoint)
      if (res?.data) return res.data
      return res
    }

    const res = await axios.get(apiEnpoint, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
      params
    })
    if (res?.data) return res.data;
  }

  // all useQuery return values
  const { ...returnValues } = useQuery(queryKey, getApi, {
    enabled: (appStateRef?.current === "authenticated" && accessToken && isMounted &&
      queryKey.every(key => key !== null && key !== undefined && key !== 0) &&
      apiEnpoint) ? true : false,
    ...options,
  })

  return {
    ...returnValues,
  }
}

useGetApi.propTypes = {
  apiEnpoint: PropTypes.string.isRequired,
  queryKey: PropTypes.string.isRequired,
  options: PropTypes.object,
}

export default useGetApi