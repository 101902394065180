import useGetApi from "../useGetApi"
import useMutateApi from "../useMutateApi"

// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

// utils
import getUrl from "api/apiMap"

export const useGetDocumentTypes = () => {
  return useGetApi(getUrl(apiEndpoints.documentTypes), queryKeys.documentTypes)
}

// mutate({ body })
export const usePostDocumentTypes = () => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(apiEndpoints.documentTypes),
    invalidateQueries: [queryKeys.documentTypes],
    successMsg: "Successfully created document type"
  })
}

// mutate({ id, body })
export const usePutDocumentTypes = () => {
  return useMutateApi({
    method: "put",
    apiEndpoint: getUrl(apiEndpoints.documentTypes),
    invalidateQueries: [queryKeys.documentTypes],
    successMsg: "Successfully updated document type"
  })
}

// mutate({ id })
export const useDeleteDocumentTypes = () => {
  return useMutateApi({
    method: "delete",
    apiEndpoint: getUrl(apiEndpoints.documentTypes),
    invalidateQueries: [queryKeys.documentTypes],
    successMsg: "Successfully deleted document type"
  })
}