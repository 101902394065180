import React from 'react'
import PropTypes from 'prop-types'
import Tabs from 'components/Tabs/Tabs'
import FavoritesTab from './FavoritesTab/FavoritesTab'

// styles
import './FavoritesPanel.scss'

const FavoritesPanel = ({ setIsOpen, isOpen }) => {
  const [activeTab, setActiveTab] = React.useState('studies')

  return <div className="favorites-panel">
    <div className="fave-tabs-wrap">
      <Tabs
        tabs={[
          {
            id: "studies",
            title: "Studies",
            action: () => setActiveTab('studies'),
          },
          {
            id: "requests",
            title: "Requests",
            action: () => setActiveTab('requests'),
          },
        ]}
        active={activeTab}
      />
    </div>
    <FavoritesTab
      type={activeTab}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    />
  </div>
}

FavoritesPanel.propTypes = {
  setIsOpen: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default FavoritesPanel