import useGetApi from "../useGetApi"
import useMutateApi from "../useMutateApi"

// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

// utils
import getUrl from "api/apiMap"

const useGetActivityTeam = (studyId, activityId) => {
  return useGetApi(`${getUrl(apiEndpoints.studies)}${studyId}/activities/${activityId}/team/`, queryKeys.activities.activityTeam(studyId, activityId))
}

// function used to post (create) and put (update)
// mutate: { body }
const usePostActivityTeam = (studyId, activityId) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: `${getUrl(apiEndpoints.studies)}${studyId}/activities/${activityId}/team/`,
    invalidateQueries: [queryKeys.activities.all],
    successMsg: "Sucessfully updated team",
  })
}

const useRefreshActivityTeam = (studyId, activityId) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: `${getUrl(apiEndpoints.studies)}${studyId}/activities/${activityId}/refresh-team/`,
    invalidateQueries: [queryKeys.activities.all],
    successMsg: "Team has been successfully refreshed",
  })
}

export {
  useGetActivityTeam,
  usePostActivityTeam,
  useRefreshActivityTeam,
}