import React, { useState, useEffect, useRef } from 'react';
import { debounce } from 'lodash'
import { useShallow } from 'zustand/react/shallow'

// components
import { Collapse } from 'antd';
import { formNav } from 'constants/authoring';
import './FormNavigator.scss'

// hooks
import useAuthoringViewStore from '../../hooks/stores/useAuthoringViewStore';

const { Panel } = Collapse;

const FormNavigator = ({
  sectDropdownOptions,
  onChangeSection,
}) => {
  const {
    formView,
    formSection,
    showPagePreview,
    isReviewerView
  } = useAuthoringViewStore(
    useShallow(state => ({ 
      formView: state.formView,
      formSection: state.formSection,
      showPagePreview: state.showPagePreview,
      isReviewerView: state.isReviewerView,
    }))
  )
  const [nav, setNav] = useState([]);
  const [activeNavItem, setActiveNavItem] = useState(null);
  const activeNavItemRef = useRef(activeNavItem)
  const [activeKey, setActiveKey] = useState(sectDropdownOptions?.[0] || "Protocol")

  let container = isReviewerView ? document.getElementById("authoring-component-wrapper") : document.getElementsByClassName("right-section")[0]
  const showPagePreviewRef = useRef();

  useEffect(() => {
    if (sectDropdownOptions?.[0]) setActiveKey(sectDropdownOptions[0])
  }, [sectDropdownOptions?.[0]])

  useEffect(() => {
    container?.addEventListener("scroll", isInViewportDebounce)

    return () => {
      container?.removeEventListener("scroll", isInViewportDebounce)
    }
  }, [nav, container])

  useEffect(() => {
    showPagePreviewRef.current = showPagePreview
  }, [showPagePreview])

  useEffect(() => {
    if (showPagePreview !== true) {
      const highlightEupasFields = async () => {
        for (let i = 0; i < 10; i++) {
          let el = document.getElementById("root_protocol_eupas_study_type-field-container")
          if (el) break;
          await new Promise(r => setTimeout(r, 100));
        }
        isInViewport()
      }

      const highlightCtgFields = async () => {
        for (let i = 0; i < 10; i++) {
          let el = document.getElementById("root_protocol_protocol_id-field-container")
          if (el) break;
          await new Promise(r => setTimeout(r, 100));
        }
        isInViewport()
      }

      const highlightStudyDetailsFields = async () => {
        for (let i = 0; i < 10; i++) {
          let el = document.getElementById("root_protocol_protocol_id-field-container")
          if (el) break;
          await new Promise(r => setTimeout(r, 100));
        }
        isInViewport()
      }

      if (formSection.id === "protocol" && (formView.id === "global" || formView.id === "eupas")) {
        highlightEupasFields()
      } else if (formSection.id === "protocol" && (formView.id === "ctg")) {
        highlightCtgFields()
      } else if (formSection.id === "secondaryAttributes") {
        highlightStudyDetailsFields()
      } else {
        isInViewport()
      }
    }
  }, [formView, formSection])

  const highlightSections = {
    studyDetails: [
      "root_protocol-object-title-container",
      "root_protocol_study_eligibilty-container",
      "root_protocol_protocol_id-field-container",
      "root_protocol_study_details_detailed_description-field-container",
      "root_protocol_vendor-field-container",
      "root_protocol_vendor_contact-field-container",
      "root_protocol_acquired_from-field-container",
      "root_protocol_transferred_to-field-container",
      "root_protocol_unapproved_new_formulation_marketed_compound-field-container",
      "root_protocol_proprietary_name-field-container",
      "root_protocol_established_name-field-container",
      "root_protocol_product_manufactured-field-container",
      "root_protocol_compound_code-field-container",
      "root_protocol_generic_name-field-container",
      "root_protocol_brand_name-field-container",
      "root_protocol_primary_cro-field-container",
      "root_protocol_availability_expanded_access-field-container",
      "root_protocol_us_fda_regulated_drug_product-field-container",
      "root_protocol_us_fda_regulated_device_product-field-container",
      "root_protocol_study_oversight-field-container",
      "root_protocol_secondary_ids-field-container"
    ],
    general: [
      "root_protocol_general-container",
      "root_protocol_study_scope-field-container",
      "root_protocol_contact_information-field-container",
      "root_protocol_study_type-field-container",
      "root_protocol_status-field-container",
      "root_protocol_study_timeline-field-container",
    ],
    studyIdentFields: [
      "root_protocol_study_identification-container",
      "root_protocol_protocol_id-field-container",
      "root_protocol_study_type-field-container",
      "root_protocol-object-title-container",
      "root_protocol_eupas_study_type-field-container",
      "root_protocol_eudract_number-field-container",
      "root_protocol_other_study_type-field-container"
    ],
    timelineFields: [
      "root_protocol_study_timeline-container",
      "root_protocol_milestone_dates-field-container",
      "root_protocol_secondary_attributes-container",
      "root_protocol_secondary_attributes-field-container"
    ]
  }

  const isInViewport = () => {
    let mostVisible = {
      nav: nav[0],
    }

    let visibleNav = null;

    for (let i = 0; i < nav.length; i++) {
      let curr = nav[i];
      let el = document.getElementById(curr.jpath);

      let visible = checkInView(container, el, true)
      if (visible) {
        visibleNav = curr;
        break
      }
    }

    mostVisible = {
      nav: visibleNav
    }

    if (mostVisible.nav) {
      if (activeNavItemRef.current) {
        if (activeNavItemRef.current.jpath === "root_protocol" || activeNavItemRef.current.jpath === "root_protocol_study_identification-container") {
          highlightSections.studyIdentFields.forEach(jpath => unhighlightSection(jpath))
        } else if (activeNavItemRef.current.jpath === "root_protocol_study_timeline-container") {
          highlightSections.timelineFields.forEach(jpath => unhighlightSection(jpath))
        } else if (formSection.id === "secondaryAttributes") {
          if (activeNavItemRef.current.jpath === "root_protocol_study_eligibilty-container") {
            highlightSections.studyDetails.forEach(jpath => unhighlightSection(jpath))
          } else if (activeNavItemRef.current.jpath === "root_protocol_general-container") {
            highlightSections.general.forEach(jpath => unhighlightSection(jpath))
          } else {
            unhighlightSection(activeNavItemRef.current.jpath)
          }
        } else if (formView.id === "ctis" && activeNavItemRef.current.jpath === "root_protocol_secondary_attributes-container") {
          unhighlightSection(activeNavItemRef.current.jpath)
          unhighlightSection("root_protocol_milestone_dates-container")
          unhighlightSection("root_protocol_study_timeline-container")
        } else {
          unhighlightSection(activeNavItemRef.current.jpath)
        }
      }

      if (mostVisible.nav.jpath === "root_protocol" || mostVisible.nav.jpath === "root_protocol_study_identification-container") {
        highlightSections.studyIdentFields.forEach(jpath => highlightSection(jpath))
      } else if (mostVisible.nav.jpath === "root_protocol_study_timeline-container") {
        highlightSection(mostVisible.nav.jpath)
        highlightSections.timelineFields.forEach(jpath => highlightSection(jpath))
      } else if (formSection.id === "secondaryAttributes") {
        if (mostVisible.nav.jpath === "root_protocol_study_eligibilty-container") {
          highlightSections.studyDetails.forEach(jpath => highlightSection(jpath))
        } else if (mostVisible.nav.jpath === "root_protocol_general-container") {
          highlightSections.general.forEach(jpath => highlightSection(jpath))
        } else {
          highlightSection(mostVisible.nav.jpath)
        }

      } else if (formView.id === "ctis" && mostVisible.nav.jpath === "root_protocol_secondary_attributes-container") {
        highlightSection(mostVisible.nav.jpath)
        highlightSection("root_protocol_milestone_dates-container")
        highlightSection("root_protocol_study_timeline-container")
      } else {
        highlightSection(mostVisible.nav.jpath)
      }

      setActiveNavItem(mostVisible.nav)
      activeNavItemRef.current = mostVisible.nav
    } else if (formSection.id === "sponsorTrialWebsite" || formSection.id === "delayedResultsCertification") {
      highlightSection("root_sponsor_trial_website-container")
      highlightSection("root_delayed_results_certification-container")
      highlightSection("documents-container")
    }
  }

  const highlightSection = (jpath) => {
    if (showPagePreviewRef.current !== true) {
      let el = document.getElementById(jpath);
      if (el) el.style.backgroundColor = "#EEF3F4"
    }
  }

  const unhighlightSection = (jpath) => {
    if (showPagePreviewRef.current !== true) {
      let el = document.getElementById(jpath);
      if (el) el.style.backgroundColor = "inherit"
    }
  }

  let isInViewportDebounce = debounce(isInViewport, 100);

  useEffect(() => {
    let headerEls = document.getElementsByClassName("form-navigator-header")

    if (headerEls.length > 0) {
      for (let i = 0; i < headerEls.length; i++) {
        let headerEl = headerEls[i];
        let parent = headerEl.querySelector('.ant-collapse-header');
        let arrowEl = headerEl.querySelector('.ant-collapse-expand-icon');
        let labelEl = headerEl.querySelector('.ant-collapse-header-text');
        if (parent && arrowEl) {
          parent.removeChild(arrowEl)
          arrowEl.style.marginLeft = "6px"

          if (labelEl.innerHTML !== "Delayed Results Certification" && labelEl.innerHTML !== "Sponsor Trial Website") {
            parent.appendChild(arrowEl)
          }
        }
      }
    }
  }, [nav])

  useEffect(() => {
    let nav = [];

    if (formNav?.[formSection?.id]?.[formView?.id]) {
      nav = formNav[formSection.id][formView.id]
    }

    setNav(nav)
    setActiveNavItem(nav?.[0])
    activeNavItemRef.current = nav?.[0]
  }, [formView, formSection])

  const scrollToField = (item) => {
    const viewScrollToOffset = isReviewerView ? 0 : 30

    let jpath = item.jpath;
    if (item.jpath === "root_protocol_study_identification-container") jpath = "root_protocol"

    const element = document.getElementById(jpath);

    if (element && container) {
      const containerRect = container.getBoundingClientRect();
      const elementRect = element.getBoundingClientRect();

      const offsetTop = elementRect.top - containerRect.top;
      const offsetLeft = elementRect.left - containerRect.left;

      setActiveNavItem(item)
      activeNavItemRef.current = item

      container.scrollTo({
        top: container.scrollTop + offsetTop - viewScrollToOffset,
        left: offsetLeft,
        behavior: 'smooth'
      });
      isInViewport()
    }
  }

  Math.easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  };

  const checkInView = (container, element, partial) => {
    const containerOffset = isReviewerView ? 300 : 100
    if (element) {
      //Get container properties
      let cTop = container.scrollTop;
      let cBottom = cTop + container.clientHeight;

      //Get element properties
      let eTop = element.offsetTop - containerOffset;
      let eBottom = eTop + element.clientHeight;

      //Check if in view    
      let isTotal = (eTop >= cTop && eBottom <= cBottom);

      let isPartial = partial && (
        (eTop < cTop && eBottom > cTop) ||
        (eBottom > cBottom && eTop < cBottom)
      );

      //Return outcome
      return (isTotal || isPartial)
    }
  }

  const getSectClassname = (sect) => {
    return sect.toLowerCase().replace(/\s/g, '-')
  }

  return (
    <div
      id="form-navigator-container"
      className="form-navigator"
    >
      <Collapse
        accordion
        activeKey={activeKey}
        bordered={false}
        onChange={(val) => {
          setActiveKey(val[0])
          onChangeSection(val[0])
        }}
        className="form-nav-collapse"
      >
        {sectDropdownOptions.map((section) => (
          <Panel
            header={section}
            key={section}
            className={`form-navigator-panel ${getSectClassname(section)}`}
          >
            <div className="flex flex-col">
              {nav.map((item) => (
                <div
                  className={`nav-item ${activeNavItem?.jpath === item.jpath ? "nav-item-active" : ""}`}
                  key={item.title}
                  onClick={() => scrollToField(item)}>
                  {item.title}
                </div>
              ))}
            </div>
          </Panel>
        ))}
      </Collapse>
    </div>
  )
}

export default FormNavigator