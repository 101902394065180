import React, { useState, useEffect } from 'react'
import { Button } from 'components-design-system'
import PrimeField from 'components/PrimeField/PrimeField'
import './preset-modal-content.scss'

const UpdatePreset = ({
  setOpenModal,
  data,
  presets,
  updatePreset
}) => {
  const [presetName, setPresetName] = useState(data.name);
  const [isInvalid, setInvalid] = useState(false);

  useEffect(() => {
    if (presetName) {
      let foundIndex = presets?.findIndex(preset => preset.name.toLowerCase() === presetName.toLowerCase());
      if (foundIndex !== -1) {
        setInvalid(true);
      } else {
        setInvalid(false);
      }
    }
  }, [presetName, presets]);

  return <div className="preset-modal-content">
    <div className="modal-content">
      <PrimeField
        schema={{
          label: "Preset name",
          orientation: "vertical",
          type: "text",
          placeholder: "Enter preset name"
        }}
        showError={isInvalid}
        errorMessage={"A preset with that name already exists, please select a different name."}
        value={presetName}
        onChange={setPresetName}
      />
    </div>
    <div className="actions">
      <Button
        size="sm"
        variant="outlined"
        onClick={() => setOpenModal(false)}
      >
        Cancel
      </Button>
      <Button
        size="sm"
        disabled={isInvalid}
        variant="primary"
        onClick={() => updatePreset({ ...data, name: presetName })}>
        Save
      </Button>
    </div>
  </div>
}

export default UpdatePreset