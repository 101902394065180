// TODO: change name to authoringReviewApi.js b/c it contains hooks but is not a hook and .js because it does not render jsx code
import useMutateApi from "../useMutateApi"

// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

// apis
import useGetApi from "../useGetApi"
import getUrl from "api/apiMap"

// authoring studies
export const useGetAuthoringReviewMetadata = (studyId, params) => {
  return useGetApi(
    getUrl(`${apiEndpoints.authoring}${studyId}/authoring-review-metadata/`),
    queryKeys.authoringReview.metadata(Number(studyId), params?.["project-id"] ? Number(params["project-id"]) : null, params?.["actionitem-id"] ? Number(params["actionitem-id"]) : null),
    {},
    params
  )
}

export const usePostCompleteTask = ({
  studyId,
  projId,
  actionitem_pk
}) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(`${apiEndpoints.actionItems}`),
    invalidateQueries: [
      queryKeys.authoringReview.completeTask(studyId, projId, actionitem_pk),
      queryKeys.authoringReview.metadata(Number(studyId), Number(projId), Number(actionitem_pk))
    ],
    successMsg: 'Successfully completed task.'
  })
}

// { id: `/${taskId}/subactionitem/${subtaskId}/complete/` }
export const usePostCompleteSubtask = ({
  studyId,
  projId,
  actionitem_pk,
  subactionitem_pk
}) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(`${apiEndpoints.actionItems}`),
    invalidateQueries: [queryKeys.authoringReview.completeSubtask(studyId, projId, actionitem_pk, subactionitem_pk), queryKeys.authoringReview.metadata(Number(studyId), Number(projId), Number(actionitem_pk))],
    successMsg: 'Successfully completed subtask.'
  })
}