import React, {useEffect, useState, memo} from 'react';
import styled from 'styled-components';

const ValidationMessage = ({
  type = "note",
  value
}) => {
  const [iconType, setIconType] = useState("fas fa-info-circle");

  useEffect(() => {
    switch(type) {
      case "note": 
        setIconType("fas fa-info-circle");
        break;
      case "error": 
        setIconType("fas fa-minus-circle");
        break;
      case "warning": 
        setIconType("fas fa-exclamation-triangle");
        break;
      case "non-site": 
        setIconType("fas fa-times-circle");
        break;
      default: 
        setIconType("fas fa-info-circle");
        break;
    }
  }, [type]);

  return (
    <Container>
      <Icon className={iconType} type={type}/>
      {value}
    </Container>
  );
};

export default memo(ValidationMessage);

const getIconColor = (type) => {
  switch(type) {
    case "note": return "#054ADA";
    case "warning": return "#A06D09";
    default: return "#D7542C";
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 8px;
`

const Icon = styled.i`
  margin-right: 5px;
  margin-top: 5px;
  color: ${props => getIconColor(props.type)};
`