const ArrayFieldItem = ({
  element,
  classNames,
  arrayItemTitle,
  showIndex
}) => {

  return (
    <div className="rjs-array-item-container">
      <div className={`rjs-array-item-title ${classNames?.arrayItemTitle ? classNames.arrayItemTitle : ""}`}>
        {showIndex && (
          <span>
            {element.index + 1}.
          </span>
        )}
        {arrayItemTitle}
      </div>
      <div className={`rjs-item-container ${classNames?.actionContainer ? classNames.actionContainer : ""}`}>
        {element.children}
      </div>
    </div>
  )
};

export default ArrayFieldItem;