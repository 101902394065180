import React from 'react'
import { Popover } from 'antd'
import PropTypes from 'prop-types'
import './MenuPopover.scss'

const MenuPopover = ({
  items,
  trigger = "click",
  buttonLayout = "vertical",
  placement = "bottomRight",
  width,
  overlayClassName,
  ...popoverProps // title
}) => {
  const renderIcon = (iconType) => {
    if (iconType) {
      let iconClass = null
      if (iconType === 'export') iconClass = 'far fa-file-export';
      if (iconType === 'show') iconClass = 'fal fa-eye';
      if (iconType === 'hide') iconClass = 'far fa-eye-slash';
      if (iconType === 'plus') iconClass = 'fal fa-plus-circle';
      if (iconType === 'configure') iconClass = 'fal fa-cog';
      if (iconType === 'delete') iconClass = 'fal fa-trash-alt';
      if (iconClass) return <i className={iconClass} />;
    }
    return null
  }

  // popover classes
  const overlayClassNames = ['prime-popover']
  if (overlayClassName) overlayClassNames.push(overlayClassName)

  return <>
    <Popover
      trigger={trigger}
      placement={placement}
      overlayStyle={width && { width }}
      overlayClassName={overlayClassNames.join(' ')}
      {...popoverProps}
      content={<div className="menu-popover-contents">
        {items?.map((item) => <div
          key={item.text}
          className={`menu-popover-item${item.disabled ? ' disabled' : ''}${item.icon === 'delete' ? ' is-delete' : ''}`}
          onClick={item.action}
        >
          <div className="menu-popover-item-icon">{renderIcon(item.icon)}</div>
          <div className="menu-popover-item-text">{item.text}</div>
        </div>)}
      </div>}
    >
      <div className="menu-ellipsis">
        {buttonLayout === 'vertical'
          ? <i className="fal fa-ellipsis-v" />
          : <i className="fal fa-ellipsis-h" />
        }
      </div>
    </Popover>
  </>
}

MenuPopover.propTypes = {
  title: PropTypes.string,
  buttonLayout: PropTypes.oneOf(['vertical', 'horizontal']),
  items: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.oneOf(['export', 'show', 'hide', 'plus', 'configure', 'delete']),
    text: PropTypes.string,
    action: PropTypes.func,
  })),
  overlayClassName: PropTypes.string,
  width: PropTypes.number,
}

export default MenuPopover