import React from 'react';
import { InputNumber, Typography } from 'antd';
import { Button } from 'components-design-system';

// contants
import { THRESHOLD_KEY } from 'constants/grids';

// styles
import './ComplianceThreshold.scss';

const { Title } = Typography

const ComplianceThreshold = (props) => {
  const [threshold, setThreshold] = React.useState(30)

  React.useEffect(() => {
    let savedThreshold = Number(localStorage.getItem(THRESHOLD_KEY))
    if (!isNaN(savedThreshold)) setThreshold(savedThreshold)
  }, [])

  const onApply = () => {
    localStorage.setItem(THRESHOLD_KEY, threshold)
    props.api.sideBarComp.close()
    props.api.refreshServerSide()
  }

  return (
    <div className="compliance-threshold-panel-container">
      <Title level={5} className='title'>Compliance Threshold</Title>
      <InputNumber
        id='compliance-threshold-input'
        min={0}
        value={threshold}
        onChange={setThreshold} />
      <Button
        type="primary"
        className='apply-btn'
        onClick={onApply}>
        Apply
      </Button>
    </div>
  );
};

export default ComplianceThreshold;