/** DataSharing.Requests.ReviewApproval API endpoints */
import useGetApi from "../useGetApi"
import useMutateApi from "../useMutateApi"
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"
import getUrl from "api/apiMap"

const useGetRequestApprovalRecords = (request_pk) => {
  return useGetApi(getUrl(`${apiEndpoints.requests}${request_pk}/approval-records/`), queryKeys.requests.approval(request_pk))
}

const usePostApprovalRecords = (request_pk) => {
  return useMutateApi(({
    method: "post",
    apiEndpoint: getUrl(`${apiEndpoints.requests}${request_pk}/approval-records/`),
    invalidateQueries: [queryKeys.requests.approval(request_pk), queryKeys.requests.review(Number(request_pk))],
    successMsg: 'Successfully updated approval records'
  }))
}

// actionItemId is query param "task-id"
const useGetRequestReviewMetadata = (request_pk, actionItemId, options) => {
  return useGetApi(getUrl(`${apiEndpoints.requests}${request_pk}/request-review-metadata/?actionitem-id=${actionItemId}`), queryKeys.requests.review(Number(request_pk)), options)
}

export {
  useGetRequestApprovalRecords,
  usePostApprovalRecords,
  useGetRequestReviewMetadata,
}