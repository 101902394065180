/** Used by DocumentCard and FormCard */
import React, { useState } from 'react'
import { motion } from 'framer-motion'
import './ThumbnailCard.scss'

const ThumbnailCard = ({
  title,
  subTitle,
  icon,
  preview,
  detailSchema,
  isComplete = false,

  // callbacks
  onOpen, // click on header, including title
  onOpenPreview, // click on preview or icon
  // TODO: review if need to open links
  href,
  previewHref,
}) => {
  const [panelOpen, setPanelOpen] = useState(false)

  const shadowVariant = {
    open: { opacity: 1, zIndex: 2, },
    closed: { opacity: 0, zIndex: -1 },
  }

  const detailsVariant = {
    open: { opacity: 1, y: 0, zIndex: 1, display: "flex" },
    closed: { opacity: 0, display: "none", zIndex: -1 },
  }

  // format: { key, title, value }
  const getDetails = () => {
    if (detailSchema) {
      return detailSchema.map((item) => {
        if (item.value) {
          return <div className="detail-row" key={item.key}>
            <p className="title">{item.title}</p>
            <p className="value">{item.value}</p>
          </div>
        }
        return null
      })
    }
    return null
  }

  const handleOpen = (evt) => {
    evt.preventDefault()
    onOpen()
  }

  const handleOpenPreview = (evt) => {
    evt.preventDefault()
    onOpenPreview()
  }

  return <div className="thumbnail-card-container"
    onMouseEnter={() => setPanelOpen(true)}
    onMouseLeave={() => setPanelOpen(false)}
  >
    <motion.a
      animate={panelOpen ? "open" : "closed"}
      variants={shadowVariant}
      className="card-mask"
      transition={{ duration: 0.3 }}
      onClick={handleOpenPreview}
    // href={previewHref ? previewHref : null}
    />

    <div className="card-preview"
      onClick={handleOpenPreview}
    >
      <div className="preview-hotspot" />
      {preview}
    </div>
    <div
      className={`card-panel${isComplete ? ' is-complete' : ''}${panelOpen ? ' panel-open' : ''}`}
    >

      <div className="card-header">
        <a
          className="card-titles"
          onClick={handleOpen}
          href={href ? href : null}
        >
          <p>{title}</p>
          <p style={{ color: "#759BAD", fontWeight: "700" }}>{subTitle}</p>
        </a>
        <div className="card-actions">
          {isComplete ?
            <i className="fas fa-check-circle" /> :
            <a
              onClick={handleOpenPreview}
              href={previewHref ? previewHref : null}
            >
              <img
                className="card-icon"
                src={icon}
                alt={"card icon"}
              />
            </a>
          }
        </div>
      </div>
      <motion.div
        animate={panelOpen ? "open" : "closed"}
        variants={detailsVariant}
        transition={{ duration: 0.5 }}
        className="card-details">
        {getDetails()}
      </motion.div>
    </div>
  </div>
}

export default ThumbnailCard