const organNameEnums = [
  "Blood and lymphatic system disorders",
  "Cardiac disorders",
  "Congenital, familial and genetic disorders",
  "Ear and labyrinth disorders",
  "Endocrine disorders",
  "Eye disorders",
  "Gastrointestinal disorders",
  "General disorders",
  "Hepatobiliary disorders",
  "Immune system disorders",
  "Infections and infestations",
  "Injury, poisoning and procedural complications",
  "Investigations",
  "Metabolism and nutrition disorders",
  "Musculoskeletal and connective tissue disorders",
  "Neoplasms benign, malignant and unspecified (incl cysts and polyps)",
  "Nervous system disorders",
  "Pregnancy, puerperium and perinatal conditions",
  "Product Issues",
  "Psychiatric disorders",
  "Renal and urinary disorders",
  "Reproductive system and breast disorders",
  "Respiratory, thoracic and mediastinal disorders",
  "Skin and subcutaneous tissue disorders",
  "Social circumstances",
  "Surgical and medical procedures",
  "Vascular disorders"
]

export const adverseEventsDataSchema = {
  type: "object",
  properties: {
    adverse_events_table: {
      type: "string",
      properties: {
        ctg: {
          type: "object",
          properties: {
            time_frame: {
              type: "textarea",
              title: "Time Frame",
              subtitle: "Provide a description of the specific period of time over which adverse event data were collected (e.g., 1 year, 6 months)",
              required: true,
            },
            notes: {
              type: "textarea",
              subtitle: "If the definition of adverse event and/or serious adverse event, used to collect adverse event information, differs from the clinicaltrials.gov Definitions, describe how the definitions differ. Also, optionally provide additional relevant information about adverse event collection.",
              title: "Adverse Event Reporting Description",
            },
            source_vocabulary: {
              type: "text",
              title: "Source Vocabulary Name for Table Default",
              subtitle: 'Please enter the name and version of the source vocabulary, if any, for adverse event terms. Source Vocabulary will be applied to all adverse event terms entered in the "Serious" and "Other" adverse event tables, unless otherwise specified.(e.g., SNOMED CT, MedDRA 10.0)'
            },
            dictionary_version: {
              type: "text",
              title: "Source Vocabulary Version"
            },
            assessment_type: {
              type: "dropdown",
              title: "Collection Approach for Table Default",
              subtitle: 'Assessment type will be applied to all adverse event terms entered in the "Serious" and "Other" adverse event tables, unless otherwise specified. If systematic, provide explanation of the method in Adverse Event Reporting Description.',
              enum: ["Unselected", "Systematic Assessment", "Non-systematic Assessment"],
              value: "Systematic Assessment"
            },
            frequency_reporting_threshold: {
              title: "Frequency Threshold for Reporting Other Adverse Events",
              subtitle: "Enter a number between 0 (no threshold; all events reported) and 5 (only events occurring in greater than 5% of participants in any Arm/Group are reported).",
              type: "number",
              required: true
            },
          },
          groups: {
            properties: {
              title: {
                title: "Arm/Group Title",
                type: "textarea",
                required: true
              },
              description: {
                title: "Arm/Group Description",
                type: "textarea",
                required: true
              },
              num_deaths: {
                title: "Participants",
                type: "number",
                required: true
              },
              part_at_risk_all_cause_mort: {
                title: "Participants",
                type: "number",
                required: true,
              },
              num_subjects_frequent_events: {
                title: "Participants",
                type: "number",
                required: true
              },
              part_at_risk_frequent_events: {
                title: "Participants",
                type: "number",
                required: true,
              },
              num_subjects_serious_events: {
                title: "Participants",
                type: "number",
                required: true
              },
              part_at_risk_serious_events: {
                title: "Participants",
                type: "number",
                required: true,
              }
            },
            show_keys: ["title", "description"]
          },
          all_cause_mortality_group_total: {
            title: "All-Cause Mortality",
            properties: {
              num_deaths: {
                title: "Total Number Affected",
                type: "number",
                required: true
              },
              part_at_risk_all_cause_mort: {
                title: "Total Number at Risk",
                type: "number",
                required: true,
              }
            }
          },
          serious_adverse_event_group_total: {
            properties: {
              num_subjects_serious_events: {
                title: "Total Number Affected",
                type: "number",
                required: true
              },
              part_at_risk_serious_events: {
                title: "Total Number at Risk",
                type: "number",
                required: true,
              }
            }
          },
          non_serious_adverse_event_group_total: {
            properties: {
              num_subjects_frequent_events: {
                title: "Total Number Affected",
                type: "number",
                required: true
              },
              part_at_risk_frequent_events: {
                title: "Total Number at Risk",
                type: "number",
                required: true,
              }
            }
          },
          serious_adverse_event: {
            type: "object",
            properties: {
              term: {
                title: "Adverse Event Term",
                type: "text"
              },
              organ_system_name: {
                title: "Organ System",
                type: "dropdown",
                enum: organNameEnums
              },
              notes: {
                title: "Adverse Event Term Additional Description",
                type: "textarea"
              },
              source_vocabulary: {
                title: "Source Vocabulary Name",
                type: "text"
              },
              dictionary_version: {
                type: "text",
                title: "Source Vocabulary Version"
              },
              assessment_type: {
                title: "Collection Approach",
                type: "dropdown",
                enum: ["Unselected", "Systematic Assessment", "Non-systematic Assessment",]
              },
            }
          },
          non_serious_adverse_event: {
            type: "object",
            properties: {
              term: {
                title: "Adverse Event Term",
                type: "text"
              },
              organ_system_name: {
                title: "Organ System",
                type: "dropdown",
                enum: ["Psyciatric disorders"]
              },
              notes: {
                title: "Adverse Event Term Additional Description",
                type: "textarea"
              },
              source_vocabulary: {
                title: "Source Vocabulary Name",
                type: "text"
              },
              dictionary_version: {
                type: "text",
                title: "Source Vocabulary Version"
              },
              assessment_type: {
                title: "Collection Approach",
                type: "dropdown",
                enum: ["Unselected", "Systematic Assessment", "Non-systematic Assessment"]
              },
            }
          },
          serious_adverse_event_stat: {
            type: "object",
            properties: {
              num_subjects_affected: {
                title: "Affected",
                type: "number"
              },
              num_subjects: {
                title: "At Risk",
                type: "number"
              },
              num_events: {
                title: "Number of Events",
                type: "number"
              },
            }
          },
          non_serious_adverse_event_stat: {
            type: "object",
            properties: {
              num_subjects_affected: {
                title: "Affected",
                type: "number"
              },
              num_subjects: {
                title: "At Risk",
                type: "number"
              },
              num_events: {
                title: "Number of Events",
                type: "number"
              },
            }
          }
        },
        eudract: {
          type: "object",
          properties: {
            time_frame: {
              title: "Timeframe for adverse event reporting",
              required: true,
              type: "textarea"
            },
            notes: {
              title: "Adverse event reporting additional description",
              type: "textarea"
            },
            assessment_type: {
              title: "Assessment type",
              type: "dropdown",
              enum: ["Systematic", "Non-systematic"],
              value: "Systematic"
            },
            frequency_reporting_threshold: {
              title: "Frequency threshold for reporting non-serious adverse events (max 5%)",
              type: "number",
              required: true
            },
            dictionary_name: {
              title: "Dictionary Name",
              type: "dropdown",
              enum: ["MedDRA", "SNOMED CT", "Other (specify)"],
              value: "MedDRA",
              required: true
            },
            other_dictionary_name: {
              title: "Other dictionary",
              type: "text"
            },
            dictionary_version: {
              title: "Version",
              type: "text",
              required: true
            }
          },
          groups: {
            properties: {
              title: {
                title: "Reporting group title",
                type: "textarea",
                required: true
              },
              description: {
                title: "Reporting group description",
                type: "textarea",
              },
              part_at_risk_serious_events: {
                title: "Subjects exposed",
                type: "number",
                required: true
              },
              num_subjects_serious_events: {
                title: "Subjects affected by serious adverse events",
                type: "number",
                required: true
              },
              num_subjects_frequent_events: {
                title: "Subjects affected by non-serious adverse events",
                type: "number",
                required: true
              },
              num_deaths: {
                title: "Total number of deaths (all causes)",
                type: "number",
                required: true
              },
              deaths_resulting_from_adverse_events: {
                title: "Total number of deaths resulting from adverse events",
                type: "number",
              }
            },
            show_keys: ["title", "description", "part_at_risk_serious_events", "num_subjects_serious_events", "num_subjects_frequent_events", "num_deaths", "deaths_resulting_from_adverse_events"]
          },
          serious_adverse_event: {
            type: "object",
            properties: {
              organ_system_name: {
                title: "System organ class",
                type: "dropdown",
                enum: organNameEnums
              },
              term: {
                title: "Event Term",
                type: "text",
                required: true
              },
              notes: {
                title: "Additional description",
                type: "textarea",
              },
              assessment_type: {
                title: "Collection Approach",
                type: "dropdown",
                enum: ["Unselected", "Systematic", "Non-systematic"]
              },
              dictionary_overridden: {
                title: "Do you want to use a different name and version for reporting this adverse event?",
                type: "dropdown",
                enum: ["Yes", "No"],
                value: "No"
              },
              dictionary_name: {
                title: "Dictionary Name",
                type: "dropdown",
                enum: ["MedDRA", "SNOMED CT", "Other (specify)"]
              },
              other_dictionary_name: {
                title: "Other dictionary",
                type: "text"
              },
              dictionary_version: {
                title: "Version",
                type: "text"
              }
            },
          },
          non_serious_adverse_event: {
            type: "object",
            properties: {
              organ_system_name: {
                title: "System organ class",
                type: "dropdown",
                enum: organNameEnums
              },
              term: {
                title: "Event Term",
                type: "text",
                required: true
              },
              notes: {
                title: "Additional description",
                type: "textarea",
              },
              assessment_type: {
                title: "Collection Approach",
                type: "dropdown",
                enum: ["Unselected", "Systematic", "Non-systematic"]
              },
              dictionary_overridden: {
                title: "Do you want to use a different name and version for reporting this adverse event?",
                type: "dropdown",
                enum: ["Yes", "No"],
                value: "No"
              },
              dictionary_name: {
                title: "Dictionary Name",
                type: "dropdown",
                enum: ["MedDRA", "SNOMED CT", "Other (specify)"]
              },
              other_dictionary_name: {
                title: "Other dictionary",
                type: "text"
              },
              dictionary_version: {
                title: "Version",
                type: "text"
              }
            },
          },
          serious_adverse_event_stat: {
            type: 'object',
            properties: {
              num_subjects_affected: {
                title: "Subjects affected number",
                type: "number"
              },
              num_subjects: {
                title: "Subjects exposed number",
                type: "number"
              },
              num_events: {
                title: "Occurrences all number",
                type: "number"
              },
              occurences_causally_related_to_treatment: {
                title: "Occurrences causally related to treatment number",
                type: "number"
              },
              deaths: {
                title: "Fatalities number",
                type: "number"
              },
              deaths_causally_related_to_treatment: {
                title: "Fatalities causally related to treatment number",
                type: "number"
              }
            }
          },
          non_serious_adverse_event_stat: {
            type: "object",
            properties: {
              num_subjects_affected: {
                title: "Subjects affected number",
                type: "number"
              },
              num_subjects: {
                title: "Subjects exposed number",
                type: "number"
              },
              num_events: {
                title: "Occurrences all number",
                type: "number"
              },
            }
          }
        }
      }
    }
  }
}