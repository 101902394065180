import { Fragment, useState, useEffect } from 'react';
import { cloneDeep } from 'lodash';
import { useShallow } from 'zustand/react/shallow'

// components
import Label from 'components/Label/Label';
import PrimeField from 'components/PrimeField/PrimeField';
import RJTableCommentContainer from '../../../components/RJTableCommentContainer/RJTableCommentContainer';

// context
import useAuthoringCommentsStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringCommentsStore';
import useResultsTableViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableViewStore';
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';

const StatisticalAnalysisItem = ({
  index,
  measure,
  analysis,
  outcomeData,
  setOutcomeData,
  schema
}) => {
  const {
    readOnly,
    formView,
    isReviewerView
  } = useAuthoringViewStore(
    useShallow(state => ({ 
      readOnly: state.readOnly,
      formView: state.formView,
      isReviewerView: state.isReviewerView
    }))
  )

  const [show, setShow] = useState(false || isReviewerView || readOnly);

  const {
    tableView
  } = useResultsTableViewStore()

  useEffect(() => {
    if (readOnly) {
      setShow(true)
    }
  }, [readOnly])

  let currView = formView.id;
  if (currView === "eudra") currView = "eudract"

  let analysisSchema = schema.properties[currView].properties.outcome_measure.properties.outcome_measure_analysis.properties;

  const onChange = (e, fieldKey) => {
    let tempData = { ...outcomeData };

    Object.keys(outcomeData).forEach(registry => {
      let findOMIndex = outcomeData[registry].outcome_measures.findIndex(item => item.id === measure.id);
      if (findOMIndex !== -1) {
        let om = cloneDeep(tempData[registry].outcome_measures[findOMIndex]);
        let foundAnalysisIndex = om.outcome_measure_analysis.findIndex(item => item.id === analysis.id);
        if (fieldKey === "title_container" || fieldKey === "description_container" || fieldKey === "analysis_specification_container") {
          let findOMEudractIndex = outcomeData["eudract"].outcome_measures.findIndex(item => item.id === measure.id);
          if (findOMEudractIndex !== -1) {
            tempData["eudract"].outcome_measures[findOMIndex].outcome_measure_analysis[foundAnalysisIndex][fieldKey] = e;
          }
        } else if (fieldKey === "parameter_type" && e.includes("Other")) {
          if (registry === "ctg") {
            tempData[registry].outcome_measures[findOMIndex].outcome_measure_analysis[foundAnalysisIndex][fieldKey] = "Other";
          } else if (registry === "eudract") {
            tempData[registry].outcome_measures[findOMIndex].outcome_measure_analysis[foundAnalysisIndex][fieldKey] = "Other (please specify)";
          } else {
            tempData[registry].outcome_measures[findOMIndex].outcome_measure_analysis[foundAnalysisIndex][fieldKey] = e;
          }
        } else if (fieldKey === "outcome_reporting_groups") {
          let items = getReportingGroupItems()
          let value = [];

          for (let i = 0; i < e.length; i++) {
            let currVal = e[i];

            let foundItem = items.find(item => item.registry_arm_id === currVal);
            if (foundItem) {
              value.push(foundItem)
            }
          }

          tempData[registry].outcome_measures[findOMIndex].outcome_measure_analysis[foundAnalysisIndex][fieldKey] = value;
        } else {
          tempData[registry].outcome_measures[findOMIndex].outcome_measure_analysis[foundAnalysisIndex][fieldKey] = e;
        }
      }

    })
    setOutcomeData(tempData);
  }

  const handleDelete = () => {
    let tempData = { ...outcomeData };
    Object.keys(outcomeData).forEach(registry => {
      let findOMIndex = outcomeData[registry].outcome_measures.findIndex(item => item.id === measure.id);
      if (findOMIndex !== -1) {
        let om = cloneDeep(tempData[registry].outcome_measures[findOMIndex]);
        let foundAnalysisIndex = om.outcome_measure_analysis.findIndex(item => item.id === analysis.id);
        if (foundAnalysisIndex !== -1) {
          tempData[registry].outcome_measures[findOMIndex].outcome_measure_analysis.splice(foundAnalysisIndex, 1)
        }
      }
    })
    setOutcomeData(tempData);
  }

  const handleUp = () => {
    let tempData = { ...outcomeData }
    Object.keys(tempData).forEach(registry => {
      let foundOMIndex = outcomeData[registry].outcome_measures.findIndex(item => item.id === measure.id);
      if (foundOMIndex !== -1) {
        let om = cloneDeep(tempData[registry].outcome_measures[foundOMIndex]);
        let foundAnalysisIndex = om.outcome_measure_analysis.findIndex(item => item.id === analysis.id);
        if (foundAnalysisIndex !== -1 && foundAnalysisIndex !== 0) {
          let tempAnalysisArray = [...om.outcome_measure_analysis];
          let saved = tempAnalysisArray[foundAnalysisIndex - 1];
          tempAnalysisArray[foundAnalysisIndex - 1] = tempAnalysisArray[foundAnalysisIndex];
          tempAnalysisArray[foundAnalysisIndex] = saved;
          tempData[registry].outcome_measures[foundOMIndex].outcome_measure_analysis = tempAnalysisArray;
        }
      }
    })

    setOutcomeData(tempData);
  }

  const handleDown = () => {
    let tempData = { ...outcomeData }
    Object.keys(tempData).forEach(registry => {
      let foundOMIndex = outcomeData[registry].outcome_measures.findIndex(item => item.id === measure.id);
      if (foundOMIndex !== -1) {
        let om = cloneDeep(tempData[registry].outcome_measures[foundOMIndex]);
        let foundAnalysisIndex = om.outcome_measure_analysis.findIndex(item => item.id === analysis.id);
        if (foundAnalysisIndex !== -1 && foundAnalysisIndex !== om.outcome_measure_analysis.length - 1) {
          let tempAnalysisArray = [...om.outcome_measure_analysis];
          let saved = tempAnalysisArray[foundAnalysisIndex + 1];
          tempAnalysisArray[foundAnalysisIndex + 1] = tempAnalysisArray[foundAnalysisIndex];
          tempAnalysisArray[foundAnalysisIndex] = saved;
          tempData[registry].outcome_measures[foundOMIndex].outcome_measure_analysis = tempAnalysisArray;
        }
      }
    })

    setOutcomeData(tempData);
  }

  const renderSubjectsInAnalysis = () => {
    let total = 0;

    analysis?.outcome_reporting_groups?.forEach(group => {
      let groupId = group.arm_id

      measure?.arms?.forEach(arm => {
        if (arm.id === groupId) {
          if (arm.subjects_analyzed) {
            total += Number(arm?.subjects_analyzed) || 0
          }
        }
      })

      measure?.subject_analysis_sets?.forEach(arm => {
        if (arm.id === groupId) {
          if (arm.subjects_analyzed) {
            total += Number(arm?.subjects_analyzed) || 0
          }
        }
      })
    })

    return (
      <tr>
        <td className="header-column">
          <Label>
            Subjects in this analysis
          </Label>
        </td>
        <td>
          <Label>
            {total}
          </Label>
        </td>
      </tr>
    )
  }

  const renderHeader = () => {
    return (
      <tr className="divider-row">
        <td colSpan={2}>
          <div className="footer-actions justify-between">
            <div
              className="flex items-center text-white text-bold"
              style={{ gap: 10, cursor: "pointer" }}
              onClick={() => setShow(!show)}>
              {show
                ? <i
                  className="fal fa-minus" />
                : <i
                  className="fal fa-plus" />
              }
              <div>
                {`${index + 1}. Statistical analysis`}
              </div>
            </div>
            {readOnly !== true &&
              <div className="flex items-center" style={{ gap: 16 }}>
                <div>
                  {index !== measure.outcome_measure_analysis.length - 1 &&
                    <i
                      className="fal fa-arrow-down text-white cursor-pointer"
                      onClick={handleDown} />
                  }
                  {index !== 0 &&
                    <i
                      className="fal fa-arrow-up text-white cursor-pointer"
                      onClick={handleUp} />
                  }
                </div>
                <i
                  onClick={handleDelete}
                  className="fal fa-trash-alt text-white cursor-pointer" />
              </div>
            }
          </div>
        </td>
      </tr>
    )
  }

  const getReportingGroupItems = () => {
    let items = []

    if (measure.arms) {
      let arms = measure.arms.map(item => ({
        arm_id: item.id,
        registry_arm_id: item.registry_arm_id,
        text: `${item.title}`
      }))
      items = [...arms]
    }

    if (measure.subject_analysis_sets) {
      let subject_analysis_sets = measure.subject_analysis_sets.map(item => ({
        arm_id: item.id,
        registry_arm_id: item.registry_arm_id,
        text: `${item.title}`
      }))
      items = [...items, ...subject_analysis_sets]
    }
    return items
  }

  return (
    <table>
      <tbody>
        {renderHeader()}
        {show && Object.keys(analysisSchema).map(sectionKey => {
          return (
            <Fragment
              key={`${tableView}-statistical-analysis-${index}-section-${sectionKey}`}>
              <tr>
                <td
                  className="disabled-row"
                  colSpan={2}>
                  {analysisSchema[sectionKey].title}
                </td>
              </tr>
              {
                Object.keys(analysisSchema[sectionKey].properties).map(rowKey => {
                  return (
                    <Fragment key={`om-statistical-analysis-set-${rowKey}`}>
                      <tr>
                        <PropertyHeader
                          analysis={analysis}
                          measure={measure}
                          sectionKey={sectionKey}
                          fieldKey={rowKey}
                          title={analysisSchema[sectionKey].properties[rowKey].title} />
                        <td>
                          <div className="flex" style={{ alignItems: "flex-end", gap: 6, justifyContent: "flex-start" }}>
                            {Object.keys(analysisSchema[sectionKey].properties[rowKey].properties).map(fieldKey => {
                              let fieldSchema = analysisSchema[sectionKey].properties[rowKey].properties[fieldKey]
                              let items = fieldSchema.enum;
                              let value = analysis[fieldKey]

                              if (fieldKey === "outcome_reporting_groups") {
                                items = getReportingGroupItems().map(item => {
                                  return {
                                    label: item.text,
                                    value: item.registry_arm_id
                                  }
                                })

                                value = analysis[fieldKey]?.filter(item => item)?.map(item => {
                                  return {
                                    value: item.registry_arm_id,
                                    label: item.text
                                  }
                                }) || []
                              }

                              return (
                                <PrimeField
                                  key={`${analysis.id}-${fieldKey}`}
                                  schema={{
                                    type: fieldSchema.type,
                                    items,
                                    orientation: "vertical",
                                    label: fieldSchema.title,
                                  }}
                                  readOnly={readOnly}
                                  onChange={e => onChange(e, fieldKey)}
                                  value={value} />
                              )
                            })}
                          </div>
                        </td>
                      </tr>
                      {tableView === "eudract" && rowKey === "outcome_reporting_groups_container" && renderSubjectsInAnalysis()}
                    </Fragment>
                  )
                })
              }
            </Fragment>
          )
        })}
      </tbody>
    </table>
  );
};

export default StatisticalAnalysisItem;

const PropertyHeader = ({
  analysis,
  measure,
  title,
  fieldKey,
  sectionKey
}) => {
  const [openCommentsForm, setOpenCommentsForm] = useState(false)
  const [showActions, setShowActions] = useState(false)

  const {
    commentsMap
  } = useAuthoringCommentsStore(
    useShallow(state => ({
      commentsMap: state.commentsMap
    }))
  )

  let jpath = `OutcomeRptGroup.om-statistical-analysis-${measure.id}-${analysis.id}-${sectionKey}-${fieldKey}`

  const commentStyle = {
    position: "absolute",
    right: 8,
    top: 8
  }

  return (
    <td
      className="header-column"
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}
      style={{ position: "relative" }}>
      <Label>{title}</Label>
      <RJTableCommentContainer
        label={title}
        jpath={jpath}
        openCommentsForm={openCommentsForm}
        setOpenCommentsForm={setOpenCommentsForm}
        setShowMenu={setShowActions}
        showMenu={showActions || commentsMap[jpath] || openCommentsForm}
        style={commentStyle}
        modalStyle={{ top: 24 }} />
    </td>
  )
}