import { v4 as uuidv4 } from 'uuid';

const bmProps = {
  ctg: {
    title: {
      title: "Baseline Measure Title",
      type: "text",
      value: "",
      validationKey: "otherTitle"
    },
    description: {
      title: "Baseline Measure Description",
      type: "textarea",
      value: ""
    },
    measure_analysis_population_description: {
      title: "Measure Analysis Population Description",
      type: "textarea",
      value: ""
    },
    parameterType: {
      categorical: {
        title: "Measure Type",
        type: "dropdown",
        readOnly: true,
        value: "Count of Participants",
        enum: ["Count of Participants"]
      },
      continuous: {
        title: "Measure Type",
        type: "dropdown",
        enum: ["Mean", "Median", "Least Squares Mean", "Geometric Mean", "Geometric Least Squares Mean"],
        value: "Mean"
      },
      customized: {
        title: "Measure Type",
        type: "dropdown",
        enum: ["Mean", "Median", "Least Squares Mean", "Geometric Mean", "Geometric Least Squares Mean", "Number"],
        value: "Mean"
      },
      customizedCategorical: {
        title: "Measure Type",
        type: "dropdown",
        enum: ["Count of Participants", "Number"],
        value: "Count of Participants"
      },
      allCustomized: {
        title: "Measure Type",
        type: "dropdown",
        enum: ["Count of Participants", "Mean", "Median", "Least Squares Mean", "Geometric Mean", "Geometric Least Squares Mean", "Number"],
        value: "Count of Participants"
      }
    },
    dispersionType: {
      categorical: {
        title: "Measure of Dispersion",
        type: "dropdown",
        readOnly: true,
        value: "Not Applicable",
        enum: ["Not Applicable", "Standard Deviation", "Inter-Quartile Range", "Full Range"],
      },
      continuous: {
        title: "Measure of Dispersion",
        type: "dropdown",
        enum: ["Standard Deviation", "Inter-Quartile Range", "Full Range"],
        value: "Standard Deviation"
      },
      customized: {
        title: "Measure of Dispersion",
        type: "dropdown",
        enum: ["Not Applicable", "Standard Deviation", "Inter-Quartile Range", "Full Range"],
        value: "Not Applicable"
      }
    },
    unitOfMeasure: {
      categorical: {
        title: "Unit of Measure",
        value: "participants",
        type: "text"
      },
      continuous: {
        title: "Unit of Measure",
        type: "text",
      }
    },
    rows: {
      continuous: {
        customized: {
          type: "array",
          title: "",
          categories: [
            {
              id: "num-analyzed",
              unique: true,
              title: "Number Analyzed",
              properties: {
                value: {
                  type: "number"
                }
              }
            },
            {
              id: `Category-${uuidv4()}`,
              title: "",
              properties: {
                value: {
                  type: "number"
                }
              }
            },
          ],
        },
        age: {
          type: "array",
          title: "",
          categories: [
            {
              id: "num-analyzed",
              unique: true,
              title: "Number Analyzed",
              properties: {
                value: {
                  type: "number"
                }
              }
            },
            {
              id: `Category-${uuidv4()}`,
              title: "",
              properties: {
                value: {
                  type: "number"
                }
              }
            },
          ],
        }
      },
      categorical: {
        customized: {
          type: "array",
          title: "",
          categories: [
            {
              id: "num-analyzed",
              unique: true,
              title: "Number Analyzed",
              properties: {
                value: {
                  type: "number"
                }
              }
            },
            {
              id: `Category-${uuidv4()}`,
              title: "",
              properties: {
                value: {
                  type: "number"
                }
              }
            },
          ],
        },
        age: {
          type: "array",
          title: "",
          categories: [
            {
              id: "num-analyzed",
              unique: true,
              title: "Number Analyzed",
              properties: {
                value: {
                  type: "number"
                }
              }
            },
            {
              id: `Category-${uuidv4()}`,
              title: "<= 18 years",
              properties: {
                value: {
                  type: "number"
                }
              }
            },
            {
              id: `Category-${uuidv4()}`,
              title: "Between 18 and 65",
              properties: {
                value: {
                  type: "number"
                }
              }
            },
            {
              id: `Category-${uuidv4()}`,
              title: ">= 65 years",
              properties: {
                value: {
                  type: "number"
                }
              }
            }
          ],
        },
        gender: {
          type: "array",
          title: "",
          categories: [
            {
              id: "num-analyzed",
              unique: true,
              title: "Number Analyzed",
              properties: {
                value: {
                  type: "number"
                }
              }
            },
            {
              id: `Category-${uuidv4()}`,
              title: "Female",
              properties: {
                value: {
                  type: "number"
                }
              }
            },            
            {
              id: `Category-${uuidv4()}`,
              title: "Male",
              properties: {
                value: {
                  type: "number"
                }
              }
            },
          ],
        },
        ethnicity: {
          type: "array",
          title: "",
          categories: [
            {
              id: "num-analyzed",
              title: "Number Analyzed",
              unique: true,
              properties: {
                value: {
                  type: "number"
                }
              }
            },
            {
              id: `Category-${uuidv4()}`,
              title: "Hispanic or Latino",
              properties: {
                value: {
                  type: "number"
                }
              }
            },
            {
              id: `Category-${uuidv4()}`,
              title: "Not Hispanic or Latino",
              properties: {
                value: {
                  type: "number"
                }
              }
            },
            {
              id: `Category-${uuidv4()}`,
              title: "Unknown or Not Reported",
              properties: {
                value: {
                  type: "number"
                }
              }
            },
          ],
        },
        race: {
          type: "array",
          title: "",
          categories: [
            {
              id: "num-analyzed",
              title: "Number Analyzed",
              unique: true,
              properties: {
                value: {
                  type: "number"
                }
              }
            },
            {
              id: `Category-${uuidv4()}`,
              title: "American Indian or Alaska Native",
              properties: {
                value: {
                  type: "number"
                }
              }
            },
            {
              id: `Category-${uuidv4()}`,
              title: "Asian",
              properties: {
                value: {
                  type: "number"
                }
              }
            },
            {
              id: `Category-${uuidv4()}`,
              title: "Native Hawaiian or Other Pacific Islander",
              properties: {
                value: {
                  type: "number"
                }
              }
            },
            {
              id: `Category-${uuidv4()}`,
              title: "Black or African American",
              properties: {
                value: {
                  type: "number"
                }
              }
            },
            {
              id: `Category-${uuidv4()}`,
              title: "White",
              properties: {
                value: {
                  type: "number"
                }
              }
            },
            {
              id: `Category-${uuidv4()}`,
              title: "More than one race",
              properties: {
                value: {
                  type: "number"
                }
              }
            },
            {
              id: `Category-${uuidv4()}`,
              title: "Unknown or Not Reported",
              properties: {
                value: {
                  type: "number"
                }
              }
            },
          ],
        }
      },
      customized: {
        specific: {
          type: "array",
          title: "",
          canEditCategoryTitle: true,
          canEditRowTitle: true,
          categories: [
            {
              id: "num-analyzed",
              unique: true,
              title: "Number Analyzed",
              properties: {
                value: {
                  type: "number"
                }
              }
            },
            {
              id: `Category-${uuidv4()}`,
              title: "",
              properties: {
                value: {
                  type: "number"
                }
              }
            },
          ],
        },
        age: {
          type: "array",
          title: "",
          canEditRowTitle: true,
          canEditCategoryTitle: true,
          categories: [
            {
              id: "num-analyzed",
              title: "Number Analyzed",
              unique: true,
              properties: {
                value: {
                  type: "number"
                }
              }
            },
            {
              id: `Category-${uuidv4()}`,
              title: "",
              properties: {
                value: {
                  type: "number"
                }
              }
            },
          ],
        },
        gender: {
          type: "array",
          title: "",
          canEditRowTitle: true, 
          canEditCategoryTitle: true,
          categories: [
            {
              id: "num-analyzed",
              title: "Number Analyzed",
              unique: true,
              properties: {
                value: {
                  type: "number"
                }
              }
            },
            {
              id: `Category-${uuidv4()}`,
              title: "",
              properties: {
                value: {
                  type: "number"
                }
              }
            },
          ],
        },
        regionEnrollment: {
          type: "array",
          title: "",
          canEditRowTitle: true,
          categories: [
            {
              id: "num-analyzed",
              title: "Number Analyzed",
              unique: true,
              properties: {
                value: {
                  type: "number"
                }
              }
            },
            {
              id: `Category-${uuidv4()}`,
              title: "",
              properties: {
                value: {
                  type: "number"
                }
              }
            },
          ],
        },
        notCollected: {
          type: "array",
          title: "",
          categories: [
            {
              id: "num-analyzed",
              title: "Number Analyzed",
              unique: true,
              properties: {
                value: {
                  type: "number",
                  readOnly: true
                }
              }
            },
          ],
        }
      }
    },
  },
  eudract: {
    title: {
      title: "Characteristic title",
      type: "text",
      value: ""
    },
    description: {
      title: "Description",
      type: "textarea",
      value: ""
    },
    parameterType: {
      continuous: {
        title: "Central tendency type",
        type: "dropdown",
        enum: ["Mean", "Median", "Least Squares Mean", "Geometric Mean", "Geometric Least Squares Mean"]
      }
    },
    dispersionType: {
      continuous: {
        title: "Dispersion type",
        type: "dropdown",
        enum: ["Standard Deviation", "Inter-Quartile Range", "Full Range"]
      }
    },
    unitOfMeasure: {
      categorical: {
        title: "Units",
        readOnly: true,
        value: "Subjects",
        type: "text"
      },
      continuous: {
        title: "Units",
        type: "dropdown",
        enum: ["years", "months", "weeks", "days", "hours", "minutes"]
      },
      customized: {
        title: "Units",
        type: "text",
      }
    },
    rows: {
      continuous: {
        customized: {
          type: "array",
          title: "",
          categories: [
            {
              id: `Category-${uuidv4()}`,
              title: "",
              properties: {
                value: {
                  type: "number"
                }
              }
            },
          ],
        } ,
        age: {
          type: "array",
          title: "",
          categories: [
            {
              id: `Category-${uuidv4()}`,
              title: "",
              properties: {
                value: {
                  type: "number"
                }
              }
            },
          ],
        },
      },
      categorical: {
        customized: {
          type: "array",
          title: "",
          canEditCategoryTitle: true,
          categories: [
            {
              id: `Category-${uuidv4()}`,
              title: "",
              properties: {
                value: {
                  type: "number"
                }
              }
            },
          ],
        } ,
        age: {
          type: "array",
          title: "",
          categories: [
            {
              id: `Category-${uuidv4()}`,
              title: "In Utero",
              properties: {
                value: {
                  type: "number"
                }
              }
            },
            {
              id: `Category-${uuidv4()}`,
              title: "Preterm newborn infants (gestional age < 37 wks)",
              properties: {
                value: {
                  type: "number"
                }
              }
            },
            {
              id: `Category-${uuidv4()}`,
              title: "Newborns (0-27 days)",
              properties: {
                value: {
                  type: "number"
                }
              }
            },
            {
              id: `Category-${uuidv4()}`,
              title: "Infants and toddlers (28 days - 23 months)",
              properties: {
                value: {
                  type: "number"
                }
              }
            },
            {
              id: `Category-${uuidv4()}`,
              title: "Children (2 - 11 years)",
              properties: {
                value: {
                  type: "number"
                }
              }
            },
            {
              id: `Category-${uuidv4()}`,
              title: "12 - 17 years",
              properties: {
                value: {
                  type: "number"
                }
              }
            },
            {
              id: `Category-${uuidv4()}`,
              title: "Adults (18 - 64 years)",
              properties: {
                value: {
                  type: "number"
                }
              }
            },
            {
              id: `Category-${uuidv4()}`,
              title: "From 65 - 84 years",
              properties: {
                value: {
                  type: "number"
                }
              }
            },
            {
              id: `Category-${uuidv4()}`,
              title: "85 years and over",
              properties: {
                value: {
                  type: "number"
                }
              }
            }
          ],
        },
        gender: {
          type: "array",
          title: "",
          categories: [
            {
              id: `Category-${uuidv4()}`,
              title: "Male",
              properties: {
                value: {
                  type: "number"
                }
              }
            },
            {
              id: `Category-${uuidv4()}`,
              title: "Female",
              properties: {
                value: {
                  type: "number"
                }
              }
            }
          ]
        }
      }
    },
  },
}

export const ctgBaselineMeasures = {
  studySpecific: {
    id: "ctg-specific-customized",
    title: "Study-Specific Measure",
    canAddCategories: true,
    properties: {
      title: bmProps.ctg.title,
      measure_description: bmProps.ctg.description,
      parameter_type: bmProps.ctg.parameterType.allCustomized,
      measure_analysis_population_description: bmProps.ctg.measure_analysis_population_description,
      dispersion_type: bmProps.ctg.dispersionType.customized,
      unit_of_measure: {
        ...bmProps.ctg.unitOfMeasure.categorical,
        value: ""
      }
    },
    rows: bmProps.ctg.rows.customized.specific,
  },
  ageContinuous: {
    id: "ctg-age-continuous",
    title: "Age, Continuous",
    properties: {
      title: {
        ...bmProps.ctg.title,
        value: "Age, Continuous",
        readOnly: true
      },
      measure_description: bmProps.ctg.description,
      measure_analysis_population_description: bmProps.ctg.measure_analysis_population_description,
      parameter_type: {
        ...bmProps.eudract.parameterType.continuous,
        value: "Mean"
      },
      dispersion_type: {
        ...bmProps.eudract.dispersionType.continuous,
        value: "Standard Deviation"
      },
      unit_of_measure: bmProps.ctg.unitOfMeasure.continuous
    },
    rows: bmProps.ctg.rows.continuous.age,
  },
  ageCategorical: {
    id: "ctg-age-categorical",
    title: "Age, Categorical",
    rows: bmProps.ctg.rows.categorical.age,
    properties: {
      title: {
        ...bmProps.ctg.title,
        value: "Age, Categorical",
        readOnly: true
      },
      measure_description: bmProps.ctg.description,
      measure_analysis_population_description: bmProps.ctg.measure_analysis_population_description,
      parameter_type: bmProps.ctg.parameterType.categorical,
      dispersion_type: bmProps.ctg.dispersionType.categorical,
      unit_of_measure: bmProps.ctg.unitOfMeasure.categorical
    }
  },
  ageCustomized: {
    id: "ctg-age-customized",
    title: "Age, Customized",
    canAddCategories: true,
    properties: {
      title: {
        ...bmProps.ctg.title,
        value: "Age, Customized",
        readOnly: true
      },
      measure_description: bmProps.ctg.description,
      measure_analysis_population_description: bmProps.ctg.measure_analysis_population_description,
      parameter_type: {
        value: "Count of Participants",
        ...bmProps.ctg.parameterType.allCustomized
      },
      dispersion_type: bmProps.ctg.dispersionType.customized,
      unit_of_measure: bmProps.ctg.unitOfMeasure.continuous
    },
    rows: bmProps.ctg.rows.customized.age,
  },
  genderCategorical: {
    id: "ctg-gender-categorical",
    title: "Sex: Female, Male",
    properties: {
      title: {
        ...bmProps.ctg.title,
        value: "Sex: Female, Male",
        readOnly: true
      },
      measure_description: bmProps.ctg.description,
      measure_analysis_population_description: bmProps.ctg.measure_analysis_population_description,
      parameter_type: bmProps.ctg.parameterType.categorical,
      dispersion_type: bmProps.ctg.dispersionType.categorical,
      unit_of_measure: bmProps.ctg.unitOfMeasure.categorical
    },
    rows: bmProps.ctg.rows.categorical.gender,
  },
  genderCustomized: {
    id: "ctg-gender-customized",
    title: "Sex/Gender, Customized",
    canAddCategories: true,
    properties: {
      title: {
        ...bmProps.ctg.title,
        value: "Sex/Gender, Customized",
        readOnly: true
      },
      measure_description: bmProps.ctg.description,
      measure_analysis_population_description: bmProps.ctg.measure_analysis_population_description,
      parameter_type: {
        ...bmProps.ctg.parameterType.allCustomized,
        value: "Count of Participants"
      },
      dispersion_type: {
        ...bmProps.ctg.dispersionType.customized,
        value: "Not Applicable"
      },
      unit_of_measure: bmProps.ctg.unitOfMeasure.continuous
    },
    rows: bmProps.ctg.rows.customized.gender,
  },
  raceCategorical: {
    id: "ctg-race-categorical",
    title: "Race (NIH/OMB)",
    properties: {
      title: {
        ...bmProps.ctg.title,
        value: "Race (NIH/OMB)",
        readOnly: true
      },
      measure_description: bmProps.ctg.description,
      measure_analysis_population_description: bmProps.ctg.measure_analysis_population_description,
      parameter_type: bmProps.ctg.parameterType.categorical,
      dispersion_type: bmProps.ctg.dispersionType.categorical,
      unit_of_measure: bmProps.ctg.unitOfMeasure.categorical
    },
    rows: bmProps.ctg.rows.categorical.race,
  },
  ethnicityCategorical: {
    id: "ctg-ethnicity-categorical",
    title: "Ethnicity (NIH/OMB)",
    properties: {
      title: {
        ...bmProps.ctg.title,
        value: "Ethnicity (NIH/OMB)",
        readOnly: true
      },
      measure_description: bmProps.ctg.description,
      measure_analysis_population_description: bmProps.ctg.measure_analysis_population_description,
      parameter_type: bmProps.ctg.parameterType.categorical,
      dispersion_type: bmProps.ctg.dispersionType.categorical,
      unit_of_measure: bmProps.ctg.unitOfMeasure.categorical
    },
    rows: bmProps.ctg.rows.categorical.ethnicity
  },
  raceEthnicityCustomized: {
    id: "ctg-ethnicity-customized",
    title: "Race/Ethnicity, Customized",
    canAddCategories: true,
    properties: {
      title: {
        ...bmProps.ctg.title,
        value: "Race/Ethnicity, Customized",
        readOnly: true
      },
      measure_description: bmProps.ctg.description,
      measure_analysis_population_description: bmProps.ctg.measure_analysis_population_description,
      parameter_type: {
        ...bmProps.ctg.parameterType.allCustomized,
        value: "Count of Participants"
      },
      dispersion_type: {
        ...bmProps.ctg.dispersionType.customized,
        value: "Not Applicable"
      },
      unit_of_measure: bmProps.ctg.unitOfMeasure.continuous
    },
    rows: bmProps.ctg.rows.customized.gender,
  },
  raceEthnicityNotCollected: {
    id: "not-collected",
    title: "Race and Ethnicity Not Collected",
    canAddRow: false,
    properties: {
      title: {
        ...bmProps.ctg.title,
        value: "Race and Ethnicity Not Collected",
        readOnly: true
      },
      measure_description: {
        ...bmProps.ctg.description,
        readOnly: true,
      },
      measure_analysis_population_description: {
        ...bmProps.ctg.measure_analysis_population_description,
        value: "Race and Ethnicity were not collected from any participant"
      },
      parameter_type: {
        ...bmProps.ctg.parameterType.customizedCategorical,
        value: "Count of Participants",
        readOnly: true
      },
      dispersion_type: {
        ...bmProps.ctg.dispersionType.categorical,
        value: "Not Applicable",
        readOnly: true
      },
      unit_of_measure: {
        ...bmProps.ctg.unitOfMeasure.continuous,
        readOnly: true,
        value: "participants"
      }
    },
    rows: bmProps.ctg.rows.customized.notCollected,
  },
  regionCategorical: {
    id: "ctg-region-categorical",
    title: "Region of Enrollment",
    canAddRow: true,
    properties: {
      title: {
        ...bmProps.ctg.title,
        value: "Region of Enrollment",
        readOnly: true
      },
      measure_description: bmProps.ctg.description,
      measure_analysis_population_description: bmProps.ctg.measure_analysis_population_description,
      parameter_type: {
        ...bmProps.ctg.parameterType.customizedCategorical,
        value: "Count of Participants"
      },
      dispersion_type: {
        ...bmProps.ctg.dispersionType.categorical,
        value: "Not Applicable"
      },
      unit_of_measure: bmProps.ctg.unitOfMeasure.continuous
    },
    rows: bmProps.ctg.rows.customized.regionEnrollment,
  },
}

export const eudractBaselineMeasures = {
  customizedCategorical: {
    id: "eudract-specific-categorical",
    title: "Study specific categorical characteristic",
    canAddCategories: true,
    properties: {
      title: bmProps.eudract.title,
      measure_description: bmProps.eudract.description,
      unit_of_measure: bmProps.eudract.unitOfMeasure.categorical
    },
    rows: bmProps.eudract.rows.categorical.customized,
  },
  customizedContinuous: {
    id: "eudract-specific-continuous",
    title: "Study specific continuous characteristic",
    canAddCategories: true,
    properties: {
      title: bmProps.eudract.title,
      measure_description: bmProps.eudract.description,
      parameter_type: {
        ...bmProps.eudract.parameterType.continuous,
        value: "Mean"
      },
      dispersion_type: {
        ...bmProps.eudract.dispersionType.continuous,
        value: "Standard Deviation"
      },
      unit_of_measure: bmProps.eudract.unitOfMeasure.customized
    },
    rows: bmProps.eudract.rows.continuous.customized,
  },
  ageCategorical: {
    id: "eudract-age-categorical",
    title: "Age categorical",
    rows: bmProps.eudract.rows.categorical.age,
    canAddCategories: true,
    properties: {
      title: {
        ...bmProps.eudract.title,
        value: "Age categorical",
        readOnly: true
      },
      measure_description: bmProps.eudract.description,
      unit_of_measure: bmProps.eudract.unitOfMeasure.categorical
    }
  },
  ageContinuous: {
    id: "eudract-age-continuous",
    title: "Age continuous",
    canAddCategories: true,
    properties: {
      title: {
        ...bmProps.eudract.title,
        value: "Age continuous",
        readOnly: true
      },
      measure_description: bmProps.eudract.description,
      parameter_type: {
        ...bmProps.eudract.parameterType.continuous,
        value: "Mean"
      },
      dispersion_type: {
        ...bmProps.eudract.dispersionType.continuous,
        value: "Standard Deviation"
      },
      unit_of_measure: bmProps.eudract.unitOfMeasure.continuous
    },
    rows: bmProps.eudract.rows.continuous.age,
  },
  genderCategorical: {
    id: "eudract-gender-categorical",
    title: "Gender categorical",
    canAddCategories: true,
    properties: {
      title: {
        ...bmProps.eudract.title,
        value: "Gender categorical",
        readOnly: true
      },
      measure_description: bmProps.eudract.description,
      unit_of_measure: bmProps.eudract.unitOfMeasure.categorical
    },
    rows: bmProps.eudract.rows.categorical.gender,
  },
}