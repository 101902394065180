// utils
import { getWidget } from '../shared/utils';
import { hideStudyDetailsFields, hideEupasProtocolFields, hideCtisProtocolFields } from './utils/utils';
import { arrayClassNames } from '../classes';
import { getPreviewStyles } from './utils/utils';

let hide = {
  "ui:options": {
    hide: true
  }
}

export const ctgProtocolUISchema = (configs) => {
  let preview = configs?.preview
  let formData = configs?.formData

  let returnObj = {
    protocol: {
      "ui:order": [
        "protocol_id",
        "study_type",
        "eupas_study_type",
        "eudract_number",
        "other_study_type",
        "study_identification",
        "contact_information",
        "study_oversight",
        "secondary_attributes",
        "study_timeline",
        "milestone_dates",
        "*",
      ],
      "ui:options": {
        title: "Study Identification and Information",
        classNames: {
          objectContainer: "rjs-gap-16",
          objectTitleContainer: "rjs-h1-container pt-24 pl-24 pr-24 pb-24 m-0",
          objectTitle: "rjs-h1",
          objectInnerContainer: "no-gap section-container"
        }
      },
      ...hideStudyDetailsFields(),
      member_states_concerned: hideCtisProtocolFields.member_states_concerned,
      study_type: {
        "ui:options": {
          title: "Study Type",
          placeholder: "Select Study Type",
          label: false,
          required: true,
          classNames: {
            fieldContainer: "w-50p pr-24 pl-8 pb-8",
          }
        }
      },
      protocol_id: {
        "ui:options": {
          label: false,
          required: true,
          title: "Unique Protocol Identification Number",
          placeholder: "Enter Unique Protocol ID",
          classNames: {
            fieldContainer: "w-50p pl-24 pr-8 pb-8",
          },
          validations: [{
            regex: /^.{0,30}$/,
            message: "Unique Protocol ID must have no more than 30 characters."
          }],
        }
      },
      study_identification: {
        "ui:order": ["protocol_id", "acronym", "brief_title", "official_title", "*"],
        "ui:options": {
          classNames: {
            fieldContainer: "rjs-full",
            objectContainer: "pl-24 pr-24 pb-13",
          }
        },
        brief_title: {
          "ui:options": {
            label: false,
            required: true,
            title: "Brief Title",
            placeholder: "Enter Brief Title",
            validations: [{
              regex: /^.{18,}$/,
              message: "A title this short cannot be sufficiently descriptive."
            },
            {
              regex: /^.{24,}$/,
              message: "A title this short may not be sufficiently descriptive."
            },
            {
              regex: /^.{0,300}$/,
              message: "Brief Title must have no more than 300 characters."
            },
            ],
            classNames: {
              fieldContainer: "rjs-full",
            },
          },
          "ui:widget": getWidget({ type: "textarea", preview })
        },
        acronym: {
          "ui:options": {
            title: "Acronym (if any)",
            placeholder: "Enter Acronym ",
            label: false,
            log: true,
            validations: [
              {
                regex: /^.{0,14}$/,
                message: "Acronym must have no more than 14 characters.",
              },
            ],
            classNames: {
              fieldContainer: "rjs-col-1-2 pr-8 pt-8",
            },
          }
        },
        official_title: {
          "ui:options": {
            label: false,
            required: true,
            title: "Official Title",
            placeholder: "Enter Official Title",
            validations: [
              {
                regex: /^.{0,600}$/,
                message: "Official Title must have no more than 600 characters.",
              },
            ],
            classNames: {
              fieldContainer: "rjs-full",
            },
          },
          "ui:widget": getWidget({ type: "textarea", preview })
        },
        secondary_ids: {
          "ui:options": {
            title: "Secondary ID's",
            itemTitle: "Identifier",
            addButtonTitle: "Add Secondary ID",
            label: false,
            addable: true,
            removable: true,
            orderable: true,
            classNames: arrayClassNames.primary
          },
          items: {
            "ui:order": ["secondary_id_type", "id", "domain", "*"],
            "ui:options": {
              hideFork: true,
            },
            secondary_id_type: {
              "ui:options": {
                hideFork: true,
                label: false,
                title: "Identifier Type",
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              },
            },
            id: {
              "ui:options": {
                label: false,
                hideFork: true,
                required: true,
                title: "data-schema", // data-schema title will pull title from data schema
                validations: [
                  {
                    regex: /^.{0,30}$/,
                    message: "Must not exceed 30 characters",
                  },
                ],
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            domain: {
              "ui:options": {
                label: false,
                required: true,
                title: "data-schema", // data-schema title will pull title from data schema
                validations: [
                  {
                    regex: /^.{0,119}$/,
                    message: "Must not exceed 119 characters",
                  },
                ],
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
          },
        },
        ...hideEupasProtocolFields.study_identification,
        ...hideCtisProtocolFields.study_identification
      },
      contact_information: {
        "ui:order": [
          "responsible_party",
          "sponsor",
          "investigator_name",
          "investigator_official_title",
          "investigator_affiliation",
          "*"
        ],
        "ui:options": {
          title: "Study Sponsor, Collaborators and Contact Information",
          classNames: {
            objectTitleContainer: "rjs-h1-container",
            objectContainer: "rjs-object-container p-24",
            objectTitle: "rjs-h1",
          }
        },
        responsible_party: {
          "ui:options": {
            title: "Responsible Party",
            placeholder: "Select Responsible Party",
            label: false,
            required: true,
            // orientation: "vertical",
            tooltip: "Select Sponsor unless the Principal Investigator has been designated as Responsible Party or the Principal Investigator is the Sponsor.",
            classNames: {
              fieldContainer: "rjs-full",
            },
          },
          "ui:widget": getWidget({ type: 'radio', preview }),
        },
        sponsor: {
          "ui:options": {
            title: "Sponsor",
            placeholder: "Select Sponsor",
            tooltip: "Primary organization conducting study and associated data analysis (not necessarily a funding source).",
            label: false,
            required: true,
            validations: [
              {
                regex: /^.{0,160}$/,
                message: "Sponsors must not have more than 160 characters",
              },
            ],
            classNames: {
              fieldContainer: "rjs-full",
              field: "rjs-col-1-2",
            },
          },
          "ui:widget": getWidget({ type: 'Lookahead', preview }),
        },
        investigator_name: {
          "ui:options": {
            title: "Investigator Name",
            placeholder: "Enter Investigator Name",
            label: false,
            required: true,
            tooltip: "Select Sponsor unless the Principal Investigator has been designated as Responsible Party or the Principal Investigator is the Sponsor.",
            classNames: {
              fieldContainer: "rjs-col-1-3",
            },
          }
        },
        investigator_official_title: {
          "ui:options": {
            title: "Investigator Official Title",
            placeholder: "Enter Official Title",
            required: true,
            validations: [{
              regex: /^.{0,254}$/,
              message: "Investigator Official Title must have no more than 254 characters."
            }],
            label: false,
            tooltip: "Select Sponsor unless the Principal Investigator has been designated as Responsible Party or the Principal Investigator is the Sponsor.",
            classNames: {
              fieldContainer: "rjs-col-1-3",
            },
          }
        },
        investigator_affiliation: {
          "ui:options": {
            title: "Investigator Affiliation",
            placeholder: "Enter Investigator Affiliation",
            label: false,
            required: true,
            validations: [{
              regex: /^.{0,160}$/,
              message: "Investigator Affiliation must have no more than 160 characters."
            },],
            tooltip: "Select Sponsor unless the Principal Investigator has been designated as Responsible Party or the Principal Investigator is the Sponsor.",
            classNames: {
              fieldContainer: "rjs-col-1-3",
            },
          }
        },
        collaborators: {
          "ui:options": {
            title: "Collaborators",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-2"
            }
          },
          "ui:widget": getWidget({ type: "Multiselect", preview })
        },
        central_contacts: {
          "ui:options": {
            title: "Central Contact",
            classNames: {
              objectTitle: "rjs-h2",
            }
          },
          central_contacts_title: {
            "ui:options": {
              label: false,
              title: "Title",
              placeholder: "Title",
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          central_contacts_first_name: {
            "ui:options": {
              label: false,
              title: "First Name",
              placeholder: "First Name",
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          central_contacts_middle_name: {
            "ui:options": {
              label: false,
              title: "M.I",
              placeholder: "Enter MI",
              classNames: {
                fieldContainer: "rjs-col-1-4"
              }
            }
          },
          central_contacts_last_name: {
            "ui:options": {
              label: false,
              required: true,
              title: "Last Name",
              placeholder: "Enter Last Name",
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          central_contacts_degree: {
            "ui:options": {
              label: false,
              title: "Degree",
              placeholder: "Enter Degree",
              classNames: {
                fieldContainer: "rjs-col-1-4"
              }
            }
          },
          central_contacts_phone_number: {
            "ui:options": {
              label: false,
              title: "Phone Number",
              placeholder: "Enter Phone Number",
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          central_contacts_ext: {
            "ui:options": {
              label: false,
              title: "Ext.",
              placeholder: "Enter Extension",
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          central_contacts_email: {
            "ui:options": {
              label: false,
              title: "E-mail",
              placeholder: "Enter E-mail",
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          }
        },
        central_contacts_backup: {
          "ui:options": {
            title: "Central Contact Backup",
            classNames: {
              objectTitle: "rjs-h2",
            }
          },
          central_contacts_backup_first_name: {
            "ui:options": {
              label: false,
              title: "First Name",
              placeholder: "First Name",
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          central_contacts_backup_middle_name: {
            "ui:options": {
              label: false,
              title: "M.I",
              placeholder: "Enter MI",
              classNames: {
                fieldContainer: "rjs-col-1-4"
              }
            }
          },
          central_contacts_backup_last_name: {
            "ui:options": {
              label: false,
              title: "Last Name",
              placeholder: "Enter Last Name",
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          central_contacts_backup_degree: {
            "ui:options": {
              label: false,
              title: "Degree",
              placeholder: "Enter Degree",
              classNames: {
                fieldContainer: "rjs-col-1-4"
              }
            }
          },
          central_contacts_backup_phone_number: {
            "ui:options": {
              label: false,
              title: "Phone Number",
              placeholder: "Enter Phone Number",
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          central_contacts_backup_ext: {
            "ui:options": {
              label: false,
              title: "Ext.",
              placeholder: "Enter Extension",
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          central_contacts_backup_email: {
            "ui:options": {
              label: false,
              title: "E-mail",
              placeholder: "Enter E-mail",
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          }
        },
        overall_study_officials: {
          "ui:options": {
            title: "Overall Study Officials",
            label: false,
            addable: true,
            removable: true,
            orderable: true,
            addButtonTitle: "Add Study Official",
            classNames: arrayClassNames.primary
          },
          items: {
            overall_study_officials_first_name: {
              "ui:options": {
                title: "First Name",
                placeholder: "Enter First Name",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            overall_study_officials_middle_name: {
              "ui:options": {
                title: "M.I",
                placeholder: "Enter MI",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-4"
                }
              }
            },
            overall_study_officials_last_name: {
              "ui:options": {
                title: "Last Name",
                placeholder: "Enter Last Name",
                label: false,
                required: true,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            overall_study_officials_degree: {
              "ui:options": {
                title: "Degree",
                placeholder: "Enter Degree",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-4"
                }
              }
            },
            overall_study_officials_organization_affiliation: {
              "ui:options": {
                title: "Organization Affiliation",
                placeholder: "Enter Organization Affiliation",
                label: false,
                required: true,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            official_role: {
              "ui:options": {
                title: "Official's Role",
                placeholder: "Select Role",
                label: false,
                required: true,
                classNames: {
                  fieldContainer: "rjs-full"
                }
              },
              "ui:widget": getWidget({ type: 'radio', preview }),
            },
          },
        },
        ...hideEupasProtocolFields.contact_information,
        ...hideCtisProtocolFields.contact_information,
        investigator_contact_details: {
          ...hide
        },
      },
      study_oversight: {
        "ui:options": {
          title: "Oversight",
          classNames: {
            objectTitleContainer: "rjs-h1-container",
            objectTitle: "rjs-h1",
            objectContainer: "rjs-oc p-24",
          }
        },
        us_drug: {
          "ui:options": {
            classNames: {
              fieldContainer: "rjs-full"
            }
          },
          us_fda_drug: {
            "ui:options": {
              title: "U.S. FDA-regulated Drug:",
              placeholder: "--Select--",
              errorMessage: "FDA Drug has not been selected.",
              label: false,
              required: true,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
            "ui:widget": getWidget({ type: 'radio', preview }),
          },
          productexported_from_us: {
            "ui:options": {
              title: "Product Exported from U.S.",
              placeholder: "--Select--",
              label: false,
              disabled: true,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
            "ui:widget": getWidget({ type: 'radio', preview }),
          },
        },
        us_device: {
          "ui:options": {
            classNames: {
              fieldContainer: "rjs-full"
            }
          },
          "ui:widget": getWidget({ type: 'radio', preview }),
          us_fda_device: {
            "ui:options": {
              title: "U.S. FDA-regulated Device:",
              placeholder: "--Select--",
              errorMessage: "FDA Device has not been selected.",
              label: false,
              required: true,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
            "ui:widget": getWidget({ type: 'radio', preview }),
          },
          unapproved_uncleared_device: {
            "ui:options": {
              title: "Unapproved/Uncleared Device",
              label: false,
              required: true,
              classNames: {
                fieldContainer: "rjs-full",
                objectTitle: "rjs-h2",
              }
            },
            "ui:widget": getWidget({ type: 'radio', preview }),
            device: {
              "ui:options": {
                title: "Unapproved/Uncleared Device:",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-2"
                }
              },
              "ui:widget": getWidget({ type: 'radio', preview }),
            },
            post_prior_to_approval_clearance: {
              "ui:options": {
                title: "Post Prior to Approval/Clearance:",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-2"
                }
              },
              "ui:widget": getWidget({ type: 'radio', preview }),
            },
          },
          post_prior_to_approval_clearance: {
            "ui:options": {
              title: "Post Prior to Approval/Clearance:",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-2"
              }
            },
            "ui:widget": getWidget({ type: 'radio', preview }),
          },
          pediatric_postmarket_surveillance: {
            "ui:options": {
              title: "Pediatric Postmarket Surveillance:",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-2"
              }
            },
            "ui:widget": getWidget({ type: 'radio', preview }),
          },
        },
        us_ind: {
          "ui:options": {
            classNames: {
              fieldContainer: "rjs-full"
            }
          },
          us_fda_ind_ide: {
            "ui:options": {
              title: "U.S. FDA IND/IDE:",
              label: false,
              required: true,
              classNames: {
                fieldContainer: "rjs-col-1-2"
              }
            },
            "ui:widget": getWidget({ type: 'radio', preview }),
          },
          fda_center: {
            "ui:options": {
              title: "FDA Center:",
              label: false,
              required: true,
              classNames: {
                fieldContainer: "rjs-col-1-2",
                field: "rjs-col-1-2"
              }
            },
            "ui:widget": getWidget({ type: 'radio', preview }),
          },
          ind_ide_number: {
            "ui:options": {
              title: "IND/IDE Number:",
              placeholder: "Enter Value",
              label: false,
              required: true,
              validations: [{
                regex: /^.{0,30}$/,
                message: "IND/IDE Number must have no more than 30 characters."
              },],
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
          },
          ind_serial_number: {
            "ui:options": {
              title: "IND Serial Number:",
              placeholder: "Enter Value",
              label: false,
              validations: [{
                regex: /^.{0,30}$/,
                message: "IND Serial Number must have no more than 30 characters."
              },],
              classNames: {
                fieldContainer: "rjs-col-2-3",
                field: "rjs-col-1-2",
              }
            },
          },
          availability_of_expanded_access: {
            "ui:options": {
              title: "Availability of Expanded Access:",
              placeholder: "--Select--",
              label: false,
              required: true,
              classNames: {
                fieldContainer: "rjs-full",
                field: "rjs-col-1-3",
              }
            },
            "ui:widget": getWidget({ type: 'radio', preview }),
          },
          expanded_access_record: {
            "ui:options": {
              title: "Expanded Access Record:",
              placeholder: "Enter value",
              label: false,
              required: true,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
          },
        },
        human_subjects_protection_review: {
          "ui:options": {
            label: true,
            classNames: {
              fieldContainer: "rjs-full"
            }
          },
          board_status: {
            "ui:options": {
              title: "Human Subjects Protection Review Board Status:",
              placeholder: "--Select--",
              errorMessage: "Review board status is a required field",
              label: false,
              required: true,
              classNames: {
                fieldContainer: "rjs-full",
                field: "rjs-col-1-3",
              }
            },
          },
          approvalnumber: {
            "ui:options": {
              title: "Approval Number:",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
          },
          board_name: {
            "ui:options": {
              title: "Board Name:",
              placeholder: "Enter name",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
          },
          board_affiliation: {
            "ui:options": {
              title: "Board Affiliation:",
              placeholder: "Enter affiliation",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
          },
          board_contact: {
            "ui:options": {
              label: false,
              classNames: {
                fieldContainer: "rjs-full"
              }
            },
            phone: {
              "ui:options": {
                title: "Phone:",
                placeholder: "Enter phone number",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              },
            },
            extension: {
              "ui:options": {
                title: "Ext:",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              },
            },
            email: {
              "ui:options": {
                title: "Email:",
                placeholder: "Enter Email Id",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              },
            },
            address: {
              "ui:options": {
                title: "Address:",
                placeholder: "Enter address",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-2-3"
                }
              },
              "ui:widget": getWidget({ type: "textarea", preview })
            },
          },
        },
        data: {
          "ui:options": {
            classNames: {
              fieldContainer: "rjs-full"
            }
          },
          data_monitoring_committee: {
            "ui:options": {
              title: "Data Monitoring Committee:",
              placeholder: "--Select--",
              label: false,
              required: true,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
            "ui:widget": getWidget({ type: 'radio', preview }),
          },
        },
        fda: {
          "ui:options": {
            classNames: {
              fieldContainer: "rjs-full"
            }
          },
          fda_regulated_intervention: {
            "ui:options": {
              title: "FDA Regulated Intervention:",
              placeholder: "--Select--",
              required: true,
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
            "ui:widget": getWidget({ type: 'radio', preview }),
          },
          section_801_clinical_trial: {
            "ui:options": {
              title: "Section 801 Clinical Trial:",
              placeholder: "--Select--",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
            "ui:widget": getWidget({ type: 'radio', preview }),
          },
        },
      },
      study_timeline: {
        "ui:options": {
          title: "Study Timelines and Status",
          classNames: {
            objectTitleContainer: "rjs-h1-container",
            objectTitle: "rjs-h1",
            objectContainer: "pt-24 pr-24 pl-24",
            fieldContainer: "m-0 rjs-full"
          }
        },
        record_verification_date: {
          "ui:options": {
            title: "Record Verification Date",
            label: false,
            required: true,
            classNames: {
              objectTitle: "rjs-h2",
              fieldContainer: "rjs-full"
            }
          },
          select_month: {
            "ui:options": {
              title: "Select Month",
              placeholder: "Select Month",
              label: false,
              required: true,
              classNames: {
                fieldContainer: "rjs-col-1-4"
              }
            }
          },
          year: {
            "ui:options": {
              title: "Year",
              placeholder: "Enter Year",
              label: false,
              required: true,
              classNames: {
                fieldContainer: "rjs-col-1-4"
              }
            }
          }
        },
        recruitment: {
          "ui:options": {
            classNames: {
              fieldContainer: "rjs-full"
            }
          },
          recruitment_status: {
            "ui:options": {
              title: "Overall Recruitment Status",
              placeholder: "Select Status",
              label: false,
              required: true,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          study_stopped: {
            "ui:options": {
              title: "Why Study Stopped?",
              placeholder: "Enter Reason",
              label: false,
              required: true,
              validations: [{
                regex: /^.{0,250}$/,
                message: "Why study stopped must have no more than 250 characters.",
              },],
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
        },
        milestone_dates: {
          ...hide,
        },
        expanded: {
          "ui:options": {
            classNames: {
              arrayTitle: "rjs-h3",
              fieldContainer: "rjs-full",
              arrayContainer: "p-24 rjs-border-radius",
              arrayItemsContainer: "flex flex-col gap-24",
              actionContainer: "flex justify-end"
            }
          },
          expanded_status: {
            "ui:options": {
              title: "Expanded Access Status",
              placeholder: "Select Status",
              label: false,
              required: true,
              classNames: {
                objectTitle: "rjs-h3",
              }
            }
          },
        },
        ...hideEupasProtocolFields.study_timeline
      },
      study_description: {
        "ui:order": ["brief_summary", "detailed_description", "*"],
        "ui:options": {
          title: "Study Description and Conditions",
          classNames: {
            fieldContainer: "rjs-full",
            objectTitleContainer: "rjs-h1-container",
            objectTitle: "rjs-h1",
            objectContainer: "p-24"
          }
        },
        brief_summary: {
          "ui:options": {
            title: "Brief Summary",
            placeholder: "Enter Brief Summary",
            //errorMessage: "Please specify all required information.",
            label: false,
            required: true,
            validations: [{
              regex: /^.{0,5000}$/,
              message: "Brief Summary must have no more than 5000 characters."
            }],
            classNames: {
              fieldContainer: "rjs-full"
            }
          },
          "ui:widget": getWidget({ type: "textarea", preview })
        },
        detailed_description: {
          "ui:options": {
            title: "Detailed Description",
            placeholder: "Enter Detailed Description",
            errorMessage: "Please specify all required information.",
            label: false,
            validations: [{
              regex: /^.{0,32000}$/,
              message: "Detailed Description must have no more than 32000 characters."
            },],
            classNames: {
              fieldContainer: "rjs-full"
            }
          },
          "ui:widget": getWidget({ type: "textarea", preview })
        },
        study_conditions: {
          "ui:options": {
            title: "Conditions",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-2"
            }
          },
          "ui:widget": getWidget({ type: "Multiselect", preview })
        },
        study_keywords: {
          "ui:options": {
            title: "Keywords",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-2"
            }
          },
          "ui:widget": getWidget({ type: "SelectTag", preview })
        },
        ...hideEupasProtocolFields.study_description,
        ...hideCtisProtocolFields.study_description,
      },
      study_scope: {
        "ui:order": ["patient_registry", "primary_purpose", "study_phase", "patient", "follow_up_duration", "duration_type", "additional_patient_registry_data", "*"],
        "ui:options": {
          title: "Study Scope and Design",
          classNames: {
            objectTitleContainer: "rjs-h1-container",
            objectTitle: "rjs-h1",
            objectContainer: "p-24"
          }
        },
        patient_registry: {
          "ui:options": {
            title: "Patient Registry",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": getWidget({ type: "CustomCheckboxGroup", preview }),
        },
        primary_purpose: {
          "ui:options": {
            title: "Primary Purpose",
            placeholder: "Select Purpose",
            errorMessage: "Primary Purpose has not been entered",
            label: false,
            required: true,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          }
        },
        study_phase: {
          "ui:options": {
            title: "Study Phase",
            placeholder: "Select Study Phase",
            errorMessage: "Phase cannot be N/A for a study of a U.S. FDA-regulated drug",
            tooltip: "Use N/A for trials that do not involve drug or biologic products",
            label: false,
            required: true,
            orientation: "vertical",
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": getWidget({ type: 'radio', preview }),
        },
        interventional_study_model: {
          "ui:options": {
            title: "Interventional Study Model",
            placeholder: "Select Interventional Study Model",
            errorMessage: "Interventional Study Model has not been entered",
            label: false,
            required: true,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          }
        },
        model_description: {
          "ui:options": {
            title: "Model Description",
            placeholder: "Enter Study Model description",
            label: false,
            validations: [{
              regex: /^.{0,1000}$/,
              message: "Model Description must have no more than 1000 characters."
            }],
            classNames: {
              fieldContainer: "rjs-full"
            }
          },
          "ui:widget": getWidget({ type: "textarea", preview })
        },
        number_of_arms: {
          "ui:options": {
            title: "Number of Arms/Groups/Cohorts in the study",
            placeholder: "Enter Number of Arms",
            errorMessage: "Number of Arms has not been entered",
            label: false,
            required: true,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          }
        },
        masking: {
          "ui:options": {
            title: "Masking",
            tooltip: "Check all roles that are masked or check None",
            label: false,
            required: true,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": getWidget({ type: "CustomCheckboxGroup", preview }),
        },
        masking_description: {
          "ui:options": {
            title: "Masking Description",
            placeholder: "Enter Masking description",
            label: false,
            validations: [{
              regex: /^.{0,1000}$/,
              message: "Masking Description must have no more than 1000 characters."
            }],
            classNames: {
              fieldContainer: "rjs-full"
            }
          },
          "ui:widget": getWidget({ type: "textarea", preview })
        },
        allocation: {
          "ui:options": {
            title: "Allocation",
            tooltip: "Select N/A for single-arm studies",
            label: false,
            required: true,
            classNames: {
              fieldContainer: "rjs-col-1-2"
            }
          },
          "ui:widget": getWidget({ type: 'radio', preview }),
        },
        enrollment_type: {
          "ui:options": {
            title: "Enrollment Type",
            placeholder: "Select Participant Type",
            errorMessage: "Enrollment Type has not been entered",
            label: false,
            required: true,
            classNames: {
              fieldContainer: "rjs-col-1-2"
            }
          },
          "ui:widget": getWidget({ type: 'radio', preview })
        },
        enrollment_type_obs: {
          "ui:options": {
            title: "Enrollment Type",
            placeholder: "Select Participant Type",
            errorMessage: "Enrollment Type has not been entered",
            label: false,
            required: true,
            classNames: {
              fieldContainer: "rjs-col-1-2"
            }
          },
          "ui:widget": getWidget({ type: 'radio', preview })
        },
        follow_up_duration: {
          "ui:options": {
            title: "Target Follow Up Duration",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
        },
        duration_type: {
          "ui:options": {
            title: "Duration",
            placeholder: "--Select--",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
        },
        additional_patient_registry_data: {
          "ui:options": {
            title: "Additional Patient Registry Data",
            placeholder: "[The AHRQ Registry of Patient Registries (RoPR) has been discontinued.]",
            label: false,
            classNames: {
              fieldContainer: "rjs-full"
            }
          },
          "ui:widget": getWidget({ type: "textarea", preview })
        },
        observational_study_model: {
          "ui:options": {
            title: "Observational Study Model",
            placeholder: "Select Observational Study Model",
            required: true,
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
        },
        time_perspective: {
          "ui:options": {
            title: "Time Perspective",
            placeholder: "Select Time Perspective",
            label: false,
            required: true,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
        },
        biospecimen: {
          "ui:options": {
            classNames: {
              arrayTitle: "rjs-h3",
              fieldContainer: "rjs-full",
              arrayContainer: "rjs-full p-24 rjs-border-radius",
              arrayItemsContainer: "flex flex-col gap-24",
              actionContainer: "flex justify-end"
            }
          },
          biospeciment_retention: {
            "ui:options": {
              title: "Biospecimen Retention",
              placeholder: "Select Biospecimen Retention",
              label: false,
              required: true,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
          },
          biospecimen_description: {
            "ui:options": {
              title: "Biospecimen Description",
              placeholder: "Enter Description",
              label: false,
              required: true,
              validations: [{
                regex: /^.{0,1000}$/,
                message: "Model Description must have no more than 1000 characters."
              }],
              classNames: {
                fieldContainer: "rjs-full"
              }
            },
            "ui:widget": getWidget({ type: "textarea", preview })
          },
        },
        enrollment_number: {
          "ui:options": {
            title: "Enrollment Number/Number of Subjects",
            label: false,
            required: true,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
        },
        number_of_cohorts: {
          "ui:options": {
            title: "Number of Cohorts in the Study",
            label: false,
            required: true,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          }
        },
        selection: {
          "ui:options": {
            tooltip: "Check all that apply",
            title: "Expanded Access Type",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": getWidget({ type: "CustomCheckboxGroup", preview }),
        },
        ...hideEupasProtocolFields.study_scope,
        ...hideCtisProtocolFields.study_scope,
      },
      study_arms: {
        "ui:options": {
          title: "Arms and Interventions",
          classNames: {
            objectTitleContainer: "rjs-h1-container",
            objectTitle: "rjs-h1",
            objectContainer: "p-24",
            fieldContainer: "rjs-full",
          }
        },
        arms: {
          "ui:options": {
            label: false,
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-full"
            }
          },
          "ui:widget": getWidget({ type: "SimpleTable", preview })
        },
        interventions: {
          "ui:options": {
            label: false,
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-full"
            }
          },
          "ui:widget": getWidget({ type: "SimpleTable", preview })
        },
        crossref: {
          "ui:options": {
            label: false,
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-full"
            }
          },
          "ui:widget": getWidget({ type: "CrossReferenceTable", preview })
        },
        ...hideEupasProtocolFields.study_arms,
        ...hideCtisProtocolFields.study_arms,
      },
      study_eligibilty: {
        "ui:options": {
          title: "Eligibility",
          classNames: {
            objectTitleContainer: "rjs-h1-container",
            objectTitle: "rjs-h1",
            objectContainer: "p-24"
          }
        },
        sex: {
          "ui:options": {
            title: "Sex:",
            placeholder: "--Select--",
            errorMessage: "Gender has not been selected.",
            label: false,
            required: true,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": getWidget({ type: 'radio', preview }),
        },
        gender_based: {
          "ui:options": {
            label: false,
            classNames: {
              fieldContainer: "rjs-full"
            }
          },
          gender_based_1: {
            "ui:options": {
              title: "Gender Based",
              placeholder: "--Select--",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
            "ui:widget": getWidget({ type: 'radio', preview }),
          },
          gender_eligibility_description: {
            "ui:options": {
              title: "Gender Eligibility Description",
              placeholder: "Enter Gender Eligibility Description",
              label: false,
              required: true,
              validations: [
                {
                  regex: /^.{0,1000}$/,
                  message: "Gender Eligibility Description must have no more than 1000 characters",
                },
              ],
              classNames: {
                fieldContainer: "rjs-full"
              }
            },
            "ui:widget": getWidget({ type: "textarea", preview })
          },
        },
        age_limits: {
          "ui:options": {
            title: "Age Limits",
            label: false,
            classNames: {
              objectTitle: "rjs-h2",
              fieldContainer: "rjs-full",
            }
          },
          minimum: {
            "ui:options": {
              classNames: {
                fieldContainer: "rjs-col-1-2",
              }
            },
            minimum_age_type: {
              "ui:options": {
                title: "Minimum:",
                placeholder: "--Select--",
                label: false,
                required: true,
                classNames: {
                  fieldContainer: "rjs-col-1-2",
                }
              },
            },
            minimum_age_value: {
              "ui:options": {
                title: "Enter Minimum Age",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-2",
                }
              },
            },
          },
          maximum: {
            "ui:options": {
              classNames: {
                fieldContainer: "rjs-col-1-2",
              }
            },
            maximum_age_type: {
              "ui:options": {
                title: "Maximum: ",
                placeholder: "--Select--",
                label: false,
                required: true,
                classNames: {
                  fieldContainer: "rjs-col-1-2",
                }
              },
            },
            maximum_age_value: {
              "ui:options": {
                title: "Enter Maximum Age",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-2",
                }
              },
            }
          }
        },
        accept_healthy_volunteers: {
          "ui:options": {
            title: "Accept Healthy Volunteers:",
            placeholder: "--Select--",
            label: false,
            required: true,
            classNames: {
              fieldContainer: "rjs-col-1-2",
            }
          },
          "ui:widget": getWidget({ type: 'radio', preview }),
        },
        is_clinical_study: {
          "ui:options": {
            title: "Is Clinical Study?",
            placeholder: "--Select--",
            label: false,
            required: true,
            hide: true,
            classNames: {
              fieldContainer: "rjs-col-1-2",
            }
          },
          "ui:widget": getWidget({ type: 'radio', preview }),
        },
        eligibility_criteria: {
          "ui:options": {
            title: "Eligibility Criteria",
            label: false,
            required: true,
            validations: [
              {
                regex: /^.{0,20000}$/,
                message: "Eligibility Criteria must have no more than 20000 characters",
              },
            ],
            classNames: {
              fieldContainer: "rjs-full"
            }
          },
          "ui:widget": getWidget({ type: "textarea", preview })
        },
        study_population_description: {
          "ui:options": {
            title: "Study Population Description",
            placeholder: "Enter Study Population Description",
            errorMessage: "Study Population Description has not been entered.",
            label: false,
            required: true,
            validations: [
              {
                regex: /^.{0,1000}$/,
                message: "Study Population Description must have no more than 1000 characters",
              },
            ],
            classNames: {
              fieldContainer: "rjs-full"
            }
          },
          "ui:widget": getWidget({ type: "textarea", preview })
        },
        sampling_method: {
          "ui:options": {
            title: "Sampling Method:",
            placeholder: "--Select--",
            errorMessage: "Sampling Method has not been entered.",
            label: false,
            required: true,
            classNames: {
              fieldContainer: "rjs-col-1-2"
            }
          },
        },
        ...hideEupasProtocolFields.study_eligibility,
        ...hideCtisProtocolFields.study_eligibility,
      },
      study_locations: {
        "ui:options": {
          title: "Locations",
          classNames: {
            objectTitleContainer: "rjs-h1-container",
            objectTitle: "rjs-h1",
            objectContainer: "p-24"
          }
        },
        locations: {
          "ui:options": {
            addButtonTitle: "Add Location",
            label: false,
            addable: true,
            removable: true,
            orderable: true,
            classNames: arrayClassNames.primary
          },
          items: {
            countries: {
              "ui:options": {
                title: "Countries:",
                type: "string",
                placeholder: "--Select--",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              },
            },
            facility: {
              "ui:options": {
                title: "Facility:",
                label: false,
                classNames: {
                  objectTitle: "rjs-h3",
                  fieldContainer: "rjs-full",
                }
              },
              name: {
                "ui:options": {
                  title: "Name:",
                  placeholder: "Enter Name",
                  label: false,
                  required: true,
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              city: {
                "ui:options": {
                  title: "City:",
                  placeholder: "Enter City",
                  label: false,
                  required: true,
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              state_province: {
                "ui:options": {
                  title: "State/Province:",
                  placeholder: "Enter State/Province",
                  label: false,
                  required: true,
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                },
              },
              zip_postalcode: {
                "ui:options": {
                  title: "Zip/Postal Code:",
                  placeholder: "Enter value",
                  label: false,
                  required: true,
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                },
              },
            },
            facility_contacts: {
              "ui:options": {
                title: "Facility Contact",
                classNames: {
                  objectTitle: "rjs-h3",
                  fieldContainer: "rjs-full",
                }
              },
              facility_contacts_first_name: {
                "ui:options": {
                  label: false,
                  title: "First Name",
                  placeholder: "First Name",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              facility_contacts_middle_name: {
                "ui:options": {
                  label: false,
                  title: "M.I",
                  placeholder: "Enter MI",
                  classNames: {
                    fieldContainer: "rjs-col-1-4"
                  }
                }
              },
              facility_contacts_last_name: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Last Name",
                  placeholder: "Enter Last Name",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              facility_contacts_degree: {
                "ui:options": {
                  label: false,
                  title: "Degree",
                  placeholder: "Enter Degree",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              facility_contacts_phone_number: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Phone Number",
                  placeholder: "Enter Phone Number",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              facility_contacts_ext: {
                "ui:options": {
                  label: false,
                  title: "Ext.",
                  placeholder: "Enter Extension",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              facility_contacts_email: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "E-mail",
                  placeholder: "Enter E-mail",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              }
            },
            facility_contacts_backup: {
              "ui:options": {
                title: "Facility Contact Backup",
                classNames: {
                  objectTitle: "rjs-h3",
                  fieldContainer: "rjs-full",
                }
              },
              facility_contacts_backup_first_name: {
                "ui:options": {
                  label: false,
                  title: "First Name",
                  placeholder: "First Name",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              facility_contacts_backup_middle_name: {
                "ui:options": {
                  label: false,
                  title: "M.I",
                  placeholder: "Enter MI",
                  classNames: {
                    fieldContainer: "rjs-col-1-4"
                  }
                }
              },
              facility_contacts_backup_last_name: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Last Name",
                  placeholder: "Enter Last Name",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              facility_contacts_backup_degree: {
                "ui:options": {
                  label: false,
                  title: "Degree",
                  placeholder: "Enter Degree",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              facility_contacts_backup_phone_number: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "Phone Number",
                  placeholder: "Enter Phone Number",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              facility_contacts_backup_ext: {
                "ui:options": {
                  label: false,
                  title: "Ext.",
                  placeholder: "Enter Extension",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              },
              facility_contacts_backup_email: {
                "ui:options": {
                  label: false,
                  required: true,
                  title: "E-mail",
                  placeholder: "Enter E-mail",
                  classNames: {
                    fieldContainer: "rjs-col-1-3"
                  }
                }
              }
            },
            overall_study_investigator: {
              "ui:options": {
                title: "Investigators",
                label: false,
                addable: true,
                removable: true,
                orderable: true,
                addButtonTitle: "Add Investigator",
                classNames: arrayClassNames.primary
              },
              items: {
                overall_study_investigator_first_name: {
                  "ui:options": {
                    title: "First Name",
                    placeholder: "Enter First Name",
                    label: false,
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                overall_study_investigator_middle_name: {
                  "ui:options": {
                    title: "M.I",
                    placeholder: "Enter MI",
                    label: false,
                    classNames: {
                      fieldContainer: "rjs-col-1-4"
                    }
                  }
                },
                overall_study_investigator_last_name: {
                  "ui:options": {
                    title: "Last Name",
                    placeholder: "Enter Last Name",
                    label: false,
                    required: true,
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                overall_study_investigator_degree: {
                  "ui:options": {
                    title: "Degree",
                    placeholder: "Enter Degree",
                    label: false,
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
                investigator_role: {
                  "ui:options": {
                    title: "Role",
                    placeholder: "Select Role",
                    label: false,
                    required: true,
                    classNames: {
                      fieldContainer: "rjs-col-1-3"
                    }
                  }
                },
              },
            },
          },
        }
      },
      study_ipd: {
        "ui:options": {
          title: "IPD Sharing Statements",
          classNames: {
            fieldContainer: "rjs-full",
            objectContainer: "rjs-gap-16 p-24",
            objectTitleContainer: "rjs-h1-container",
            objectTitle: "rjs-h1"
          }
        },
        plan_to_share_IPD: {
          "ui:options": {
            title: "Plan to Share IPD?",
            placeholder: "--Select--",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": getWidget({ type: 'radio', preview }),
        },
        plan_description: {
          "ui:options": {
            title: "Plan Description",
            errorMessage: "IPD Description must have no more than 1000 characters",
            label: false,
            validations: [{
              regex: /^.{0,1000}$/,
              message: "Plan Description must have no more than 1000 characters."
            },
            ],
            classNames: {
              fieldContainer: "rjs-full"
            }
          },
          "ui:widget": getWidget({ type: "textarea", preview })
        },
        ipd_sharing_info: {
          "ui:options": {
            title: "Supporting Information Type",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": getWidget({ type: "CustomCheckboxGroup", preview }),
        },
        time_frame: {
          "ui:options": {
            title: "Time Frame",
            errorMessage: "IPD Sharing Time Frame must have no more than 1000 characters.",
            label: false,
            validations: [{
              regex: /^.{0,1000}$/,
              message: "IPD Sharing Time Frame must have no more than 1000 characters."
            }],
            classNames: {
              fieldContainer: "rjs-col-2-3"
            }
          },
          "ui:widget": getWidget({ type: "textarea", preview })
        },
        access_criteria: {
          "ui:options": {
            title: "Access Criteria",
            //errorMessage: "IPD Sharing Access Criteria must have no more than 1000 characters.",
            label: false,
            validations: [
              {
                regex: /^.{0,1000}$/,
                message: "IPD Sharing Access Criteria must have no more than 1000 characters."
              },
            ],
            classNames: {
              fieldContainer: "rjs-full"
            }
          },
          "ui:widget": getWidget({ type: "textarea", preview })
        },
        url_text: {
          "ui:options": {
            title: "URL",
            errorMessage: "IPD Sharing URL must have no more than 3999 characters.",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-2"
            }
          },
        },
      },
      study_outcome_measures: {
        "ui:options": {
          title: "Study Endpoints / Outcome Measures",
          classNames: {
            fieldContainer: "rjs-full",
            objectContainer: "rjs-gap-16 p-24",
            objectTitleContainer: "rjs-h1-container",
            objectTitle: "rjs-h1",
          }
        },
        outcome_measures: {
          "ui:options": {
            title: "Outcome Measures",
            addButtonTitle: "Add Outcome Measures",
            arrayItemTitle: "Outcome Measure",
            label: false,
            addable: true,
            removable: true,
            orderable: true,
            canCopy: true,
            canCopyOutcomeMeasure: true,
            showIndex: true,
            classNames: arrayClassNames.primary
          },
          items: {
            title: {
              "ui:options": {
                title: "Title",
                placeholder: "Enter Title",
                label: false,
                required: true,
                hideFork: true,
                rows: 1,
                validations: [{
                  regex: /^.{0,254}$/,
                  message: "Title must have no more than 254 characters."
                },],
                classNames: {
                  fieldContainer: "rjs-full"
                }
              },
              "ui:widget": getWidget({ type: "textarea", preview })
            },
            time_frame: {
              "ui:options": {
                title: "Time Frame",
                placeholder: "Enter Time Frame",
                label: false,
                required: true,
                hideFork: true,
                validations: [{
                  regex: /^.{0,254}$/,
                  message: "Time Frame must have no more than 254 characters."
                },],
                classNames: {
                  fieldContainer: "rjs-full"
                }
              }
            },
            description: {
              "ui:options": {
                title: "Description",
                placeholder: "Enter Description",
                label: false,
                hideFork: true,
                validations: [{
                  regex: /^.{0,999}$/,
                  message: "Description must have no more than 999 characters."
                },],
                hideFork: true,
                classNames: {
                  fieldContainer: "rjs-full"
                }
              },
              "ui:widget": getWidget({ type: "textarea", preview })
            },
            type: {
              "ui:options": {
                title: "Type",
                label: false,
                required: true,
                hideFork: true,
                orientation: "horizontal",
                hideFork: true,
                classNames: {
                  fieldContainer: "rjs-full"
                }
              },
              "ui:widget": getWidget({ type: 'radio', preview }),
            },
            ...hideCtisProtocolFields.study_outcome_measures.outcome_measures
          }
        },
        ...hideEupasProtocolFields.study_outcome_measures
      },
      study_reference: {
        "ui:options": {
          title: "Citations, References and URLs",
          classNames: {
            fieldContainer: "rjs-full",
            objectTitleContainer: "rjs-h1-container",
            objectTitle: "rjs-h1",
            objectContainer: "p-24"
          }
        },
        citations: {
          "ui:options": {
            title: "Citations",
            addButtonTitle: "Add Citation",
            label: false,
            addable: true,
            removable: true,
            orderable: true,
            classNames: arrayClassNames.primary
          },
          items: {
            pub_med_id: {
              "ui:options": {
                title: "PubMed ID",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            result_reference: {
              "ui:options": {
                title: "Results Reference",
                placeholder: "--Select--",
                label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              },
              "ui:widget": getWidget({ type: 'radio', preview })
            },
            citation_text: {
              "ui:options": {
                title: "Enter Citation Text",
                errorMessage: "The citation description should be upto 2000 characters and cannot be editable",
                label: false,
                validations: [{
                  regex: /^.{0,1000}$/,
                  message: "Citation description should have no more than 1000 characters."
                },
                {
                  regex: /^.{0,5000}$/,
                  message: "Citation description must have no more than 5000 characters."
                }
                ],
                classNames: {
                  fieldContainer: "rjs-full"
                }
              },
              "ui:widget": getWidget({ type: "textarea", preview })
            },
          },
        },
        links: {
          "ui:options": {
            title: "Links",
            addButtonTitle: "Add Link",
            label: false,
            addable: true,
            removable: true,
            orderable: true,
            classNames: arrayClassNames.primary
          },
          items: {
            url_text: {
              "ui:options": {
                title: "URL",
                //errorMessage: "IPD Sharing URL must have no more than 3999 characters.",
                label: false,
                required: true,
                validations: [{
                  regex: /^.{0,254}$/,
                  message: "URL should have no more than 254 characters.",
                },
                ],
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            description_text: {
              "ui:options": {
                title: "Enter Description",
                //errorMessage: "Note : Link Description has not been entered",
                label: false,
                required: true,
                validations: [{
                  regex: /^.{0,254}$/,
                  message: "Link Description should have no more than 254 characters.",
                },
                ],
                classNames: {
                  fieldContainer: "rjs-full"
                }
              },
              "ui:widget": getWidget({ type: "textarea", preview })
            },
          },
        },
        available: {
          "ui:options": {
            title: "Available IPD/Information",
            addButtonTitle: "Add Data/Information",
            label: false,
            addable: true,
            removable: true,
            orderable: true,
            classNames: arrayClassNames.primary
          },
          items: {
            "ui:order": ["data_info", "url_text", "identifier", "description", "comments"],
            data_info: {
              "ui:options": {
                title: "Data/Information Type",
                placeholder: "--Select--",
                label: false,
                required: true,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              },
            },
            url_text: {
              "ui:options": {
                title: "URL",
                //errorMessage: "IPD Sharing URL must have no more than 3999 characters.",
                label: false,
                required: true,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            identifier: {
              "ui:options": {
                title: "Identifier",
                label: false,
                required: true,
                validations: [{
                  regex: /^.{0,30}$/,
                  message: "Study Document Identifier must have no more than 30 characters."
                }],
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
              }
            },
            comments: {
              "ui:options": {
                title: "Comments",
                label: false,
                required: true,
                validations: [{
                  regex: /^.{0,1000}$/,
                  message: "Study Document Comments must have no more than 1000 characters."
                }],
                classNames: {
                  fieldContainer: "rjs-full"
                }
              },
              "ui:widget": getWidget({ type: "textarea", preview })
            },
            description: {
              "ui:options": {
                title: "Reason",
                placeholder: "Please specify the reason",
                label: false,
                required: true,
                validations: [{
                  regex: /^.{0,254}$/,
                  message: "Study Document Other Type must have no more than 254 characters."
                }],
                classNames: {
                  fieldContainer: "rjs-full"
                }
              }
            },
          },
        },
        ...hideEupasProtocolFields.study_reference,
        ...hideCtisProtocolFields.study_reference,
      },
      sponsors: {
        ...hide
      },
      ctis_form: {
        ...hide
      },
      mscs: {
        ...hide
      },
      protocol_information: {
        ...hide
      },
      eupas_study_type: {
        ...hide
      },
      eudract_number: {
        ...hide
      },
      other_study_type: {
        ...hide
      },
    }
  }

  if (preview === true) {
    getPreviewStyles(returnObj, formData);
  }

  return returnObj;
}