import useGetApi from "../useGetApi"
import useMutateApi from "../useMutateApi"

// constants
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

// utils
import getUrl from "api/apiMap"

export const useGetRequestSecondaryIdTypes = () => {
  return useGetApi(getUrl(apiEndpoints.requestSecondaryIdTypes), queryKeys.requestSecondaryIdTypes)
}

// mutate({ body })
export const usePostRequestSecondaryIdTypes = () => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(apiEndpoints.requestSecondaryIdTypes),
    invalidateQueries: [queryKeys.requestSecondaryIdTypes],
    successMsg: "Successfully created secondary id type"
  })
}

// mutate({ id, body })
export const usePutRequestSecondaryIdTypes = () => {
  return useMutateApi({
    method: "put",
    apiEndpoint: getUrl(apiEndpoints.requestSecondaryIdTypes),
    invalidateQueries: [queryKeys.requestSecondaryIdTypes],
    successMsg: "Successfully updated secondary id type"
  })
}

// mutate({ id })
export const useDeleteRequestSecondaryIdTypes = () => {
  return useMutateApi({
    method: "delete",
    apiEndpoint: getUrl(apiEndpoints.requestSecondaryIdTypes),
    invalidateQueries: [queryKeys.requestSecondaryIdTypes],
    successMsg: "Successfully deleted secondary id type"
  })
}