import React, { useState, useEffect } from 'react';
import moment from 'moment';
import StaticField from '../../../../../../../components/StaticField/StaticField'
import './ClinicalTrialsGov.scss'

// apis
import { useUserSettings } from 'api/hooks';

const ClinicalTrialsGov = ({ clinicalTrialsData }) => {
  const { data: userSettings } = useUserSettings()
  const [items, setItems] = useState([
    {
      label: "NCT ID",
      value: "-",
      key: "nct_number",
      type: "string"
    },
    {
      label: "Initial Release",
      value: "-",
      key: "initial_release",
      type: "date"
    },
    {
      label: "Initial Results Release",
      value: "-",
      key: "initial_results_release",
      type: "date"
    },
    {
      label: "Last Public Release",
      value: "-",
      key: "last_public_release",
      type: "date"
    },
    {
      label: "Last Updated",
      value: "-",
      key: "last_updated_on_ctg",
      type: "date"
    },
    {
      label: "Registration Submitted",
      value: "-",
      key: "ctg_study_submitted",
      type: "date"
    },
    {
      label: "Results Posted",
      value: "-",
      key: "ctg_results_posted",
      type: "date"
    },
    {
      label: "Corrections Expected",
      value: "-",
      key: "prs_comments_corrections_expected",
      type: "number"
    },
    {
      label: "Delayed Certification Filed?",
      value: "-",
      key: "delayed_certification_filed",
      type: "boolean"
    },
    {
      label: "Delayed Certification Filed Date",
      value: "-",
      key: "delayed_certification_filed_date",
      type: "date"
    },
    {
      label: "Registration Posted",
      value: "-",
      key: "ctg_study_posted",
      type: "date"
    },
    {
      label: "Results Submitted",
      value: "-",
      key: "ctg_results_submitted",
      type: "date"
    }
  ])

  useEffect(() => {
    if (clinicalTrialsData) {
      let tempItems = [...items];
      for (let i = 0; i < tempItems.length; i++) {
        let curr = tempItems[i];
        if (clinicalTrialsData[curr.key] !== null && clinicalTrialsData[curr.key] !== undefined) {
          if (curr?.type === "date") curr.value = moment(clinicalTrialsData[curr.key], "YYYY-MM-DD").format(userSettings?.date_format)
          else if (curr?.type === "boolean") curr.value = clinicalTrialsData[curr.key] ? "Yes" : "No"
          else curr.value = clinicalTrialsData[curr.key]
        }
      }
      setItems(tempItems);
    }
  }, [clinicalTrialsData])

  const displayItems = () => {
    return items.map(item => {
      return (
        <StaticField
          key={item.key}
          title={item.label}
          value={item.value}
          style={{
            width: "50%",
            marginBottom: 12
          }} />
      )
    })
  }

  return <div className="clinical-trials-gov">
    <div className="clinical-trials-title-wrap">
      <div className="clinical-trials-title">
        ClinicalTrials.Gov
      </div>
    </div>
    <div className="clinical-trials-content">
      {displayItems()}
    </div>
  </div>
}

export default ClinicalTrialsGov