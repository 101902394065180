import React from 'react'
import "ag-grid-enterprise"

// components
import { AgGridReact } from "ag-grid-react";
import PropTypes from 'prop-types'
import Presets from 'components/Grid/Presets/Presets'
import Loading from 'components/Loading/Loading';
import ComplianceThreshold from 'components/Grid/ComplianceThreshold/ComplianceThreshold';

// column types
import dateColumn from './columnTypes/dateColumn'

// cell renderers
import DateCellRenderer from 'components/ag-grid-cell-renderers/Date/Date';
import PhaseBadge from 'components/badges/PhaseBadge/PhaseBadge';
import StudyTypeBadge from 'components/badges/StudyTypeBadge/StudyTypeBadge';
import VerificationBadge from 'components/badges/VerificationBadge/VerificationBadge';
import ActionItemStatus from 'components/ag-grid-cell-renderers/ActionItemStatus/ActionItemStatus';
import StudiesCellRenderer from 'components/ag-grid-cell-renderers/StudiesCellRenderer/StudiesCellRenderer';
import RequestsCellRenderer from 'containers/requests/components/RequestsCellRenderer';
import FavoriteStudyCellRenderer from 'components/ag-grid-cell-renderers/FavoriteCellRenderer/FavoriteStudyCellRenderer/FavoriteStudyCellRenderer'
import FavoriteRequestCellRenderer from 'components/ag-grid-cell-renderers/FavoriteCellRenderer/FavoriteRequestCellRenderer/FavoriteRequestCellRenderer';
import StatusCellRenderer from 'components/ag-grid-cell-renderers/StatusCellRenderer/StatusCellRenderer';
import ViewStudiesOngoingActivities from 'containers/studies/Studies/ActivitiesCellRenderer/ActivitiesCellRenderer';
import FavoriteCommentCellRenderer from 'components/ag-grid-cell-renderers/FavoriteCellRenderer/FavoriteCommentCellRenderer/FavoriteCommentCellRenderer';
import ActionItemDocs from 'components/ag-grid-cell-renderers/ActionItemDocs/ActionItemDocs';
import ActionItemForms from 'components/ag-grid-cell-renderers/ActionItemForms/ActionItemForms';
import WorkflowBadge from 'components/badges/WorkflowBadge/WorkflowBadge';
import RegistryCellRenderer from 'containers/studies/Study/subcomponents/Assessments/AssessmentsGrid/RegistryCellRenderer/RegistryCellRenderer';
import UserFullnameCellRenderer from 'components/ag-grid-cell-renderers/UserFullnameCellRenderer/UserFullnameCellRenderer';
import FilzeSizeCellRenderer from 'components/ag-grid-cell-renderers/FileSizeCellRenderer/FilzeSizeCellRenderer';
import SuccessFailedCellRenderer from 'components/ag-grid-cell-renderers/SuccessFailedCellRenderer/SuccessFailedCellRenderer';
import CountriesCellRenderer from 'components/ag-grid-cell-renderers/CountriesCellRenderer/CountriesCellRenderer';

// assets
import noRowsImg from 'assets/images/empty-grid.png'

// styles
import 'styles/ag-theme-prime.scss'
import './GridTypeA.scss'

const GridTypeA = ({
  gridRef,
  className,
  style,
  rowData,
  columnDefs,
  pagination = false,
  paginationPageSize = 10,
  onFilterChanged,
  search,
  defaultColDef: defaultColDefProps,
  gridOptions: gridOptionProps,
  colWidth,
  domLayout = 'normal',
  // columnTypes,
  onGridReady: onGridReadyProp,
  // menuTabs, // {["filterMenuTab"]}
  getRowHeight,
  rowSelection, // if row selection "multiple", rowMultiSelectWithClick and selection count will appear on status bar

  // status bar
  statusBar: statusBarProp,
  showRowCount = true,

  // sideBar and toolPanels
  sideBar: sideBarProp,
  presetGridName,
  complianceThreshold,

  ...restOfGridProps // rowModelType, serverSideDatasource
}) => {
  const [gridApi, setGridApi] = React.useState()
  const [gridColumnApi, setGridColumnApi] = React.useState()

  React.useEffect(() => {
    if (gridApi) {
      // gridApi.setRowData(rowData)
      gridApi.resetRowHeights()
    }
  }, [gridApi])

  React.useEffect(() => {
    if (columnDefs && gridApi) {
      gridApi.setColumnDefs(columnDefs)
    }
  }, [columnDefs, gridApi])

  React.useEffect(() => {
    if (gridApi && search !== undefined) {
      if (search) {
        gridApi.setQuickFilter(search)
      } else {
        gridApi.setQuickFilter('')
      }
      sizeColumns()
    }
    else if (gridApi) {
      sizeColumns()
    }
  }, [gridApi, search])

  const NoRowsOverlayComponent = () => {
    return <div className="no-rows-overlay-component">
      <img src={noRowsImg} alt="No rows image" />
    </div>
  }

  const gridOptions = {
    icons: {
      "presets": '<i class="fa fa-list" />',
      "calicon": '<i class="fal fa-clock" />',
    },
    loadingOverlayComponent: () => <Loading position="relative" />,
    noRowsOverlayComponent: () => <NoRowsOverlayComponent />,
    ...gridOptionProps,
  }

  const sideBar = React.useMemo(() => {
    if (sideBarProp || sideBarProp === false) return sideBarProp;

    let toolPanels = ["columns", "filters"]
    if (presetGridName) {
      toolPanels.push({
        id: 'presets',
        labelDefault: "Presets",
        labelKey: 'presets',
        iconKey: 'presets',
        toolPanel: Presets,
        toolPanelParams: {
          gridName: presetGridName,
        }
      })
    }

    if (complianceThreshold) {
      toolPanels.push({
        id: 'compliance-threshold',
        labelDefault: "Threshold",
        labelKey: 'threshold',
        iconKey: 'calicon',
        toolPanel: ComplianceThreshold,
      })
    }

    return {
      hiddenByDefault: false,
      toolPanels,
    }
  })

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
    lockPosition: false,
    // // TODO: for cell-ellipsis-text style, position vertically:
    // cellClass: ["cell-ellipsis-text"],
    cellClass: ["cell-wrap-text"],
    autoHeight: true, // for text wrapping
    tooltipComponent: "customTooltip",
    // menuTabs: ["filterMenuTab"],
    enableRowGroup: false,
    enableValue: true, // to be able to aggregate by cols via the GUI
    wrapHeaderText: true,
    ...defaultColDefProps,

    filterParams: {
      buttons: ['clear'],
      ...defaultColDefProps?.filterParams,
    },
  }

  const frameworkComponents = {
    Date: DateCellRenderer,
    PhaseBadge,
    StudyTypeBadge,
    VerificationBadge,
    ActionItemStatus,
    StudiesCellRenderer,
    RequestsCellRenderer,
    FavoriteStudyCellRenderer,
    FavoriteRequestCellRenderer,
    StatusCellRenderer,
    ViewStudiesOngoingActivities,
    FavoriteCommentCellRenderer,
    ActionItemDocs,
    ActionItemForms,
    WorkflowBadge,
    RegistryCellRenderer,
    UserFullnameCellRenderer,
    FilzeSizeCellRenderer,
    SuccessFailedCellRenderer,
    CountriesCellRenderer
  }

  const columnTypes = {
    dateColumn,
  }

  // grid events
  const onGridReady = (params) => {
    const {
      api,
      columnApi,
      ...restOfParams
    } = params
    setGridApi(api)
    setGridColumnApi(columnApi)
    if (onGridReadyProp) onGridReadyProp(params);
  }

  const sizeColumns = () => {
    if (colWidth === 'sizeToFit') {
      gridApi.sizeColumnsToFit()
    } else if (colWidth === 'autoSizeAll') {
      gridColumnApi.autoSizeColumns()
    }
  }

  // grid components
  const statusBar = React.useMemo(() => {
    let bar = {}
    if (statusBarProp) {
      bar.statusPanels = [...statusBarProp.statusPanels]
    } else {
      bar.statusPanels = []
    }
    if (showRowCount) bar.statusPanels.push({
      statusPanel: "agTotalAndFilteredRowCountComponent",
    });
    if (rowSelection) bar.statusPanels.push({
      statusPanel: "agSelectedRowCountComponent",
      align: "left",
    });
    return bar
  }, [statusBarProp])

  return <div
    className={`ag-theme-balham grid-type-a prime-grid${className ? ' ' + className : ''}`}
    style={style}
  >

    <AgGridReact
      ref={gridRef}
      rowData={rowData && Array.isArray(rowData) ? rowData : undefined} // not sent if rowModelType="serverSide"
      gridOptions={gridOptions}
      columnDefs={columnDefs}
      defaultColDef={defaultColDef}
      columnTypes={columnTypes}
      sideBar={sideBar}
      onGridReady={onGridReady}
      onFilterChanged={onFilterChanged}
      pagination={pagination}
      paginationPageSize={paginationPageSize}
      animateRows={true}
      enableRangeSelection={true}

      // row selection
      rowSelection={rowSelection}
      rowMultiSelectWithClick={rowSelection === 'multiple' && true}

      rowHeight={35} // works with .ag-cell 3px vertical padding
      getRowHeight={getRowHeight} // will override rowHeight prop
      domLayout={domLayout}

      statusBar={statusBar}
      frameworkComponents={frameworkComponents}
      {...restOfGridProps}
    />
  </div>
}

GridTypeA.propTypes = {
  search: PropTypes.string,
  rowData: PropTypes.arrayOf(PropTypes.object),
  colWidth: PropTypes.oneOf([
    'autoSizeAll',
    'sizeToFit',
  ]),
  domLayout: PropTypes.oneOf([
    'normal', // size of parent container
    'autoHeight', // height increases with number of rows
  ]),
  showRowCount: PropTypes.bool,
  showSelectedCount: PropTypes.bool,
  presetGridName: PropTypes.string,
}

export default GridTypeA