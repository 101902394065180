import { useState, useEffect } from 'react';
import { cloneDeep, uniqBy } from 'lodash';
import { v4 as uuid } from 'uuid'
import { useShallow } from 'zustand/react/shallow'

// components
import PrimeField from 'components/PrimeField/PrimeField';
import ResultsTableCreateModal from '../../../../components/ResultsTableCreateModal/ResultsTableCreateModal';
import ResultsTableDeleteModal from '../../../../components/ResultsTableDeleteModal/ResultsTableDeleteModal';
import Arm from '../Arm/Arm';

// context
import useResultsTableViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableViewStore';
import useResultsTableArmsStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableArmsStore';
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';
import useAuthoringDataStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringDataStore';

const Arms = ({
  schema,
  adverseData,
  setAdverseData
}) => {
  const {
    formData,
    setFormData,
  } = useAuthoringDataStore(
    useShallow(state => ({
      formData: state.formData,
      setFormData: state.setFormData,
    }))
  )

  const {
    readOnly
  } = useAuthoringViewStore(
    useShallow(state => ({ readOnly: state.readOnly }))
  )

  const {
    adverseArmOptions
  } = useResultsTableArmsStore()

  const {
    tableView,
  } = useResultsTableViewStore()

  const [showDropdown, setShowDropdown] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedGroupType, setSelectedGroupType] = useState(null);

  const onAddGroup = (applyAll) => {
    const copyArms = (groups) => {
      let tempData = { ...adverseData };

      const populateValues = (registry, fieldKey, schemaKey, registryGroups) => {
        let values = registryGroups.map(group => {
          let value = { 
            group_id: group.id,
            registry_arm_id: group.registry_arm_id
          };
          Object.keys(schema.properties[registry][schemaKey].properties).forEach(key => {
            let val = null;
            value[key] = val;
          })
          return value;
        })

        tempData[registry][fieldKey].forEach(event => {
          if (event.values) event.values = [...event.values, ...cloneDeep(values)];
          else event.values = cloneDeep(values);
        }) 
      }

      Object.keys(adverseData).forEach(registry => {
        if (applyAll || tableView === registry) {

          let registryGroups = groups.map(regGroup => {
            let newGroup = { ...regGroup }
            let existingIndex = tempData[registry].groups.findIndex(group => group.registry_arm_id === newGroup.registry_arm_id);
            if (existingIndex !== -1) newGroup.id = "ReportedEvents-InterventionGroup." + uuid();
            Object.keys(schema.properties[registry].groups.properties).forEach(key => {
              newGroup[key] = regGroup[key] || null;
            })
            return newGroup;
          })
          populateValues(registry, "serious_adverse_events", "serious_adverse_event_stat", registryGroups);
          populateValues(registry, "non_serious_adverse_events", "non_serious_adverse_event_stat", registryGroups);

          if (tempData[registry].groups) tempData[registry].groups = [...tempData[registry].groups, ...cloneDeep(registryGroups)];
          else tempData[registry].groups = cloneDeep(registryGroups);
        }
      })
      setAdverseData(tempData)
    }

    const filterDuplicateArms = (a, b) => {
      let ret = []
      b.forEach(bItem => {
        let foundItem = a.find(aItem => aItem.registry_arm_id === bItem.registry_arm_id);
        if (!foundItem) ret.push(bItem)
      })

      return ret;
    }

    const formatArmIds = (arms) => {
      return arms.map(arm => {
        let oldArmIds = arm.id.split(".")
        let newId = arm.id;
        if (oldArmIds?.[1] && oldArmIds?.[0]?.includes("Participant")) {
          newId = "ReportedEvents-InterventionGroup." + oldArmIds[1];
        } else if (oldArmIds?.[0] && !oldArmIds?.[1]) {
          newId = "ReportedEvents-InterventionGroup." + oldArmIds[0];
        }
        return {
          ...arm,
          id: newId
        }
      })
    }

    switch (selectedGroupType.id) {
      case "copy-protocol": 
        break;
      case "copy-pf":
        if (formData?.results?.participant_flow?.participant_flow_table?.global_arms) {
          let pfArms = cloneDeep(formData.results?.participant_flow.participant_flow_table.global_arms);
          let registryArms = adverseData?.[tableView]?.groups ? [...adverseData[tableView].groups] : [];
          let filteredArms = [...filterDuplicateArms(registryArms, pfArms)];
          copyArms(formatArmIds(filteredArms))
        }
        break;
      case "copy-bc": return;
      case "create-new": 
        let id = uuid();
        let group = {
          id: "ReportedEvents-InterventionGroup." + id,
          registry_arm_id: "ReportedEvents-InterventionGroup." + id,
        }
        copyArms([group])
        break;
      default: 
        let foundPeriod = formData?.results?.participant_flow?.participant_flow_table?.eudract_periods?.find(period => period.id === selectedGroupType.id);
        if (foundPeriod) {
          let periodArms = cloneDeep(foundPeriod.arms);
          
          let registryArms = adverseData?.[tableView]?.groups ? [...adverseData[tableView].groups] : [];
          let filteredArms = [...filterDuplicateArms(registryArms, periodArms)];
          copyArms(formatArmIds(filteredArms));
        }
        break;
    }

    closeCreateModal()
  }

  const onDeleteGroup = (applyAll) => {
    let tempData = { ...adverseData };

    Object.keys(tempData).forEach(registry => {
      if (tableView === registry || applyAll) {
        // delete serious adverse eventsvar displayGroups
        tempData[registry].serious_adverse_events.forEach(event => {
          let foundEventIndex = event.values.findIndex(value => value.registry_arm_id === selectedGroupType.registry_arm_id);
          if (foundEventIndex !== -1) event.values.splice(foundEventIndex, 1);
        })

        // delete non-serious adverse events
        tempData[registry].non_serious_adverse_events.forEach(event => {
          let foundEventIndex = event.values.findIndex(value => value.registry_arm_id === selectedGroupType.registry_arm_id);
          if (foundEventIndex !== -1) event.values.splice(foundEventIndex, 1);
        })

        // delete group
        let foundGroupIndex = tempData[registry].groups.findIndex(item => item.registry_arm_id === selectedGroupType.registry_arm_id);
        if (foundGroupIndex !== -1) tempData[registry].groups.splice(foundGroupIndex, 1);
      }
    })

    setAdverseData(tempData);
    closeDeleteModal();
  }

  const displayGroupRows = () => {
    let rows = [];

    schema.properties[tableView].groups.show_keys.forEach((key, i) => {
      let fieldSchema = schema.properties[tableView].groups.properties[key];

      rows.push(
        <tr key={`arm-row-${key}-${i}`}>
          <td
            className="header-column">
            {fieldSchema.title}
          </td>
          {displayGroups(fieldSchema, key, i)}
          {i === 0 && readOnly !== true &&
            <td
              className="text-center add-arm-action-col"
              rowSpan={3}>
              {showDropdown
                ?
                <PrimeField
                  schema={{
                    type: "dropdown",
                    placeholder: "Select arm",
                    orientation: "vertical",
                    items: adverseArmOptions
                  }}
                  readOnly={readOnly}
                  value={null}
                  onChange={e => {
                    let foundItem = adverseArmOptions.find(item => item.id === e)
                    openCreateModal(foundItem)
                  }} />
                : <i
                  onClick={() => setShowDropdown(true)}
                  className="fal fa-plus-circle add-arm-icon" />
              }
            </td>
          }
        </tr>
      )
    })

    return rows;
  }

  const displayGroups = (fieldSchema, key, rowIndex) => {
    return adverseData?.[tableView]?.groups?.map((arm, colIndex) => {
      return (
        <Arm 
          key={arm.registry_arm_id}
          rowIndex={rowIndex}
          colIndex={colIndex}
          fieldKey={key}
          fieldSchema={fieldSchema}
          arm={arm}
          arms={adverseData?.[tableView]?.groups || []}
          openDeleteModal={openDeleteModal}
          schema={schema}
          adverseData={adverseData}
          setAdverseData={setAdverseData} />
      )
    })
  }

  const openCreateModal = (arm) => {
    setSelectedGroupType(arm)
    setShowCreateModal(true);
    setShowDropdown(false);
  }

  const closeCreateModal = () => {
    setSelectedGroupType(null);
    setShowCreateModal(false);
    setShowDropdown(false);
  }

  const openDeleteModal = (arm) => {
    setSelectedGroupType(arm);
    setShowDeleteModal(true);
  }

  const closeDeleteModal = () => {
    setSelectedGroupType(null);
    setShowDeleteModal(false);
  }

  return (
    <>
      {displayGroupRows()}
      <ResultsTableCreateModal
        id="ae-create-arm"
        header="Create Group"
        onSubmit={onAddGroup}
        showModal={showCreateModal}
        setShowModal={closeCreateModal} />
      <ResultsTableDeleteModal
        id="ae-delete-arm"
        header="Delete Group"
        onSubmit={onDeleteGroup}
        showModal={showDeleteModal}
        setShowModal={closeDeleteModal} />
    </>
  );
};

export default Arms;