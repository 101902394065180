// TODO: change name to studiesApi b/c it contains hooks but is not a hook
import useGetApi from "../useGetApi"
import getUrl from "api/apiMap"
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"
import useMutateApi from "../useMutateApi"
import { useGetFavoriteStudies } from ".."

const useGetStudy = (id) => {
  return useGetApi(`${getUrl(apiEndpoints.studies)}${id}/`, queryKeys.studies.detail(Number(id)))
}

const useGetStudyHeader = (id) => {
  return useGetApi(`${getUrl(apiEndpoints.studies)}${id}/header`, queryKeys.studies.header(id))
}

// { body: { study_id } }
const usePostStudy = (id) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(apiEndpoints.studies),
    invalidateQueries: [queryKeys.studies.detail(Number(id))],
    // successMsg: "Successfully created study. You will now be redirected...",
  })
}

// TODO: update with new studies api
const useGetStudyActivities = (studyId) => {
  return useGetApi(`${apiEndpoints.studies}${studyId}/activities`, queryKeys.studies.activities(studyId))
}

// { body }
const usePutStudy = () => {
  return useMutateApi({
    method: "put",
    apiEndpoint: getUrl(apiEndpoints.studies),
    invalidateQueries: [queryKeys.studies.all],
    successMsg: "Sucessfully updated study",
    // "Removed study from favorites"
    // "Added study to favorites"
  })
}

/** FAVORITE STUDIES */

// imported useGetFavoriteStudies

const usePostFavoriteStudy = (id) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: getUrl(`${apiEndpoints.studies}`),
    invalidateQueries: [queryKeys.studies.list(),queryKeys.favoriteStudies],
    successMsg: "Successfully updated favorite studies",
  })
}

export {
  useGetStudy,
  useGetStudyHeader,
  usePostStudy,
  useGetStudyActivities,
  usePutStudy,
  useGetFavoriteStudies,
  usePostFavoriteStudy,
}