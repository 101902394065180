/** Adobe Embed component that shows a full PDF view with all controls and annotations functionality */
import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import AdobeEmbedSdkClient from 'classes/AdobeEmbedSdkClient'
import Loader from 'components/Loading/Loading'
import './PdfFullWindow.scss'

// api
import { useUserInfo } from 'api/hooks'
import { useGetEnvVars } from 'api/hooks/enum'

const PdfFullWindow = ({
  divId = "pdf-div",
  selectedDocument,

  // callbacks:
  getServerUpdates, // when PDF preview loads
  onAnnotationEvent, // when user adds, deletes, or updates UI
  onAnnotationSelected, // when user selects annotation they may then modify
  onLoadPreview, // callback when get annotations
  selectedAnnotation,

  // styles:
  defaultViewMode = "FIT_WIDTH",
  wrapperStyle = {},
  style = {},
}) => {
  const { data: envVars } = useGetEnvVars()
  const { data: user } = useUserInfo()
  const [userProfile, setUserProfile] = useState(null)
  const [apis, setApis] = useState(null) // Adobe Embed SDK APIs
  // TODO: review if both annotationManager and annotationManagerRef needed?
  const [annotationManager, setAnnotationManager] = useState(null)
  const annotationManagerRef = useRef()
  const [appRenderDone, setAppRenderDone] = useState(false)
  const [initDone, setInitDone] = useState(false)
  const documentRef = useRef()
  documentRef.current = selectedDocument

  // ...get user profile, then init Adobe Embed
  React.useEffect(() => {
    if (envVars) {
      if (user) {
        if (userProfile) {
          initEmbedWithURL(userProfile)
        } else {
          const profile = {
            name: `${user.first_name} ${user.last_name}`,
            firstName: user.first_name,
            lastName: user.last_name,
            email: user.email,
          }
          setUserProfile(profile)
          initEmbedWithURL(profile)
        }
      }
    }
  }, [envVars, user])

  const updateUi = (values, uiAnnots) => {
    const {
      add,
      remove,
      update,
    } = values

    const annotationManager = annotationManagerRef.current

    const addPromise = new Promise((resolve, reject) => {
      if (add.length > 0) {
        // make sure comments come before replies, otherwise will get "Parent not found for given annotation..." error
        const addComments = add.filter((item) => item.motivation === 'commenting')
        const addReplies = add.filter((item) => item.motivation === 'replying')
        const addSorted = [...addComments, ...addReplies]
        if (addSorted.length > 0) {
          annotationManager.addAnnotations(addSorted)
            .then(() => {
              // console.log(`Added annotations`, {
              //   addSorted,
              // })
              resolve(addSorted)
            })
            .catch(error => {
              console.log(`ERROR adding annotations:`, {
                error,
                add: addSorted,
              })
              reject(error)
            })
        } else {
          resolve([])
        }
      } else {
        resolve([])
      }
    })

    const removePromise = new Promise((resolve, reject) => {
      if (remove.length > 0) {
        const annotationIds = remove.map((annot) => annot.id)
        const filters = { annotationIds }
        annotationManager.deleteAnnotations(filters)
          .then(() => {
            // console.log(`Deleted annotations`, {
            //   remove,
            //   filters,
            //   annotationIds,
            // })
            resolve(remove)
          })
          .catch(error => {
            console.log('ERROR deleting annotation', error)
            reject(error)
          })
      } else {
        resolve([])
      }
    })

    const updatePromise = new Promise((resolve, reject) => {
      if (update.length > 0) {
        update.forEach((servAnnot) => {
          // find same annotation on UI
          const uiAnnot = uiAnnots.find((annot) => annot.created === servAnnot.created && annot.creator.name === servAnnot.creator.name)
          const updateData = { ...servAnnot, id: uiAnnot.id }
          annotationManager.updateAnnotation(updateData)
            .then(() => {
              // console.log(`Updated annotation`, {
              //   uiAnnot,
              //   updateData,
              // })
              resolve(updateData)
            })
            .catch(error => {
              console.log(`ERROR updating annotations`, error)
              reject(error)
            })
        })
      } else {
        resolve([])
      }
    })

    return Promise.all([
      addPromise,
      removePromise,
      updatePromise,
    ])
  }

  const initEmbedWithURL = ({
    name,
    firstName,
    lastName,
    email,
  } = {}) => {
    let options = {
      embedMode: "FULL_WINDOW",
      // enableLinearization: true, // show first page quickly
      // enableFormFilling: false, // true by default
      defaultViewMode,
      showDisabledSaveButton: true,

      // annotations:
      enableAnnotationAPIs: true,
      showAnnotationTools: true,
      includePDFAnnotations: true, // displays existing annotations
      enableAnnotationEvents: true, // false by default
    }

    const viewSDKClient = new AdobeEmbedSdkClient(envVars.REACT_APP_ADOBE_EMBED_CLIENT_ID)
    viewSDKClient
      .ready()
      .then(() => {
        viewSDKClient.previewFile(divId, {
          url: selectedDocument?.document_file,
          fileName: selectedDocument?.name || ' ',
          fileId: selectedDocument?.file_id,
        }, options)
          .then((res) => {
            // res.executeCommand: ƒ ()

            viewSDKClient.registerAppRenderingDone(({ type, data }) => {
              // console.log('app rendering done')
              setAppRenderDone(true)
            })

            res.getAPIs()
              .then((apis) => {
                setApis(apis)
              })

            /** status callback */

            // viewSDKClient.registerStatusApiHandler()

            res.getAnnotationManager()
              .then((annotationManager) => {

                annotationManagerRef.current = annotationManager
                setAnnotationManager(annotationManager)

                // when selectedDocument opens, add latest server annotations to ui
                const updateUiWithServData = (uiAnnots) => {
                  getServerUpdates(uiAnnots)
                    .then((updates) => {
                      updateUi({ ...updates, status: "SUCCESS" }, uiAnnots)
                        .then((values) => {
                          // [add, remove, update]
                          // console.log('promises resolved', values)
                          setInitDone(true)
                        })
                    })
                }

                annotationManager.getAnnotations()
                  .then((uiAnnots) => {
                    updateUiWithServData(uiAnnots)
                    if (onLoadPreview) onLoadPreview(uiAnnots, selectedDocument);
                  })

                // update UI when any annotation selected
                // adding an annotation to the ui triggers SELECTED event
                viewSDKClient.registerSelectedEvent(({ data }) => {
                  annotationManager.getAnnotations()
                    .then((uiAnnotations) => {
                      onAnnotationSelected(data, uiAnnotations)
                        .then((response) => {
                          // response = {add, remove, update}
                          updateUi(response, uiAnnotations)
                        })
                    })
                })

                viewSDKClient.registerAnnotEvent(
                  (evt) => {
                    annotationManager.getAnnotations()
                      .then((uiAnnotations) => {
                        onAnnotationEvent(evt.type, evt.data, uiAnnotations)
                          .then((response) => {
                            // response = {add, remove, update}
                            updateUi(response, uiAnnotations)
                          })
                      })
                  }
                )

                /** save callback */

                // automatically saves to avoid "Saving..." message
                viewSDKClient.registerMockAutoSaveApi()
              })

            // not sure what TOGGLE_COMMENTING does, if anything:
            res.executeCommand('TOGGLE_COMMENTING', true)
              // .then(() => console.log('TOGGLE_COMMENTING SUCCESS'))
              .catch((error) => console.log('TOGGLE_COMMENTING', error))
          })

        if (name || email) {
          viewSDKClient.registerProfile({
            name,
            firstName,
            lastName,
            email,
          })
        }
      })
  }

  // annotationManager
  // functionality to select existing annotation and shift focus to it - not currently used:
  const selectAnnotation = (annotId) => {
    try {
      if (annotId) annotationManager.selectAnnotation(annotId)
    } catch (error) {
      console.log('selectAnnotation ERROR', {
        annotId,
        error,
      })
    }
  }

  useEffect(() => {
    if (selectedAnnotation) {
      selectAnnotation(selectedAnnotation)
    }
  }, [selectedAnnotation])

  useEffect(() => {
    if (annotationManager) {
      openCommentPanel()
    }
  }, [annotationManager])

  const openCommentPanel = () => {
    annotationManager.setConfig({ showCommentsPanel: true })
      // .then(() => console.log('openCommentPanel SUCCESS'))
      .catch(error => console.log('openCommentPanel', error))
  }

  return <div
    className="doc-pdf"
    style={wrapperStyle}
  >
    <div id={divId} className="document-pdf" style={style} />
    {initDone &&
      <div className="hide-undo-redo" />
    }
    {/* loader starts with solid background, is transparent when the app has loaded, and is finally removed when annotations have synced with server */}
    {!initDone && <Loader className={`pdf-full-win-loader${appRenderDone ? ' app-rendered' : ''}`} />}
  </div>
}

PdfFullWindow.propTypes = {
  divId: PropTypes.string,
  selectedDocument: PropTypes.shape({
    document_file: PropTypes.string,
    name: PropTypes.string,
    file_id: PropTypes.string.isRequired,
  }),
  getServerUpdates: PropTypes.func,
  onAnnotationEvent: PropTypes.func,
  onAnnotationSelected: PropTypes.func,
  onLoadPreview: PropTypes.func,
  selectedAnnotation: PropTypes.object,
  defaultViewMode: PropTypes.oneOf(["FIT_WIDTH", "FIT_PAGE"]),
  wrapperStyle: PropTypes.object,
  style: PropTypes.object,
}

export default PdfFullWindow