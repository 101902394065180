// utils
import { hideEupasProtocolFields, hideCtisProtocolFields } from './utils/utils';
import {
  objectClassNames,
  arrayClassNames
} from '../classes';

let hide = {
  "ui:options": {
    hide: true,
  }
}

export const secondaryAttributesUISchema = {
  protocol: {
    "ui:order": [
      "study_details_detailed_description",
      "study_description",
      "protocol_id",
      "study_eligibilty",
      "vendor",
      "vendor_contact",
      "acquired_from",
      "transferred_to",
      "unapproved_new_formulation_marketed_compound",
      "proprietary_name",
      "established_name",
      "product_manufactured",
      "compound_code",
      "generic_name",
      "brand_name",
      "primary_cro",
      "availability_expanded_access",
      "us_fda_regulated_drug_product",
      "us_fda_regulated_device_product",
      "study_oversight",
      "secondary_ids",
      "general",
      "study_scope",
      "contact_information",
      "study_type",
      "status",
      "study_timeline",
      "*"
    ],
    "ui:options": {
      title: "Study Details",
      classNames: {
        objectContainer: "rjs-gap-16",
        objectTitleContainer: "rjs-h1-container pt-24 pl-24 pr-24 pb-24 m-0",
        objectTitle: "rjs-h1",
        objectInnerContainer: "no-gap section-container"
      }
    },
    study_details_detailed_description: {
      "ui:options": {
        title: "Title",
        validations: [{
          regex: /^.{0,32000}$/,
          message: "Title must have no more than 32000 characters."
        }],
        label: false,
        classNames: {
          fieldContainer: "rjs-full pl-24 pr-24",
        },
      },
      "ui:widget": "textarea"
    },
    study_type: {
      "ui:options": {
        title: "Study type",
        placeholder: "Select Study Type",
        label: false,
        classNames: {
          fieldContainer: "rjs-full pl-24",
          field: "rjs-col-1-3",
        },
      }
    },
    protocol_id: {
      "ui:options": {
        label: false,
        required: true,
        title: "Study ID",
        placeholder: "Enter Unique Protocol ID",
        validations: [{
          regex: /^.{0,30}$/,
          message: "Unique Protocol ID must have no more than 30 characters."
        },],
        classNames: {
          fieldContainer: "rjs-full pl-24 pr-24 pt-16 pb-16",
          field: "rjs-col-1-3",
        },
      }
    },
    vendor: {
      "ui:options": {
        label: false,
        required: true,
        title: "Vendor",
        classNames: {
          fieldContainer: "w-1-2p pl-24 pr-8",
        },
      }
    },
    vendor_contact: {
      "ui:options": {
        label: false,
        required: true,
        title: "Vendor Contact",
        classNames: {
          fieldContainer: "w-1-2p pr-24 pl-8",
        },
      }
    },
    acquired_from: {
      "ui:options": {
        label: false,
        required: true,
        title: "Acquired from",
        classNames: {
          fieldContainer: "w-1-2p pl-24 pr-8",
        },
      }
    },
    transferred_to: {
      "ui:options": {
        label: false,
        required: true,
        title: "Transferred to",
        classNames: {
          fieldContainer: "w-1-2p pr-24 pl-8",
        },
      }
    },
    unapproved_new_formulation_marketed_compound: {
      "ui:options": {
        label: false,
        required: true,
        title: "Unapproved new formulation of a Marketed Compound",
        classNames: {
          fieldContainer: "w-1-2p  pl-24 pr-8",
        },
      }
    },
    proprietary_name: {
      "ui:options": {
        label: false,
        required: true,
        title: "Proprietary Name",
        classNames: {
          fieldContainer: "w-1-2p pr-24 pl-8",
        },
      }
    },
    established_name: {
      "ui:options": {
        label: false,
        required: true,
        title: "Established Name",
        classNames: {
          fieldContainer: "w-1-2p pl-24 pr-8",
        },
      }
    },
    product_manufactured: {
      "ui:options": {
        label: false,
        required: true,
        title: "Product Manufactured",
        classNames: {
          fieldContainer: "w-1-2p  pr-24 pl-8",
        },
      }
    },
    compound_code: {
      "ui:options": {
        label: false,
        required: true,
        title: "Compound Code",
        classNames: {
          fieldContainer: "w-1-2p  pl-24 pr-8",
        },
      }
    },
    generic_name: {
      "ui:options": {
        label: false,
        required: true,
        title: "Generic Name",
        classNames: {
          fieldContainer: "w-1-2p  pr-24 pl-8",
        },
      }
    },
    brand_name: {
      "ui:options": {
        label: false,
        required: true,
        title: "Brand Name",
        classNames: {
          fieldContainer: "w-1-2p  pl-24 pr-8",
        },
      }
    },
    primary_cro: {
      "ui:options": {
        label: false,
        required: true,
        title: "Primary CRO",
        classNames: {
          fieldContainer: "w-1-2p  pr-24 pl-8",
        },
      }
    },
    availability_expanded_access: {
      "ui:options": {
        label: false,
        required: true,
        title: "Availability of Expanded Access",
        classNames: {
          fieldContainer: "w-1-2p  pl-24 pr-8",
        },
      }
    },
    us_fda_regulated_drug_product: {
      "ui:options": {
        label: false,
        required: true,
        title: "U.S. FDA-regulated Drug Product",
        classNames: {
          fieldContainer: "w-1-2p pr-24 pl-8",
        },
      }
    },
    us_fda_regulated_device_product: {
      "ui:options": {
        label: false,
        required: true,
        title: "U.S. FDA-regulated Device Product ",
        classNames: {
          fieldContainer: "rjs-full pl-24 pr-8 pr-24 pb-16",
          field: "rjs-col-1-2",
        },
      }
    },
    secondary_ids: {
      "ui:options": {
        title: "Secondary ID's",
        itemTitle: "Identifier",
        addButtonTitle: "Add Secondary ID",
        label: false,
        addable: true,
        removable: true,
        orderable: true,
        classNames: {
          ...arrayClassNames.primary,
          fieldContainer: "rjs-full pr-24 pb-24 pl-24",
        }
      },
      items: {
        secondary_id_type: {
          "ui:options": {
            label: false,
            title: "Identifier Type",
            validations: [],
            classNames: arrayClassNames.primary
          },
        },
        id: {
          "ui:options": {
            label: false,
            title: "data-schema", // data-schema title will pull title from data schema
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          }
        },
        domain: {
          "ui:options": {
            label: false,
            title: "data-schema", // data-schema title will pull title from data schema
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          }
        },
      },
    },
    general: {
      "ui:options": {
        title: "General",
        classNames: objectClassNames.primary
      },
      countries: {
        "ui:options": {
          title: "Countries:",
          type: "string",
          placeholder: "--Select--",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
      },
      indication: {
        "ui:options": {
          label: false,
          title: "Indication",
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        }
      },
      remarks: {
        "ui:options": {
          label: false,
          title: "Remarks",
          classNames: {
            fieldContainer: "rjs-full",
          },
        },
        "ui:widget": "textarea"
      },
      eudract_number: {
        "ui:options": {
          label: false,
          title: "EudraCT Number",
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        }
      },
      intervention_type: {
        "ui:options": {
          title: "Intervention Type",
          placeholder: "--Select--",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
      },
      product_id: {
        "ui:options": {
          label: false,
          title: "Product ID",
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        }
      },
      product_name: {
        "ui:options": {
          label: false,
          title: "Product Name",
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        }
      },
      program_id: {
        "ui:options": {
          label: false,
          title: "Program ID",
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        }
      },
      program_name: {
        "ui:options": {
          label: false,
          title: "Program Name",
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        }
      },
      therapeutic: {
        "ui:options": {
          label: false,
          title: "Therapeutic Area",
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        }
      },
      countries_manufactured: {
        "ui:options": {
          title: "Country DP Manufactured",
          placeholder: "--Select--",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
        "ui:widget": "Multiselect"
      },
      age_group: {
        "ui:options": {
          title: "Age Group",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
      },
      minimum_age_value: {
        "ui:options": {
          title: "Age Group",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
      },
      minimum_age_value: {
        "ui:options": {
          title: "Minimum Age (Years)",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
      },
      maximum_age_value: {
        "ui:options": {
          title: "Maximum Age (Years)",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
      }
    },
    status: {
      "ui:options": {
        title: "Status",
        classNames: {
          objectTitle: "rjs-h3",
          fieldContainer: "rjs-full",
          objectContainer: "pl-24 pr-24 pb-13"
        }
      },
      study_status: {
        "ui:options": {
          label: false,
          title: "Study Status",
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        }
      },
      record_status: {
        "ui:options": {
          title: "Record Status",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        }
      },
    },
    milestone_dates: {
      "ui:options": {
        title: "Milestone Dates",
        classNames: objectClassNames.primary
      },
      conception_date: {
        "ui:options": {
          title: "Conception Date",
          label: false,
          classNames: {
            fieldContainer: "rjs-full",
            field: "rjs-col-1-3",
          }
        },
        "ui:widget": "date"
      },
      pra: {
        "ui:options": {
          title: "PRA",
          classNames: {
            objectTitle: "rjs-h3",
          }
        },
        baseline: {
          "ui:options": {
            title: "Baseline",
            label: false,
          },
          "ui:widget": "date"
        },
        forecast: {
          "ui:options": {
            title: "Forecast",
            label: false,
          },
          "ui:widget": "date"
        },
        actual: {
          "ui:options": {
            title: "Actual",
            label: false,
          },
          "ui:widget": "date"
        },
      },
      fpi: {
        "ui:options": {
          title: "Study Start Date",
          classNames: {
            objectTitle: "rjs-h3"
          }
        },
        baseline: {
          "ui:options": {
            title: "Baseline",
            label: false,
          },
          "ui:widget": "date"
        },
        forecast: {
          "ui:options": {
            title: "Forecast",
            label: false,
          },
          "ui:widget": "date"
        },
        actual: {
          "ui:options": {
            title: "Actual",
            label: false,
          },
          "ui:widget": "date"
        },
      },
      lpo: {
        "ui:options": {
          title: "LPO/LPLV/SCD",
          classNames: {
            objectTitle: "rjs-h3"
          }
        },
        baseline: {
          "ui:options": {
            title: "Baseline",
            label: false,
          },
          "ui:widget": "date"
        },
        forecast: {
          "ui:options": {
            title: "Forecast",
            label: false,
          },
          "ui:widget": "date"
        },
        actual: {
          "ui:options": {
            title: "Actual",
            label: false,
          },
          "ui:widget": "date"
        },
      },
      pcd: {
        "ui:options": {
          title: "PCD",
          classNames: {
            objectTitle: "rjs-h3"
          }
        },
        baseline: {
          "ui:options": {
            title: "Baseline",
            label: false,
          },
          "ui:widget": "date"
        },
        forecast: {
          "ui:options": {
            title: "Forecast",
            label: false,
          },
          "ui:widget": "date"
        },
        actual: {
          "ui:options": {
            title: "Actual",
            label: false,
          },
          "ui:widget": "date"
        },
      },
      csr: {
        "ui:options": {
          title: "CSR Completed",
          classNames: {
            objectTitle: "rjs-h3",
          }
        },
        baseline: {
          "ui:options": {
            title: "Baseline",
            label: false,
          },
          "ui:widget": "date"
        },
        forecast: {
          "ui:options": {
            title: "Forecast",
            label: false,
          },
          "ui:widget": "date"
        },
        actual: {
          "ui:options": {
            title: "Actual",
            label: false,
          },
          "ui:widget": "date"
        },
      },
      fsa: {
        "ui:options": {
          title: "FSA",
          classNames: {
            objectTitle: "rjs-h3",
          }
        },
        baseline: {
          "ui:options": {
            title: "Baseline",
            label: false,
          },
          "ui:widget": "date"
        },
        forecast: {
          "ui:options": {
            title: "Forecast",
            label: false,
          },
          "ui:widget": "date"
        },
        actual: {
          "ui:options": {
            title: "Actual",
            label: false,
          },
          "ui:widget": "date"
        },
      },
      recruitment_end_date: {
        "ui:options": {
          title: "Recruitment End Date",
          classNames: {
            objectTitle: "rjs-h3",
          }
        },
        forecast: {
          "ui:options": {
            title: "Forecast",
            label: false,
          },
          "ui:widget": "date"
        },
        actual: {
          "ui:options": {
            title: "Actual",
            label: false,
          },
          "ui:widget": "date"
        },
      },
      first_patient_enrolled_date: {
        "ui:options": {
          title: "First Patient Enrolled",
          classNames: {
            objectTitle: "rjs-h3",
          }
        },
        forecast: {
          "ui:options": {
            title: "Forecast",
            label: false,
          },
          "ui:widget": "date"
        },
        actual: {
          "ui:options": {
            title: "Actual",
            label: false,
          },
          "ui:widget": "date"
        },
      },
    },
    secondary_attributes: {
      "ui:options": {
        title: "Secondary Attributes",
        classNames: objectClassNames.primary
      },
      internal: {
        "ui:options": {
          title: "Internal",
          classNames: {
            objectTitle: "rjs-h2",
            fieldContainer: "rjs-full",
          }
        },
        first_product_indication_approval_date_in_any_country: {
          "ui:options": {
            title: "First Product and Indication Approval Date in Any Country",
            classNames: {
              objectTitle: "rjs-h3",
            }
          },
          baseline: {
            "ui:options": {
              title: "Baseline",
              label: false,
            },
            "ui:widget": "date"
          },
          forecast: {
            "ui:options": {
              title: "Forecast",
              label: false,
            },
            "ui:widget": "date"
          },
          actual: {
            "ui:options": {
              title: "Actual",
              label: false,
            },
            "ui:widget": "date"
          },
        },
        product_indication_first_approval_date: {
          "ui:options": {
            title: "Product and Indication First Approval Date",
            classNames: {
              objectTitle: "rjs-h3",
            }
          },
          baseline: {
            "ui:options": {
              title: "Baseline",
              label: false,
            },
            "ui:widget": "date"
          },
          forecast: {
            "ui:options": {
              title: "Forecast",
              label: false,
            },
            "ui:widget": "date"
          },
          actual: {
            "ui:options": {
              title: "Actual",
              label: false,
            },
            "ui:widget": "date"
          },
        },
        fcsr_live: {
          "ui:options": {
            title: "FCSR Live Forecast",
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-full",
              field: "rjs-col-1-3",
            }
          },
          forecast: {
            "ui:options": {
              label: false,
              removeLabel: true,
            },
            "ui:widget": "date"
          },
        },
        fpi_live: {
          "ui:options": {
            title: "FPI Live Forecast",
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-col-1-3",
            }
          },
          forecast: {
            "ui:options": {
              label: false,
              removeLabel: true,
            },
            "ui:widget": "date"
          },
        },
        fsa_live: {
          "ui:options": {
            title: "FSA Live Forecast",
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-col-1-3",
            }
          },
          forecast: {
            "ui:options": {
              label: false,
              removeLabel: true,
            },
            "ui:widget": "date"
          },
        },
        lpi_live: {
          "ui:options": {
            title: "LPI Live Forecast",
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-col-1-3",
            }
          },
          forecast: {
            "ui:options": {
              label: false,
              removeLabel: true,
            },
            "ui:widget": "date"
          },
        },
        lpo_live: {
          "ui:options": {
            title: "LPO Live Forecast",
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-col-1-3",
            }
          },
          forecast: {
            "ui:options": {
              label: false,
              removeLabel: true,
            },
            "ui:widget": "date"
          },
        },
        lpop_live: {
          "ui:options": {
            title: "LPOP (PCD) Live Forecast",
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-col-1-3",
            }
          },
          forecast: {
            "ui:options": {
              label: false,
              removeLabel: true,
            },
            "ui:widget": "date"
          },
        },
        pca_live: {
          "ui:options": {
            title: "PCA Live Forecast",
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-col-1-3",
            }
          },
          forecast: {
            "ui:options": {
              label: false,
              removeLabel: true,
            },
            "ui:widget": "date"
          },
        },
        pra_live: {
          "ui:options": {
            title: "PRA Live Forecast",
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-col-1-3",
            }
          },
          forecast: {
            "ui:options": {
              label: false,
              removeLabel: true,
            },
            "ui:widget": "date"
          },
        },
        tlgs_live: {
          "ui:options": {
            title: "TLGs Live Forecast",
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-col-1-3",
            }
          },
          forecast: {
            "ui:options": {
              label: false,
              removeLabel: true,
            },
            "ui:widget": "date"
          },
        },
        first_patient_consented_live: {
          "ui:options": {
            title: "First Patient Consented - Live Forecast",
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-col-1-3",
            }
          },
          forecast: {
            "ui:options": {
              label: false,
              removeLabel: true,
            },
            "ui:widget": "date"
          },
        },
        first_patient_consented: {
          "ui:options": {
            title: "First Patient Consented",
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-col-1-3",
            }
          },
          forecast: {
            "ui:options": {
              title: "Forecast",
              label: false,
            },
            "ui:widget": "date"
          },
          actual: {
            "ui:options": {
              title: "Actual",
              label: false,
            },
            "ui:widget": "date"
          },
        },
        lpi: {
          "ui:options": {
            title: "LPI",
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-col-1-3",
            }
          },
          forecast: {
            "ui:options": {
              title: "Forecast",
              label: false,
            },
            "ui:widget": "date"
          },
          actual: {
            "ui:options": {
              title: "Actual",
              label: false,
            },
            "ui:widget": "date"
          },
        },
        pca: {
          "ui:options": {
            title: "PCA",
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-col-1-3",
            }
          },
          forecast: {
            "ui:options": {
              title: "Forecast",
              label: false,
            },
            "ui:widget": "date"
          },
          actual: {
            "ui:options": {
              title: "Actual",
              label: false,
            },
            "ui:widget": "date"
          },
        },
        tlgs: {
          "ui:options": {
            title: "TLGs",
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-col-1-3",
            }
          },
          forecast: {
            "ui:options": {
              title: "Forecast",
              label: false,
            },
            "ui:widget": "date"
          },
          actual: {
            "ui:options": {
              title: "Actual",
              label: false,
            },
            "ui:widget": "date"
          },
        },
        gov_approval: {
          "ui:options": {
            title: "Governance Approval",
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-col-1-3",
            }
          },
          forecast: {
            "ui:options": {
              title: "Planned",
              label: false,
            },
            "ui:widget": "date"
          },
          actual: {
            "ui:options": {
              title: "Actual",
              label: false,
            },
            "ui:widget": "date"
          },
        },
        alternate_id: {
          "ui:options": {
            title: "Alternate ID",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
        },
        affiliate_val: {
          "ui:options": {
            title: "Affiliate",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
        },
        /* countries_product_authorized_in: {
        "ui:options": {
          title: "Countries product is authorized in",
          placeholder: "--Select--",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
        "ui:widget": "Multiselect"
      }, */
        primary_site_location: {
          "ui:options": {
            title: "Primary Site Location",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
        },
        redacted_documents: {
          "ui:options": {
            title: "Status of Redacted Documents For Submission to CTG",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
        },
        result_preparation: {
          "ui:options": {
            label: false,
            title: "Status of Received TFLs for Study Results Preparation",
            classNames: {
              fieldContainer: "rjs-col-2-3",
              field: "rjs-col-1-2",
            }
          },
        },
        lsfd: {
          "ui:options": {
            title: "LSFD",
            classNames: {
              fieldContainer: "rjs-col-1-3",
              objectTitle: "rjs-h3",
            }
          },
          baseline: {
            "ui:options": {
              removeLabel: true,
              label: false,
            },
            "ui:widget": "date"
          }
        },
        long_term_follow_up_lplv: {
          "ui:options": {
            title: "Long Term Follow Up LPLV",
            classNames: {
              fieldContainer: "rjs-col-1-3",
              objectTitle: "rjs-h3",
            }
          },
          baseline: {
            "ui:options": {
              removeLabel: true,
              label: false,
            },
            "ui:widget": "date"
          }
        },
        in_scope_for_data_sharing: {
          "ui:options": {
            title: "In Scope for Data Sharing",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        journal_publication: {
          "ui:options": {
            label: false,
            title: "Journal Publication",
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
        },
        publication_plan: {
          "ui:options": {
            label: false,
            title: "Publication Plan",
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
        },
        document_redaction_required: {
          "ui:options": {
            title: "Document Redaction Required",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        redaction_required_for_publication: {
          "ui:options": {
            title: "Redaction Required for Publication",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        product_approval_date_on_before_lpo: {
          "ui:options": {
            title: "Product Approval Date is on or before LPO",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        product_indication_approved_in_any_country: {
          "ui:options": {
            title: "Product and Indication is Approved in Any Country",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        product_discontinued: {
          "ui:options": {
            title: "Product Discontinued",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        product_discontinued_date: {
          "ui:options": {
            title: "Product Discontinued Date",
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-full",
            }
          },
          baseline: {
            "ui:options": {
              title: "Baseline",
              label: false,
            },
            "ui:widget": "date"
          },
          forecast: {
            "ui:options": {
              title: "Forecast",
              label: false,
            },
            "ui:widget": "date"
          },
          actual: {
            "ui:options": {
              title: "Actual",
              label: false,
            },
            "ui:widget": "date"
          },
        },
      },
      usa: {
        "ui:options": {
          title: "USA",
          classNames: {
            objectTitle: "rjs-h3",
          }
        },
        is_product_fda_approved: {
          "ui:options": {
            title: "Product and Indication FDA Approved",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        product_indication_approved_by_fda_and_ema_on_or_before_lpo: {
          "ui:options": {
            title: "Product and Indication is approved by FDA and EMA on or before LPO date",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-2-3"
            }
          },
          "ui:widget": "radio",
        },
        fda_product_approval_date: {
          "ui:options": {
            title: "Product and Indication FDA Approval Date",
            classNames: {
              objectTitle: "rjs-h3",
            }
          },
          baseline: {
            "ui:options": {
              removeLabel: true,
              label: false,
            },
            "ui:widget": "date"
          },
        },
        ctg_delay_certification_submitted_date: {
          "ui:options": {
            title: "CTG Delayed Certification Submitted Date",
            classNames: {
              objectTitle: "rjs-h3",
            }
          },
          baseline: {
            "ui:options": {
              title: "Baseline",
              label: false,
              removeLabel: true,
            },
            "ui:widget": "date"
          },
        },
        // us_ind_ide: {
        //   "ui:options": {
        //     title: "US FDA IND/IDE",
        //     label: false,
        //     styles: {
        //       field: {
        //         width: "50%",
        //         marginTop: 12
        //       },
        //       widgetContainer: widgetContainer.lg
        //     }
        //   },
        //   "ui:widget": "radio",
        // },
        us_imp: {
          "ui:options": {
            title: "US IMP",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        // us_ide: {
        //   "ui:options": {
        //     title: "US IDE",
        //     label: false,
        //     styles: {
        //       field: {
        //         width: "50%",
        //         marginTop: 12
        //       },
        //       widgetContainer: widgetContainer.lg
        //     }
        //   },
        //   "ui:widget": "radio",
        // },
        section_801: {
          "ui:options": {
            title: "Section 801",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        is_ctg_delay_certification_submitted: {
          "ui:options": {
            title: "CTG Delayed Certification Filed",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        manufactured_in_us: {
          "ui:options": {
            title: "Manufactured in or Exported from the US",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        maintenance_amendment_required: {
          "ui:options": {
            title: "Maintenance/Amendment Required",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        ctg_maintenance_required: {
          "ui:options": {
            title: "CTG Maintenance Required",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        protocol_amendment_required: {
          "ui:options": {
            title: "Protocol Amendment Required",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        /* product_discontinuation_date_on_before_lpo: {
          "ui:options": {
            title: "Product Discontinuation Date is on or before LPO",
            label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
          },
          "ui:widget": "radio",
        }, */
        FDA_approval_of_drug_for_the_indication_received_after_1_year_of_PCD: {
          "ui:options": {
            title: "FDA Approval Of Drug For The Indication Received After 1 Year Of PCD",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        /* LPO_1_year_after_the_date_of_filing_of_delayed_results: {
          "ui:options": {
            title: "LPO is 1 Year After The Date Of Filing Of Delayed Results?",
            label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
          },
          "ui:widget": "radio",

        }, */
        product_fda_any: {
          "ui:options": {
            title: "Product is FDA Approved for any use",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        /* is_drug_fda_approved_any_use_before_PCD: {
          "ui:options": {
            title: "Drug is FDA Approved For Any Use Before PCD",
            label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
          },
          "ui:widget": "radio",
        }, */
        /* is_drug_fda_approved_any_use_before_LPO: {
          "ui:options": {
            title: "Drug is FDA approved for any use before LPO",
            label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
          },
          "ui:widget": "radio",
        }, */
        /* is_fda_approval_of_drug_for_the_indication_received_after_1_year_of_LPO: {
          "ui:options": {
            title: "FDA Approval Of Drug For The Indication is Received After 1 Year Of LPO",
            label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
          },
          "ui:widget": "radio",
        }, */
        /* product_and_indication_approved_by_FDA_before_PCD: {
          "ui:options": {
            title: "Product and Indication is approved by FDA before PCD",
            label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
          },
          "ui:widget": "radio",
        }, */
        /* product_for_the_indication_studied_approved_by_FDA_before_LPO: {
          "ui:options": {
            title: "Product for the Indication studied is approved by FDA before LPO",
            label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
          },
          "ui:widget": "radio",
        }, */
        /* SCD_1_year: {
          "ui:options": {
            title: "Is SCD 1 year after the date of delayed results filing?",
            label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
          },
          "ui:widget": "radio",
        }, */
        /* SCD_2_year: {
          "ui:options": {
            title: "Is SCD 2 years after the date of delayed results filing?",
            label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
          },
          "ui:widget": "radio",
        },
        is_pcd: {
          "ui:options": {
            title: "Is PCD=SCD?",
            label: false,
            disabled: true,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
          },
          "ui:widget": "radio",
        },*/
        type_results: {
          "ui:options": {
            title: "Type of Results",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          }
        },
        /* product_clinical: {
          "ui:options": {
            title: "Product is FDA approved for indication studied in the clinical trial",
            label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
          },
          "ui:widget": "radio",
        }, */
        fda_market_approval: {
          "ui:options": {
            title: "FDA Market Approval",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
        },
        manufacturer: {
          "ui:options": {
            title: "Manufacturer",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
        },
        fda_product_approval_date_any_use: {
          "ui:options": {
            title: "FDA Product Approval Date for any use",
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-col-1-3"
            }
          },
          baseline: {
            "ui:options": {
              removeLabel: true,
              label: false,
            },
            "ui:widget": "date"
          }
        },
        ctg_interim_results_required: {
          "ui:options": {
            title: "CTG Interim Results Required",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        /* sponsor_is_manufacturer: {
          "ui:options": {
            title: "Sponsor = Manufacturer ?",
            label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
          },
          "ui:widget": "radio",
        }, */
        /* expanded_access_already_registered: {
          "ui:options": {
            title: "Is the expanded access record already registered?",
            label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
          },
          "ui:widget": "radio",
        }, */
        /* expanded_access_available_at_registration_of_act: {
          "ui:options": {
            title: "Is expanded access available at the time of registration of the applicable clinical study?",
            label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
          },
          "ui:widget": "radio",
        }, */
        fda_product_approval_date: {
          "ui:options": {
            title: "Product and Indication FDA Approval Date",
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-full",
            }
          },
          baseline: {
            "ui:options": {
              title: "Baseline",
              label: false,
            },
            "ui:widget": "date"
          },
          forecast: {
            "ui:options": {
              title: "Forecast",
              label: false,
            },
            "ui:widget": "date"
          },
          actual: {
            "ui:options": {
              title: "Actual",
              label: false,
            },
            "ui:widget": "date"
          },
        },
        product_indication_latest_approval_date_fda_ema: {
          "ui:options": {
            title: "Product and Indication Latest Approval Date (FDA & EMA)",
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-full",
            }
          },
          baseline: {
            "ui:options": {
              title: "Baseline",
              label: false,
            },
            "ui:widget": "date"
          },
          forecast: {
            "ui:options": {
              title: "Forecast",
              label: false,
            },
            "ui:widget": "date"
          },
          actual: {
            "ui:options": {
              title: "Actual",
              label: false,
            },
            "ui:widget": "date"
          },
        },
        delayed_certification_filed_date: {
          "ui:options": {
            title: "Delayed Certification Filed Date",
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-full",
            }
          },
          baseline: {
            "ui:options": {
              title: "Baseline",
              label: false,
            },
            "ui:widget": "date"
          },
          forecast: {
            "ui:options": {
              title: "Forecast",
              label: false,
            },
            "ui:widget": "date"
          },
          actual: {
            "ui:options": {
              title: "Actual",
              label: false,
            },
            "ui:widget": "date"
          },
        },
        /*    expanded_access_availability_date: {
            "ui:options": {
              title: "Expanded Access Availability Date",
              styles: {
                field: {
                  width: "100%",
                  marginBottom: 16
                },
                objectTitle: {
                  fontWeight: "bold"
                },
                objectInnerContainer: {
                  ...containerFlexStart,
                  // width: noMarginWidgetContainer.md.width * 4
                },
              }
            },
            baseline: {
              "ui:options": {
                title: "Baseline",
                label: false,
                styles: {
                field: widgetContainerMarginRight.md,
                widgetContainer: widgetContainerMarginRight.md
                }
              },
              "ui:widget": "date"
            },
            forecast: {
              "ui:options": {
                title: "Forecast",
                label: false,
                styles: {
                field: widgetContainerMarginRight.md,
                widgetContainer: widgetContainerMarginRight.md
                }
              },
              "ui:widget": "date"
            },
            actual: {
              "ui:options": {
                title: "Actual",
                label: false,
                styles: {
                field: widgetContainerMarginRight.md,
                widgetContainer: widgetContainerMarginRight.md
                }
              },
              "ui:widget": "date"
            },
          }, */
      },
      europe: {
        "ui:options": {
          title: "Europe",
          classNames: {
            objectTitle: "rjs-h3",
          }
        },
        eu_marketing_authorization: {
          "ui:options": {
            title: "EU Marketing Authorization",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        ema_cta_date: {
          "ui:options": {
            title: "EMA CTA Approval Date",
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-full",
            }
          },
          baseline: {
            "ui:options": {
              title: "Baseline",
              label: false,
            },
            "ui:widget": "date"
          },
          forecast: {
            "ui:options": {
              title: "Forecast",
              label: false,
            },
            "ui:widget": "date"
          },
          actual: {
            "ui:options": {
              title: "Actual",
              label: false,
            },
            "ui:widget": "date"
          },
        },
        ema_cta: {
          "ui:options": {
            title: "EMA CTA Approved",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        pass_category: {
          "ui:options": {
            label: false,
            title: "PASS Category",
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          }
        },
        pediatric_study: {
          "ui:options": {
            title: "Pediatric Study",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        eu_pip: {
          "ui:options": {
            title: "EU PIP",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        date_of_latest_approval: {
          "ui:options": {
            title: "Date Of Latest Approval",
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-col-1-3"
            }
          },
          baseline: {
            "ui:options": {
              title: "Baseline",
              removeLabel: true,
              label: false,
            },
            "ui:widget": "date"
          }
        },
        part_of_an_agreed_pip: {
          "ui:options": {
            title: "Part Of An Agreed PIP",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        /* marketing_holder: {
          "ui:options": {
            title: "Is the Sponsor of the Study the Same as the EU Marketing Authorization Holder?",
            label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
          },
          "ui:widget": "radio",
        }, */
        date_of_cta: {
          "ui:options": {
            title: "EU CTA Submitted Date",
            classNames: {
              objectTitle: "rjs-h3",
            }
          },
          baseline: {
            "ui:options": {
              removeLabel: true,
              label: false,
            },
            "ui:widget": "date"
          },
        },
        date_of_results: {
          "ui:options": {
            title: "EU Results Submitted Date",
            classNames: {
              objectTitle: "rjs-h3",
            }
          },
          baseline: {
            "ui:options": {
              removeLabel: true,
              label: false,
            },
            "ui:widget": "date"
          },
        },
        /* eu_marketing_authorization_application_date: {
          "ui:options": {
            title: "EU Marketing Authorization Application Date",
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-full",
            }
          },
          baseline: {
            "ui:options": {
              title: "Baseline",
              label: false,
              styles: {
              field: widgetContainerMarginRight.md,
              widgetContainer: widgetContainerMarginRight.md
              }
            },
            "ui:widget": "date"
          },
          forecast: {
            "ui:options": {
              title: "Forecast",
              label: false,
              styles: {
              field: widgetContainerMarginRight.md,
              widgetContainer: widgetContainerMarginRight.md
              }
            },
            "ui:widget": "date"
          },
          actual: {
            "ui:options": {
              title: "Actual",
              label: false,
              styles: {
              field: widgetContainerMarginRight.md,
              widgetContainer: widgetContainerMarginRight.md
              }
            },
            "ui:widget": "date"
          },
        }, */
        eu_subsequent_marketing_authorization_application_date: {
          "ui:options": {
            title: "EU Subsequent Marketing Authorization Application Date",
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-full",
            }
          },
          baseline: {
            "ui:options": {
              title: "Baseline",
              label: false,
            },
            "ui:widget": "date"
          },
          forecast: {
            "ui:options": {
              title: "Forecast",
              label: false,
            },
            "ui:widget": "date"
          },
          actual: {
            "ui:options": {
              title: "Actual",
              label: false,
            },
            "ui:widget": "date"
          },
        },
        marketing_authorization_holder_for_the_product_under_investigation: {
          "ui:options": {
            title: "Marketing Authorization Holder For The Product Under Investigation",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
        },
        pass: {
          "ui:options": {
            title: "Pass Status",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        eudract_interim_results_required: {
          "ui:options": {
            title: "EU CTR Interim Results Required",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        trial_ongoing_in_eu_eea_on_after_jan312025: {
          "ui:options": {
            title: "Will trial be ongoing in EU/EEA on or after 31 Jan 2025",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        sponsor_opted_for_submission_per_eu_directive: {
          "ui:options": {
            title: "Has Sponsor opted for submission per EU Directive?",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        cta_submission_per_eu_directive_complete: {
          "ui:options": {
            title: "Is CTA submission as per EU Directive complete?",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        intermediate_result_analysis_specified_in_protocol: {
          "ui:options": {
            title: "Has intermediate result analysis been specifed in Protocol?",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        date_of_initial_application_cta_submitted_in_eu_eea: {
          "ui:options": {
            title: "Date of Initial Application (CTA) submitted/to be submitted in EU/EEA",
            classNames: {
              objectTitle: "rjs-h3",
            }
          },
          baseline: {
            "ui:options": {
              removeLabel: true,
              label: false,
            },
            "ui:widget": "date"
          },
        },
        lpo_interim_analysis: {
          "ui:options": {
            title: "LPO for Interim Analysis",
            classNames: {
              objectTitle: "rjs-h3",
            }
          },
          baseline: {
            "ui:options": {
              removeLabel: true,
              label: false,
            },
            "ui:widget": "date"
          },
        },
        lpo_eu_eea: {
          "ui:options": {
            title: "LPO in EU/EEA",
            classNames: {
              objectTitle: "rjs-h3",
            }
          },
          baseline: {
            "ui:options": {
              title: "Baseline",
              label: false,
            },
            "ui:widget": "date"
          },
          forecast: {
            "ui:options": {
              title: "Forecast",
              label: false,
            },
            "ui:widget": "date"
          },
          actual: {
            "ui:options": {
              title: "Actual",
              label: false,
            },
            "ui:widget": "date"
          },
        },
      },
      germany: {
        "ui:options": {
          title: "Germany",
          classNames: {
            objectTitle: "rjs-h3",
          }
        },
        confirmatory_study: {
          "ui:options": {
            title: "Confirmatory Study",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        germany_cta_date: {
          "ui:options": {
            title: "Germany CTA Approval Date",
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-full",
            }
          },
          baseline: {
            "ui:options": {
              title: "Baseline",
              label: false,
            },
            "ui:widget": "date"
          },
          forecast: {
            "ui:options": {
              title: "Forecast",
              label: false,
            },
            "ui:widget": "date"
          },
          actual: {
            "ui:options": {
              title: "Actual",
              label: false,
            },
            "ui:widget": "date"
          },
        },
        germany_cta: {
          "ui:options": {
            title: "Germany CTA Approved",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        mma_smaa_approval_date: {
          "ui:options": {
            title: "New Product Authorization/Variation/Rejection Date",
            classNames: {
              objectTitle: "rjs-h3",
              fieldContainer: "rjs-full",
            }
          },
          baseline: {
            "ui:options": {
              title: "Baseline",
              label: false,
            },
            "ui:widget": "date"
          },
          forecast: {
            "ui:options": {
              title: "Forecast",
              label: false,
            },
            "ui:widget": "date"
          },
          actual: {
            "ui:options": {
              title: "Actual",
              label: false,
            },
            "ui:widget": "date"
          },
        },
        product_authorized_in_eu_germany: {
          "ui:options": {
            title: "Product Authorized In EU or Germany",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        /* product_authorized_in_EU_or_Germany_before_LPO: {
          "ui:options": {
            title: "Product Authorized In EU or Germany Before LPO",
            label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
          },
          "ui:widget": "radio",
        }, */
        product_authorized_in_eu_germany_date: {
          "ui:options": {
            title: "Product Authorization Date In EU or Germany (Earliest)",
            classNames: {
              objectTitle: "rjs-h3",
            }
          },
          baseline: {
            "ui:options": {
              removeLabel: true,
              label: false,
            },
            "ui:widget": "date"
          }
        },
        /* product_authorized_in_eu_germany_before_jan272022: {
          "ui:options": {
            title: "Product authorized in EU/Germany before 27 Jan,2022 ",
            label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
          },
          "ui:widget": "radio",
        }, */
        /* product_authorized_eu_germany_before_july272021: {
          "ui:options": {
            title: "Product authorized in EU/Germany before 27 July 2021",
            label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
          },
          "ui:widget": "radio",
        }, */
        mma_smaa_submitted_to_germany: {
          "ui:options": {
            title: "Is trial included in a new product authorization/variation/rejection",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        /* product_authorization_date_in_eu_germany_before_jan012011: {
          "ui:options": {
            title: "Is product authorization date in EU/Germany before 01 Jan 2011? ",
            label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
          },
          "ui:widget": "radio",
        }, */
      },
      spain: {
        "ui:options": {
          title: "Spain",
          classNames: {
            objectTitle: "rjs-h3",
          }
        },
        /* aemps_authorization_date: {
          "ui:options": {
            title: "AEMPS Authorization Date",
            classNames: {
              objectTitle: "rjs-h3",
            }
          },
          baseline: {
            "ui:options": {
              removeLabel: true,
              label: false,
            },
            "ui:widget": "date"
          }
        }, */
        aemps_authorization: {
          "ui:options": {
            title: "AEMPS Authorization/Approved by AEMPS",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        post_authorization_study: {
          "ui:options": {
            title: "Post-Authorization Study",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        rec_authorization: {
          "ui:options": {
            title: "REC Authorization",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        rec_authorization_date: {
          "ui:options": {
            title: "REC Authorization Date",
            classNames: {
              objectTitle: "rjs-h3",
            }
          },
          date_baseline_rec: {
            "ui:options": {
              removeLabel: true,
              label: false,
            },
            "ui:widget": "date"
          }
        },
        registered_on_reec: {
          "ui:options": {
            title: "Registered on REeC",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        with_prospective_follow_up_drugs: {
          "ui:options": {
            title: "Prospective Follow-up Drugs Study",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        results_published_in_any_scientific_journal_or_other_media: {
          "ui:options": {
            title: "Results Already Published in Any Scientific Journal or Other Media",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        involves_medicinal_product: {
          "ui:options": {
            title: "Study involves a medicinal product?",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        date_of_publication: {
          "ui:options": {
            title: "Date of Publication of Results",
            classNames: {
              objectTitle: "rjs-h3",
            }
          },
          baseline: {
            "ui:options": {
              removeLabel: true,
              label: false,
            },
            "ui:widget": "date"
          },
        },
      },
      netherlands: {
        "ui:options": {
          title: "Netherlands",
          classNames: {
            objectTitle: "rjs-h3",
            fieldContainer: "rjs-full",
          }
        },
        involves_dutch_people: {
          "ui:options": {
            title: "Involves Dutch Participants or Researchers",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        /* study_protocol_submitted_to_ccmo_on_after_mar152002: {
          "ui:options": {
            title: "Is study protocol submitted to CCMO for assessment on or after March 15,2002?",
            label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
          },
          "ui:widget": "radio",
        }, */
      },
      mexico: {
        "ui:options": {
          title: "Mexico",
          classNames: {
            objectTitle: "rjs-h3",
            fieldContainer: "rjs-full",
          }
        },
        cofepris_approval: {
          "ui:options": {
            title: "COFEPRIS Approval",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        cofepris_approval_date: {
          "ui:options": {
            title: "COFEPRIS Approval Date",
            classNames: {
              objectTitle: "rjs-h3",
            }
          },
          baseline: {
            "ui:options": {
              removeLabel: true,
              label: false,
            },
            "ui:widget": "date"
          }
        },
      },
      south_korea: {
        "ui:options": {
          title: "South Korea",
          classNames: {
            objectTitle: "rjs-h3",
            fieldContainer: "rjs-full",
          }
        },
        study_funded_by_korean_ministry_of_health_welfare: {
          "ui:options": {
            title: "Is study funded by Korean Ministry of Health and Welfare?",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        study_registered_on_cris: {
          "ui:options": {
            title: "Is study registered on CRIS (Clinical Research Information Service - primary registry of South Korea)?",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        /* study_approved_by_mfds_before_2019: {
          "ui:options": {
            title: "Is the study approved by Korean Ministry of Food and Drug(MFDS) before 2019?",
            label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
          },
          "ui:widget": "radio",
        }, */
      },
      japan: {
        "ui:options": {
          title: "Japan",
          classNames: {
            objectTitle: "rjs-h3",
            fieldContainer: "rjs-full",
          }
        },
        bioequivalence_study: {
          "ui:options": {
            title: "Bioequivalence Study",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        product_approved_in_any_country: {
          "ui:options": {
            title: "Product is Approved In Any Country",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        /* product_approved_in_any_country_before_lpo: {
          "ui:options": {
            title: "Product is Approved In Any Country Before LPO?",
            label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
          },
          "ui:widget": "radio",
        }, */
        /* submission: {
          "ui:options": {
            title: "Submission of clinical trial notification before September 1, 2020",
            label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
          },
          "ui:widget": "radio",
        }, */
        date_of_approval: {
          "ui:options": {
            title: "Date of Approval in Any Country",
            classNames: {
              objectTitle: "rjs-h3",
            }
          },
          baseline: {
            "ui:options": {
              removeLabel: true,
              label: false,
            },
            "ui:widget": "date"
          },
        },
        trial_notification_submission_date: {
          "ui:options": {
            title: "Date of submission of clinical trial notification",
            classNames: {
              objectTitle: "rjs-h3",
            }
          },
          baseline: {
            "ui:options": {
              removeLabel: true,
              label: false,
            },
            "ui:widget": "date"
          },
        },
      },
      united_kingdom: {
        "ui:options": {
          title: "United Kingdom",
          classNames: {
            objectTitle: "rjs-h3",
            fieldContainer: "rjs-full",
          }
        },
        /* study_approved: {
          "ui:options": {
            title: "Study approved by UKs research ethics committee before 01 January 2021",
            label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
          },
          "ui:widget": "radio",
        }, */
        clinical_trial: {
          "ui:options": {
            title: "Clinical trial registration available publicly on a public register",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        /* ctimp_submitted_on_after_jan012022: {
          "ui:options": {
            title: "CTIMP submitted on or after 01 January 2022?",
            label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
          },
          "ui:widget": "radio",
        }, */
        is_research_study: {
          "ui:options": {
            title: "Is this a research study ?",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
        /* final_report_submitted_before_sep152021: {
          "ui:options": {
            title: "Has the final report been submitted before September 15, 2021?",
            label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
          },
          "ui:widget": "radio",
        }, */
        final_report_submission_date: {
          "ui:options": {
            title: "Date of final report submission",
            classNames: {
              objectTitle: "rjs-h3",
            }
          },
          baseline: {
            "ui:options": {
              removeLabel: true,
              label: false,
            },
            "ui:widget": "date"
          }
        },
        study_approval_uk_research_ethics_committee_date: {
          "ui:options": {
            title: "Date of study approval by UK’s Research Ethics Committee",
            classNames: {
              objectTitle: "rjs-h3",
            }
          },
          baseline: {
            "ui:options": {
              removeLabel: true,
              label: false,
            },
            "ui:widget": "date"
          }
        },
        ctimp_submission_date: {
          "ui:options": {
            title: "Date of CTIMP submission",
            classNames: {
              objectTitle: "rjs-h3",
            }
          },
          baseline: {
            "ui:options": {
              removeLabel: true,
              label: false,
            },
            "ui:widget": "date"
          }
        },
      },
      australia_and_newzealand: {
        "ui:options": {
          title: "Australia and New Zealand",
          classNames: {
            objectTitle: "rjs-h3",
            fieldContainer: "rjs-full",
          }
        },
        study_registered_on_anzctr: {
          "ui:options": {
            title: "Is study registered on ANZCTR?",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
      },
      //       /* canada: {
      //         "ui:options": {
      //           title: "Canada",
      //           styles: {
      //             field: fieldF,
      //             objectInnerContainer,
      //             objectTitle: containerSubtitle,
      //           }
      //         },
      //         study_authorized_in_canada_on_after_may292013: {
      //           "ui:options": {
      //             title: "Is the study authorized in Canada on or after 29 May 2013?",
      //             label: false,
      //             styles: {
      //               field: {
      //                 width: "50%",
      //                 marginTop: 12
      //               },
      //               widgetContainer: widgetContainer.lg
      //             }
      //           },
      //           "ui:widget": "radio",
      //         },
      //       }, */
      /* portugal: {
        "ui:options": {
          title: "Portugal",
          styles: {
            field: fieldF,
            objectInnerContainer,
            objectTitle: containerSubtitle,
          }
        },
         *//* study_submitted_to_nca_infarmed_on_after_jan012017: {
"ui:options": {
title: "Is the study submitted to NCA (INFARMED) on or after 01 January 2017?",
label: false,
styles: {
field: {
width: "50%",
marginTop: 12
},
widgetContainer: widgetContainer.lg
}
},
"ui:widget": "radio",
}, *//*
                                                                  *//* study_submitted_to_nca_infarmed_before_dec052016: {
"ui:options": {
title: "Is the study submitted to NCA (INFARMED) before 05 December 2016?",
label: false,
styles: {
field: {
width: "50%",
marginTop: 12
},
widgetContainer: widgetContainer.lg
}
},
"ui:widget": "radio",
}, *//*
                                                           }, */
      south_africa: {
        "ui:options": {
          title: "South Africa",
          classNames: {
            objectTitle: "rjs-h3",
            fieldContainer: "rjs-full",
          }
        },
        study_approved_by_south_african_research_ethics_committee: {
          "ui:options": {
            title: "Is the study approved by a South African research ethics committee?",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
      },
      switzerland: {
        "ui:options": {
          title: "Switzerland",
          classNames: {
            objectTitle: "rjs-h3",
            fieldContainer: "rjs-full",
          }
        },
        /* study_submitted_to_swiss_ethics_committee_before_jan012014: {
          "ui:options": {
            title: "Is the study submitted to Swiss ethics committee before 01 January 2014?",
            label: false,
                classNames: {
                  fieldContainer: "rjs-col-1-3"
                }
          },
          "ui:widget": "radio",
        }, */
        study_received_approval_from_swiss_ethics_committee: {
          "ui:options": {
            title: "Has the study received approval from Swiss ethics committee?",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
      },
      /* turkey: {
        "ui:options": {
          title: "Turkey",
          styles: {
            field: fieldF,
            objectInnerContainer,
            objectTitle: containerSubtitle,
          }
        },
         *//* study_information_submitted_to_turkish_agency_on_after_dec012019: {
"ui:options": {
title: "Is the study information submitted to Turkish Agency on or after 01 Dec 2019?",
label: false,
styles: {
field: {
width: "50%",
marginTop: 12
},
widgetContainer: widgetContainer.lg
}
},
"ui:widget": "radio",
}, *//*
                                                                 study_information_submitted_to_turkish_agency_in_after_2016: {
                                                                   "ui:options": {
                                                                     title: "Is the study information submitted to Turkish Agency in 2016 or after?",
                                                                     label: false,
                                                                     styles: {
                                                                       field: {
                                                                         width: "50%",
                                                                         marginTop: 12
                                                                       },
                                                                       widgetContainer: widgetContainer.lg
                                                                     }
                                                                   },
                                                                   "ui:widget": "radio",
                                                                 },
                                                               }, */
      china: {
        "ui:options": {
          title: "China",
          classNames: {
            objectTitle: "rjs-h3",
            fieldContainer: "rjs-full",
          }
        },
        new_drug_application_submitted_to_chinese_authority: {
          "ui:options": {
            title: "Is the new drug application submitted to Chinese authority?",
            label: false,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          },
          "ui:widget": "radio",
        },
      },
    },
    protocol_amendments: {
      "ui:options": {
        title: "Protocol Amendments",
        classNames: objectClassNames.primary
      },
      protocol_amendment: {
        "ui:options": {
          addButtonTitle: "Add Protocol Amendment",
          arrayItemTitle: "Protocol Amendment",
          label: false,
          addable: true,
          removable: true,
          orderable: true,
          showIndex: true,
          classNames: arrayClassNames.primary
        },
        items: {
          number: {
            "ui:options": {
              title: "Number",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          type: {
            "ui:options": {
              title: "Type",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            }
          },
          date: {
            "ui:options": {
              title: "Date",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-3"
              }
            },
            "ui:widget": "date"
          },
          reason: {
            "ui:options": {
              title: "Reason",
              label: false,
              classNames: {
                fieldContainer: "rjs-full"
              }
            },
            "ui:widget": "textarea"
          },
          comments: {
            "ui:options": {
              title: "Comments",
              label: false,
              classNames: {
                fieldContainer: "rjs-full"
              }
            },
            "ui:widget": "textarea"
          },
        }
      },
    },
    contact_information: {
      "ui:order": [
        "responsible_party",
        "sponsor",
        "investigator_name",
        "investigator_official_title",
        "investigator_affiliation",
        "*"
      ],
      "ui:options": {
        classNames: {
          objectTitle: "rjs-h3",
          objectContainer: "pl-24 pr-24",
          fieldContainer: "rjs-full"
        }
      },
      responsible_party: {
        ...hide
      },
      sponsor: {
        "ui:options": {
          title: "Sponsor",
          placeholder: "Select Sponsor",
          required: true,
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-3",
          }
        },
        "ui:widget": "Lookahead",
      },
      investigator_name: {
        ...hide
      },
      investigator_official_title: {
        ...hide
      },
      investigator_affiliation: {
        ...hide
      },
      collaborators: {
        ...hide
      },
      central_contacts: {
        ...hide
      },
      central_contacts_backup: {
        ...hide
      },
      overall_study_officials: {
        ...hide
      },
      scientific_advice: {
        ...hide
      },
      ...hideEupasProtocolFields.contact_information,
    },
    study_oversight: {
      "ui:options": {
        classNames: {
          fieldContainer: "rjs-full",
          objectContainer: "pl-24 pr-24"
        }
      },
      us_ind: {
        "ui:options": {
          classNames: {
            objectTitle: "rjs-h3",
            fieldContainer: "rjs-full",
          }
        },
        us_fda_ind_ide: {
          "ui:options": {
            title: "U.S. FDA IND/IDE:",
            required: true,
            label: false,
            classNames: {
              fieldContainer: "rjs-full"
            }
          },
          "ui:widget": "radio",
        },
        fda_center: {
          ...hide
        },
        ind_ide_number: {
          ...hide
        },
        ind_serial_number: {
          ...hide
        },
        availability_of_expanded_access: {
          ...hide
        },
        expanded_access_record: {
          ...hide
        },
      },
      us_drug: {
        ...hide
      },
      us_device: {
        ...hide
      },
      human_subjects_protection_review: {
        ...hide
      },
      data: {
        ...hide
      },
      fda: {
        ...hide
      },
    },
    study_timeline: {
      "ui:options": {
        classNames: {
          objectTitle: "rjs-h3",
          fieldContainer: "rjs-full",
          objectContainer: "pl-24 pr-24"
        }
      },
      recruitment: {
        "ui:options": {
          label: false,
          classNames: {
            fieldContainer: "rjs-full"
          }
        },
        recruitment_status: {
          "ui:options": {
            title: "Overall Recruitment Status",
            placeholder: "Select Status",
            label: false,
            required: true,
            classNames: {
              fieldContainer: "rjs-col-1-3"
            }
          }
        },
        study_stopped: {
          ...hide
        },
      },
      record_verification_date: {
        ...hide
      },
      milestone_dates: {
        ...hide
      },
      expanded: {
        ...hide
      },
      ...hideEupasProtocolFields.study_timeline,
    },
    study_scope: {
      "ui:order": ["study_phase", "primary_purpose", "*"],
      "ui:options": {
        classNames: {
          objectTitle: "rjs-h3",
          fieldContainer: "rjs-full",
          objectContainer: "pl-24 pr-24"
        }
      },
      patient_registry: {
        ...hide
      },
      follow_up_duration: {
        ...hide
      },
      duration_type: {
        ...hide
      },
      additional_patient_registry_data: {
        ...hide
      },
      primary_purpose: {
        ...hide
      },
      study_phase: {
        "ui:options": {
          title: "Study Phase",
          placeholder: "Select Study Phase",
          //errorMessage: "Phase cannot be N/A for a study of a U.S. FDA-regulated drug",
          tooltip: "Use N/A for trials that do not involve drug or biologic products",
          label: false,
          required: true,
          orientation: "vertical",
          classNames: {
            fieldContainer: "rjs-col-1-3"
          }
        },
        "ui:widget": "radio",
      },
      interventional_study_model: {
        ...hide
      },
      model_description: {
        ...hide
      },
      number_of_arms: {
        ...hide
      },
      masking: {
        ...hide
      },
      masking_description: {
        ...hide
      },
      allocation: {
        ...hide
      },
      enrollment_number: {
        ...hide
      },
      enrollment_type: {
        ...hide
      },
      enrollment_type_obs: {
        ...hide
      },
      checkbox: {
        ...hide
      },
      observational_study_model: {
        ...hide
      },
      time_perspective: {
        ...hide
      },
      biospecimen: {
        ...hide
      },
      number_of_subjects: {
        ...hide
      },
      number_of_cohorts: {
        ...hide
      },
      selection: {
        ...hide
      },
      ...hideEupasProtocolFields.study_scope,
      ...hideCtisProtocolFields.study_scope,
    },
    study_eligibilty: {
      "ui:order": ["accept_healthy_volunteers", "is_clinical_study", "age_limits", "*"],
      "ui:options": {
        classNames: {
          objectContainer: "pl-24 pr-24",
          objectTitle: "rjs-h3",
          fieldContainer: "rjs-full"
        }
      },
      sex: {
        ...hide
      },
      gender_based: {
        ...hide
      },
      age_limits: {
        "ui:options": {
          title: "Age Limits",
          label: false,
          classNames: {
            objectTitle: "rjs-h3",
            fieldContainer: "rjs-full",
          }
        },
        minimum: {
          "ui:options": {
            classNames: {
              fieldContainer: "rjs-col-1-2",
            }
          },
          minimum_age_type: {
            "ui:options": {
              title: "Minimum:",
              placeholder: "--Select--",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-2",
              }
            },
          },
          minimum_age_value: {
            "ui:options": {
              title: "Enter Minimum Age",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-2",
              }
            },
          },
        },
        maximum: {
          "ui:options": {
            classNames: {
              fieldContainer: "rjs-col-1-2",
            }
          },
          maximum_age_type: {
            "ui:options": {
              title: "Maximum: ",
              placeholder: "--Select--",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-2",
              }
            },
          },
          maximum_age_value: {
            "ui:options": {
              title: "Enter Maximum Age",
              label: false,
              classNames: {
                fieldContainer: "rjs-col-1-2",
              }
            },
          }
        }
      },
      accept_healthy_volunteers: {
        "ui:options": {
          title: "Healthy Volunteers",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-2",
          }
        },
        "ui:widget": "radio",
      },
      is_clinical_study: {
        "ui:options": {
          title: "Is this a clinical study?",
          label: false,
          classNames: {
            fieldContainer: "rjs-col-1-2",
          }
        },
        "ui:widget": "radio",
      },
      eligibility_criteria: {
        ...hide
      },
      study_population_description: {
        ...hide
      },
      sampling_method: {
        ...hide
      },
      population_under_study: {
        ...hide
      },
      additional_patient_information: {
        ...hide
      },
      is_patients_followed_up: {
        ...hide
      },
      follow_up_description: {
        ...hide
      },
      study_carried_with_established_data_source: {
        ...hide
      },
      sources_of_data: {
        ...hide
      },
      registered_data_sources: {
        ...hide
      },
      unregistered_data_sources: {
        ...hide
      },
      other_data_source: {
        ...hide
      },
      principle_inclusion_criteria: {
        ...hide
      },
      principle_exlusion_criteria: {
        ...hide
      },
    },
    study_description: {
      ...hide
    },
    eupas_study_type: {
      ...hide
    },
    eudract_number: {
      ...hide
    },
    other_study_type: {
      ...hide
    },
    study_identification: {
      ...hide
    },
    study_arms: {
      ...hide
    },
    study_locations: {
      ...hide
    },
    study_ipd: {
      ...hide
    },
    study_outcome_measures: {
      ...hide
    },
    study_reference: {
      ...hide
    },
    ctis_form: {
      ...hide
    },
    mscs: {
      ...hide
    },
  },
}