import { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useShallow } from 'zustand/react/shallow'

// components
import PrimeField from 'components/PrimeField/PrimeField';
import ArmActions from '../../../components/ArmActions/ArmActions';
import { Button } from 'components-design-system';
import SubjectJoinReason from './SubjectJoinReason';
import CellValidation from '../../../components/CellValidation/CellValidation';
import ProductCard from '../ProductCard/ProductCard';

// context
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';

const Arm = ({
  schema,
  arm,
  arms,
  rowIndex,
  colIndex,
  fieldKey,
  handleLeft,
  handleRight,
  handleDelete,
  handleChange,
  onDeleteProduct = {},
  onOpenProduct = {},
  onAddReason = {},
  onChangeReason = {},
  onDeleteReason = {},
  type = "period",
  periodIndex
}) => {
  const [showActions, setShowActions] = useState(false)

  const { ref: visibleRef, inView: inViewport } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  });

  const {
    readOnly
  } = useAuthoringViewStore(
    useShallow(state => ({ readOnly: state.readOnly }))
  )

  let isManualOverride = fieldKey === "title" || fieldKey === "description"

  const renderFields = () => {
    if (fieldKey === "products") {
      let products = arm?.products || []
      return (
        <>
          <div style={{ display: 'flex', flexDirection: "column", gap: 4 }}>
            {products.map((product, i) => {
              return (
                <ProductCard
                  key={`${arm.id}-product-${i}`}
                  index={i}
                  arm={arm}
                  product={product}
                  readOnly={readOnly}
                  showActions={showActions}
                  onOpenProduct={onOpenProduct}
                  onDeleteProduct={onDeleteProduct} />
              )
            })}
          </div>
          {readOnly !== true &&
            <Button
              size="sm"
              variant="primary-dashed"
              style={{
                marginTop: 16
              }}
              onClick={() => onOpenProduct({ arm })}>
              <i
                className="fal fa-plus-circle"
                style={{
                  marginTop: 2,
                  marginRight: 8
                }} />
              Add Product
            </Button>
          }
        </>
      )
    } else if (fieldKey === "joined_reason_details") {
      let reasons = arm?.joined_reason_details || []

      return (
        <>
          <div>
            {reasons.map((reason, reasonIndex) => {
              return (
                <SubjectJoinReason
                  key={arm.id + colIndex + rowIndex + reasonIndex}
                  arm={arm}
                  reason={reason}
                  onChangeReason={(key, val) => onChangeReason(key, val, colIndex, reasonIndex)}
                  onDeleteReason={() => onDeleteReason(colIndex, reasonIndex)} />
              )
            })}
          </div>
          {readOnly !== true &&
            <Button
              size="sm"
              variant="primary-dashed"
              style={{
                marginTop: 16
              }}
              onClick={() => onAddReason({ arm, armIndex: colIndex })}
            >
              <i
                className="fal fa-plus-circle"
                style={{
                  marginTop: 2,
                  marginRight: 8
                }} />
              Add Reason
            </Button>
          }
        </>
      )
    } else if (isManualOverride) {
      return (
        <PrimeField
          schema={{
            ...schema,
            style: { width: "100%" },
            size: "small"
          }}
          disabled={true}
          readOnly={readOnly}
          value={arm?.[fieldKey]}
          onChange={val => handleChange(val, arm, fieldKey)} />
      )
    } else {
      return (
        <>
          <PrimeField
            schema={{
              ...schema,
              style: { width: "100%" },
              size: "small"
            }}
            readOnly={readOnly}
            value={arm[fieldKey]}
            onChange={val => handleChange(val, arm, fieldKey)} />
          {
            fieldKey === "type" && arm.type === "Other" && (
              <PrimeField
                schema={{
                  type: "text",
                  placeholder: "Please enter type",
                  style: { marginTop: 6 }
                }}
                readOnly={readOnly}
                value={arm.other_type}
                onChange={val => handleChange(val, arm, "other_type")}
              />
            )
          }
        </>
      )
    }
  }
  if (arm === null) {
    return <td />
  }

  return (
    <td
      ref={visibleRef}
      className="text-left"
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}>
      {inViewport ? (
        <>
          {renderFields()}
          {readOnly !== true && (
            <ArmActions
              arms={arms}
              arm={arm}
              colIndex={colIndex}
              showActions={showActions}
              handleLeft={rowIndex === 0 ? () => handleLeft(colIndex) : null}
              handleRight={rowIndex === 0 ? () => handleRight(colIndex) : null}
              canDelete={rowIndex === 0 ? true : false}
              handleDeleteArm={handleDelete}
              jpath={`pf-arm-${type}-${arm.id}-${fieldKey}`}
              source={"participant-flow"}
              manualOverride={isManualOverride}
              periodIndex={periodIndex} />
          )}
          <CellValidation
            table="participants"
            errorKey={`results_participant_flow_participant_flow_table_global_arms_${colIndex + 1}_${fieldKey}`} />
        </>
      ) : (
        <div style={{ padding: 10 }} />
      )}
    </td>
  );
};

export default Arm;