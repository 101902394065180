// TODO: update to new user preset api
import React, { useState, useEffect } from 'react'

// Components
import Preset from './Preset'
import PresetFooterActions from './PresetFooterActions/PresetFooterActions'

import './UserPresets.scss'

const UserPresets = ({
  presets,
  activePreset,
  agGridApi,
  onLoad,
  setDefaultPreset,
  onResetPreset,
  onOpenModal,
}) => {
  return <div className="user-presets">
    {presets.length > 0 ?
      <div className="preset-items-wrap">
        {presets.map((preset, i) => {
          return <Preset
            key={i}
            type="user"
            index={i}
            agGridApi={agGridApi}
            setDefaultPreset={setDefaultPreset}
            onLoad={onLoad}
            onOpenModal={onOpenModal}
            preset={preset}
            isActive={activePreset?.id === preset.id} />
        })}
      </div>
      : <div className="user-no-presets">
        You do not have any saved presets
      </div>}
    <PresetFooterActions
      onOpenModal={onOpenModal}
      disableSave={activePreset !== null}
      onResetPreset={onResetPreset}
    />
  </div>
}

export default UserPresets