import React, { useEffect, useState } from 'react';

// components
import PrimeField from 'components/PrimeField/PrimeField';
import Label from 'components/Label/Label';
import Note from 'components/Note/Note';

// hoc 
import InputWrapper from '../../hoc/InputWrapper';
import { Button } from 'components-design-system'

const RJTypeUnitsAssigned = (props) => {
  let {
    id,
    carbonProps,
    invalid,
    invalidText,
    value,
    onChange,
    onFocus,
    onBlur,
  } = props;
  const [showField, setShowField] = useState(false);

  let type = "text";
  let variant, placeholder;
  if (carbonProps.type) type = carbonProps.type;

  if (invalid) {
    variant = "error"
  }

  useEffect(() => {
    if (value) setShowField(true);
  }, [])

  const handleButton = () => {
    if (showField) {
      onChange("")
      setShowField(false)
    } else {
      setShowField(true)
    }
  }

  return (
    <div>
      {showField === true &&
        <PrimeField
          schema={{
            id: id + "-field",
            className: "rjs-widget",
            style: carbonProps?.styles && carbonProps.styles.widget,
            type,
            disabled: carbonProps.disabled,
            variant,
          }}
          value={value || ""}
          onChange={e => onChange(e)}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      }
      {invalid && <Note type="error" text={invalidText} style={{ marginTop: 4 }} />}
      <Button
        variant="outlined"
        size="sm"
        style={{
          marginTop: 8,
          marginBottom: 8
        }}
        onClick={handleButton}>
        {showField ? "Delete Units - " : "Add Units + "}
      </Button>
      <Label>
        Note: Only use if units other than participants are assigned (e.g., eyes, lesions, implants)
      </Label>
    </div>
  );
};

export default InputWrapper(RJTypeUnitsAssigned);