import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import parse from "html-react-parser";
import { Dropdown } from 'antd';

// components
import CommentTag from 'components/CommentTag/CommentTag';
import RJCommentActions from '../RJCommentActions/RJCommentActions';

// hooks
import useAuthoringStore from '../../../hooks/stores/useAuthoringStore';
import { useUserPermissions } from 'api/hooks';
import {
  useGetAuthoringComments,
  usePutAuthoringComment,
  useFavoriteAuthoringComment,
  useResolveAuthoringComment
} from 'api/hooks/studies/useAuthoringApi';

const RJCommentItem = ({
  comment,
  index,
  handleEdit,
  handleDelete,
  showMenu = true,
}) => {
  const authoringId = useAuthoringStore(state => state.authoringId)
  const { data: permissions } = useUserPermissions()
  const favoriteAuthoringComment = useFavoriteAuthoringComment(authoringId)
  const resolveAuthoringComment = useResolveAuthoringComment(authoringId)
  const [currComment, setCurrComment] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    setCurrComment(comment);
  }, [comment])

  const handleFavorite = () => {
    let updatedComment = {
      ...currComment,
      favorite: !currComment.favorite
    }
    favoriteAuthoringComment({
      id: `${currComment.id}/favorite/`, body: {
        favorite: !currComment.favorite
      }
    })
  }

  const handleResolved = () => {
    if (permissions["study.authoringcomment.resolve"]) {
      let updatedComment = {
        resolved: !currComment.resolved
      }

      resolveAuthoringComment({ id: `${currComment.id}/resolve/`, body: updatedComment })
    }
  }

  const displayTags = (tags) => {
    return tags.map(tag => <CommentTag key={currComment.id + tag} type={tag} />)
  }

  if (!currComment) return <></>

  let initials = "";
  if (currComment?.author_fullname) {
    let names = currComment.author_fullname.split(" ");
    if (names[0] && names[0].length > 0) initials += names[0][0];
    if (names[1] && names[1].length > 0) initials += names[1][0];
    initials.toUpperCase();
  }

  const items = [
    {
      key: 'edit',
      label: "Edit",
      disabled: !permissions["study.authoringcomment.edit"] && comment.author_id !== permissions.user_id
    },
    {
      key: 'delete',
      label: "Delete",
      disabled: !permissions["study.authoringcomment.delete"] && comment.author_id !== permissions.user_id
    }
  ];

  const onClick = (e) => {
    if (e.key === "edit") {
      handleEdit(comment);
      setOpenMenu(false);
    } else if (e.key === "delete") {
      handleDelete(comment)
    }
  }

  return (
    <Container id={"rj-comment-item-container" + index}>
      <CommentInitial>
        {initials}
      </CommentInitial>
      <CommentHeader>
        <AuthorContainer>
          {currComment.author_fullname && currComment.author_fullname}
          <TagsContainer>
            {displayTags(currComment.tags)}
          </TagsContainer>
        </AuthorContainer>
        <ActionContainer>
          <FavoriteIcon
            className="fal fa-star"
            favorite={currComment.favorite}
            onClick={() => handleFavorite()} />
          <ResolvedIcon
            className="fal fa-check-circle"
            resolved={currComment.resolved}
            onClick={() => handleResolved()} />
          {permissions && showMenu !== false && <Dropdown
            menu={{
              items,
              onClick
            }}
            getPopupContainer={() => document.getElementById('rj-comment-item-container' + index)}
            placement="bottom"
            destroyPopupOnHide={true}
          >
            <MenuIcon
              onClick={() => setOpenMenu(!openMenu)}
              className="fal fa-ellipsis-h">
            </MenuIcon>
          </Dropdown>}
        </ActionContainer>
      </CommentHeader>
      <CommentSubheader>
        {currComment.date && moment(currComment.date).format("YYYY-MM-DD HH:mm:ss")}
      </CommentSubheader>
      <Comment>
        {currComment.text && parse(currComment.text)}
      </Comment>
    </Container>
  );
};

export default RJCommentItem;

const Container = styled.div`
  position: relative;
  padding: 8px 16px;
  background-color: #F4F4F4;
  border-radius: 8px;
  margin-bottom: 16px;
`

const CommentInitial = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 16px;
  background-color: #759BAD;
  position: absolute;
  top: -14px;
  left: -14px;
  color: #fff;
`

const TagsContainer = styled.div`
  margin-left: 16px;
`

const CommentHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
`

const AuthorContainer = styled.div`
  position: relative;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #393939;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 72px;
`

const CommentSubheader = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 0.32px;
  color: #393939;
  margin-bottom: 12px;
`

const Comment = styled.div`
  p {
    font-size: .85rem;
  }
`

const FavoriteIcon = styled.i`
  color: ${props => props.favorite && "#DBA51F"};
  font-weight: ${props => props.favorite && "800"};
  font-size: 12px;
  cursor: pointer;
  transition: all .3s;

  &:hover {
    color: #DBA51F;
    font-weight: 800;
  }
  `

const ResolvedIcon = styled.i`
  color: ${props => props.resolved && "#24a148"};
  font-weight: ${props => props.resolved && "800"};
  font-size: 12px;
  cursor: pointer;
  transition: all .3s;

  &:hover {
    color: #24a148;
    font-weight: 800;
  }
`

const MenuIcon = styled.i`
  position: relative;
  font-size: 14px;
  cursor: pointer;
  transition: all .3s;
`