import { useMemo } from 'react';
import { Button } from 'components-design-system';
import TextInput from 'components/TextInput/TextInput'
import { Select } from 'components-design-system'
import Field from 'containers/requests/components/Field/Field';

// apis
import { useGetRequestSecondaryIdTypes } from 'api/hooks/admin/useRequestSecondaryIdTypesApi';

// styles
import './SecondaryIdField.scss'

const SecondaryIdField = ({
  onChange,
  value,
  field,
  fieldKey,
  readOnly
}) => {
  const { data: secondaryIdTypes = [] } = useGetRequestSecondaryIdTypes()

  const onAdd = () => {
    onChange(fieldKey, [...value, { id_type: null, value: "" }])
  }

  const onDelete = (index) => {
    let newValue = [...value]
    newValue.splice(index, 1)
    onChange(fieldKey, newValue)
  }

  const onChangeSecondaryIds = (key, val, index) => {
    let newValue = [...value]
    newValue[index][key] = val
    onChange(fieldKey, newValue)
  }

  const options = useMemo(() => {
    return secondaryIdTypes.map(type => type.name)
  }, [secondaryIdTypes])

  return (
    <div className="request-secondary-ids">
      <div className="secondary-ids">
        {value?.map((fields, index) => {
          return (
            <div className="secondary-id" key={index}>
              <Field
                label={"Type"}
                className="id-type-selector"
              >
                <Select
                  options={options}
                  value={fields.id_type}
                  disabled={readOnly}
                  onChange={val => onChangeSecondaryIds("id_type", val, index)} />
              </Field>


              <Field
                label={"Identifier"}
                className="id-selector"
              >
                <TextInput
                  value={fields.value}
                  disabled={readOnly}
                  onChange={e => onChangeSecondaryIds("value", e.target.value, index)} />
              </Field>
              {readOnly !== true && <i className="fal fa-trash-alt delete-secondary-id" onClick={() => onDelete(index)} />}

            </div>
          )
        })}
      </div>
      {readOnly !== true && <Button
        onClick={onAdd}
        variant='primary-dashed'>
        Add Secondary ID
      </Button>}
    </div>
  );
};

export default SecondaryIdField;