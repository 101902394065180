import { useState, useEffect, useMemo } from 'react'
import dayjs from 'dayjs'
import { Tooltip } from 'antd'
import PrimeField from 'components/PrimeField/PrimeField'

// apis
import { useUserSettings } from 'api/hooks';

// context
import useWorkflowTracker from 'context/hooks/useWorkflowTracker';

// styles
import './State.scss'

const State = ({
  stateData,
  state,
  holdStateIndex,
  index,
  handleChange,
  isLast,
  userItems,
  type
}) => {
  const {
    isOnHold
  } = useWorkflowTracker()

  const title = useMemo(() => {
    if (state.is_current) return ' - Current State'
    switch (state.status) {
      case "Completed": {
        return ' - Completed'
      }
      case "Bypassed": {
        return ' - Bypassed'
      }
      default: {
        if (holdStateIndex !== -1 && holdStateIndex < index) {
          return ' - Lift hold to change dates of future states. Dates recalculated when hold is lifted'
        }
        return ""
      }
    }
  }, [state])

  const isDisabled = useMemo(() => {
    if (holdStateIndex !== -1) {
      return holdStateIndex <= index
    }

    return false
  }, [holdStateIndex])

  let startDate = state?.start_state_date;
  let endDate = stateData?.[index + 1]?.start_state_date
  // const goalDays = dayjs(endDate).diff(dayjs(startDate), "day")
  let goalDays = dayjs(endDate).diff(dayjs(startDate), "day")
  if(goalDays < 0) {
    // startDate = endDate
    goalDays = 0;
  }


  return (
    <div className="state-item">
      <div className="state-item-header">
        {state.state_title}&nbsp; <p className="state-status">{title}</p>
      </div>
      <div className="state-inputs">
        <div className="column">
          <div className="title">
            <>
              Start date
              {index > 0 &&
                <>
                  <br />(previous end date)
                </>
              }
            </>
          </div>
          <div style={{ width: 160 }}>
            <PrimeField
              schema={{
                type: "date",
              }}
              disabled={index !== 0 || isDisabled}
              onChange={(val) => handleChange("start_state_date", val, index)}
              value={startDate} />
          </div>
        </div>
        <div className="column">
          {!isLast && <>
            <div className="title">End date</div>
            <div style={{ width: 160 }}>
              <PrimeField
                schema={{
                  type: "date",
                }}
                disabled={isDisabled}
                onChange={(val) => handleChange("end_state_date", val, index)}
                value={endDate} />
            </div>
          </>}
        </div>
        <div className="column">
          {!isLast && <>
            <div className="title">Duration (Days)</div>
            <div className="content">
              <PrimeField
                schema={{
                  type: "number"
                }}
                min={0}
                disabled={isDisabled}
                onChange={(val) => handleChange("goal_days", val, index)}
                value={goalDays} />
            </div>
          </>}
        </div>
        <div className="column assignees-col" style={{ marginLeft: isLast && 251 }}>
          <div className="title">Assignee</div>
          <div className="content">
            <PrimeField
              schema={{
                type: "multiselect",
                placeholder: "Select assignee",
                items: userItems || []
              }}
              tooltip={userItems.length === 0 && `To make selection, first add team members to the ${type}`}
              disabled={isDisabled || userItems.length === 0}
              value={state.assigned_to_ids}
              onChange={(val) => handleChange("assigned_to_ids", val, index)} />
          </div>

        </div>
      </div>
    </div>
  );
};

export default State;