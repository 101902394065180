import { useState } from 'react';
import { useShallow } from 'zustand/react/shallow'
import { cloneDeep } from 'lodash';
import { v4 as uuid } from 'uuid'

// components
import { Button } from 'components-design-system';
import StatisticalAnalysisItem from '../StatisticalAnalysisItem/StatisticalAnalysisItem';

// context
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';

const StatisticalAnalysis = ({
  measure,
  outcomeData,
  setOutcomeData,
  schema
}) => {
  const {
    readOnly,
    isReviewerView
  } = useAuthoringViewStore(
    useShallow(state => ({ 
      readOnly: state.readOnly,
      isReviewerView: state.isReviewerView
    }))
  )

  const [showData, setShowData] = useState(false || isReviewerView || readOnly);

  const addStatisticalAnalysis = () => {
    let tempData = cloneDeep(outcomeData)
    let statisticalAnalysisId = uuid();

    Object.keys(tempData).forEach(registry => {
      let findOMIndex = tempData[registry].outcome_measures.findIndex(item => item.id === measure.id);
      if (findOMIndex !== -1) {
        let om = cloneDeep(tempData[registry].outcome_measures[findOMIndex]);
        let outcomeMeasureAnalysis = {
          id: statisticalAnalysisId,
        };
        let analysisSchema = schema.properties[registry].properties.outcome_measure.properties.outcome_measure_analysis.properties;
        Object.keys(analysisSchema).forEach(sectionKey => {
          Object.keys(analysisSchema[sectionKey].properties).forEach(rowKey => {
            Object.keys(analysisSchema[sectionKey].properties[rowKey].properties).forEach(fieldKey => {
              outcomeMeasureAnalysis[fieldKey] = null;
            })
          })
        })
        if (!om.outcome_measure_analysis) om.outcome_measure_analysis = []
        om.outcome_measure_analysis.push(outcomeMeasureAnalysis);

        if (!tempData[registry].outcome_measures[findOMIndex].outcome_measure_analysis) tempData[registry].outcome_measures[findOMIndex].outcome_measure_analysis = []
        tempData[registry].outcome_measures[findOMIndex].outcome_measure_analysis.push(outcomeMeasureAnalysis)
      }
    })

    setOutcomeData(tempData);
    setShowData(true);
  }

  return (
    <>
      {showData && (
        <tr>
          <td colSpan={measure?.arms?.length > 0 ? 4 : 3}>
            <div className="flex flex-col" style={{ gap: 16 }}>
              {measure?.outcome_measure_analysis?.map((analysis, i) => {
                return (
                  <StatisticalAnalysisItem
                    key={`om-statistical-analysis-item-${measure.id}-${i}`}
                    index={i}
                    measure={measure}
                    analysis={analysis}
                    schema={schema}
                    outcomeData={outcomeData}
                    setOutcomeData={setOutcomeData} />
                )
              })}
            </div>
          </td>
        </tr>
      )}
      <tr
        className="disabled-row">
        <td colSpan={measure?.arms?.length > 0 ? 4 : 3}>
          <div className="footer-actions">
            {measure?.outcome_measure_analysis?.length > 0 && (
              <Button
                size="sm"
                variant="primary-dashed"
                onClick={() => setShowData(!showData)}>
                {showData ? "Hide statistical analysis -" : "Show statistical analysis +"}
              </Button>
            )}
            {readOnly !== true && (
              <Button
                size="sm"
                variant="primary-dashed"
                onClick={addStatisticalAnalysis}>
                Add statistical analysis +
              </Button>
            )}
          </div>
        </td>
      </tr>
    </>
  );
};

export default StatisticalAnalysis;