import { useState } from 'react';
import { useShallow } from 'zustand/react/shallow'

// components
import PrimeField from 'components/PrimeField/PrimeField';
import ArmActions from '../../../components/ArmActions/ArmActions';
import { Button } from 'components-design-system';
import CellValidation from '../../../components/CellValidation/CellValidation';

// context
import useAuthoringViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringViewStore';
import useProtocolTableArmsStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useProtocolTableArmsStore';

const Cell = ({
  fieldSchema,
  fieldKey,
  onChange,
  rowIndex,
  colIndex,
  tableData,
  setTableData,
  cell,
  tableType
}) => {
  const {
    setArms,
    setInterventions
  } = useProtocolTableArmsStore()

  const {
    readOnly
  } = useAuthoringViewStore(
    useShallow(state => ({ readOnly: state.readOnly }))
  )

  const [showActions, setShowActions] = useState(false)

  const handleLeft = () => {
    let newData = [...tableData];
    let temp = newData[colIndex - 1];
    newData[colIndex - 1] = newData[colIndex];
    newData[colIndex] = temp;
    setTableData([...newData]);
  }

  const handleRight = () => {
    let newData = [...tableData];
    let temp = newData[colIndex + 1];
    newData[colIndex + 1] = newData[colIndex];
    newData[colIndex] = temp;
    setTableData([...newData]);
  }

  const onDeleteArm = () => {
    let newData = [...tableData];
    newData.splice(colIndex, 1);
    setTableData([...newData]);

    if (tableType === "arms") {
      setArms([...newData])
    } else if (tableType === "interventions") {
      setInterventions([...newData])
    }
  }

  const renderField = () => {
    let type = fieldSchema.type;
    if (type === "string") type = "text"
    let value = cell[fieldKey]
    if(type === "multiselect" && !value) value = []

    return (
      <PrimeField
        schema={{
          type: type,
          mode: fieldSchema.mode,
          items: fieldSchema.enum || []
        }}
        readOnly={readOnly}
        value={value}
        onChange={onChange} />
    )
  }

  return (
    <td
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}>
      {renderField()}
      {readOnly !== true &&
        <ArmActions
          arms={tableData}
          arm={cell}
          colIndex={colIndex}
          showActions={showActions}
          showReorderModal={false}
          handleLeft={rowIndex === 0 ? handleLeft : null}
          handleRight={rowIndex === 0 ? handleRight : null}
          canDelete={rowIndex === 0 ? true : false}
          handleDeleteArm={onDeleteArm}
          jpath={`st-${tableType}-${cell.id}-${fieldKey}`}
          type={tableType.slice(0, tableType.length - 1)} />
      }
      <CellValidation
        table={tableType}
        errorKey={`protocol_study_arms_${tableType}_${colIndex}_${fieldKey}`} />
      {rowIndex === 0 && (
        <CellValidation
          table={tableType}
          errorKey={`protocol_study_arms_${tableType}_${colIndex}`} />
      )}
    </td>
  );
};

export default Cell;