import React, { useState, useEffect, createContext } from 'react';
import styled from 'styled-components';
import { useShallow } from 'zustand/react/shallow'

// components
import Header from '../components/Header/Header'
import OutcomeMeasure from './OutcomeMeasure';

// context
import useAuthoringDataStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useAuthoringDataStore';
import useResultsTableViewStore from 'containers/studies/Study/subcomponents/Authoring/hooks/stores/useResultsTableViewStore';

export const OMContext = createContext(null);


const OutcomePreview = (props) => {
  const [baselinePeriod, setBaselinePeriod] = useState(null);
  let { schema = null, options, formContext } = props;
  const {
    formData,
  } = useAuthoringDataStore(
    useShallow(state => ({
      formData: state.formData,
    }))
  )
  const {
    tableView
  } = useResultsTableViewStore()
  let data = formData?.results?.outcome_measures?.outcome_measures_table?.[tableView];

  let bgColor = "rgb(238, 238, 254)";

  useEffect(() => {
    if(formContext?.formData?.results?.participant_flow?.participant_flow_table?.eudract_periods) {
      let baselinePeriod = formContext.formData.results.participant_flow.participant_flow_table.eudract_periods.find(period => period?.baseline_period === "Yes");
      setBaselinePeriod(baselinePeriod)
    }
  }, [formContext.formData])

  let sas = data?.subject_analysis_sets;

  if(tableView === "eudract") {
    sas = formData?.results?.baseline_characteristics?.baseline_characteristics_table?.[tableView]?.subject_analysis_sets
  }

  return (
    <OMContext.Provider value={{
      view: tableView,
      data
    }}>
      <MeasureList>
        {tableView === "eudract" && baselinePeriod && 
          <Header
            title={"Periods"}
            bgColor={bgColor}
            subjectAnalysisSets={baselinePeriod?.arms || null}
            view={tableView}/>
        }
        <Header
          title={"Subject Analysis Sets"}
          bgColor={bgColor}
          subjectAnalysisSets={sas || null}/>
        {data?.outcome_measures?.map((om, i) => (
          <OutcomeMeasure 
            key={om.id}
            index={i + 1}
            bgColor={bgColor}
            data={om} 
            schema={schema}
            view={tableView}
            formData={formData}/>
        ))}
      </MeasureList>

    </OMContext.Provider>
  );
};

export default OutcomePreview;

const MeasureList = styled.div`

`