import useGetApi from "../useGetApi"
import useMutateApi from "../useMutateApi"
import getUrl from "api/apiMap"
import apiEndpoints from "api/utils/apiEndpoints"
import queryKeys from "api/utils/queryKeys"

export const useGetGridPresets = (gridName) => {
  return useGetApi(
    `${getUrl(apiEndpoints.gridPresets)}`,
    queryKeys.gridPresets.gridDetails(gridName),
    {},
    { "preset-ident": gridName }
  )
}

export const usePostGridPreset = (gridName) => {
  return useMutateApi({
    method: "post",
    apiEndpoint: `${getUrl(apiEndpoints.gridPresets)}`,
    invalidateQueries: [queryKeys.gridPresets.gridDetails(gridName)],
    successMsg: 'Successfully created preset'
  })
}

export const usePutGridPreset = (gridName) => {
  return useMutateApi({
    method: "put",
    apiEndpoint: `${getUrl(apiEndpoints.gridPresets)}`,
    invalidateQueries: [queryKeys.gridPresets.gridDetails(gridName)],
    // successMsg: 'Successfully updated preset'
  })
}

export const useDeleteGridPreset = (gridName) => {
  return useMutateApi({
    method: "delete",
    apiEndpoint: `${getUrl(apiEndpoints.gridPresets)}`,
    invalidateQueries: [queryKeys.gridPresets.gridDetails(gridName)],
    successMsg: 'Successfully deleted preset'
  })
}