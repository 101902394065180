import { useMemo } from 'react';
import { Popover } from 'antd'

// styles
import './DocumentsUploadStatus.scss'

const iconProps = {
  info: {
    color: "#18669E",
    icon: "info-circle"
  },
  warning: {
    color: "#ECB81E",
    icon: "exclamation-triangle",
  },
  error: {
    color: "#D7542C",
    icon: "do-not-enter"
  }
}

const DocumentsUploadStatus = (props) => {
  const {
    data,
    value = []
  } = props;

  if (!value || value?.length === 0) return <div>N/A</div>;

  return (
    <div className="submissions-documents-upload-container">
      {value.map((docName) => {
        return (
          <DocumentUpload
            key={docName}
            name={docName}
            status={data?.document_upload_status?.[docName]} />
        )
      })}
    </div>
  );
};

export default DocumentsUploadStatus;

const DocumentUpload = ({
  name,
  status
}) => {

  const getStatus = useMemo(() => {
    let statuses = []
    if (status?.messages) {
      if (status.messages?.info?.length > 0) {
        statuses.push({
          type: "Info",
          icon: <i className={`fas fa-${iconProps.info.icon}`} style={{ color: iconProps.info.color, fontSize: ".75rem" }} />,
          messages: status.messages.info
        })
      }
      if (status.messages?.warning?.length > 0) {
        statuses.push({
          type: "Warning",
          icon: <i className={`fas fa-${iconProps.warning.icon}`} style={{ color: iconProps.warning.color, fontSize: ".75rem" }} />,
          messages: status.messages.warning
        })
      }
      if (status.messages?.error?.length > 0) {
        statuses.push({
          type: "Error",
          icon: <i className={`fas fa-${iconProps.error.icon}`} style={{ color: iconProps.error.color, fontSize: ".75rem" }} />,
          messages: status.messages.error.map(message => message.replace("ERROR: ", ""))
        })
      }
    }

    return statuses
  }, [status])

  return (
    <Popover
      open={getStatus.length === 0 ? false : undefined}
      content={(
        <div className="doc-upload-status-popover-container">
          {getStatus?.map(status => {
            return (
              <div>
                <div style={{ fontWeight: "600" }}>
                  {status.icon} {status.type}
                </div>
                <ul>
                  {status.messages.map(message => {
                    return (
                      <li>{message}</li>
                    )
                  })}
                </ul>
              </div>
            )
          })}
        </div>
      )}>
      <div className="document-upload">
        {name}
        <div className="icon-status-container">
          {getStatus?.map(status => status.icon)}
        </div>
      </div>
    </Popover>
  )
}