import {
  useLocation,
  Navigate,
  Outlet,
} from 'react-router-dom'

import useAuth from 'context/hooks/useAuth'
import { useUserPermissions } from 'api/hooks'
import useAppState from 'context/hooks/useAppState'

const RequireAuth = ({ }) => {
  const location = useLocation()
  const pathname = location.pathname
  
  const {
    appState
  } = useAppState()
  const { data: permissions, isLoading: isPermissionsLoading } = useUserPermissions()

  if (isPermissionsLoading === false && !permissions) return <Navigate to="/login" state={{ from: pathname }} replace={true} />

  if (appState === "authenticated") {
    return <Outlet />
  }

  return <Navigate to="/login" state={{ from: pathname }} replace={true} />
}

export default RequireAuth